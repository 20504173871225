import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { ExchangesLegendV2Provider } from "src/context/CEX/Dashboard/ExchangesLegendV2";
import { ExchangesOverviewContext } from "src/context/CEX/Dashboard/v2/ExchangesOverview";
import { entries } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { ExchangesBalancesData } from "src/state/CEX/CEXDashboard/v1/ExchangesStore";
import {
  ExchangePieV2Type,
  ExchangesV2DataProvider,
} from "src/state/CEX/CEXDashboard/v2/providers/ExchangesV2DataProvider";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { ExchangesLegendV2 } from "../../../ExchangesOverview/ExchangesLegendV2";
import { ExchangesPieV2, ExchangesPieV2Props } from "../../../ExchangesOverview/ExchangesPieV2";
import { useChartColorWheel } from "../../../shared/hooks/useChartColorWheel";
import { DashboardV2Widget, getWidgetInfo, getWidgetTitle } from "../shared/constants";
import * as styles from "./style";

type ExchangeTypeTitleMap = Record<ExchangePieV2Type, string>;

const EXCHANGE_TYPE_TITLE_MAP: ExchangeTypeTitleMap = {
  [ExchangePieV2Type.FreeUsd]: "Free Balance in USD",
  [ExchangePieV2Type.LockedUsd]: "Locked Balance in USD",
  [ExchangePieV2Type.TotalUsd]: "Total Balance in USD",
  [ExchangePieV2Type.TotalTokens]: "Total Balance in tokens",
};

type ExchangesData = {
  data: number[];
  exchanges: string[];
  colors: string[];
};

const getExchangePieData = ({ data, exchanges, colors }: ExchangesData) => {
  const pieData: ExchangesPieV2Props["data"] = {
    labels: exchanges,
    datasets: [
      {
        indexAxis: "y",
        data,
        backgroundColor: colors,
        borderWidth: 0,
        hoverOffset: 3,
      },
    ],
  };

  return pieData;
};

type ExchangePieData = {
  type: ExchangePieV2Type;
  data: ExchangesPieV2Props["data"];
  title: string;
};
interface UseExchangesPieDataParams {
  exchanges: Record<ExchangePieV2Type, ExchangesBalancesData>;
}

const useExchangesData = ({ exchanges }: UseExchangesPieDataParams) => {
  const colorWheel = useChartColorWheel();

  const exchangesData: ExchangePieData[] = useMemo(
    () =>
      entries(exchanges).map(([type, balances]) => {
        const values = Object.values(balances);
        const exchanges = Object.keys(balances);
        const colors = colorWheel.slice(0, values.length);

        const data = getExchangePieData({ data: values, exchanges, colors });

        const title = EXCHANGE_TYPE_TITLE_MAP[type];

        return { type, data, title };
      }),
    [colorWheel, exchanges]
  );

  return exchangesData;
};

export interface ExchangesOverviewProps
  extends Omit<CardProps, "title" | "info" | "after" | "loading">,
    styles.ExchangesCardProps {}

export const ExchangesOverview = observer(({ orientation, ...props }: ExchangesOverviewProps) => {
  const { pieData, loading } = useLateInitContext(ExchangesOverviewContext.Context);

  const exchangesData = useExchangesData({
    exchanges: pieData,
  });

  return (
    <styles.ExchangesCard
      title={getWidgetTitle(DashboardV2Widget.ExchangesOverview)}
      info={getWidgetInfo(DashboardV2Widget.ExchangesOverview)}
      after={<ExchangesLegendV2 />}
      loading={loading}
      $orientation={orientation}
      {...props}
    >
      <styles.PiesContainer>
        {exchangesData.map(({ data, title, type }) => (
          <styles.ChartContainer key={type}>
            <styles.ChartWrapper>
              <ExchangesPieV2 data={data} type={type} />
            </styles.ChartWrapper>
            <styles.ChartTitle>{title}</styles.ChartTitle>
          </styles.ChartContainer>
        ))}
      </styles.PiesContainer>
    </styles.ExchangesCard>
  );
});

export interface ExchangesOverviewWidgetProps extends ExchangesOverviewProps {}

export const ExchangesOverviewWidget = (props: ExchangesOverviewWidgetProps) => {
  const dataProvider = useLocalStore(ExchangesV2DataProvider);
  return (
    <ExchangesOverviewContext.Provider>
      <ExchangesLegendV2Provider dataProvider={dataProvider}>
        <ExchangesOverview {...props} />
      </ExchangesLegendV2Provider>
    </ExchangesOverviewContext.Provider>
  );
};
