import { ButtonProps } from "src/components/shared/Buttons/v2/Button";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";

export interface SaveButtonProps extends ButtonProps {}

export const SaveButton = (props: SaveButtonProps) => (
  <SubmitButton size="m" {...props}>
    Add
  </SubmitButton>
);
