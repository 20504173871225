import { makeAutoObservable, runInAction } from "mobx";
import { SingleValue } from "react-select";
import { getBotSettings } from "src/api/bots/CEX/settings";
import { deleteElem } from "src/helpers/array";
import { getDataByKey } from "src/helpers/forms/getByKey";
import { getSelectorValueFromOptions } from "src/helpers/forms/selectors";
import { GenericSelectorValue, objectToArrayOptions } from "src/helpers/obj";
import { CEXSettings } from "src/modules/settings";
import { SETTINGS_PARAMS_CONFIG_MAP } from "./shared/consts";
import { FormControlCb, LabelsParamsMapType, SettingsParamsPaths } from "./shared/types";
import {
  formConfigToSelectorOptions,
  getParamDependencies,
  handleSettingsParamValue,
} from "./shared/utils";

export class ChooseSettingParamsStore {
  private _botUUID: string;

  private _selectParam: SettingsParamsPaths | "" = "";

  private _settingsParams: SettingsParamsPaths[] = [];

  private _setValueFormCbs: FormControlCb[] = [];

  private _labelsParamsMap = formConfigToSelectorOptions(SETTINGS_PARAMS_CONFIG_MAP);

  private _selectorOptions = objectToArrayOptions(this._labelsParamsMap);

  private _currentBotSettings: CEXSettings | null = null;

  private _isLoading = false;

  constructor(uuid: string) {
    this._botUUID = uuid;

    makeAutoObservable(this);
  }

  get selectParam() {
    if (!this._selectParam) return null;

    const getSelectValue = getSelectorValueFromOptions(this._selectorOptions);

    return getSelectValue(this._selectParam);
  }

  get selectorOptions() {
    return this._selectorOptions.filter((el) => !this._settingsParams.includes(el.value));
  }

  get settingsParams() {
    return this._settingsParams;
  }

  get isAddedParams() {
    return !this._settingsParams.length;
  }

  get isLoading() {
    return this._isLoading;
  }

  get currentBotName() {
    return this._currentBotSettings?.name || "";
  }

  loadData = () => {
    this._getCurrentSettings();
  };

  setFormSetter = (cbs: ((param: SettingsParamsPaths) => void)[]) => {
    this._setValueFormCbs = cbs;
  };

  selectorHandler = (
    data: SingleValue<GenericSelectorValue<LabelsParamsMapType, SettingsParamsPaths>>
  ) => {
    const selectParam = data?.value;

    if (selectParam) this._selectParam = selectParam;
  };

  addSettingParam = () => {
    if (!this._selectParam) return;

    this._addSettingsParam(this._selectParam);

    const paramDependency = getParamDependencies(this._selectParam);

    if (paramDependency) {
      paramDependency.forEach((param) => {
        this._addSettingsParam(param);
      });
    }

    this._resetSelectParam();
  };

  setSettingsParams = (params: SettingsParamsPaths[], isRegisterParam: boolean = false) => {
    params.forEach((param) => {
      this._addSettingsParam(param, isRegisterParam);
    });
  };

  removeSettingParamHandler =
    (param: SettingsParamsPaths, unregisterCbs: FormControlCb[]) => () => {
      this._removeSettingsParam(param, unregisterCbs);

      const paramDependencies = getParamDependencies(param);

      if (paramDependencies) {
        paramDependencies.forEach((el) => {
          this._removeSettingsParam(el, unregisterCbs);
        });
      }
    };

  removeSetCb = (cb: FormControlCb) => {
    const foundIndex = this._setValueFormCbs.findIndex((el) => el === cb);

    if (foundIndex !== -1) {
      this._setValueFormCbs.splice(foundIndex, 1);
    }
  };

  getCurrentSettingsParam = (param: SettingsParamsPaths) => {
    if (!this._currentBotSettings) return "";

    const value = getDataByKey(this._currentBotSettings.settings, param);

    return handleSettingsParamValue(value);
  };

  private _setCurrentBotSettings = (data: CEXSettings) => {
    this._currentBotSettings = data;
  };

  private _checkDuplicateParam = (param: SettingsParamsPaths) =>
    this._settingsParams.includes(param);

  private _setSettingsParams = (param: SettingsParamsPaths) => {
    const isExistParam = this._checkDuplicateParam(param);

    if (!isExistParam) runInAction(() => this._settingsParams.push(param));
  };

  // isRegisterParam flag for call set value for react-hook-form state
  private _addSettingsParam = (param: SettingsParamsPaths, isRegisterParam: boolean = true) => {
    this._setSettingsParams(param);

    if (isRegisterParam)
      this._setValueFormCbs.forEach((cb) => {
        cb(param);
      });
  };

  private _removeSettingsParam = (param: SettingsParamsPaths, unregisterCbs: FormControlCb[]) => {
    runInAction(() => deleteElem(this._settingsParams, param));

    unregisterCbs.forEach((cb) => cb(param));
  };

  private _resetSelectParam = () => {
    this._selectParam = "";
  };

  private _getCurrentSettings = async () => {
    this._isLoading = true;

    try {
      const { isError, data } = await getBotSettings(this._botUUID);

      if (!isError) {
        this._setCurrentBotSettings(data);
      }
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  };

  destroy = () => {};
}
