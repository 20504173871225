import styled from "styled-components";
import { Form } from "../../LiquidityTab/base/Form";
import { Wrapper } from "../shared";

export const AddForm = styled(Form)`
  width: 100%;

  align-items: flex-end;

  gap: 20px;
`;

export const InputWrapper = styled(Wrapper)`
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
`;
