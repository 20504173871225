import { makeAutoObservable } from "mobx";
import { IDisposable } from "src/helpers/utils";
import { ISwapProviders } from "..";
import { PriceCalculatorStore } from "./PriceCalculator";
import { ISwapWidgetParams, SwapWidgetStore } from "./SwapWidget";
import {
  INonNullableVersionProviderBuilder,
  NonNullableVersionProvider,
} from "./shared/Providers/NonNullableVersionProvider";
import { RouterProvider } from "./shared/Providers/RouterProvider";
import { IPriceCalculator } from "./shared/SwapModules/PriceCalculator";
import { ISwapWidget } from "./shared/SwapModules/SwapWidget";
import { EmptyPriceCalculator } from "./shared/SwapModules/empty/EmptyPriceCalculator";
import { EmptySwapWidget } from "./shared/SwapModules/empty/EmptySwapWidget";
import { INonNullableVersionProvider } from "./shared/VersionedSwapState";

export interface ISwapModulesParams
  extends Omit<ISwapWidgetParams, "calculatorState" | "routerProvider" | "versionProvider"> {
  swapProviders: ISwapProviders;
}

export interface ISwapModules extends IDisposable {
  swapWidgetState: ISwapWidget;
  calculatorState: IPriceCalculator;
}

export class SwapModulesStore implements ISwapModules {
  private _routerProvider: RouterProvider | null = null;

  private _calculatorState: PriceCalculatorStore | null = null;

  private _swapWidgetState: SwapWidgetStore | null = null;

  private _nonNullableVersionProvider: INonNullableVersionProviderBuilder & IDisposable;

  constructor(params: ISwapModulesParams) {
    makeAutoObservable(this);

    const { swapSettingsProvider } = params.swapProviders;

    this._nonNullableVersionProvider = new NonNullableVersionProvider({
      versionProvider: swapSettingsProvider.versionProvider,
    });

    this._initState(params);
  }

  private _initState = async (params: ISwapModulesParams) => {
    const versionProvider = await this._nonNullableVersionProvider.getVersion();

    if (!versionProvider) return;

    this._createModules(params, versionProvider);
  };

  private _createModules = (
    params: ISwapModulesParams,
    versionProvider: INonNullableVersionProvider
  ) => {
    const { swapSettingsProvider } = params.swapProviders;

    const routerProvider = new RouterProvider({
      chainInfoProvider: params.chainInfoProvider,
      swapSettingsProvider,
      versionProvider,
    });

    this._routerProvider = routerProvider;

    const calculatorState = new PriceCalculatorStore({
      ...params,
      routerProvider,
      pairAddressProvider: swapSettingsProvider,
      versionProvider,
    });

    this._calculatorState = calculatorState;

    const swapWidget = new SwapWidgetStore({
      ...params,
      calculatorState: calculatorState.state,
      routerProvider,
      versionProvider,
    });

    this._swapWidgetState = swapWidget;
  };

  get swapWidgetState(): ISwapWidget {
    return this._swapWidgetState || new EmptySwapWidget();
  }

  get calculatorState(): IPriceCalculator {
    return this._calculatorState?.state || new EmptyPriceCalculator();
  }

  destroy = () => {
    this._nonNullableVersionProvider.destroy();
    this._swapWidgetState?.destroy();
    this._calculatorState?.destroy();
    this._routerProvider?.destroy();
  };
}
