import { ChainId } from "./chains";

export const QUOTER_V2_ADDRESSES: Partial<Record<ChainId, Record<string, string>>> = {
  [ChainId.Ethereum]: {
    UniSwapV3: "0x61fFE014bA17989E743c5F6cB21bF9697530B21e",
  },
  [ChainId.Arbitrum]: {
    UniSwapV3: "0x61fFE014bA17989E743c5F6cB21bF9697530B21e",
  },
  [ChainId.Polygon]: {
    UniSwapV3: "0x61ffe014ba17989e743c5f6cb21bf9697530b21e",
  },
  [ChainId.Core]: {
    SushiSwapV3: "0xf83ACd98eBa2d14182847Dd076BbC6D64D03FC29",
  },
  [ChainId.Mantle]: {
    FusionXV3: "0x90f72244294E7c5028aFd6a96E18CC2c1E913995",
  },
  [ChainId.Kava]: {
    Wagmi: "0x8187808B163E7CBAcCc4D0A9B138AE6196ac1f72",
  },
  [ChainId.BSCTestnet]: {
    PancakeSwapV3: "0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2",
  },
  [ChainId.BSC]: {
    PancakeSwapV3: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
  },
  [ChainId.opBNB]: {
    PancakeSwapV3: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
  },
  [ChainId.Linea]: {
    LynexV3: "0x851d97Fd7823E44193d227682e32234ef8CaC83e",
  },
  [ChainId.LineaTestnet]: {
    LynexV3: "0x81caadB9770c6BCD96E1682758fF3c527fb0c9D8",
  },
};
