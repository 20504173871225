import { useMemo } from "react";
import { formatElapsedTime, unixToSinceFormat } from "src/helpers/dateUtils";
import CEXDashboardIcons from "src/icons/CEXDashboardIcons";
import { PartyExchange } from "src/state/CEX/CEXDashboard/v1/KPIStore";
import { KPICardProps } from "../../../../shared/KPICard";
import { CaptionTitle } from "../../../../shared/KPICard/Caption/style";
import * as styles from "../shared/style";

const getSinceTimeText = (sinceTime: number) => {
  const formattedSinceTime = unixToSinceFormat(sinceTime);
  const formattedElapsedTime = formatElapsedTime(sinceTime);
  return {
    sinceTime: formattedSinceTime,
    elapsedTime: formattedElapsedTime,
  };
};

const useSinceDateText = (sinceTime: number) => {
  const sinceTimeText = useMemo(
    () => (sinceTime ? getSinceTimeText(sinceTime) : { sinceTime: "-", elapsedTime: "-" }),
    [sinceTime]
  );

  return sinceTimeText;
};

const useExchangesTimeText = (exchanges: PartyExchange[]) => {
  const exchangesText = useMemo(
    () =>
      exchanges
        .map(({ name, workingSince }) => {
          const { sinceTime, elapsedTime } = getSinceTimeText(workingSince);
          const formattedSinceTime = `${sinceTime} / ${elapsedTime}`;
          return `${name} - ${formattedSinceTime}`;
        })
        .join("\n"),
    [exchanges]
  );
  return exchangesText;
};

export interface WorkingSinceCardProps
  extends Omit<KPICardProps, "title" | "caption" | "subtitle" | "kpi"> {
  sinceDate: number;
  exchanges: PartyExchange[];
}

export const WorkingSinceCard = ({ sinceDate, exchanges, ...props }: WorkingSinceCardProps) => {
  const { sinceTime: sinceTimeText, elapsedTime: elapsedTimeText } = useSinceDateText(sinceDate);

  const exchangesText = useExchangesTimeText(exchanges);

  return (
    <styles.StyledKPICard
      title="Working with Gotbit:"
      info={exchangesText}
      titleIcon={<CEXDashboardIcons.Calendar />}
      kpi={
        <styles.KPIWrapper>
          <styles.KPILabel>{sinceTimeText}</styles.KPILabel>
          <CaptionTitle>{elapsedTimeText}</CaptionTitle>
        </styles.KPIWrapper>
      }
      {...props}
    />
  );
};
