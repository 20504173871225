import { checkParty } from "src/helpers/checkParty";
import { Abilities } from "src/modules/abilities";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
`;

export interface ContentProps {
  abilities: Abilities;
  abilityName: string;
  party: string;
}

export const Content = styled.div<ContentProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ${({ abilities, abilityName, party }) => {
    const checkPermsn = checkParty(abilities, abilityName, party);

    if (checkPermsn)
      return `
  "current-fundings new-funding"`;

    return `
  "current-fundings current-fundings"`;
  }};

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: ${({ abilities, abilityName, party }) => {
      const checkPermsn = checkParty(abilities, abilityName, party);

      if (checkPermsn)
        return `
        "new-funding" "current-fundings"`;

      return `
      "current-fundings current-fundings"`;
    }};
  }
`;

export const Title = styled.h3`
  color: #166ce2;
  padding-bottom: 5px;
  border-bottom: ${({ theme }) => theme.borders.solidGray};
`;

export const SubTitle = styled.h4`
  color: #767676;
  margin: 0px 0px 10px;
`;
