import { makeAutoObservable } from "mobx";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import { makeLoggable } from "src/helpers/logger";
import { StringSelectorValue } from "src/modules/shared";
import {
  ExchangeLegendChartData,
  IExchangesLegendChartDataProvider,
} from "../../shared/ExchangesLegendV2Store/types";

export enum ExchangePieV2Type {
  FreeUsd = "Free usd",
  LockedUsd = "Locked usd",
  TotalUsd = "Total usd",
  TotalTokens = "Total tokens",
}

const DEFAULT_CHART_IDS = Object.values(ExchangePieV2Type);

export class ExchangesV2DataProvider
  implements IExchangesLegendChartDataProvider<ExchangePieV2Type>
{
  private _chartDataMap: Map<string, ExchangeLegendChartData> = new Map();

  private _selectedChart: ExchangePieV2Type = ExchangePieV2Type.FreeUsd;

  constructor() {
    makeAutoObservable(this);

    makeLoggable<any>(this, { _chartDataMap: true });
  }

  get columnTitles() {
    return {
      amount: "Balance",
    };
  }

  private get _chartIds() {
    return Array.from(this._chartDataMap.keys());
  }

  private get _chartOptions() {
    const keys = this._chartIds.length ? this._chartIds : DEFAULT_CHART_IDS;
    return getSelectorList(keys);
  }

  private get _chartValue() {
    return stringToSelectorValue(this._selectedChart);
  }

  private _onChartChange = (data: StringSelectorValue | null) => {
    if (!data) return;
    this._selectedChart = data.value as ExchangePieV2Type;
  };

  get chartSelectorProps() {
    return {
      value: this._chartValue,
      options: this._chartOptions,
      onChange: this._onChartChange,
    };
  }

  private get _currentChartData() {
    return this._chartDataMap.get(this._selectedChart);
  }

  get data() {
    return this._currentChartData?.data ?? [];
  }

  get total() {
    return this._currentChartData?.total;
  }

  get charts() {
    return Array.from(this._chartDataMap.values()).map(({ chart }) => chart);
  }

  setChartData = (chartId: ExchangePieV2Type, data: ExchangeLegendChartData) => {
    this._chartDataMap.set(chartId, data);
  };

  removeChartData = (chartId: ExchangePieV2Type) => {
    this._chartDataMap.delete(chartId);
  };

  destroy = () => {
    this._chartDataMap.clear();
  };
}
