import React, { ComponentPropsWithoutRef } from "react";
import { numFormatter } from "src/helpers/separation";
import * as styles from "./style";

interface Props extends ComponentPropsWithoutRef<"div"> {
  value: string | number;
}

export const ThousandthSeparator = ({ value, ...props }: Props) => (
  <styles.Wrapper {...props}>{numFormatter(value)}</styles.Wrapper>
);
