import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export type InOutIndicatorType = "in" | "out" | "neutral";

export interface InOutIndicatorProps extends ComponentPropsWithoutRef<"div"> {
  type?: InOutIndicatorType;
}

export const InOutIndicator = ({ type = "neutral", ...props }: InOutIndicatorProps) => (
  <styles.Container {...props}>
    <styles.StyledIndicator $type={type} />
  </styles.Container>
);
