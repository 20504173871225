import { providers } from "ethers";
import { SyncSwapClassicPoolFactory } from "src/contracts/ethers/SyncSwapClassicPoolFactory";
import { SyncSwapClassicPoolFactory__factory } from "src/contracts/ethers/factories/SyncSwapClassicPoolFactory__factory";
import { IUniswapV2Factory__factory } from "src/contracts/factories/uniswap/v2/IUniswapV2Factory__factory";
import { IUniswapV2Factory } from "src/contracts/uniswap/v2/IUniswapV2Factory";
import { EntriesUnion } from "src/helpers/utils";
import { ADDRESS_ZERO, SYNC_SWAP_FACTORY_ADDRESS, SwapV2DEXType } from "../../../../../utils";
import { IPairContract, PairContract } from "./PairContract";

type SwapFactoryContractMap = {
  [SwapV2DEXType.SYNC]: SyncSwapClassicPoolFactory;
  [SwapV2DEXType.UNISWAP]: IUniswapV2Factory;
};

type SwapFactoryContract = EntriesUnion<SwapFactoryContractMap, "type", "contract">;

export interface IFactoryContract {
  get address(): string;
  get dexType(): SwapV2DEXType;
  getPair: (tokenA: string, tokenB: string) => Promise<IPairContract | null>;
  getPairAddress: (tokenA: string, tokenB: string) => Promise<string>;
}

export class FactoryContract implements IFactoryContract {
  private _address: string;

  private _factory!: SwapFactoryContract;

  private _provider: providers.JsonRpcProvider;

  constructor(address: string, provider: providers.JsonRpcProvider) {
    this._address = address;

    this._provider = provider;

    this._initFactory(address, provider);
  }

  private _initFactory = (address: string, provider: providers.JsonRpcProvider) => {
    const factory: SwapFactoryContract =
      address === SYNC_SWAP_FACTORY_ADDRESS
        ? {
            type: SwapV2DEXType.SYNC,
            contract: SyncSwapClassicPoolFactory__factory.connect(address, provider),
          }
        : {
            type: SwapV2DEXType.UNISWAP,
            contract: IUniswapV2Factory__factory.connect(address, provider),
          };

    this._factory = factory;
  };

  get address() {
    return this._address;
  }

  get dexType() {
    return this._factory.type;
  }

  getPairAddress = (tokenA: string, tokenB: string) => {
    const { type, contract } = this._factory;
    switch (type) {
      case SwapV2DEXType.SYNC: {
        return contract.getPool(tokenA, tokenB);
      }
      case SwapV2DEXType.UNISWAP: {
        return contract.getPair(tokenA, tokenB);
      }
    }
  };

  getPair = async (tokenA: string, tokenB: string) => {
    const address = await this.getPairAddress(tokenA, tokenB);
    if (!address || address === ADDRESS_ZERO) return null;
    const contract = new PairContract(address, this._provider, this.dexType);
    return contract;
  };
}
