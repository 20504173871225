import React, { ComponentPropsWithoutRef } from "react";
import { Headline3 } from "src/components/shared/Typography";
import * as styles from "./shared";

export interface SettingsModuleProps extends ComponentPropsWithoutRef<"div"> {
  title?: string;
}

export const SettingsModule = ({ title, children, ...props }: SettingsModuleProps) => (
  <styles.Container {...props}>
    <styles.WrapperTitle>
      <Headline3>{title}</Headline3>
    </styles.WrapperTitle>

    <styles.Content>{children}</styles.Content>
  </styles.Container>
);
