import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { makeLoggable } from "src/helpers/logger";
import { Nullish, delay } from "src/helpers/utils";
import {
  GetDashboardRequestParams,
  IBaseDashboardV2StoreParams,
  IDashboardV2StateProvider,
} from "..";
import {
  GenerateTimeSeriesOptions,
  generateAmount,
  generateRandomBoolean,
  generateTime,
  generateTimeSeries,
  generateTransactionsPoints,
  pointsToSeriesData,
} from "../../../shared/mocks";
import { padSeriesData, rawSeriesToData, timeToMs } from "../../utils";
import { INITIAL_TRANSACTIONS_DATA, TransactionsData } from "./BalancesV2Store";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

type PNLData = {
  allTime: number;
  absBars: {
    time: number[];
    value: number[];
  };
  percentBars: {
    time: number[];
    value: number[];
  };
};

const INITIAL_DATA: PNLData = {
  allTime: 0,
  absBars: {
    time: [],
    value: [],
  },
  percentBars: {
    time: [],
    value: [],
  },
};

const generateAllTimePNL = () => {
  const allTime = generateAmount();
  const isNegative = generateRandomBoolean();
  return isNegative ? -allTime : +allTime;
};

const generatePNLData = (): PNLData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 10, max: 30 });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    step: {
      value: 1,
      unit: "day",
    },
    startTimestamp,
    count: pointsCount,
    value: {
      min: -1000,
      max: 1000,
      precision: 0.01,
    },
  };

  const absBars = generateTimeSeries(generateSeriesOptions);
  const percentBars = generateTimeSeries(generateSeriesOptions);

  const allTime = generateAllTimePNL();

  return {
    allTime,
    absBars: pointsToSeriesData(absBars),
    percentBars: pointsToSeriesData(percentBars),
  };
};

interface IPNlStoreParams extends IBaseDashboardV2StoreParams {}

export default class PNLV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: PNLData = INITIAL_DATA;

  private _allTimePNL: number | null = null;

  private _monthPNL: number | null = null;

  private _previousMonthPNL: number | null = null;

  private _transactionsData: TransactionsData = INITIAL_TRANSACTIONS_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IPNlStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { transactions: true, allTime: true, previousMonth: true, month: true });
  }

  get loading() {
    return this._baseState.loading;
  }

  private _getRequestParams: GetDashboardRequestParams = (range) =>
    this._stateProvider.getRequestParams(range);

  private get _currentMonth() {
    return this._stateProvider.currentMonth;
  }

  private get _previousMonth() {
    return this._stateProvider.previousMonth;
  }

  private get _currentRange() {
    return this._stateProvider.selectedRange;
  }

  private _setData = (data: PNLData) => {
    this._data = data;
  };

  private _setTransactionsData = (data: TransactionsData) => {
    this._transactionsData = data;
  };

  private _setAllTimePNL = (pnl: number | null) => {
    this._allTimePNL = pnl;
  };

  private _setMonthPNL = (pnl: number | null) => {
    this._monthPNL = pnl;
  };

  private _setPreviousMonthPNL = (pnl: number | null) => {
    this._previousMonthPNL = pnl;
  };

  private get _transactions() {
    // const transactions = toJS(this._transactionsData);
    const { time, value } = this._transactionsData;
    return { time: timeToMs(time), value };
  }

  get transactions() {
    const transactions = this._transactions;
    return padSeriesData(transactions);
  }

  get allTime() {
    return this._allTimePNL;
  }

  get previousMonth() {
    return this._previousMonthPNL;
  }

  private _getRangeMonth = (range: DateTimeRange | null) => {
    if (!range) return null;
    const [start, end] = range;
    if (!start || !end) return null;
    return start.format("MMMM");
  };

  get previousMonthName() {
    return this._getRangeMonth(this._previousMonth);
  }

  get month() {
    return this._monthPNL;
  }

  get monthName() {
    return this._getRangeMonth(this._currentMonth);
  }

  private get _absBars() {
    return rawSeriesToData(this._data.absBars);
  }

  get absBars() {
    const seriesData = this._absBars;
    return padSeriesData(seriesData);
  }

  private get _percentBars() {
    return rawSeriesToData(this._data.percentBars);
  }

  get percentBars() {
    const seriesData = this._percentBars;
    return padSeriesData(seriesData);
  }

  private _getTotalPnl = async (
    setTotalPnl: (pnl: number | null) => void,
    period: Nullish<DateTimeRange>
  ) => {
    const queryParams = this._getRequestParams(period);
    if (!queryParams) return;

    setTotalPnl(null);
    try {
      await delay(200);
      const data = generateAllTimePNL();
      setTotalPnl(data);
    } catch {
      setTotalPnl(null);
    }
  };

  private _getAllTimePNL = async () => {
    await this._getTotalPnl(this._setAllTimePNL, this._currentRange);
  };

  private _getMonthPNL = async () => {
    await this._getTotalPnl(this._setMonthPNL, this._currentMonth);
  };

  private _getPreviousMonthPNL = async () => {
    await this._getTotalPnl(this._setPreviousMonthPNL, this._previousMonth);
  };

  private _getPNL = async () => {
    const queryParams = this._getRequestParams();
    if (!queryParams) return;

    this._setData(INITIAL_DATA);
    this._setTransactionsData(INITIAL_TRANSACTIONS_DATA);
    try {
      await delay(200);
      const data = generatePNLData();
      const transactionsData = generateTransactionsPoints(data.absBars.time);

      this._setData(data);
      this._setTransactionsData(transactionsData);
    } catch {
      this._setData(INITIAL_DATA);
      this._setTransactionsData(INITIAL_TRANSACTIONS_DATA);
    }
  };

  onStatsUpdate = async () => {
    await Promise.all([
      this._getPNL(),
      this._getMonthPNL(),
      this._getAllTimePNL(),
      this._getPreviousMonthPNL(),
    ]);
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
