import styled from "styled-components";

export const FormContainer = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 16px;
`;
