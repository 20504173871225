import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { useMemo } from "react";
import Icons from "src/icons/Icons";
import * as styles from "./style";

export interface FilterOwnProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export interface FilterProps
  extends FilterOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof FilterOwnProps> {}

export const Filter = observer(({ value, onChange, ...props }: FilterProps) => {
  const id = useMemo(() => nanoid(), []);

  return (
    <styles.Container {...props}>
      <styles.SearchInput
        id={id}
        placeholder="Search"
        error={false}
        value={value}
        onChange={onChange}
      />
      <styles.IconWrapper htmlFor={id}>{Icons.search()}</styles.IconWrapper>
    </styles.Container>
  );
});
