import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export type QuotesInfoItem = string;

export interface QuotesInfoProps extends ComponentPropsWithoutRef<"div"> {
  items: QuotesInfoItem[];
}

export const QuotesInfo = ({ items, ...props }: QuotesInfoProps) => (
  <styles.Container {...props}>
    {items.map((price, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <styles.Quote key={index}>{price}</styles.Quote>
    ))}
  </styles.Container>
);
