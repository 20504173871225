import { ComponentPropsWithoutRef } from "react";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { FormContent, FormRow } from "../../Settings/shared/style";
import * as styles from "./style";

interface PriceCalculatorProps extends ComponentPropsWithoutRef<"div"> {}

export const PriceCalculator = (props: PriceCalculatorProps) => (
  <styles.Container {...props}>
    <styles.Title>Price calculator</styles.Title>
    <styles.FormContainer>
      <FormContent>
        <FormRow>
          <LabeledInput type="number" label="Target price" />
          <LabeledInput type="number" label="Pool size before action" />
        </FormRow>
        <FormRow>
          <LabeledInput type="number" label="Pool size after action" />
          <LabeledInput type="number" label="Assets needed" />
        </FormRow>
      </FormContent>
    </styles.FormContainer>
  </styles.Container>
);
