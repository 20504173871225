import { observer } from "mobx-react-lite";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { AccountCurrencyFields } from "../../../shared/AccountCurrencyFields";
import { TimeframeField } from "../../../shared/TimeframeField";

export const FormContent = observer(() => (
  <>
    <RowWrapper>
      <AccountCurrencyFields />
    </RowWrapper>

    <RowWrapper>
      <TimeframeField />
    </RowWrapper>
  </>
));
