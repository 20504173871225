import { TooltipProps } from "src/components/shared/Graph/shared/Tooltip";
import { GraphTooltipRoot } from "../../../../../shared/Graph/GraphTooltip/GraphTooltipRoot";
import { PriceTooltipSeries } from "./PriceTooltipSeries";

export interface PriceChartTooltipProps extends TooltipProps {}

export const PriceChartTooltip = (props: PriceChartTooltipProps) => {
  const TooltipRoot = GraphTooltipRoot;

  const TooltipSeries = PriceTooltipSeries;

  return <TooltipRoot series={TooltipSeries} {...props} />;
};
