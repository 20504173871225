import { InputStyle } from "src/components/shared/Forms/Inputs";
import * as styles from "./style";

export interface DateTimeInputProps
  extends InputStyle.StyledInputProps,
    React.ComponentPropsWithoutRef<"input"> {
  after?: null | JSX.Element;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const DateTimeInput = ({ inputRef, after, ...props }: DateTimeInputProps) => (
  <styles.InputWrapper>
    <InputStyle.Input {...props} ref={inputRef} />
    {after}
  </styles.InputWrapper>
);
