import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { TokenFillContext } from "src/context/DEXV2/DEXV2Create/TokenFill";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FirstTabStepProps } from "..";
import { TablePanel } from "../TablePanel";
import { useBotUUID } from "../hooks/useBotUUID";
import { BalancesTable } from "./BalancesTable";

interface TokenFillProps extends FirstTabStepProps {}

export const TokenFill = observer(({ nextStep }: TokenFillProps) => {
  const state = useLateInitContext(TokenFillContext);
  const {
    tickers: { quote: quoteTicker, base: baseTicker },
  } = state;

  const uuid = useBotUUID();

  const next = useCallback(() => {
    state.finishDeploy(nextStep);
  }, [nextStep, state]);

  useEffect(() => {
    if (!uuid) return;
    state.setBotUUID(uuid);
    state.getVaults();
  }, [state, uuid]);

  const headingProps = { title: "Token Fill", ...state.chainMeta };

  const fillWarning = `To continue you need to fill the Main vault contract with ${baseTicker} token to perform Sell operations, and ${quoteTicker} token to perform Buy operations.`;

  return (
    <TablePanel
      headingProps={headingProps}
      warning={fillWarning}
      nextStep={next}
      loading={state.loading}
      onRefreshClick={state.refreshBalances}
      onChainRefreshClick={state.refreshChainBalances}
      nextStepLabel="Finish"
    >
      <BalancesTable />
    </TablePanel>
  );
});
