import { ComponentPropsWithoutRef, useCallback, useMemo } from "react";
import { OwnRefProps } from "src/helpers/utils";
import { ToggleButtonStyleProps } from "../ToggleButton/style";
import * as styles from "./style";

export interface MultiToggleButtonItem<V extends string> {
  value: V;
  label: JSX.Element | string;
}

const EMPTY_ITEM: MultiToggleButtonItem<string> = {
  value: "",
  label: "",
};

export interface MultiToggleButtonProps<V extends string>
  extends Omit<
      ComponentPropsWithoutRef<"button">,
      keyof ToggleButtonStyleProps | "onChange" | "value" | "onClick" | "children"
    >,
    Partial<Pick<ToggleButtonStyleProps, "size" | "elevated">>,
    OwnRefProps<"button"> {
  value?: MultiToggleButtonItem<V>["value"];
  onChange?: (value: MultiToggleButtonItem<V>["value"]) => void;
  items?: MultiToggleButtonItem<V>[];
  disableSelected?: boolean;
  errorHint?: string;
}

export const MultiToggleButton = <K extends string = string>({
  items = [EMPTY_ITEM] as MultiToggleButtonItem<K>[],
  onChange,
  value,
  ownRef,
  size = "m",
  elevated = false,
  disableSelected,
  errorHint,
  ...props
}: MultiToggleButtonProps<K>) => {
  const currentIndex = useMemo(() => {
    const index = items.findIndex((item) => item.value === value);
    return Math.max(index, 0);
  }, [value, items]);

  const nextIndex = useMemo(() => (currentIndex + 1) % items.length, [currentIndex, items.length]);

  const currentItem = items[currentIndex].label;
  const isFirstIndex = currentIndex === 0;

  const onNextItem = useCallback(() => {
    const nextItemKey = items[nextIndex].value;
    onChange?.(nextItemKey);
  }, [items, nextIndex, onChange]);
  return (
    <styles.StyledMultiToggleButton
      $selected={isFirstIndex && !disableSelected}
      $size={size}
      $elevated={elevated}
      onClick={onNextItem}
      ref={ownRef}
      $error={Boolean(errorHint)}
      {...props}
    >
      {currentItem}
    </styles.StyledMultiToggleButton>
  );
};
