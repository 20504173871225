import { useTheme } from "styled-components";
import { LineColor, ThemeLineColor } from "../types";

export function useLinePrimaryColor(color: LineColor): string;
export function useLinePrimaryColor(color?: LineColor): string | undefined;
export function useLinePrimaryColor(color?: LineColor) {
  const theme = useTheme();
  if (!color) return undefined;
  const themeColor = theme[color as ThemeLineColor] ?? color;
  return themeColor;
}
