import Icons from "src/icons/Icons";
import { Indicator, IndicatorsKeys } from "src/state/MarketCheck/types";
import * as styles from "./style";

interface MetaInfoIconProps {
  indicatorKey: IndicatorsKeys;
  indicatorMetaData: Indicator["meta"];
}

const metaInfoToString = (metaInfo: Record<string, string | number>) =>
  Object.entries(metaInfo)
    .map(([key, value]) => `${key}: ${value}`)
    .join("\n");

export const MetaInfoIcon = ({ indicatorKey, indicatorMetaData }: MetaInfoIconProps) => {
  if (!indicatorMetaData) return null;

  return (
    <styles.MetaPromptInfo
      data-tooltip-content={metaInfoToString(indicatorMetaData)}
      data-tooltip-id={indicatorKey}
    >
      <Icons.Prompt />

      <styles.TitlePromptMsg id={indicatorKey} />
    </styles.MetaPromptInfo>
  );
};
