import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { MarketCheckContext } from "src/context/MarketCheck";
import { Pagination } from "src/components/shared/Pagination";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { Loader } from "src/components/shared/Loader";
import { FullSizeProps } from "src/modules/shared";
import { TableVirtualRows } from "./TableVirtualRows";
import { TableParams } from "./TableParams";
import { partiesListColumns } from "./columns";
import * as styles from "./style";

interface PartiesListTableProps extends FullSizeProps {}

export const PartiesListTable = observer((props: PartiesListTableProps) => {
  const state = useLateInitContext(MarketCheckContext);
  const [tableRef, scrollToTop] = useScrollToTop<HTMLTableElement>();

  useEffect(() => {
    state.loadData();
  }, [state]);

  const getPartiesList = useCallback(
    (fromPage: number, pageSize: number) =>
      state.getPaginationPartiesList({ fromPage, pageSize, onSuccess: scrollToTop }),
    [scrollToTop, state]
  );

  return (
    <>
      <TableParams {...props} />

      <styles.TableWrapper>
        <TableVirtualRows
          data={state.partiesList}
          columns={partiesListColumns}
          rowSize={30}
          tableWrapperRef={tableRef}
          sortingState={state.tableSortingState}
          onSortingChange={state.setTableSortingState}
        />

        <styles.PaginationWrapper>
          <Pagination
            forcePage={state.currentPage}
            pageCount={state.pagesCount}
            itemsPerPage={state.itemsPerPage}
            getItems={getPartiesList}
            onForcePageChange={state.setPage}
          />
        </styles.PaginationWrapper>

        <Loader show={state.isLoading} />
      </styles.TableWrapper>
    </>
  );
});
