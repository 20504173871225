import { observer } from "mobx-react-lite";
import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { Switches } from "src/components/shared/Switches";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

interface Props {
  showLoader: boolean;
  party: string;
}

const PERIOD_INFO = `Period:
1 or 5 minute candles timeframe`;

export const PeriodSettings = observer(({ party, showLoader }: Props) => {
  const state = useLateInitContext(CEXSettingsContext);

  const items = [
    { value: 1, label: "1" },
    { value: 5, label: "5" },
  ];

  return (
    <SettingsPanel
      label="Period"
      party={party}
      loader={showLoader}
      submitHandler={state.submitModulHandler("period")}
      info={PERIOD_INFO}
      saved={state.savedModuls.period}
      style={{ gridArea: "period" }}
    >
      <styles.Wrapper>
        <Switches
          items={items}
          selectedItem={state.data.settings.period.value}
          onChange={state.getHandler("settings.period.value")}
        />
      </styles.Wrapper>
    </SettingsPanel>
  );
});
