import { makeAutoObservable } from "mobx";
import { IDEXV2CreateDataProvider } from ".";
import { ITokenAddressProvider } from "../../shared/TokenTickerValidationStore";

type CreateTokenType = "base" | "quote" | "stable";

interface ICreateAddressProviderStoreParams {
  dataProvider: IDEXV2CreateDataProvider;
  tokenType: CreateTokenType;
}

export default class CreateAddressProviderStore implements ITokenAddressProvider {
  private _dataProvider: IDEXV2CreateDataProvider;

  private _tokenType: CreateTokenType;

  constructor({ dataProvider, tokenType }: ICreateAddressProviderStoreParams) {
    makeAutoObservable(this);

    this._dataProvider = dataProvider;
    this._tokenType = tokenType;
  }

  private get _data() {
    return this._dataProvider.data;
  }

  get address() {
    switch (this._tokenType) {
      case "base":
        return this._data.base_data.base;
      case "quote":
        return this._data.base_data.quote;
      case "stable":
        return this._data.main_data.stable;
      default: {
        throw new Error("Unknown token type");
      }
    }
  }
}
