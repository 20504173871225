import React from "react";
import { Aum } from "src/state/MarketCheck/types";
import { getRounded } from "../helpers";
import { AssetCell } from "./AssetCell";
import * as styles from "./style";

interface AUMCellProps {
  cellData: Aum;
}

const tableHeaders = ["", "Δbase", "Δquote", "Δtotal"];

export const AUMCell = ({ cellData }: AUMCellProps) => {
  const { total, quote, base, stats } = cellData;

  return (
    <styles.Wrapper>
      <styles.AssetsContainer>
        <AssetCell label="Total" value={total} />
        <AssetCell label="Quote" value={quote.value} percent={quote.percent} />
        <AssetCell label="Base" value={base.value} percent={base.percent} />
      </styles.AssetsContainer>

      <styles.TableContainer>
        {tableHeaders.map((header) => (
          <styles.TableHeader key={header}>{header}</styles.TableHeader>
        ))}

        {stats.map((stat) => (
          <React.Fragment key={stat.month}>
            <styles.Month>{stat.month}</styles.Month>
            <styles.Delta>{getRounded(stat.base)}</styles.Delta>
            <styles.Delta>{getRounded(stat.quote)}</styles.Delta>
            <styles.Delta>{getRounded(stat.total)}</styles.Delta>
          </React.Fragment>
        ))}
      </styles.TableContainer>
    </styles.Wrapper>
  );
};
