import { observer } from "mobx-react-lite";
import { ConstraintsInput } from "src/components/BotsContent/CEX/CEXBotSettings/shared";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FormContent, SettingsPanel } from "../shared";

export const MainSettings = observer(() => {
  const state = useLateInitContext(DEXV2CreatingContext);

  return (
    <SettingsPanel>
      <FormContent>
        <LabeledInput
          type="number"
          step="any"
          label="Volume executors number"
          {...state.getInputProps("main_data.volume_executors_num")}
        />

        <LabeledInput
          type="number"
          step="any"
          label="Limit executors number"
          {...state.getInputProps("main_data.limit_executors_num")}
        />

        <LabeledInput
          type="number"
          step="any"
          label="Counter executors number"
          {...state.getInputProps("main_data.counter_executors_num")}
        />

        <LabeledPercentRangeInput
          label="Token Transaction Buy Fee"
          step="0.1"
          {...state.getInputProps("main_data.tt_buy_fee")}
        />

        <LabeledPercentRangeInput
          label="Token Transaction Sell Fee"
          step="0.1"
          {...state.getInputProps("main_data.tt_sell_fee")}
        />

        <LabeledInput
          type="number"
          step="any"
          label="Gas Limit"
          {...state.getInputProps("main_data.gas_limit")}
        />

        <ConstraintsInput
          label="Stable"
          {...state.getInputProps("main_data.stable")}
          {...state.getFieldErrorAsProps("main_data.stable")}
        />
      </FormContent>
    </SettingsPanel>
  );
});
