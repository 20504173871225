import { ComponentPropsWithoutRef, memo } from "react";
import { CopyButton } from "src/components/shared/Buttons/v2/variants/IconButtons/CopyButton";
import { setTextClipboard } from "src/helpers/clipboard";
import { strFormatter } from "src/helpers/string";
import { trimStartEndSlashes } from "src/helpers/url";
import { AddressLink } from "../../shared";
import * as styles from "./style";

export interface AddressCellOwnProps {
  address: string;
  scannerLink?: string;
  isShort?: boolean;
}
export interface AddressCellProps extends ComponentPropsWithoutRef<"div">, AddressCellOwnProps {}

export interface HeaderAddressCellProps extends ComponentPropsWithoutRef<"div"> {
  copyCb: () => void;
}

export const HeaderAddressCell = memo(({ copyCb, ...props }: HeaderAddressCellProps) => (
  <styles.AddressWrapper {...props}>
    Address
    <CopyButton
      onClick={() => {
        copyCb();
      }}
    />
  </styles.AddressWrapper>
));

export const AddressCell = memo(
  ({ address, scannerLink: baseUrl, isShort = true, ...props }: AddressCellProps) => {
    const scannerLink = baseUrl ? `${trimStartEndSlashes(baseUrl)}/${address}` : undefined;
    return (
      <styles.AddressWrapper {...props}>
        <AddressLink href={scannerLink}>{isShort ? strFormatter(address) : address}</AddressLink>
        <CopyButton
          onClick={() => {
            setTextClipboard(address);
          }}
        />
      </styles.AddressWrapper>
    );
  }
);
