import { CopyButton } from "src/components/shared/Buttons/v2/variants/IconButtons/CopyButton";
import { StatusPoint } from "src/components/shared/GridTable";
import { setTextClipboard } from "src/helpers/clipboard";
import { strFormatter } from "src/helpers/string";
import { Wrapper } from "../style";

export interface WithdrawerChipProps {
  withdrawer: string;
}

export const WithdrawerChip = ({ withdrawer }: WithdrawerChipProps) => (
  <Wrapper>
    <StatusPoint type="neutral">Withdrawer: {strFormatter(withdrawer)}</StatusPoint>
    <CopyButton
      onClick={() => {
        setTextClipboard(withdrawer);
      }}
    />
  </Wrapper>
);
