import { Controller, useFormContext } from "react-hook-form";
import { DateTimeInputPrompt } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { getUTCDateTimeInputValue } from "src/helpers/dateUtils";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import { ModuleSoftParamsShell } from "../../../../shared/ModuleSoftParamsShell";
import { SoftParamsFormsProps } from "../../../../shared/ModuleSoftParamsShell/types";
import { InputWrapper } from "../../../../shared/style";
import { CompareSelector } from "../shared/CompareSelector";

interface ModuleWithTimestampProps extends SoftParamsFormsProps {}

export const ModuleWithTimestamp = ({ index, ...props }: ModuleWithTimestampProps) => {
  const { control } = useFormContext<IStrategyCreator>();

  return (
    <ModuleSoftParamsShell {...props}>
      <InputWrapper>
        <CompareSelector control={control} name={`conditions.${index}.soft_params.compare`} />

        <Controller
          control={control}
          name={`conditions.${index}.soft_params.value`}
          rules={{ required: ERRORS_MSGS.isRequired }}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            const error = getFieldError(errors, `conditions.${index}.soft_params.value`);
            return (
              <LabeledInput
                label="Time"
                after={<DateTimeInputPrompt />}
                type="datetime-local"
                placeholder="YYYY-MM-DDThh:mm"
                value={value ? getUTCDateTimeInputValue(parseInt(value, 10)) : ""}
                onChange={(e) => onChange(String(getChangeEventValue(e)))}
                errorHint={error?.message}
              />
            );
          }}
        />
      </InputWrapper>
    </ModuleSoftParamsShell>
  );
};
