import { useMemo } from "react";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import { DashboardView } from "src/state/CEX/CEXDashboard/v1";
import { DashboardV2View } from "src/state/CEX/CEXDashboard/v2";
import { getDashboardVersion } from "../config/versions";
import { SHOW_VIEWS_TOGGLE, VISIBLE_V1_VIEWS } from "../config/views";

interface DashboardViewMenuItem extends MenuItemData {
  value: DashboardView;
}

const BASE_DASHBOARD_VIEW_MENU_ITEMS: DashboardViewMenuItem[] = [
  { label: "Summary", value: "summary" },
  { label: "Detailed", value: "detailed" },
  { label: "Balance", value: "balance-summary" },
];

const useDashboardV1Views = () => {
  const showToggle = SHOW_VIEWS_TOGGLE;
  const visibleViews = VISIBLE_V1_VIEWS;

  const menuItems = useMemo(
    () => BASE_DASHBOARD_VIEW_MENU_ITEMS.filter((item) => visibleViews.includes(item.value)),
    [visibleViews]
  );

  return { showToggle, menuItems };
};

interface DashboardV2ViewMenuItem extends MenuItemData {
  value: DashboardV2View;
}

const DASHBOARD_V2_VIEW_MENU_ITEMS: DashboardV2ViewMenuItem[] = [
  { label: "Balance", value: "balance-summary" },
  { label: "Market", value: "market-info" },
  { label: "PNL", value: "pnl" },
];

const useDashboardV2Views = () => {
  const showToggle = SHOW_VIEWS_TOGGLE;

  const menuItems = DASHBOARD_V2_VIEW_MENU_ITEMS;

  return { showToggle, menuItems };
};

export const useDashboardViews = () => {
  const version = getDashboardVersion();

  const v1Views = useDashboardV1Views();
  const v2Views = useDashboardV2Views();

  return version === "v1" ? v1Views : v2Views;
};
