import { StyledProps } from "src/helpers/styled/types";
import styled from "styled-components";

interface TokenDirectionCellProps {
  color: string;
  backgroundColor: string;
}

interface StyledTokenDirectionCellProps extends StyledProps<TokenDirectionCellProps> {}

export const StyledDirectionCell = styled.span<StyledTokenDirectionCellProps>`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
`;
