import { observer } from "mobx-react-lite";
import { useState } from "react";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { Headline2 } from "src/components/shared/Typography";
import { MultiGridAbilitiesContext, MultiGridContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { IModuleCreator } from "src/state/CEX/multiGrid/CreateMultiGrid";
import { AddModuleModal } from "../AddModuleModal";
import * as styles from "./style";

export interface AddExchangeModuleProps {
  label: string;
  moduleCreatorState: IModuleCreator;
}

export const AddExchangeModule = observer(
  ({ label, moduleCreatorState }: AddExchangeModuleProps) => {
    const [showModal, setShowModal] = useState(false);

    const mainState = useLateInitContext(MultiGridContext);

    const { abilityName } = useLateInitContext(MultiGridAbilitiesContext);

    return (
      <styles.Container>
        <Headline2>{label}</Headline2>

        <PermissionWrapper party={mainState.party} abilityName={abilityName}>
          <AddButton
            onClick={() => {
              setShowModal(true);
            }}
          />
        </PermissionWrapper>

        {showModal ? (
          <AddModuleModal
            moduleCreatorState={moduleCreatorState}
            show={showModal}
            close={setShowModal}
          />
        ) : null}
      </styles.Container>
    );
  }
);
