import { makeAutoObservable, runInAction } from "mobx";
import { makeVolume } from "src/api/bots/CEX/exchange";
import { getPathAndKey, getTargetValueByPath, getValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { balanceComparison, getAccBalances } from "src/helpers/trading";
import { MakeVolume } from "src/modules/exchange/trade";
import { ChangeValidate, SelectorValue } from "src/modules/shared";
import {
  graterThan,
  graterThanKey,
  isNumber,
  required,
  smallerThan,
  smallerThanKey,
  validateData,
} from "src/validation-schemas";
import ExchangeStore from "..";
import windowConsent from "../../../WindowConsent";
import {
  accountsMapToSelectorValue,
  filterBaseNameAccounts,
  sortAccounts,
} from "../../CEXApiKeys/AccountsBindings";

type SelectorFields = "account_uuid";

type InputFields = "number" | "priceMax" | "priceMin" | "quoteMax" | "quoteMin" | "sellFirst";

class VolumeStore {
  data: MakeVolume = {
    // bot_uuid: "",
    account_uuid: "",
    number: "",
    priceMax: "",
    priceMin: "",
    quoteMax: "",
    quoteMin: "",
    sellFirst: true,
    symbol: "",
    precisionPrice: 0,
    precisionAmount: 0,
  };

  showLoader: boolean = false;

  validation = {
    number: [
      required(),
      graterThan(0, "The value must be positive"),
      smallerThan(25, "The number of orders must not exceed 25"),
      isNumber(),
    ],
    priceMax: [
      required(),
      graterThan(0, "The value must be positive"),
      isNumber(),
      graterThanKey("priceMin", "Price max must be greater than Price min"),
    ],
    priceMin: [
      required(),
      graterThan(0, "The value must be positive"),
      isNumber(),
      smallerThanKey("priceMax", "Price min should be less than Price max"),
    ],
    quoteMax: [
      required(),
      graterThan(0, "The value must be positive"),
      isNumber(),
      graterThanKey("quoteMin", "Amount max must be greater than Amount min"),
    ],
    quoteMin: [
      required(),
      graterThan(0, "The value must be positive"),
      isNumber(),
      smallerThanKey("quoteMax", "Amount min should be less than Amount max"),
    ],
    account_uuid: required(),
  };

  onChangeValidate: ChangeValidate = {
    priceMax: ["priceMin", "priceMax"],
    priceMin: ["priceMin", "priceMax"],
    quoteMax: ["quoteMax", "quoteMin"],
    quoteMin: ["quoteMax", "quoteMin"],
  };

  errors = {
    bot_uuid: "",
    account_uuid: "",
    number: "",
    priceMax: "",
    priceMin: "",
    quoteMax: "",
    quoteMin: "",
  };

  mainState: ExchangeStore;

  currentAccName = "";

  constructor(state: ExchangeStore) {
    this.mainState = state;

    makeAutoObservable(this);
  }

  get accounts() {
    const accounts = filterBaseNameAccounts(this.mainState._accounts, ["info"]);

    const accountsOptions = accountsMapToSelectorValue(accounts).sort((a, b) =>
      sortAccounts(a.label, b.label)
    );

    return accountsOptions;
  }

  get currentNameAcc() {
    const currAcc = this.accounts.find(
      (el) => el.id === this.data.account_uuid && el.value === this.currentAccName
    );
    if (currAcc) return currAcc;

    return { label: "", value: "" };
  }

  setLoader = (bool: boolean) => {
    this.showLoader = bool;
  };

  getHandler = (field: InputFields) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.data, path);

    runInAction(() => {
      targetData[endKey] = getChangeEventValue(e);
    });

    if (field in this.onChangeValidate) {
      this.validate(this.onChangeValidate[field]);
    }
  };

  setValue = <K extends keyof MakeVolume>(field: K, value: MakeVolume[K]) => {
    this.data[field] = value;
  };

  getError = (key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  selectorHandler = (field: SelectorFields) => (data: SelectorValue | null) => {
    if (data) {
      this.setValue(field, String(data.id));
      runInAction(() => {
        this.currentAccName = String(data.value);
      });
    }
  };

  validate = (validateKeys: string[] | undefined) =>
    validateData(this.validation, this.data, this.errors, validateKeys);

  submitHandler = () => (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate(undefined);

    const [message, needQuote, needBase] = this.confirmVolume();

    if (valid) {
      windowConsent.showWindow(
        "You agree to the terms of the transaction Volume",
        message,
        this.liquidityHandler(+needQuote, +needBase)
      );
    }
  };

  liquidityHandler = (needQuote: number, needBase: number) => async () => {
    this.setLoader(true);

    const [quote, base] = this.mainState.pair.split("_");

    let checkQuote = false;
    let checkBase = false;

    try {
      const balances = await getAccBalances({
        account_uuid: this.data.account_uuid,
        account_name: this.currentNameAcc.label,
        quoteTicker: quote,
        baseTicker: base,
      });

      if (balances) {
        checkQuote = balanceComparison({
          balance: balances.quoteBalance,
          account_name: this.currentNameAcc.label,
          ticker: quote,
          amount: needQuote,
          typeOperation: "VOLUME",
        });

        checkBase = balanceComparison({
          balance: balances.baseBalance,
          account_name: this.currentNameAcc.label,
          ticker: base,
          amount: needBase,
          typeOperation: "VOLUME",
        });
      }

      if (checkQuote && checkBase) {
        await this.volumeRequest();
      } else {
        const textMessage = `Are you sure you want to place volume orders from ${this.currentNameAcc.label.toUpperCase()}?`;

        const subTextMessage = `Insufficient funds in the account.\n 
            You need ${needQuote} ${quote} and ${needBase} ${base}`;

        windowConsent.showWindow(textMessage, subTextMessage, this.volumeRequest);
      }
    } catch (error) {
      logError(error);
    } finally {
      this.setLoader(false);
    }
  };

  volumeRequest = async () => {
    this.data.symbol = this.mainState.pair;

    try {
      const { isError } = await makeVolume(this.data);

      if (!isError) {
        showSuccessMsg("Applications were created successfully");

        this.mainState.updLimitTradingData();
      }
    } catch (error) {
      logError(error);
    }
  };

  confirmVolume = () => {
    runInAction(() => {
      this.data.precisionPrice = this.mainState.pricePrecision;
    });
    runInAction(() => {
      this.data.precisionAmount = this.mainState.amountPrecision;
    });

    const needAmount = [];
    const [quote, base] = this.mainState.pair.split("_");
    const needBase = (+this.data.quoteMax / +this.data.priceMax).toFixed(this.data.precisionAmount);
    const needQuote = (+this.data.quoteMax).toFixed(this.data.precisionPrice);

    const message = `
      For this action you need ${needBase} ${base} and ${needQuote} ${quote}
    `;

    needAmount.push(message);
    needAmount.push(needQuote);
    needAmount.push(needBase);

    return needAmount;
  };
}

export default VolumeStore;
