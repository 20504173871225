import { observer } from "mobx-react-lite";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SeparatedTooltip } from "./SeparatedTooltip";

const TOOLTIP_PLACE = "left";

export const Tooltips = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);
  const { orderBookState } = mainState;

  return (
    <>
      <SeparatedTooltip
        place={TOOLTIP_PLACE}
        id="sell-tooltip"
        orderType={orderBookState.currentOrderType}
        quote={mainState.quote}
        base={mainState.base}
        totalFilling={orderBookState.totalFillingSell}
        ourFilling={orderBookState.ourFillingSell}
        organicFilling={orderBookState.organicFillingSell}
      />

      <SeparatedTooltip
        place={TOOLTIP_PLACE}
        id="buy-tooltip"
        orderType={orderBookState.currentOrderType}
        quote={mainState.quote}
        base={mainState.base}
        totalFilling={orderBookState.totalFillingBuy}
        ourFilling={orderBookState.ourFillingBuy}
        organicFilling={orderBookState.organicFillingBuy}
      />
    </>
  );
});
