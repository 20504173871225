import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;

  grid-area: virtual-range;
`;
