import { useMemo } from "react";
import { useDashboardColors } from "./useDashboardColors";

export const useChartColorWheel = () => {
  const { accent } = useDashboardColors();

  const wheel = useMemo(() => {
    const colors = Object.values(accent);
    return colors;
  }, [accent]);

  return wheel;
};
