import { ScrollSize } from "src/components/shared/shared";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
  TableSortMixin,
} from "src/components/shared/Table";
import styled from "styled-components";

interface PriceProps {
  side: "buy" | "sell" | "";
}

export const Container = styled.div`
  /* position: relative;

  height: 100%;
  width: 100%;

  padding: 0px 5px 5px; */

  width: 100%;
  height: 100%;

  display: flex;

  overflow-y: scroll;

  ${ScrollSize}

  @media (max-width: 880px) {
    height: 0px;
    flex: 1 1 auto;

    padding: 0px;
  }
`;

export const TradeHistoryTable = styled(Table)`
  display: table;

  overflow-y: inherit;
`;

export const Head = styled(TableHead)`
  position: sticky;
  top: 0;

  background-color: ${({ theme }) => theme.bgSettingsPnl};
  z-index: 1;
`;

export const Body = styled(TableBody)`
  height: calc(100% - 32px);

  overflow-y: inherit;
`;

export const Row = styled(TableRow)``;

export const HeadRow = styled(TableHeadRow)`
  height: 29px;
`;

export const HeaderCell = styled(TableHeader)`
  /* padding-right: 15px; */

  font-weight: ${({ theme }) => theme.fontWeights["700"]};

  :first-of-type {
    text-align: left;
  }

  :last-of-type {
    text-align: right;
  }

  ${TableSortMixin}
`;

export const Cell = styled(TableCell)`
  padding: 2.5px;

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.darkGray};
`;

const CellText = styled.span`
  width: 100%;
  display: inline-block;

  padding: 2.5px;

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.darkGray};
`;

export const Price = styled(CellText)<PriceProps>`
  color: ${({ theme, side }) => {
    switch (side) {
      case "buy":
        return theme.successColor;
      case "sell":
        return theme.dangerColor;

      default:
        return theme.smallHeader;
    }
  }};
  text-align: center;
`;

export const Amount = styled(CellText)`
  text-align: right;
`;

export const Time = styled(CellText)`
  text-align: left;
`;
