import { makeAutoObservable } from "mobx";
import { GetExchangesResponse, getExchanges } from "src/api/bots/CEX/dashboard";
import { logError } from "src/helpers/network/logger";
import { roundToTwoDecimals } from "src/helpers/rounding";
import { IDisposable, Mapper, entries } from "src/helpers/utils";
import { IBaseStatsStoreParams, IDashboardStateProvider, IStatsFetcher } from ".";
import { ExchangePieType } from "./ExchangesLegendStore";

interface IExchangesStoreParams extends IBaseStatsStoreParams {}

export type ExchangesBalancesData = Record<string, number>;

type ExchangesData = {
  freeUsd: ExchangesBalancesData;
  lockedUsd: ExchangesBalancesData;
  totalTokens: ExchangesBalancesData;
  totalUsd: ExchangesBalancesData;
};

const INITIAL_DATA: ExchangesData = {
  freeUsd: {},
  lockedUsd: {},
  totalTokens: {},
  totalUsd: {},
};

const exchangeResponseToData: Mapper<GetExchangesResponse["free_usd"], ExchangesBalancesData> = (
  data
) => {
  const exchangeEntries = entries(data).map(([exchange, balance]) => {
    const roundedBalance = roundToTwoDecimals(+balance);
    return [exchange, roundedBalance];
  });

  return Object.fromEntries(exchangeEntries);
};

const exchangesResponseToData: Mapper<GetExchangesResponse, ExchangesData> = ({
  free_usd,
  locked_usd,
  total_tokens,
  total_usd,
}) => ({
  freeUsd: exchangeResponseToData(free_usd),
  lockedUsd: exchangeResponseToData(locked_usd),
  totalUsd: exchangeResponseToData(total_usd),
  totalTokens: exchangeResponseToData(total_tokens),
});

export default class ExchangesStore implements IStatsFetcher, IDisposable {
  private _stateProvider: IDashboardStateProvider;

  private _data: ExchangesData = INITIAL_DATA;

  private _loading = false;

  constructor({ stateProvider }: IExchangesStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;
  }

  private get _botParams() {
    return this._stateProvider.botParams;
  }

  private get _queryParams() {
    return this._stateProvider.queryParams;
  }

  private _setData = (data: ExchangesData) => {
    this._data = data;
  };

  get freeUsd() {
    return this._data.freeUsd;
  }

  get totalUsd() {
    return this._data.totalUsd;
  }

  get totalTokens() {
    return this._data.totalTokens;
  }

  get lockedUsd() {
    return this._data.lockedUsd;
  }

  get pieData() {
    return {
      [ExchangePieType.FreeUsd]: this.freeUsd,
      [ExchangePieType.LockedUsd]: this.lockedUsd,
      [ExchangePieType.TotalUsd]: this.totalUsd,
      [ExchangePieType.TotalTokens]: this.totalTokens,
    };
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _getExchanges = async () => {
    const { party } = this._botParams;
    const queryParams = this._queryParams;
    if (!party || !queryParams) return;

    this._setData(INITIAL_DATA);
    this._setLoading(true);
    try {
      const { data, isError } = await getExchanges(party, queryParams);

      if (!isError) {
        const ExchangesData = exchangesResponseToData(data);
        this._setData(ExchangesData);
      }
    } finally {
      this._setLoading(false);
    }
  };

  getStats = async () => {
    try {
      await this._getExchanges();
    } catch (error) {
      logError(error);
    }
  };

  destroy = () => {};
}
