import { Pagination } from "src/components/shared/Pagination";
import { TableSortMixin } from "src/components/shared/Table";
import { CardSurface, ScrollSize } from "src/components/shared/shared";
import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;

  height: 0px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding: 2.5px;

  ${CardSurface}
`;

interface OrderProps {
  side: "buy" | "sell" | "";
}

interface OrderStateProps {
  state: "hot" | "cold";
}

interface TableBodyProps {
  columnIndex: number | null;
}

interface TableCellProps {
  highlight?: boolean;
}

const CenteringMixin = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div`
  width: 100%;

  display: flex;
  flex: 1 1 auto;

  overflow-x: auto;

  border-radius: 4px;

  ${ScrollSize}
`;

export const StateTable = styled.table`
  width: 100%;

  text-indent: 0px;
  border-color: inherit;
  border-spacing: 0;

  tr {
    th,
    td {
      &:nth-child(4n + 1),
      &[colspan="4"] {
        border-right: 2px solid ${({ theme }) => theme.inputBorderColor};
      }

      &:last-child,
      &:nth-last-child(2) {
        border-right: 1px solid ${({ theme }) => theme.headerBorder};
      }
    }
  }
`;

export const Head = styled.thead`
  position: sticky;
  top: 0;

  margin: 0;

  background-color: ${({ theme }) => theme.bgSettingsPnl};

  z-index: 1;
`;

export const StateTableBody = styled.tbody<TableBodyProps>`
  ${({ columnIndex }) =>
    columnIndex !== null
      ? css`
          & > tr > td:nth-child(${columnIndex + 1}) {
            background-color: ${({ theme }) => hexToRgb(theme.bgGrayBlack, 0.7)};
          }
        `
      : ""}
`;

export const Row = styled.tr`
  height: 30px;

  border-color: ${({ theme }) => theme.headerBorder};

  :hover {
    background-color: ${({ theme }) => theme.bgGrayBlack};
  }
`;

export const HeadRow = styled.tr`
  height: 30px;

  background-color: ${({ theme }) => theme.bgSettingsPnl};
`;

export const HeadCell = styled.th`
  position: relative;

  height: 30px;
  min-width: 60px;

  padding: 0px;

  border: 1px solid;
  border-color: ${({ theme }) => theme.headerBorder};

  color: ${({ theme }) => theme.textColor};

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  font-weight: ${({ theme }) => theme.fontWeights["700"]};

  ${TableSortMixin}
`;

export const StateTableCell = styled.td<TableCellProps>`
  height: 30px;
  min-width: 60px;

  padding: 0px;

  border: 1px solid;
  border-color: ${({ theme }) => theme.headerBorder};

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.textColor};

  background-color: ${({ theme, highlight }) =>
    highlight ? hexToRgb(theme.bgGrayBlack, 0.7) : "inherit"};
`;

export const CellText = styled.span`
  height: 100%;
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.textColor};

  ${CenteringMixin}
`;

export const Order = styled(CellText)<OrderProps>`
  background-color: ${({ theme, side }) => {
    switch (side) {
      case "buy":
        return hexToRgb(theme.successColor, 0.5);
      case "sell":
        return hexToRgb(theme.dangerColor, 0.5);

      default:
        return "inherit";
    }
  }};
`;

export const StateOrder = styled(CellText)<OrderStateProps>`
  background-color: ${({ theme, state }) => {
    switch (state) {
      case "hot":
        return hexToRgb(theme.botColorStatus.yellow, 0.5);

      default:
        return "inherit";
    }
  }};
`;

export const StatePagination = styled(Pagination)`
  height: 40px;
`;
