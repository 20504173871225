import { Observer, observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { Column, TableProps } from "react-table";
import { useExchangesLegendV2State } from "src/context/CEX/Dashboard/ExchangesLegendV2";
import { deepMerge } from "src/helpers/utils/deepMerge";
import {
  ExchangePieItem,
  ExchangePieItemRow,
  ExchangesColumnTitles,
} from "src/state/CEX/CEXDashboard/shared/ExchangesLegendV2Store/types";
import { StatsTableProps } from "../../../shared/StatsTable";
import { LegendLabelV2 } from "./LegendLabelV2";
import * as styles from "./style";

const DEFAULT_TABLE_COLUMN: Partial<Column<ExchangePieItemRow>> = {
  disableSortBy: true,
};

const DEFAULT_TABLE_COLUMN_TITLES: Required<ExchangesColumnTitles> = {
  label: "",
  amount: "Amount",
  percent: "Percent",
};

const useLegendColumnsTitles = (exchangesTitle: ExchangesColumnTitles | null) => {
  const titles = useMemo(
    () => deepMerge(DEFAULT_TABLE_COLUMN_TITLES, exchangesTitle) as Required<ExchangesColumnTitles>,
    [exchangesTitle]
  );

  const getColumnTitle = useCallback((key: keyof ExchangesColumnTitles) => titles[key], [titles]);

  return getColumnTitle;
};

export interface LegendTableV2Props
  extends Pick<StatsTableProps<ExchangePieItem>, keyof TableProps> {}

export const LegendTableV2 = observer((props: LegendTableV2Props) => {
  const state = useExchangesLegendV2State();

  const getColumnTitle = useLegendColumnsTitles(state.columnTitles);

  const columns = useMemo(() => {
    const columnsSchema: Column<ExchangePieItemRow>[] = [
      {
        Header: getColumnTitle("label"),
        accessor: "label",
        Cell: ({ row }) => {
          const { label, color, visible, index } = row.original;
          return (
            <LegendLabelV2
              text={label}
              color={color}
              hidden={!visible}
              onClick={() => state.toggleItemVisibility(index)}
              onMouseEnter={() => state.triggerItemHover(index)}
              onMouseLeave={() => state.resetItemHover()}
            />
          );
        },
        Footer: "Total",
        width: 40,
      },
      {
        Header: getColumnTitle("amount"),
        accessor: "amount",
        Footer: () => <Observer>{() => <>{state.total}</>}</Observer>,
        width: 30,
      },
      {
        Header: getColumnTitle("percent"),
        accessor: "percent",
        Footer: "100%",
        width: 30,
      },
    ];

    return columnsSchema;
  }, [getColumnTitle, state]);

  return (
    <styles.Table
      columns={columns}
      data={state.data}
      defaultColumn={DEFAULT_TABLE_COLUMN}
      {...props}
    />
  );
});
