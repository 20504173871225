import { CardSurface, ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  border: ${({ theme }) => `1px solid ${theme.inputBorderColor}`};

  ${CardSurface}
`;

export const Header = styled.div`
  height: 32px;
  width: 100%;

  display: flex;
  align-items: center;

  padding: 0px 8px;
  border-radius: 4px 4px 0px 0px;

  border-bottom: ${({ theme }) => `1px solid ${theme.inputBorderColor}`};
  background-color: ${({ theme }) => theme.bgGrayBlack};
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.inputTextColor};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
`;

export const Content = styled.div`
  position: relative;

  height: 0px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding: 8px;

  overflow: auto;

  ${ScrollSize}
`;
