import styled from "styled-components";

interface IconWrapperProps {
  currentFill?: boolean;
  currentStroke?: boolean;
}

export const IconWrapper = styled.span<IconWrapperProps>`
  display: inherit;
  color: inherit;
  font-size: inherit;

  > svg {
    width: 1em;
    height: 1em;
  }

  > svg * {
    ${({ currentFill: fill }) => fill && "fill: currentColor"}
    ${({ currentStroke: stroke }) => stroke && "stroke: currentColor"}
  }
`;

export const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ControlsContainer = styled(BaseContainer)`
  min-width: 200px;
`;

export const ComponentsContainer = styled(BaseContainer)`
  width: 100%;
  align-items: center;
`;
