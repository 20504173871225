import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface StatsSectionItemProps extends ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export const StatsSectionItem = ({ label, value, ...props }: StatsSectionItemProps) => (
  <styles.Container {...props}>
    <styles.Label>{label}</styles.Label>
    <styles.Value>{value}</styles.Value>
  </styles.Container>
);
