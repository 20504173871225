import { ApiModules, apiUrl } from "src/api";
import { IPairAnalytic } from "src/modules/analytics";
import ResponseHandler from "src/state/network/ResponseHandler";

export const getAllAnalytics = async (party: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `${party}/candle-analytics`),
    method: "GET",
  });

export const getPairAnalytics = async (party: string, pair: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `${party}/candle-analytics?pair=${pair}`),
    method: "GET",
  });

export const getAnalytic = async (id: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.CandleAnalytics, `${id}`),
    method: "GET",
  });

export const createAnalytic = async (party: string, data: IPairAnalytic) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `${party}/candle-analytics`),
    method: "POST",
    data,
  });

export const changeAnalytic = async (id: string, content: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.CandleAnalytics, `${id}`),
    method: "PUT",
    data: { content },
  });

export const deleteAnalytic = async (id: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.CandleAnalytics, `${id}`),
    method: "DELETE",
  });

export const setDefaultAnalytic = async (id: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.CandleAnalytics, `${id}/default`),
    method: "PUT",
  });

export const deleteDefaultAnalytic = async (id: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.CandleAnalytics, `${id}/default`),
    method: "DELETE",
  });

export const changeAnalyticName = async (id: string, name: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.CandleAnalytics, `${id}/name`),
    method: "PUT",
    data: { name },
  });
