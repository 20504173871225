import { makeAutoObservable } from "mobx";
import { GetBalancesResponse, getBalances } from "src/api/bots/CEX/dashboard";
import { logError } from "src/helpers/network/logger";
import { IDisposable, Mapper } from "src/helpers/utils";
import { IBaseStatsStoreParams, IDashboardStateProvider, IStatsFetcher } from ".";
import { padSeriesData, rawSeriesToData } from "../utils";

interface IBalancesStoreParams extends IBaseStatsStoreParams {}

type BalancesData = GetBalancesResponse;

const INITIAL_DATA: BalancesData = {
  free: {
    time: [],
    value: [],
  },
  locked: {
    time: [],
    value: [],
  },
};

const balancesResponseToData: Mapper<GetBalancesResponse, BalancesData> = (response) => response;

export default class OldBalancesStore implements IStatsFetcher, IDisposable {
  private _stateProvider: IDashboardStateProvider;

  private _data: BalancesData = INITIAL_DATA;

  private _loading = false;

  constructor({ stateProvider }: IBalancesStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;
  }

  private get _botParams() {
    return this._stateProvider.botParams;
  }

  private get _queryParams() {
    return this._stateProvider.queryParams;
  }

  private _setData = (data: BalancesData) => {
    this._data = data;
  };

  private get _locked() {
    return rawSeriesToData(this._data.locked);
  }

  get locked() {
    const seriesData = this._locked;
    return padSeriesData(seriesData);
  }

  private get _free() {
    return rawSeriesToData(this._data.free);
  }

  get free() {
    const seriesData = this._free;
    return padSeriesData(seriesData);
  }

  get startBalances() {
    const freeStart = this._free.value[0] ?? 0;
    const lockedStart = this._locked.value[0] ?? 0;
    return { free: freeStart, locked: lockedStart };
  }

  get endBalances() {
    const freeValues = this._free.value;
    const freeEnd = freeValues[freeValues.length - 1] ?? 0;

    const lockedValues = this._locked.value;
    const lockedEnd = lockedValues[lockedValues.length - 1] ?? 0;

    return { free: freeEnd, locked: lockedEnd };
  }

  get deltaBalances() {
    const { startBalances } = this;
    const { endBalances } = this;
    const lockedDelta = endBalances.locked - startBalances.locked;
    const freeDelta = endBalances.free - startBalances.free;
    return { free: freeDelta, locked: lockedDelta };
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _getBalances = async () => {
    const { party } = this._botParams;
    const queryParams = this._queryParams;
    if (!party || !queryParams) return;

    this._setData(INITIAL_DATA);
    this._setLoading(true);
    try {
      const { data, isError } = await getBalances(party, queryParams);

      if (!isError) {
        const balancesData = balancesResponseToData(data);
        this._setData(balancesData);
      }
    } finally {
      this._setLoading(false);
    }
  };

  getStats = async () => {
    try {
      await this._getBalances();
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}
