import {
  InfoTooltip as BaseInfoTooltip,
  INFO_TOOLTIP_CLASSNAME,
} from "src/components/AllBots/Bots/DEXV2Bots/shared/ModeChip/ModeInfoTooltip/style";
import styled from "styled-components";

export const StyledTooltip = styled(BaseInfoTooltip)`
  &.${INFO_TOOLTIP_CLASSNAME} {
    width: auto;
    white-space: pre;
  }
`;
