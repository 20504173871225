import styled, { css } from "styled-components";
import { Column } from "@tanstack/react-table";
import { ScrollSize } from "./shared";

interface ColumnProps {
  column: Column<unknown, unknown>;
}

export const CenteringMixin = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColumnSortMixin = css<ColumnProps>`
  ${({ column }) => css`
    ${column.getIsSorted() &&
    css`
      padding-right: 15px;
      ::after {
        color: ${({ theme }) => theme.primaryColor};
        content: "${column.getIsSorted() === "desc" ? "▼" : "▲"}";
        position: absolute;
      }
    `}
    ${column.getCanSort() &&
    css`
      cursor: pointer;
    `}
  `}
`;

export const TContainer = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex: 1 1 auto;

  overflow: auto;

  border-radius: 4px;

  ${ScrollSize}
`;

export const TableV2 = styled.table`
  position: relative;

  width: 100%;

  text-indent: 0px;
  border-color: inherit;
  border-spacing: 0;
`;

export const TableHeadV2 = styled.thead`
  position: sticky;
  top: 0;

  margin: 0;

  background-color: ${({ theme }) => theme.bgSettingsPnl};

  z-index: 1;
`;

export const TableHeaderCellV2 = styled.th`
  position: relative;

  height: 30px;

  padding: 0px 5px;

  border-color: ${({ theme }) => theme.headerBorder};

  color: ${({ theme }) => theme.textColor};

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  font-weight: ${({ theme }) => theme.fontWeights["700"]};
  user-select: none;

  ${ColumnSortMixin}
`;

export const TableBodyV2 = styled.tbody``;

export const TableRowV2 = styled.tr`
  height: 30px;

  border-color: ${({ theme }) => theme.headerBorder};
`;

export const TableHeadRowV2 = styled(TableRowV2)`
  background-color: ${({ theme }) => theme.bgSettingsPnl};
`;

export const TableCellV2 = styled.td`
  height: 30px;

  padding: 0px;

  border-color: ${({ theme }) => theme.headerBorder};

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.textColor};
`;

export const TableCellText = styled.span`
  height: 100%;
  width: 100%;

  color: ${({ theme }) => theme.textColor};

  ${CenteringMixin}
`;
