import { BigNumber, providers } from "ethers";
import { AlgebraPool } from "src/contracts/ethers/AlgebraPool";
import { AlgebraPool__factory } from "src/contracts/ethers/factories/AlgebraPool__factory";
import { IUniswapV3Pool__factory } from "src/contracts/factories/uniswap/v3/IUniswapV3Pool__factory";
import { IUniswapV3Pool } from "src/contracts/uniswap/v3/IUniswapV3Pool";
import { EntriesUnion } from "src/helpers/utils";
import { SwapV3DEXType } from "src/state/DEXV2/DEXV2Swap/utils";

type SwapV3PoolContractMap = {
  [SwapV3DEXType.LYNEX]: AlgebraPool;
  [SwapV3DEXType.UNISWAP]: IUniswapV3Pool;
};

type SwapV3PoolContract = EntriesUnion<SwapV3PoolContractMap, "type", "contract">;

export type V3PoolInfo = {
  token0: string;
  token1: string;
  fee: number;
  liquidity: BigNumber;
  sqrtPriceX96: BigNumber;
  tick: number;
};

export interface IV3PoolContract {
  get address(): string;
  getPoolInfo: () => Promise<V3PoolInfo>;
}

export class V3PoolContract implements IV3PoolContract {
  private _address: string;

  private _contract!: SwapV3PoolContract;

  constructor(address: string, provider: providers.JsonRpcProvider, dexType: SwapV3DEXType) {
    this._address = address;

    this._contract = this._getContract(address, provider, dexType);
  }

  private _getContract = (
    address: string,
    provider: providers.JsonRpcProvider,
    dexType: SwapV3DEXType
  ): SwapV3PoolContract => {
    switch (dexType) {
      case SwapV3DEXType.LYNEX: {
        return {
          type: SwapV3DEXType.LYNEX,
          contract: AlgebraPool__factory.connect(address, provider),
        };
      }
      case SwapV3DEXType.UNISWAP: {
        return {
          type: SwapV3DEXType.UNISWAP,
          contract: IUniswapV3Pool__factory.connect(address, provider),
        };
      }
    }
  };

  get address() {
    return this._address;
  }

  getPoolInfo = async () => {
    const { type, contract } = this._contract;
    switch (type) {
      case SwapV3DEXType.LYNEX: {
        const [token0, token1, liquidity, state] = await Promise.all([
          contract.token0(),
          contract.token1(),
          contract.liquidity(),
          contract.globalState(),
        ]);
        return {
          token0,
          token1,
          fee: state.fee,
          liquidity,
          sqrtPriceX96: state.price,
          tick: state.tick,
        };
      }
      case SwapV3DEXType.UNISWAP: {
        const [token0, token1, fee, liquidity, slot0] = await Promise.all([
          contract.token0(),
          contract.token1(),
          contract.fee(),
          contract.liquidity(),
          contract.slot0(),
        ]);
        return {
          token0,
          token1,
          fee,
          liquidity,
          sqrtPriceX96: slot0.sqrtPriceX96,
          tick: slot0.tick,
        };
      }
    }
  };
}
