import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 120px;
  height: 45px;

  display: flex;
  flex-wrap: wrap;

  margin: 5px 0px;
  gap: 5px;

  overflow-y: auto;

  ${ScrollSize}
`;
