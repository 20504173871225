import { StyledProps } from "src/helpers/styled/types";
import styled from "styled-components";

interface PNLV2ValueProps {
  color?: string;
}

interface StyledPNLV2ValueProps extends StyledProps<PNLV2ValueProps> {}

export const StyledPNLV2Value = styled.span<StyledPNLV2ValueProps>`
  display: flex;
  align-items: center;

  color: ${({ theme, $color = theme.textColor }) => $color};

  font-size: 0.8em;
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
  letter-spacing: 0.2px;
  white-space: nowrap;
`;
