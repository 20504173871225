import { makeAutoObservable } from "mobx";
import { getAllApiKeys } from "src/api/bots/CEX/apiKeys";
import { getLiquiditySettings } from "src/api/bots/CEX/liquidity";
import { getDecimals } from "src/api/bots/CEX/settings";
import { BotInfo } from "src/components/BotsContent/CEX";
import { botNameToParts } from "src/helpers/botName";
import { logError } from "src/helpers/network/logger";
import { IDisposable, keys } from "src/helpers/utils";
import { BotAccountName, BotAccountsMap } from "src/modules/accounts";
import { LiquidGridSettings } from "src/modules/exchange/trade";
import { Decimals } from "src/modules/settings";
import {
  allApiKeysResponseToBotAccountsMap,
  filterBaseNameAccounts,
} from "../CEXApiKeys/AccountsBindings";

export type LiquidityBotParams = Pick<BotInfo, "bot_uuid" | "market">;

export type Precision = Decimals;

export type LiquidityFormSuggestions = {
  precision: Precision;
};

const EMPTY_SUGGESTIONS: LiquidityFormSuggestions = {
  precision: {
    price: "",
    amount: "",
  },
};

export class LiquidityTabStore implements IDisposable {
  private _botParams: LiquidityBotParams = {
    bot_uuid: "",
    market: "",
  };

  accounts: BotAccountName[] = [];

  private _settings: LiquidGridSettings[] = [];

  private _suggestions: LiquidityFormSuggestions = EMPTY_SUGGESTIONS;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSuggestionsByKey = <K extends keyof LiquidityFormSuggestions>(
    key: K,
    keySuggestions: LiquidityFormSuggestions[K]
  ) => {
    this._suggestions[key] = keySuggestions;
  };

  setSuggestions = (suggestions: LiquidityFormSuggestions) => {
    this._suggestions = suggestions;
  };

  clearSuggestions = () => {
    this._suggestions = EMPTY_SUGGESTIONS;
  };

  get suggestions() {
    return this._suggestions;
  }

  private _setSettings = (settings: LiquidGridSettings[]) => {
    this._settings = settings;
  };

  get settings() {
    return this._settings.length ? this._settings[0] : undefined;
  }

  private _setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setBotParams = (params: LiquidityBotParams) => {
    this._botParams = params;
  };

  get botNameParts() {
    return botNameToParts(this._botParams.market);
  }

  private get _pair() {
    if (!this.botNameParts) return "";

    const { base, quote } = this.botNameParts;
    return `${quote}_${base}`;
  }

  private get _exchange() {
    return this.botNameParts?.exchange ?? "";
  }

  get botUUID() {
    return this._botParams.bot_uuid;
  }

  getAccounts = async () => {
    const { isError, data } = await getAllApiKeys(this._botParams.bot_uuid);
    if (!isError) {
      const accounts = allApiKeysResponseToBotAccountsMap(data);
      this._setActiveAccounts(accounts);
    }
  };

  private _setActiveAccounts = (accounts: BotAccountsMap) => {
    const filteredAccounts = filterBaseNameAccounts(accounts, ["mm", "info"]);
    this.accounts = keys(filteredAccounts);
  };

  private _getLiquidity = async (showLoading = true) => {
    this._setSettings([]);

    if (showLoading) {
      this._setLoading(true);
    }

    try {
      const { data, isError } = await getLiquiditySettings(this._botParams.bot_uuid);

      if (!isError) {
        this._setSettings(data as LiquidGridSettings[]);
      }
    } catch {
      this._setSettings([]);
    } finally {
      if (showLoading) {
        this._setLoading(false);
      }
    }
  };

  getLiquidity = () => this._getLiquidity();

  loadData = async () => {
    try {
      this._setLoading(true);

      await Promise.all([this._getLiquidity(false), this.getAccounts(), this.getSuggestions()]);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  getSuggestions = () => this._getDecimalsSuggestions();

  private _getDecimalsSuggestions = async () => {
    try {
      const { data, isError } = await getDecimals("", this._pair, this._exchange);

      if (!isError) {
        this.setSuggestionsByKey("precision", data.data);
      } else {
        this.setSuggestionsByKey("precision", EMPTY_SUGGESTIONS.precision);
      }
    } catch {
      this.setSuggestionsByKey("precision", EMPTY_SUGGESTIONS.precision);
    }
  };

  destroy = () => {};
}
