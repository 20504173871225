import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  display: flex;

  gap: 10px;
`;

export const VerticWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 15px;
`;
