import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 280px;

  display: flex;
  flex-direction: column;

  gap: 20px;
`;
