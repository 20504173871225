import styled from "styled-components";
import { CardSurface } from "../../shared";

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 15px;

  padding: 15px 15px 30px;

  ${CardSurface}
`;

export const PanelHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 20px;
`;

export const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
`;
