import { LabeledInput } from "src/components/shared/Forms/Inputs";
import styled from "styled-components";

import { ToggleLabel as BaseToggleLabel, GridContentWrapper } from "../../../shared";

export const ContentWrapper = styled(GridContentWrapper)``;

export const ReadOnlyInput = styled(LabeledInput)`
  @media screen and (max-width: 450px) {
    order: -1;
  }
`;

export const ToggleLabel = styled(BaseToggleLabel)`
  width: 100%;

  align-items: center;

  align-self: flex-end;
  min-height: 60%;

  grid-column: 1/-1;
`;
