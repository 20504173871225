import { useEffect } from "react";
import { ChooseSettingParamsStore } from "src/state/expertSystem/strategies/changeSettingsModule/ChooseSettingParamsStore";
import { FormControlCb } from "src/state/expertSystem/strategies/changeSettingsModule/shared/types";

export const useSetFormSetter = (cb: FormControlCb, state: ChooseSettingParamsStore) => {
  useEffect(() => {
    // When adding settings parameters, select default values
    state.setFormSetter([cb]);

    return () => {
      state.removeSetCb(cb);
    };
  }, [state, cb]);
};
