import { ColumnDef } from "@tanstack/react-table";
import { Observer, observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { SeparatorNum } from "src/components/shared/Separator";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { TableContext } from "src/context/shared/Table";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CreatedFloatingOrder } from "src/modules/exchange/trade";
import { AmountCell } from "../AmountCell";
import { ColumnFilter } from "../OrdersTable/ColumnFilter";
import { TableRows } from "../OrdersTable/TableRows";
import { HeadCheckBox, Side, Text } from "../shared/style";
import { FloatingOrderInfo } from "./FloatingOrderInfo";

const STATUS_FILTERS = ["new", "canceled", "failed", "completed"];

export const FloatingOrders = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);

  const { floatingOrdersState } = mainState;

  const { tableState } = floatingOrdersState;

  useEffect(() => {
    floatingOrdersState.downloadData();
  }, [floatingOrdersState]);

  const columns = useMemo(() => {
    const columnSchema: ColumnDef<CreatedFloatingOrder>[] = [
      {
        header: () => (
          <Observer>
            {() => <HeadCheckBox checked={tableState.allChecked} onChange={tableState.allCheck} />}
          </Observer>
        ),
        accessorKey: "id",
        id: "idOrder",
        enableColumnFilter: false,
        enableSorting: false,
        size: 25,
        cell: ({
          cell: {
            row: {
              original: { status, id },
            },
          },
        }) => (
          <Observer>
            {() => (
              <CheckBox
                disabled={status !== "new"}
                checked={tableState.selectItems.includes(String(id))}
                onChange={() => tableState.check(String(id))}
                style={{ marginLeft: "0px", width: "18px" }}
              />
            )}
          </Observer>
        ),
      },
      {
        header: () => "Created",
        accessorKey: "created_at",
        id: "createdAtOrder",
        enableColumnFilter: false,
        enableResizing: false,
        cell: (info) => <Text>{info.getValue<string>()}</Text>,
      },
      {
        header: () => "%",
        accessorKey: "price_percent",
        id: "pricePercentOrder",
        enableColumnFilter: false,
        enableResizing: false,
        size: 25,
        cell: (info) => <SeparatorNum value={info.getValue<string>()} />,
      },
      {
        header: () => "Cur price",
        accessorKey: "price_current",
        id: "priceOrder",
        enableColumnFilter: false,
        enableResizing: false,
        size: 65,
        cell: (info) => <SeparatorNum value={info.getValue<string>()} />,
      },
      {
        header: () => "Amount",
        accessorKey: "amount",
        id: "amountOrder",
        enableColumnFilter: false,
        enableResizing: false,
        cell: ({
          cell: {
            row: {
              original: { amount, price_current },
            },
          },
        }) => <AmountCell amount={amount} price={price_current} />,
      },
      {
        header: () => "Filled",
        accessorKey: "amount_filled",
        id: "amountFilledOrder",
        enableColumnFilter: false,
        enableResizing: false,
        cell: ({
          cell: {
            row: {
              original: { amount_filled, amount_filled_limit },
            },
          },
        }) => (
          <>
            <SeparatorNum value={amount_filled} /> / <SeparatorNum value={amount_filled_limit} />{" "}
          </>
        ),
      },
      {
        header: () => "Side",
        accessorKey: "side",
        id: "sideOrder",
        enableColumnFilter: false,
        enableResizing: false,
        cell: ({
          cell: {
            row: { original: order },
          },
        }) => (
          <Side side={order.side}>
            {order.side}
            <FloatingOrderInfo orderId={String(order.id)} />
          </Side>
        ),
      },
      {
        id: "statusOrder",
        header: () => (
          <ColumnFilter
            label="Status"
            filters={STATUS_FILTERS}
            filterParam="status"
            defaultFilter="new"
          />
        ),
        accessorKey: "status",
        enableSorting: false,
        enableResizing: false,
        cell: (info) => <Text>{info.getValue<string>()}</Text>,
      },
      {
        header: () => (
          <Observer>
            {() => (
              <Button
                size="xs"
                disabled={!tableState.cancelActive}
                onClick={tableState.cancelOrders}
              >
                Cancel
              </Button>
            )}
          </Observer>
        ),
        accessorKey: "cancel",
        id: "cancelOrder",
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        cell: ({
          cell: {
            row: {
              original: { status, id },
            },
          },
        }) => (
          <Observer>
            {() => (
              <DeleteButton
                disabled={status !== "new"}
                style={{ margin: "auto" }}
                onClick={() => tableState.cancelOrder([String(id)])}
              />
            )}
          </Observer>
        ),
      },
    ];

    return columnSchema;
  }, [tableState]);

  return (
    <TableContext.Provider value={tableState}>
      <TableRows
        data={tableState.tableItems}
        columns={columns}
        loader={floatingOrdersState.isLoading}
        headTooltipProps={{
          amountAllOrders: floatingOrdersState.ordersCount,
          amountBuyOrders: floatingOrdersState.buyOrderCount,
          amountSellOrders: floatingOrdersState.sellOrderCount,
        }}
      />
    </TableContext.Provider>
  );
});
