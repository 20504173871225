import { ApiModules, apiUrl } from "src/api";
import { Note } from "src/modules/shared";
import ResponseHandler from "src/state/network/ResponseHandler";

export interface ChartRequestProps {
  symbol: string;
  party: string;
  step: number;
  start: number;
  stop: number;
}

export const getGraphanaStats = async (base: string, step: number, start: number, stop: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Chart, `${base}/stats?step=${step}&start=${start}&stop=${stop}`),
    method: "GET",
  });

export const getGraphanaBalanceTotal = async (
  base: string,
  step: number,
  start: number,
  stop: number
) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Chart, `${base}/total?step=${step}&start=${start}&stop=${stop}`),
    method: "GET",
  });

export const getGraphanaBalanceSum = async (
  base: string,
  step: number,
  start: number,
  stop: number
) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Chart, `${base}/balance?step=${step}&start=${start}&stop=${stop}`),
    method: "GET",
  });

export const getHistory = async (botName: string, start: number, stop: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${botName}/history?start=${start}&stop=${stop}`),
    method: "GET",
  });

export const getCEXNotes = async (market: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${market}/note`),
    method: "GET",
  });

export const setCEXNotes = async (market: string, data: Note) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${market}/note`),
    method: "PUT",
    data,
  });

export const getDEXNotes = async (market: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `${market}/note`),
    method: "GET",
  });

export const setDEXNotes = async (market: string, data: Note) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `${market}/note`),
    method: "PUT",
    data,
  });

export const getCEXInfo = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Bot, `${bot_uuid}/get-info`),
    method: "GET",
  });

export const getPnLStats = async ({ symbol, party, step, start, stop }: ChartRequestProps) =>
  ResponseHandler.handler({
    url: apiUrl(
      ApiModules.Chart,
      `${symbol}/stats/dw?party=${party}&step=${step}&start=${start}&stop=${stop}`
    ),
    method: "GET",
  });
