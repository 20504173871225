import { Tabs } from "src/components/shared/Tabs";
import { Headline3 } from "src/components/shared/Typography";
import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  height: 0px;

  display: flex;
  flex: 1 1 auto;
`;

export const TabsModules = styled(Tabs)`
  grid-area: tabs;
`;

export const ListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 15px;
`;

export const List = styled.div`
  position: relative;

  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 400px));
  grid-template-rows: repeat(auto-fill, 313px);
  grid-column-gap: 10px;
  grid-row-gap: 20px;

  flex: 1 1 auto;

  justify-content: flex-start;

  overflow-y: scroll;

  padding: 5px 5px 0px;

  z-index: 1;

  ${ScrollSize}
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;

export const Title = styled(Headline3)``;
