import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface ChipCorrelationTableHeaderProps extends ComponentPropsWithoutRef<"thead"> {}

export const ChipCorrelationTableHeader = (props: ChipCorrelationTableHeaderProps) => (
  <styles.Header {...props}>
    <styles.HeaderRow>
      <styles.HeaderCell />
      <styles.HeaderCell>ETH</styles.HeaderCell>
      <styles.HeaderCell>BTC</styles.HeaderCell>
    </styles.HeaderRow>
  </styles.Header>
);
