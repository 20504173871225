import { observer } from "mobx-react-lite";
import { useCallback, useContext } from "react";
import { Switch } from "src/components/shared/Switches/Switch";
import { PartyContext } from "src/context/UserManager/UserManager";
import { Account, Credential } from "src/modules/userManager";
import WindowConsent from "src/state/WindowConsent";
import * as styles from "./style";

interface APIProps {
  API: Credential;
  account: Account;
}

export const ItemAPI = observer(({ API, account }: APIProps) => {
  const partyState = useContext(PartyContext);

  const switchName = `${account.name}-${API.id}`;

  const toggle = useCallback(
    (uuid: string, id: number) => {
      if (partyState)
        WindowConsent.showWindow(
          "",
          `Are you sure you want to switch the API for the account: ${account.name}?`,
          partyState?.toggleAPI,
          { uuid, id }
        );
    },
    [partyState, account.name]
  );

  return (
    <styles.Container>
      <Switch
        label=""
        name={switchName}
        value={API.id}
        checked={API.id === account.active_id}
        onChange={() => toggle(account.uuid, API.id)}
        onClick={() => {}}
      />
      <styles.Item
        hide={partyState?.currentAPI === API.id}
        onClick={() => {
          partyState?.setCurrentAPI(API.id);
          partyState?.setCurrentAcc(account.uuid);
        }}
      >
        {API.name}
      </styles.Item>
    </styles.Container>
  );
});
