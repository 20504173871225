import { useFormContext } from "react-hook-form";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { ControlledSelector } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledSelector";
import {
  getSelectorList,
  primitiveSelectorValueToString,
  stringToSelectorValue,
} from "src/helpers/forms/selectors";
import { DEPTH_MODES, IStrategyModuleCreateType } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import { ExchPairFields } from "../../../shared";

const DEPTH_PERCENTS = ["-20", "-10", "-5", "-2", "+2", "+5", "+10", "+20"];

const MODULE_TYPE = "depth_abs";

export const FormContent = () => {
  const { control } = useFormContext<IStrategyModuleCreateType<typeof MODULE_TYPE>>();

  return (
    <RowWrapper>
      <ExchPairFields showAllExchanges />

      <ControlledSelector
        controllerProps={{
          control,
          name: "hard_params.percent",
          rules: {
            required: ERRORS_MSGS.isRequired,
          },
        }}
        componentProps={{
          label: "Percent %",
          menuPosition: "fixed",
          options: getSelectorList(DEPTH_PERCENTS),
          getValueHandler: stringToSelectorValue,
          onChangeHandler: primitiveSelectorValueToString,
        }}
      />

      <ControlledSelector
        controllerProps={{
          control,
          name: "hard_params.mode",
          rules: {
            required: ERRORS_MSGS.isRequired,
          },
        }}
        componentProps={{
          label: "Mode",
          menuPosition: "fixed",
          options: getSelectorList(DEPTH_MODES),
          getValueHandler: stringToSelectorValue,
          onChangeHandler: primitiveSelectorValueToString,
        }}
      />
    </RowWrapper>
  );
};
