import { Chart, PluginsData } from "chart.js";

declare module "chart.js" {
  export interface PluginsData {}

  export interface ChartData {
    plugins?: PluginsData;
  }
}

export const addPluginData = <P extends keyof PluginsData>(
  chart: Chart,
  key: P,
  value: PluginsData[P]
) => {
  const currentPlugins = chart.data.plugins ?? {};
  currentPlugins[key] = value;
  // eslint-disable-next-line no-param-reassign
  chart.data.plugins = currentPlugins;
};
