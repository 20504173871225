import {
  INFO_TOOLTIP_CLASSNAME,
  InfoTooltip,
} from "src/components/AllBots/Bots/DEXV2Bots/shared/ModeChip/ModeInfoTooltip/style";
import { TableContent } from "src/components/BotsContent/DEX_V2/Stats/Tables/TablePanel/TableContent";
import { SELECTOR_CLASSNAME } from "src/components/shared/Forms/Selectors/shared/style";
import styled from "styled-components";
import { SelectColumnFilter } from "../TableHeader/Filters/SelectColumnFilter";

export const StyledAccountingTable = styled(TableContent)`
  grid-template-columns: minmax(40px, auto) repeat(3, minmax(90px, 1fr)) repeat(
      5,
      minmax(190px, 1fr)
    );

  grid-template-rows: 35px repeat(auto-fill, 32px);

  th {
    height: auto;
    overflow: unset;
    align-self: center;
  }
`;

export const ExchangesTooltip = styled(InfoTooltip)`
  &.${INFO_TOOLTIP_CLASSNAME} {
    width: auto;
    max-width: 200px;
    font-weight: ${({ theme }) => theme.fontWeights["600"]};
    color: ${({ theme }) => theme.textColor};
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }
`;

export const NamesSelectorFilter = styled(SelectColumnFilter)`
  & .${SELECTOR_CLASSNAME}__control {
    width: 140px;
  }
`;
