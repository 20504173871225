import { memo } from "react";
import { ScrollSize } from "src/components/shared/shared";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
} from "src/components/shared/Table";
import { OrderType } from "src/state/CEX/CEXExchange/trade/OpenOrders/BuySellOrders/BuySellStore";
import styled, { css } from "styled-components";

interface ShellProps {
  fill?: number;
}

interface TotalProps {
  highlight: boolean;
}

export interface PriceProps {
  type: OrderType;
}

export const OrderBookContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Container = memo(styled.div`
  width: 100%;
  height: 0px;

  display: flex;
  flex: 1 1 auto;

  overflow-y: scroll;

  ${ScrollSize}
`);

export const OrderBookTable = styled(Table)`
  display: table;
`;

export const Head = styled(TableHead)``;

export const HeadRow = styled(TableHeadRow)``;

export const Header = styled(TableHeader)``;

export const Cell = memo(styled(TableCell)`
  padding: 2.5px;
`);

export const EmptyOrder = styled.div`
  position: relative;

  ::after {
    content: "";
    position: absolute;

    top: 50%;
    left: 0;

    width: 100%;
    height: 2px;

    background-color: ${({ theme }) => theme.lineColor};
    transform: translateY(-50%);
  }
`;

export const Row = memo(styled(TableRow)`
  position: absolute;

  cursor: pointer;

  top: 0;
  left: 0;
  width: 100%;
`);

export const SellRow = styled(Row)`
  :hover,
  :hover ~ & {
    background-color: rgb(43, 49, 57, 0.3);
  }

  :hover {
    border-top: 0.5px dashed rgb(132, 142, 156);
  }
`;

export const BuyRow = styled(Row)`
  :hover {
    background-color: rgb(43, 49, 57, 0.3) !important;
  }

  :hover ~ & {
    background-color: transparent !important;
  }

  :hover {
    border-bottom: 0.5px dashed rgb(132, 142, 156);
  }
`;

export const Body = styled(TableBody)`
  position: relative;

  width: 100%;

  overflow-y: inherit;

  :hover > ${BuyRow} {
    background-color: rgb(43, 49, 57, 0.3);
  }
`;

export const Shell = memo(styled.div<ShellProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  animation: 0.3s ease-out 0s 1 normal none running none;

  z-index: -1;
  opacity: 0.3;
`);

export const SellShell = styled(Shell)`
  background: ${({ fill = 0, theme }) => `linear-gradient(to right, ${
    theme.contentBackgroundColor
  }  0%, ${theme.contentBackgroundColor}  ${100 - fill}%,
    rgba(246, 70, 93, 1) ${100 - fill}%,  rgba(246, 70, 93, 1) 100%)`};
`;

export const BuyShell = styled(Shell)`
  background: ${({ fill = 0, theme }) => `linear-gradient(to right, ${
    theme.contentBackgroundColor
  }  0%, ${theme.contentBackgroundColor}  ${100 - fill}%,
    rgba(14, 203, 129,  1) ${100 - fill}%,  rgba(14, 203, 129,  1) 100%)`};
`;

export const TextSizeMixin = css`
  width: 100%;

  display: block;
`;

export const Price = memo(styled.span<PriceProps>`
  color: ${({ theme, type }) => (type === "buyOrder" ? theme.successColor : theme.dangerColor)};
  text-align: left;

  ${TextSizeMixin}
`);

export const HighlightCell = memo(styled.span<TotalProps>`
  color: ${({ theme, highlight }) =>
    highlight ? theme.primaryColor : theme.exchangeTableColorText};

  ${TextSizeMixin}
`);
