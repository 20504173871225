import { Headline2 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Header = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Title = styled(Headline2)`
  ::first-letter {
    text-transform: capitalize;
  }
`;
