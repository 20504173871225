import { makeAutoObservable, observable } from "mobx";
import { getBotSettings } from "src/api/bots/DEXV2/settings";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import {
  IBotChainInfoProvider,
  IBotTradePairProvider,
  ISwapPairAddressProvider,
} from "../DEXV2Bots/DEXV2BotStore";
import { INITIAL_PAIR_ADDRESSES } from "../DEXV2Stats/Vaults";
import { INativeUSDPriceProvider } from "../Providers/NativeUSDPriceProvider";
import { ITradePairPriceProvider } from "../Providers/TradePairUSDPriceProvider";
import { INITIAL_DEXV2_SETTINGS } from "../constants";
import WithdrawerProvider, { IWithdrawerProvider } from "../shared/WithdrawerProvider";
import { DEXV2Settings } from "../types";
import { IBaseUSDPriceProvider } from "./SwapModules/shared/Providers/BaseUsdPriceProvider";
import { IBlockTimestampProvider } from "./SwapModules/shared/Providers/BlockTimestampProvider";
import { IGasPriceProvider } from "./SwapModules/shared/Providers/GasPriceProvider";
import {
  ISwapSettingsProvider,
  SwapSettingsProvider,
} from "./SwapModules/shared/Providers/SwapSettingsProvider";

export interface ISwapProviders {
  get baseUSDPriceProvider(): IBaseUSDPriceProvider;
  get tradePairPriceProvider(): ITradePairPriceProvider;
  get tradePairProvider(): IBotTradePairProvider;
  get swapSettingsProvider(): ISwapSettingsProvider;
  get blockTimestampProvider(): IBlockTimestampProvider;
  get gasPriceProvider(): IGasPriceProvider;
  get nativeUSDPriceProvider(): INativeUSDPriceProvider;
}

export interface IDEXV2SwapParams {
  tradePairProvider: IBotTradePairProvider;
  chainInfoProvider: IBotChainInfoProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  tradePairPriceProvider: ITradePairPriceProvider;
  nativeUSDPriceProvider: INativeUSDPriceProvider;
  baseUSDPriceProvider: IBaseUSDPriceProvider;
  blockTimestampProvider: IBlockTimestampProvider;
  gasPriceProvider: IGasPriceProvider;
}
export class DEXV2SwapStore implements IDisposable, ISwapProviders {
  private _botUUID = "";

  private _loading = false;

  private _settings: DEXV2Settings = INITIAL_DEXV2_SETTINGS;

  private _botChainInfoProvider: IBotChainInfoProvider;

  private _withdrawerProvider: IWithdrawerProvider;

  private _tradePairProvider: IBotTradePairProvider;

  private _tradePairPriceProvider: ITradePairPriceProvider;

  private _pairAddressProvider: ISwapPairAddressProvider;

  private _swapSettingsProvider: ISwapSettingsProvider & IDisposable;

  private _baseUSDPriceProvider: IBaseUSDPriceProvider;

  private _gasPriceProvider: IGasPriceProvider;

  private _blockTimestampProvider: IBlockTimestampProvider;

  private _nativeUSDPriceProvider: INativeUSDPriceProvider;

  constructor({
    tradePairProvider,
    chainInfoProvider,
    pairAddressProvider,
    tradePairPriceProvider,
    nativeUSDPriceProvider,
    blockTimestampProvider,
    gasPriceProvider,
    baseUSDPriceProvider,
  }: IDEXV2SwapParams) {
    makeAutoObservable<this, "_baseUSDPriceProvider">(this, {
      _baseUSDPriceProvider: observable.ref,
    });

    this._botChainInfoProvider = chainInfoProvider;
    this._tradePairProvider = tradePairProvider;
    this._pairAddressProvider = pairAddressProvider;

    this._swapSettingsProvider = new SwapSettingsProvider({
      settingsProvider: this,
      botUUIDProvider: this,
      chainInfoProvider: this._botChainInfoProvider,
      pairAddressProvider: this._pairAddressProvider,
    });

    this._withdrawerProvider = new WithdrawerProvider(this);

    this._tradePairPriceProvider = tradePairPriceProvider;

    this._baseUSDPriceProvider = baseUSDPriceProvider;

    this._gasPriceProvider = gasPriceProvider;

    this._blockTimestampProvider = blockTimestampProvider;

    this._nativeUSDPriceProvider = nativeUSDPriceProvider;

    makeLoggable<any>(this, {
      factoryAddress: true,
      _routerAddress: true,
    });
  }

  get gasPriceProvider() {
    return this._gasPriceProvider;
  }

  get blockTimestampProvider() {
    return this._blockTimestampProvider;
  }

  get nativeUSDPriceProvider() {
    return this._nativeUSDPriceProvider;
  }

  get baseUSDPriceProvider() {
    return this._baseUSDPriceProvider;
  }

  get tradePairPriceProvider() {
    return this._tradePairPriceProvider;
  }

  get tradePairProvider() {
    return this._tradePairProvider;
  }

  get swapSettingsProvider() {
    return this._swapSettingsProvider;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  private _setSettings(settings: DEXV2Settings) {
    this._settings = settings;
  }

  get settings() {
    return this._settings;
  }

  get chainMeta() {
    return this._botChainInfoProvider.chainMeta;
  }

  get withdrawer() {
    return this._withdrawerProvider.withdrawer;
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  get addresses() {
    return this._tradePair?.addresses ?? INITIAL_PAIR_ADDRESSES;
  }

  get transactionFees() {
    return this._swapSettingsProvider.transactionFees;
  }

  get dexName() {
    return this._swapSettingsProvider.dexName;
  }

  private _getWithdrawer = async () => {
    await this._withdrawerProvider.getWithdrawer();
  };

  private _getSettings = async () => {
    const { isError, data } = await getBotSettings(this._botUUID);
    if (!isError) {
      this._setSettings(data);
    }
    return isError;
  };

  getSettings = async () => {
    this._setLoading(true);

    try {
      await Promise.all([this._getSettings(), this._getWithdrawer()]);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._swapSettingsProvider.destroy();
  };
}
