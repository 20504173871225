import styled from "styled-components";
import { StatsTable } from "../../../../../shared/StatsTable";

export const Table = styled(StatsTable)`
  td,
  th {
    font-size: ${({ theme }) => theme.fontSizes["8px"]};
  }

  thead {
    tr {
      background: ${({ theme }) => theme.contentBackgroundColor};
      border: none;
    }
  }
  tfoot {
    tr {
      background: ${({ theme }) => theme.contentBackgroundColor};
      border: none;
    }
  }
` as typeof StatsTable;
