import { Headline3 } from "src/components/shared/Typography";
import * as styles from "./style";

export const LoadingState = () => (
  <>
    <Headline3>Deploying Main Vaults...</Headline3>

    <styles.LoaderWrapper>
      <styles.StatusLoader show />
    </styles.LoaderWrapper>
  </>
);
