import styled from "styled-components";

export const WrapperForGroup = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HorizontalWrap = styled.div`
  display: flex;

  & > div:first-of-type {
    margin-right: 10px;
  }
`;

export const TradesMinWrap = styled.div`
  height: 60px;
`;
