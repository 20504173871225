import styled from "styled-components";

export const Container = styled.div`
  display: grid;

  grid-template-columns: repeat(auto-fill, 40px);
  grid-template-rows: auto;
  grid-column-gap: 60px;
  grid-row-gap: 12px;
  justify-items: stretch;
  align-items: stretch;
`;

export const InfoItem = styled.div`
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Label = styled.span`
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["8px"]};
  color: ${({ theme }) => theme.lightGray};
`;

export const Value = styled.span`
  white-space: nowrap;
  /* font-family: "Roboto"; */
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
  color: ${({ theme }) => theme.darkGray};
`;

export const Quote = styled(Label)`
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  color: inherit;
`;
