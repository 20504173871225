import { lighten, rgba } from "polished";
import styled from "styled-components";

interface TitleProps {
  checked: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 12px;
`;

export const SwitchTitle = styled.label<TitleProps>`
  font-weight: ${({ theme }) => theme.fontWeights["600"]};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  color: ${({ theme, checked }) => (checked ? theme.primaryColor : theme.smallHeader)};

  cursor: pointer;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => rgba(theme.smallHeader, 0.5)};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: ${({ theme }) => theme.radii["34px"]};

  ::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    /* left: 4px; */
    bottom: -3px;
    background-color: ${({ theme }) => theme.smallHeader};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: ${({ theme }) => theme.radii["50%"]};
  }
`;

export const CheckBox = styled.input.attrs({
  type: "checkbox",
})`
  opacity: 0;
  width: 0;
  height: 0;

  :checked + ${Slider} {
    background-color: ${({ theme }) => rgba(theme.primaryColor, 0.5)};
  }

  :focus + ${Slider} {
    box-shadow: 0 0 1px ${({ theme }) => lighten(0.03, theme.primaryColor)};
  }

  :checked + ${Slider}::before {
    background-color: ${({ theme }) => theme.primaryColor};
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }

  :disabled + ${Slider} {
    opacity: 0.5;
    pointer-events: none;
    background-color: ${({ theme }) => rgba(theme.smallHeader, 0.5)};

    ::before {
      background-color: ${({ theme }) => theme.smallHeader};
    }
  }
`;
