import { ChangeEvent, useCallback } from "react";
import {
  ConstraintsInput,
  ConstraintsInputProps,
} from "src/components/BotsContent/CEX/CEXBotSettings/shared";
import { LabeledInputProps } from "src/components/shared/Forms/Inputs";

export interface DecimalInputProps
  extends Omit<LabeledInputProps, "onChange" | "pattern" | "inputMode" | "value" | "step" | "type">,
    ConstraintsInputProps {
  onChange?: (input: string) => void;
  value?: string;
}

const decimalRegexp = /^\d*(?:[.])?\d*$/;
const decimalEnforcer = (nextUserInput: string) => {
  if (nextUserInput === "") {
    return "";
  }
  if (nextUserInput === ".") {
    return "0.";
  }
  if (decimalRegexp.test(nextUserInput)) {
    return nextUserInput;
  }
  return null;
};

export const DecimalInput = ({ onChange, ...props }: DecimalInputProps) => {
  const validateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const nextInput = decimalEnforcer(event.target.value.replace(/,/g, "."))?.replace(
        /^0+$/,
        "0"
      );
      if (nextInput !== undefined) {
        onChange?.(nextInput);
      }
    },
    [onChange]
  );
  return (
    <ConstraintsInput
      pattern="^[0-9]*[.,]?[0-9]*$"
      inputMode="decimal"
      type="text"
      onChange={validateChange}
      {...props}
    />
  );
};
