import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { DEXV2SwapContext } from "src/context/DEXV2/DEXV2Swap/DEXV2Swap";
import { noOp } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import { LabeledAddress } from "./LabeledAddress";
import * as styles from "./style";

export interface PairInfoProps extends ComponentPropsWithoutRef<"div"> {}

const formatPercentFee = (fee: string) => `${fee}%`;

export const PairInfo = observer((props: PairInfoProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const scannerLink = getScannerLink(botState.chainInfo, "wallet");

  const {
    addresses: { quote: quoteAddress, base: baseAddress },
    transactionFees,
  } = useLateInitContext(DEXV2SwapContext);

  const buyFee = formatPercentFee(transactionFees.buy);
  const sellFee = formatPercentFee(transactionFees.sell);
  return (
    <styles.Container {...props}>
      <styles.Title>Pair Info</styles.Title>

      <styles.Content>
        <styles.InfoPanel>
          <styles.InfoRow>
            <LabeledInput
              label="Token Transaction Buy Fee"
              type="text"
              readOnly
              onChange={noOp}
              value={buyFee}
            />
            <LabeledInput
              label="Token transaction Sell Fee"
              type="text"
              readOnly
              onChange={noOp}
              value={sellFee}
            />
          </styles.InfoRow>
        </styles.InfoPanel>

        <styles.InfoPanel>
          <styles.InfoRow>
            <LabeledAddress
              label="Quote contract"
              address={quoteAddress}
              scannerLink={scannerLink}
            />
            <LabeledAddress label="Base contract" address={baseAddress} scannerLink={scannerLink} />
          </styles.InfoRow>
        </styles.InfoPanel>
      </styles.Content>
    </styles.Container>
  );
});
