import React, { useEffect, useState } from "react";
import { MenuItems } from "src/modules/shared";
import * as styles from "./style";

interface Props {
  defaultKey: string;
  onChange: Function;
  menuItems: MenuItems[];
  onClickActive: () => void;
}

export const AccountSwitcher = ({
  defaultKey,
  onChange = () => null,
  menuItems,
  onClickActive = () => null,
}: Props) => {
  const [currentKey, setCurrentKey] = useState(defaultKey);

  useEffect(() => {
    setCurrentKey(defaultKey);
  }, [defaultKey]);

  const onChangeKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentKey(event.target.value);
    onChange(event.target.value);
  };

  return (
    <styles.Container>
      <styles.Header label="Balances" />
      <styles.Switcher
        items={menuItems}
        selectedItem={currentKey}
        onChange={onChangeKey}
        direction="column"
        onClickActive={onClickActive}
      />
    </styles.Container>
  );
};
