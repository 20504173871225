import { makeAutoObservable, runInAction } from "mobx";
import {
  changeAnalytic,
  changeAnalyticName,
  createAnalytic,
  deleteAnalytic,
  deleteDefaultAnalytic,
  getAllAnalytics,
  getAnalytic,
  setDefaultAnalytic,
} from "src/api/bots/CEX/analytics";
import { ChartData, IChartingLibraryWidget } from "src/charting_library/charting_library";
import { joinPair } from "src/helpers/botName";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { noOp } from "src/helpers/utils";
import { Analytic, ListAnalytic, NewPairAnalytic } from "src/modules/analytics";
import { TerminalRequestMode } from "../CEXExchange/shared/TerminalSettingsStore";

class AnalyticsStore {
  botUUID: string = "";

  market: string = "";

  party: string = "";

  private _savePair = "";

  currentAnalytic: string | null = null;

  editAnalytic: string | null = null;

  analytics: ListAnalytic[] = [];

  selectPair: string = "";

  newPair: string = "";

  updChartCb: () => Promise<void> | void = noOp;

  chartWidget: IChartingLibraryWidget | null = null;

  forceChartCb = noOp;

  private _isCreate: boolean = false;

  requestMode: TerminalRequestMode = "FETCH";

  constructor() {
    makeAutoObservable(this);
  }

  get exchange() {
    if (!this.market) return "";

    return this.market.split("_")[2];
  }

  get pair() {
    // return this.currentPair;
    return this.selectPair;
  }

  get currentPair() {
    return joinPair(this.market.split("_")[0], this.market.split("_")[1]);
  }

  get currentChart() {
    if (!this.currentAnalytic) return undefined;

    // return this.analytics.find((el) => el.default === this.currentStrategy);

    return this.analyticsMap[this.currentAnalytic];
  }

  get strategyChosen() {
    return !!this.currentAnalytic;
  }

  get analyticsMap() {
    const hashMap: Record<string, Analytic> = {};

    for (const analytic of this.analytics) {
      for (const el of analytic.analytics) {
        hashMap[el.uuid] = el;
      }
    }

    return hashMap;
  }

  get pairEditAnalytic() {
    if (!this.editAnalytic) return "";
    return this.analyticsMap[this.editAnalytic].pair;
  }

  setChartWidget = (widget: IChartingLibraryWidget) => {
    this.chartWidget = widget;
  };

  setParty = (party: string) => {
    this.party = party;
  };

  setBotUUID = (uuid: string) => {
    this.botUUID = uuid;
  };

  setMarket = (market: string) => {
    this.market = market;

    this.setPair(this.currentPair);
  };

  setPair = (pair: string) => {
    this.selectPair = pair;
  };

  setNewPair = (pair: string) => {
    this.newPair = pair;
  };

  setUpdChartCb = (cb: () => Promise<void>) => {
    this.updChartCb = cb;
  };

  setCurrentAnalytic = (id: string) => {
    this.currentAnalytic = id;

    this.setPair(this.analyticsMap[id].pair);

    this.selectCurrentAnalytic();
  };

  setEditAnalytic = (uuid: string) => {
    this.editAnalytic = uuid;
  };

  setForceUpdChart = (cb: () => void) => {
    this.forceChartCb = cb;
  };

  selectCurrentAnalytic = () => {
    const data = { ...this.currentChart, id: this.currentChart?.uuid };

    if (this.currentChart) this.chartWidget?.loadChartFromServer(data as any);
  };

  startCreateAnalytic = (pair: string) => {
    this.setPair(pair);
    this.setNewPair(pair);
    this.resetCurrentAnalytic();

    this.forceChartCb();
  };

  createNewPairAnalytic = (pair: string) => {
    this.setPair(pair);
    this.setNewPair("");
    this.resetCurrentAnalytic();

    this.forceChartCb();
  };

  resetCurrentAnalytic = () => {
    this.currentAnalytic = null;
  };

  deleteAnalytic = () => {
    if (this.chartWidget)
      this.chartWidget.removeChartFromServer(String(this.currentAnalytic), () => {
        this.resetCurrentAnalytic();
        runInAction(() => {
          this.selectPair = this.currentPair;
        });
        this.fetchAllChart();
        this.forceChartCb();
      });
  };

  createAnalytic = (analytic: NewPairAnalytic) => {
    if (this.chartWidget) {
      runInAction(() => {
        this._isCreate = true;
      });

      this.resetCurrentAnalytic();

      // set pair for create new analytic
      runInAction(() => {
        this._savePair = analytic.pair;
      });

      this.chartWidget.saveChartToServer(noOp, noOp, {
        chartName: analytic.name,
      });
    }
  };

  saveAnalytic = () => {
    if (!this.currentChart) return;

    runInAction(() => {
      this._isCreate = false;
    });

    runInAction(() => {
      this._savePair = this.currentChart ? this.currentChart?.pair : "";
    });

    if (this.chartWidget)
      this.chartWidget.saveChartToServer(noOp, noOp, {
        chartName: this.currentChart.name,
      });
  };

  saveMediator = async (data: ChartData) => {
    if (this._isCreate) {
      this.fetchCreateNewAnalytic(data);
    } else this.fetchSaveAnalytic(data);
  };

  editNameAnalytic = async (analytic: NewPairAnalytic) => {
    if (!this.editAnalytic) return;

    try {
      const { isError } = await changeAnalyticName(this.editAnalytic, analytic.name);

      if (!isError) {
        this.setEditAnalytic("");
        this.fetchAllChart();
        showSuccessMsg("Analytics successfully edited");
      }
    } catch (err) {
      logError(err);
    }
  };

  fetchAllChart = async () => {
    try {
      const { isError, data } = await getAllAnalytics(this.party);

      if (!isError) {
        runInAction(() => {
          this.analytics = data;
        });
      }
    } catch (err) {
      runInAction(() => {
        this.analytics = [];
      });
    }
  };

  fetchCreateNewAnalytic = async (data: ChartData) => {
    const mutateDataChart = {
      content: data.content,
      name: data.name,
      pair: this._savePair,
    };

    try {
      const { isError } = await createAnalytic(this.party, mutateDataChart);

      if (!isError) {
        showSuccessMsg("Analytics successfully created");

        this.fetchAllChart();
      }
    } catch (err) {
      logError(err);
    }
  };

  fetchSaveAnalytic = async (data: ChartData) => {
    if (!this.currentAnalytic) return;

    try {
      const { isError } = await changeAnalytic(this.currentAnalytic, data.content);

      if (!isError) {
        showSuccessMsg("Analytics successfully saved");

        this.fetchAllChart();
      }
    } catch (err) {
      logError(err);
    }
  };

  fetchCurrentChart = async (uuid: string): Promise<string> => {
    try {
      const { isError, data } = await getAnalytic(uuid);

      if (!isError) {
        return data.content;
      }

      return "";
    } catch {
      return "";
    }
  };

  fetchDeleteAnalytic = async (uuid: string) => {
    try {
      const { isError } = await deleteAnalytic(uuid);

      if (!isError) {
        this.fetchAllChart();
      }
    } catch (err) {
      logError(err);
    }
  };

  changeDefaultAnalytic = async (uuid: string) => {
    const isDefaultAnalytic = this.analyticsMap[uuid].default;

    try {
      const { isError } = isDefaultAnalytic
        ? await deleteDefaultAnalytic(uuid)
        : await setDefaultAnalytic(uuid);

      if (!isError) {
        this.fetchAllChart();
      }
    } catch (err) {
      logError(err);
    }
  };
}

export default AnalyticsStore;
