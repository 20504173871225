import { ISeriesApi, SeriesType } from "lightweight-charts";

export interface ISeriesApiState<T extends SeriesType> {
  get api(): ISeriesApi<T> | null;

  get isDisposed(): boolean;

  setDisposed: () => void;
}

/** Wrapper around lightweight-charts series api with disposal state
 * for cases when hooks may be called after series is removed */
export class SeriesApiStore<T extends SeriesType> implements ISeriesApiState<T> {
  private _disposed: boolean;

  private _seriesApi: ISeriesApi<T> | null;

  constructor(series: ISeriesApi<T>) {
    this._seriesApi = series;
    this._disposed = false;
  }

  get api(): ISeriesApi<T> | null {
    if (this._disposed) return null;
    return this._seriesApi;
  }

  get isDisposed(): boolean {
    return this._disposed;
  }

  setDisposed = () => {
    this._disposed = true;
    this._seriesApi = null;
  };
}
