import { DEXV2Vault } from "src/api/bots/DEXV2/create";
import { AddressCell } from "src/components/BotsContent/DEX_V2/Stats/Tables/TablePanel/AddressCell";
import { VaultTypeCell } from "src/components/BotsContent/DEX_V2/Stats/Tables/Vaults/VaultTypeCell/VaultTypeCell";
import * as styles from "./style";

export interface WalletRowProps extends DEXV2Vault {
  scannerLink?: string;
}

export const WalletRow = ({ address, base, quote, type, scannerLink }: WalletRowProps) => (
  <styles.BalanceRow>
    <styles.BalanceCell>
      <AddressCell
        address={address}
        style={{ justifyContent: "flex-start" }}
        scannerLink={scannerLink}
      />
    </styles.BalanceCell>
    <styles.BalanceCell>
      <VaultTypeCell type={type} />
    </styles.BalanceCell>
    <styles.BalanceCell>{base}</styles.BalanceCell>
    <styles.BalanceCell>{quote}</styles.BalanceCell>
  </styles.BalanceRow>
);
