import styled from "styled-components";
import { Title } from "../../style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

export const Label = styled(Title).attrs({ as: "p" })``;

export const Value = styled.span`
  color: ${({ theme }) => theme.dashboard.textHeading};

  font-size: ${({ theme }) => theme.fontSizes["9px"]};
  font-weight: ${({ theme }) => theme.fontWeights[600]};
`;
