import { observer } from "mobx-react-lite";
import React, { ComponentPropsWithoutRef, RefAttributes } from "react";
import { Popup } from "reactjs-popup";
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";
import { PopupContext } from "src/context/shared/PopupContext";
import Icons from "src/icons/Icons";
import { IconButton } from "../Buttons/v2/IconButton";
import { Loader } from "../Loader";
import * as styles from "./style";

type PopupPropsWithoutTrigger = Omit<PopupProps & RefAttributes<PopupActions>, "trigger">;

export interface ModalPanelV2Props extends ComponentPropsWithoutRef<"div"> {
  label: string;
  children: JSX.Element | JSX.Element[];
  afterLabel?: JSX.Element;
  style?: React.CSSProperties;
  loading?: boolean;
  trigger?: JSX.Element | ((isOpen: boolean) => JSX.Element);
  popupProps?: PopupPropsWithoutTrigger;
  zIndex?: number;
}

export const ModalPanelV2 = observer(
  ({
    label,
    children,
    loading,
    afterLabel,
    trigger,
    popupProps,
    zIndex,
    ...props
  }: ModalPanelV2Props) => {
    const zIndexStyle = zIndex ? { zIndex } : {};
    const overlayStyle = { ...popupProps?.overlayStyle, ...zIndexStyle };

    return (
      <Popup
        trigger={trigger}
        modal
        {...popupProps}
        overlayStyle={overlayStyle}
        closeOnDocumentClick={false}
      >
        {
          ((close: () => void) => (
            <PopupContext.Provider value={{ close }}>
              <styles.Container>
                <styles.Panel {...props}>
                  <styles.CloseWrapper>
                    <IconButton onClick={close} size="l" boxed>
                      {Icons.cross()}
                    </IconButton>
                  </styles.CloseWrapper>

                  <styles.WrapperTitle>
                    <styles.Title>{label}</styles.Title>
                    {afterLabel}
                  </styles.WrapperTitle>

                  <styles.Content>{children}</styles.Content>

                  <Loader show={loading} />
                </styles.Panel>
              </styles.Container>
            </PopupContext.Provider>
          )) as unknown as React.ReactNode
        }
      </Popup>
    );
  }
);
