import { rgba } from "polished";
import { StyledIconButton } from "src/components/shared/Buttons/v2/IconButton/style";
import styled, { css } from "styled-components";
import { HighlightMixin, HighlightProps } from "../../shared";

export interface DropDownProps {
  hide: boolean;
}

export interface ScopeItem {
  widthArrow: number;
}

export const Container = styled.div`
  width: 100%;
  height: max-content;
`;

export const ItemBody = styled.div`
  position: relative;

  display: flex;

  margin-bottom: 16px;

  :not(:first-of-type) {
    margin-left: 10px;
    padding-left: 30px;

    ::before {
      position: absolute;
      content: "";
      left: 0px;
      top: -8px;
      bottom: 8px;
      border-left: dashed;
      border-width: 1px;
      border-color: #d6d6d6;
    }
  }
`;

export const ItemsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  position: relative;

  height: 20px;
  width: max-content;
  min-width: 108px;

  display: flex;
  align-items: center;

  border-radius: ${({ theme }) => theme.radii["2px"]};
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
`;

export const ItemHeader = styled(ItemContainer)`
  margin-bottom: 16px;
  padding-left: 10px;

  justify-content: space-between;

  background-color: #a1a1a11a;
  border: 1px solid #efefef;
`;

export const ExchAccHeader = styled(ItemHeader)`
  width: 145px;

  overflow-x: hidden;
`;

export const ScopeGenTop = styled(ItemHeader)``;

export const ScopeItemHeader = styled(ItemHeader)<ScopeItem & HighlightProps>`
  background: ${({ theme }) => rgba(theme.primaryColor, 0.1)};
  border: 1px solid ${({ theme }) => rgba(theme.primaryColor, 0.2)};

  ::before {
    position: absolute;

    left: ${({ widthArrow }) => `${-widthArrow - 16}px`};
    bottom: 8px;

    width: ${({ widthArrow }) => `${widthArrow - 10}px`};

    content: "";
    border-bottom: dashed;
    border-width: 1px;
    border-color: #d6d6d6;
  }

  ${HighlightMixin}
`;

export const Party = styled(ItemContainer)<ScopeItem & HighlightProps>`
  padding-left: 11px;

  background-color: #a1a1a11a;
  border: 1px solid #efefef;
  color: ${({ theme }) => theme.primaryColor};

  cursor: pointer;

  ::before {
    position: absolute;

    width: ${({ widthArrow }) => `${widthArrow - 10}px`};

    content: "";
    left: ${({ widthArrow }) => `${-widthArrow + 9}px`};
    bottom: 7px;
    border-bottom: dashed;
    border-width: 1px;
    border-color: #d6d6d6;
  }

  ${HighlightMixin}
`;

export const ScopeIconButtonMixin = css`
  ${StyledIconButton} {
    margin-top: -5px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: start;

  & :not(:last-child) {
    margin-right: 8px;
  }

  ${ScopeIconButtonMixin}

  ${StyledIconButton}:not(:first-of-type) {
    margin-right: 0px;
  }
`;

export const PartyWrapper = styled(Wrapper)`
  :not(:last-of-type) {
    margin-bottom: 16px;
  }
`;
