import { SelectionPanelProps } from "src/components/shared/Forms/Selectors";
import { SelectorValue } from "src/modules/shared";
import * as styles from "./style";

type SelectorProps = Pick<
  SelectionPanelProps<true>["selectorProps"],
  "options" | "onChange" | "value" | "label" | "isDisabled"
>;

export interface SelectorFilterProps extends SelectorProps {
  onRemove: (value: string) => void;
}

export const SelectorFilter = ({ value, onRemove, label, ...props }: SelectorFilterProps) => (
  <styles.StyledSelectionPanel
    selectorProps={{
      label,
      isMulti: true,
      value,
      ...props,
    }}
    selectItems={value as SelectorValue[]}
    removeClick={onRemove}
  />
);
