import { ICache } from "../ICache";

export class MapCache<T> implements ICache<T> {
  private _cache: Map<string, T>;

  constructor() {
    this._cache = new Map();
  }

  get = (key: string) => this._cache.get(key);

  set = (key: string, value: T) => this._cache.set(key, value);

  has = (key: string) => this._cache.has(key);

  delete = (key: string) => this._cache.delete(key);

  clear = () => {
    this._cache.clear();
  };
}
