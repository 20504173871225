import { StyledProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";
import { Button } from "../../Button";
import { BackgroundColorMixin, TextColorMixin } from "../../ToggleButton/style";

export interface SaveButtonStyleProps {
  saved: boolean;
}

export interface SaveButtonStyledProps extends StyledProps<SaveButtonStyleProps> {}

const BorderMixin = ({ $saved }: Pick<SaveButtonStyledProps, "$saved">) => css`
  border-width: 1px;
  ${() => {
    if (!$saved) {
      return css`
        border-color: ${({ theme }) => (theme.primaryMode === "orange" ? "#ff974d" : "#99c4ff")};
      `;
    }
  }}
`;

export const StyledSaveButton = styled(Button).attrs({
  size: "s",
})<SaveButtonStyledProps>`
  min-width: 50px;
  height: 20px;

  font-weight: ${({ theme }) => theme.fontWeights["500"]};
  line-height: ${({ theme }) => theme.lineHeights["10px"]};

  ${({ $saved }) => BackgroundColorMixin({ $selected: $saved })}
  ${({ $saved }) => TextColorMixin({ $selected: $saved })}
  ${BorderMixin}  

  :disabled {
    opacity: 1;
  }
`;
