import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import GraphsStore from "src/state/DEXV2/DEXV2Stats/Graphs";
import { DEXV2BotContext } from "../../DEXV2Bots/DEXV2Bot";

export const GraphsContext = createLateInitContext<GraphsStore>();

type GraphsProviderProps = ChildrenProps;

export const GraphsProvider = ({ children }: GraphsProviderProps) => {
  const botState = useLateInitContext(DEXV2BotContext);

  const store = useLocalStore(GraphsStore, {
    botChainProvider: botState,
    tickersProvider: botState,
    tradePairProvider: botState,
    tradePairPriceProvider: botState.tradePairPriceProvider,
    nativeUSDPriceProvider: botState.nativeUSDPriceProvider,
  });

  return <GraphsContext.Provider value={store}>{children}</GraphsContext.Provider>;
};
