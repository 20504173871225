import styled from "styled-components";

export const CommentCell = styled.span`
  max-width: 170px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;

  &::after {
    content: "";
    display: block;
  }
`;
