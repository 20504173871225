import { RefObject, useCallback, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { useVirtual } from "react-virtual";
import {
  flexRender,
  Row,
  useReactTable,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedUniqueValues,
  getFacetedRowModel,
  ColumnDef,
  Column,
  OnChangeFn,
} from "@tanstack/react-table";
import { useTableVirtualPadding } from "src/hooks/useTableVirtualPadding";
import { PartyMarketCheck } from "src/state/MarketCheck/types";
import * as styles from "./style";

interface TableVirtualRowsProps {
  data: PartyMarketCheck[];
  columns: ColumnDef<PartyMarketCheck>[];
  rowSize: number;
  tableWrapperRef: RefObject<HTMLDivElement>;
  sortingState: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
}

export const TableVirtualRows = observer(
  ({
    data,
    columns,
    rowSize,
    tableWrapperRef,
    sortingState,
    onSortingChange,
  }: TableVirtualRowsProps) => {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const id = useMemo(() => nanoid(), []);
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const table = useReactTable({
      data,
      columns,
      state: { sorting: sortingState, columnFilters },
      manualSorting: Boolean(sortingState && onSortingChange),
      onColumnFiltersChange: setColumnFilters,
      onSortingChange,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      debugTable: false,
    });

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
      estimateSize: useCallback(() => rowSize, [rowSize]),
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const [PaddingTop, PaddingBottom] = useTableVirtualPadding(virtualRows, totalSize);

    return (
      <styles.Wrapper ref={tableWrapperRef}>
        <styles.Container ref={tableContainerRef}>
          <styles.OrdersTable>
            <styles.Head data-tooltip-id={id}>
              {table.getHeaderGroups().map((headerGroup) => (
                <styles.HeaderRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <styles.HeaderCell
                      key={header.id}
                      colSpan={header.colSpan}
                      style={header.column.getCanResize() ? { width: header.getSize() } : undefined}
                      column={header.column as Column<unknown, unknown>}
                      {...{
                        className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </styles.HeaderCell>
                  ))}
                </styles.HeaderRow>
              ))}
            </styles.Head>

            <styles.Body>
              <PaddingTop />

              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index];

                return (
                  <styles.Row key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <styles.Cell
                        key={cell.id}
                        style={
                          cell.column.getCanResize() ? { width: cell.column.getSize() } : undefined
                        }
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </styles.Cell>
                    ))}
                  </styles.Row>
                );
              })}

              <PaddingBottom />
            </styles.Body>
          </styles.OrdersTable>
        </styles.Container>
      </styles.Wrapper>
    );
  }
);
