import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { ConstraintsInput } from "src/components/BotsContent/CEX/CEXBotSettings/shared";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FormDynamicRow, FormRow, SettingsPanel } from "../../shared";
import { FormContent } from "../style";

export interface BaseSettingsPanelProps extends ComponentPropsWithoutRef<"div"> {}

export const BaseSettingsPanel = observer((props: BaseSettingsPanelProps) => {
  const state = useLateInitContext(DEXV2CreatingContext);

  return (
    <SettingsPanel {...props}>
      <FormContent>
        <FormRow>
          <LabeledSelector
            label="Party"
            {...state.getSelectorProps("base_data.party")}
            errorHint={state.errors.base_data?.party}
          />
          <LabeledInput label="Bot name" {...state.getInputProps("base_data.bot_name")} />
        </FormRow>

        <FormRow>
          <LabeledSelector label="Network" {...state.getSelectorProps("network")} />
          <LabeledSelector label="DEX version" {...state.getSelectorProps("version")} />
        </FormRow>

        <FormDynamicRow>
          <LabeledSelector
            label="Exchange (DEX)"
            {...state.getSelectorProps("base_data.exchange")}
            errorHint={state.errors.base_data?.exchange}
          />
          {state.isPoolPercentVisible && (
            <LabeledSelector
              label="Pool percent"
              {...state.getSelectorProps("main_data.pool_percent")}
              errorHint={state.errors.main_data?.pool_percent}
            />
          )}
        </FormDynamicRow>

        <FormRow>
          <ConstraintsInput
            label="Quote contract"
            {...state.getInputProps("base_data.quote")}
            {...state.getFieldErrorAsProps("base_data.quote")}
          />
          <ConstraintsInput
            label="Base contract"
            {...state.getInputProps("base_data.base")}
            {...state.getFieldErrorAsProps("base_data.base")}
          />
        </FormRow>

        <FormRow>
          <ConstraintsInput
            label="Withdrawer"
            {...state.getInputProps("base_data.withdrawer")}
            {...state.getFieldErrorAsProps("base_data.withdrawer")}
          />
          <LabeledInput
            type="number"
            step="1"
            label="Receivers number"
            {...state.getInputProps("base_data.receiver_num")}
          />
        </FormRow>
      </FormContent>
    </SettingsPanel>
  );
});
