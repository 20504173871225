import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";

export interface ChainInfoContainerProps {
  color?: string;
}

const getInfoColors = (mainColor: string) => ({
  primary: mainColor,
  background: hexToRgb(mainColor, 0.1),
});

export const ChainInfoContainer = styled.div<ChainInfoContainerProps>`
  ${({ color, theme }) => {
    const mainColor = color ?? theme.lightGray;
    const { primary, background } = getInfoColors(mainColor);
    return css`
      height: 56px;
      min-width: 120px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      padding: 8px;

      border-radius: ${({ theme }) => theme.radii["4px"]};
      border: 1px solid ${primary};
      background: ${background};
    `;
  }}
`;

export const Text = styled.span`
  margin: 0;
  padding: 0;

  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.inputTextColor};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    height: 38px;
    width: 38px;
  }
`;

export const IconPlaceholder = styled.div`
  border-radius: 50%;
  background: ${({ theme }) => hexToRgb(theme.lightGray, 0.4)};
`;
