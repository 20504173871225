import { SeriesType } from "lightweight-charts";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { ChartApiContext } from "src/context/Graph/ChartApi";
import { ChartApiStore } from "src/state/Graph/ChartApiStore";
import { ChartSeries, ChartSeriesProps } from "./ChartSeries";
import { ChartTooltip, ChartTooltipProps } from "./ChartTooltip";
import { SelectionCanvas } from "./SelectionCanvas";
import { DEFAULT_MOBILE_TOOLTIP_QUERY } from "./constants";
import {
  useChartState,
  useDefaultChartOptions,
  useDefaultChartThemeOptions,
  useGraphActionsInfo,
  useLegend,
  useSeries,
  useTooltipDataCopy,
  useTooltipShow,
} from "./hooks";
import * as styles from "./style";
import { GraphDataRequest, GraphLegendOptions, OptionalChartOptions, SeriesProps } from "./types";
import { getPaneCanvas, getPaneCrosshairCanvas } from "./utils";

export interface GraphRootProps
  extends React.ComponentPropsWithoutRef<"div">,
    Pick<ChartTooltipProps, "showTooltip" | "tooltip"> {
  series?: SeriesProps[];
  allowTimeScale?: boolean;
  autoColorScales?: boolean;
  startOptions?: OptionalChartOptions;
  options?: OptionalChartOptions;
  request?: GraphDataRequest;
  tooltipQuery?: string;
  legendOptions?: GraphLegendOptions;
  showInfo?: boolean;
}

export const GraphRoot = observer(
  ({
    series: seriesProps = [],
    request,
    allowTimeScale = true,
    showTooltip: outerShowTooltip = true,
    autoColorScales = true,
    tooltip,
    tooltipQuery = DEFAULT_MOBILE_TOOLTIP_QUERY,
    startOptions,
    options,
    legendOptions,
    showInfo,
    ...props
  }: GraphRootProps) => {
    const container = useRef<HTMLDivElement>(null);

    const [paneCrosshairCanvas, setPaneCrosshairCanvas] = useState<HTMLCanvasElement | null>(null);
    const [paneCanvasContainer, setPaneCanvasContainer] = useState<HTMLDivElement | null>(null);
    const paneCanvas = useRef<HTMLCanvasElement | null>(null);

    const [chart, setChart] = useChartState();

    const {
      seriesMap,
      setSeries,
      seriesTitlesMap,
      setSeriesOptions,
      setSeriesTitle,
      defaultizedSeriesProps,
    } = useSeries({
      series: seriesProps,
    });

    const showTooltip = useTooltipShow({
      tooltipQuery,
      showTooltip: outerShowTooltip,
    });

    const onTooltipCopy = useTooltipDataCopy({ showTooltip });

    const Info = useGraphActionsInfo({ showTooltip, allowTimeScale, showInfo });

    const defaultChartOptions = useDefaultChartOptions({
      allowTimeScale,
      startOptions,
    });

    const defaultChartThemeOptions = useDefaultChartThemeOptions({
      options,
      series: defaultizedSeriesProps,
      autoColorScales,
    });

    const Legend = useLegend({ legendOptions });

    // chart rendering and initializing lines
    useEffect(() => {
      if (!container.current) return;

      const { clientWidth, clientHeight } = container.current;

      const options: OptionalChartOptions = {
        ...defaultChartOptions,
        width: clientWidth,
        height: clientHeight,
      };

      const chartState = ChartApiStore.createChartApi(container.current, options);
      setChart(chartState);
      const chartApi = chartState.api;

      const paneCrosshairCanvasNode = getPaneCrosshairCanvas(chartApi);
      if (paneCrosshairCanvasNode) {
        setPaneCrosshairCanvas(paneCrosshairCanvasNode);
      }

      const paneCanvasNode = getPaneCanvas(chartApi);
      if (paneCanvasNode) {
        paneCanvas.current = paneCanvasNode;
        const paneCanvasContainerNode = paneCanvasNode?.parentNode;
        if (paneCanvasContainerNode) {
          setPaneCanvasContainer(paneCanvasContainerNode as HTMLDivElement);
        }
      }

      return () => {
        chartState.dispose();
        setChart(null);

        setPaneCanvasContainer(null);
        setPaneCrosshairCanvas(null);
      };
    }, [container, defaultChartOptions, setChart]);

    // applying theme
    useEffect(() => {
      const chartApi = chart?.api;

      chartApi?.applyOptions(defaultChartThemeOptions);

      chartApi?.timeScale().fitContent();
    }, [chart, defaultChartThemeOptions]);

    // resizing chart
    useEffect(() => {
      const resizeChart = () => {
        if (!container.current) return;
        const { clientWidth, clientHeight } = container.current;

        const chartApi = chart?.api;

        chartApi?.resize(clientWidth, clientHeight);
        chartApi?.timeScale().fitContent();
      };

      window.addEventListener("resize", resizeChart);

      return () => window.removeEventListener("resize", resizeChart);
    }, [chart, container]);

    return (
      <ChartApiContext.Provider value={chart}>
        <styles.Container {...props}>
          {Legend}
          <styles.GraphContainer ref={container} onDoubleClick={onTooltipCopy}>
            <SelectionCanvas
              paneCanvas={paneCanvas}
              paneCanvasContainer={paneCanvasContainer}
              paneCrosshairCanvas={paneCrosshairCanvas}
              allowTimeScale={allowTimeScale}
              request={request}
            />
            {defaultizedSeriesProps.map((props) => {
              const key = props.id;

              return (
                <ChartSeries
                  key={key}
                  onOptionsUpdate={setSeriesOptions}
                  onTitleUpdate={setSeriesTitle}
                  onSeriesChange={setSeries as ChartSeriesProps<SeriesType>["onSeriesChange"]}
                  {...props}
                />
              );
            })}
          </styles.GraphContainer>
          <ChartTooltip
            container={paneCanvasContainer}
            seriesTitlesMap={seriesTitlesMap}
            seriesMap={seriesMap}
            showTooltip={showTooltip}
            tooltip={tooltip}
          />
          {Info}
        </styles.Container>
      </ChartApiContext.Provider>
    );
  }
);
