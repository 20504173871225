import cleanDeep from "clean-deep";
import { makeAutoObservable } from "mobx";
import { UseFormSetValue } from "react-hook-form";
import { getDataByKey, setData } from "src/helpers/forms/getByKey";
import { getPathsFromObject, isEmptyObject } from "src/helpers/obj";
import { toParseJSON, toStringifyJSON } from "src/helpers/string";
import { noOp } from "src/helpers/utils";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ChooseSettingParamsStore } from "./ChooseSettingParamsStore";
import { HIDDEN_SETTINGS_PARAMS } from "./shared/consts";
import {
  ChangeableCEXSettings,
  IChangeSettingsModuleStore,
  SettingsParamsPaths,
} from "./shared/types";
import { setCalcVolumesMaxParams } from "./shared/utils";

export class ActionChangeSettingsStore implements IChangeSettingsModuleStore {
  private _chooseParamsState: ChooseSettingParamsStore;

  private _setModuleSoftParam: UseFormSetValue<IStrategyCreator> = noOp;

  private _moduleIndex: number | null = null;

  private _defaultSettingsConfig: Partial<ChangeableCEXSettings> = {};

  private _settingsConfig: Partial<ChangeableCEXSettings> = {};

  constructor(state: ChooseSettingParamsStore, jsonConfig: string) {
    this._chooseParamsState = state;
    this._parseConfig(jsonConfig);

    makeAutoObservable(this);
  }

  get chooseParamsState() {
    return this._chooseParamsState;
  }

  get defaultSettingsConfig() {
    return this._defaultSettingsConfig;
  }

  setModuleSoftParamSetter = (cb: UseFormSetValue<IStrategyCreator>) => {
    this._setModuleSoftParam = cb;
  };

  setModuleIndex = (indx: number) => {
    this._moduleIndex = indx;
  };

  generateSettingsConfig = (data: Partial<ChangeableCEXSettings>) => {
    this._settingsConfig = data;

    this._setActionSoftParam(data);
  };

  getAddedParamStatus = (param: SettingsParamsPaths) => {
    const value = getDataByKey(this._settingsConfig, param);

    return value !== undefined;
  };

  removeConfigParam = (param: SettingsParamsPaths) => {
    setData(this._settingsConfig as ChangeableCEXSettings, param, undefined as any);

    this._settingsConfig = cleanDeep(this._settingsConfig);

    this._setActionSoftParam(this._settingsConfig);
  };

  private _setActionSoftParam = (data: Partial<ChangeableCEXSettings>) => {
    if (this._moduleIndex === null) return;

    const isEmpty = isEmptyObject(data);

    const moduleParamName = `actions.${this._moduleIndex}.soft_params.settings` as const;

    if (!isEmpty) {
      setCalcVolumesMaxParams(data);

      const jsonSettingsConfig = toStringifyJSON(data);

      this._setModuleSoftParam(moduleParamName, jsonSettingsConfig);
    } else {
      this._setModuleSoftParam(moduleParamName, "");
    }
  };

  private _parseConfig = (jsonConfig: string) => {
    if (!jsonConfig) return;

    let settingsConfig = toParseJSON<Partial<ChangeableCEXSettings>>(jsonConfig);

    // remove implicit fields from configuration
    HIDDEN_SETTINGS_PARAMS.forEach((el) => {
      setData(settingsConfig as ChangeableCEXSettings, el as SettingsParamsPaths, undefined as any);

      settingsConfig = cleanDeep(settingsConfig);
    });

    this._defaultSettingsConfig = settingsConfig;
    this._settingsConfig = settingsConfig;

    const paths = getPathsFromObject<ChangeableCEXSettings>(this._defaultSettingsConfig);

    this.chooseParamsState.setSettingsParams(paths);
  };

  destroy = () => {};
}
