import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import styled from "styled-components";
import { Container } from "../../shared/shared";

export const ContentArea = styled(Container)`
  max-width: 1500px;
`;

export const Content = styled.div`
  display: grid;
  grid-template-rows: 527px 375px;

  grid-template-columns: minmax(400px, 588px) minmax(350px, 388px) minmax(350px, 488px);

  gap: 15px;

  grid-template-areas:
    "users         roles       scopes"
    "hierarchies   groups      scopes";

  @media screen and (max-width: 1200px) {
    grid-template-rows: 527px 527px 375px;

    grid-template-columns: repeat(2, minmax(0, 1fr));

    grid-template-areas:
      "users         scopes  "
      "roles         scopes  "
      "hierarchies   groups  ";
  }

  @media screen and (max-width: 850px) {
    grid-template-rows: repeat(2, 375px) repeat(2, 527px) 375px;

    grid-template-columns: minmax(350px, 1fr);

    grid-template-areas:
      "users         "
      "roles         "
      "scopes        "
      "hierarchies   "
      "groups        ";
  }
`;

export const UsersFallback = styled(CrashPanel)`
  grid-area: users;
`;

export const RolesFallback = styled(CrashPanel)`
  grid-area: roles;
`;
export const HierarchiesFallback = styled(CrashPanel)`
  grid-area: hierarchies;
`;
export const UserGroupsFallback = styled(CrashPanel)`
  grid-area: groups;
`;
export const ScopesFallback = styled(CrashPanel)`
  grid-area: scopes;
`;
