import { observer } from "mobx-react-lite";
import { Tooltip } from "react-tooltip";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Loader } from "src/components/shared/Loader";
import { Headline3 } from "src/components/shared/Typography";
import { MultiGridStateContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ColumnWrapper, CountInfoRow, PanelContainer, Wrapper } from "../../shared";

const PRICE_TOOLTIP = "price_tooltip";

export const StatsPanel = observer(() => {
  const state = useLateInitContext(MultiGridStateContext);

  return (
    <PanelContainer style={{ gridArea: "stats" }}>
      <Headline3>Stats</Headline3>

      <ColumnWrapper>
        <CountInfoRow>Ratio</CountInfoRow>

        <Wrapper>
          {" "}
          <LabeledInput
            readOnly
            type="number"
            label="Buy Count"
            value={state.gridStats.buy_levels}
          />
          <LabeledInput
            readOnly
            type="number"
            label="Sell Count"
            value={state.gridStats.sell_levels}
          />
          <LabeledInput
            readOnly
            type="number"
            label="Disbalance Count"
            value={state.gridStats.disbalance_levels}
          />
        </Wrapper>
      </ColumnWrapper>

      <ColumnWrapper>
        <CountInfoRow>Position</CountInfoRow>

        <Wrapper>
          <LabeledInput
            readOnly
            type="number"
            label="Quote"
            value={state.gridStats.position_quote}
          />
          <LabeledInput readOnly type="number" label="Base" value={state.gridStats.position_base} />
          <LabeledInput readOnly type="number" label="Price" value={state.positionPrice} />
        </Wrapper>
      </ColumnWrapper>

      <ColumnWrapper>
        <CountInfoRow>Trade Error</CountInfoRow>

        <Wrapper>
          <LabeledInput
            data-tooltip-id={PRICE_TOOLTIP}
            data-tooltip-content={state.sellErrorTotal ? `Total: ${state.sellErrorTotal}` : null}
            readOnly
            type="number"
            label="Sell Amount"
            value={state.gridStats.sell_error.amount}
          />
          <LabeledInput
            readOnly
            type="number"
            label="Sell Price"
            value={state.gridStats.sell_error.price}
          />
          <LabeledInput
            data-tooltip-id={PRICE_TOOLTIP}
            data-tooltip-content={state.buyErrorTotal ? `Total: ${state.buyErrorTotal}` : null}
            readOnly
            type="number"
            label="Buy Amount"
            value={state.gridStats.buy_error.amount}
          />
          <LabeledInput
            readOnly
            type="number"
            label="Buy Price"
            value={state.gridStats.buy_error.price}
          />
          <Tooltip id={PRICE_TOOLTIP} variant="info" />
        </Wrapper>
      </ColumnWrapper>

      <ColumnWrapper>
        <CountInfoRow>Profit</CountInfoRow>

        <Wrapper>
          <LabeledInput readOnly type="number" label="Quote" value={state.gridStats.profit_quote} />
          <LabeledInput readOnly type="number" label="Base" value={state.gridStats.profit_base} />
        </Wrapper>
      </ColumnWrapper>

      <Loader show={state.isLoading} />
    </PanelContainer>
  );
});
