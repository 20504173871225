import { observer } from "mobx-react-lite";
import { LiquidityContext } from "src/context/CEX/Dashboard/v2/Liquidity";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { DashboardV2Widget, getWidgetInfo, getWidgetTitle } from "../../shared/constants";
import { useLiquidityData } from "../BuyLiquidityChart";
import { LiquidityBar, LiquidityBarProps } from "../shared/LiquidityBar";
import { LiquidityCard } from "../shared/style";

export interface SellLiquidityChartProps
  extends Pick<LiquidityBarProps, "zoomDefault">,
    CardProps {}

export const SellLiquidityChart = observer(({ zoomDefault, ...props }: SellLiquidityChartProps) => {
  const { time, sell, price, loading, priceRange } = useLateInitContext(LiquidityContext.Context);

  const data = useLiquidityData({ time, data: sell, price });
  const barProps = { data, zoomDefault };
  return (
    <LiquidityCard
      title={getWidgetTitle(DashboardV2Widget.SellLiquidity)}
      info={getWidgetInfo(DashboardV2Widget.SellLiquidity)}
      loading={loading}
      {...props}
    >
      <LiquidityBar priceRange={priceRange} {...barProps} />
    </LiquidityCard>
  );
});
