import styled, { css } from "styled-components";
import { SettingsTabs } from "../../CEX/CEXBotSettings/shared";

export interface ContainerProps {
  showNotes?: boolean;
  showTables?: boolean;
}

const getDesktopGrid = ({ showNotes, showTables }: ContainerProps) => {
  const notesArea = !showNotes ? "PnL" : "notes";

  if (showTables) {
    return css`
      grid-template-columns: repeat(2, minmax(auto, 1fr)) minmax(auto, 2fr);
      grid-template-rows: 120px 150px repeat(2, 240px);
      grid-template-areas:
        "bot      bot         gasWallets"
        "PnL      ${notesArea}  gasWallets"
        "graphs   graphs    vaults"
        "graphs   graphs    hashes";
    `;
  }
  return css`
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    grid-template-rows: 120px 150px repeat(2, 240px);
    grid-template-areas:
      "bot      bot     "
      "PnL      ${notesArea} "
      "graphs   graphs   "
      "graphs   graphs   ";
  `;
};

const getTabletGrid = ({ showNotes, showTables }: ContainerProps) => {
  const notesArea = !showNotes ? "PnL" : "notes";
  if (showTables) {
    return css`
      @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-template-rows: 120px 150px repeat(4, 200px);

        grid-template-areas:
          "bot      bot       "
          "PnL      ${notesArea}"
          "tables   tables    "
          "tables   tables    "
          "graphs   graphs    "
          "graphs   graphs    ";
      }
    `;
  }
  return css`
    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(2, minmax(auto, 1fr));
      grid-template-rows: 120px 150px repeat(2, 200px);

      grid-template-areas:
        "bot      bot       "
        "PnL      ${notesArea}"
        "graphs   graphs    "
        "graphs   graphs    ";
    }
  `;
};

const getMobileGrid = ({ showNotes, showTables }: ContainerProps) => {
  const emptyRow = { area: "", row: "" };
  const notesRow = showNotes ? { area: '"notes"', row: "120px" } : emptyRow;
  const tablesRow = showTables
    ? {
        area: `
        "tables "
        "tables "`,
        row: "repeat(2, 200px)",
      }
    : emptyRow;

  return css`
    @media screen and (max-width: 500px) {
      grid-template-columns: 100%;
      grid-template-rows: 100px 150px ${notesRow.row} ${tablesRow.row} repeat(2, 200px);

      grid-template-areas:
        "bot    "
        "PnL    "
        ${notesRow.area}
        ${tablesRow.area}
        "graphs "
        "graphs ";
    }
  `;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;

  display: grid;

  gap: 15px;

  flex: 1 1 auto;

  ${getDesktopGrid}
  ${getTabletGrid}
  ${getMobileGrid}
`;

export const TablesTabs = styled(SettingsTabs)`
  grid-area: tables;
`;
