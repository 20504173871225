import { SwitchMenu } from "src/components/shared/SwitchMenu";
import { Label } from "src/components/shared/SwitchMenu/MenuItem/style";
import { StyledProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";
import { Card } from "src/components/BotsContent/CEX/shared/Card";
import { MainContentWrapper } from "src/components/BotsContent/CEX/shared/Card/style";
import { Container as InfoPanelSectionContainer } from "../ProfitLossInfoPanel/InfoPanelSection/style";
import { Container as InfoPanelContainer } from "../ProfitLossInfoPanel/style";

export interface PNLCardProps {
  orientation?: "horizontal" | "vertical";
}

interface StyledPNLCardProps extends StyledProps<PNLCardProps> {}

export const PNLCard = styled(Card)<StyledPNLCardProps>`
  ${({ $orientation }) => {
    if ($orientation === "vertical") {
      return css`
        flex-direction: column;
        gap: 8px;

        ${InfoPanelContainer} {
          flex-direction: row;
          ${InfoPanelSectionContainer} {
            padding: 8px;
            align-items: center;
          }
        }
      `;
    }
    return css`
      ${MainContentWrapper} {
        width: calc(100% - 120px);
      }
    `;
  }}
`;

export const ModeSwitch = styled(SwitchMenu).attrs({ direction: "row" })`
  max-width: 150px;
  height: 24px;

  ${Label} {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;
