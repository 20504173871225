import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RoadMapSteps } from "src/components/BotCreating/DEX";
import { RoadMap } from "src/components/BotCreating/DEX/RoadMap";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { Loader } from "src/components/shared/Loader";
import { CreateStrategyContext } from "src/context/CEX/ExpertSystem";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useStep } from "src/hooks/useStep";
import { IStrategyCreator } from "src/modules/expertSystem";
import {
  EMPTY_STRATEGY_CREATOR,
  StrategyCreatingTabs,
} from "src/state/expertSystem/strategies/CreateStrategyStore";
import { ModalPanel } from "../../../shared";
import { useButtonSize } from "../../../shared/hooks/useButtonSize";
import { ActionsStep } from "./ActionsStep";
import { ConditionsStep } from "./ConditionsStep";
import { SettingsStep } from "./SettingsStep";
import * as styles from "./style";

type TabRoadMapSteps<T extends StrategyCreatingTabs = StrategyCreatingTabs> =
  T extends StrategyCreatingTabs ? RoadMapSteps<T> : never;

const STRATEGY_CREATING_STEPS: TabRoadMapSteps[] = [
  { value: "Settings", label: "Settings" },
  { value: "Conditions", label: "Conditions" },
  { value: "Actions", label: "Actions" },
];

const STRATEGY_CREATING_STEPS_CONTENT = [
  <SettingsStep key="settings_step" />,
  <ConditionsStep key="condition_step" />,
  <ActionsStep key="action_step" />,
];

const FORM_ID = "strategy_create_form";

export const CreateStrategy = observer(() => {
  const state = useLateInitContext(CreateStrategyContext);
  const { close: closePopup } = useLateInitContext(PopupContext);

  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<IStrategyCreator>({
    defaultValues: EMPTY_STRATEGY_CREATOR,
  });

  const { trigger, handleSubmit } = methods;

  const formContent = STRATEGY_CREATING_STEPS_CONTENT;
  const formSteps = STRATEGY_CREATING_STEPS;
  const stepsCount = formSteps.length;
  const lastStep = stepsCount - 1;

  const [step, { nextStep, prevStep, canGoToPrev }] = useStep(stepsCount);

  const switchNextStep = () => {
    const nextModule = formSteps[step].value;
    if (step !== lastStep) {
      return () => state.nextStepHandler(nextModule, trigger, nextStep);
    }
  };

  useEffect(() => {
    state.loadData();
  }, [state]);

  const btnSize = useButtonSize();

  return (
    <ModalPanel title="Create new strategy">
      <RoadMap items={STRATEGY_CREATING_STEPS} currentStep={step} />

      <FormProvider {...methods}>
        <styles.FormContent id={FORM_ID} onSubmit={handleSubmit(state.submitHandler(closePopup))}>
          {formContent[step]}

          <Loader show={state.isLoading} />
        </styles.FormContent>
      </FormProvider>

      <styles.Footer>
        <OutlineButton size={btnSize} onClick={() => close()}>
          Cancel
        </OutlineButton>

        <styles.ButtonWrapper>
          {canGoToPrev ? (
            <OutlineButton size={btnSize} onClick={prevStep}>
              Step back
            </OutlineButton>
          ) : null}

          {step !== lastStep ? (
            <OutlineButton size={btnSize} onClick={switchNextStep()}>
              Next
            </OutlineButton>
          ) : (
            <SubmitButton size={btnSize} form={FORM_ID} onClick={switchNextStep()}>
              Create
            </SubmitButton>
          )}
        </styles.ButtonWrapper>
      </styles.Footer>
    </ModalPanel>
  );
});
