import { useCallback } from "react";
import { ChildrenProps, IDisposable } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import { useLocalStoreCreator } from "src/hooks/useLocalStore";
import { ITooltipSeriesDataProvider, ITooltipState, TooltipStore } from "src/state/Graph/Tooltip";
import { TooltipSeriesData } from "src/state/Graph/Tooltip/types";

export const TooltipContext = createLateInitContext<ITooltipState<TooltipSeriesData>>();

export const useTooltipContext = <T extends TooltipSeriesData>(): ITooltipState<T> => {
  const ctx = useLateInitContext(TooltipContext);
  return ctx as ITooltipState<T>;
};

export interface TooltipProviderProps<T extends TooltipSeriesData> extends ChildrenProps {
  seriesDataProvider: ITooltipSeriesDataProvider<T>;
}

export const TooltipProvider = <T extends TooltipSeriesData>({
  children,
  seriesDataProvider,
}: TooltipProviderProps<T>) => {
  const tooltipStoreCreator = useCallback(() => {
    const store: ITooltipState<T> & IDisposable = new TooltipStore(seriesDataProvider);
    return store;
  }, [seriesDataProvider]);

  const state: ITooltipState<T> = useLocalStoreCreator(tooltipStoreCreator);

  return <TooltipContext.Provider value={state}>{children}</TooltipContext.Provider>;
};
