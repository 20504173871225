import { observer } from "mobx-react-lite";
import { useState } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import Icons from "src/icons/Icons";
import { ICEXBotInfoWithMarket, IDEXBotInfoWithMarket } from "src/modules/bots";
import { SelectorValue } from "src/modules/shared";
import * as styles from "./style";

interface BotProps {
  bot: ICEXBotInfoWithMarket | IDEXBotInfoWithMarket;
  parties: SelectorValue[];
  clickMoveHandler: (bot_uuid: string, party: string) => () => void;
}

export const Bot = observer(({ bot, parties, clickMoveHandler }: BotProps) => {
  const [party, setParty] = useState("");

  const setSelect = (data: SelectorValue | null) => {
    setParty(String(data?.value ?? ""));
  };

  return (
    <styles.RowBot>
      <LabeledInput label="" readOnly value={bot.market} onChange={() => {}} />

      <styles.Wrapper>
        <LabeledSelector
          isClearable={false}
          menuPosition="fixed"
          options={parties}
          value={{ value: party, label: party } as SelectorValue}
          onChange={setSelect}
        />

        <IconButton onClick={clickMoveHandler(bot.bot_uuid, party)}>{Icons.moveBot()}</IconButton>
      </styles.Wrapper>
    </styles.RowBot>
  );
});
