import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import VaultsStore from "src/state/DEXV2/DEXV2Stats/Vaults";
import { DEXV2BotContext } from "../../DEXV2Bots/DEXV2Bot";

export const VaultsContext = createLateInitContext<VaultsStore>();

type VaultsProviderProps = ChildrenProps;

export const VaultsProvider = ({ children }: VaultsProviderProps) => {
  const botState = useLateInitContext(DEXV2BotContext);

  const store = useLocalStore(VaultsStore, {
    botInfoProvider: botState,
    chainInfoProvider: botState,
    tradePairProvider: botState,
    tradePairPriceProvider: botState.tradePairPriceProvider,
  });

  return <VaultsContext.Provider value={store}>{children}</VaultsContext.Provider>;
};
