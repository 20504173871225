import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { RegisterBotRequest, registerBot } from "src/api/bots/DEXV2/create";
import { getParties } from "src/api/userManager/partiesAPI";
import { TabStepNavigationAction } from "src/components/BotCreating/DEX";
import { createConstraintModalStyledText } from "src/components/BotsContent/CEX/CEXBotSettings/utils";
import { SelectorProps } from "src/components/shared/Forms/Selectors";
import { AddressHelper__factory } from "src/contracts/factories/AddressHelper__factory";
import { ContractsMap, isWalletAddress } from "src/helpers/chain/contracts";
import { getData, getPathAndKey, getTargetValueByPath, setData } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
  SelectorHandler,
  Validator,
} from "src/helpers/forms/types";
import { NonArrayObject, ObjectPathValue } from "src/helpers/forms/types/NestedObject";
import { makeLoggable } from "src/helpers/logger";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import {
  ExcludeStrict,
  Extends,
  IDisposable,
  KeysOfType,
  Nullish,
  entries,
} from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import contracts from "src/json/contracts.json";
import { DEXV2CreatingBot, DEXV2ExchangeVersion } from "src/modules/bots";
import { SelectorValue } from "src/modules/shared";
import { FieldError, FieldErrorProps } from "src/state/CEX/CEXSettings";
import WindowConsent from "src/state/WindowConsent";
import { IChainMetaProvider } from "src/state/chain/ChainMetaStore";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { ExchangeInfo } from "src/state/chain/ChainsInfoStore";
import {
  graterThan,
  greaterThanNumberKey,
  isEthAddress,
  isInteger,
  isNumber,
  required,
  shortThan,
  smallerThan,
  smallerThanNumberKey,
  strictlyGreaterThan,
  strictlySmallerThan,
  validateData,
} from "src/validation-schemas";
import { MODE_SWITCH_ITEMS, TRIGGER_COMPARE_ITEMS } from "../../constants";
import {
  IWalletAddressValidation,
  IWalletsState,
  IWalletsValidation,
} from "../../shared/WalletsStore";
import BlacklistWalletsStore, {
  IBlacklistWalletsProvider,
} from "../../shared/settings/BlacklistWalletsStore";
import SwapWalletsStore from "../../shared/settings/SwapWalletsStore";
import TransferWalletsStore, {
  ITransferWalletsProvider,
} from "../../shared/settings/TransferWalletsStore";
import CounterStrategiesStore, {
  ICounterStrategies,
} from "../../shared/settings/counter/CounterStrategiesStore";
import { DEXV2CounterStrategy, InputFieldProps, LimitCompare, SwitchOption } from "../../types";
import { formatPoolPercent } from "../../utils";
import {
  COUNTER_VALIDATION as SETTINGS_COUNTER_VALIDATION,
  SLIPPAGE_VALIDATION,
} from "../../validation";
import CreateConstraintsStore, {
  ICreateConstraints,
  ICreateConstraintsStateProvider,
  ICreateConstraintsValidation,
} from "./CreateConstraintsStore";

interface DEXExchange {
  spender: string;
  network: string;
  walletLink: string;
  hashLink: string;
}

export interface DEXExchangesList {
  [key: string]: DEXExchange;
}

export interface NodeAddress {
  name: string;
  id: string;
  rpc: string;
  rpc_moralis: string;
}

export interface NodesAddressesList {
  [key: string]: NodeAddress;
}

type ExchangesVersionsMap = Partial<Record<DEXV2ExchangeVersion, ExchangeInfo[]>>;

type ChainExchangesVersionsMap = Partial<Record<string, ExchangesVersionsMap>>;

export type DEXV2CreatingKeys = FormDataKeys<DEXV2CreatingBot>;

type FormSelectors =
  | Extends<DEXV2CreatingKeys, "base_data.party" | "base_data.exchange" | "main_data.pool_percent">
  | "network"
  | "version";

interface FormSelectorsProps
  extends Pick<
    SelectorProps<SelectorValue, false, any>,
    "options" | "value" | "onChange" | "isDisabled"
  > {}

interface ConstraintsValidationParams extends ICreateConstraintsValidation {}

interface StepHandlerParams {
  nextStep: () => void;
  constraints?: ConstraintsValidationParams;
}

export type DEXV2CreatingModuleNames = KeysOfType<DEXV2CreatingBot, NonArrayObject>;

type DEXV2CreatingModule<M extends DEXV2CreatingModuleNames> = Pick<DEXV2CreatingBot, M>;

export const DEXV2_CREATE_MODULES: readonly DEXV2CreatingModuleNames[] = [
  "base_data",
  "main_data",
  "limit_data",
  "volume_data",
  "counter_data",
] as const;

const INITIAL_DEXV2_CREATING: DEXV2CreatingBot = {
  base_data: {
    chain_id: 0,
    party: "",
    bot_name: "",
    quote: "",
    base: "",
    exchange: "",
    withdrawer: "",
    transfer_oracle_wallets: [],
    swap_oracle_wallets: [],
    receiver_num: "",
  },
  main_data: {
    limit_executors_num: 3,
    volume_executors_num: 3,
    counter_executors_num: 3,
    tt_buy_fee: 0,
    tt_sell_fee: 0,
    gas_limit: 500000,
    pool_percent: 0,
    stable: "",
  },
  volume_data: {
    on: false,
    use_receiver: false,
    period: 5,
    min_trades: 3,
    max_trades: 5,
    buy_percent: 50,
    min_amount: 1,
    max_amount: 3,

    slippage: 0.5,
    gas_mult: 0,
    gas_price_limit: 0,
  },
  limit_data: {
    on: false,
    use_receiver: false,
    mod: "buy",
    trigger_compare: "less",
    period: 60,
    price: 1,
    min_amount: 1,
    max_amount: 1,

    slippage: 0.5,
    gas_mult: 0,
    gas_price_limit: 0,
  },
  counter_data: {
    on: false,
    cumulative: true,
    black_listed_wallets: [],
    data: [],
    gas_mult: 0,
    gas_price_limit: 0,
    slippage: 0.5,
  },
};

export interface DEXVersionOptions extends Omit<SelectorValue, "id"> {
  value: DEXV2ExchangeVersion;
  label: string;
}

export const DEX_VERSION_OPTIONS: DEXVersionOptions[] = [
  { value: DEXV2ExchangeVersion.V2, label: "v2" },
  { value: DEXV2ExchangeVersion.V3, label: "v3" },
];

const BASE_VALIDATION: FormValidation<DEXV2CreatingModule<"base_data">> = {
  "base_data.party": required(),
  "base_data.bot_name": [required(), shortThan(20)],
  "base_data.quote": [required(), isEthAddress()],
  "base_data.base": [required(), isEthAddress()],
  "base_data.exchange": required(),
  "base_data.withdrawer": [required(), isEthAddress()],
  "base_data.receiver_num": [required(), isInteger(), smallerThan(10, "Receivers must <= 10")],
};

const executorsValidation: Validator[] = [
  required(),
  isInteger(),
  strictlyGreaterThan(0, "Executors must be positive"),
  smallerThan(50, "Executors must <= 50"),
];

const MAIN_VALIDATION: FormValidation<DEXV2CreatingModule<"main_data">> = {
  "main_data.limit_executors_num": executorsValidation,
  "main_data.volume_executors_num": executorsValidation,
  "main_data.counter_executors_num": executorsValidation,
  "main_data.tt_buy_fee": required(),
  "main_data.tt_sell_fee": required(),
  "main_data.gas_limit": [
    required(),
    isNumber(),
    strictlyGreaterThan(21_000, "Gas Limit must be greater than 21000"),
    strictlySmallerThan(20_000_000, "Gas Limit must be less than 20000000"),
  ],
  "main_data.pool_percent": [required(), isNumber()],
  "main_data.stable": [required(), isEthAddress()],
};

const VOLUME_VALIDATION: FormValidation<DEXV2CreatingModule<"volume_data">> = {
  "volume_data.period": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "Period must be positive"),
    strictlySmallerThan(1480, "Period must be less than 1480"),
  ],
  "volume_data.min_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    smallerThanNumberKey("volume_data.max_amount", "Min Amount must be smaller than Max Amount"),
  ],
  "volume_data.max_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    greaterThanNumberKey("volume_data.min_amount", "Min Amount must be greater than Max Amount"),
  ],
  "volume_data.min_trades": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    smallerThanNumberKey("volume_data.max_trades", "Min Trades must be smaller than Max Trades"),
  ],
  "volume_data.max_trades": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    strictlySmallerThan(100, "Max Trades must be less than 100"),
    greaterThanNumberKey("volume_data.min_trades", "Min Trades must be greater than Max Trades"),
  ],
  "volume_data.buy_percent": required(),
  "volume_data.slippage": SLIPPAGE_VALIDATION,
  "volume_data.gas_mult": required(),
  "volume_data.gas_price_limit": [required(), isNumber(), graterThan(0, "Max Gas must be >= 0")],
};

const LIMIT_VALIDATION: FormValidation<DEXV2CreatingModule<"limit_data">> = {
  "limit_data.period": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "Period must be positive"),
    strictlySmallerThan(3600, "Period must be less than 3600"),
  ],
  "limit_data.mod": required(),
  "limit_data.trigger_compare": required(),
  "limit_data.price": [required(), isNumber(), strictlyGreaterThan(0, "PriceUSD must be positive")],
  "limit_data.min_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    smallerThanNumberKey("limit_data.max_amount", "Min Amount must be smaller than Max Amount"),
  ],
  "limit_data.max_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    greaterThanNumberKey("limit_data.min_amount", "Min Amount must be greater than Max Amount"),
  ],
  "limit_data.slippage": SLIPPAGE_VALIDATION,
  "limit_data.gas_mult": required(),
  "limit_data.gas_price_limit": [required(), isNumber(), graterThan(0, "Max Gas must be >= 0")],
};

const COUNTER_VALIDATION: FormValidation<DEXV2CreatingModule<"counter_data">> = {
  ...SETTINGS_COUNTER_VALIDATION,
};

const dexV2CreateToRequest = ({
  base_data: { withdrawer, ...baseSettings },
  ...otherSettings
}: DEXV2CreatingBot): RegisterBotRequest => ({
  ...otherSettings,
  base_data: {
    ...baseSettings,
    withdrawers: [withdrawer],
  },
});

export interface IDEXV2CreateDataProvider {
  get data(): DEXV2CreatingBot;
}

export interface IDEXV2Create extends IDEXV2CreateDataProvider {
  get transferWalletsState(): IWalletsValidation;
  get swapWalletsState(): IWalletsValidation;
  get blacklistWalletsState(): IWalletsValidation;
  isWalletAddress: (address: string) => Promise<Record<string, boolean>>;
}

export default class DEXV2CreatingStore
  implements
    IDisposable,
    ITransferWalletsProvider,
    IWalletAddressValidation,
    IBlacklistWalletsProvider,
    ICreateConstraintsStateProvider
{
  data: DEXV2CreatingBot = INITIAL_DEXV2_CREATING;

  private _transferWalletsStore: TransferWalletsStore;

  private _swapWalletsStore: SwapWalletsStore;

  private _blacklistWalletsStore: BlacklistWalletsStore;

  handlers: FormHandlers<DEXV2CreatingBot> = {};

  loading = false;

  onChangeValidate: Partial<Record<DEXV2CreatingKeys, DEXV2CreatingKeys[]>> = {
    "volume_data.min_amount": ["volume_data.max_amount"],
    "volume_data.max_amount": ["volume_data.min_amount"],
    "volume_data.min_trades": ["volume_data.max_trades"],
    "volume_data.max_trades": ["volume_data.min_trades"],
    "limit_data.min_amount": ["limit_data.max_amount"],
    "limit_data.max_amount": ["limit_data.min_amount"],
  };

  validation: FormValidation<DEXV2CreatingBot> = {
    ...BASE_VALIDATION,
    ...MAIN_VALIDATION,
    ...VOLUME_VALIDATION,
    ...LIMIT_VALIDATION,
    ...COUNTER_VALIDATION,
  };

  warningValidation: FormValidation<DEXV2CreatingBot> = {};

  errors: FormErrors<DEXV2CreatingBot> = {};

  modulesValidationKeys: Record<DEXV2CreatingModuleNames, DEXV2CreatingKeys[]>;

  private _getModuleValidationKeys = (module: DEXV2CreatingModuleNames) => {
    const moduleKey = `${module}` as const;

    const validationKeys = Object.keys(this.validation) as DEXV2CreatingKeys[];

    const moduleValidationKeys = validationKeys.filter((key) => key.startsWith(moduleKey));

    switch (moduleKey) {
      case "base_data": {
        return [...moduleValidationKeys, "main_data.pool_percent"];
      }
      case "main_data": {
        return moduleValidationKeys.filter((key) => key !== "main_data.pool_percent");
      }
      default: {
        return moduleValidationKeys;
      }
    }
  };

  private _contracts: ContractsMap = contracts;

  private _chainProvider: IChainProvider;

  private _chainMetaProvider: IChainMetaProvider;

  private _parties: string[] = [];

  private _dexVersion: DEXV2ExchangeVersion | null = null;

  private _counterStrategiesState: ICounterStrategies;

  private _constraintsStore: ICreateConstraints;

  constructor(chainProvider: IChainProvider, chainMetaProvider: IChainMetaProvider) {
    this.modulesValidationKeys = Object.fromEntries(
      DEXV2_CREATE_MODULES.map((module) => [module, this._getModuleValidationKeys(module)])
    ) as Record<DEXV2CreatingModuleNames, DEXV2CreatingKeys[]>;

    makeAutoObservable(this, { getSelectorProps: false });

    this._transferWalletsStore = new TransferWalletsStore(this, this);

    this._swapWalletsStore = new SwapWalletsStore(this, this);

    this._blacklistWalletsStore = new BlacklistWalletsStore(this);

    this._chainProvider = chainProvider;

    this._chainMetaProvider = chainMetaProvider;

    this._counterStrategiesState = new CounterStrategiesStore(this);

    this._constraintsStore = new CreateConstraintsStore({
      stateProvider: this,
      chainProvider,
    });

    makeLoggable<any>(this, {
      _selectedChainId: true,
      _selectedChain: true,
      _chainsOptions: true,
      chainMeta: true,
      selectNetWork: true,
      netWorksList: true,
      data: true,
      errors: true,
      _addressHelper: true,
      _rpcProvider: true,
    });
  }

  private get _chainsInfo() {
    return this._chainProvider.chainsInfo;
  }

  private get _dexVersionExchanges(): ChainExchangesVersionsMap {
    const { chainExchanges } = this._chainsInfo;

    const chainVersionExchangesEntries = entries(chainExchanges).map(([chainId, exchanges]) => {
      if (!chainId || !exchanges) return null;

      const versionExchanges = Object.values(exchanges).reduce((versionsMap, exchange) => {
        if (exchange) {
          const { version } = exchange;

          const currentVersionExchanges = versionsMap[version] ?? [];
          currentVersionExchanges.push(exchange);

          // eslint-disable-next-line no-param-reassign
          versionsMap[version] = currentVersionExchanges;
        }
        return versionsMap;
      }, {} as ExchangesVersionsMap);

      return [chainId, versionExchanges] as const;
    }, {});

    const chainVersionExchanges = Object.fromEntries(filterBoolean(chainVersionExchangesEntries));

    return chainVersionExchanges;
  }

  private get _chainVersionExchangesInfo() {
    const versionExchanges = this._dexVersionExchanges[this._selectedChainId];
    if (!versionExchanges) return [];
    const version = this._dexVersion;
    if (!version) return [];

    const exchanges = versionExchanges[version];
    if (!exchanges) return [];

    return exchanges;
  }

  private get _chainVersionExchanges() {
    const exchanges = this._chainVersionExchangesInfo;

    return exchanges.map(({ name }) => name);
  }

  private get _currentExchangeInfo() {
    const exchange = this._getDataByKey("base_data.exchange");

    const exchanges = this._chainVersionExchangesInfo;

    return exchanges.find(({ name }) => name === exchange);
  }

  private get _currentExchangePoolPercents() {
    const exchange = this._currentExchangeInfo;

    return exchange?.poolPercents ?? [0];
  }

  private get _poolPercentOptions() {
    return this._currentExchangePoolPercents.map((percent) => ({
      label: formatPoolPercent(percent),
      value: percent,
    }));
  }

  private get _chains() {
    return filterBoolean(Object.values(this._chainsInfo.chains));
  }

  private get _chainsOptions(): SelectorValue[] {
    return this._chains.map(({ id, name }) => ({ id, value: name, label: name }));
  }

  private _setChainId = (chainId: string) => {
    this._setDataByKey("base_data.chain_id", +(chainId ?? 0));
    this._chainProvider.setChainId(chainId);

    this._onChainIdChanged(chainId);
  };

  private _onChainIdChanged = (_chainId: string) => {
    this._clearDexVersion();
  };

  private get _selectedChainId() {
    return this._chainProvider.chainID;
  }

  private get _selectedChain() {
    return this._chainProvider.currentChain;
  }

  private get _chainMetaMap() {
    return this._chainMetaProvider.chainMetaMap;
  }

  get chainMeta() {
    if (!this._selectedChainId) return undefined;
    return this._chainMetaMap.get(this._selectedChainId);
  }

  private get _provider() {
    return this._chainProvider.multicallProvider;
  }

  private get _addressHelper() {
    if (!this._provider || !this._selectedChainId) return undefined;

    const chainId = this._selectedChainId;

    const contractAddress = this._contracts[chainId]?.[0].contracts.AddressHelper;
    if (!contractAddress) return undefined;

    return AddressHelper__factory.connect(contractAddress, this._provider);
  }

  private get _versionsOptions() {
    return DEX_VERSION_OPTIONS;
  }

  private _setDexVersion = (version: DEXV2ExchangeVersion | null) => {
    this._dexVersion = version;

    this._onDexVersionChanged(version);
  };

  private _clearDexVersion = () => {
    this._setDexVersion(null);
  };

  private _onDexVersionChanged = (version: DEXV2ExchangeVersion | null) => {
    this._clearExchanges();
    this._clearPoolPercent(version);
  };

  private _clearExchanges = () => {
    this._setDataByKey("base_data.exchange", "");
  };

  private _clearPoolPercent = (version: DEXV2ExchangeVersion | null) => {
    const value = version !== DEXV2ExchangeVersion.V3 ? 0 : "";
    this._setDataByKey("main_data.pool_percent", value);
  };

  get isPoolPercentVisible() {
    return this._dexVersion === DEXV2ExchangeVersion.V3;
  }

  private _syncQuoteStable = () => {
    const quote = this._getDataByKey("base_data.quote");
    if (!quote) return;

    const currentStable = this._getDataByKey("main_data.stable");
    if (currentStable) return;

    this._setDataByKey("main_data.stable", quote);
  };

  private _syncStepData = (module: DEXV2CreatingModuleNames) => {
    switch (module) {
      case "base_data": {
        this._syncQuoteStable();
        break;
      }
      default: {
        break;
      }
    }
  };

  switchOptions = (
    key: "mod" | "trigger_compare" | "input_event" | "output_event"
  ): SwitchOption[] => {
    switch (key) {
      case "mod":
      case "output_event":
      case "input_event": {
        return MODE_SWITCH_ITEMS.slice();
      }
      case "trigger_compare": {
        return TRIGGER_COMPARE_ITEMS.slice();
      }
    }
  };

  setSwitchOption = (key: "trigger_compare") => (value: string) => {
    switch (key) {
      case "trigger_compare": {
        this._setDataByKey("limit_data.trigger_compare", value as LimitCompare);
      }
    }
  };

  get transferWallets() {
    return this.data.base_data.transfer_oracle_wallets;
  }

  setTransferWallets = (wallets: string[]) => {
    this.data.base_data.transfer_oracle_wallets = wallets;
  };

  private get _transferWalletsState() {
    return this._transferWalletsStore.transferWalletsState;
  }

  get transferWalletsState(): IWalletsState & IWalletsValidation {
    return this._transferWalletsState;
  }

  get swapWallets() {
    return this.data.base_data.swap_oracle_wallets;
  }

  setSwapWallets = (wallets: string[]) => {
    this.data.base_data.swap_oracle_wallets = wallets;
  };

  private get _swapWalletsState(): IWalletsState & IWalletsValidation {
    return this._swapWalletsStore.swapWalletsState;
  }

  get swapWalletsState() {
    return this._swapWalletsState;
  }

  get blacklistWallets() {
    return this.data.counter_data.black_listed_wallets;
  }

  setBlacklistWallets = (wallets: string[]) => {
    this.data.counter_data.black_listed_wallets = wallets;
  };

  get blacklistWalletsState() {
    return this._blacklistWalletsStore.blacklistWalletsState;
  }

  get counterStrategies() {
    return this.data.counter_data.data ?? [];
  }

  get counterStrategiesError() {
    return this.errors.counter_data?.data;
  }

  setCounterStrategies = (strategies: DEXV2CounterStrategy[]) => {
    this.data.counter_data.data = strategies;
  };

  saveCounterStrategies = async () => true;

  updateCounterStrategy = async (strategy: DEXV2CounterStrategy) =>
    await this._counterStrategiesState.updateCounterStrategy(strategy);

  deleteCounterStrategy = async (index: number) =>
    await this._counterStrategiesState.deleteCounterStrategy(index);

  toggleCounterStrategyStatus = async (index: number) =>
    await this._counterStrategiesState.toggleActiveCounterStrategy(index);

  setSelectedCounterStrategy = (index: Nullish<number>) => {
    this._counterStrategiesState.setSelectedCounterStrategy(index);
  };

  get selectedCounterStrategy() {
    return this._counterStrategiesState.selectedCounterStrategy;
  }

  private _setPartiesList(parties: string[]) {
    this._parties = parties;
  }

  getPartiesList = async () => {
    try {
      const { data, isError } = await getParties();

      if (!isError) {
        this._setPartiesList(data);
      } else {
        this._setPartiesList([]);
      }
    } catch (err) {
      this._setPartiesList([]);
      logError(err);
    }
  };

  setLoading = (bool: boolean) => {
    this.loading = bool;
  };

  private _validateOnChangeKey = (key: DEXV2CreatingKeys) => {
    if (!this.validation[key]) return;

    const validateKeys =
      key in this.onChangeValidate
        ? ([key, ...this.onChangeValidate[key]!] as DEXV2CreatingKeys[])
        : [key];

    return this.validate(validateKeys);
  };

  private _getErrorByKey = (key: DEXV2CreatingKeys) =>
    getData(this.errors as Required<FormErrors<DEXV2CreatingBot>>, key);

  private _getDataByKey = <K extends DEXV2CreatingKeys = DEXV2CreatingKeys>(key: K) =>
    getData(this.data, key);

  private _setDataByKey = <K extends DEXV2CreatingKeys>(
    key: K,
    value: ObjectPathValue<DEXV2CreatingBot, K>
  ) => {
    setData(this.data, key, value);
  };

  getInputProps = (
    key: ExcludeStrict<
      DEXV2CreatingKeys,
      | "volume_data.on"
      | "limit_data.on"
      | "counter_data.on"
      | "base_data.transfer_oracle_wallets"
      | "volume_data.use_receiver"
      | "limit_data.use_receiver"
      | "counter_data.data"
      | "counter_data.cumulative"
    >
  ): InputFieldProps => ({
    errorHint: this._getErrorByKey(key),
    value: this._getDataByKey(key),
    onChange: this.getHandler(key),
  });

  getHandler = (key: DEXV2CreatingKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this.data, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (key) {
          case "main_data.tt_buy_fee":
          case "main_data.tt_sell_fee":
          case "volume_data.buy_percent":
          case "volume_data.gas_mult":
          case "limit_data.gas_mult":
          case "counter_data.gas_mult": {
            const newValue = getChangeEventValue(e);
            if (+newValue <= 100) {
              targetData[endKey] = newValue;
            }
            break;
          }
          case "limit_data.trigger_compare":
          case "limit_data.mod": {
            targetData[endKey] = getChangeEventValue(e, true);
            break;
          }
          default: {
            targetData[endKey] = getChangeEventValue(e);
          }
        }

        this._validateOnChangeKey(key);
      };
    }
    return this.handlers[key]!;
  };

  private _selectorEnabled = (key: FormSelectors) => {
    switch (key) {
      case "version": {
        return Boolean(this._selectedChainId);
      }
      case "base_data.exchange": {
        return Boolean(this._dexVersion);
      }
      default: {
        return true;
      }
    }
  };

  private _selectorValue = computedFn((key: FormSelectors): SelectorValue | null => {
    switch (key) {
      case "version": {
        const version = this._dexVersion;
        const option = this._versionsOptions.find(({ value }) => value === version) ?? null;
        return option;
      }
      case "network": {
        const selectedChain = this._selectedChain;
        if (!selectedChain) return null;
        const { id, name } = selectedChain;
        return { id, value: name, label: name };
      }
      case "main_data.pool_percent": {
        const percent = this._getDataByKey(key);
        const option = this._poolPercentOptions.find(({ value }) => value === percent) ?? null;
        return option;
      }
      default: {
        const value = this._getDataByKey(key);
        if (!value) return null;
        return stringToSelectorValue(value);
      }
    }
  });

  private _getSelectorHandler = (key: FormSelectors): SelectorHandler => {
    switch (key) {
      case "version": {
        return (data) => {
          if (!data) {
            this._setDexVersion(null);
          } else {
            const value = String(data.value) as DEXV2ExchangeVersion;
            this._setDexVersion(value);
          }
        };
      }
      case "network": {
        return (data) => {
          if (!data || !data.id) return;
          const chainId = data.id;
          this._setChainId(`${chainId}`);
        };
      }
      case "main_data.pool_percent": {
        return (data) => {
          const value = data ? +data.value : "";
          this._setDataByKey(key, value);
        };
      }
      default: {
        return (data) => {
          if (!data) return;
          this._setDataByKey(key, String(data.value));
        };
      }
    }
  };

  private _selectorOptions = computedFn((key: FormSelectors): SelectorValue[] => {
    switch (key) {
      case "version": {
        return this._versionsOptions;
      }
      case "network": {
        return this._chainsOptions;
      }
      case "main_data.pool_percent": {
        return this._poolPercentOptions;
      }
      case "base_data.party": {
        return getSelectorList(this._parties);
      }
      case "base_data.exchange": {
        return getSelectorList(this._chainVersionExchanges);
      }
    }
  });

  getSelectorProps = (key: FormSelectors): FormSelectorsProps => ({
    value: this._selectorValue(key),
    onChange: this._getSelectorHandler(key),
    options: this._selectorOptions(key),
    isDisabled: !this._selectorEnabled(key),
  });

  validate = (validateKeys?: DEXV2CreatingKeys[]) =>
    validateData(this.validation, this.data, this.errors, validateKeys);

  private _submitAllSettings = async () => {
    this.setLoading(true);

    let uuid: string | undefined = undefined;
    try {
      const requestData = dexV2CreateToRequest(this.data);
      const { party } = this.data.base_data;

      const { isError, data } = await registerBot(party, requestData);

      if (!isError) {
        uuid = data.deployer_id;
        showSuccessMsg("Profile created successfully");
      }
    } finally {
      this.setLoading(false);
      // eslint-disable-next-line no-unsafe-finally
      return uuid;
    }
  };

  isWalletAddress = async (address: string | string[]): Promise<Record<string, boolean>> =>
    await isWalletAddress(address, this._addressHelper);

  private _getWarningByKey = (key: DEXV2CreatingKeys) =>
    this._constraintsStore.getWarningByKey(key);

  private _getConstraintsValidation = (
    module: Extends<DEXV2CreatingModuleNames, "base_data" | "main_data">
  ) => this._constraintsStore.getConstraintsValidation(module);

  getFieldError = (key: DEXV2CreatingKeys): FieldError | undefined => {
    const error = getData(this.errors as Required<FormErrors<DEXV2CreatingBot>>, key);
    if (error) return { type: "error", message: error };

    const constraintError = this._getWarningByKey(key);

    if (constraintError) return { type: "warning", message: constraintError };
  };

  getFieldErrorAsProps = (key: DEXV2CreatingKeys): FieldErrorProps | undefined => {
    const fieldError = this.getFieldError(key);
    if (!fieldError) return undefined;
    const { type, message } = fieldError;
    return { errorHint: message, errorType: type };
  };

  private _constraintsStepHandler = async ({
    nextStep,
    constraints: { validator, getValidationReport },
  }: Required<StepHandlerParams>) => {
    try {
      this.setLoading(true);

      await validator();

      this.setLoading(false);

      const { title, message } = getValidationReport();
      const styledMessage = createConstraintModalStyledText(message);

      WindowConsent.showWindow(title, styledMessage, nextStep);
    } catch (err) {
      logError(err);
    }
  };

  private _nextStepHandler =
    (module: DEXV2CreatingModuleNames, { nextStep, constraints }: StepHandlerParams) =>
    async () => {
      const valid = this.validate(this.modulesValidationKeys[module]);

      if (valid) {
        if (constraints) {
          return this._constraintsStepHandler({ nextStep, constraints });
        }

        nextStep();
      }
    };

  nextStepHandler = (module: DEXV2CreatingModuleNames, nextStep: () => void) => {
    const nextStepCb = () => {
      this._syncStepData(module);
      nextStep();
    };

    switch (module) {
      case "base_data":
      case "main_data": {
        return this._nextStepHandler(module, {
          nextStep: nextStepCb,
          constraints: this._getConstraintsValidation(module),
        });
      }
      default: {
        return this._nextStepHandler(module, { nextStep: nextStepCb });
      }
    }
  };

  private _submitHandler = async (nextStep: TabStepNavigationAction) => {
    const valid = this.validate();

    if (valid) {
      try {
        const uuid = await this._submitAllSettings();
        if (!uuid) return;
        nextStep(uuid);
      } catch (err) {
        logError(err);
      }
    }
  };

  finishStepHandler = (nextStep: TabStepNavigationAction) => () => {
    this._submitHandler(nextStep);
  };

  destroy = () => {};
}
