import { Headline3 } from "src/components/shared/Typography";
import styled from "styled-components";

export const DemoGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 15px;
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Title = styled(Headline3)``;
