import { observer } from "mobx-react-lite";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { UserGroupsContext } from "src/context/UserManager/UserGroups";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "../../../shared";

export const AddUserGroup = observer(() => {
  const {
    userGroupsState: { addUserGroupState: state },
  } = useLateInitContext(UserGroupsContext);

  return (
    <ModalPanel
      label="Add user group"
      show={state.isShown}
      close={state.closeModal}
      loading={state.isLoading}
    >
      <styles.AccessForm
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        onSubmit={state.submitHandler()}
      >
        <styles.FormContent>
          <styles.FormRow>
            <LabeledInput
              label="User group name"
              value={state.newUserGroup.name}
              onChange={state.getHandler("name")}
              errorHint={state.errors.name}
            />
          </styles.FormRow>
          <styles.FormRow>
            <LabeledSelector
              label="User group type"
              value={state.selectorValue("type")}
              options={state.selectorOptions("type")}
              onChange={state.getSelectorHandler("type")}
            />
            <LabeledSelector
              label="User group head"
              value={state.selectorValue("head")}
              options={state.selectorOptions("head")}
              onChange={state.getSelectorHandler("head")}
              isDisabled={!state.selectorEnabled("head")}
            />
          </styles.FormRow>
        </styles.FormContent>
        <styles.ButtonsWrapper>
          <OutlineButton type="submit" stretched>
            Add user group +
          </OutlineButton>
        </styles.ButtonsWrapper>
      </styles.AccessForm>
    </ModalPanel>
  );
});
