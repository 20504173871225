import { observer } from "mobx-react-lite";
import { ComponentType } from "react";
import { SeriesContext } from "src/context/Graph/Series";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Legend, LegendOwnProps, LegendProps } from "./Legend";

export interface ChartLegendProps extends Omit<LegendProps, keyof LegendOwnProps> {
  legend?: ComponentType<LegendProps>;
}

export const ChartLegend = observer(({ legend, ...props }: ChartLegendProps) => {
  const { legendSeriesData, toggleSeriesVisibility } = useLateInitContext(SeriesContext);

  const LegendComponent = legend ?? Legend;
  return (
    <LegendComponent data={legendSeriesData} toggleVisibility={toggleSeriesVisibility} {...props} />
  );
});
