import { makeAutoObservable } from "mobx";
import { fetchBalance } from "src/api/bots/CEX/exchange";
import { Balance } from "src/modules/exchange/balance";
import ExchangeStore from ".";

export default class BalancesStore {
  private _balances: Balance[] = [];

  accountState = "";

  isLoading = false;

  mainState: ExchangeStore;

  constructor(state: ExchangeStore) {
    this.mainState = state;

    this.mainState.setUpdHandlers("updBalances", this.downloadData);

    makeAutoObservable(this);
  }

  downloadData = () => {
    this._fetchBalance(this.mainState.currentAccID);
  };

  private _setAccountState = (state: string) => {
    this.accountState = state;
  };

  private _setBalances = (balances: Balance[]) => {
    this._balances = balances;
  };

  private _setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  private _resetBalances = () => {
    this._setBalances([]);

    this._setAccountState("");
  };

  get balances() {
    if (this._balances.length)
      return this._balances.slice().sort((a, b) => this._sortBalances(a, b));

    return [];
  }

  get quoteFree() {
    const findQuote = this._balances.find((el) => el.asset === this.mainState.quote);

    if (findQuote) return findQuote.free;

    return 0;
  }

  get baseFree() {
    const findQuote = this._balances.find((el) => el.asset === this.mainState.base);

    if (findQuote) return findQuote.free;

    return 0;
  }

  private _sortBalances = (a: Balance, b: Balance) => {
    const firstCheck = this._priorityBalanceCheck(a);
    const secondCheck = this._priorityBalanceCheck(b);

    return secondCheck - firstCheck;
  };

  private _priorityBalanceCheck = (a: Balance) => {
    if (this.mainState.quote.includes(a.asset)) return 2;
    if (this.mainState.base.includes(a.asset)) return 1;
    return 0;
  };

  private _fetchBalance = async (account_uuid: string) => {
    this._setLoading(true);

    try {
      const {
        data: { data },
        isError,
      } = await fetchBalance({
        account_uuid,
      });

      if (!isError) {
        this._setBalances(data);

        if (!data.length) {
          this._setAccountState("NO BALANCE");
        }
      } else {
        this._resetBalances();
      }
    } catch (error) {
      this._resetBalances();
    } finally {
      this._setLoading(false);
    }
  };
}
