import { observer } from "mobx-react-lite";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { SuggestionLabeledInput } from "src/components/shared/Forms/Variants";
import { IGridForm } from "src/state/CEX/liquidityTab/GridForm";
import * as styles from "./style";

export interface GridFormContentProps extends React.ComponentPropsWithoutRef<"div"> {
  state: IGridForm;
}

export const GridFormContent = observer(({ state, ...props }: GridFormContentProps) => (
  <styles.FormControlsContainer {...props}>
    <styles.FormControlsRow>
      <LabeledSelector
        label="Account"
        options={state.selectorOptions("accountName")}
        value={state.selectorValue("accountName")}
        onChange={state.selectorHandler("accountName")}
        errorHint={state.errors.accountName}
      />
      <LabeledInput
        type="number"
        label="Period check (sec.)"
        value={state.data.delay}
        onChange={state.getHandler("delay")}
        errorHint={state.errors.delay}
      />
    </styles.FormControlsRow>

    <styles.FormControlsRow>
      <LabeledInput
        type="number"
        label="Quote"
        value={state.data.quotePerOrder}
        onChange={state.getHandler("quotePerOrder")}
        errorHint={state.errors.quotePerOrder}
      />
      <LabeledInput
        type="number"
        label="Order number"
        value={state.data.ordersCount}
        onChange={state.getHandler("ordersCount")}
        errorHint={state.errors.ordersCount}
      />
    </styles.FormControlsRow>

    <styles.FormControlsRow>
      <SuggestionLabeledInput
        type="number"
        label="Price Dec"
        value={state.data.precision.price}
        onChange={state.getHandler("precision.price")}
        errorHint={state.errors.precision?.price}
        getSuggestion={(getRef) =>
          state.isActiveSuggestion("precision.price") ? (
            <styles.Suggestion
              value={state.suggestions.precision.price}
              setValue={state.applySuggestion("precision.price")}
              ownRef={getRef}
            />
          ) : null
        }
        suggestionGap={8}
      />
      <SuggestionLabeledInput
        type="number"
        label="Amount Dec"
        value={state.data.precision.amount}
        onChange={state.getHandler("precision.amount")}
        errorHint={state.errors.precision?.amount}
        getSuggestion={(getRef) =>
          state.isActiveSuggestion("precision.amount") ? (
            <styles.Suggestion
              value={state.suggestions.precision.amount}
              setValue={state.applySuggestion("precision.amount")}
              ownRef={getRef}
            />
          ) : null
        }
        suggestionGap={8}
      />
    </styles.FormControlsRow>

    <styles.FormControlsRow>
      <LabeledInput
        type="number"
        label="Min price"
        value={state.data.minPrice}
        onChange={state.getHandler("minPrice")}
        errorHint={state.errors.minPrice}
      />
      <LabeledInput
        type="number"
        label="Max Price"
        value={state.data.maxPrice}
        onChange={state.getHandler("maxPrice")}
        errorHint={state.errors.maxPrice}
      />
    </styles.FormControlsRow>

    <styles.FormControlsRow>
      <LabeledPercentRangeInput
        label="Profit %"
        max="20"
        step="0.01"
        value={state.percentRangeValue("profit.quote")}
        onChange={state.getHandler("profit.quote")}
        errorHint={state.errors.profit?.quote}
      />
      <LabeledPercentRangeInput
        label="Maker Fee %"
        max="1"
        step="0.01"
        value={state.percentRangeValue("sellFees.quote")}
        onChange={state.getHandler("sellFees.quote")}
        errorHint={state.errors.sellFees?.quote}
      />
    </styles.FormControlsRow>
  </styles.FormControlsContainer>
));
