import { FormValidation, Validator } from "src/helpers/forms/types";
import { graterThan, isNumber, required, smallerThan } from "src/validation-schemas";
import { DEXV2SettingsModule } from "../types";

export const SLIPPAGE_VALIDATION: Validator[] = [
  required(),
  isNumber(),
  graterThan(0, "Slippage must be >= 0"),
  smallerThan(100, "Slippage must be <= 100"),
];
export const COUNTER_VALIDATION: FormValidation<DEXV2SettingsModule<"counter_data">> = {
  "counter_data.gas_mult": required(),
  "counter_data.gas_price_limit": [required(), isNumber(), graterThan(0, "Max Gas must be >= 0")],
  "counter_data.slippage": SLIPPAGE_VALIDATION,
};
