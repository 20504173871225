import { Sides } from "src/helpers/styled/spacing";
import styled, { css } from "styled-components";

const BorderMixin = (sides: Sides[] = []) => css`
  ${({ theme }) =>
    sides.map(
      (side) => css`
        border-${side}: 1px solid ${theme.dashboard.borderDefault};
      `
    )}
`;

export const Cell = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  color: ${({ theme }) => theme.dashboard.textSecondary};
  font-size: 9px;
  font-weight: ${({ theme }) => theme.fontWeights["600"]};

  ${BorderMixin(["right", "bottom"])};
`;

export const Body = styled.tbody`
  display: contents;
`;

export const Row = styled.tr`
  display: contents;
`;

export const StyledTable = styled.table`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr repeat(2, 3fr);
  grid-template-rows: 1fr 3fr;

  border-collapse: collapse;
  ${BorderMixin(["top"])};

  tr {
    th:last-of-type,
    td:last-of-type {
      border: none;
      ${BorderMixin(["bottom"])};
    }
  }
`;
