import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const SettingsForm = styled.form`
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  border-bottom: ${({ theme }) => theme.borders.solidGray};
  flex: 0 0 auto;

  & > div:last-of-type {
    max-width: calc(50% - 10px);
    margin: 10px 0px 0px;
  }

  @media (max-width: 600px) {
    & > div:last-of-type {
      max-width: 100%;
    }
  }
`;

export const Title = styled.h4`
  margin: 0;
  color: #166ce2;
  padding-bottom: 10px;
  /* border-bottom: ${({ theme }) => theme.borders.solidGray}; */
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  padding-top: 20px;
  flex: 0 0 auto;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const SubTitle = styled.h4`
  color: #767676;
  margin: 0px 0px 10px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 0 0 auto;

  :not(:last-of-type) {
    margin-bottom: 10px;
  }

  & > div {
    :not(:last-of-type) {
      margin-right: 10px;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 10px;
    & > div {
      margin: 0;
    }
  }
`;
