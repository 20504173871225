import { useMemo } from "react";
import { formatDecimalPercent } from "src/helpers/string";
import { Nullish } from "src/helpers/utils";

export interface DecimalPercentCellProps {
  value: Nullish<number | string>;
}

export const DecimalPercentCell = ({ value }: DecimalPercentCellProps) => {
  const formattedValue = useMemo(() => formatDecimalPercent(value), [value]);
  return <>{formattedValue}</>;
};
