import { Loader } from "src/components/shared/Loader";
import * as styles from "./style";

export const NativeGraph = ({ data, data: { native = [] }, showLoader, ...props }) => (
  <styles.Container {...props}>
    <styles.Title>Native</styles.Title>
    <styles.DEXGraph
      series={[
        {
          type: "Area",
          data: native,
          title: "native",
        },
      ]}
      data={native}
      allowTimeScale={false}
      showTooltip={false}
    />
    <Loader show={showLoader} />
  </styles.Container>
);
