import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "src/components/shared/Toaster";
import {
  getCEXBindings,
  getCEXCurrentBindings,
  setCEXCurrentBindings,
} from "src/api/bots/CEX/bindingModules";
import { getPartyBots } from "src/api/userManager/partiesAPI";
import { joinMarket, joinMarketBotName } from "src/helpers/botName";
import { FormDataKeys } from "src/helpers/forms/types";
import { logError } from "src/helpers/network/logger";
import { SelectorValue } from "src/modules/shared";
import {
  BindingArray,
  CEXBindingModule,
  IBotModule,
  IExchangeModule,
  IPartyBot,
  ISwapModule,
} from "../botModules";

export interface ICurrentCEXBindings {
  swapBindingUUID: string | null;
  botBindingUUID: string | null;
  exchangeBindingUUID: string | null;
  currentBinding: string | null;
  botUUID: string | null;
}

type CurrentBindingsSelectorKeys = FormDataKeys<Omit<ICurrentCEXBindings, "currentBinding">>;

class BindingsBotStore {
  _botUUID = "";

  bindings: BindingArray = [];

  swapSelected: SelectorValue | null = null;

  botSelected: SelectorValue | null = null;

  exchangeSelected: SelectorValue | null = null;

  checkSwap = false;

  checkBot = false;

  checkExchange = false;

  swapModules: SelectorValue[] = [];

  _mainBotModules: SelectorValue[] = [];

  exchangeModules: SelectorValue[] = [];

  currentBindings: ICurrentCEXBindings = {
    swapBindingUUID: null,
    botBindingUUID: null,
    exchangeBindingUUID: null,
    currentBinding: null,
    botUUID: null,
  };

  _party = "";

  _partyBots: IPartyBot[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get currentBinding() {
    switch (this.currentBindings.currentBinding) {
      case "bot":
        return this.botSelected ? this.botSelected.value : null;
      case "swap":
        return this.swapSelected ? this.swapSelected.value : null;
      case "exchange":
        return this.exchangeSelected ? this.exchangeSelected.value : null;
      default:
        return "";
    }
  }

  get botBinding() {
    return this.bindings.find(
      (el) => el.uuid === this.currentBindings.botBindingUUID && el.kind === "bot"
    ) as IBotModule;
  }

  getFullBotName(bot_uuid: string) {
    const findBot = this._partyBots.find((bot) => bot.bot_uuid === bot_uuid);

    if (findBot) {
      const market = joinMarket({
        quote: findBot.quote,
        base: findBot.base,
        exchange: findBot.exchange,
      });

      return joinMarketBotName(market, findBot.name);
    }

    return "";
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  setParty = (party: string) => {
    this._party = party;
  };

  setPartyBots = (bots: IPartyBot[]) => {
    this._partyBots = bots;
  };

  selectorHandler = (field: CurrentBindingsSelectorKeys) => (data: SelectorValue | null) => {
    this.setBinding(data, field);
  };

  setBinding = async (data: SelectorValue | null, field: CurrentBindingsSelectorKeys) => {
    const prevValueID = this.currentBindings[field];
    const prevValueSelect = data;

    this.checkData(data, field);

    try {
      const { isError } = await setCEXCurrentBindings({
        data: this.currentBindings,
        botUUID: this._botUUID,
      });

      if (isError) {
        this.currentBindings[field] = prevValueID;

        this.setCurrentBinding(field, prevValueSelect);
      } else if (!data) {
        this.setCurrentBinding(field, { value: "", label: "", id: undefined });
      } else this.setCurrentBinding(field, data);
    } catch (error) {
      logError(error);
    }
  };

  checkData = (data: SelectorValue | null, field: CurrentBindingsSelectorKeys) => {
    if (data) {
      if (typeof data.id === "string" && data.id) {
        this.currentBindings[field] = data.id;
      }
    } else this.currentBindings[field] = null;
  };

  setCurrentBinding = (field: CurrentBindingsSelectorKeys, data: SelectorValue | null) => {
    if (field === "swapBindingUUID") {
      this.swapSelected = data;
    } else if (field === "botBindingUUID") {
      this.botSelected = data;
    } else if (field === "exchangeBindingUUID") {
      this.exchangeSelected = data;
    }
  };

  setCurrentBindings = (data: ICurrentCEXBindings) => {
    this.checkBot = false;
    this.checkSwap = false;
    this.checkExchange = false;
    this.currentBindings = data;
    switch (data.currentBinding) {
      case "bot":
        this.checkBot = true;
        break;
      case "swap":
        this.checkSwap = true;
        break;
      case "exchange":
        this.checkExchange = true;
        break;
      default:
    }
  };

  findCurrentsBindings = () => {
    const exchangeBinding = this.findBinding(this.currentBindings.exchangeBindingUUID, "exchange");

    const swapBinding = this.findBinding(this.currentBindings.swapBindingUUID, "swap");

    this.botSelected = this.findBotSelected();

    if (exchangeBinding) this.exchangeSelected = exchangeBinding;

    if (swapBinding) this.swapSelected = swapBinding;
  };

  findBotSelected = () => {
    if (!this.botBinding) return null;

    return {
      value: this.botBinding.uuid,
      label: `${this.botBinding.name} (${this.getFullBotName(this.botBinding.mainBot)})`,
      id: this.botBinding.uuid,
    };
  };

  private _getModuleBinding = (module: CEXBindingModule) => {
    switch (module.kind) {
      case "bot":
        return (module as IBotModule).mainBot;
      case "swap":
        return (module as ISwapModule).swap;
      case "exchange":
        return (module as IExchangeModule).exchange;

      default:
        return "";
    }
  };

  findBinding = (uuid: string | null, kind: string) => {
    const binding = this.bindings.find((el) => el.uuid === uuid && el.kind === kind);

    if (binding) {
      const moduleName = `${binding.name} (${this._getModuleBinding(binding)})`;

      return {
        value: moduleName,
        label: moduleName,
        id: binding.uuid,
      };
    }
  };

  checkBinding = (uuid: string | null) => {
    if (uuid) {
      return true;
    }
    toast.error("First choose your binding!");
  };

  toggleSwapBinding = () => {
    if (this.checkBinding(this.currentBindings.swapBindingUUID)) {
      this.toggleCheckBoxSwap();
      if (this.checkSwap) {
        this.currentBindings.currentBinding = "swap";
      } else this.currentBindings.currentBinding = "";
      this.setSwapBinding();
    }
  };

  async setSwapBinding() {
    try {
      const { isError } = await setCEXCurrentBindings({
        data: this.currentBindings,
        botUUID: this._botUUID,
      });

      if (!isError) {
        toast.success("Binding completed successfully");
        this.disableCheckBoxBot();
        this.disableCheckBoxExchange();
      } else this.toggleCheckBoxSwap();
    } catch (error) {
      this.toggleCheckBoxSwap();
    }
  }

  toggleBotBinding = () => {
    if (this.checkBinding(this.currentBindings.botBindingUUID)) {
      this.toggleCheckBoxBot();
      if (this.checkBot) {
        this.currentBindings.currentBinding = "bot";
      } else this.currentBindings.currentBinding = "";

      this.setBotBinding();
    }
  };

  async setBotBinding() {
    try {
      const { isError } = await setCEXCurrentBindings({
        data: this.currentBindings,
        botUUID: this._botUUID,
      });

      if (!isError) {
        toast.success("Binding completed successfully");
        this.disableCheckBoxSwap();
        this.disableCheckBoxExchange();
      } else this.toggleCheckBoxBot();
    } catch (error) {
      this.toggleCheckBoxBot();
    }
  }

  toggleExchangeBinding = () => {
    if (this.checkBinding(this.currentBindings.exchangeBindingUUID)) {
      this.toggleCheckBoxExchange();
      if (this.checkExchange) {
        this.currentBindings.currentBinding = "exchange";
      } else this.currentBindings.currentBinding = "";

      this.setExchangeBinding();
    }
  };

  async setExchangeBinding() {
    try {
      const { isError } = await setCEXCurrentBindings({
        data: this.currentBindings,
        botUUID: this._botUUID,
      });

      if (!isError) {
        toast.success("Binding completed successfully");
        this.disableCheckBoxSwap();
        this.disableCheckBoxBot();
      } else this.toggleCheckBoxExchange();
    } catch (error) {
      this.toggleCheckBoxExchange();
    }
  }

  toggleCheckBoxSwap = () => {
    this.checkSwap = !this.checkSwap;
  };

  toggleCheckBoxBot = () => {
    this.checkBot = !this.checkBot;
  };

  toggleCheckBoxExchange = () => {
    this.checkExchange = !this.checkExchange;
  };

  disableCheckBoxSwap = () => {
    this.checkSwap = false;
  };

  disableCheckBoxBot = () => {
    this.checkBot = false;
  };

  disableCheckBoxExchange = () => {
    this.checkExchange = false;
  };

  setBindings = (arr: BindingArray) => {
    this.bindings = arr;
  };

  addBindings = (item: CEXBindingModule) => {
    const obj: SelectorValue = {
      label: "",
      value: "",
      id: item.uuid,
    };

    switch (item.kind) {
      case "bot": {
        const botModule = item as IBotModule;

        if (botModule.mainBot) {
          obj.value = botModule.uuid;
          obj.label = `${botModule.name} (${this.getFullBotName(botModule.mainBot)})`;
          this.addBindingsBot(obj);
        }
        break;
      }

      case "swap": {
        const swapModule = item as ISwapModule;

        if (swapModule.swap) {
          obj.value = `${swapModule.name} (${swapModule.swap})`;
          obj.label = obj.value;
          this.addBindingsSwap(obj);
        }
        break;
      }

      case "exchange": {
        const exchangeModule = item as IExchangeModule;

        if (exchangeModule.exchange) {
          obj.value = `${exchangeModule.name} (${exchangeModule.exchange})`;
          obj.label = obj.value;
          this.addBindingsExchange(obj);
        }
        break;
      }
      default:
        break;
    }
  };

  addBindingsSwap = (obj: SelectorValue) => this.swapModules.push(obj);

  addBindingsBot = (obj: SelectorValue) => this._mainBotModules.push(obj);

  addBindingsExchange = (obj: SelectorValue) => this.exchangeModules.push(obj);

  clearBindings = () => {
    this.swapModules = [];
    this._mainBotModules = [];
    this.exchangeModules = [];

    this.swapSelected = null;
    this.botSelected = null;
    this.exchangeSelected = null;
  };

  loadBindings = async (checkBindings?: (bindings: CEXBindingModule[]) => void) => {
    this.clearBindings();

    try {
      const { data, isError } = await getPartyBots(this._party);

      if (!isError)
        runInAction(() => {
          this._partyBots = data;
        });
    } finally {
      try {
        const { data, isError } = await getCEXBindings(this._party);

        if (!isError) {
          const { bindings } = data;

          this.setBindings(bindings);

          if (checkBindings) {
            // check bindings for show prompt DTP
            checkBindings(bindings);
          }

          for (const item of bindings) {
            this.addBindings(item);
          }

          try {
            const { isError, data } = await getCEXCurrentBindings(this._botUUID);

            if (!isError) {
              this.setCurrentBindings(data);

              this.findCurrentsBindings();
            }
          } catch (error) {
            logError(error);
          }
        }
      } catch (error) {
        logError(error);
      }
    }
  };

  checkDiff = (
    checkDiffPrompt: (obj: ICurrentCEXBindings, isOriginPair: boolean) => void,
    isOriginPair: boolean
  ) => {
    if (isOriginPair && checkDiffPrompt) {
      // check current bindings for show prompt Diff
      checkDiffPrompt(this.currentBindings, isOriginPair);
    }
  };

  destroy = () => {};
}

export default BindingsBotStore;
