import { ColumnDef } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { Loader } from "src/components/shared/Loader";
import { SeparatorNum } from "src/components/shared/Separator";
import { ThousandthSeparator } from "src/components/shared/ThousandthSeparator";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { toRounding } from "src/helpers/rounding";
import { useFindWidth } from "src/hooks/useFindWidth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import RangeStore from "src/state/CEX/CEXExchange/OrderBook/RangeStore";
import { OrderType } from "src/state/CEX/CEXExchange/trade/OpenOrders/BuySellOrders/BuySellStore";
import { BuyCup } from "./Cups/BuyCup";
import { SellCup } from "./Cups/SellCup";
import { LastTradePanel } from "./LastTradePanel";
import { Tooltips } from "./Tooltips";
import { EmptyOrder, OrderBookContainer, Price } from "./shared";
import * as styles from "./style";

export const MAX_QUOTE_POINT = 1000;

interface OrderBookProps {
  DTPMin: number;
  DTPMax: number;
}

export interface CupProps {
  // data: OrderBookOrder[] | OurOrder[];
  data: any;
  showMobVers: boolean;
}

export const usePriceCellConfig = <T,>() =>
  useCallback(
    (type: OrderType): ColumnDef<T> => ({
      header: () => "",
      accessorKey: "price",
      id: "price_orderBook",
      cell: (info) => {
        const price = info.getValue<string>();

        return (
          <Price type={type} data-value={price}>
            {+price ? <SeparatorNum value={price} /> : <EmptyOrder />}
          </Price>
        );
      },
    }),
    []
  );

export const useGetOrderContent = () =>
  useCallback((value: number, precision: number) => {
    if (value) return <ThousandthSeparator value={toRounding(value, precision)} />;

    return <EmptyOrder />;
  }, []);

export const OrderBook = observer(({ DTPMin, DTPMax }: OrderBookProps) => {
  const mainState = useLateInitContext(ExchangeContext);

  const state = mainState.orderBookState;

  const sellContainerRef = useRef<HTMLDivElement>(null);
  const buyContainerRef = useRef<HTMLDivElement>(null);

  const sellCupRef = useRef<HTMLTableSectionElement>(null);

  const buyCupRef = useRef<HTMLTableSectionElement>(null);

  const rangeState = useMemo(
    () => new RangeStore(mainState, sellContainerRef, buyContainerRef, sellCupRef, buyCupRef),
    [mainState]
  );

  useEffect(() => {
    const sellCup = sellContainerRef.current;
    const buyCup = buyContainerRef.current;

    const cb = () => {
      // search for the desired value in the sell cup
      rangeState.findTopRangePos(DTPMax);

      // search for the desired value in the buy cup
      rangeState.findBottomRangePos(DTPMin);
    };

    if (sellCup !== null) sellCup.addEventListener("scroll", cb);
    if (buyCup !== null) buyCup.addEventListener("scroll", cb);
    window.addEventListener("resize", cb);

    return () => {
      if (sellCup !== null) sellCup.removeEventListener("scroll", cb);
      if (buyCup !== null) buyCup.removeEventListener("scroll", cb);
      window.removeEventListener("resize", cb);
    };
  });

  useEffect(() => {
    // search for the desired value in the buy cup
    rangeState.findBottomRangePos(DTPMin);
  }, [rangeState, DTPMin, state.buyOrders, state.sellOrders, state.currentMode]);

  useEffect(() => {
    // search for the desired value in the sell cup
    rangeState.findTopRangePos(DTPMax);
  }, [rangeState, DTPMax, state.buyOrders, state.sellOrders, state.currentMode]);

  useLayoutEffect(() => {
    state.resetSellCupScroll();
  }, [state]);

  const showMobVers = useFindWidth(880);

  return (
    <styles.Container>
      <OrderBookContainer>
        <styles.Header>
          <styles.HeaderTitle style={{ textAlign: "left" }}>Price</styles.HeaderTitle>
          <styles.HeaderTitle style={{ textAlign: "right" }}>Amount</styles.HeaderTitle>
          {!showMobVers ? (
            <styles.HeaderTitle style={{ textAlign: "right" }}>Total</styles.HeaderTitle>
          ) : null}
        </styles.Header>

        {state.showSellCup ? (
          <SellCup
            data={state.sellOrders}
            sellCupRef={sellCupRef}
            sellContainerRef={sellContainerRef}
            showMobVers={showMobVers}
          />
        ) : (
          ""
        )}

        <LastTradePanel />

        {state.showBuyCup ? (
          <BuyCup
            data={state.buyOrders}
            buyCupRef={buyCupRef}
            buyContainerRef={buyContainerRef}
            showMobVers={showMobVers}
          />
        ) : (
          ""
        )}
      </OrderBookContainer>

      <Tooltips />

      <Loader show={state.isLoading} />

      {mainState.isOriginPair ? (
        <styles.LeftRange
          style={{
            top: rangeState.topPosition,
            bottom: rangeState.bottomPosition,
            borderColor: rangeState.rangeStatus,
          }}
        />
      ) : (
        ""
      )}

      {mainState.isOriginPair ? (
        <styles.RightRange
          style={{
            top: rangeState.topPosition,
            bottom: rangeState.bottomPosition,
            borderColor: rangeState.rangeStatus,
          }}
        />
      ) : (
        ""
      )}
    </styles.Container>
  );
});
