import { ComponentPropsWithoutRef } from "react";
import { ChipCorrelationData } from "src/state/CEX/CEXDashboard/v2/widgets/ChipCorrelationStore";
import { ChipCorrelationTableHeader } from "./ChipCorrelationTableHeader";
import { TokenCorrelationCell } from "./TokenDirectionCell";
import * as styles from "./style";

export interface ChipCorrelationTableOwnProps extends Partial<ChipCorrelationData> {}

export interface ChipCorrelationTableProps
  extends ChipCorrelationTableOwnProps,
    ComponentPropsWithoutRef<"table"> {}

export const ChipCorrelationTable = ({
  base = "Base",
  eth,
  btc,
  ...props
}: ChipCorrelationTableProps) => (
  <styles.StyledTable {...props}>
    <ChipCorrelationTableHeader />
    <styles.Body>
      <styles.Row>
        <styles.Cell>{base}</styles.Cell>
        <styles.Cell>
          <TokenCorrelationCell value={eth} />
        </styles.Cell>
        <styles.Cell>
          <TokenCorrelationCell value={btc} />
        </styles.Cell>
      </styles.Row>
    </styles.Body>
  </styles.StyledTable>
);
