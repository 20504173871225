import { unixToDateFormat } from "src/helpers/dateUtils";
import { ConditionCompareType, ConditionCreatedModule } from "src/modules/expertSystem";

export const MODULE_COMPARE_LABEL_MAP: Record<ConditionCompareType, string> = {
  lte: "<=",
  lt: "<",
  eq: "=",
  ne: "!=",
  gt: ">",
  gte: ">=",
};

export const convertConditionParams = (module: ConditionCreatedModule) => {
  if (module.type === "settings_value")
    return {
      ...module,
      updated_at: module.updated_at ? unixToDateFormat(module.updated_at, "FullDate") : "",
    };

  const humanCompare = module.soft_params.compare
    ? MODULE_COMPARE_LABEL_MAP[module.soft_params.compare]
    : "";

  const convertCondition = {
    ...module,
    updated_at: module.updated_at ? unixToDateFormat(module.updated_at, "FullDate") : "",
    soft_params: {
      ...module.soft_params,
      compare: humanCompare,
    },
  };

  if (convertCondition.type === "time") {
    return {
      ...convertCondition,
      current: convertCondition.current
        ? unixToDateFormat(parseInt(convertCondition.current, 10), "FullDate")
        : "",
      soft_params: {
        ...convertCondition.soft_params,
        value: convertCondition.soft_params.value
          ? unixToDateFormat(parseInt(convertCondition.soft_params.value, 10), "FullDate")
          : "",
      },
    };
  }

  return convertCondition;
};

export const convertConditions = (conditions: ConditionCreatedModule[]) =>
  conditions.map(convertConditionParams);
