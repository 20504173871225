import { Chart, Plugin } from "chart.js";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import {
  ExchangePieItem,
  IExchangesLegendState,
} from "src/state/CEX/CEXDashboard/shared/ExchangesLegendV2Store/types";

interface IExchangesLegendPluginOptions<Id extends string = string> {
  id: Id;
  state: IExchangesLegendState<Id>;
}

declare module "chart.js" {
  interface PluginOptionsByType<TType extends ChartType> {
    exchangesLegendV2?: IExchangesLegendPluginOptions;
  }
}

const getPluginOptions = (chart: Chart) => {
  const pluginOptions = chart.options.plugins?.exchangesLegendV2;
  const chartId = pluginOptions?.id;
  const state = pluginOptions?.state as IExchangesLegendState<string>;
  if (!chartId || !state) return null;
  return { chartId, state };
};

export const ExchangesLegendV2Plugin: Plugin<"pie"> = {
  id: "exchanges-legend-v2",

  afterUpdate: (chart) => {
    const data = chart.data.datasets[0].data as number[];
    const items = chart.options.plugins?.legend?.labels?.generateLabels?.(chart);

    const options = getPluginOptions(chart);
    if (!options || !items) return;

    const { chartId, state } = options;

    const pieItems: ExchangePieItem[] = filterBoolean(
      items.map(({ text, index, hidden, fillStyle }, _) => {
        if (index === undefined) return null;
        return {
          label: text,
          visible: !hidden,
          index,
          amount: data[index],
          color: fillStyle as string,
        };
      })
    );

    state.setChartData(chartId, chart, pieItems);
  },

  afterDestroy(chart) {
    const options = getPluginOptions(chart);
    if (!options) return;

    const { chartId, state } = options;

    state.removeChartData(chartId);
  },
};
