import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import styled, { css, keyframes } from "styled-components";

export const rotateAnim = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export interface RefreshButtonProps {
  $loading?: boolean;
}

export const RefreshButton = styled(ReloadButton)<RefreshButtonProps>`
  position: absolute;
  right: 10px;
  top: 10px;
  ${({ $loading = false }) =>
    $loading
      ? css`
          > svg {
            animation: ${rotateAnim} 2s linear infinite;
          }
        `
      : ""}
`;
