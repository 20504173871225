import { ComponentPropsWithoutRef } from "react";
import { InfoIconTooltip } from "src/components/BotsContent/CEX/shared/Card/Header/InfoIconTooltip";
import * as styles from "./style";

export interface CaptionProps extends ComponentPropsWithoutRef<"div"> {
  subtitle?: string;
  info?: string;
  caption?: string | JSX.Element;
}

export const Caption = ({ subtitle, info, caption, ...props }: CaptionProps) => {
  const CaptionBody =
    typeof caption === "string" ? <styles.CaptionBody>{caption}</styles.CaptionBody> : caption;

  if (!caption && !subtitle) {
    return null;
  }

  return (
    <styles.CaptionWrapper {...props}>
      {subtitle && (
        <styles.TitleRow>
          <styles.CaptionTitle>{subtitle}</styles.CaptionTitle>
          <InfoIconTooltip info={info} />
        </styles.TitleRow>
      )}
      {caption && CaptionBody}
    </styles.CaptionWrapper>
  );
};
