import { ReactNode } from "react";
import { ExternalToast } from "sonner";
import { CopyToast } from "./CopyToast";

declare module "sonner" {
  interface ToastT {
    showCopyButton?: boolean;
  }
}

interface CreateToastSettingsType {
  message?: string | ReactNode;
  toastSettings?: ExternalToast;
  defaultToastSettings?: ExternalToast;
}

const DEFAULT_TOAST_SETTINGS: ExternalToast = {
  cancel: { label: "" },
  showCopyButton: true,
  classNames: {
    toast: "sonner-root-toast",
    title: "toast-msg-content",
    description: "toast-msg-content",
    cancelButton: "toast-cancel-button",
    actionButton: "toast-action-button",
  },
};

export const SUCCESS_TOAST_SETTINGS: ExternalToast = {
  ...DEFAULT_TOAST_SETTINGS,
  duration: 1000,
  showCopyButton: false,
};

const getSettingsWithDefault = (
  settings?: ExternalToast,
  customDefault = DEFAULT_TOAST_SETTINGS
) => ({ ...customDefault, ...settings });

const getCopyButton = (msg: string): ExternalToast["action"] => ({
  // @ts-ignore because label should be string, but we need to pass JSX
  label: <CopyToast msg={msg} />,
  onClick: (e) => e.preventDefault(),
});

export const createToastSettings = ({
  message,
  toastSettings,
  defaultToastSettings,
}: CreateToastSettingsType): Omit<ExternalToast, "showCopyButton"> => {
  const { showCopyButton, ...settings } = getSettingsWithDefault(
    toastSettings,
    defaultToastSettings
  );

  if (typeof message !== "string" || !showCopyButton) return { ...settings };

  return { ...settings, action: getCopyButton(message) };
};
