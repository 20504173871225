import { observer } from "mobx-react-lite";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Headline3 } from "src/components/shared/Typography";
import { PartyInfoStore } from "src/state/UserManager/EditParty/Info/PartyInfoStore";
import * as styles from "../../../shared";
import { SaveInfoButton } from "./style";

export const GroupInfo = observer(({ state }: { state: PartyInfoStore }) => {
  const tgChatId = state.editGroupInfo.tg_chat_id ? state.editGroupInfo.tg_chat_id : "";

  return (
    <>
      <Headline3>Group info</Headline3>
      <styles.AccessForm
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        onSubmit={state.submitGroupInfoHandler()}
      >
        <styles.GridContentWrapper>
          <LabeledInput
            type="number"
            label="TG chat ID"
            value={tgChatId}
            onChange={state.getHandler("tg_chat_id")}
            errorHint={state.errors.tg_chat_id}
          />
          <LabeledSelector
            label="Client user group"
            options={state.selectorOptions("client_group")}
            value={state.selectorValue("client_group")}
            onChange={state.getSelectorHandler("client_group")}
            errorHint={state.errors.client_group}
          />
          <LabeledSelector
            label="Admin user group"
            options={state.selectorOptions("admin_group")}
            value={state.selectorValue("admin_group")}
            onChange={state.getSelectorHandler("admin_group")}
            errorHint={state.errors.admin_group}
          />
          <LabeledSelector
            label="Admin"
            options={state.selectorOptions("admin")}
            value={state.selectorValue("admin")}
            onChange={state.getSelectorHandler("admin")}
            errorHint={state.errors.admin}
            isDisabled={!state.selectorEnabled("admin")}
          />
          <SaveInfoButton stretched>Save information</SaveInfoButton>
        </styles.GridContentWrapper>
      </styles.AccessForm>
    </>
  );
});
