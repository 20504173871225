import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { SELECTOR_CLASSNAME } from "src/components/shared/Forms/Selectors/shared/style";
import styled from "styled-components";

export const Selector = styled(LabeledSelector)`
  .${SELECTOR_CLASSNAME}__menu-list {
    max-height: 100px;
  }

  .${SELECTOR_CLASSNAME}__menu-portal {
    z-index: 10000;
  }
` as typeof LabeledSelector;
