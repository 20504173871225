import { Chart, ChartEvent } from "chart.js";
import { useMemo, useRef } from "react";
import { roundSingleValue, toRounding } from "src/helpers/rounding";
import { numFormatter } from "src/helpers/separation";
import { ICoordObj } from "src/modules/shared";

interface ChartEventArgs {
  event: ChartEvent;
  replay: boolean;
  changed?: boolean;
  cancelable: false;
  inChartArea: boolean;
}

const FONT_CROSSHAIR_LABEL = "12px sans-serif";
const CROSSHAIR_LABEL_COLOR = "#fff";
const CROSSHAIR_LABEL_ALIGN = "center";
const CROSSHAIR_COLOR = "rgba(102, 102, 102, 0.7)";

const drawCrosshair = (
  chart: Chart<"line">,
  crosshairRef: React.MutableRefObject<ICoordObj[] | undefined>,
  pricePrecision: number
) => {
  const crosshair = crosshairRef.current;

  if (!crosshair) return;

  const {
    ctx,
    chartArea: { left, bottom },
    scales: { xAxis, y },
  } = chart;

  ctx.save();

  ctx.beginPath();

  crosshair.forEach((line) => {
    ctx.lineWidth = 0.5;
    ctx.strokeStyle = CROSSHAIR_COLOR;
    ctx.setLineDash([5, 5]);
    ctx.moveTo(line.startX, line.startY);
    ctx.lineTo(line.endX, line.endY);
    ctx.stroke();
  });

  ctx.fillStyle = CROSSHAIR_COLOR;
  // create label for y axis
  ctx.fillRect(0, crosshair[1].startY - 10, left, 20);
  // create label for x axis
  ctx.fillRect(crosshair[0].startX - 35, bottom, left + 10, 20);

  ctx.font = FONT_CROSSHAIR_LABEL;

  const yValue = y.getValueForPixel(crosshair[1].startY) || 0;

  const yLabelValue = numFormatter(roundSingleValue(yValue));

  const xValue = xAxis.getValueForPixel(crosshair[0].startX) || 0;

  const xLabelValue = toRounding(xValue, pricePrecision);

  ctx.textAlign = CROSSHAIR_LABEL_ALIGN;

  ctx.fillStyle = CROSSHAIR_LABEL_COLOR;
  // add text label text for y axis
  ctx.fillText(yLabelValue, left / 2, crosshair[1].startY + 5);
  // add text label text for x axis
  ctx.fillText(xLabelValue, crosshair[0].startX, bottom + 15);

  ctx.restore();
};

const setCoordCrosshair = (
  chart: Chart<"line">,
  args: ChartEventArgs,
  crosshair: React.MutableRefObject<ICoordObj[] | undefined>
) => {
  const {
    chartArea: { left, right, top, bottom },
  } = chart;

  const xCoord = args.event.x;
  const yCoord = args.event.y;

  const crosshairRef = crosshair;
  const eventArgs = args;

  if (xCoord === null || yCoord === null) return;

  if (!args.inChartArea && crosshair.current) {
    crosshairRef.current = undefined;
    eventArgs.changed = true;
  } else if (args.inChartArea) {
    crosshairRef.current = [
      {
        startX: xCoord,
        startY: top,
        endX: xCoord,
        endY: bottom,
      },
      {
        startX: left,
        startY: yCoord,
        endX: right,
        endY: yCoord,
      },
    ];
    eventArgs.changed = true;
  }
};

export const useChartJsCrosshairPlugin = (pricePrecision: number) => {
  const crosshair = useRef<ICoordObj[] | undefined>(undefined);

  const hoverCrosshairPlugin = useMemo(
    () => ({
      id: "hoverCrosshair",
      events: ["mousemove"],
      beforeDatasetsDraw(chart: Chart<"line">) {
        drawCrosshair(chart, crosshair, pricePrecision);
      },

      afterEvent(chart: Chart<"line">, args: ChartEventArgs) {
        setCoordCrosshair(chart, args, crosshair);
      },
    }),
    [pricePrecision]
  );

  return hoverCrosshairPlugin;
};
