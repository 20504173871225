import styled, { css } from "styled-components";

interface PointProps {
  active: boolean;
  completed: boolean;
}

export const Container = styled.div`
  position: relative;

  display: block;

  flex: 1;
`;

export const WrapperStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;
`;

export const BgMixin = css<PointProps>`
  background-color: ${({ theme, active, completed }) =>
    active || completed ? theme.primaryColor : "#DFDFDF"};
`;

export const StepPoint = styled.span<PointProps>`
  position: relative;

  width: 24px;
  height: 24px;

  display: block;

  z-index: 1;

  border-radius: 100%;

  ${BgMixin}

  box-shadow: ${({ active }) => `0 0 0 4px ${active ? "rgba(21, 108, 227, 0.08)" : "none"}`};

  ${({ active, completed }) => {
    if (active)
      return css`
        :after {
          position: absolute;

          top: 25%;
          left: 25%;

          width: 50%;
          height: 50%;

          content: "";

          border-radius: 100%;

          background-color: #ffffff;
        }
      `;
    if (completed)
      return css`
        :after {
          position: absolute;

          left: 38%;
          top: 28%;

          content: "";

          width: 4px;
          height: 6px;

          border-bottom: solid;
          border-right: solid;
          border-width: 2px;
          border-color: #fff;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          transition: all 0.5s;
        }
      `;
  }}
`;

export const LabelPoint = styled.span<PointProps>`
  font-size: 12px;
  color: ${({ theme, active, completed }) =>
    active || completed ? theme.primaryColor : theme.bigTextColor};
`;

export const StepConnector = styled.div<PointProps>`
  position: absolute;

  top: 71%;
  left: calc(-49% + 10px);
  right: calc(50% + 10px);

  z-index: 0;

  height: 4px;

  display: block;

  ${BgMixin}
`;
