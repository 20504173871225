import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { getUTCRangeFromDuration } from "src/helpers/dateUtils";
import { useFindWidth } from "src/hooks/useFindWidth";
import { BaseCEXPNLWidget } from "../../shared/BaseCEXPNLWidget";
import { BotInfoProps } from "../..";

export interface TerminalPnLProps extends BotInfoProps {
  isShortView?: boolean;
  contentDirection?: "row" | "column";
}

export const TerminalPnL = observer(
  ({ botInfo: { party }, contentDirection }: TerminalPnLProps) => {
    const [range, setRange] = useState<DateTimeRange>(getUTCRangeFromDuration({ hours: 12 }));

    const showMobile = useFindWidth(880);

    const position = useMemo(() => (showMobile ? "bottom left" : "top left"), [showMobile]);

    return (
      <BaseCEXPNLWidget
        party={party}
        range={range}
        onRangeChange={setRange}
        pickerPosition={position}
        orientation={contentDirection}
      />
    );
  }
);
