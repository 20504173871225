import { ComponentPropsWithoutRef } from "react";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { noOp } from "src/helpers/utils";
import {
  DateTimeRangePickerSelector,
  DateTimeRangePickerSelectorProps,
} from "../DatePickers/DateTimeRangePickerSelector";
import { DateTimeRange } from "../DatePickers/shared/models/dateTimeRange";
import { EMPTY_DATE_TIME_RANGE } from "../DatePickers/shared/utils/valueManager";
import { Loader } from "../Loader";
import { DesktopPNLV2 } from "./DesktopPNLV2";
import { MobilePNLV2, MobilePNLV2OwnProps } from "./MobilePNLV2";
import * as styles from "./style";

interface PNLV2RangePickerProps {
  range?: DateTimeRange;
  onRangeChange?: (range: DateTimeRange) => void;
  showPicker?: boolean;
  pickerProps?: Omit<DateTimeRangePickerSelectorProps, "value" | "onChange">;
}

interface PNLV2ContentProps extends MobilePNLV2OwnProps {}

export interface PNLV2RootProps
  extends Pick<ComponentPropsWithoutRef<"div">, "className" | "style"> {}

export interface PNLV2Props extends PNLV2ContentProps, PNLV2RangePickerProps, PNLV2RootProps {
  isMobile?: boolean;
  loading?: boolean;
}

export const PNLV2 = ({
  isMobile = true,
  loading,
  orientation,
  showHeader,
  range = EMPTY_DATE_TIME_RANGE,
  onRangeChange = noOp,
  showPicker,
  pickerProps,
  className,
  style,
  showFeeSymbol = true,
  ...props
}: PNLV2Props) => {
  const rootProps = {
    className,
    style,
  };

  const baseProps = {
    ...props,
    showFeeSymbol,
  };

  const mobileProps = {
    orientation,
    showHeader,
    ...baseProps,
  };

  return (
    <styles.Container {...rootProps}>
      {showPicker && (
        <DateTimeRangePickerSelector
          value={range}
          onChange={onRangeChange}
          maxDate={getCurrentDayjs()}
          disableFuture
          {...pickerProps}
        />
      )}
      {isMobile ? <MobilePNLV2 {...mobileProps} /> : <DesktopPNLV2 {...baseProps} />}
      <Loader show={loading} />
    </styles.Container>
  );
};
