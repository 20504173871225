import { makeAutoObservable } from "mobx";
import { getPartyConfig } from "src/api/bots/CEX/dashboard";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";

interface SantimentData {
  slug: string;
  ticker: string;
}

export class SantimentStore implements IDisposable {
  private _data?: SantimentData;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get data() {
    return this._data;
  }

  private _setData = (data: SantimentData) => {
    this._data = data;
  };

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _getSantimentData = async (party: string) => {
    if (!party) return;

    this._setLoading(true);

    try {
      const { data, isError } = await getPartyConfig(party);

      if (!isError) this._setData(data);
    } finally {
      this._setLoading(false);
    }
  };

  getSantimentData = async (party: string) => {
    try {
      await this._getSantimentData(party);
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}
