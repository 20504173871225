import styled from "styled-components";
import { Cell, Row } from "../style";

export const Header = styled.thead`
  display: contents;
`;

export const HeaderCell = styled(Cell).attrs({ as: "th" })`
  background-color: ${({ theme }) => theme.dashboard.backgroundTableHead};
`;

export const HeaderRow = styled(Row)``;
