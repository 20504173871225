import { memo } from "react";
import { StatsWalletType } from "src/api/bots/DEXV2/stats";
import { StatusPoint, StatusType } from "src/components/shared/GridTable";

interface TypeCellProps {
  type: StatsWalletType;
}

export const WalletTypeCell = memo(({ type }: TypeCellProps) => {
  const checkType = (type: StatsWalletType): StatusType => {
    type.toLowerCase();

    switch (type) {
      case "deployer":
        return "neutral";

      case "volume":
        return "danger";

      case "limit":
        return "success";

      case "counter":
        return "warning";

      default:
        return "neutral";
    }
  };

  return (
    <StatusPoint type={checkType(type)} style={{ width: "55px" }}>
      {type}
    </StatusPoint>
  );
});
