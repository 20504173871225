import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { ConstraintsInput } from "src/components/BotsContent/CEX/CEXBotSettings/shared";
import { Form } from "src/components/BotsContent/DEX_V2/Stats/Tables/shared";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { WithdrawGasContext } from "src/context/DEXV2/DEXV2Stats/GasWallets/WIthdrawGas";
import { useLateInitContext } from "src/hooks/useLateInitContext";

export interface WithdrawGasFormProps extends Omit<ComponentPropsWithoutRef<"form">, "onSubmit"> {}

export const WithdrawGasForm = observer((props: WithdrawGasFormProps) => {
  const state = useLateInitContext(WithdrawGasContext);

  return (
    <Form onSubmit={state.formState.submitHandler()} {...props}>
      <ConstraintsInput
        label="Address"
        {...state.formState.getInputProps("receiver")}
        {...state.formState.getFieldErrorAsProps("receiver")}
      />

      <SubmitButton size="m">Withdraw</SubmitButton>
    </Form>
  );
});
