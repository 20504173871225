import { createContextProvider } from "src/context/utils/createContextProvider";
import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import CreateModuleStore, {
  ICreateModuleParams,
} from "src/state/expertSystem/modules/CreateModuleStore";
import ModulesStore from "src/state/expertSystem/modules/ModulesStore";
import { ChooseSettingParamsStore } from "src/state/expertSystem/strategies/changeSettingsModule/ChooseSettingParamsStore";
import { ExpertSystemContext } from ".";

const useModulesStore = () => {
  const expertState = useLateInitContext(ExpertSystemContext);
  const state = useLocalStore(ModulesStore, expertState);
  return state;
};

export const ModulesContext = createContextProvider<ModulesStore>({
  stateCreator: useModulesStore,
});

export const CreateModulesContext = createLateInitContext<CreateModuleStore>();

interface CreateModulesProviderProps
  extends ChildrenProps,
    Pick<ICreateModuleParams, "onModuleCreate"> {}

export const CreateModulesProvider = ({ children, onModuleCreate }: CreateModulesProviderProps) => {
  const expertState = useLateInitContext(ExpertSystemContext);
  const state = useLocalStore(CreateModuleStore, {
    onModuleCreate,
    expertState,
  });

  return <CreateModulesContext.Provider value={state}>{children}</CreateModulesContext.Provider>;
};

export const ChooseSettingParamsContext = createLateInitContext<ChooseSettingParamsStore>();
