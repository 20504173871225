import { Subtitle } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div``;

export const SwapPriceInfo = styled(Subtitle).attrs({ as: "p" })`
  min-height: 28px;

  b {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;
