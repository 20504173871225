import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ModalPanel, ModalProps } from "src/components/shared/ModalPanel";
import {
  GasFillModalContent,
  GasFillModalContentOwnProps,
  GasFillModalContentProps,
} from "./GasFillModalContent";

interface ModalGasFillProps
  extends ModalProps,
    Omit<GasFillModalContentOwnProps, "onClose" | "setLoading"> {}

export const ModalGasFill = observer(({ show, close, wallets, deployerId }: ModalGasFillProps) => {
  const [loading, setLoading] = useState(false);

  const contentProps: GasFillModalContentProps = {
    onClose: close,
    wallets,
    setLoading,
    deployerId,
  };

  return (
    <ModalPanel label="Gas Fill" show={show} close={close} loading={loading} zIndex={1000}>
      <GasFillModalContent {...contentProps} />
    </ModalPanel>
  );
});
