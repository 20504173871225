import { Subtitle } from "src/components/shared/Typography";
import styled from "styled-components";
import { ScrollSize } from "src/components/shared/shared";
import { TransactionsList as BaseTransactionsList } from "../TransactionsList";

export const Container = styled.div`
  width: 100%;

  height: 120px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled(Subtitle).attrs({ as: "p" })`
  font-size: ${({ theme }) => theme.fontSizes.heading3};
`;

export const TransactionList = styled(BaseTransactionsList)`
  overflow-y: auto;

  ${ScrollSize};
`;
