import { CenteringMixin } from "src/components/shared/TableV2";
import { ThousandthSeparator } from "src/components/shared/ThousandthSeparator";
import { Headline2 } from "src/components/shared/Typography";
import { CardSurface, EmptyTextLineHeight } from "src/components/shared/shared";
import { FundingType } from "src/state/funding/CEX/types";
import styled from "styled-components";

interface FundingCellProps {
  type: FundingType;
}

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;

  gap: 15px;

  padding: 15px 20px;

  ${CardSurface}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const HeaderInfo = styled(Headline2)`
  color: ${({ theme }) => theme.lightGray};

  ${EmptyTextLineHeight}
`;

export const FundingCell = styled(ThousandthSeparator)<FundingCellProps>`
  color: ${({ theme, type }) => (type === "withdraw" ? theme.dangerColor : theme.successColor)};

  ${({ type }) =>
    type === "deposit" &&
    `
    ::before {
      content: "+";
    }
    `}

  ${CenteringMixin}
`;
