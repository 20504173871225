import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { PartyContext } from "src/context/UserManager/UserManager";
import { ExchangesProps } from "..";
import { AccountItem } from "./AccountItem";
import * as styles from "./style";

interface ExchangeProps extends ExchangesProps {
  name: string;
}

export const Exchange = observer(({ name, showAddAPI, showEditAcc, showAddAcc }: ExchangeProps) => {
  const [hide, setHide] = useState(false);

  const partyState = useContext(PartyContext);

  return (
    <styles.ExchContainer>
      <styles.ExchangeHeaderRow>
        <styles.ExchangeHeader hide={hide} onClick={() => setHide(!hide)}>
          <styles.ExchName>{name}</styles.ExchName>
        </styles.ExchangeHeader>

        <AddButton
          onClick={() => {
            showAddAcc(name);
          }}
        />
      </styles.ExchangeHeaderRow>

      {hide ? (
        <styles.ExchBody>
          {partyState?._accounts[name].map((el, index) => (
            <AccountItem
              account={el}
              key={el.uuid}
              showAddAPI={showAddAPI}
              showEditAcc={showEditAcc}
            />
          ))}
        </styles.ExchBody>
      ) : null}
    </styles.ExchContainer>
  );
});
