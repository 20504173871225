import { GoogleSpreadsheet, GoogleSpreadsheetWorksheet } from "google-spreadsheet";

type SpreadsheetProperties = NonNullable<
  Parameters<typeof GoogleSpreadsheet.createNewSpreadsheetDocument>[1]
>;

type WorksheetProperties = NonNullable<Parameters<GoogleSpreadsheet["addSheet"]>[0]>;

type AddRowsParams = NonNullable<Parameters<GoogleSpreadsheetWorksheet["addRows"]>>;

type RowOptions = AddRowsParams[1];

export type RowRawData = Exclude<AddRowsParams[0][number], any[]>[string];

export type RowSerialiazer<T extends object> = (value: T) => RowRawData[];

export interface CreateSpreadsheetOptions<T extends object> {
  token: string;
  data: T[];
  spreadsheet?: SpreadsheetProperties;
  sheet?: WorksheetProperties;
  rows?: RowOptions & { serializer?: RowSerialiazer<T> };
}

export type SheetsState = {
  sheet: GoogleSpreadsheet;
  worksheet: GoogleSpreadsheetWorksheet;
};

export const createSpreadsheet = async <T extends object>({
  token,
  data,
  spreadsheet: spreadsheetOptions,
  sheet: { headerValues: inHeaderValues, ...sheetOptions } = {},
  rows: { serializer, ...rowsOptions } = {},
}: CreateSpreadsheetOptions<T>): Promise<SheetsState> => {
  const document = await GoogleSpreadsheet.createNewSpreadsheetDocument(
    { token },
    spreadsheetOptions
  );

  const initialSheet = document.sheetsByIndex[0];

  const headerValues = inHeaderValues || Object.keys(data[0]);

  const sheet = await document.addSheet({
    headerValues,
    ...sheetOptions,
  });

  const dataSerializer = serializer || ((value: T) => Object.values(value));

  await sheet.addRows(data.map(dataSerializer), rowsOptions);

  if (initialSheet) {
    await initialSheet.delete();
  }
  return { sheet: document, worksheet: sheet };
};

export const getSpreadsheetUrl = (sheet: GoogleSpreadsheet) => {
  const id = sheet.spreadsheetId;
  return `https://docs.google.com/spreadsheets/d/${id}`;
};
