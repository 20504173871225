import { AddressLink } from "src/components/BotsContent/DEX_V2/Stats/Tables/shared";
import { rotateAnim } from "src/components/BotsContent/DEX_V2/Swap/SwapWidget/RefreshButton/style";
import ExchangeIcons from "src/icons/ExchangeIcons";
import styled, { css } from "styled-components";

export interface LoadingIconProps {
  $loading?: boolean;
}

export const LoadingIcon = styled(ExchangeIcons.reloading)<LoadingIconProps>`
  ${({ $loading = false }) =>
    $loading
      ? css`
          animation: ${rotateAnim} 2s linear infinite;
        `
      : ""}
`;

export const SpreadsheetLink = styled(AddressLink)`
  font-family: inherit;
  font-size: inherit;
  display: inline;
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: none;
`;
