import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Loader } from "src/components/shared/Loader";
import { ModuleListContext, MultiGridContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import ModulesStore from "src/state/CEX/multiGrid/Modules";
import { ColumnWrapper } from "../../shared";
import { CreatedModule } from "./CreatedModule";
import { ListHeader } from "./ListHeader";
import * as styles from "./style";

export const ModuleList = observer(() => {
  const mainState = useLateInitContext(MultiGridContext);
  const state = useMemo(() => new ModulesStore(mainState), [mainState]);

  useEffect(() => {
    if (!state.gridUUID) return;
    state.loadData();
  }, [state, state.gridUUID]);

  return (
    <ModuleListContext.Provider value={state}>
      <ColumnWrapper style={{ gridArea: "moduleList" }}>
        <ListHeader />

        <styles.Container>
          <styles.Content>
            {state.exchModules.map((el) => (
              <CreatedModule key={el.id} module={el} />
            ))}
          </styles.Content>

          <Loader show={state.isLoading} />
        </styles.Container>
      </ColumnWrapper>
    </ModuleListContext.Provider>
  );
});
