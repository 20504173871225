import { Subtitle } from "src/components/shared/Typography";
import { ScrollSize } from "src/components/shared/shared";
import styled, { css } from "styled-components";
import { Wrapper } from "../shared";

const FormRowMixin = css`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 13px;
`;

export const FormContainer = styled.form`
  position: relative;

  ${FormRowMixin}
`;

export const TickersContent = styled.div`
  height: 0px;

  padding: 0px 5px;

  overflow: auto;

  ${FormRowMixin}
  ${ScrollSize}
`;

export const TickerElementWrapper = styled(Wrapper)`
  align-items: center;

  padding: 2.5px 0px;
`;

export const TickersHeader = styled(Wrapper)`
  width: calc(100% - 35px);
`;

export const HeaderTitle = styled(Subtitle)`
  width: 100%;
`;
