import { Token } from "@uniswap/sdk-core";
import { ethers } from "ethers";
import { AddressHelper } from "src/contracts/AddressHelper";
import { IERC20 } from "src/contracts/IERC20";
import contracts from "src/json/contracts.json";
import { chainErrorHandler } from "../network/chain";
import { Nullish } from "../utils";

type Contracts = typeof contracts;
export type ContractInfo = Contracts["1"][0];
export type ChainID = string;
export type ContractsMap = Partial<Record<ChainID, ContractInfo[]>>;

export type ContractNames = keyof ContractInfo["contracts"];

const contractsMap: ContractsMap = contracts;

export const getContractAddress = (chainId: ChainID, contract: ContractNames) => {
  const address = contractsMap[chainId]?.[0].contracts[contract];
  return address;
};

export const isWalletAddress = async (
  address: string | string[],
  addressHelper: Nullish<AddressHelper>
): Promise<Record<string, boolean>> => {
  if (!addressHelper) return {};
  try {
    if (!Array.isArray(address)) {
      const isContract = await addressHelper.hasCodeSingle(address);
      return { [address]: !isContract };
    }

    const areContracts = await addressHelper.hasCode(address);
    return Object.fromEntries(address.map((address, ind) => [address, !areContracts[ind]]));
  } catch (err) {
    chainErrorHandler(err);
    return {};
  }
};

export const isTransactionSuccess = (receipt?: ethers.providers.TransactionReceipt) =>
  receipt && receipt.status === 1;

export const getIERC20Token = async (contract: IERC20, chainId: number) => {
  try {
    const [decimalsResult, nameResult, symbolResult] = await Promise.allSettled([
      contract.decimals(),
      contract.name(),
      contract.symbol(),
    ]);
    // we need to fetch at least decimals for token to be valid
    if (decimalsResult.status === "rejected") {
      throw decimalsResult.reason;
    }
    const decimals = decimalsResult.value;
    const symbol = symbolResult.status === "fulfilled" ? symbolResult.value : "TOKEN";
    const name = nameResult.status === "fulfilled" ? nameResult.value : "Token";

    return new Token(chainId, contract.address, decimals, symbol, name);
  } catch (err) {
    chainErrorHandler(err);
    return null;
  }
};
