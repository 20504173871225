import { ISeriesApi, SeriesType } from "lightweight-charts";
import { ISeriesApiProvider, SeriesOptions } from "src/components/shared/Graph/types";
import { Nullish } from "src/helpers/utils";
import { BaseSeriesLegendData } from "../types";

export const getSeriesColor = <T extends SeriesType>(seriesApi: ISeriesApi<T>) => {
  switch (seriesApi.seriesType()) {
    case "Line": {
      const options = (seriesApi as ISeriesApi<"Line">).options();
      return options.color;
    }
    case "Area": {
      const options = (seriesApi as ISeriesApi<"Area">).options();
      return options.lineColor;
    }
    default: {
      return undefined;
    }
  }
};

export const seriesMapToLegendData = <T extends SeriesType>(
  id: string,
  title: string,
  options: SeriesOptions<T> | null,
  series: Nullish<ISeriesApiProvider<T>>
): BaseSeriesLegendData<T> | null => {
  if (!options || !series) return null;

  const seriesApi = series.api();

  const { visible } = options;

  const color = getSeriesColor(seriesApi);

  const legendData = {
    visible,
    id,
    title,
    color,
    series: seriesApi,
  } as BaseSeriesLegendData<T>;

  return legendData;
};
