import { DropDownProps } from "src/components/UserManagement/Access/Scopes/ScopesTree/shared";
import { SelectItem } from "src/components/UserManagement/Access/shared";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: 12px;
  }
`;

export const Item = styled(SelectItem)<DropDownProps>`
  position: relative;

  height: 100%;
  min-width: 40px;

  display: inline-flex;
  align-items: center;

  padding-right: 15px;

  color: ${({ hide, theme }) => (hide ? theme.primaryColor : theme.darkGray)};
  text-decoration: ${({ hide }) => (hide ? "underline" : "")};
  font-size: 10px;

  ::before {
    position: absolute;
    content: "";
    width: 3.5px;
    height: 3.5px;
    right: 5px;
    top: 7.5px;
    border-bottom: solid;
    border-right: solid;
    border-width: 1.3px;
    border-color: currentColor;
    -ms-transform: ${({ hide }) => (hide ? "rotate(-45deg)" : "rotate(45deg)")};
    -webkit-transform: ${({ hide }) => (hide ? "rotate(-45deg)" : "rotate(45deg)")};
    transform: ${({ hide }) => (hide ? "rotate(-45deg)" : "rotate(45deg)")};
    transition: all 0.5s;
  }
`;
