import { CheckBox } from "src/components/shared/CheckBox";
import { HashesList } from "src/components/shared/HashesList";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled, { css } from "styled-components";
import { InfoPanel } from "./InfoPanel";

export const FormContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormRow = styled.div`
  width: 100%;

  display: flex;
  gap: 28px;
`;

export const FormCol = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HeaderCheckbox = styled(CheckBox)`
  @media ${getMobileMediaQuery("450px")} {
    width: 100%;
  }
`;

export interface WalletsListProps {
  fixedHeight?: boolean;
}

export const WalletsList = styled(HashesList)<WalletsListProps>`
  ${({ fixedHeight }) => {
    if (fixedHeight) {
      return css`
        height: 55px;
      `;
    }
  }}
`;

export const ModuleInfoPanel = styled(InfoPanel)`
  padding: 0;

  gap: 10px;
  justify-content: space-between;

  border-radius: 0;

  box-shadow: none;
`;

export const ModuleInfoContent = styled(FormContent)`
  flex-direction: row;
  gap: 12px;
`;
