import { observer } from "mobx-react-lite";
import { WalletLabeledInput } from "src/components/BotCreating/DEX/shared/WalletLabeledInput";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { TransferWalletsPanelProps } from "../../Main/TransferWalletsPanel";
import { SettingsPanel } from "../../shared/SettingsPanel";
import { FormContent, WalletsList } from "../../shared/style";

export interface BlacklistPanelProps extends TransferWalletsPanelProps {}

export const BlacklistPanel = observer(({ isMobile, ...props }: BlacklistPanelProps) => {
  const { blacklistWalletsState: state } = useLateInitContext(DEXV2SettingsContext);

  const saveEnabled = Boolean(state.wallet);

  return (
    <SettingsPanel label="Blacklist settings" {...props}>
      <FormContent>
        <WalletLabeledInput
          label="Blacklist Wallet"
          addEnabled={saveEnabled}
          onAdd={state.addWallet}
          value={state.wallet}
          onChange={state.setWallet}
          errorHint={state.walletErrors}
        />
        <WalletsList
          fixedHeight={isMobile}
          hashes={state.walletsAsFieldProps}
          onDeleteHash={state.deleteWallet}
        />
      </FormContent>
    </SettingsPanel>
  );
});
