import { ComponentPropsWithoutRef } from "react";
import { Headline2 } from "src/components/shared/Typography";
import * as styles from "./style";

export interface ModalPanelProps extends ComponentPropsWithoutRef<"div"> {
  title?: string;
}

export const ModalPanel = ({ title, children, ...props }: ModalPanelProps) => (
  <styles.Surface {...props}>
    <styles.Panel>
      <styles.Header>
        <Headline2>{title}</Headline2>
      </styles.Header>

      <styles.Content>{children}</styles.Content>
    </styles.Panel>
  </styles.Surface>
);
