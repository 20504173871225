import { makeAutoObservable, runInAction } from "mobx";
import { fetchVolume24 } from "src/api/bots/CEX/exchange";
import { logError } from "src/helpers/network/logger";
import { calcRoundingValue, toRounding } from "src/helpers/rounding";
import { numFormatter } from "src/helpers/separation";
import { Volume24h } from "src/modules/exchange/trade";
import ExchangeStore from ".";

const INITIAL_VOLUMES = {
  baseVolume: "",
  close: "",
  high: "",
  low: "",
  open: "",
  quoteVolume: "",
  time: "",
};

class VolumeInfoStore {
  private volumes: Volume24h = INITIAL_VOLUMES;

  mainStore: ExchangeStore;

  constructor(state: ExchangeStore) {
    this.mainStore = state;

    this.mainStore.setUpdHandlers("updVolumes", this.downloadData);

    makeAutoObservable(this);
  }

  get quote() {
    return this.mainStore.quote;
  }

  get base() {
    return this.mainStore.base;
  }

  get hight() {
    return this.volumes.high ? toRounding(+this.volumes.high, this.mainStore.pricePrecision) : "-";
  }

  get low() {
    return this.volumes.low ? toRounding(+this.volumes.low, this.mainStore.pricePrecision) : "-";
  }

  get quoteVolume() {
    return this.volumes.quoteVolume ? numFormatter(this.volumes.quoteVolume) : "-";
  }

  get baseVolume() {
    return this.volumes.baseVolume ? numFormatter(this.volumes.baseVolume) : "-";
  }

  get changePricePercent() {
    const deltaPercent = (this.delta / +this.volumes.open) * 100;

    return +this.volumes.close && +this.volumes.open ? toRounding(deltaPercent, 2) : "-";
  }

  get changePrice() {
    return +this.volumes.close && +this.volumes.open
      ? toRounding(this.delta, calcRoundingValue(this.delta))
      : "";
  }

  get delta() {
    return +this.volumes.close - +this.volumes.open;
  }

  downloadData = () => {
    this.getVolumes(this.mainStore.currentAccID, this.mainStore.exchange, this.mainStore.pair);
  };

  getVolumes = async (account_uuid: string, exchange: string, pair: string) => {
    try {
      const {
        isError,
        data: { data },
      } = await fetchVolume24({
        account_uuid,
        exchange,
        pair,
      });

      if (!isError) {
        runInAction(() => {
          this.volumes = data;
        });
      }
    } catch (error) {
      logError(error);
    }
  };
}

export default VolumeInfoStore;
