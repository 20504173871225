import { unixToDateFormat } from "src/helpers/dateUtils";
import * as styles from "./style";

export interface StatusProps extends React.ComponentPropsWithoutRef<"div"> {
  status: string;
  updateTime: number;
}

const Status = ({ status, updateTime, ...props }: StatusProps) => (
  <styles.Status {...props}>
    <styles.StatusText>
      Status:
      <styles.Indicator status={status} />
    </styles.StatusText>
    <styles.LastUpdateText>
      Last update:
      <styles.Date>{unixToDateFormat(updateTime, "FullDate")}</styles.Date>
    </styles.LastUpdateText>
  </styles.Status>
);

export default Status;
