import { StyledProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";
import { ButtonSize, ButtonStyleProps, ElevationMixin } from "../Button/style";
import { BaseButton } from "../shared/style";

export interface ToggleButtonStyleProps extends Pick<ButtonStyleProps, "elevated"> {
  size: Omit<ButtonSize, "xs">;
  selected: boolean;
}

export interface ToggleButtonStyledProps extends StyledProps<ToggleButtonStyleProps> {}

export const BackgroundColorMixin = ({
  $selected,
}: Pick<ToggleButtonStyledProps, "$selected">) => css`
  ${({ theme }) => {
    const mainColor = $selected ? "primary" : "secondary";
    const { enabled, hover, active, focused } = theme.buttonBackgroundStateColors[mainColor];

    return css`
      background-color: ${enabled};

      :hover {
        background-color: ${hover};
      }

      :active {
        background-color: ${active};
      }

      :focus-visible {
        background-color: ${focused};
      }
    `;
  }}
`;

export const TextColorMixin = ({ $selected }: Pick<ToggleButtonStyledProps, "$selected">) => css`
  ${({ theme }) => {
    const secondaryColor =
      theme.mode === "light" ? theme.primaryColor : theme.contentBackgroundColor;
    const textColor = $selected ? theme.backgroundWhiteColor : secondaryColor;
    return css`
      color: ${textColor};
    `;
  }}
`;

const SizeMixin = ({ $size }: Pick<ToggleButtonStyledProps, "$size">) => css`
  ${({ theme }) => {
    switch ($size) {
      case "l": {
        return css`
          height: 38px;
          width: 38px;
          font-size: ${theme.fontSizes["12px"]};
          line-height: ${theme.lineHeights["12px"]};
          padding: 6px;
        `;
      }
      case "m": {
        return css`
          height: 30px;
          width: 30px;
          font-size: ${theme.fontSizes["10px"]};
          line-height: ${theme.lineHeights["10px"]};
          padding: 4px;
        `;
      }
      case "s": {
        return css`
          height: 22px;
          width: 22px;
          font-size: ${theme.fontSizes["8px"]};
          line-height: ${theme.lineHeights["8px"]};
          padding: 2px;
        `;
      }
    }
  }}
`;

export const ToggleButton = styled(BaseButton)<ToggleButtonStyledProps>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radii["4px"]};
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
  flex: 0 0 auto;

  ${BackgroundColorMixin}
  ${TextColorMixin} 
  ${SizeMixin};

  ${({ $elevated }) => ElevationMixin({ $elevated, $variant: "contained" })}
`;
