import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";
import { SideWrapper } from "../shared";

export const ScopesContainer = styled(PanelContainer)`
  padding: 30px 25px;
  grid-area: scopes;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  height: calc(100% - 32px - 30px);
  display: flex;

  margin-top: 32px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

export const ButtonSideWrapper = styled(SideWrapper)`
  margin-top: 37px;
`;
