import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.p`
  margin: 0;
  padding: 0;

  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  font-weight: ${({ theme }) => theme.fontWeights["600"]};
  color: ${({ theme }) => theme.dashboard.textHeading};
`;
