import { KPICardProps } from "../../../../../shared/KPICard";
import * as styles from "./style";

export interface PNLCardProps extends Omit<KPICardProps, "caption" | "subtitle" | "kpi"> {
  value: number | null;
}

export const PNLCard = ({ value: pnl, ...props }: PNLCardProps) => {
  const Kpi = <styles.KPIText>{pnl}</styles.KPIText>;
  return <styles.PNLKPICard kpi={Kpi} {...props} />;
};
