import { makeAutoObservable, when } from "mobx";
import { StepAction } from "src/components/BotCreating/DEX/hooks/useStepActions";
import { isTransactionSuccess } from "src/helpers/chain/contracts";
import { makeLoggable } from "src/helpers/logger";
import { showErrorMsg } from "src/helpers/message";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logError } from "src/helpers/network/logger";
import { LoadState } from "src/helpers/network/state";
import { IDisposable, WhenReactionPromise } from "src/helpers/utils";
import DeployerInfoStore from "./DeployerInfoStore";

export default class ContractsDeployStore implements IDisposable {
  private _loading = false;

  private _deployStatus: LoadState = LoadState.Init;

  private _botUUID = "";

  private _deployerInfo: DeployerInfoStore;

  private _waitDeployDepsReaction?: WhenReactionPromise;

  constructor(deployerInfo: DeployerInfoStore) {
    this._deployerInfo = deployerInfo;

    makeAutoObservable(this);

    makeLoggable<any>(this, {
      _info: true,
      _chainProvider: () => this._chainProvider.currentChain,
    });
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _setDeployStatus = (state: LoadState) => {
    this._deployStatus = state;
  };

  get deployStatus() {
    return this._deployStatus;
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  private get _info() {
    return this._deployerInfo.info;
  }

  private get _chainProvider() {
    return this._deployerInfo.chainProvider;
  }

  get chainMeta() {
    return this._deployerInfo.chainMeta;
  }

  private get _waitDeployParams() {
    const { provider } = this._chainProvider;
    const { hash } = this._info;
    if (!provider || !hash) {
      return null;
    }
    return { provider, hash };
  }

  private get _waitDeployDeps() {
    return when(() => Boolean(this._waitDeployParams), { timeout: 1500 });
  }

  private get _currentWaitDeployDeps() {
    this._waitDeployDepsReaction?.cancel();
    const waitDeployDeps = this._waitDeployDeps;
    this._waitDeployDepsReaction = waitDeployDeps;
    return waitDeployDeps;
  }

  private _getDeployParams = async () => {
    const waitDeployDeps = this._currentWaitDeployDeps;

    const waitDeployParams = this._waitDeployParams;
    try {
      await waitDeployDeps;
    } catch {
      this._setDeployStatus(LoadState.Error);
      return null;
    }

    return waitDeployParams;
  };

  private _waitDeploy = async () => {
    const waitDeployParams = await this._getDeployParams();
    if (!waitDeployParams) {
      this._setDeployStatus(LoadState.Error);
      return;
    }

    const { provider, hash } = waitDeployParams;

    try {
      this._setDeployStatus(LoadState.Loading);

      const receipt = await provider.waitForTransaction(hash);
      if (isTransactionSuccess(receipt)) {
        this._setDeployStatus(LoadState.Success);
        return receipt;
      }
      this._setDeployStatus(LoadState.Error);
      return null;
    } catch (error) {
      chainErrorHandler(error);
      this._setDeployStatus(LoadState.Error);
    }
  };

  getDeployerStatus = async (prevStepCb: StepAction) => {
    this._setLoading(true);

    try {
      await this._deployerInfo.getDeployerInfo(this._botUUID);

      const deployStatus = this._info.status;

      // if the deployment is unsuccessful,
      // the transaction status is not tracked,
      // return to the deployment stage
      if (deployStatus !== "OK" && deployStatus !== "pending" && deployStatus !== "") {
        this._failedDeploy(deployStatus);
        prevStepCb();
      } else await this._waitDeploy();
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  private _failedDeploy = (status: string) => {
    showErrorMsg(`Deploy failed
    
    Message: ${status}
    `);
  };

  destroy = () => {
    this._waitDeployDepsReaction?.cancel();
  };
}
