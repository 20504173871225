import { saveAs } from "file-saver";
import { IReactionDisposer, makeAutoObservable, observable, reaction, runInAction } from "mobx";
import { getHashes, getWallets } from "src/api/bots/DEX/stats";
import { toast } from "src/components/shared/Toaster";
import { toCSV } from "src/helpers/csv";
import {
  Networks,
  getBalanceFloat,
  getBalanceHelperContract,
  getBalanceNativeFloat,
} from "src/helpers/getBalances";

import exch from "src/json/exchanges.json";

type ExchangesMetaType = typeof exch;

type ExchangeList = keyof ExchangesMetaType;

export interface Wallet {
  open: string;
}

interface WalletsAddresses {
  accounts: Wallet[];
  left: string;
  right: string;
  trackAccounts: Wallet[];
}

export interface BalancePoint {
  value: string;
  allowance: boolean | null;
}

interface Balance {
  nativeBalances: BalancePoint[];
  leftBalances: BalancePoint[];
  rightBalances: BalancePoint[];
}

type BalanceKeys = keyof Balance;

interface ConvertBalances {
  accounts: Balance;
  trackAccounts: Balance;
}

interface IHashesResponse {
  Data: string[];
  Page: number;
  Pages: number;
}

const EMPTY_CSV_HASHES = { data: "" };

const EMPTY_WALLETS: WalletsAddresses = {
  accounts: [],
  left: "",
  right: "",
  trackAccounts: [],
};

const EMPTY_HASHES: IHashesResponse = {
  Data: [],
  Page: 0,
  Pages: 0,
};

export class WalletsStore {
  private _botUUID = "";

  market = "";

  wallets: WalletsAddresses = EMPTY_WALLETS;

  hashes: IHashesResponse = EMPTY_HASHES;

  hashesCount = 0;

  private _allHashesCSV = EMPTY_CSV_HASHES;

  link = "";

  exchanges: ExchangesMetaType;

  convertBalances: ConvertBalances = {
    accounts: {
      nativeBalances: [],
      leftBalances: [],
      rightBalances: [],
    },
    trackAccounts: {
      nativeBalances: [],
      leftBalances: [],
      rightBalances: [],
    },
  };

  loadWallets = false;

  loadHashes = false;

  private _saveAllHashesReaction?: IReactionDisposer = undefined;

  constructor() {
    makeAutoObservable<this, "_allHashesCSV">(this, {
      _allHashesCSV: observable.ref,
    });
    // set exchanges info from json file
    this.exchanges = exch;
  }

  get botUUID() {
    return this._botUUID;
  }

  get exchangeMeta() {
    if (!this.exchange) return null;
    return this.exchanges[this.exchange];
  }

  setMarket = (value: string) => {
    this.market = value;
  };

  subscribe = () => {
    this._saveAllHashesReaction = reaction(
      () => this._allHashesCSV,
      (hashesCSV) => {
        const csv = hashesCSV?.data;
        if (!csv) return;
        const blob = new Blob([csv], {
          type: "text/csv;charset=utf-8;",
        });
        const fileName = `${this.market}_hashes`;
        saveAs(blob, `${fileName}.csv`);
        this._clearAllHashesCSV();
      }
    );
  };

  _clearAllHashesCSV = () => {
    this._allHashesCSV = EMPTY_CSV_HASHES;
  };

  unsubscribe = () => {
    this._saveAllHashesReaction?.();
  };

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;

    this.convertBalances = {
      accounts: {
        nativeBalances: [],
        leftBalances: [],
        rightBalances: [],
      },
      trackAccounts: {
        nativeBalances: [],
        leftBalances: [],
        rightBalances: [],
      },
    };
  };

  setLoad = (field: "loadWallets" | "loadHashes", bool: boolean) => {
    this[field] = bool;
  };

  setWalletValue = (arr: string[], wallets: Wallet[]) => {
    if (wallets) {
      for (const el of wallets) {
        arr.push(el.open);
      }
    }
  };

  setBalances = (
    arr: BalancePoint[] = [],
    startField: "accounts" | "trackAccounts",
    endField: BalanceKeys
  ) => {
    this.convertBalances[startField][endField] = arr;
  };

  get exchange() {
    if (this.market) return this.market.split("_")[2] as ExchangeList;
    return "";
  }

  setTextClipboard = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Copied!");
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    } else this.fallback(text);
  };

  get _balancesHelperContract() {
    if (!this.exchanges || !this.exchange) return null;

    const exchangeInfo = this.exchanges[this.exchange];

    const { network } = exchangeInfo;
    return getBalanceHelperContract(network as Networks);
  }

  getNativeBalances = (
    walletsAccountValue: string[],
    walletsTrackValue: string[],
    field: BalanceKeys
  ) => {
    const contract = this._balancesHelperContract;
    if (!contract) return;

    getBalanceNativeFloat(contract, walletsAccountValue).then((data) => {
      this.setBalances(data, "accounts", field);
    });

    getBalanceNativeFloat(contract, walletsTrackValue).then((data) => {
      this.setBalances(data, "trackAccounts", field);
    });
  };

  getBalances = (
    walletsAccountValue: string[],
    walletsTrackValue: string[],
    token: string,
    field: BalanceKeys
  ) => {
    const contract = this._balancesHelperContract;
    if (!contract || !this.exchange) return;

    const exchangeMeta = this.exchanges[this.exchange];

    if (!exchangeMeta) return;

    getBalanceFloat(contract, token, walletsAccountValue, exchangeMeta.spender).then((data) => {
      this.setBalances(data, "accounts", field);
    });

    getBalanceFloat(contract, token, walletsTrackValue, exchangeMeta.spender).then((data) => {
      this.setBalances(data, "trackAccounts", field);
    });
  };

  getWallets = async () => {
    this.setLoad("loadWallets", true);

    try {
      const { data, isError } = await getWallets(this._botUUID);
      if (isError) return;
      const { wallets } = data;

      runInAction(() => {
        this.wallets = wallets;
      });

      const walletsAccountValue: string[] = [];
      const walletsTrackValue: string[] = [];
      this.setWalletValue(walletsAccountValue, wallets.accounts);
      this.setWalletValue(walletsTrackValue, wallets.trackAccounts);

      this.getNativeBalances(walletsAccountValue, walletsTrackValue, "nativeBalances");
      this.getBalances(walletsAccountValue, walletsTrackValue, wallets.left, "leftBalances");
      this.getBalances(walletsAccountValue, walletsTrackValue, wallets.right, "rightBalances");
    } finally {
      this.setLoad("loadWallets", false);
    }
  };

  updateHashes = () => {
    this.getHashes(0, 15);
  };

  getAllHashes = async () => {
    if (this.hashesCount === 0) return;

    this.setLoad("loadHashes", true);

    try {
      const { data, isError } = await getHashes(this._botUUID, 0, this.hashesCount);

      if (!isError) {
        const { hashes } = data;
        const hashesCSV = toCSV([hashes.Data], [this.market], "Columns");
        // this.setData("_allHashesCSV", { data: hashesCSV });

        runInAction(() => {
          this._allHashesCSV = { data: hashesCSV };
        });
      } else {
        this._clearAllHashesCSV();
      }
    } catch {
      this._clearAllHashesCSV();
    } finally {
      this.setLoad("loadHashes", false);
    }
  };

  getHashes = async (page: number, size: number) => {
    this.setLoad("loadHashes", true);

    try {
      const { data, isError } = await getHashes(this._botUUID, page, size);
      if (!isError) {
        const { hashes } = data;

        runInAction(() => {
          this.hashes = hashes;
        });

        const pagesCount = hashes?.Pages ?? 0;

        runInAction(() => {
          this.hashesCount = size * pagesCount;
        });
      }
    } finally {
      this.setLoad("loadHashes", false);
    }
  };

  private _resetHashes = () => {
    this.hashes = EMPTY_HASHES;
  };

  copyWalletHandler = (text: string) => () => {
    this.setTextClipboard(text);
  };

  copyAllWallet = (field: "MM" | "Track") => () => {
    if (this.wallets) {
      const text = [];
      if (field === "MM") {
        for (const el of this.wallets.accounts) {
          text.push(el.open);
        }
      } else if (field === "Track") {
        for (const el of this.wallets.trackAccounts) {
          text.push(el.open);
        }
      }
      const allText = text.join("\n");
      this.setTextClipboard(allText);
    }
  };

  fallback = (text: string) => {
    const isIos = navigator.userAgent.match(/ipad|iphone/i);
    const textarea = document.createElement("textarea");

    // create textarea
    textarea.value = text;

    // ios will zoom in on the input if the font-size is < 16px
    textarea.style.fontSize = "20px";
    document.body.appendChild(textarea);

    // select text
    if (isIos) {
      const range = document.createRange();
      range.selectNodeContents(textarea);

      const selection = window.getSelection();

      if (!selection) return;

      selection.removeAllRanges();
      selection.addRange(range);
      textarea.setSelectionRange(0, 999999);
    } else {
      textarea.select();
    }

    // copy selection
    document.execCommand("copy");

    // cleanup
    document.body.removeChild(textarea);

    toast.success("Copied!");
  };

  destroy = () => {};
}
