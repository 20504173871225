import { ComponentPropsWithoutRef } from "react";
import Icons from "src/icons/Icons";
import { PNLV2Direction, PNLV2ValueInfo } from "../../PNLV2Item";
import * as styles from "./style";

interface UsePNLV2ArrowParams extends Pick<PNLV2ValueProps, "showArrow"> {
  direction: PNLV2Direction;
}

const usePNLV2Arrow = ({ direction, showArrow }: UsePNLV2ArrowParams) => {
  if (!showArrow || direction === "neutral") return null;

  return direction === "profit" ? Icons.arrowUpV2() : Icons.arrowDownV2();
};

export interface PNLV2ValueOwnProps extends Partial<PNLV2ValueInfo> {
  showArrow?: boolean;
}

export interface PNLV2ValueProps extends PNLV2ValueOwnProps, ComponentPropsWithoutRef<"span"> {
  children?: string;
}

export const PNLV2Value = ({
  children,
  direction = "neutral",
  showArrow,
  color,
}: PNLV2ValueProps) => {
  const Arrow = usePNLV2Arrow({ direction, showArrow });

  return (
    <styles.StyledPNLV2Value $color={color}>
      {children}
      {Arrow}
    </styles.StyledPNLV2Value>
  );
};
