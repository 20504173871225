import { Label } from "src/components/shared/Forms/Label";
import { Label as StyledLabel } from "src/components/shared/Forms/Label/style";
import { TitleSpan } from "src/components/shared/shared";
import styled from "styled-components";

export const CurrentLimit = styled(TitleSpan)``;

export const CurrentLimitQuote = styled(CurrentLimit)``;

export const CurrentLimitValue = styled(CurrentLimit)`
  font-family: "Roboto";
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const SpreadLimitsColumnHeader = styled(Label)`
  margin-bottom: -12px;
  justify-self: start;

  ${StyledLabel} {
    font-weight: ${({ theme }) => theme.fontWeights[600]};
    font-size: ${({ theme }) => theme.fontSizes["13px"]};
  }
`;

export const SpreadLimitsRowHeader = styled(TitleSpan)`
  grid-column: row-headers;
  margin-right: -3px;
  justify-self: start;
`;

export const SpreadLimitsTable = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: [row-headers] min-content [items-start] repeat(2, 1fr) [items-end];
  column-gap: 12px;
  row-gap: 18px;
`;
