import { createContextProvider } from "src/context/utils/createContextProvider";
import useLocalStore from "src/hooks/useLocalStore";
import { FundingDEXStore } from "src/state/DEX/fundingDEX";

const FundingDEXStoreCreator = () => useLocalStore(FundingDEXStore);

const { Context: FundingDEXContext, Provider: FundingDEXProvider } =
  createContextProvider<FundingDEXStore>({
    stateCreator: FundingDEXStoreCreator,
  });

export { FundingDEXContext, FundingDEXProvider };
