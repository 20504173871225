import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { PermissionProps, PermissionWrapper } from "src/permissions/PermissionWrapper";
import * as styles from "./style";

export interface SubmitButtonGroupProps extends ComponentPropsWithoutRef<"div">, PermissionProps {
  formId?: string;
}

export const SubmitButtonGroup = observer(
  ({ formId, children, party, abilityName, ...props }: SubmitButtonGroupProps) => (
    <PermissionWrapper party={party} abilityName={abilityName}>
      <styles.ButtonGroupWrapper {...props}>
        {children}
        <SubmitButton stretched form={formId} />
      </styles.ButtonGroupWrapper>
    </PermissionWrapper>
  )
);
