import { observer } from "mobx-react-lite";
import React from "react";
import * as styles from "./style";

interface InfoRowsProps {
  created: string;
  updated: string;
  uuid: string;
  style?: React.CSSProperties | undefined;
}

export const InfoRows = observer(({ created, updated, uuid, ...props }: InfoRowsProps) => (
  <styles.InfoWrapper {...props}>
    <styles.InfoRow>
      created at: <styles.InfoRow>{created}</styles.InfoRow>{" "}
    </styles.InfoRow>
    <styles.InfoRow>
      updated at: <styles.InfoRow> {updated}</styles.InfoRow>
    </styles.InfoRow>
    <styles.InfoRow>
      account uuid: <styles.InfoRow>{uuid}</styles.InfoRow>
    </styles.InfoRow>
  </styles.InfoWrapper>
));
