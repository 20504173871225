import { makeAutoObservable } from "mobx";
import { IBotUUIDProvider } from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { IHashesDownloadStoreProvider } from "../HashesDownloadStore";
import { HashType, IBotNameProvider } from "../HashesTypeStore";
import { IHashesPaginationFetcherProvider } from "./HashesPaginationFetcherProvider";

export class HashesDownloadProvider implements IHashesDownloadStoreProvider {
  private _type: HashType;

  private _uuidProvider: IBotUUIDProvider;

  private _botNameProvider: IBotNameProvider;

  private _fetcherProvider: IHashesPaginationFetcherProvider<HashType>;

  constructor(
    type: HashType,
    uuidProvider: IBotUUIDProvider,
    botNameProvider: IBotNameProvider,
    fetcherProvider: IHashesPaginationFetcherProvider<HashType>
  ) {
    makeAutoObservable(this);

    this._type = type;
    this._uuidProvider = uuidProvider;
    this._botNameProvider = botNameProvider;
    this._fetcherProvider = fetcherProvider;
  }

  private _getHashesFetcher = () => this._fetcherProvider.getHashesFetcher();

  getItems = async (_itemsCount: number) => {
    const uuid = this._uuidProvider.botUUID;
    if (!uuid) return;
    const getHashes = this._getHashesFetcher();

    const response = await getHashes(uuid);
    if (response) {
      const { items } = response;
      const hashes = items.map((item) => item.hash);
      return hashes;
    }
  };

  get columName() {
    const name = this._botNameProvider.botName;
    return name ? `${name}_${this._type}` : this._type;
  }

  get fileName(): string {
    return this.columName;
  }
}
