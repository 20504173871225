import { Headline4 } from "src/components/shared/Typography";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";
import { ProgressBar } from "../../../shared/ProgressBar";

export const Container = styled.div`
  position: relative;
  padding: 12px 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  ${CardSurface};

  box-shadow: none;
`;

export const Title = styled(Headline4)`
  font-size: ${({ theme }) => theme.fontSizes["8px"]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.dashboard.textSecondary};
  letter-spacing: -0.2px;
  text-align: center;
`;

export const TotalVolumeBar = styled(ProgressBar)`
  height: 100%;
`;
