import { observer } from "mobx-react-lite";
import { forwardRef } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { PopupComponent } from "src/components/shared/popup/PopupComponent";
import { CreateModulesProvider, ModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CreateModule } from "./CreateModule";

const AddModuleBtn = forwardRef<HTMLButtonElement>(({ ...props }, ref) => (
  <OutlineButton size="m" ownRef={ref} {...props}>
    + Add module
  </OutlineButton>
));

export const CreationModal = observer(() => {
  const { onModuleCreate } = useLateInitContext(ModulesContext.Context);

  return (
    <PopupComponent trigger={<AddModuleBtn />}>
      <CreateModulesProvider onModuleCreate={onModuleCreate}>
        <CreateModule />
      </CreateModulesProvider>
    </PopupComponent>
  );
});
