import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface ContentPanelProps extends ComponentPropsWithoutRef<"div"> {
  title?: string;
}

export const ContentPanel = ({ title, children, ...props }: ContentPanelProps) => (
  <styles.Container {...props}>
    <styles.Header>
      <styles.Title>{title}</styles.Title>{" "}
    </styles.Header>

    <styles.Content>{children}</styles.Content>
  </styles.Container>
);
