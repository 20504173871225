import styled from "styled-components";
import { TableCellText } from "src/components/shared/TableV2";

export const Wrapper = styled.div`
  width: 135px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled(TableCellText)`
  width: auto;
`;

export const Value = styled(TableCellText)`
  width: auto;
  color: ${({ theme }) => theme.labelColor};
`;
