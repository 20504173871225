import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { useControlledValue } from "src/hooks/useControlledValue";
import { InputSetPage } from "./InputSetPage";
import * as styles from "./style";

interface SelectedItem {
  selected: number;
}

export interface PaginationOwnProps extends Pick<ReactPaginateProps, "pageCount" | "forcePage"> {
  onPageChange?(page: number): void;
  onForcePageChange?: (page: number) => void;
  itemsPerPage: number;
  getItems: (fromPage: number, toPage: number) => void;
}

export interface PaginationProps
  extends PaginationOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof PaginationOwnProps> {}

export const Pagination = observer(
  ({
    onPageChange,
    pageCount,
    getItems,
    itemsPerPage,
    onForcePageChange,
    forcePage,
    ...props
  }: PaginationProps) => {
    const [forcePageValue, setForcePage] = useControlledValue({
      controlled: forcePage,
      default: 0,
      onChange: onForcePageChange,
    });

    const handlePageClick = useCallback(
      ({ selected: newPage }: SelectedItem) => {
        getItems(newPage, itemsPerPage);
        onPageChange?.(newPage);
        setForcePage(newPage);
      },
      [getItems, itemsPerPage, onPageChange, setForcePage]
    );

    return (
      <styles.Container {...props}>
        <styles.StyledPaginateContainer>
          <ReactPaginate
            breakLabel="..."
            // nextLabel=">"
            nextLabel={<styles.NextStep />}
            previousLabel={<styles.PrewStep />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={pageCount || 1}
            // renderOnZeroPageCount={null}
            breakClassName="break-me"
            containerClassName="pagination"
            activeClassName="active"
            forcePage={forcePageValue}
          />
        </styles.StyledPaginateContainer>
        <InputSetPage
          pageCount={pageCount}
          onForcePageChange={setForcePage}
          getItems={getItems}
          itemsPerPage={itemsPerPage}
        />
      </styles.Container>
    );
  }
);
