import { ActivationIndicator } from "src/components/shared/Indicators";
import { Indicators } from "src/state/MarketCheck/types";
import { MetaInfoIcon } from "./MetaInfoIcon";
import { useGetIndicatorsList } from "./useGetIndicatorsList";
import * as styles from "./style";

interface IndicatorsCellProps {
  cellData: Indicators;
}

export const IndicatorsCell = ({ cellData }: IndicatorsCellProps) => {
  const indicatorsFullList = useGetIndicatorsList(cellData);

  return (
    <styles.Wrapper>
      {indicatorsFullList.map(({ key, label, metaData, status }) => (
        <styles.Container key={key}>
          <styles.Label>
            {label}

            <MetaInfoIcon indicatorKey={key} indicatorMetaData={metaData} />
          </styles.Label>

          <ActivationIndicator status={status} />
        </styles.Container>
      ))}
    </styles.Wrapper>
  );
};
