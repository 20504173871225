import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { generateAmount, generatePercentChange, generateTokenTickers } from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type BalancesDynamicsData = {
  currency: string;
  percent: string;
  abs: string;
  absUsd: string;
};

export type BalancesDynamicsBars = {
  labels: string[];
  data: number[];
};

const generateBalancesDynamics = (): BalancesDynamicsData[] => {
  const tokensCount = faker.number.int({ min: 2, max: 5 });

  const tokens = generateTokenTickers({ count: tokensCount });

  const data = tokens.map((token) => {
    const percent = generatePercentChange();
    const abs = generateAmount();
    const absUsd = generateAmount();

    return {
      currency: token,
      percent,
      abs,
      absUsd,
    };
  });

  return data;
};

interface IBalancesDynamicsStoreParams extends IBaseDashboardV2StoreParams {}

export class BalancesDynamicsV2Store implements IDashboardV2WidgetState {
  private _data: BalancesDynamicsData[] = [];

  private _baseState: BaseWidgetV2Store;

  private _stateProvider: IDashboardV2StateProvider;

  constructor({ stateProvider }: IBalancesDynamicsStoreParams) {
    makeAutoObservable(this);

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    this._stateProvider = stateProvider;

    makeLoggable(this, { data: true, bars: true });
  }

  private _setData = (data: BalancesDynamicsData[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get loading() {
    return this._baseState.loading;
  }

  private get _tokens() {
    return this._data.map(({ currency }) => currency);
  }

  private get _tokensPercent() {
    const values = Object.values(this._data);
    const percentage = values.map(({ percent }) => +percent);
    return percentage;
  }

  get bars(): BalancesDynamicsBars {
    const values = this._tokensPercent;
    const labels = this._tokens;
    return { labels, data: values };
  }

  get absTotal() {
    return this._data.reduce((sum, { absUsd }) => sum + +absUsd, 0);
  }

  onStatsUpdate = async () => {
    const requestParams = this._stateProvider.getRequestParams();
    if (!requestParams) return;

    try {
      await delay(300);
      const data = generateBalancesDynamics();
      this._setData(data);
    } catch {
      this._setData([]);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
