import { Pagination } from "src/components/shared/Pagination";
import { TableSortMixin } from "src/components/shared/Table";
import { CardSurface, ScrollSize } from "src/components/shared/shared";
import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;

  height: 0px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding: 2.5px;

  ${CardSurface}
`;

const CenteringMixin = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 0px;

  display: flex;
  flex: 1 1 auto;

  overflow-x: auto;

  border-radius: 4px;

  ${ScrollSize}
`;

export const StateTable = styled.table`
  width: 100%;
  height: 0px;

  text-indent: 0px;
  border-color: inherit;
  border-spacing: 0;
`;

export const Head = styled.thead`
  position: sticky;
  top: 0;

  margin: 0;

  background-color: ${({ theme }) => theme.bgSettingsPnl};

  z-index: 1;
`;

export const StateTableBody = styled.tbody``;

export const Row = styled.tr`
  height: 30px;

  border-color: ${({ theme }) => theme.headerBorder};

  :hover {
    background-color: ${({ theme }) => theme.bgGrayBlack};
  }
`;

export const HeadRow = styled.tr`
  height: 30px;

  background-color: ${({ theme }) => theme.bgSettingsPnl};
`;

export const HeadCell = styled.th`
  position: relative;

  min-width: 35px;

  padding: 0px 5px;

  text-align: left;

  border: 1px solid;
  border-right: none;
  border-left: none;
  border-color: ${({ theme }) => theme.headerBorder};

  color: ${({ theme }) => theme.textColor};

  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  font-weight: ${({ theme }) => theme.fontWeights["700"]};

  ${TableSortMixin}
`;

export const StateTableCell = styled.td`
  min-width: 35px;

  padding: 0px 5px;

  border: 1px solid;
  border-right: none;
  border-left: none;
  border-color: ${({ theme }) => theme.headerBorder};

  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.textColor};
`;

export const CellText = styled.span`
  height: 100%;
  width: 100%;

  color: ${({ theme }) => theme.textColor};

  ${CenteringMixin}
  justify-content: flex-start;
  white-space: nowrap;
`;

export const StatePagination = styled(Pagination)`
  height: 40px;
`;
