import { ColumnDef } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { SeparatorNum } from "src/components/shared/Separator";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { getCurrentDayjs, stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import { CreatedLimitOrder } from "src/modules/exchange/trade";
import { AmountCell } from "../AmountCell";
import { VIEW_LIMIT_ORDER_PARAMS } from "../LimitOrders";
import { OrderInfoModal } from "../OrderInfoModal";
import { TableRows } from "../OrdersTable/TableRows";
import { InfoButton } from "../shared/InfoButton";
import { Side } from "../shared/style";
import { TotalFeesModal } from "./TotalFeesModal";
import * as styles from "./style";

interface AccountHistoryProps {
  party: string;
}

export const AccountHistory = observer(({ party }: AccountHistoryProps) => {
  const mainState = useLateInitContext(ExchangeContext);

  const state = mainState.accHistoryState;

  const [showModal, setShowModal] = useState(false);
  const [showFeeModal, setShowFeeModal] = useState(false);

  useEffect(() => {
    if (!mainState.currentAccID) return;
    state.downloadData();
  }, [state, mainState.currentAccID]);

  const columns = useMemo(() => {
    const columnSchema: ColumnDef<CreatedLimitOrder>[] = [
      {
        header: () => "Time",
        accessorKey: "time",
        maxSize: 80,
        cell: (info) => {
          const timeUNIXFormat = stringDateToUnix(info.getValue<string>());

          return <styles.CellText>{unixToDateFormat(timeUNIXFormat, "FullDate")}</styles.CellText>;
        },
      },
      {
        header: () => "Price",
        accessorKey: "price",
        enableResizing: false,
        cell: (info) => <SeparatorNum value={info.getValue<string>()} />,
      },
      {
        header: () => "Amount",
        accessorKey: "amount",
        enableResizing: false,
        cell: ({
          cell: {
            row: {
              original: { amount, price },
            },
          },
        }: any) => <AmountCell amount={amount} price={price} />,
      },
      {
        header: () => "Side",
        accessorKey: "side",
        maxSize: 50,
        cell: ({
          cell: {
            row: { original: order },
          },
        }: any) => (
          <Side side={order.side}>
            {order.side}
            <InfoButton
              onClick={() => {
                setShowModal(true);
                state.setSelectOrderId(order.id);
              }}
            />
          </Side>
        ),
      },
      {
        header: () => (
          <styles.WrapperCell>
            Fee
            <InfoButton onClick={() => setShowFeeModal(true)} />
          </styles.WrapperCell>
        ),
        accessorKey: "fee",
        enableResizing: false,
        enableSorting: false,
        cell: ({
          cell: {
            row: {
              original: { fee, feeCurrency },
            },
          },
        }) => (
          <styles.CellText>
            <SeparatorNum value={fee} /> <styles.Currency>{feeCurrency}</styles.Currency>
          </styles.CellText>
        ),
      },
    ];

    return columnSchema;
  }, [state]);

  return (
    <>
      <styles.Wrapper>
        <DateTimeRangePickerSelector
          value={state.rangeHistory}
          onChange={state.setRangeHistory}
          maxDate={getCurrentDayjs()}
          disableFuture
          popupProps={{
            position: "top left",
          }}
          mobileQuery="(max-width: 400px)"
        />
        <IconButton
          onClick={() => {
            state.uploadAccHistory();
          }}
        >
          {Icons.archive()}
        </IconButton>
      </styles.Wrapper>

      <TableRows
        data={state.orders}
        columns={columns}
        loader={state.isLoading}
        headTooltipProps={{
          amountAllOrders: state.orders.length,
          amountBuyOrders: state.amountBuyOrders,
          amountSellOrders: state.amountSellOrders,
        }}
      />
      {showModal ? (
        <OrderInfoModal
          label="Limit order info:"
          data={state.orderInfo}
          keys={VIEW_LIMIT_ORDER_PARAMS}
          show={showModal}
          close={setShowModal}
        />
      ) : null}

      {showFeeModal ? (
        <TotalFeesModal
          label="Total fees:"
          show={showFeeModal}
          close={setShowFeeModal}
          loading={false}
        />
      ) : null}
    </>
  );
});
