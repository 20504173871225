import { InfoButton } from "src/components/BotsContent/CEX/Exchange/TradePanel/Orders/shared/InfoButton";
import { RefButtonWrapper } from "src/components/shared/Buttons/v2/variants/RefButtonWrapper";
import { ModalPanelV2 } from "src/components/shared/ModalPanelV2";
import { StrategyListContext } from "src/context/CEX/ExpertSystem";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { InfoModalContent, InfoModalContentProps } from "./InfoModalContent";

export interface StrategyInfoModalProps extends InfoModalContentProps {}

export const StrategyInfoModal = ({ uuid }: StrategyInfoModalProps) => {
  const state = useLateInitContext(StrategyListContext);

  const strategy = state.findStrategy(uuid);

  return (
    <ModalPanelV2
      label={`Name: ${strategy?.name || ""}`}
      trigger={
        <RefButtonWrapper>
          <InfoButton size="l" />
        </RefButtonWrapper>
      }
    >
      <InfoModalContent uuid={uuid} />
    </ModalPanelV2>
  );
};
