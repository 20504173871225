import styled from "styled-components";
import { Graph } from "../../../../shared/Graph";
import { ReportItem } from "../style";

export const AssetsBalancesCont = styled(ReportItem)`
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  grid-area: assets-balances;
`;

export const AssetsGraphsContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-auto-rows: 250px;
  grid-gap: 10px;
  @media (max-width: 620px) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
`;

export const AssetsGraph = styled(Graph)`
  height: calc(100% - 60px);
`;

export const Subtitle = styled.h4`
  width: 100%;
  color: ${({ theme }) => theme.textColor};
`;

export const AssetGraphContainer = styled.div`
  position: relative;
  height: calc(100% - 18px);
  width: 100%;
  display: flex;
  flex-direction: column;
`;
