import { Chart } from "react-chartjs-2";
import { ChartPoint } from "src/modules/shared";
import { useBaseData, useBaseOptions } from "./hooks";
import * as styles from "./style";

interface PNLChartProps {
  series: ChartPoint[];
  className?: string;
}

export const PNLChart = ({ series, className }: PNLChartProps) => {
  const data = useBaseData(series);
  const options = useBaseOptions(series);

  return (
    <styles.ChartWrapper className={className}>
      <Chart type="line" options={options} data={data} height="100%" />
    </styles.ChartWrapper>
  );
};
