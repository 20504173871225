import { observer } from "mobx-react-lite";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { RefButtonWrapper } from "src/components/shared/Buttons/v2/variants/RefButtonWrapper";
import { ModalPanelV2 } from "src/components/shared/ModalPanelV2";
import { StrategyListContext } from "src/context/CEX/ExpertSystem";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import { LaunchesLimitUpdateForm, LaunchesLimitUpdateFormProps } from "./LaunchesLimitUpdateForm";

export interface LaunchesLimitUpdateModalProps extends LaunchesLimitUpdateFormProps {}

export const LaunchesLimitUpdateModal = observer((props: LaunchesLimitUpdateModalProps) => {
  const { launchesLimitLoading } = useLateInitContext(StrategyListContext);

  return (
    <ModalPanelV2
      label="Update launches limit"
      trigger={
        <RefButtonWrapper>
          <IconButton elevated size="s">
            {Icons.edit()}
          </IconButton>
        </RefButtonWrapper>
      }
      loading={launchesLimitLoading}
    >
      <LaunchesLimitUpdateForm {...props} />
    </ModalPanelV2>
  );
});
