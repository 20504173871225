import { Selector } from "src/components/shared/Forms/Selectors";
import { SELECTOR_CLASSNAME } from "src/components/shared/Forms/Selectors/shared/style";
import styled from "styled-components";

export const Container = styled.div``;

export const StyledSelectorFilter = styled(Selector)`
  width: auto;

  & .${SELECTOR_CLASSNAME}__single-value {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes["11px"]};
    color: currentColor;
  }

  & .${SELECTOR_CLASSNAME}__control {
    width: 80px;
    height: 24px;
  }

  & .${SELECTOR_CLASSNAME}__menu-list {
    min-height: 40px;
    max-height: 120px;
  }

  & .${SELECTOR_CLASSNAME}__dropdown-indicator {
    ::before {
      width: 4px;
      height: 4px;
    }
  }
` as typeof Selector;
