import { Price, Token } from "@uniswap/sdk-core";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import { useSwapWidgetState } from "../../hooks/useSwapWidgetState";
import { PriceText } from "./PriceText";
import * as styles from "./style";

const getPriceInfoMessage = (
  midPrice?: Price<Token, Token>,
  nextMidPrice?: Price<Token, Token>,
  executionPrice?: Price<Token, Token>
) => {
  const currentPrice = <PriceText label="Current price" price={midPrice} />;
  const nextPrice = <PriceText label="Next price" price={nextMidPrice} />;
  const averagePrice = <PriceText label="Average price" price={executionPrice} />;

  const prices = [currentPrice, averagePrice, nextPrice];

  const delimiter = " / ";

  return prices.reduce(
    (prevPrice, curPrice) =>
      prevPrice ? (
        <>
          {prevPrice}
          {delimiter}
          {curPrice}
        </>
      ) : (
        curPrice
      ),
    null as ReactNode
  );
};

export interface PriceInfoProps {}

export const PriceInfo = observer((_props: PriceInfoProps) => {
  const { swapInfoState } = useSwapWidgetState();
  const {
    price: { midPrice, nextMidPrice, executionPrice },
  } = swapInfoState.info;

  const message = getPriceInfoMessage(midPrice, nextMidPrice, executionPrice);

  return <styles.SwapPriceInfo>{message}</styles.SwapPriceInfo>;
});
