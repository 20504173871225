import useLocalStore from "src/hooks/useLocalStore";
import { MarketCheckContext } from "src/context/MarketCheck";
import { MarketCheckStore } from "src/state/MarketCheck";
import { PartiesList } from "./PartiesList";
import * as styles from "./style";

export const MarketCheck = () => {
  const state = useLocalStore(MarketCheckStore);

  return (
    <MarketCheckContext.Provider value={state}>
      <styles.ContentArea>
        <PartiesList />
      </styles.ContentArea>
    </MarketCheckContext.Provider>
  );
};
