import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { SeparatorNum } from "src/components/shared/Separator";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { toRounding } from "src/helpers/rounding";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "../shared/style";

interface AmountCellProps {
  amount: string;
  price: string;
}

export const AmountCell = observer(({ amount, price }: AmountCellProps) => {
  const exchangeState = useLateInitContext(ExchangeContext);

  const totalOpenOrder = useMemo(
    () => exchangeState.convertBase(parseFloat(amount), price ? parseFloat(price) : 0),
    [amount, price, exchangeState]
  );

  return (
    <styles.AmountWrapper
      data-tooltip-id="totalAmount"
      data-tooltip-content={toRounding(parseFloat(totalOpenOrder), exchangeState.pricePrecision)}
    >
      <SeparatorNum value={amount} />
    </styles.AmountWrapper>
  );
});
