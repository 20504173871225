import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: auto;

  padding: 10px;

  display: flex;

  font-weight: ${({ theme }) => theme.fontWeights["600"]};
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};

  ${CardSurface};
`;
