import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useLayoutEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Loader } from "src/components/shared/Loader";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { FloatingOrderParams } from "src/modules/exchange/trade";
import { CreateFloatingOrderStore } from "src/state/CEX/CEXExchange/trade/OpenOrders/BuySellOrders/CreateFloatingOrderStore";
import {
  ERRORS_MSGS,
  graterThan,
  strictlySmallerThan,
  wrapHookFormValidation,
} from "src/validation-schemas";
import { OrderPanelProps } from "../../shared/OrderPanel";
import { BuySellForm } from "../../shared/style";

export interface FloatingOrderFormProps extends Pick<OrderPanelProps, "side" | "formId"> {
  setOrderSize: Dispatch<SetStateAction<number | undefined>>;
}

export const FloatingOrderForm = observer(
  ({ formId, side, setOrderSize }: FloatingOrderFormProps) => {
    const mainState = useLateInitContext(ExchangeContext);
    const state = useLocalStore(CreateFloatingOrderStore, mainState);
    const [totalQuote, setTotalQuote] = useState<string>("");

    const { control, handleSubmit, setValue } = useFormContext<FloatingOrderParams>();

    const pricePercent = useWatch({ control, name: "price_percent" });
    const amount = useWatch({ control, name: "amount" });

    const totalQuoteHandler = (value: string) => {
      if (pricePercent) {
        const newAmount = state.calcAmount(side, pricePercent, parseFloat(value));

        setValue("amount", newAmount);
      }

      setTotalQuote(value);
    };

    useLayoutEffect(() => {
      if (pricePercent && amount) {
        const totalQuote = state.calcTotalQuote(side, pricePercent, amount);

        setTotalQuote(String(totalQuote));
      }
    }, [state, side, pricePercent, amount]);

    useLayoutEffect(() => {
      setOrderSize(parseFloat(totalQuote) || undefined);
    }, [totalQuote, setOrderSize]);

    return (
      <BuySellForm id={formId} onSubmit={handleSubmit(state.submitFloatingOrderHandler(side))}>
        <Controller
          control={control}
          name="price_percent"
          rules={{
            required: ERRORS_MSGS.isRequired,
            validate: {
              graterOne: wrapHookFormValidation(graterThan(1)),
              smallerHundred: wrapHookFormValidation(strictlySmallerThan(100)),
            },
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <LabeledInput
              label="Percent %"
              type="number"
              value={value}
              onChange={(e) => onChange(getChangeEventValue(e))}
              errorHint={errors.price_percent?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="amount_filled_limit"
          rules={{
            required: ERRORS_MSGS.isRequired,
            validate: {
              positiveNumber: wrapHookFormValidation(graterThan(0, "The number must be positive")),
            },
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <LabeledInput
              label="Amount fill limit"
              type="number"
              value={value}
              onChange={(e) => onChange(getChangeEventValue(e))}
              errorHint={errors.amount_filled_limit?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="amount"
          rules={{
            required: ERRORS_MSGS.isRequired,
            validate: {
              positiveNumber: wrapHookFormValidation(graterThan(0, "The number must be positive")),
            },
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <LabeledInput
              label="Amount"
              type="number"
              value={value}
              onChange={(e) => onChange(getChangeEventValue(e))}
              errorHint={errors.amount?.message}
            />
          )}
        />

        <LabeledInput
          label="Total quote &#8776;"
          value={totalQuote}
          onChange={(e) => totalQuoteHandler(String(getChangeEventValue(e)))}
        />

        <Loader show={state.isLoading} />
      </BuySellForm>
    );
  }
);
