import { observer } from "mobx-react-lite";
import { useLayoutEffect, useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { CSS_VARS, setCssVariable } from "src/helpers/cssVariables";
import { useClosePopup } from "src/hooks/useClosePopup";
import { BotSideMenu, NAV_PARAMS } from "./Content";
import * as styles from "./style";

interface SideMenuProps {
  isMobile: boolean;
}

interface SetSideMenuParams {
  isBotPage: boolean;
  setCssVariable: typeof setCssVariable;
  setIsVisible: (visible: boolean) => void;
  setShowSideMenuButton: (show: boolean) => void;
}

const SIDE_MENU_WIDTH = "80px";

const useIsBotPage = () => {
  const paths = NAV_PARAMS.map((el) => el.path);
  const match = useRouteMatch({ path: paths });

  return Boolean(match);
};

const setMobileSideMenu = ({
  isBotPage,
  setShowSideMenuButton,
  setIsVisible,
  setCssVariable,
}: SetSideMenuParams) => {
  setShowSideMenuButton(isBotPage);
  setIsVisible(false);
  setCssVariable(CSS_VARS.SIDE_MENU_SPACE, "0px");
};

const setDesktopSideMenu = ({
  isBotPage,
  setShowSideMenuButton,
  setIsVisible,
  setCssVariable,
}: SetSideMenuParams) => {
  setShowSideMenuButton(false);
  setIsVisible(isBotPage);
  setCssVariable(CSS_VARS.SIDE_MENU_SPACE, isBotPage ? SIDE_MENU_WIDTH : "0px");
};

export const SideMenu = observer(({ isMobile }: SideMenuProps) => {
  const isBotPage = useIsBotPage();

  const [isVisible, setIsVisible] = useState(false);
  const [isShowSideMenuButton, setShowSideMenuButton] = useState(false);

  const sidebarRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const setSideMenuParams: SetSideMenuParams = {
      isBotPage,
      setShowSideMenuButton,
      setIsVisible,
      setCssVariable,
    };

    if (isMobile) setMobileSideMenu(setSideMenuParams);
    else setDesktopSideMenu(setSideMenuParams);
  }, [isBotPage, isMobile, setIsVisible, setShowSideMenuButton]);

  const hideMobSideBar = (bool: React.SetStateAction<boolean>) => {
    if (isMobile) setIsVisible(bool);
  };

  useClosePopup(sidebarRef, hideMobSideBar);

  return (
    <styles.Container ref={sidebarRef}>
      {isShowSideMenuButton && (
        <styles.SideMenuButton isMenuVisible={isVisible} onClick={() => setIsVisible(!isVisible)} />
      )}

      <styles.SideMenuWrapper isVisible={isVisible} sideMenuWidth={SIDE_MENU_WIDTH}>
        <styles.SideMenuContent>
          <BotSideMenu />
        </styles.SideMenuContent>
      </styles.SideMenuWrapper>
    </styles.Container>
  );
});
