import { Component, ComponentType, ErrorInfo, PropsWithChildren } from "react";
import { toast } from "src/components/shared/Toaster";
import { CommonKeys } from "src/helpers/utils";
import { CrashPanel } from "./CrashPanel";

export interface ErrorBoundaryProps {
  fallback?: JSX.Element;
}

export interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): Partial<ErrorBoundaryState> | null {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    if (error instanceof Error) toast.error(error.message);
  }

  render() {
    const { hasError } = this.state;

    const { children } = this.props;

    if (hasError) {
      const { fallback } = this.props;

      if (fallback) {
        // You can render any custom fallback UI
        return fallback;
      }

      return <CrashPanel />;
    }

    return children;
  }
}

export const withErrorBoundary = <P extends JSX.IntrinsicAttributes>(
  Component: CommonKeys<P, ErrorBoundaryProps> extends never ? ComponentType<P> : never
): ComponentType<P & ErrorBoundaryProps> => {
  const WrappedComponent: ComponentType<P & ErrorBoundaryProps> = ({ fallback, ...props }) => {
    const errorBoundaryProps = { fallback };

    const OriginalComponent = Component as ComponentType<P>;

    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <OriginalComponent {...(props as P)} />
      </ErrorBoundary>
    );
  };

  // Format for display in DevTools
  const name = Component.displayName || Component.name || "Unknown";
  WrappedComponent.displayName = `withErrorBoundary(${name})`;

  return WrappedComponent;
};
