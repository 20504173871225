import { useFullscreen } from "src/hooks/useFullscreen";
import { PartiesListTable } from "./PartiesListTable";
import * as styles from "./style";

export const PartiesList = () => {
  const { isFullscreen, toggleFullscreen } = useFullscreen();

  return (
    <styles.Container isFullscreen={isFullscreen}>
      <styles.Title>Parties list</styles.Title>

      <PartiesListTable isFullscreen={isFullscreen} toggleFullscreen={toggleFullscreen} />
    </styles.Container>
  );
};
