import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { WalletTransaction } from "src/state/DEXV2/DEXV2Stats/GasWallets/WithdrawGasStore";
import { Transaction, TransactionOwnProps } from "./Transaction";
import * as styles from "./style";

export interface TransactionsListProps
  extends Omit<TransactionOwnProps, "transaction">,
    ComponentPropsWithoutRef<"ul"> {
  transactions: WalletTransaction[];
}

export const TransactionsList = observer(
  ({ transactions, txScannerLink, walletScannerLink, ...props }: TransactionsListProps) => {
    const items = transactions.map((transaction) => (
      <Transaction
        key={transaction.address}
        transaction={transaction}
        txScannerLink={txScannerLink}
        walletScannerLink={walletScannerLink}
      />
    ));

    return <styles.TransactionsList {...props}>{items}</styles.TransactionsList>;
  }
);
