import { DEXV2Settings } from "../types";

export const MODE_SWITCH_ITEMS = [
  { label: "Buy", value: "buy" },
  { label: "Sell", value: "sell" },
] as const;

export const TRIGGER_COMPARE_ITEMS = [
  { label: ">", value: "greater" },
  { label: "<", value: "less" },
] as const;
export const INITIAL_DEXV2_SETTINGS: DEXV2Settings = {
  party: "",
  bot_name: "",
  chain_id: 0,
  base: "",
  quote: "",
  market: "",
  receivers: [],
  market_router: "",
  pair_addr: "",
  dex_version: "",
  version: "",
  base_data: {
    tt_buy_fee: 0,
    tt_sell_fee: 0,
    gas_limit: 0,
    pool_percent: 0,
    stable: "",
  },
  volume_data: {
    period: 0,
    min_trades: 0,
    max_trades: 0,
    buy_percent: 0,
    min_amount: 0,
    max_amount: 0,
    slippage: "",
    gas_mult: 0,
    gas_price_limit: 0,
    use_receiver: false,
    status: false,
  },
  limit_data: {
    mod: "buy",
    trigger_compare: "greater",
    period: 0,
    price: 0,
    min_amount: 0,
    max_amount: 0,
    slippage: "",
    gas_mult: 0,
    gas_price_limit: 0,
    use_receiver: false,
    status: false,
  },
  counter_data: {
    black_listed_wallets: [],
    data: [],
    gas_mult: 0,
    gas_price_limit: "",
    status: false,
    cumulative: false,
    slippage: "",
  },
};
