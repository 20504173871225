import { FormValidation } from "src/helpers/forms/types";
import {
  graterThan,
  greaterThanNumberKey,
  isNumber,
  required,
  shortThan,
  smallerThanNumberKey,
  strictlyGreaterThan,
  strictlySmallerThan,
} from "src/validation-schemas";
import { DEXV2SettingsModule } from "../../types";
import { SLIPPAGE_VALIDATION } from "../../validation";

export const VOLUME_VALIDATION: FormValidation<DEXV2SettingsModule<"volume_data">> = {
  "volume_data.period": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "Period must be positive"),
    strictlySmallerThan(1480, "Period must be less than 1480"),
  ],
  "volume_data.min_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    smallerThanNumberKey("volume_data.max_amount", "Min Amount must be smaller than Max Amount"),
  ],
  "volume_data.max_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    greaterThanNumberKey("volume_data.min_amount", "Min Amount must be greater than Max Amount"),
  ],
  "volume_data.min_trades": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    smallerThanNumberKey("volume_data.max_trades", "Min Trades must be smaller than Max Trades"),
  ],
  "volume_data.max_trades": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    strictlySmallerThan(100, "Max Trades must be less than 100"),
    greaterThanNumberKey("volume_data.min_trades", "Min Trades must be greater than Max Trades"),
  ],
  "volume_data.buy_percent": required(),
  "volume_data.slippage": SLIPPAGE_VALIDATION,
  "volume_data.gas_mult": required(),
  "volume_data.gas_price_limit": [required(), isNumber(), graterThan(0, "Max Gas must be >= 0")],
};

export const LIMIT_VALIDATION: FormValidation<DEXV2SettingsModule<"limit_data">> = {
  "limit_data.period": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "Period must be positive"),
    strictlySmallerThan(3600, "Period must be less than 3600"),
  ],
  "limit_data.mod": required(),
  "limit_data.trigger_compare": required(),
  "limit_data.price": [required(), isNumber(), strictlyGreaterThan(0, "PriceUSD must be positive")],
  "limit_data.min_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    smallerThanNumberKey("limit_data.max_amount", "Min Amount must be smaller than Max Amount"),
  ],
  "limit_data.max_amount": [
    required(),
    isNumber(),
    strictlyGreaterThan(0, "The value must be positive"),
    greaterThanNumberKey("limit_data.min_amount", "Min Amount must be greater than Max Amount"),
  ],
  "limit_data.slippage": SLIPPAGE_VALIDATION,
  "limit_data.gas_mult": required(),
  "limit_data.gas_price_limit": [required(), isNumber(), graterThan(0, "Max Gas must be >= 0")],
};

export const BASE_VALIDATION: FormValidation<DEXV2SettingsModule<"base_data">> = {
  "base_data.tt_buy_fee": required(),
  "base_data.tt_sell_fee": required(),
  bot_name: [required(), shortThan(20)],
  "base_data.gas_limit": [
    required(),
    isNumber(),
    strictlyGreaterThan(21000, "Gas Limit must be greater than 21000"),
    strictlySmallerThan(20000000, "Gas Limit must be less than 20000000"),
  ],
};
