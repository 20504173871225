import { useCallback } from "react";
import { ChildrenProps, IDisposable, noOp } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import { useLocalStoreCreator } from "src/hooks/useLocalStore";
import { ISeriesState, SeriesStore } from "src/state/Graph/Series";
import { ISeriesStateOptions } from "src/state/Graph/Series/types";

export const SeriesContext = createLateInitContext<ISeriesState>();

export interface SeriesProviderProps extends ChildrenProps, ISeriesStateOptions {
  stateProvider?: ISeriesState;
}

const seriesStateStub: IDisposable = {
  destroy: noOp,
};

export const SeriesProvider = ({
  children,
  stateProvider,
  autoShowScales,
}: SeriesProviderProps) => {
  const SeriesStoreCreator = useCallback((): ISeriesState & IDisposable => {
    if (stateProvider) {
      // use stub in case outer state provider is passed
      return seriesStateStub as ISeriesState & IDisposable;
    }
    const store = new SeriesStore({ autoShowScales });
    return store;
  }, [autoShowScales, stateProvider]);

  const localState: ISeriesState = useLocalStoreCreator(SeriesStoreCreator);
  const state = stateProvider || localState;

  return <SeriesContext.Provider value={state}>{children}</SeriesContext.Provider>;
};
