import styled from "styled-components";
import { PanelContainer } from "../../../shared";

export const ModuleContainer = styled(PanelContainer)`
  flex: 0 0 auto;
`;

export const FormContent = styled.form`
  display: flex;
  flex: 1 1 auto;
`;
