import { Currency, CurrencyAmount } from "@uniswap/sdk-core";
import { useMemo } from "react";
import { LabeledInputProps } from "src/components/shared/Forms/Inputs";
import { Nullish } from "src/helpers/utils";
import { formatFiatAmount } from "src/state/DEXV2/DEXV2Swap/utils";
import { InfoPrompt } from "../../../shared/InfoPrompt";
import { SettingsInfoTooltip } from "../SettingsInfoTooltip";
import * as styles from "./style";

export interface PriceInfoInputProps extends Omit<LabeledInputProps, "labelAfter"> {
  price?: Nullish<CurrencyAmount<Currency>>;
}

export const PriceInfoInput = ({ price, ...props }: PriceInfoInputProps) => {
  const formattedPrice = useMemo(
    () => (price ? formatFiatAmount(price, { tickerPosition: "end" }) : undefined),
    [price]
  );

  return (
    <styles.StyledInfoInput
      labelAfter={<InfoPrompt message={formattedPrice} prompt={SettingsInfoTooltip} />}
      {...props}
    />
  );
};
