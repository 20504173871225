import { FC, useMemo } from "react";
import { Column } from "react-table";
import { LiquidGridCurrentError } from "src/state/CEX/liquidityTab/ActiveGrid";
import { TableProps, indexColumn } from "../../base/Table";
import ErrorCellWrapper from "./ErrorCellWrapper";
import * as styles from "./style";

export interface ErrorsTableProps
  extends Omit<TableProps<LiquidGridCurrentError>, "data" | "columns"> {
  errors: LiquidGridCurrentError[];
}

export const ErrorsTable = ({ errors, ...props }: ErrorsTableProps) => {
  const columns = useMemo(() => {
    const columnsSchema: Column<LiquidGridCurrentError>[] = [
      indexColumn(),
      {
        Header: "Error text",
        accessor: "text",
        Cell: ErrorCellWrapper,
      },
      {
        Header: "Time",
        accessor: "time",
        Cell: ErrorCellWrapper,
      },
    ];
    return columnsSchema;
  }, []);

  return (
    <styles.ErrorTableContent<FC<TableProps<LiquidGridCurrentError>>>
      data={errors}
      columns={columns}
      {...props}
    />
  );
};
