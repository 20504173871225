import { observer } from "mobx-react-lite";
import React from "react";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import ExchangeIcons from "src/icons/ExchangeIcons";
import * as styles from "./style";

export const LastTradePanel = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);
  const state = mainState.orderBookState;

  return (
    <styles.LastTradeContainer>
      <styles.Wrapper>
        <styles.LastTrade side={mainState.lastTrade ? mainState.lastTrade.side : ""}>
          {mainState.lastTrade?.price || "-"}
        </styles.LastTrade>

        <styles.SpreadPercent>
          ~{state.spreadPercent ? state.spreadPercent : ""}%
        </styles.SpreadPercent>
      </styles.Wrapper>

      <styles.ModesWrapper>
        <styles.OrderBookMode
          active={state.currentMode === "ALL"}
          onClick={() => {
            state.setCurrentMode("ALL");
          }}
        >
          {ExchangeIcons.orderBook()}
        </styles.OrderBookMode>

        <styles.OrderBookMode
          active={state.currentMode === "BUY"}
          onClick={() => {
            state.setCurrentMode("BUY");
          }}
        >
          {ExchangeIcons.buyOrders()}
        </styles.OrderBookMode>

        <styles.OrderBookMode
          active={state.currentMode === "SELL"}
          onClick={() => {
            state.setCurrentMode("SELL");
          }}
        >
          {ExchangeIcons.sellOrders()}
        </styles.OrderBookMode>
      </styles.ModesWrapper>
    </styles.LastTradeContainer>
  );
});
