import { useMemo } from "react";
import { entries } from "src/helpers/utils";

export const useParamsText = (params: Record<string, any>) => {
  const paramsText = useMemo(
    () =>
      entries(params)
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n"),
    [params]
  );

  return paramsText;
};
