import {
  Body,
  Cell,
  Header,
  HeaderCell,
  TableContainer,
  TableRow,
} from "src/components/shared/GridTable";
import styled from "styled-components";

export const BalancesContainer = styled.div`
  position: relative;

  display: flex;
  height: 0px;
  flex: 1 1 auto;
`;

export const BalancesTable = styled(TableContainer)`
  grid-template-rows: repeat(auto-fill, 20px);
  grid-template-columns:
    minmax(50px, 60px) minmax(min-content, 1fr)
    minmax(min-content, 1fr);

  column-gap: 5px;
`;

export const Head = styled(Header)``;

export const HeadRow = styled(TableRow)``;

export const HeadCell = styled(HeaderCell)`
  height: 20px;

  padding: 2.5px;

  overflow: inherit;

  font-size: ${({ theme }) => theme.fontSizes["11px"]};
`;

export const QuoteHeader = styled(HeadCell)``;

export const TableBody = styled(Body)`
  position: relative;
`;

export const Row = styled(TableRow)`
  font-size: ${({ theme }) => theme.fontSizes["15px"]};
`;

export const InfoRow = styled(TableRow)``;

export const TableCell = styled(Cell)`
  height: 20px;

  padding: 2.5px;

  overflow: inherit;
`;

export const QuoteCell = styled(TableCell)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
