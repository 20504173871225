import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 16px;
`;

export const FormContainer = styled.form`
  height: 0;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 16px;
  padding-right: 5px;

  overflow: auto;

  ${ScrollSize}
`;
