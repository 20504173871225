import { ComponentPropsWithoutRef } from "react";
import { InfoPrompt } from "./InfoPrompt";
import * as styles from "./style";

export interface HeaderCellProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
  info?: string;
  infoId?: string;
}

export const HeaderCell = ({ title, info, infoId }: HeaderCellProps) => (
  <styles.Container>
    <styles.Title>{title}</styles.Title>
    <InfoPrompt renderTooltip={false} id={infoId}>
      {info}
    </InfoPrompt>
  </styles.Container>
);
