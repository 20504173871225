import { observer } from "mobx-react-lite";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXV2VolumeInfo } from "src/state/DEXV2/DEXV2Settings/Info";
import { useBaseInfoFormat, useTokenFormat } from "../../../hooks";
import { InfoInput } from "../../../shared/InfoInput";
import { InfoPanelProps } from "../../../shared/InfoPanel";
import * as style from "../../../shared/style";

interface VolumeInfoDataParams {
  info: DEXV2VolumeInfo;
}

const useVolumeInfoData = ({ info }: VolumeInfoDataParams) => {
  const {
    averageVolume: { base: averageVolumeBase, usd: averageVolumeUsd },
  } = info;

  const baseInfo = useBaseInfoFormat({ info });

  const { usd: averageVolumeUsdText, value: averageVolumeBaseText } = useTokenFormat({
    value: averageVolumeBase,
    usd: averageVolumeUsd,
    formatSpace: true,
  });

  return {
    ...baseInfo,
    averageVolumeUsdText,
    averageVolumeBaseText,
  };
};

export interface VolumeInfoProps extends Omit<InfoPanelProps, "label" | "children"> {}

export const VolumeInfo = observer(({ ...props }: VolumeInfoProps) => {
  const { volumeInfo } = useLateInitContext(DEXV2SettingsContext);

  const {
    averageVolumeUsdText,
    averageVolumeBaseText,
    last24hFeesUsdText,
    last24hFeesNativeText,
    impactAverageText,
    impactBuySellText,
    gasNativeText,
    gasUsdText,
  } = useVolumeInfoData({ info: volumeInfo });

  return (
    <style.ModuleInfoPanel label="Volume Info" {...props}>
      <style.ModuleInfoContent>
        <style.FormCol>
          <style.FormRow>
            <InfoInput label="Price Impact" value={impactAverageText} info={impactBuySellText} />
            <InfoInput
              label="Fees paid last 24h"
              value={last24hFeesNativeText}
              info={last24hFeesUsdText}
            />
          </style.FormRow>
          <style.FormRow>
            <InfoInput
              label="Avg Volume 24h"
              value={averageVolumeBaseText}
              info={averageVolumeUsdText}
            />
            <InfoInput label="Transaction Cost" value={gasNativeText} info={gasUsdText} />
          </style.FormRow>
        </style.FormCol>
      </style.ModuleInfoContent>
    </style.ModuleInfoPanel>
  );
});
