import { Headline2 } from "src/components/shared/Typography";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormContainer = styled.div`
  display: flex;

  padding: 16px 20px;

  ${CardSurface}
`;

export const Title = styled(Headline2)``;
