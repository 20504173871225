export enum DashboardV2Widget {
  AccountBalances = "account-balances",
  Arbitrage = "arbitrage",
  Spread = "spread",
  AumSummary = "aum-summary",
  FiatBalance = "fiat-balance",
  TokensBalances = "tokens-balances",
  BalancesDynamics = "balances-dynamics",
  BalancesOverview = "balances-overview",
  BuyLiquidity = "buy-liquidity",
  SellLiquidity = "sell-liquidity",
  ChipCorrelation = "chip-correlation",
  ExchangesOverview = "exchanges-overview",
  ExchangesFee = "exchanges-fee",
  Pairs = "pairs",
  TotalVolumeShare = "total-volume-share",
  VolumeShare = "volume-share",
  PNLChart = "pnl-chart",
  CurrencyFee = "currency-fee",
  PNLSummary = "pnl-summary",
  AllDepth = "all-depth",
  PriceChart = "price-chart",
}

const V2_WIDGET_TITLES: Record<DashboardV2Widget, string> = {
  [DashboardV2Widget.AccountBalances]: "Balance by accounts",
  [DashboardV2Widget.Arbitrage]: "Arbitrage",
  [DashboardV2Widget.Spread]: "Spread",
  [DashboardV2Widget.AumSummary]: "Summary",
  [DashboardV2Widget.FiatBalance]: "Total USDT balance",
  [DashboardV2Widget.TokensBalances]: "Total token balance",
  [DashboardV2Widget.BalancesDynamics]: "Balance sheet dynamics",
  [DashboardV2Widget.BalancesOverview]: "Balance overview",
  [DashboardV2Widget.BuyLiquidity]: "Buy liquidity",
  [DashboardV2Widget.SellLiquidity]: "Sell liquidity",
  [DashboardV2Widget.ChipCorrelation]: "Blue chip correlation",
  [DashboardV2Widget.ExchangesOverview]: "Exchange overview",
  [DashboardV2Widget.Pairs]: "All pairs",
  [DashboardV2Widget.TotalVolumeShare]: "Share of Gotbit volume in total volume",
  [DashboardV2Widget.VolumeShare]: "Volume share",
  [DashboardV2Widget.PNLChart]: "Profits & Loss chart",
  [DashboardV2Widget.CurrencyFee]: "Fee by currency",
  [DashboardV2Widget.PNLSummary]: "PNL",
  [DashboardV2Widget.ExchangesFee]: "Fee by exchange",
  [DashboardV2Widget.AllDepth]: "All depth",
  [DashboardV2Widget.PriceChart]: "Price",
};

const BALANCES_OVERVIEW_INFO_TEXT = `This block shows the history of balances on all accounts on the chosen exchanges at the end of the displayed time interval.

Each value is divided into free and locked parts.
Locked balance is the balance that is used to trade and provide liquidity, Free balance is the balance that is not used in current active strategies (opposite to locked balance) and can be withdrawn at the moment.
Total is the sum of free and locked balances.`;

const BUY_LIQUIDITY_INFO_TEXT = `Bar charts show organic and Gotbit shares of liquidity in the orderbook for buy side orders.
Yellow line displays the average price of the ticker in USD.`;

const SELL_LIQUIDITY_INFO_TEXT = `Bar charts show organic and Gotbit shares of liquidity in the orderbook for sell side orders.
Yellow line displays the average price of the ticker in USD.
`;

const CHIP_CORRELATION_INFO_TEXT = "Correlation between the blue chips";

const EXCHANGES_OVERVIEW_INFO_TEXT = `This block shows the current distribution of balances across exchanges in USD. 
You can choose which values to show in the table below from the dropdown menu.`;

const VOLUME_SHARE_INFO_TEXT = `This block displays the shares of organic and Gotbit 24 hour volume on all chosen exchanges.
`;

const PNL_INFO_TEXT = `-Profit & Loss chart values at each time interval are calculated as the sum of quote currency change and base currency change for the period in USD equivalent.

-Period PnL takes into account history for the chosen period

-Monthly PnL takes into account history for the current and previous months respectively

-Absolute and Percent buttons display absolute and relative change respectively
`;

const PNL_SUMMARY_INFO_TEXT =
  "PNL is calculated based on the difference between the current value of your assets and the value of your assets at the time of purchase. PNL is calculated in USD.";

const V2_WIDGET_INFO: Record<DashboardV2Widget, string> = {
  [DashboardV2Widget.AccountBalances]: "",
  [DashboardV2Widget.Arbitrage]: "",
  [DashboardV2Widget.Spread]: "",
  [DashboardV2Widget.AumSummary]: "",
  [DashboardV2Widget.FiatBalance]: "",
  [DashboardV2Widget.TokensBalances]: "",
  [DashboardV2Widget.BalancesDynamics]: "",
  [DashboardV2Widget.BalancesOverview]: BALANCES_OVERVIEW_INFO_TEXT,
  [DashboardV2Widget.ExchangesFee]: "",
  [DashboardV2Widget.BuyLiquidity]: BUY_LIQUIDITY_INFO_TEXT,
  [DashboardV2Widget.SellLiquidity]: SELL_LIQUIDITY_INFO_TEXT,
  [DashboardV2Widget.ChipCorrelation]: CHIP_CORRELATION_INFO_TEXT,
  [DashboardV2Widget.ExchangesOverview]: EXCHANGES_OVERVIEW_INFO_TEXT,
  [DashboardV2Widget.Pairs]: "",
  [DashboardV2Widget.TotalVolumeShare]: "",
  [DashboardV2Widget.VolumeShare]: VOLUME_SHARE_INFO_TEXT,
  [DashboardV2Widget.PNLChart]: PNL_INFO_TEXT,
  [DashboardV2Widget.CurrencyFee]: "",
  [DashboardV2Widget.PNLSummary]: PNL_SUMMARY_INFO_TEXT,
  [DashboardV2Widget.AllDepth]: "",
  [DashboardV2Widget.PriceChart]: "",
};

export const getWidgetInfo = (name: DashboardV2Widget) => V2_WIDGET_INFO[name];

export const getWidgetTitle = (name: DashboardV2Widget) => V2_WIDGET_TITLES[name];
