import { AreaData } from "lightweight-charts";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { SeriesProps } from "src/components/shared/Graph/types";
import { FiatBalanceContext } from "src/context/CEX/Dashboard/v2/FiatBalance";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FiatBalanceData } from "src/state/CEX/CEXDashboard/v2/widgets/FiatBalanceV2Store";
import { CardOwnProps, CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { DashboardV2Widget, getWidgetTitle } from "../../shared/constants";
import { BaseBalanceChart } from "../shared/BaseBalanceChart";

interface UseSeriesProps {
  data: FiatBalanceData;
}

const useSeriesData = ({ data }: UseSeriesProps): SeriesProps[] => {
  const seriesProps = useMemo((): SeriesProps[] => {
    const series: SeriesProps[] = [
      {
        type: "Area",
        data: data as AreaData[],
        title: "USDT",
        options: {
          lineWidth: 1,
        },
      },
    ];
    return series;
  }, [data]);

  return seriesProps;
};

export interface FiatBalanceChartProps extends Omit<CardProps, keyof CardOwnProps> {}

export const FiatBalanceChart = observer((props: FiatBalanceChartProps) => {
  const { data, onRangeChange, loading } = useLateInitContext(FiatBalanceContext.Context);

  const seriesProps = useSeriesData({ data });

  return (
    <BaseBalanceChart
      title={getWidgetTitle(DashboardV2Widget.FiatBalance)}
      loading={loading}
      graphProps={{ series: seriesProps, request: onRangeChange }}
      {...props}
    />
  );
});

export interface FiatBalanceWidgetProps extends FiatBalanceChartProps {}

export const FiatBalanceWidget = (props: FiatBalanceWidgetProps) => (
  <FiatBalanceContext.Provider>
    <FiatBalanceChart {...props} />
  </FiatBalanceContext.Provider>
);
