import { Route, Switch } from "react-router-dom";
import { BotNavigation } from "./BotNavigation";

interface NavPath {
  path: string;
  botType: "CEX" | "DEX" | "DEX_V2";
}

export const NAV_PARAMS: NavPath[] = [
  { path: "/CEX/:path/", botType: "CEX" },
  { path: "/DEX/:path/", botType: "DEX" },
  { path: "/DEX_V2/:path/", botType: "DEX_V2" },
];

export const BotSideMenu = () => (
  <Switch>
    {NAV_PARAMS.map((el) => (
      <Route
        key={el.path}
        path={el.path}
        // eslint-disable-next-line react/no-children-prop
        children={() => <BotNavigation botType={el.botType} />}
      />
    ))}
  </Switch>
);
