import React, { useCallback, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { useVirtual } from "react-virtual";
import {
  flexRender,
  Row,
  useReactTable,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Loader } from "src/components/shared/Loader";
import * as styles from "./style";

interface TradeVirtualRowsProps {
  rowSize: number;
  columns: any;
  orders: any;
  loader: boolean;
}

export const TradeVirtualRows = observer(
  ({ orders, rowSize, columns, loader }: TradeVirtualRowsProps) => {
    const id = useMemo(() => nanoid(), []);

    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
      data: orders,
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
      estimateSize: useCallback(() => rowSize, [rowSize]),
    });
    const { virtualItems: virtualRows } = rowVirtualizer;

    return (
      <styles.Container ref={tableContainerRef}>
        <styles.TradeHistoryTable>
          <styles.Head data-tooltip-id={id}>
            {table.getHeaderGroups().map((headerGroup) => (
              <styles.HeadRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <styles.HeaderCell
                    key={header.id}
                    colSpan={header.colSpan}
                    column={header.column}
                    {...{
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </styles.HeaderCell>
                ))}
              </styles.HeadRow>
            ))}
          </styles.Head>
          <styles.Body
            style={{
              height: `${rowVirtualizer.totalSize}px`,
              width: "100%",
              position: "relative",
            }}
          >
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<any>;

              return (
                <styles.Row
                  key={row.id}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <styles.Cell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </styles.Cell>
                  ))}
                </styles.Row>
              );
            })}
          </styles.Body>
        </styles.TradeHistoryTable>

        <Loader show={loader} />
      </styles.Container>
    );
  }
);
