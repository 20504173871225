import { ISeriesApi, SeriesType } from "lightweight-charts";
import { toJS } from "mobx";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { ChartApiContext } from "src/context/Graph/ChartApi";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ISeriesApiState, SeriesApiStore } from "src/state/Graph/SeriesApiStore";
import {
  UseDefaultSeriesOptionsParams,
  UseSeriesOptionsParams,
  useDefaultSeriesOptions,
  usePriceLines,
  useSeriesOptions,
} from "./hooks";
import {
  ISeriesApiProvider,
  OptionalSeriesOptions,
  SeriesOptions,
  SeriesRootBaseProps,
} from "./types";
import { createSeries } from "./utils";

export type SeriesRootProps<T extends SeriesType> = SeriesRootBaseProps<T> & {
  onOptionsUpdate?: (id: string, options: SeriesOptions<T>) => void;
  onTitleUpdate?: (id: string, title: string) => void;
  showPriceSuffix?: boolean;
};

const SeriesInnerRoot = <T extends SeriesType>(
  {
    type,
    data = [],
    priceLines = [],
    visible = true,
    side = "left",
    showPriceSuffix = false,
    title,
    id,
    onOptionsUpdate,
    onTitleUpdate,
    startOptions,
    ...props
  }: SeriesRootProps<T>,
  ref: ForwardedRef<ISeriesApiProvider<T> | undefined>
) => {
  const chart = useLateInitContext(ChartApiContext);

  // useState to force useEffects and imperative handle rerun after series is attached to chart
  const [series, setSeries] = useState<ISeriesApiState<T> | null>(null);

  const { clearPriceLines, updatePriceLines } = usePriceLines({
    priceLines,
  });

  // explicitly clean data from any observables
  const seriesData = useMemo(() => toJS(data), [data]);

  // explicit sync callback to expose current options
  const syncUpdateOptions = useCallback(
    (series: ISeriesApi<T>, newOptions?: OptionalSeriesOptions<T>) => {
      if (newOptions) {
        series.applyOptions(newOptions);
      }
      const options = series.options() as SeriesOptions<T>;
      onOptionsUpdate?.(id, options);
    },
    [onOptionsUpdate, id]
  );

  useImperativeHandle(
    ref,
    () => {
      const seriesApi = series?.api;
      if (!seriesApi) return undefined;
      return {
        api: () => seriesApi,
        toggleVisibility: () => {
          const isVisible = !seriesApi.options().visible;
          syncUpdateOptions(seriesApi, { visible: isVisible });
        },
        show: () => {
          syncUpdateOptions(seriesApi, { visible: true });
        },
        hide: () => {
          syncUpdateOptions(seriesApi, { visible: false });
        },
      };
    },
    [series, syncUpdateOptions]
  );

  const defaultSeriesOptions = useDefaultSeriesOptions({
    showPriceSuffix,
    startOptions,
  } as UseDefaultSeriesOptionsParams<T>);

  const seriesOptions = useSeriesOptions({
    type,
    data: seriesData,
    title,
    ...props,
  } as UseSeriesOptionsParams);

  useEffect(() => {
    const currentChart = chart;
    const chartApi = currentChart?.api;
    if (!chartApi) return;

    const series = createSeries(type, chartApi, defaultSeriesOptions) as ISeriesApi<T>;
    const seriesState = new SeriesApiStore(series);
    setSeries(seriesState);
    syncUpdateOptions(series);

    return () => {
      const chartApi = currentChart?.api;

      seriesState.setDisposed();
      setSeries(null);
      const seriesApi = seriesState.api;

      clearPriceLines(seriesApi);

      chartApi?.removeSeries(series);
    };
  }, [chart, clearPriceLines, defaultSeriesOptions, syncUpdateOptions, type]);

  useEffect(() => {
    const seriesApi = series?.api;
    updatePriceLines(seriesApi);
  }, [series, updatePriceLines]);

  useEffect(() => {
    const seriesApi = series?.api;
    if (seriesApi) {
      syncUpdateOptions(seriesApi, seriesOptions);
    }
  }, [series, seriesOptions, syncUpdateOptions]);

  useEffect(() => {
    onTitleUpdate?.(id, title);
  }, [id, onTitleUpdate, series, title]);

  useEffect(() => {
    const seriesApi = series?.api;
    if (seriesApi) {
      syncUpdateOptions(seriesApi, { visible, priceScaleId: side });
    }
  }, [series, side, syncUpdateOptions, visible]);

  useEffect(() => {
    const chartApi = chart?.api;
    const seriesApi = series?.api;

    seriesApi?.setData(seriesData);
    chartApi?.timeScale().fitContent();
  }, [chart, series, seriesData]);

  return null;
};

export const SeriesRoot = forwardRef(SeriesInnerRoot) as <T extends SeriesType>(
  props: SeriesRootProps<T> & { ref?: ForwardedRef<ISeriesApiProvider<T> | undefined> }
) => ReturnType<typeof SeriesInnerRoot>;
