import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ModalPanel, ModalProps } from "src/components/shared/ModalPanel";
import { WithdrawModalContent, WithdrawModalContentProps } from "./WithdrawModalContent";

export interface ModalWithdrawParams
  extends Omit<WithdrawModalContentProps, "setLoading" | "onClose"> {}

export interface ModalWithdrawProps extends ModalProps, ModalWithdrawParams {}

export const ModalWithdraw = observer(
  ({ show, close, fromVault, tradePair }: ModalWithdrawProps) => {
    const [loading, setLoading] = useState(false);

    const contentProps: WithdrawModalContentProps = {
      onClose: close,
      fromVault,
      setLoading,
      tradePair,
    };

    return (
      <ModalPanel
        label={`Withdraw from ${fromVault?.type.toUpperCase()} vault`}
        show={show}
        close={close}
        loading={loading}
        zIndex={1000}
      >
        <WithdrawModalContent {...contentProps} />
      </ModalPanel>
    );
  }
);
