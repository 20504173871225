import { makeAutoObservable } from "mobx";

class CallBlockerStore {
  private _isBlocked = false;

  private _remainingTime = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get isBlocked() {
    return this._isBlocked;
  }

  get remainingTime() {
    return this._remainingTime;
  }

  setBlocked = (bool: boolean) => {
    this._isBlocked = bool;
  };

  blockFunctionCall = async (ms: number) => {
    if (this._isBlocked) {
      return;
    }

    this.setBlocked(true);
    this._remainingTime = ms / 1000;

    const interval = setInterval(() => {
      this._remainingTime -= 1;
      if (this._remainingTime <= 0) {
        clearInterval(interval);
        this.setBlocked(false);

        this._remainingTime = 0;
      }
    }, 1000);

    await new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

    clearInterval(interval);
    this.setBlocked(false);
    this._remainingTime = 0;
  };
}

export default CallBlockerStore;
