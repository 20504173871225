import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { Pagination } from "src/components/shared/Pagination";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { HashesContext } from "src/context/DEXV2/DEXV2Stats/Hashes/Hashes";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { HistoryHashesUnion } from "src/state/DEXV2/DEXV2Stats/Hashes";
import { HashType } from "src/state/DEXV2/DEXV2Stats/Hashes/HashesTypeStore";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { TablePanelProps } from "../TablePanel";
import { HashesMenuItem, InteractionPanel } from "./InteractionPanel";
import { VaultsHashesTable, VaultsHashesTableProps } from "./VaultsHashesTable";
import { WalletsHashesTable } from "./WalletsHashesTable";
import * as styles from "./style";

export interface UseHashesTableParams {
  hashes: HistoryHashesUnion;
  tableProps: Omit<VaultsHashesTableProps, "scanner" | "hashes">;
}

const useHashesTable = ({ hashes, tableProps }: UseHashesTableParams) => {
  switch (hashes.type) {
    case "limit":
    case "volume":
    case "counter": {
      return <VaultsHashesTable hashes={hashes.items} {...tableProps} />;
    }
    case "wallet": {
      return <WalletsHashesTable hashes={hashes.items} {...tableProps} />;
    }
  }
};

const HASHES_TYPES: HashesMenuItem[] = [
  {
    label: "Volume",
    value: "volume",
  },
  {
    label: "Limit",
    value: "limit",
  },
  {
    label: "Counter",
    value: "counter",
  },
  {
    label: "Wallet",
    value: "wallet",
  },
];

export const useHashesTableTabs = () => {
  const { hasAbility } = useLateInitContext(AbilitiesContext);
  const showLimit = hasAbility(DEXV2Ability.LimitHashView);
  const showVolume = hasAbility(DEXV2Ability.VolumeHashView);
  const showCounter = hasAbility(DEXV2Ability.CounterHashView);
  const showWallets = hasAbility(DEXV2Ability.WalletsHashView);

  const tabs = useMemo(
    () =>
      HASHES_TYPES.filter(({ value }) => {
        if (value === "limit" && showLimit) {
          return true;
        }
        if (value === "volume" && showVolume) {
          return true;
        }
        if (value === "counter" && showCounter) {
          return true;
        }
        if (value === "wallet" && showWallets) {
          return true;
        }
        return false;
      }),
    [showCounter, showLimit, showVolume, showWallets]
  );

  return tabs;
};

export interface HashesProps extends Pick<TablePanelProps, "style"> {
  tabs: HashesMenuItem[];
}

export const Hashes = observer(({ tabs, ...props }: HashesProps) => {
  const state = useLateInitContext(HashesContext);

  const botState = useLateInitContext(DEXV2BotContext);
  const { botUUID } = botState;

  const [tableRef, scrollToTop] = useScrollToTop<HTMLTableElement>();

  const refreshHashes = useCallback(() => {
    state.getHashes({ fromPage: 0, onSuccess: scrollToTop });
    state.setPage(0);
  }, [scrollToTop, state]);

  const onCurrentTypeChange = useCallback(
    (type: HashType) => {
      state.setCurrentHashType(type);
      refreshHashes();
    },
    [refreshHashes, state]
  );

  const getHashes = useCallback(
    (fromPage: number, toPage: number) => {
      state.getHashes({ fromPage, pageSize: toPage, onSuccess: scrollToTop });
    },
    [scrollToTop, state]
  );

  useEffect(() => {
    const defaultType = tabs[0]?.value;
    if (!botUUID || !defaultType) return;
    state.setBotUUID(botUUID);
    onCurrentTypeChange(defaultType);
  }, [botUUID, onCurrentTypeChange, refreshHashes, state, tabs, tabs.length]);

  const HashesTable = useHashesTable({
    hashes: state.hashes,
    tableProps: { ownRef: tableRef },
  });

  return (
    <styles.HashesPanel
      title="Bot hashes"
      afterTitle={
        <InteractionPanel
          items={tabs}
          currentType={state.currentHashType}
          setCurrentType={onCurrentTypeChange}
          onRefresh={refreshHashes}
          onDownload={state.downloadHashes}
        />
      }
      isLoading={state.loading}
      {...props}
    >
      <styles.Wrapper>
        <styles.Content>{HashesTable}</styles.Content>

        <Pagination
          onForcePageChange={state.setPage}
          forcePage={state.currentPage}
          pageCount={state.pagesCount}
          getItems={getHashes}
          itemsPerPage={state.itemsPerPage}
        />
      </styles.Wrapper>
    </styles.HashesPanel>
  );
});
