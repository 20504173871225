import styled from "styled-components";
import { KPICard } from "../../../../../shared/KPICard";
import { PNLValue } from "../../../ProfitLossChart/ProfitLossInfoPanel/InfoPanelSection/PNLValue";

export const Container = styled.div``;

export const KPIText = styled(PNLValue).attrs({ forwardedAs: "h4" })`
  margin: 0;
  padding: 0;
`;

export const PNLKPICard = styled(KPICard)`
  gap: 8px;
`;
