import { Button } from "src/components/shared/Buttons/v2/Button";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { noOp } from "src/helpers/utils";
import * as styles from "./style";

export interface DateTimeRangePickerButtonsProps {
  showReset?: boolean;
  onReset?: () => void;
  showAccept?: boolean;
  onAccept?: () => void;
}

export const DateTimeRangePickerButtons = ({
  showReset = true,
  onReset = noOp,
  showAccept = true,
  onAccept = noOp,
}: DateTimeRangePickerButtonsProps) => (
  <styles.ButtonsContainer>
    {showReset && (
      <OutlineButton size="m" stretched onClick={onReset}>
        Reset
      </OutlineButton>
    )}
    {showAccept && (
      <Button size="m" stretched onClick={onAccept}>
        Save
      </Button>
    )}
  </styles.ButtonsContainer>
);
