import styled from "styled-components";

export const HashesListItem = styled.li`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 14px;
`;

export const AddressWrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 5px;
`;
