import { observer } from "mobx-react-lite";
import { WalletGasFillContext } from "src/context/DEXV2/DEXV2Create/WalletGasFill";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import { Deployer } from "./Deployer";
import * as styles from "./style";

export const BalancesTable = observer(() => {
  const state = useLateInitContext(WalletGasFillContext);
  const { wallet, chainInfo, nativeTicker } = state;
  const scannerLink = getScannerLink(chainInfo, "wallet");

  return (
    <styles.BalanceContainer>
      <styles.BalanceHeader>
        <styles.BalanceRow>
          <styles.BalanceHeaderCell style={{ textAlign: "left" }}>Address</styles.BalanceHeaderCell>
          <styles.BalanceHeaderCell>Type</styles.BalanceHeaderCell>
          <styles.BalanceHeaderCell>{nativeTicker}</styles.BalanceHeaderCell>
        </styles.BalanceRow>
      </styles.BalanceHeader>

      <styles.BalanceBody>
        <Deployer {...wallet} scannerLink={scannerLink} />
      </styles.BalanceBody>
    </styles.BalanceContainer>
  );
});
