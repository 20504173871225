import { deleteAction, getActions } from "src/api/expertSystem";
import ModulesInfoStore, {
  DeleteModuleCb,
  GetModuleCb,
  IPartiesProvider,
} from "./ModulesInfoStore";

export class ActionsInfoStoreProvider {
  private _partiesProvider: IPartiesProvider;

  constructor(partiesProvider: IPartiesProvider) {
    this._partiesProvider = partiesProvider;
  }

  private _getActionsCb: GetModuleCb<"action"> = async (party, params) => {
    const { isError, data } = await getActions(party, params);

    if (!isError) {
      return data;
    }
    return null;
  };

  private _deleteConditionsCb: DeleteModuleCb = async (uuid) => {
    const { isError } = await deleteAction(uuid);

    return !isError;
  };

  createConditionsStore = () =>
    new ModulesInfoStore({
      partiesProvider: this._partiesProvider,
      getModules: this._getActionsCb,
      deleteModule: this._deleteConditionsCb,
    });
}
