import { makeAutoObservable } from "mobx";
import WalletsStore, { IWalletAddressValidation, IWalletsFormProvider } from "../WalletsStore";
import { TransferWalletsAdapter } from "./TransferWalletsAdapter";

export interface ITransferWalletsProvider {
  get transferWallets(): string[];
  setTransferWallets: (wallets: string[]) => void;
}

export default class TransferWalletsStore {
  private _transferWalletsState: IWalletsFormProvider;

  constructor(
    walletsProvider: ITransferWalletsProvider,
    walletValidationProvider: IWalletAddressValidation,
    validateWallets?: boolean
  ) {
    makeAutoObservable(this);

    const adapter = new TransferWalletsAdapter(walletsProvider);

    this._transferWalletsState = new WalletsStore(adapter, {
      walletValidationProvider,
      walletName: "transfer wallet",
      validateWallets,
    });
  }

  get transferWalletsState() {
    return this._transferWalletsState;
  }
}
