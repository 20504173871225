import { Slippage } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/SlippageStore";
import { DecimalInput, DecimalInputProps } from "../DecimalInput";

export interface SlippageInputProps
  extends Omit<DecimalInputProps, "onChange" | "value" | "errorHint" | "errorType" | "label"> {
  value: Slippage;
  onChange?: (slippage: string) => void;
}

export const SlippageInput = ({
  value: slippage,
  onChange: onSlippageChange,
  ...props
}: SlippageInputProps) => {
  const { slippage: slippageValue, error } = slippage;
  const errorHint = error?.message;
  const errorType = error?.type;

  return (
    <DecimalInput
      label="Slippage %"
      value={slippageValue}
      onChange={onSlippageChange}
      errorHint={errorHint}
      errorType={errorType}
      {...props}
    />
  );
};
