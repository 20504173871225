import styled, { css } from "styled-components";
import { Table } from "../../base/Table";

export const FlexScrollTable = (height: number) => css`
  width: 100%;
  height: 100%;
  max-height: ${height}px;

  display: flex;
  flex-flow: column;

  thead {
    flex: 0 0 auto;
  }

  tbody {
    display: block;

    flex: 1 1 auto;

    overflow-y: auto;
    overflow-x: hidden;
  }

  tr {
    width: 100%;

    display: table;
    table-layout: fixed;
  }
`;

export const ErrorTableContent = styled(Table)`
  ${FlexScrollTable(400)}

  th, td {
    :first-of-type {
      width: 5%;
    }

    :nth-of-type(2) {
      width: 70%;
    }
  }

  th {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  td {
    :last-of-type {
      padding-right: 3px;
    }

    font-weight: ${({ theme }) => theme.fontWeights.normal};

    :first-of-type {
      font-weight: ${({ theme }) => theme.fontWeights[500]};
    }

    :not(:first-of-type) {
      word-break: break-all;
    }
  }

  td,
  th {
    text-align: start;
    :last-of-type {
      text-align: center;
    }

    font-size: ${({ theme }) => theme.fontSizes["11px"]};
  }

  tr {
    color: ${({ theme }) => theme.darkGray};

    th,
    td {
      padding-bottom: 15px;
    }

    :last-of-type {
      td {
        padding-bottom: 0;
      }
    }
  }
`;
