import { CheckBox } from "src/components/shared/CheckBox";
import { Graph } from "src/components/shared/Graph";

const { default: styled } = require("styled-components");

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const GraphContainer = styled(Graph)`
  width: 100%;
  height: 210px;
  display: flex;
  & > * {
    font-family: Roboto !important;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTitleWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  & > div {
    flex-direction: row;
  }
  :after {
    position: absolute;
    display: block;
    /* width: 40vw; */
    height: 1px;
    background-color: gray;
    content: "";
    right: 5px;
    left: 250px;
  }
`;

export const SubTitle = styled.h4`
  margin-right: 15px;
  color: ${({ theme }) => theme.textColor};
`;

export const AssetCheckBox = styled(CheckBox)`
  color: ${({ theme }) => theme.assetColor};
`;

export const TokenCheckBox = styled(CheckBox)`
  color: ${({ theme }) => theme.tokenColor};
`;
