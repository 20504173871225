import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { ScrollSize } from "src/components/shared/shared";
import { BlockPanel } from "../BlockPanel";

export const Container = styled(BlockPanel)`
  grid-area: funding-form;
`;

export const FormContent = styled.form`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;

  gap: 20px;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  gap: 20px;

  @media (max-width: 550px) {
    flex-wrap: wrap;

    & > button {
      flex-grow: 1;
    }
  }
`;

export const TextArea = styled(TextareaAutosize)`
  width: 100%;

  padding: 9px 13px;
  background-color: ${({ theme }) => theme.bgGrayBlack};

  resize: none;
  outline: none;
  border: none;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  font-size: 1em;
  color: ${({ theme }) => theme.inputTextColor};

  :focus {
    box-shadow: ${({ theme }) => `0 0 0 1px ${theme.inputBorderColor}`};
  }

  ${ScrollSize}

  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.radii["10px"]};
    cursor: pointer;
    background-color: rgba(180, 180, 180, 0.5);
  }
`;
