import { ChainInfoChip as BaseChainInfoChip } from "src/components/AllBots/Bots/DEXV2Bots/shared/ChainInfoChip";
import { Warning } from "src/components/shared/Prompts/Warning";
import { Subtitle } from "src/components/shared/Typography";
import { CardSurface } from "src/components/shared/shared";
import styled, { css } from "styled-components";
import { FormCol, FormRow } from "../../Settings/shared/style";
import { TablePanelHeader } from "../../Stats/Tables/TablePanel/TablePanelHeader";
import { VaultTypeCell } from "../../Stats/Tables/Vaults/VaultTypeCell/VaultTypeCell";
import { SwapActionButton as BaseSwapActionButton } from "./SwapActionButton";

export interface DisabledSurfaceProps {
  $disabled?: boolean;
}

export const DisabledSurfaceMixin = css<DisabledSurfaceProps>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

export const Card = styled.div<DisabledSurfaceProps>`
  position: relative;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 14px;

  padding: 25px 20px 22px;

  ${CardSurface}

  ${DisabledSurfaceMixin}
`;

export const CardHeader = styled(TablePanelHeader)`
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 15px;
`;

export const CardRow = styled(FormRow)`
  gap: 14px;
  align-items: flex-end;
`;

export const SwapActionButton = styled(BaseSwapActionButton)`
  margin-top: auto;
`;

export const LargeVaultTypeCell = styled(VaultTypeCell)`
  width: 100%;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
`;

export const InputCol = styled(FormCol)`
  gap: 10px;
  position: relative;
`;

export const InputsWrapper = styled(FormCol)`
  gap: 15px;
`;

export const InfoRow = styled(FormRow)`
  justify-content: space-between;
  align-items: center;
`;

export const BalanceSpan = styled(Subtitle).attrs({ as: "span" })``;

export const GasPriceWrapper = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;

  > svg {
    width: 15px;
    height: auto;
  }
`;

export const GasPriceSpan = styled(BalanceSpan)`
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
`;

export const SwapPriceInfo = styled(Subtitle).attrs({ as: "p" })``;

export const WarningAlert = styled(Warning).attrs({ appearance: "danger" })``;

export const ChainInfoChip = styled(BaseChainInfoChip)`
  justify-content: flex-end;
`;
