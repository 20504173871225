import styled, { css } from "styled-components";

export interface OrientationProps {
  $orientation: "row" | "column" | "row-reverse" | "column-reverse";
}

export interface OrientationOuterProps {
  orientation: OrientationProps["$orientation"];
}

export interface ContainerProps extends OrientationProps {}

export const Container = styled.div<ContainerProps>`
  height: 100%;
  width: 100%;

  display: flex;

  ${({ $orientation: orientation }) => {
    switch (orientation) {
      case "column":
      case "column-reverse": {
        return css`
          flex-direction: ${orientation};
          gap: 20px;
        `;
      }
      case "row":
      case "row-reverse": {
        return css`
          flex-direction: row;
          gap: 27px;
        `;
      }
    }
  }}

  padding: 13px 17px 28px;
`;

export const Divider = styled.hr<OrientationProps>`
  margin: 0;
  border: none;

  ${({ $orientation: orientation }) => {
    switch (orientation) {
      case "column":
      case "column-reverse": {
        return css`
          border-bottom: 1px solid ${({ theme }) => theme.lineColor};
        `;
      }
      case "row":
      case "row-reverse": {
        return css`
          height: 100%;
          margin-top: 8px;
          border-right: 1px solid ${({ theme }) => theme.lineColor};
        `;
      }
    }
  }}

  border-radius: 15px;
`;
