import { SelectorDropdown } from "src/components/shared/DatePickers/DateTimeRangePickerSelector/SelectorDropdown";
import { Headline4 } from "src/components/shared/Typography";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const TimezoneText = styled(Headline4)`
  color: ${({ theme }) => theme.dashboard.textAdditional};
  font-weight: ${({ theme }) => theme.fontWeights[400]};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
`;

export const RangePickerSelector = styled(SelectorDropdown)`
  flex-basis: 250px;
  max-width: 250px;
  flex-grow: 1;
  flex-shrink: 1;
  ${({ size }) => {
    if (size === "small") {
      return css`
        flex-basis: auto;
      `;
    }
  }};
`;
