import styled from "styled-components";
import { StyledProps } from "src/helpers/styled/types";
import { ToggleButton } from "../ToggleButton/style";

export interface MultiToggleButtonStyleProps {
  error?: boolean;
}

export interface MultiToggleButtonStyledProps extends StyledProps<MultiToggleButtonStyleProps> {}

export const StyledMultiToggleButton = styled(ToggleButton)<MultiToggleButtonStyledProps>`
  box-shadow: ${({ theme, $error }) => ($error ? `0 0 0 1px ${theme.errorLabelColor}` : "none")};
`;
