import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import {
  FundingAbilitiesContext,
  FundingListProvider,
  FundingStoreContext,
  TransferListProvider,
} from "src/context/CEX/FundingProvider";
import { checkParty } from "src/helpers/checkParty";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { useAppState } from "src/state";
import { BotInfoProps } from "..";
import { FundingForm } from "./FundingForm";
import { FundingHeader } from "./FundingHeader";
import { FundingInfo } from "./FundingInfo/FundingInfo";
import { TransferList } from "./Tables/TransferList";
import * as styles from "./style";

export const VIEW_ACCOUNT_ABILITIES = "cex-exchange-view";

export const DISABLE_CONFIG = {
  enableSorting: false,
  enableColumnFilter: false,
};

export interface FundingProps extends BotInfoProps {
  abilityName: string;
}

export const CEXFunding = observer(({ abilityName, botInfo: { party, market } }: FundingProps) => {
  const { userState } = useAppState();
  const state = useLateInitContext(FundingStoreContext);

  const abilitiesProps = { abilityName };

  const checkPermsn = checkParty(userState.abilities, VIEW_ACCOUNT_ABILITIES, state.party);

  useEffect(() => {
    if (!party) return;
    state.setParty(party);
    if (checkPermsn) state.loadData();
  }, [state, party, checkPermsn]);

  return (
    <FundingAbilitiesContext.Provider value={abilitiesProps}>
      <styles.Container>
        <FundingHeader />
        <styles.Content>
          <FundingListProvider>
            <FundingInfo market={market} />
          </FundingListProvider>

          <PermissionWrapper party={state.party} abilityName={abilityName}>
            <FundingForm />
          </PermissionWrapper>

          <PermissionWrapper party={state.party} abilityName={VIEW_ACCOUNT_ABILITIES}>
            <TransferListProvider>
              <TransferList />
            </TransferListProvider>
          </PermissionWrapper>
        </styles.Content>
      </styles.Container>
    </FundingAbilitiesContext.Provider>
  );
});
