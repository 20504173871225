import { makeAutoObservable } from "mobx";
import { getBalances } from "src/api/bots/DEX/stats";
import { PNLV2Price } from "src/components/shared/PNLV2/shared/PNLV2Items";
import { calcRoundingValue } from "src/helpers/rounding";
import { ChartPoint } from "src/modules/shared";

interface DEXBalancesChartsGroup {
  left: ChartPoint[];
  right: ChartPoint[];
  native: ChartPoint[];
}

export class DEXStatsStore {
  private _botUUID = "";

  market = "";

  graphicsData: DEXBalancesChartsGroup = {
    left: [],
    right: [],
    native: [],
  };

  interval = 720;

  token = "";

  deltaToken = 0;

  deltaTotal = 0;

  price: PNLV2Price = 0;

  fees = 0;

  showLoader = false;

  constructor() {
    makeAutoObservable(this);
  }

  get bot_uuid() {
    return this._botUUID;
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  setMarket = (value: string) => {
    this.market = value;
  };

  setLoad = (bool: boolean) => {
    this.showLoader = bool;
  };

  setInterval = (value: number) => {
    this.interval = value;
    this.calculatePNLInfo(this.interval);
  };

  setGraphicsData = (data: DEXBalancesChartsGroup) => {
    if (data) {
      this.graphicsData = data;
      this.calculatePNLInfo(this.interval);
    }
  };

  clearPNLInfo = () => {
    this.deltaToken = 0;
    this.deltaTotal = 0;
    this.price = 0;
    this.fees = 0;
  };

  calculatePNLInfo = (interval: number) => {
    if (this.graphicsData.left.length) {
      const N = this.graphicsData.left.length - 1;
      let L = 0;
      const arr = this.graphicsData.left;

      const timeInterval = arr[N].time - interval * 60;
      for (let i = arr.length - 2; i > 0; i -= 1) {
        if (timeInterval >= arr[i].time && timeInterval <= arr[i + 1].time) {
          L = i;
          break;
        }
      }
      if (L && N) {
        const token = this.graphicsData.right[N].value - this.graphicsData.right[L].value;
        this.deltaToken = token;
        const total = this.graphicsData.left[N].value - this.graphicsData.left[L].value;
        this.deltaTotal = total;
        if (
          (total >= 0 && token >= 0) ||
          (total >= 0 && +token.toFixed(calcRoundingValue(token)) === 0) ||
          (+total.toFixed(calcRoundingValue(total)) === 0 && token >= 0)
        ) {
          this.price = "NET PROFIT";
        } else if (
          (total < 0 && token < 0) ||
          (total < 0 && +token.toFixed(calcRoundingValue(token)) === 0) ||
          (+total.toFixed(calcRoundingValue(total)) === 0 && token < 0)
        ) {
          this.price = "NET LOSS";
        } else {
          this.price = +Math.abs(this.deltaTotal / this.deltaToken).toFixed(
            calcRoundingValue(+Math.abs(this.deltaTotal / this.deltaToken))
          );
        }

        this.fees = this.graphicsData.native[N].value - this.graphicsData.native[L].value;
      } else {
        this.clearPNLInfo();
      }
    }
  };

  loadBalances = async () => {
    this.clearBalances();
    this.clearPNLInfo();

    this.setLoad(true);

    try {
      const { data, isError } = await getBalances(this._botUUID);
      if (!isError) {
        const { balances } = data;
        this.setGraphicsData(balances);
      }
    } finally {
      this.setLoad(false);
    }
  };

  clearBalances = () => {
    this.graphicsData = {
      left: [],
      right: [],
      native: [],
    };
  };

  updateBalances = () => {
    this.loadBalances();
  };

  destroy = () => {};
}
