import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useCallback, useContext, useRef } from "react";
import { noOp } from "src/helpers/utils";
import Icons from "src/icons/Icons";
import { ThemeContext } from "styled-components";
import { BotMark } from "../../shared/BotMark";
import { MobBotContainer, MobLine, Name } from "../../shared/shared";
import { copyBotCredential } from "../../shared/utils";
import { DEXV2BotProps, useBaseModeChipProps, useBotActionPermissions } from "../DEXV2Bot";
import { ChainInfoChip } from "../shared/ChainInfoChip";
import * as styles from "./style";

interface UseMobileChainInfoChipParams extends Pick<DEXV2BotMobProps, "chainMeta"> {
  isCompact: boolean;
}

const useMobileChainInfoChip = ({ chainMeta, isCompact }: UseMobileChainInfoChipParams) => {
  if (chainMeta === undefined) {
    return null;
  }
  if (chainMeta === null) {
    return <MobLine />;
  }
  if (isCompact) {
    return <styles.MobChainChip icon={chainMeta.icon} />;
  }

  return <ChainInfoChip {...chainMeta} />;
};

export interface DEXV2BotMobProps extends DEXV2BotProps {
  compactQuery?: string;
}

const DEFAULT_COMPACT_QUERY = "(max-width: 650px)";

export const DEXV2BotMob = observer(
  ({
    favorite,
    toggleIsWatched,
    isWatched,
    bot_uuid,
    name,
    pair,
    exchange,
    dex_version,
    link,
    colorStatus,
    showStartBtn,
    onStartClick = noOp,
    party,
    isStopped,
    chainMeta,

    limitLastTrade,
    limitMessage,
    limitNextTrade,
    limitStatus,

    volumeLastTrade,
    volumeMessage,
    volumeNextTrade,
    volumeStatus,

    counterMessage,
    counterStatus,
    counterLastTrade,
    compactQuery = DEFAULT_COMPACT_QUERY,
    getInfoContainer,
    showModesInfo = true,

    credential,
    ...props
  }: DEXV2BotMobProps) => {
    const { botColorStatus: colors, botBgColorStatus: bgColors } = useContext(ThemeContext);

    const containerRef = useRef<HTMLDivElement>(null);

    const isCompact = useMediaQuery(compactQuery);

    const startButtonPermissions = useBotActionPermissions(party);

    const ChainChip = useMobileChainInfoChip({ chainMeta, isCompact });

    const showStartButton = showStartBtn && startButtonPermissions;

    const toggleWatched = useCallback(() => {
      toggleIsWatched?.(bot_uuid);
    }, [bot_uuid, toggleIsWatched]);

    const baseModeChipProps = useBaseModeChipProps({
      getInfoContainer,
      showModesInfo,
      bot_uuid,
      containerRef,
    });

    const modeChipProps = {
      ...baseModeChipProps,
      $isCompact: isCompact,
    };

    return (
      <MobBotContainer {...props} ref={containerRef}>
        {favorite ? (
          <styles.FavoriteButton onClick={toggleWatched}>
            {isWatched ? Icons.startStatusTrue() : Icons.startStatusFalse()}
          </styles.FavoriteButton>
        ) : null}

        <styles.MobFrame
          color={colors[colorStatus]}
          style={{ backgroundColor: bgColors[colorStatus] }}
        >
          <styles.Content showDivider={isCompact}>
            <styles.ColumnWrapper>
              <styles.PairMob to={`/DEX_V2/${bot_uuid}/?tab=STATS`} color={colors[colorStatus]}>
                {pair?.split("_").join(" _ ")}
              </styles.PairMob>
              <Name>{name}</Name>
            </styles.ColumnWrapper>

            <styles.MobExchangeCellContent>
              <styles.MobExch href={link || "#"} target="_blank">
                {exchange}
              </styles.MobExch>
              <styles.MobDEXV2Version>{dex_version}</styles.MobDEXV2Version>
            </styles.MobExchangeCellContent>

            {ChainChip}

            <styles.ModesWrapper>
              <styles.MobModeChip
                mode="limit"
                nextTrade={limitNextTrade}
                lastTrade={limitLastTrade}
                message={limitMessage}
                status={limitStatus}
                {...modeChipProps}
              />
              <styles.MobModeChip
                mode="volume"
                nextTrade={volumeNextTrade}
                lastTrade={volumeLastTrade}
                message={volumeMessage}
                status={volumeStatus}
                {...modeChipProps}
              />
              <styles.MobModeChip
                mode="counter"
                message={counterMessage}
                lastTrade={counterLastTrade}
                status={counterStatus}
                {...modeChipProps}
              />
            </styles.ModesWrapper>

            {isCompact && <styles.Divider />}

            <styles.ButtonCellContent>
              {showStartButton ? (
                <styles.MobStartButton
                  started={!isStopped}
                  onClick={onStartClick}
                  color={colors[colorStatus]}
                  showCompact={isCompact}
                />
              ) : (
                <BotMark
                  onClick={() => copyBotCredential(credential)}
                  color={colors[colorStatus]}
                />
              )}
            </styles.ButtonCellContent>
          </styles.Content>
        </styles.MobFrame>
      </MobBotContainer>
    );
  }
);
