import { Validate } from "react-hook-form";
import { ConditionTypes, IStrategyCreator } from "src/modules/expertSystem";
import { graterThan, wrapHookFormValidation } from "src/validation-schemas";

const MODULES_WITH_TIMEFRAME_VALUE: ConditionTypes[] = ["bot_status", "multigrinder_status"];
const MODULES_WITH_DECIMAL_VALUE: ConditionTypes[] = [
  "price_abs",
  "price_change",
  "balance_abs",
  "balance_change",
  "balance_delta",
  "depth_abs",
  "spread_abs",
  "volatility_abs",
  "volume_abs",
  "volume_change",
  "price_account_pnl",
  "price_exchange_pnl",
];
const MODULES_WITH_TIMESTAMP_VALUE: ConditionTypes[] = ["time"];
const MODULES_WITH_POSITIVE_VALUE: ConditionTypes[] = [
  "price_abs",
  "balance_abs",
  "depth_abs",
  "spread_abs",
  "volatility_abs",
  "volume_abs",
  "price_account_pnl",
  "price_exchange_pnl",
];
const MODULES_WITH_PERCENT_VALUE: ConditionTypes[] = [
  "price_change",
  "balance_change",
  "volume_change",
];

export type ConditionFormType =
  | "moduleWithTimeframe"
  | "moduleWithDecimal"
  | "moduleWithTimestamp"
  | "moduleSettingsValue";

export const getFormType = (type: ConditionTypes): ConditionFormType | undefined => {
  const isTimeframeModule = MODULES_WITH_TIMEFRAME_VALUE.includes(type);

  if (isTimeframeModule) return "moduleWithTimeframe";

  const isDecimalModule = MODULES_WITH_DECIMAL_VALUE.includes(type);

  if (isDecimalModule) return "moduleWithDecimal";

  const isTimestampModule = MODULES_WITH_TIMESTAMP_VALUE.includes(type);

  if (isTimestampModule) return "moduleWithTimestamp";

  if (type === "settings_value") return "moduleSettingsValue";
};

export const getValidate = (type: ConditionTypes) => {
  const validate:
    | Validate<string, IStrategyCreator>
    | Record<string, Validate<string, IStrategyCreator>> = {};

  const isPositiveValueModule = MODULES_WITH_POSITIVE_VALUE.includes(type);
  const isGraterOneHundred = MODULES_WITH_PERCENT_VALUE.includes(type);

  if (isPositiveValueModule) validate.positiveValue = wrapHookFormValidation(graterThan(0));
  if (isGraterOneHundred) validate.graterOneHundred = wrapHookFormValidation(graterThan(-100));

  return validate;
};
