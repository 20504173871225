import { makeAutoObservable } from "mobx";
import { DEXV2Vault } from "src/api/bots/DEXV2/create";
import { ModalTransferParams } from "src/components/BotsContent/DEX_V2/Stats/Tables/Vaults/ModalTransfer";
import { ModalWithdrawParams } from "src/components/BotsContent/DEX_V2/Stats/Tables/Vaults/ModalWithdraw";
import { arrayToMapObj } from "src/helpers/array";
import { setTextClipboard } from "src/helpers/clipboard";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import {
  IBotChainInfoProvider,
  IBotChainMetaProvider,
  IBotInfoProvider,
  IBotTradePairProvider,
  IBotUUIDProvider,
} from "../../DEXV2Bots/DEXV2BotStore";
import { ITradePairPriceProvider } from "../../Providers/TradePairUSDPriceProvider";
import { PairAddresses, PairTickers } from "../../shared/TradePair";
import WithdrawerProvider, { IWithdrawerProvider } from "../../shared/WithdrawerProvider";
import VaultsProviderStore, { IVaultsProvider } from "./VaultsProvider";

export const INITIAL_VAULT: DEXV2Vault = {
  address: "",
  type: "main",
  base: 0,
  quote: 0,
};

export const INITIAL_PAIR_TICKERS: PairTickers = {
  base: "Base",
  quote: "Quote",
};

export const INITIAL_PAIR_ADDRESSES: PairAddresses = {
  base: "",
  quote: "",
};

export interface IVaultsParams {
  botInfoProvider: IBotInfoProvider;
  tradePairProvider: IBotTradePairProvider;
  chainInfoProvider: IBotChainInfoProvider;
  tradePairPriceProvider: ITradePairPriceProvider;
}

export default class VaultsStore implements IDisposable, IBotUUIDProvider {
  private _selectedVaultAddress = "";

  private _loading = false;

  private _botUUID = "";

  private _vaultsProvider: IVaultsProvider;

  private _tradePairProvider: IBotTradePairProvider;

  private _chainMetaProvider: IBotChainMetaProvider;

  private _withdrawerProvider: IWithdrawerProvider;

  constructor({
    botInfoProvider,
    tradePairProvider,
    chainInfoProvider,
    tradePairPriceProvider,
  }: IVaultsParams) {
    makeAutoObservable(this);

    this._tradePairProvider = tradePairProvider;
    this._chainMetaProvider = chainInfoProvider;

    this._withdrawerProvider = new WithdrawerProvider(this);

    this._vaultsProvider = new VaultsProviderStore({
      chainProvider: chainInfoProvider,
      botUUIDProvider: botInfoProvider,
      addressesProvider: botInfoProvider,
      priceProvider: {
        tradePairPriceProvider,
        tradePairProvider: this._tradePairProvider,
      },
    });

    makeLoggable(this, { vaults: true, withdrawer: true, vaultsQuotes: true });
  }

  get chainMeta() {
    return this._chainMetaProvider.chainMeta;
  }

  get tradePair() {
    return this._tradePairProvider.tradePair;
  }

  get tickers() {
    return this._vaultsProvider.tickers ?? INITIAL_PAIR_TICKERS;
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  get vaults() {
    return this._vaultsProvider.vaults;
  }

  get roundedVaults() {
    return this._vaultsProvider.roundedVaults;
  }

  get roundedTotalVaults() {
    return this._vaultsProvider.roundedTotalVaults;
  }

  get vaultsDeployed() {
    return this._vaultsProvider.vaultsDeployed;
  }

  private get _addressVaultMap() {
    return arrayToMapObj(this.vaults, "address");
  }

  setCurrentVaultAddress = (address: string) => {
    this._selectedVaultAddress = address;
  };

  get selectedVault(): DEXV2Vault {
    return this._addressVaultMap[this._selectedVaultAddress] ?? INITIAL_VAULT;
  }

  get withdrawer() {
    return this._withdrawerProvider.withdrawer ?? "";
  }

  private _getWithdrawer = async () => {
    await this._withdrawerProvider.getWithdrawer();
  };

  get vaultsQuotes() {
    return this._vaultsProvider.vaultsQuotes;
  }

  get isEmptyQuotes() {
    return this._vaultsProvider.isEmptyQuotes;
  }

  get transferParams(): ModalTransferParams | null {
    const fromVault = this.selectedVault;
    const deployerId = this.botUUID;
    const { tradePair } = this;
    const { withdrawer } = this;
    if (!deployerId || !tradePair || !withdrawer) return null;
    return {
      fromVault,
      deployerId,
      tradePair,
      withdrawer,
    };
  }

  get withdrawParams(): ModalWithdrawParams | null {
    const fromVault = this.selectedVault;
    const { tradePair } = this;
    if (!tradePair) return null;
    return {
      fromVault,
      tradePair,
    };
  }

  get modalDepsReady() {
    return Boolean(this.withdrawParams) && Boolean(this.transferParams);
  }

  copyAllAddresses = () => {
    const addresses: string[] = [];
    this.vaults.forEach((el) => {
      addresses.push(el.address);
    });

    setTextClipboard(addresses.join("\n"));
  };

  getVaults = async () => {
    this._setLoading(true);

    try {
      await Promise.all([this._vaultsProvider.getVaults(), this._getWithdrawer()]);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  refreshBalances = async () => {
    await this._vaultsProvider.refreshBalances(this._setLoading);
  };

  refreshChainBalances = async () => {
    await this._vaultsProvider.refreshChainBalances(this._setLoading);
  };

  destroy = () => {
    this._vaultsProvider.destroy();
  };
}
