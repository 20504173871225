import { ComponentPropsWithoutRef } from "react";
import { Nullish } from "src/helpers/utils";
import { PNLV2ItemLabelProps, usePNLV2ColorProps, usePNLV2ValueInfo } from "../PNLV2Item";
import { PNLV2ItemContainer, PNLV2ItemContainerProps } from "../PNLV2Item/style";
import { PNLV2Label } from "../shared/PNLV2Label";
import { PNLV2USDValue, PNLV2USDValueOwnProps, PNLV2USDValueProps } from "./PNLV2USDValue";
import * as styles from "./style";

export interface PNLV2USDItemOwnProps
  extends Omit<PNLV2USDValueOwnProps, "showSymbol" | "showPlus">,
    PNLV2ItemLabelProps,
    PNLV2ItemContainerProps {
  valueUSD?: Nullish<number | string>;
}

export interface PNLV2USDItemProps extends PNLV2USDItemOwnProps, ComponentPropsWithoutRef<"div"> {}

export const PNLV2USDItem = ({
  label,
  coloredLabel,
  orientation,
  space,
  value,
  valueUSD,
  type,
  showArrow,
  ...props
}: PNLV2USDItemProps) => {
  const { colorProps, otherProps: rootProps } = usePNLV2ColorProps(props);

  const { color: valueColor } = usePNLV2ValueInfo({ value, ...colorProps });

  const itemProps: PNLV2USDValueProps = {
    type,
    showArrow,
    ...colorProps,
  };

  return (
    <PNLV2ItemContainer $orientation={orientation} $space={space} {...rootProps}>
      {label && (
        <PNLV2Label colored={coloredLabel} color={valueColor}>
          {label}
        </PNLV2Label>
      )}
      <styles.ValueWrapper>
        <PNLV2USDValue value={value} {...itemProps} />
        <styles.ValueDelimiter $color={valueColor}>/</styles.ValueDelimiter>
        <PNLV2USDValue value={valueUSD} showSymbol showPlus={false} {...itemProps} />
      </styles.ValueWrapper>
    </PNLV2ItemContainer>
  );
};
