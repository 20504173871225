import { DateTimeRangeField } from "../../DateTimeFields/DateTimeRangeField";
import { DateTimeRangePickerButtons } from "../DateTimeRangePickerButtons";
import { DesktopDateTimeRangePickerProps } from "../DesktopDateTimeRangePicker";
import { PickerShortcuts } from "../PickerShortcuts";
import * as styles from "./style";

export interface MobileDateTimeRangePickerProps extends DesktopDateTimeRangePickerProps {}

export const MobileDateTimeRangePicker = ({
  fieldProps,
  buttonsProps,
  shortcutsProps: { show: showShortcuts, ...otherShortcutsProps },
}: MobileDateTimeRangePickerProps) => {
  const Shortcuts = showShortcuts ? <PickerShortcuts {...otherShortcutsProps} /> : null;

  return (
    <styles.Container>
      <DateTimeRangeField {...fieldProps} />
      {Shortcuts}
      <DateTimeRangePickerButtons {...buttonsProps} />
    </styles.Container>
  );
};
