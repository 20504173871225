import styled from "styled-components";
import { ComponentsContainer } from "../shared/style";

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const BotButtonsContainer = styled(ComponentsContainer)`
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 200px;
`;
