import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import WindowConsent from "src/state/WindowConsent";
import { AddHierarchy } from "./AddHierarchy";
import { EditHierarchy } from "./EditHierarchy";
import { SelectFilters } from "./SelectFilters";
import * as styles from "./style";

export const HierarchyList = observer(() => {
  const state = useLateInitContext(UserManagerContext);

  const remove = useCallback(
    (hierarchy_id: number, user_name: string, scope: string) => {
      WindowConsent.showWindow(
        "",
        `Are you sure you want to delete the hierarchy:
         ${scope}?`,
        state.removeHierarchy,
        { hierarchy_id, user_name }
      );
    },
    [state.removeHierarchy]
  );

  const columns: any = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "role",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "scope",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "id",
        disableSortBy: true,
        Cell: ({
          cell: {
            row: {
              original: { id, scope, name },
            },
          },
        }: any) => <DeleteButton onClick={() => remove(id, name, scope)} />,
      },
    ],
    [remove]
  );

  return (
    <>
      <styles.HierarchyList
        style={{ gridArea: "hierarchies" }}
        label="All"
        btnLabel="add dependency +"
        openModal={state.openPanel("showAddHierarchyPanel")}
        data={state.hierarchy}
        columns={columns}
        filter={<SelectFilters />}
        loader={state.isLoadingHierarchie}
      />

      <AddHierarchy />
      <EditHierarchy />
    </>
  );
});
