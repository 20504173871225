import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import { ElementRef } from "src/helpers/utils";
import { TooltipDate } from "./TooltipDate";
import { TooltipSeries } from "./TooltipSeries";
import * as styles from "./style";

export interface TooltipProps extends ComponentPropsWithoutRef<"div"> {
  ownRef: ElementRef<"div">;
}

export const Tooltip = observer(({ ownRef, ...props }: TooltipProps) => {
  const state = useTooltipContext();

  return (
    <styles.TooltipContainer ref={ownRef} {...props}>
      <styles.Content>
        <TooltipDate />
        <styles.SeriesContainer>
          {state.seriesTitles.map((title) => (
            <TooltipSeries title={title} key={title} />
          ))}
        </styles.SeriesContainer>
      </styles.Content>
    </styles.TooltipContainer>
  );
});
