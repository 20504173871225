import { makeAutoObservable, runInAction } from "mobx";
import { getMultiGridUUID } from "src/api/bots/CEX/multigrinder";
import { noOp } from "src/helpers/utils";
import MultiGridFormStore from "./MultiGridForm";

export interface IBaseMultiGridForm {
  formState: MultiGridFormStore;
  isLoading: boolean;
  submitHandler: (e: React.FormEvent) => Promise<void>;
}

interface MultiGridUpdHandlers {
  updSettings: () => Promise<void> | void;
  updModules: () => Promise<void> | void;
  updGridState: () => Promise<void> | void;
}

type UpdHandlersKeys = Array<keyof MultiGridUpdHandlers>;

class MultiGridStore {
  party = "";

  multiGridExists = false;

  gridUUID = "";

  updHandlers: MultiGridUpdHandlers = {
    updGridState: noOp,
    updModules: noOp,
    updSettings: noOp,
  };

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  private get updKeys() {
    return Object.keys(this.updHandlers) as UpdHandlersKeys;
  }

  setParty = (value: string) => {
    this.party = value;
  };

  updAllData = () => {
    this.updKeys.forEach((key) => {
      this.updHandlers[key]();
    });
  };

  changeStateGrid = (bool: boolean) => {
    this.multiGridExists = bool;
  };

  checkGridState = async () => {
    // this._checkExist(this.party);
    await this._getMultiGrindersUUID(this.party);
    this.changeStateGrid(!!this.gridUUID);
  };

  setGridUUID = (uuids: string[]) => {
    if (uuids.length)
      runInAction(() => {
        // eslint-disable-next-line prefer-destructuring
        this.gridUUID = uuids[0];
      });
  };

  setUpdHandlers = <K extends keyof MultiGridUpdHandlers>(key: K, cb: () => void) => {
    this.updHandlers[key] = cb;
  };

  private _getMultiGrindersUUID = async (party: string) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const { isError, data } = await getMultiGridUUID(party);

      if (!isError) {
        this.setGridUUID(data);
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default MultiGridStore;
