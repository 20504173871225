import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { PopupProps } from "reactjs-popup/dist/types";
import { PNLV2Props, PNLV2RootProps } from "src/components/shared/PNLV2";
import { InOutIndicators } from "src/components/shared/PNLV2/shared/InOutIndicators";
import { PNLFee } from "src/components/shared/PNLV2/shared/PNLFee";
import { PNLV2Price } from "src/components/shared/PNLV2/shared/PNLV2Items";
import useLocalStore from "src/hooks/useLocalStore";
import useMediaQuery from "src/hooks/useMediaQuery";
import { CEXPNLStore } from "src/state/CEX/shared/CEXPNLStore";
import { LayoutPNLProps } from "../LayoutCEXPNL";
import { useFeeChecked, useFundingsExchangeInfo, usePrice } from "../hooks";
import * as styles from "./style";

const MOBILE_QUERY = "(max-width: 880px)";

export interface BasePNLProps
  extends Pick<PNLV2Props, "showHeader" | "orientation" | "range" | "onRangeChange">,
    PNLV2RootProps {
  party?: string;
  pickerPosition?: PopupProps["position"];
  showCharts?: boolean;
  setUpdatePNLCb?: (cb: () => void) => void;
}

export const BaseCEXPNLWidget = observer(
  ({
    party,
    range,
    onRangeChange,
    setUpdatePNLCb,
    pickerPosition,
    showCharts,
    showHeader,
    orientation,
    ...props
  }: BasePNLProps) => {
    const state = useLocalStore(CEXPNLStore);

    const { fundingsExchange, priceNet, priceGrowth, fee } = state.data;

    const fundingsExchangeInfo = useFundingsExchangeInfo(fundingsExchange);
    const [useFee, onFeeChecked] = useFeeChecked(true);
    const price = usePrice({ priceGrowth, priceNet, useFee });
    const showMobile = useMediaQuery(MOBILE_QUERY);

    const getPnl = useCallback(() => {
      if (party && range) state.getPNLData({ party, range, withCharts: showCharts });
    }, [party, range, showCharts, state]);

    useEffect(() => {
      getPnl();
    }, [getPnl]);

    useEffect(() => {
      setUpdatePNLCb?.(getPnl);
    }, [getPnl, setUpdatePNLCb]);

    const { deltaQuoteUsd, ...stateData } = state.data;
    const pnlData = { ...stateData, price: price as PNLV2Price, deltaQuote: deltaQuoteUsd };
    const pnlFeeProps = { value: fee, checked: useFee, onChange: onFeeChecked };
    const pnlIndicatorsProps = { info: fundingsExchangeInfo };

    const pickerProps = useMemo((): PNLV2Props["pickerProps"] => {
      const defaultPickerPosition = showMobile ? "bottom left" : "bottom right";

      return { popupProps: { position: pickerPosition || defaultPickerPosition } };
    }, [pickerPosition, showMobile]);

    const pnlProps: LayoutPNLProps<false> = {
      afterTitle: <InOutIndicators {...pnlIndicatorsProps} />,
      afterHeader: <PNLFee {...pnlFeeProps} />,
      showHeader,
      orientation,
      ...pnlData,
    };

    const pnlChartsProps: LayoutPNLProps<true> = { ...state.data };

    return (
      <styles.StyledLayoutPNL
        showCharts={showCharts}
        loading={state.loading}
        showPicker
        rangePickerProps={{ value: range, onChange: onRangeChange, ...pickerProps }}
        pnlProps={showCharts ? pnlChartsProps : pnlProps}
        {...props}
      />
    );
  }
);
