import { useState } from "react";
import { ButtonSize } from "src/components/shared/Buttons/v2/Button/style";
import { DropdownButton } from "src/components/shared/Buttons/v2/DropdownButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { PopoverMenu, PopoverMenuItem } from "src/components/shared/PopoverMenu";
import { DemoCard } from "../../shared/DemoCard";
import { ComponentsContainer, ControlsContainer } from "../shared/style";

export interface DropdownButtonsProps {}

const MENU_ITEMS: PopoverMenuItem[] = ["Download", "Create a Copy", "Mark as Draft", "Delete"].map(
  (it) => ({
    label: it,
  })
);

const DROPDOWN_SIZE: ButtonSize[] = ["s", "m", "l"];

export const DropdownButtons = (props: DropdownButtonsProps) => {
  const [disabled, setDisabled] = useState(false);

  const sizes = DROPDOWN_SIZE;

  return (
    <DemoCard title="Dropdown Buttons">
      <ControlsContainer>
        <CheckBox
          label="Disabled"
          checked={disabled}
          onChange={(e) => setDisabled(e.target.checked)}
        />
      </ControlsContainer>

      <ComponentsContainer>
        {sizes.map((size) => (
          <PopoverMenu
            key={size}
            trigger={(isOpen) => (
              <DropdownButton size={size} open={isOpen} disabled={disabled}>
                Choose
              </DropdownButton>
            )}
            items={MENU_ITEMS}
            disabled={disabled}
          />
        ))}
      </ComponentsContainer>
    </DemoCard>
  );
};
