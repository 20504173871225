import { IconButton, IconButtonProps } from "src/components/shared/Buttons/v2/IconButton";
import * as styles from "./style";

export interface ExpandButtonProps extends IconButtonProps {
  hide?: boolean;
}

export const ExpandButton = ({ hide, ...props }: ExpandButtonProps) => (
  <IconButton size="xs" style={{ marginTop: 0 }} {...props}>
    <styles.ExpandIcon $hide={!hide} />
  </IconButton>
);
