import { SelectorDropdown } from "src/components/shared/DatePickers/DateTimeRangePickerSelector/SelectorDropdown";
import { Title } from "src/components/shared/DatePickers/DateTimeRangePickerSelector/SelectorDropdown/style";
import { PNLV2 } from "src/components/shared/PNLV2";
import styled from "styled-components";

export const DropdownSelector = styled(SelectorDropdown)`
  ${Title} {
    @media (min-width: 500px) and (max-width: 750px), (min-width: 1100px) and (max-width: 1450px) {
      font-size: ${({ theme }) => theme.fontSizes["11px"]};
    }
  }
`;

export const StyledPNL = styled(PNLV2)`
  max-width: 1000px;

  grid-area: PnL;

  font-size: 16px;
`;
