import { Selector } from "src/components/shared/Forms/Selectors";
import { SELECTOR_CLASSNAME } from "src/components/shared/Forms/Selectors/shared/style";
import styled from "styled-components";

export const StyledSelectorFilter = styled(Selector)`
  & .${SELECTOR_CLASSNAME}__control {
    width: 80px;
  }

  & .${SELECTOR_CLASSNAME}__menu-list {
    min-height: 40px;
    max-height: 120px;
  }
` as typeof Selector;
