import { ComponentType } from "react";
import { LegendProps } from "src/components/shared/Graph/shared/ChartLegend/Legend";
import { GraphLegendSeriesOwnProps } from "../GraphLegendSeries";
import * as styles from "./style";

export interface SpreadChartLegendRootProps extends LegendProps {
  series: ComponentType<GraphLegendSeriesOwnProps>;
}

export const GraphLegendRoot = ({
  data,
  toggleVisibility,
  series: SeriesComponent,
  ...props
}: SpreadChartLegendRootProps) => (
  <styles.Container {...props}>
    {data.map(({ series, ...seriesProps }) => (
      <SeriesComponent
        key={seriesProps.id}
        onToggleVisibility={toggleVisibility}
        {...seriesProps}
      />
    ))}
  </styles.Container>
);
