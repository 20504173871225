import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { PartyContext, UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { PartyInfoProviderImpl } from "src/state/UserManager/EditParty/Info/PartyInfoProvider";
import { PartyInfoStore } from "src/state/UserManager/EditParty/Info/PartyInfoStore";
import { GroupInfo } from "./GroupInfo";
import { SantimentInfo } from "./SantimentInfo";
import { Container } from "./style";

export const Info = observer(() => {
  const partyState = useLateInitContext(PartyContext);
  const managerState = useLateInitContext(UserManagerContext);

  const state = useLocalStore(PartyInfoStore, new PartyInfoProviderImpl(managerState, partyState));

  useEffect(() => {
    state.getPartyInfo();
  }, [state]);

  return (
    <Container>
      <GroupInfo state={state} />

      <SantimentInfo state={state} />

      <Loader show={state.isLoading} />
    </Container>
  );
});
