import { noOp, noOpAsync } from "src/helpers/utils";
import { IPriceCalculator, PriceCalculatorInfo } from "../PriceCalculator";

export class EmptyPriceCalculator implements IPriceCalculator {
  get enabled() {
    return false;
  }

  get targetPrice(): string {
    return "";
  }

  get info(): PriceCalculatorInfo {
    return {
      baseReservesBefore: {},
      baseReservesAfter: {},
      quoteAmount: {},
    };
  }

  get canQuery() {
    return false;
  }

  get isRefreshing() {
    return false;
  }

  get isForceRefreshing() {
    return false;
  }

  updateTargetPrice = noOp;

  refreshCalculator = noOpAsync;

  forceRefreshCalculator = noOpAsync;
}
