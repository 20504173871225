import styled from "styled-components";

export const ButtonGroupWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
