import { SeriesType } from "lightweight-charts";
import { useMemo } from "react";
import { PickUnion } from "src/helpers/utils";
import { OptionalSeriesOptions } from "../types";
import { UseSeriesOptionsParams } from "./useSeriesOptions";

type UseTitleParams = PickUnion<UseSeriesOptionsParams, "title" | "showTitleLabel">;

export const useTitle = ({ title, showTitleLabel }: UseTitleParams) => {
  const options = useMemo(
    (): OptionalSeriesOptions<SeriesType> => (showTitleLabel ? { title } : {}),
    [showTitleLabel, title]
  );

  return options;
};
