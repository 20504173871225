import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { Loader } from "src/components/shared/Loader";
import { BalanceOverviewContext } from "src/context/CEX/Dashboard/v2/BalanceOverview";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { InfoPanelSection } from "./InfoPanelSection";
import * as styles from "./style";

export interface BalancesOverviewInfoPanelProps extends ComponentPropsWithoutRef<"div"> {}

export const BalancesOverviewInfoPanel = observer((props: BalancesOverviewInfoPanelProps) => {
  const { endBalances, startBalances, deltaBalances, loading } = useLateInitContext(
    BalanceOverviewContext.Context
  );

  return (
    <styles.Container {...props}>
      <InfoPanelSection title="Start of period balance:" {...startBalances} />
      <InfoPanelSection title="End of period balance:" {...endBalances} />
      <InfoPanelSection title="Delta end - start:" {...deltaBalances} />
      <Loader show={loading} />
    </styles.Container>
  );
});
