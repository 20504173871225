import { CardSurface } from "src/components/shared/shared";
import { FullSizeProps } from "src/modules/shared";
import styled, { css } from "styled-components";

interface FullSizeTableProps extends Pick<FullSizeProps, "isFullscreen"> {}

export const Container = styled.div<FullSizeTableProps>`
  display: flex;
  flex-direction: column;

  gap: 10px;

  grid-area: state;

  ${({ isFullscreen }) => {
    if (isFullscreen) {
      return css`
        ${CardSurface}
      `;
    }
  }}
`;
