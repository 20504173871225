import * as styles from "./style";

export type ChipVariant = "filled" | "outlined";

export interface ChipProps {
  label?: string;
  color?: string;
  variant?: ChipVariant;
  onDelete?: () => void;
}

export const Chip = ({ label, onDelete, variant = "outlined", ...props }: ChipProps) => (
  <styles.ChipContainer variant={variant} {...props}>
    {label}
    {onDelete ? <styles.RemoveBtn type="button" onClick={onDelete} /> : null}
  </styles.ChipContainer>
);
