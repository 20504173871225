import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { ITickerGroup } from "src/state/UserManager/EditParty/TickersStore";
import { TickerForm } from "../shared/TickerForm";
import { TickerHeader } from "./TickerHeader";
import * as styles from "./style";

const FORM_ID = "save_ticker";

export interface TickerProps {
  tickerGroup: ITickerGroup;
}

export const Ticker = observer(({ tickerGroup }: TickerProps) => {
  const methods = useForm<ITickerGroup>({
    defaultValues: tickerGroup,
  });

  return (
    <FormProvider {...methods}>
      <styles.Container>
        <TickerHeader formID={FORM_ID} />

        <TickerForm id={FORM_ID} />
      </styles.Container>
    </FormProvider>
  );
});
