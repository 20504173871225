import { makeAutoObservable } from "mobx";
import { getConstraints } from "src/api/bots/CEX/settings";
import { entries } from "src/helpers/utils";
import { CEXSettings } from "src/modules/settings";
import { CEXSettingsKeys } from ".";
import { ConstraintsValidator, ConstraintsValidators } from "./ConstraintsValidators";

export type ConstraintLevel = "warning" | "alert";

export type ConstraintError = {
  type: ConstraintLevel;
  message: string;
};

export type ConstraintsErrorsReport = {
  title: string;
  message: string;
};

type CEXSettingsConstraints = {
  warning: Partial<{
    "settings.pair.dontTradePrice": string;
    "settings.virtualRange.diff": string;
  }>;
  alert: Partial<{
    "settings.virtualRange.diff": string;
    "settings.pair.dontTradePrice": string;
    "settings.volume.tradePerDayMinUSD": string;
  }>;
};

type Constraints<Level extends ConstraintLevel> = CEXSettingsConstraints[Level];
type ConstraintsKeys<Level extends ConstraintLevel> = keyof Constraints<Level>;
type AllConstraintsKeys = ConstraintsKeys<"alert"> | ConstraintsKeys<"warning">;

type ConstraintsSettingsKeysMap = Partial<Record<CEXSettingsKeys, AllConstraintsKeys[]>>;

type WarningConstraints = Constraints<"warning">;
type AlertConstraints = Constraints<"alert">;

type ConstraintsValidation<T extends ConstraintLevel> = {
  [K in ConstraintsKeys<T>]: ConstraintsValidator<NonNullable<Constraints<T>[K]>>;
};
type WarningsValidation = ConstraintsValidation<"warning">;
type AlertsValidation = ConstraintsValidation<"alert">;

type ConstraintsErrors<Level extends ConstraintLevel> = Partial<
  Record<keyof Constraints<Level>, string | undefined>
>;
type AlertsErrors = ConstraintsErrors<"alert">;
type WarningsErrors = ConstraintsErrors<"warning">;

const EMPTY_WARNINGS: WarningConstraints = {};

const EMPTY_ALERTS: AlertConstraints = {};

export default class ConstraintsStore {
  private _warnings: WarningConstraints = EMPTY_WARNINGS;

  private _alerts: AlertConstraints = EMPTY_ALERTS;

  _constraintsSettingsKeysMap: ConstraintsSettingsKeysMap = {
    "settings.pair.dontTradePriceMax": ["settings.pair.dontTradePrice"],
    "settings.pair.dontTradePriceMin": ["settings.pair.dontTradePrice"],
  };

  alertsErrors: AlertsErrors = {};

  warningsErrors: WarningsErrors = {};

  constructor() {
    makeAutoObservable(this);
  }

  private _setConstraints = (constraints: Partial<CEXSettingsConstraints>) => {
    this._warnings = constraints?.warning ?? EMPTY_WARNINGS;
    this._alerts = constraints?.alert ?? EMPTY_ALERTS;
  };

  clearConstraints = () => {
    this._warnings = EMPTY_WARNINGS;
    this._alerts = EMPTY_ALERTS;
  };

  clearConstraintsErrors = () => {
    this.alertsErrors = {};
    this.warningsErrors = {};
  };

  private _clearConstraintsLevelErrorsByKeys = <Level extends ConstraintLevel>(
    errors: ConstraintsErrors<Level>,
    validation: ConstraintsValidation<Level>,
    keys: CEXSettingsKeys[]
  ) => {
    const constraintKeys = this._getConstraintsValidationKeys(validation, keys);
    for (const key of constraintKeys) {
      const alertKey = key as ConstraintsKeys<Level>;
      if (errors[alertKey]) {
        // eslint-disable-next-line no-param-reassign
        errors[alertKey] = "";
      }
    }
  };

  clearConstraintsErrorsByKeys = (keys: CEXSettingsKeys[]) => {
    this._clearConstraintsLevelErrorsByKeys(this.alertsErrors, this._alertsValidation, keys);
    this._clearConstraintsLevelErrorsByKeys(this.warningsErrors, this._warningsValidation, keys);
  };

  private _warningsValidation: WarningsValidation = {
    "settings.pair.dontTradePrice": ConstraintsValidators.dtpRangeIncrease,
    "settings.virtualRange.diff": ConstraintsValidators.virtualRangeDiffIncrease,
  };

  private _alertsValidation: AlertsValidation = {
    "settings.pair.dontTradePrice": ConstraintsValidators.dtpRangePercentWidth,
    "settings.virtualRange.diff": ConstraintsValidators.virtualRangeDiffIncrease,
    "settings.volume.tradePerDayMinUSD": ConstraintsValidators.tradePerDayMinUSDMaxValue,
  };

  private _getConstraintsError = <Level extends ConstraintLevel>(
    errors: ConstraintsErrors<Level>,
    key: CEXSettingsKeys
  ): string | undefined => {
    const errorKeys = Object.keys(errors) as ConstraintsKeys<Level>[];

    // try to get error with the same name as settings key
    const constraintError = errors[key as ConstraintsKeys<Level>];
    if (constraintError) {
      return constraintError;
    }

    // try to get constraint keys from map if not 1 to 1 relation
    const settingExtraConstraintKeys = this._constraintsSettingsKeysMap[key];
    if (!settingExtraConstraintKeys) return undefined;

    // remove non existing keys in constraint errors and join all existing errors
    const settingExtraLevelConstraintKeys = settingExtraConstraintKeys.filter((key) =>
      errorKeys.includes(key as ConstraintsKeys<Level>)
    ) as ConstraintsKeys<Level>[];
    const errorMessages = settingExtraLevelConstraintKeys.map((key) => errors[key]).filter(Boolean);
    if (!errorMessages.length) return undefined;
    const error = errorMessages.join("\n");
    return error;
  };

  getConstraintsErrorByKey = (key: CEXSettingsKeys): ConstraintError | undefined => {
    const alert = this._getConstraintsError(this.alertsErrors, key);
    if (alert) return { type: "alert", message: alert };

    const warning = this._getConstraintsError(this.warningsErrors, key);
    if (warning) return { type: "warning", message: warning };
    return undefined;
  };

  private _getAllConstraintsErrors = (
    alertsErrors: ConstraintsErrors<"alert">,
    warningErrors: ConstraintsErrors<"warning">
  ): [string, string] => {
    const joinErrors = (errors: (string | undefined)[]) => errors.filter(Boolean).join("\n");

    const alertsErrorMessages = Object.values(alertsErrors) as (string | undefined)[];
    const warningErrorMessages = entries(warningErrors).map((entry) => {
      if (!entry) return undefined;
      const [key, error] = entry;
      if (!error) return undefined;
      const alsoAlertError = Boolean(alertsErrors[key]);
      if (alsoAlertError) return undefined;
      return error;
    });
    return [joinErrors(alertsErrorMessages), joinErrors(warningErrorMessages)];
  };

  getConstraintsErrorsReport = (keys?: CEXSettingsKeys[]): ConstraintsErrorsReport => {
    const getErrorMessageByLevel = (fieldErrors: ConstraintError[], level: ConstraintLevel) => {
      const errors = fieldErrors
        .filter((error) => error.type === level)
        .flatMap((error) => error.message.split("\n"));
      // remove duplicate strings in case same constraint key
      // is linked with several settings => duplicate errors
      const errorsSet = new Set(errors);
      return Array.from(errorsSet).join("\n");
    };

    const getConstraintErrors = (keys?: CEXSettingsKeys[]): [string, string] => {
      if (!keys) {
        return this._getAllConstraintsErrors(this.alertsErrors, this.warningsErrors);
      }
      const fieldErrors = keys
        .map(this.getConstraintsErrorByKey)
        .filter(Boolean) as ConstraintError[];
      const alertsErrors = getErrorMessageByLevel(fieldErrors, "alert");
      const warningsErrors = getErrorMessageByLevel(fieldErrors, "warning");
      return [alertsErrors, warningsErrors];
    };

    const [alertsErrors, warningsErrors] = getConstraintErrors(keys);

    switch (true) {
      case Boolean(alertsErrors) && Boolean(warningsErrors): {
        return {
          title: "alerts and warnings",
          message: `alerts: ${alertsErrors}\n\nwarnings: ${warningsErrors}`,
        };
      }
      case Boolean(alertsErrors): {
        return {
          title: "alerts",
          message: alertsErrors,
        };
      }
      case Boolean(warningsErrors): {
        return {
          title: "warnings",
          message: warningsErrors,
        };
      }
      default: {
        return {
          title: "no warnings",
          message: "ok",
        };
      }
    }
  };

  private _validateConstraintsLevel = <Level extends ConstraintLevel>(
    validation: ConstraintsValidation<Level>,
    data: CEXSettings,
    savedData: CEXSettings,
    constraints: Constraints<Level>,
    errors: ConstraintsErrors<Level>,
    validateKeys?: ConstraintsKeys<Level>[]
  ) => {
    let valid = true;

    // eslint-disable-next-line no-param-reassign
    validateKeys = validateKeys || (Object.keys(validation) as ConstraintsKeys<Level>[]);

    for (const key of validateKeys) {
      const validator = validation[key];
      if (!validator) continue;

      const constraint = constraints[key];
      if (constraint === undefined || constraint === null) continue;

      const error = validator(data, savedData, constraint);
      // eslint-disable-next-line no-param-reassign
      errors[key] = error;

      if (error) {
        valid = false;
      }
    }

    return valid;
  };

  private _validateAlertsConstraints = (
    data: CEXSettings,
    savedSettings: CEXSettings,
    validateKeys?: CEXSettingsKeys[]
  ) => {
    const alertsKeys = this._getConstraintsValidationKeys(this._alertsValidation, validateKeys);
    return this._validateConstraintsLevel<"alert">(
      this._alertsValidation,
      data,
      savedSettings,
      this._alerts,
      this.alertsErrors,
      alertsKeys
    );
  };

  private _validateWarningsConstraints = (
    data: CEXSettings,
    savedSettings: CEXSettings,
    validateKeys?: CEXSettingsKeys[]
  ) => {
    const warningKeys = this._getConstraintsValidationKeys(this._warningsValidation, validateKeys);
    return this._validateConstraintsLevel<"warning">(
      this._warningsValidation,
      data,
      savedSettings,
      this._warnings,
      this.warningsErrors,
      warningKeys
    );
  };

  private _getConstraintsValidationKeys = <Level extends ConstraintLevel>(
    validation: ConstraintsValidation<Level>,
    settingsKeys?: CEXSettingsKeys[]
  ): ConstraintsKeys<Level>[] => {
    const validationKeys = Object.keys(validation) as ConstraintsKeys<Level>[];
    // return all keys from validation by default
    if (!settingsKeys) return validationKeys;

    const constraintKeys = new Set<ConstraintsKeys<Level>>();
    for (const key of settingsKeys) {
      // by default assume that setting key name === constraint key name from validation
      const settingConstraintKey = validation[key as ConstraintsKeys<Level>];
      if (settingConstraintKey !== undefined) {
        constraintKeys.add(key as ConstraintsKeys<Level>);
        continue;
      }

      // try to get constraint keys from map if not 1 to 1 relation
      const settingExtraConstraintKeys = this._constraintsSettingsKeysMap[key];
      if (!settingExtraConstraintKeys) continue;

      // remove non existing keys in validation and add to total set
      const settingExtraLevelConstraintKeys = settingExtraConstraintKeys.filter((key) =>
        validationKeys.includes(key as ConstraintsKeys<Level>)
      ) as ConstraintsKeys<Level>[];
      settingExtraLevelConstraintKeys.forEach(constraintKeys.add, constraintKeys);
    }
    return Array.from(constraintKeys);
  };

  validateConstraints = (
    data: CEXSettings,
    savedSettings: CEXSettings,
    validateKeys?: CEXSettingsKeys[]
  ) => {
    const alertsValid = this._validateAlertsConstraints(data, savedSettings, validateKeys);
    const warningsValid = this._validateWarningsConstraints(data, savedSettings, validateKeys);
    return alertsValid && warningsValid;
  };

  getConstraints = async (bot_uuid: string) => {
    try {
      const { data, isError } = await getConstraints(bot_uuid);

      if (!isError) {
        this._setConstraints(data);
      } else {
        this.clearConstraints();
      }
    } catch {
      this.clearConstraints();
    }
  };
}
