import { useEffect, useMemo } from "react";
import { Headline2 } from "src/components/shared/Typography";
import { AnalyticsContext } from "src/context/CEX/Analytics";
import AnalyticsStore from "src/state/CEX/analytics/Analytics";
import { BotInfoProps } from "..";
import { ChartPanel } from "./ChartPanel";
import { SelectionPanel } from "./SelectionPanel";
import * as styles from "./style";

export interface AnalyticsProps extends BotInfoProps {}

export const Analytics = ({ botInfo }: AnalyticsProps) => {
  const state = useMemo(() => new AnalyticsStore(), []);

  useEffect(() => {
    if (!botInfo.market) return;
    state.setMarket(botInfo.market);

    if (botInfo.bot_uuid) state.setBotUUID(botInfo.bot_uuid);

    if (botInfo.party) {
      state.setParty(botInfo.party);
      state.fetchAllChart();
    }
  }, [state, botInfo]);

  return (
    <AnalyticsContext.Provider value={state}>
      <styles.Container>
        <Headline2>Party: {botInfo.party}</Headline2>

        <styles.Content>
          <SelectionPanel />
          <ChartPanel />
        </styles.Content>
      </styles.Container>
    </AnalyticsContext.Provider>
  );
};
