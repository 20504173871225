import { ComponentPropsWithoutRef } from "react";
import Icons from "src/icons/Icons";
import * as styles from "./style";

export interface ReceiverIconProps extends ComponentPropsWithoutRef<"svg"> {
  useReceiver: boolean;
}

export const ReceiverIcon = ({ useReceiver, ...props }: ReceiverIconProps) => {
  const Icon = useReceiver ? Icons.Tick : Icons.Rule;
  const color = useReceiver ? "primary" : undefined;

  return (
    <styles.IconWrapper $color={color}>
      <Icon {...props} />
    </styles.IconWrapper>
  );
};
