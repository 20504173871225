import { ComponentPropsWithoutRef, useCallback } from "react";
import * as styles from "./style";

export interface GraphLegendSeriesOwnProps {
  id: string;
  title: string;
  color?: string;
  visible?: boolean;
  onToggleVisibility?: (id: string) => void;
}

export interface GraphLegendSeriesProps
  extends Omit<ComponentPropsWithoutRef<"div">, keyof GraphLegendSeriesOwnProps>,
    GraphLegendSeriesOwnProps {}

export const GraphLegendSeries = ({
  title,
  id,
  color,
  visible,
  onToggleVisibility,
}: GraphLegendSeriesProps) => {
  const toggleVisibility = useCallback(() => {
    onToggleVisibility?.(id);
  }, [onToggleVisibility, id]);

  return (
    <styles.SeriesContainer key={id} onClick={toggleVisibility}>
      {color && <styles.SeriesColorBox $color={color} />}
      <styles.SeriesTitle $visible={visible}>{title}</styles.SeriesTitle>
    </styles.SeriesContainer>
  );
};
