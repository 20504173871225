import { ComponentProps } from "react";
import { Bar as BarChart } from "react-chartjs-2";

type BarChartProps<TData, TLabel> = ComponentProps<typeof BarChart<TData, TLabel>>;

export interface BarProps<TData, TLabel> extends Omit<BarChartProps<TData, TLabel>, "ref"> {
  chartRef?: BarChartProps<TData, TLabel>["ref"];
}

export const Bar = <TData = number[], TLabel = unknown>({
  chartRef,
  ...props
}: BarProps<TData, TLabel>) => <BarChart {...props} ref={chartRef} />;
