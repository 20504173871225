import { saveAs } from "file-saver";
import { makeAutoObservable, runInAction } from "mobx";
import { PublicTerminalWithRangeProps, fetchAccountHistory } from "src/api/bots/CEX/exchange";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { joinPair } from "src/helpers/botName";
import { toCSV } from "src/helpers/csv";
import { getUTCRangeFromDuration, stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { toRounding } from "src/helpers/rounding";
import { CreatedLimitOrder } from "src/modules/exchange/trade";
import ExchangeStore from ".";

class AccountHistoryStore {
  private _orders: CreatedLimitOrder[] = [];

  isLoading = false;

  selectOrderID = "";

  rangeHistory: DateTimeRange = getUTCRangeFromDuration({ hours: 12 });

  mainStore: ExchangeStore;

  constructor(state: ExchangeStore) {
    this.mainStore = state;

    makeAutoObservable(this);
  }

  get orders() {
    return this._orders.map(({ price, amount, side, id, fee, pair, ...otherParams }) => ({
      ...otherParams,
      id,
      amount: toRounding(+amount, this.mainStore.amountPrecision),
      price: toRounding(+price, this.mainStore.pricePrecision),
      side: side.toUpperCase(),
      fee: toRounding(+fee, this.mainStore.pricePrecision),
      pair: joinPair(pair.quote, pair.base),
    }));
  }

  get _selectOrder() {
    return this.orders.find((el) => el.id === this.selectOrderID);
  }

  get orderInfo() {
    return {
      ...this._selectOrder,
      time: unixToDateFormat(stringDateToUnix(this._selectOrder?.time || ""), "FullDate"),
    };
  }

  get amountSellOrders() {
    return this._orders.filter((el) => el.side === "sell").length;
  }

  get amountBuyOrders() {
    return this._orders.filter((el) => el.side === "buy").length;
  }

  get start() {
    return this.rangeHistory[0]?.unix();
  }

  get end() {
    return this.rangeHistory[1]?.unix();
  }

  get rowsCSV() {
    return this.orders.map((order) => Object.values(order).map((el) => String(el)));
  }

  get headersCSV() {
    if (!this.orders.length) return [];
    return Object.keys(this.orders[0]);
  }

  get currencies() {
    return Object.keys(this.totalFeeMap);
  }

  get totalFeeMap() {
    const map: { [key: string]: number } = {};

    for (const el of this.orders) {
      if (!map[el.feeCurrency]) {
        map[el.feeCurrency] = +el.fee;
      } else {
        map[el.feeCurrency] += +el.fee;
      }
    }

    return map;
  }

  downloadData = () => {
    this._fetchAccHistory({
      account_uuid: this.mainStore.currentAccID,
      pair: this.mainStore.pair,
      start: this.start,
      end: this.end,
    });
  };

  setSelectOrderId = (id: string) => {
    this.selectOrderID = id;
  };

  setRangeHistory = (range: DateTimeRange) => {
    this.rangeHistory = range;

    this.downloadData();
  };

  uploadAccHistory = async () => {
    if (this._orders.length) {
      const csv = toCSV(this.rowsCSV, this.headersCSV);

      const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
      });
      const fileName = `${this.mainStore.market}_acc_history`;
      saveAs(blob, `${fileName}.csv`);
    }
  };

  private _fetchAccHistory = async ({
    account_uuid,
    pair,
    start,
    end,
  }: PublicTerminalWithRangeProps) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const {
        data: { data },
        isError,
      } = await fetchAccountHistory({
        account_uuid,
        pair,
        start,
        end,
      });

      if (!isError) {
        runInAction(() => {
          this._orders = data;
        });
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default AccountHistoryStore;
