import styled from "styled-components";

export const Footer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  gap: 32px;
`;
