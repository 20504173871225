import { observer } from "mobx-react-lite";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";

import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PriceInfoInput } from "../../shared/PriceInfoInput";
import { SettingsModeIcon } from "../../shared/SettingsModeIcon";
import { SettingsPanel, SettingsPanelProps } from "../../shared/SettingsPanel";
import * as styles from "../../shared/style";

export interface VolumePanelProps extends Omit<SettingsPanelProps, "label" | "children"> {}

export const VolumePanel = observer(({ ...props }: VolumePanelProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  return (
    <SettingsPanel
      label="Volume Settings"
      labelIcon={<SettingsModeIcon mode="volume" />}
      checkbox={
        <styles.HeaderCheckbox
          label="Use Receiver"
          checked={state.data.volume_data.use_receiver}
          onChange={state.getHandler("volume_data.use_receiver")}
        />
      }
      {...props}
    >
      <styles.FormContent>
        <LabeledInput
          type="number"
          step="any"
          label="Period Minutes"
          {...state.getInputProps("volume_data.period")}
        />
        <styles.FormRow>
          <PriceInfoInput
            type="number"
            step="any"
            label="Min Amount"
            price={state.getFieldUSD("volume_data.min_amount")}
            {...state.getInputProps("volume_data.min_amount")}
          />
          <PriceInfoInput
            type="number"
            step="any"
            label="Max Amount"
            price={state.getFieldUSD("volume_data.max_amount")}
            {...state.getInputProps("volume_data.max_amount")}
          />
        </styles.FormRow>
        <styles.FormRow>
          <LabeledInput
            type="number"
            step="any"
            label="Min Trades"
            {...state.getInputProps("volume_data.min_trades")}
          />
          <LabeledInput
            type="number"
            step="any"
            label="Max Trades"
            {...state.getInputProps("volume_data.max_trades")}
          />
        </styles.FormRow>
        <LabeledPercentRangeInput
          label="Buy Percent"
          step="0.1"
          {...state.getInputProps("volume_data.buy_percent")}
        />
      </styles.FormContent>
    </SettingsPanel>
  );
});
