import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import useNavigation from "src/hooks/useNavigation";
import { CEXMenuItems } from "./MenuItems/CEXMenuItems";
import { DEXMenuItems } from "./MenuItems/DEXMenuItems";
import { DEXV2MenuItems } from "./MenuItems/DEXV2MenuItems";
import * as styles from "./style";

export interface HeaderMenuItem {
  label: string;
  value: string;
  icon: () => JSX.Element;
  to: (path: string) => string;
}

export interface BotNavigationProps {
  botType: "CEX" | "DEX" | "DEX_V2";
}

export const BotNavigation = observer(({ botType }: BotNavigationProps) => {
  let menuItems = [];
  switch (botType) {
    case "CEX": {
      menuItems = CEXMenuItems();
      break;
    }
    case "DEX": {
      menuItems = DEXMenuItems();
      break;
    }
    case "DEX_V2": {
      menuItems = DEXV2MenuItems();
      break;
    }
  }

  const { path } = useParams<{ path: string }>();

  const { currentTab } = useNavigation(path);

  return (
    <styles.MenuList>
      {menuItems.map(({ value, label, icon, to }) => {
        const isActiveTab = currentTab === value;
        return (
          <styles.MenuItem key={value} isSelected={isActiveTab}>
            <styles.NavLink to={to(path)} replace={isActiveTab}>
              <styles.IconWrapper isSelected={isActiveTab}>
                {icon ? icon() : null}
              </styles.IconWrapper>

              <styles.Label isSelected={isActiveTab}>{label}</styles.Label>
            </styles.NavLink>
          </styles.MenuItem>
        );
      })}
    </styles.MenuList>
  );
});
