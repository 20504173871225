/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { AddressHelper, AddressHelperInterface } from "../AddressHelper";

const _abi = [
  {
    inputs: [
      {
        internalType: "address[]",
        name: "accounts",
        type: "address[]",
      },
    ],
    name: "hasCode",
    outputs: [
      {
        internalType: "bool[]",
        name: "",
        type: "bool[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "hasCodeSingle",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x61032a61003a600b82828239805160001a60731461002d57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600436106100405760003560e01c8063045775c3146100455780638f1f1a201461006e575b600080fd5b61005861005336600461018a565b61009b565b604051610065919061024f565b60405180910390f35b61008b61007c366004610295565b6001600160a01b03163b151590565b6040519015158152602001610065565b805160609060008167ffffffffffffffff8111156100bb576100bb610158565b6040519080825280602002602001820160405280156100e4578160200160208202803683370190505b50905060005b828110156101505761011e858281518110610107576101076102b7565b60200260200101516001600160a01b03163b151590565b828281518110610130576101306102b7565b91151560209283029190910190910152610149816102cd565b90506100ea565b509392505050565b634e487b7160e01b600052604160045260246000fd5b80356001600160a01b038116811461018557600080fd5b919050565b6000602080838503121561019d57600080fd5b823567ffffffffffffffff808211156101b557600080fd5b818501915085601f8301126101c957600080fd5b8135818111156101db576101db610158565b8060051b604051601f19603f8301168101818110858211171561020057610200610158565b60405291825284820192508381018501918883111561021e57600080fd5b938501935b82851015610243576102348561016e565b84529385019392850192610223565b98975050505050505050565b6020808252825182820181905260009190848201906040850190845b8181101561028957835115158352928401929184019160010161026b565b50909695505050505050565b6000602082840312156102a757600080fd5b6102b08261016e565b9392505050565b634e487b7160e01b600052603260045260246000fd5b6000600182016102ed57634e487b7160e01b600052601160045260246000fd5b506001019056fea26469706673582212208d65666d0b32835353605c9612894126783657d8a87bfe704285f03c3fc4756164736f6c63430008100033";

export class AddressHelper__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<AddressHelper> {
    return super.deploy(overrides || {}) as Promise<AddressHelper>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): AddressHelper {
    return super.attach(address) as AddressHelper;
  }
  connect(signer: Signer): AddressHelper__factory {
    return super.connect(signer) as AddressHelper__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): AddressHelperInterface {
    return new utils.Interface(_abi) as AddressHelperInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): AddressHelper {
    return new Contract(address, _abi, signerOrProvider) as AddressHelper;
  }
}
