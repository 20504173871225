import { CurrencyAmount, NativeCurrency, Percent, Token } from "@uniswap/sdk-core";
import { useMemo } from "react";
import { separation } from "src/helpers/separation";
import { DEXV2BaseInfo } from "src/state/DEXV2/DEXV2Settings/Info";
import { DEXV2ModeImpactInfo } from "src/state/DEXV2/DEXV2Settings/Info/PriceImpactInfo/ModeImpactInfoStore";
import {
  formatCurrencyAmount,
  formatFiatAmount,
  formatPriceImpact,
  formatPriceImpactValue,
} from "src/state/DEXV2/DEXV2Swap/utils";

const formatSeparation = (value: string, useSeparation: boolean) =>
  useSeparation ? separation(value) : value;

interface UseNativeFormatParams {
  value: CurrencyAmount<NativeCurrency> | undefined;
  usd: CurrencyAmount<Token> | undefined;
  formatSpace?: boolean;
}

export const useNativeFormat = ({ value, usd, formatSpace = false }: UseNativeFormatParams) => {
  const formattedNative = useMemo(() => {
    const gasNativeText = formatCurrencyAmount(value);
    const gasUsdText = formatFiatAmount(usd, { tickerPosition: "end" });

    return {
      usd: formatSeparation(gasUsdText, formatSpace),
      value: formatSeparation(gasNativeText, formatSpace),
    };
  }, [value, usd, formatSpace]);

  return formattedNative;
};

interface UseTokenFormatParams {
  value: CurrencyAmount<Token> | undefined;
  usd: CurrencyAmount<Token> | undefined;
  formatSpace?: boolean;
}

export const useTokenFormat = ({ value, usd, formatSpace = false }: UseTokenFormatParams) => {
  const formattedGas = useMemo(() => {
    const gasTokenText = formatCurrencyAmount(value);
    const gasUsdText = formatFiatAmount(usd, { tickerPosition: "end" });

    return {
      usd: formatSeparation(gasUsdText, formatSpace),
      value: formatSeparation(gasTokenText, formatSpace),
    };
  }, [value, usd, formatSpace]);

  return formattedGas;
};

const formatSwapPriceImpactValue = (value: Percent | undefined) => {
  const priceImpact = formatPriceImpact(value);
  return priceImpact.impact;
};

interface UsePriceImpactFormatParams extends DEXV2ModeImpactInfo {}

export const usePriceImpactFormat = ({ buy, sell, average }: UsePriceImpactFormatParams) => {
  const formattedPriceImpact = useMemo(() => {
    const buyText = formatSwapPriceImpactValue(buy);
    const sellText = formatSwapPriceImpactValue(sell);
    const averageText = formatPriceImpactValue(average);

    const buySellText = `Buy: ${buyText}\nSell: ${sellText}`;

    return { buySell: buySellText, average: averageText };
  }, [buy, sell, average]);

  return formattedPriceImpact;
};

interface UseBaseInfoFormatParams {
  info: DEXV2BaseInfo;
}

export const useBaseInfoFormat = ({ info }: UseBaseInfoFormatParams) => {
  const {
    last24hFees: { native: last24hFeesNative, usd: last24hFeesUsd },
    gasEstimate: { usd: gasUsd, native: gasNative },
    priceImpact: { buy, sell, average },
  } = info;

  const { usd: gasUsdText, value: gasNativeText } = useNativeFormat({
    value: gasNative,
    usd: gasUsd,
  });

  const { usd: last24hFeesUsdText, value: last24hFeesNativeText } = useNativeFormat({
    value: last24hFeesNative,
    usd: last24hFeesUsd,
  });

  const { buySell: impactBuySellText, average: impactAverageText } = usePriceImpactFormat({
    buy,
    sell,
    average,
  });

  return {
    last24hFeesUsdText,
    last24hFeesNativeText,
    gasUsdText,
    gasNativeText,
    impactBuySellText,
    impactAverageText,
  };
};
