import { observer } from "mobx-react-lite";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import useLocalStore from "src/hooks/useLocalStore";
import AddLiquidityBinding, {
  IAddLiquidityBindingParams,
} from "src/state/CEX/CEXApiKeys/AddLiquidityBinding";
import { AccountSelector } from "../../../../shared/Account/AccountSelector";
import { AccountFormProps } from "../../../../shared/AccountForm";
import * as styles from "../../../VolumeAccounts/AddVolumeAccountModal/AddVolumeAccountModalContent/style";

export interface AddLiquidityAccountModalContentOwnProps extends IAddLiquidityBindingParams {}

export interface AddLiquidityAccountModalContentProps
  extends AccountFormProps,
    AddLiquidityAccountModalContentOwnProps {}

export const AddLiquidityAccountModalContent = observer(
  ({
    onSuccess,
    setLoading,
    onAddAccount,
    bindingsProvider,
    ...props
  }: AddLiquidityAccountModalContentProps) => {
    const state = useLocalStore(AddLiquidityBinding, {
      bindingsProvider,
      onSuccess,
      setLoading,
      onAddAccount,
    });

    return (
      <styles.Form onSubmit={state.addAccount} {...props}>
        <styles.FormContent>
          <LabeledSelector
            label="Name"
            placeholder="name"
            onChange={state.selectorHandler("botAccountName")}
            options={state.selectorOptions("botAccountName")}
            value={state.selectorValue("botAccountName")}
            errorHint={state.errors.name}
          />
          <AccountSelector
            onChange={state.selectorHandler("accountName")}
            options={state.selectorOptions("accountName")}
            value={state.selectorValue("accountName")}
            errorHint={state.errors.account?.name}
          />
          <styles.Button />
        </styles.FormContent>
      </styles.Form>
    );
  }
);
