import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { Headline3 } from "src/components/shared/Typography";
import { ICEXBotInfoWithMarket, IDEXBotInfoWithMarket } from "src/modules/bots";
import { SelectorValue } from "src/modules/shared";
import { Bot } from "./Bot";
import * as styles from "./style";

export interface BotsProps {
  typeBot: "CEX" | "DEX" | "DEXV2";
  bots: ICEXBotInfoWithMarket[] | IDEXBotInfoWithMarket[];
  parties: SelectorValue[];
  clickMoveHandler: (name: string, party: string) => () => void;
  getBots: () => void;
  showLoader?: boolean;
}

export const Bots = observer(
  ({ typeBot, bots = [], parties, clickMoveHandler, getBots, showLoader }: BotsProps) => {
    useEffect(() => {
      getBots();
    }, [getBots]);

    return (
      <styles.Container>
        <Headline3>{typeBot} bots</Headline3>
        <styles.Content>
          {bots.length
            ? bots.map((el) => (
                <Bot
                  bot={el}
                  key={el?.bot_uuid}
                  parties={parties}
                  clickMoveHandler={clickMoveHandler}
                />
              ))
            : "There are no bots in this party"}
          <Loader show={showLoader} />
        </styles.Content>
      </styles.Container>
    );
  }
);
