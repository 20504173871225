import styled from "styled-components";

export type ErrorCellProps = {
  isActive?: boolean;
};

export const ErrorCellWrapper = styled.span<ErrorCellProps>`
  ${({ isActive, theme }) => {
    if (isActive) {
      return `color: ${theme.primaryColor}`;
    }
  }}
`;
