import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
`;

const ModuleCell = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px;
`;

export const LeftModuleContent = styled(ModuleCell)`
  width: 40%;

  border-right: 1px solid #e0e0e0;
  padding-right: 5px;
`;

export const RightModuleContent = styled(ModuleCell)`
  width: 60%;

  padding-left: 5px;
`;

export const Wrapper = styled.div`
  display: flex;

  gap: 7px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex: 1;

  gap: 5px;
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: 0px;

  display: flex;
  flex: 1 1 auto;
`;

export const InfoText = styled.span`
  display: flex;

  word-break: break-word;
  overflow: auto;

  color: ${({ theme }) => theme.smallHeader};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};

  ${ScrollSize}
`;

export const ErrorText = styled(InfoText)`
  color: ${({ theme }) => theme.dangerColor};
`;

export const SeeMore = styled.span`
  white-space: nowrap;

  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  font-style: italic;
  color: ${({ theme }) => theme.primaryColor};

  cursor: pointer;
`;
