import React, { ComponentPropsWithoutRef } from "react";
import { StatsCommonProps } from "src/components/BotsContent/CEX/LiquidityTab/GridStats/StatsPanel/StatsList/StatsItem";
import { InfoPanelCell } from "./InfoPanelCell";
import * as styles from "./style";

type CellValue = string | number | boolean;

export interface CellData {
  value: CellValue;
  label: string;
  quote?: string;
  color?: string;
  cellProps?: {
    value?: StatsCommonProps;
    label?: StatsCommonProps;
  };
}

export interface InfoPanelProps extends ComponentPropsWithoutRef<"div"> {
  data: CellData[];
}

export const InfoPanel = ({ data = [], ...props }: InfoPanelProps) => (
  <styles.Container {...props}>
    {data.map((item, index) => {
      const key = item.label + index;
      return <InfoPanelCell key={key} data={item} />;
    })}
  </styles.Container>
);
