import { IArrayInputFormProvider } from "./ArrayInputStore";
import { IWalletsProvider } from "./WalletInputStore";

export class WalletsInputFormAdapter implements IArrayInputFormProvider<string> {
  private _provider: IWalletsProvider;

  constructor(provider: IWalletsProvider) {
    this._provider = provider;
  }

  get formArray() {
    return this._provider.wallets;
  }

  setFormArray = (arr: string[]) => {
    this._provider.setWallets(arr);
  };
}
