import { SelectorContainer } from "src/components/shared/DatePickers/DateTimeRangePickerSelector/SelectorDropdown/style";
import { Selector } from "src/components/shared/Forms/Selectors";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import { StringSelectorValue } from "src/state/UserManager/Scopes/EditScopeStore";
import styled from "styled-components";

enum SelectorsBreakpoints {
  Tablet = "900px",
  Mobile = "700px",
}

export const SelectorsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  @media ${getMobileMediaQuery(SelectorsBreakpoints.Mobile)} {
    flex-direction: column;
  }
`;

export const PickerContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;

  ${SelectorContainer} {
    max-width: 200px;
    min-width: 100px;
  }

  @media ${getMobileMediaQuery(SelectorsBreakpoints.Mobile)} {
    ${SelectorContainer} {
      max-width: 100%;
    }
  }
`;

export const AccountingSelector = styled(Selector).attrs({
  isMulti: true,
})`` as typeof Selector<StringSelectorValue, true>;
