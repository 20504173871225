import { PrimitiveSelectorValue } from "src/helpers/forms/selectors";
import {
  CandleCloserChangeableSettingsModule,
  ChangeableCEXSettings,
  DecimalsChangeableSettingsModule,
  FormControlConfig,
  PairChangeableSettingsModule,
  PeriodChangeableSettingsModule,
  SettingsParamsPaths,
  SpreadDecompressionChangeableSettingsModule,
  TradeChangeableSettingsModule,
  VerifyOrderChangeableSettingsModule,
  VirtualRangeChangeableSettingsModule,
  VolumeChangeableSettingsModule,
} from "src/state/expertSystem/strategies/changeSettingsModule/shared/types";

export const EMPTY_PAIR_SETTINGS_MODULE: PairChangeableSettingsModule = {
  // candlePercentMax: "",
  // candlePercentMin: "",
  dontCheckAmount: "",
  dontTradePriceMax: "",
  dontTradePriceMin: "",
  minExchangeAmount: "",
};

export const EMPTY_PERIOD_SETTINGS_MODULE: PeriodChangeableSettingsModule = {
  value: 1,
};

export const EMPTY_VOLUME_SETTINGS_MODULE: VolumeChangeableSettingsModule = {
  modifyDeltaBalanceBase: "",
  modifyDeltaBalanceQuoteUSD: "",
  // modifyTradePerDayMaxUSD: "",
  modifyTradePerDayMinUSD: "",
  // tradePerDayMaxUSD: "",
  tradePerDayMinUSD: "",
};

export const EMPTY_TRADE_SETTINGS_MODULE: TradeChangeableSettingsModule = {
  buyBeforeSellMax: "",
  buyBeforeSellMin: "",
  buyPercent: "",
  maxTrades: "",
  minTrades: "",
};

export const EMPTY_DECIMALS_SETTINGS_MODULE: DecimalsChangeableSettingsModule = {
  amount: "",
  price: "",
};

export const EMPTY_VIRTUAL_RANGE_MODULE: VirtualRangeChangeableSettingsModule = {
  buyMaxAmount: "",
  buyMinAmount: "",
  cancelSpreadOrders: false,
  diff: "",
  enableBuyWall: false,
  enableSellWall: false,
  ratio: "",
  sellMaxAmount: "",
  sellMinAmount: "",
  spread: "",
};

export const EMPTY_SPREAD_DECOMPRESSION_MODULE: SpreadDecompressionChangeableSettingsModule = {
  alongMode: { dayLimit: "", oneTimeLimit: "" },
  on: false,
  oppositeMode: { dayLimit: "", oneTimeLimit: "" },
};

export const EMPTY_VERIFY_ORDER_MODULE: VerifyOrderChangeableSettingsModule = {
  on: false,
  percent: "",
};

export const EMPTY_CANDLE_CLOSER_MODULE: CandleCloserChangeableSettingsModule = {
  on: false,
  outOfRange: false,
  period: 5,
};

export const EMPTY_CHANGEABLE_SETTINGS_PARAMS: ChangeableCEXSettings = {
  candleCloser: EMPTY_CANDLE_CLOSER_MODULE,
  decimals: EMPTY_DECIMALS_SETTINGS_MODULE,
  pair: EMPTY_PAIR_SETTINGS_MODULE,
  period: EMPTY_PERIOD_SETTINGS_MODULE,
  spreadDecompression: EMPTY_SPREAD_DECOMPRESSION_MODULE,
  trade: EMPTY_TRADE_SETTINGS_MODULE,
  verifyOrder: EMPTY_VERIFY_ORDER_MODULE,
  virtualRange: EMPTY_VIRTUAL_RANGE_MODULE,
  volume: EMPTY_VOLUME_SETTINGS_MODULE,
};

export const SETTINGS_PARAMS_CONFIG_MAP: Record<SettingsParamsPaths, FormControlConfig> = {
  "pair.minExchangeAmount": { label: "MinExchangeAmount", type: "input" },
  "pair.dontCheckAmount": { label: "DontCheckAmount", type: "input" },
  "pair.dontTradePriceMax": { label: "DontTradePriceMax", type: "input" },
  "pair.dontTradePriceMin": { label: "DontTradePriceMin", type: "input" },
  "period.value": { label: "Period", type: "switches" },
  "decimals.amount": { label: "Amount decimals", type: "input" },
  "decimals.price": { label: "Price decimals", type: "input" },
  "volume.tradePerDayMinUSD": { label: "Volume $", type: "input" },
  "volume.modifyDeltaBalanceQuoteUSD": { label: "Modify Delta $", type: "input" },
  "volume.modifyTradePerDayMinUSD": { label: "Modify Volume $", type: "input" },
  "volume.modifyDeltaBalanceBase": { label: "Modify Delta Base", type: "input" },
  "trade.buyPercent": { label: "BuyPercent", type: "range" },
  "trade.buyBeforeSellMin": { label: "BBsMin", type: "input" },
  "trade.buyBeforeSellMax": { label: "BBsMax", type: "input" },
  "trade.maxTrades": { label: "TradesMax", type: "input" },
  "trade.minTrades": { label: "TradesMin", type: "input" },
  "virtualRange.cancelSpreadOrders": { label: "Cancel Spread Orders", type: "checkbox" },
  "virtualRange.diff": { label: "Diff", type: "input" },
  "virtualRange.enableBuyWall": { label: "Buy Wall", type: "checkbox" },
  "virtualRange.enableSellWall": { label: "Sell Wall", type: "checkbox" },
  "virtualRange.spread": { label: "Spread", type: "input" },
  "virtualRange.ratio": { label: "Ratio", type: "input" },
  "virtualRange.buyMaxAmount": { label: "Buy Max Amount", type: "input" },
  "virtualRange.buyMinAmount": { label: "Buy Min Amount", type: "input" },
  "virtualRange.sellMinAmount": { label: "Sell Min Amount", type: "input" },
  "virtualRange.sellMaxAmount": { label: "Sell Max Amount", type: "input" },
  "spreadDecompression.alongMode.dayLimit": { label: "Along With Mode / Day Limit", type: "input" },
  "spreadDecompression.alongMode.oneTimeLimit": {
    label: "Along With Mode / One Time Limit",
    type: "input",
  },
  "spreadDecompression.oppositeMode.dayLimit": {
    label: "Opposite to Mode / Day Limit",
    type: "input",
  },
  "spreadDecompression.oppositeMode.oneTimeLimit": {
    label: "Opposite to Mode / One Time Limit",
    type: "input",
  },
  "spreadDecompression.on": { label: "Spread Decompression / On", type: "toggle" },
  "verifyOrder.on": { label: "Verify Order / On", type: "toggle" },
  "verifyOrder.percent": { label: "Verify order", type: "selector" },
  "candleCloser.on": { label: "Candle Closer / On", type: "toggle" },
  "candleCloser.period": { label: "Candle Closer Period", type: "switches" },
  "candleCloser.outOfRange": { label: "Candle Closer ONLY Out of range", type: "checkbox" },
};

// these parameters are calculated implicitly before submitting the data
// and should not be taken into account in the form on the client
export const HIDDEN_SETTINGS_PARAMS = [
  "volume.tradePerDayMaxUSD",
  "volume.modifyTradePerDayMaxUSD",
] as const;

export const VERIFY_ORDER_PERCENTS = [
  { label: "10%", value: "0.1" },
  { label: "12.5%", value: "0.125" },
  { label: "15%", value: "0.15" },
  { label: "17.5%", value: "0.175" },
  { label: "20%", value: "0.2" },
  { label: "22.5%", value: "0.225" },
  { label: "25%", value: "0.25" },
  { label: "30%", value: "0.3" },
  { label: "35%", value: "0.35" },
  { label: "40%", value: "0.4" },
];

export const SETTINGS_SWITCHES_ITEMS_MAP: Partial<
  Record<SettingsParamsPaths, PrimitiveSelectorValue<number>[]>
> = {
  "candleCloser.period": [
    { value: 5, label: "5" },
    { value: 15, label: "15" },
  ],
  "period.value": [
    { value: 1, label: "1" },
    { value: 5, label: "5" },
  ],
};

export const PARAMETER_DEPENDENCY_GRAPH: Partial<
  Record<SettingsParamsPaths, SettingsParamsPaths[]>
> = {
  "pair.dontTradePriceMax": ["pair.dontTradePriceMin"],
  "pair.dontTradePriceMin": ["pair.dontTradePriceMax"],
  "trade.buyBeforeSellMin": ["trade.buyBeforeSellMax"],
  "trade.buyBeforeSellMax": ["trade.buyBeforeSellMin"],
  "trade.maxTrades": ["trade.minTrades"],
  "trade.minTrades": ["trade.maxTrades"],
  "virtualRange.buyMaxAmount": ["virtualRange.buyMinAmount"],
  "virtualRange.buyMinAmount": ["virtualRange.buyMaxAmount"],
  "virtualRange.sellMinAmount": ["virtualRange.sellMaxAmount"],
  "virtualRange.sellMaxAmount": ["virtualRange.sellMinAmount"],
  "spreadDecompression.alongMode.dayLimit": ["spreadDecompression.alongMode.oneTimeLimit"],
  "spreadDecompression.alongMode.oneTimeLimit": ["spreadDecompression.alongMode.dayLimit"],
  "spreadDecompression.oppositeMode.dayLimit": ["spreadDecompression.oppositeMode.oneTimeLimit"],
  "spreadDecompression.oppositeMode.oneTimeLimit": ["spreadDecompression.oppositeMode.dayLimit"],
};

export const ONLY_EQUAL_PARAMS: SettingsParamsPaths[] = [
  "candleCloser.on",
  "candleCloser.period",
  "candleCloser.outOfRange",
  "verifyOrder.on",
  "verifyOrder.percent",
  "spreadDecompression.on",
  "virtualRange.enableSellWall",
  "virtualRange.enableBuyWall",
  "virtualRange.cancelSpreadOrders",
  "period.value",
];
