import { makeAutoObservable, runInAction } from "mobx";
import {
  getMultiGrinderSettings,
  getMultiGrinderStatus,
  startMultiGrinder,
  stopMultiGrinder,
  updMultiGrinderSettings,
} from "src/api/bots/CEX/multigrinder";
import { showSuccessMsg } from "src/helpers/message";
import MultiGridStore, { IBaseMultiGridForm } from ".";
import { MultiGridStatus } from "../shared/types";
import MultiGridFormStore from "./MultiGridForm";

interface IMultiGridStatus {
  lastUpdateAt: number;
  status: MultiGridStatus | "";
}

const EMPTY_GRID_STATUS: IMultiGridStatus = {
  lastUpdateAt: 0,
  status: "",
};

class ActiveMultiGridStore implements IBaseMultiGridForm {
  private _gridStatusData: IMultiGridStatus = EMPTY_GRID_STATUS;

  formState: MultiGridFormStore;

  mainState: MultiGridStore;

  isLoading = false;

  constructor(state: MultiGridStore) {
    this.mainState = state;

    this.mainState.setUpdHandlers("updSettings", this.loadData);

    this.formState = new MultiGridFormStore();

    makeAutoObservable(this);
  }

  get party() {
    return this.mainState.party;
  }

  get gridUUID() {
    return this.mainState.gridUUID;
  }

  get gridStatus() {
    return this._gridStatusData.status;
  }

  get gridLastUpd() {
    return this._gridStatusData.lastUpdateAt;
  }

  private _setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  loadData = () => {
    this._getGridSettings(this.gridUUID);
    this._getGridStatus(this.gridUUID);
  };

  private _getGridSettings = async (party: string) => {
    this._setLoading(true);

    try {
      const { isError, data } = await getMultiGrinderSettings(party);

      if (!isError) {
        this.formState.setSettings(data);
      }
    } finally {
      this._setLoading(false);
    }
  };

  private _getGridStatus = async (uuid: string) => {
    try {
      const { isError, data } = await getMultiGrinderStatus(uuid);

      if (!isError) {
        runInAction(() => {
          this._gridStatusData = data;
        });
      }
    } catch {
      runInAction(() => {
        this._gridStatusData = EMPTY_GRID_STATUS;
      });
    }
  };

  startGrid = async () => {
    this._setLoading(true);

    try {
      const { isError } = await startMultiGrinder(this.gridUUID);

      if (!isError) {
        showSuccessMsg("Grid started");
      }
    } finally {
      this._setLoading(false);
    }
  };

  stopGrid = async () => {
    this._setLoading(true);

    try {
      const { isError } = await stopMultiGrinder(this.gridUUID);

      if (!isError) {
        showSuccessMsg("Grid stopped");
      }
    } finally {
      this._setLoading(false);
    }
  };

  submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.formState.validate();

    if (valid) {
      this._setLoading(true);

      try {
        const { isError } = await updMultiGrinderSettings(
          this.gridUUID,
          this.formState.grinder.settings
        );

        if (!isError) {
          showSuccessMsg("Grid settings saved successfully");
        }
      } finally {
        this._setLoading(false);
      }
    }
  };
}

export default ActiveMultiGridStore;
