import { makeAutoObservable } from "mobx";
import { GetDeployInfoResponse, getDeployInfo } from "src/api/bots/DEXV2/create";
import { ChainID } from "src/helpers/chain/contracts";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { IChainMetaProvider } from "src/state/chain/ChainMetaStore";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { IBotAddressesProvider, IBotChainProvider } from "../DEXV2Bots/DEXV2BotStore";
import { SetLoading } from "../DEXV2Stats/GasWallets/WithdrawGasStore";

export interface DeployerInfo extends Pick<GetDeployInfoResponse, "base" | "quote"> {
  chainId: ChainID;
  hash: string;
  status: string;
}

const INITIAL_DEPLOYER_INFO: DeployerInfo = {
  hash: "",
  chainId: "",
  base: "",
  quote: "",
  status: "",
};

const deployerInfoRespToInfo = ({
  trx_hash,
  trx_status,
  chain_id,
  base,
  quote,
}: GetDeployInfoResponse): DeployerInfo => ({
  hash: trx_hash,
  status: trx_status,
  base,
  quote,
  chainId: `${chain_id}`,
});

export default class DeployerInfoStore
  implements IBotChainProvider, IBotAddressesProvider, IDisposable
{
  private _info: DeployerInfo = INITIAL_DEPLOYER_INFO;

  private _chainProvider: IChainProvider;

  private _chainMetaProvider: IChainMetaProvider;

  constructor(chainProvider: IChainProvider, chainMetaProvider: IChainMetaProvider) {
    makeAutoObservable(this);

    this._chainProvider = chainProvider;

    this._chainMetaProvider = chainMetaProvider;
  }

  private _setInfo = (info: DeployerInfo) => {
    this._info = info;
  };

  get info() {
    return this._info;
  }

  get addresses() {
    const baseAddress = this.info.base;
    const quoteAddress = this.info.quote;
    if (!quoteAddress || !baseAddress) {
      return null;
    }
    return { quote: quoteAddress, base: baseAddress };
  }

  get chainProvider() {
    return this._chainProvider;
  }

  private get _chainMetaMap() {
    return this._chainMetaProvider.chainMetaMap;
  }

  get chainMeta() {
    const { chainId } = this._info;
    return this._chainMetaMap.get(chainId);
  }

  getDeployerInfo = async (botUUID: string, setLoading?: SetLoading) => {
    setLoading?.(true);

    try {
      const { isError, data } = await getDeployInfo(botUUID);

      if (!isError) {
        const info = deployerInfoRespToInfo(data);
        this._setInfo(info);
        const { chainId } = info;
        if (chainId) {
          this._chainProvider.setChainId(chainId);
        }
      }
    } catch (err) {
      logError(err);
    } finally {
      setLoading?.(false);
    }
  };

  destroy = () => {};
}
