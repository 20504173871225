const HTTPS_PROTOCOL = "https://";
const WS_PROTOCOL = "wss://";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const apiUrl = HTTPS_PROTOCOL + baseUrl;

export const wsUrl = WS_PROTOCOL + baseUrl;

export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const enableStories = process.env.REACT_APP_ENABLE_STORIES;
