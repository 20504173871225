import { CurrencyAmount, NativeCurrency, Token } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { ABSTRACT_NATIVE_CURRENCY } from "src/state/DEXV2/DEXV2Swap/utils";
import { INativeUSDPriceProvider } from "src/state/DEXV2/Providers/NativeUSDPriceProvider";
import { IGasPriceProvider } from "../shared/Providers/GasPriceProvider";
import { ISwapGasLimitProvider } from "../shared/Providers/SwapSettingsProvider";

interface ISwapInfoGasEstimateParams {
  gasLimitProvider: ISwapGasLimitProvider;
  nativeUSDPriceProvider: INativeUSDPriceProvider;
  gasPriceProvider: IGasPriceProvider;
}

export interface ISwapInfoGasEstimate {
  get gasEstimate(): CurrencyAmount<NativeCurrency> | undefined;
  get gasEstimateUSD(): CurrencyAmount<Token> | undefined;
}

export class SwapInfoGasEstimateStore implements ISwapInfoGasEstimate, IDisposable {
  private _nativeUSDPriceProvider: INativeUSDPriceProvider;

  private _gasPriceProvider: IGasPriceProvider;

  private _gasLimitProvider: ISwapGasLimitProvider;

  constructor({
    gasLimitProvider,
    nativeUSDPriceProvider,
    gasPriceProvider,
  }: ISwapInfoGasEstimateParams) {
    makeAutoObservable(this);

    this._nativeUSDPriceProvider = nativeUSDPriceProvider;

    this._gasPriceProvider = gasPriceProvider;

    this._gasLimitProvider = gasLimitProvider;

    makeLoggable(this, {});
  }

  private get _nativeUSDPrice() {
    return this._nativeUSDPriceProvider.nativeUSDPrice;
  }

  private get _gasLimit() {
    return this._gasLimitProvider.gasLimit;
  }

  private get _gasPrice() {
    return this._gasPriceProvider.gasPrice;
  }

  get gasEstimate() {
    const gasLimit = this._gasLimit;
    const gasPrice = this._gasPrice;
    if (!gasLimit || !gasPrice) return undefined;

    const { gasPriceWei } = gasPrice;
    const gasEstimate = gasPriceWei.mul(gasLimit);

    return CurrencyAmount.fromRawAmount(ABSTRACT_NATIVE_CURRENCY, gasEstimate.toString());
  }

  get gasEstimateUSD(): CurrencyAmount<Token> | undefined {
    const nativeUSDPrice = this._nativeUSDPrice;
    const rawGasAmount = this.gasEstimate;
    if (!rawGasAmount || !nativeUSDPrice) return undefined;

    return nativeUSDPrice.quote(rawGasAmount);
  }

  destroy = () => {};
}
