import styled, { css } from "styled-components";
import { TradePanel as BaseTradePanel } from "./TradePanel";
import { VolumePanel as BaseVolumePanel } from "./VolumePanel";

export const MainSettingsPanelMixin = css`
  width: auto;
  max-width: 100%;
  flex-grow: 1;
`;

export const SideSettingsPanelMixin = css`
  width: auto;
  min-width: 30%;
  flex-shrink: 0;
`;

export const VolumePanel = styled(BaseVolumePanel)`
  /* ${MainSettingsPanelMixin} */
`;

export const TradePanel = styled(BaseTradePanel)`
  /* ${SideSettingsPanelMixin} */
`;
