import { StrategyCreatedModule } from "src/modules/expertSystem";
import { useParamsText } from "../../../../../../shared/hooks/useParamText";
import { InfoColumn, InfoLabel, InfoUniqueName } from "../../../../../../shared/style";
import * as styles from "./style";

export interface ModuleInfoProps {
  module: StrategyCreatedModule;
}

export const ModuleInfo = ({ module }: ModuleInfoProps) => {
  const { hard_params, soft_params, ...mainParams } = module;

  const mainParamsText = useParamsText(mainParams);
  const hardParamsText = useParamsText(hard_params);
  const softParamsText = useParamsText(soft_params);

  return (
    <styles.Container>
      <InfoColumn>
        <InfoLabel>Main params:</InfoLabel>
        <InfoUniqueName>{mainParamsText}</InfoUniqueName>
      </InfoColumn>

      <InfoColumn>
        <InfoLabel>Hard params:</InfoLabel>
        <InfoUniqueName>{hardParamsText}</InfoUniqueName>
      </InfoColumn>

      <InfoColumn>
        <InfoLabel>Soft params:</InfoLabel>
        <InfoUniqueName>{softParamsText}</InfoUniqueName>
      </InfoColumn>
    </styles.Container>
  );
};
