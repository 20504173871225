import { observer } from "mobx-react-lite";
import { ITooltip as TooltipProps } from "react-tooltip";
import { BalancesTooltipContent, BalancesTooltipContentProps } from "./BalancesTooltipContent";
import * as styles from "./style";

export interface BalancesTooltipProps
  extends TooltipProps,
    Pick<BalancesTooltipContentProps, "currency" | "balances"> {}

export const BalancesTooltip = observer(
  ({ currency, balances, ...props }: BalancesTooltipProps) => {
    const contentProps = { currency, balances };

    return (
      <styles.StyledTooltip {...props}>
        <BalancesTooltipContent {...contentProps} />
      </styles.StyledTooltip>
    );
  }
);
