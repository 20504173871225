import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;

  background: ${({ theme }) => theme.dashboard.backgroundSurface};
`;

export const Label = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.dashboard.textSecondary};
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
`;

export const Balance = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.dashboard.textDefault};
  font-weight: ${({ theme }) => theme.fontWeights["600"]};
`;
