import { useCallback } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { ControlledCheckBox } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledCheckbox";
import { ControlledInput } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledInput";
import { ControlledInputPercentRange } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledInputPercentRange";
import { ControlledSelector } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledSelector";
import { ControlledSwitches } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledSwitches";
import { ControlledToggle } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledToggle";
import {
  getSelectorValueFromOptions,
  primitiveSelectorValueToString,
} from "src/helpers/forms/selectors";
import { VERIFY_ORDER_PERCENTS } from "src/state/expertSystem/strategies/changeSettingsModule/shared/consts";
import {
  FormControlConfig,
  SettingsParamsPaths,
} from "src/state/expertSystem/strategies/changeSettingsModule/shared/types";
import { getLabelParam, getSwitchesItems } from "../../../utils/changeSettings";

interface SwitchControllerProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  param: SettingsParamsPaths;
  config: FormControlConfig;
  control: Control<TForm>;
  name: TName;
}

export const SwitchController = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  config,
  control,
  param,
  name,
}: SwitchControllerProps<TForm, TName>) => {
  const getContent = useCallback(
    (config: FormControlConfig) => {
      switch (config.type) {
        case "input":
          return (
            <ControlledInput
              controllerProps={{
                control,
                name,
                type: "number",
                ignoreType: true,
                label: getLabelParam(param),
              }}
            />
          );

        case "selector":
          return (
            <ControlledSelector
              componentProps={{
                label: getLabelParam(param),
                menuPosition: "fixed",
                options: VERIFY_ORDER_PERCENTS,
                getValueHandler: getSelectorValueFromOptions(VERIFY_ORDER_PERCENTS),
                onChangeHandler: primitiveSelectorValueToString,
              }}
              controllerProps={{ control, name }}
            />
          );

        case "range":
          return (
            <ControlledInputPercentRange
              controllerProps={{ control, name, label: getLabelParam(param) }}
            />
          );

        case "checkbox":
          return (
            <ControlledCheckBox controllerProps={{ control, name, label: getLabelParam(param) }} />
          );

        case "switches":
          return (
            <ControlledSwitches
              controllerProps={{
                control,
                name,
                label: getLabelParam(param),
                componentProps: { items: getSwitchesItems(param), style: { width: "max" } },
              }}
            />
          );

        case "toggle":
          return (
            <ControlledToggle controllerProps={{ control, name, label: getLabelParam(param) }} />
          );

        default:
          return null;
      }
    },
    [control, name, param]
  );

  return <>{getContent(config)}</>;
};
