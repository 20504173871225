import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const Content = styled.div`
  position: relative;

  height: 100%;
  width: 100%;

  display: flex;
  flex: 1;
`;

export const TVChartContainer = styled(PanelContainer)`
  /* height: 100%; */

  box-shadow: none;

  & > iframe {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
