import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ContentRows } from "./ContentRows";
import * as styles from "./style";

export interface ModalContentProps {
  orderId: string;
}

export const ModalContent = observer(({ orderId }: ModalContentProps) => {
  const mainState = useLateInitContext(ExchangeContext);

  const { floatingOrdersState } = mainState;

  const { getStatusState, orderInfo } = floatingOrdersState;

  const { isStatusLoading } = getStatusState;

  useEffect(() => {
    getStatusState.setSelectOrderId(orderId);
  }, [getStatusState, orderId]);

  return (
    <styles.Container>
      <styles.Content>
        {orderInfo && <ContentRows orderInfo={orderInfo} />}

        <Loader show={isStatusLoading} />
      </styles.Content>
    </styles.Container>
  );
});
