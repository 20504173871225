import styled from "styled-components";
import { KPICard } from "../../../../shared/KPICard";
import { KPIText } from "../../../../shared/KPICard/style";

export const KPILabel = styled(KPIText)``;

export const KPIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledKPICard = styled(KPICard)`
  padding: 12px 8px;

  gap: 18px;

  ${KPIText} {
    font-size: ${({ theme }) => theme.fontSizes["14px"]};
  }
`;
