import { CSSProperties } from "react";
import * as styles from "./style";

export type StatsItemData = {
  name: string;
  value: string | number;
};

export interface StatsCommonProps {
  style?: CSSProperties | undefined;
  className?: string | undefined;
}

type StatsPropsGetter<P> = (props: P) => StatsCommonProps;

export type StatsItemPropsGetters = {
  [P in keyof StatsItemData]?: StatsPropsGetter<Pick<StatsItemData, P>>;
};

export interface StatsItemProps extends React.ComponentPropsWithoutRef<"div"> {
  item: StatsItemData;
  itemProps?: StatsItemPropsGetters;
}

const StatsItem = ({ item: { name, value }, itemProps = {}, ...props }: StatsItemProps) => (
  <styles.StatsItem {...props}>
    <styles.Title {...itemProps?.name?.({ name })}>{name}</styles.Title>
    <styles.Value {...itemProps?.value?.({ value })}>{value}</styles.Value>
  </styles.StatsItem>
);

export default StatsItem;
