import { useCallback, useContext } from "react";
import {
  ChartingLibraryWidgetOptions,
  ResolutionString,
} from "src/charting_library/charting_library";
import { hexToRgb } from "src/helpers/colors";
import { ThemeContext } from "styled-components";

export const useDefaultTVChartProps = (): Omit<
  ChartingLibraryWidgetOptions,
  "container" | "datafeed"
> => {
  const theme = useContext(ThemeContext);

  const themeToggle = useCallback(() => localStorage.getItem("theme") === "dark", []);

  return {
    interval: "5" as ResolutionString,
    library_path: "/charting_library/",
    charts_storage_url: "https://saveload.tradingview.com",
    charts_storage_api_version: "1.1",
    client_id: "tradingview.com",
    user_id: "crypto_user",
    locale: "en",
    fullscreen: false,
    autosize: true,
    studies_overrides: {
      "volume.volume.color.0": hexToRgb(theme.dangerColor, 0.6),
      "volume.volume.color.1": hexToRgb(theme.successColor, 0.6),
    },
    disabled_features: [
      "use_localstorage_for_settings",
      "header_symbol_search",
      "header_compare",
      "symbol_search_hot_key",
      "header_saveload",
    ],
    enabled_features: ["move_logo_to_main_pane", "pricescale_unit", "header_in_fullscreen_mode"],
    overrides: {
      "paneProperties.background": theme.bgSettingsPnl,
      "paneProperties.vertGridProperties.color": theme.gridChartColor,
      "paneProperties.horzGridProperties.color": theme.gridChartColor,
      "scalesProperties.textColor": theme.textColor,
      "mainSeriesProperties.candleStyle.upColor": theme.successColor,
      "mainSeriesProperties.candleStyle.downColor": theme.dangerColor,

      "mainSeriesProperties.hollowCandleStyle.upColor": theme.successColor,
      "mainSeriesProperties.hollowCandleStyle.downColor": theme.dangerColor,
    },
    custom_css_url: themeToggle() ? "/darkTV.css" : "/lightTV.css",
  };
};
