import { makeAutoObservable, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import { FormEvent } from "react";
import { MultiValue } from "react-select";
import { SelectorPropsGetter } from "src/components/BotsContent/CEX/ExpertSystem/Modules/shared/ModuleFilters";
import { getSelectorList, toRawSelectorValue } from "src/helpers/forms/selectors";
import {
  ExecutionOrderType,
  STRATEGY_EXECUTION_ORDER_TYPES,
  STRATEGY_STATUSES,
  StrategyStatus,
} from "src/modules/expertSystem";
import { SelectorValue } from "src/modules/shared";
import FiltersEnabledStore from "../../modules/Filters/FiltersEnabledStore";

export type FiltersKeys = "status" | "type";

export type StrategyFiltersState = {
  status: StrategyStatus[];
  type: ExecutionOrderType[];
};

export interface IStrategyFiltersParams {
  onSubmit?: OnModuleFiltersSubmit;
  onClose?: () => void;
  initialState?: StrategyFiltersState;
}

type OnModuleFiltersSubmit = (values: StrategyFiltersState) => Promise<boolean | undefined>;

const INITIAL_VALUES: StrategyFiltersState = {
  type: [],
  status: [],
};

export default class StrategyFiltersStore {
  private _options!: StrategyFiltersState;

  private _values!: StrategyFiltersState;

  private _onSubmit?: OnModuleFiltersSubmit;

  private _onClose?: () => void;

  private _enabledState: FiltersEnabledStore<FiltersKeys>;

  constructor({ onSubmit, onClose, ...params }: IStrategyFiltersParams) {
    makeAutoObservable<any>(this, {
      _selectorOptions: false,
      _selectorValues: false,
      getFilterProps: false,
    });

    this._onSubmit = onSubmit;

    this._onClose = onClose;

    this._initState(params);

    this._enabledState = new FiltersEnabledStore({ filterStateProvider: this });
  }

  get state() {
    return this._values;
  }

  private _initState = ({ initialState }: IStrategyFiltersParams) => {
    this._values = toJS(initialState) ?? INITIAL_VALUES;

    const initialTypeOptions = STRATEGY_EXECUTION_ORDER_TYPES;

    this._options = {
      type: initialTypeOptions.slice(),
      status: STRATEGY_STATUSES.slice(),
    };
  };

  private _setFilter = <K extends FiltersKeys>(key: K, value: StrategyFiltersState[K]) => {
    this._values[key] = value;
  };

  private _removeFilterValue = <K extends FiltersKeys>(
    key: K,
    value: StrategyFiltersState[K][number]
  ) => {
    const currentValues = this._values[key] as string[];

    const newValues = currentValues.filter((v) => v !== value) as StrategyFiltersState[K];

    this._setFilter(key, newValues);
  };

  private _selectorOptions = computedFn((field: FiltersKeys) =>
    getSelectorList(this._options[field])
  );

  private _selectorValues = computedFn((field: FiltersKeys) =>
    getSelectorList(this._values[field])
  );

  private _selectorOnChange = (field: FiltersKeys) => (data: MultiValue<SelectorValue>) => {
    if (data) {
      const newValues = toRawSelectorValue(data) as StrategyFiltersState[FiltersKeys];
      this._setFilter(field, newValues);
    }
  };

  private _selectorOnRemove = (field: FiltersKeys) => (item: string) => {
    this._removeFilterValue(field, item as StrategyFiltersState[FiltersKeys][number]);
  };

  getSelectorProps: SelectorPropsGetter<FiltersKeys> = (key) => ({
    options: this._selectorOptions(key),
    value: this._selectorValues(key),
    onChange: this._selectorOnChange(key),
    onRemove: this._selectorOnRemove(key),
    isDisabled: !this._enabledState.state[key],
  });

  reset = () => {
    this._values = INITIAL_VALUES;
  };

  submit = async (e: FormEvent) => {
    e.preventDefault();

    const isSuccess = await this._onSubmit?.(this._values);

    if (isSuccess) {
      this._onClose?.();
    }
  };

  destroy = () => {};
}
