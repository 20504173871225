import { ComponentPropsWithoutRef, useMemo } from "react";
import * as styles from "./style";

export enum ModuleStatusType {
  unused = "unused",
  linked = "linked",
  active = "active",
}

const useModuleStatus = (linked: boolean, linkedActive: boolean) => {
  const moduleStatus = useMemo(() => {
    if (!linked) {
      return ModuleStatusType.unused;
    }
    if (!linkedActive) {
      return ModuleStatusType.linked;
    }
    return ModuleStatusType.active;
  }, [linked, linkedActive]);

  return moduleStatus;
};

const useModuleStatusText = (status: ModuleStatusType) => {
  switch (status) {
    case ModuleStatusType.unused: {
      return "Not used in strategy";
    }
    case ModuleStatusType.active:
    case ModuleStatusType.linked: {
      return "Used in strategy";
    }
  }
};

const useModuleIndicatorStatus = (status: ModuleStatusType) => {
  switch (status) {
    case ModuleStatusType.unused: {
      return "inactive";
    }
    case ModuleStatusType.linked: {
      return "failed";
    }
    case ModuleStatusType.active: {
      return "active";
    }
  }
};

export interface ModuleStatusProps extends ComponentPropsWithoutRef<"span"> {
  linked: boolean;
  linkedActive: boolean;
}

export const ModuleStatus = ({ linked, linkedActive }: ModuleStatusProps) => {
  const moduleStatus = useModuleStatus(linked, linkedActive);

  const statusText = useModuleStatusText(moduleStatus);

  const indicatorStatus = useModuleIndicatorStatus(moduleStatus);

  return (
    <styles.ModuleStatusCell>
      <styles.StyledModuleStatus status={indicatorStatus} />
      {statusText}
    </styles.ModuleStatusCell>
  );
};
