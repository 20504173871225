import { Fundings } from "src/state/MarketCheck/types";
import { FundingList } from "./FundingList";
import * as styles from "./style";

interface FundingCellProps {
  cellData: Fundings;
}

export const FundingCell = ({ cellData }: FundingCellProps) => {
  const { deposit, withdraw } = cellData;

  return (
    <styles.Wrapper>
      <FundingList title="Deposit" items={deposit} />

      <FundingList title="Withdraw" items={withdraw} />
    </styles.Wrapper>
  );
};
