import { Panel } from "src/components/shared/Tabs/style";
import styled from "styled-components";
import { SettingsTabs } from "../shared";

export const VirtWallsWrapper = styled.div`
  padding: 15px;
  border: ${({ theme }) => theme.borders.solidGray};
  border-radius: ${({ theme }) => theme.radii["16px"]};
`;

export const Content = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const WrapperForModules = styled.div`
  width: 100%;
  /* height: 100%; */
  position: relative;
  display: flex;
  /* flex: 1; */
  justify-content: space-between;
  /* max-height: 80px; */
  padding-bottom: 21px;

  & > div:not(:last-of-type) {
    margin-right: 34px;
  }

  :after {
    position: absolute;
    display: block;
    width: 100%;
    content: "";
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.lineColor};
    bottom: 0;
  }

  @media screen and (max-width: 850px) {
    & > div:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 450px) {
    padding-bottom: 0px;

    :after {
      display: none;
    }
  }
`;

export const WrapperForModule = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const BindingsTabs = styled(SettingsTabs)`
  flex: 1;

  ${Panel} {
    padding: 7px 0px 10px;
  }
`;

export const NoContent = styled.div`
  width: 100%;
  height: 53px;
  opacity: 0.5;
  z-index: 999;
  pointer-events: none;
`;
