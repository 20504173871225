import { Chart, ChartDataset, Plugin } from "chart.js";
import { roundToTwoDecimals } from "src/helpers/rounding";
import { addPluginData } from ".";

interface TotalizerOptions {
  direction?: "data" | "axis";
  filter?: (dataset: ChartDataset, chart: Chart) => boolean;
}

declare module "chart.js" {
  export interface PluginsData {
    totalizer?: {
      totals: number[];
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    totalizer?: TotalizerOptions;
  }
}

export const Totalizer: Plugin = {
  id: "totalizer",

  beforeUpdate: (chart, _args, options: TotalizerOptions) => {
    const totals: number[] = [];

    const { direction } = options;
    const { filter } = options;

    const shouldSumData = direction === "data";
    chart.data.datasets
      .filter((dataset) => (filter ? filter(dataset, chart) : true))
      .forEach((dataset, datasetIndex) => {
        if (chart.isDatasetVisible(datasetIndex)) {
          if (shouldSumData) {
            const total = dataset.data.reduce<number>((sum, value) => sum + (value as number), 0);
            totals[datasetIndex] = total;
          } else {
            dataset.data.forEach((value, index) => {
              totals[index] = (totals[index] || 0) + (value as number);
            });
          }
        }
      });
    addPluginData(chart, "totalizer", { totals });
  },

  // indicate to the library to skip altering the filter callback with context
  // as we use it as pure (see: scriptable options)
  // @ts-ignore
  descriptors: {
    _scriptable: (name: string) => name !== "filter",
  },
};

export const getTotal = (chart: Chart<any>, dataIndex: number) => {
  const total = chart.data.plugins?.totalizer?.totals?.[dataIndex] ?? 0;
  return roundToTwoDecimals(total);
};
