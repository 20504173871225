import { Percent } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { ISwapInfoRoute } from "../SwapInfoRouteStore";

interface ISwapInfoImpactV3Params {
  routeInfo: ISwapInfoRoute;
}

export interface IBaseSwapInfoImpact {
  get priceImpact(): Percent | undefined;
}

export class SwapInfoImpactV3Store implements IBaseSwapInfoImpact, IDisposable {
  private _routeInfo: ISwapInfoRoute;

  constructor({ routeInfo }: ISwapInfoImpactV3Params) {
    makeAutoObservable(this);

    makeLoggable(this, {});

    this._routeInfo = routeInfo;
  }

  private get _swapRoute() {
    return this._routeInfo.swapRoute;
  }

  /**
   * v3 price impact is based on the next price impact
   * or the execution price impact if no next price impact is available
   * */
  get priceImpact() {
    const trade = this._swapRoute?.trade;

    const nextPriceImpact = trade?.nextPriceImpact;
    const executionPriceImpact = trade?.priceImpact;

    const priceImpactPercent = nextPriceImpact === null ? executionPriceImpact : nextPriceImpact;

    return priceImpactPercent;
  }

  destroy = () => {};
}
