import { Tooltip } from "react-tooltip";
import styled from "styled-components";

export const Container = styled(Tooltip)`
  position: relative;
  max-height: 200px !important;

  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 5px 12px !important;

  border-radius: ${({ theme }) => theme.radii["4px"]};

  color: ${({ theme }) => theme.primaryColor} !important;
  background: ${({ theme }) => theme.promptMsgBg} !important;
  opacity: 1 !important;

  pointer-events: auto !important;
  overflow-x: clip;

  ::after {
    visibility: hidden;
  }

  ::before {
    visibility: hidden;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 3px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

export const ContentPoint = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

export const Title = styled.span`
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  color: currentColor;
  /* border-bottom: ${({ theme }) => theme.borders.solidGray}; */

  padding-bottom: 5px;
`;

export const Value = styled(Title)`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  border-bottom: none;
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.primaryColor};
  margin: 3px 0px;
`;
