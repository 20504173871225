import { formatFiat } from "src/helpers/string";
import CEXDashboardIcons from "src/icons/CEXDashboardIcons";
import { PartyExchange } from "src/state/CEX/CEXDashboard/v1/KPIStore";
import { KPICard, KPICardProps } from "../../../../shared/KPICard";
import { ExchangesList } from "./ExchangesList";

export interface AUMCardProps extends Omit<KPICardProps, "title" | "caption" | "subtitle"> {
  exchanges: PartyExchange[];
  kpi: string;
}

const AUM_INFO_TEXT =
  "AUM is the total amount of assets (total balance) in USD on provided accounts. ";

export const AUMCard = ({ exchanges, kpi, ...props }: AUMCardProps) => {
  const subtitle = "Current active exchanges:";

  return (
    <KPICard
      title="Assets under management (AUM):"
      info={AUM_INFO_TEXT}
      kpi={formatFiat(kpi)}
      titleIcon={<CEXDashboardIcons.Candles />}
      subtitle={subtitle}
      caption={<ExchangesList exchanges={exchanges} />}
      {...props}
    />
  );
};
