import { Label } from "src/components/shared/Forms/Label";
import { ExpressionContainer } from "../../../../../shared";
import * as styles from "./style";

interface SettingsExpressionProps {
  expression: string;
}

export const SettingsExpression = ({ expression }: SettingsExpressionProps) => (
  <ExpressionContainer>
    <Label>Expression:</Label>

    <styles.SettingsExpression disabled value={expression} />
  </ExpressionContainer>
);
