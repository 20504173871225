import { FormProvider, useForm } from "react-hook-form";
import { BaseModuleFormWithType } from "src/components/BotsContent/CEX/ExpertSystem/shared/BaseModuleFormWithType";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { StrategyModuleGroupCreateType } from "src/modules/expertSystem";
import { getEmptyConditionModule, getModulesInGroup } from "../shared/utils";
import { FormContent } from "./FormContent";

const MODULE_GROUP = "balance_change_modules";

const MODULES_IN_GROUP = getModulesInGroup(MODULE_GROUP);

export const BalanceChangeModules = () => {
  const state = useLateInitContext(CreateModulesContext);
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<StrategyModuleGroupCreateType<typeof MODULE_GROUP>>({
    defaultValues: getEmptyConditionModule(MODULE_GROUP),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <BaseModuleFormWithType
        onSubmit={handleSubmit(state.createConditionHandler(close))}
        formId={MODULE_GROUP}
        types={MODULES_IN_GROUP}
      >
        <FormContent />
      </BaseModuleFormWithType>
    </FormProvider>
  );
};
