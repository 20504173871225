import { ComponentPropsWithoutRef, memo } from "react";
import { DEXV2VaultType } from "src/api/bots/DEXV2/create";
import { StatusType } from "src/components/shared/GridTable";
import * as styles from "./style";

export interface TypeCellProps extends ComponentPropsWithoutRef<"span"> {
  type: DEXV2VaultType;
  text?: string;
}

export const VaultTypeCell = memo(({ type, text = type, ...props }: TypeCellProps) => {
  const checkType = (type: DEXV2VaultType): StatusType => {
    type.toLowerCase();

    switch (type) {
      case "main":
        return "neutral";

      case "limit":
        return "danger";

      case "volume":
        return "success";

      case "counter":
        return "warning";

      default:
        return "neutral";
    }
  };

  return (
    <styles.TypeCell type={checkType(type)} {...props}>
      {text}
    </styles.TypeCell>
  );
});
