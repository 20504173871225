import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  align-items: center;

  gap: 5px;
`;

export const ExchSelector = styled(LabeledSelector)`
  flex: 1;
`;
