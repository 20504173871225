import { observer } from "mobx-react-lite";
import { BotActionButton } from "src/components/AllBots/Bots/shared/BotActionButton";
import { getDowntime } from "src/helpers/dateUtils";
import { useBgColorStatus, useColorStatus } from "src/helpers/getStatus/getBotStatusColor";
import Icons from "src/icons/Icons";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { BotMark } from "../../shared/BotMark";
import { BotPairButton } from "../../shared/BotPairButton";
import {
  DownTime,
  Favorite,
  LockTime,
  MobBotContainer,
  MobFrame,
  MobLine,
  Name,
} from "../../shared/shared";
import { copyBotCredential } from "../../shared/utils";
import { CEXBotProps } from "../CEXBot";
import * as styles from "./style";

export const CEXBotMob = observer(
  ({
    favorite,
    toggleIsWatched,
    status,
    timeDontWork,
    modifyVolume,
    link,
    exchange,
    side,
    message,
    diff,
    modifyTime,
    pair,
    party,
    bot_uuid,
    market,
    name,
    actions,
    isWatched,
    credential,
    ...props
  }: CEXBotProps) => {
    const { base, quote, updated_at, created_at, ...containerProps } = props;

    const botColorStatus = useColorStatus();

    const botBgColorStatus = useBgColorStatus();

    const ActionButton = <BotActionButton status={status} actions={actions} />;

    const timeNotWork = Number(getDowntime(timeDontWork).split(":")[0]);

    return (
      <MobBotContainer {...containerProps}>
        {favorite ? (
          <Favorite onClick={() => toggleIsWatched(bot_uuid, market)}>
            {isWatched ? Icons.startStatusTrue() : Icons.startStatusFalse()}
          </Favorite>
        ) : null}

        <MobFrame
          color={botColorStatus(status, timeDontWork)}
          style={{ backgroundColor: botBgColorStatus(status, timeDontWork) }}
        >
          <styles.FirstRow>
            {bot_uuid ? (
              <styles.Wrapper>
                <BotPairButton
                  to={`/CEX/${bot_uuid}/`}
                  color={botColorStatus(status, timeDontWork)}
                  style={{ height: "25px" }}
                >
                  {pair?.split("_").join(" _ ")}
                </BotPairButton>
                <Name>{name}</Name>
              </styles.Wrapper>
            ) : null}

            <styles.MobExchange href={link || "#"} target="_blank">
              {exchange}
            </styles.MobExchange>

            <DownTime
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              <styles.MobTime>
                {timeNotWork > 1000 ? ">999:59" : getDowntime(timeDontWork, false)}
              </styles.MobTime>
            </DownTime>

            {modifyVolume && getDowntime(modifyTime) ? (
              <LockTime
                style={{
                  height: "100%",
                }}
              >
                {Icons.lock()}{" "}
                <styles.MobLockTime>
                  {modifyVolume && getDowntime(modifyTime, false)}
                </styles.MobLockTime>
              </LockTime>
            ) : null}

            <styles.WrapperForBtn>
              {bot_uuid ? (
                <styles.MobExchangeLink
                  to={`/CEX/${bot_uuid}/?tab=EXCHANGE`}
                  color={botColorStatus(status, timeDontWork)}
                >
                  {side}
                </styles.MobExchangeLink>
              ) : null}

              {ActionButton ? (
                <PermissionWrapper abilityName="bot-manager" party={party}>
                  {ActionButton}
                </PermissionWrapper>
              ) : (
                <BotMark
                  onClick={() => copyBotCredential(credential)}
                  color={botColorStatus(status, timeDontWork)}
                />
              )}
            </styles.WrapperForBtn>
          </styles.FirstRow>

          <styles.SecondRow>
            {diff ? <styles.MobComment>{diff}</styles.MobComment> : <MobLine />}

            {message ? <styles.MobMessage>{message}</styles.MobMessage> : <MobLine />}
          </styles.SecondRow>
        </MobFrame>
      </MobBotContainer>
    );
  }
);
