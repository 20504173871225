import { ComponentPropsWithoutRef } from "react";
import { Headline3 } from "src/components/shared/Typography";
import { Panel } from "../shared";

export interface BlockPanelProps extends ComponentPropsWithoutRef<"div"> {
  title?: string;
}

export const BlockPanel = ({ title, children, ...props }: BlockPanelProps) => (
  <Panel {...props}>
    {title ? <Headline3>{title}</Headline3> : " "}

    {children}
  </Panel>
);
