import ResponseHandler from "src/state/network/ResponseHandler";
import { PartiesListParams, PartyMarketCheck } from "src/state/MarketCheck/types";
import { ApiResponse } from "src/modules/network";
import { buildQueryUrl } from "src/helpers/url";
import { PaginationMetaResponse } from "../types";
import { ApiModules, apiUrl } from "..";

interface GetPartiesListResponse {
  items: PartyMarketCheck[];
  meta: PaginationMetaResponse;
}

export const getPartiesList = async (
  params: PartiesListParams
): Promise<ApiResponse<GetPartiesListResponse>> => {
  const url = buildQueryUrl("/indicators", params);

  return ResponseHandler.handler<GetPartiesListResponse>({
    url: apiUrl(ApiModules.Empty, url),
    method: "GET",
  });
};
