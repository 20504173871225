import styled from "styled-components";

export const Status = styled.div`
  display: flex;
`;

export const BaseStatusText = styled.span`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes["20px"]};
`;

export const StatusText = styled(BaseStatusText)`
  color: ${({ theme }) => theme.textColor};
  margin-right: 25px;
`;

export const LastUpdateText = styled(BaseStatusText)`
  color: ${({ theme }) => theme.lightGray};
`;

export type IndicatorProps = {
  status: string;
};

export const Indicator = styled.div<IndicatorProps>`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  border-radius: ${({ theme }) => theme.radii["100%"]};
  background-color: ${({ status, theme: { botColorStatus } }) => {
    if (status === "active") {
      return botColorStatus.green;
    }
    if (status === "failed" || status === "error") {
      return botColorStatus.blue;
    }
    if (status === "sleeping" || status === "stopped") {
      return botColorStatus.darkRed;
    }

    return botColorStatus.gray;
  }};
`;

export const Date = styled.span`
  margin-left: 8px;
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes["20px"]};
  color: ${({ theme }) => theme.darkGray};
`;
