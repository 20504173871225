import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import CEXApiKeysStore from "src/state/CEX/CEXApiKeys/CEXApiKeys";

export const ApiKeysContext = createLateInitContext<CEXApiKeysStore>();

type ApiKeysProviderProps = ChildrenProps;

export const ApiKeysProvider = ({ children }: ApiKeysProviderProps) => {
  const store = useLocalStore(CEXApiKeysStore);

  return <ApiKeysContext.Provider value={store}>{children}</ApiKeysContext.Provider>;
};
