import { observer } from "mobx-react-lite";
import { ProfitStatsProps } from "../ProfitStats";
import StatsPanel from "../StatsPanel";
import { StatsItemDataProps } from "../StatsPanel/StatsList";

export interface BuySellStatsProps extends ProfitStatsProps {}

export const BuySellStats = observer(({ stats, ...props }: ProfitStatsProps) => {
  const buySellStatsItems: StatsItemDataProps[] = [
    { name: "Buy count", value: stats.buySell.buy },
    { name: "Sell count", value: stats.buySell.sell },
  ];

  return <StatsPanel title="Buy/Sell ratio" items={buySellStatsItems} {...props} />;
});
