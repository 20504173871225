import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useMemo, useState } from "react";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import { useWidgetsState } from "../../../shared/hooks/useWidgetsState";
import { ProfitLossBar, ProfitLossBarProps } from "./ProfitLossBar";
import { ProfitLossInfoPanel } from "./ProfitLossInfoPanel";
import * as styles from "./style";

export type PNLMode = "absolute" | "percent";

interface ModeMenuItem extends MenuItemData {
  value: PNLMode;
}

const MODE_MENU_ITEMS: ModeMenuItem[] = [
  { label: "absolute", value: "absolute" },
  { label: "percent", value: "percent" },
];

const PNL_INFO_TEXT = `-Profit & Loss chart values at each time interval are calculated as the sum of quote currency change and base currency change for the period in USD equivalent.

-Period PnL takes into account history for the chosen period

-Monthly PnL takes into account history for the current and previous months respectively

-Absolute and Percent buttons display absolute and relative change respectively
`;

export interface ProfitLossChartProps
  extends Pick<ProfitLossBarProps, "zoomDefault">,
    ComponentPropsWithoutRef<"div"> {}

export const ProfitLossChart = observer(({ zoomDefault, ...props }: ProfitLossChartProps) => {
  const { percentBars, absBars, loading } = useWidgetsState("pnl");
  const [mode, setMode] = useState<PNLMode>("absolute");

  const { time, value } = mode === "absolute" ? absBars : percentBars;

  const pnlData: ProfitLossBarProps["data"] = useMemo(
    () => ({
      datasets: [
        {
          data: value,
          borderWidth: 0,
        },
      ],
      labels: time,
    }),
    [time, value]
  );

  const barProps = {
    mode,
    data: pnlData,
    zoomDefault,
  };

  return (
    <styles.PNLCard
      title="Profits & Loss chart"
      info={PNL_INFO_TEXT}
      after={<ProfitLossInfoPanel />}
      afterTitle={
        <styles.ModeSwitch
          selectedItem={mode}
          menuItems={MODE_MENU_ITEMS}
          onChange={(value) => setMode(value as PNLMode)}
        />
      }
      loading={loading}
      {...props}
    >
      <ProfitLossBar {...barProps} />
    </styles.PNLCard>
  );
});
