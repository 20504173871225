import styled from "styled-components";
import { CheckBox } from "src/components/shared/CheckBox";
import { TradeForm } from "../shared";

export const Form = styled(TradeForm)`
  flex-direction: row;

  & > div:not(:last-of-type) {
    margin-right: 12px;
  }
`;

export const ColumnWrapper = styled.div`
  width: 100%;
  /* height: 154px; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

export const SelectColumn = styled(ColumnWrapper)`
  & > div:nth-child(2) {
    padding-top: 2px;
  }
`;

export const BtnWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: flex-end;
`;

export const CheckBoxWrapper = styled.div`
  height: 48px;
  width: 100%;

  display: flex;
  align-items: flex-end;
`;
