import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { generateAmount, generateTokenTicker } from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type CurrencyFeeData = {
  currency: string;
  amount: string;
  amountUsd: string;
};

export type CurrencyFeeDataKeys = keyof CurrencyFeeData;

const generateCurrencyFeeData = (): CurrencyFeeData => {
  const currency = generateTokenTicker();
  const amount = generateAmount();
  const amountUsd = generateAmount();

  return { currency, amount, amountUsd };
};

const generateCurrencyFee = (): CurrencyFeeData[] =>
  faker.helpers.multiple(generateCurrencyFeeData, { count: { min: 3, max: 10 } });

export class CurrencyFeeV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: CurrencyFeeData[] = [];

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBaseDashboardV2StoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { data: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: CurrencyFeeData[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData([]);
    try {
      await delay(200);
      const data = generateCurrencyFee();
      this._setData(data);
    } catch {
      this._setData([]);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
