import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { pushOrReplaceHistory } from "src/helpers/router";
import useLocalStore from "src/hooks/useLocalStore";
import { useAppState } from "src/state";
import { ConfirmStore } from "src/state/auth/ConfirmStore";
import { AuthForm } from "../base/AuthForm";
import * as styles from "./style";

type LoginQueryParams = {
  login: string;
};

export const Confirm = observer(() => {
  const { userState } = useAppState();
  const state = useLocalStore(ConfirmStore);

  const { login } = useParams<LoginQueryParams>();
  const history = useHistory();

  useEffect(() => {
    state.setValue("code", "");
    state.setValue("login", login);
  }, [state, login]);

  const toHomePage = useCallback(() => {
    userState.logIn();
    pushOrReplaceHistory(history, "/MainPage");
  }, [history, userState]);

  return (
    <styles.Container>
      <AuthForm
        title="Input code"
        onSubmit={state.submitHandler(toHomePage)}
        isLoading={state.showLoader}
        submitBefore={<OutlineButton onClick={state.sendAuthCode}>Send new email</OutlineButton>}
      >
        <styles.FormContent>
          <LabeledInput
            label="Code"
            type="number"
            value={state.data.code}
            onChange={state.getHandler("code")}
            errorHint={state.errors.code}
          />
        </styles.FormContent>
      </AuthForm>
    </styles.Container>
  );
});
