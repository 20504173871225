import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable, Nullish } from "src/helpers/utils";
import { ISwapPairAddressProvider } from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { CacheOptions, tryParseCurrencyAmount } from "src/state/DEXV2/DEXV2Swap/utils";
import { IRouterProvider } from "../shared/Providers/RouterProvider";
import { IRouteParams, SwapRoute, SwapRouteError, SwapRouteState } from "../shared/Swap/Router";
import { Field, ISwapState, isExactInput } from "../shared/SwapStateStore";

export interface ISwapInfoRouteParams {
  swapState: ISwapState;
  routerProvider: IRouterProvider;
  poolAddressProvider: ISwapPairAddressProvider;
}

export interface ISwapInfoRoute {
  routeTrade: (options?: CacheOptions) => Promise<void>;
  get routeInitialized(): boolean;
  get swapRoute(): SwapRoute | null;
  get swapRouteError(): Nullish<SwapRouteError>;
  get swapPath(): Token[] | null;
  get parsedAmount(): CurrencyAmount<Token> | undefined;
}

export class SwapInfoRouteStore implements ISwapInfoRoute, IDisposable {
  private _swapState: ISwapState;

  private _swapRoute: SwapRoute | null = null;

  private _swapRouteError: Nullish<SwapRouteError>;

  private _routerProvider: IRouterProvider;

  private _poolAddressProvider: ISwapPairAddressProvider;

  constructor({ swapState, routerProvider, poolAddressProvider }: ISwapInfoRouteParams) {
    makeAutoObservable(this);

    this._swapState = swapState;

    this._routerProvider = routerProvider;

    this._poolAddressProvider = poolAddressProvider;

    makeLoggable<any>(this, {
      swapRoute: true,
      swapPath: true,
      parsedAmount: true,
      swapRouteError: true,
      _routeParams: true,
    });
  }

  private get _router() {
    return this._routerProvider.router;
  }

  private get _swap() {
    return this._swapState.swap;
  }

  get swapRoute() {
    return this._swapRoute;
  }

  get swapRouteError() {
    return this._swapRouteError;
  }

  private _setSwapRoute = (route: SwapRoute) => {
    this._swapRoute = route;
  };

  private _setSwapRouteError = (error: Nullish<SwapRouteError>) => {
    this._swapRouteError = error;
  };

  get swapPath() {
    const tokenIn = this._swap[Field.INPUT];
    const tokenOut = this._swap[Field.OUTPUT];
    if (!tokenIn || !tokenOut) return null;
    return [tokenIn, tokenOut];
  }

  get parsedAmount() {
    const { type, amount, [Field.INPUT]: currencyIn, [Field.OUTPUT]: currencyOut } = this._swap;
    const token = isExactInput(type) ? currencyIn : currencyOut;
    const parsedAmount = tryParseCurrencyAmount(amount, token);
    return parsedAmount;
  }

  private get _poolAddress() {
    return this._poolAddressProvider.pairAddress;
  }

  private get _routeParams(): Omit<IRouteParams, "options"> | null {
    const { type } = this._swap;
    const path = this.swapPath;
    const { parsedAmount } = this;
    const poolAddress = this._poolAddress;
    if (!parsedAmount || !path || !poolAddress) return null;
    return { amount: parsedAmount, swapType: type, path, pools: [poolAddress] };
  }

  routeTrade = async (options?: CacheOptions) => {
    const router = this._router;
    const routeParams = this._routeParams;
    if (!router || !routeParams) return;

    const route = await router.route({ ...routeParams, options });
    if (route.state === SwapRouteState.Invalid) {
      this._setSwapRouteError(route.error);
    } else {
      this._setSwapRouteError(null);
      this._setSwapRoute(route);
    }
  };

  get routeInitialized() {
    const path = this.swapPath;
    const router = this._router;
    if (!path || !router) return false;
    return true;
  }

  destroy = () => {};
}
