import { observer } from "mobx-react-lite";
import React from "react";
import { StatusConnect } from "src/components/shared/Indicators/StatusConnect";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { TerminalRequestMode } from "src/state/CEX/CEXExchange/shared/TerminalSettingsStore";
import { REQUEST_MODES } from "../../..";
import { SettingsModule } from "../SettingsModule";
import { RequestModeSwitcher } from "../shared";

export const TVChartModule = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);

  const tvChartSettings = mainState.tvChartSettingsState;

  const { settingsConfig } = mainState.terminalSettingsState;

  return (
    <SettingsModule title="TV Chart">
      <RequestModeSwitcher
        key="tv_chart_modes"
        items={REQUEST_MODES}
        disabled={!mainState.terminalSettingsState.wsModeSupport}
        selectedItem={settingsConfig.tvChartModule.requestMode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          tvChartSettings.setStreamMode(e.target.value as TerminalRequestMode)
        }
      />

      <StatusConnect
        type="data"
        status={tvChartSettings.exchStatusConnection}
        delay={tvChartSettings.exchangeDelay}
      />
    </SettingsModule>
  );
});
