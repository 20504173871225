import {
  InputSlotProps,
  SuggestionLabeledInput,
  SuggestionLabeledInputOwnProps,
  SuggestionLabeledInputProps,
} from "src/components/shared/Forms/Variants";
import { DecimalInput, DecimalInputProps } from "../DecimalInput";

export interface DecimalSuggestionInputProps<T extends HTMLElement>
  extends SuggestionLabeledInputOwnProps<T>,
    Omit<DecimalInputProps, "after"> {}

export const DecimalSuggestionInput = <T extends HTMLElement = HTMLDivElement>({
  ...props
}: DecimalSuggestionInputProps<T>) => {
  // explicit casting to base input props because onChange callbacks
  // are technically "incompatible", but are compatible in runtime
  // since underlying input is DecimalInput
  const Input = DecimalInput as React.ComponentType<InputSlotProps>;
  return <SuggestionLabeledInput<T> input={Input} {...(props as SuggestionLabeledInputProps<T>)} />;
};
