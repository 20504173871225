import { useMemo } from "react";
import * as styles from "./style";

interface MilestoneProps {
  currentStep: number;
  step: number;
  label?: string;
}

export const Milestone = ({ currentStep, step, label }: MilestoneProps) => {
  const active = useMemo(() => currentStep === step, [currentStep, step]);

  const completed = useMemo(() => currentStep > step, [currentStep, step]);

  return (
    <styles.Container>
      <styles.WrapperStep>
        {step > 0 ? <styles.StepConnector active={active} completed={completed} /> : ""}

        {label ? (
          <styles.LabelPoint active={active} completed={completed}>
            {label}
          </styles.LabelPoint>
        ) : (
          ""
        )}

        <styles.StepPoint active={active} completed={completed} />
      </styles.WrapperStep>
    </styles.Container>
  );
};
