import { makeAutoObservable } from "mobx";
import { IDisposable } from "src/helpers/utils";
import { IBotUUIDProvider } from "../../DEXV2Bots/DEXV2BotStore";
import HashesDownloadStore from "./HashesDownloadStore";
import HashesPaginationStore, { HashesPaginationConfig } from "./HashesPaginationStore";
import { HashesDownloadProvider } from "./Providers/HashesDownloadProvider";
import { IHashesPaginationFetcherProvider } from "./Providers/HashesPaginationFetcherProvider";
import { VaultsPaginationFetcherProvider } from "./Providers/HashesPaginationFetcherProvider/VaultsPaginationFetcherProvider";
import { WalletsPaginationFetcherProvider } from "./Providers/HashesPaginationFetcherProvider/WalletsPaginationFetcherProvider";
import { HashesPaginationProvider } from "./Providers/HashesPaginationProvider";

export type VaultHashType = "volume" | "limit" | "counter";
export type WalletHashType = "wallet";

export type HashType = VaultHashType | WalletHashType;

export interface IBotNameProvider {
  get botName(): string;
}

export interface IBotHashesInfoProvider extends IBotNameProvider, IBotUUIDProvider {}

export default class HashesTypeStore<T extends HashType> implements IDisposable {
  private _type: T;

  private _paginationStore: HashesPaginationStore<T>;

  private _downloadStore: HashesDownloadStore;

  private _botInfoProvider: IBotHashesInfoProvider;

  constructor(
    type: T,
    botInfoProvider: IBotHashesInfoProvider,
    paginationConfig?: HashesPaginationConfig
  ) {
    makeAutoObservable(this);

    this._type = type;
    this._botInfoProvider = botInfoProvider;

    const fetcherProvider = this._getHashesFetcherProvider(this._type);

    const hashesProvider = new HashesPaginationProvider(this._botInfoProvider, fetcherProvider);
    this._paginationStore = new HashesPaginationStore(this._type, hashesProvider, paginationConfig);

    const downloadsProvider = new HashesDownloadProvider(
      this._type,
      this._botInfoProvider,
      this._botInfoProvider,
      fetcherProvider
    );
    this._downloadStore = new HashesDownloadStore(downloadsProvider);
  }

  private _getHashesFetcherProviderImpl = (type: T) => {
    switch (type) {
      case "limit":
      case "volume":
      case "counter": {
        return new VaultsPaginationFetcherProvider(
          type
        ) as IHashesPaginationFetcherProvider<HashType>;
      }
      case "wallet": {
        return new WalletsPaginationFetcherProvider(this._botInfoProvider);
      }
      default: {
        throw new Error("unknown hash type");
      }
    }
  };

  private _getHashesFetcherProvider = (type: T) =>
    this._getHashesFetcherProviderImpl(type) as IHashesPaginationFetcherProvider<T>;

  get type() {
    return this._type;
  }

  get paginationStore() {
    return this._paginationStore;
  }

  get downloadStore() {
    return this._downloadStore;
  }

  destroy = () => {
    this.paginationStore.destroy();
    this.downloadStore.destroy();
  };
}
