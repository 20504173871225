import { ComponentPropsWithoutRef, ReactNode } from "react";
import { AsProps } from "src/helpers/styled/types";
import { OwnRefProps } from "src/helpers/utils";
import * as styles from "./style";

interface DefaultizedButtonProps
  extends Omit<ButtonProps, keyof styles.ButtonStyleProps>,
    styles.ButtonStyledProps {}

const useDefaultizedButtonProps = ({
  color,
  variant,
  size,
  stretched,
  elevated,
  ...props
}: ButtonProps): DefaultizedButtonProps => {
  const { children, endIcon, startIcon } = props;
  const hasSingleIcon = !children && Boolean(startIcon) !== Boolean(endIcon);
  return {
    ...props,
    $color: color ?? "primary",
    $variant: variant ?? "contained",
    $size: size ?? "l",
    $stretched: stretched ?? false,
    $elevated: elevated ?? true,
    $singleIcon: hasSingleIcon,
  };
};

export interface ButtonOwnProps
  extends Omit<Partial<styles.ButtonStyleProps>, "singleIcon">,
    AsProps {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
}

export interface ButtonProps
  extends Omit<ComponentPropsWithoutRef<"button">, keyof ButtonOwnProps>,
    OwnRefProps<"button">,
    ButtonOwnProps {}

export const Button = (outerProps: ButtonProps) => {
  const { startIcon, endIcon, ownRef, children, as, ...props } =
    useDefaultizedButtonProps(outerProps);

  const StartIcon = startIcon && <styles.ButtonIcon>{startIcon}</styles.ButtonIcon>;
  const EndIcon = endIcon && <styles.ButtonIcon>{endIcon}</styles.ButtonIcon>;

  const Content = children && <styles.ButtonContent>{children}</styles.ButtonContent>;

  return (
    <styles.StyledButton ref={ownRef} as={as} {...props}>
      {StartIcon}
      {Content}
      {EndIcon}
    </styles.StyledButton>
  );
};
