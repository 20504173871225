import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled, { css } from "styled-components";
import { PriceCalculator as BasePriceCalculator } from "../Dashboard/PriceCalculator";
import * as BasePriceCalculatorStyles from "../Dashboard/PriceCalculator/style";
import { FormContent } from "../Settings/shared/style";
import { Container as PageContainer, TABLET_SETTINGS_BREAKPOINT } from "../Settings/style";
import { PairInfo as BasePairInfo } from "./PairInfo";
import * as BasePairInfoStyles from "./PairInfo/style";

export const Container = styled(PageContainer)`
  max-width: 1700px;

  padding-top: 0px;
`;

export const Content = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: minmax(450px, 1fr) minmax(auto, 310px) minmax(auto, 420px);
  grid-template-rows: minmax(auto, 470px) minmax(auto, 217px);
  grid-template-areas:
    "screener     screener  swap"
    "vaults      pair_info calculator";

  row-gap: 26px;
  column-gap: 15px;

  @media ${getMobileMediaQuery(TABLET_SETTINGS_BREAKPOINT)} {
    grid-template-columns: minmax(380px, 1fr) minmax(0, 1fr);
    grid-template-rows:
      repeat(2, minmax(235px, auto)) minmax(auto, 217px)
      minmax(450px, 1fr);
    grid-template-areas:
      "swap   calculator"
      "swap    pair_info"
      "vaults     vaults"
      "screener  screener";
  }

  @media ${getMobileMediaQuery("700px")} {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows:
      minmax(450px, auto) minmax(400px, auto) minmax(212px, auto)
      auto minmax(auto, 217px);
    grid-template-areas:
      "swap"
      "screener"
      "calculator"
      "pair_info"
      "vaults";
  }
`;

export const LargeTitleMixin = css`
  min-height: 30px;
  display: inline-flex;
  align-items: center;
`;

export const PairInfo = styled(BasePairInfo)`
  justify-content: space-between;

  ${BasePairInfoStyles.Title} {
    ${LargeTitleMixin}
  }

  @media ${getMobileMediaQuery(TABLET_SETTINGS_BREAKPOINT)} {
    justify-content: unset;

    ${BasePairInfoStyles.InfoPanel} {
      justify-content: center;
    }
    ${BasePairInfoStyles.Content} {
      height: 100%;
    }
  }
`;

export const PriceCalculator = styled(BasePriceCalculator)`
  justify-content: space-between;

  ${BasePriceCalculatorStyles.Title} {
    ${LargeTitleMixin}
  }

  ${BasePriceCalculatorStyles.FormContainer} {
    min-height: 172px;
  }

  ${FormContent} {
    justify-content: space-between;
  }

  @media ${getMobileMediaQuery(TABLET_SETTINGS_BREAKPOINT)} {
    justify-content: unset;

    ${BasePriceCalculatorStyles.FormContainer} {
      height: 100%;
    }
  }
`;
