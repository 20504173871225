import { Headline3 } from "src/components/shared/Typography";
import Icons from "src/icons/Icons";
import * as styles from "./style";

export const ErrorState = () => (
  <>
    <styles.IconWrapper>
      <Icons.Error />
    </styles.IconWrapper>

    <Headline3 style={{ width: "200px", textAlign: "center" }}>
      Vault contracts deployment <styles.ErrorText>failed</styles.ErrorText>
    </Headline3>

    <styles.DefaultText>
      The profile creation failed to complete successfully, try again
    </styles.DefaultText>
  </>
);
