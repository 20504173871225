import { observer } from "mobx-react-lite";
import { darken } from "polished";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { useDashboardColors } from "../../../shared/hooks/useDashboardColors";
import { useWidgetsState } from "../../../shared/hooks/useWidgetsState";
import { BalancesInfoPanel } from "./BalancesInfoPanel";
import { BalancesOverviewBar, BalancesOverviewBarProps } from "./BalancesOverviewBar";
import * as styles from "./style";

const BALANCES_INFO_TEXT = `This block shows the history of balances on all accounts on the chosen exchanges at the end of the displayed time interval.

Each value is divided into free and locked parts.
Locked balance is the balance that is used to trade and provide liquidity, Free balance is the balance that is not used in current active strategies (opposite to locked balance) and can be withdrawn at the moment.
Total is the sum of free and locked balances.`;

export interface OldBalancesOverviewChartProps
  extends Pick<BalancesOverviewBarProps, "zoomDefault">,
    CardProps {}

export const OldBalancesOverviewChart = observer(
  ({ zoomDefault, ...props }: OldBalancesOverviewChartProps) => {
    const { free, locked, loading } = useWidgetsState("balances");

    const {
      accent: { dodgerBlue, coralPink },
    } = useDashboardColors();

    const balancesData: BalancesOverviewBarProps["data"] = {
      datasets: [
        {
          label: "Locked",
          data: locked.value,
          borderWidth: 0,
          backgroundColor: dodgerBlue,
          hoverBackgroundColor: darken(0.15, dodgerBlue),
        },
        {
          label: "Free",
          data: free.value,
          borderWidth: 0,
          backgroundColor: coralPink,
          hoverBackgroundColor: darken(0.15, coralPink),
        },
      ],
      labels: free.time,
    };

    const barProps = { data: balancesData, zoomDefault };
    return (
      <styles.BalancesCard
        title="Balance overview"
        info={BALANCES_INFO_TEXT}
        after={<BalancesInfoPanel />}
        loading={loading}
        {...props}
      >
        <BalancesOverviewBar {...barProps} />
      </styles.BalancesCard>
    );
  }
);
