import React from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { observer } from "mobx-react-lite";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import * as styles from "./style";

export const FooterPanel = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);
  const terminalSettings = mainState.terminalSettingsState;

  return (
    <styles.Footer>
      <OutlineButton color="neutral" onClick={() => terminalSettings.resetSettings()}>
        Reset
      </OutlineButton>

      <OutlineButton color="primary" onClick={() => terminalSettings.saveSettings()}>
        Save
      </OutlineButton>
    </styles.Footer>
  );
});
