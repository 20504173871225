import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { generateTokenTicker } from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type ChipCorrelationData = {
  base: string;
  eth: number;
  btc: number;
};

const generateChipValue = () => faker.number.float({ min: -1, max: 1 });

const generateChipData = (): ChipCorrelationData => ({
  base: generateTokenTicker(),
  eth: generateChipValue(),
  btc: generateChipValue(),
});

const INITIAL_DATA: ChipCorrelationData = {
  base: "",
  eth: 0,
  btc: 0,
};

export class ChipCorrelationStore implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: ChipCorrelationData = INITIAL_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBaseDashboardV2StoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { data: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: ChipCorrelationData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData(INITIAL_DATA);
    try {
      await delay(200);
      const data = generateChipData();
      this._setData(data);
    } catch {
      this._setData(INITIAL_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
