import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { PaginationProps } from "..";
import * as styles from "./style";

interface InputSetPageProps extends Omit<PaginationProps, "setPage"> {}

const getPageFromInput = (value: string) => {
  if (value === "") return 1;
  const pageNum = +value;
  if (!isFinite(pageNum)) return -1;
  return +value;
};

export const InputSetPage = observer(
  ({ pageCount, onForcePageChange, itemsPerPage, getItems }: InputSetPageProps) => {
    const minPage = 1;
    const maxPage = Math.max(1, pageCount ?? 0);

    const [inputValue, setInputValue] = useState("");

    const onPageChange = useCallback(
      (newPage: number) => {
        if (newPage >= minPage && newPage <= maxPage) {
          const newPageIndex = newPage - 1;
          onForcePageChange?.(newPageIndex);
          getItems(newPageIndex, itemsPerPage);
        }
      },
      [getItems, itemsPerPage, maxPage, onForcePageChange]
    );

    const debouncedHandleSize = useMemo(() => debounce(onPageChange, 500), [onPageChange]);

    const onChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        const newPage = getPageFromInput(e.target.value);
        debouncedHandleSize(newPage);
      },
      [debouncedHandleSize]
    );

    return (
      <styles.InputContainer>
        <styles.InputPage
          type="number"
          step="1"
          min={minPage}
          max={maxPage}
          value={inputValue}
          onChange={onChange}
        />
      </styles.InputContainer>
    );
  }
);
