import { ComponentPropsWithoutRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CheckBox } from "src/components/shared/CheckBox";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { CEXBindingModule } from "src/state/CEX/botModules";
import * as styles from "./style";

export interface FormContentProps extends ComponentPropsWithoutRef<"form"> {
  formId: string;
  submitHandler: (module: CEXBindingModule) => Promise<void>;
}

export const FormContent = ({ formId, submitHandler, children }: FormContentProps) => {
  const { control, handleSubmit } = useFormContext<CEXBindingModule>();

  return (
    <styles.FormContent onSubmit={handleSubmit(submitHandler)} id={formId}>
      <styles.CheckboxWrapper>
        <Controller
          control={control}
          name="mainToCurrent"
          render={({ field: { value, onChange } }) => (
            <CheckBox
              label="Main to Current"
              onChange={(e) => onChange(getChangeEventValue(e))}
              checked={value}
            />
          )}
        />

        <Controller
          control={control}
          name="same"
          render={({ field: { value, onChange } }) => (
            <CheckBox
              label="Same"
              onChange={(e) => onChange(getChangeEventValue(e))}
              checked={value}
            />
          )}
        />
      </styles.CheckboxWrapper>

      {children}
    </styles.FormContent>
  );
};
