import React, { useMemo } from "react";
import { Observer, observer } from "mobx-react-lite";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ColumnDef } from "@tanstack/react-table";
import { OrderBookOrder } from "src/modules/exchange/orderBook";
import { CupProps, MAX_QUOTE_POINT, useGetOrderContent, usePriceCellConfig } from "../..";
import { VirtualSellRowCup } from "./VirtualSellRowCup";
import * as styles from "./style";
import { SellShell } from "../../shared";

export interface SellCupProps extends CupProps {
  sellCupRef: React.RefObject<HTMLTableSectionElement>;
  sellContainerRef: React.RefObject<HTMLDivElement>;
}

export const SellCup = observer(
  ({ data = [], sellCupRef, sellContainerRef, showMobVers }: SellCupProps) => {
    const mainState = useLateInitContext(ExchangeContext);

    const { orderBookState } = mainState;

    const getPriceConfig = usePriceCellConfig<OrderBookOrder>();
    const priceCellConfig = getPriceConfig("sellOrder");
    const getOrderContent = useGetOrderContent();

    const SelectionShell = observer(({ order }: { order: OrderBookOrder }) => (
      <SellShell fill={+order.ourAmount ? (+order.ourAmount / +order.amount) * 100 : 0} />
    ));

    const columns = useMemo(() => {
      const columnSchema: ColumnDef<OrderBookOrder>[] = [
        priceCellConfig,
        {
          header: () => "",
          accessorKey: "amount",
          id: "amount_orderBook",
          cell: ({
            cell: {
              row: {
                original: { amount, total },
              },
            },
          }) => (
            <styles.Amount highlight={+total >= MAX_QUOTE_POINT}>
              {getOrderContent(+amount, orderBookState.amountPrecision)}
            </styles.Amount>
          ),
        },
        {
          header: () => "",
          accessorKey: "total",
          id: "total_orderBook",
          cell: (info) => {
            const total = +info.getValue<string>();

            return (
              <Observer>
                {() => (
                  <>
                    <styles.Total highlight={total >= MAX_QUOTE_POINT}>
                      {getOrderContent(total, orderBookState.totalPrecision)}
                    </styles.Total>
                    <SelectionShell order={info.row.original} />
                  </>
                )}
              </Observer>
            );
          },
        },
      ];

      return columnSchema;
    }, [orderBookState, SelectionShell, priceCellConfig, getOrderContent]);

    const shortColumns = useMemo(() => {
      const columnSchema: ColumnDef<OrderBookOrder>[] = [
        priceCellConfig,
        {
          header: () => "",
          accessorKey: "amount",
          id: "amount_orderBook",
          cell: ({
            cell: {
              row: { original },
            },
          }) => (
            <Observer>
              {() => (
                <>
                  <styles.Amount highlight={+original.total >= MAX_QUOTE_POINT}>
                    {getOrderContent(+original.amount, orderBookState.amountPrecision)}
                  </styles.Amount>

                  <SelectionShell order={original} />
                </>
              )}
            </Observer>
          ),
        },
      ];

      return columnSchema;
    }, [orderBookState, SelectionShell, priceCellConfig, getOrderContent]);

    return (
      <VirtualSellRowCup
        sellCupRef={sellCupRef}
        sellContainerRef={sellContainerRef}
        rowSize={18}
        data={data}
        columns={showMobVers ? shortColumns : columns}
      />
    );
  }
);
