import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { BindingsBotProvider } from "src/context/CEX/BindingsBotProvider";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useFindWidth } from "src/hooks/useFindWidth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionProps, PermissionWrapper } from "src/permissions/PermissionWrapper";
import { useAppState } from "src/state";
import { BotInfo } from "../../..";
import { Balances } from "../../Balances";
import { BuySellForms } from "../../BuySellForms";
import { ChartPanel } from "../../ChartPanel";
import { DesktopTradingHistory } from "../../DesktopTradingHistory";
import { OrderBook } from "../../OrderBook";
import { OrderBookFallback } from "../../OrderBook/style";
import { PairChange } from "../../PairChange";
import { PairChangeFallback } from "../../PairChange/style";
import { Settings } from "../../Settings";
import { TerminalPnL } from "../../TerminalPnL";
import { TitleExchange } from "../../TitleExchange";
import { TradePanel } from "../../TradePanel";
import { FinanceWrapper, PNLWrapper } from "../../shared";
import { MobileVersion } from "../MobileVersion";
import * as styles from "./style";

export interface FullExchangeProps extends PermissionProps {
  botInfoProps: BotInfo;
}

export const FullExchange = observer(({ abilityName, botInfoProps }: FullExchangeProps) => {
  const exchangeState = useLateInitContext(ExchangeContext);
  const settingsState = useLateInitContext(CEXSettingsContext);

  const { userState } = useAppState();

  const showTradeHistory = useFindWidth(1230);

  const showMobile = useFindWidth(880);

  if (!showMobile) {
    return (
      <styles.DesktopContainer party={botInfoProps.party} abilities={userState.abilities}>
        {showTradeHistory ? <TitleExchange /> : null}
        {!showTradeHistory ? <DesktopTradingHistory botInfo={botInfoProps} /> : null}
        <FinanceWrapper>
          <PermissionWrapper party={botInfoProps.party} abilityName="cex-stats-view">
            <ErrorBoundary fallback={<styles.PNLContainerFallback />}>
              <styles.PNLContainer>
                <PNLWrapper>
                  <TerminalPnL botInfo={botInfoProps} isShortView />
                </PNLWrapper>
              </styles.PNLContainer>
            </ErrorBoundary>
          </PermissionWrapper>

          <ErrorBoundary>
            <Balances
              switchAccount={exchangeState.switchAccount}
              updAcc={exchangeState.updAccData}
            />
          </ErrorBoundary>
        </FinanceWrapper>

        <ChartPanel party={botInfoProps.party} />

        <ErrorBoundary fallback={<styles.TradePanelFallback />}>
          <TradePanel
            showTradeHistory={showTradeHistory}
            botInfo={botInfoProps}
            abilityName={abilityName}
            showNotes
          />
        </ErrorBoundary>
        <ErrorBoundary fallback={<PairChangeFallback />}>
          <styles.PairSwitcherContainer>
            <PairChange />
          </styles.PairSwitcherContainer>
        </ErrorBoundary>
        <styles.OrderBookContainer>
          <ErrorBoundary fallback={<OrderBookFallback />}>
            <OrderBook
              DTPMin={+settingsState.data.settings.pair.dontTradePriceMin}
              DTPMax={+settingsState.data.settings.pair.dontTradePriceMax}
            />
          </ErrorBoundary>
        </styles.OrderBookContainer>
        <PermissionWrapper party={botInfoProps.party} abilityName="cex-settings-view">
          <ErrorBoundary fallback={<styles.SettingsFallback />}>
            <BindingsBotProvider>
              <Settings botInfo={botInfoProps} abilityName="cex-settings-edit" />
            </BindingsBotProvider>
          </ErrorBoundary>
        </PermissionWrapper>
        <PermissionWrapper party={botInfoProps.party} abilityName={abilityName}>
          <BuySellForms showTradeHistory={showTradeHistory} />
        </PermissionWrapper>
      </styles.DesktopContainer>
    );
  }
  return (
    <MobileVersion
      abilityName={abilityName}
      botInfoProps={botInfoProps}
      showTradeHistory={showTradeHistory}
    />
  );
});
