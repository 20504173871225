import { VirtualItem } from "react-virtual";

export const useTableVirtualPadding = (
  virtualRows: VirtualItem[],
  totalSize: number
): [React.ComponentType, React.ComponentType] => {
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

  const PaddingTop = () =>
    paddingTop > 0 ? (
      <tr>
        <td style={{ height: `${paddingTop}px` }} />
      </tr>
    ) : null;

  const paddingBottom =
    virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  const PaddingBottom = () =>
    paddingBottom > 0 ? (
      <tr>
        <td style={{ height: `${paddingBottom}px` }} />
      </tr>
    ) : null;

  return [PaddingTop, PaddingBottom];
};
