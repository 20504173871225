import { SwitchLabel } from "src/components/shared/Switches/style";
import styled from "styled-components";
import { ModesSwitch } from "../../Limit/LimitPanel/style";
import { SettingsFormContent } from "../../shared/SettingsFormContent";
import { SettingsPanel } from "../../shared/SettingsPanel";
import { Content } from "../../shared/SettingsPanel/style";

export const CounterSwitch = styled(ModesSwitch)`
  ${SwitchLabel} {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes["14px"]};
  }
`;

export const FormContent = styled(SettingsFormContent)`
  padding: 0;
  gap: 20px;
`;

interface StyledCounterPanelProps {
  $isMobile?: boolean;
}

export const StyledCounterPanel = styled(SettingsPanel)<StyledCounterPanelProps>`
  ${Content} {
    height: ${({ $isMobile }) => ($isMobile ? "150px" : "0px")};

    flex: 1 1 auto;
  }
`;
