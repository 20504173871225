import { Headline2 } from "src/components/shared/Typography";
import styled from "styled-components";
import { CEXNotes } from "../Stats/CEXNotes";

export const BotName = styled(Headline2)`
  color: ${({ theme }) => theme.primaryColor};
`;

export const FinanceWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 5px;

  grid-area: finances;

  & > div:nth-child(2) {
    height: calc(100% - 40px);
  }
`;

export const PNLWrapper = styled.div`
  width: 100%;
`;

export const ExchNotes = styled(CEXNotes)`
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  box-shadow: none;
`;
