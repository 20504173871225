import { ModalPanel } from "src/components/shared/ModalPanel";
import styled from "styled-components";

export const EditPartyModal = styled(ModalPanel)`
  width: 100%;

  @media screen and (max-width: 670px) {
    width: calc(100% - 20px);
    padding: 15px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 70vh;

  display: flex;
  flex-direction: column;
`;
