import styled from "styled-components";

export const Form = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  grid-area: new-funding;

  padding-left: 20px;

  @media (max-width: 900px) {
    padding-left: 0px;
  }
`;

export const Content = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;

  & > div:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;

  & > div:not(:last-of-type) {
    margin-right: 12px;
  }
  /* 
  & > div {
    flex: 1;
  } */

  @media (max-width: 550px) {
    flex-direction: column;

    & > div {
      margin: 0px;
    }

    & > div:first-of-type {
      margin-right: 0px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  padding: 10px 0px 5px 0px;
`;
