import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import RestAPIProvider from "./Providers/RestAPIProvider";
import WSProvider from "./Providers/WSProvider";
import OrderBookStore from "./OrderBookStore";

class DataStorageStore {
  mainState: OrderBookStore;

  restAPIProvider: RestAPIProvider;

  streamProvider: WSProvider;

  private _firstLoad = true;

  private _pairChangeReaction?: IReactionDisposer;

  constructor(state: OrderBookStore) {
    this.mainState = state;
    this.restAPIProvider = new RestAPIProvider(this);
    this.streamProvider = new WSProvider(this);

    makeAutoObservable(this);

    this.subscribe();
  }

  get pair() {
    return this.mainState.pair;
  }

  get exchange() {
    return this.mainState.exchange;
  }

  get data() {
    switch (this.mainState.requestMode) {
      case "FETCH":
        return this.restAPIProvider.data;

      case "WS":
        return this.streamProvider.data;

      default:
        return null;
    }
  }

  get isLoading() {
    return this.restAPIProvider.isLoading;
  }

  get lockedConnect() {
    return this.streamProvider.connectStatus === 1 || this.streamProvider.connectStatus === 2;
  }

  get firstLoad() {
    return this._firstLoad;
  }

  get infoAcc() {
    return this.mainState.mainStore.infoAcc;
  }

  subscribe = () => {
    this._pairChangeReaction = reaction(
      () => this.pair,
      () => {
        this.changePair();
      }
    );
  };

  unsubscribe = () => {
    this._pairChangeReaction?.();
  };

  changePair = () => {
    // toggle flag when changing pair in websocket mode
    this.resetScroll();
    this.streamProvider.resetData();
  };

  setFirstLoad = (bool: boolean) => {
    this._firstLoad = bool;
  };

  resetScroll = () => {
    this.setFirstLoad(true);
  };

  loadData = () => {
    switch (this.mainState.requestMode) {
      case "FETCH":
        if (!this.mainState.exchange) return;

        this._checkOpenWS();

        this.restAPIProvider.fetchData(this.mainState.pair, this.mainState.exchange);

        break;
      case "WS":
        this.streamProvider.streamData();
        break;

      default:
        break;
    }
  };

  private _checkOpenWS = () => {
    if (
      this.streamProvider.connectStatus !== 3 ||
      this.streamProvider.connectStatus !== undefined
    ) {
      this.streamProvider.webSocketState.closeWebSocket();
    }
  };

  closeConnect = () => {
    this.streamProvider.closeConnect();
  };
}

export default DataStorageStore;
