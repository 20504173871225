import { Headline1 } from "src/components/shared/Typography";
import styled from "styled-components";
import { ChainInfoCard } from "../ChainInfoCard";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(Headline1)`
  font-size: 35px;
`;

export const ChainInfo = styled(ChainInfoCard)``;
