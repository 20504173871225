import { Price, Token } from "@uniswap/sdk-core";
import { formatPrice } from "src/state/DEXV2/DEXV2Swap/utils";

export interface PriceTextProps {
  label: string;
  price?: Price<Token, Token>;
}

export const PriceText = ({ label, price }: PriceTextProps) => {
  const formattedPrice = formatPrice(price, true);
  return (
    <>
      {label} <b>{formattedPrice}</b>
    </>
  );
};
