import { Plugin, TooltipOptions } from "chart.js";
import { useMemo } from "react";
import { Pie, PieProps } from "src/components/shared/Charts/Pie";
import { ChartPlaceholder } from "src/config/chartjs/plugins/chartPlaceholder";
import { Totalizer, getTotal } from "src/config/chartjs/plugins/totalizer";
import { ExchangesLegendContext } from "src/context/CEX/Dashboard/ExchangesLegend";
import { deepMerge } from "src/helpers/utils/deepMerge";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ExchangePieType } from "src/state/CEX/CEXDashboard/v1/ExchangesLegendStore";
import { useBaseTooltip, usePlaceholderOptions } from "../../../../shared/config";
import { useDashboardColors } from "../../../../shared/hooks/useDashboardColors";
import { ExchangesLegendPlugin } from "../plugin";

const useBaseOptions = () => {
  const { textSecondary } = useDashboardColors();

  const tooltipOptions = useBaseTooltip();

  const options = useMemo(() => {
    const pieOptions: PieProps<number[], string>["options"] = {
      locale: "en-US",
      maintainAspectRatio: false,
      layout: {
        padding: 3,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...(tooltipOptions as TooltipOptions<"pie">),
          bodyFont: {
            size: 8,
            weight: "400",
          },
          footerColor: textSecondary,
          footerFont: {
            size: 8,
            weight: "500",
          },
          callbacks: {
            title() {
              const { label } = this.dataPoints[0];
              return label;
            },
            label({ formattedValue }) {
              return formattedValue;
            },
            footer(items) {
              const { datasetIndex } = items[0];
              const total = getTotal(this.chart, datasetIndex);
              return `Total: ${total}`;
            },
          },
        },
        totalizer: { direction: "data" },
        chartPlaceholder: {
          shape: { type: "circle" },
        },
      },
    };
    return pieOptions;
  }, [textSecondary, tooltipOptions]);

  return options;
};

export interface ExchangesPieProps extends PieProps<number[], string> {
  type: ExchangePieType;
}

export const ExchangesPie = ({ type, ...props }: ExchangesPieProps) => {
  const state = useLateInitContext(ExchangesLegendContext.Context);

  const baseOptions = useBaseOptions();

  const placeholderOptions = usePlaceholderOptions();

  const pluginOptions: PieProps<number[], string>["options"] = useMemo(
    () => ({
      plugins: {
        exchangesLegend: {
          id: type,
          state,
        },
      },
    }),
    [state, type]
  );

  const options = useMemo(
    () => deepMerge(placeholderOptions, baseOptions, pluginOptions),
    [baseOptions, placeholderOptions, pluginOptions]
  );

  return (
    <Pie
      options={options}
      {...props}
      plugins={[Totalizer as Plugin<"pie">, ExchangesLegendPlugin, ChartPlaceholder]}
    />
  );
};
