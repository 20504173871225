import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { TableContext } from "src/context/shared/Table";
import { useClosePopup } from "src/hooks/useClosePopup";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

interface ColumnFilterProps {
  label: string;
  filters: string[];
  filterParam: string;
  defaultFilter?: string;
}

export const ColumnFilter = observer(
  ({ label, filters, filterParam, defaultFilter }: ColumnFilterProps) => {
    const [showPanel, setShowPanel] = useState(false);

    const tableState = useLateInitContext(TableContext);

    const panel = useRef<HTMLDivElement>(null);

    // Calculate the options for filtering
    // using the preFilteredRows
    // const options = useMemo(() => {
    //   const options = new Set();
    //   preFilteredRows.forEach((row: any) => {
    //     options.add(row.values[id]);
    //   });

    //   return [...options.values()];
    // }, [id, preFilteredRows]);

    useClosePopup(panel, setShowPanel);

    useEffect(() => {
      tableState.setAllFilters(filters, filterParam, defaultFilter);
    }, [tableState, filters, defaultFilter, filterParam]);

    return (
      <styles.Container ref={panel}>
        <styles.Wrapper onClick={() => setShowPanel(!showPanel)}>
          <styles.ColumnName>{label}</styles.ColumnName>

          <styles.Row check={showPanel} />
        </styles.Wrapper>

        {showPanel ? (
          <styles.Content>
            {/* <styles.FindStatus
              error={false}
              value={filterValue || ""}
              onChange={(e) => setFilter(e.target.value)}
            /> */}

            <styles.CheckFilter
              onChange={() => tableState.selectAllFilters()}
              label="All"
              value=""
              checked={tableState.checkAllFilters}
            />

            {tableState.filters.map((option: any, i: number) => (
              <styles.CheckFilter
                onChange={(e) => tableState.setFilters(e.target.value || "")}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                label={option}
                value={option}
                checked={tableState.selectFilters.includes(option)}
              />
            ))}
          </styles.Content>
        ) : null}
      </styles.Container>
    );
  }
);
