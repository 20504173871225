import { makeAutoObservable, runInAction } from "mobx";
import { UseFormReset } from "react-hook-form";
import { toast } from "src/components/shared/Toaster";
import {
  addTickerGroup,
  deleteTickerGroup,
  editTickerGroup,
  getPartyTickerGroups,
} from "src/api/userManager/partiesAPI";
import { showSuccessMsg } from "src/helpers/message";
import WindowConsent from "src/state/WindowConsent";
import EditPartyStore from "./EditParty";

export type TickerType = "base" | "quote";

export interface ITicker {
  exchange: string;
  name: string;
  ratio: string;
}

export interface INewTickerGroup {
  type: TickerType | "";
  name: string;
  tickers: ITicker[];
}

export interface ITickerGroup extends INewTickerGroup {
  uuid: string;
}

export interface TickerLoaders {
  isLoading: boolean;
  isCreateLoading: boolean;
}

class TickersStore {
  tickerGroups: ITickerGroup[] = [];

  mainStore: EditPartyStore;

  private _loaders: TickerLoaders = {
    isLoading: false,
    isCreateLoading: false,
  };

  constructor(state: EditPartyStore) {
    this.mainStore = state;

    makeAutoObservable(this);
  }

  loadData = () => {
    this.mainStore.getAccounts();
    this._getTickers(this.party);
  };

  get party() {
    return this.mainStore.party;
  }

  get exchangeList() {
    return this.mainStore.exchSelector;
  }

  get isLoading() {
    return this._loaders.isLoading;
  }

  get isCreateLoading() {
    return this._loaders.isCreateLoading;
  }

  setLoading = <K extends keyof TickerLoaders>(field: K, load: boolean) => {
    this._loaders[field] = load;
  };

  private _getTickers = async (party: string) => {
    this.setLoading("isLoading", true);

    try {
      const { isError, data } = await getPartyTickerGroups(party);

      if (!isError) {
        runInAction(() => {
          this.tickerGroups = data;
        });
      }
    } finally {
      this.setLoading("isLoading", false);
    }
  };

  validateEmptyTickers = (tickers: ITicker[]) => tickers.length > 0;

  validate = (ticker: ITickerGroup | INewTickerGroup) => {
    const valid = this.validateEmptyTickers(ticker.tickers);

    if (!valid) {
      toast.error("The ticker group must contain at least one ticker");
    }

    return valid;
  };

  createTickerGroup = (closeModalCb: () => void) => async (ticker: INewTickerGroup) => {
    const valid = this.validate(ticker);

    if (!valid) return;

    this.setLoading("isCreateLoading", true);

    try {
      const { isError } = await addTickerGroup(this.party, ticker);

      if (!isError) {
        this.loadData();
        closeModalCb();
        showSuccessMsg("Ticker group successfully created");
      }
    } finally {
      this.setLoading("isCreateLoading", false);
    }
  };

  editTicker = (reset: UseFormReset<ITickerGroup>) => async (ticker: ITickerGroup) => {
    const valid = this.validate(ticker);

    if (!valid) return;

    this.setLoading("isLoading", true);

    try {
      const { isError } = await editTickerGroup(ticker.uuid, ticker);

      if (!isError) {
        this._getTickers(this.party);
        reset(ticker);
        showSuccessMsg(`Ticker group ${ticker.name} changed successfully`);
      }
    } finally {
      this.setLoading("isLoading", false);
    }
  };

  removeHandler = (name: string, uuid: string) => () => {
    WindowConsent.showWindow(
      "Are you sure you want to remove the ticker group:",
      `${name}`,
      this._deleteTicker,
      { uuid }
    );
  };

  private _deleteTicker = async ({ uuid }: { uuid: string }) => {
    this.setLoading("isLoading", true);

    try {
      const { isError } = await deleteTickerGroup(uuid);

      if (!isError) {
        this._getTickers(this.party);
        showSuccessMsg("Ticker group successfully deleted");
      }
    } finally {
      this.setLoading("isLoading", false);
    }
  };

  destroy = () => {};
}

export default TickersStore;
