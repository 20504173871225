import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface BindButtonsProps extends ComponentPropsWithoutRef<"div"> {
  buttons?: JSX.Element;
}

export const BindButtons = ({ buttons, ...props }: BindButtonsProps) => (
  <styles.WrapperForButton {...props}>{buttons}</styles.WrapperForButton>
);
