import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { Price } from "src/components/shared/shared";
import styled from "styled-components";

interface OrderBookModeProps {
  active: boolean;
}

export const LastTradeContainer = styled.div`
  width: 100%;
  height: 28px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  padding: 0px 2.5px;

  border: 1px solid;
  border-color: ${({ theme }) => theme.lineColor};
  border-left: none;
  border-right: none;

  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModesWrapper = styled(Wrapper)`
  width: 85px;
`;

export const OrderBookMode = styled(IconButton).attrs({
  boxed: true,
})<OrderBookModeProps>`
  opacity: ${({ active }) => (active ? "1" : "0.5")};
`;

export const LastTrade = styled(Price)`
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;

export const SpreadPercent = styled.span`
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.smallHeader};
`;
