import { OurDepth } from "src/state/MarketCheck/types";
import { DepthInfo } from "./DepthInfo";
import * as styles from "./style";

interface OurDepthCellProps {
  cellData: OurDepth;
}

export const OurDepthCell = ({ cellData }: OurDepthCellProps) => {
  const { buy, sell } = cellData;

  return (
    <styles.Wrapper>
      <DepthInfo title="Buy %" depthData={buy} />

      <DepthInfo title="Sell %" depthData={sell} />
    </styles.Wrapper>
  );
};
