import { ChangeEvent, ComponentPropsWithoutRef } from "react";
import { PNLFee } from "src/components/shared/PNLV2/shared/PNLFee";
import { InOutIndicators } from "src/components/shared/PNLV2/shared/InOutIndicators";
import { PNLPrice } from "./PNLPrice";
import * as styles from "./style";

export interface PNLHeaderOwnProps {
  fee?: string | number;
  price?: string | number;
  feeChecked?: boolean;
  info?: string;
  onFeeChecked?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export interface PNLHeaderProps extends PNLHeaderOwnProps, ComponentPropsWithoutRef<"div"> {}

export const PNLHeader = ({
  fee,
  price,
  feeChecked,
  onFeeChecked,
  info,
  ...props
}: PNLHeaderProps) => (
  <styles.Container {...props}>
    <InOutIndicators info={info} />
    <PNLPrice value={price} />
    <PNLFee value={fee} checked={feeChecked} onChange={onFeeChecked} />
  </styles.Container>
);
