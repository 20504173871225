import { ApiKeysContainer } from "src/components/BotsContent/CEX/CEXApiKeys/Accounts/style";
import styled from "styled-components";
import { Container } from "../shared";

export const AccountContainer = styled(Container)`
  min-width: 0;
`;

export const Content = styled(ApiKeysContainer)`
  width: 100%;
  height: initial;
  flex: initial;

  gap: 15px;
  flex-direction: column;
`;
