import { observer } from "mobx-react-lite";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Headline2 } from "src/components/shared/Typography";
import { PartyContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Credential } from "src/modules/userManager";
import WindowConsent from "src/state/WindowConsent";
import { EditName } from "../../EditName";
import * as styles from "./style";

export const MetaData = observer(() => {
  const partyState = useLateInitContext(PartyContext);

  const metaDataRemove = (API: Credential, label: string) => {
    if (partyState)
      WindowConsent.showWindow(
        "",
        `Are you sure you want to remove the ${label} from the API: ${API.name}`,
        partyState?.removeMetaDataAPI,
        { API, label }
      );
  };

  return (
    <styles.Container>
      <Headline2>MetaData</Headline2>
      <styles.Content>
        <EditName
          name={partyState?.currentCredential.name}
          updNameRequest={partyState?.editName}
          type="API"
        />

        {partyState?.currentMetaKeys.map((el, index) => (
          <styles.Wrapper key={el}>
            <LabeledInput
              label={el}
              value={partyState?.currentCredential.meta[el] || ""}
              onChange={() => {}}
            />

            <DeleteButton
              onClick={() =>
                partyState?.currentCredential
                  ? metaDataRemove(partyState?.currentCredential, el)
                  : null
              }
            />
          </styles.Wrapper>
        ))}
      </styles.Content>
    </styles.Container>
  );
});
