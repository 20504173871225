import { setTextClipboard } from "src/helpers/clipboard";
import { joinStrings } from "src/helpers/string";
import { getRootURL } from "src/helpers/url";

export interface IBotCredential {
  market: string;
  name?: string;
  UUID: string;
  link: string;
}

/**
 * method for copying bot credentials to clipboard
 * @param cred - bot credential
 */
export const copyBotCredential = (cred: IBotCredential) => {
  const rootUrl = getRootURL();

  const botLink = rootUrl + cred.link;

  const market = `Market: ${cred.market}`;
  const name = cred.name ? `Bot name: ${cred.name}` : "";
  const uuid = `UUID: ${cred.UUID}`;
  const url = `URL: ${botLink}`;

  const text = joinStrings([market, name, uuid, url]);

  setTextClipboard(text);
};
