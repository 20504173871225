import { deepmerge } from "@mui/utils";
import { SeriesType } from "lightweight-charts";
import { useMemo } from "react";
import { OmitUnion } from "src/helpers/utils";
import { OptionalSeriesOptions, USeriesRootBaseProps } from "../types";
import { useMinMove } from "./useMinMove";
import { useSeriesTypeOptions } from "./useSeriesTypeOptions";
import { useTitle } from "./useTitle";

export type UseSeriesOptionsParams = OmitUnion<
  USeriesRootBaseProps,
  "id" | "side" | "visible" | "startOptions"
>;

export const useSeriesOptions = <T extends SeriesType>({
  options: outerOptions,
  data,
  title,
  showTitleLabel,
  ...props
}: UseSeriesOptionsParams) => {
  const typeOptions = useSeriesTypeOptions(props);

  const minMoveOptions = useMinMove({ data });

  const titleOptions = useTitle({ title, showTitleLabel });

  const options = useMemo(
    () =>
      deepmerge<OptionalSeriesOptions<T>>(
        { ...typeOptions, ...minMoveOptions, ...titleOptions },
        outerOptions
      ),
    [minMoveOptions, outerOptions, titleOptions, typeOptions]
  );

  return options;
};
