import { FormProvider, useForm } from "react-hook-form";
import { BaseModuleForm } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategyModuleCreateType } from "src/modules/expertSystem";
import { CONDITIONAL_EMPTY_MAP } from "../shared/consts";

const MODULE_TYPE = "time";

export const TimeTrigger = () => {
  const state = useLateInitContext(CreateModulesContext);
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<IStrategyModuleCreateType<typeof MODULE_TYPE>>({
    defaultValues: CONDITIONAL_EMPTY_MAP[MODULE_TYPE],
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <BaseModuleForm
        formId={MODULE_TYPE}
        onSubmit={handleSubmit(state.createConditionHandler(close))}
      />
    </FormProvider>
  );
};
