import { useCallback, useMemo } from "react";
import { ActionMeta, OnChangeValue } from "react-select";
import { SelectorCommonProps } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/SelectColumnFilter";
import { SeriesLegendData } from "src/state/Graph/Series/types";
import { LegendSeriesOption } from "./LegendSeriesOption";
import * as styles from "./style";

type LegendSeriesData = Omit<SeriesLegendData, "series">;

export type LegendSeriesSelectorValue = {
  value: string;
  label: string;
  color?: string;
  id: string;
};

const legendDataToSelectorValue = ({ title, color, id }: LegendSeriesData) => ({
  value: title,
  label: title,
  color,
  id,
});

interface UseLegendSeriesSelectorValueParams extends Pick<LegendSeriesSelectorProps, "data"> {}

const useLegendSeriesSelectorValue = ({ data }: UseLegendSeriesSelectorValueParams) => {
  const selectorValue = useMemo((): LegendSeriesSelectorValue | null => {
    const selectedItem = data.find((item) => item.visible);
    return selectedItem ? legendDataToSelectorValue(selectedItem) : null;
  }, [data]);

  const selectorOptions = useMemo(
    (): LegendSeriesSelectorValue[] => data.map(legendDataToSelectorValue),
    [data]
  );
  return { selectorValue, selectorOptions };
};

export interface LegendSeriesSelectorProps extends SelectorCommonProps {
  data: LegendSeriesData[];
  showSeries: (id: string) => void;
}

export const LegendSeriesSelector = ({ data, showSeries, ...props }: LegendSeriesSelectorProps) => {
  const { selectorValue, selectorOptions } = useLegendSeriesSelectorValue({
    data,
  });

  const selectorOnChange: (
    newValue: OnChangeValue<LegendSeriesSelectorValue, false>,
    actionMeta: ActionMeta<LegendSeriesSelectorValue>
  ) => void = useCallback(
    (newValue) => {
      if (newValue) {
        showSeries(newValue.id);
      }
    },
    [showSeries]
  );

  return (
    <styles.StyledSelectorFilter
      value={selectorValue}
      onChange={selectorOnChange}
      options={selectorOptions}
      components={{
        Option: LegendSeriesOption as typeof LegendSeriesOption<false>,
      }}
      {...props}
    />
  );
};
