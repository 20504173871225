import { BaseHeadline } from "src/components/shared/Typography";
import styled from "styled-components";

export const AddressLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  /* width: 100%; */
  min-width: 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;

  ${BaseHeadline}

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  font-family: "Roboto", monospace;
  font-weight: ${({ theme }) => theme.fontWeights.normal};

  color: #328ee3;

  cursor: pointer;
`;

export const Form = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 40px;
`;
