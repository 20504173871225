import { ISeriesApi, OhlcData, SeriesType, SingleValueData } from "lightweight-charts";
import { calcRoundingValues, roundSingleValue, toRounding } from "src/helpers/rounding";
import { objectToFormatString } from "src/helpers/string";
import { entries } from "src/helpers/utils";
import { getSeriesColor } from "../../Series/utils";
import { MouseEventSeriesData, SeriesData, SeriesDataValue, UTooltipSeriesData } from "../types";

export const isOHLCData = (data: SeriesData<SeriesType>): data is OhlcData =>
  (data as OhlcData).open !== undefined && (data as OhlcData).close !== undefined;

export const isSingleValueData = (data: SeriesData<SeriesType>): data is SingleValueData =>
  (data as SingleValueData).value !== undefined;

const getSeriesValue = <T extends SeriesType>(seriesData: SeriesData<T>): SeriesDataValue<T> => {
  if (isOHLCData(seriesData)) {
    const { open, high, low, close } = seriesData;
    const data = { open, high, low, close };
    const precision = calcRoundingValues(Object.values(data));

    const roundedData = entries(data).reduce(
      (acc, [key, value]) => {
        const roundedValue = +toRounding(value, precision);
        acc[key] = roundedValue;
        return acc;
      },
      {} as typeof data
    );

    return roundedData as SeriesDataValue<T>;
  }
  const { value } = seriesData;
  const roundedData = +roundSingleValue(value);
  return roundedData as SeriesDataValue<T>;
};

export const seriesDataToTooltipSeriesData = <T extends SeriesType>(
  seriesTitle: string,
  series: ISeriesApi<T> | null,
  seriesData: MouseEventSeriesData
): UTooltipSeriesData<T> | undefined => {
  if (!series) return;

  const data = seriesData.get(series) as SeriesData<T> | undefined;
  if (!data) return;

  const options = series.options();
  if (!options.visible) return;

  const value = getSeriesValue(data);

  const title = seriesTitle;

  const color = getSeriesColor(series);

  return { value, title, color } as UTooltipSeriesData<T>;
};

export const seriesDataToString = <T extends SeriesType>(
  seriesTitle: string,
  seriesData: UTooltipSeriesData<T>
) => {
  const { title, color, ...data } = seriesData;
  const dataText = Object.values(data)
    .map((v) => {
      if (typeof v === "object") {
        return objectToFormatString(v);
      }
      return v;
    })
    .join(" - ");

  const seriesText = `${seriesTitle}: ${dataText}`;

  return seriesText;
};
