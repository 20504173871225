import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { PartyContext } from "src/context/UserManager/UserManager";
import { stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { InfoRows } from "../InfoRows";
import { Credentials } from "./Credentials";
import { MetaData } from "./MetaData";
import * as styles from "./style";

export const CredentialsPanel = observer(() => {
  const partyState = useContext(PartyContext);
  const createdAt = partyState?.currentCredential?.info?.created_at;
  const updatedAt = partyState?.currentCredential?.info?.updated_at;

  return (
    <styles.Container>
      <Credentials />
      <MetaData />
      <InfoRows
        created={createdAt ? unixToDateFormat(stringDateToUnix(createdAt), "FullDate") : ""}
        updated={updatedAt ? unixToDateFormat(stringDateToUnix(updatedAt), "FullDate") : ""}
        uuid={partyState?.currentAccount || ""}
      />
    </styles.Container>
  );
});
