import { observer } from "mobx-react-lite";
import { SettingsPanelProps } from "src/components/BotsContent/DEX_V2/Settings/shared/SettingsPanel";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { WalletLabeledInput } from "../../../shared/WalletLabeledInput";
import { SideSettingsPanel, WalletsList } from "../../shared";
import { FormContent } from "../style";

export interface SwapWalletsPanelProps extends Omit<SettingsPanelProps, "label" | "children"> {}

export const SwapWalletsPanel = observer((props: SwapWalletsPanelProps) => {
  const { swapWalletsState, errors } = useLateInitContext(DEXV2CreatingContext);

  const saveSwapEnabled = Boolean(swapWalletsState.wallet);
  return (
    <SideSettingsPanel label="Swap Wallets" {...props}>
      <FormContent>
        <WalletLabeledInput
          label="Swap Wallet"
          addEnabled={saveSwapEnabled}
          onAdd={swapWalletsState.addWallet}
          value={swapWalletsState.wallet}
          onChange={swapWalletsState.setWallet}
          errorHint={swapWalletsState.walletErrors}
        />

        <WalletsList
          hashes={swapWalletsState.walletsAsFieldProps}
          hashesErrors={errors.base_data?.swap_oracle_wallets}
          onDeleteHash={swapWalletsState.deleteWallet}
        />
      </FormContent>
    </SideSettingsPanel>
  );
});
