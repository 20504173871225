import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { CheckBox } from "src/components/shared/CheckBox";
import { Header } from "src/components/shared/PanelHeader/style";
import { Switches } from "src/components/shared/Switches";
import { WrapperForRadioItems } from "src/components/shared/Switches/style";
import styled from "styled-components";

export const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (max-width: 1200px) and (min-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const RangeCheckbox = styled(CheckBox)`
  flex-shrink: 0;
  margin-bottom: 5px;
`;

export const CandlesSettings = styled(SettingsPanel)`
  ${Header} {
    @media screen and (max-width: 1200px) and (min-width: 950px) {
      flex-direction: column;
      gap: 5px;
    }
  }
`;

export const CandlesSwitches = styled(Switches)`
  ${WrapperForRadioItems} {
    max-width: 90px;
  }
`;
