import { roundSingleValue } from "src/helpers/rounding";
import { entries } from "src/helpers/utils";
import { ColumnTotalCb } from "src/state/CEX/CEXDashboard/shared/TableTotalizer";
import {
  AccountBalance,
  AccountBalances,
  AccountBalancesKeys,
  BalancesColumnsKeys,
} from "src/state/CEX/CEXDashboard/v2/widgets/AccountBalancesStore";

export type AccountBalancesTotal = Required<AccountBalance>;

export type AccountBalancesTotalMap = {
  [key in BalancesColumnsKeys]: AccountBalancesTotal;
};

const INITIAL_BALANCES_TOTAL: AccountBalancesTotal = {
  total: 0,
  free: 0,
  locked: 0,
};

const getBalanceTotal = (currentTotal: number, balance: number | undefined) =>
  +roundSingleValue(currentTotal + (balance ?? 0));

export const getAccountBalancesTotal: ColumnTotalCb<AccountBalances, AccountBalancesTotalMap> = (
  id,
  row,
  prevTotal
) => {
  const key = id as AccountBalancesKeys;
  switch (key) {
    case "exchange":
    case "accountName": {
      return prevTotal;
    }
    default: {
      const balances = row[key];
      const prevTotalBalance = prevTotal as AccountBalancesTotal | undefined;
      const total = prevTotalBalance || INITIAL_BALANCES_TOTAL;

      const newTotal = entries(total).reduce<AccountBalancesTotal>((totals, [key, value]) => {
        // eslint-disable-next-line no-param-reassign
        totals[key] = getBalanceTotal(value, balances[key]);
        return totals;
      }, {} as AccountBalancesTotal);

      return newTotal;
    }
  }
};
