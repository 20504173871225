import styled from "styled-components";
import { PromptMsg } from "src/components/shared/shared";
import { BlockPanel } from "../../BlockPanel";

export const Container = styled(BlockPanel)`
  grid-area: funding-list;
`;

export const TitlePromptMsg = styled(PromptMsg)`
  font-size: 13px !important;
`;
