import { TeamsContext } from "src/context/UserManager/Groups";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { TeamMember } from "src/modules/userManager";
import { TeamColorProps } from "../Team";
import { CountSpan } from "../Team/style";
import { Party } from "./Party";
import * as styles from "./style";

export interface MemberProps
  extends TeamMember,
    Omit<React.ComponentPropsWithRef<"div">, keyof TeamColorProps>,
    TeamColorProps {
  team: string;
}

export const Member = ({ team, name, parties, head, accentColor, ...props }: MemberProps) => {
  const state = useLateInitContext(TeamsContext);

  const matchedParties = state.partiesSearchMatchesForMember(team, name);
  const memberMatched = Boolean(matchedParties.size);

  return (
    <styles.Card accent={accentColor} isHighlighted={memberMatched} {...props}>
      <styles.CardContent>
        <styles.FixedDragIcon />
        <styles.CardHeader>
          <styles.Name head={head} accent={accentColor}>
            {name} <CountSpan>({parties.length})</CountSpan>
          </styles.Name>
        </styles.CardHeader>
        <styles.CardBody>
          <styles.PartiesList>
            {parties.map((party) => (
              <Party name={party} key={party} isHighlighted={matchedParties.has(party)} />
            ))}
          </styles.PartiesList>
        </styles.CardBody>
      </styles.CardContent>
    </styles.Card>
  );
};
