import { darken, lighten } from "polished";
import { StyledProps } from "src/helpers/styled/types";
import styled, { DefaultTheme, css } from "styled-components";

const getColorShade = (theme: DefaultTheme) =>
  theme.mode === "light" ? lighten(0.4, theme.primaryColor) : darken(0.3, theme.primaryColor);

interface OrientationProps {
  orientation?: "horizontal" | "vertical";
}

export interface ProgressSpanStyleProps extends OrientationProps {
  backgroundColor?: string;
}

export interface ProgressSpanStyledProps extends StyledProps<ProgressSpanStyleProps> {}

export const ProgressSpan = styled.span<ProgressSpanStyledProps>`
  position: relative;
  z-index: 0; /* Fix Safari's bug during composition of different paint. */

  overflow: hidden;
  display: block;

  ${({ $orientation }) => {
    if ($orientation === "vertical") {
      return css`
        width: 4px;
      `;
    }
    return css`
      height: 4px;
    `;
  }}

  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || getColorShade(theme)};

  border-radius: ${({ theme }) => theme.radii["2px"]};
`;

const TRANSITION_DURATION = 0.4;

export interface ProgressBarStyleProps extends OrientationProps {
  color?: string;
  animate?: boolean;
  transform?: number;
}

export interface ProgressBarStyledProps extends StyledProps<ProgressBarStyleProps> {}

export const ProgressBar = styled.span<ProgressBarStyledProps>`
  position: absolute;

  background-color: ${({ color, theme }) => color || theme.primaryColor};

  transition: transform 0.2s linear;
  ${({ $animate }) =>
    $animate &&
    css`
      transition: transform ${TRANSITION_DURATION}s linear;
    `}

  ${({ $orientation, $transform }) => {
    if ($orientation === "vertical") {
      return css`
        height: 100%;
        left: 0;
        right: 0;
        top: 0;

        transform-origin: bottom;
        ${$transform &&
        css`
          transform: translateY(${$transform}%);
        `}
      `;
    }
    return css`
      width: 100%;

      left: 0;
      bottom: 0;
      top: 0;

      transform-origin: left;
      ${$transform &&
      css`
        transform: translateX(${$transform}%);
      `}
    `;
  }}
`;
