import { useCallback } from "react";
import { DateTimeRangeFieldProps } from "../DateTimeFields/DateTimeRangeField";
import { DatePickerOnChange, DatePickerRange } from "../shared/models/datePicker";
import { dayjsRangeToPickerRange, pickerRangeToDayjsRange } from "../shared/utils/time-utils";
import { rangeValueManager } from "../shared/utils/valueManager";
import * as styles from "./style";

export interface UseDatePickerProps
  extends Pick<DateTimeRangeFieldProps, "value" | "onChange" | "maxDate" | "minDate"> {
  showUTC?: boolean;
}

export type UseDatePickerResponse = {
  value: DatePickerRange;
  onChange: DatePickerOnChange;
  minDate?: Date;
  maxDate?: Date;
};

export interface DatePickerProps extends UseDatePickerProps {}

const useDatePicker = ({
  value: currentRange,
  onChange: onRangeChange,
  minDate,
  maxDate,
  showUTC,
}: UseDatePickerProps): UseDatePickerResponse => {
  const dateRange = dayjsRangeToPickerRange(currentRange ?? rangeValueManager.emptyValue, {
    useUtc: showUTC,
  });

  const onDateRangeChange: DatePickerOnChange = useCallback(
    (value) => {
      const valueRange: DatePickerRange = Array.isArray(value) ? value : [value, null];
      const newValue = pickerRangeToDayjsRange(valueRange, { useUtc: showUTC });
      onRangeChange?.(newValue, {
        validationError: rangeValueManager.emptyValue,
      });
    },
    [onRangeChange, showUTC]
  );

  return {
    value: dateRange,
    onChange: onDateRangeChange,
    minDate: minDate?.toDate(),
    maxDate: maxDate?.toDate(),
  };
};

export const DatePicker = (props: DatePickerProps) => {
  const pickerProps = useDatePicker(props);
  return (
    <styles.Container>
      <styles.DatePicker
        {...pickerProps}
        selectRange
        locale="en-US"
        calendarType="ISO 8601"
        goToRangeStartOnSelect
        showFixedNumberOfWeeks
      />
    </styles.Container>
  );
};
