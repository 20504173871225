import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const RowsContainer = styled.div`
  position: relative;

  height: 0px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 16px;
  padding-bottom: 8px;

  overflow: auto;

  ${ScrollSize}
`;
