import {
  FilterButton,
  FilterButtonProps,
} from "src/components/ExchangeAccounting/Content/TableHeader/Filters/FilterDropdown/FilterButton";
import { SidebarProps } from "src/components/shared/Sidebar";
import * as styles from "./style";

export interface FilterPanelProps
  extends Omit<SidebarProps, "popupProps" | "onReset" | "onSubmit">,
    Pick<FilterButtonProps, "count"> {}

export const FilterPanel = ({ children, count, ...props }: FilterPanelProps) => (
  <styles.StyledSidebar
    label="Filters"
    popupProps={{ trigger: <FilterButton size="m" count={count} /> }}
    {...props}
  >
    {children}
  </styles.StyledSidebar>
);
