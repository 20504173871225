import { ThemeProvider } from "styled-components";
import { Header } from "../components/Header";
import { darkTheme, lightTheme } from "../components/Themes";
import { ModalConsent } from "../components/shared/ModalConsent";
import { ToasterInit } from "../components/shared/Toaster";
import { AbilitiesProvider } from "../context/DEXV2/DEXV2Bots/AbilitiesContext";
import { useDarkMode } from "../hooks/UseDarkMode";
import { AppRouter } from "./AppRouter";
import * as styles from "./style";

export const App = () => {
  const { theme, themeToggler, mountedComponent } = useDarkMode();
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  if (!mountedComponent) return <div />;

  return (
    <ThemeProvider theme={themeMode}>
      <AbilitiesProvider>
        <styles.GlobalStyle theme={themeMode} />

        <styles.AppContent>
          <Header themeToggler={themeToggler} />

          <styles.PageContent>
            <AppRouter />
          </styles.PageContent>
        </styles.AppContent>

        <ModalConsent />

        <ToasterInit />
      </AbilitiesProvider>
    </ThemeProvider>
  );
};
