import { ModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { StrategyModuleCategory } from "src/modules/expertSystem";
import { ModulesStoreMap } from "src/state/expertSystem/modules/ModulesStore";

export const useModuleState = <T extends StrategyModuleCategory>(moduleType: T) => {
  const {
    currentModuleState: { type, state },
  } = useLateInitContext(ModulesContext.Context);
  if (type !== moduleType) {
    throw new Error(
      "Invalid current module type, make sure to switch to the correct module type first!"
    );
  }
  return state as ModulesStoreMap[T];
};
