import { ColumnDef, ColumnDefBase, TableOptions } from "@tanstack/react-table";
import { StrategyModuleTypes } from "src/modules/expertSystem";
import { TableRowsProps } from "../../../shared";
import { CellText } from "../../../shared/TableRows/style";
import { Chip } from "../../../shared/TagList/Chip";
import { TAG_COLOR_MAP } from "../../../shared/utils/modules";
import * as styles from "./style";

export const BASE_MODULES_COLUMN_PROPS: Omit<ColumnDefBase<any>, "footer" | "cell"> = {
  enableColumnFilter: false,
  enableSorting: false,
};

export const BASE_MODULE_CELL_TEXT: Pick<ColumnDef<any>, "cell"> = {
  cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
};

export const BASE_CELL_MODULE: Pick<ColumnDef<any>, "cell"> = {
  cell: (info) => {
    const module = info.getValue<StrategyModuleTypes>();

    return <Chip label={`${module}`} color={TAG_COLOR_MAP[module]} />;
  },
};

export interface ModulesTableProps<D extends object>
  extends Omit<TableRowsProps, "data" | "columns" | "rowSize">,
    Pick<TableOptions<D>, "data" | "columns"> {}

export const ModulesTable = <D extends object>(props: ModulesTableProps<D>) => (
  <styles.StyledModulesTable {...props} />
);
