import { ColumnDef } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { SeparatorNum } from "src/components/shared/Separator";
import { ThousandthSeparator } from "src/components/shared/ThousandthSeparator";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Trade } from "src/modules/exchange/trade";
import * as styles from "./style";
import { TradeVirtualRows } from "./TradeVirtualRow";

export const TradeHistory = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);
  const state = mainState.tradeHistoryState;

  useEffect(() => {
    state.downloadData();
  }, [state]);

  const columns = useMemo(() => {
    const columnSchema: ColumnDef<Trade>[] = [
      {
        header: () => "Time",
        accessorKey: "time",
        cell: (info) => <styles.Time>{info.getValue<string>()}</styles.Time>,
      },
      {
        header: () => "Price",
        accessorKey: "price",
        cell: ({
          cell: {
            row: {
              original: { side, price },
            },
          },
        }) => (
          <styles.Price side={side}>
            <SeparatorNum value={price} />
          </styles.Price>
        ),
      },
      {
        header: () => "Amount",
        accessorKey: "amount",
        cell: (info) => (
          <styles.Amount>
            <ThousandthSeparator value={info.getValue<string>()} />
          </styles.Amount>
        ),
      },
    ];

    return columnSchema;
  }, []);

  return (
    <TradeVirtualRows
      columns={columns}
      orders={state.tradeHistory}
      loader={state.isLoading}
      rowSize={20}
    />
  );
});
