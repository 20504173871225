import {
  TableBodyV2,
  TableCellText,
  TableCellV2,
  TableHeaderCellV2,
  TableHeadRowV2,
  TableHeadV2,
  TableRowV2,
  TableV2,
  TContainer,
} from "src/components/shared/TableV2";
import { Abilities } from "src/modules/abilities";
import styled from "styled-components";

interface HeaderProps {
  abilities: Abilities;
  party: string;
}

interface RowProps {
  isOpacity: boolean;
}

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Container = styled(TContainer)``;

export const OrdersTable = styled(TableV2)`
  height: fit-content;
`;

export const Head = styled(TableHeadV2)``;

export const Body = styled(TableBodyV2)``;

export const Row = styled(TableRowV2)<RowProps>`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  opacity: ${({ isOpacity }) => (isOpacity ? 0.5 : 1)};
`;

export const HeaderRow = styled(TableHeadRowV2)``;

export const HeaderCell = styled(TableHeaderCellV2)<HeaderProps>`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;

export const Cell = styled(TableCellV2)``;

export const CellText = styled(TableCellText)`
  white-space: nowrap;
`;
