import { useMemo } from "react";
import { hexToRgb } from "src/helpers/colors";
import { useLinePrimaryColor } from "../../../hooks";
import { OptionalSeriesOptions } from "../types";
import { UseSeriesTypeOptionsParams } from "./useSeriesTypeOptions";

export const getColorAlphaLevel = (alpha: number, showColor: boolean) => (showColor ? alpha : 0);

type UseAreaColorParams = Extract<UseSeriesTypeOptionsParams, { type: "Area" }>;

const useAreaColor = ({ color = "primaryColor", showAreaColor = true }: UseAreaColorParams) => {
  const lineColor = useLinePrimaryColor(color);

  const colors = useMemo((): OptionalSeriesOptions<"Area"> => {
    const topColor = hexToRgb(lineColor, getColorAlphaLevel(0.56, showAreaColor));
    const bottomColor = hexToRgb(lineColor, getColorAlphaLevel(0.04, showAreaColor));
    return {
      lineColor,
      topColor,
      bottomColor,
    };
  }, [lineColor, showAreaColor]);

  return colors;
};

type UseLineColorParams = Extract<UseSeriesTypeOptionsParams, { type: "Line" }>;

const useLineColor = ({ color = "primaryColor" }: UseLineColorParams) => {
  const lineColor = useLinePrimaryColor(color);

  const colors = useMemo(
    (): OptionalSeriesOptions<"Line"> => ({
      color: lineColor,
    }),
    [lineColor]
  );

  return colors;
};

type UseSeriesColorParams = UseSeriesTypeOptionsParams;

/**
 * Custom hook to determine the color props for a series based on its type.
 * @returns The color props for the series.
 */
export const useSeriesColor = (props: UseSeriesColorParams) => {
  const areaColors = useAreaColor(props as UseAreaColorParams);
  const lineColors = useLineColor(props as UseLineColorParams);

  const colors = useMemo(() => {
    switch (props.type) {
      case "Area":
        return areaColors;
      case "Line":
        return lineColors;
      default:
        return {};
    }
  }, [areaColors, lineColors, props.type]);

  return colors;
};
