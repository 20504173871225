import { observer } from "mobx-react-lite";
import { useFormContext } from "react-hook-form";
import { ControlledInput } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledInput";
import { Extends } from "src/helpers/utils";
import { IStrategyModuleCreateType, StrategyModuleTypes } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import { AccountField } from "../AccountField";

type ModulesWithAccCurrency = Extends<
  StrategyModuleTypes,
  "balance_abs" | "balance_change" | "balance_delta"
>;

export const AccountCurrencyFields = observer(() => {
  const { control } = useFormContext<IStrategyModuleCreateType<ModulesWithAccCurrency>>();

  return (
    <>
      <AccountField />

      <ControlledInput
        controllerProps={{
          label: "Currency",
          control,
          name: "hard_params.currency",
          rules: {
            required: ERRORS_MSGS.isRequired,
          },
        }}
      />
    </>
  );
});
