import { CSSProperties, useCallback, useMemo } from "react";
import { ActionMeta, MultiValueGenericProps, OnChangeValue, components } from "react-select";
import {
  CellValue,
  ColumnInstance,
  FilterProps,
  FilterValue,
  UseFiltersColumnProps,
} from "react-table";
import {
  PrimitiveSelectorValue,
  RawPrimitiveSelectorValue,
  getPrimitiveSelectorList,
  toRawPrimitiveSelectorValue,
} from "src/helpers/forms/selectors";
import { Comparators } from "src/helpers/sorting";
import { ColumnFiltersIds } from "../shared";
import { CheckboxInputOption } from "./CheckboxInputOption";
import * as styles from "./style";

type SelectFilterValue = RawPrimitiveSelectorValue | "ALL";

export interface FiltersColumnProps<D extends object, V = FilterValue>
  extends UseFiltersColumnProps<D> {
  setFilter: (updater: ((filterValue: V) => V) | V) => void;
  filterValue: V;
}

export const getSelectColumnFilterCount = <D extends object>(
  props: SelectColumnFilterProps<D>
): number | undefined => props.column.filterValue?.length;

const sortOptions = (options: Set<SelectFilterValue>) =>
  Array.from(options.values()).sort((a, b) =>
    typeof a === "number" ? Comparators.Number(a, b as number) : Comparators.String(a, b as string)
  );

const ALL_OPTION_LABEL = "ALL";

const ALL_OPTIONS: SelectFilterValue[] = [ALL_OPTION_LABEL];

interface UseSelectColumnFilterOptions<D extends object>
  extends Pick<ColumnInstance<D>, "preFilteredRows" | "id"> {}

const useSelectColumnFilterOptions = <D extends object>({
  preFilteredRows,
  id,
}: UseSelectColumnFilterOptions<D>) => {
  const options = useMemo(() => {
    const options = new Set<SelectFilterValue>();

    preFilteredRows.forEach((row) => {
      const rowValue = row.values[id];
      if (Array.isArray(rowValue)) {
        const values = rowValue as CellValue<SelectFilterValue[]>;
        values.forEach((value) => options.add(value));
      } else {
        const value = rowValue as CellValue<SelectFilterValue>;
        options.add(value);
      }
    });

    const sortedOptions = sortOptions(options);

    return sortedOptions;
  }, [id, preFilteredRows]);

  const selectorOptions = useMemo(() => {
    const baseOptions = getPrimitiveSelectorList(ALL_OPTIONS.concat(options));
    return baseOptions;
  }, [options]);

  return { options, selectorOptions };
};

interface UseSelectColumnFilterValueParams {
  values?: SelectFilterValue[];
  options: SelectFilterValue[];
}

const useSelectColumnFilterValue = ({ values = [], options }: UseSelectColumnFilterValueParams) => {
  const allOptionsSelected = values.length && values.length === options.length;

  const selectorValue = useMemo(() => {
    const allOption = allOptionsSelected ? ALL_OPTIONS : [];
    return getPrimitiveSelectorList(values.concat(allOption));
  }, [allOptionsSelected, values]);

  return selectorValue;
};

// wrapper around react-select MultiValueContainer to filter all option
const MultiValueContainer = (props: MultiValueGenericProps<SelectFilterValue>) => {
  // eslint-disable-next-line react/destructuring-assignment
  const isAllValue = props.data.value === ALL_OPTION_LABEL;
  return !isAllValue ? <components.MultiValueContainer {...props} /> : null;
};

export interface SelectorCommonProps {
  style?: CSSProperties | undefined;
  className?: string | undefined;
}

export interface SelectColumnFilterProps<D extends object>
  extends FilterProps<D>,
    SelectorCommonProps {}

export const SelectColumnFilter = <D extends object>({
  column,
  style,
  className,
}: SelectColumnFilterProps<D>) => {
  const selectorProps = { style, className };

  const { options, selectorOptions } = useSelectColumnFilterOptions<D>(column);

  const { filterValue, setFilter } = column as FiltersColumnProps<D>;

  const selectorValue = useSelectColumnFilterValue({
    values: filterValue,
    options,
  });

  const selectorOnChange: (
    newValue: OnChangeValue<PrimitiveSelectorValue<SelectFilterValue>, true>,
    actionMeta: ActionMeta<PrimitiveSelectorValue<SelectFilterValue>>
  ) => void = useCallback(
    (value, { option, action }) => {
      // remove all option from new values
      const values = toRawPrimitiveSelectorValue(value).filter(
        (value) => value !== ALL_OPTION_LABEL
      );

      if (option?.label === ALL_OPTION_LABEL) {
        switch (action) {
          case "select-option": {
            setFilter(options);
            return;
          }
          case "deselect-option": {
            setFilter([]);
          }
        }
      } else {
        setFilter(values);
      }
    },
    [options, setFilter]
  );

  return (
    <styles.StyledSelectorFilter
      isMulti
      options={selectorOptions}
      value={selectorValue}
      onChange={selectorOnChange}
      components={{
        Option: CheckboxInputOption as typeof CheckboxInputOption<
          PrimitiveSelectorValue<SelectFilterValue>,
          true
        >,
        MultiValueContainer,
      }}
      hideSelectedOptions={false}
      defaultMenuIsOpen
      closeMenuOnSelect={false}
      {...selectorProps}
    />
  );
};

SelectColumnFilter.filterId = ColumnFiltersIds.Select;
