import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { PNLV2Props } from "src/components/shared/PNLV2";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import PNLStore from "src/state/DEXV2/DEXV2Stats/PNL";
import * as styles from "./style";

export const DEXV2PNL = observer(() => {
  const botState = useLateInitContext(DEXV2BotContext);
  const state = useLocalStore(PNLStore, botState);

  const { botUUID } = botState;

  useEffect(() => {
    if (!botUUID) return;
    state.setBotUUID(botUUID);
    state.getPNL();
  }, [botUUID, state]);

  const { pnlInfo: pnlProps, dateRange, setDateRange } = state;

  const pickerProps = useMemo(
    (): PNLV2Props["pickerProps"] => ({
      popupProps: { position: "bottom left" },
      mobileQuery: "(max-width: 400px)",
      selectorDropdown: styles.DropdownSelector,
    }),
    []
  );

  return (
    <styles.StyledPNL
      {...pnlProps}
      orientation="column"
      showFeeSymbol={false}
      loading={state.loading}
      showPicker
      range={dateRange}
      onRangeChange={setDateRange}
      {...pickerProps}
    />
  );
});
