import { CardSurface } from "src/components/shared/shared";
import { Headline1 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export const Title = styled(Headline1)`
  margin-bottom: 40px;
`;

export const FormContainer = styled.div`
  position: relative;

  width: 100%;
  max-width: 488px;

  display: flex;
  flex-direction: column;

  padding: 28px 26px 32px;

  ${CardSurface}
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 17px;
  width: 100%;

  & > * {
    width: 210px;
    max-width: 100%;
  }
`;
