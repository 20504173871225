import styled from "styled-components";
import {
  TableBodyV2,
  TableCellV2,
  TableHeaderCellV2,
  TableHeadRowV2,
  TableHeadV2,
  TableRowV2,
  TableV2,
  TContainer,
} from "src/components/shared/TableV2";
import { ScrollSize } from "src/components/shared/shared";

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  position: relative;
  overflow-y: auto;

  ${ScrollSize}
`;

export const Container = styled(TContainer)`
  overflow: initial;
`;

export const OrdersTable = styled(TableV2)`
  height: fit-content;
  border-radius: ${({ theme }) => theme.radii["4px"]};
`;

export const Head = styled(TableHeadV2)``;

export const HeaderRow = styled(TableHeadRowV2)`
  border-bottom: 1px solid ${({ theme }) => theme.dashboard.borderDefault};
  background: ${({ theme }) => theme.dashboard.backgroundBaseContrast};
`;

export const HeaderCell = styled(TableHeaderCellV2)`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;

export const Body = styled(TableBodyV2)``;

export const Row = styled(TableRowV2)`
  background: ${({ theme }) => theme.contentBackgroundColor};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
`;

export const Cell = styled(TableCellV2)`
  height: 100%;
  padding: 8px;
  border: 0.5px solid ${({ theme }) => theme.dashboard.backgroundTableHead};
  border-bottom: 0.6px solid ${({ theme }) => theme.dashboard.borderDefault};
`;
