import { ComponentPropsWithoutRef } from "react";
import { Switch, SwitchProps } from "src/components/shared/Switches/Switch";
import * as styles from "./style";

export interface ModuleItemProps extends ComponentPropsWithoutRef<"div"> {
  switchProps: SwitchProps;
}

export const SelectModuleItem = ({ switchProps, ...props }: ModuleItemProps) => (
  <styles.Container {...props}>
    <Switch {...switchProps} />
  </styles.Container>
);
