import {
  ColumnFiltersState,
  OnChangeFn,
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useCallback, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useVirtual } from "react-virtual";
import { Loader } from "src/components/shared/Loader";
import { useId } from "src/hooks/useId";
import { HeadTooltip, HeadTooltipProps } from "../HeadTooltip";
import * as styles from "./style";

export type OnSortingChange = OnChangeFn<SortingState>;

export interface TableRowsProps extends ComponentPropsWithoutRef<"div"> {
  columns: any;
  data: any;
  loader: boolean;
  filters?: string[];
  rowSize?: number;
  sortingState?: SortingState;
  onSortingChange?: OnSortingChange;
  headTooltipProps?: Omit<HeadTooltipProps, "id">;
}

export const TableRows = observer(
  ({
    data: orders,
    rowSize = 30,
    columns,
    loader,
    filters = [],
    sortingState,
    onSortingChange,
    headTooltipProps,
    ...props
  }: TableRowsProps) => {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<SortingState>([]);

    const headTooltipID = useId(4);

    const table = useReactTable({
      data: orders,
      columns,
      state: {
        columnFilters,
        sorting: sortingState || sorting,
      },
      onSortingChange: onSortingChange || setSorting,
      manualSorting: Boolean(sortingState && onSortingChange),
      onColumnFiltersChange: setColumnFilters,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      debugTable: false,
    });

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
      estimateSize: useCallback(() => rowSize, [rowSize]),
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

    const PaddingTop = () =>
      paddingTop > 0 ? (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      ) : null;

    const paddingBottom =
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    const PaddingBottom = () =>
      paddingBottom > 0 ? (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      ) : null;

    return (
      <styles.TableWrapper ref={tableContainerRef} {...props}>
        <styles.StateTable>
          <styles.Head data-tooltip-id={headTooltipID}>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <styles.HeadRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <styles.HeadCell
                    key={header.id}
                    colSpan={header.colSpan}
                    column={header.column}
                    style={{
                      width: header.getSize(),
                    }}
                    {...{
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </styles.HeadCell>
                ))}
              </styles.HeadRow>
            ))}
          </styles.Head>
          <styles.StateTableBody ref={tableBodyRef}>
            <PaddingTop />

            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<any>;

              return (
                <styles.Row key={row.id}>
                  {row.getVisibleCells().map((cell, index) => (
                    <styles.StateTableCell key={cell.id} style={{ width: cell.column.getSize() }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </styles.StateTableCell>
                  ))}
                </styles.Row>
              );
            })}

            <PaddingBottom />
          </styles.StateTableBody>
        </styles.StateTable>

        {headTooltipProps && <HeadTooltip id={headTooltipID} {...headTooltipProps} />}

        <Tooltip id="totalAmount" variant="info" />

        <Loader show={loader} />
      </styles.TableWrapper>
    );
  }
);
