import { ContentPanel } from "../../../../shared";
import { PanelWrapper } from "../../../../shared/style";
import { ConditionList } from "./ConditionList";
import { ExpressionArea } from "./ExpressionArea";
import { FormsArea } from "./FormsArea";

export const ConditionsStep = () => (
  <PanelWrapper>
    <ContentPanel title="Available conditions">
      <ConditionList />
    </ContentPanel>
    <ContentPanel title="Added conditions in strategy">
      <FormsArea />
      <ExpressionArea />
    </ContentPanel>
  </PanelWrapper>
);
