import React, { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";
import { Headline3 } from "../../Typography";

export interface CardPanelProps extends ComponentPropsWithoutRef<"div"> {
  title?: string;
  labelElement?: JSX.Element;
  afterTitle?: JSX.Element;
}

export const CardPanel = ({ title, labelElement, children, afterTitle }: CardPanelProps) => (
  <styles.PanelContainer>
    <styles.PanelHeader>
      {title ? <Headline3>{title}</Headline3> : labelElement}
      {afterTitle}
    </styles.PanelHeader>

    <styles.PanelContent>{children}</styles.PanelContent>
  </styles.PanelContainer>
);
