import { decimalToPercentString } from "src/helpers/calcPerc";
import { isInfinity } from "src/helpers/math";
import { toRounding } from "src/helpers/rounding";
import { separation } from "src/helpers/separation";
import { CEXSettings } from "src/modules/settings";

export type ConstraintsValidator<T> = (
  data: CEXSettings,
  savedData: CEXSettings,
  constraint: T
) => string;

export class ConstraintsValidators {
  static dtpRangeIncrease: ConstraintsValidator<string> = (data, savedData, constraint) => {
    const currentRange =
      +data.settings.pair.dontTradePriceMax - +data.settings.pair.dontTradePriceMin;
    const savedRange =
      +savedData.settings.pair.dontTradePriceMax - +savedData.settings.pair.dontTradePriceMin;
    const rangeChange = Math.abs(currentRange - savedRange);
    const roundedRangeChange = +toRounding(rangeChange, +data.settings.decimals.price);
    const limitRangeValue = parseFloat(constraint);
    if (roundedRangeChange > limitRangeValue) return "New range width is different from previous!";
    return "";
  };

  static dtpRangePercentWidth: ConstraintsValidator<string> = (data, _savedData, constraint) => {
    const currentRange =
      +data.settings.pair.dontTradePriceMax - +data.settings.pair.dontTradePriceMin;
    const rangeChangePercent = Math.abs(currentRange) / +data.settings.pair.dontTradePriceMin;
    const roundedRangeChangePercent = +toRounding(rangeChangePercent, 4);
    const limitPercent = parseFloat(constraint);
    const isInfinityChangePercent = isInfinity(roundedRangeChangePercent);
    if (isInfinityChangePercent || roundedRangeChangePercent > limitPercent)
      return `New range width is greater than ${decimalToPercentString(limitPercent)}%!`;
    return "";
  };

  static virtualRangeDiffIncrease: ConstraintsValidator<string> = (
    data,
    _savedData,
    constraint
  ) => {
    const currentDiff = Math.abs(+data.settings.virtualRange.diff);
    const limitValue = +constraint;
    if (currentDiff >= limitValue) {
      return `New Diff is more than ${limitValue}!`;
    }
    return "";
  };

  static tradePerDayMinUSDMaxValue: ConstraintsValidator<string> = (
    data,
    _savedData,
    constraint
  ) => {
    const { tradePerDayMinUSD } = data.settings.volume;

    if (typeof tradePerDayMinUSD === "number" && tradePerDayMinUSD >= +constraint) {
      const formattedLimit = separation(constraint);
      return `New Volume $ is more than ${formattedLimit}$!`;
    }
    return "";
  };
}
