import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { InfoPanel } from "src/components/shared/InfoPanel";
import { TimeLine } from "src/components/shared/TimeLine";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SettingsToggleSwitch } from "../shared/SettingsToggleSwitch";
import * as styles from "./styles";

interface Props {
  party: string;
  showLoader: boolean;
}

const VERIF_ORDER = [
  { label: "10%", value: 0.1 },
  { label: "12.5%", value: 0.125 },
  { label: "15%", value: 0.15 },
  { label: "17.5%", value: 0.175 },
  { label: "20%", value: 0.2 },
  { label: "22.5%", value: 0.225 },
  { label: "25%", value: 0.25 },
  { label: "30%", value: 0.3 },
  { label: "35%", value: 0.35 },
  { label: "40%", value: 0.4 },
];

const VERIFY_INFO = `Verify:
Verification order - what part of trade volume is the verify order

Punish multiplier % - doesn't work

Punish max count -doesn't work

Punish period -  time window to represent verify triggering`;

export const VerifyOrderSettings = observer(({ showLoader, party }: Props) => {
  const state = useLateInitContext(CEXSettingsContext);

  const verifValue = {
    value: +state.data.settings.verifyOrder.percent ? +state.data.settings.verifyOrder.percent : "",
    label: +state.data.settings.verifyOrder.percent
      ? `${+state.data.settings.verifyOrder.percent * 100}%`
      : "",
  };

  useEffect(() => {
    state.setTradeVolume(+state.data.settings.volume.tradePerDayMinUSD);
  }, [state, state.data.settings.volume.tradePerDayMinUSD]);

  const volumePerHour = state.tradeVolume / 24;

  const volumePerPeriod = (volumePerHour / 60) * state.data.settings.period.value;

  const volumePerTrade = state.data.settings.trade.maxTrades
    ? volumePerPeriod / state.data.settings.trade.maxTrades
    : 0;

  const currentVerify = volumePerTrade * +state.data.settings.verifyOrder.percent;

  const items = [
    {
      label: "Volume per day $",
      value: String(state.tradeVolume),
      quote: "",
    },
    {
      label: "Volume per hour $",
      value: volumePerHour.toFixed(0),
      quote: "",
    },
    {
      label: "Volume per period $",
      value: volumePerPeriod.toFixed(0),
      quote: "",
    },
    {
      label: "Volume per trade $",
      value: volumePerTrade.toFixed(0),

      quote: "",
    },
    {
      label: "Current verefication order $",
      value: currentVerify.toFixed(0),
      quote: "",
      color: +currentVerify < +state.data.settings.pair.minExchangeAmount ? "rgb(239, 0, 0)" : "",
    },
  ];

  return (
    <styles.VerifySettings
      label="Verify Order"
      party={party}
      submitHandler={state.submitModulHandler("verifyOrder")}
      loader={showLoader}
      toggle={
        <SettingsToggleSwitch
          party={party}
          checked={state.data.settings.verifyOrder.on}
          onChange={state.getOnHandler("verifyOrder")}
        />
      }
      info={VERIFY_INFO}
      saved={state.savedModuls.verifyOrder}
      disabled={!state.data.settings.verifyOrder.on}
      style={{ gridArea: "verify" }}
    >
      <InfoPanel data={items} style={{ marginBottom: "15px" }} />

      <TimeLine
        period={state.verifyPeriod}
        endTime={state.endTime}
        points={state.decompVerify}
        labelNested="Verify per period"
        intervalStep={state.periodSteps}
        style={{ marginBottom: "15px" }}
      />

      <LabeledSelector
        label="Verify order"
        options={VERIF_ORDER}
        value={verifValue}
        onChange={state.onVerifyPercentSelected}
        // @ts-ignore
        getError={state.getError("settings.verifyOrder.percent")}
        errorHint={state.errors.settings?.verifyOrder?.percent}
      />
    </styles.VerifySettings>
  );
});
