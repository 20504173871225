import { getCEXNotes, setCEXNotes } from "src/api/bots/CEX/stats";
import { Notes, NotesProps } from "src/components/shared/Notes";

export interface CEXNotesProps
  extends Omit<NotesProps, "getNote" | "setNote" | "abilityName">,
    React.ComponentPropsWithoutRef<"div"> {}

export const CEXNotes = (props: CEXNotesProps) => (
  <Notes getNote={getCEXNotes} setNote={setCEXNotes} abilityName="cex-notes-edit" {...props} />
);
