import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface SettingsInfoTooltipContentProps extends ComponentPropsWithoutRef<"div"> {
  price?: string;
}

export const SettingsInfoTooltipContent = ({
  price,
  ...props
}: SettingsInfoTooltipContentProps) => (
  <styles.Container {...props}>
    <styles.Price>{price}</styles.Price>
  </styles.Container>
);
