import { Observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Headline3 } from "src/components/shared/Typography";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import { INewTickerGroup, TickerType } from "src/state/UserManager/EditParty/TickersStore";
import { ERRORS_MSGS } from "src/validation-schemas";
import { Wrapper } from "../shared";
import { HeaderWrapper } from "../style";
import { TickerElement } from "./TickerElement";
import * as styles from "./style";

const TYPE_OPTIONS: TickerType[] = ["base", "quote"];

const EMPTY_TICKER_ELEMENT = { name: "", exchange: "", ratio: "" };

export interface TickerFormProps extends ComponentPropsWithoutRef<"form"> {}

export const TickerForm = ({ ...props }: TickerFormProps) => {
  const tickerTypes = useMemo(() => getSelectorList(TYPE_OPTIONS), []);

  const { control, watch } = useFormContext<INewTickerGroup>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tickers",
  });

  const watchFieldArray = watch("tickers");

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));

  return (
    <styles.FormContainer onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} {...props}>
      <Wrapper>
        <Controller
          control={control}
          name="name"
          rules={{ required: ERRORS_MSGS.isRequired }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <LabeledInput
              label="Name"
              value={value}
              onChange={(e) => onChange(getChangeEventValue(e))}
              errorHint={errors.name?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="type"
          rules={{ required: ERRORS_MSGS.isRequired }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <Observer>
              {() => (
                <LabeledSelector
                  menuPosition="fixed"
                  label="Type"
                  options={tickerTypes}
                  onChange={(data) => onChange(data?.value)}
                  value={stringToSelectorValue(value)}
                  errorHint={errors.type?.message}
                />
              )}
            </Observer>
          )}
        />
      </Wrapper>

      <HeaderWrapper>
        <Headline3>Tickers</Headline3>
        <AddButton onClick={() => append(EMPTY_TICKER_ELEMENT)} />
      </HeaderWrapper>

      <styles.TickersHeader>
        <styles.HeaderTitle>Exchange</styles.HeaderTitle>
        <styles.HeaderTitle>Ticker</styles.HeaderTitle>
        <styles.HeaderTitle>Ratio</styles.HeaderTitle>
      </styles.TickersHeader>

      <styles.TickersContent>
        {controlledFields.map((field, index) => (
          <TickerElement key={field.id} index={index} remove={remove} />
        ))}
      </styles.TickersContent>
    </styles.FormContainer>
  );
};
