import { useCallback, useMemo, useState } from "react";
import { CurrencyData, FundingsExchange } from "src/api/bots/CEX/dashboard";
import { formatPNLDelta } from "src/components/shared/PNLV2/shared/utilts";
import { isEmptyObject } from "src/helpers/obj";
import { entries } from "src/helpers/utils";

const fundingToText = (token: string, value: string) => {
  const amount = formatPNLDelta(value);
  return `    ${token}: ${amount}`;
};

const fundingsToText = (fundings: CurrencyData | undefined) => {
  if (!fundings) return "";

  const transactionsText = entries(fundings)
    .map(([token, value]) => fundingToText(token, value))
    .join("\n");

  return transactionsText;
};

const getFundingsExchangeText = (fundings: FundingsExchange) => {
  if (!fundings || isEmptyObject(fundings)) return "No fundings";

  return Object.keys(fundings)
    .map((exchange) => {
      const fundingsText = fundingsToText(fundings[exchange]);

      return `${exchange}:\n${fundingsText}`;
    })
    .join("\n");
};

export const useFundingsExchangeInfo = (fundings: FundingsExchange) => {
  const text = useMemo(() => getFundingsExchangeText(fundings), [fundings]);
  return text;
};

interface UsePriceProps {
  priceGrowth: string;
  priceNet: string;
  useFee: boolean;
}

export const usePrice = ({ priceGrowth, priceNet, useFee }: UsePriceProps) =>
  useMemo(() => {
    if (useFee) return priceNet;

    return priceGrowth;
  }, [priceGrowth, priceNet, useFee]);

export const useFeeChecked = (initialValue: boolean) => {
  const [useFee, setUseFee] = useState(initialValue);

  const onFeeChecked = useCallback(() => {
    setUseFee(!useFee);
  }, [useFee]);

  return [useFee, onFeeChecked] as const;
};
