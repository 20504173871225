import { observer } from "mobx-react-lite";
import { useTableTotalizer } from "src/context/CEX/Dashboard/TableTotalizer";
import {
  CurrencyFeeData,
  CurrencyFeeDataKeys,
} from "src/state/CEX/CEXDashboard/v2/widgets/CurrencyFeeV2Store";
import { FiatCell, FiatCellProps } from "../../../../shared/StatsTable/Cells";
import { FeeTotalMap } from "../utils/totals";

export interface CurrencyFeeFooterProps extends Omit<FiatCellProps, "value"> {
  columnId: CurrencyFeeDataKeys;
}

export const CurrencyFeeFooter = observer(({ columnId, ...props }: CurrencyFeeFooterProps) => {
  const totalizer = useTableTotalizer<CurrencyFeeData, FeeTotalMap>();

  const total = totalizer.columnTotal(columnId) ?? 0;

  return <FiatCell value={total} {...props} />;
});
