import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 20px;
`;

export const Header = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
`;

export const WrapperForCheckBoxes = styled.div`
  display: flex;
  justify-content: flex-end;

  min-width: 230px;
`;
