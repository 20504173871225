import { checkAbility } from "src/helpers/checkParty";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { useTogglePrimaryColor } from "src/hooks/useTogglePrimaryColor";
import { useAppState } from "src/state";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { HeaderProps } from "..";
import { MenuItem } from "./MenuItem";
import { PermissionMenuItem } from "./PermissionMenuItem";
import { SubMenu } from "./SubMenu";
import * as styles from "./style";

export interface ISideBarItem {
  abilityName?: string;
  to: string;
  label: string;
}

const SIDEBAR_ITEMS: ISideBarItem[] = [
  { abilityName: "party-view", to: "/market-check", label: "Market check" },
  { abilityName: "auth-view", to: "/Access", label: "Access" },
  { abilityName: "auth-user-group-view", to: "/Teams", label: "Manage groups" },
  { abilityName: "accounting-view", to: "/Accounting", label: "Accounting" },
];

const ROAD_MAP_LINK =
  "https://gotbit-mm-dev.notion.site/24ec4c3792ac4d92af2fae59ee1f1573?v=cb0ddc4971304d158988a4a73f207766";

const useCreateMenu = () => {
  const { userState } = useAppState();
  const showCEXCreate = checkAbility(userState.abilities, "bot-manager");
  const showDEXV2Create = checkAbility(userState.abilities, DEXV2Ability.BotManage);

  const CEXCreate = showCEXCreate && (
    <MenuItem to="/BotCreating" key="CEX">
      CEX
    </MenuItem>
  );

  const DEXCreate = showDEXV2Create && (
    <MenuItem to="/DEXCreating" key="DEXV2">
      DEX
    </MenuItem>
  );

  const createItems = filterBoolean([CEXCreate, DEXCreate]);

  const createMenu = createItems.length > 0 && <SubMenu title="Create bot">{createItems}</SubMenu>;

  return createMenu;
};

export interface MenuProps extends HeaderProps {
  onLogOut: () => void;
  onUpdAbil: () => Promise<void>;
}

export const Menu = ({ themeToggler, onLogOut, onUpdAbil }: MenuProps) => {
  const CreateMenu = useCreateMenu();
  const togglePrimaryColor = useTogglePrimaryColor();

  return (
    <styles.Container>
      <styles.Navigation>
        <styles.MenuList>
          <MenuItem to="/AllBots">All bots</MenuItem>
          {CreateMenu}
          {SIDEBAR_ITEMS.map((item, index) => {
            const key = `menu_item${index}`;
            return <PermissionMenuItem key={key} item={item} />;
          })}
        </styles.MenuList>
      </styles.Navigation>

      <styles.Separator />

      <styles.MenuList>
        <MenuItem onClick={themeToggler}>Switch theme</MenuItem>
        <MenuItem onClick={togglePrimaryColor}>Switch main color</MenuItem>
        <styles.Update onClick={onUpdAbil}>Update abilities</styles.Update>
        <styles.Logout onClick={onLogOut}>Logout</styles.Logout>
      </styles.MenuList>

      <styles.Separator />

      <styles.MenuList>
        <MenuItem>
          <styles.MenuLink href={ROAD_MAP_LINK} target="_blank" rel="noreferrer">
            Roadmap
          </styles.MenuLink>
        </MenuItem>
      </styles.MenuList>
    </styles.Container>
  );
};
