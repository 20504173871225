import { CardSurface, ScrollSize } from "src/components/shared/shared";
import { SwitchMenu } from "src/components/shared/SwitchMenu";
import { Label } from "src/components/shared/SwitchMenu/MenuItem/style";
import { Tabs } from "src/components/shared/Tabs";
import { Content, Panel } from "src/components/shared/Tabs/style";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  grid-area: tabs;

  flex: 1;
`;

export const ExchangeTabs = styled(Tabs)`
  ${Panel} {
    height: 35px;

    display: flex;

    margin-bottom: 0;

    overflow-y: auto;

    ${ScrollSize}

    ${SwitchMenu} {
      min-width: 400px;
    }

    ${Label} {
      font-size: ${({ theme }) => theme.fontSizes["10px"]};

      @media screen and (max-width: 450px) {
        font-size: ${({ theme }) => theme.fontSizes["12px"]};
      }

      @media screen and (max-width: 450px) {
        font-size: ${({ theme }) => theme.fontSizes["10px"]};
      }
    }
  }

  ${Content} {
    position: relative;

    height: 0px;
    flex: 1 1 auto;

    padding: 10px 10px 10px;

    ${CardSurface}
  }
`;
