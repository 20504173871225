import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { AllDepthContext } from "src/context/CEX/Dashboard/v2/AllDepth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Card, CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { DashboardV2Widget, getWidgetTitle } from "../../shared/constants";
import { DepthChart, DepthChartProps } from "./DepthChart";
import * as styles from "./style";

const ALL_DEPTH_TITLE = "All depth";
const OUR_DEPTH_TITLE = "Our depth";

export interface AllDepthChartProps extends Omit<CardProps, "title" | "loading"> {}

export const AllDepthChart = observer((props: AllDepthChartProps) => {
  const { allDepth, ourDepth, loading } = useLateInitContext(AllDepthContext.Context);

  const baseChartProps: Partial<DepthChartProps> = useMemo(
    () => ({
      gap: 2,
      levelWidth: 30,
    }),
    []
  );

  const allDepthProps: Partial<DepthChartProps> = useMemo(
    () => ({
      ...baseChartProps,
      ticks: { padding: 25 },
    }),
    [baseChartProps]
  );

  const ourDepthProps: Partial<DepthChartProps> = useMemo(
    () => ({
      ...baseChartProps,
      ticks: { position: "top", padding: 20, level: { show: false } },
    }),
    [baseChartProps]
  );

  return (
    <Card title={getWidgetTitle(DashboardV2Widget.AllDepth)} loading={loading} {...props}>
      <styles.DepthContainer>
        <styles.ChartWrapper>
          <DepthChart data={allDepth} {...allDepthProps} />
        </styles.ChartWrapper>
        <styles.AllDepthTitle>{ALL_DEPTH_TITLE}</styles.AllDepthTitle>
        <styles.ChartWrapper>
          <DepthChart data={ourDepth} {...ourDepthProps} />
        </styles.ChartWrapper>
        <styles.OurDepthTitle>{OUR_DEPTH_TITLE}</styles.OurDepthTitle>
      </styles.DepthContainer>
    </Card>
  );
});
