import styled from "styled-components";
import { SettingsPanel } from "../../shared/SettingsPanel";
import { Content } from "../../shared/SettingsPanel/style";
import { VolumeInfo } from "./VolumeInfo";

export const StyledPanel = styled(SettingsPanel)`
  ${Content} {
    height: 100%;
  }
`;

export const StyledVolumeInfo = styled(VolumeInfo)`
  height: auto;
  margin-top: auto;
`;
