import { AuxiliaryModuleText } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { Title } from "../style";
import * as styles from "./style";

export interface ModuleHeaderProps {
  title?: string;
  remove: () => void;
  moduleKey?: string;
}

export const ModuleHeader = ({ remove, title, moduleKey }: ModuleHeaderProps) => (
  <styles.Header>
    <DeleteButton onClick={remove} />

    {title ? (
      <Title>
        {title}
        {moduleKey ? <AuxiliaryModuleText> / {moduleKey}</AuxiliaryModuleText> : null}:
      </Title>
    ) : null}
  </styles.Header>
);
