import styled from "styled-components";

export const Content = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  & > div:last-of-type {
    margin: 0;
  }
  @media (max-width: 900px) {
    width: 100%;
    padding-left: 0px;
  }
  @media (max-width: 850px) {
    & > div > div:last-of-type {
      margin-left: 12px;
    }
  }
  @media (max-width: 600px) {
    & > div > div:last-of-type {
      margin: 0;
    }
  }
`;
