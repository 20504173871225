import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { PanelContainer } from "src/components/shared/shared";
import { checkParty } from "src/helpers/checkParty";
import { Abilities } from "src/modules/abilities";
import styled from "styled-components";

export interface GridProps {
  abilities: Abilities;
  party: string;
}

export const DesktopContainer = styled.div`
  height: 100%;
  max-width: 1700px;

  display: grid;
  grid-template-rows: 30px 417px 209px 204px;

  grid-template-columns:
    minmax(250px, 540px) minmax(300px, 560px)
    minmax(250px, 300px) minmax(250px, 300px);

  grid-template-areas: ${({ abilities, party }: GridProps) => {
    const checkTrade = checkParty(abilities, "cex-exchange-trade", party);

    const checkSettings = checkParty(abilities, "cex-settings-view", party);

    // clients view
    if (!checkTrade && !checkSettings)
      return `
    "trading-view             trading-view       pair-selector          trade-history-title "
    "trading-view             trading-view       order-book             trade-history       "
    "trading-view             trading-view       order-book             trade-history       "
    "finances                 finances           tabs                   tabs                ";`;

    if (!checkTrade && checkSettings)
      return `
    "trading-view             trading-view       pair-selector          trade-history-title "
    "trading-view             trading-view       order-book             trade-history       "
    "finances                 finances           tabs                   tabs                "
    "form-exchange            form-exchange      form-exchange          form-exchange       ";`;

    if (checkTrade && !checkSettings)
      return `
    "trading-view             trading-view       pair-selector          trade-history-title "
    "trading-view             trading-view       order-book             trade-history       "
    "finances                 tabs               buy-sell-wrap          buy-sell-wrap       "
    "finances                 tabs               buy-sell-wrap          buy-sell-wrap       ";`;

    // all permissions
    return `
    "trading-view              trading-view       pair-selector          trade-history-title"
    "trading-view              trading-view       order-book             trade-history      "
    "tabs                      finances           buy-sell-wrap          buy-sell-wrap      "
    "form-exchange             form-exchange      buy-sell-wrap          buy-sell-wrap      ";`;
  }};

  grid-gap: 5px;

  margin-top: 0px !important;

  @media screen and (max-width: 1230px) {
    grid-template-rows: 30px 185px 163px minmax(145px, 190px) 234px;

    grid-template-columns:
      minmax(170px, 0.5fr) minmax(170px, 0.5fr) minmax(240px, 1fr)
      minmax(230px, 0.6fr);

    grid-template-areas: ${({ abilities, party }: GridProps) => {
      const checkTrade = checkParty(abilities, "cex-exchange-trade", party);

      const checkSettings = checkParty(abilities, "cex-settings-view", party);

      // clients view
      if (!checkTrade && !checkSettings)
        return `
      "title            title            title             pair-selector"
      "trading-view     trading-view     trading-view      order-book"
      "trading-view     trading-view     trading-view      order-book"
      "finances         finances         tabs              tabs"
      "finances         finances         tabs              tabs";
      `;

      // external view
      if (!checkTrade && checkSettings)
        return `
      "title            title            title             pair-selector"
      "trading-view     trading-view     trading-view      order-book"
      "trading-view     trading-view     trading-view      order-book"
      "finances         finances         tabs              tabs"
      "form-exchange    form-exchange    tabs              tabs";
      `;

      if (checkTrade && !checkSettings)
        return `
      "title            title            trading-view      pair-selector"
      "finances         finances         trading-view      order-book"
      "finances         finances         trading-view      order-book"
      "tabs             tabs             tabs              buy-sell-wrap"
      "tabs             tabs             tabs              buy-sell-wrap";
      `;

      // all permissions view
      return `
      "title            title            title             pair-selector"
      "trading-view     trading-view     trading-view      order-book"
      "trading-view     trading-view     trading-view      order-book"
      "form-exchange    finances         finances          buy-sell-wrap"
      "form-exchange    tabs             tabs              buy-sell-wrap";`;
    }};
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  grid-area: tabs;

  & > div {
    position: relative;
  }
`;

export const OrderBookContainer = styled.div`
  grid-area: order-book;
  background: none;
`;

export const TradingHistoryContainer = styled(PanelContainer)`
  position: relative;

  grid-area: trade-history;

  padding: 0px 5px 5px;
`;

export const TradeHistoryTitleContainer = styled.div`
  grid-area: trade-history-title;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`;

export const PairSwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-area: pair-selector;
`;

export const PNLContainer = styled.div`
  width: 100%;
  /* height: 30px; */

  display: flex;
`;

export const PNLContainerFallback = styled(CrashPanel)`
  flex: 1;
`;

export const TradePanelFallback = styled(CrashPanel)`
  grid-area: tabs;
`;

export const SettingsFallback = styled(CrashPanel)`
  grid-area: form-exchange;
`;
