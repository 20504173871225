import { ComponentProps } from "react";
import { Doughnut as DoughnutChart } from "react-chartjs-2";

type DoughnutChartProps<TData, TLabel> = ComponentProps<typeof DoughnutChart<TData, TLabel>>;

export interface DoughnutProps<TData, TLabel>
  extends Omit<DoughnutChartProps<TData, TLabel>, "ref"> {
  chartRef?: DoughnutChartProps<TData, TLabel>["ref"];
}

export const Doughnut = <TData = number[], TLabel = unknown>({
  chartRef,
  ...props
}: DoughnutProps<TData, TLabel>) => <DoughnutChart {...props} ref={chartRef} />;
