import styled from "styled-components";
import { CellText } from "../../../shared/TableRows/style";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
`;

export const LimitText = styled(CellText)`
  width: auto;
  min-width: 2ch;
`;
