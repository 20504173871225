import { makeAutoObservable } from "mobx";
import { GetExchangesResponse, getExchanges } from "src/api/bots/CEX/dashboard";
import { roundToTwoDecimals } from "src/helpers/rounding";
import { Mapper, entries } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { ExchangePieV2Type } from "../providers/ExchangesV2DataProvider";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

interface IExchangesStoreParams extends IBaseDashboardV2StoreParams {}

export type ExchangesBalancesData = Record<string, number>;

type ExchangesData = {
  freeUsd: ExchangesBalancesData;
  lockedUsd: ExchangesBalancesData;
  totalTokens: ExchangesBalancesData;
  totalUsd: ExchangesBalancesData;
};

const INITIAL_DATA: ExchangesData = {
  freeUsd: {},
  lockedUsd: {},
  totalTokens: {},
  totalUsd: {},
};

const exchangeResponseToData: Mapper<GetExchangesResponse["free_usd"], ExchangesBalancesData> = (
  data
) => {
  const exchangeEntries = entries(data).map(([exchange, balance]) => {
    const roundedBalance = roundToTwoDecimals(+balance);
    return [exchange, roundedBalance];
  });

  return Object.fromEntries(exchangeEntries);
};

const exchangesResponseToData: Mapper<GetExchangesResponse, ExchangesData> = ({
  free_usd,
  locked_usd,
  total_tokens,
  total_usd,
}) => ({
  freeUsd: exchangeResponseToData(free_usd),
  lockedUsd: exchangeResponseToData(locked_usd),
  totalUsd: exchangeResponseToData(total_usd),
  totalTokens: exchangeResponseToData(total_tokens),
});

export class ExchangesV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: ExchangesData = INITIAL_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IExchangesStoreParams) {
    makeAutoObservable(this);

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    this._stateProvider = stateProvider;
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: ExchangesData) => {
    this._data = data;
  };

  get freeUsd() {
    return this._data.freeUsd;
  }

  get totalUsd() {
    return this._data.totalUsd;
  }

  get totalTokens() {
    return this._data.totalTokens;
  }

  get lockedUsd() {
    return this._data.lockedUsd;
  }

  get pieData() {
    return {
      [ExchangePieV2Type.FreeUsd]: this.freeUsd,
      [ExchangePieV2Type.LockedUsd]: this.lockedUsd,
      [ExchangePieV2Type.TotalUsd]: this.totalUsd,
      [ExchangePieV2Type.TotalTokens]: this.totalTokens,
    };
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;
    if (!requestParams) return;

    const { party, queryParams } = requestParams;

    this._setData(INITIAL_DATA);
    try {
      const { data, isError } = await getExchanges(party, queryParams);

      if (!isError) {
        const ExchangesData = exchangesResponseToData(data);
        this._setData(ExchangesData);
      }
    } catch (error) {
      this._setData(INITIAL_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
