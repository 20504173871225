import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ModalPanel, ModalProps } from "src/components/shared/ModalPanel";
import { WithdrawGasProvider } from "src/context/DEXV2/DEXV2Stats/GasWallets/WIthdrawGas";
import { WithdrawGasParameters } from "src/state/DEXV2/DEXV2Stats/GasWallets/WithdrawGasStore";
import { WithdrawGasModalContent } from "./WithdrawGasModalContent";

export interface ModalWithdrawGasProps
  extends ModalProps,
    Omit<WithdrawGasParameters, "setLoading" | "onClose"> {}

export const ModalWithdrawGas = observer(
  ({ show, close, wallets, withdrawer, deployerId }: ModalWithdrawGasProps) => {
    const [loading, setLoading] = useState(false);

    const withdrawProps: WithdrawGasParameters = {
      onClose: close,
      wallets,
      setLoading,
      withdrawer,
      deployerId,
    };

    return (
      <ModalPanel label="Withdraw Gas" show={show} close={close} loading={loading} zIndex={1000}>
        <WithdrawGasProvider {...withdrawProps}>
          <WithdrawGasModalContent />
        </WithdrawGasProvider>
      </ModalPanel>
    );
  }
);
