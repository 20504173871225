import { createContextProvider } from "src/context/utils/createContextProvider";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import ExpertSystemStore from "src/state/expertSystem";
import SelectorProvider from "src/state/expertSystem/modules/SelectorProvider";
import StrategyListStore from "src/state/expertSystem/strategies";
import CreateStrategyStore from "src/state/expertSystem/strategies/CreateStrategyStore";

const CreateExpertSystemStore = () => useLocalStore(ExpertSystemStore);

const { Context: ExpertSystemContext, Provider: ExpertSystemProvider } =
  createContextProvider<ExpertSystemStore>({
    stateCreator: CreateExpertSystemStore,
  });

export { ExpertSystemContext, ExpertSystemProvider };

export const DynamicSelectionContext = createLateInitContext<SelectorProvider>();

const CreatorStrategyListStore = () =>
  useLocalStore(StrategyListStore, useLateInitContext(ExpertSystemContext));

const { Context: StrategyListContext, Provider: StrategyListProvider } =
  createContextProvider<StrategyListStore>({
    stateCreator: CreatorStrategyListStore,
  });

export { StrategyListContext, StrategyListProvider };

const CreatorCreateStrategyStore = () =>
  useLocalStore(CreateStrategyStore, useLateInitContext(StrategyListContext));

const { Context: CreateStrategyContext, Provider: CreateStrategyProvider } =
  createContextProvider<CreateStrategyStore>({
    stateCreator: CreatorCreateStrategyStore,
  });

export { CreateStrategyContext, CreateStrategyProvider };
