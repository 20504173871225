import { observer } from "mobx-react-lite";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { RenameKeys } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { InfoPanelProps } from "../../shared/InfoPanel";
import { FormContent, WalletsListProps } from "../../shared/style";
import * as styles from "./style";

export interface ReceiversInfoProps
  extends Omit<InfoPanelProps, "label" | "children">,
    RenameKeys<WalletsListProps, { fixedHeight: "fixedHashesHeight" }> {}

export const ReceiversInfo = observer(({ fixedHashesHeight, ...props }: ReceiversInfoProps) => {
  const { receivers } = useLateInitContext(DEXV2SettingsContext);

  return (
    <styles.InfoPanel label="Receivers" {...props}>
      <FormContent>
        <styles.HashesList hashes={receivers} fixedHeight={fixedHashesHeight} showDelete={false} />
      </FormContent>
    </styles.InfoPanel>
  );
});
