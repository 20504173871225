import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { Loader } from "src/components/shared/Loader";
import { Pagination } from "src/components/shared/Pagination";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { WalletsContext } from "../../../shared/context/Stats";
import * as styles from "./style";

interface HashesListProps {
  title: string;
  icon: JSX.Element;
}

export const HashesList = observer(({ title, icon }: HashesListProps) => {
  const state = useLateInitContext(WalletsContext);

  const [currentPage, setCurrentPage] = useState(0);

  return (
    <styles.HashesContainer
      title={title}
      icon={icon}
      button={
        <styles.HashesButtonsContainer>
          <ReloadButton size="s" onClick={() => state.getHashes(currentPage, 10)} />
          <Button variant="dashed" size="s" onClick={state.getAllHashes}>
            Download
          </Button>
        </styles.HashesButtonsContainer>
      }
    >
      <styles.Content>
        {state.hashes && state.hashes.Data
          ? state.hashes.Data.map((el) =>
              el !== "" ? (
                <styles.HashRow
                  href={state.exchangeMeta ? state.exchangeMeta.hashLink + el : "#"}
                  key={el}
                >
                  <span>{el}</span>
                </styles.HashRow>
              ) : null
            )
          : null}
        <Loader show={state.loadHashes} />
      </styles.Content>
      <Pagination
        onPageChange={setCurrentPage}
        // items={hashes?.Data}
        pageCount={state.hashes?.Pages}
        getItems={state.getHashes}
        itemsPerPage={10}
      />
    </styles.HashesContainer>
  );
});
