import { makeAutoObservable } from "mobx";
import { fetchTradeHistory } from "src/api/bots/CEX/exchange";
import { stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { toRounding } from "src/helpers/rounding";
import { Trade } from "src/modules/exchange/trade";
import TradesDataStorageStore from "../TradesDataStorageStore";

class RestAPIProvider {
  dataStorage: TradesDataStorageStore;

  private _data: Trade[] = [];

  private _isLoading = false;

  constructor(state: TradesDataStorageStore) {
    this.dataStorage = state;

    makeAutoObservable(this);
  }

  get data() {
    return this._data.map((trade) => this.handleTrade(trade)).reverse();
  }

  get originData() {
    return this._data.reverse();
  }

  get isLoading() {
    return this._isLoading;
  }

  private _setData = (data: Trade[]) => {
    this._data = data;
  };

  private _setLoading = (loading: boolean) => {
    this._isLoading = loading;
  };

  fetchData = async (pair: string, exchange: string) => {
    await this._fetchTradeHistory(pair, exchange);
  };

  handleTrade = ({ price, amount, time, ...other }: Trade) => ({
    ...other,
    price: toRounding(parseFloat(price), this.dataStorage.pricePrecision),
    amount: toRounding(parseFloat(amount), this.dataStorage.amountPrecision),
    time: unixToDateFormat(stringDateToUnix(time), "FullTime"),
  });

  private _fetchTradeHistory = async (pair: string, exchange: string) => {
    this._setLoading(true);

    try {
      const { data, isError } = await fetchTradeHistory({
        pair,
        exchange,
        account_uuid: this.dataStorage.infoAcc,
      });

      if (!isError) {
        this._setData(data);
      } else {
        this._resetData();
      }
    } catch {
      this._resetData();
    } finally {
      this._setLoading(false);
    }
  };

  private _resetData = () => {
    this._data = [];
  };
}

export default RestAPIProvider;
