import { Headline3 } from "src/components/shared/Typography";
import Icons from "src/icons/Icons";
import { useTheme } from "styled-components";
import * as styles from "./style";

export const FinishState = () => {
  const theme = useTheme();
  return (
    <>
      <styles.IconWrapper>
        <Icons.Success fill={theme.primaryColor} />
      </styles.IconWrapper>

      <Headline3 style={{ width: "200px", textAlign: "center" }}>
        Vault contracts has been <styles.ScsText>successfully</styles.ScsText> deployed
      </Headline3>

      <styles.DefaultText>The profile creation completed</styles.DefaultText>
    </>
  );
};
