import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { Headline2 } from "src/components/shared/Typography";
import { MultiGridStateContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useFullscreen } from "src/hooks/useFullscreen";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Wrapper } from "../../shared";
import { StateHeader } from "./StateHeader";
import { StateTable } from "./StateTable";
import * as styles from "./style";

export const GridState = observer(() => {
  const state = useLateInitContext(MultiGridStateContext);

  const { isFullscreen, toggleFullscreen } = useFullscreen();

  useEffect(() => {
    state.loadData();
  }, [state]);

  const fullscreenStyleConfig: React.CSSProperties = {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 999,
  };

  return (
    <styles.Container
      isFullscreen={isFullscreen}
      style={isFullscreen ? fullscreenStyleConfig : undefined}
    >
      <Wrapper>
        <Headline2>State</Headline2>
        <ReloadButton onClick={state.mainState.updAllData} />
      </Wrapper>
      <StateHeader isFullscreen={isFullscreen} toggleFullscreen={toggleFullscreen} />
      <StateTable />
    </styles.Container>
  );
});
