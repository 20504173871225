import { observer } from "mobx-react-lite";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { RefButtonWrapper } from "src/components/shared/Buttons/v2/variants/RefButtonWrapper";
import { ModalPanelV2 } from "src/components/shared/ModalPanelV2";
import { StrategyListContext } from "src/context/CEX/ExpertSystem";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ExtendForm, ExtendFormProps } from "./ExtendForm";

export interface ExtendModalProps extends ExtendFormProps {}

export const ExtendModal = observer(({ uuid }: ExtendModalProps) => {
  const { extendLoading } = useLateInitContext(StrategyListContext);

  return (
    <ModalPanelV2
      label="Extend"
      trigger={
        <RefButtonWrapper>
          <Button elevated size="s">
            Extend
          </Button>
        </RefButtonWrapper>
      }
      loading={extendLoading}
    >
      <ExtendForm uuid={uuid} />
    </ModalPanelV2>
  );
});
