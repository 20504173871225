import { ComponentPropsWithoutRef } from "react";
import { PNLValue } from "./PNLValue";
import * as styles from "./style";

export interface InfoPanelSectionProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
  value: number | null;
}

export const InfoPanelSection = ({ title, value, ...props }: InfoPanelSectionProps) => (
  <styles.Container {...props}>
    <styles.Title>{title}</styles.Title>
    <PNLValue>{value}</PNLValue>
  </styles.Container>
);
