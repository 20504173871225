import { observer } from "mobx-react-lite";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SettingsPanelProps } from "../../shared/SettingsPanel";
import { FormRow } from "../../shared/style";
import * as styles from "./style";
import { Divider } from "../../shared/SettingsFormContent/style";

export interface PeriodProps extends Omit<SettingsPanelProps, "label" | "children"> {}

export const TradePanel = observer(({ ...props }: PeriodProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  return (
    <styles.SettingsPanel label="Trade Settings" {...props}>
      <styles.FormContent>
        <FormRow>
          <LabeledInput
            type="number"
            step="0.1"
            label="Slippage"
            {...state.getInputProps("limit_data.slippage")}
          />

          <LabeledInput
            type="number"
            step="0.1"
            label="Gas Max"
            {...state.getInputProps("limit_data.gas_price_limit")}
          />
        </FormRow>

        <LabeledPercentRangeInput
          step="0.1"
          label="Gas Mult"
          {...state.getInputProps("limit_data.gas_mult")}
        />

        <Divider $orientation="column" />

        <styles.StyledLimitInfo />
      </styles.FormContent>
    </styles.SettingsPanel>
  );
});
