import { observer } from "mobx-react-lite";
import { ChipCorrelationContext } from "src/context/CEX/Dashboard/v2/ChipCorrelation";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { DashboardV2Widget, getWidgetInfo, getWidgetTitle } from "../shared/constants";
import { ChipCorrelationTable } from "./ChipCorrelationTable";
import * as styles from "./style";

export interface ChipCorrelationProps extends Omit<CardProps, "title" | "info"> {}

export const ChipCorrelation = observer((props: ChipCorrelationProps) => {
  const { data, loading } = useLateInitContext(ChipCorrelationContext.Context);

  return (
    <styles.StyledCard
      title={getWidgetTitle(DashboardV2Widget.ChipCorrelation)}
      loading={loading}
      info={getWidgetInfo(DashboardV2Widget.ChipCorrelation)}
      {...props}
    >
      <ChipCorrelationTable {...data} />
    </styles.StyledCard>
  );
});

export interface ChipCorrelationWidgetProps extends ChipCorrelationProps {}

export const ChipCorrelationWidget = (props: ChipCorrelationWidgetProps) => (
  <ChipCorrelationContext.Provider>
    <ChipCorrelation {...props} />
  </ChipCorrelationContext.Provider>
);
