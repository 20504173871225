import { observer } from "mobx-react-lite";
import { useTableTotalizer } from "src/context/CEX/Dashboard/TableTotalizer";
import { formatFiat } from "src/helpers/string";
import { PairsData, PairsDataKeys } from "src/state/CEX/CEXDashboard/v2/widgets/PairsV2Store";
import { PairsTotalMap } from "../utils/totals";

export interface PairsFooterProps {
  columnId: PairsDataKeys;
}

export const PairsFooter = observer(({ columnId }: PairsFooterProps) => {
  const totalizer = useTableTotalizer<PairsData, PairsTotalMap>();

  const total = totalizer.columnTotal(columnId) ?? 0;

  const formattedTotal = formatFiat(total);

  return <>{formattedTotal}</>;
});
