import { PNLV2ItemContainer } from "src/components/shared/PNLV2/shared/Item/PNLV2Item/style";
import styled from "styled-components";
import { StyledPNLV2Value } from "../shared/PNLV2Value/style";

export const PNLUSDItemContainer = styled(PNLV2ItemContainer)``;

export const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

export const ValueDelimiter = styled(StyledPNLV2Value)``;
