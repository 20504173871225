import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  min-width: max-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AssetsContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 4px;
  justify-content: space-between;
`;

export const AssetLabel = styled.span`
  font-weight: bold;
`;

export const AssetValue = styled.span`
  justify-self: flex-end;
`;

export const AssetPercent = styled.span`
  color: ${({ theme }) => theme.labelColor};
`;

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, min-content);
  grid-template-rows: repeat(4, auto);
  grid-row-gap: 3px;
  grid-column-gap: 12px;
  align-items: center;
`;

export const TableHeader = styled.div`
  font-weight: bold;
  text-align: right;
`;

export const Month = styled.div`
  text-align: left;
  font-weight: bold;
`;

export const Delta = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.labelColor};
`;
