import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled(PanelContainer)`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* padding: 9px 16px 16px; */
  padding: 0px 5px 5px;
`;

export const Header = styled.div`
  width: 100%;

  display: flex;

  padding: 5px;
`;

export const HeaderTitle = styled.span`
  width: 100%;

  color: ${({ theme }) => theme.inputTextColor};
  font-size: ${({ theme }) => theme.fontSizes["15px"]};
  font-weight: ${({ theme }) => theme.fontWeights["700"]};
`;

export const OrderBookFallback = styled(CrashPanel)``;

export const LeftRange = styled.div`
  position: absolute;
  top: 32px;
  left: -5px;
  bottom: -1px;

  width: 13px;

  z-index: 1;

  border: 1px solid;
  border-color: ${({ theme }) => theme.primaryColor};
  border-right: none;
`;

export const RightRange = styled(LeftRange)`
  left: inherit;
  right: -5px;

  width: 8px;

  border-left: none;
`;
