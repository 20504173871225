import { ComponentPropsWithoutRef } from "react";
import { formatFiat } from "src/helpers/string";
import * as styles from "./style";

export interface AumCellProps extends ComponentPropsWithoutRef<"span"> {
  children: string;
}

export const AumCell = ({ children: value, ...props }: AumCellProps) => {
  const formattedValue = formatFiat(value);
  return (
    <styles.Container {...props}>
      <styles.Label>AUM</styles.Label>
      <styles.Balance>{formattedValue}</styles.Balance>
    </styles.Container>
  );
};
