import { forwardRef, memo } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { Sidebar } from "src/components/shared/Sidebar";
import HeaderIcons from "src/icons/HeaderIcons";
import { TerminalRequestMode } from "src/state/CEX/CEXExchange/shared/TerminalSettingsStore";
import { SettingsPanel } from "./SettingsPanel";
import * as styles from "./style";

export const REQUEST_MODES: { value: TerminalRequestMode; label: string }[] = [
  { label: "Rest", value: "FETCH" },
  { label: "WS", value: "WS" },
];
const SettingsBtn = forwardRef<HTMLButtonElement>(({ ...props }, ref) => (
  <IconButton ownRef={ref} {...props}>
    <styles.IconWrap>{HeaderIcons.settings()}</styles.IconWrap>
  </IconButton>
));

export const TerminalSettings = memo(() => (
  <Sidebar
    label="Terminal settings"
    popupProps={{
      trigger: <SettingsBtn />,
    }}
  >
    <SettingsPanel />
  </Sidebar>
));
