import { observer } from "mobx-react-lite";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { getSelectorList } from "src/helpers/forms/selectors";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { OrderForm } from "./OrderForm";
import * as styles from "./style";

export interface BuySellFormsProps {
  showTradeHistory: boolean;
}

const MENU_ITEMS = ["BUY", "SELL"];

export const BuySellForms = observer(({ showTradeHistory }: BuySellFormsProps) => {
  const mainState = useLateInitContext(ExchangeContext);

  return (
    <styles.Container>
      {showTradeHistory ? (
        <styles.TabsModules
          menuItems={getSelectorList(MENU_ITEMS)}
          defaultKey="BUY"
          setFunc={mainState.buySellState.setFuncCurrentKey}
        >
          <OrderForm key="BUY" side="BUY" />
          <OrderForm key="SELL" side="SELL" />
        </styles.TabsModules>
      ) : (
        <styles.FormsWrapper>
          <OrderForm side="BUY" />
          <OrderForm side="SELL" />
        </styles.FormsWrapper>
      )}
    </styles.Container>
  );
});
