import React, { ComponentPropsWithoutRef, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTheme } from "styled-components";
import { useId } from "src/hooks/useId";
import * as styles from "./style";

export interface SwitchProps extends Omit<ComponentPropsWithoutRef<"input">, "type"> {
  label?: string | number;
  name?: string | undefined;
  value: string | number;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}

export const Switch = observer(
  ({ label, name = "SwitchToggle", value, checked, ...props }: SwitchProps) => {
    const id = useId();
    const theme = useTheme();

    return (
      <styles.WrapperForRadio>
        <styles.TextLabel htmlFor={id} style={{ color: checked ? theme.primaryColor : "#767676" }}>
          <span>{label}</span>
        </styles.TextLabel>

        <styles.RadioButton id={id} name={name} value={value} checked={checked} {...props} />

        <styles.LabelForRadio htmlFor={id} title={String(label)}>
          <styles.StyledRadioIcon checked={checked} />
        </styles.LabelForRadio>
      </styles.WrapperForRadio>
    );
  }
);
