import { Control, Controller, FieldError, FieldPath, FieldValues } from "react-hook-form";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { ERRORS_MSGS, isInteger, wrapHookFormValidation } from "src/validation-schemas";

export interface LaunchesLimitInputProps<
  TForm extends FieldValues,
  TName extends FieldPath<TForm>,
> {
  control: Control<TForm>;
  name: TName;
  label?: string;
}

export const LaunchesLimitInput = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  control,
  name,
  label = "Launches limit",
}: LaunchesLimitInputProps<TForm, TName>) => (
  <Controller
    control={control}
    name={name}
    rules={{
      required: ERRORS_MSGS.isRequired,
      validate: wrapHookFormValidation(isInteger()),
    }}
    render={({ field: { value, onChange }, formState: { errors } }) => {
      const error = errors[name] as FieldError | undefined;

      return (
        <LabeledInput
          type="number"
          label={label}
          value={value}
          onChange={(e) => onChange(getChangeEventValue(e))}
          errorHint={error?.message}
        />
      );
    }}
  />
);
