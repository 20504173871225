import { ComponentPropsWithoutRef } from "react";
import { PNLV2Header, PNLV2HeaderOwnProps, PNLV2HeaderProps } from "../shared/PNLHeader";
import { PNLV2Fee } from "../shared/PNLV2Fee";
import { PNLV2Data, PNLV2Items, PNLV2ItemsOwnProps, PNLV2ItemsProps } from "../shared/PNLV2Items";
import * as styles from "./style";

export interface MobilePNLV2OwnProps
  extends Omit<PNLV2HeaderOwnProps, "after">,
    PNLV2Data,
    Pick<PNLV2ItemsOwnProps, "orientation" | "showArrow"> {
  showHeader?: boolean;
  afterHeader?: JSX.Element;
  showFeeSymbol?: boolean;
}

export interface MobilePNLV2Props extends MobilePNLV2OwnProps, ComponentPropsWithoutRef<"div"> {}

export const MobilePNLV2 = ({
  afterHeader,
  afterTitle,
  title,
  quote,
  base,
  deltaBase,
  deltaQuote,
  price,
  fee,
  showFeeSymbol,
  showHeader = true,
  orientation = "row",
  showArrow,
  ...props
}: MobilePNLV2Props) => {
  const itemsSpace = orientation === "column" ? "space-between" : undefined;

  const after = afterHeader || <PNLV2Fee showSymbol={showFeeSymbol} value={fee} />;
  const headerProps: PNLV2HeaderProps = { after, afterTitle, title };
  const itemsProps: PNLV2ItemsProps = {
    quote,
    base,
    deltaBase,
    deltaQuote,
    price,
    orientation,
    itemsSpace,
    space: "space-between",
    showArrow,
  };

  return (
    <styles.PNLV2CardContainer {...props}>
      {showHeader && <PNLV2Header {...headerProps} />}
      <PNLV2Items {...itemsProps} />
    </styles.PNLV2CardContainer>
  );
};
