import { Column } from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import { useClosePopup } from "src/hooks/useClosePopup";
import * as styles from "./style";

interface ColumnFilterProps {
  column: Column<any, unknown>;
  filters: string[];
  defaultFilter?: string;
}

export const ColumnFilter = ({ column, filters, defaultFilter }: ColumnFilterProps) => {
  const [showPanel, setShowPanel] = useState(false);

  const panel = useRef<HTMLDivElement>(null);

  useClosePopup(panel, setShowPanel);

  useEffect(() => {
    if (defaultFilter) column.setFilterValue(defaultFilter);
  }, [defaultFilter, column]);

  return (
    <styles.Container ref={panel}>
      <styles.Wrapper onClick={() => setShowPanel(!showPanel)}>
        <styles.ColumnName>{`${column.getFilterValue()}`}</styles.ColumnName>

        <styles.Row check={showPanel} />
      </styles.Wrapper>

      {showPanel ? (
        <styles.Content>
          {filters.map((option) => (
            <styles.CheckFilter
              onChange={(e) => column.setFilterValue(e.target.value)}
              key={option}
              label={option}
              value={option}
              checked={column.getFilterValue() === option}
            />
          ))}
        </styles.Content>
      ) : null}
    </styles.Container>
  );
};
