import styled from "styled-components";

export const Name = styled.span`
  width: 50%;

  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};

  color: ${({ theme }) => theme.primaryColor};

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BtnWrapper = styled.div`
  width: 50%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 25px;
`;
