import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { Loader } from "src/components/shared/Loader";
import {
  ActiveMultiGridContext,
  MultiGridAbilitiesContext,
} from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { MultiGridForm } from "../../MultiGridForm";
import { GridConfigContainer, Wrapper } from "../../shared";
import * as styles from "./style";

const GRID_FORM_ID = "GRID_FORM_CHANGE";

export const MultiGridSettings = observer(() => {
  const state = useLateInitContext(ActiveMultiGridContext);

  useEffect(() => {
    state.loadData();
  }, [state]);

  const { abilityName } = useLateInitContext(MultiGridAbilitiesContext);

  return (
    <GridConfigContainer style={{ gridArea: "settings", maxWidth: "1000px" }}>
      <MultiGridForm id={GRID_FORM_ID} state={state} />

      <PermissionWrapper party={state.party} abilityName={abilityName}>
        <styles.ButtonsGroup>
          <SubmitButton stretched form={GRID_FORM_ID}>
            Save
          </SubmitButton>

          <Wrapper>
            <Button color="success" stretched onClick={state.startGrid}>
              Start
            </Button>

            <Button color="danger" stretched onClick={state.stopGrid}>
              Stop
            </Button>
          </Wrapper>
        </styles.ButtonsGroup>
      </PermissionWrapper>

      <Loader show={state.isLoading} />
    </GridConfigContainer>
  );
});
