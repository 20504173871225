import styled from "styled-components";

export const ChartTitle = styled.span`
  color: ${({ theme }) => theme.dashboard.textSecondary};
  text-align: center;
  font-size: 8px;
  font-weight: 400;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  letter-spacing: 1px;
  text-transform: uppercase;
`;
