import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const SettingsForm = styled.form`
  width: 100%;
  position: relative;

  display: flex;

  ${CardSurface}
`;
