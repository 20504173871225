import { IconButtonProps } from "src/components/shared/Buttons/v2/IconButton";
import * as styles from "./style";

export interface RefreshButtonProps extends Omit<IconButtonProps, "disabled" | "onClick"> {
  loading?: boolean;
  onRefresh?: () => void;
}

export const RefreshButton = ({ onRefresh, loading, ...props }: RefreshButtonProps) => (
  <styles.RefreshButton disabled={loading} $loading={loading} onClick={onRefresh} {...props} />
);
