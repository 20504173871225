import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 33px;
`;

export const Content = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 15px 5px 0px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  & > div:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;
