import styled from "styled-components";
import { BalanceSpan } from "../FieldWrapper/BalanceSpan/style";

export const GasPriceWrapper = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;

  > svg {
    width: 15px;
    height: auto;
  }
`;

export const GasPriceSpan = styled(BalanceSpan)`
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
`;
