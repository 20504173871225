import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useCallback } from "react";
import * as styles from "./style";

export interface AccountFormProps extends Omit<ComponentPropsWithoutRef<"form">, "onKeyPress"> {}

export const AccountForm = observer((props: AccountFormProps) => {
  const onFormKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLFormElement>) =>
      event.key === "Enter" && event.preventDefault(),
    []
  );

  return <styles.Form onKeyPress={onFormKeyPress} {...props} />;
});
