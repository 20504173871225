import { useEffect } from "react";
import { ExchangeAccountingContext } from "src/context/CEX/ExchangeAccounting";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Content } from "./Content";
import { Header } from "./Header";
import * as styles from "./style";

export interface ExchangeAccountingProps {}

export const ExchangeAccounting = (props: ExchangeAccountingProps) => {
  const state = useLateInitContext(ExchangeAccountingContext.Context);

  useEffect(() => {
    state.getExchangesAccounting();
  }, [state]);

  return (
    <styles.Container>
      <Header />

      <Content />
    </styles.Container>
  );
};
