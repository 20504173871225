import { Tabs } from "src/components/shared/Tabs";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import { FundingMenuValue } from "src/state/funding/CEX/types";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FundingListContext } from "src/context/CEX/FundingProvider";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { FundingTable } from "./FundingTable";
import * as styles from "./style";

interface FundingMenuItem extends MenuItemData {
  value: FundingMenuValue;
}

const fundingTabs: FundingMenuItem[] = [
  { label: "Aggregated", value: FundingMenuValue.AGGREGATED },
  { label: "Transfers", value: FundingMenuValue.TRANSFERS },
  { label: "Auto", value: FundingMenuValue.AUTO },
  { label: "Manual", value: FundingMenuValue.MANUAL },
];

export const FundingList = () => {
  const state = useLateInitContext(FundingListContext);

  const onTabChange = (activeList: string) => {
    state.activeFundingMenu = activeList as FundingMenuValue;
  };

  return (
    <styles.Container title="Deposit & Withdrawals">
      <Tabs menuItems={fundingTabs} defaultKey={FundingMenuValue.AGGREGATED} onChange={onTabChange}>
        <ErrorBoundary key={FundingMenuValue.AGGREGATED}>
          <FundingTable />
        </ErrorBoundary>

        <ErrorBoundary key={FundingMenuValue.TRANSFERS}>
          <FundingTable />
        </ErrorBoundary>

        <ErrorBoundary key={FundingMenuValue.AUTO}>
          <FundingTable />
        </ErrorBoundary>

        <ErrorBoundary key={FundingMenuValue.MANUAL}>
          <FundingTable />
        </ErrorBoundary>
      </Tabs>

      <styles.TitlePromptMsg id="comment" />
    </styles.Container>
  );
};
