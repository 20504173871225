import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { IBotProvider } from "../../DEXV2Bots/DEXV2BotStore";
import { HashesPaginationConfig, HistoryHashes } from "./HashesPaginationStore";
import HashesTypeStore, { HashType, IBotHashesInfoProvider } from "./HashesTypeStore";

const PAGINATION_CONFIG: HashesPaginationConfig = {
  itemsPerPage: 10,
};

export interface GetHashesParams {
  fromPage: number;
  pageSize?: number;
  onSuccess?: () => void;
}

export type HistoryHashesUnion<T extends HashType = HashType> = T extends HashType
  ? HistoryHashes<T>
  : never;

export default class HashesStore implements IBotHashesInfoProvider, IDisposable {
  private _limitHashesStore: HashesTypeStore<"limit">;

  private _volumeHashesStore: HashesTypeStore<"volume">;

  private _counterHashesStore: HashesTypeStore<"counter">;

  private _walletHashesStore: HashesTypeStore<"wallet">;

  private _currentHashType: HashType = "volume";

  private _botUUID = "";

  private _botProvider: IBotProvider;

  constructor(botProvider: IBotProvider) {
    makeAutoObservable(this);

    this._limitHashesStore = new HashesTypeStore("limit", this, PAGINATION_CONFIG);
    this._volumeHashesStore = new HashesTypeStore("volume", this, PAGINATION_CONFIG);
    this._counterHashesStore = new HashesTypeStore("counter", this, PAGINATION_CONFIG);
    this._walletHashesStore = new HashesTypeStore("wallet", this, PAGINATION_CONFIG);

    this._botProvider = botProvider;

    makeLoggable(this, {
      currentHashType: true,
      hashes: true,
      itemsPerPage: true,
      pagesCount: true,
    });
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  private get _bot() {
    return this._botProvider.bot;
  }

  private get _quote() {
    return this._bot.quote;
  }

  private get _base() {
    return this._bot.base;
  }

  get botName() {
    return `${this._quote}_${this._base}`;
  }

  setCurrentHashType = (type: HashType) => {
    this._currentHashType = type;
  };

  get currentHashType() {
    return this._currentHashType;
  }

  private get _currentHashesStore() {
    switch (this._currentHashType) {
      case "wallet": {
        return this._walletHashesStore;
      }
      case "volume": {
        return this._volumeHashesStore;
      }
      case "counter": {
        return this._counterHashesStore;
      }
      case "limit": {
        return this._limitHashesStore;
      }
      default: {
        throw Error(`unknown currentHashType: ${this._currentHashType}`);
      }
    }
  }

  private get _currentPaginationStore() {
    return this._currentHashesStore.paginationStore;
  }

  private get _currentDownloadStore() {
    return this._currentHashesStore.downloadStore;
  }

  get loading() {
    return this._currentPaginationStore.loading || this._currentDownloadStore.loading;
  }

  get itemsPerPage() {
    return this._currentPaginationStore.itemsPerPage;
  }

  get pagesCount() {
    return this._currentPaginationStore.pagesCount;
  }

  setPage = (page: number) => {
    this._currentPaginationStore.setCurrentPage(page);
  };

  get currentPage() {
    return this._currentPaginationStore.currentPage;
  }

  get hashes(): HistoryHashesUnion {
    return this._currentPaginationStore.hashes;
  }

  getHashes = async ({ fromPage, pageSize, onSuccess }: GetHashesParams) => {
    try {
      const isSuccess = await this._currentPaginationStore.getHashes(
        fromPage,
        pageSize ?? this.itemsPerPage
      );
      if (isSuccess) {
        onSuccess?.();
      }
    } catch (err) {
      logError(err);
    }
  };

  private get _itemsCount() {
    return this.itemsPerPage * this.pagesCount;
  }

  downloadHashes = async () => {
    try {
      await this._currentDownloadStore.downloadHashes(this._itemsCount);
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {
    const hashesStores = [this._limitHashesStore, this._volumeHashesStore, this._walletHashesStore];
    hashesStores.forEach((store) => {
      store.destroy();
    });
  };
}
