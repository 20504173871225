import { ModalBackground, PanelContainer } from "src/components/shared/shared";
import styled, { keyframes } from "styled-components";
import { KPIText } from "../../KPICard/style";

export const BackgroundOverlay = styled(ModalBackground)``;

const bottomDrawerIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  to {
    transform: translateY(0);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
`;

export const Panel = styled(PanelContainer)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 16px;
  border-radius: 10px 10px 0 0;

  animation: ${bottomDrawerIn} 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
`;

export const Header = styled.div`
  display: flex;
  gap: 8px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Title = styled(KPIText)``;
