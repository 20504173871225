import { observer } from "mobx-react-lite";
import React from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { SwitchItem, SwitchItemContainer } from "src/components/shared/nestedList";
import { AnalyticsContext } from "src/context/CEX/Analytics";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import { Analytic } from "src/modules/analytics";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";

interface StrategyProps {
  analytic: Analytic;
  defaultAnalytic: string;
  shownEditModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Strategy = observer(({ analytic, defaultAnalytic, shownEditModal }: StrategyProps) => {
  const state = useLateInitContext(AnalyticsContext);

  return (
    <SwitchItemContainer>
      <PermissionWrapper abilityName="cex-candle-analytics-edit" party={state.party}>
        <CheckBox
          label=""
          value={analytic.uuid}
          checked={analytic.uuid === defaultAnalytic}
          onChange={() => {
            state.changeDefaultAnalytic(analytic.uuid);
          }}
        />
      </PermissionWrapper>

      <SwitchItem
        hide={analytic.uuid === state.currentAnalytic}
        onClick={() => state.setCurrentAnalytic(analytic.uuid)}
      >
        {analytic.name}
      </SwitchItem>

      <PermissionWrapper abilityName="cex-candle-analytics-edit" party={state.party}>
        <IconButton
          onClick={() => {
            state.setEditAnalytic(analytic.uuid);
            shownEditModal(true);
          }}
        >
          {Icons.edit()}
        </IconButton>
      </PermissionWrapper>
    </SwitchItemContainer>
  );
});
