import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { WalletGasFillContext } from "src/context/DEXV2/DEXV2Create/WalletGasFill";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { TabStepProps } from "..";
import { TablePanel } from "../TablePanel";
import { useBotUUID } from "../hooks/useBotUUID";
import { BalancesTable } from "./BalancesTable";

interface WalletGasFillProps extends TabStepProps {}

const getDeployPriceWarning = (price: number, nativeTicker: string) =>
  `Based on current network load you need to fill this wallet with ${price} ${nativeTicker}`;

export const WalletGasFill = observer(({ nextStep }: WalletGasFillProps) => {
  const state = useLateInitContext(WalletGasFillContext);

  const uuid = useBotUUID();

  const next = useCallback(() => {
    state.deployProfile(nextStep);
  }, [nextStep, state]);

  useEffect(() => {
    if (!uuid) return;
    state.setBotUUID(uuid);
    state.getDeployer();
  }, [state, uuid]);

  const headingProps = { title: "Wallet Gas Fill", ...state.chainMeta };

  const priceWarning = getDeployPriceWarning(state.deployerPrice, state.nativeTicker);

  return (
    <TablePanel
      headingProps={headingProps}
      warning={priceWarning}
      onRefreshWarning={state.refreshDeployPrice}
      nextStep={next}
      onRefreshClick={state.refreshDeployerBalance}
      onChainRefreshClick={state.refreshChainDeployerBalance}
      loading={state.loading}
      warningLoading={state.priceLoading}
      nextDisabled={state.deployDisabled}
    >
      <BalancesTable />
    </TablePanel>
  );
});
