import { observer } from "mobx-react-lite";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import AddUserStore from "src/state/UserManager/AddUserStore";
import * as styles from "../../../shared";

export const AddUser = observer(() => {
  const state = useLateInitContext(UserManagerContext);

  const userState = useLocalStore(AddUserStore, state);

  return (
    <ModalPanel
      label="Add user"
      show={state.visiblePanel.showAddUserPanel}
      close={state.openPanel("showAddUserPanel")}
      loading={userState.isLoadingUser}
    >
      <styles.AccessForm
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        onSubmit={userState.submitHandler()}
      >
        <styles.FormContent>
          <styles.FormRow>
            <LabeledInput
              label="Tg handler"
              value={userState.newUser.tg_handler}
              onChange={userState.getHandler("tg_handler")}
              // @ts-ignore
              getError={userState.getError("tg_handler")}
              errorHint={userState.errors.tg_handler}
            />

            <LabeledInput
              label="Login"
              value={userState.newUser.name}
              onChange={userState.getHandler("name")}
              errorHint={userState.errors.name}
            />
          </styles.FormRow>

          <styles.FormRow>
            <LabeledSelector
              label="Type"
              options={userState.selectorOptions("group_type")}
              value={userState.selectorValue("group_type")}
              onChange={userState.getSelectorHandler("group_type")}
              // @ts-ignore
              getError={userState.getError("group_type")}
              errorHint={userState.errors.group_type}
            />

            <LabeledSelector
              label="Group name"
              options={userState.selectorOptions("group_name")}
              value={userState.selectorValue("group_name")}
              onChange={userState.getSelectorHandler("group_name")}
              // @ts-ignore
              getError={userState.getError("group_name")}
              errorHint={userState.errors.group_name}
              isDisabled={!userState.selectorEnabled("group_name")}
            />
          </styles.FormRow>

          <styles.FormRow>
            <LabeledInput
              label="Email"
              value={userState.newUser.email}
              onChange={userState.getHandler("email")}
              errorHint={userState.errors.email}
            />

            <LabeledInput
              label="Password"
              value={userState.newUser.password}
              onChange={userState.getHandler("password")}
              errorHint={userState.errors.password}
            />
          </styles.FormRow>
          <styles.FormRow>
            <LabeledSelector
              label="Role"
              options={userState.selectorOptions("hierarchy.role")}
              onChange={userState.getSelectorHandler("hierarchy.role")}
              errorHint={userState.errors.hierarchy?.role}
            />

            <LabeledSelector
              label="Scope"
              options={userState.selectorOptions("hierarchy.scope_id")}
              onChange={userState.getSelectorHandler("hierarchy.scope_id")}
              errorHint={userState.errors.hierarchy?.scope_id}
            />
          </styles.FormRow>
        </styles.FormContent>
        <SubmitButton style={{ marginTop: "20px" }}>Add user</SubmitButton>
      </styles.AccessForm>
    </ModalPanel>
  );
});
