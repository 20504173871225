import { observer } from "mobx-react-lite";
import * as styles from "src/components/UserManagement/Access/shared";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { ScopesContext } from "src/context/UserManager/Scopes";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ButtonSideWrapper } from "../style";

export const AddScope = observer(() => {
  const {
    scopesState: { addScopeState },
  } = useLateInitContext(ScopesContext);
  return (
    <ModalPanel
      label="Add scope"
      show={addScopeState.isShown}
      close={addScopeState.closeModal}
      loading={addScopeState.isLoading}
    >
      <styles.AccessForm
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        onSubmit={addScopeState.submitHandler()}
      >
        <LabeledInput
          label="Scope name"
          placeholder="Name"
          value={addScopeState.newScope.name}
          onChange={addScopeState.getHandler("name")}
          errorHint={addScopeState.errors.name}
        />
        <ButtonSideWrapper>
          <OutlineButton type="submit" stretched>
            Add scope
          </OutlineButton>
        </ButtonSideWrapper>
      </styles.AccessForm>
    </ModalPanel>
  );
});
