import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { SwitchesProps } from "src/components/shared/Switches";
import { getChangeEventValue } from "src/helpers/forms/inputs";

export interface ControllerWrapperProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  label?: string;
  control: Control<TForm>;
  name: TName;
  rules?: Omit<
    RegisterOptions<TForm, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  ignoreType?: boolean;
  component: React.ComponentType<SwitchesProps>;
  componentProps: Pick<SwitchesProps, "items" | "style">;
}

export const ControllerWrapper = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  label,
  name,
  component: SwitchesComponent,
  componentProps,
  ignoreType = false,
  ...props
}: ControllerWrapperProps<TForm, TName>) => (
  <Controller
    name={name}
    {...props}
    render={({ field: { value, onChange } }) => (
      <SwitchesComponent
        label={label}
        selectedItem={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(getChangeEventValue(e, ignoreType))
        }
        {...componentProps}
      />
    )}
  />
);
