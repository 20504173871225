import { makeAutoObservable, runInAction } from "mobx";
import { ApiResponse } from "src/modules/network";
import { ApiResponseError } from "src/state/network/ResponseHandler";

export interface OrdersStore {
  getOrder: (id: string) => Promise<ApiResponse<any, ApiResponseError>>;
}

class GetStatusOrderStore<T> {
  selectOrderID = "";

  private _selectOrderStatus: T | undefined;

  isStatusLoading = false;

  parentState: OrdersStore;

  constructor(state: OrdersStore) {
    this.parentState = state;

    makeAutoObservable(this);
  }

  get selectOrderStatus() {
    return this._selectOrderStatus;
  }

  resetSelectOrder = () => {
    runInAction(() => {
      this._selectOrderStatus = undefined;
    });
  };

  setSelectOrderId = (id: string) => {
    this.selectOrderID = id;

    this.resetSelectOrder();

    this._getOrderStatus();
  };

  private _getOrderStatus = async () => {
    runInAction(() => {
      this.isStatusLoading = true;
    });

    try {
      const {
        isError,
        data: { data },
      } = await this.parentState.getOrder(this.selectOrderID);

      if (!isError) {
        runInAction(() => {
          this._selectOrderStatus = data;
        });
      }
    } finally {
      runInAction(() => {
        this.isStatusLoading = false;
      });
    }
  };
}

export default GetStatusOrderStore;
