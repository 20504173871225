import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { delay } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import {
  generateAccountName,
  generateBalance,
  generateExchange,
  generateRandomBoolean,
} from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type AccountBalance = {
  total?: number;
  free?: number;
  locked?: number;
};

export type AccountBalances = {
  exchange: string;
  accountName: string;
  totalUSD: AccountBalance;
  baseAmount: AccountBalance;
  quoteAmount: AccountBalance;
  otherAmount: AccountBalance;
};

const generateBalanceOrNull = () => (generateRandomBoolean() ? generateBalance() : undefined);

const generateBalances = (): AccountBalance => ({
  total: generateBalanceOrNull(),
  free: generateBalanceOrNull(),
  locked: generateBalanceOrNull(),
});

const generateAccountBalance = (): AccountBalances => {
  const exchange = generateExchange();
  const accountName = generateAccountName();
  return {
    exchange,
    accountName,
    totalUSD: generateBalances(),
    baseAmount: generateBalances(),
    quoteAmount: generateBalances(),
    otherAmount: generateBalances(),
  };
};

const generateAccountBalances = (): AccountBalances[] => {
  const accountBalances: AccountBalances[] = faker.helpers.multiple(generateAccountBalance, {
    count: { min: 5, max: 10 },
  });
  return accountBalances;
};

export type AccountBalancesKeys = keyof AccountBalances;

export const BALANCES_COLUMNS = ["baseAmount", "otherAmount", "quoteAmount", "totalUSD"] as const;

export type BalancesColumnsKeys = (typeof BALANCES_COLUMNS)[number];

interface IAccountBalancesStoreParams extends IBaseDashboardV2StoreParams {}

export class AccountBalancesV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: AccountBalances[] = [];

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IAccountBalancesStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  get loading() {
    return this._baseState.loading;
  }

  private _setData = (data: AccountBalances[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData([]);
    try {
      await delay(300);
      const accountBalances = generateAccountBalances();
      this._setData(accountBalances);
    } catch {
      this._setData([]);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
