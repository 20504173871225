import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";
import { HighlightMixin, HighlightProps } from "../../Access/shared";
import { DragIcon } from "../shared/DragIcon";

export interface AccentColorProps {
  accent: string;
}
export interface CardProps extends AccentColorProps, HighlightProps {}

export const Card = styled.div<CardProps>`
  width: 100%;

  position: relative;

  ${CardSurface}

  ${HighlightMixin}

  :before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(100% - 8px);
    border-bottom: ${({ accent }) => `1px solid ${accent}`};
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;

  padding: 20px 0;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
`;

export interface NameProps extends AccentColorProps {
  head?: boolean;
}

export const Name = styled.p<NameProps>`
  margin: 0;
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme, head, accent }) => (head ? accent : theme.textColor)};

  word-wrap: break-word;
`;

export const PartiesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const FixedDragIcon = styled(DragIcon).attrs({ size: "l" })`
  position: absolute;
  right: 0;
  top: 0;
`;
