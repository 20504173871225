import { observer } from "mobx-react-lite";
import { useCallback, useContext } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Headline2 } from "src/components/shared/Typography";
import { PartyContext } from "src/context/UserManager/UserManager";
import Icons from "src/icons/Icons";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import WindowConsent from "src/state/WindowConsent";
import * as styles from "./style";

export const Credentials = observer(() => {
  const partyState = useContext(PartyContext);

  const onArchiveAPI = useCallback(() => {
    if (!partyState) return;
    const api = partyState.currentCredential;
    const account = partyState.currentAcc;
    WindowConsent.showWindow(
      "",
      `Are you sure you want to archive API: ${api.name} from Account: ${account.name}?`,
      partyState.archiveAPI
    );
  }, [partyState]);

  return (
    <styles.Container>
      <styles.HeadLineWrapper>
        <Headline2>Credentials</Headline2>
        <styles.HeadlineButtonsWrapper>
          <IconButton onClick={onArchiveAPI} disabled={partyState?.isActiveCurrentAPI}>
            {Icons.archive()}
          </IconButton>
          <PermissionWrapper abilityName="cex-api-view-secret" party={partyState?.partyName}>
            <IconButton onClick={() => partyState?.getShownAPIKey()}>{Icons.show()}</IconButton>
          </PermissionWrapper>
        </styles.HeadlineButtonsWrapper>
      </styles.HeadLineWrapper>

      <styles.Contant>
        <LabeledInput
          label="Open"
          value={String(partyState?.currentCredential.data.KEY)}
          onChange={() => {}}
          readOnly
        />
        <LabeledInput
          label="Secret"
          value={String(partyState?.currentCredential.data.SECRET)}
          onChange={() => {}}
          readOnly
        />
        <LabeledInput
          label="Account ID"
          value={String(partyState?.currentCredential.data.ACCOUNT_ID)}
          onChange={() => {}}
          readOnly
        />
      </styles.Contant>
    </styles.Container>
  );
});
