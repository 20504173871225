import { Color, Chart as IChart, Ticks } from "chart.js";
import Zoom from "chartjs-plugin-zoom";
import { observer } from "mobx-react-lite";
import { rgba } from "polished";
import { useMemo, useRef } from "react";
import { Bar, BarProps } from "src/components/shared/Charts/Bar";
import { ChartPlaceholder } from "src/config/chartjs/plugins/chartPlaceholder";
import { formatPNLWithSign } from "src/helpers/string";
import { deepMerge } from "src/helpers/utils/deepMerge";
import { PNLMode } from "..";
import {
  BASE_TIME_CONFIG,
  useBaseTooltip,
  useGridOptions,
  usePlaceholderOptions,
} from "../../../../shared/config";
import { useChartZoom } from "../../../../shared/hooks/useChartZoom";
import { useDashboardColors } from "../../../../shared/hooks/useDashboardColors";

const useBaseOptions = (mode: PNLMode) => {
  const tooltipOptions = useBaseTooltip();

  const options = useMemo(
    (): BarProps<any, any>["options"] => ({
      maintainAspectRatio: false,
      layout: { padding: 8 },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...tooltipOptions,
          callbacks: {
            labelTextColor: ({ dataIndex, dataset }) => {
              const backgroundColor = (dataset.backgroundColor as Color[])[dataIndex];
              return backgroundColor;
            },
            label: ({ raw }) => {
              const value = raw as number;
              if (mode === "percent") {
                return formatPNLWithSign(value, "percent");
              }
              return formatPNLWithSign(value, "fiat");
            },
          },
        },
      },
      scales: {
        x: {
          ...BASE_TIME_CONFIG,
          type: "time",
        },
        y: {
          ticks: {
            callback(value, index, ticks) {
              if (mode !== "percent") {
                return Ticks.formatters.numeric.apply(this, [value as number, index, ticks]);
              }
              return `${(value as number).toFixed(2)}%`;
            },
            precision: 2,
          },
        },
      },
    }),
    [mode, tooltipOptions]
  );

  return options;
};

export interface ProfitLossBarProps extends BarProps<number[], number> {
  zoomDefault?: boolean;
  mode: PNLMode;
}

export const ProfitLossBar = observer(
  ({ options: inOptions, data, zoomDefault, mode, ...props }: ProfitLossBarProps) => {
    const chartRef = useRef<IChart<any, any, any>>(null);

    const baseOptions = useBaseOptions(mode);

    const gridOptions = useGridOptions();

    const placeholderOptions = usePlaceholderOptions();

    const {
      accent: { darkLime, rubyRed },
    } = useDashboardColors();

    const zoomOptions = useChartZoom({
      chartRef,
      shouldZoom: zoomDefault,
      data,
    });

    const options = useMemo(
      () => deepMerge(placeholderOptions, zoomOptions, baseOptions, gridOptions, inOptions),
      [baseOptions, gridOptions, inOptions, placeholderOptions, zoomOptions]
    );

    const profitLossData: ProfitLossBarProps["data"] = useMemo(() => {
      const dataset = data.datasets[0];
      const dataValues = dataset.data;
      const backgrounds = dataValues.map((value) => {
        if (value >= 0) {
          return rgba(darkLime, 0.5);
        }
        return rgba(rubyRed, 0.5);
      });
      const hoverBackgrounds = backgrounds.map((color) => rgba(color, 0.8));
      return {
        ...data,
        datasets: [
          {
            ...dataset,
            backgroundColor: backgrounds,
            hoverBackgroundColor: hoverBackgrounds,
          },
        ],
      };
    }, [darkLime, data, rubyRed]);

    return (
      <Bar
        chartRef={chartRef}
        options={options}
        data={profitLossData}
        plugins={[Zoom, ChartPlaceholder]}
        {...props}
      />
    );
  }
);
