import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Headline3 } from "src/components/shared/Typography";
import { CreateMultiGridContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import { INewExchangeModule } from "src/state/CEX/multiGrid/AddModuleModal";
import { PanelContainer, Wrapper } from "../../../shared";

interface ExchangeModuleProps {
  module: INewExchangeModule;
}

export const ExchangeModule = observer(({ module }: ExchangeModuleProps) => {
  const state = useLateInitContext(CreateMultiGridContext);

  const selectAccount = useMemo(
    () => state.accountsState.getSelectAccount(module.id, module.settings.accountUUID),
    [state, module]
  );

  return (
    <PanelContainer style={{ boxShadow: "none" }}>
      <Wrapper style={{ justifyContent: "space-between" }}>
        <Headline3>{`${module.id.toUpperCase()} - ${module.pair}`}</Headline3>
        <IconButton
          onClick={() => {
            state.removeExchModule(module.id);
          }}
        >
          {Icons.remove()}
        </IconButton>
      </Wrapper>

      <Wrapper>
        <LabeledInput readOnly label="Account" value={selectAccount ? selectAccount.label : ""} />

        <LabeledInput readOnly label="Maker Fee %" value={module.settings.makerFee} />
      </Wrapper>

      <Wrapper>
        <LabeledInput readOnly label="Price Dec" value={module.settings.priceDecimals} />
        <LabeledInput readOnly label="Amount Dec" value={module.settings.amountDecimals} />
      </Wrapper>
    </PanelContainer>
  );
});
