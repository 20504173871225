import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "src/components/shared/Toaster";
import { createMultiGrinder } from "src/api/bots/CEX/multigrinder";
import { showSuccessMsg } from "src/helpers/message";
import MultiGridStore, { IBaseMultiGridForm } from ".";
import AccountManagerStore from "../../shared/AccountManager";
import { INewExchangeModule } from "./AddModuleModal";
import MultiGridFormStore from "./MultiGridForm";

export interface IModuleCreator {
  party: string;
  gridUUID: string;

  accountsState: AccountManagerStore;

  addExchModule: (module: INewExchangeModule, closeModal: (bool: boolean) => void) => void;
}

class CreateMultiGridStore implements IModuleCreator, IBaseMultiGridForm {
  formState: MultiGridFormStore;

  mainState: MultiGridStore;

  accountsState: AccountManagerStore;

  isLoading = false;

  constructor(state: MultiGridStore) {
    this.mainState = state;

    this.formState = new MultiGridFormStore();

    this.accountsState = new AccountManagerStore(this);

    makeAutoObservable(this);
  }

  get party() {
    return this.mainState.party;
  }

  get gridUUID() {
    return this.mainState.gridUUID;
  }

  get moduleList() {
    return this.formState.grinder.exchanges;
  }

  checkModuleDuplication = (id: string) => {
    const findModuleIndx = this.findModule(this.formState.grinder.exchanges, id);

    if (findModuleIndx === -1) {
      return true;
    }

    toast.error(`Module for ${id} exchange already exists!`);
    return false;
  };

  findModule = (arr: INewExchangeModule[], id: string) => arr.findIndex((el) => el.id === id);

  removeExchModule = (id: string) => {
    const findModuleIndx = this.findModule(this.formState.grinder.exchanges, id);

    if (findModuleIndx !== -1) {
      this.formState.grinder.exchanges.splice(findModuleIndx, 1);
    }
  };

  addExchModule = (module: INewExchangeModule, closeModal: (bool: boolean) => void) => {
    const valid = this.checkModuleDuplication(module.id);

    if (valid) {
      this.formState.grinder.exchanges.push(module);

      showSuccessMsg("Module successfully added");

      closeModal(false);
    }
  };

  submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    runInAction(() => {
      this.formState.grinder.party = this.party;
    });

    const valid = this.formState.validate();

    if (valid) {
      runInAction(() => {
        this.isLoading = true;
      });

      try {
        const { isError } = await createMultiGrinder(this.formState.grinder);

        if (!isError) {
          showSuccessMsg("Multy grinder created successfully");

          // this.mainState.changeStateGrid(true);
          this.mainState.checkGridState();
        }
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  };
}

export default CreateMultiGridStore;
