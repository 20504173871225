import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadLineWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HeadlineButtonsWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Contant = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 15px;

  & > div:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;
