import { observer } from "mobx-react-lite";
import { CompareFormRow } from "src/components/BotsContent/DEX_V2/Settings/Limit/LimitPanel/style";
import { MultiToggleButton } from "src/components/shared/Buttons/v2/MultiToggleButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FormContent, FormRow, SettingsPanel, Switch } from "../shared";

export const LimitSettings = observer(() => {
  const state = useLateInitContext(DEXV2CreatingContext);

  return (
    <SettingsPanel>
      <FormContent>
        <CheckBox
          label="Use Receiver"
          checked={state.data.limit_data.use_receiver}
          onChange={state.getHandler("limit_data.use_receiver")}
        />
        <Switch
          name="chooseMode"
          direction="row"
          label="Choose Mode"
          items={state.switchOptions("mod")}
          selectedItem={state.data.limit_data.mod}
          onChange={state.getHandler("limit_data.mod")}
        />
        <LabeledInput
          type="number"
          step="any"
          label="Period"
          {...state.getInputProps("limit_data.period")}
        />
        <CompareFormRow>
          <MultiToggleButton
            items={state.switchOptions("trigger_compare")}
            value={state.data.limit_data.trigger_compare}
            onChange={state.setSwitchOption("trigger_compare")}
            type="button"
            disableSelected
          />
          <LabeledInput
            type="number"
            step="any"
            label="Price"
            {...state.getInputProps("limit_data.price")}
          />
        </CompareFormRow>
        <FormRow>
          <LabeledInput
            type="number"
            step="any"
            label="Min Amount"
            {...state.getInputProps("limit_data.min_amount")}
          />

          <LabeledInput
            type="number"
            step="any"
            label="Max Amount"
            {...state.getInputProps("limit_data.max_amount")}
          />
        </FormRow>
        <LabeledInput
          type="number"
          step="0.1"
          label="Slippage"
          {...state.getInputProps("limit_data.slippage")}
        />
        <LabeledPercentRangeInput
          step="0.1"
          label="Gas Mult"
          {...state.getInputProps("limit_data.gas_mult")}
        />
        <LabeledInput
          type="number"
          step="0.1"
          label="Gas Max"
          {...state.getInputProps("limit_data.gas_price_limit")}
        />
      </FormContent>
    </SettingsPanel>
  );
});
