import { PNLChartsType } from "src/state/CEX/shared/CEXPNLStore/types";
import * as styles from "./style";

export const PNLCharts = ({ deltaQuoteChart, deltaBaseChart, priceChart }: PNLChartsType) => {
  if (!deltaBaseChart || !deltaQuoteChart || !priceChart) return null;

  return (
    <styles.ChartsWrapper>
      <styles.PNLChartContainer series={deltaQuoteChart} />

      <styles.ChartsSeparator />

      <styles.PNLChartContainer series={deltaBaseChart} />

      <styles.ChartsSeparator />

      <styles.PNLChartContainer series={priceChart} />
    </styles.ChartsWrapper>
  );
};
