import { observer } from "mobx-react-lite";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ListContainer } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { getActionTitle } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/modules";
import { CreateStrategyContext } from "src/context/CEX/ExpertSystem";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ModuleTypeList } from "../../shared";

export const ActionList = observer(() => {
  const state = useLateInitContext(CreateStrategyContext);

  const { control } = useFormContext<IStrategyCreator>();

  const { append } = useFieldArray({
    control,
    name: "actions",
  });

  return (
    <ListContainer>
      {state.actionTypeList.map((el) => (
        <ModuleTypeList
          key={el}
          title={getActionTitle(el)}
          modules={state.getActionList(el)}
          appendModule={state.addActionModuleHandler(append)}
          cbCounter={state.getActionAddedModule}
        />
      ))}
    </ListContainer>
  );
});
