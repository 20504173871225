import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const SettingsPanel = styled(PanelContainer)`
  display: flex;
  flex-direction: column;

  padding: 15px 17px;
  gap: 15px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
