import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";
import { Header as BaseHeader } from "../SettingsPanel/style";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 13px 15px 28px;

  ${CardSurface}
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
`;

export const Header = styled(BaseHeader)``;
