import { ComponentPropsWithoutRef } from "react";
import { ThemeMode } from "src/components/Themes";
import { Loader } from "src/components/shared/Loader";
import { useThemeMode } from "src/hooks/useThemeMode";
import * as styles from "./style";

const getDEXScreenerWidgetUrl = (
  baseUrl: string,
  network: string,
  pairAddress: string,
  theme: ThemeMode,
  showInfo: boolean
) => {
  const infoParam = showInfo ? "info=1" : "info=0";
  if (baseUrl) {
    return `${baseUrl}?embed=1&theme=${theme}&${infoParam}`;
  }
  if (!network || !pairAddress) return null;
  return `https://dexscreener.com/${network}/${pairAddress}?embed=1&theme=${theme}&${infoParam}`;
};

export interface DEXScreenerChartOwnProps {
  network?: string;
  pairAddress?: string;
  baseUrl?: string;
  showInfo?: boolean;
}

export interface DEXScreenerChartProps
  extends DEXScreenerChartOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, keyof DEXScreenerChartOwnProps> {}

export const DEXScreenerChart = ({
  network = "",
  pairAddress = "",
  baseUrl = "",
  showInfo = true,
  ...props
}: DEXScreenerChartProps) => {
  const theme = useThemeMode();

  const widgetUrl = getDEXScreenerWidgetUrl(baseUrl, network, pairAddress, theme, showInfo);

  const showLoading = !widgetUrl;

  return (
    <styles.Container {...props}>
      {widgetUrl && <iframe title="DEXScreener Chart Widget" src={widgetUrl} />}
      <Loader show={showLoading} />
    </styles.Container>
  );
};
