import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { generateAmount, generateExchange, generatePriceUSD } from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type ExchangesFeesData = Record<string, number>;

const INITIAL_DATA: ExchangesFeesData = {};

const generateFeeExchange = () => {
  const exchange = generateExchange();
  const fee = generateAmount();
  return { exchange, fee: +fee };
};

const generateFeeExchanges = (): ExchangesFeesData =>
  faker.helpers
    .multiple(generateFeeExchange, { count: { min: 1, max: 6 } })
    .reduce((exchangesData, { exchange, fee }) => {
      // eslint-disable-next-line no-param-reassign
      exchangesData[exchange] = fee;
      return exchangesData;
    }, {} as ExchangesFeesData);

interface IExchangesFeeStoreParams extends IBaseDashboardV2StoreParams {}

export class ExchangesFeeV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: ExchangesFeesData = INITIAL_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IExchangesFeeStoreParams) {
    makeAutoObservable(this);

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    this._stateProvider = stateProvider;

    makeLoggable(this, { data: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: ExchangesFeesData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;
    if (!requestParams) return;

    this._setData(INITIAL_DATA);
    try {
      await delay(200);
      const data = generateFeeExchanges();
      this._setData(data);
    } catch (error) {
      this._setData(INITIAL_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
