import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { Form } from "src/components/BotsContent/DEX_V2/Stats/Tables/shared";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { GasWalletsContext } from "src/context/DEXV2/DEXV2Stats/GasWallets/GasWallets";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import GasFillStore, {
  GasFillParameters,
} from "src/state/DEXV2/DEXV2Stats/GasWallets/GasFillStore";

export interface GasFillModalContentOwnProps extends Omit<GasFillParameters, "chainProvider"> {}

export interface GasFillModalContentProps
  extends GasFillModalContentOwnProps,
    Omit<ComponentPropsWithoutRef<"form">, "onSubmit" | keyof GasFillModalContentOwnProps> {}
export const GasFillModalContent = observer(
  ({ onClose, wallets, setLoading, deployerId, ...props }: GasFillModalContentProps) => {
    const walletsState = useLateInitContext(GasWalletsContext);

    const fillParams: GasFillParameters = {
      onClose,
      wallets,
      setLoading,
      deployerId,
      chainProvider: walletsState.chainProvider,
    };

    const state = useLocalStore(GasFillStore, fillParams);

    return (
      <Form onSubmit={state.formState.submitHandler()} {...props}>
        <LabeledInput
          label="Amount of gas"
          type="number"
          step="any"
          {...state.formState.getInputProps("amount")}
        />

        <SubmitButton size="m">Distribute</SubmitButton>
      </Form>
    );
  }
);
