import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  ${CardSurface}

  box-shadow: none;
`;
export const Content = styled.div`
  width: 100%;
  height: 0;

  display: flex;
  flex: 1 1 auto;
`;

export const MainContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
