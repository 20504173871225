import styled from "styled-components";

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const DownloadButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const RefreshButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const CountText = styled.p`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  min-width: 70px;

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.smallHeader};

  strong: {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;
