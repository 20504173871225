import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { toast } from "src/components/shared/Toaster";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { DropdownButton } from "src/components/shared/Buttons/v2/DropdownButton";
import { ExchangeAccountingContext } from "src/context/CEX/ExchangeAccounting";
import { CreateSpreadsheetOptions, getSpreadsheetUrl } from "src/helpers/google/sheets";
import { LoadState } from "src/helpers/network/state";
import { useExportSpreadsheet } from "src/hooks/google/useExportSpreadsheet";
import { useGoogleAuth } from "src/hooks/google/useGoogleAuth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ExchangeAccounting, ExportDataType } from "src/state/CEX/accounting";
import { DownloadButton } from "../DownloadButton";
import * as styles from "./style";

const SHEET_API_SCOPES = ["https://www.googleapis.com/auth/drive.file"];

export interface SpreadsheetButtonProps {}

export const SpreadsheetButton = observer((props: SpreadsheetButtonProps) => {
  const state = useLateInitContext(ExchangeAccountingContext.Context);

  const { accessToken, isLoggedIn, login } = useGoogleAuth({
    scopes: SHEET_API_SCOPES,
  });

  const { loadState, exportSpreadsheet } = useExportSpreadsheet<ExchangeAccounting>({
    onSuccess: ({ sheet }) => {
      const url = getSpreadsheetUrl(sheet);
      toast.success(
        <>
          <styles.SpreadsheetLink href={url}>Spreadsheet</styles.SpreadsheetLink> has been created!
        </>
      );
    },
    onError: (error) => {
      const message = (error as any)?.message || "Something went wrong";
      toast.error(message);
    },
  });

  const getSpreadSheetOptions = useCallback(
    (
      type: ExportDataType,
      data: ExchangeAccounting[]
    ): CreateSpreadsheetOptions<ExchangeAccounting> => ({
      token: accessToken,
      data,
      spreadsheet: {
        title: state.getExportDocumentTitle(type),
      },
      sheet: { title: "Accounting" },
      rows: { serializer: state.accountingToSheets },
    }),
    [accessToken, state]
  );

  const exportSpreadsheetByType = useCallback(
    (type: ExportDataType) => {
      const exportData = state.getExportData(type);

      const options = getSpreadSheetOptions(type, exportData);

      exportSpreadsheet(options);
    },
    [exportSpreadsheet, getSpreadSheetOptions, state]
  );

  const loading = loadState === LoadState.Loading;

  return (
    <>
      {isLoggedIn ? (
        <DownloadButton
          trigger={(isOpen: boolean) => (
            <DropdownButton
              size="m"
              variant="outline"
              open={isOpen}
              startIcon={loading && <styles.LoadingIcon $loading={loading} />}
              disabled={loading}
            >
              Export to Sheets
            </DropdownButton>
          )}
          onDownloadFiltered={() => exportSpreadsheetByType("filtered")}
          onDownloadOriginal={() => exportSpreadsheetByType("full")}
        />
      ) : (
        <Button size="m" onClick={() => login()}>
          Sign in with Google
        </Button>
      )}
    </>
  );
});
