import { KeyboardEvent } from "react";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

export const BuySellForm = styled.form.attrs(() => ({
  onKeyDown: (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") event.preventDefault();
  },
}))`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1 1 auto;

  gap: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: end;

  gap: 9px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

export const TriggerWrapper = styled(Wrapper)``;

export const ButtonWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const WrapperFees = styled.div`
  display: flex;

  justify-content: space-between;

  gap: 3px;
`;

export const FeeTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.labelColor};

  border-bottom: 1px dashed currentColor;

  cursor: pointer;
`;

export const FeeLabel = styled(FeeTitle)`
  color: #fff;
  border-bottom: none;
`;

export const FeeValue = styled(FeeTitle)`
  color: #fff;
  border-bottom: none;
`;

export const HeadTooltip = styled(Tooltip)`
  display: flex !important;
  flex-direction: column !important;

  gap: 5px !important;
`;
