import { ToggleSwitch, ToggleSwitchProps } from "src/components/shared/ToggleSwitch";
import { checkParty } from "src/helpers/checkParty";
import { useAppState } from "src/state";

export interface SettingsToggleSwitchProps extends Omit<ToggleSwitchProps, "disabled"> {
  party: string;
}

export const SettingsToggleSwitch = ({ party, ...props }: SettingsToggleSwitchProps) => {
  const { userState } = useAppState();

  const canEdit = checkParty(userState.abilities, "cex-settings-edit", party);

  return <ToggleSwitch disabled={!canEdit} {...props} />;
};
