import { Indicator } from "src/components/BotsContent/CEX/LiquidityTab/Status/style";
import { BaseHeadline } from "src/components/shared/Typography";
import styled, { css } from "styled-components";

const WrapperMixin = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  ${WrapperMixin}
`;

export const Title = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.textColor};

  ${BaseHeadline}
`;

export const ModuleStatus = styled(Indicator)`
  width: 11px;
  height: 11px;

  margin: 0px;
`;

export const Wrapper = styled.div`
  width: 50%;

  ${WrapperMixin}
`;

export const TitleWrapper = styled.div`
  gap: 5px;

  ${WrapperMixin}
`;

export const BtnWrapper = styled.div`
  gap: 15px;

  ${WrapperMixin}
`;
