import { CheckBox } from "src/components/shared/CheckBox";
import {
  CheckBox as StyledCheckBox,
  Label as CheckBoxLabel,
  LabelText as CheckBoxLabelText,
} from "src/components/shared/CheckBox/style";
import { SwitchMenu } from "src/components/shared/SwitchMenu";
import { Label } from "src/components/shared/SwitchMenu/MenuItem/style";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const FeeCheckbox = styled(CheckBox)`
  ${CheckBoxLabelText} {
    margin: 0;
    margin-left: 4px;
  }

  ${CheckBoxLabel} {
    width: 12px;
    height: 12px;
  }

  ${StyledCheckBox} {
    :checked + ${CheckBoxLabel}::before {
      width: 2px;
      height: 4px;
      left: 3px;
      top: 1.5px;
      border-width: 1.5px;
    }
  }
`;

export const ModeSwitch = styled(SwitchMenu).attrs({ direction: "row" })`
  max-width: 150px;
  min-width: 120px;
  height: 24px;

  ${Label} {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;
