import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { CreateBotContext } from "src/context/CEX/CreateBotProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SettingsPanel } from "../SettingsPanel";
import { Wrapper } from "../shared";

export const ExchangeSettings = observer(() => {
  const state = useLateInitContext(CreateBotContext);

  useEffect(() => {
    state.getPartiesList();
    state.getError("party");
    state.getError("settings.pair.exchange");
  }, [state]);

  return (
    <SettingsPanel style={{ gridArea: "exchange" }} title="Exchange">
      <Wrapper>
        <LabeledSelector
          label="Party"
          placeholder="party"
          options={state.selectorOptions("party")}
          value={state.selectorValue("party")}
          onChange={state.onSelectorChange("party")}
          errorHint={state.errors.party}
        />
        <LabeledSelector
          label="Exchange"
          placeholder="exchange"
          options={state.selectorOptions("exchange")}
          value={state.selectorValue("exchange")}
          onChange={state.onSelectorChange("exchange")}
          errorHint={state.errors.settings?.pair?.exchange}
          isDisabled={!state.exchangeSelectorEnabled}
        />
      </Wrapper>
      <Wrapper>
        <LabeledInput
          label="Quote"
          value={state.data.settings?.pair?.quote ?? ""}
          onChange={state.getHandler("settings.pair.quote")}
          errorHint={state.errors.settings?.pair?.quote}
        />
        <LabeledInput
          label="Base"
          value={state.data.settings?.pair?.base ?? ""}
          onChange={state.getHandler("settings.pair.base")}
          errorHint={state.errors.settings?.pair?.base}
        />
      </Wrapper>
    </SettingsPanel>
  );
});
