import { Observer, observer } from "mobx-react-lite";
import { Controller, useFormContext } from "react-hook-form";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { BotModulesContext } from "src/context/CEX/BotModules/BotModulesProvider";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IBotModule } from "src/state/CEX/botModules";
import { ERRORS_MSGS } from "src/validation-schemas";

export const MainBotModuleForm = observer(() => {
  const state = useLateInitContext(BotModulesContext);

  const { control } = useFormContext<IBotModule>();

  return (
    <>
      <Controller
        control={control}
        name="mainBot"
        rules={{ required: ERRORS_MSGS.isRequired }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <Observer>
            {() => (
              <LabeledSelector
                label="Main bot"
                options={state.partyBots}
                onChange={(data) => onChange(data?.value)}
                value={{
                  value,
                  label: state.getFullBotName(value),
                }}
                errorHint={errors.mainBot?.message}
              />
            )}
          </Observer>
        )}
      />

      <Controller
        control={control}
        name="mainBotQuote"
        rules={{ required: ERRORS_MSGS.isRequired }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <LabeledInput
            label="Main Quote"
            value={value}
            onChange={(e) => onChange(getChangeEventValue(e))}
            errorHint={errors.mainBotQuote?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="currentQuote"
        rules={{ required: ERRORS_MSGS.isRequired }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <LabeledInput
            label="Current Quote"
            value={value}
            onChange={(e) => onChange(getChangeEventValue(e))}
            errorHint={errors.currentQuote?.message}
          />
        )}
      />
    </>
  );
});
