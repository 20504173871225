import { Token } from "@uniswap/sdk-core";
import { observer } from "mobx-react-lite";
import { DecimalSuggestionInputProps } from "../DecimalSuggestionInput";
import * as styles from "./style";

export interface TokenInputProps
  extends Omit<DecimalSuggestionInputProps<HTMLDivElement>, "getSuggestion" | "suggestionGap"> {
  token?: Token;
}

const getTokenTicker = (token?: Token) => {
  if (!token) return null;
  const { symbol: ticker } = token;
  if (!ticker) {
    return null;
  }
  return ticker;
};

export const TokenInput = observer(({ token, ...props }: TokenInputProps) => {
  const ticker = getTokenTicker(token);

  return (
    <styles.TokenLabeledInput
      getSuggestion={(getRef) =>
        ticker ? <styles.TokenTickerChip ownRef={getRef} value={ticker} /> : null
      }
      suggestionGap={6}
      {...props}
    />
  );
});
