import styled from "styled-components";
import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import InfoHeading from "../../LiquidityTab/InfoHeading";

export const Container = styled.div`
  width: 100%;

  display: grid;

  grid-gap: 10px;

  grid-template-columns: minmax(375px, 1fr) minmax(170px, 1fr) minmax(245px, 1fr);

  grid-template-rows: auto 360px 95px 395px 95px;

  grid-template-areas:
    "heading      heading     heading "
    "settings     stats       stats   "
    "settings     state       state   "
    "moduleList   state       state   "
    "moduleList   logs        logs    ";

  @media screen and (max-width: 960px) {
    grid-template-columns: minmax(375px, 1fr) minmax(245px, 1fr);

    grid-template-rows: auto 360px 95px 365px 395px 95px;

    grid-template-areas:
      "heading      heading     "
      "settings     settings    "
      "settings     settings    "
      "moduleList   stats       "
      "state        state       "
      "logs         logs        ";
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 100%;

    grid-template-rows: auto 470px 265px 375px 395px 95px;

    grid-template-areas:
      "heading      "
      "settings     "
      "moduleList   "
      "stats        "
      "state        "
      "logs         ";
  }
`;

export const GridHeading = styled(InfoHeading)`
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }

  & * {
    @media screen and (max-width: 900px) {
      font-size: ${({ theme }) => theme.fontSizes["16px"]};
    }

    @media screen and (max-width: 750px) {
      font-size: ${({ theme }) => theme.fontSizes["14px"]};
    }

    @media screen and (max-width: 450px) {
      font-size: ${({ theme }) => theme.fontSizes["12px"]};
    }
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;

  white-space: nowrap;

  gap: 25px;
`;

export const MultiGridSettingsFallback = styled(CrashPanel)`
  grid-area: settings;
`;

export const ModuleListFallback = styled(CrashPanel)`
  grid-area: moduleList;
`;

export const StatsPanelFallback = styled(CrashPanel)`
  grid-area: stats;
`;

export const GridStateFallback = styled(CrashPanel)`
  grid-area: state;
`;
