import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { DEXV2VolumeInfo } from ".";
import {
  IBotChainInfoProvider,
  IBotTradePairProvider,
  ISwapPairAddressProvider,
} from "../../DEXV2Bots/DEXV2BotStore";
import { IBaseUSDPriceProvider } from "../../DEXV2Swap/SwapModules/shared/Providers/BaseUsdPriceProvider";
import { ISwapSettingsProvider } from "../../DEXV2Swap/SwapModules/shared/Providers/SwapSettingsProvider";
import { IDEXV2SettingsProvider } from "../DEXV2SettingsInfoStore";
import {
  AverageVolumeInfoStore,
  IAverageVolumeDataProvider,
  IAverageVolumeInfo,
} from "./AverageVolumeInfoStore";
import { ModeImpactInfoStoreProvider } from "./PriceImpactInfo/ModeImpactInfoStoreProvider";

export interface IVolumeInfoParams {
  swapSettingsProvider: ISwapSettingsProvider;
  settingsProvider: IDEXV2SettingsProvider;
  tradePairProvider: IBotTradePairProvider;
  baseUSDPriceProvider: IBaseUSDPriceProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  botChainInfoProvider: IBotChainInfoProvider;
}

export class VolumeInfoStore implements IAverageVolumeDataProvider, IDisposable {
  private _settingsProvider: IDEXV2SettingsProvider;

  private _averageVolumeState: IAverageVolumeInfo & IDisposable;

  private _modeImpactInfoStateProvider: ModeImpactInfoStoreProvider;

  constructor(params: IVolumeInfoParams) {
    makeAutoObservable(this);

    const {
      settingsProvider,
      swapSettingsProvider,
      tradePairProvider,
      baseUSDPriceProvider,
      pairAddressProvider,
      botChainInfoProvider,
    } = params;

    this._settingsProvider = settingsProvider;

    this._averageVolumeState = new AverageVolumeInfoStore({
      tradePairProvider,
      baseUSDPriceProvider,
      volumeDataProvider: this,
    });

    this._modeImpactInfoStateProvider = new ModeImpactInfoStoreProvider({
      swapSettingsProvider,
      settingsProvider,
      tradePairProvider,
      pairAddressProvider,
      botChainInfoProvider,
      impactDataProvider: this,
    });

    makeLoggable(this, { info: true });
  }

  private get _impactInfoState() {
    return this._modeImpactInfoStateProvider.impactInfoState;
  }

  private get _settings() {
    return this._settingsProvider.settings;
  }

  private get _averageVolumeBase() {
    return this._averageVolumeState.averageVolumeBase;
  }

  private get _averageVolumeUSD() {
    return this._averageVolumeState.averageVolumeUSD;
  }

  get inputAmount() {
    return this._settings.volume_data.max_amount.toString();
  }

  get trades() {
    return {
      min: this._settings.volume_data.max_trades,
      max: this._settings.volume_data.min_trades,
    };
  }

  get amounts() {
    return {
      min: this._settings.volume_data.max_amount,
      max: this._settings.volume_data.min_amount,
    };
  }

  get period() {
    return this._settings.volume_data.period;
  }

  get info(): Omit<DEXV2VolumeInfo, "gasEstimate" | "last24hFees"> {
    return {
      averageVolume: {
        base: this._averageVolumeBase,
        usd: this._averageVolumeUSD,
      },
      priceImpact: this._impactInfoState?.priceImpact ?? {},
    };
  }

  destroy = () => {
    this._modeImpactInfoStateProvider.destroy();
    this._averageVolumeState.destroy();
  };
}
