import { SortingState, Updater } from "@tanstack/react-table";
import { makeAutoObservable, runInAction } from "mobx";
import { getPartiesList } from "src/api/marketCheck";
import { FilterParams, PaginationParams, SortParams } from "src/api/types";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { DurationWithMonth } from "src/helpers/dateUtils";
import { logError } from "src/helpers/network/logger";
import RangePickerStore from "src/state/shared/RangePicker";
import {
  DepthPercentValue,
  PartiesListPaginationParams,
  PartiesListParams,
  PartyMarketCheck,
  SortColumns,
} from "./types";

const DEFAULT_MARKET_CHECK_RANGE: DurationWithMonth = { hours: 12 };

export const DEPTH_PERCENTS = ["2", "5", "10", "20"] as const;

const DEFAULT_PAGINATION_STATE: PaginationState = {
  currentPage: 0,
  pagesCount: 0,
  itemsPerPage: 10,
};

interface PaginationState {
  currentPage: number;
  pagesCount: number;
  itemsPerPage: number;
}

export class MarketCheckStore {
  private _partiesList: PartyMarketCheck[] = [];

  private _depth: DepthPercentValue = "2";

  private _isLoading = false;

  private _paginationState = DEFAULT_PAGINATION_STATE;

  private _sortingState?: SortParams<SortColumns>;

  private _filterState?: FilterParams;

  private _rangeState: RangePickerStore;

  constructor() {
    this._rangeState = new RangePickerStore(this, DEFAULT_MARKET_CHECK_RANGE);

    makeAutoObservable(this);
  }

  get partiesList() {
    return this._partiesList;
  }

  get isLoading() {
    return this._isLoading;
  }

  get currentPage() {
    return this._paginationState.currentPage;
  }

  get itemsPerPage() {
    return this._paginationState.itemsPerPage;
  }

  get pagesCount() {
    return this._paginationState.pagesCount;
  }

  get depth() {
    return this._depth;
  }

  get range() {
    return this._rangeState.range;
  }

  get tableSortingState(): SortingState {
    if (!this._sortingState) return [];

    const { sort_by, sort_dir } = this._sortingState;

    return [{ id: sort_by, desc: sort_dir === "desc" }];
  }

  private get _paginationQueryParams(): PaginationParams {
    const currentPage = this._paginationState.currentPage + 1;
    const pageSize = this._paginationState.itemsPerPage;

    return { page: `${currentPage}`, limit: `${pageSize}` };
  }

  private get _queryParams() {
    const paginationParams = this._paginationQueryParams;

    const queryParams: PartiesListParams = {
      depth_level: this.depth,
      from: `${this._rangeState.start}`,
      to: `${this._rangeState.end}`,
      ...paginationParams,
      ...this._filterState,
      ...this._sortingState,
    };

    return queryParams;
  }

  private _setPartiesList = (data: PartyMarketCheck[]) => {
    this._partiesList = data;
  };

  private _setIsLoading = (loading: boolean) => {
    this._isLoading = loading;
  };

  setPage = (page: number) => {
    this._paginationState.currentPage = page;
  };

  getPaginationPartiesList = async ({
    fromPage,
    pageSize,
    onSuccess,
  }: PartiesListPaginationParams) => {
    this._paginationState.currentPage = fromPage;
    this._paginationState.itemsPerPage = pageSize;

    const isSuccess = await this.loadData();

    if (isSuccess) onSuccess?.();
  };

  setRange = (period: DateTimeRange) => this._rangeState.setRange(period);

  setDepth = (depth: DepthPercentValue) => {
    this._depth = depth;

    this.loadData();
  };

  setTableSortingState = (updater: Updater<SortingState>) => {
    this._updateSortingState(updater);

    this.loadData();
  };

  private _updateSortingState = (updater: Updater<SortingState>) => {
    if (typeof updater === "function") {
      const newState = updater(this.tableSortingState);

      this._setSortingState(newState);
    } else this._setSortingState(updater);
  };

  private _setSortingState = (sortingState: SortingState) => {
    const [sort] = sortingState;

    if (!sort) {
      this._sortingState = undefined;
      return;
    }

    const { id, desc } = sort;

    this._sortingState = { sort_by: id as SortColumns, sort_dir: desc ? "desc" : "asc" };
  };

  loadData = async () => {
    if (this._rangeState.start && this._rangeState.end)
      return await this._loadPartiesList(this._queryParams);
  };

  private _loadPartiesList = async (partiesListParam: PartiesListParams) => {
    this._setIsLoading(true);

    try {
      const { isError, data } = await getPartiesList(partiesListParam);

      if (!isError) {
        runInAction(() => {
          this._setPartiesList(data.items);
          this._paginationState.pagesCount = data.meta.pages;
        });

        return true;
      }
      this._setPartiesList([]);

      return false;
    } catch (error) {
      logError(error);
      this._setPartiesList([]);
      return false;
    } finally {
      this._setIsLoading(false);
    }
  };

  destroy = () => {};
}
