import { Dayjs } from "dayjs";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { unixToMs } from "src/helpers/dateUtils";
import { roundToTwoDecimals } from "src/helpers/rounding";
import { ChartSeriesData, RawSeriesData } from "src/modules/dashboard";

export const rawSeriesToData = ({ value, time }: RawSeriesData): ChartSeriesData => ({
  value: valuesToNumber(value),
  time: timeToMs(time),
});

export const timeToMs = (times: number[]) => times.map(unixToMs);

export const valuesToNumber = (values: (string | number)[], useRounding = true) =>
  values.map((value) => {
    const numberValue = +value;
    return useRounding ? roundToTwoDecimals(numberValue) : numberValue;
  });

// utils to pad data for chartjs to avoid clipping
export const padValues = <T>(values: T[]): T[] => {
  if (values.length < 2) {
    return values;
  }

  return [null, ...values, null] as T[];
};

export const padTime = (times: number[]) => {
  if (times.length < 2) {
    return times;
  }

  // assuming all bars step is the same
  const barsTimeStep = times[1] - times[0];

  const startTime = times[0];
  const endTime = times[times.length - 1];

  const startBarTime = startTime - barsTimeStep;

  const endBarTime = endTime + barsTimeStep;

  const paddedTime = [startBarTime, ...times, endBarTime];

  return paddedTime;
};

type BaseSeriesData<T> = {
  value: T[];
  time: number[];
};

export const padSeriesData = <T>(series: BaseSeriesData<T>) => {
  const paddedTime = padTime(series.time);
  if (!paddedTime) return series;
  const paddedValues = padValues(series.value);

  return { time: paddedTime, value: paddedValues };
};

export const getMinLimitRange = (min: Dayjs, range: DateTimeRange): DateTimeRange | null => {
  const [start, end] = range;

  if (!start || !end) return null;

  if (end.isSameOrBefore(min, "second")) {
    return null;
  }

  const isMinInRange = min.isBetween(start, end, "second", "[)");

  return isMinInRange ? [min, end] : range;
};

export const getMaxLimitRange = (max: Dayjs, range: DateTimeRange): DateTimeRange | null => {
  const [start, end] = range;

  if (!start || !end) return null;

  if (start.isSameOrAfter(max, "second")) {
    return null;
  }

  const isMaxInRange = max.isBetween(start, end, "second", "(]");

  return isMaxInRange ? [start, max] : range;
};
