import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { DEXV2PermissionsWrapper } from "../../shared/Permissions/DEXV2PermissionsWrapper";
import { SaveModuleButton } from "../Buttons/SaveModuleButton";
import { PanelComponents, SaveableProps } from "../Volume";
import { OrientationOuterProps } from "../shared/SettingsFormContent/style";
import * as styles from "./style";

interface UseWalletsSettingsParams extends OrientationOuterProps {
  isMobile: boolean;
}

const useWalletsSettings = ({ orientation, isMobile }: UseWalletsSettingsParams) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  const { hasAbility } = useLateInitContext(AbilitiesContext);
  const saveTransferEnabled = hasAbility(DEXV2Ability.TransferWalletsEdit);
  const saveSwapEnabled = hasAbility(DEXV2Ability.SwapWalletsEdit);

  const TransferWallets = (
    <DEXV2PermissionsWrapper ability={DEXV2Ability.TransferWalletsView}>
      <styles.TransferWalletsSettings
        orientation={orientation}
        onSubmit={state.updateTransferWallets}
        disableSubmit={saveTransferEnabled}
        loader={state.transferWalletsLoading}
      >
        <styles.TransferWalletsPanel isMobile={isMobile} saveable={saveTransferEnabled} />
      </styles.TransferWalletsSettings>
    </DEXV2PermissionsWrapper>
  );

  const SwapWallets = (
    <DEXV2PermissionsWrapper ability={DEXV2Ability.SwapWalletsView}>
      <styles.SwapWalletsSettings
        orientation={orientation}
        onSubmit={state.updateSwapWallets}
        loader={state.swapWalletsLoading}
        disableSubmit={saveSwapEnabled}
      >
        <styles.SwapWalletsPanel isMobile={isMobile} saveable={saveSwapEnabled} />
      </styles.SwapWalletsSettings>
    </DEXV2PermissionsWrapper>
  );

  return { transfer: TransferWallets, swap: SwapWallets };
};

export interface MainProps extends ComponentPropsWithoutRef<"div">, SaveableProps {}

export const Main = observer(({ ...props }: MainProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  const isMobile = useMediaQuery(getMobileMediaQuery("700px"));
  const settingsOrientation = isMobile ? "column" : "row";

  const managePanelComponents: PanelComponents = {
    btn: <SaveModuleButton module="base_data" />,
  };

  const { swap: SwapWallets, transfer: TransferWallets } = useWalletsSettings({
    isMobile,
    orientation: settingsOrientation,
  });

  return (
    <styles.SettingsContainer {...props} $orientation={settingsOrientation}>
      <styles.MainSettings
        orientation={settingsOrientation}
        onSubmit={state.submitModuleHandler("base_data")}
        loader={state.moduleLoading("base_data")}
      >
        <styles.MainPanel {...managePanelComponents} />
      </styles.MainSettings>
      {TransferWallets}
      {SwapWallets}
    </styles.SettingsContainer>
  );
});
