import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { pushOrReplaceHistory } from "src/helpers/router";
import { useLateInitContext } from "src/hooks/useLateInitContext";

const useDeployLink = () => {
  const { botUUID } = useLateInitContext(DEXV2BotContext);

  const history = useHistory();

  const goToDeploy = useCallback(() => {
    const deployLink = `/DEXCreating/${botUUID}/?tab=GasFill`;
    pushOrReplaceHistory(history, deployLink);
  }, [botUUID, history]);

  return goToDeploy;
};

interface DeployButtonProps {}

export const DeployButton = observer((props: DeployButtonProps) => {
  const goToDeploy = useDeployLink();

  return (
    <Button size="s" onClick={goToDeploy}>
      Go to deploy
    </Button>
  );
});
