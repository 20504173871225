import { SwitchMenu } from "src/components/shared/SwitchMenu";
import { Headline1, Subtitle } from "src/components/shared/Typography";
import { EmptyTextLineHeight } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SelectorsRow = styled.div`
  min-height: 50px;
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled(Headline1)`
  font-size: ${({ theme }) => theme.fontSizes["24px"]};
`;

export const PartyText = styled(Subtitle).attrs({ as: "p" })`
  font-size: ${({ theme }) => theme.fontSizes["13px"]};

  ${EmptyTextLineHeight}
`;

export const ViewSwitch = styled(SwitchMenu)`
  max-width: 200px;
`;
