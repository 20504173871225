import { observer } from "mobx-react-lite";
import { WalletLabeledInput } from "src/components/BotCreating/DEX/shared/WalletLabeledInput";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SettingsPanel } from "../../shared/SettingsPanel";
import { FormContent } from "../../shared/style";
import { TransferWalletsPanelProps } from "../TransferWalletsPanel";
import { MainWalletsList } from "../TransferWalletsPanel/style";

export interface SwapWalletsPanelProps extends TransferWalletsPanelProps {}

export const SwapWalletsPanel = observer(
  ({ isMobile, saveable, ...props }: SwapWalletsPanelProps) => {
    const { swapWalletsState: state, swapWalletsSubmitState: submitState } =
      useLateInitContext(DEXV2SettingsContext);

    const addEnabled = saveable && Boolean(state.wallet);

    return (
      <SettingsPanel label="Swap Wallets" {...props}>
        <FormContent>
          <WalletLabeledInput
            label="Swap Wallet"
            onAdd={submitState.addUpdateWallet}
            addEnabled={addEnabled}
            value={state.wallet}
            onChange={state.setWallet}
            errorHint={state.walletErrors}
          />
          <MainWalletsList
            hashes={state.walletsAsFieldProps}
            hashesErrors={state.walletsError}
            onDeleteHash={submitState.deleteUpdateWallet}
            showDelete={saveable}
            fixedHeight={isMobile}
          />
        </FormContent>
      </SettingsPanel>
    );
  }
);
