import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { AnalyticsContext } from "src/context/CEX/Analytics";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import WindowConsent from "src/state/WindowConsent";
import * as styles from "./style";

export const FooterPanel = observer(() => {
  const state = useLateInitContext(AnalyticsContext);

  const remove = useCallback(() => {
    WindowConsent.showWindow(
      "Are you sure you want to delete the scope:",
      `${state.currentChart?.name}?`,
      state.deleteAnalytic
    );
  }, [state]);

  return (
    <styles.Footer>
      <styles.Wrapper>
        <styles.RowWrapper>
          <styles.InfoRow>
            Created at:{" "}
            <styles.ValueRow>
              {state.currentChart?.created_at
                ? unixToDateFormat(state.currentChart?.created_at, "FullDate")
                : ""}
            </styles.ValueRow>
            {"  "}
            by:
            {"  "}
            <styles.ValueRow>{state.currentChart?.author_created}</styles.ValueRow>
          </styles.InfoRow>
          <styles.InfoRow>
            Updated at:{" "}
            <styles.ValueRow>
              {state.currentChart?.updated_at
                ? unixToDateFormat(state.currentChart?.updated_at, "FullDate")
                : ""}
            </styles.ValueRow>
            {"  "}
            by:
            {"  "}
            <styles.ValueRow>{state.currentChart?.author_updated}</styles.ValueRow>
          </styles.InfoRow>
        </styles.RowWrapper>
      </styles.Wrapper>

      <PermissionWrapper abilityName="cex-candle-analytics-edit" party={state.party}>
        <styles.Wrapper>
          <OutlineButton disabled={!state.strategyChosen} onClick={() => remove()}>
            Delete strategy
          </OutlineButton>
          <Button disabled={!state.strategyChosen} onClick={() => state.saveAnalytic()}>
            Save strategy
          </Button>
        </styles.Wrapper>
      </PermissionWrapper>
    </styles.Footer>
  );
});
