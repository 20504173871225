import { Chip } from "./Chip";
import * as styles from "./style";

export type TagItem = {
  label: string;
  count: number;
  color: string;
};

export interface TagListProps {
  tags: TagItem[];
}

export const TagList = ({ tags }: TagListProps) => (
  <styles.Container>
    {tags.map(({ label, count, color }) => (
      <Chip key={label} label={`${label} (${count})`} color={color} />
    ))}
  </styles.Container>
);
