import { observer } from "mobx-react-lite";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import ModuleFiltersStore, {
  ModulesFilterKey,
} from "src/state/expertSystem/modules/Filters/ModuleFiltersStore";
import { FilterPanelContent } from "../../../../shared/FilterPanel/FilterPanelContent";
import { useModuleState } from "../../../hooks/useModuleState";
import { SelectorFilter } from "../../../shared/ModuleFilters/SelectorFIlter";

export const MODULES_FILTERS_TITLES: Record<ModulesFilterKey, string> = {
  type: "Type",
  status: "Status",
};

export interface ConditionsFiltersContentProps {}

export const ConditionsFiltersContent = observer((props: ConditionsFiltersContentProps) => {
  const { updateFilters, filtersState } = useModuleState("condition");

  const { close } = useLateInitContext(PopupContext);

  const state = useLocalStore(ModuleFiltersStore, {
    type: "condition",
    initialState: filtersState,
    onSubmit: updateFilters,
    onClose: close,
  });

  const { getSelectorProps, reset, submit } = state;

  return (
    <FilterPanelContent onReset={reset} onSubmit={submit}>
      <SelectorFilter label={MODULES_FILTERS_TITLES.type} {...getSelectorProps("type")} />
      <SelectorFilter label={MODULES_FILTERS_TITLES.status} {...getSelectorProps("status")} />
    </FilterPanelContent>
  );
});
