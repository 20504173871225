import styled from "styled-components";
import * as VaultsStyles from "../../Stats/Tables/Vaults/styles";

export const VaultsPanel = styled(VaultsStyles.VaultsPanel)``;

export const VaultsTable = styled(VaultsStyles.VaultsTable)`
  grid-template-columns:
    minmax(150px, 1.65fr) minmax(60px, 1fr)
    minmax(50px, 1.65fr)
    minmax(50px, 1.65fr)
    90px;
`;

export const AddressWrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 10px;
`;

export const AddressTitle = styled.div`
  text-align: left;
`;
