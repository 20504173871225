import { ToggleButton } from "src/components/shared/Buttons/v2/ToggleButton";
import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const LightNotesSvg = styled.span`
  & > svg * {
    stroke: ${({ theme }) => theme.backgroundWhiteColor};
  }
`;

export const TradingHistoryContainer = styled(PanelContainer)`
  position: relative;

  grid-area: trade-history;

  padding: 0px 5px 5px;
`;

export const TradeHistoryTitleContainer = styled.div`
  grid-area: trade-history-title;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  align-items: center;
  margin: 0;
`;

export const NotesToggleButton = styled(ToggleButton)`
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
`;
