import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import { DashboardBreakpoints } from "../../../shared/style";

export enum BalanceSummaryArea {
  AumSummary = "aum-summary",
  BalancesDynamics = "balances-dynamics",
  BalancesOverview = "balances-overview",
  BalancesInfo = "balances-info",
  Exchanges = "exchanges",
  AccountBalances = "account-balances",
  FiatBalance = "fiat-balance",
  TokensBalances = "tokens-balances",
  TrackableTokens = "trackable-tokens",
}

export const Container = styled.div`
  width: 100%;

  display: grid;

  grid-template-columns: minmax(240px, 2fr) minmax(140px, 1fr) minmax(230px, 2fr);
  grid-template-rows: 150px 60px 350px 400px repeat(2, 260px);

  gap: 8px;

  grid-template-areas:
    "${BalanceSummaryArea.AumSummary} ${BalanceSummaryArea.BalancesInfo} ${BalanceSummaryArea.BalancesDynamics}"
    "${BalanceSummaryArea.TrackableTokens} ${BalanceSummaryArea.BalancesInfo} ${BalanceSummaryArea.BalancesDynamics}"
    "${BalanceSummaryArea.BalancesOverview} ${BalanceSummaryArea.BalancesOverview} ${BalanceSummaryArea.Exchanges}"
    "${BalanceSummaryArea.AccountBalances} ${BalanceSummaryArea.AccountBalances} ${BalanceSummaryArea.AccountBalances}"
    "${BalanceSummaryArea.FiatBalance} ${BalanceSummaryArea.FiatBalance} ${BalanceSummaryArea.FiatBalance}"
    "${BalanceSummaryArea.TokensBalances} ${BalanceSummaryArea.TokensBalances} ${BalanceSummaryArea.TokensBalances}";

  @media ${getMobileMediaQuery(DashboardBreakpoints.Tablet)} {
    grid-template-columns: minmax(240px, 3fr) minmax(130px, 2fr);
    grid-template-rows: 150px 60px 220px 350px 400px repeat(2, 260px);

    grid-template-areas:
      "${BalanceSummaryArea.AumSummary} ${BalanceSummaryArea.BalancesInfo}"
      "${BalanceSummaryArea.TrackableTokens} ${BalanceSummaryArea.BalancesInfo}"
      "${BalanceSummaryArea.BalancesDynamics} ${BalanceSummaryArea.BalancesDynamics}"
      "${BalanceSummaryArea.BalancesOverview} ${BalanceSummaryArea.Exchanges}"
      "${BalanceSummaryArea.AccountBalances} ${BalanceSummaryArea.AccountBalances}"
      "${BalanceSummaryArea.FiatBalance} ${BalanceSummaryArea.FiatBalance}"
      "${BalanceSummaryArea.TokensBalances} ${BalanceSummaryArea.TokensBalances}";
  }

  @media ${getMobileMediaQuery(DashboardBreakpoints.XMobile)} {
    grid-template-columns: minmax(150px, 1fr);
    grid-template-rows: 150px 60px 368px auto 350px 480px 400px repeat(2, 260px);
    grid-template-areas:
      "${BalanceSummaryArea.AumSummary}"
      "${BalanceSummaryArea.TrackableTokens}"
      "${BalanceSummaryArea.BalancesDynamics}"
      "${BalanceSummaryArea.BalancesInfo}"
      "${BalanceSummaryArea.BalancesOverview}"
      "${BalanceSummaryArea.Exchanges}"
      "${BalanceSummaryArea.AccountBalances}"
      "${BalanceSummaryArea.FiatBalance}"
      "${BalanceSummaryArea.TokensBalances}";
  }
`;
