import { ChartDataset } from "chart.js";
import { InOutIndicatorStatus } from "src/config/chartjs/plugins/inOutIndicator";
import { formatFiat, joinStrings } from "src/helpers/string";
import { entries } from "src/helpers/utils";
import { BalancesTokensTransactions, BalancesTokensTransactionsValue } from "src/modules/dashboard";

export const TRANSACTIONS_DATASET_LABEL = "Transactions";

export const isTransactionsDataset = (dataset: ChartDataset) =>
  dataset.label === TRANSACTIONS_DATASET_LABEL;

const hasTokenTransactions = (transactions?: BalancesTokensTransactions) => {
  if (!transactions) return undefined;
  return Object.keys(transactions).length > 0;
};

export const getTransactionsIndicatorStatus = (
  datasets: ChartDataset[],
  dataIndex: number
): InOutIndicatorStatus => {
  const transactionsDataset = datasets.find(
    (dataset) => dataset.label === TRANSACTIONS_DATASET_LABEL
  );
  if (!transactionsDataset) return {};

  const transactions = transactionsDataset.data[dataIndex] as BalancesTokensTransactionsValue;
  if (!transactions) return {};

  const { in: inTransactions, out: outTransactions } = transactions;

  const hasInTransactions = hasTokenTransactions(inTransactions);
  const hasOutTransactions = hasTokenTransactions(outTransactions);

  return {
    in: hasInTransactions,
    out: hasOutTransactions,
  };
};

export const transactionToText = (token: string, value: string) => {
  const amount = formatFiat(value, false);
  return `${token}: ${amount}`;
};

export const transactionsToText = (
  transactions: BalancesTokensTransactions | undefined,
  title = "Transactions"
) => {
  if (!transactions) return "";

  const transactionsText = entries(transactions)
    .map(([token, value]) => transactionToText(token, value))
    .join("\n");

  return joinStrings([title, transactionsText]);
};

export const getTransactionsText = (datasets: ChartDataset[], dataIndex: number) => {
  const transactionsDataset = datasets.find(
    (dataset) => dataset.label === TRANSACTIONS_DATASET_LABEL
  );

  const transactions = transactionsDataset?.data[dataIndex] as BalancesTokensTransactionsValue;

  if (!transactions) return "";
  const { in: inTransactions, out: outTransactions } = transactions;

  const inTransactionsText = transactionsToText(inTransactions, "In:");
  const outTransactionsText = transactionsToText(outTransactions, "Out:");

  const transactionsText = joinStrings([inTransactionsText, outTransactionsText], "\n\n");

  return transactionsText;
};
