import { formatFiat } from "src/helpers/string";
import * as styles from "./style";

export interface InfoPanelSectionProps {
  title: string;
  free: number;
  locked: number;
}

export const InfoPanelSection = ({ title, free, locked }: InfoPanelSectionProps) => {
  const formattedLocked = formatFiat(locked);
  const formattedFree = formatFiat(free);
  return (
    <styles.Container>
      <styles.Title>{title}</styles.Title>
      <styles.BalanceWrapper>
        <styles.BalanceText>
          {formattedFree} / {formattedLocked}
        </styles.BalanceText>
        <styles.Subtitle>free / locked</styles.Subtitle>
      </styles.BalanceWrapper>
    </styles.Container>
  );
};
