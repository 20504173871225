import { ComponentPropsWithoutRef } from "react";
import { LaunchesLimitUpdateModal } from "./LaunchesLimitUpdateModal";
import * as styles from "./style";

export interface LaunchesLimitCellProps extends ComponentPropsWithoutRef<"div"> {
  limit: number;
  uuid: string;
  showEdit?: boolean;
}

export const LaunchesLimitCell = ({ showEdit, limit, uuid, ...props }: LaunchesLimitCellProps) => (
  <styles.Container {...props}>
    <styles.LimitText>{limit}</styles.LimitText>
    {showEdit && <LaunchesLimitUpdateModal uuid={uuid} defaultLimit={limit} />}
  </styles.Container>
);
