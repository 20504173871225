import { Headline4 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  padding: 16px;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const Title = styled(Headline4)`
  color: ${({ theme }) => theme.dashboard.textSecondary};
`;
