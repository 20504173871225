import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { IBotTradePairProvider } from "../../DEXV2Bots/DEXV2BotStore";
import { IBaseUSDPriceProvider } from "../../DEXV2Swap/SwapModules/shared/Providers/BaseUsdPriceProvider";
import { parseBaseQuote } from "../utils";

type VolumeRangeData = {
  min: number | string;
  max: number | string;
};

export interface IAverageVolumeDataProvider {
  get trades(): VolumeRangeData;
  get amounts(): VolumeRangeData;
  get period(): number | string | undefined;
}

export interface IVolumeInfoParams {
  volumeDataProvider: IAverageVolumeDataProvider;
  tradePairProvider: IBotTradePairProvider;
  baseUSDPriceProvider: IBaseUSDPriceProvider;
}

export interface IAverageVolumeInfo {
  get averageVolumeBase(): CurrencyAmount<Token> | undefined;
  get averageVolumeUSD(): CurrencyAmount<Token> | undefined;
}

const MINUTES_IN_DAY = 24 * 60;

export class AverageVolumeInfoStore implements IAverageVolumeInfo, IDisposable {
  private _baseUSDPriceProvider: IBaseUSDPriceProvider;

  private _tradePairProvider: IBotTradePairProvider;

  private _volumeDataProvider: IAverageVolumeDataProvider;

  constructor({ tradePairProvider, baseUSDPriceProvider, volumeDataProvider }: IVolumeInfoParams) {
    makeAutoObservable(this);

    this._baseUSDPriceProvider = baseUSDPriceProvider;

    this._tradePairProvider = tradePairProvider;

    this._volumeDataProvider = volumeDataProvider;

    makeLoggable(this, { averageVolumeBase: true });
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private get _baseUSDPrice() {
    return this._baseUSDPriceProvider.baseUSDPrice;
  }

  private get _trades() {
    return this._volumeDataProvider.trades;
  }

  private get _amount() {
    return this._volumeDataProvider.amounts;
  }

  private get _period() {
    return this._volumeDataProvider.period;
  }

  private get _rawAverageVolumeBase() {
    const { min: minAmount, max: maxAmount } = this._amount;
    const { min: minTrades, max: maxTrades } = this._trades;
    const period = this._period;

    const averageAmount = (+minAmount + +maxAmount) / 2;
    const averageTrades = (+minTrades + +maxTrades) / 2;

    if (!period) return undefined;

    const periodCount = MINUTES_IN_DAY / +period;

    const averageVolume = averageAmount * averageTrades * periodCount;
    return averageVolume;
  }

  private get _baseVolumeQuote() {
    const baseToken = this._tradePair?.base;
    const averageVolumeBase = this._rawAverageVolumeBase;
    const baseUSDPrice = this._baseUSDPrice;

    const baseQuote = parseBaseQuote(averageVolumeBase, baseToken, baseUSDPrice);

    return baseQuote;
  }

  get averageVolumeBase() {
    return this._baseVolumeQuote.base;
  }

  get averageVolumeUSD() {
    return this._baseVolumeQuote.usd;
  }

  destroy = () => {};
}
