import { SettingsForm } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel/style";
import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import styled from "styled-components";
import { SettingsTabs } from "./shared";

export const BotSettingsForm = styled.div`
  position: relative;
  display: grid;
  padding: 23px 0px;
  margin: 0 auto;

  grid-gap: 10px;
  grid-template-columns:
    minmax(166px, 193px) minmax(170px, 327px) minmax(245px, 465px) minmax(245px, 367px)
    minmax(305px, 405px);
  grid-template-rows: 104px 133px 51px 120px 89px 109px;

  grid-template-areas:
    "period       link        decimals          trade       spread"
    "pair         pair        volume            trade       spread"
    "pair         pair        volume            trade       verify"
    "name         name        binding           binding     verify"
    "finances     finances    binding           binding     verify"
    "finances     finances    binding           binding     candle"
    " _           _           saveDiff          saveDiff    submit";

  @media screen and (max-width: 1200px) {
    grid-template-columns:
      151px minmax(170px, 286px) minmax(144px, 200px) minmax(80px, 135px)
      minmax(225px, 326px);

    grid-template-rows: 104px 173px 120px 187px 260px;

    grid-template-areas:
      "period       link        decimals          decimals          trade"
      "pair         pair        volume            volume            trade"
      "name         name        binding           binding           binding"
      "finances     finances    binding           binding           binding"
      "candle       spread      spread            verify            verify"
      " _           _           saveDiff          saveDiff          submit";
  }

  @media screen and (max-width: 950px) {
    grid-template-columns: 151px minmax(130px, 316px) minmax(245px, 424px);

    grid-template-rows: 104px 173px 120px 187px 307px 227px 100px;

    grid-template-areas:
      "period       link        decimals   "
      "pair         pair        volume     "
      "name         name        trade      "
      "finances     finances    trade      "
      "binding      binding     binding    "
      "spread       spread      verify     "
      "candle       candle      verify     "
      "saveDiff     saveDiff    submit     ";
  }

  @media screen and (max-width: 650px) {
    grid-template-columns: 151px 1fr;

    grid-template-rows: 104px 104px 173px 173px 307px 120px 187px 307px 1fr;

    grid-template-areas:
      "period       link           "
      "decimals     decimals       "
      "pair         pair           "
      "volume       volume         "
      "trade        trade          "
      "name         name           "
      "finances     finances       "
      "binding      binding        "
      "spread       spread         "
      "verify       verify         "
      "candle       candle         "
      "saveDiff     saveDiff       "
      "submit       submit         ";
  }

  @media screen and (max-width: 450px) {
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    flex: 1;
  }
`;

export const BotSettingsFormFallback = styled(CrashPanel)`
  margin-top: 20px;
`;

export const TabSettings = styled(SettingsTabs)`
  ${SettingsForm}:not(:first-of-type) {
    margin-top: 13px;
  }
`;

export const PeriodWrapper = styled.div`
  width: 100%;
  margin-bottom: 13px;
`;
