import { CSSProperties } from "react";
import { GraphRootProps } from "..";
import { ChartLegend } from "../../shared/ChartLegend";
import { Legend } from "../../shared/ChartLegend/Legend";

interface UseLegendParams extends Pick<GraphRootProps, "legendOptions"> {}

export const useLegend = ({ legendOptions: { show, legend, position } = {} }: UseLegendParams) => {
  if (!show) return null;

  const LegendComponent = legend ?? Legend;

  const positionStyles: CSSProperties | undefined =
    position === "bottom" ? { order: 2 } : undefined;

  return <ChartLegend legend={LegendComponent} style={positionStyles} />;
};
