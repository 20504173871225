import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import TableTotalizerStore, {
  ColumnsTotalMap,
  ITableTotalizerOptions,
} from "src/state/CEX/CEXDashboard/shared/TableTotalizer";

const TableTotalizerContext = createLateInitContext<TableTotalizerStore<Record<string, any>>>();

export const useTableTotalizer = <
  D extends object,
  T extends ColumnsTotalMap<D>,
>(): TableTotalizerStore<D, T> => {
  const ctx = useLateInitContext(TableTotalizerContext);
  return ctx as TableTotalizerStore<D, T>;
};

export interface TableTotalizerProviderProps<D extends object, T extends ColumnsTotalMap<D>>
  extends ChildrenProps {
  options?: ITableTotalizerOptions<D, T>;
}

export const TableTotalizerProvider = <D extends object, T extends ColumnsTotalMap<D>>({
  children,
  options,
}: TableTotalizerProviderProps<D, T>) => {
  const store = useLocalStore(
    TableTotalizerStore,
    options as ITableTotalizerOptions<object, Record<string, any>>
  );

  return <TableTotalizerContext.Provider value={store}>{children}</TableTotalizerContext.Provider>;
};
