import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { logError } from "src/helpers/network/logger";
import { IDisposable, ISubscribable } from "src/helpers/utils";
import { IDashboardV2StateProvider } from "..";

export interface IDashboardV2WidgetState extends IDisposable, ISubscribable {
  onStatsUpdate: () => Promise<void>;
}

interface IBaseWidgetV2Params {
  state: IDashboardV2StateProvider;
  widgetState: IDashboardV2WidgetState;
}

export class BaseWidgetV2Store implements IDisposable, ISubscribable {
  private _state: IDashboardV2StateProvider;

  private _widgetState: IDashboardV2WidgetState;

  private _loading = false;

  private _updateStatsReaction?: IReactionDisposer;

  constructor({ state, widgetState }: IBaseWidgetV2Params) {
    makeAutoObservable(this);

    this._state = state;
    this._widgetState = widgetState;

    this._decorateWidgetState();
  }

  private _decorateWidgetStateLifecycle = (event: "destroy" | "subscribe") => {
    const currentEventCb = this._widgetState[event];

    this._widgetState[event] = () => {
      currentEventCb.call(this._widgetState);
      this[event]();
    };
  };

  private _decorateWidgetState = () => {
    this._decorateWidgetStateLifecycle("subscribe");
    this._decorateWidgetStateLifecycle("destroy");
  };

  subscribe = () => {
    this._updateStatsReaction = reaction(
      () => this._state.updatesCount,
      () => {
        this.getStats();
      },
      { fireImmediately: true }
    );
  };

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  getStats = async () => {
    this._setLoading(true);
    try {
      await this._widgetState.onStatsUpdate();
    } catch (error) {
      logError(error);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._updateStatsReaction?.();
  };
}
