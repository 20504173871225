import { ButtonProps } from "src/components/shared/Buttons/v2/Button";
import * as styles from "./style";

export interface RefreshDataButtonProps extends ButtonProps {
  onRefresh: () => void;
}

export const RefreshDataButton = ({ onRefresh, ...props }: RefreshDataButtonProps) => (
  <styles.StyledButton
    size="m"
    style={{ alignSelf: "flex-start" }}
    onClick={() => onRefresh()}
    color="secondary"
    variant="outline"
    {...props}
  >
    Refresh data
  </styles.StyledButton>
);
