import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { V2SwapRoute } from "../../v2/Swap/Providers/V2RouteStateProvider";
import { ISwapInfoRoute } from "../SwapInfoRouteStore";
import { IBaseSwapInfoImpact } from "./SwapInfoImpactV3Store";

interface ISwapInfoImpactV2Params {
  routeInfo: ISwapInfoRoute;
}

export class SwapInfoImpactV2Store implements IBaseSwapInfoImpact, IDisposable {
  private _routeInfo: ISwapInfoRoute;

  constructor({ routeInfo }: ISwapInfoImpactV2Params) {
    makeAutoObservable(this);

    makeLoggable(this, {});

    this._routeInfo = routeInfo;
  }

  private get _swapRoute() {
    return this._routeInfo.swapRoute;
  }

  /**
   * we denote v2 price impact not as diff between midPrice and executionPrice
   * but the ratio between midPrice and nextMidPrice
   *  */
  get priceImpact() {
    const trade = this._swapRoute?.trade as V2SwapRoute["trade"] | undefined;

    const priceImpactPercent = trade?.nextPriceImpact;

    return priceImpactPercent;
  }

  destroy = () => {};
}
