import { makeAutoObservable, runInAction } from "mobx";
import { placeLiquidity } from "src/api/bots/CEX/exchange";
import { getPathAndKey, getValueByPath } from "src/helpers/forms/getByKey";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { toRounding } from "src/helpers/rounding";
import { BalanceComparisonProps, balanceComparison, getAccBalances } from "src/helpers/trading";
import { PlaceLiquidity } from "src/modules/exchange/trade";
import { ChangeValidate, SelectorValue } from "src/modules/shared";
import {
  graterThan,
  graterThanKey,
  isNumber,
  required,
  smallerThan,
  smallerThanKey,
  validateData,
} from "src/validation-schemas";
import ExchangeStore from "..";
import windowConsent from "../../../WindowConsent";
import {
  accountsMapToSelectorValue,
  filterBaseNameAccounts,
  sortAccounts,
} from "../../CEXApiKeys/AccountsBindings";

type SelectorFields = "account_uuid" | "side";

type InputFields = "number" | "priceMax" | "priceMin" | "assetMax" | "assetMin";

class LiquidityStore {
  data: PlaceLiquidity = {
    number: "",
    priceMax: "",
    priceMin: "",
    assetMax: "",
    assetMin: "",
    account_uuid: "",
    side: "",
  };

  pricePrecision: number = 0;

  amountPrecision: number = 0;

  showLoader: boolean = false;

  private _curve = false;

  validation = {
    number: [
      required(),
      graterThan(0, "The value must be positive"),
      smallerThan(25, "The number of orders must not exceed 25"),
      isNumber(),
    ],
    priceMax: [
      required(),
      graterThan(0, "The value must be positive"),
      isNumber(),
      graterThanKey("priceMin", "Price max must be greater than Price min"),
    ],
    priceMin: [
      required(),
      graterThan(0, "The value must be positive"),
      isNumber(),
      smallerThanKey("priceMax", "Price min should be less than Price max"),
    ],
    assetMax: [
      required(),
      graterThan(0, "The value must be positive"),
      isNumber(),
      graterThanKey("assetMin", "Asset max must be greater than Asset min"),
    ],
    assetMin: [
      required(),
      graterThan(0, "The value must be positive"),
      isNumber(),
      smallerThanKey("assetMax", "Asset min should be less than Asset max"),
    ],
    account_uuid: required(),
    side: required(),
  };

  onChangeValidate: ChangeValidate = {
    priceMax: ["priceMin", "priceMax"],
    priceMin: ["priceMin", "priceMax"],
    assetMax: ["assetMax", "assetMin"],
    assetMin: ["assetMax", "assetMin"],
  };

  errors = {
    number: "",
    priceMax: "",
    priceMin: "",
    assetMax: "",
    assetMin: "",
    account_uuid: "",
    side: "",
  };

  mainState: ExchangeStore;

  constructor(state: ExchangeStore) {
    this.mainState = state;

    makeAutoObservable(this);
  }

  get accounts() {
    const accounts = filterBaseNameAccounts(this.mainState._accounts, ["info", "mm"]);

    const accountsOptions = accountsMapToSelectorValue(accounts).sort((a, b) =>
      sortAccounts(a.label, b.label)
    );

    return accountsOptions;
  }

  get currentNameAcc() {
    const currAcc = this.accounts.find((el) => el.id === this.data.account_uuid);
    if (currAcc) return currAcc;

    return { label: "", value: "", id: "" };
  }

  get curve() {
    return this._curve;
  }

  private get _liquidityMode() {
    return this._curve ? "curve" : "linear";
  }

  setField = <K extends keyof this>(field: K, value: this[K]) => {
    this[field] = value;
  };

  getHandler = (field: InputFields) => (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setValue(field, this.getChangeEventValue(e));
    if (field in this.onChangeValidate) {
      this.validate(this.onChangeValidate[field]);
    }
  };

  checkBoxHandler = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    this._curve = e.target.checked;
  };

  setValue = <K extends keyof PlaceLiquidity>(field: K, value: PlaceLiquidity[K]) => {
    this.data[field] = value;
  };

  getChangeEventValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    return "";
  };

  getError = (key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  selectorHandler = (field: SelectorFields) => (data: SelectorValue | null) => {
    switch (field) {
      case "side": {
        if (data) this.setValue(field, String(data.value));
        break;
      }
      case "account_uuid": {
        if (data?.id) this.setValue(field, String(data.id));
        break;
      }
    }
  };

  validate = (validateKeys: string[] | undefined) =>
    validateData(this.validation, this.data, this.errors, validateKeys);

  submitHandler = () => (e: React.FormEvent) => {
    e.preventDefault();
    const valid = this.validate(undefined);
    const [message, need] = this.confirmLiquidity();
    if (valid) {
      windowConsent.showWindow(
        "You agree to the terms of the transaction Liquidity",
        message,
        this.liquidityHandler(+need)
      );
    }
  };

  liquidityHandler = (amountOrder: number) => async () => {
    const [quote, base] = this.mainState.pair.split("_");

    this.setField("showLoader", true);

    try {
      const balances = await getAccBalances({
        account_uuid: this.data.account_uuid,
        account_name: this.currentNameAcc.label,
        quoteTicker: quote,
        baseTicker: base,
      });

      if (balances) {
        let balanceComparisonProps: BalanceComparisonProps | null = null;

        if (this.data.side === "BUY") {
          balanceComparisonProps = {
            balance: balances.quoteBalance,
            account_name: this.currentNameAcc.label,
            ticker: quote,
            amount: amountOrder,
            typeOperation: "LIQUIDITY",
          };
        } else {
          balanceComparisonProps = {
            balance: balances.baseBalance,
            account_name: this.currentNameAcc.label,
            ticker: base,
            amount: amountOrder,
            typeOperation: "LIQUIDITY",
          };
        }

        const checkBalance = balanceComparison(balanceComparisonProps);

        if (checkBalance) {
          await this.liquidityRequest();
        } else {
          const textMessage = `Are you sure you want to place liquidity a ${
            this.data.side
          } from ${balanceComparisonProps.account_name.toUpperCase()}?`;

          const subTextMessage = `Insufficient funds in the account.\n 
              You need ${balanceComparisonProps.amount} ${balanceComparisonProps.ticker}`;

          windowConsent.showWindow(textMessage, subTextMessage, this.liquidityRequest);
        }
      }
    } catch (error) {
      logError(error);
    } finally {
      this.setField("showLoader", false);
    }
  };

  liquidityRequest = async () => {
    const data = {
      ...this.data,
      symbol: this.mainState.pair,
      precisionPrice: this.mainState.pricePrecision,
      precisionAmount: this.mainState.amountPrecision,
    };

    try {
      const { isError } = await placeLiquidity({ data, mode: this._liquidityMode });

      if (!isError) {
        showSuccessMsg("Applications were created successfully");

        this.mainState.updLimitTradingData();
      }
    } catch (error) {
      logError(error);
    }
  };

  confirmLiquidity = () => {
    let message = "";
    const [asset, token] = this.mainState.pair.split("_");
    let need = 0;
    const needAmount = [];
    if (this.data.side === "SELL") {
      need = +toRounding(
        ((Number(this.data.assetMin) + Number(this.data.assetMax)) /
          (Number(this.data.priceMin) + Number(this.data.priceMax))) *
          +this.data.number,
        this.amountPrecision
      );

      message = `${token}`;
    } else {
      need = +toRounding(
        ((+this.data.assetMin + +this.data.assetMax) / 2) * +this.data.number,
        this.pricePrecision
      );

      message = `${asset}`;
    }

    needAmount.push(`
    For this action you need around ${need} ${message}
  `);
    needAmount.push(need);
    return needAmount;
  };
}

export default LiquidityStore;
