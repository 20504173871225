import { makeAutoObservable, runInAction, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import {
  getBotSettings,
  getDecimals,
  getSpreadPeriods,
  getVerifyPeriods,
  setBotSettings,
} from "src/api/bots/CEX/settings";
import { ConstraintsInputProps } from "src/components/BotsContent/CEX/CEXBotSettings/shared";
import { createConstraintModalStyledText } from "src/components/BotsContent/CEX/CEXBotSettings/utils";
import { LabeledInputProps } from "src/components/shared/Forms/Inputs";
import { toast } from "src/components/shared/Toaster";
import { botNameToParts } from "src/helpers/botName";
import { getCurrentUnix, hoursToSeconds, unixToDateFormat } from "src/helpers/dateUtils";
import {
  getData,
  getPathAndKey,
  getTargetValueByPath,
  getValueByPath,
} from "src/helpers/forms/getByKey";
import { FormDataKeys, FormErrors, FormValidation } from "src/helpers/forms/types";
import { toRounding } from "src/helpers/rounding";
import { CEXSettings, Decimals, DecompPoint, PairDecimals } from "src/modules/settings";
import { SelectorValue } from "src/modules/shared";
import WindowConsent from "src/state/WindowConsent";
import {
  graterThan,
  graterThanKey,
  greaterThanNumberKey,
  isInteger,
  isNumber,
  notEqual,
  required,
  shortThan,
  smallerThanKey,
  smallerThanNumberKey,
  validateData,
} from "src/validation-schemas";
import { CEXBindingModule } from "../botModules";
import ConstraintsStore from "./ConstraintsStore";
import { ICurrentCEXBindings } from "./bindingsBots";

export const EMPTY_DECIMALS: PairDecimals = {
  decimals: {
    price: "",
    amount: "",
  },
};

type SuggestionsDeepKeys = FormDataKeys<PairDecimals>;

export type Submit<P, R = void> = (param: P) => Promise<R>;

export type SubmitConfig<P, R = void> = {
  func: Submit<P, R>;
  param: P;
};

export type ConstraintLevel = "warning" | "alert";

export type ErrorType = "error" | ConstraintLevel;

export type FieldError = {
  type: ErrorType;
  message: string;
};

export type FieldErrorProps = Required<
  ConstraintsInputProps & Pick<LabeledInputProps, "errorHint">
>;

const INITIAL_CEX_SETTINGS: CEXSettings = {
  link: "",
  name: "",
  saveDiff: true,
  settings: {
    pair: {
      exchange: "",
      quote: "",
      base: "",
      minExchangeAmount: 0,
      dontCheckAmount: 0,
      dontTradePriceMax: 0,
      dontTradePriceMin: 0,
      candlePercentMax: 0,
      candlePercentMin: 0,
    },
    period: {
      value: 0,
    },
    volume: {
      tradePerDayMinUSD: 0,
      tradePerDayMaxUSD: 0,
      modifyTradePerDayMinUSD: 0,
      modifyTradePerDayMaxUSD: 0,
      modifyDeltaBalanceQuoteUSD: 0,
      modifyDeltaBalanceBase: 0,
    },
    trade: {
      minTrades: 0,
      maxTrades: 0,
      buyPercent: 0,
      buyBeforeSellMin: 0,
      buyBeforeSellMax: 0,
    },
    decimals: {
      amount: "",
      price: "",
    },
    virtualRange: {
      binding: {
        kind: "bot",
        mainBot: "",
        rightToLeft: true,
        same: true,
        mainBotAsset: "",
        currentAsset: "",
        name: "",
        id: 0,
      },
      cancelSpreadOrders: true,
      enableBuyWall: true,
      enableSellWall: true,
      buyMinAmount: 0,
      buyMaxAmount: 0,
      sellMinAmount: 0,
      sellMaxAmount: 0,
      spread: 0,
      diff: 0,
      ratio: 0,
    },
    spreadDecompression: {
      on: false,
      alongMode: {
        oneTimeLimit: "0",
        dayLimit: "0",
      },
      oppositeMode: {
        oneTimeLimit: "0",
        dayLimit: "0",
      },
    },
    verifyOrder: {
      on: false,
      percent: "",
    },
    candleCloser: {
      on: false,
      period: 5,
      outOfRange: false,
    },
  },
};

export type CEXSettingsKeys = FormDataKeys<CEXSettings>;

type CEXSettingsModules = keyof CEXSettings["settings"];
export class CEXSettingsStore {
  data: CEXSettings = INITIAL_CEX_SETTINGS;

  private _savedSettings: CEXSettings = INITIAL_CEX_SETTINGS;

  private _suggestions: PairDecimals = EMPTY_DECIMALS;

  private _constraints: ConstraintsStore;

  handlers: any = {};

  maxBuy = 0;

  minSell = 0;

  hideDiff = false;

  hideBuyPercent = false;

  recomendDiff: number | null = null;

  loader = false;

  private _loadsCount: Partial<Record<string, number>> = {};

  validation: FormValidation<CEXSettings> = {
    link: required(),
    name: shortThan(50),
    "settings.period.value": required(),
    "settings.pair.minExchangeAmount": [required(), isNumber()],
    "settings.pair.dontCheckAmount": [required(), isNumber()],
    "settings.pair.dontTradePriceMax": [
      required(),
      isNumber(),
      graterThan(0, "The value must be positive"),
      greaterThanNumberKey("settings.pair.dontTradePriceMin", "DTPMax must be greater than DTPMin"),
    ],
    "settings.pair.dontTradePriceMin": [
      required(),
      isNumber(),
      graterThan(0, "The value must be positive"),
      smallerThanNumberKey("settings.pair.dontTradePriceMax", "DTPMin should be less than DTPMax"),
    ],
    "settings.decimals.amount": [required(), isInteger()],
    "settings.decimals.price": [required(), isInteger()],
    "settings.trade.minTrades": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      notEqual(0, "Value cannot be 0"),
      smallerThanKey("settings.trade.maxTrades", "TradesMin should be less than TradesMax"),
    ],
    "settings.trade.maxTrades": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      notEqual(0, "Value cannot be 0"),
      graterThanKey("settings.trade.minTrades", "TradesMax must be greater than TradesMin"),
    ],

    "settings.trade.buyBeforeSellMin": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      smallerThanKey("settings.trade.buyBeforeSellMax", "BBsMin should be less than BBsMax"),
    ],
    "settings.trade.buyBeforeSellMax": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      graterThanKey("settings.trade.buyBeforeSellMin", "BBsMax must be greater than BBsMin"),
    ],
    "settings.trade.buyPercent": required(),
    "settings.volume.tradePerDayMinUSD": [required(), graterThan(0, "The value must be positive")],
    "settings.volume.modifyTradePerDayMinUSD": [
      required(),
      graterThan(0, "The value must be positive"),
    ],
    "settings.volume.modifyDeltaBalanceQuoteUSD": [
      required(),
      graterThan(0, "The value must be positive"),
    ],
    "settings.volume.modifyDeltaBalanceBase": [
      required(),
      graterThan(0, "The value must be positive"),
    ],
    "settings.virtualRange.diff": [required(), isNumber()],
    "settings.virtualRange.spread": [required(), isInteger()],
    "settings.virtualRange.ratio": [required(), isNumber()],
    "settings.virtualRange.buyMinAmount": [
      required(),
      isNumber(),
      graterThan(0, "The value must be positive"),
      smallerThanKey(
        "settings.virtualRange.buyMaxAmount",
        "Buy min amount should be less than buy max amount"
      ),
    ],
    "settings.virtualRange.buyMaxAmount": [
      required(),
      isNumber(),
      graterThan(0, "The value must be positive"),
      graterThanKey("settings.virtualRange.buyMinAmount", "Buy max amount must be buy min amount"),
    ],
    "settings.virtualRange.sellMinAmount": [
      required(),
      isNumber(),
      graterThan(0, "The value must be positive"),
      smallerThanKey(
        "settings.virtualRange.sellMaxAmount",
        "Sell min amount should be less than sell max amount"
      ),
    ],
    "settings.virtualRange.sellMaxAmount": [
      required(),
      isNumber(),
      graterThan(0, "The value must be positive"),
      graterThanKey(
        "settings.virtualRange.sellMinAmount",
        "Sell max amount must be sell min amount"
      ),
    ],

    "settings.spreadDecompression.alongMode.oneTimeLimit": [
      required(),
      isNumber(),
      graterThan(0, "The value must be positive"),
    ],
    "settings.spreadDecompression.alongMode.dayLimit": [
      required(),
      isNumber(),
      graterThanKey(
        "settings.spreadDecompression.alongMode.oneTimeLimit",
        "Day limit amount must be greater than one time limit"
      ),
    ],
    "settings.spreadDecompression.oppositeMode.oneTimeLimit": [
      required(),
      isNumber(),
      graterThan(0, "The value must be positive"),
    ],
    "settings.spreadDecompression.oppositeMode.dayLimit": [
      required(),
      isNumber(),
      graterThanKey(
        "settings.spreadDecompression.alongMode.oneTimeLimit",
        "Day limit amount must be greater than one time limit"
      ),
    ],

    "settings.verifyOrder.percent": [
      required(),
      isNumber(),
      graterThan(0, "The value must be positive"),
    ],

    "settings.candleCloser.period": [required(), graterThan(1, "The value must be positive")],
  };

  validModules: Record<CEXSettingsModules, CEXSettingsKeys[]> = {
    period: ["settings.period.value"],
    pair: [
      "settings.pair.minExchangeAmount",
      "settings.pair.dontCheckAmount",
      "settings.pair.dontTradePriceMax",
      "settings.pair.dontTradePriceMin",
    ],
    volume: [
      "settings.volume.tradePerDayMinUSD",
      "settings.volume.modifyTradePerDayMinUSD",
      "settings.volume.modifyDeltaBalanceQuoteUSD",
      "settings.volume.modifyDeltaBalanceBase",
    ],
    trade: [
      "settings.trade.minTrades",
      "settings.trade.maxTrades",
      "settings.trade.buyBeforeSellMin",
      "settings.trade.buyBeforeSellMax",
      "settings.trade.buyPercent",
    ],
    decimals: ["settings.decimals.amount", "settings.decimals.price"],
    virtualRange: [
      "settings.virtualRange.diff",
      "settings.virtualRange.spread",
      "settings.virtualRange.ratio",
      "settings.virtualRange.buyMinAmount",
      "settings.virtualRange.buyMaxAmount",
      "settings.virtualRange.sellMinAmount",
      "settings.virtualRange.sellMaxAmount",
    ],
    spreadDecompression: [
      "settings.spreadDecompression.alongMode.oneTimeLimit",
      "settings.spreadDecompression.alongMode.dayLimit",
      "settings.spreadDecompression.oppositeMode.oneTimeLimit",
      "settings.spreadDecompression.oppositeMode.dayLimit",
    ],
    verifyOrder: ["settings.verifyOrder.percent"],
    candleCloser: ["settings.candleCloser.period"],
  };

  onChangeValidate: Partial<Record<CEXSettingsKeys, CEXSettingsKeys[]>> = {
    "settings.trade.minTrades": ["settings.trade.minTrades", "settings.trade.maxTrades"],
    "settings.trade.maxTrades": ["settings.trade.minTrades", "settings.trade.maxTrades"],
    "settings.trade.buyBeforeSellMin": [
      "settings.trade.buyBeforeSellMin",
      "settings.trade.buyBeforeSellMax",
    ],
    "settings.trade.buyBeforeSellMax": [
      "settings.trade.buyBeforeSellMin",
      "settings.trade.buyBeforeSellMax",
    ],
    "settings.pair.dontTradePriceMin": [
      "settings.pair.dontTradePriceMin",
      "settings.pair.dontTradePriceMax",
    ],
    "settings.pair.dontTradePriceMax": [
      "settings.pair.dontTradePriceMin",
      "settings.pair.dontTradePriceMax",
    ],
    "settings.virtualRange.buyMinAmount": [
      "settings.virtualRange.buyMinAmount",
      "settings.virtualRange.buyMaxAmount",
    ],
    "settings.virtualRange.buyMaxAmount": [
      "settings.virtualRange.buyMinAmount",
      "settings.virtualRange.buyMaxAmount",
    ],
    "settings.virtualRange.sellMinAmount": [
      "settings.virtualRange.sellMinAmount",
      "settings.virtualRange.sellMaxAmount",
    ],
    "settings.virtualRange.sellMaxAmount": [
      "settings.virtualRange.sellMinAmount",
      "settings.virtualRange.sellMaxAmount",
    ],

    "settings.spreadDecompression.alongMode.oneTimeLimit": [
      "settings.spreadDecompression.alongMode.oneTimeLimit",
      "settings.spreadDecompression.alongMode.dayLimit",
    ],
    "settings.spreadDecompression.alongMode.dayLimit": [
      "settings.spreadDecompression.alongMode.oneTimeLimit",
      "settings.spreadDecompression.alongMode.dayLimit",
    ],

    "settings.spreadDecompression.oppositeMode.oneTimeLimit": [
      "settings.spreadDecompression.oppositeMode.oneTimeLimit",
      "settings.spreadDecompression.oppositeMode.dayLimit",
    ],
    "settings.spreadDecompression.oppositeMode.dayLimit": [
      "settings.spreadDecompression.oppositeMode.oneTimeLimit",
      "settings.spreadDecompression.oppositeMode.dayLimit",
    ],
  };

  _bot_uuid = "";

  _market = "";

  setMarket = (market: string) => {
    this._market = market;
  };

  private get _marketParts() {
    return botNameToParts(this._market);
  }

  private get _pair() {
    if (!this._marketParts) return "";

    const { base, quote } = this._marketParts;
    return `${quote}_${base}`;
  }

  private get _exchange() {
    return this._marketParts?.exchange ?? "";
  }

  errors: any = {};

  DTPMin = 0;

  DTPMax = 0;

  diff = 0;

  _decompVerify: DecompPoint[] = [];

  _decompSpread: DecompPoint[] = [];

  verifyPeriod = hoursToSeconds(24);

  spreadPeriod = hoursToSeconds(24);

  periodSteps = hoursToSeconds(4);

  endTime = getCurrentUnix();

  minutesPeriods: SelectorValue[] = [
    { value: 0, label: `${0}m` },
    { value: 15, label: `${15}m` },
    { value: 30, label: `${30}m` },
    { value: 45, label: `${45}m` },
  ];

  tradeVolume = 0;

  savedModuls: any = {
    link: true,
    name: true,
    pair: true,
    period: true,
    volume: true,
    trade: true,
    decimals: true,
    virtualRange: true,
    spreadDecompression: true,
    verifyOrder: true,
    candleCloser: true,
  };

  constructor() {
    this._constraints = new ConstraintsStore();

    makeAutoObservable(this, {
      isActiveSuggestion: false,
    });
  }

  private _setSavedSettings = (settings: CEXSettings) => {
    this._savedSettings = toJS(settings);
  };

  private _updateSettings = () => {
    this._syncSavedSettings();
    this._constraints.clearConstraintsErrors();
  };

  private _syncSavedSettings = () => {
    this._setSavedSettings(this.data);
  };

  getFieldError = (key: CEXSettingsKeys): FieldError | undefined => {
    const error = getData(this.errors as Required<FormErrors<CEXSettings>>, key);
    if (error) return { type: "error", message: error };

    const constraintError = this._constraints.getConstraintsErrorByKey(key);
    return constraintError;
  };

  getFieldErrorAsProps = (key: CEXSettingsKeys): FieldErrorProps | undefined => {
    const fieldError = this.getFieldError(key);
    if (!fieldError) return undefined;
    const { type, message } = fieldError;
    return { errorHint: message, errorType: type };
  };

  setSuggestionsByKey = <K extends keyof PairDecimals>(key: K, keySuggestions: PairDecimals[K]) => {
    this._suggestions[key] = keySuggestions;
  };

  setSuggestions = (suggestions: PairDecimals) => {
    this._suggestions = suggestions;
  };

  private _clearSuggestions = () => {
    this._suggestions = EMPTY_DECIMALS;
  };

  get suggestions() {
    return this._suggestions;
  }

  isActiveSuggestion = computedFn((key: SuggestionsDeepKeys) => {
    switch (key) {
      case "decimals.amount": {
        return (
          this.suggestions.decimals.amount !== "" &&
          this.suggestions.decimals.amount !== this.data.settings.decimals.amount
        );
      }
      case "decimals.price": {
        return (
          this.suggestions.decimals.price !== "" &&
          this.suggestions.decimals.price !== this.data.settings.decimals.price
        );
      }
    }
  });

  applySuggestion = (key: SuggestionsDeepKeys) => () => {
    switch (key) {
      case "decimals.amount": {
        this._setDecimalsByKey("amount", this.suggestions.decimals.amount);
        break;
      }
      case "decimals.price": {
        this._setDecimalsByKey("price", this.suggestions.decimals.price);
        break;
      }
    }
  };

  get decompVerify() {
    const arr = [];

    for (const el of this._decompVerify) {
      arr.push({
        info: `${unixToDateFormat(el.time, "ShortTime")}: Fail with ${el.side} from ${el.account}`,
        amount: el.amount,
        price: el.price,
        total: el.amount * el.price,
        time: el.time,
        id: el.id,
      });
    }

    return arr;
  }

  get decompSpread() {
    const arr = [];

    for (const el of this._decompSpread) {
      arr.push({
        info: `${unixToDateFormat(el.time, "ShortTime")}: ${el.side} from ${el.account}`,
        amount: el.amount,
        price: el.price,
        total: el.amount * el.price,
        time: el.time,
        id: el.id,
      });
    }
    return arr;
  }

  get hoursPeriods() {
    const periods = [];
    let minutes = 0;

    for (let i = 0; i < 24; i += 1) {
      periods.push({ value: (minutes += 60), label: `${minutes / 60}h` });
    }

    return periods;
  }

  get pricePrecision() {
    if (this.data.settings.decimals.price === "") return 0;

    return this.data.settings.decimals.price;
  }

  get amountPrecision() {
    if (this.data.settings.decimals.amount === "") return null;

    return this.data.settings.decimals.amount;
  }

  get pip() {
    return 10 ** (-1 * +this.data.settings.decimals.price);
  }

  get modulePrice() {
    return ((this.DTPMin + this.DTPMax) * 50) / (100 + this.diff);
  }

  get DTPMinPrompt() {
    return this.modulePrice - (+this.data.settings.virtualRange.spread * this.pip) / 2;
  }

  get DTPMaxPrompt() {
    return this.modulePrice + (+this.data.settings.virtualRange.spread * this.pip) / 2;
  }

  get percentRange() {
    const DTPMin = +this.data.settings.pair.dontTradePriceMin;
    const DTPMax = +this.data.settings.pair.dontTradePriceMax;

    const range = ((DTPMax - DTPMin) / DTPMin) * 100;

    return toRounding(range, 2);
  }

  setBotUUID = (bot_uuid: string) => {
    this.endTime = getCurrentUnix();
    this._bot_uuid = bot_uuid;
    this.clearHandlers();
    this.clearErrors();
    this.changeAllSavestatus();
  };

  setHideDiff = (bool: boolean) => {
    this.hideDiff = bool;
  };

  setHideBuyPercent = (bool: boolean) => {
    this.hideBuyPercent = bool;
  };

  setLoading = (bool: boolean) => {
    this.loader = bool;
  };

  setData = (obj: CEXSettings) => {
    this.data.link = obj.link;
    this.data.name = obj.name || "";
    this.overwriteForm("settings", this.data, obj);
  };

  setInitSettings = (obj: CEXSettings) => {
    // this.DTPMin = this.calcDTP(+obj.settings.pair.dontTradePriceMin);
    // this.DTPMax = this.calcDTP(+obj.settings.pair.dontTradePriceMax);
    this.DTPMin = +obj.settings.pair.dontTradePriceMin;
    this.DTPMax = +obj.settings.pair.dontTradePriceMax;

    this.diff = +obj.settings.virtualRange.diff;
  };

  setDecomp = (key: "_decompSpread" | "_decompVerify", arr: DecompPoint[]) => {
    this[key] = arr;
  };

  onVerifyPercentSelected = (data: SelectorValue | null) => {
    if (data) this.setVerifyDecimalPercent(String(data.value));
    this.changeSaveStatus("verifyOrder", false);
  };

  setVerifyDecimalPercent = (value: string) => {
    this.data.settings.verifyOrder.percent = value;
    this._validateOnChangeKey("settings.verifyOrder.percent");
  };

  setVerifyPercent = (value: string) => {
    this.data.settings.verifyOrder.percent = String(+value / 100);
  };

  setTradeVolume = (value: number) => {
    this.tradeVolume = value;
  };

  setOn = (field: "spreadDecompression" | "verifyOrder" | "candleCloser", value: boolean) => {
    this.data.settings[field].on = value;
  };

  getMaxBuy = (value: number) => {
    this.maxBuy = value;
  };

  getMinSell = (value: number) => {
    this.minSell = value;
  };

  getRecomendDiff = (value: number) => {
    this.recomendDiff = value;
  };

  private _increaseLoadsCount = (id: string) => {
    const currentCount = this._loadsCount[id] ?? 0;
    this._loadsCount[id] = currentCount + 1;
  };

  increaseBotLoadsCount = () => {
    this._increaseLoadsCount(this._bot_uuid);
  };

  initLoadsCount = () => {
    this.clearLoadsCount();
  };

  clearLoadsCount = () => {
    this._loadsCount = {};
  };

  get botLoadsCount() {
    return this._loadsCount[this._bot_uuid] ?? 0;
  }

  private _getConstraints = async () => {
    this._constraints.clearConstraintsErrors();
    await this._constraints.getConstraints(this._bot_uuid);
  };

  private _getSuggestions = async () => {
    this._clearSuggestions();
    await this.getDecimalsSuggestions();
  };

  getData = async () => {
    this.setLoading(true);

    try {
      const { isError, data } = await getBotSettings(this._bot_uuid);

      if (!isError) {
        this.increaseBotLoadsCount();
        this.setData(data);
        this._syncSavedSettings();
        this.setInitSettings(data);

        this._getSuggestions();
        this._getConstraints();

        // a request is made for the number of decompressions after receiving the counterPeriods
        this.getVerifyPeriods();
        this.getSpreadPeriods();
      }
    } finally {
      this.setLoading(false);
    }
  };

  getDataForTerminal = async () => {
    this.setLoading(true);

    try {
      const { isError, data } = await getBotSettings(this._bot_uuid);

      if (!isError) {
        this.increaseBotLoadsCount();

        this.setData(data);
        this._syncSavedSettings();
        this.setInitSettings(data);

        this._getConstraints();
      }
    } finally {
      this.setLoading(false);
    }
  };

  getDiff = (value: number) => {
    this.data.settings.virtualRange.diff = Number(value.toFixed(1));
    this.changeSaveStatus("virtualRange", false);
    this._validateOnChangeKey("settings.virtualRange.diff");
  };

  private _setDecimalsByKey = (key: keyof Decimals, value: number | "") => {
    this.data.settings.decimals[key] = value;
    this.changeSaveStatus("decimals", false);
    this._validateOnChangeKey(`settings.decimals.${key}`);
  };

  overwriteForm = (key: any, obj1: any, obj2: any) => {
    for (const nextKey of Object.keys(obj1[key])) {
      const nextObj1 = obj1[key];
      const nextObj2 = obj2[key];
      if (nextObj2 === undefined || nextObj1 === undefined) continue;
      if (typeof nextObj1[nextKey] === "object") {
        this.overwriteForm(nextKey, nextObj1, nextObj2);
      } else {
        nextObj1[nextKey] = nextObj2[nextKey];
      }
    }
  };

  private _validateOnChangeKey = (key: CEXSettingsKeys) => {
    if (!this.validation[key]) return;

    const validateKeys =
      key in this.onChangeValidate ? (this.onChangeValidate[key] as CEXSettingsKeys[]) : [key];

    const errorsValid = this.validate(validateKeys);
    if (!errorsValid) {
      // clear all warnings/errors for validate keys, since error is found
      this._constraints.clearConstraintsErrorsByKeys(validateKeys);
    } else {
      this._validateConstraints(validateKeys);
    }
  };

  getHandler = (key: CEXSettingsKeys) => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this.data, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (key === "settings.trade.buyPercent") {
          const value = this.getChangeEventValueRangePer(e);

          if (Number(value) <= 100) targetData[endKey] = value;
        }
        // @ts-ignore remove punishMult key if not used
        else if (key === "settings.spreadDecompression.punishMult") {
          const value = this.getChangeEventValueRangePer(e);

          if (Number(value) <= 1) targetData[endKey] = value;
        } else if (
          key === "settings.pair.dontTradePriceMin" ||
          key === "settings.pair.dontTradePriceMax"
        ) {
          targetData[endKey] = this.getChangeEventValueDTP(e);
        } else {
          targetData[endKey] = this.getChangeEventValue(e);
        }
        this._validateOnChangeKey(key);

        const path = key.split(".");

        if (path.length > 1) {
          this.changeSaveStatus(path[1], false);
        } else this.changeSaveStatus(path[0], false);
      };
    }
    return this.handlers[key];
  };

  changeSaveStatus = (key: string, bool: boolean) => {
    if (this.savedModuls[key] !== undefined) this.savedModuls[key] = bool;
  };

  changeAllSavestatus = () => {
    this.savedModuls = {
      link: true,
      name: true,
      pair: true,
      period: true,
      volume: true,
      trade: true,
      decimals: true,
      virtualRange: true,
      spreadDecompression: true,
      verifyOrder: true,
      candleCloser: true,
    };
  };

  getChangeEventValueDTP = (e: React.ChangeEvent<HTMLInputElement>) => e.target.value;

  getChangeEventValueRangePer = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    return +e.target.value;
  };

  getChangeEventValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number" || e.target.type === "radio" || e.target.type === "range") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    if (e.target.type === "checkbox") {
      return e.target.checked;
    }
    if (e.target.type === "text") {
      return e.target.value;
    }
    // this.formCleared = false;
  };

  getError = (key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  clearHandlers = () => {
    this.handlers = {};
  };

  clearErrors = () => {
    this.errors = {};
  };

  validate = (validateKeys: string[] | undefined) =>
    validateData(this.validation, this.data, this.errors, validateKeys);

  private _validateConstraints = (validateKeys?: CEXSettingsKeys[]) =>
    this._constraints.validateConstraints(this.data, this._savedSettings, validateKeys);

  private _submitWithConstraints = async <P>(
    { func: submitter, param }: SubmitConfig<P>,
    validationKeys?: CEXSettingsKeys[]
  ) => {
    const constraintsValid = this._validateConstraints(validationKeys);

    if (!constraintsValid) {
      const { title, message } = this._constraints.getConstraintsErrorsReport(validationKeys);
      const styledTitle = createConstraintModalStyledText(
        `You have ${title}, do you want to proceed submitting?`
      );
      const styledMessage = createConstraintModalStyledText(message);
      WindowConsent.showWindow(styledTitle, styledMessage, submitter, param);
    } else {
      await submitter(param);
    }
  };

  getOnHandler =
    (field: "spreadDecompression" | "verifyOrder" | "candleCloser") =>
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const valid = this.validateModul(
        { settings: { [field]: this.data.settings[field] } },
        this.validModules[field]
      );

      if (valid) {
        this.setOn(field, e.target.checked);

        try {
          const { isError } = await setBotSettings(this._bot_uuid, {
            settings: { [field]: this.data.settings[field] },
          });

          if (!isError && isError !== undefined) {
            toast.success(
              `Settings ${this.data.settings[field].on ? "enabled" : "disabled"} successfully`
            );
            this._updateSettings();
          } else if (isError && isError !== undefined) {
            this.setOn(field, !this.data.settings[field].on);
          }
        } catch {
          this.setOn(field, !this.data.settings[field].on);
        }
      }
    };

  private _submitAllSettings = async () => {
    this.setLoading(true);

    this.data.settings.pair.dontTradePriceMin = +this.data.settings.pair.dontTradePriceMin;

    this.data.settings.pair.dontTradePriceMax = +this.data.settings.pair.dontTradePriceMax;

    try {
      const { isError } = await setBotSettings(this._bot_uuid, this.data);
      if (!isError) {
        toast.success("Settings saved successfully");
        this.changeAllSavestatus();
        this._updateSettings();
      }
    } finally {
      this.setLoading(false);
    }
  };

  submitHandler =
    () =>
    // return (e: React.FormEvent) => {
    () => {
      // e.preventDefault();
      const valid = this.validate(undefined);

      if (valid) {
        this._submitWithConstraints({
          func: this._submitAllSettings,
          param: undefined,
        });
      }
    };

  private _submitExchangeSettings = async () => {
    this.setLoading(true);

    this.data.settings.pair.dontTradePriceMin = +this.data.settings.pair.dontTradePriceMin;

    this.data.settings.pair.dontTradePriceMax = +this.data.settings.pair.dontTradePriceMax;

    try {
      const { isError } = await setBotSettings(this._bot_uuid, this.data);
      if (!isError && isError !== undefined) toast.success("Settings saved successfully");
      this.changeAllSavestatus();
      this._updateSettings();
    } finally {
      this.setLoading(false);
    }
  };

  exchSubmitHandler = () => (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate(undefined);

    if (valid) {
      this._submitWithConstraints({
        func: this._submitExchangeSettings,
        param: undefined,
      });
    }
  };

  validateModul = (modul: any, validateKeys?: CEXSettingsKeys[]) =>
    validateData(this.validation, modul, this.errors, validateKeys);

  private _saveModuleSettings = async (module: CEXSettingsModules) => {
    this.setLoading(true);

    if (module === "pair") {
      this.data.settings.pair.dontTradePriceMin = +this.data.settings.pair.dontTradePriceMin;

      this.data.settings.pair.dontTradePriceMax = +this.data.settings.pair.dontTradePriceMax;
    }

    try {
      const { isError } = await setBotSettings(this._bot_uuid, {
        settings: { [module]: this.data.settings[module] },
      });

      if (!isError) {
        toast.success(`${module} settings module successfully saved`);

        this.changeSaveStatus(module, true);
        this._updateSettings();
      }
    } finally {
      this.setLoading(false);
    }
  };

  submitModulHandler = (module: CEXSettingsModules) => async (e: React.FormEvent) => {
    e.preventDefault();

    const validationKeys = this.validModules[module];

    const errorsValid = this.validateModul(
      { settings: { [module]: this.data.settings[module] } },
      validationKeys
    );

    if (errorsValid) {
      this._submitWithConstraints(
        { func: this._saveModuleSettings, param: module },
        validationKeys
      );
    }
  };

  submitFieldHandler = (modul: "link" | "name") => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validateModul({ [modul]: this.data[modul] }, [modul]);

    if (valid) {
      this.setLoading(true);

      try {
        const { isError } = await setBotSettings(this._bot_uuid, {
          [modul]: this.data[modul],
        });

        if (!isError) {
          toast.success(`${modul} settings module successfully saved`);
          this.changeSaveStatus(modul, true);
          this._updateSettings();
        }
      } finally {
        this.setLoading(false);
      }
    }
  };

  toggleSaveDiffPair = () => {
    this.data.saveDiff = !this.data.saveDiff;
  };

  checkBindings = (bindings: CEXBindingModule[]) => {
    if (bindings.length) {
      this.setHideBuyPercent(true);
    } else this.setHideBuyPercent(false);
  };

  checkDiffPrompt = (obj: ICurrentCEXBindings, isOriginPair: boolean) => {
    if (isOriginPair && obj.currentBinding) {
      this.setHideDiff(true);
    } else this.setHideDiff(false);
  };

  calcDTP = (value: number) => (value * 100) / (100 + +this.diff);

  async getVerifyPeriods() {
    try {
      const {
        data: { values },
        isError,
      } = await getVerifyPeriods(this._bot_uuid, this.verifyPeriod);

      if (!isError) {
        this.setDecomp("_decompVerify", values);
      }
    } catch {
      this.setDecomp("_decompVerify", []);
    }
  }

  async getSpreadPeriods() {
    try {
      const {
        data: { values },
        isError,
      } = await getSpreadPeriods(this._bot_uuid, this.spreadPeriod);

      if (!isError) {
        this.setDecomp("_decompSpread", values);
      }
    } catch {
      this.setDecomp("_decompSpread", []);
    }
  }

  getDecimalsSuggestions = async () => {
    if (!this._market) return;
    try {
      const { data, isError } = await getDecimals("", this._pair, this._exchange);

      if (!isError) {
        this.setSuggestionsByKey("decimals", data.data);
      } else {
        this.setSuggestionsByKey("decimals", EMPTY_DECIMALS.decimals);
      }
    } catch {
      this.setSuggestionsByKey("decimals", EMPTY_DECIMALS.decimals);
    }
  };

  destroy = () => {};
}
