import { ChangeEventHandler, ComponentPropsWithoutRef, useCallback } from "react";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import * as styles from "./style";
import { PNLMode } from "../ProfitLossChartV2";

interface ModeMenuItem extends MenuItemData {
  value: PNLMode;
}

const MODE_MENU_ITEMS: ModeMenuItem[] = [
  { label: "absolute", value: "absolute" },
  { label: "percent", value: "percent" },
];

export interface ProfitLossHeaderPanelProps extends ComponentPropsWithoutRef<"div"> {
  currentMode?: PNLMode;
  onModeChange?: (mode: PNLMode) => void;
  feeChecked?: boolean;
  onFeeChecked?: (checked: boolean) => void;
}

export const ProfitLossHeaderPanel = ({
  currentMode = "absolute",
  onModeChange: onModeChangeProp,
  feeChecked = false,
  onFeeChecked,
  ...props
}: ProfitLossHeaderPanelProps) => {
  const onModeChange = useCallback(
    (value: string) => {
      onModeChangeProp?.(value as PNLMode);
    },
    [onModeChangeProp]
  );

  const onFeeChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onFeeChecked?.(e.target.checked);
    },
    [onFeeChecked]
  );

  return (
    <styles.Container {...props}>
      <styles.FeeCheckbox checked={feeChecked} onChange={onFeeChange} label="Fee" />
      <styles.ModeSwitch
        selectedItem={currentMode}
        menuItems={MODE_MENU_ITEMS}
        onChange={onModeChange}
      />
    </styles.Container>
  );
};
