import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import {
  ArrowItemHeader,
  ItemBody,
  ItemContainer,
  ItemHeaderWrapper,
  ItemName,
} from "src/components/shared/nestedList";
import { AnalyticsContext } from "src/context/CEX/Analytics";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ListAnalytic } from "src/modules/analytics";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { Strategy } from "./Strategy";

interface PairProps {
  shownModal: React.Dispatch<React.SetStateAction<boolean>>;
  shownEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  pairAnalytic: ListAnalytic;
}

export const Pair = observer(({ pairAnalytic, shownModal, shownEditModal }: PairProps) => {
  const [hide, setHide] = useState(false);

  const state = useLateInitContext(AnalyticsContext);

  return (
    <ItemContainer>
      <ItemHeaderWrapper>
        <ArrowItemHeader hide={hide} onClick={() => setHide(!hide)}>
          <ItemName>{pairAnalytic.pair}</ItemName>
        </ArrowItemHeader>

        <PermissionWrapper abilityName="cex-candle-analytics-edit" party={state.party}>
          <AddButton
            onClick={() => {
              state.startCreateAnalytic(pairAnalytic.pair);
              shownModal(true);
            }}
          />
        </PermissionWrapper>
      </ItemHeaderWrapper>
      {hide ? (
        <ItemBody>
          {pairAnalytic.analytics.map((el) => (
            <Strategy
              key={el.uuid}
              analytic={el}
              defaultAnalytic={pairAnalytic.default}
              shownEditModal={shownEditModal}
            />
          ))}
        </ItemBody>
      ) : null}
    </ItemContainer>
  );
});
