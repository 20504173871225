import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { ExchangesLegendContext } from "src/context/CEX/Dashboard/ExchangesLegend";
import * as styles from "./style";

export const TRACKABLE_TOKENS_DATA = Array(2).fill({
  exchange: "Binance",
  tokens: ["BTC", "ETH", "XRP", "ADA", "LTC", "DOT", "BNB", "LINK", "BCH", "XLM"],
});

export interface MobileSummaryProps {}

export const MobileSummary = observer((props: MobileSummaryProps) => (
  <styles.Container>
    <ErrorBoundary fallback={<styles.KPIFallback />}>
      <styles.StatsKPIPanel />
    </ErrorBoundary>
    <styles.StatsContainer>
      <ErrorBoundary fallback={<styles.PNLFallback />}>
        <styles.StyledPNLChart />
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.ExchangesFallback />}>
        <ExchangesLegendContext.Provider>
          <styles.StyledExchangesChart />
        </ExchangesLegendContext.Provider>
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.BalancesFallback />}>
        <styles.StyledBalancesChart />
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.BuyLiquidityFallback />}>
        <styles.StyledBuyLiquidityChart />
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.SellLiquidityFallback />}>
        <styles.StyledSellLiquidityChart />
      </ErrorBoundary>
      <ErrorBoundary fallback={<styles.VolumeLiquidityFallback />}>
        <styles.StyledVolumeShare />
      </ErrorBoundary>
      <styles.StyledSantimentChart />
    </styles.StatsContainer>
  </styles.Container>
));
