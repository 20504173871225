import { CardSurface, ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

interface StyledContainerProps {
  error?: boolean;
}

export const Container = styled.div<StyledContainerProps>`
  position: relative;

  min-height: 400px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  border: ${({ theme, error }) =>
    `1px solid ${error ? theme.inputBorderColor : theme.errorLabelColor}`};

  ${CardSurface}
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  padding: 8px 16px;
  border-radius: 4px 4px 0px 0px;

  border-bottom: ${({ theme }) => `1px solid ${theme.inputBorderColor}`};
`;

export const Content = styled.div`
  position: relative;

  /* height: 0px; */

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding: 8px;
  gap: 16px;

  /* overflow: auto;

  ${ScrollSize} */
`;
