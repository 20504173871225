import { EmptyTextLineHeight } from "src/components/shared/shared";
import { Headline2 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Content = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Headline2)`
  color: ${({ theme }) => theme.lightGray};

  ${EmptyTextLineHeight}
`;
