import { Switches } from "src/components/shared/Switches";
import { SwitchLabel, WrapperForRadioItems } from "src/components/shared/Switches/style";
import styled, { css } from "styled-components";
import { OrientationProps } from "../../shared/SettingsFormContent/style";
import { FormRow } from "../../shared/style";

export interface ModesSwitchProps extends OrientationProps {}

export const ModesSwitch = styled(Switches)<ModesSwitchProps>`
  ${({ $orientation }) => {
    switch ($orientation) {
      case "column":
      case "column-reverse": {
        return css`
          flex-direction: ${$orientation};
        `;
      }
      case "row":
      case "row-reverse": {
        return css`
          flex-direction: ${$orientation};
          align-items: center;
          justify-content: space-between;
        `;
      }
    }
  }}

  gap: 6px;

  ${SwitchLabel} {
    margin: 0;
  }

  ${WrapperForRadioItems} {
    max-width: 100%;
    gap: 12px;
    justify-content: flex-start;
  }
`;

export const CompareFormRow = styled(FormRow)`
  gap: 20px;
  align-items: flex-end;
`;
