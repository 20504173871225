import styled from "styled-components";
import { CardSurface } from "../../../shared";

export const TooltipContainer = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 0px;

  display: none;

  width: 180px;
  min-height: 60px;

  padding: 8px;

  color: ${({ theme }) => theme.textColor};
  ${CardSurface}

  pointer-events: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SeriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
`;
