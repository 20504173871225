import { Suggestion } from "src/components/BotsContent/CEX/LiquidityTab/GridForm/GridFormContent/style";
import { InputStyle } from "src/components/shared/Forms/Inputs/Input/";
import styled from "styled-components";
import { DecimalSuggestionInput } from "../DecimalSuggestionInput";

export const Container = styled.div``;

export const TokenLabeledInput = styled(DecimalSuggestionInput)`
  ${InputStyle.Input} {
    height: 50px;
  }
`;

export const TokenTickerChip = styled(Suggestion)`
  height: 36px;
  font-size: ${({ theme }) => theme.fontSizes["15px"]};
`;
