import { ApiModules, apiUrl } from "src/api";
import ResponseHandler from "src/state/network/ResponseHandler";
import { DEXV2VaultTypeOrder, PermissionResponse } from "./stats";

export interface SwapExactTokensForTokensPermissionRequest {
  amount_in: string;
  deadline: number;
  from_vault: DEXV2VaultTypeOrder;
  min_amount_out: string;
  path: string[];
  sender: string;
  use_receiver: boolean;
}

export interface SwapExactTokensForTokensPermissionResponse extends PermissionResponse {}

export const getSwapExactTokensForTokensPermission = async (
  botId: string,
  data: SwapExactTokensForTokensPermissionRequest
) =>
  ResponseHandler.handler<SwapExactTokensForTokensPermissionResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/perm/setft`),
    method: "PATCH",
    data,
  });

export interface SwapTokensForExactTokensPermissionRequest {
  amount_out: string;
  deadline: number;
  from_vault: DEXV2VaultTypeOrder;
  max_amount_in: string;
  path: string[];
  sender: string;
  use_receiver: boolean;
}

export interface SwapTokensForExactTokensPermissionResponse extends PermissionResponse {}

export const getSwapTokensForExactTokensPermission = async (
  botId: string,
  data: SwapTokensForExactTokensPermissionRequest
) =>
  ResponseHandler.handler<SwapTokensForExactTokensPermissionResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/perm/stfet`),
    method: "PATCH",
    data,
  });
