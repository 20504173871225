import { LiquidityAccountName } from "src/modules/accounts";
import { PermissionProps } from "src/permissions/PermissionWrapper";
import { AccountsHeader } from "../../shared/AccountsHeader";
import { useAccountModalState } from "../VolumeAccounts";
import {
  AddLiquidityAccountModal,
  AddLiquidityAccountModalProps,
} from "./AddLiquidityAccountModal";
import { LiquidityAccount } from "./LiquidityAccount";
import * as styles from "./style";

export interface LiquidityAccountsProps
  extends PermissionProps,
    Pick<AddLiquidityAccountModalProps, "onRefresh" | "onAddAccount" | "bindingsProvider"> {
  accountNames: LiquidityAccountName[];
}

export const LiquidityAccounts = ({
  accountNames,
  party,
  abilityName,
  ...props
}: LiquidityAccountsProps) => {
  const { show: showAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useAccountModalState();

  const permissionProps = { abilityName, party };

  const accounts = accountNames.map((name) => (
    <LiquidityAccount key={name} accountName={name} {...permissionProps} />
  ));

  return (
    <styles.LiquidityContainer>
      <AccountsHeader type="liquidity" onAddAccount={onOpenAdd} {...permissionProps} />

      <styles.AccountsContainer>{accounts}</styles.AccountsContainer>
      <AddLiquidityAccountModal show={showAdd} close={onCloseAdd} {...props} />
    </styles.LiquidityContainer>
  );
};
