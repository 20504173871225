import styled from "styled-components";
import { CellText } from "../../../shared/TableRows/style";

export const Container = styled(CellText)``;

interface LaunchesCountTextProps {
  $accent?: boolean;
}

export const LaunchesCountText = styled.span<LaunchesCountTextProps>`
  color: ${({ theme, $accent }) => ($accent ? theme.dangerColor : theme.textColor)};
`;
