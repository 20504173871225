import styled from "styled-components";
import { ModalBackground, PanelContainer, PanelTitle } from "../../shared";

export const Container = styled(ModalBackground)``;

export const Panel = styled(PanelContainer)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  width: 360px;

  display: flex;
  flex-direction: column;

  padding: 16px;
  border-radius: 0px;

  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;
`;

export const WrapperTitle = styled.div`
  display: flex;

  margin-bottom: 16px;

  gap: 20px;

  @media screen and (max-width: 450px) {
    margin-bottom: 14px;
  }
`;

export const Title = styled(PanelTitle)`
  white-space: break-spaces;
`;

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
`;
