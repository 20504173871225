import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { DEXV2LimitInfo } from ".";
import {
  IBotChainInfoProvider,
  IBotTradePairProvider,
  ISwapPairAddressProvider,
} from "../../DEXV2Bots/DEXV2BotStore";
import { ISwapSettingsProvider } from "../../DEXV2Swap/SwapModules/shared/Providers/SwapSettingsProvider";
import { IDEXV2SettingsProvider } from "../DEXV2SettingsInfoStore";
import { ModeImpactInfoStoreProvider } from "./PriceImpactInfo/ModeImpactInfoStoreProvider";

export interface ILimitInfoParams {
  swapSettingsProvider: ISwapSettingsProvider;
  settingsProvider: IDEXV2SettingsProvider;
  tradePairProvider: IBotTradePairProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  botChainInfoProvider: IBotChainInfoProvider;
}

export class LimitInfoStore implements IDisposable {
  private _settingsProvider: IDEXV2SettingsProvider;

  private _modeImpactInfoStateProvider: ModeImpactInfoStoreProvider;

  constructor(params: ILimitInfoParams) {
    makeAutoObservable(this);

    const {
      settingsProvider,
      swapSettingsProvider,
      tradePairProvider,
      pairAddressProvider,
      botChainInfoProvider,
    } = params;

    this._settingsProvider = settingsProvider;

    this._modeImpactInfoStateProvider = new ModeImpactInfoStoreProvider({
      swapSettingsProvider,
      settingsProvider,
      tradePairProvider,
      pairAddressProvider,
      botChainInfoProvider,
      impactDataProvider: this,
    });

    makeLoggable(this, { info: true });
  }

  private get _impactInfoState() {
    return this._modeImpactInfoStateProvider.impactInfoState;
  }

  private get _settings() {
    return this._settingsProvider.settings;
  }

  get inputAmount() {
    return this._settings.limit_data.max_amount.toString();
  }

  get info(): Pick<DEXV2LimitInfo, "priceImpact"> {
    return {
      priceImpact: this._impactInfoState?.priceImpact ?? {},
    };
  }

  destroy = () => {
    this._modeImpactInfoStateProvider.destroy();
  };
}
