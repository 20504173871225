import styled from "styled-components";
import { SeriesColorBox } from "../../GraphLegend/GraphLegendSeries/style";

export const Container = styled.div`
  display: flex;
  height: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.dashboard.textSubhead};
  font-size: ${({ theme }) => theme.fontSizes["8px"]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const ColorBox = styled(SeriesColorBox)``;

export const Value = styled(Title)``;
