import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import {
  PairInfo,
  PriceCalculator,
  PriceCalculatorFallback,
  SwapScreenerFallback,
  SwapWidgetFallback,
  VaultsFallback,
} from "../DesktopSwap/style";
import { SwapScreener } from "../SwapScreener";
import { SwapWidget } from "../SwapWidget";
import { Vaults } from "../Vaults";
import * as styles from "./style";

export interface MobileSwapProps {}

enum SwapTabs {
  Swap = "SWAP",
  Vaults = "Vaults",
  Info = "INFO",
  Screener = "SCREENER",
}

interface SwapMenuItem extends MenuItemData {
  value: SwapTabs;
}

const SWAP_MENU_ITEMS: SwapMenuItem[] = [
  {
    label: "Swap",
    value: SwapTabs.Swap,
  },
  {
    label: "Vaults",
    value: SwapTabs.Vaults,
  },
  {
    label: "Info",
    value: SwapTabs.Info,
  },
  {
    label: "Chart",
    value: SwapTabs.Screener,
  },
];

const useSwapTabs = () => {
  const { hasAbility } = useLateInitContext(AbilitiesContext);
  const showVaults = hasAbility(DEXV2Ability.VaultsView);
  const tabsItems = useMemo(
    () =>
      SWAP_MENU_ITEMS.filter(({ value: tab }) => {
        switch (tab) {
          case SwapTabs.Vaults: {
            return showVaults;
          }
          default: {
            return true;
          }
        }
      }),
    [showVaults]
  );

  const SwapTab = (
    <styles.SwapTabContainer key={SwapTabs.Swap}>
      <ErrorBoundary fallback={<SwapWidgetFallback />}>
        <SwapWidget />
      </ErrorBoundary>
    </styles.SwapTabContainer>
  );

  const VaultsTab = showVaults ? (
    <styles.VaultsTabContainer key={SwapTabs.Vaults}>
      <ErrorBoundary fallback={<VaultsFallback />}>
        <Vaults />
      </ErrorBoundary>
    </styles.VaultsTabContainer>
  ) : null;

  const InfoTab = (
    <styles.InfoTabContainer key={SwapTabs.Info}>
      <ErrorBoundary fallback={<PriceCalculatorFallback />}>
        <PriceCalculator />
      </ErrorBoundary>
      <PairInfo />
    </styles.InfoTabContainer>
  );

  const ScreenerTab = (
    <styles.ScreenerTabContainer key={SwapTabs.Screener}>
      <ErrorBoundary fallback={<SwapScreenerFallback />}>
        <SwapScreener showInfo={false} />
      </ErrorBoundary>
    </styles.ScreenerTabContainer>
  );
  const tabs = filterBoolean([SwapTab, VaultsTab, InfoTab, ScreenerTab]);

  return { tabsItems, tabs };
};

export const MobileSwap = observer((_props: MobileSwapProps) => {
  const { tabs, tabsItems } = useSwapTabs();

  return (
    <styles.Container>
      <styles.SwapTabs menuItems={tabsItems} defaultKey={SwapTabs.Swap} type="spread">
        {tabs}
      </styles.SwapTabs>
    </styles.Container>
  );
});
