import { Input } from "src/components/shared/Forms/Inputs/Input/Input/style";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 133px;
  display: flex;
`;

export const SearchInput = styled(Input)`
  font-size: 0.7em;
  padding-right: 30px;
`;

export const IconWrapper = styled.label`
  position: absolute;
  right: 13px;
  top: 7px;
  cursor: pointer;
`;
