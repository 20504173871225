import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useCallback, useMemo, useState } from "react";
import { PNLV2Context } from "src/context/CEX/Dashboard/v2/PNLV2";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DashboardV2Widget, getWidgetInfo, getWidgetTitle } from "../../shared/constants";
import { ProfitLossBar, ProfitLossBarProps } from "../ProfitLossBar";
import { ProfitLossHeaderPanel } from "../ProfitLossHeaderPanel";
import { ProfitLossInfoPanel } from "../ProfitLossInfoPanel";
import * as styles from "./style";

export type PNLMode = "absolute" | "percent";

export interface ProfitLossChartV2Props
  extends Pick<ProfitLossBarProps, "zoomDefault">,
    styles.PNLCardProps,
    ComponentPropsWithoutRef<"div"> {}

export const ProfitLossChartV2 = observer(
  ({ zoomDefault, orientation, ...props }: ProfitLossChartV2Props) => {
    const { percentBars, absBars, transactions, loading } = useLateInitContext(
      PNLV2Context.Context
    );

    const [mode, setMode] = useState<PNLMode>("absolute");
    const [feeChecked, setFeeChecked] = useState<boolean>(false);

    const toggleFeeChecked = useCallback(() => setFeeChecked((prev) => !prev), []);

    const { time, value } = mode === "absolute" ? absBars : percentBars;

    const pnlData: ProfitLossBarProps["data"] = useMemo(() => {
      const transactionsValue = toJS(transactions.value);
      return {
        datasets: [
          {
            label: "PNL",
            data: value,
            borderWidth: 0,
          },
          {
            label: "Transactions",
            type: "line",
            data: transactionsValue,
            hidden: true,
          },
        ],
        labels: time,
      };
    }, [time, transactions.value, value]);

    const barProps = {
      mode,
      data: pnlData,
      zoomDefault,
    };

    return (
      <styles.PNLCard
        title={getWidgetTitle(DashboardV2Widget.PNLChart)}
        info={getWidgetInfo(DashboardV2Widget.PNLChart)}
        after={<ProfitLossInfoPanel />}
        afterTitle={
          <ProfitLossHeaderPanel
            currentMode={mode}
            onModeChange={setMode}
            feeChecked={feeChecked}
            onFeeChecked={toggleFeeChecked}
          />
        }
        loading={loading}
        $orientation={orientation}
        {...props}
      >
        <ProfitLossBar {...barProps} />
      </styles.PNLCard>
    );
  }
);
