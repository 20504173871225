import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  width: 100%;
  max-width: 490px;

  display: flex;
  justify-content: space-between;
`;
