import { observer } from "mobx-react-lite";
import { CheckBox } from "src/components/shared/CheckBox";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

export const VirtualRange = observer(() => {
  const state = useLateInitContext(CEXSettingsContext);

  return (
    <styles.Container>
      <CheckBox
        label="Buy Wall"
        checked={state.data.settings.virtualRange.enableBuyWall}
        onChange={state.getHandler("settings.virtualRange.enableBuyWall")}
        disabled
      />

      <CheckBox
        label="Sell Wall"
        checked={state.data.settings.virtualRange.enableSellWall}
        onChange={state.getHandler("settings.virtualRange.enableSellWall")}
        disabled
      />
    </styles.Container>
  );
});
