import { ColumnDef } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { OrderInfoModal } from "src/components/BotsContent/CEX/Exchange/TradePanel/Orders/OrderInfoModal";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { TransferListContext } from "src/context/CEX/FundingProvider";
import { stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import { ITransferWithID } from "src/state/funding/CEX/TransferListStore";
import { DISABLE_CONFIG } from "../../..";
import { FundingCell } from "../../../shared";
import { TableVirtualRows } from "../../TableVirtualRows";
import { CellText } from "../../style";

const VIEW_TRANSFER_PARAMS = [
  "address",
  "time",
  "id",
  "currency",
  "type",
  "amount",
  "fee",
  "status",
  "chain",
  "txId",
] as Array<keyof ITransferWithID>;

export const TransfersTable = observer(() => {
  const state = useLateInitContext(TransferListContext);

  const [showModal, setShowModal] = useState(false);

  const columns = useMemo(() => {
    const columnSchema: ColumnDef<ITransferWithID>[] = [
      {
        header: "Type",
        accessorKey: "type",
        enableSorting: false,
        cell: (info) => <CellText>{info.getValue<string>().toUpperCase()}</CellText>,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        cell: (info) => (
          <FundingCell type={info.cell.row.original.type} value={info.getValue<string>()} />
        ),
      },
      {
        header: "Currency",
        enableSorting: false,
        accessorKey: "currency",
        cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
      },
      {
        header: "Exchange",
        accessorKey: "exchange",
        enableSorting: false,
        cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
      },
      {
        header: "Account",
        accessorKey: "name",
        enableSorting: false,
        cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
      },
      {
        header: "Time",
        accessorKey: "time",
        enableColumnFilter: false,
        cell: (info) => (
          <CellText>
            {unixToDateFormat(stringDateToUnix(info.getValue<string>()), "FullDate")}
          </CellText>
        ),
      },
      {
        header: () => "Info",
        id: "info_column",
        accessorKey: "id",
        ...DISABLE_CONFIG,
        size: 20,
        cell: (info) => {
          const transfer = info.cell.row.original;

          return (
            <CellText>
              <IconButton
                onClick={() => {
                  setShowModal(true);
                  state.setShowTransfer(transfer.currency, transfer.id);
                }}
              >
                <Icons.Prompt />
              </IconButton>
            </CellText>
          );
        },
      },
      {
        header: () => "Add",
        id: "add_column",
        accessorKey: "id",
        ...DISABLE_CONFIG,
        size: 20,
        cell: (info) => {
          const transfer = info.cell.row.original;

          return (
            <CellText>
              <AddButton
                onClick={() => state.setTransferInFunding(transfer.currency, transfer.id)}
              />
            </CellText>
          );
        },
      },
    ];

    return columnSchema;
  }, [state]);

  return (
    <>
      <TableVirtualRows
        data={state.transferList}
        columns={columns}
        rowSize={30}
        party={state.party}
        loader={state.isLoading}
      />
      <OrderInfoModal
        label="Transfer info:"
        data={state.showTransfer}
        keys={VIEW_TRANSFER_PARAMS}
        show={showModal}
        close={setShowModal}
      />
    </>
  );
});
