import { DashboardContext } from "src/context/CEX/Dashboard";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DashboardWidgetsKeys } from "src/state/CEX/CEXDashboard/v1";

const WRONG_VERSION_MESSAGE = "useWidgetsState: Wrong dashboard version!";

type DashboardWidgetsV1Keys = DashboardWidgetsKeys;

export const useWidgetsState = <K extends DashboardWidgetsV1Keys>(key: K) => {
  const { state, version } = useLateInitContext(DashboardContext);
  if (version !== "v1") {
    throw Error(WRONG_VERSION_MESSAGE);
  }

  const widgetState = state.widgetsStatesMap[key];

  return widgetState;
};
