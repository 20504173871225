import { Tabs as BaseTabs } from "src/components/shared/Tabs";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  box-sizing: border-box;
`;

export const SwapTabs = styled(BaseTabs)``;

export const SwapTabContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 520px;

  display: flex;
`;

export const VaultsTabContainer = styled.div`
  width: 100%;
  height: 300px;

  display: flex;
`;

export const InfoTabContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ScreenerTabContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;

  display: flex;
`;
