import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Loader } from "src/components/shared/Loader";
import { BotModulesContext } from "src/context/CEX/BotModules/BotModulesProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { ITypeBotModuleParams } from "src/state/CEX/botModules";
import { DesktopList, MobList } from "./Lists";
import { ExchangeModule } from "./Modules/ExchangeModule";
import { MainBotModule } from "./Modules/MainBotModule";
import { SwapModule } from "./Modules/SwapModule";
import * as styles from "./style";

export type ModuleType = "SWAP" | "MAINBOT" | "EXCHANGE";

interface MenuItem {
  label: string;
  modalLabel: string;
  value: ModuleType;
}

const MODULES_MENU_ITEMS: MenuItem[] = [
  { label: "Swap", modalLabel: "Create swap module", value: "SWAP" },
  { label: "Main Bot", modalLabel: "Create main bot module", value: "MAINBOT" },
  {
    label: "Exchange",
    modalLabel: "Create exchange module",
    value: "EXCHANGE",
  },
];

export interface ModulesListProps {}

export const ModulesList = observer(() => {
  const state = useLateInitContext(BotModulesContext);

  const showTabs = useMediaQuery("(max-width: 1150px)");

  const getModuleKey = useCallback((module: ITypeBotModuleParams) => module.name + module.uuid, []);

  const getContent = useCallback(
    (moduleType: ModuleType) => {
      switch (moduleType) {
        case "SWAP":
          return state.swapModules.map((module) => (
            <SwapModule key={getModuleKey(module)} module={module} />
          ));

        case "MAINBOT":
          return state.botModules.map((module) => (
            <MainBotModule key={getModuleKey(module)} module={module} />
          ));

        case "EXCHANGE":
          return state.exchangeModules.map((module) => (
            <ExchangeModule key={getModuleKey(module)} module={module} />
          ));

        default:
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
      }
    },
    [state, getModuleKey]
  );

  if (showTabs) {
    return (
      <styles.Container>
        <styles.TabsModules menuItems={MODULES_MENU_ITEMS} defaultKey="SWAP">
          {MODULES_MENU_ITEMS.map((el) => (
            <MobList
              key={el.value}
              isLoading={state.isLoading}
              type={el.value}
              modalLabel={el.modalLabel}
            >
              {getContent(el.value)}
            </MobList>
          ))}
        </styles.TabsModules>
      </styles.Container>
    );
  }
  return (
    <styles.Container>
      {MODULES_MENU_ITEMS.map((el, index) => {
        const key = el.value + index;
        return (
          <DesktopList key={key} label={el.label} type={el.value} modalLabel={el.modalLabel}>
            {getContent(el.value)}
          </DesktopList>
        );
      })}

      <Loader show={state.isLoading} />
    </styles.Container>
  );
});
