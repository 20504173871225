import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { SwapVaultsStore } from "src/state/DEXV2/DEXV2Swap/Vaults";
import { DEXV2BotContext } from "../../DEXV2Bots/DEXV2Bot";
import { DEXV2SwapContext } from "../DEXV2Swap";

export const SwapVaultsContext = createLateInitContext<SwapVaultsStore>();

type SwapVaultsProviderProps = ChildrenProps;

export const SwapVaultsProvider = ({ children }: SwapVaultsProviderProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const state = useLateInitContext(DEXV2SwapContext);

  const store = useLocalStore(SwapVaultsStore, {
    botChainProvider: botState,
    botInfoProvider: botState,
    tradePairProvider: botState,
    tradePairPriceProvider: state.tradePairPriceProvider,
  });

  return <SwapVaultsContext.Provider value={store}>{children}</SwapVaultsContext.Provider>;
};
