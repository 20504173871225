import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { ModuleErrors } from "src/state/CEX/multiGrid/Modules";
import { ErrorsModal } from "./ErrorsModal";
import { ErrorContainer, ErrorText, InfoText, SeeMore, TextWrapper } from "./style";

export interface ErrorInfoProps {
  exchange: string;
  errors: ModuleErrors[];
}

export const ErrorInfo = observer(({ exchange, errors }: ErrorInfoProps) => {
  const isErrors = useMemo(() => Boolean(errors.length), [errors]);

  const getCountActiveErrors = useCallback(() => errors.filter((el) => el.active).length, [errors]);

  const activeErrors = useMemo(() => getCountActiveErrors(), [getCountActiveErrors]);

  return (
    <ErrorContainer>
      <ErrorText>Errors: {activeErrors}</ErrorText>
      <InfoText>
        Time: {isErrors ? unixToDateFormat(errors[0].created_at, "FullDate") : "-"}
      </InfoText>
      {isErrors ? (
        <TextWrapper>
          <InfoText>{errors[0].text}</InfoText>
        </TextWrapper>
      ) : null}

      {isErrors ? (
        <ErrorsModal
          exchange={exchange}
          activeErrors={activeErrors}
          trigger={<SeeMore>see more</SeeMore>}
          errors={errors}
        />
      ) : null}
    </ErrorContainer>
  );
});
