import { SeriesType } from "lightweight-charts";
import { useMemo } from "react";
import { MIN_MOVE_RULES } from "../constants";
import { OptionalSeriesOptions } from "../types";
import { getSeriesDataValue } from "../utils";
import { UseSeriesOptionsParams } from "./useSeriesOptions";

const getMinMove = (input: number) => {
  const defaultMove = 0.0001;
  for (const { lowerBound, value } of MIN_MOVE_RULES) {
    if (input >= lowerBound) {
      return value;
    }
  }
  return defaultMove;
};

interface UseMinMoveParams extends Pick<UseSeriesOptionsParams, "data"> {}

/**
 * Custom hook to calculate the minimum move for chart for the price scale based on series data.
 */
export const useMinMove = ({ data }: UseMinMoveParams) => {
  const averageValue = useMemo(() => {
    if (!data.length) {
      return 0;
    }
    const sum = data.reduce((acc, data) => acc + getSeriesDataValue(data), 0);
    const avg = sum / data.length;
    return avg;
  }, [data]);

  const minMoveOptions = useMemo((): OptionalSeriesOptions<SeriesType> => {
    const minMove = getMinMove(averageValue);
    return {
      priceFormat: {
        minMove,
      },
    };
  }, [averageValue]);

  return minMoveOptions;
};
