import styled from "styled-components";
import { TitleSpan } from "../shared";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InfoPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
`;

export const TimePeriod = styled(TitleSpan)`
  padding-right: 3px;
`;

export const CountPoint = styled(TimePeriod)`
  padding-right: 0px;
`;

export const Line = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.bgGrayBlack};
  margin-bottom: 6px;
`;

export const Point = styled.div`
  position: absolute;
  top: -0.5px;
  height: 4px;
  /* border: 0.1px solid; */
  min-width: 1px;
  border-radius: ${({ theme }) => theme.radii["100%"]};
  background-color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
`;

export const TimeScale = styled.div`
  width: 100%;
  height: 10px;
  display: flex;
`;

export const TimeInterval = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes["9px"]};
  color: ${({ theme }) => theme.textColor};
  height: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
