import styled from "styled-components";

export const FormBotCreating = styled.form`
  width: 100%;
  max-width: 1700px;

  display: flex;
  flex-direction: column;

  padding: 20px;
  gap: 15px;
`;

export const Content = styled.div`
  display: flex;

  gap: 15px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 40px;
`;
