import { SwitchMenu } from "src/components/shared/SwitchMenu";
import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled(PanelContainer)`
  display: flex;
  flex-direction: column;

  grid-area: trading-view;

  flex: 1;

  @media screen and (max-width: 450px) {
    height: 270px;
    flex: 0 0 auto;
  }
`;

export const Header = styled.div`
  height: 40px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 5px;

  border-bottom: 1px solid #e3effd99;
`;

export const SwitchGraphs = styled(SwitchMenu)`
  width: 250px;

  flex: 0 0 auto;

  @media screen and (max-width: 450px) {
    width: 220px;
  }
`;
