import styled from "styled-components";

interface IconWrapperProps {
  $color?: "primary" | "neutral";
}

export const IconWrapper = styled.span<IconWrapperProps>`
  color: ${({ $color = "neutral", theme }) =>
    $color === "primary" ? theme.primaryColor : theme.lightGray};
`;
