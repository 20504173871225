import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Column } from "react-table";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { VaultHashType } from "src/state/DEXV2/DEXV2Stats/Hashes/HashesTypeStore";
import { HashesResponseMap } from "src/state/DEXV2/DEXV2Stats/Hashes/Providers/HashesPaginationFetcherProvider";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import { AddressCell } from "../../TablePanel/AddressCell";
import { TableContentProps } from "../../TablePanel/TableContent";
import { TransactionStatusCell } from "../TransactionStatusCell";
import * as styles from "./style";

type VaultHistoryHash = HashesResponseMap[VaultHashType];

export const useVaultsHashesColumns = () => {
  const { chainInfo } = useLateInitContext(DEXV2BotContext);
  const txScannerLink = getScannerLink(chainInfo, "tx");

  const vaultsColumns = useMemo(() => {
    const columnsSchema: Column<VaultHistoryHash>[] = [
      {
        Header: "Hash",
        accessor: "hash",
        Cell: ({ value }) => <AddressCell address={value} scannerLink={txScannerLink} />,
        disableFilters: true,
      },
      {
        Header: "Type",
        accessor: "type",
        sortType: "string",
        disableFilters: true,
      },
      {
        Header: "Time",
        accessor: "date",
        sortType: "string",
        disableFilters: true,
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "string",
        Cell: ({ value }) => <TransactionStatusCell status={value} />,
        disableFilters: true,
      },
    ];

    return columnsSchema;
  }, [txScannerLink]);
  return vaultsColumns;
};

export interface VaultsHashesTableProps extends Omit<TableContentProps, "data" | "columns"> {
  hashes: VaultHistoryHash[];
}

export const VaultsHashesTable = observer(({ hashes, ...props }: VaultsHashesTableProps) => {
  const columns = useVaultsHashesColumns();

  return <styles.HashesTable columns={columns} data={hashes} {...props} />;
});
