import { useMemo } from "react";
import { useQuery } from "react-query";
import { getCEXBot } from "src/api/bots/CEX/bots";
import { CEXBotActions } from "src/components/AllBots/Bots/CEXBots/CEXBot";
import { CEXBotMob } from "src/components/AllBots/Bots/CEXBots/CEXBotMob";
import { useFindWidth } from "src/hooks/useFindWidth";
import { BotInfoProps } from "..";
import * as styles from "./style";

const HEADER = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Downtime", icon: "" },
  { title: "Side", icon: "" },
  { title: "Message", icon: "" },
  { title: "Comment", icon: "" },
];

export interface BotInfoCEXProps extends BotInfoProps {
  actions?: CEXBotActions;
}

export const BotInfoCEX = ({ botInfo: { bot_uuid }, actions }: BotInfoCEXProps) => {
  const {
    data: { data },
  }: any = useQuery<any>([`CEXBot/${bot_uuid}`, bot_uuid], () => getCEXBot(bot_uuid!), {
    refetchInterval: 5000,
    initialData: {
      bot: {
        base: "",
        diff: "",
        exchange: "",
        link: "",
        message: "",
        modifyTime: 0,
        modifyVolume: false,
        quote: "",
        side: "",
        status: 0,
        timeDontWork: "",
        updated_at: "",
      },
    },
    enabled: Boolean(bot_uuid),
  });

  const showMobVers = useFindWidth(850);

  const pair = useMemo(
    () => (typeof data === "object" ? `${data?.bot.quote}_${data?.bot.base}` : ""),
    [data]
  );

  const market = useMemo(
    () => (typeof data === "object" ? `${pair}_${data?.bot.exchange}` : ""),
    [data, pair]
  );

  const statsLink = `/CEX/${data?.bot.bot_uuid}/?tab=STATS`;

  const credential = {
    market,
    name: data?.bot.name,
    UUID: data?.bot.bot_uuid,
    link: statsLink,
  };

  return (
    <styles.Container>
      {!showMobVers ? <styles.HeaderForPages cells={HEADER} /> : null}
      {showMobVers ? (
        <CEXBotMob
          favorite={false}
          pair={pair}
          market={market}
          actions={actions}
          credential={credential}
          {...data?.bot}
        />
      ) : (
        <styles.CEXBotForPages
          favorite={false}
          pair={pair}
          market={market}
          actions={actions}
          credential={credential}
          {...data?.bot}
        />
      )}
    </styles.Container>
  );
};
