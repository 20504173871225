import { ApiModules, apiUrl } from "src/api";
import { DEXSettings } from "src/modules/settings";
import ResponseHandler from "src/state/network/ResponseHandler";

export const getDEXSettings = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `${bot_uuid}/settings`),
    method: "GET",
  });

export const setDEXSettings = async (bot_uuid: string, data: DEXSettings) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `${bot_uuid}/settings`),
    method: "PUT",
    data,
  });
