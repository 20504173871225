import { observer } from "mobx-react-lite";
import { GridStats } from "src/state/CEX/liquidityTab/ActiveGrid";
import StatsPanel, { StatsPanelProps } from "../StatsPanel";
import { StatsItemDataProps } from "../StatsPanel/StatsList";

export interface ProfitStatsProps extends Omit<StatsPanelProps, "title" | "items"> {
  stats: GridStats;
}

export const ProfitStats = observer(({ stats, ...props }: ProfitStatsProps) => {
  const profitStatsItems: StatsItemDataProps[] = [
    {
      name: "Base",
      value: stats.profit.base,
    },
    {
      name: "Quote",
      value: stats.profit.quote,
    },
  ];

  return <StatsPanel title="Profit" items={profitStatsItems} {...props} />;
});
