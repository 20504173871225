import { FieldPath, FieldValues } from "react-hook-form";
import { CheckBox } from "src/components/shared/CheckBox";
import { ControllerWrapper, ControllerWrapperProps } from "./ControllerWrapper";

export interface ControlledCheckBoxProps<
  TForm extends FieldValues,
  TName extends FieldPath<TForm>,
> {
  controllerProps: Omit<ControllerWrapperProps<TForm, TName>, "component">;
}

export const ControlledCheckBox = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  controllerProps,
}: ControlledCheckBoxProps<TForm, TName>) => (
  <ControllerWrapper {...controllerProps} component={CheckBox} />
);
