import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled, { css } from "styled-components";
import { FormContent } from "../../Settings/shared/style";
import { TABLET_SETTINGS_BREAKPOINT } from "../../Settings/style";
import { PairInfo as BasePairInfo } from "../PairInfo";
import * as BasePairInfoStyles from "../PairInfo/style";
import { PriceCalculator as BasePriceCalculator } from "../PriceCalculator";
import * as BasePriceCalculatorStyles from "../PriceCalculator/style";
import { SwapScreener as BaseSwapScreener } from "../SwapScreener";
import { SwapWidget as BaseSwapWidget } from "../SwapWidget";
import { Vaults as BaseVaults } from "../Vaults";

export const Content = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: minmax(450px, 1fr) minmax(auto, 310px) minmax(auto, 420px);
  grid-template-rows: minmax(auto, 520px) minmax(auto, 217px);
  grid-template-areas:
    "screener     screener  swap"
    "vaults      pair_info calculator";

  row-gap: 15px;
  column-gap: 10px;

  @media ${getMobileMediaQuery(TABLET_SETTINGS_BREAKPOINT)} {
    grid-template-columns: minmax(380px, 1fr) minmax(0, 1fr);
    grid-template-rows:
      repeat(2, minmax(260px, auto)) minmax(auto, 217px)
      minmax(450px, 1fr);
    grid-template-areas:
      "swap   calculator"
      "swap    pair_info"
      "vaults     vaults"
      "screener  screener";
  }

  @media ${getMobileMediaQuery("700px")} {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows:
      minmax(520px, auto) minmax(400px, auto) minmax(212px, auto)
      auto minmax(auto, 217px);
    grid-template-areas:
      "swap"
      "screener"
      "calculator"
      "pair_info"
      "vaults";
  }
`;

export const LargeTitleMixin = css`
  min-height: 30px;
  display: inline-flex;
  align-items: center;
`;

export const PairInfo = styled(BasePairInfo)`
  grid-area: pair_info;

  justify-content: space-between;

  ${BasePairInfoStyles.Title} {
    ${LargeTitleMixin}
  }

  @media ${getMobileMediaQuery(TABLET_SETTINGS_BREAKPOINT)} {
    justify-content: unset;

    ${BasePairInfoStyles.InfoPanel} {
      justify-content: center;
    }
    ${BasePairInfoStyles.Content} {
      height: 100%;
    }
  }
`;

export const PriceCalculator = styled(BasePriceCalculator)`
  grid-area: calculator;

  justify-content: space-between;

  ${BasePriceCalculatorStyles.Title} {
    ${LargeTitleMixin}
  }

  ${BasePriceCalculatorStyles.FormContainer} {
    min-height: 172px;
  }

  ${FormContent} {
    justify-content: space-between;
  }

  @media ${getMobileMediaQuery(TABLET_SETTINGS_BREAKPOINT)} {
    justify-content: unset;

    ${BasePriceCalculatorStyles.FormContainer} {
      height: 100%;
    }
  }
`;

export const PriceCalculatorFallback = styled(CrashPanel)`
  grid-area: calculator;
  min-height: 220px;
`;

export const SwapScreener = styled(BaseSwapScreener)`
  grid-area: screener;
`;

export const SwapScreenerFallback = styled(CrashPanel)`
  grid-area: screener;
`;

export const SwapWidget = styled(BaseSwapWidget)`
  grid-area: swap;
`;

export const SwapWidgetFallback = styled(CrashPanel)`
  grid-area: swap;
`;

export const Vaults = styled(BaseVaults)`
  grid-area: vaults;
`;

export const VaultsFallback = styled(CrashPanel)`
  grid-area: vaults;
`;
