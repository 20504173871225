import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useModuleState } from "../hooks/useModuleState";
import { ModulesEditTableProps, ModulesList } from "../layout/ModulesList";
import { ActionsFilters } from "./ActionsFilters";
import { ActionsTable } from "./ActionsTable";

export interface ActionsProps extends ModulesEditTableProps {}

export const Actions = observer(({ editing }: ActionsProps) => {
  const { getModules, refreshModules } = useModuleState("action");

  useEffect(() => {
    getModules(0);
  }, [getModules]);

  const Table = <ActionsTable editing={editing} />;

  const Filters = <ActionsFilters />;

  return <ModulesList table={Table} filter={Filters} onRefresh={refreshModules} />;
});
