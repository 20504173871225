import { observer } from "mobx-react-lite";
import useLocalStore from "src/hooks/useLocalStore";
import { Spectr, SpectrProps } from "./Spectr";
import { SpectrContext } from "./shared/context";
import { SpectrStore } from "./shared/state/Spectr";
import * as styles from "./style";

interface TimeLineProps extends SpectrProps {}

export const TimeLine = observer(({ botUUID, startPoint, endPoint }: TimeLineProps) => {
  const state = useLocalStore(SpectrStore);

  return (
    <SpectrContext.Provider value={state}>
      <styles.TimeLineContainer>
        <styles.StatusLine>
          <styles.CurrentTime>{state.trackedTime}</styles.CurrentTime>
          <styles.Status>
            Status:
            <styles.StatusInd id="statusInd" bgColor={state.trackedStatus} />
          </styles.Status>
        </styles.StatusLine>
        <Spectr botUUID={botUUID} startPoint={startPoint} endPoint={endPoint} />
      </styles.TimeLineContainer>
    </SpectrContext.Provider>
  );
});
