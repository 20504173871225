import { useParamsText } from "src/components/BotsContent/CEX/ExpertSystem/shared/hooks/useParamText";
import { FloatingOrderStatus } from "src/modules/exchange/trade";
import { Side } from "../../../../shared/style";
import { FilledOrdersTable } from "./FilledOrdersTable";
import * as styles from "./style";

export interface ContentRowsProps {
  orderInfo: FloatingOrderStatus;
}

export const ContentRows = ({ orderInfo }: ContentRowsProps) => {
  const { side, id, created_at, error, filled_orders, ...otherParams } = orderInfo;

  const params = useParamsText(otherParams);

  return (
    <>
      <styles.InfoColumn>
        <styles.InfoCell>
          <styles.InfoLabel>ID:</styles.InfoLabel>
          <styles.InfoUniqueName>{String(id)}</styles.InfoUniqueName>
        </styles.InfoCell>

        <styles.InfoCell>
          <styles.InfoLabel>Side:</styles.InfoLabel>
          <Side side={side}>{side}</Side>
        </styles.InfoCell>

        <styles.InfoCell>
          <styles.InfoLabel>Created at:</styles.InfoLabel>
          <styles.InfoUniqueName>{created_at}</styles.InfoUniqueName>
        </styles.InfoCell>
      </styles.InfoColumn>

      <styles.InfoColumn>
        <styles.InfoLabel>Other params:</styles.InfoLabel>
        <styles.InfoUniqueName>{params}</styles.InfoUniqueName>
      </styles.InfoColumn>

      <styles.InfoColumn>
        <styles.InfoLabel>Filled orders:</styles.InfoLabel>
        <FilledOrdersTable orders={filled_orders} />
      </styles.InfoColumn>

      <styles.InfoColumn>
        <styles.InfoLabel>Errors:</styles.InfoLabel>
        <styles.InfoUniqueName>{error}</styles.InfoUniqueName>
      </styles.InfoColumn>
    </>
  );
};
