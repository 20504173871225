import {
  ConditionModuleGroupsNames,
  StrategyModuleGroupCreateType,
} from "src/modules/expertSystem";

export const CONDITIONAL_EMPTY_MAP: {
  [Key in ConditionModuleGroupsNames]: StrategyModuleGroupCreateType<Key>;
} = {
  abs_modules: {
    type: "price_abs",
    name: "",
    party: "",
    hard_params: {
      exchange: "",
      pair: "",
    },
  },

  timeframe_modules: {
    type: "price_change",
    name: "",
    party: "",
    hard_params: {
      exchange: "",
      pair: "",
      time: "",
    },
  },
  bot_status: {
    type: "bot_status",
    name: "",
    party: "",
    hard_params: {
      bot_uuid: "",
      status: "",
    },
  },
  time: {
    type: "time",
    name: "",
    party: "",
    hard_params: {},
  },
  balance_abs: {
    type: "balance_abs",
    name: "",
    party: "",
    hard_params: {
      accounts: [],
      currency: "",
    },
  },
  balance_change_modules: {
    type: "balance_change",
    name: "",
    party: "",
    hard_params: {
      accounts: [],
      currency: "",
      timeframe: "",
    },
  },
  depth_abs: {
    type: "depth_abs",
    name: "",
    party: "",
    hard_params: {
      exchange: "",
      mode: "",
      pair: "",
      percent: "",
    },
  },
  settings_value: { type: "settings_value", name: "", party: "", hard_params: { bot_uuid: "" } },
  // price_account_pnl: {
  //   type: "price_account_pnl",
  //   name: "",
  //   party: "",
  //   hard_params: {
  //     accounts: [],
  //     side: "",
  //     timeframe: "",
  //   },
  // },
  // price_exchange_pnl: {
  //   type: "price_exchange_pnl",
  //   name: "",
  //   party: "",
  //   hard_params: {
  //     exchanges: [],
  //     side: "",
  //     timeframe: "",
  //   },
  // },
  multigrinder_status: {
    type: "multigrinder_status",
    name: "",
    party: "",
    hard_params: {
      multigrinder_uuid: "",
      status: "",
    },
  },
};
