import { Chart, ChartDataset } from "chart.js";
import Zoom from "chartjs-plugin-zoom";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Chart as ChartComponent } from "react-chartjs-2";
import { ChartPlaceholder } from "src/config/chartjs/plugins/chartPlaceholder";
import { InOutIndicatorPlugin } from "src/config/chartjs/plugins/inOutIndicator";
import { Totalizer, getTotal } from "src/config/chartjs/plugins/totalizer";
import { roundSingleValue } from "src/helpers/rounding";
import { joinStrings } from "src/helpers/string";
import { isDefined } from "src/helpers/utils";
import { deepMerge } from "src/helpers/utils/deepMerge";
import { BalancesTokensTransactionsValue } from "src/modules/dashboard";
import {
  BASE_TIME_CONFIG,
  useBaseTooltip,
  useGridOptions,
  useInOutIndicatorOptions,
  usePlaceholderOptions,
} from "../../../../../shared/config";
import { useChartZoom } from "../../../../../shared/hooks/useChartZoom";
import { useDashboardColors } from "../../../../../shared/hooks/useDashboardColors";
import { ChartProps } from "../../../../../shared/types";
import { getSeriesLabel } from "../../../../../shared/utils";
import {
  getTransactionsIndicatorStatus,
  getTransactionsText,
  isTransactionsDataset,
} from "../../../../../shared/utils/inOutTransactions";

const isPriceDataset = (dataset: ChartDataset) => dataset.label === "Price";

const getPriceText = (chart: Chart<"bar" | "line">, dataIndex: number) => {
  const dataset = chart.data.datasets.find(isPriceDataset);
  if (!dataset) return 0;
  const price = dataset.data[dataIndex] as number;
  const priceText = roundSingleValue(+price);
  return priceText;
};

const FOOTER_DELIMITER_LINE = "‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾";

const useBaseOptions = () => {
  const { textSubhead, textAdditional, borderDefault, textSecondary } = useDashboardColors();

  const tooltipOptions = useBaseTooltip();

  const baseInOutOptions = useInOutIndicatorOptions();

  const options = useMemo(
    (): ChartProps<"bar" | "line", number[], number>["options"] => ({
      maintainAspectRatio: false,
      layout: { padding: 8 },
      scales: {
        x: {
          type: "time",
          ...BASE_TIME_CONFIG,
          stacked: true,
        },
        y: {
          stacked: true,
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",

          grid: {
            drawOnChartArea: false,
            color: borderDefault,
            borderColor: borderDefault,
          },
          ticks: {
            color: textSecondary,
          },
        },
      },
      plugins: {
        tooltip: {
          ...tooltipOptions,
          displayColors: true,
          mode: "index",
          bodyFont: {
            size: 8,
            weight: "400",
          },
          footerColor: textSubhead,
          footerFont: {
            size: 8,
            weight: "500",
          },
          multiKeyBackground: "rgba(0,0,0,0)",
          filter(item) {
            const dataset = item.chart.data.datasets[item.datasetIndex];
            return dataset.type !== "line";
          },
          callbacks: {
            footer() {
              const { dataIndex } = this.dataPoints[0];
              const total = getTotal(this.chart, dataIndex);
              const totalText = `Total: ${total}`;

              const price = getPriceText(this.chart, dataIndex);
              const priceText = `Price: ${price}`;

              const { datasets } = this.chart.data;

              const transactionsText = getTransactionsText(datasets, dataIndex);

              const footerDelimiter = transactionsText ? FOOTER_DELIMITER_LINE : "";

              return joinStrings([totalText, priceText, footerDelimiter, transactionsText]);
            },
            labelColor(item) {
              const color = (item.dataset.backgroundColor as string) ?? "#fff";
              return {
                borderColor: "rgba(0,0,0,0)",
                backgroundColor: color,
                borderWidth: 0,
              };
            },
            label(ctx) {
              const title = ctx.dataset.label || "";
              const value = ctx.raw as number;
              const total = getTotal(ctx.chart, ctx.dataIndex);

              return getSeriesLabel(title, value, total);
            },
          },
        },
        legend: {
          position: "top",
          align: "end",
          labels: {
            pointStyle: "rect",
            usePointStyle: true,
            boxHeight: 12,
            font: {
              size: 10,
              weight: "400",
            },
            color: textAdditional,
            filter(item, data) {
              const { datasetIndex } = item;
              if (!isDefined(datasetIndex)) return false;
              const dataset = data.datasets[datasetIndex];
              return !isTransactionsDataset(dataset);
            },
          },
        },
        totalizer: {
          filter: (dataset) => !isPriceDataset(dataset) && !isTransactionsDataset(dataset),
        },
        inOutIndicator: {
          ...baseInOutOptions,
          getInOutStatus(datasets, index) {
            return getTransactionsIndicatorStatus(datasets, index);
          },
          datasetLabels: ["Free", "Locked"],
        },
      },
    }),
    [baseInOutOptions, borderDefault, textAdditional, textSecondary, textSubhead, tooltipOptions]
  );

  return options;
};

export interface BalancesOverviewBarProps
  extends Omit<
    ChartProps<"bar" | "line", number[] | BalancesTokensTransactionsValue[], number>,
    "type"
  > {
  zoomDefault?: boolean;
}

export const BalancesOverviewBar = observer(
  ({ options: inOptions, zoomDefault, ...props }: BalancesOverviewBarProps) => {
    const baseOptions = useBaseOptions();

    const placeholderOptions = usePlaceholderOptions();

    const gridOptions = useGridOptions();

    const zoomOptions = useChartZoom({
      shouldZoom: zoomDefault,
      data: props.data,
    });

    const options = useMemo(
      () => deepMerge(placeholderOptions, gridOptions, baseOptions, zoomOptions, inOptions),
      [baseOptions, gridOptions, inOptions, placeholderOptions, zoomOptions]
    );

    return (
      <ChartComponent
        type="bar"
        options={options}
        plugins={[Totalizer, Zoom, ChartPlaceholder, InOutIndicatorPlugin]}
        {...props}
      />
    );
  }
);
