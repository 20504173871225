import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { generateTime, generateTimeSeries } from "src/state/CEX/shared/mocks";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type PriceData = {
  price: ChartPoint[];
  basePrice: number;
  volume: ChartPoint[];
};

const INITIAL_PRICE_DATA: PriceData = {
  price: [],
  volume: [],
  basePrice: 0,
};

const generatePriceData = (): PriceData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 20, max: 60 });

  const priceData = generateTimeSeries({
    startTimestamp,
    count: pointsCount,
    value: {
      min: 1000,
      max: 100_000,
      precision: 0.01,
    },
  });

  const volumeData = generateTimeSeries({
    startTimestamp,
    count: pointsCount,
    value: {
      min: 10_000,
      max: 1_000_000,
      precision: 0.01,
    },
  });

  const basePrice =
    priceData.map(({ value }) => value).reduce((a, b) => a + b, 0) / priceData.length;

  return {
    price: priceData,
    basePrice,
    volume: volumeData,
  };
};

export class PriceV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: PriceData = INITIAL_PRICE_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBaseDashboardV2StoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { data: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: PriceData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData(INITIAL_PRICE_DATA);
    try {
      await delay(200);
      const data = generatePriceData();
      this._setData(data);
    } catch {
      this._setData(INITIAL_PRICE_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
