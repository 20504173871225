import { ChainInfoCardOwnProps } from "../ChainInfoCard";
import * as styles from "./style";

export interface StepHeadingOwnProps extends ChainInfoCardOwnProps {
  title: string;
}

export interface StepHeadingProps
  extends StepHeadingOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof StepHeadingOwnProps> {}

export const StepHeading = ({ title, abbr, icon, color, ...props }: StepHeadingProps) => {
  const chainInfoProps = { abbr, icon, color };
  return (
    <styles.Container {...props}>
      <styles.Title>{title}</styles.Title>
      <styles.ChainInfo {...(chainInfoProps as ChainInfoCardOwnProps)} />
    </styles.Container>
  );
};
