import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { formatDecimalPercent } from "src/helpers/string";
import { delay } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { generateAmount, generatePercent } from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

type TotalVolumeData = {
  total: string;
  our: string;
};

const INITIAL_DATA: TotalVolumeData = {
  total: "",
  our: "",
};

const generateTotalVolumeData = (): TotalVolumeData => {
  const total = generateAmount();
  const percent = generatePercent();
  const our = (+total * +percent).toString();

  return {
    total,
    our,
  };
};

export class TotalVolumeV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: TotalVolumeData = INITIAL_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBaseDashboardV2StoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { our: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: TotalVolumeData) => {
    this._data = data;
  };

  get our() {
    return this._data.our;
  }

  get total() {
    return this._data.total;
  }

  get ourPercent() {
    const percent = +this._data.our / +this._data.total;

    if (Number.isNaN(percent)) {
      return "0";
    }

    return formatDecimalPercent(percent, false);
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData(INITIAL_DATA);
    try {
      await delay(200);
      const data = generateTotalVolumeData();
      this._setData(data);
    } catch {
      this._setData(INITIAL_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
