import {
  Body,
  Cell,
  Header,
  HeaderCell,
  TableContainer,
  TableRow,
} from "src/components/shared/GridTable";
import styled from "styled-components";

export const BalanceContainer = styled(TableContainer)`
  grid-template-columns:
    minmax(150px, 1.65fr)
    minmax(50px, 1.65fr)
    minmax(50px, 1.65fr);
`;

export const BalanceHeader = styled(Header)``;

export const BalanceBody = styled(Body)``;

export const BalanceRow = styled(TableRow)``;

export const BalanceHeaderCell = styled(HeaderCell)``;

export const BalanceCell = styled(Cell)``;

export const DeployerCell = styled(BalanceCell)`
  position: sticky;
  top: 45px;

  z-index: 1;

  background: ${({ theme }) => theme.bgSettingsPnl};
`;
