import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { BotWalletConnectionContext } from "src/context/DEXV2/DEXV2Stats/Vaults/BotWalletConnection";
import { SwapVaultsContext } from "src/context/DEXV2/DEXV2Swap/Vaults/SwapVaults";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BotWalletConnectionState } from "src/state/DEXV2/DEXV2Stats/Vaults/BotWalletConnectionStore";
import { Field } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/SwapStateStore";
import { swapRouteErrorToText } from "src/state/DEXV2/DEXV2Swap/utils";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { useSwapWidgetState } from "../../hooks/useSwapWidgetState";
import { ConnectWalletButton } from "./ConnectWalletButton";
import { SwapButton } from "./SwapButton";
import { SwitchChainButton } from "./SwitchChainButton";

export interface SwapActionButtonProps
  extends Pick<ComponentPropsWithoutRef<"button">, "style" | "className"> {}

export const SwapActionButton = observer((props: SwapActionButtonProps) => {
  const walletState = useLateInitContext(BotWalletConnectionContext);
  const { swapInfoState, swapParamsReady } = useSwapWidgetState();
  const { vaultsDeployed } = useLateInitContext(SwapVaultsContext);

  const {
    [Field.INPUT]: { token: inputToken, amount: inputTokenAmount, balance: inputTokenBalance },
    [Field.OUTPUT]: { token: outputToken },
    trade: { trade, error },
  } = swapInfoState.info;

  const { connectionState: walletConnectionState } = walletState;

  const { hasAbility } = useLateInitContext(AbilitiesContext);
  const enableSwap = hasAbility(DEXV2Ability.ExchangeTrade);

  const connectDisabled = !vaultsDeployed;

  const isDisabled = useMemo(
    () =>
      !enableSwap ||
      // no vaults available for swap
      !vaultsDeployed ||
      !trade ||
      !inputTokenAmount ||
      // No wallet installed
      walletConnectionState === BotWalletConnectionState.Initial ||
      // If there is no balance loaded, we should default to isDisabled=false
      Boolean(inputTokenBalance?.lessThan(inputTokenAmount)) ||
      // Some of swap params are missing
      !swapParamsReady,
    [
      enableSwap,
      inputTokenAmount,
      inputTokenBalance,
      swapParamsReady,
      trade,
      vaultsDeployed,
      walletConnectionState,
    ]
  );

  const insufficientBalance = useMemo(
    () => inputTokenBalance && inputTokenAmount && inputTokenBalance.lessThan(inputTokenAmount),
    [inputTokenAmount, inputTokenBalance]
  );

  const swapRouteError = useMemo(
    () => (error !== undefined ? swapRouteErrorToText(error) : null),
    [error]
  );

  switch (true) {
    case walletConnectionState === BotWalletConnectionState.Initialized: {
      return <ConnectWalletButton disabled={connectDisabled} {...props} />;
    }
    case walletConnectionState === BotWalletConnectionState.WrongNetwork: {
      const tokenChainId = inputToken?.chainId ?? outputToken?.chainId;
      const newChainId = tokenChainId ?? walletState.botChainId;
      return <SwitchChainButton chainId={newChainId} disabled={connectDisabled} {...props} />;
    }
    case Boolean(swapRouteError): {
      // explicit cast since narrowing wont work here
      return (
        <Button disabled {...props}>
          {swapRouteError as string}
        </Button>
      );
    }
    case insufficientBalance: {
      return (
        <Button disabled {...props}>
          {`Insufficient ${inputToken?.symbol} balance`}
        </Button>
      );
    }
    default: {
      return <SwapButton disabled={isDisabled} {...props} />;
    }
  }
});
