import styled, { CSSProperties } from "styled-components";

export interface ButtonsContainerProps {
  wrap?: NonNullable<CSSProperties["flexWrap"]>;
  direction?: NonNullable<CSSProperties["flex"]>;
  align?: NonNullable<CSSProperties["alignItems"]>;
}

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  width: 100%;
  display: flex;
  align-items: ${({ align = "center" }) => align};
  flex-direction: ${({ direction = "row" }) => direction};
  flex-wrap: ${({ wrap = "wrap" }) => wrap};
  gap: 10px;
`;

export const ButtonsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;
