import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";

export interface ModuleSettingsProps extends ComponentPropsWithoutRef<"div"> {
  currentKey: string;
  children: (JSX.Element | null)[];
}

export const ModuleSettings = observer(({ currentKey, children }: ModuleSettingsProps) => (
  <>{children?.filter((child) => child?.key === currentKey)}</>
));
