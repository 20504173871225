import { makeAutoObservable } from "mobx";

class ExpertSystemStore {
  private _party = "";

  constructor() {
    makeAutoObservable(this);
  }

  get party() {
    return this._party;
  }

  setParty = (party: string) => {
    this._party = party;
  };

  // eslint-disable-next-line class-methods-use-this
  destroy = () => {};
}

export default ExpertSystemStore;
