import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { Loader } from "src/components/shared/Loader";
import { ModuleType } from ".";
import { CreateModal } from "./CreateModal";
import * as styles from "./style";

interface ListProps extends ComponentPropsWithoutRef<"div"> {
  type: ModuleType;
  label?: string;
  modalLabel?: string;
}

interface MobListProps extends ListProps {
  isLoading: boolean;
}

export const DesktopList = observer(({ label, modalLabel, type, children }: ListProps) => (
  <styles.ListContainer>
    <styles.TitleWrapper>
      <styles.Title>{label}</styles.Title>

      <CreateModal label={modalLabel} type={type} />
    </styles.TitleWrapper>

    <styles.List key={type}>{children}</styles.List>
  </styles.ListContainer>
));

export const MobList = observer(({ type, modalLabel, isLoading, children }: MobListProps) => (
  <styles.ListContainer>
    <styles.TitleWrapper>
      <styles.Title>Add module</styles.Title>

      <CreateModal label={modalLabel} type={type} />
    </styles.TitleWrapper>

    <styles.List key={type}>
      {children}

      <Loader show={isLoading} />
    </styles.List>
  </styles.ListContainer>
));
