import { useMemo } from "react";
import { Bar, BarProps } from "src/components/shared/Charts/Bar";
import { ChartPlaceholder } from "src/config/chartjs/plugins/chartPlaceholder";
import { deepMerge } from "src/helpers/utils/deepMerge";
import { useBaseTooltip, useGridOptions, usePlaceholderOptions } from "../../../../shared/config";
import { useDashboardColors } from "../../../../shared/hooks/useDashboardColors";
import { getSeriesLabel, getSeriesTotal } from "../../../../shared/utils";

const useBaseOptions = () => {
  const baseTooltip = useBaseTooltip();

  const { textSubhead, textAdditional } = useDashboardColors();

  const options = useMemo(
    (): BarProps<any, any>["options"] => ({
      maintainAspectRatio: false,
      layout: { padding: 8 },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      plugins: {
        tooltip: {
          ...baseTooltip,
          displayColors: true,
          mode: "index",
          bodyFont: {
            size: 8,
            weight: "400",
          },
          footerColor: textSubhead,
          footerFont: {
            size: 8,
            weight: "500",
          },
          multiKeyBackground: "rgba(0,0,0,0)",
          callbacks: {
            footer() {
              const totalText = getSeriesTotal(this);
              return `Total: ${totalText}`;
            },
            labelColor(item) {
              const color = (item.dataset.backgroundColor as string) ?? "#fff";
              return {
                borderColor: "rgba(0,0,0,0)",
                backgroundColor: color,
                borderWidth: 0,
              };
            },
            label(ctx) {
              const title = ctx.dataset.label || "";
              const value = ctx.raw as number;
              const total = getSeriesTotal(this);

              return getSeriesLabel(title, value, total);
            },
          },
        },
        legend: {
          position: "bottom",
          align: "center",
          labels: {
            pointStyle: "circle",
            usePointStyle: true,
            boxHeight: 4,
            font: {
              size: 10,
              weight: "400",
            },
            color: textAdditional,
            filter(tooltipItem, data) {
              const datasetIndex = tooltipItem.datasetIndex ?? 0;
              const dataset = data.datasets[datasetIndex];
              return !dataset.hidden;
            },
          },
        },
      },
    }),
    [baseTooltip, textAdditional, textSubhead]
  );

  return options;
};

export interface VolumeShareBarProps extends BarProps<number[], string> {}

export const VolumeShareBar = ({ options: inOptions, ...props }: VolumeShareBarProps) => {
  const baseOptions = useBaseOptions();

  const gridOptions = useGridOptions();

  const placeholderOptions = usePlaceholderOptions();

  const options = useMemo(
    () => deepMerge(placeholderOptions, baseOptions, gridOptions, inOptions),
    [baseOptions, gridOptions, inOptions, placeholderOptions]
  );

  return <Bar options={options} {...props} plugins={[ChartPlaceholder]} />;
};
