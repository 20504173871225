import { observer } from "mobx-react-lite";
import useLocalStore from "src/hooks/useLocalStore";
import AddVolumeBinding, {
  IAddVolumeBindingParams,
} from "src/state/CEX/CEXApiKeys/AddVolumeBinding";
import { AccountSelector } from "../../../../shared/Account/AccountSelector";
import { AccountFormProps } from "../../../../shared/AccountForm";
import * as styles from "./style";

export interface AddVolumeAccountModalContentOwnProps extends IAddVolumeBindingParams {}

export interface AddVolumeAccountModalContentProps
  extends AccountFormProps,
    AddVolumeAccountModalContentOwnProps {}

export const AddVolumeAccountModalContent = observer(
  ({
    onSuccess,
    setLoading,
    onAddAccount,
    bindingsProvider,
    ...props
  }: AddVolumeAccountModalContentProps) => {
    const state = useLocalStore(AddVolumeBinding, {
      bindingsProvider,
      onSuccess,
      setLoading,
      onAddAccount,
    });

    return (
      <styles.Form onSubmit={state.addAccount} {...props}>
        <styles.FormContent>
          <AccountSelector
            options={state.selectorOptions("accountName")}
            onChange={state.selectorHandler("accountName")}
            value={state.selectorValue("accountName")}
            errorHint={state.errors.account?.name}
          />
          <styles.Button />
        </styles.FormContent>
      </styles.Form>
    );
  }
);
