import { AddressCell } from "src/components/BotsContent/DEX_V2/Stats/Tables/TablePanel/AddressCell";
import { StatusPoint } from "src/components/shared/GridTable";
import { DeployerWallet } from "src/state/DEXV2/DEXV2Create/WalletGasFillStore";
import * as styles from "./style";

export interface DeployerProps extends DeployerWallet {
  scannerLink?: string;
}

export const Deployer = ({ address, type, balance, scannerLink }: DeployerProps) => (
  <styles.BalanceRow>
    <styles.DeployerCell style={{ textAlign: "left" }}>
      <AddressCell
        address={address}
        style={{ justifyContent: "flex-start" }}
        scannerLink={scannerLink}
      />
    </styles.DeployerCell>
    <styles.DeployerCell>
      <StatusPoint type="neutral">{type}</StatusPoint>{" "}
    </styles.DeployerCell>
    <styles.DeployerCell>{balance}</styles.DeployerCell>
  </styles.BalanceRow>
);
