import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { ChooseSettingParamsContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ConditionChangeSettingsStore } from "src/state/expertSystem/strategies/changeSettingsModule/ConditionChangeSettingsStore";
import { FormRows } from "./FormRows";
import { SettingsExpression } from "./SettingsExpression";

export interface FormContentProps {
  moduleIndex: number;
  setModuleParam: UseFormSetValue<IStrategyCreator>;
  softParam: string;
}

export const FormContent = observer(
  ({ softParam, moduleIndex, setModuleParam }: FormContentProps) => {
    const state = useLateInitContext(ChooseSettingParamsContext);
    const {
      expression,
      defaultCompareConfig,
      defaultSettings,
      setModuleSoftParamSetter,
      setModuleIndex,
      setSettingsConfig,
      setComparesConfig,
      generateExpression,
      getAddedParamStatus,
      removeConfigParam,
    } = useLocalStore(ConditionChangeSettingsStore, state, softParam);

    useEffect(() => {
      setModuleSoftParamSetter(setModuleParam);
      setModuleIndex(moduleIndex);
    }, [setModuleParam, setModuleSoftParamSetter, setModuleIndex, moduleIndex]);

    return (
      <>
        <FormRows
          defaultCompareConfig={defaultCompareConfig}
          defaultSettings={defaultSettings}
          setComparesConfig={setComparesConfig}
          setSettingsConfig={setSettingsConfig}
          generateExpression={generateExpression}
          getParamStatus={getAddedParamStatus}
          removeConfigParam={removeConfigParam}
        />

        <SettingsExpression expression={expression} />
      </>
    );
  }
);
