import { FieldPath, FieldValues } from "react-hook-form";
import { LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { ControllerWrapper, ControllerWrapperProps } from "./ControllerWrapper";

export interface ControlledInputPercentRangeProps<
  TForm extends FieldValues,
  TName extends FieldPath<TForm>,
> {
  controllerProps: Omit<ControllerWrapperProps<TForm, TName>, "component">;
}

export const ControlledInputPercentRange = <
  TForm extends FieldValues,
  TName extends FieldPath<TForm>,
>({
  controllerProps,
}: ControlledInputPercentRangeProps<TForm, TName>) => (
  <ControllerWrapper {...controllerProps} component={LabeledPercentRangeInput} />
);
