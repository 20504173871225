import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";
import { WrapperWithInputsMixin } from "../../../../shared";

export const FormsAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 16px;

  padding: 0px 8px 8px;

  overflow-y: auto;

  ${ScrollSize}
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 8px;

  ${WrapperWithInputsMixin}
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;
