import { ComponentPropsWithoutRef, useContext } from "react";
import Icons from "src/icons/Icons";
import { ListDEXBot } from "src/state/DEX/DEXBots";
import { ThemeContext } from "styled-components";
import { BotMark } from "../../shared/BotMark";
import { BotPairButton } from "../../shared/BotPairButton";
import { BotContainer, Exchange, IconWrapper, Message, Time } from "../../shared/shared";
import { IBotCredential, copyBotCredential } from "../../shared/utils";
import * as styles from "./style";

interface DEXBotOwnProps extends ListDEXBot {
  favorite: boolean;
  toggleIsWatched: (v: string) => void;
}
export interface DEXBotProps
  extends DEXBotOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, keyof DEXBotOwnProps> {
  credential: IBotCredential;
}

export const DEXBot = ({
  swap_bot_uuid,
  favorite,
  toggleIsWatched,
  visible,
  client,
  pair,
  exchange,
  link,
  lastTrade,
  nextTrade,
  message,
  colorStatus,
  ...props
}: DEXBotProps) => {
  const {
    market,
    swap_bot_id,
    base,
    note,
    party,
    party_id,
    quote,
    created_at,
    updated_at,
    credential,
    ...containerProps
  } = props;

  const { botColorStatus: colors, botBgColorStatus: bgColors } = useContext(ThemeContext);

  return (
    <BotContainer {...containerProps}>
      {favorite ? (
        <styles.Favorites>
          <IconWrapper onClick={() => toggleIsWatched(swap_bot_uuid)}>
            {visible ? Icons.startStatusTrue() : Icons.startStatusFalse()}
          </IconWrapper>
        </styles.Favorites>
      ) : null}

      <styles.DEXFrame color={colors[colorStatus]}>
        <styles.Content style={{ backgroundColor: bgColors[colorStatus] }}>
          <styles.CellContent>
            <BotPairButton to={`/DEX/${swap_bot_uuid}/?tab=STATS`} color={colors[colorStatus]}>
              {pair?.split("_").join(" _ ")}
            </BotPairButton>
          </styles.CellContent>

          <styles.CellContent>
            <Exchange href={link || "#"} target="_blank">
              {" "}
              {exchange}
            </Exchange>
          </styles.CellContent>

          <styles.CellContent>
            <Time> {lastTrade}</Time>
          </styles.CellContent>

          <styles.CellContent>
            <Time> {nextTrade}</Time>
          </styles.CellContent>

          <styles.CellContent style={{ padding: "5px 7px 5px 10px" }}>
            <Message>{message}</Message>
          </styles.CellContent>

          <styles.CellContent>
            <BotMark
              onClick={() => copyBotCredential(credential)}
              color={colors[colorStatus]}
              size="l"
            />
          </styles.CellContent>
        </styles.Content>
      </styles.DEXFrame>
    </BotContainer>
  );
};
