import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { SaveButton } from "src/components/shared/Buttons/v2/variants/SaveButton";
import { CardPanel } from "src/components/shared/Panels/CardPanel";
import { BotModulesContext } from "src/context/CEX/BotModules/BotModulesProvider";
import { AbilitiesContext } from "src/context/shared/Abilities";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { CEXBindingModule } from "src/state/CEX/botModules";
import { FormContent } from "./shared/FormContent";
import * as styles from "./style";

export interface ModulePanelProps extends ComponentPropsWithoutRef<"div"> {
  module: CEXBindingModule;
}

export const ModulePanel = observer(({ module, children }: ModulePanelProps) => {
  const state = useLateInitContext(BotModulesContext);

  const { abilityName } = useLateInitContext(AbilitiesContext);

  const {
    formState: { isDirty },
    reset,
  } = useFormContext<CEXBindingModule>();

  const formId = useMemo(() => module.kind + module.uuid, [module]);

  const BtnPanel = () => (
    <PermissionWrapper abilityName={abilityName} party={state.party}>
      <styles.BtnWrapper>
        <SaveButton form={formId} saved={!isDirty} />

        <DeleteButton onClick={state.removeHandler(module)} />
      </styles.BtnWrapper>
    </PermissionWrapper>
  );

  return (
    <CardPanel labelElement={<styles.Name>{module.name}</styles.Name>} afterTitle={<BtnPanel />}>
      <FormContent formId={formId} submitHandler={state.editModule(reset)}>
        {children}
      </FormContent>
    </CardPanel>
  );
});
