import { unixToDateFormat } from "src/helpers/dateUtils";
import { IStrategyModuleInfo } from "src/modules/expertSystem";
import { InfoColumn, InfoLabel, InfoRow, InfoUniqueName } from "../../../../shared";
import { useParamsText } from "../../../../shared/hooks/useParamText";
import * as styles from "./style";

export interface InfoModalContentProps {
  module: IStrategyModuleInfo;
}

export const InfoModalContent = ({
  module: { uuid, unique_name, current, updated_at, hard_params },
}: InfoModalContentProps) => {
  const paramsText = useParamsText(hard_params);

  return (
    <styles.Container>
      <InfoRow>
        <InfoLabel>UUID:</InfoLabel>
        <InfoUniqueName>{uuid}</InfoUniqueName>
      </InfoRow>

      <InfoRow>
        <InfoLabel>Identifier:</InfoLabel>
        <InfoUniqueName>{unique_name}</InfoUniqueName>
      </InfoRow>

      {current ? (
        <InfoRow>
          <InfoLabel>Current:</InfoLabel>
          <InfoUniqueName>{current}</InfoUniqueName>
        </InfoRow>
      ) : null}

      {updated_at ? (
        <InfoRow>
          <InfoLabel>Updated at:</InfoLabel>
          <InfoUniqueName>{unixToDateFormat(updated_at, "FullDate")}</InfoUniqueName>
        </InfoRow>
      ) : null}

      <InfoColumn>
        <InfoLabel>Parameters:</InfoLabel>
        <InfoUniqueName>{paramsText}</InfoUniqueName>
      </InfoColumn>
    </styles.Container>
  );
};
