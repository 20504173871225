import { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { ChartApiContext } from "src/context/Graph/ChartApi";
import { ChartScaleContext } from "src/context/Graph/ChartScale";
import { noOp } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useResizeObserver from "src/hooks/useResizeObserver";
import { GraphRootProps } from "..";

export interface SelectionCanvasProps extends Pick<GraphRootProps, "allowTimeScale" | "request"> {
  paneCanvas: React.RefObject<HTMLCanvasElement>;
  paneCanvasContainer: HTMLDivElement | null;
  paneCrosshairCanvas: HTMLCanvasElement | null;
}

export const SelectionCanvas = ({
  paneCanvas,
  paneCanvasContainer,
  paneCrosshairCanvas,
  allowTimeScale,
  request = noOp,
}: SelectionCanvasProps) => {
  const chart = useLateInitContext(ChartApiContext);

  const state = useLateInitContext(ChartScaleContext);

  const selectionCanvas = useRef<HTMLCanvasElement>(null);

  const setCrosshairVisible = useCallback(
    (show: boolean) => {
      const chartApi = chart?.api;
      chartApi?.applyOptions({
        crosshair: {
          vertLine: { visible: show, labelVisible: show },
          horzLine: { visible: show, labelVisible: show },
        },
      });
    },
    [chart]
  );

  const handleDown = useCallback(
    (e: MouseEvent) => {
      const chartApi = chart?.api;

      state.findCanvasViewPortPosition(paneCrosshairCanvas);
      state.setStartPoint(e.clientX, chartApi?.timeScale());
      setCrosshairVisible(false);
    },
    [chart, paneCrosshairCanvas, setCrosshairVisible, state]
  );

  const handleMove = useCallback(
    (e: MouseEvent) => {
      const selectionCanvasEl = selectionCanvas.current;
      if (!selectionCanvasEl) return;

      state.paintOverScale(e.clientX, selectionCanvasEl);
    },
    [selectionCanvas, state]
  );

  const handleUp = useCallback(
    (e: MouseEvent) => {
      const chartApi = chart?.api;
      const selectionCanvasEl = selectionCanvas.current;
      if (!selectionCanvasEl) return;

      state.setEndPoint(e.clientX, chartApi?.timeScale(), request, selectionCanvasEl);
      setCrosshairVisible(true);
    },
    [chart, request, selectionCanvas, setCrosshairVisible, state]
  );

  const handleOut = useCallback(() => {
    state.resetPoints();

    const selectionCanvasEl = selectionCanvas.current;
    if (!selectionCanvasEl) return;

    state.resetCanvas(selectionCanvasEl);
    setCrosshairVisible(true);
  }, [selectionCanvas, setCrosshairVisible, state]);

  useResizeObserver(paneCanvas.current, () => {
    const selectionCanvasNode = selectionCanvas.current;
    const paneCanvasNode = paneCanvas.current;
    if (!selectionCanvasNode || !paneCanvasNode) return;
    selectionCanvasNode.width = paneCanvasNode.width;
    selectionCanvasNode.height = paneCanvasNode.height;

    selectionCanvasNode.style.zIndex = paneCanvasNode.style.zIndex;
    selectionCanvasNode.style.width = paneCanvasNode.style.width;
    selectionCanvasNode.style.height = paneCanvasNode.style.height;
  });

  useEffect(() => {
    if (!allowTimeScale) return;

    if (!paneCrosshairCanvas) return;

    paneCrosshairCanvas.addEventListener("mousedown", handleDown);
    paneCrosshairCanvas.addEventListener("mousemove", handleMove);
    paneCrosshairCanvas.addEventListener("mouseup", handleUp);
    paneCrosshairCanvas.addEventListener("mouseout", handleOut);
    return () => {
      paneCrosshairCanvas.removeEventListener("mousedown", handleDown);
      paneCrosshairCanvas.removeEventListener("mousemove", handleMove);
      paneCrosshairCanvas.removeEventListener("mouseup", handleUp);
      paneCrosshairCanvas.removeEventListener("mouseout", handleOut);
    };
  }, [allowTimeScale, handleDown, handleMove, handleUp, handleOut, paneCrosshairCanvas]);

  return (
    <>
      {allowTimeScale &&
        paneCanvasContainer &&
        createPortal(
          <canvas
            ref={selectionCanvas}
            style={{ position: "absolute", top: "0px", left: "0px" }}
          />,
          paneCanvasContainer
        )}
    </>
  );
};
