import { ComponentPropsWithoutRef } from "react";
import { useId } from "src/hooks/useId";
import { FooterPanel, FooterPanelProps } from "../FooterPanel";
import * as styles from "./style";

export interface FilterPanelContentProps
  extends Pick<FooterPanelProps, "onReset">,
    Pick<ComponentPropsWithoutRef<"form">, "onSubmit" | "children">,
    Pick<ComponentPropsWithoutRef<"div">, "style" | "className"> {}

export const FilterPanelContent = ({
  onReset,
  onSubmit,
  children,
  ...props
}: FilterPanelContentProps) => {
  const formId = useId(5);

  return (
    <styles.Content {...props}>
      <styles.FormContainer id={formId} onSubmit={onSubmit}>
        {children}
      </styles.FormContainer>

      <FooterPanel onReset={onReset} formId={formId} />
    </styles.Content>
  );
};
