import { createPortal } from "react-dom";
import {
  AddLiquidityAccountModal,
  AddLiquidityAccountModalProps,
} from "src/components/BotsContent/CEX/CEXApiKeys/Accounts/LiquidityAccounts/AddLiquidityAccountModal";
import { useAccountModalState } from "src/components/BotsContent/CEX/CEXApiKeys/Accounts/VolumeAccounts";
import { AccountsHeader } from "src/components/BotsContent/CEX/CEXApiKeys/shared/AccountsHeader";
import { LiquidityAccountName } from "src/modules/accounts";
import { Account } from "../Account";
import * as styles from "./style";

export interface LiquidityAccountsProps
  extends Pick<AddLiquidityAccountModalProps, "onRefresh" | "onAddAccount" | "bindingsProvider"> {
  accountNames: LiquidityAccountName[];
}

export const LiquidityAccounts = ({ accountNames, ...props }: LiquidityAccountsProps) => {
  const { show: showAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useAccountModalState();

  const liquidityAccounts = accountNames.map((name) => <Account key={name} accountName={name} />);

  return (
    <styles.LiquidityContainer>
      <AccountsHeader type="liquidity" onAddAccount={onOpenAdd} checkPermission={false} />
      <styles.AccountsContainer>{liquidityAccounts}</styles.AccountsContainer>
      {createPortal(
        <AddLiquidityAccountModal show={showAdd} close={onCloseAdd} {...props} />,
        document.body
      )}
    </styles.LiquidityContainer>
  );
};
