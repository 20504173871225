import styled from "styled-components";

export const CellText = styled.span`
  word-spacing: -0.5px;
  white-space: nowrap;
`;

export const WrapperCell = styled(CellText)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;
`;

export const Currency = styled(CellText)`
  font-weight: bold;
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  gap: 10px;
`;
