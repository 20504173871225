import styled, { css } from "styled-components";
import { MainSettingsPanelMixin } from "../Volume/style";
import { SettingsForm } from "../shared/SettingsForm";
import { OrientationProps } from "../shared/SettingsFormContent/style";
import { MainPanel as BaseMainPanel } from "./MainPanel";
import { SwapWalletsPanel as BaseSwapWalletsPanel } from "./SwapWalletsPanel";
import { TransferWalletsPanel as BaseTransferWalletsPanel } from "./TransferWalletsPanel";

export const MainPanel = styled(BaseMainPanel)`
  ${MainSettingsPanelMixin}
`;

export const TransferWalletsPanel = styled(BaseTransferWalletsPanel)``;

export const SwapWalletsPanel = styled(BaseSwapWalletsPanel)``;

export interface SettingsContainerProps extends Partial<OrientationProps> {}

export const SettingsContainer = styled.div<SettingsContainerProps>`
  width: 100%;

  display: flex;

  ${({ $orientation = "row" }) => {
    switch ($orientation) {
      case "column":
      case "column-reverse": {
        return css`
          flex-direction: ${$orientation};
          gap: 20px;
        `;
      }
      case "row":
      case "row-reverse": {
        return css`
          flex-direction: ${$orientation};
          gap: 15px;
        `;
      }
    }
  }}
`;

export const WalletsSettings = styled(SettingsForm)`
  flex: 1;
  /* min-width: 170px; */
`;

export const TransferWalletsSettings = styled(WalletsSettings)``;

export const SwapWalletsSettings = styled(WalletsSettings)``;

export const MainSettings = styled(SettingsForm)`
  flex-basis: 40%;
`;
