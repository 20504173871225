import styled from "styled-components";

export const WrapperForVirtWallsInputs = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-top: 19px;

  & > div:not(:last-of-type) {
    margin-right: 34px;
  }

  @media screen and (max-width: 850px) {
    & > div:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 376px) {
    flex-direction: column;
    padding-top: 15px;
  }
`;

export const Wrapper = styled.div`
  display: contents;

  & > div:not(:last-of-type) {
    margin-right: 34px;
  }

  @media screen and (max-width: 376px) {
    display: flex;
    & > div:not(:last-of-type) {
      margin-right: 6px;
    }
  }
`;

export const WrapperForInputs = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HorWrapperInputs = styled(WrapperForInputs)`
  @media screen and (max-width: 376px) {
    flex-direction: row;

    & > div:not(:last-of-type) {
      margin-right: 6px;
    }
  }
`;

export const WrapperForCheckBoxWall = styled.div`
  min-height: 48.6px;
  display: flex;
  padding-top: 18px;
  align-items: center;
`;
