import { observer } from "mobx-react-lite";
import { WalletsContext } from "src/components/BotsContent/DEX/shared/context/Stats";
import { SeparatorNum } from "src/components/shared/Separator";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import DEXStatsIcons from "src/icons/DEXStatsIcons";
import { BalancePoint, Wallet } from "src/state/DEX/DEXStats/Wallets";
import { Copy, Row } from "../style";
import * as styles from "./style";

type WalletType = "MM" | "Track";

export interface WalletRowProps {
  title: WalletType;
  wallets: Wallet[];
  leftBalances: BalancePoint[];
  rightBalances: BalancePoint[];
  nativeBalances: BalancePoint[];
}

export const WalletRow = observer(
  ({ wallets = [], title, leftBalances, rightBalances, nativeBalances }: WalletRowProps) => {
    const state = useLateInitContext(WalletsContext);

    const cutWallet = (wallet: string) =>
      `${wallet.slice(0, 5)}...${wallet.slice(wallet.length - 4, wallet.length)}`;

    return (
      <Row>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Title key={el.open}>{`${title}${index + 1}`}</styles.Title>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Wallet
              key={el.open}
              href={state.exchangeMeta ? state.exchangeMeta.walletLink + el.open : "#"}
            >
              {cutWallet(el.open)}
            </styles.Wallet>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <Copy key={el.open} onClick={state.copyWalletHandler(el.open)}>
              {DEXStatsIcons.copy()}
            </Copy>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Value
              key={el.open}
              allowance={leftBalances.length ? leftBalances[index]?.allowance : null}
            >
              <SeparatorNum value={leftBalances.length ? leftBalances[index].value : ""} />
            </styles.Value>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Value
              key={el.open}
              allowance={rightBalances.length ? rightBalances[index]?.allowance : null}
            >
              <SeparatorNum value={rightBalances.length ? rightBalances[index].value : ""} />
            </styles.Value>
          ))}
        </styles.Column>
        <styles.Column>
          {wallets?.map((el, index) => (
            <styles.Value
              key={el.open}
              allowance={nativeBalances.length ? nativeBalances[index]?.allowance : null}
            >
              <SeparatorNum value={nativeBalances.length ? nativeBalances[index].value : ""} />
            </styles.Value>
          ))}
        </styles.Column>
      </Row>
    );
  }
);
