import { useEffect, useState } from "react";

export const useHover = <T extends Element>() => {
  const [hovering, setHovering] = useState(false);
  const [ref, setRef] = useState<T | null>(null);

  useEffect(() => {
    const node = ref;

    if (!node) return;

    const handleMouseEnter = () => {
      setHovering(true);
    };

    const handleMouseLeave = () => {
      setHovering(false);
    };

    node.addEventListener("mouseenter", handleMouseEnter);
    node.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      node.removeEventListener("mouseenter", handleMouseEnter);
      node.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [ref]);

  return [setRef, hovering] as const;
};
