import * as styles from "./style";

export interface ChainInfoChipOwnProps {
  abbr?: string;
  icon?: JSX.Element;
  color?: string;
}

export interface ChainInfoChipProps
  extends ChainInfoChipOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof ChainInfoChipOwnProps> {}

export const ChainInfoChip = ({ abbr, icon, ...props }: ChainInfoChipProps) => (
  <styles.ChipContainer {...props}>
    {icon && <styles.IconWrapper>{icon}</styles.IconWrapper>}
    {abbr && <styles.Text>{abbr}</styles.Text>}
  </styles.ChipContainer>
);
