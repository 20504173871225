import { UseFieldResponse } from "@mui/x-date-pickers/internals";
import {
  FormField,
  FormFieldControlProps,
  FormFieldOuterProps,
} from "src/components/shared/Forms/FormField";
import { LabelInputCombinedProps } from "src/components/shared/Forms/Inputs";
import { DateTimeInput, DateTimeInputProps } from "../DateTimeInput";

export interface LabeledDateTimeInputOwnProps
  extends Omit<UseFieldResponse<{}>, "ref" | "error">,
    Pick<DateTimeInputProps, "inputRef" | "after"> {}

export interface LabeledDateTimeInputProps
  extends LabeledDateTimeInputOwnProps,
    LabelInputCombinedProps,
    Pick<FormFieldOuterProps, "style" | "className"> {}

export const LabeledDateTimeInput = ({
  label,
  errorHint,
  style,
  className,
  ...props
}: LabeledDateTimeInputProps) => {
  const fieldProps = { label, errorHint, style, className };
  const inputProps = props;

  // eslint-disable-next-line react/no-unstable-nested-components
  const FieldControl = ({ isError, id }: FormFieldControlProps) => (
    <DateTimeInput {...inputProps} error={isError} id={id} />
  );

  return <FormField control={FieldControl} {...fieldProps} />;
};
