import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { useExchangesLegendV2State } from "src/context/CEX/Dashboard/ExchangesLegendV2";
import * as styles from "./style";

export interface ExchangesLegendV2Props extends ComponentPropsWithoutRef<"div"> {}

export const ExchangesLegendV2 = observer((props: ExchangesLegendV2Props) => {
  const { chartSelectorProps } = useExchangesLegendV2State();
  return (
    <styles.LegendContainer {...props}>
      {chartSelectorProps && <styles.PieSelector {...chartSelectorProps} />}
      <styles.Table />
    </styles.LegendContainer>
  );
});
