import { ColumnSort, SortingState, Updater } from "@tanstack/react-table";
import { makeAutoObservable, runInAction } from "mobx";
import { joinQueryValues } from "src/api";
import { FiltersParams, GetModulesRequestParams, ModulesSortingParams } from "src/api/expertSystem";
import { PaginationMetaResponse, PaginationParams } from "src/api/types";
import { OnSortingChange } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { makeLoggable } from "src/helpers/logger";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { IDisposable, arrToObj, entries } from "src/helpers/utils";
import { IStrategyCategoryModuleInfo, StrategyModuleCategory } from "src/modules/expertSystem";
import WindowConsent from "src/state/WindowConsent";
import { ModuleFiltersState } from "../Filters/ModuleFiltersStore";

export interface IPartiesProvider {
  get party(): string;
}

export interface RemoveModuleParams {
  uuid: string;
  onSuccess?: () => void;
}

export interface GetModulesResponse<T extends StrategyModuleCategory> {
  items: IStrategyCategoryModuleInfo<T>[];
  meta: PaginationMetaResponse;
}

const MODULES_SORTING_IDS = ["name", "created_at"] as const;

type ModulesSortingId = (typeof MODULES_SORTING_IDS)[number];

interface ModulesColumnSort extends ColumnSort {
  id: ModulesSortingId;
}

type ConditionsSortingState = ModulesColumnSort[];

const INITIAL_SORTING_STATE: ConditionsSortingState = [{ id: "created_at", desc: true }];

export type GetModuleCb<T extends StrategyModuleCategory> = (
  party: string,
  params?: GetModulesRequestParams
) => Promise<GetModulesResponse<T> | null>;

export type DeleteModuleCb = (uuid: string) => Promise<boolean>;

export interface IModulesInfoParams<T extends StrategyModuleCategory> {
  getModules: GetModuleCb<T>;
  deleteModule: DeleteModuleCb;
  partiesProvider: IPartiesProvider;
  pageSize?: number;
}

//  generate interface for this class
export interface IModulesInfoStore<T extends StrategyModuleCategory> {
  get loading(): boolean;
  get modules(): IStrategyCategoryModuleInfo<T>[];
  get pagesCount(): number;
  get pageSize(): number;
  get currentPage(): number;
  get sortingState(): SortingState;
  get filtersState(): ModuleFiltersState<T> | undefined;
  get filtersCount(): number;

  setPageSize: (pageSize: number) => void;
  setCurrentPage: (page: number) => void;
  setSortingState: (updater: Updater<SortingState>) => Promise<void>;
  getModules: (fromPage: number, pageSize?: number) => Promise<void>;
  refreshModules: () => Promise<void>;
  getModuleById: (uuid: string) => IStrategyCategoryModuleInfo<T> | undefined;
  removeModule: (params: RemoveModuleParams) => void;
  updateFilters: (state: ModuleFiltersState<T>) => Promise<boolean | undefined>;
}

export default class ModulesInfoStore<T extends StrategyModuleCategory>
  implements IModulesInfoStore<T>, IDisposable
{
  private _modules: IStrategyCategoryModuleInfo<T>[] = [];

  private _partiesProvider: IPartiesProvider;

  private _loading = false;

  private _currentPage = 0;

  private _pageSize: number;

  private _pagesCount = 0;

  private _filtersState?: ModuleFiltersState<T>;

  private _sortingState: ConditionsSortingState = INITIAL_SORTING_STATE;

  private _getModulesCb: GetModuleCb<T>;

  private _deleteModuleCb: DeleteModuleCb;

  constructor({ partiesProvider, getModules, deleteModule, pageSize }: IModulesInfoParams<T>) {
    makeAutoObservable(this);

    this._partiesProvider = partiesProvider;
    this._getModulesCb = getModules;
    this._deleteModuleCb = deleteModule;

    this._pageSize = pageSize ?? 10;

    makeLoggable<this, "_sortingQueryParams">(this, {
      filtersState: true,
      sortingState: true,
      _sortingQueryParams: true,
    });
  }

  get loading() {
    return this._loading;
  }

  get modules() {
    return this._modules;
  }

  get pagesCount() {
    return this._pagesCount;
  }

  setPageSize = (pageSize: number) => {
    this._pageSize = pageSize;
  };

  get pageSize() {
    return this._pageSize;
  }

  setCurrentPage = (page: number) => {
    this._currentPage = page;
  };

  get currentPage() {
    return this._currentPage;
  }

  setSortingState = async (updater: Updater<SortingState>) => {
    this._updateSortingState(updater);

    await this._getModules();
  };

  get sortingState() {
    return this._sortingState;
  }

  private get _modulesMap(): Partial<Record<string, IStrategyCategoryModuleInfo<T>>> {
    return arrToObj(this._modules, ({ uuid }) => uuid);
  }

  getModules = async (fromPage: number, pageSize?: number) => {
    await this._getModules(fromPage, pageSize);
  };

  refreshModules = async () => {
    await this._getModules();
  };

  getModuleById = (uuid: string) => {
    const modulesMap = this._modulesMap;
    return modulesMap[uuid];
  };

  removeModule = (params: RemoveModuleParams) => {
    const module = this.getModuleById(params.uuid);

    WindowConsent.showWindow(
      "",
      `Are you sure you want to remove ${module?.name} condition?`,
      this._removeModule,
      params
    );
  };

  updateFilters = async (state: ModuleFiltersState<T>) => {
    this._setFiltersState(state);

    this._resetPagination();

    const isSuccess = await this._getModules();

    return isSuccess;
  };

  get filtersState() {
    return this._filtersState;
  }

  get filtersCount() {
    const filtersState = this._filtersState;
    if (!filtersState) return 0;

    const totalCount = Object.values(filtersState).reduce(
      (count, values) => count + values.length,
      0
    );

    return totalCount;
  }

  private get _party() {
    return this._partiesProvider.party;
  }

  private _resetPagination = () => {
    this.setCurrentPage(0);
  };

  private _updatePaginationParams = (fromPage?: number, pageSize?: number) => {
    if (fromPage !== undefined) {
      this.setCurrentPage(fromPage);
    }

    if (pageSize) {
      this.setPageSize(pageSize);
    }
  };

  private get _paginationQueryParams(): PaginationParams {
    const currentPage = this._currentPage + 1;
    const pageSize = this._pageSize;
    return {
      page: `${currentPage}`,
      limit: `${pageSize}`,
    };
  }

  private get _filtersQueryParams(): FiltersParams | undefined {
    const filtersState = this._filtersState;
    if (!filtersState) return undefined;

    for (const [filterKey, filterValue] of entries(filtersState)) {
      if (!filterValue.length) continue;
      return {
        filter_key: filterKey,
        filter_val: joinQueryValues(filterValue),
      };
    }

    return undefined;
  }

  private get _sortingQueryParams(): ModulesSortingParams | undefined {
    const columnSort = this._sortingState[0];
    if (!columnSort) return undefined;
    const { desc, id } = columnSort;
    return {
      sort_by: id,
      sort_dir: desc ? "desc" : "asc",
    };
  }

  private get _queryParams(): GetModulesRequestParams {
    const paginationParams = this._paginationQueryParams;
    const filtersParams = this._filtersQueryParams;
    const sortingParams = this._sortingQueryParams;

    const queryParams: GetModulesRequestParams = {
      pagination: paginationParams,
      filter: filtersParams,
      sort: sortingParams,
    };

    return queryParams;
  }

  private get _currentSortingStateMap() {
    return arrToObj(this._sortingState, ({ id }) => id);
  }

  //  computes what's changed in a new sorting state
  private _getSortingStateDiff = (newState: ConditionsSortingState) => {
    const stateDiff: ConditionsSortingState = [];

    const currentSortingMap = this._currentSortingStateMap;

    newState.forEach((columnState) => {
      if (
        !currentSortingMap[columnState.id] ||
        currentSortingMap[columnState.id].desc !== columnState.desc
      ) {
        stateDiff.push(columnState);
      }
    });

    return stateDiff;
  };

  private _setSortingState = (sortingState: SortingState) => {
    const newSortingState = sortingState as ConditionsSortingState;
    const stateDiff = this._getSortingStateDiff(newSortingState);

    // if diff is empty => all columns are unsorted (new state should be empty)
    // so we restore the initial sorting state here
    if (stateDiff.length === 0) {
      this._sortingState = INITIAL_SORTING_STATE;
      return;
    }

    // else apply the first diff as a new sorting state
    const firstDiffColumn = stateDiff[0];

    this._sortingState = [firstDiffColumn];
  };

  private _updateSortingState: OnSortingChange = (updater) => {
    if (typeof updater === "function") {
      const newState = updater(this._sortingState);
      this._setSortingState(newState);
    } else {
      this._setSortingState(updater);
    }
  };

  private _setFiltersState = (filtersState: ModuleFiltersState<T>) => {
    this._filtersState = filtersState;
  };

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _setModules = (modules: IStrategyCategoryModuleInfo<T>[]) => {
    this._modules = modules;
  };

  private _resetModules = () => {
    this._setModules([]);
  };

  private _getModules = async (fromPage?: number, pageSize?: number) => {
    const party = this._party;
    if (!party) return;

    this._updatePaginationParams(fromPage, pageSize);

    this._setLoading(true);
    try {
      const queryParams = this._queryParams;

      const data = await this._getModulesCb(party, queryParams);

      if (data) {
        const {
          items: modules,
          meta: { pages },
        } = data;

        this._setModules(modules);

        runInAction(() => {
          this._pagesCount = pages;
        });

        return true;
      }

      this._resetModules();
    } catch (error) {
      this._resetModules();
    } finally {
      this._setLoading(false);
    }
  };

  private _removeModule = async ({ uuid, onSuccess }: RemoveModuleParams) => {
    const module = this.getModuleById(uuid);

    try {
      const isSuccess = await this._deleteModuleCb(uuid);

      if (isSuccess) {
        showSuccessMsg(`Module ${module?.name} removed successfully`);
        onSuccess?.();

        await this.refreshModules();
      }
    } catch (error) {
      logError(error);
    }
  };

  destroy = () => {};
}
