import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { IBotModule } from "src/state/CEX/botModules";
import { CreateModule } from "..";
import { MainBotModuleForm } from "../../Modules/MainBotModule/MainBotModuleForm";

export const CreateMainBotModule = observer(() => {
  const methods = useForm<IBotModule>({ defaultValues: { kind: "bot" } });

  return (
    <FormProvider {...methods}>
      <CreateModule>
        <MainBotModuleForm />
      </CreateModule>
    </FormProvider>
  );
});
