import { ComponentPropsWithoutRef } from "react";
import { OwnRefProps } from "src/helpers/utils";
import * as styles from "./style";

export interface ButtonGroupProps
  extends Omit<ComponentPropsWithoutRef<"div">, keyof styles.ButtonGroupProps>,
    OwnRefProps<"div">,
    Partial<styles.ButtonGroupProps> {}

export const ButtonGroup = ({
  gap = "none",
  orientation = "horizontal",
  children,
  ownRef,
  ...props
}: ButtonGroupProps) => (
  <styles.ButtonGroup $gap={gap} $orientation={orientation} ref={ownRef} {...props}>
    {children}
  </styles.ButtonGroup>
);
