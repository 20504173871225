import { SwitchMenu } from "src/components/shared/SwitchMenu";
import { Label } from "src/components/shared/SwitchMenu/MenuItem/style";
import styled from "styled-components";

export const OrderFormSwitch = styled(SwitchMenu)`
  ${Label} {
    @media screen and (max-width: 450px) {
      font-size: ${({ theme }) => theme.fontSizes["10px"]};
    }
  }
`;
