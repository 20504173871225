import { ScrollSize } from "src/components/shared/shared";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;

  flex-direction: column;
`;

export interface TriggerPanelProps {
  isDisabled?: boolean;
}

export const TriggerPanel = styled.div<TriggerPanelProps>`
  position: relative;
  /* width: 63px; */
  height: 19px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.bgGrayBlack};

  cursor: pointer;
  padding: 0px 5px;
  border: 1px solid #a2a2a2;
  border-radius: 2px;

  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        opacity: 0.4;
        cursor: default;
        pointer-events: none;
      `;
    }
  }}

  ::before {
    position: absolute;
    content: "";
    width: 3px;
    height: 3px;
    right: 5px;
    top: 6px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    border-color: #a2a2a2;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all 0.1s;
  }
`;

export const Menu = styled.div`
  width: 100px;
  /* min-height: 80px; */
  max-height: 200px;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.selectMenuBg};

  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 5px;

  border-radius: ${({ theme }) => theme.radii["4px"]};
  box-shadow:
    0px 20px 25px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  border: none;
  background: ${({ theme }) => theme.selectMenuBg};
  z-index: 1000;

  ${ScrollSize}
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  padding: 5px;
  height: 28px;

  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  color: ${({ theme }) => theme.inputTextColor};

  :hover {
    background: ${({ theme }) => theme.inputHoverBgColor};
    color: ${({ theme }) => theme.primaryColor};
  }

  :last-of-type {
    border-radius: 0px 0px 4px 4px;
  }

  :first-of-type {
    border-radius: 4px 4px 0px 0px;
  }
`;
