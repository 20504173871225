import { ContentPanel } from "../../../../shared";
import { PanelWrapper } from "../../../../shared/style";
import { ActionList } from "./ActionList";
import { ModulesForms } from "./ModulesForms";

export const ActionsModules = () => (
  <PanelWrapper>
    <ContentPanel title="Available actions">
      <ActionList />
    </ContentPanel>
    <ContentPanel title="Module settings">
      <ModulesForms />
    </ContentPanel>
  </PanelWrapper>
);
