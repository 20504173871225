import { observer } from "mobx-react-lite";
import React from "react";
import { Loader } from "src/components/shared/Loader";
import { SeparatorNum } from "src/components/shared/Separator";

import * as styles from "./style";

const precision = {
  BTC: 8,
  ETH: 6,
  USD: 2,
  USDC: 2,
  BCH: 5,
  VET: 2,
};

export const BalancesTable = observer(
  ({ showLoader, balanceTableData, usdTotalPercent, deltaTotal }) => (
    <styles.BalancesTableContainer>
      <styles.BalancesTable>
        <styles.Head>
          <styles.HeaderRow>
            <styles.Header />
            <styles.Header>%</styles.Header>
            <styles.Header>Abs</styles.Header>
            <styles.Header>Abs $</styles.Header>
          </styles.HeaderRow>
        </styles.Head>
        <styles.Body>
          {balanceTableData.map(({ currency, deltaPercent, deltaCurrency, deltaAbs }) => (
            <styles.Row key={currency}>
              <styles.Cell>{currency}</styles.Cell>
              <styles.Cell>
                <SeparatorNum value={deltaPercent?.toFixed(2) ? deltaPercent?.toFixed(2) : "-"} />
              </styles.Cell>
              <styles.Cell>
                <SeparatorNum
                  value={deltaCurrency ? deltaCurrency.toFixed(precision[currency] || 4) : "-"}
                />
              </styles.Cell>
              <styles.Cell>
                <SeparatorNum value={deltaAbs ? deltaAbs?.toFixed(2) : "-"} />
              </styles.Cell>
            </styles.Row>
          ))}
        </styles.Body>
        <styles.TableFooter>
          <styles.Row>
            <styles.Cell>Total</styles.Cell>
            <styles.Cell>
              {/* <SeparatorNum
                  value={usdTotalPercent ? usdTotalPercent?.toFixed(2) : 0}
                /> */}
              _
            </styles.Cell>
            <styles.Cell>_</styles.Cell>
            <styles.Cell>
              <SeparatorNum value={deltaTotal ? deltaTotal?.toFixed(2) : 0} />
            </styles.Cell>
          </styles.Row>
        </styles.TableFooter>
      </styles.BalancesTable>
      <Loader show={showLoader} />
    </styles.BalancesTableContainer>
  )
);
