import { makeAutoObservable, runInAction } from "mobx";
import { transfer } from "src/api/bots/CEX/exchange";
import { getPathAndKey, getValueByPath } from "src/helpers/forms/getByKey";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { toRounding } from "src/helpers/rounding";
import { balanceComparison, getAccBalances } from "src/helpers/trading";
import { isVolumeAccountName } from "src/modules/accounts";
import { Transfer } from "src/modules/exchange/trade";
import { SelectorValue } from "src/modules/shared";
import windowConsent from "src/state/WindowConsent";
import { isNumber, required, validateData } from "src/validation-schemas";
import ExchangeStore from "..";
import {
  accountsMapToSelectorValue,
  filterBaseNameAccounts,
  sortAccounts,
} from "../../CEXApiKeys/AccountsBindings";

type SelectorFields = "sellAccountUUID" | "buyAccountUUID";

type InputFields = "price" | "amount" | "sellFirst" | "totalAsset";

class TransferStore {
  data: Transfer = {
    // bot_uuid: "",
    pair: "",
    price: "",
    amount: "",
    sellAccountUUID: "",
    buyAccountUUID: "",
    sellFirst: true,
  };

  buyNameAcc = "";

  sellNameAcc = "";

  totalAsset: number | "" = 0;

  showLoader: boolean = false;

  validation = {
    price: [required(), isNumber()],
    amount: [required(), isNumber()],
    sellAccountUUID: required(),
    buyAccountUUID: required(),
  };

  errors = {
    // bot_uuid: "",
    price: "",
    amount: "",
    sellAccountUUID: "",
    buyAccountUUID: "",
  };

  mainState: ExchangeStore;

  constructor(state: ExchangeStore) {
    this.mainState = state;

    makeAutoObservable(this);
  }

  get accounts() {
    const accounts = filterBaseNameAccounts(this.mainState._accounts, ["info"]);

    const accountsOptions = accountsMapToSelectorValue(accounts).sort((a, b) =>
      sortAccounts(a.label, b.label)
    );

    return accountsOptions;
  }

  get currentBuyAcc() {
    return {
      id: this.data.buyAccountUUID,
      value: this.buyNameAcc,
      label: this.buyNameAcc,
    };
  }

  get currentSellAcc() {
    return {
      id: this.data.sellAccountUUID,
      value: this.sellNameAcc,
      label: this.sellNameAcc,
    };
  }

  setLoader = (bool: boolean) => {
    this.showLoader = bool;
  };

  setTotalAsset = (v: number | "") => {
    this.totalAsset = v;
  };

  setValue = <K extends keyof Transfer>(field: K, value: Transfer[K]) => {
    this.data[field] = value;
  };

  setAccount = (field: SelectorFields, data: SelectorValue) => {
    // to be able to select the same volume accounts
    const isVolumeAcc = isVolumeAccountName(String(data.value));

    if (field === "sellAccountUUID") {
      if (this.data.buyAccountUUID === data.id && this.buyNameAcc === data.value && !isVolumeAcc) {
        this.setValue("buyAccountUUID", this.data.sellAccountUUID);
        runInAction(() => {
          this.buyNameAcc = this.sellNameAcc;
        });
      }

      this.setValue("sellAccountUUID", String(data.id));
      runInAction(() => {
        this.sellNameAcc = String(data.value);
      });
    } else {
      if (
        this.data.sellAccountUUID === data.id &&
        this.sellNameAcc === data.value &&
        !isVolumeAcc
      ) {
        this.setValue("sellAccountUUID", this.data.buyAccountUUID);
        runInAction(() => {
          this.sellNameAcc = this.buyNameAcc;
        });
      }

      this.setValue("buyAccountUUID", String(data.id));
      runInAction(() => {
        this.buyNameAcc = String(data.value);
      });
    }
  };

  setField = <K extends keyof this>(field: K, value: this[K]) => {
    this[field] = value;
  };

  calcAvgPrice = (
    minSell: number,
    maxBuy: number,
    dontTradePriceMin: number,
    dontTradePriceMax: number,
    pricePrecision: number
  ) => {
    if (
      !dontTradePriceMin ||
      !dontTradePriceMax ||
      minSell < dontTradePriceMin ||
      maxBuy > dontTradePriceMax
    ) {
      this.setValue("price", +toRounding((minSell + maxBuy) / 2, pricePrecision));
    } else {
      this.setValue(
        "price",
        +toRounding(
          (Math.max(maxBuy, dontTradePriceMin) + Math.min(minSell, dontTradePriceMax)) / 2,
          pricePrecision
        )
      );
    }
    this.updateTotalAsset();
  };

  getHandler = (field: InputFields) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (field === "totalAsset") {
      this.setTotalAsset(this.getChangeEventValue(e));
      this.updateAmount();
    } else if (field === "price" || field === "amount") {
      this.setValue(field, this.getChangeEventValue(e));
      this.updateTotalAsset();
    } else this.setValue(field, this.getChangeEventCheckBox(e));
  };

  getChangeEventValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    return "";
  };

  getChangeEventCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => e.target.checked;

  getError = (key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  selectorHandler = (field: SelectorFields) => (data: SelectorValue | null) => {
    if (data) this.setAccount(field, data);
  };

  updateAmount = () => {
    if (this.totalAsset && this.data.price) {
      this.data.amount = +toRounding(
        +this.totalAsset / +this.data.price,
        this.mainState.amountPrecision
      );
    }
  };

  updateTotalAsset = () => {
    if (this.data.price && this.data.amount) {
      this.totalAsset = +this.data.price * +this.data.amount;
    }
  };

  validate = (validateKeys: string[] | undefined) =>
    validateData(this.validation, this.data, this.errors, validateKeys);

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate(undefined);

    if (valid) {
      this.setLoader(true);

      const [quote, base] = this.mainState.pair.split("_");

      try {
        const buyAccBalances = await getAccBalances({
          account_uuid: this.data.buyAccountUUID,
          account_name: this.buyNameAcc,
          quoteTicker: quote,
          baseTicker: base,
        });

        let checkQuote = false;
        let checkBase = false;

        if (buyAccBalances) {
          checkQuote = balanceComparison({
            balance: buyAccBalances.quoteBalance,
            account_name: this.buyNameAcc,
            ticker: quote,
            amount: +this.totalAsset,
            typeOperation: "TRANSFER",
          });
        }

        const sellAccBalances = await getAccBalances({
          account_uuid: this.data.sellAccountUUID,
          account_name: this.sellNameAcc,
          quoteTicker: quote,
          baseTicker: base,
        });

        if (sellAccBalances) {
          checkBase = balanceComparison({
            balance: sellAccBalances.baseBalance,
            account_name: this.sellNameAcc,
            ticker: base,
            amount: +this.data.amount,
            typeOperation: "TRANSFER",
          });
        }

        if (checkQuote && checkBase) {
          await this.transferRequest();
        } else {
          const textMessage = `Are you sure you want to transfer from ${this.buyNameAcc.toUpperCase()} to ${
            this.sellNameAcc
          }?`;

          const subTextMessage = `Insufficient funds in the account.\n 
            You need ${this.totalAsset} ${quote} on ${this.buyNameAcc.toUpperCase()}
             and ${this.data.amount} ${base} on ${this.sellNameAcc.toUpperCase()}`;

          windowConsent.showWindow(textMessage, subTextMessage, this.transferRequest);
        }
      } catch (error) {
        logError(error);
      } finally {
        this.setLoader(false);
      }
    }
  };

  transferRequest = async () => {
    this.data.amount = +toRounding(+this.data.amount, this.mainState.amountPrecision);

    this.data.pair = this.mainState.pair;

    try {
      const { isError } = await transfer(this.data);

      if (!isError) {
        showSuccessMsg("Applications were created successfully");

        this.mainState.updLimitTradingData();
      }
    } catch (error) {
      logError(error);
    }
  };
}

export default TransferStore;
