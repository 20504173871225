import { observer } from "mobx-react-lite";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { CopyButton } from "src/components/shared/Buttons/v2/variants/IconButtons/CopyButton";
import { StatusPoint } from "src/components/shared/GridTable";
import { BotWalletConnectionContext } from "src/context/DEXV2/DEXV2Stats/Vaults/BotWalletConnection";
import { setTextClipboard } from "src/helpers/clipboard";
import { strFormatter } from "src/helpers/string";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import BotWalletConnectionStore, {
  BotWalletConnectionState,
} from "src/state/DEXV2/DEXV2Stats/Vaults/BotWalletConnectionStore";
import { DeployButton } from "./DeployButton";
import * as styles from "./style";

const getStateContent = (state: BotWalletConnectionStore, deployed: boolean) => {
  if (!deployed) {
    return <DeployButton />;
  }
  switch (state.connectionState) {
    case BotWalletConnectionState.Initial: {
      return <StatusPoint type="danger">Please connect your wallet!</StatusPoint>;
    }
    case BotWalletConnectionState.Initialized: {
      return (
        <Button size="s" onClick={state.connectWallet}>
          Connect Wallet
        </Button>
      );
    }
    case BotWalletConnectionState.Connected: {
      const connectedWalletText = `Connected wallet: ${strFormatter(state.chainAccount)}`;
      return (
        <styles.Wrapper>
          <styles.AddressChip>{connectedWalletText}</styles.AddressChip>

          <CopyButton
            onClick={() => {
              setTextClipboard(state.chainAccount);
            }}
          />

          <IconButton onClick={state.disconnectWallet}>{Icons.moveBot()}</IconButton>
        </styles.Wrapper>
      );
    }
    case BotWalletConnectionState.WrongNetwork: {
      return (
        <styles.Wrapper>
          <styles.WrongNetworkChip>Wrong network</styles.WrongNetworkChip>
        </styles.Wrapper>
      );
    }
  }
};

export interface WalletConnectionProps {
  deployed?: boolean;
}

export const WalletConnection = observer(({ deployed = true }: WalletConnectionProps) => {
  const state = useLateInitContext(BotWalletConnectionContext);

  const stateContent = getStateContent(state, deployed);

  return <>{stateContent}</>;
});
