import { observer } from "mobx-react-lite";
import { Panel, PanelProps } from "../../base/Panel";
import StatsList, { StatsListProps } from "./StatsList";

export interface StatsPanelProps extends PanelProps, Pick<StatsListProps, "items"> {}

const StatsPanel = observer(({ items, ...props }: StatsPanelProps) => (
  <Panel {...props}>
    <StatsList items={items} />
  </Panel>
));

export default StatsPanel;
