import styled from "styled-components";

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding: 5px 0px;
`;

export const RowWrapper = styled.div`
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 15px;
`;

export const InfoRow = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights["400"]};
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
  color: ${({ theme }) => theme.lightGray};

  @media screen and (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["8px"]};
  }
`;

export const ValueRow = styled(InfoRow)`
  color: ${({ theme }) => theme.darkGray};
`;
