import React, { useMemo } from "react";
import { Observer, observer } from "mobx-react-lite";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ColumnDef } from "@tanstack/react-table";
import { OrderBookOrder } from "src/modules/exchange/orderBook";
import { CupProps, MAX_QUOTE_POINT, useGetOrderContent, usePriceCellConfig } from "../..";
import { VirtualBuyRowCup } from "./VirtualBuyRowCup";
import { BuyShell } from "../../shared";
import * as styles from "./style";

export interface BuyCupProps extends CupProps {
  buyCupRef: React.RefObject<HTMLTableSectionElement>;
  buyContainerRef: React.RefObject<HTMLDivElement>;
}

export const BuyCup = observer(
  ({ data = [], buyCupRef, buyContainerRef, showMobVers }: BuyCupProps) => {
    const mainState = useLateInitContext(ExchangeContext);
    const { orderBookState } = mainState;

    const getPriceConfig = usePriceCellConfig<OrderBookOrder>();
    const priceCellConfig = getPriceConfig("buyOrder");
    const getOrderContent = useGetOrderContent();

    const SelectionShell = observer(({ order }: { order: OrderBookOrder }) => (
      <BuyShell fill={+order.ourAmount ? (+order.ourAmount / +order.amount) * 100 : 0} />
    ));

    const columns = useMemo(() => {
      const columnSchema: ColumnDef<OrderBookOrder>[] = [
        priceCellConfig,
        {
          header: () => "",
          accessorKey: "amount",
          id: "amount_orderBook",
          cell: ({
            cell: {
              row: {
                original: { amount, total },
              },
            },
          }) => (
            <styles.Amount data-value={amount} highlight={+total >= MAX_QUOTE_POINT}>
              {getOrderContent(+amount, orderBookState.amountPrecision)}
            </styles.Amount>
          ),
        },
        {
          header: () => "",
          accessorKey: "total",
          id: "total_orderBook",
          cell: (info) => {
            const total = info.getValue<string>();

            return (
              <Observer>
                {() => (
                  <styles.Total data-value={total} highlight={+total >= MAX_QUOTE_POINT}>
                    {getOrderContent(+total, orderBookState.totalPrecision)}

                    <SelectionShell order={info.row.original} />
                  </styles.Total>
                )}
              </Observer>
            );
          },
        },
      ];

      return columnSchema;
    }, [orderBookState, priceCellConfig, SelectionShell, getOrderContent]);

    const shortColumns = useMemo(() => {
      const columnSchema: ColumnDef<OrderBookOrder>[] = [
        priceCellConfig,
        {
          header: () => "",
          accessorKey: "amount",
          id: "amount_orderBook",
          cell: ({
            cell: {
              row: { original },
            },
          }) => (
            <Observer>
              {() => (
                <>
                  <styles.Amount
                    data-value={original.amount}
                    highlight={+original.total >= MAX_QUOTE_POINT}
                  >
                    {getOrderContent(+original.amount, orderBookState.amountPrecision)}
                  </styles.Amount>

                  <SelectionShell order={original} />
                </>
              )}
            </Observer>
          ),
        },
      ];

      return columnSchema;
    }, [orderBookState, priceCellConfig, SelectionShell, getOrderContent]);

    return (
      <VirtualBuyRowCup
        buyCupRef={buyCupRef}
        buyContainerRef={buyContainerRef}
        rowSize={18}
        data={data}
        columns={showMobVers ? shortColumns : columns}
      />
    );
  }
);
