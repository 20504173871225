import { makeAutoObservable, runInAction } from "mobx";
import { MultiGrinderStateProps, getMultiGrinderState } from "src/api/bots/CEX/multigrinder";
import { logError } from "src/helpers/network/logger";
import { roundSingleValue } from "src/helpers/rounding";
import MultiGridStore from ".";

export type OrderState = "hot" | "cold";

export interface ExchangeState {
  buy: string;
  mmb: string;
  mmb_state: OrderState;
  mms: string;
  mms_state: OrderState;
  sell: string;
  side: string;
}

export interface IGridStateRow {
  price: string;
  buy: string;
  sell: string;
  columns: { [key: string]: ExchangeState };
}

type OrderError = { amount: string; price: string };

export interface IGridStats {
  buy_levels: number | "";
  sell_levels: number | "";
  disbalance_levels: number | "";
  position_base: string;
  position_quote: string;
  profit_base: string;
  profit_quote: string;
  buy_error: OrderError;
  sell_error: OrderError;
}

export type GridStateStep = "complete" | "update_status";

export interface IGridState {
  created_at: number;
  exchanges: string[];
  logs: string[];
  offset: number;
  party: string;
  profit: string;
  rows: IGridStateRow[];
  step: GridStateStep;
  stats: IGridStats;
}

interface MetaInfoGridState extends Omit<IGridState, "logs" | "exchanges" | "rows" | "stats"> {}

const GRID_STATS_EMPTY: IGridStats = {
  buy_levels: "",
  disbalance_levels: "",
  position_base: "",
  position_quote: "",
  profit_base: "",
  profit_quote: "",
  sell_levels: "",
  sell_error: {
    amount: "",
    price: "",
  },
  buy_error: {
    amount: "",
    price: "",
  },
};

const META_INFO_EMPTY: MetaInfoGridState = {
  created_at: 0,
  offset: 0,
  party: "",
  profit: "",
  step: "complete",
};

class MultiGridStateStore {
  private _stateRows: IGridStateRow[] = [];

  private _logs: string[] = [];

  private _exchanges: string[] = [];

  private _metaInfo: MetaInfoGridState = META_INFO_EMPTY;

  private _pageCount = 0;

  private _gridStats = GRID_STATS_EMPTY;

  offset: number = 0;

  stateCount = 0;

  isLoading = false;

  mainState: MultiGridStore;

  constructor(state: MultiGridStore) {
    this.mainState = state;

    this.mainState.setUpdHandlers("updGridState", this.loadData);

    makeAutoObservable(this);
  }

  get stateRows() {
    return this._stateRows;
  }

  get logs() {
    return this._logs;
  }

  get exchanges() {
    return this._exchanges;
  }

  get createdTime() {
    return this._metaInfo.created_at;
  }

  get currentStep() {
    return this._metaInfo.step;
  }

  get pageCount() {
    return this._pageCount;
  }

  get gridStats() {
    return this._gridStats;
  }

  get positionPrice() {
    const price =
      parseFloat(this.gridStats.position_quote) / parseFloat(this.gridStats.position_base);

    return roundSingleValue(Math.abs(price));
  }

  get sellErrorTotal() {
    const total =
      parseFloat(this.gridStats.sell_error.amount) * parseFloat(this.gridStats.sell_error.price);

    if (total) return roundSingleValue(total);

    return "";
  }

  get buyErrorTotal() {
    const total =
      parseFloat(this.gridStats.buy_error.amount) * parseFloat(this.gridStats.buy_error.price);

    if (total) return roundSingleValue(total);

    return "";
  }

  loadData = () => {
    this._getState({
      uuid: this.mainState.gridUUID,
      step: this.currentStep,
    });
  };

  getOffset = (fromPage: number) => {
    this._getState({
      uuid: this.mainState.gridUUID,
      step: this.currentStep,
      // state counting starts from 1
      // pagination countdown starts from 0
      offset: fromPage + 1,
    });
  };

  setOffset = (value: number) => {
    this.offset = value;
  };

  toggleStep = (step: GridStateStep) => {
    runInAction(() => {
      this._metaInfo.step = step;
    });

    this.getOffset(this.offset);
  };

  private _setData = (state: IGridState) => {
    const { logs, rows, exchanges, stats, ...other } = state;

    runInAction(() => {
      this._logs = logs;
    });
    runInAction(() => {
      this._stateRows = rows;
    });
    runInAction(() => {
      this._exchanges = exchanges;
    });
    runInAction(() => {
      this._metaInfo = other;
    });
    runInAction(() => {
      this._gridStats = stats;
    });

    runInAction(() => {
      // state counting starts from 1
      // pagination countdown starts from 0
      this.offset = other.offset - 1;
    });
  };

  private _resetData = () => {
    runInAction(() => {
      this._logs = [];
    });
    runInAction(() => {
      this._stateRows = [];
    });
    runInAction(() => {
      this._exchanges = [];
    });
    runInAction(() => {
      this._metaInfo = META_INFO_EMPTY;
    });
    runInAction(() => {
      this._gridStats = GRID_STATS_EMPTY;
    });
  };

  private _getState = async (props: MultiGrinderStateProps) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const { isError, data } = await getMultiGrinderState(props);

      if (!isError) {
        runInAction(() => {
          this._pageCount = data.count;
        });

        this._setData(data.state);
      } else this._resetData();
    } catch (error) {
      logError(error);
      this._resetData();
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default MultiGridStateStore;
