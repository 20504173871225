import { SwitchMenu } from "src/components/shared/SwitchMenu";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  gap: 5px;
`;

export const SwitchGraphs = styled(SwitchMenu)`
  width: 250px;
`;
