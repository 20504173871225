import { Container } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel/style";
import { PanelHeader } from "src/components/shared/PanelHeader";
import { Title } from "src/components/shared/PanelHeader/style";
import styled from "styled-components";

export const ApiKeysContainer = styled(Container)`
  padding: 20px 17px;
`;

export const Header = styled(PanelHeader)`
  padding: 0;
  margin-bottom: 16px;

  ${Title} {
    font-size: ${({ theme }) => theme.fontSizes.heading2};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
