import { ComponentPropsWithoutRef } from "react";
import { ModuleHeader, ModuleHeaderProps } from "../ModuleHeader";
import { HardParamsView, HardParamsViewProps } from "./HardParamsView";
import * as styles from "./style";

export interface ModuleSoftParamsShellProps
  extends HardParamsViewProps,
    ComponentPropsWithoutRef<"div"> {
  headerProps: ModuleHeaderProps;
}

export const ModuleSoftParamsShell = ({
  name,
  hardParams,
  headerProps,
  children,
}: ModuleSoftParamsShellProps) => (
  <styles.Container>
    <ModuleHeader {...headerProps} />

    <HardParamsView name={name} hardParams={hardParams} />

    {children}
  </styles.Container>
);
