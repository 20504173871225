import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { addScope } from "src/api/userManager/scopesAPI";
import { toast } from "src/components/shared/Toaster";
import { getPathAndKey, getTargetValueByPath, getValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { IDisposable, Optional } from "src/helpers/utils";
import { NewScope } from "src/modules/userManager";
import { required, textFormat, validateData } from "src/validation-schemas";
import { ListUpdate, ScopesUpdate } from "./ScopesStore";

type NewScopeForm = Optional<NewScope, "parent_id">;

const INITIAL_SCOPE: NewScopeForm = {
  name: "",
};

const ERROR_SCOPE_MESSAGE = `The scope name must be at least 5 and not more than 14 characters.
You can only enter Latin letters using _ - as well as numbers.`;

export default class AddScopeStore implements IDisposable {
  private _validation = {
    name: [
      required(),
      textFormat(ERROR_SCOPE_MESSAGE, /^[a-zA-Z0-9][a-zA-Z0-9_-]{0,12}[a-zA-Z0-9]$/),
    ],
    parent_id: required(),
  };

  private _listUpdater: ListUpdate;

  private _isShownReaction: IReactionDisposer;

  handlers: any = {};

  errors: any = {};

  isLoading = false;

  newScope: NewScopeForm = INITIAL_SCOPE;

  isShown: boolean = false;

  constructor({ getScopesList }: ScopesUpdate) {
    this._listUpdater = getScopesList;

    makeAutoObservable(this);

    this._isShownReaction = reaction(
      () => this.isShown,
      (isShown) => {
        if (!isShown) {
          this._resetForm();
        }
      }
    );
  }

  private _setIsModalShow(isShown: boolean) {
    this.isShown = isShown;
  }

  private _setModalShow = (isShown: boolean, scopeId?: number) => {
    this._setIsModalShow(isShown);
    this.newScope.parent_id = scopeId;
  };

  private _resetForm = () => {
    this.handlers = {};
    this.errors = {};
    this.newScope = INITIAL_SCOPE;
  };

  openModal = (scopeId: number) => {
    this._setModalShow(true, scopeId);
  };

  closeModal = () => {
    this._setModalShow(false);
  };

  getHandler = (key: string) => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.newScope, path);

      switch (key) {
        default: {
          this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
            targetData[endKey] = getChangeEventValue(e);
          };
        }
      }
    }

    return this.handlers[key];
  };

  getError = (key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  validate = (validateKeys: string[] | undefined) =>
    validateData(this._validation, this.newScope, this.errors, validateKeys);

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate(undefined);

    if (valid) {
      runInAction(() => {
        this.isLoading = true;
      });

      try {
        const { isError } = await addScope(this.newScope as NewScope);

        if (!isError) {
          toast.success("Scope created successfully");
          this._setIsModalShow(false);
          this._listUpdater();
        }
      } finally {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  };

  destroy() {
    this._isShownReaction();
  }
}
