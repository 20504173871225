import { ComponentPropsWithoutRef } from "react";
import { DEXV2ExchangeVersion } from "src/modules/bots";
import * as styles from "./style";

export interface DEXVersionChipProps extends ComponentPropsWithoutRef<"span"> {
  children: DEXV2ExchangeVersion;
}

export const DEXVersionChip = ({ children, ...props }: DEXVersionChipProps) => {
  const versionText = `v${children}`;

  return <styles.VersionSpan {...props}>{versionText}</styles.VersionSpan>;
};
