import { observer } from "mobx-react-lite";
import { FilterPanel } from "../../../shared";
import { useModuleState } from "../../hooks/useModuleState";
import { ConditionsFiltersContent } from "./ConditionsFiltersContent";

export interface ConditionsFiltersProps {}

export const ConditionsFilters = observer(() => {
  const { filtersCount } = useModuleState("condition");

  return (
    <FilterPanel count={filtersCount}>
      <ConditionsFiltersContent />
    </FilterPanel>
  );
});
