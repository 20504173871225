import { useForm } from "react-hook-form";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { StrategyListContext } from "src/context/CEX/ExpertSystem";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { UpdateStrategyLaunchesLimitData } from "src/state/expertSystem/strategies";
import { LaunchesLimitInput } from "../../../../shared/LaunchesLimitInput";
import * as styles from "./style";

export interface LaunchesLimitUpdateFormProps {
  uuid: string;
  defaultLimit?: number | "";
}

export const LaunchesLimitUpdateForm = ({
  uuid,
  defaultLimit = "",
}: LaunchesLimitUpdateFormProps) => {
  const state = useLateInitContext(StrategyListContext);
  const { close } = useLateInitContext(PopupContext);

  const { handleSubmit, control } = useForm<UpdateStrategyLaunchesLimitData>({
    defaultValues: { limit: defaultLimit },
  });

  return (
    <styles.FormContainer onSubmit={handleSubmit(state.updateLaunchesLimitHandler(uuid, close))}>
      <LaunchesLimitInput control={control} name="limit" />

      <SubmitButton size="l" />
    </styles.FormContainer>
  );
};
