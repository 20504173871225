import styled from "styled-components";
import { RadioIcon } from "./RadioIcon";

export const WrapperForRadio = styled.div`
  display: flex;
  align-items: center;
`;

export const TextLabel = styled.label`
  margin-right: 5px;
  color: ${({ theme }) => theme.smallHeader};
  cursor: pointer;
`;

export const LabelForRadio = styled.label`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const StyledRadioIcon = styled(RadioIcon)`
  inline-size: 100%;
  block-size: 100%;
  flex-shrink: 0;
  color: ${({ theme, checked }) => (checked ? theme.primaryColor : "#767676")};
  transition: color 0.15s ease-in-out;
`;

export const RadioButton = styled.input.attrs({ type: "radio" })`
  position: absolute;
  z-index: -1;
  opacity: 0;

  :disabled + ${LabelForRadio} {
    pointer-events: none;
    opacity: 0.5;
  }
`;
