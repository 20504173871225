import { MainSettings } from "./MainSettings";
import { SwapSettings, SwapSettingsProps } from "./SwapSettings";
import * as styles from "./style";

interface SettingsProps extends SwapSettingsProps {}

export const Settings = ({ party, abilityName }: SettingsProps) => (
  <styles.SettingsContainer>
    <MainSettings />
    <SwapSettings party={party} abilityName={abilityName} />
  </styles.SettingsContainer>
);
