import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { LiquidityTabContext } from "src/context/CEX/LiquidityTab/LiquidityTabProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import CreateGrid, { ICreateGrid } from "src/state/CEX/liquidityTab/CreateGrid";
import { LiquidityTabProps } from "..";
import { GridForm } from "../GridForm";
import InfoHeading from "../InfoHeading";
import { Panel } from "../base/Panel";
import { SubmitButtonGroup } from "../base/SubmitButtonGroup";
import * as styles from "./style";

interface CreateGridViewProps extends LiquidityTabProps {}

const GRID_FORM_ID = "GRID_FORM_CREATE";

const CreateGridView = observer(
  ({ abilityName, botInfo: { market = "", party } }: CreateGridViewProps) => {
    const liquidityTabState = useLateInitContext(LiquidityTabContext);
    const state: ICreateGrid = useLocalStore(CreateGrid, liquidityTabState);

    const name = market.split("_").join(" ");

    return (
      <>
        <InfoHeading title={name} />

        <styles.Content>
          <Panel title="Settings" style={{ maxWidth: "450px" }}>
            <GridForm state={state} id={GRID_FORM_ID} />
          </Panel>

          <SubmitButtonGroup abilityName={abilityName} party={party} formId={GRID_FORM_ID} />

          <Loader show={state.loading} />
        </styles.Content>
      </>
    );
  }
);

export default CreateGridView;
