import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import { DesktopHeader } from "./Header/DesktopHeader";
import { ExchangeSelectorWrapper } from "./Header/DesktopHeader/ExchangesSelector/style";
import { Container as PickerContainer } from "./Header/DesktopHeader/TimeRangePicker/style";
import { SelectorsRow } from "./Header/DesktopHeader/style";
import { DashboardBreakpoints } from "./shared/style";

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1300px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-grow: 1;
`;

export const DashboardHeader = styled(DesktopHeader)`
  @media ${getMobileMediaQuery(DashboardBreakpoints.Tablet)} {
    ${SelectorsRow} {
      flex-direction: column;
    }

    ${PickerContainer} {
      justify-content: start;
    }

    ${ExchangeSelectorWrapper} {
      flex-direction: row;
      max-width: 500px;
    }
  }
`;
