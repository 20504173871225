import { FieldPath, FieldValues } from "react-hook-form";
import { GroupBase } from "react-select";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { SelectorValue } from "src/modules/shared";
import {
  ComponentSelectorProps,
  ControllerWrapper,
  ControllerWrapperProps,
} from "./ControllerWrapper";

export interface ControlledSelectorProps<
  TForm extends FieldValues,
  TName extends FieldPath<TForm>,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> {
  controllerProps: Omit<
    ControllerWrapperProps<TForm, TName, Option, IsMulti, Group>,
    "component" | "componentProps"
  >;
  componentProps: ComponentSelectorProps<TForm, TName, Option, IsMulti, Group>;
}

export const ControlledSelector = <
  TForm extends FieldValues,
  TName extends FieldPath<TForm>,
  Option = SelectorValue,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  controllerProps,
  componentProps,
}: ControlledSelectorProps<TForm, TName, Option, IsMulti, Group>) => (
  <ControllerWrapper
    {...controllerProps}
    componentProps={componentProps}
    component={LabeledSelector}
  />
);
