import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";
import { Wrapper } from "../../shared";

interface TitleProps {
  highlight?: boolean;
}

export const Container = styled.div`
  position: relative;

  height: 0px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  overflow-y: auto;

  ${ScrollSize}
`;

export const HeaderWrapper = styled(Wrapper)`
  width: auto;

  justify-content: space-between;
`;

export const TitleText = styled.span<TitleProps>`
  font-size: ${({ theme }) => theme.fontSizes["14px"]};

  color: ${({ theme, highlight }) => (highlight ? theme.dangerColor : theme.textColor)};
`;

export const SubTitle = styled(TitleText)`
  color: ${({ theme, highlight }) => (highlight ? theme.dangerColor : theme.labelColor)};
`;
