import { Headline2 } from "src/components/shared/Typography";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import { Masonry } from "../Masonry";
import { AccentColorProps } from "../Member/style";

export const TeamWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 17px;
`;

export const CountSpan = styled.span`
  color: ${({ theme }) => theme.lightGray};
`;

export interface HeadSpanProps extends Partial<AccentColorProps> {}

export const HeadSpan = styled.span<HeadSpanProps>`
  ${({ accent }) => (accent ? `color: ${accent}` : "")};
`;

export const Name = styled(Headline2)`
  padding: 0 15px;
`;

export const MembersMasonry = styled(Masonry)`
  width: 100%;
  height: 100%;
  max-height: 500px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  padding: 12px 12px;

  @media ${getMobileMediaQuery("450px")} {
    grid-template-columns: repeat(auto-fill, minmax(140px, 100%));
  }
`;
