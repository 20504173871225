import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { TeamsContext } from "src/context/UserManager/Groups";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { TeamsList } from "./TeamsList";
import * as styles from "./style";

export const Teams = observer(() => {
  const state = useLateInitContext(TeamsContext);

  const isMobile = useMediaQuery(getMobileMediaQuery(styles.MOBILE_TEAMS_BREAKPOINT));

  useEffect(() => {
    state.setTeamSelectorActive(isMobile);
  }, [isMobile, state]);

  useEffect(() => {
    state.fetchTeams();
  }, [state]);

  return (
    <styles.Container>
      <styles.Title>Admin Teams</styles.Title>

      <styles.FiltersWrapper>
        {isMobile ? (
          <styles.TeamsSelector
            options={state.selectableTeams}
            value={state.selectedTeam}
            onChange={state.onTeamSelected}
            isDisabled={!state.selectableTeams}
          />
        ) : null}
        <styles.PartiesSearch value={state.partiesSearchText} onChange={state.setPartiesFilter} />
      </styles.FiltersWrapper>

      <TeamsList />
      <Loader show={state.isLoading} />
    </styles.Container>
  );
});
