import { UniqueNameText } from "src/components/BotsContent/CEX/ExpertSystem/Modules/Conditions/ConditionsTable/UniqueNameText";
import { CellText } from "src/components/BotsContent/CEX/ExpertSystem/shared/TableRows/style";
import styled from "styled-components";

export const InfoCell = styled(CellText)`
  height: fit-content;
  width: fit-content;

  gap: 8px;
`;

export const InfoRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const InfoColumn = styled(InfoRow)`
  flex-direction: column;
  align-items: flex-start;
`;

export const InfoLabel = styled.span`
  color: ${({ theme }) => theme.textColor};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const InfoUniqueName = styled(UniqueNameText)`
  height: auto;

  padding-left: 16px;
`;
