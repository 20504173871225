import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import { FormContent, SettingsContainer } from "../shared";

export enum CounterSettingsAreas {
  BlackList = "blacklist",
  Strategies = "strategies",
  Settings = "settings",
}

export const Container = styled(SettingsContainer)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "${CounterSettingsAreas.Strategies} ${CounterSettingsAreas.Strategies}"
    "${CounterSettingsAreas.Settings} ${CounterSettingsAreas.BlackList}";

  ${FormContent} {
    width: 100%;
  }

  @media ${getMobileMediaQuery("500px")} {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 300px repeat(2, auto);
    grid-template-areas:
      "${CounterSettingsAreas.Strategies}"
      "${CounterSettingsAreas.Settings}"
      "${CounterSettingsAreas.BlackList}";
  }
`;
