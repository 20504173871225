import { SwitchMenu } from "src/components/shared/SwitchMenu";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";
import { BaseButton } from "src/components/shared/Buttons/v2/shared/style";
import { Wrapper } from "../../../shared";

interface FullscreenProps {
  isFullscreen: boolean;
}

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;
`;

export const TimeWrapper = styled(Wrapper)`
  height: 100%;
`;

export const TimeContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 220px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;

  ${CardSurface}
`;

export const InfoText = styled.span`
  color: ${({ theme }) => theme.smallHeader};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;

export const StepsSwitching = styled(SwitchMenu)`
  max-width: 250px;
`;

export const FullscreenButton = styled(BaseButton).attrs({
  boxed: true,
})<FullscreenProps>`
  color: ${({ theme, isFullscreen }) => (isFullscreen ? theme.primaryColor : theme.textColor)};

  font-size: 28px;

  ${CardSurface}

  :active {
    box-shadow: none;
  }
`;
