import styled from "styled-components";

export const Prompt = styled.div`
  position: absolute;
  height: 24px;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 20.5px;
  right: 3px;
  color: ${({ theme }) => theme.inputTextColor};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  padding: 5px 10px;
  /* background: ${({ theme }) => theme.bgGrayBlack}; */
  background: rgba(0, 0, 0, 0.08);
  border-radius: ${({ theme }) => theme.radii["4px"]};

  & > svg {
    margin-left: 3px;
  }
`;

export const Text = styled.span``;
