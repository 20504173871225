import { GroupBase, OptionProps, components } from "react-select";
import { noOp } from "src/helpers/utils";
import * as styles from "./style";

export interface CheckboxInputOptionProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends OptionProps<Option, IsMulti, Group> {}

export const CheckboxInputOption = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  isSelected,
  isDisabled,
  children,
  label,
  ...props
}: CheckboxInputOptionProps<Option, IsMulti, Group>) => {
  const optionProps = {
    isSelected,
    isDisabled,
    label,
  };
  return (
    <components.Option {...props} {...optionProps}>
      <styles.CheckboxOption
        checked={isSelected}
        onChange={noOp}
        label={label}
        disabled={isDisabled}
        useHtmlFor={false}
      />
    </components.Option>
  );
};
