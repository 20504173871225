import { makeAutoObservable, observable } from "mobx";
import { computedFn } from "mobx-utils";
import { IdType, Row } from "react-table";
import { IDisposable } from "src/helpers/utils";

type TotalValue<V = any> = V;

export type ColumnsTotalMap<D extends object, V = any> = {
  [key in IdType<D>]: TotalValue<V> | undefined;
};

export type ColumnTotalCb<D extends object, T extends ColumnsTotalMap<D> = ColumnsTotalMap<D>> = (
  id: IdType<D>,
  row: D,
  prevTotal: T[IdType<D>] | undefined
) => T[IdType<D>];

export interface ITableTotalizerOptions<D extends object, T extends ColumnsTotalMap<D>> {
  columnTotalCb?: ColumnTotalCb<D, T>;
  initialTotals?: T;
}

export default class TableTotalizerStore<
  D extends object = {},
  T extends ColumnsTotalMap<D> = ColumnsTotalMap<D>,
> implements IDisposable
{
  private _rows: Row<D>[] = [];

  private _getColumnTotalCb?: ColumnTotalCb<D, T>;

  constructor({ columnTotalCb }: ITableTotalizerOptions<D, T> = {}) {
    makeAutoObservable<this, "_rows">(this, {
      columnTotal: false,
      _rows: observable.shallow,
    });
    this._getColumnTotalCb = columnTotalCb;
  }

  setRows = (rows: Row<D>[]) => {
    this._rows = rows;
  };

  private get _columnsTotal() {
    const totals = {} as T;

    const getColumnTotalCb = this._getColumnTotalCb;

    if (!getColumnTotalCb) {
      return totals;
    }

    this._rows.forEach((row) => {
      for (const key of Object.keys(row.values)) {
        const totalKey = key as IdType<D>;
        const currentTotal = totals[totalKey];

        const newTotal = getColumnTotalCb(totalKey, row.original, currentTotal);

        totals[totalKey] = newTotal;
      }
    });

    return totals;
  }

  columnTotal = computedFn(<K extends IdType<D>>(columnId: K): T[K] | undefined => {
    const total = this._columnsTotal[columnId];
    return total;
  });

  destroy = () => {};
}
