import { Abilities } from "src/modules/abilities";
import styled from "styled-components";

export interface ContentProps {
  abilities: Abilities;
  abilityName: string;
  party: string;
}

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1;

  padding: 0px 10px;
  gap: 30px;
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(3, 280px);
  flex: 1 1;

  gap: 10px;

  grid-template-areas:
    "PnL           funding-form"
    "funding-list  funding-list"
    "transfer-list transfer-list";

  @media screen and (max-width: 1100px) {
    grid-template-columns: 100%;

    grid-template-rows: auto repeat(3, 280px);

    grid-template-areas:
      "funding-form   "
      "PnL            "
      "funding-list   "
      "transfer-list  ";
  }
`;
