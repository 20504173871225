import { Component } from "react";

export default class DEXStatsIcons extends Component {
  static exclamation = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.218 0.500023L19.901 6.18202V14.218L14.218 19.901H6.18202L0.499023 14.218V6.18202L6.18202 0.499023L14.218 0.500023ZM9.00002 13V15H11V13H9.00002ZM9.00002 5.00002V11H11V5.00002H9.00002Z"
        fill="#166CE2"
      />
    </svg>
  );

  static copy = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 4.25V1.25C4.25 1.05109 4.32902 0.860322 4.46967 0.71967C4.61032 0.579018 4.80109 0.5 5 0.5H14.75C14.9489 0.5 15.1397 0.579018 15.2803 0.71967C15.421 0.860322 15.5 1.05109 15.5 1.25V11C15.5 11.1989 15.421 11.3897 15.2803 11.5303C15.1397 11.671 14.9489 11.75 14.75 11.75H11.75V14.7448C11.75 15.1617 11.4133 15.5 10.9948 15.5H1.25525C1.15604 15.5001 1.05779 15.4806 0.966112 15.4427C0.874436 15.4048 0.791139 15.3492 0.720988 15.279C0.650837 15.2089 0.595209 15.1256 0.557289 15.0339C0.519369 14.9422 0.499902 14.844 0.5 14.7448L0.50225 5.00525C0.50225 4.58825 0.839 4.25 1.2575 4.25H4.25ZM5.75 4.25H10.9948C11.4118 4.25 11.75 4.58675 11.75 5.00525V10.25H14V2H5.75V4.25ZM2.00225 5.75L2 14H10.25V5.75H2.00225Z"
        //   fill="#767676"
      />
    </svg>
  );

  static hash = () => (
    <svg
      width="16"
      height="19"
      viewBox="0 0 18 21"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3.055C14.5 3.552 18 7.367 18 12V16.5V21H0V12C0 7.367 3.5 3.552 8 3.055V0H10V3.055ZM16 19V12C16 10.1435 15.2625 8.36301 13.9497 7.05025C12.637 5.7375 10.8565 5 9 5C7.14348 5 5.36301 5.7375 4.05025 7.05025C2.7375 8.36301 2 10.1435 2 12V19H16ZM9 17C7.67392 17 6.40215 16.4732 5.46447 15.5355C4.52678 14.5979 4 13.3261 4 12C4 10.6739 4.52678 9.40215 5.46447 8.46447C6.40215 7.52678 7.67392 7 9 7C10.3261 7 11.5979 7.52678 12.5355 8.46447C13.4732 9.40215 14 10.6739 14 12C14 13.3261 13.4732 14.5979 12.5355 15.5355C11.5979 16.4732 10.3261 17 9 17ZM9 15C9.79565 15 10.5587 14.6839 11.1213 14.1213C11.6839 13.5587 12 12.7956 12 12C12 11.2044 11.6839 10.4413 11.1213 9.87868C10.5587 9.31607 9.79565 9 9 9C8.20435 9 7.44129 9.31607 6.87868 9.87868C6.31607 10.4413 6 11.2044 6 12C6 12.7956 6.31607 13.5587 6.87868 14.1213C7.44129 14.6839 8.20435 15 9 15ZM9 13C8.73478 13 8.48043 12.8946 8.29289 12.7071C8.10536 12.5196 8 12.2652 8 12C8 11.7348 8.10536 11.4804 8.29289 11.2929C8.48043 11.1054 8.73478 11 9 11C9.26522 11 9.51957 11.1054 9.70711 11.2929C9.89464 11.4804 10 11.7348 10 12C10 12.2652 9.89464 12.5196 9.70711 12.7071C9.51957 12.8946 9.26522 13 9 13Z"
        // fill="#1A1A1A"
      />
    </svg>
  );
}
