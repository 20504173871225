import { useMemo } from "react";
import { SelectorValue } from "src/modules/shared";
import { DepthPercentValue } from "src/state/MarketCheck/types";
import * as styles from "./style";

interface DepthSelectorProps {
  depth: DepthPercentValue;
  setDepth: (depth: DepthPercentValue) => void;
}

interface DepthSelectorValue extends SelectorValue {
  value: DepthPercentValue;
}

const DEPTH_OPTIONS: DepthSelectorValue[] = [
  { label: "2%", value: "2" },
  { label: "5%", value: "5" },
  { label: "10%", value: "10" },
  { label: "20%", value: "20" },
] as const;

export const DepthSelector = ({ depth, setDepth }: DepthSelectorProps) => {
  const selectorValue = useMemo(
    () => DEPTH_OPTIONS.find((option) => option.value === depth),
    [depth]
  );

  return (
    <styles.StyledDepthSelector
      value={selectorValue}
      options={DEPTH_OPTIONS}
      onChange={(data) => setDepth((data as DepthSelectorValue).value)}
    />
  );
};
