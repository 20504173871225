import { StrategyModulesHardParams } from "src/modules/expertSystem";
import { LabelValue } from "../../LabelValue";
import * as styles from "./style";

export interface HardParamsViewProps {
  name: string;
  hardParams: StrategyModulesHardParams;
}

export const HardParamsView = ({ name, hardParams }: HardParamsViewProps) => (
  <styles.Container>
    <LabelValue label="Name" value={name} />

    {(Object.keys(hardParams) as Array<keyof typeof hardParams>).map((key) => (
      <LabelValue key={key} label={key} value={hardParams[key]} />
    ))}
  </styles.Container>
);
