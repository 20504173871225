import { isAxiosError } from "axios";
import { useCallback, useState } from "react";
import { toast } from "src/components/shared/Toaster";
import {
  CreateSpreadsheetOptions,
  SheetsState,
  createSpreadsheet,
} from "src/helpers/google/sheets";
import { LoadState } from "src/helpers/network/state";
import { useAppState } from "src/state";

interface UseExportSpreadsheetParams {
  onError?: (error: unknown) => void;
  onSuccess?: (state: SheetsState) => void;
}

export const useExportSpreadsheet = <T extends object>({
  onError,
  onSuccess,
}: UseExportSpreadsheetParams) => {
  const { googleAuthState: authState } = useAppState();

  const [loadState, setLoadState] = useState<LoadState>(LoadState.Init);

  const [sheetsState, setSheetsState] = useState<SheetsState | null>(null);

  const exportSpreadsheet = useCallback(
    async (createOptions: CreateSpreadsheetOptions<T>) => {
      try {
        setLoadState(LoadState.Loading);

        const sheetsState = await createSpreadsheet(createOptions);

        setLoadState(LoadState.Success);

        setSheetsState(sheetsState);

        if (onSuccess) {
          onSuccess(sheetsState);
        } else {
          toast.success("Spreadsheet created!");
        }
      } catch (error: unknown) {
        if (isAxiosError(error) && error.response?.status === 401) {
          toast.error("Your session has expired. Please login again.");
          authState.clearAccessToken();
        } else {
          onError?.(error);
        }
        setLoadState(LoadState.Error);
        setSheetsState(null);
      }
    },
    [authState, onError, onSuccess]
  );

  return { exportSpreadsheet, loadState, sheetsState };
};
