import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { DEXV2Vault, DEXV2VaultType } from "src/api/bots/DEXV2/create";
import { ModalTransferParams } from "src/components/BotsContent/DEX_V2/Stats/Tables/Vaults/ModalTransfer";
import { arrayToMapObj } from "src/helpers/array";
import { setTextClipboard } from "src/helpers/clipboard";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import {
  IBotChainProvider,
  IBotInfoProvider,
  IBotTradePairProvider,
} from "../../DEXV2Bots/DEXV2BotStore";
import { INITIAL_PAIR_TICKERS, INITIAL_VAULT } from "../../DEXV2Stats/Vaults";
import VaultsProviderStore, { IVaultsProvider } from "../../DEXV2Stats/Vaults/VaultsProvider";
import { ITradePairPriceProvider } from "../../Providers/TradePairUSDPriceProvider";
import WithdrawerProvider, { IWithdrawerProvider } from "../../shared/WithdrawerProvider";

export interface ISwapVaultsBalancesProvider {
  refreshBalances: () => Promise<void>;
  refreshChainBalances: () => Promise<void>;
}

type DEXV2SwapVaultType = Exclude<DEXV2VaultType, "main">;

const DEXV2_SWAP_VAULTS: DEXV2SwapVaultType[] = ["limit", "volume", "counter"];

export interface ISwapVaultProvider {
  vault: DEXV2Vault;
}

export interface ISwapVaultsParams {
  botChainProvider: IBotChainProvider;
  tradePairProvider: IBotTradePairProvider;
  botInfoProvider: IBotInfoProvider;
  tradePairPriceProvider: ITradePairPriceProvider;
}

export class SwapVaultsStore
  implements IDisposable, ISwapVaultsBalancesProvider, ISwapVaultProvider
{
  private _selectedVaultAddress = "";

  private _currentFromVaultAddress = "";

  private _loading = false;

  private _botUUID = "";

  private _vaultsProvider: IVaultsProvider;

  private _newVaultsReaction: IReactionDisposer;

  private _tradePairProvider: IBotTradePairProvider;

  private _withdrawerProvider: IWithdrawerProvider;

  private _tradePairPriceProvider: ITradePairPriceProvider;

  constructor({
    botChainProvider,
    tradePairProvider,
    botInfoProvider,
    tradePairPriceProvider,
  }: ISwapVaultsParams) {
    makeAutoObservable(this, { isSelectedVault: false });

    this._tradePairProvider = tradePairProvider;

    this._withdrawerProvider = new WithdrawerProvider(this);

    this._tradePairPriceProvider = tradePairPriceProvider;

    this._vaultsProvider = new VaultsProviderStore({
      chainProvider: botChainProvider,
      botUUIDProvider: botInfoProvider,
      addressesProvider: botInfoProvider,
      priceProvider: {
        tradePairPriceProvider: this._tradePairPriceProvider,
        tradePairProvider: this._tradePairProvider,
      },
    });

    this._newVaultsReaction = reaction(
      () => this.vaults.length && this.vaults[0].address,
      () => {
        this._setInitialSelectedVault(this.vaults);
      }
    );

    makeLoggable(this, {
      vaults: true,
      roundedVaults: true,
      selectedVault: true,
      vault: true,
      transferParams: true,
    });
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  get vaults() {
    return this._vaultsProvider.vaults;
  }

  get roundedVaults() {
    return this._vaultsProvider.roundedVaults;
  }

  get roundedTotalVaults() {
    return this._vaultsProvider.roundedTotalVaults;
  }

  get vaultsDeployed() {
    return this._vaultsProvider.vaultsDeployed;
  }

  private _setInitialSelectedVault = (vaults: DEXV2Vault[]) => {
    const address = vaults.find((vault) => !this.isVaultDisabled(vault) && vault.type === "volume")
      ?.address;

    if (address) {
      this.setSelectedVaultAddress(address);
    }
  };

  isVaultDisabled = ({ type }: DEXV2Vault) =>
    !DEXV2_SWAP_VAULTS.includes(type as DEXV2SwapVaultType);

  private get _addressVaultMap() {
    return arrayToMapObj(this.vaults, "address");
  }

  get tradePair() {
    return this._tradePairProvider.tradePair;
  }

  get tickers() {
    return this._vaultsProvider.tickers ?? INITIAL_PAIR_TICKERS;
  }

  get withdrawer() {
    return this._withdrawerProvider.withdrawer ?? "";
  }

  private _getWithdrawer = async () => {
    await this._withdrawerProvider.getWithdrawer();
  };

  setSelectedVaultAddress = (address: string) => {
    this._selectedVaultAddress = address;
  };

  get selectedVault(): DEXV2Vault {
    return this._addressVaultMap[this._selectedVaultAddress] ?? INITIAL_VAULT;
  }

  setCurrentFromVaultAddress = (address: string) => {
    this._currentFromVaultAddress = address;
  };

  get currentFromVault(): DEXV2Vault {
    return this._addressVaultMap[this._currentFromVaultAddress] ?? INITIAL_VAULT;
  }

  get vault() {
    return this.selectedVault;
  }

  isSelectedVault = (address: string) => this.selectedVault.address === address;

  get vaultsQuotes() {
    return this._vaultsProvider.vaultsQuotes;
  }

  get isEmptyQuotes() {
    return this._vaultsProvider.isEmptyQuotes;
  }

  get transferParams(): ModalTransferParams | null {
    const fromVault = this.currentFromVault;
    const deployerId = this.botUUID;
    const { tradePair } = this;
    const { withdrawer } = this;
    if (!deployerId || !tradePair || !withdrawer) return null;
    return {
      fromVault,
      deployerId,
      tradePair,
      withdrawer,
    };
  }

  get modalDepsReady() {
    return Boolean(this.transferParams);
  }

  copyAllAddresses = () => {
    const addresses: string[] = [];
    this.vaults.forEach((el) => {
      addresses.push(el.address);
    });

    setTextClipboard(addresses.join("\n"));
  };

  getVaults = async () => {
    this._setLoading(true);

    try {
      await Promise.all([this._vaultsProvider.getVaults(), this._getWithdrawer()]);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  refreshBalances = async () => {
    await this._vaultsProvider.refreshBalances(this._setLoading);
  };

  refreshChainBalances = async () => {
    await this._vaultsProvider.refreshChainBalances(this._setLoading);
  };

  destroy = () => {
    this._vaultsProvider.destroy();

    this._newVaultsReaction();
  };
}
