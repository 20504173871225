import { useCallback } from "react";
import { AccessForm } from "../../shared";
import * as styles from "./style";

export interface SaveFormProps extends Omit<React.ComponentPropsWithoutRef<"form">, "onKeyPress"> {
  saved?: boolean;
}

export const SaveForm = ({ saved = false, children, ...props }: SaveFormProps) => {
  const onEnterPrevent = useCallback(
    (event: React.KeyboardEvent<HTMLFormElement>) =>
      event.key === "Enter" && event.preventDefault(),
    []
  );
  return (
    <AccessForm onKeyPress={onEnterPrevent} {...props}>
      {children}
      <styles.SaveButton saved={saved} />
    </AccessForm>
  );
};
