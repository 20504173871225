import { makeAutoObservable } from "mobx";
import { IERC20__factory } from "src/contracts/factories/IERC20__factory";
import { makeLoggable } from "src/helpers/logger";
import { chainErrorHandler } from "src/helpers/network/chain";
import { ConstraintsErrorsReport } from "src/state/CEX/CEXSettings/ConstraintsStore";
import { IChainProvider } from "src/state/chain/ChainProviderStore";

export interface ITokenAddressProvider {
  address: string;
}

interface ITokenTickerValidationParams {
  chainProvider: IChainProvider;
  addressProvider: ITokenAddressProvider;
  tokenType?: string;
}

export interface ITokenTickerValidation {
  get ticker(): string;
  get warning(): string | undefined;
  validateTicker: () => Promise<boolean>;
  get tokenValidationReport(): ConstraintsErrorsReport;
}

export const getTokenValidationReportText = (
  ticker: string,
  warning?: string
): ConstraintsErrorsReport => {
  const title = `Failed to get ${ticker} contract ticker:`;
  const message = "Please make sure the network or contract address is correct.";

  return { title, message: warning ?? message };
};

export default class TokenTickerValidationStore implements ITokenTickerValidation {
  private _ticker: string = "";

  private _warning: string | undefined = undefined;

  private _chainProvider: IChainProvider;

  private _addressProvider: ITokenAddressProvider;

  private _tokenType: string;

  constructor({ chainProvider, addressProvider, tokenType }: ITokenTickerValidationParams) {
    makeAutoObservable(this);

    this._chainProvider = chainProvider;

    this._addressProvider = addressProvider;

    this._tokenType = tokenType ?? "token";

    makeLoggable<any>(this, { _address: true, warning: true });
  }

  private get _provider() {
    return this._chainProvider.multicallProvider;
  }

  private get _address() {
    return this._addressProvider.address;
  }

  private _setWarning = (warning: string) => {
    this._warning = warning;
  };

  get warning() {
    return this._warning;
  }

  private _setTicker = (ticker: string) => {
    this._ticker = ticker;
  };

  get ticker() {
    return this._ticker;
  }

  private _getTokenSymbol = async (address: string): Promise<string> => {
    if (!this._provider) {
      return "";
    }

    const tokenContract = IERC20__factory.connect(address, this._provider);

    try {
      const symbol = await tokenContract.symbol();

      return symbol;
    } catch (err) {
      chainErrorHandler(err);
      return "";
    }
  };

  private _validateTicker = (ticker: string) => {
    const tokenType = this._tokenType;

    const warning = !ticker ? `Failed to get ${tokenType} ticker` : "";
    this._setWarning(warning);

    return !warning;
  };

  private _getTokenTicker = async () => {
    const address = this._address;

    const ticker = await this._getTokenSymbol(address);

    return ticker;
  };

  validateTicker = async () => {
    const ticker = await this._getTokenTicker();
    this._setTicker(ticker);

    const isValid = this._validateTicker(ticker);

    return isValid;
  };

  get tokenValidationReport(): ConstraintsErrorsReport {
    if (!this._warning) {
      return { title: "", message: "" };
    }

    return getTokenValidationReportText(this._tokenType);
  }
}
