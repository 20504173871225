import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { AccountBalance } from "src/state/CEX/CEXDashboard/v2/widgets/AccountBalancesStore";
import { AccountBalanceList } from "../../AccountBalanceList";
import * as styles from "./style";

export interface BalancesTooltipContentProps extends ComponentPropsWithoutRef<"div"> {
  currency: string;
  balances: AccountBalance;
}

export const BalancesTooltipContent = observer(
  ({ currency, balances, ...props }: BalancesTooltipContentProps) => (
    <styles.Container {...props}>
      <styles.Title>Price in {currency}</styles.Title>
      <AccountBalanceList balances={balances} currency={currency} />
    </styles.Container>
  )
);
