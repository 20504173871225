import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { ChooseSettingParamsContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import {
  ChangeableCEXSettings,
  SettingsParamsPaths,
} from "src/state/expertSystem/strategies/changeSettingsModule/shared/types";
import { getDefaultParam } from "src/state/expertSystem/strategies/changeSettingsModule/shared/utils";
import { settingsValidationSchema } from "src/state/expertSystem/strategies/changeSettingsModule/shared/validation";
import { FieldIndicator } from "../../../../../shared/ChangeSettingsModule/FieldIndicator";
import { SwitchController } from "../../../../../shared/ChangeSettingsModule/FormFields/SwitchController";
import { useSetFormSetter } from "../../../../../shared/ChangeSettingsModule/FormFields/hooks/useSetFormSetter";
import { useYupValidationResolver } from "../../../../../shared/ChangeSettingsModule/FormFields/hooks/useYupValidationResolver";
import { RowsContainer } from "../../../../../shared/ChangeSettingsModule/FormFields/style";
import { LabelValue } from "../../../../../shared/LabelValue";
import { SubModuleShell } from "../../../../../shared/SubModuleShell";
import { getControlConfig } from "../../../../../shared/utils/changeSettings";

export interface FormRowsProps {
  generateSettings: (data: Partial<ChangeableCEXSettings>) => void;
  defaultConfig: Partial<ChangeableCEXSettings>;
  getParamStatus: (param: SettingsParamsPaths) => boolean;
  removeConfigParam: (param: SettingsParamsPaths) => void;
}

export const FormRows = observer(
  ({ generateSettings, defaultConfig, getParamStatus, removeConfigParam }: FormRowsProps) => {
    const state = useLateInitContext(ChooseSettingParamsContext);

    const { control, unregister, setValue, handleSubmit } = useForm<Partial<ChangeableCEXSettings>>(
      {
        defaultValues: defaultConfig,
        resolver: useYupValidationResolver(settingsValidationSchema),
      }
    );

    const setCb = useCallback(
      (param: SettingsParamsPaths) => setValue(param, getDefaultParam(param)),
      [setValue]
    );

    useSetFormSetter(setCb, state);

    const handleForm = handleSubmit(generateSettings);

    return (
      <>
        <RowsContainer
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleForm();
            }
          }}
        >
          {state.settingsParams.map((param) => (
            <SubModuleShell
              key={param}
              headerProps={{
                remove: state.removeSettingParamHandler(param, [
                  (param: SettingsParamsPaths) => {
                    unregister(param);
                    removeConfigParam(param);
                  },
                ]),
              }}
            >
              <SwitchController
                config={getControlConfig(param)}
                control={control}
                name={param}
                param={param}
              />

              <FieldIndicator isAdded={getParamStatus(param)} />

              <LabelValue label="Current value" value={state.getCurrentSettingsParam(param)} />
            </SubModuleShell>
          ))}
        </RowsContainer>

        <OutlineButton disabled={state.isAddedParams} size="m" onClick={handleForm}>
          Confirm
        </OutlineButton>
      </>
    );
  }
);
