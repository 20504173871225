import { Prompt } from "src/components/shared/Prompts/Prompt";
import styled from "styled-components";

export const FormControlsRow = styled.div`
  display: flex;
  gap: 17px;
  > * {
    min-width: 0;
  }
`;

export const FormControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Suggestion = styled(Prompt)`
  top: revert;
`;
