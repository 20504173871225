import { makeAutoObservable } from "mobx";
import { toast } from "src/components/shared/Toaster";
import ExchangeStore from "..";
import { TerminalRequestMode } from "../shared/TerminalSettingsStore";
import StreamingStore from "./StreamingStore";

class SettingsProvider {
  mainState: ExchangeStore;

  streamingState: StreamingStore;

  constructor(state: ExchangeStore) {
    this.mainState = state;

    this.streamingState = new StreamingStore(this);

    this.mainState.setUpdHandlers("updChart", this.streamingState.updData);

    makeAutoObservable(this);
  }

  get party() {
    return this.mainState.party;
  }

  get botUUID() {
    return this.mainState.botUUID;
  }

  get pair() {
    return this.mainState.pair;
  }

  get exchange() {
    return this.mainState.exchange;
  }

  get terminalSettings() {
    return this.mainState.terminalSettingsState;
  }

  get settingsConfig() {
    return this.terminalSettings.settingsConfig.tvChartModule;
  }

  get requestMode() {
    return this.settingsConfig.requestMode;
  }

  get exchStatusConnection() {
    return this.streamingState.webSocketState.exchStatusConnection;
  }

  get exchangeDelay() {
    return this.streamingState.webSocketState.exchangeDelayTitle;
  }

  get infoAcc() {
    return this.mainState.infoAcc;
  }

  setStreamMode = (mode: TerminalRequestMode) => {
    if (mode === "WS" && this.streamingState.lockedConnect) {
      toast.error("WS connection already open");
      return;
    }

    this.terminalSettings.toggleRequestMode("tvChartModule", mode);
  };
}

export default SettingsProvider;
