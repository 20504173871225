import { observer } from "mobx-react-lite";
import { ConstraintsInput } from "src/components/BotsContent/CEX/CEXBotSettings/shared";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SettingsPanel, SettingsPanelProps } from "../../shared/SettingsPanel";
import { FormContent, FormRow } from "../../shared/style";

export interface MainPanelProps extends Omit<SettingsPanelProps, "label" | "children"> {}

export const MainPanel = observer(({ ...props }: MainPanelProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  return (
    <SettingsPanel label="Main Settings" {...props}>
      <FormContent>
        <FormRow>
          <LabeledInput label="Bot name" {...state.getInputProps("bot_name")} />
          <LabeledInput
            label="Gas limit"
            type="number"
            step="any"
            {...state.getInputProps("base_data.gas_limit")}
          />
        </FormRow>

        <ConstraintsInput
          label="Stable"
          {...state.getInputProps("base_data.stable")}
          {...state.getFieldErrorAsProps("base_data.stable")}
        />
        <LabeledPercentRangeInput
          step="0.1"
          label="Token Transaction Buy Fee"
          {...state.getInputProps("base_data.tt_buy_fee")}
        />
        <LabeledPercentRangeInput
          step="0.1"
          label="Token Transaction Sell Fee"
          {...state.getInputProps("base_data.tt_sell_fee")}
        />
      </FormContent>
    </SettingsPanel>
  );
});
