import { observer } from "mobx-react-lite";
import React, { ComponentPropsWithoutRef, useEffect } from "react";
import Icons from "src/icons/Icons";
import WindowConsent from "src/state/WindowConsent";
import { IconButton } from "../Buttons/v2/IconButton";
import { Loader } from "../Loader";
import * as styles from "./style";

export type CloseModalCb =
  | React.Dispatch<React.SetStateAction<boolean>>
  | ((bool: boolean) => void);

export interface ModalProps {
  show: boolean;
  close: (bool: boolean) => void;
}

export interface ModalPanelProps
  extends ComponentPropsWithoutRef<"div">,
    ModalProps,
    styles.ModalContainerProps {
  label: string;
  children: JSX.Element | JSX.Element[];
  afterLabel?: JSX.Element;
  style?: React.CSSProperties;
  loading?: boolean;
}

export const ModalPanel = observer(
  ({ label, children, show, close, loading, afterLabel, zIndex, ...props }: ModalPanelProps) => {
    useEffect(() => {
      const closeModal = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          if (!WindowConsent.hide) {
            WindowConsent.hideWindow();
            e.stopImmediatePropagation();
          } else {
            close(false);
          }
        }
      };

      document.addEventListener("keydown", closeModal);

      return () => {
        document.removeEventListener("keydown", closeModal);
      };
    });

    return (
      <>
        {show ? (
          <styles.Container zIndex={zIndex}>
            <styles.Panel {...props}>
              <styles.CloseWrapper>
                <IconButton onClick={() => close(false)} size="l" boxed>
                  {Icons.cross()}
                </IconButton>
              </styles.CloseWrapper>

              <styles.WrapperTitle>
                <styles.Title>{label}</styles.Title>
                {afterLabel}
              </styles.WrapperTitle>

              <styles.Content>{children}</styles.Content>

              <Loader show={loading} />
            </styles.Panel>
          </styles.Container>
        ) : null}
      </>
    );
  }
);
