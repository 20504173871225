import styled, { createGlobalStyle } from "styled-components";
import { CSS_VARS } from "src/helpers/cssVariables";
import { CrashPanel } from "../components/shared/ErrorBoundary/CrashPanel";

export const AppContent = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.mainBackgroundColor};

  display: flex;
  flex-direction: column;
`;

export const PageContent = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  padding-top: ${({ theme }) => theme.dimensions.headerHeight};
`;

export const RouterContainer = styled.div`
  width: calc(100% - var(${CSS_VARS.SIDE_MENU_SPACE}));
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageFallback = styled(CrashPanel)`
  margin: 30px;
  width: calc(100% - 30px);
`;

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Roboto", "San Francisco Pro", 'Inter';

    /* Custom scrollbar for Firefox */
    scrollbar-color: rgba(180, 180, 180, 0.5) transparent;
    scrollbar-width: thin;

    box-sizing: border-box;

    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  html, body {
    background-color: ${({ theme }) => theme.mainBackgroundColor};
    -webkit-font-smoothing: antialiased;
  }

  body {
    overflow-y: scroll;
  }

  .react-tooltip {
    overflow: visible;

    z-index: 1000;
  }

  :root {
    ${CSS_VARS.SIDE_MENU_SPACE}: 0px;
  }

  /* Custom scrollbar for Chrome/Edge/Safari */
  :not(textarea)::-webkit-scrollbar {
    width: .5em;
    height: .5em;
  }
   
  :not(textarea)::-webkit-scrollbar-track {
    background-color: inherit;
  }
   
  :not(textarea)::-webkit-scrollbar-thumb {
    background-color: rgba(180, 180, 180, 0.5);
    border-radius: 0.2em;
  }

  :not(textarea)::-webkit-scrollbar-thumb:hover {
    background-color: rgba(180, 180, 180, 1);
  }
`;
