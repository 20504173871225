import { makeAutoObservable } from "mobx";

export type RefreshType = "force" | "normal";

export type RefreshState = {
  loading: boolean;
  type: RefreshType;
};

const INITIAL_REFRESH_STATE: RefreshState = {
  loading: false,
  type: "normal",
};

export class RefreshStateStore {
  private _refreshing: RefreshState = INITIAL_REFRESH_STATE;

  constructor() {
    makeAutoObservable(this);
  }

  setRefreshing = (loading: boolean, type: RefreshType) => {
    this._refreshing = { loading, type };
  };

  get refreshing() {
    return this._refreshing;
  }

  private _refreshingByType = (type: RefreshType) => {
    const { type: currentType, loading } = this._refreshing;
    if (type === currentType) return loading;
    return false;
  };

  get isRefreshing() {
    return this._refreshingByType("normal");
  }

  get isForceRefreshing() {
    return this._refreshingByType("force");
  }
}
