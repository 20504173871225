import { observer } from "mobx-react-lite";
import { BaseModuleFormProps } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ModuleSettings } from "../../ModuleSettings";
import { AbsModules } from "./AbsModules";
import { BalanceAbs } from "./BalanceAbs";
import { BalanceChangeModules } from "./BalanceChangeModules";
import { BotStatus } from "./BotStatus";
import { ChangeModules } from "./ChangeModules";
import { DepthAbs } from "./DepthAbs";
import { MultigridStatus } from "./MultigridStatus";
import { SettingsValue } from "./SettingsValue";
import { TimeTrigger } from "./TimeTrigger";

export interface ConditionModuleForm extends BaseModuleFormProps {}

export const ModulesForms = observer(() => {
  const state = useLateInitContext(CreateModulesContext);
  return (
    <ModuleSettings currentKey={state.currentCondition}>
      <AbsModules key="abs_modules" />
      <BalanceAbs key="balance_abs" />
      <DepthAbs key="depth_abs" />
      <BotStatus key="bot_status" />
      <BalanceChangeModules key="balance_change_modules" />
      <ChangeModules key="timeframe_modules" />
      <TimeTrigger key="time" />
      {/* <PriceExchangePnL key="price_exchange_pnl" />
      <PriceAccountPnL key="price_account_pnl" /> */}
      <SettingsValue key="settings_value" createCb={state.createConditionHandler} />
      <MultigridStatus key="multigrinder_status" />
    </ModuleSettings>
  );
});
