import { deepmerge } from "@mui/utils";
import { ColorType } from "lightweight-charts";
import { useMemo } from "react";
import { useTheme } from "styled-components";
import { GraphRootProps } from "..";
import { priceScaleOptionsLibDefaults } from "../constants";
import { OptionalChartOptions, SeriesPriceScaleId } from "../types";
import { useLinePrimaryColor } from "./useLinePrimaryColor";

interface UseScaleColorParams extends Pick<UseDefaultChartThemeOptionsParams, "series"> {
  side: SeriesPriceScaleId;
}

/**
 * Custom hook that returns the scale color based on the graph series props
 * @returns The scale color.
 */
const useScaleColor = ({ side, series }: UseScaleColorParams) => {
  const sideColor = useMemo(() => {
    const sideSeries = series?.find((series) => series.side === side);
    if (!sideSeries) return undefined;
    if (sideSeries.type === "Area" || sideSeries.type === "Line")
      return sideSeries.color ?? "primaryColor";
  }, [series, side]);

  const scaleColor = useLinePrimaryColor(sideColor);

  return scaleColor;
};

export interface UseDefaultChartThemeOptionsParams
  extends Pick<GraphRootProps, "options" | "series" | "autoColorScales"> {}

export const useDefaultChartThemeOptions = ({
  options: outerOptions,
  series,
  autoColorScales,
}: UseDefaultChartThemeOptionsParams) => {
  const theme = useTheme();

  const leftScaleColor = useScaleColor({ side: "left", series });
  const rightScaleColor = useScaleColor({ side: "right", series });

  const axisColors = useMemo((): OptionalChartOptions => {
    if (!autoColorScales)
      // restore the defaults if turned off
      return {
        leftPriceScale: {
          borderColor: priceScaleOptionsLibDefaults.borderColor,
        },
        rightPriceScale: {
          borderColor: priceScaleOptionsLibDefaults.borderColor,
        },
      };

    return {
      leftPriceScale: {
        borderColor: leftScaleColor,
      },
      rightPriceScale: {
        borderColor: rightScaleColor,
      },
    };
  }, [autoColorScales, leftScaleColor, rightScaleColor]);

  const defaultOptions: OptionalChartOptions = useMemo(
    () => ({
      layout: {
        background: {
          type: ColorType.Solid,
          color: theme.contentBackgroundColor,
        },
        textColor: theme.textColor,
      },
      grid: {
        horzLines: {
          color: theme.tradingViewGridColor,
        },
        vertLines: {
          color: theme.tradingViewGridColor,
        },
      },
      ...axisColors,
    }),
    [theme, axisColors]
  );

  const options = useMemo(
    () => deepmerge(defaultOptions, outerOptions),
    [defaultOptions, outerOptions]
  );

  return options;
};
