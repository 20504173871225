import { observer } from "mobx-react-lite";
import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { SuggestionLabeledInput } from "src/components/shared/Forms/Variants";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "../../shared";
import { Suggestion } from "./style";

interface Props {
  showLoader: boolean;
  party: string;
}

const DECIMAL_INFO = `Decimal:
Amount — decimals for Amount on selected exchange on selected pair

Amount — decimals for Price on selected exchange on selected pair`;

export const Decimals = observer(({ party, showLoader }: Props) => {
  const state = useLateInitContext(CEXSettingsContext);

  return (
    <SettingsPanel
      label="Decimals"
      party={party}
      loader={showLoader}
      submitHandler={state.submitModulHandler("decimals")}
      info={DECIMAL_INFO}
      saved={state.savedModuls.decimals}
      style={{ gridArea: "decimals" }}
    >
      <styles.Wrapper>
        <SuggestionLabeledInput
          type="number"
          step="any"
          label="Amount decimals"
          value={state.data.settings.decimals.amount}
          onChange={state.getHandler("settings.decimals.amount")}
          errorHint={state.errors.settings?.decimals?.amount}
          getSuggestion={(getRef) =>
            state.isActiveSuggestion("decimals.amount") ? (
              <Suggestion
                value={state.suggestions.decimals.amount}
                setValue={state.applySuggestion("decimals.amount")}
                ownRef={getRef}
              />
            ) : null
          }
          suggestionGap={8}
          // @ts-ignore
          getError={state.getError("settings.decimals.amount")}
        />
        <SuggestionLabeledInput
          type="number"
          step="any"
          label="Price decimals"
          value={state.data.settings.decimals.price}
          onChange={state.getHandler("settings.decimals.price")}
          errorHint={state.errors.settings?.decimals?.price}
          getSuggestion={(getRef) =>
            state.isActiveSuggestion("decimals.price") ? (
              <Suggestion
                value={state.suggestions.decimals.price}
                setValue={state.applySuggestion("decimals.price")}
                ownRef={getRef}
              />
            ) : null
          }
          suggestionGap={8}
          // @ts-ignore
          getError={state.getError("settings.decimals.price")}
        />
      </styles.Wrapper>
    </SettingsPanel>
  );
});
