import { StatusPoint, StatusPointProps } from "src/components/shared/GridTable";
import styled from "styled-components";

export const AddressChip = styled(StatusPoint).attrs<{}, StatusPointProps>({
  type: "success",
})`
  color: ${({ theme }) => theme.primaryColor};
  background: none;
`;

export const WrongNetworkChip = styled(StatusPoint).attrs<{}, StatusPointProps>({
  type: "danger",
})``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;
