import { IconButton, IconButtonProps } from "src/components/shared/Buttons/v2/IconButton";
import Icons from "src/icons/Icons";

export interface DragIconProps extends IconButtonProps {}

export const DragIcon = (props: DragIconProps) => (
  <IconButton boxed {...props}>
    {Icons.drag()}
  </IconButton>
);
