import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  border-top: ${({ theme }) => theme.borders.solidGray};
`;

export const WrapperForButton = styled.div`
  display: flex;
  & > button {
    margin-left: 10px;
  }
  @media (max-width: 600px) {
    & > button {
      margin-left: 0px;
    }
    justify-content: space-between;
    padding-top: 20px;
  }
`;
