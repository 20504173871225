import { Percent } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { DEXV2ExchangeVersion } from "src/modules/bots";
import { getPriceImpactWarning, invertPriceImpact } from "src/state/DEXV2/DEXV2Swap/utils";
import { INonNullableVersionProvider, VersionedSwapState } from "../../shared/VersionedSwapState";
import { ISwapInfoRoute } from "../SwapInfoRouteStore";
import { ISwapInfoTradePair } from "../SwapInfoTradePairStore";
import { SwapInfoImpactV2Store } from "./SwapInfoImpactV2Store";
import { IBaseSwapInfoImpact, SwapInfoImpactV3Store } from "./SwapInfoImpactV3Store";

export interface PriceImpact {
  percent: Percent;
  warning?: boolean;
}

interface ISwapInfoImpactParams {
  pairInfo: ISwapInfoTradePair;
  routeInfo: ISwapInfoRoute;
  versionProvider: INonNullableVersionProvider;
}

export interface ISwapInfoImpact {
  get priceImpact(): PriceImpact | undefined;
}

export class SwapInfoImpactStore implements ISwapInfoImpact, IDisposable {
  private _baseVersionedState;

  private _pairInfo: ISwapInfoTradePair;

  constructor({ pairInfo, routeInfo, versionProvider }: ISwapInfoImpactParams) {
    makeAutoObservable(this);

    this._baseVersionedState = new VersionedSwapState({
      versionProvider,
      getVersionedState: this._getImpactInfo,
      ...{ routeInfo },
    });

    this._pairInfo = pairInfo;

    makeLoggable(this, {});
  }

  private _getImpactInfo = (
    version: DEXV2ExchangeVersion,
    params: Pick<ISwapInfoImpactParams, "routeInfo">
  ): IBaseSwapInfoImpact => {
    switch (version) {
      case DEXV2ExchangeVersion.V2: {
        return new SwapInfoImpactV2Store(params);
      }
      case DEXV2ExchangeVersion.V3: {
        return new SwapInfoImpactV3Store(params);
      }
    }
  };

  private get _baseState() {
    return this._baseVersionedState.state;
  }

  private get _baseImpact() {
    return this._baseState.priceImpact;
  }

  private get _shouldInvertPrice() {
    return this._pairInfo.shouldInvertPrice;
  }

  private _getPriceImpact = (priceImpact: Percent | undefined): PriceImpact | undefined => {
    if (!priceImpact) {
      return undefined;
    }

    const marketPriceImpact = this._shouldInvertPrice
      ? invertPriceImpact(priceImpact)
      : priceImpact;
    return {
      percent: marketPriceImpact,
      warning: getPriceImpactWarning(marketPriceImpact),
    };
  };

  get priceImpact(): PriceImpact | undefined {
    const baseImpact = this._baseImpact;
    return this._getPriceImpact(baseImpact);
  }

  destroy = () => {
    this._baseVersionedState.destroy();
  };
}
