import { Headline3 } from "src/components/shared/Typography";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SubTitle = styled(Headline3)`
  color: #99a2ad;
`;

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;

  ${CardSurface};
`;
