import { DateTimeValidationError, MuiPickersAdapter, TimezoneProps } from "@mui/x-date-pickers";
import {
  BaseDateValidationProps,
  DefaultizedProps,
  TimeValidationProps,
  Validator,
  validateDateTime,
} from "@mui/x-date-pickers/internals";
import { DayRangeValidationProps } from "../models/dateRange";
import { DateRange, NonEmptyDateRange } from "../models/range";

export type DateTimeRangeValidationErrorValue = DateTimeValidationError | "invalidRange" | null;

export type DateTimeRangeValidationError = [
  DateTimeRangeValidationErrorValue,
  DateTimeRangeValidationErrorValue,
];

export interface DateTimeRangeComponentValidationProps<TDate>
  extends DayRangeValidationProps<TDate>,
    TimeValidationProps<TDate>,
    Required<BaseDateValidationProps<TDate>>,
    DefaultizedProps<TimezoneProps, "timezone"> {}

export const isRangeValid = <TDate>(
  utils: MuiPickersAdapter<TDate>,
  range: DateRange<TDate> | null
): range is NonEmptyDateRange<TDate> =>
  Boolean(range && range[0] && range[1] && !utils.isBefore(range[1], range[0]));

export const validateDateTimeRange: Validator<
  DateRange<any>,
  any,
  DateTimeRangeValidationError,
  DateTimeRangeComponentValidationProps<any>
> = ({ props, value, adapter }) => {
  const [start, end] = value;

  const { shouldDisableDate, ...otherProps } = props;

  const dateTimeValidations: DateTimeRangeValidationError = [
    validateDateTime({
      adapter,
      value: start,
      props: {
        ...otherProps,
        shouldDisableDate: (day) => !!shouldDisableDate?.(day, "start"),
      },
    }),
    validateDateTime({
      adapter,
      value: end,
      props: {
        ...otherProps,
        shouldDisableDate: (day) => !!shouldDisableDate?.(day, "end"),
      },
    }),
  ];

  if (dateTimeValidations[0] || dateTimeValidations[1]) {
    return dateTimeValidations;
  }

  // for partial input
  if (start === null || end === null) {
    return [null, null];
  }

  if (!isRangeValid(adapter.utils, value)) {
    return ["invalidRange", "invalidRange"];
  }

  return [null, null];
};
