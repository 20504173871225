export default class DEXV2DashboardIcons {
  static gas = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3351 4.17269C18.3335 4.09646 18.2502 4.04648 18.1844 4.08507C13.4437 6.86711 13.6041 14.0064 13.6582 15.1067C13.6617 15.1777 13.5945 15.2241 13.5296 15.1952C13.0398 14.9765 11.4015 14.0416 11.3354 11.2974C11.3336 11.221 11.2512 11.1712 11.1853 11.2097C8.48297 12.7884 6.66667 15.7328 6.66667 19C6.66667 23.9706 10.8453 28 16 28C21.1547 28 25.3333 23.9706 25.3333 19C25.3333 11.756 18.4638 10.2161 18.3351 4.17269Z"
        stroke="#156CE3"
        strokeWidth="1.5"
      />
    </svg>
  );

  static volume = () => (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2941 9.02941C18.2941 10.735 14.4227 12.1176 9.64706 12.1176C4.87142 12.1176 1 10.735 1 9.02941M18.2941 4.08824C18.2941 2.38265 14.4227 1 9.64706 1C4.87142 1 1 2.38265 1 4.08824V18.9118C1 20.6173 4.87142 22 9.64706 22C14.4227 22 18.2941 20.6173 18.2941 18.9118V4.08824Z"
        stroke="#156CE3"
        strokeWidth="1.5"
      />
      <path
        opacity="0.3"
        d="M18.2941 4.08824C18.2941 5.79383 14.4227 7.17648 9.64706 7.17648C4.87142 7.17648 1 5.79383 1 4.08824M18.2941 13.9706C18.2941 15.6762 14.4227 17.0588 9.64706 17.0588C4.87142 17.0588 1 15.6762 1 13.9706"
        stroke="#156CE3"
        strokeWidth="1.5"
      />
    </svg>
  );

  static holders = () => (
    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        opacity="0.3"
        cx="4.54625"
        cy="4.54625"
        r="4.54625"
        transform="matrix(-1 0 0 1 19.185 0.99995)"
        stroke="#156CE3"
        strokeWidth="1.5"
      />
      <path
        d="M6.68277 16.8377C6.68277 15.8599 7.29748 14.9876 8.21837 14.6587V14.6587C12.3702 13.1759 16.9072 13.1759 21.059 14.6587V14.6587C21.9799 14.9876 22.5946 15.8599 22.5946 16.8377V18.5839C22.5946 19.801 21.5167 20.736 20.3118 20.5638L18.4571 20.2989C15.9244 19.9371 13.353 19.9371 10.8203 20.2989L8.96561 20.5638C7.76075 20.736 6.68277 19.801 6.68277 18.5839V16.8377Z"
        stroke="#156CE3"
        strokeWidth="1.5"
      />
      <path
        opacity="0.3"
        d="M21.4581 10.2183C23.4309 10.2183 25.0302 8.61902 25.0302 6.64623C25.0302 4.67345 23.4309 3.07418 21.4581 3.07418"
        stroke="#156CE3"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M25.3703 18.3677L25.7203 18.4177C26.77 18.5676 27.7092 17.7531 27.7092 16.6927V15.5179C27.7092 14.7496 27.2262 14.0642 26.5026 13.8058C25.7809 13.5481 25.0443 13.3473 24.2995 13.2036"
        stroke="#156CE3"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        opacity="0.3"
        d="M7.25113 10.2183C5.27834 10.2183 3.67908 8.61902 3.67908 6.64623C3.67908 4.67345 5.27834 3.07418 7.25113 3.07418"
        stroke="#156CE3"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3.33887 18.3677L2.98888 18.4177C1.93914 18.5676 0.999954 17.7531 0.999954 16.6927V15.5179C0.999954 14.7496 1.48294 14.0642 2.2065 13.8058C2.92824 13.5481 3.66481 13.3473 4.40964 13.2036"
        stroke="#156CE3"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
