import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { BaseModuleForm } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared/style";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CEXBotStatus } from "src/modules/bots";
import { IStrategyModuleCreateType } from "src/modules/expertSystem";
import { SelectorValue } from "src/modules/shared";
import { ERRORS_MSGS } from "src/validation-schemas";
import { BotUUIDField } from "../../../shared/BotUUIDField";
import { getEmptyConditionModule } from "../shared/utils";

const MODULE_TYPE = "bot_status";

const BOT_STATUS_LIST = [
  { label: "Red", value: "Red", id: 0 },
  { label: "Yellow", value: "Yellow", id: 1 },
  { label: "Blue", value: "Blue", id: 2 },
  { label: "Green", value: "Green", id: 3 },
  { label: "Gray", value: "Gray", id: 4 },
];

export const BotStatus = observer(() => {
  const state = useLateInitContext(CreateModulesContext);
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<IStrategyModuleCreateType<typeof MODULE_TYPE>>({
    defaultValues: getEmptyConditionModule(MODULE_TYPE),
  });

  const { handleSubmit, control } = methods;

  const getSelectorValue = useCallback(
    (id: CEXBotStatus | "") => BOT_STATUS_LIST.find((el) => el.id === id),
    []
  );

  return (
    <FormProvider {...methods}>
      <BaseModuleForm
        onSubmit={handleSubmit(state.createConditionHandler(close))}
        formId={MODULE_TYPE}
      >
        <RowWrapper>
          <BotUUIDField />

          <Controller
            control={control}
            name="hard_params.status"
            rules={{ required: ERRORS_MSGS.isRequired }}
            render={({ field: { value, onChange }, formState: { errors } }) => {
              const error = getFieldError(errors, "hard_params.status");

              return (
                <LabeledSelector
                  label="Bot status"
                  menuPosition="fixed"
                  options={BOT_STATUS_LIST}
                  onChange={(data) => onChange((data as SelectorValue)?.id)}
                  value={getSelectorValue(value as CEXBotStatus)}
                  errorHint={error?.message}
                />
              );
            }}
          />
        </RowWrapper>
      </BaseModuleForm>
    </FormProvider>
  );
});
