import { Observer, observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Column } from "react-table";
import { DEXV2Vault } from "src/api/bots/DEXV2/create";
import { Switch } from "src/components/shared/Switches/Switch";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { BotWalletConnectionContext } from "src/context/DEXV2/DEXV2Stats/Vaults/BotWalletConnection";
import { SwapVaultsContext } from "src/context/DEXV2/DEXV2Swap/Vaults/SwapVaults";
import { noOp } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useModalState } from "src/hooks/useModalState";
import { BotWalletConnectionState } from "src/state/DEXV2/DEXV2Stats/Vaults/BotWalletConnectionStore";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import { TablePanelProps } from "../../Stats/Tables/TablePanel";
import { AddressCell, HeaderAddressCell } from "../../Stats/Tables/TablePanel/AddressCell";
import { ActionsPanel } from "../../Stats/Tables/Vaults/ActionsPanel";
import { ModalTransfer } from "../../Stats/Tables/Vaults/ModalTransfer";
import { VaultTypeCell } from "../../Stats/Tables/Vaults/VaultTypeCell/VaultTypeCell";
import {
  VaultsQuotesTooltip,
  getVaultsQuotesTooltipAttrs,
} from "../../Stats/Tables/Vaults/VaultsQuotesTooltip";
import { BalancesCell } from "../../Stats/Tables/Vaults/styles";
import { DisabledCellWrapper } from "./DisabledCellWrapper";
import { VaultsInteractionPanel } from "./VaultsInteractionPanel";
import * as styles from "./style";

export interface VaultsProps extends Omit<TablePanelProps, "title" | "afterTitle" | "isLoading"> {}

export const Vaults = observer((props: VaultsProps) => {
  const state = useLateInitContext(SwapVaultsContext);
  const {
    tickers: { quote: quoteTicker, base: baseTicker },
    transferParams,
    modalDepsReady,
    roundedTotalVaults,
  } = state;

  const botState = useLateInitContext(DEXV2BotContext);
  const { botUUID, chainInfo } = botState;
  const scannerLink = getScannerLink(chainInfo, "wallet");

  const { connectionState } = useLateInitContext(BotWalletConnectionContext);
  const isWalletConnected = connectionState === BotWalletConnectionState.Connected;

  const { hasAbility } = useLateInitContext(AbilitiesContext);

  const {
    isShown: isShownTransfer,
    close: onTransferClose,
    show: onTransferOpen,
  } = useModalState();
  const transferEnabled = hasAbility(DEXV2Ability.VaultsTransfer);

  useEffect(() => {
    if (!botUUID) return;
    state.setBotUUID(botUUID);
    state.getVaults();
  }, [botUUID, state]);

  const columns = useMemo(() => {
    const columnsSchema: Column<DEXV2Vault>[] = [
      {
        Header: (
          <HeaderAddressCell
            style={{ justifyContent: "flex-start" }}
            copyCb={state.copyAllAddresses}
          />
        ),
        accessor: "address",
        Cell: ({ row, value }) => (
          <Observer>
            {() => (
              <styles.AddressWrapper>
                <Switch
                  label=""
                  name="active-vault"
                  value={value}
                  checked={state.isSelectedVault(value)}
                  onChange={() => state.setSelectedVaultAddress(value)}
                  onClick={noOp}
                  disabled={state.isVaultDisabled(row.original)}
                />
                <AddressCell address={value} scannerLink={scannerLink} />
              </styles.AddressWrapper>
            )}
          </Observer>
        ),
        disableSortBy: true,
        disableFilters: true,
        Footer: "Total",
      },
      {
        Header: "Type",
        accessor: "type",
        sortType: "string",
        Cell: ({ row, value }) => (
          <DisabledCellWrapper row={row}>
            <VaultTypeCell type={value} />
          </DisabledCellWrapper>
        ),
        disableFilters: true,
      },
      {
        Header: quoteTicker,
        accessor: "quote",
        sortType: "number",
        Cell: ({ row, value }) => (
          <DisabledCellWrapper row={row} disablePointer={false}>
            <BalancesCell
              {...getVaultsQuotesTooltipAttrs(row.original.address, "quote")}
              value={value}
            />
          </DisabledCellWrapper>
        ),
        disableFilters: true,
        Footer: (
          <BalancesCell
            {...getVaultsQuotesTooltipAttrs("total", "quote")}
            value={roundedTotalVaults.quote}
          />
        ),
      },
      {
        Header: baseTicker,
        accessor: "base",
        sortType: "number",
        Cell: ({ row, value }) => (
          <DisabledCellWrapper row={row} disablePointer={false}>
            <BalancesCell
              {...getVaultsQuotesTooltipAttrs(row.original.address, "base")}
              value={value}
            />
          </DisabledCellWrapper>
        ),
        disableFilters: true,
        Footer: (
          <BalancesCell
            {...getVaultsQuotesTooltipAttrs("total", "base")}
            value={roundedTotalVaults.base}
          />
        ),
      },
      {
        Header: "Actions",
        id: "actions",
        accessor: "address",
        Cell: ({ value }) => (
          <ActionsPanel
            address={value}
            onTransferOpen={onTransferOpen}
            transferEnabled={transferEnabled}
            onVaultAddressChange={state.setCurrentFromVaultAddress}
            disabled={!isWalletConnected || !modalDepsReady}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
    ];

    return columnsSchema;
  }, [
    baseTicker,
    isWalletConnected,
    modalDepsReady,
    onTransferOpen,
    quoteTicker,
    scannerLink,
    state,
    transferEnabled,
    roundedTotalVaults,
  ]);

  return (
    <styles.VaultsPanel
      title="Vaults"
      afterTitle={<VaultsInteractionPanel />}
      isLoading={state.loading}
      {...props}
    >
      <styles.VaultsTable data={state.roundedVaults} columns={columns} showFooter />

      <VaultsQuotesTooltip quotesProvider={state} />

      {transferParams && (
        <ModalTransfer show={isShownTransfer} close={onTransferClose} {...transferParams} />
      )}
    </styles.VaultsPanel>
  );
});
