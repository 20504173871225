import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { Headline3 } from "src/components/shared/Typography";
import styled from "styled-components";

interface BalanceProps {
  exceed: boolean;
}

export const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
`;

export const HeaderCol = styled.div`
  width: 100%;
  min-width: 0;

  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const CurrencyTitle = styled(Headline3)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Balance = styled.span<BalanceProps>`
  color: ${({ exceed, theme }) => (exceed ? theme.dangerColor : theme.textColor)};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};

  @media screen and (max-width: 880px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;

export const UpdateBtn = styled(ReloadButton)`
  @media screen and (max-width: 880px) {
    display: none;
  }
`;
