import { observer } from "mobx-react-lite";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import useMediaQuery from "src/hooks/useMediaQuery";
import { DashboardBreakpoints } from "../../../shared/style";
import { DesktopSummary } from "./DesktopSummary";
import { MobileSummary } from "./MobileSummary";

export interface SummaryViewProps {}

export const SummaryView = observer((props: SummaryViewProps) => {
  const mobileQuery = getMobileMediaQuery(DashboardBreakpoints.XMobile);
  const isMobile = useMediaQuery(mobileQuery);
  return <>{isMobile ? <MobileSummary /> : <DesktopSummary />}</>;
});
