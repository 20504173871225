import { StyledProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";
import { InOutIndicatorType } from ".";

interface IndicatorProps {
  type: InOutIndicatorType;
}

interface StyledIndicatorProps extends StyledProps<IndicatorProps> {}

const InOutIndicatorColorMixin = css<StyledIndicatorProps>`
  background-color: ${({
    theme: {
      dashboard: { accent, textDefault },
    },
    $type,
  }) => {
    switch ($type) {
      case "in":
        return accent.darkLime;
      case "out":
        return accent.rubyRed;
      case "neutral":
        return textDefault;
    }
  }};
`;

export const StyledIndicator = styled.div<StyledIndicatorProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;

  ${InOutIndicatorColorMixin}
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;
