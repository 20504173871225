import { CheckBox } from "src/components/shared/CheckBox";
import { CheckBox as CheckBoxStyled, Label } from "src/components/shared/CheckBox/style";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledFee = styled.span`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.dashboard.textSubhead};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
  letter-spacing: 0.2px;
`;

export const StyledCheckbox = styled(CheckBox)`
  ${Label} {
    width: 12px;
    height: 12px;
  }

  ${CheckBoxStyled} {
    :checked + ${Label}::before {
      width: 3px;
      height: 5px;
      left: 3px;
      top: 1px;
      border-width: 1.8px;
    }
  }
`;
