import { hexToRgb } from "src/helpers/colors";
import styled from "styled-components";

export const Container = styled.div`
  height: 20px;
  width: 100%;

  display: flex;
  align-items: center;

  padding: 0px 10px;
`;

export const VolumeWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const BuyVolume = styled.div`
  position: relative;
  display: flex;

  background-color: ${({ theme }) => hexToRgb(theme.successColor, 0.5)};

  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`;

export const SellVolume = styled.div`
  position: relative;
  display: flex;

  background-color: ${({ theme }) => hexToRgb(theme.dangerColor, 0.5)};

  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`;

const VolumeTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;

export const BuyTitle = styled(VolumeTitle)`
  padding-right: 5px;

  color: ${({ theme }) => theme.successColor};
`;

export const BuyValue = styled(BuyTitle)`
  position: absolute;

  left: 10px;
  top: 2.5px;

  white-space: nowrap;
`;

export const SellTitle = styled(VolumeTitle)`
  padding-left: 5px;

  color: ${({ theme }) => theme.dangerColor};
`;

export const SellValue = styled(SellTitle)`
  position: absolute;

  right: 10px;
  top: 2.5px;

  white-space: nowrap;
`;

export const OrderCount = styled(VolumeTitle)`
  color: ${({ theme }) => theme.labelColor};
`;
