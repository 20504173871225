import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ModuleListContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ModuleConfig } from "src/state/CEX/multiGrid/AddModuleModal";
import { IExchangeModule } from "src/state/CEX/multiGrid/Modules";
import { ModuleContent } from "./ModuleContent";
import { ModuleHeader } from "./ModuleHeader";
import * as styles from "./style";

export interface CreatedModuleProps {
  module: IExchangeModule;
}

export const CreatedModule = observer(({ module }: CreatedModuleProps) => {
  const state = useLateInitContext(ModuleListContext);

  const methods = useForm<ModuleConfig>({
    defaultValues: module.settings,
  });

  useEffect(() => {
    methods.reset(module.settings);
  }, [methods, module.settings]);

  return (
    <styles.ModuleContainer style={{ height: "230px", boxShadow: "none" }}>
      <FormProvider {...methods}>
        <ModuleHeader module={module} />

        <styles.FormContent
          onSubmit={methods.handleSubmit(state.saveModuleSettings(module.id))}
          id={module.id}
        >
          <ModuleContent module={module} />
        </styles.FormContent>
      </FormProvider>
    </styles.ModuleContainer>
  );
});
