import { useCallback, useMemo } from "react";
import { GroupBase } from "react-select";
import { SelectorProps } from "src/components/shared/Forms/Selectors";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import { SelectorValue } from "src/modules/shared";
import * as styles from "./style";

export interface TokensSelectorProps
  extends Omit<
    SelectorProps<SelectorValue, false, GroupBase<SelectorValue>>,
    "value" | "onChange" | "options"
  > {
  options?: string[];
  value?: string;
  onChange?: (token: string) => void;
}

export const TokensSelector = ({
  options: outerOptions = [],
  onChange: outerOnChange,
  value: outerValue = "",
  ...props
}: TokensSelectorProps) => {
  const options = useMemo(() => getSelectorList(outerOptions), [outerOptions]);

  const value = useMemo(() => stringToSelectorValue(outerValue), [outerValue]);

  const onChange = useCallback(
    (selectorValue: SelectorValue | null) => {
      if (!selectorValue) return;
      const token = selectorValue.value as string;
      outerOnChange?.(token);
    },
    [outerOnChange]
  );

  return (
    <styles.StylesTokensSelector value={value} onChange={onChange} options={options} {...props} />
  );
};
