import { SelectColumnFilter } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/SelectColumnFilter";
import { SELECTOR_CLASSNAME } from "src/components/shared/Forms/Selectors/shared/style";
import styled from "styled-components";
import { ColumnsVisibilityFilter } from "../../../shared/StatsTable/ColumnsVisibilityFilter";

export const StyledColumnsFilter = styled(ColumnsVisibilityFilter)`
  & .${SELECTOR_CLASSNAME}__control {
    width: 140px;
    height: 24px;
  }
`;

export const StyledSelectorFilter = styled(SelectColumnFilter)`
  & .${SELECTOR_CLASSNAME}__control {
    width: 120px;
  }
` as typeof SelectColumnFilter;

export const ExchangeText = styled.span`
  align-self: flex-start;
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
`;
