import { makeAutoObservable } from "mobx";
import { GetLiquidityResponse, getLiquidity } from "src/api/bots/CEX/dashboard";
import { makeLoggable } from "src/helpers/logger";
import { Mapper, entries } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { timeToMs, valuesToNumber } from "../../utils";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type LiquidityTypeData = {
  total: number[];
  notOur: number[];
  our: number[];
};

type LiquidityData = {
  buy: LiquidityTypeData;
  sell: LiquidityTypeData;
  time: number[];
  price: number[];
};

const INITIAL_DATA: LiquidityData = {
  buy: {
    total: [],
    notOur: [],
    our: [],
  },
  sell: {
    total: [],
    notOur: [],
    our: [],
  },
  time: [],
  price: [],
};

const dataToNumbers = (data: GetLiquidityResponse["buy"]) =>
  Object.fromEntries(entries(data).map(([key, data]) => [key, valuesToNumber(data)])) as Record<
    keyof GetLiquidityResponse["buy"],
    number[]
  >;

const liquidityResponseToData: Mapper<GetLiquidityResponse, LiquidityData> = ({
  time,
  buy,
  sell,
  price_avg,
}) => {
  const buyNumbers = dataToNumbers(buy);

  const sellNumbers = dataToNumbers(sell);

  return {
    time,
    buy: {
      our: buyNumbers.our,
      total: buyNumbers.all,
      notOur: buyNumbers.not_our,
    },
    sell: {
      our: sellNumbers.our,
      total: sellNumbers.all,
      notOur: sellNumbers.not_our,
    },
    price: valuesToNumber(price_avg, false),
  };
};

interface ILiquidityStoreParams extends IBaseDashboardV2StoreParams {}

export class LiquidityV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: LiquidityData = INITIAL_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: ILiquidityStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { priceRange: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: LiquidityData) => {
    this._data = data;
  };

  get time() {
    return timeToMs(this._data.time);
  }

  get buy() {
    return this._data.buy;
  }

  get sell() {
    return this._data.sell;
  }

  get price() {
    return this._data.price;
  }

  get _minPrice() {
    return this._data.price.length ? Math.min.apply(0, this._data.price) : undefined;
  }

  get _maxPrice() {
    return this._data.price.length ? Math.max.apply(0, this._data.price) : undefined;
  }

  get priceRange() {
    const max = this._maxPrice;
    const min = this._minPrice;
    if (max === undefined || min === undefined) return undefined;
    return Math.abs(max - min);
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    const { party, queryParams } = requestParams;

    this._setData(INITIAL_DATA);
    try {
      const { data, isError } = await getLiquidity(party, queryParams);

      if (!isError) {
        const LiquidityData = liquidityResponseToData(data);
        this._setData(LiquidityData);
      }
    } catch {
      this._setData(INITIAL_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
