import { FilterType, SortByFn } from "react-table";
import { Comparators } from "src/helpers/sorting";
import { PairTickers } from "src/state/DEXV2/shared/TradePair";
import { TradeSide } from "src/state/DEXV2/shared/TradeToken";
import { NumberFilterRange } from "../NumberRangeColumnFilter";

const getFilterValuesType = (values: any[]) => typeof values[0];

const getArrayFilter = (filterValues: any[]) => {
  const valueType = getFilterValuesType(filterValues);

  switch (valueType) {
    case "string": {
      return (values: string | string[]) => {
        if (Array.isArray(values)) {
          return filterValues.some((filterValue) => values.includes(filterValue));
        }
        return filterValues.includes(values);
      };
    }
    default: {
      return (values: any | any[]) => {
        if (Array.isArray(values)) {
          return filterValues.some((filterValue) => values.some((value) => value === filterValue));
        }
        return filterValues.some((filter) => filter === values);
      };
    }
  }
};

export function createIncludesFilter<D extends object>(): FilterType<D> {
  const filter: FilterType<D> = (rows, ids, filterValue) => {
    const id = ids[0];
    const filterValues = filterValue as any[];
    const filter = getArrayFilter(filterValues);

    const filteredRows = rows.filter((row) => {
      const rowValue = row.values[id];
      return filter(rowValue);
    });

    return filteredRows;
  };

  filter.autoRemove = (filterValue: any[]) => {
    const remove = !filterValue || !filterValue.length;
    return remove;
  };

  return filter;
}

type CompareCondition = "less" | "greater" | "strictGreater" | "strictLess";

const rangeComparator = (a: number, b: number, condition: CompareCondition) => {
  switch (condition) {
    case "less":
      return a <= b;
    case "greater":
      return a >= b;
    case "strictGreater":
      return a > b;
    case "strictLess":
      return a < b;
  }
};

interface IsInRangeOptions {
  minInclusive?: boolean;
  maxInclusive?: boolean;
}

export const isInRange = (
  value: number,
  min: number | "",
  max: number | "",
  { minInclusive, maxInclusive }: IsInRangeOptions = {}
) => {
  // eslint-disable-next-line no-param-reassign
  minInclusive = minInclusive ?? false;
  // eslint-disable-next-line no-param-reassign
  maxInclusive = maxInclusive ?? false;

  const skipMinCheck = typeof min !== "number";
  const skipMaxCheck = typeof max !== "number";

  const greaterThanMin =
    skipMinCheck ||
    (minInclusive
      ? rangeComparator(value, min, "greater")
      : rangeComparator(value, min, "strictGreater"));

  const lessThanMax =
    skipMaxCheck ||
    (maxInclusive
      ? rangeComparator(value, max, "less")
      : rangeComparator(value, max, "strictLess"));

  return greaterThanMin && lessThanMax;
};

export function createNumberBetweenFilter<D extends object>(
  options: IsInRangeOptions = {}
): FilterType<D> {
  const filter: FilterType<D> = (rows, ids, filterValue) => {
    const id = ids[0];
    const filterValues = filterValue as NumberFilterRange;

    const [min, max] = filterValues;

    const filteredRows = rows.filter((row) => {
      const rowValue = row.values[id];
      return isInRange(rowValue, min, max, options);
    });

    return filteredRows;
  };

  filter.autoRemove = (filterValue: NumberFilterRange) => {
    if (!filterValue || !filterValue.length) return true;
    const [min, max] = filterValue;
    return min === "" && max === "";
  };

  return filter;
}

export const createExchangesSortBy = <D extends object>() => {
  const exchangesSortBy: SortByFn<D> = (rowA, rowB, columnId) => {
    const exchangesA = rowA.values[columnId] as string[];
    const exchangesB = rowB.values[columnId] as string[];

    const exchangesACount = exchangesA.length;
    const exchangesBCount = exchangesB.length;
    return Comparators.Number(exchangesACount, exchangesBCount);
  };

  return exchangesSortBy;
};

export const createStringNumberSortBy = <D extends object>() => {
  const stringNumberSortBy: SortByFn<D> = (rowA, rowB, columnId) => {
    const valueA = rowA.values[columnId] as string;
    const valueB = rowB.values[columnId] as string;

    return Comparators.StringNumber(valueA, valueB);
  };

  return stringNumberSortBy;
};

export const createTokenPairSortBy = <D extends object>(order: TradeSide = "quote") => {
  const tokenPairSortBy: SortByFn<D> = (rowA, rowB, columnId) => {
    const valueA = rowA.values[columnId] as PairTickers;
    const valueB = rowB.values[columnId] as PairTickers;

    const thisSide = order;
    const thatSide = order === "base" ? "quote" : "base";

    const thisSideCompare = Comparators.String(valueA[thisSide], valueB[thisSide]);
    if (thisSideCompare) return thisSideCompare;

    const otherSideCompare = Comparators.String(valueA[thatSide], valueB[thatSide]);

    return otherSideCompare;
  };

  return tokenPairSortBy;
};
