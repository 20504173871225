import { Button, ButtonProps } from "src/components/shared/Buttons/v2/Button";
import { ButtonGroup, ButtonGroupProps } from "src/components/shared/Buttons/v2/ButtonGroup";
import { DropdownButton } from "src/components/shared/Buttons/v2/DropdownButton";
import { PopoverMenu, PopoverMenuProps } from "src/components/shared/PopoverMenu";

export interface SubmitSplitButtonOwnProps {
  submitProps?: ButtonProps;
  menuProps?: Omit<PopoverMenuProps, "trigger">;
}

export interface SubmitSplitButtonProps
  extends SubmitSplitButtonOwnProps,
    Omit<ButtonGroupProps, keyof SubmitSplitButtonOwnProps> {}

export const SubmitSplitButton = ({ submitProps, menuProps, ...props }: SubmitSplitButtonProps) => {
  const submitText = submitProps?.children ?? "Submit";
  return (
    <ButtonGroup {...props}>
      <PopoverMenu
        trigger={(isOpen) => <DropdownButton open={isOpen} />}
        {...menuProps}
        popupProps={{ position: "top center" }}
      />
      <Button stretched {...submitProps}>
        {submitText}
      </Button>
    </ButtonGroup>
  );
};
