import { makeAutoObservable } from "mobx";
import AccountListStore from "src/state/shared/AccountListStore";
import AccountReceiver from "src/state/shared/AccountReceiver";
import ExchangePairsStore from "src/state/shared/ExchangePairsStore";

interface InitialState {
  state: AccountReceiver;
}

class SelectorProvider {
  private _accountListState: AccountListStore;

  private _exchPairState: ExchangePairsStore;

  private _exchange = "";

  constructor({ state }: InitialState) {
    makeAutoObservable(this);
    this._accountListState = new AccountListStore({
      mainState: state,
      exchState: this,
    });

    this._exchPairState = new ExchangePairsStore(this);
  }

  get exchange() {
    return this._exchange;
  }

  get exchPairState() {
    return this._exchPairState;
  }

  get accListState() {
    return this._accountListState;
  }

  get fullAccList() {
    return this._accountListState.fullAccOptions;
  }

  setExchange = (exch: string) => {
    this._exchange = exch;
  };

  destroy = () => {};
}

export default SelectorProvider;
