import { Headline1 } from "src/components/shared/Typography";
import styled from "styled-components";

export const StoriesContainer = styled.div`
  width: 100%;
  max-width: 800px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 20px;
`;

export const StoriesContent = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 20px;
`;

export const StoryTitle = styled(Headline1)``;
