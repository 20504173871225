import { useMemo } from "react";
import { SuggestionLabeledInput } from "src/components/shared/Forms/Variants";
import { roundNumber } from "src/helpers/rounding";
import { InputFieldProps } from "src/state/DEXV2/types";
import * as styles from "./style";

interface AmountSuggestionProps extends InputFieldProps {
  maxAmount: number | null;
  setAmount: (maxAmount: number) => void;
}

export const AmountSuggestionInput = ({
  maxAmount,
  setAmount,
  ...inputProps
}: AmountSuggestionProps) => {
  const roundedMaxAmount = maxAmount && roundNumber(maxAmount);

  const isShowSuggestion = useMemo(
    () => maxAmount !== null && inputProps.value !== maxAmount,
    [maxAmount, inputProps.value]
  );

  return (
    <SuggestionLabeledInput
      label="Amount"
      type="number"
      step="any"
      {...inputProps}
      getSuggestion={(setRef) =>
        isShowSuggestion ? (
          <styles.AmountPrompt
            value={String(roundedMaxAmount)}
            setValue={() => setAmount(maxAmount as number)}
            ownRef={setRef}
          />
        ) : null
      }
    />
  );
};
