import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useMemo } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Loader } from "src/components/shared/Loader";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { Headline3 } from "src/components/shared/Typography";
import { PartyContext } from "src/context/UserManager/UserManager";
import AddAccStore from "src/state/UserManager/EditParty/AddAccStore";
import * as styles from "./style";

interface AddAccModalProps {
  show: boolean;
  hide: React.Dispatch<React.SetStateAction<boolean>>;
}

type AddAccApiTypeProps =
  | {
      type: "API";
      defaultExchange?: never;
    }
  | { type: "ACCOUNT"; defaultExchange: string };

export type AddAccProps = AddAccApiTypeProps & AddAccModalProps;

export const AddAccEntity = observer(({ show, hide, type, defaultExchange }: AddAccProps) => {
  const partyState = useContext(PartyContext);

  const accState = useMemo(
    () => new AddAccStore(String(partyState?.party), type, String(partyState?.newAPIAcc?.uuid)),
    [partyState?.party, type, partyState?.newAPIAcc?.uuid]
  );

  const label = type === "ACCOUNT" ? "account" : "API";

  useEffect(() => {
    accState.resetForm();
    accState.setTrigger(hide);
    if (partyState) accState.setUpdateList(partyState?.getAccounts);
  }, [accState, partyState, hide, show, type]);

  useEffect(() => {
    if (type === "ACCOUNT") accState.getExchangesList();
  }, [type, accState]);

  useEffect(() => {
    if (type === "ACCOUNT") {
      accState.setExchange(defaultExchange);
    }
  }, [type, accState, defaultExchange]);

  return (
    <ModalPanel label={`Add ${label}`} show={show} close={hide} loading={false}>
      <styles.AccForm
        onSubmit={accState.submitHandler()}
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
      >
        <Headline3>Account</Headline3>

        <styles.Wrapper>
          {type === "ACCOUNT" ? (
            <LabeledSelector
              label="Exchange"
              options={accState.exchanges}
              value={{
                value: String(accState?.account.exchange),
                label: String(accState?.account.exchange),
              }}
              onChange={accState?.getAccountHandler("exchange")}
              errorHint={accState?.errors.exchange}
            />
          ) : (
            <LabeledInput
              label="Exchange"
              value={String(partyState?.newAPIAcc?.exchange)}
              readOnly
              onChange={() => {}}
            />
          )}
          {type === "ACCOUNT" ? (
            <LabeledInput
              label="Account name"
              value={String(accState?.account.name)}
              onChange={accState?.getAccountHandler("name")}
              errorHint={accState?.errors.name}
            />
          ) : (
            <LabeledInput
              label="Account name"
              value={String(partyState?.newAPIAcc?.name)}
              onChange={() => {}}
              readOnly
            />
          )}
        </styles.Wrapper>

        <Headline3>API</Headline3>

        <LabeledInput
          label="Open"
          value={String(accState?.account.credentials.data.KEY)}
          onChange={accState?.getAccountHandler("credentials.data.KEY")}
          errorHint={accState?.errors.credentials?.data?.KEY}
        />
        <LabeledInput
          label="Secret"
          value={String(accState?.account.credentials.data.SECRET)}
          onChange={accState?.getAccountHandler("credentials.data.SECRET")}
          errorHint={accState?.errors.credentials?.data?.SECRET}
        />
        <LabeledInput
          label="Account ID"
          value={String(accState?.account.credentials.data.ACCOUNT_ID)}
          onChange={accState?.getAccountHandler("credentials.data.ACCOUNT_ID")}
          errorHint={accState?.errors.credentials?.data?.ACCOUNT_ID}
        />

        <styles.Wrapper>
          <LabeledInput
            label="API name"
            value={String(accState?.account.credentials.name)}
            onChange={accState?.getAccountHandler("credentials.name")}
            errorHint={accState?.errors.credentials?.name}
          />

          <OutlineButton stretched type="submit">
            Add {label} +
          </OutlineButton>
        </styles.Wrapper>
      </styles.AccForm>
      <Loader show={accState.isLoadingAcc} />
    </ModalPanel>
  );
});
