import { CurrencyAmount, NativeCurrency, Price, Token } from "@uniswap/sdk-core";
import { makeAutoObservable } from "mobx";
import { GetBotBalanceHistoryResponse, getBotBalanceHistory } from "src/api/bots/DEXV2/history";
import { BalancesGraphData } from "src/components/BotsContent/DEX_V2/Stats/Graphs/BalancesGraph";
import { NativeGraphData } from "src/components/BotsContent/DEX_V2/Stats/Graphs/NativeGraph";
import { formatQuote } from "src/components/BotsContent/DEX_V2/Stats/Tables/Vaults/VaultsQuotesTooltip/VaultsPricesTooltipContent";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { logError } from "src/helpers/network/logger";
import { calcRoundingValues } from "src/helpers/rounding";
import { IDisposable, entries } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { ChartPoint } from "src/modules/shared";
import { ITooltipSeriesDataProvider } from "src/state/Graph/Tooltip";
import {
  SeriesDataMap,
  SeriesSingleDataValue,
  TooltipSeriesData,
} from "src/state/Graph/Tooltip/types";
import {
  IBotChainProvider,
  IBotTickersProvider,
  IBotTradePairProvider,
} from "../../DEXV2Bots/DEXV2BotStore";
import {
  ABSTRACT_NATIVE_CURRENCY,
  tryParseCurrencyAmount,
  zeroCurrencyAmount,
} from "../../DEXV2Swap/utils";
import { INativeUSDPriceProvider } from "../../Providers/NativeUSDPriceProvider";
import { ITradePairPriceProvider } from "../../Providers/TradePairUSDPriceProvider";
import { TradeSide } from "../../shared/TradeToken";
import { VaultQuotes, getBaseQuotes, getQuoteQuotes } from "../Vaults/VaultsProvider";

export type NativeQuote = { usd?: CurrencyAmount<Token> };

export const getNativeQuotes = (
  native: number,
  nativeUSDPrice: Price<NativeCurrency, Token>
): NativeQuote => {
  if (native === 0) {
    return {
      usd: zeroCurrencyAmount(nativeUSDPrice.quoteCurrency),
    };
  }

  const nativeAmount = tryParseCurrencyAmount(native.toString(), ABSTRACT_NATIVE_CURRENCY);

  if (!nativeAmount) return {};

  const nativeUsdAmount = nativeUSDPrice.quote(nativeAmount);
  return { usd: nativeUsdAmount };
};

export type GraphQuote =
  | ({
      currency: "base";
    } & VaultQuotes["base"])
  | ({ currency: "quote" } & VaultQuotes["quote"])
  | ({ currency: "native" } & NativeQuote);

export type GraphsTooltipData = TooltipSeriesData & {
  quote?: GraphQuote;
};

export interface IGraphsParams {
  tickersProvider: IBotTickersProvider;
  botChainProvider: IBotChainProvider;
  tradePairProvider: IBotTradePairProvider;
  tradePairPriceProvider: ITradePairPriceProvider;
  nativeUSDPriceProvider: INativeUSDPriceProvider;
}

export default class GraphsStore
  implements ITooltipSeriesDataProvider<GraphsTooltipData>, IDisposable
{
  private _botUUID = "";

  private _loading = false;

  private _quoteBalances: ChartPoint[] = [];

  private _baseBalances: ChartPoint[] = [];

  private _nativeBalances: ChartPoint[] = [];

  private _tickersProvider: IBotTickersProvider;

  private _botChainProvider: IBotChainProvider;

  private _tradePairProvider: IBotTradePairProvider;

  private _tradePairPriceProvider: ITradePairPriceProvider;

  private _nativeUSDPriceProvider: INativeUSDPriceProvider;

  constructor({
    tickersProvider,
    botChainProvider,
    tradePairPriceProvider,
    tradePairProvider,
    nativeUSDPriceProvider,
  }: IGraphsParams) {
    makeAutoObservable(this);

    this._tickersProvider = tickersProvider;
    this._botChainProvider = botChainProvider;
    this._tradePairPriceProvider = tradePairPriceProvider;
    this._nativeUSDPriceProvider = nativeUSDPriceProvider;
    this._tradePairProvider = tradePairProvider;
  }

  private get _chainInfo() {
    return this._botChainProvider.chainProvider.currentChain;
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private get _pairPrice() {
    return this._tradePairPriceProvider.pairPrice;
  }

  private get _nativeUSDPrice() {
    return this._nativeUSDPriceProvider.nativeUSDPrice;
  }

  private get _nativeTicker() {
    return this._chainInfo?.native;
  }

  get nativeTicker() {
    return this._nativeTicker ?? "Native";
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  private get _tickers() {
    return this._tickersProvider.tickers;
  }

  private _setBalances = ({ quote, native, base }: GetBotBalanceHistoryResponse) => {
    this._quoteBalances = quote;
    this._nativeBalances = native;
    this._baseBalances = base;
  };

  private get _quoteTicker() {
    return this._tickers?.quote;
  }

  get quoteTicker() {
    return this._quoteTicker ?? "Quote";
  }

  private get _baseTicker() {
    return this._tickers?.base;
  }

  get baseTicker() {
    return this._baseTicker ?? "Base";
  }

  get quotePrecision() {
    return this._getPrecision(this._quoteBalances);
  }

  get basePrecision() {
    return this._getPrecision(this._baseBalances);
  }

  get nativePrecision() {
    return this._getPrecision(this._nativeBalances);
  }

  get balancesPoints(): BalancesGraphData {
    const quote = this._quoteBalances;
    const base = this._baseBalances;
    return { quote, base };
  }

  get nativePoints(): NativeGraphData {
    return this._nativeBalances;
  }

  private _getPrecision = (points: ChartPoint[]) => {
    const pricePoints: number[] = [];

    points.forEach((el) => {
      pricePoints.push(el.value);
    });

    const precision = calcRoundingValues(pricePoints);

    return precision;
  };

  private _getTooltipNativeQuotes = (amount: number) => {
    const nativeUSDPrice = this._nativeUSDPrice;
    if (!nativeUSDPrice) return {};

    return getNativeQuotes(amount, nativeUSDPrice);
  };

  private _getTooltipBalanceQuotes = (amount: number, side: TradeSide) => {
    const pairPrice = this._pairPrice;
    const tradePair = this._tradePair;
    if (!pairPrice || !tradePair) return {};

    switch (side) {
      case "quote": {
        return getQuoteQuotes(amount, tradePair.quote, pairPrice.quote);
      }
      case "base": {
        return getBaseQuotes(amount, tradePair.base, pairPrice.base);
      }
    }
  };

  private _getGraphTooltipQuote = (seriesData: TooltipSeriesData): GraphQuote | undefined => {
    const baseTicker = this._baseTicker;
    const quoteTicker = this._quoteTicker;
    const nativeTicker = this._nativeTicker;
    if (!baseTicker || !quoteTicker || !nativeTicker) {
      return undefined;
    }

    const { title: ticker, value } = seriesData;

    const price = value as SeriesSingleDataValue;

    switch (ticker) {
      case quoteTicker: {
        const quotes = this._getTooltipBalanceQuotes(price, "quote");
        return { currency: "quote", ...quotes };
      }

      case baseTicker: {
        const quotes = this._getTooltipBalanceQuotes(price, "base");
        return { currency: "base", ...quotes };
      }
      case nativeTicker: {
        const quotes = this._getTooltipNativeQuotes(price);
        return { currency: "native", ...quotes };
      }
      default: {
        return undefined;
      }
    }
  };

  getTooltipData = (seriesData: TooltipSeriesData): GraphsTooltipData => {
    const quote = this._getGraphTooltipQuote(seriesData);
    return { ...seriesData, quote };
  };

  private _quotesToString = (quote?: GraphQuote): Record<string, string> => {
    if (!quote) return {};
    const { currency, ...quotes } = quote;
    const filledQuotes = filterBoolean(entries(quotes));
    const formattedQuotes = filledQuotes.map(
      ([type, quote]) => [type, formatQuote(type, quote)] as const
    );

    return Object.fromEntries(formattedQuotes);
  };

  seriesToString = (seriesDataMap: SeriesDataMap<GraphsTooltipData>, utcTimestamp: number) => {
    const dataEntries = Array.from(seriesDataMap);
    const date = unixToDateFormat(utcTimestamp, "FullDate");

    const seriesData = dataEntries.map(([seriesTitle, { quote, value }]) => {
      const quotes = this._quotesToString(quote);
      const data = {
        amount: value,
        ...quotes,
      };

      const dataText = Object.values(data).join(" - ");

      const seriesText = `${seriesTitle}: ${dataText}`;

      return seriesText;
    });

    const seriesString = [date, ...seriesData].join("\n");
    return seriesString;
  };

  private _refreshPairPrice = async () => {
    await this._tradePairPriceProvider.getTradePairPrice({ waitTimeout: 3000 });
  };

  private _refreshNativeUSDPrice = async () => {
    await this._nativeUSDPriceProvider.getNativeUSDPrice({ waitTimeout: 3000 });
  };

  private _getPoints = async () => {
    this._setLoading(true);
    try {
      const { isError, data } = await getBotBalanceHistory(this._botUUID);
      if (!isError) {
        this._setBalances(data ?? []);
      }
    } finally {
      this._setLoading(false);
    }
  };

  getGraphs = async () => {
    try {
      await Promise.all([
        this._getPoints(),
        this._refreshPairPrice(),
        this._refreshNativeUSDPrice(),
      ]);
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}
