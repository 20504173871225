import { Card, CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { BaseDoughnut, BaseDoughnutProps } from "../../../shared/BaseDoughnut";

export interface BalancesDoughnutProps
  extends Pick<BaseDoughnutProps<number[], string>, "data">,
    CardProps {}

export const BalancesDoughnut = ({ data, ...props }: BalancesDoughnutProps) => (
  <Card title="Total balance" {...props}>
    <BaseDoughnut data={data} />
  </Card>
);
