import { ComponentPropsWithoutRef } from "react";
import { getModeIcon } from "src/components/AllBots/Bots/DEXV2Bots/shared/ModeChip/ModeIcon";
import { DEXV2BotMode } from "src/modules/bots";
import * as styles from "./style";

export interface SettingsModeIconProps extends ComponentPropsWithoutRef<"span"> {
  mode: DEXV2BotMode;
}

export const SettingsModeIcon = ({ mode, ...props }: SettingsModeIconProps) => {
  const icon = getModeIcon(mode);
  return <styles.IconWrapper {...props}>{icon}</styles.IconWrapper>;
};
