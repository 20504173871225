import styled from "styled-components";
import { SettingsPanel as BaseSettingsPanel } from "../../shared/SettingsPanel";
import { Content } from "../../shared/SettingsPanel/style";
import { FormContent as BaseFormContent } from "../../shared/style";
import { LimitInfo } from "./LimitInfo";

export const FormContent = styled(BaseFormContent)``;

export const SettingsPanel = styled(BaseSettingsPanel)`
  ${Content} {
    height: 100%;
  }
`;

export const StyledLimitInfo = styled(LimitInfo)``;
