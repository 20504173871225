import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import {
  CreateMultiGridContext,
  MultiGridAbilitiesContext,
  MultiGridContext,
} from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import CreateMultiGridStore from "src/state/CEX/multiGrid/CreateMultiGrid";
import InfoHeading from "../../LiquidityTab/InfoHeading";
import { AddExchangeModule } from "../AddExchangeModule";
import { MultiGridForm } from "../MultiGridForm";
import { GridConfigContainer } from "../shared";
import { ModuleList } from "./ModuleList";

const GRID_FORM_ID = "GRID_FORM_CREATE";

export const CreateMultiGrid = observer(() => {
  const mainState = useLateInitContext(MultiGridContext);

  const { abilityName } = useLateInitContext(MultiGridAbilitiesContext);

  const state = useMemo(() => new CreateMultiGridStore(mainState), [mainState]);

  return (
    <CreateMultiGridContext.Provider value={state}>
      <GridConfigContainer>
        <InfoHeading title={state.party || ""} />
        <MultiGridForm id={GRID_FORM_ID} state={state} />
        <PermissionWrapper party={state.party} abilityName={abilityName}>
          <>
            <AddExchangeModule label="Add exchange module" moduleCreatorState={state} />

            <ModuleList />

            <SubmitButton stretched form={GRID_FORM_ID} />
          </>
        </PermissionWrapper>
      </GridConfigContainer>
    </CreateMultiGridContext.Provider>
  );
});
