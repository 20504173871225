import { hexToRgb } from "src/helpers/colors";
import styled from "styled-components";

export interface AppearanceProps {
  appearance: "primary" | "danger";
}

export const Container = styled.div<AppearanceProps>`
  position: relative;

  display: flex;
  align-items: center;
  gap: 10px;

  padding: 10px 28px;

  border-radius: ${({ theme }) => theme.radii["4px"]};
  background: ${({ theme, appearance }) => {
    switch (appearance) {
      case "primary": {
        return theme.promptMsgBg;
      }
      case "danger": {
        return hexToRgb(theme.dangerColor, 0.2);
      }
    }
  }};

  font-size: ${({ theme }) => theme.fontSizes["11px"]};
`;

export const Text = styled.p<AppearanceProps>`
  width: 100%;

  margin: 0;

  display: flex;

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme, appearance }) => {
    switch (appearance) {
      case "primary": {
        return theme.primaryColor;
      }
      case "danger": {
        return theme.dangerColor;
      }
    }
  }};
`;
