import { observer } from "mobx-react-lite";
import { SettingsPanelProps } from "src/components/BotsContent/DEX_V2/Settings/shared/SettingsPanel";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FormContent, SideSettingsPanel } from "../../shared";

export interface TradeSettingsPanelProps extends Omit<SettingsPanelProps, "label" | "children"> {}

export const TradeSettingsPanel = observer((props: TradeSettingsPanelProps) => {
  const state = useLateInitContext(DEXV2CreatingContext);

  return (
    <SideSettingsPanel label="Trade Settings" {...props}>
      <FormContent>
        <LabeledInput
          type="number"
          step="0.1"
          label="Slippage"
          {...state.getInputProps("counter_data.slippage")}
        />
        <LabeledPercentRangeInput
          step="0.1"
          label="Gas Mult"
          {...state.getInputProps("counter_data.gas_mult")}
        />
        <LabeledInput
          type="number"
          step="0.1"
          label="Gas Max"
          {...state.getInputProps("counter_data.gas_price_limit")}
        />
      </FormContent>
    </SideSettingsPanel>
  );
});
