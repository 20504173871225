import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useRef } from "react";
import {
  CellPropGetter,
  Column,
  FooterPropGetter,
  HeaderPropGetter,
  TableCommonProps,
  UseFiltersColumnOptions,
  useFilters,
  useTable,
} from "react-table";
import { FilterDropdown } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/FilterDropdown";
import { createIncludesFilter } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/utils";
import { AccountBalancesContext } from "src/context/CEX/Dashboard/v2/AccountBalances";
import { TableInstanceProvider } from "src/context/shared/TableInstance";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import {
  AccountBalances,
  AccountBalancesKeys,
  BALANCES_COLUMNS,
  BalancesColumnsKeys,
} from "src/state/CEX/CEXDashboard/v2/widgets/AccountBalancesStore";
import { Card, CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { setTableColumnWidth, useTableOptions } from "../../../shared/StatsTable";
import { AccountBalanceList, AccountBalanceListProps } from "./AccountBalanceList";
import { AccountBalancesTableContent } from "./AccountBalancesTableContent";
import {
  BALANCES_CELL_CLASS_NAME,
  BALANCES_HEADER_CLASS_NAME,
} from "./AccountBalancesTableContent/style";
import { BalancesFooter, BalancesFooterProps } from "./BalancesFooter";
import * as styles from "./style";
import { getAccountBalancesTotal } from "./utils/totals";
import { DashboardV2Widget, getWidgetTitle } from "../shared/constants";

const DEFAULT_COLUMN: Partial<Column<AccountBalances>> = {
  ...setTableColumnWidth(100),
  disableFilters: true,
  disableSortBy: true,
};

const DEFAULT_FILTER_COLUMN: UseFiltersColumnOptions<AccountBalances> = {
  Filter: (props) => (
    <FilterDropdown<AccountBalances> filter={styles.StyledSelectorFilter} filterProps={props} />
  ),
  filter: createIncludesFilter(),
  disableFilters: false,
};

const ACCOUNT_COLUMNS_TITLES: Record<AccountBalancesKeys, string> = {
  exchange: "Exchange",
  accountName: "Account name",
  totalUSD: "Total USD",
  baseAmount: "Base amount",
  quoteAmount: "Quote amount",
  otherAmount: "Other amount",
};

const LOCKED_COLUMNS = ["exchange", "accountName"];

const getColumnTitleById = (id: string) => ACCOUNT_COLUMNS_TITLES[id as AccountBalancesKeys];

const BALANCES_COLUMNS_SET = new Set<AccountBalancesKeys>(BALANCES_COLUMNS);

const BALANCE_CURRENCIES_TITLES: Record<BalancesColumnsKeys, string> = {
  totalUSD: "USD",
  baseAmount: "Base",
  quoteAmount: "Quote",
  otherAmount: "Other",
};

export const getCurrencyTitleById = (id: BalancesColumnsKeys) => BALANCE_CURRENCIES_TITLES[id];

const getBalancesExtraProps = (id: string, className: string): Partial<TableCommonProps> =>
  BALANCES_COLUMNS_SET.has(id as AccountBalancesKeys)
    ? {
        className,
      }
    : {};

const balancesColumnsPropsGetter: CellPropGetter<AccountBalances> = (props, meta) => {
  const extraProps = getBalancesExtraProps(meta.cell.column.id, BALANCES_CELL_CLASS_NAME);
  return [props, extraProps];
};

const balancesFootersPropsGetter: FooterPropGetter<AccountBalances> = (props, meta) => {
  const extraProps = getBalancesExtraProps(meta.column.id, BALANCES_CELL_CLASS_NAME);
  return [props, extraProps];
};

const balancesHeadersPropsGetter: HeaderPropGetter<AccountBalances> = (props, meta) => {
  const extraProps = getBalancesExtraProps(meta.column.id, BALANCES_HEADER_CLASS_NAME);
  return [props, extraProps];
};

export interface AccountBalancesTableProps extends Omit<CardProps, "title"> {}

export const AccountBalancesTable = observer((props: AccountBalancesTableProps) => {
  const { data, loading } = useLateInitContext(AccountBalancesContext.Context);

  const cardRef = useRef<HTMLDivElement>(null);

  const getBalancesCellProps = useCallback(
    (id: BalancesColumnsKeys): Omit<AccountBalanceListProps, "balances"> => {
      const title = getColumnTitleById(id);
      return {
        showEmpty: true,
        showInfo: true,
        currency: title,
        infoAnchorEl: cardRef,
      };
    },
    []
  );

  const getBalancesTotalProps = useCallback(
    (id: BalancesColumnsKeys): Omit<BalancesFooterProps, "balances"> => {
      const title = getColumnTitleById(id);
      return {
        showInfo: false,
        showEmpty: false,
        columnId: id,
        currency: title,
        infoAnchorEl: cardRef,
      };
    },
    []
  );

  const columns = useMemo(
    (): Column<AccountBalances>[] => [
      {
        Header: getColumnTitleById("exchange"),
        accessor: "exchange",
        Cell: ({ value }) => <styles.ExchangeText>{value}</styles.ExchangeText>,
        ...DEFAULT_FILTER_COLUMN,
        disableSortBy: false,
        Footer: "Total",
      },
      {
        Header: getColumnTitleById("accountName"),
        accessor: "accountName",
        Cell: ({ value }) => <styles.ExchangeText>{value}</styles.ExchangeText>,
      },
      {
        Header: getColumnTitleById("totalUSD"),
        accessor: "totalUSD",
        Cell: ({ value }) => (
          <AccountBalanceList balances={value} {...getBalancesCellProps("totalUSD")} />
        ),
        Footer: () => <BalancesFooter {...getBalancesTotalProps("totalUSD")} />,
      },

      {
        Header: getColumnTitleById("baseAmount"),
        accessor: "baseAmount",
        Cell: ({ value }) => (
          <AccountBalanceList balances={value} {...getBalancesCellProps("baseAmount")} />
        ),
        Footer: () => <BalancesFooter {...getBalancesTotalProps("totalUSD")} />,
      },
      {
        Header: getColumnTitleById("quoteAmount"),
        accessor: "quoteAmount",
        Cell: ({ value }) => (
          <AccountBalanceList balances={value} {...getBalancesCellProps("quoteAmount")} />
        ),
        Footer: () => <BalancesFooter {...getBalancesTotalProps("quoteAmount")} />,
      },
      {
        Header: getColumnTitleById("otherAmount"),
        accessor: "otherAmount",
        Cell: ({ value }) => (
          <AccountBalanceList balances={value} {...getBalancesCellProps("otherAmount")} />
        ),
        Footer: () => <BalancesFooter {...getBalancesTotalProps("otherAmount")} />,
      },
    ],
    [getBalancesCellProps, getBalancesTotalProps]
  );

  const { options, plugins } = useTableOptions({
    data,
    defaultColumn: DEFAULT_COLUMN,
    columns,
  });

  const table = useTable(options, useFilters, ...plugins);

  return (
    <TableInstanceProvider instance={table}>
      <Card
        title={getWidgetTitle(DashboardV2Widget.AccountBalances)}
        ownRef={cardRef}
        afterTitle={
          <styles.StyledColumnsFilter
            instance={table}
            getTitleById={getColumnTitleById}
            lockedColumns={LOCKED_COLUMNS}
          />
        }
        loading={loading}
        {...props}
      >
        <AccountBalancesTableContent
          extraProps={{
            cellProps: balancesColumnsPropsGetter,
            headerProps: balancesHeadersPropsGetter,
            footerProps: balancesFootersPropsGetter,
          }}
          getColumnsTotal={getAccountBalancesTotal}
        />
      </Card>
    </TableInstanceProvider>
  );
});

export interface AccountBalancesWidgetProps extends AccountBalancesTableProps {}

export const AccountBalancesWidget = (props: AccountBalancesWidgetProps) => (
  <AccountBalancesContext.Provider>
    <AccountBalancesTable {...props} />
  </AccountBalancesContext.Provider>
);
