import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { observer } from "mobx-react-lite";
import { VaultsQuotesProviderContext } from "src/context/DEXV2/DEXV2Stats/Vaults/VaultsQuotesProvider";
import { entries } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { VaultQuotesMap } from "src/state/DEXV2/DEXV2Stats/Vaults/VaultsProvider";
import { formatCurrencyAmount, formatFiatAmount } from "src/state/DEXV2/DEXV2Swap/utils";
import { TradeSide } from "src/state/DEXV2/shared/TradeToken";
import { QuotesInfo } from "../QuotesInfo";

const DATA_TIP_DELIMITER = "/";

export type VaultQuoteAddress = (string & {}) | "total";

export const getVaultQuotesDataTip = (address: VaultQuoteAddress, side: TradeSide) =>
  `${address}${DATA_TIP_DELIMITER}${side}`;

const getVaultInfoFromQuotesDataTip = (content: string) => {
  if (!content) {
    return null;
  }
  const tokens = content.split(DATA_TIP_DELIMITER);
  if (tokens.length !== 2) {
    throw new Error("Invalid quotes data tip format");
  }
  const [address, side] = tokens as [VaultQuoteAddress, TradeSide];
  return { address, side };
};

export const formatQuote = (type: "usd" | TradeSide, quote: CurrencyAmount<Token> | undefined) => {
  if (type === "usd") {
    return formatFiatAmount(quote, {
      tickerPosition: "end",
    });
  }
  return formatCurrencyAmount(quote, true);
};

const useVaultSideQuotes = (vaultsQuotes: VaultQuotesMap, dataTip: string | null) => {
  if (!dataTip) return null;

  const vaultInfo = getVaultInfoFromQuotesDataTip(dataTip);

  if (!vaultInfo) return null;

  const { address: vaultAddress, side: tradeSide } = vaultInfo;

  const tradeSideQuotes =
    vaultAddress === "total"
      ? vaultsQuotes.total?.[tradeSide]
      : vaultsQuotes.vaults[vaultAddress]?.[tradeSide];

  if (!tradeSideQuotes) return null;

  const formattedQuotes = filterBoolean(entries(tradeSideQuotes)).map(([quoteType, quote]) =>
    formatQuote(quoteType, quote)
  );

  return formattedQuotes;
};

export interface VaultsQuotesTooltipContentProps {
  dataTip: string | null;
}

export const VaultsQuotesTooltipContent = observer(
  ({ dataTip }: VaultsQuotesTooltipContentProps) => {
    const { vaultsQuotes } = useLateInitContext(VaultsQuotesProviderContext);

    const quotes = useVaultSideQuotes(vaultsQuotes, dataTip);

    const hasQuotes = quotes && quotes.length > 0;

    return <>{hasQuotes && <QuotesInfo items={quotes} />}</>;
  }
);
