import { makeAutoObservable } from "mobx";
import { formatElapsedTime } from "src/helpers/dateUtils";
import { Comparators } from "src/helpers/sorting";
import { RequestRecentParty } from "src/modules/bots";

export interface RecentParty {
  createdAt: number;
  daysPassed: string;
  name: string;
}

export interface SelectableRecentParty extends RecentParty {
  selected: boolean;
}

export interface IParty {
  party: string;
}

export interface IRecentPartiesFilter {
  setRecentParties(parties: RequestRecentParty[]): void;
  togglePartySelection(name: string): void;
  get recentParties(): SelectableRecentParty[];
  filter({ party }: IParty): boolean;
}

export default class RecentPartiesFilterStore implements IRecentPartiesFilter {
  private _recentParties: RecentParty[] = [];

  private _selectedRecentParties = new Set<string>();

  constructor() {
    makeAutoObservable(this);
  }

  private _setRecentParties = (parties: RecentParty[]) => {
    this._recentParties = parties;
  };

  private _toRecentParty = ({ party, created_at }: RequestRecentParty): RecentParty => {
    const daysAfterCreated = formatElapsedTime(created_at);
    return {
      name: party,
      createdAt: created_at,
      daysPassed: daysAfterCreated,
    };
  };

  private _toRecentParties = (parties: RequestRecentParty[]): RecentParty[] =>
    parties.map((party) => this._toRecentParty(party));

  private _syncSelectedRecentParties = (parties: RecentParty[]) => {
    const partiesSet = new Set(parties.map(({ name }) => name));
    this._selectedRecentParties.forEach((selectedParty) => {
      if (!partiesSet.has(selectedParty)) {
        this._selectedRecentParties.delete(selectedParty);
      }
    });
  };

  setRecentParties = (parties: RequestRecentParty[]) => {
    const recentParties = this._toRecentParties(parties);

    this._syncSelectedRecentParties(recentParties);

    this._setRecentParties(recentParties);
  };

  togglePartySelection = (name: string) => {
    const isSelected = this._selectedRecentParties.has(name);
    if (isSelected) {
      this._selectedRecentParties.delete(name);
    } else {
      this._selectedRecentParties.add(name);
    }
  };

  private get _recentSelectableParties(): SelectableRecentParty[] {
    return this._recentParties.map((party) => {
      const selected = this._selectedRecentParties.has(party.name);
      return { ...party, selected };
    });
  }

  get recentParties() {
    return this._recentSelectableParties
      .sort((a, b) => Comparators.Number(b.createdAt, a.createdAt))
      .sort((a, b) => Comparators.Boolean.reverse(a.selected, b.selected));
  }

  filter = ({ party }: IParty) =>
    this._selectedRecentParties.size === 0 || this._selectedRecentParties.has(party);
}
