import { ReactElement, cloneElement, forwardRef } from "react";
import { OwnRefProps } from "src/helpers/utils";

interface BaseButtonProps extends OwnRefProps<"button"> {}

export interface RefButtonWrapperProps {
  children: ReactElement<BaseButtonProps>;
}

export const RefButtonWrapper = forwardRef<HTMLButtonElement, RefButtonWrapperProps>(
  ({ children, ...rest }, ref) => {
    const childWithRef = cloneElement(children, { ownRef: ref, ...rest });

    return <>{childWithRef}</>;
  }
);
