import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { SeparatorNum } from "src/components/shared/Separator";
import styled from "styled-components";
import { TablePanel } from "../TablePanel";
import { TableContent } from "../TablePanel/TableContent";
import { Header } from "../TablePanel/TablePanelHeader/style";

export const VaultsPanel = styled(TablePanel)`
  grid-area: vaults;

  ${Header} {
    align-items: center;
  }
`;

export const VaultsFallback = styled(CrashPanel)`
  grid-area: vaults;
`;

export const VaultsTable = styled(TableContent)`
  grid-template-columns:
    minmax(150px, 1.65fr) minmax(50px, 1fr)
    minmax(50px, 1.65fr)
    minmax(50px, 1.65fr)
    90px;
`;

export const ActionWrapper = styled.div`
  display: flex;

  gap: 3px;
`;

export const BalancesCell = styled(SeparatorNum)`
  width: 100%;
  height: 100%;
  display: block;
`;
