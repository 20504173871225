import { AreaData } from "lightweight-charts";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { SeriesProps } from "src/components/shared/Graph/types";
import { TokensBalancesContext } from "src/context/CEX/Dashboard/v2/TokensBalances";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { TokenBalanceData } from "src/state/CEX/CEXDashboard/v2/widgets/TokensBalancesV2Store";
import { CardOwnProps, CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { BaseBalanceChart } from "../shared/BaseBalanceChart";
import { TokensSelector } from "./TokensSelector";

interface UseSeriesProps {
  data: TokenBalanceData;
}

const useSeriesData = ({ data: { ticker, data } }: UseSeriesProps): SeriesProps[] => {
  const seriesProps = useMemo((): SeriesProps[] => {
    const series: SeriesProps[] = [
      {
        type: "Area",
        data: data as AreaData[],
        title: ticker,
        options: {
          lineWidth: 1,
        },
      },
    ];
    return series;
  }, [data, ticker]);

  return seriesProps;
};

export interface TokensBalancesChartProps extends Omit<CardProps, keyof CardOwnProps> {}

export const TokensBalancesChart = observer((props: TokensBalancesChartProps) => {
  const { data, onRangeChange, tokens, loading, currentToken, setCurrentToken } =
    useLateInitContext(TokensBalancesContext.Context);

  const seriesProps = useSeriesData({ data });

  return (
    <BaseBalanceChart
      title={`Total ${currentToken} balance`}
      loading={loading}
      afterTitle={
        <TokensSelector value={currentToken} options={tokens} onChange={setCurrentToken} />
      }
      graphProps={{ series: seriesProps, request: onRangeChange }}
      {...props}
    />
  );
});

export interface TokensBalancesWidgetProps extends TokensBalancesChartProps {}

export const TokensBalancesWidget = (props: TokensBalancesWidgetProps) => (
  <TokensBalancesContext.Provider>
    <TokensBalancesChart {...props} />
  </TokensBalancesContext.Provider>
);
