import { Token, TradeType } from "@uniswap/sdk-core";
import { InsufficientInputAmountError, InsufficientReservesError } from "@uniswap/v2-sdk";
import { LogLevel, logDev } from "src/helpers/network/logger";
import { CacheOptions, validatePath } from "../../../utils";
import { V2SwapRoute, V2SwapRouteError } from "../../v2/Swap/Providers/V2RouteStateProvider";
import { V3SwapRoute, V3SwapRouteError } from "../../v3/Swap/Providers/V3RouteStateProvider";
import { QuotesError } from "../../v3/Swap/error/Error";
import { TokenAmount } from "./entities/TokenAmount";

export type SwapRouteError = V2SwapRouteError | V3SwapRouteError;

export enum SwapRouteState {
  Valid,
  Invalid,
}

export type SwapRoute = V2SwapRoute | V3SwapRoute;

export type ValidSwapRoute = SwapRoute & {
  state: SwapRouteState.Valid;
};

export type InvalidSwapRoute = {
  state: SwapRouteState.Invalid;

  error?: SwapRouteError;
};

export type RouteResponse = ValidSwapRoute | InvalidSwapRoute;

type SwapRouteStateValues<State extends SwapRouteState> = Omit<
  Extract<RouteResponse, { state: State }>,
  "state"
>;

const createSwapRouteResponse = <State extends SwapRouteState>(
  state: State,
  values: SwapRouteStateValues<State>
): RouteResponse =>
  ({
    state,
    ...values,
  }) as RouteResponse;

export interface IRouteOptions extends CacheOptions {}

export interface IRouteParams {
  amount: TokenAmount;
  swapType: TradeType;
  path: Token[];
  pools: string[];
  options?: IRouteOptions;
}

export interface IRouter {
  route: (params: IRouteParams) => Promise<RouteResponse>;
}

export interface IGetRouteStateParams extends IRouteParams {}

export interface IRouteStateProvider {
  getRouteState: (params: IGetRouteStateParams) => Promise<SwapRoute | null>;
}

export interface IRouterParams {
  routeStateProvider: IRouteStateProvider;
}

export class Router implements IRouter {
  private _routeProvider: IRouteStateProvider;

  constructor({ routeStateProvider }: IRouterParams) {
    this._routeProvider = routeStateProvider;
  }

  private _handleRouteError = (err: unknown) => {
    if (err instanceof InsufficientInputAmountError) {
      return createSwapRouteResponse(SwapRouteState.Invalid, {
        error: V2SwapRouteError.InsufficientInputAmount,
      });
    }
    if (err instanceof InsufficientReservesError) {
      return createSwapRouteResponse(SwapRouteState.Invalid, {
        error: V2SwapRouteError.InsufficientReserves,
      });
    }
    if (err instanceof QuotesError) {
      return createSwapRouteResponse(SwapRouteState.Invalid, {
        error: V3SwapRouteError.QuoteError,
      });
    }
    throw err;
  };

  route = async (params: IRouteParams): Promise<RouteResponse> => {
    const { amount, swapType, path, pools } = params;

    validatePath(path);
    logDev(["Router route: ", amount, swapType, path, pools]);

    try {
      const route = await this._routeProvider.getRouteState(params);

      if (!route) return createSwapRouteResponse(SwapRouteState.Invalid, {});

      return createSwapRouteResponse(SwapRouteState.Valid, route);
    } catch (err) {
      logDev(["Router route error: ", err], { level: LogLevel.Error });
      return this._handleRouteError(err);
    }
  };
}
