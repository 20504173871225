import { IPriceLine, ISeriesApi, PriceLineOptions, SeriesType } from "lightweight-charts";
import { useCallback, useMemo, useRef } from "react";
import { Nullish, SetRequired, Simplify, isDefined } from "src/helpers/utils";
import { SeriesRootBaseProps } from "../types";

export type PriceLineMap = Map<string, IPriceLine>;

export type DefaultizedPriceLinesOptions = Simplify<
  SetRequired<Partial<PriceLineOptions>, "price" | "id">
>;

type UsePriceLinesParams = Required<Pick<SeriesRootBaseProps<SeriesType>, "priceLines">>;

/**
 * Custom hook for managing price lines for a chart series.
 * @returns A tuple containing functions to update and clear price lines state.
 */
export const usePriceLines = ({ priceLines }: UsePriceLinesParams) => {
  const priceLinesMapRef = useRef<PriceLineMap>(new Map());

  const defaultizedPriceLines = useMemo(
    (): DefaultizedPriceLinesOptions[] =>
      priceLines.map(
        ({ id, price, lineVisible = true, axisLabelVisible = true, ...options }, index) => {
          const priceLineId = id ?? `price-line-${index}`;
          const isDefinedPrice = isDefined(price);
          const defaultPrice = isDefinedPrice ? price : 0;
          const defaultLineVisible = isDefinedPrice ? lineVisible : false;
          const defaultAxisLabelVisible = isDefinedPrice ? axisLabelVisible : false;
          return {
            id: priceLineId,
            price: defaultPrice,
            lineVisible: defaultLineVisible,
            axisLabelVisible: defaultAxisLabelVisible,
            ...options,
          };
        }
      ),
    [priceLines]
  );

  const removePriceLine = useCallback(
    (priceLine: IPriceLine, series: Nullish<ISeriesApi<SeriesType>>) => {
      series?.removePriceLine(priceLine);
    },
    []
  );

  const removePriceLineById = useCallback(
    (id: string, series: Nullish<ISeriesApi<SeriesType>>) => {
      const priceLinesMap = priceLinesMapRef.current;

      const priceLine = priceLinesMap.get(id);

      if (priceLine) {
        removePriceLine(priceLine, series);
        priceLinesMap.delete(id);
      }
    },
    [removePriceLine]
  );

  const clearPriceLines = useCallback(
    (series: Nullish<ISeriesApi<SeriesType>>) => {
      const priceLinesMap = priceLinesMapRef.current;

      priceLinesMap.forEach((priceLine) => {
        removePriceLine(priceLine, series);
      });

      priceLinesMap.clear();
    },
    [removePriceLine]
  );

  const updatePriceLines = useCallback(
    (series: Nullish<ISeriesApi<SeriesType>>) => {
      if (!series) return;

      const priceLinesMap = priceLinesMapRef.current;

      const priceLinesIds = new Set(defaultizedPriceLines.map(({ id }) => id));

      defaultizedPriceLines.forEach((options) => {
        const priceLineId = options.id;
        const cachedPriceLine = priceLinesMap.get(priceLineId);
        if (cachedPriceLine) {
          cachedPriceLine.applyOptions(options);
        } else {
          const priceLine = series.createPriceLine(options);
          priceLinesMap.set(priceLineId, priceLine);
        }
      });

      priceLinesMap.forEach((_priceLine, priceLineId) => {
        if (!priceLinesIds.has(priceLineId)) {
          removePriceLineById(priceLineId, series);
        }
      });
    },
    [defaultizedPriceLines, removePriceLineById]
  );

  return {
    clearPriceLines,
    updatePriceLines,
  };
};
