import { observer } from "mobx-react-lite";
import { useWidgetsState } from "../../../../shared/hooks/useWidgetsState";
import { InfoPanelSection } from "./InfoPanelSection";
import * as styles from "./style";

export interface ProfitLossInfoPanelProps {}

type PNLInfoData = { title: string; value: number | null };

type UsePnlInfoDataResponse = {
  allTime: PNLInfoData;
  monthly: PNLInfoData;
  current: PNLInfoData;
};

export const usePNLInfoData = (): UsePnlInfoDataResponse => {
  const { allTime, previousMonth, previousMonthName, monthName, month } = useWidgetsState("pnl");

  const currentPNLTitle = `${monthName || "Current"} Pnl:`;
  const monthlyPNLTitle = `${previousMonthName || "Monthly"} Pnl:`;

  return {
    allTime: { title: "Period PnL:", value: allTime },
    monthly: { title: monthlyPNLTitle, value: previousMonth },
    current: { title: currentPNLTitle, value: month },
  };
};

export const ProfitLossInfoPanel = observer((props: ProfitLossInfoPanelProps) => {
  const { allTime, monthly, current } = usePNLInfoData();
  return (
    <styles.Container {...props}>
      <InfoPanelSection {...allTime} />
      <InfoPanelSection {...current} />
      <InfoPanelSection {...monthly} />
    </styles.Container>
  );
});
