import { observer } from "mobx-react-lite";
import { DashboardFundingContext } from "src/context/CEX/Dashboard/Funding";
import { BalancesDoughnut, BalancesDoughnutProps } from "../../../widgets/v1/BalancesDoughnut";
import { FundingTable } from "../../../widgets/v1/FundingTable";
import { LiquidityCharts } from "../../../widgets/v1/LiquidityCharts";
import { LiquidityDoughnut, LiquidityDoughnutProps } from "../../../widgets/v1/LiquidityDoughnut";
import * as styles from "./style";

const BASE_DOUGHNUT_PROPS: Omit<BalancesDoughnutProps["data"]["datasets"][0], "data"> = {
  backgroundColor: ["#00796B", "#78909C"],
  hoverOffset: 15,
  borderWidth: 0,
};

const BALANCES_DATA: BalancesDoughnutProps["data"] = {
  labels: ["Free", "Locked"],
  datasets: [
    {
      data: [80, 20],
      ...BASE_DOUGHNUT_PROPS,
    },
  ],
};

const LIQUIDITY_DATA: LiquidityDoughnutProps["data"] = {
  labels: ["USDC  ", "USDT"],
  datasets: [
    {
      data: [95, 5],
      ...BASE_DOUGHNUT_PROPS,
    },
  ],
};

export interface DetailedViewProps {}

export const DetailedView = observer((props: DetailedViewProps) => (
  <styles.Container>
    <BalancesDoughnut data={BALANCES_DATA} style={{ width: "150px", height: "120px" }} />
    <LiquidityDoughnut data={LIQUIDITY_DATA} style={{ width: "150px", height: "120px" }} />
    <LiquidityCharts />

    <styles.Spacer />
    <DashboardFundingContext.Provider>
      <FundingTable style={{ maxWidth: "600px", minWidth: "auto", height: "250px" }} />
    </DashboardFundingContext.Provider>
    <styles.Spacer />

    <styles.Spacer />
  </styles.Container>
));
