import { Container as BaseContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled(BaseContainer)`
  align-items: center;
  gap: 16px;

  padding-top: 15px;

  flex: 1 1 auto;
`;
