import { ComponentPropsWithoutRef } from "react";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { ModuleHeader, ModuleHeaderProps } from "../ModuleHeader";

export interface SubModuleShellProps extends ComponentPropsWithoutRef<"div"> {
  headerProps: ModuleHeaderProps;
}

export const SubModuleShell = ({ headerProps, children }: SubModuleShellProps) => (
  <RowWrapper>
    <ModuleHeader {...headerProps} />

    {children}
  </RowWrapper>
);
