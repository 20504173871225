import { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { getUTCDateTimeInputValue } from "src/helpers/dateUtils";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import { IStrategyCreator, STRATEGY_EXECUTION_ORDER_TYPES } from "src/modules/expertSystem";
import {
  ERRORS_MSGS,
  hookFormIsFutureDate,
  isInteger,
  wrapHookFormValidation,
} from "src/validation-schemas";
import { DateTimeInputPrompt } from "../../../../shared";
import { LaunchesLimitInput } from "../../../shared/LaunchesLimitInput";
import * as styles from "./style";

export const SettingsStep = () => {
  const { control } = useFormContext<IStrategyCreator>();

  const executionOptions = useMemo(() => getSelectorList(STRATEGY_EXECUTION_ORDER_TYPES), []);

  return (
    <styles.Container>
      <styles.Wrapper>
        <Controller
          control={control}
          name="name"
          rules={{ required: ERRORS_MSGS.isRequired }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <LabeledInput
              label="Name"
              value={value}
              onChange={(e) => onChange(getChangeEventValue(e))}
              errorHint={errors.name?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="execution_order"
          rules={{ required: ERRORS_MSGS.isRequired }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <LabeledSelector
              label="Execution order"
              options={executionOptions}
              onChange={(data) => onChange(data?.value)}
              value={stringToSelectorValue(value)}
              errorHint={errors.execution_order?.message}
            />
          )}
        />
      </styles.Wrapper>

      <Controller
        control={control}
        name="expired_at"
        rules={{
          required: ERRORS_MSGS.isRequired,
          validate: hookFormIsFutureDate(),
        }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <LabeledInput
            label="Expired at"
            after={<DateTimeInputPrompt />}
            type="datetime-local"
            placeholder="YYYY-MM-DDThh:mm"
            value={getUTCDateTimeInputValue(value)}
            onChange={(e) => onChange(getChangeEventValue(e))}
            errorHint={errors.expired_at?.message}
          />
        )}
      />

      <styles.Wrapper>
        <LaunchesLimitInput control={control} name="launches_limit" label="Launches total" />

        <Controller
          control={control}
          name="cooldown"
          rules={{
            required: ERRORS_MSGS.isRequired,
            validate: wrapHookFormValidation(isInteger()),
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <LabeledInput
              label="Cooldown"
              type="number"
              value={value}
              onChange={(e) => onChange(getChangeEventValue(e))}
              errorHint={errors.cooldown?.message}
            />
          )}
        />
      </styles.Wrapper>
    </styles.Container>
  );
};
