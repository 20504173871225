import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { LiquidityTabContext } from "src/context/CEX/LiquidityTab/LiquidityTabProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionProps } from "src/permissions/PermissionWrapper";
import { BotInfoProps } from "..";
import ActiveGridView from "./ActiveGridView";
import CreateGridView from "./CreateGridView";
import * as styles from "./styles";

export interface LiquidityTabProps extends Pick<PermissionProps, "abilityName">, BotInfoProps {}

export const LiquidityTab = observer((tabProps: LiquidityTabProps) => {
  const { bot_uuid, market } = tabProps.botInfo;
  const state = useLateInitContext(LiquidityTabContext);

  useEffect(() => {
    if (!bot_uuid || !market) return;
    state.setBotParams({ market, bot_uuid });
    state.loadData();
  }, [state, bot_uuid, market]);

  return (
    <styles.Container>
      {state.settings ? <ActiveGridView {...tabProps} /> : <CreateGridView {...tabProps} />}
      <Loader show={state.loading} />
    </styles.Container>
  );
});
