import { CardSurface } from "src/components/shared/shared";
import { Headline3 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Content = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 20px 23px 15px;

  ${CardSurface}
`;

export const Title = styled(Headline3)`
  margin-bottom: 15px;
`;
