import styled, { css } from "styled-components";
import { SettingsFormContent } from "../shared/SettingsFormContent";
import { Content } from "../shared/SettingsPanel/style";
import { BlacklistPanel as BaseBlacklistPanel } from "./BlacklistPanel";
import { CounterPanel as BaseCounterPanel } from "./CounterPanel";
import { TradePanel as BaseTradePanel } from "./TradePanel";

interface CounterPanelProps {
  $isMobile?: boolean;
}

export const CounterPanel = styled(BaseCounterPanel)<CounterPanelProps>`
  max-width: 100%;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      ${Content} {
        height: 150px;
      }
    `}
`;

export const BlacklistPanel = styled(BaseBlacklistPanel)`
  flex-basis: 50%;
`;

export const CounterPanelGroup = styled(SettingsFormContent)`
  padding: 0;
`;

export const TradePanel = styled(BaseTradePanel)`
  flex-basis: 50%;
`;
