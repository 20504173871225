import {
  ComponentPropsWithoutRef,
  FormEventHandler,
  KeyboardEventHandler,
  useCallback,
} from "react";
import { Loader } from "src/components/shared/Loader";
import { ChildrenProps } from "src/helpers/utils";
import { SettingsFormContent, SettingsFormContentProps } from "../SettingsFormContent";
import * as styles from "./style";

export interface SettingsFormOwnProps
  extends ChildrenProps,
    Partial<Pick<SettingsFormContentProps, "orientation">> {
  loader?: boolean;
}

export interface SettingsFormProps
  extends SettingsFormOwnProps,
    Omit<ComponentPropsWithoutRef<"form">, "onKeyPress"> {
  disableSubmit?: boolean;
}

export const SettingsForm = ({
  children,
  loader,
  orientation = "row",
  disableSubmit,
  onSubmit: outerOnSubmit,
  ...props
}: SettingsFormProps) => {
  const onPreventEnter: KeyboardEventHandler<HTMLFormElement> = useCallback(
    (event) => event.key === "Enter" && event.preventDefault(),
    []
  );

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      if (disableSubmit || !outerOnSubmit) {
        e.preventDefault();
        return;
      }
      outerOnSubmit(e);
    },
    [disableSubmit, outerOnSubmit]
  );

  return (
    <styles.SettingsForm onKeyPress={onPreventEnter} onSubmit={onSubmit} {...props}>
      <SettingsFormContent orientation={orientation}>{children}</SettingsFormContent>
      <Loader show={loader} />
    </styles.SettingsForm>
  );
};
