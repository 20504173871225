import { BaseButton } from "src/components/shared/Buttons/v2/shared/style";
import styled, { DefaultTheme } from "styled-components";

const getBackgroundColor = (theme: DefaultTheme) => {
  const { mode, primaryMode } = theme;
  switch (primaryMode) {
    case "blue": {
      return mode === "light" ? "#e8f0fc" : "#a3b7d3";
    }
    case "orange": {
      return mode === "light" ? "#ffdbc0" : "#8d3c00";
    }
  }
};

export const SwapButton = styled(BaseButton)`
  position: absolute;
  z-index: 100;

  left: 50%;
  bottom: 0;
  transform: translate(-50%, 59%);

  width: 65px;
  height: 65px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${({ theme }) => theme.radii["50%"]};
  border: solid 2px ${({ theme }) => theme.contentBackgroundColor};
  background: ${({ theme }) => getBackgroundColor(theme)};
  color: ${({ theme }) => theme.primaryColor};
`;
