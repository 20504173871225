import { ComponentPropsWithoutRef } from "react";
import { Loader } from "src/components/shared/Loader";
import { ElementRef } from "src/helpers/utils";
import { Header } from "./Header";
import * as styles from "./style";

export interface CardOwnProps {
  title?: string;
  afterTitle?: JSX.Element;
  after?: JSX.Element;
  before?: JSX.Element;
  info?: string;
  loading?: boolean;
}

export interface CardProps extends CardOwnProps, ComponentPropsWithoutRef<"div"> {
  ownRef?: ElementRef<"div">;
}

export const Card = ({
  children,
  title,
  afterTitle,
  after,
  before,
  info,
  loading = false,
  ownRef,
  ...props
}: CardProps) => {
  const headerProps = { title, afterTitle, info };
  return (
    <styles.CardContainer ref={ownRef} {...props}>
      {before}
      <styles.MainContentWrapper>
        <Header {...headerProps} />
        <styles.Content>{children}</styles.Content>
      </styles.MainContentWrapper>
      {after}
      <Loader show={loading} />
    </styles.CardContainer>
  );
};
