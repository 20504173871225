import useLocalStore from "src/hooks/useLocalStore";
import BindingsBotStore from "src/state/CEX/CEXSettings/bindingsBots";
import { createContextProvider } from "../utils/createContextProvider";

const CreateBindingsBotStore = () => useLocalStore(BindingsBotStore);

const { Context: BindingsBotContext, Provider: BindingsBotProvider } =
  createContextProvider<BindingsBotStore>({
    stateCreator: CreateBindingsBotStore,
  });

export { BindingsBotContext, BindingsBotProvider };
