import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { SelectPanel } from "src/components/shared/Forms/Selectors/SelectionPanel/SelectPanel";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import AddRoleStore from "src/state/UserManager/AddRoleStore";
import * as styles from "../../../shared";

export const AddRole = observer(() => {
  const state = useLateInitContext(UserManagerContext);

  const roleState = useMemo(() => new AddRoleStore(), []);

  // form reset on panel open/close
  useEffect(() => {
    roleState.resetForm();
    roleState.setUpdateList(state.getRolesList);
  }, [roleState, state.visiblePanel.showAddRolesPanel, state.getRolesList]);

  return (
    <ModalPanel
      label="Add role"
      show={state.visiblePanel.showAddRolesPanel}
      close={state.openPanel("showAddRolesPanel")}
      loading={roleState.isLoadingRole}
    >
      <styles.Container>
        <styles.AccessForm
          onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
          onSubmit={roleState.submitHendler()}
        >
          <styles.Wrapper>
            <styles.Column>
              <LabeledInput
                label="Role name"
                value={roleState.newRole.name}
                onChange={roleState.getHandler()}
                errorHint={roleState.nameErr}
              />{" "}
            </styles.Column>

            <styles.Column>
              <styles.Column>
                <styles.InputWrapper>
                  <LabeledSelector
                    label="Abilities"
                    isMulti
                    value={roleState.selectAbilities}
                    options={state.selectAbilities}
                    onChange={roleState.setAbilitiesHandler()}
                    errorHint={roleState.abilitiesErr}
                  />

                  <DeleteButton onClick={roleState.clearAbilities} />
                </styles.InputWrapper>

                <SelectPanel
                  selectItems={roleState.selectAbilities}
                  removeClick={roleState.removeAbility}
                />
              </styles.Column>

              <OutlineButton type="submit" style={{ marginTop: "43px" }}>
                Create role +
              </OutlineButton>
            </styles.Column>
          </styles.Wrapper>
        </styles.AccessForm>
      </styles.Container>
    </ModalPanel>
  );
});
