import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  min-height: 0%;
  max-height: 116px;
  overflow-y: auto;

  display: flex;

  ${ScrollSize};
`;

export const HashesList = styled.ul`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 9px;

  margin: 0;
  padding: 0;
  padding-right: 3px;

  list-style: none;
`;
