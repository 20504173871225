import { KeyboardEvent } from "react";
import styled from "styled-components";

export const FormContent = styled.form.attrs(() => ({
  onKeyDown: (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") event.preventDefault();
  },
}))`
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
  flex: 1 1 0;
`;

export const Footer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  gap: 32px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 32px;
`;
