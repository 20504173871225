import { Indicator, Indicators, IndicatorsKeys } from "src/state/MarketCheck/types";

const INDICATORS_LABEL: Record<IndicatorsKeys, string> = {
  bots_with_errors: "Bots with errors",
  grinder_with_errors: "Grinder with errors",
  volume_not_target: "Volume target",
  low_liquidity: "Low liquidity levels",
  to_be_done: "To be done",
};

export const useGetIndicatorsList = (actualIndicators: Indicators) => {
  const allKeys = Object.keys(INDICATORS_LABEL) as IndicatorsKeys[];
  const actualKeys = allKeys.filter((indicator) => actualIndicators[indicator]);
  const indicatorsFullList = actualKeys.map((key) => ({
    key,
    label: INDICATORS_LABEL[key],
    metaData: (actualIndicators[key] as Indicator).meta,
    status: (actualIndicators[key] as Indicator).state,
  }));

  return indicatorsFullList;
};
