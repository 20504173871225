import { observer } from "mobx-react-lite";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Loader } from "src/components/shared/Loader";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

export const VolumeForm = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);

  return (
    <styles.Form
      onSubmit={mainState.volumeState.submitHandler()}
      onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
      disabled={!mainState.isOriginPair}
    >
      <styles.Content>
        <styles.Wrapper>
          <LabeledSelector
            label="Account"
            isDisabled={!mainState.isOriginPair}
            options={mainState.volumeState.accounts}
            onChange={mainState.volumeState.selectorHandler("account_uuid")}
            value={mainState.volumeState.currentNameAcc}
            // @ts-ignore
            getError={mainState.volumeState.getError("account_uuid")}
            errorHint={mainState.volumeState.errors.account_uuid}
          />
          <LabeledInput
            readOnly={!mainState.isOriginPair}
            type="number"
            step="any"
            label="Number"
            value={mainState.volumeState.data.number}
            onChange={mainState.volumeState.getHandler("number")}
            // @ts-ignore
            getError={mainState.volumeState.getError("number")}
            errorHint={mainState.volumeState.errors.number}
          />
        </styles.Wrapper>
        <styles.Wrapper>
          <LabeledInput
            readOnly={!mainState.isOriginPair}
            type="number"
            step="any"
            label="Quote max"
            value={mainState.volumeState.data.quoteMax}
            onChange={mainState.volumeState.getHandler("quoteMax")}
            // @ts-ignore
            getError={mainState.volumeState.getError("quoteMax")}
            errorHint={mainState.volumeState.errors.quoteMax}
          />
          <LabeledInput
            readOnly={!mainState.isOriginPair}
            type="number"
            step="any"
            label="Quote min"
            value={mainState.volumeState.data.quoteMin}
            onChange={mainState.volumeState.getHandler("quoteMin")}
            // @ts-ignore
            getError={mainState.volumeState.getError("quoteMin")}
            errorHint={mainState.volumeState.errors.quoteMin}
          />
        </styles.Wrapper>
        <styles.Wrapper>
          <LabeledInput
            readOnly={!mainState.isOriginPair}
            type="number"
            step="any"
            label="Price max"
            value={mainState.volumeState.data.priceMax}
            onChange={mainState.volumeState.getHandler("priceMax")}
            // @ts-ignore
            getError={mainState.volumeState.getError("priceMax")}
            errorHint={mainState.volumeState.errors.priceMax}
          />
          <LabeledInput
            readOnly={!mainState.isOriginPair}
            type="number"
            step="any"
            label="Price min"
            value={mainState.volumeState.data.priceMin}
            onChange={mainState.volumeState.getHandler("priceMin")}
            // @ts-ignore
            getError={mainState.volumeState.getError("priceMin")}
            errorHint={mainState.volumeState.errors.priceMin}
          />
        </styles.Wrapper>
      </styles.Content>

      <Loader show={mainState.volumeState.showLoader} />

      <styles.Footer>
        <CheckBox
          disabled={!mainState.isOriginPair}
          label="Do sell before buy"
          checked={mainState.volumeState.data.sellFirst}
          onChange={mainState.volumeState.getHandler("sellFirst")}
        />
        <SubmitButton disabled={!mainState.isOriginPair} size="m" />
      </styles.Footer>
    </styles.Form>
  );
});
