import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { DEXV2SettingsProvider } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { DEXV2SwapProvider } from "src/context/DEXV2/DEXV2Swap/DEXV2Swap";
import { SwapModulesProvider } from "src/context/DEXV2/DEXV2Swap/Modules/SwapModules";
import { SwapVaultsProvider } from "src/context/DEXV2/DEXV2Swap/Vaults/SwapVaults";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useQueryParams } from "src/hooks/useQueryParams";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { isDEXV2ContentTab } from "src/components/Header/SideMenu/Content/BotNavigation/MenuItems/DEXV2MenuItems";
import { Settings } from "./Settings";
import { DEXStats } from "./Stats";
import { Swap } from "./Swap";
import { DEXV2ContentPermission } from "./shared/Permissions/DEXV2ContentPermission";
import * as styles from "./style";

export interface DEXV2QueryParams {
  uuid: string;
}

const useContentTab = () => {
  const queryParams = useQueryParams();
  const urlTab = queryParams.get("tab") ?? "";

  const currentTab = isDEXV2ContentTab(urlTab) ? urlTab : "";
  return currentTab;
};

export const DEXV2Content = observer(() => {
  const { uuid } = useParams<DEXV2QueryParams>();

  const state = useLateInitContext(DEXV2BotContext);
  const { isLoading } = state;

  const currentTab = useContentTab();

  useEffect(() => {
    state.setBotUUID(uuid);
    state.loadBot();
  }, [state, uuid]);

  const getContent = () => {
    switch (currentTab) {
      case "SETTINGS": {
        return (
          <DEXV2ContentPermission ability={DEXV2Ability.SettingsView} loading={isLoading}>
            <DEXV2SettingsProvider>
              <ErrorBoundary>
                <Settings bot_uuid={uuid} />
              </ErrorBoundary>
            </DEXV2SettingsProvider>
          </DEXV2ContentPermission>
        );
      }

      case "SWAP": {
        return (
          <DEXV2ContentPermission ability={DEXV2Ability.ExchangeView} loading={isLoading}>
            <DEXV2SwapProvider>
              <SwapVaultsProvider>
                <SwapModulesProvider>
                  <ErrorBoundary>
                    <Swap />
                  </ErrorBoundary>
                </SwapModulesProvider>
              </SwapVaultsProvider>
            </DEXV2SwapProvider>
          </DEXV2ContentPermission>
        );
      }

      case "STATS":
      case "": {
        return (
          <DEXV2ContentPermission ability={DEXV2Ability.StatsView} loading={isLoading}>
            <ErrorBoundary>
              <DEXStats />
            </ErrorBoundary>
          </DEXV2ContentPermission>
        );
      }
    }
  };

  return <styles.Content>{getContent()}</styles.Content>;
});
