import { Graph } from "src/components/shared/Graph";
import { GraphRootStyles } from "src/components/shared/Graph/style";
import styled from "styled-components";
import { Card } from "src/components/BotsContent/CEX/shared/Card";
import { Content } from "src/components/BotsContent/CEX/shared/Card/style";

export const GraphCard = styled(Card)`
  ${Content} {
    padding: 8px;
  }
`;

export const StyledGraph = styled(Graph)`
  ${GraphRootStyles.GraphContainer} {
    height: 0;
  }
`;
