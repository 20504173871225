import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { Tooltip } from "react-tooltip";
import { Loader } from "src/components/shared/Loader";
import { SeparatorNum } from "src/components/shared/Separator";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { separation } from "src/helpers/separation";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Balance } from "src/modules/exchange/balance";
import * as styles from "./style";

interface Props {
  data: Balance[];
  accountState: string;
  loader: boolean;
}

export const BalanceTable = observer(({ data = [], loader, accountState, ...props }: Props) => {
  const exchangeState = useLateInitContext(ExchangeContext);

  return (
    <styles.ScrollContainer {...props}>
      <styles.BalancesTable>
        <styles.Head>
          <styles.HeadRow>
            <styles.QuoteHeader>Quote</styles.QuoteHeader>
            <styles.Header>Free</styles.Header>
            <styles.Header>Locked</styles.Header>
          </styles.HeadRow>
        </styles.Head>
        <styles.Body>
          {!data.length ? (
            <styles.InfoRow>
              <styles.Cell>{accountState}</styles.Cell>
            </styles.InfoRow>
          ) : null}

          {data.map(({ asset, free, locked }) => {
            const id = nanoid();

            const checkHighlight = exchangeState.base === asset || exchangeState.quote === asset;

            const showTotal = exchangeState.base === asset && !!exchangeState.lastTrade;

            return (
              <styles.Row key={asset}>
                <styles.QuoteCell highlight={checkHighlight}>{asset}</styles.QuoteCell>

                <styles.Cell
                  highlight={checkHighlight}
                  showTotal={showTotal}
                  data-tooltip-content={separation(
                    exchangeState.convertBase(
                      free,
                      exchangeState.lastTrade ? +exchangeState.lastTrade.price : 0
                    )
                  )}
                  data-tooltip-id={id}
                >
                  <SeparatorNum value={free} />

                  {exchangeState.base === asset && exchangeState.lastTrade ? (
                    <Tooltip style={{ fontSize: "12px" }} float id={id} variant="info" />
                  ) : null}
                </styles.Cell>

                <styles.Cell
                  highlight={checkHighlight}
                  showTotal={showTotal}
                  data-tooltip-content={separation(
                    exchangeState.convertBase(
                      locked,
                      exchangeState.lastTrade ? +exchangeState.lastTrade.price : 0
                    )
                  )}
                  data-tooltip-id={id}
                  // data-iscapture="true"
                >
                  <SeparatorNum value={locked} />
                </styles.Cell>
              </styles.Row>
            );
          })}
        </styles.Body>
      </styles.BalancesTable>

      <Loader show={loader} />
    </styles.ScrollContainer>
  );
});
