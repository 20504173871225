import styled from "styled-components";

export const AccForm = styled.form`
  width: 100%;
  height: 345px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-of-type {
    margin-right: 20px;
  }
`;
