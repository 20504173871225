import { Headline2 } from "src/components/shared/Typography";
import { Container } from "../shared";
import { DecimalSettings } from "./DecimalSettings";
import { ExchangeSettings } from "./ExchangeSettings";
import { NamePanel } from "./NamePanel";
import { PairSettings } from "./PairSettings";
import { PeriodSettings } from "./PeriodSettings";
import { TradeSettings } from "./TradeSettings";
import { VolumeSettings } from "./VolumeSettings";
import * as styles from "./style";

export const Settings = () => (
  <Container>
    <Headline2>Settings</Headline2>

    <styles.Content>
      <NamePanel />

      <ExchangeSettings />

      <DecimalSettings />
      <PeriodSettings />

      <PairSettings />
      <VolumeSettings />

      <TradeSettings />
    </styles.Content>
  </Container>
);
