import styled from "styled-components";
import { BlockPanel } from "../../BlockPanel";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  grid-area: transfer-list;
`;

export const TransfersContainer = styled(BlockPanel)`
  justify-content: flex-start;
`;
