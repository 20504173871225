import { ContentPanel } from "../../../../shared";
import { PanelWrapper } from "../../../../shared/style";
import { ConditionList } from "./ConditionList";
import { ModulesForms } from "./ModulesForms";

export const ConditionsModules = () => (
  <PanelWrapper>
    <ContentPanel title="Available conditions">
      <ConditionList />
    </ContentPanel>
    <ContentPanel title="Module settings">
      <ModulesForms />
    </ContentPanel>
  </PanelWrapper>
);
