import styled from "styled-components";
import { Label } from "../shared";

export const WrapperForSwitch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
`;

export const WrapperForRadioItems = styled.div`
  /* max-width: 80px; */
  height: 30px;
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
`;

export const SwitchLabel = styled(Label)``;
