import styled from "styled-components";

export const StatsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
`;

export const Title = styled.span`
  white-space: nowrap;

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.labelColor};
`;

export const Value = styled.span`
  white-space: nowrap;

  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes["14px"]};
  color: ${({ theme }) => theme.darkGray};
`;
