import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { ExchangesLegendV2Provider } from "src/context/CEX/Dashboard/ExchangesLegendV2";
import { ExchangesFeeContext } from "src/context/CEX/Dashboard/v2/ExchangesFee";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import {
  EXCHANGES_FEE_ID,
  ExchangesFeeV2DataProvider,
} from "src/state/CEX/CEXDashboard/v2/providers/ExchangesFeeV2DataProvider";
import { ExchangesFeesData } from "src/state/CEX/CEXDashboard/v2/widgets/ExchangesFeeV2Store";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { ExchangesPieV2, ExchangesPieV2Props } from "../../../ExchangesOverview/ExchangesPieV2";
import { useChartColorWheel } from "../../../shared/hooks/useChartColorWheel";
import { DashboardV2Widget, getWidgetInfo, getWidgetTitle } from "../shared/constants";
import * as styles from "./style";

interface UseExchangesPieDataParams {
  fees: ExchangesFeesData;
}

const useExchangesFeeData = ({ fees }: UseExchangesPieDataParams) => {
  const colorWheel = useChartColorWheel();

  const exchangesData = useMemo(() => {
    const values = Object.values(fees);
    const exchanges = Object.keys(fees);
    // get colors from color wheel for each exchange
    const colors = colorWheel.slice(0, values.length);

    const data: ExchangesPieV2Props["data"] = {
      labels: exchanges,
      datasets: [
        {
          indexAxis: "y",
          data: values,
          backgroundColor: colors,
          borderWidth: 0,
          hoverOffset: 3,
        },
      ],
    };

    return data;
  }, [colorWheel, fees]);

  return exchangesData;
};

export interface ExchangesFeeProps
  extends Omit<CardProps, "title" | "info" | "after" | "loading"> {}

export const ExchangesFee = observer((props: ExchangesFeeProps) => {
  const { data, loading } = useLateInitContext(ExchangesFeeContext.Context);

  const exchangesData = useExchangesFeeData({
    fees: data,
  });

  return (
    <styles.StyledExchangesCard
      title={getWidgetTitle(DashboardV2Widget.ExchangesFee)}
      info={getWidgetInfo(DashboardV2Widget.ExchangesFee)}
      after={<styles.StyledExchangesLegend />}
      loading={loading}
      {...props}
    >
      <styles.PieWrapper>
        <ExchangesPieV2 data={exchangesData} type={EXCHANGES_FEE_ID} />
      </styles.PieWrapper>
    </styles.StyledExchangesCard>
  );
});

export interface ExchangesFeeWidgetProps extends ExchangesFeeProps {}

export const ExchangesFeeWidget = (props: ExchangesFeeWidgetProps) => {
  const dataProvider = useLocalStore(ExchangesFeeV2DataProvider);
  return (
    <ExchangesFeeContext.Provider>
      <ExchangesLegendV2Provider dataProvider={dataProvider}>
        <ExchangesFee {...props} />
      </ExchangesLegendV2Provider>
    </ExchangesFeeContext.Provider>
  );
};
