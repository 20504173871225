import { ComponentPropsWithoutRef } from "react";
import { useHover } from "src/hooks/useHover";
import { useId } from "src/hooks/useId";
import CEXDashboardIcons from "src/icons/CEXDashboardIcons";
import * as styles from "./style";

export interface InfoIconTooltipProps extends ComponentPropsWithoutRef<"span"> {
  info?: string;
}

export const InfoIconTooltip = ({ info, ...props }: InfoIconTooltipProps) => {
  const id = useId();
  const [spanRef, isHovered] = useHover<HTMLSpanElement>();
  const InfoIcon = isHovered ? CEXDashboardIcons.FilledInfo : CEXDashboardIcons.OutlinedInfo;
  return (
    <>
      {info ? (
        <styles.InfoIconWrapper
          data-tooltip-content={info}
          data-tooltip-id={id}
          ref={spanRef}
          {...props}
        >
          <InfoIcon />
        </styles.InfoIconWrapper>
      ) : null}
      <styles.InfoTooltip id={id} place="right" />
    </>
  );
};
