import { Plugin, TooltipOptions } from "chart.js";
import { useMemo } from "react";
import { Pie, PieProps } from "src/components/shared/Charts/Pie";
import { ChartPlaceholder } from "src/config/chartjs/plugins/chartPlaceholder";
import { Totalizer, getTotal } from "src/config/chartjs/plugins/totalizer";
import { useExchangesLegendV2State } from "src/context/CEX/Dashboard/ExchangesLegendV2";
import { deepMerge } from "src/helpers/utils/deepMerge";
import { useBaseTooltip, usePlaceholderOptions } from "../../shared/config";
import { useDashboardColors } from "../../shared/hooks/useDashboardColors";
import { ExchangesLegendV2Plugin } from "../plugin";

const useBaseOptions = () => {
  const { textSecondary } = useDashboardColors();

  const tooltipOptions = useBaseTooltip();

  const options = useMemo(
    (): PieProps<number[], string>["options"] => ({
      locale: "en-US",
      maintainAspectRatio: false,
      layout: {
        padding: 3,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...(tooltipOptions as TooltipOptions<"pie">),
          bodyFont: {
            size: 8,
            weight: "400",
          },
          footerColor: textSecondary,
          footerFont: {
            size: 8,
            weight: "500",
          },
          callbacks: {
            title() {
              const { label } = this.dataPoints[0];
              return label;
            },
            label({ formattedValue }) {
              return formattedValue;
            },
            footer(items) {
              const { datasetIndex } = items[0];
              const total = getTotal(this.chart, datasetIndex);
              return `Total: ${total}`;
            },
          },
        },
        totalizer: { direction: "data" },
        chartPlaceholder: {
          shape: { type: "circle" },
        },
      },
    }),
    [textSecondary, tooltipOptions]
  );

  return options;
};

export interface ExchangesPieV2Props extends PieProps<number[], string> {
  type: string;
}

export const ExchangesPieV2 = ({ type, ...props }: ExchangesPieV2Props) => {
  const state = useExchangesLegendV2State();

  const baseOptions = useBaseOptions();

  const placeholderOptions = usePlaceholderOptions();

  const pluginOptions = useMemo(
    (): PieProps<number[], string>["options"] => ({
      plugins: {
        exchangesLegendV2: {
          id: type,
          state,
        },
      },
    }),
    [state, type]
  );

  const options = useMemo(
    () => deepMerge(placeholderOptions, baseOptions, pluginOptions),
    [baseOptions, placeholderOptions, pluginOptions]
  );

  return (
    <Pie
      options={options}
      plugins={[Totalizer as Plugin<"pie">, ExchangesLegendV2Plugin, ChartPlaceholder]}
      {...props}
    />
  );
};
