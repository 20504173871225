import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
} from "src/components/shared/Table";
import styled from "styled-components";
import { ReportItem } from "../style";

export const BalancesTableContainer = styled(ReportItem)`
  position: relative;
  padding: 0;
  min-width: 290px;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  grid-area: balances-table;
`;

export const BalancesTable = styled(Table)`
  min-height: 200px;
`;

export const Head = styled(TableHead)``;

export const Header = styled(TableHeader)``;

export const Body = styled(TableBody)`
  height: calc(100% - 93px);
  overflow-y: auto;
`;

export const HeaderRow = styled(TableHeadRow)`
  height: 36px;
`;

export const Row = styled(TableRow)`
  :not(:last-of-type) {
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.headerBorder};
  }
`;

export const Cell = styled(TableCell)`
  padding: 15px 5px;
`;

export const TableFooter = styled.tfoot`
  width: 100%;
  border-top: ${({ theme }) => theme.borders.solidGray};

  color: ${({ theme }) => theme.inputTextColor};
`;
