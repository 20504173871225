import { observer } from "mobx-react-lite";
import { LiquidGridCurrentError } from "src/state/CEX/liquidityTab/ActiveGrid";
import { Panel, PanelProps } from "../base/Panel";
import { ErrorsTable } from "./ErrorsTable";
import * as styles from "./style";

interface ErrorsTablePanelProps extends Omit<PanelProps, "title"> {
  count: number;
  errors: LiquidGridCurrentError[];
  title?: string;
}

export const ErrorsTablePanel = observer(
  ({ count, errors, title = "Errors", ...props }: ErrorsTablePanelProps) => (
    <Panel
      title={
        <>
          {title} <styles.ErrorsCounter>{count}</styles.ErrorsCounter>
        </>
      }
      {...props}
    >
      <styles.ScrollTableWrapper>
        <ErrorsTable errors={errors} />
      </styles.ScrollTableWrapper>
    </Panel>
  )
);
