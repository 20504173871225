import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { DEXV2SettingsStore } from "src/state/DEXV2/DEXV2Settings";
import { DEXV2BotContext } from "../DEXV2Bots/DEXV2Bot";

export const DEXV2SettingsContext = createLateInitContext<DEXV2SettingsStore>();

type DEXV2SettingsProviderProps = ChildrenProps;

export const DEXV2SettingsProvider = ({ children }: DEXV2SettingsProviderProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const settingsStore = useLocalStore(DEXV2SettingsStore, {
    botChainProvider: botState,
    chainInfoProvider: botState,
    tradePairProvider: botState,
    pairAddressProvider: botState,
    nativeUSDPriceProvider: botState.nativeUSDPriceProvider,
    baseUSDPriceProvider: botState.baseUSDPriceProvider,
    gasPriceProvider: botState.gasPriceProvider,
  });

  return (
    <DEXV2SettingsContext.Provider value={settingsStore}>{children}</DEXV2SettingsContext.Provider>
  );
};
