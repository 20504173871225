import { Active } from "src/state/MarketCheck/types";
import { ActiveInfo } from "./ActiveInfo";
import * as styles from "./style";

interface ActiveCellProps {
  cellData: Active;
}

export const ActiveCell = ({ cellData }: ActiveCellProps) => (
  <styles.Wrapper>
    <ActiveInfo label="Active exchanges" value={`${cellData.exchanges}`} />

    <ActiveInfo label="Active pairs" value={`${cellData.pairs}`} />
  </styles.Wrapper>
);
