import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;

  grid-area: balances;

  border-radius: ${({ theme }) => theme.radii["4px"]};

  box-shadow: ${({ theme }) => theme.shadows.dashboard};
  background: ${({ theme }) => theme.bgSettingsPnl};
`;

export const Head = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 1230px) {
    display: none;
  }
`;

export const AccountTitle = styled.h2`
  width: 90px;

  margin-bottom: 3px;

  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};

  color: ${({ theme }) => theme.textColor};
`;

export const Body = styled.div`
  width: 100%;
  height: 0px;

  display: flex;

  flex: 1 1 auto;

  padding-left: 3px;

  border-radius: ${({ theme }) => theme.radii["4px"]};

  background: ${({ theme }) => theme.bgSettingsPnl};
`;
