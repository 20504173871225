import { ChartType, TooltipModel } from "chart.js";
import { roundToTwoDecimals } from "src/helpers/rounding";
import { formatDecimalPercent } from "src/helpers/string";

export const getSeriesLabel = (title: string, value: number | string, total?: number | string) => {
  if (!total) {
    return `${title}: ${value}`;
  }

  const percentage = formatDecimalPercent(+value / +total);

  return `${title}: ${value} (${percentage})`;
};

export const getSeriesTotal = <TType extends ChartType>(model: TooltipModel<TType>) => {
  const dataPoint = model.dataPoints[0];
  if (!dataPoint) return 0;
  const { dataIndex } = model.dataPoints[0];
  const { datasets } = model.chart.data;

  const totalDataset = datasets.find((dataset) => dataset.label === "Total");
  const total = (totalDataset?.data[dataIndex] ?? 0) as number;
  const roundedTotal = roundToTwoDecimals(total);
  return roundedTotal;
};
