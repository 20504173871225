import { Pagination } from "src/components/shared/Pagination";
import { SwitchMenu } from "src/components/shared/SwitchMenu";
import styled, { css } from "styled-components";
import { UniqueNameText } from "../Modules/Conditions/ConditionsTable/UniqueNameText";

export const TabHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 8px 12px;
  gap: 8px;

  @media (max-width: 525px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TabContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const TablePagination = styled(Pagination)`
  height: 30px;
  width: 250px;

  align-self: flex-end;

  padding: 5px;
`;

export const SwitchTabs = styled(SwitchMenu)`
  max-width: 250px;
`;

export const PanelWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: minmax(150px, 0.5fr) minmax(200px, 1fr);
  grid-template-rows: 1fr;
  gap: 8px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr;
  }
`;

export const ListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;
`;

export const WrapperWithInputsMixin = css`
  width: 100%;

  & > div {
    max-width: 318px;
  }
`;

export const RowWrapper = styled(Wrapper)`
  align-items: flex-end;
  flex-wrap: wrap;

  ${WrapperWithInputsMixin}
`;

export const AuxiliaryModuleText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.lightGray};
`;

export const InfoRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const InfoColumn = styled(InfoRow)`
  flex-direction: column;
  align-items: flex-start;
`;

export const InfoLabel = styled.span`
  color: ${({ theme }) => theme.textColor};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const InfoUniqueName = styled(UniqueNameText)`
  height: auto;

  padding-left: 16px;
`;
