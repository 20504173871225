import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { Headline3 } from "src/components/shared/Typography";
import { PartyContext, TickersContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import TickersStore from "src/state/UserManager/EditParty/TickersStore";
import { AddTicker } from "./AddTicker";
import { Ticker } from "./Ticker";
import * as styles from "./style";

export const Tickers = observer(() => {
  const partyState = useLateInitContext(PartyContext);

  const state = useLocalStore(TickersStore, partyState);

  useEffect(() => {
    state.loadData();
  }, [state]);

  return (
    <TickersContext.Provider value={state}>
      <styles.Container>
        <styles.HeaderWrapper>
          <Headline3>Add ticker group</Headline3>
          <AddTicker />
        </styles.HeaderWrapper>

        <styles.ContentWrapper>
          <styles.Content>
            {state.tickerGroups.map((el) => (
              <Ticker key={el.uuid} tickerGroup={el} />
            ))}
          </styles.Content>

          <Loader show={state.isLoading} />
        </styles.ContentWrapper>
      </styles.Container>
    </TickersContext.Provider>
  );
});
