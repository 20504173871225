import { CellProps } from "react-table";
import { LiquidGridCurrentError } from "src/state/CEX/liquidityTab/ActiveGrid";
import * as styles from "./style";

export interface ErrorCellWrapperProps<D extends object>
  extends Pick<CellProps<D>, "value" | "row"> {}

const ErrorCellWrapper = ({ row, value }: ErrorCellWrapperProps<LiquidGridCurrentError>) => (
  <styles.ErrorCellWrapper isActive={row.original.isCurrent}>{value}</styles.ErrorCellWrapper>
);

export default ErrorCellWrapper;
