import { observer } from "mobx-react-lite";
import { IBaseGridForm } from "src/state/CEX/liquidityTab/CreateGrid";
import { Form, FormProps } from "../base/Form";
import { GridFormContent } from "./GridFormContent";

export interface GridFormProps extends Omit<FormProps, "isLoading" | "onSubmit"> {
  state: IBaseGridForm;
}

export const GridForm = observer(({ state, ...props }: GridFormProps) => (
  <Form onSubmit={state.submitHandler()} {...props}>
    <GridFormContent state={state.form} />
  </Form>
));
