import { makeAutoObservable, runInAction } from "mobx";
import {
  OrderTerminalProps,
  cancelAllOrders,
  cancelManyOrder,
  cancelOrder,
  fetchLimitOrder,
  fetchLimitOrders,
} from "src/api/bots/CEX/exchange";
import { joinPair } from "src/helpers/botName";
import { stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { toRounding } from "src/helpers/rounding";
import { CreatedLimitOrder } from "src/modules/exchange/trade";
import TableStore, { TableOrders } from "src/state/Table";
import ExchangeStore from "../..";
import GetStatusOrderStore, { OrdersStore } from "./GetStatusOrderStore";

export default class LimitOrdersStore implements TableOrders, OrdersStore {
  private _limitOrders: CreatedLimitOrder[] = [];

  selectedOrders: string[] = [];

  mainState: ExchangeStore;

  tableState: TableStore;

  isLoading = false;

  getStatusState: GetStatusOrderStore<CreatedLimitOrder>;

  constructor(state: ExchangeStore) {
    this.mainState = state;

    this.tableState = new TableStore(this);

    this.getStatusState = new GetStatusOrderStore(this);

    this.mainState.setUpdHandlers("updLimitOrders", this.downloadData);

    makeAutoObservable(this);
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  downloadData = () => {
    this._fetchLimitOrders(this.mainState.currentAccID, this.mainState.pair);
  };

  get items() {
    return this.limitOrders;
  }

  get limitOrders() {
    return this._limitOrders.map(({ price, amount, side, id, ...otherParams }) => ({
      ...otherParams,
      id,
      amount: toRounding(+amount, this.mainState.amountPrecision),
      price: toRounding(+price, this.mainState.pricePrecision),
      side: side.toUpperCase(),
    }));
  }

  get amountSellOrders() {
    return this._limitOrders.filter((el) => el.side === "sell").length;
  }

  get amountBuyOrders() {
    return this._limitOrders.filter((el) => el.side === "buy").length;
  }

  get orderInfo() {
    const orderStatus = this.getStatusState.selectOrderStatus;

    if (orderStatus)
      return {
        ...orderStatus,
        pair: joinPair(orderStatus?.pair.quote || "", orderStatus?.pair.base || ""),
        time: unixToDateFormat(stringDateToUnix(orderStatus?.time || ""), "FullDate"),
        side: orderStatus.side.toUpperCase(),
      };

    return undefined;
  }

  cancelRequest = async (selectItems: string[]) => {
    this.setLoading(true);

    try {
      const { isError } = await cancelOrder({
        account_uuid: this.mainState.currentAccID,
        order_id: selectItems[0],
        pair: this.mainState.pair,
      });

      if (!isError) {
        this.mainState.updLimitTradingData();
      }

      return isError;
    } finally {
      this.setLoading(false);
    }
  };

  cancelManyRequest = async (selectItems: string[]) => {
    this.setLoading(true);

    try {
      const { isError } = await cancelManyOrder({
        account_uuid: this.mainState.currentAccID,
        order_ids: selectItems,
        pair: this.mainState.pair,
      });

      if (!isError) {
        this.mainState.updLimitTradingData();
      }

      return isError;
    } finally {
      this.setLoading(false);
    }
  };

  cancelAllRequest = async () => {
    this.setLoading(true);

    try {
      const { isError } = await cancelAllOrders({
        account_uuid: this.mainState.currentAccID,
        pair: this.mainState.pair,
      });

      if (!isError) {
        this.mainState.updLimitTradingData();
      }

      return isError;
    } finally {
      this.setLoading(false);
    }
  };

  private _getLimitOrder = async ({ account_uuid, order_id, pair }: OrderTerminalProps) =>
    fetchLimitOrder({
      account_uuid,
      order_id,
      pair,
    });

  getOrder = async (id: string) =>
    this._getLimitOrder({
      account_uuid: this.mainState.currentAccID,
      order_id: id,
      pair: this.mainState.pair,
    });

  resetSelectOrders = () => {
    this.tableState.resetSelectItems();
  };

  private _fetchLimitOrders = async (account_uuid: string, pair: string) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const {
        data: { data },
        isError,
      } = await fetchLimitOrders({
        account_uuid,
        pair,
      });

      if (!isError) {
        this._setOrders(data);
      } else {
        this._setOrders([]);
      }
    } catch {
      this._setOrders([]);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  private _setOrders = (orders: CreatedLimitOrder[]) => {
    this._limitOrders = orders;
  };
}
