const durationRegex = /^(\d+M)?(\d+W)?(\d+D)?(\d+)?$/;

const DURATION_UNITS = ["month", "weeks", "days", "minutes"] as const;

type DurationUnits = (typeof DURATION_UNITS)[number];

export type Duration = Partial<Record<DurationUnits, number>>;

export type DurationFormatError = string | null;

export type DurationParseResult = {
  duration: Duration;
  error: DurationFormatError;
};

export const parseTVChartDuration = (str: string): DurationParseResult => {
  const matches = str.match(durationRegex);
  const duration: Duration = {};

  if (!matches) {
    return { duration, error: "Invalid duration format!" };
  }

  DURATION_UNITS.forEach((unit, index) => {
    const match = matches[index + 1];
    if (match && unit !== "minutes") {
      duration[unit] = parseInt(match.slice(0, -1), 10);
    } else if (match && unit === "minutes") {
      duration[unit] = parseInt(match, 10);
    }
  });

  if (Object.keys(duration).length === 0) {
    return {
      duration,
      error: "Duration must contain at least one time interval",
    };
  }

  return { duration, error: null };
};
