import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { ISideBarItem } from ".";
import { MenuItem } from "./MenuItem";

export interface PermissionMenuItemProps {
  item: ISideBarItem;
}

export const PermissionMenuItem = ({
  item: { abilityName, to, label },
}: PermissionMenuItemProps) => {
  const Item = <MenuItem to={to}>{label}</MenuItem>;
  return (
    <>
      {abilityName ? <PermissionWrapper abilityName={abilityName}>{Item}</PermissionWrapper> : Item}
    </>
  );
};
