import { ethers, providers } from "ethers";
import { BalanceHelperV4 } from "src/contracts/BalanceHelperV4";
import { BalanceHelperV4__factory } from "src/contracts/factories/BalanceHelperV4__factory";
import nodeAddresses from "src/json/nodeAddresses.json";
import { ChainInfo } from "src/state/chain/ChainsInfoStore";
import { getContractAddress } from "../chain/contracts";
import { calcRoundingValues } from "../rounding";
import { Nullish } from "../utils";

export type NodeAddresses = typeof nodeAddresses;
export type Networks = keyof NodeAddresses;

const _getBalanceHelperContract = (chainId: string, provider: ethers.providers.JsonRpcProvider) => {
  const contractAddress = getContractAddress(chainId, "BalanceHelperV4");
  if (!contractAddress) return null;

  return BalanceHelperV4__factory.connect(contractAddress, provider);
};

const getBalanceHelperContractParams = (networkOrChainInfo: Networks | ChainInfo) => {
  if (typeof networkOrChainInfo === "string") {
    const network = networkOrChainInfo;
    const provider = new providers.JsonRpcProvider(nodeAddresses[network].rpc_moralis);
    const chainId = nodeAddresses[network].id;

    return { chainId, provider };
  }
  const { rpc, id: chainId } = networkOrChainInfo;
  const provider = new providers.JsonRpcProvider(rpc);

  return { chainId, provider };
};

export const getBalanceHelperContract = (
  networkOrChainInfo: Networks | ChainInfo,
  inProvider?: Nullish<providers.JsonRpcProvider>
) => {
  const { chainId, provider: baseProvider } = getBalanceHelperContractParams(networkOrChainInfo);
  const provider = inProvider ?? baseProvider;
  return _getBalanceHelperContract(chainId, provider);
};

export const getBalance = async (
  balancesContract: BalanceHelperV4,
  token: string,
  addresses: string[],
  spender: string
) => {
  if (spender)
    return (
      await balancesContract["getBalance(address,address[],address)"](token, addresses, spender)
    ).map(({ balance, allowance }) => [balance, allowance] as const);

  return await balancesContract["getBalance(address,address[])"](token, addresses);
};

export const getBalanceNative = async (balancesContract: BalanceHelperV4, addresses: string[]) =>
  await balancesContract.getBalanceNative(addresses);

export const getBalanceFloat = async (
  balancesContract: BalanceHelperV4,
  token: string,
  address: string[],
  spender: string
) => {
  const balances = await getBalance(balancesContract, token, address, spender);

  const roundingValue = calcRoundingValues(
    balances.map((balance) => {
      const eth = ethers.BigNumber.isBigNumber(balance) ? balance : balance[0];
      return ethers.utils.formatEther(eth);
    })
  );

  if (spender) {
    const balancesWithSpender = balances as Array<[ethers.BigNumber, boolean]>;
    return balancesWithSpender.map(([balance, allowance]) => ({
      value: (+ethers.utils.formatEther(balance)).toFixed(roundingValue),
      allowance,
    }));
  }
  const balancesWithoutSpender = balances as Array<ethers.BigNumber>;

  return balancesWithoutSpender.map((balance) => ({
    value: (+ethers.utils.formatEther(balance)).toFixed(roundingValue),
    allowance: null,
  }));
};

export const getBalanceNativeFloat = async (
  balancesContract: BalanceHelperV4,
  address: string[]
) => {
  const balances = await getBalanceNative(balancesContract, address);

  const roundingValue = calcRoundingValues(balances.map((b) => ethers.utils.formatEther(b)));

  return balances.map((balance) => ({
    value: (+ethers.utils.formatEther(balance)).toFixed(roundingValue),
    allowance: null,
  }));
};
