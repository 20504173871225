import { useMemo } from "react";
import { DropdownButton } from "src/components/shared/Buttons/v2/DropdownButton";
import { PopoverMenu, PopoverMenuProps } from "src/components/shared/PopoverMenu";

export interface DownloadButtonProps extends Omit<PopoverMenuProps, "items"> {
  onDownloadOriginal?: () => void;
  onDownloadFiltered?: () => void;
  text?: string;
}

export const DownloadButton = ({
  onDownloadOriginal,
  onDownloadFiltered,
  text = "Download",
  disabled,
  trigger: outerTrigger,
  ...props
}: DownloadButtonProps) => {
  const disabledProps = { disabled };

  const defaultTrigger = (isOpen: boolean) => (
    <DropdownButton size="m" variant="outline" open={isOpen} {...disabledProps}>
      {text}
    </DropdownButton>
  );

  const trigger = outerTrigger || defaultTrigger;

  const actionItems = useMemo(() => {
    const originalItem = {
      label: "Original",
      onClick: onDownloadOriginal,
    };

    const filteredItem = {
      label: "Filtered",
      onClick: onDownloadFiltered,
    };

    return [originalItem, filteredItem];
  }, [onDownloadFiltered, onDownloadOriginal]);

  return <PopoverMenu trigger={trigger} items={actionItems} {...disabledProps} {...props} />;
};
