import { makeAutoObservable } from "mobx";
import WalletsStore, { IWalletAddressValidation, IWalletsFormProvider } from "../WalletsStore";
import { SwapWalletsAdapter } from "./SwapWalletsAdapter";

export interface ISwapWalletsProvider {
  get swapWallets(): string[];
  setSwapWallets: (wallets: string[]) => void;
}

export default class SwapWalletsStore {
  private _swapWalletsState: IWalletsFormProvider;

  constructor(
    walletsProvider: ISwapWalletsProvider,
    walletValidationProvider: IWalletAddressValidation,
    validateWallets?: boolean
  ) {
    makeAutoObservable(this);

    const adapter = new SwapWalletsAdapter(walletsProvider);

    this._swapWalletsState = new WalletsStore(adapter, {
      walletValidationProvider,
      walletName: "swap wallet",
      validateWallets,
    });
  }

  get swapWalletsState() {
    return this._swapWalletsState;
  }
}
