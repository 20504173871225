import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { SeparatorNum } from "src/components/shared/Separator";
import { ThousandthSeparator } from "src/components/shared/ThousandthSeparator";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { calcRoundingValue, toRounding } from "src/helpers/rounding";
import { useFindWidth } from "src/hooks/useFindWidth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { OrderSideType } from "src/modules/exchange/trade";
import { capitalizeOnlyFirstLetter } from "../utils";
import * as styles from "./style";

export interface HeaderFormProps {
  side: OrderSideType;
  orderSize?: number;
}

export const HeaderForm = observer(({ side, orderSize }: HeaderFormProps) => {
  const { updAllData, quote, base, balancesState } = useLateInitContext(ExchangeContext);
  const { quoteFree, baseFree } = balancesState;

  const balance = side === "BUY" ? quoteFree : baseFree;

  const isShortBalance = useFindWidth(500);

  const getBalance = useCallback(() => {
    if (balance) {
      if (isShortBalance) {
        return <ThousandthSeparator value={toRounding(balance, calcRoundingValue(balance))} />;
      }
      return <SeparatorNum value={toRounding(balance, calcRoundingValue(balance))} />;
    }
    return "NO BALANCE";
  }, [balance, isShortBalance]);

  return (
    <styles.Header>
      <styles.HeaderCol>
        <styles.CurrencyTitle>
          {capitalizeOnlyFirstLetter(side)}: {side === "BUY" ? quote : base}
        </styles.CurrencyTitle>

        <styles.Balance exceed={orderSize && balance ? orderSize > balance : false}>
          {getBalance()}
        </styles.Balance>
      </styles.HeaderCol>

      <styles.UpdateBtn onClick={updAllData} />
    </styles.Header>
  );
});
