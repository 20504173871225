import { observer } from "mobx-react-lite";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { BindingsBotContext } from "src/context/CEX/BindingsBotProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { WrapperForModule } from "../style";

export const BotModule = observer(() => {
  const state = useLateInitContext(BindingsBotContext);

  return (
    <WrapperForModule>
      <LabeledSelector
        labelAfter={
          <CheckBox label="Bot module" checked={state.checkBot} onChange={state.toggleBotBinding} />
        }
        value={state.currentBindings.botBindingUUID ? state.botSelected : null}
        isClearable={!state.checkBot}
        options={state._mainBotModules}
        onChange={state.selectorHandler("botBindingUUID")}
      />
    </WrapperForModule>
  );
});
