import { makeAutoObservable } from "mobx";
import { IDisposable } from "src/helpers/utils";
import {
  IBotChainInfoProvider,
  IBotTradePairProvider,
  ISwapPairAddressProvider,
} from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import {
  INonNullableVersionProviderBuilder,
  NonNullableVersionProvider,
} from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/Providers/NonNullableVersionProvider";
import { RouterProvider } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/Providers/RouterProvider";
import { ISwapSettingsProvider } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/Providers/SwapSettingsProvider";
import { INonNullableVersionProvider } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/VersionedSwapState";
import { IDEXV2SettingsProvider } from "../../DEXV2SettingsInfoStore";
import {
  IModeImpactInfo,
  IPriceImpactInfoDataProvider,
  ModeImpactInfoStore,
} from "./ModeImpactInfoStore";

interface IModeImpactInfoProviderParams {
  swapSettingsProvider: ISwapSettingsProvider;
  settingsProvider: IDEXV2SettingsProvider;
  tradePairProvider: IBotTradePairProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  botChainInfoProvider: IBotChainInfoProvider;
  impactDataProvider: IPriceImpactInfoDataProvider;
}

export class ModeImpactInfoStoreProvider implements IDisposable {
  private _impactInfoState: (IModeImpactInfo & IDisposable) | null = null;

  private _routerProvider: RouterProvider | null = null;

  private _nonNullableVersionProvider: INonNullableVersionProviderBuilder & IDisposable;

  constructor(params: IModeImpactInfoProviderParams) {
    makeAutoObservable(this);

    const { swapSettingsProvider } = params;

    this._nonNullableVersionProvider = new NonNullableVersionProvider({
      versionProvider: swapSettingsProvider.versionProvider,
    });

    this._initState(params);
  }

  private _initState = async (params: IModeImpactInfoProviderParams) => {
    const versionProvider = await this._nonNullableVersionProvider.getVersion();

    if (!versionProvider) return;

    this._createState(params, versionProvider);
  };

  private _createState = (
    params: IModeImpactInfoProviderParams,
    versionProvider: INonNullableVersionProvider
  ) => {
    const {
      botChainInfoProvider,
      swapSettingsProvider,
      tradePairProvider,
      pairAddressProvider,
      impactDataProvider,
    } = params;

    const routerProvider = new RouterProvider({
      chainInfoProvider: botChainInfoProvider,
      swapSettingsProvider,
      versionProvider,
    });

    this._routerProvider = routerProvider;

    this._impactInfoState = new ModeImpactInfoStore({
      dataProvider: impactDataProvider,
      tradePairProvider,
      routerProvider,
      pairAddressProvider,
      versionProvider,
    });
  };

  get impactInfoState(): IModeImpactInfo | null {
    return this._impactInfoState;
  }

  destroy = () => {
    this._nonNullableVersionProvider.destroy();
    this._impactInfoState?.destroy();
    this._routerProvider?.destroy();
  };
}
