import { ComponentPropsWithoutRef } from "react";
import { PanelHeaderOwnProps } from "src/components/shared/PanelHeader";
import { ChildrenProps } from "src/helpers/utils";
import * as styles from "./style";

export interface SettingsPanelOwnProps extends PanelHeaderOwnProps, styles.ContentProps {}

export interface SettingsPanelProps
  extends ChildrenProps,
    SettingsPanelOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, keyof SettingsPanelOwnProps> {}

export const SettingsPanel = ({
  label,
  labelIcon,
  children,
  checkbox,
  toggle,
  info,
  btn,
  disabled,
  ...props
}: SettingsPanelProps) => (
  <styles.Container {...props}>
    <styles.Header
      label={label}
      labelIcon={labelIcon}
      checkbox={checkbox}
      toggle={toggle}
      btn={btn}
      info={info}
    />
    <styles.Content disabled={disabled}>{children}</styles.Content>
  </styles.Container>
);
