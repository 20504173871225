import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { Tabs } from "src/components/shared/Tabs";
import { Content, Panel } from "src/components/shared/Tabs/style";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: buy-sell-wrap;
  flex: 1;

  @media (max-width: 880px) {
    min-width: 0;
    height: 288px;
  }
`;

export const TabsModules = styled(Tabs)`
  grid-area: tabs;

  ${Panel} {
    margin-bottom: 5px;
  }

  ${Content} {
    height: calc(100% - 35px);
  }
`;

export const FormsWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  gap: 5px;
`;

export const FormFallback = styled(CrashPanel)`
  flex: 1 1 0;

  padding: 0px;
`;
