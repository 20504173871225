import { ColumnDef } from "@tanstack/table-core";
import { Observer, observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { SeparatorNum } from "src/components/shared/Separator";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { TableContext } from "src/context/shared/Table";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CreatedAlgoOrder } from "src/modules/exchange/trade";
import { AmountCell } from "../AmountCell";
import { VIEW_LIMIT_ORDER_PARAMS } from "../LimitOrders";
import { OrderInfoModal } from "../OrderInfoModal";
import { OpenOrderLink } from "../OrderInfoModal/style";
import { ColumnFilter } from "../OrdersTable/ColumnFilter";
import { TableRows } from "../OrdersTable/TableRows";
import { InfoButton } from "../shared/InfoButton";
import * as styles from "../shared/style";

interface Props {
  party: string;
}
const STATUS_FILTERS = ["new", "canceled", "failed", "expired", "placed"];

const VIEW_ALGO_ORDER_PARAMS = [
  "id",
  "externalId",
  "triggerCompare",
  "triggerPrice",
  "price",
  "amount",
  "side",
  "createdAt",
  "updatedAt",
  "expiredAt",
  "status",
  "attempts",
  "exchangeId",
  "pair",
  "apiKey",
  "last_price_price",
  "last_price_updatedAt",
  // "error",
] as Array<keyof CreatedAlgoOrder>;

export const StopOrders = observer(({ party }: Props) => {
  const mainState = useLateInitContext(ExchangeContext);

  const { stopOrdersState } = mainState;

  const { tableState } = stopOrdersState;

  const { getStatusState } = stopOrdersState;

  const ordersState = mainState.limitOrdersState;

  const [showModal, setShowModal] = useState(false);

  const [showLimitModal, setShowLimitModal] = useState(false);

  useEffect(() => {
    stopOrdersState.downloadData();
  }, [stopOrdersState]);

  const columns = useMemo(() => {
    const columnSchema: ColumnDef<CreatedAlgoOrder>[] = [
      {
        header: () => (
          <Observer>
            {() => (
              <styles.HeadCheckBox
                checked={tableState.allChecked}
                style={{ marginLeft: "0px", width: "18px" }}
                onChange={tableState.allCheck}
              />
            )}
          </Observer>
        ),
        accessorKey: "id",
        id: "idOrder",
        enableColumnFilter: false,
        enableSorting: false,
        size: 20,
        cell: ({
          cell: {
            row: {
              original: { status, id },
            },
          },
        }) => (
          <Observer>
            {() => (
              <CheckBox
                disabled={status !== "new"}
                checked={tableState.selectItems.includes(String(id))}
                onChange={() => tableState.check(String(id))}
                style={{ marginLeft: "0px", width: "18px" }}
              />
            )}
          </Observer>
        ),
      },

      {
        header: () => "Trigger",
        accessorKey: "triggerPrice",
        id: "triggerOrder",
        enableColumnFilter: false,
        enableResizing: false,
        cell: ({
          cell: {
            row: {
              original: { triggerPrice, triggerCompare },
            },
          },
        }) => (
          <>
            {triggerCompare === "greater" ? ">" : "<"}
            <SeparatorNum value={triggerPrice} />
          </>
        ),
      },
      {
        header: () => "Price",
        accessorKey: "price",
        id: "priceOrder",
        enableColumnFilter: false,
        enableResizing: false,
        cell: (info) => <SeparatorNum value={info.getValue<string>()} />,
      },
      {
        header: () => "Amount",
        accessorKey: "amount",
        id: "amountOrder",
        enableColumnFilter: false,
        enableResizing: false,
        cell: ({
          cell: {
            row: {
              original: { amount, price },
            },
          },
        }) => <AmountCell amount={amount} price={price} />,
      },
      {
        header: () => "Side",
        accessorKey: "side",
        id: "sideOrder",
        enableColumnFilter: false,
        enableResizing: false,
        cell: ({
          cell: {
            row: { original: order },
          },
        }) => (
          <styles.Side side={order.side}>
            {order.side}
            <InfoButton
              onClick={() => {
                setShowModal(true);
                getStatusState.setSelectOrderId(String(order.id));
              }}
            />
          </styles.Side>
        ),
      },

      {
        header: () => "Expired",
        accessorKey: "expiredAt",
        id: "expiredAtOrder",
        enableColumnFilter: false,
        enableResizing: false,
        cell: (info) => <styles.Text>{info.getValue<string>()}</styles.Text>,
      },
      {
        id: "statusOrder",
        header: () => (
          <ColumnFilter
            label="Status"
            filters={STATUS_FILTERS}
            filterParam="status"
            defaultFilter="new"
          />
        ),
        accessorKey: "status",
        enableSorting: false,
        enableResizing: false,
        cell: (info) => <styles.Text>{info.getValue<string>()}</styles.Text>,
      },

      {
        header: () => (
          <Observer>
            {() => (
              <Button
                size="xs"
                disabled={!tableState.cancelActive}
                onClick={tableState.cancelOrders}
              >
                Cancel
              </Button>
            )}
          </Observer>
        ),
        accessorKey: "cancel",
        id: "cancelOrder",
        enableColumnFilter: false,
        enableSorting: false,
        size: 50,
        cell: ({
          cell: {
            row: {
              original: { status, id },
            },
          },
        }) => (
          <Observer>
            {() => (
              <DeleteButton
                disabled={status !== "new"}
                style={{ margin: "auto" }}
                onClick={() => tableState.cancelOrder([String(id)])}
              />
            )}
          </Observer>
        ),
      },
    ];

    return columnSchema;
  }, [tableState, getStatusState]);

  return (
    <TableContext.Provider value={tableState}>
      <TableRows
        data={tableState.tableItems}
        columns={columns}
        loader={stopOrdersState.isLoading}
        headTooltipProps={{
          amountAllOrders: stopOrdersState.algoOrders.length,
          amountBuyOrders: stopOrdersState.amountBuyOrders,
          amountSellOrders: stopOrdersState.amountSellOrders,
        }}
      />

      {showModal ? (
        <OrderInfoModal
          label="Stop order info:"
          data={stopOrdersState.orderInfo}
          keys={VIEW_ALGO_ORDER_PARAMS}
          show={showModal}
          close={setShowModal}
          error={stopOrdersState.algoStatusError}
          loading={getStatusState.isStatusLoading}
          afterLabel={
            <>
              {stopOrdersState.orderInfo?.status === "placed" ? (
                <OpenOrderLink
                  onClick={() => {
                    setShowLimitModal(true);
                    if (stopOrdersState.orderInfo?.externalId)
                      ordersState.getStatusState.setSelectOrderId(
                        stopOrdersState.orderInfo?.externalId
                      );
                  }}
                >
                  Open placed order
                </OpenOrderLink>
              ) : (
                ""
              )}
              <DeleteButton
                disabled={stopOrdersState.orderInfo?.status !== "new"}
                onClick={() => {
                  tableState.cancelOrder([String(stopOrdersState.orderInfo?.id)]);
                  setShowModal(false);
                }}
              />
            </>
          }
        />
      ) : null}

      {showLimitModal ? (
        <OrderInfoModal
          label="Limit order info:"
          data={ordersState.orderInfo}
          keys={VIEW_LIMIT_ORDER_PARAMS}
          show={showModal}
          close={setShowLimitModal}
          loading={ordersState.getStatusState.isStatusLoading}
          afterLabel={
            <DeleteButton
              disabled={ordersState.orderInfo?.status !== "open"}
              onClick={() => {
                ordersState.tableState.cancelOrder([String(ordersState.orderInfo?.id)]);
                setShowLimitModal(false);
              }}
            />
          }
        />
      ) : null}
    </TableContext.Provider>
  );
});
