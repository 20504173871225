import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ModalPanel, ModalProps } from "src/components/shared/ModalPanel";
import {
  StrategiesModalContent,
  StrategiesModalContentOwnProps,
  StrategiesModalContentProps,
} from "./StrategiesModalContent";

interface ModalGasFillProps
  extends Pick<ModalProps, "show">,
    Omit<StrategiesModalContentOwnProps, "setLoading"> {}

export const StrategiesModal = observer(
  ({ show, onClose, initialData, onSave, ...props }: ModalGasFillProps) => {
    const [loading, setLoading] = useState(false);

    const contentProps: StrategiesModalContentProps = {
      onClose,
      setLoading,
      initialData,
      onSave,
    };

    return (
      <ModalPanel
        label="Strategies"
        show={show}
        close={onClose}
        loading={loading}
        zIndex={1000}
        {...props}
      >
        <StrategiesModalContent {...contentProps} />
      </ModalPanel>
    );
  }
);
