import { BaseValueType, BaselineData, HistogramData, LineStyle } from "lightweight-charts";
import { rgba } from "polished";
import { useMemo } from "react";
import { SeriesCommonProps, SeriesProps } from "src/components/shared/Graph/types";
import { PriceData } from "src/state/CEX/CEXDashboard/v2/widgets/PriceV2Store";
import { useDashboardColors } from "../../../../../shared/hooks/useDashboardColors";

const useBaselineColors = () => {
  const {
    accent: { darkLime, rubyRed },
  } = useDashboardColors();

  const colors = useMemo(
    (): SeriesCommonProps<"Baseline">["options"] => ({
      topLineColor: darkLime,
      topFillColor1: rgba(darkLime, 0.3),
      topFillColor2: rgba(darkLime, 0.05),
      bottomLineColor: rubyRed,
      bottomFillColor1: rgba(rubyRed, 0.05),
      bottomFillColor2: rgba(rubyRed, 0.3),
    }),
    [darkLime, rubyRed]
  );

  return colors;
};

interface UseSeriesProps {
  data: PriceData;
}

export const useSeriesData = ({
  data: { basePrice, price, volume },
}: UseSeriesProps): SeriesProps[] => {
  const { borderDefault, textSecondary } = useDashboardColors();

  const baselineColors = useBaselineColors();

  const seriesProps = useMemo((): SeriesProps[] => {
    const baseValue: BaseValueType = { type: "price", price: basePrice };
    return [
      {
        type: "Baseline",
        data: price as BaselineData[],
        title: "Price",
        side: "right",
        startOptions: {
          lastValueVisible: false,
        },
        options: {
          lineWidth: 1,
          baseValue,
          ...baselineColors,
        },
        priceLines: [
          {
            price: basePrice,
            color: textSecondary,
            lineWidth: 1,
            lineStyle: LineStyle.Dashed,
          },
        ],
      },
      {
        type: "Histogram",
        data: volume as HistogramData[],
        title: "Volume",
        side: "",
        startOptions: {
          priceFormat: {
            type: "volume",
          },
        },
        options: {
          color: borderDefault,
        },
      },
    ];
  }, [basePrice, baselineColors, borderDefault, price, textSecondary, volume]);
  return seriesProps;
};
