import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { IBotModule } from "src/state/CEX/botModules";
import { ModulePanel } from "../ModulePanel";

import { MainBotModuleForm } from "./MainBotModuleForm";

export interface MainBotModuleProps {
  module: IBotModule;
}

export const MainBotModule = observer(({ module }: MainBotModuleProps) => {
  const methods = useForm<IBotModule>({
    defaultValues: module,
  });

  return (
    <FormProvider {...methods}>
      <ModulePanel module={module}>
        <MainBotModuleForm />
      </ModulePanel>
    </FormProvider>
  );
});
