import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import {
  MultiToggleButton,
  MultiToggleButtonItem,
} from "src/components/shared/Buttons/v2/MultiToggleButton";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { CreatableSelector } from "src/components/shared/Forms/Selectors";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { OrdersType } from "src/state/CEX/CEXExchange/OrderBook/OrderBookStore";
import * as styles from "./style";

const SLIDER_ITEMS: MultiToggleButtonItem<OrdersType>[] = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Our",
    value: "OUR",
  },
  {
    label: "Org",
    value: "ORGANIC",
  },
];

export const PairChange = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);

  const update = useCallback(() => {
    if (!mainState.market) return;

    mainState.updAllData();
  }, [mainState]);

  const handleCreate = (inputValue: string) => {
    mainState.setNewPair(inputValue);
    mainState.changePair(String(inputValue));
  };

  return (
    <styles.Container>
      <MultiToggleButton
        items={SLIDER_ITEMS}
        onChange={mainState.orderBookState.toggleOrdersType}
        value={mainState.orderBookState.currentOrderType}
        // style={{ flexShrink: 0 }}
      />

      <CreatableSelector
        value={{ value: mainState.pair, label: mainState.pair }}
        options={mainState.shortPairsList}
        onChange={(data) => {
          mainState.changePair(String(data ? data.value : ""));
        }}
        onInputChange={mainState.setSearchPair()}
        onCreateOption={handleCreate}
      />
      <ReloadButton onClick={() => update()} />
    </styles.Container>
  );
});
