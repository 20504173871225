import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface BalancesTickerProps extends ComponentPropsWithoutRef<"span"> {
  color?: string;
}

export const BalancesTicker = ({ color, ...props }: BalancesTickerProps) => (
  <styles.StylesTicker $color={color} {...props} />
);
