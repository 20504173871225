import { observer } from "mobx-react-lite";
import { IBaseMultiGridForm } from "src/state/CEX/multiGrid";
import { Form } from "../../LiquidityTab/base/Form";
import { MultiGridFormContent } from "./MultiGridFormContent";

interface MultiGridFormProps {
  id: string;
  state: IBaseMultiGridForm;
}

export const MultiGridForm = observer(({ state, id }: MultiGridFormProps) => (
  <Form style={{ height: "360px" }} id={id} onSubmit={state.submitHandler}>
    <MultiGridFormContent state={state.formState} />
  </Form>
));
