import { makeAutoObservable } from "mobx";
import { GetKPIIndexResponse, getKPIIndex } from "src/api/bots/CEX/dashboard";
import { formatDecimalPercent } from "src/helpers/string";
import { Mapper } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

type IndexImpactData = { indexImpact: string };

const INITIAL_DATA: IndexImpactData = {
  indexImpact: "",
};

const indexResponseToData: Mapper<GetKPIIndexResponse, IndexImpactData> = ({ index_impact }) => ({
  indexImpact: index_impact,
});

interface IIndexImpactStoreParams extends IBaseDashboardV2StoreParams {}

export default class IndexImpactV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: IndexImpactData = INITIAL_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IIndexImpactStoreParams) {
    makeAutoObservable(this);

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    this._stateProvider = stateProvider;
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  get loading() {
    return this._baseState.loading;
  }

  private _setData = (data: IndexImpactData) => {
    this._data = data;
  };

  get indexImpact() {
    const { indexImpact } = this._data;
    const percentImpact = formatDecimalPercent(indexImpact, false);
    return percentImpact;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    const { party, queryParams } = requestParams;

    this._setData(INITIAL_DATA);

    try {
      const { data, isError } = await getKPIIndex(party, queryParams);

      if (!isError) {
        const kpiData = indexResponseToData(data);
        this._setData(kpiData);
      }
    } catch {
      this._setData(INITIAL_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
