import { observer } from "mobx-react-lite";
import { ChainInfoChipOwnProps } from "src/components/AllBots/Bots/DEXV2Bots/shared/ChainInfoChip";
import { WalletsInteractionPanel } from "src/components/BotsContent/DEX_V2/shared/WalletsInteractionPanel";
import { VaultsContext } from "src/context/DEXV2/DEXV2Stats/Vaults/Vaults";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { IChainMeta } from "src/modules/chain";

const useChainInfoProps = (chainMeta?: IChainMeta): ChainInfoChipOwnProps => {
  const chainInfoMobile = useMediaQuery(
    "(min-width: 1100px) and (max-width: 1350px), (max-width: 700px)"
  );

  const chainInfoProps = chainMeta;
  const chainInfoMobileProps = chainInfoMobile ? { abbr: undefined } : undefined;
  return { ...chainInfoProps, ...chainInfoMobileProps };
};

interface VaultsInteractionPanelProps {}

export const VaultsInteractionPanel = observer((_props: VaultsInteractionPanelProps) => {
  const { withdrawer, chainMeta, refreshBalances, vaultsDeployed, refreshChainBalances } =
    useLateInitContext(VaultsContext);

  const chainInfoProps = useChainInfoProps(chainMeta);

  return (
    <WalletsInteractionPanel
      withdrawer={withdrawer}
      walletsDeployed={vaultsDeployed}
      chainInfoProps={chainInfoProps}
      onRefreshBackendBalances={refreshBalances}
      onRefreshChainBalances={refreshChainBalances}
    />
  );
});
