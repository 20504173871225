import { observer } from "mobx-react-lite";
import { MultiGridStateContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import { FullSizeProps } from "src/modules/shared";
import { GridStateStep } from "src/state/CEX/multiGrid/MultiGridState";
import * as styles from "./style";

const STEPS = [
  { label: "Before", value: "update_status" },
  { label: "After", value: "complete" },
];

interface StateHeaderProps extends FullSizeProps {}

export const StateHeader = observer(({ isFullscreen, toggleFullscreen }: StateHeaderProps) => {
  const state = useLateInitContext(MultiGridStateContext);

  const onChangeKey = (value: string) => {
    const key = value as GridStateStep;
    state.toggleStep(key);
  };

  return (
    <styles.HeaderContainer>
      <styles.TimeWrapper>
        <styles.TimeContainer>
          <styles.InfoText>Time:</styles.InfoText>
          <styles.InfoText>
            {state.createdTime ? unixToDateFormat(state.createdTime, "FullDate") : "_"}
          </styles.InfoText>
        </styles.TimeContainer>

        <styles.FullscreenButton isFullscreen={isFullscreen} onClick={toggleFullscreen}>
          {Icons.fullScreen()}
        </styles.FullscreenButton>
      </styles.TimeWrapper>

      <styles.StepsSwitching
        border={false}
        selectedItem={state.currentStep}
        direction="row"
        menuItems={STEPS}
        onChange={onChangeKey}
      />
    </styles.HeaderContainer>
  );
});
