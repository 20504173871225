import { observer } from "mobx-react-lite";
import { Button, ButtonProps } from "src/components/shared/Buttons/v2/Button";
import { useSwapWidgetState } from "../../../hooks/useSwapWidgetState";

export interface SwapButtonProps extends Omit<ButtonProps, "onClick"> {}

export const SwapButton = observer((props: SwapButtonProps) => {
  const state = useSwapWidgetState();
  return (
    <Button onClick={state.swap} {...props}>
      Swap
    </Button>
  );
});
