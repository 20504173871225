import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { Popup } from "reactjs-popup";
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";
import { PopupContext } from "src/context/shared/PopupContext";

type PopupPropsWithoutTrigger = Omit<PopupProps & React.RefAttributes<PopupActions>, "trigger">;

export interface PopupComponentProps extends PopupPropsWithoutTrigger {
  children: ReactNode;
  trigger?: JSX.Element | ((isOpen: boolean) => JSX.Element);
  zIndex?: number;
}

export const PopupComponent = observer(
  ({ children, trigger, zIndex, overlayStyle, ...props }: PopupComponentProps) => {
    const zIndexStyle = zIndex ? { zIndex } : {};
    const overlayStyles = { ...overlayStyle, ...zIndexStyle };

    return (
      <Popup
        trigger={trigger}
        modal
        overlayStyle={overlayStyles}
        closeOnDocumentClick={false}
        {...props}
      >
        {
          ((close: () => void) => (
            <PopupContext.Provider value={{ close }}>{children}</PopupContext.Provider>
          )) as unknown as React.ReactNode
        }
      </Popup>
    );
  }
);
