import { IDisposable } from "src/helpers/utils";
import { UserManagerStore } from "..";
import AddPartyStore from "./AddPartyStore";
import AddScopeStore from "./AddScopeStore";
import EditScopeStore from "./EditScopeStore";

export type ListUpdate = () => void;
export interface ScopesUpdate {
  getScopesList: ListUpdate;
  getPartiesList: ListUpdate;
}

export class ScopesStore implements IDisposable {
  private _userManagerState: UserManagerStore;

  scopesState;

  constructor(userManagerState: UserManagerStore) {
    this._userManagerState = userManagerState;
    this.scopesState = {
      addScopeState: new AddScopeStore(this._userManagerState),
      addPartyState: new AddPartyStore(this._userManagerState),
      editScopeState: new EditScopeStore(this._userManagerState),
    };
  }

  destroy() {
    for (const store of Object.values(this.scopesState)) {
      store.destroy();
    }
  }
}
