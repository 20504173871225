import { FieldError, FieldErrors, FieldPath, FieldValues } from "react-hook-form";
import { getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";

export const getFieldError = <TForm extends FieldValues, TName extends FieldPath<TForm>>(
  errors: FieldErrors<TForm>,
  name: TName
) => {
  // for structures with several levels of nesting
  const [path, endKey] = getPathAndKey(name);
  const targetData = getTargetValueByPath(errors, path, false);

  if (!targetData) return undefined;

  return targetData[endKey] as FieldError | undefined;
};
