import { ComponentPropsWithoutRef } from "react";
import { Link } from "react-router-dom";
import { OwnRefProps } from "src/helpers/utils";
import { ButtonOwnProps } from "../Button";
import * as styles from "./style";

type LinkElement = "a" | typeof Link;
export type LinkButtonProps<T extends LinkElement> = Omit<
  ComponentPropsWithoutRef<T>,
  keyof ButtonOwnProps
> &
  OwnRefProps<T> &
  Omit<ButtonOwnProps, "as"> & {
    as?: T;
  };

export const LinkButton = <T extends LinkElement = "a">({ as, ...props }: LinkButtonProps<T>) => {
  const forwardedAs = as ?? "a";
  return <styles.StyledLinkButton<LinkElement> forwardedAs={forwardedAs} {...props} />;
};
