import { StyledProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";

export interface PriceDirectionProps {
  direction: "profit" | "loss" | "neutral";
}

export interface StyledPriceDirectionProps extends StyledProps<PriceDirectionProps> {}

export const PriceDirectionColorMixin = css<StyledPriceDirectionProps>`
  color: ${({
    $direction = "neutral",
    theme: {
      dashboard: { accent },
    },
  }) => {
    switch ($direction) {
      case "profit":
        return accent.darkLime;
      case "loss":
        return accent.rubyRed;
      case "neutral":
        return "inherit";
    }
  }};
`;

export const StyledPNLValue = styled.span<StyledPriceDirectionProps>`
  font-size: ${({ theme }) => theme.fontSizes["15px"]};
  font-weight: ${({ theme }) => theme.fontWeights["600"]};
  color: ${({ theme }) => theme.dashboard.textHeading};
  line-height: 20px;
  letter-spacing: 0.5px;

  strong {
    ${PriceDirectionColorMixin}
  }
`;
