import { observer } from "mobx-react-lite";
import { useSortBy, useTable } from "react-table";
import * as styles from "./style";

interface Props {
  data: any;
  columns: any;
  label?: string;
  btnLabel?: string;
  filter?: JSX.Element;
}

export const ListContent = observer(
  ({ label, filter, btnLabel, data, columns, ...props }: Props) => {
    const options: any = {
      data: data.slice(),
      columns,
      autoResetSortBy: false,
    };

    const { rows, prepareRow, getTableProps, getTableBodyProps } = useTable(options, useSortBy);

    return (
      <styles.TableContent {...getTableProps()}>
        <styles.Body {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const { key, ...rowProps } = row.getRowProps();
            return (
              <styles.Row key={key} {...rowProps}>
                <styles.Cell>{index + 1}</styles.Cell>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps();
                  return (
                    <styles.Cell key={key} {...cellProps}>
                      {cell.render("Cell")}
                    </styles.Cell>
                  );
                })}
              </styles.Row>
            );
          })}
        </styles.Body>
      </styles.TableContent>
    );
  }
);
