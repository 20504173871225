import { ComponentPropsWithoutRef } from "react";
import { formatPNLWithSign } from "src/helpers/string";
import * as styles from "./style";

export interface PNLValueProps extends ComponentPropsWithoutRef<"span"> {
  children: number | null;
}

interface UsePnlValueResponse extends styles.PriceDirectionProps {
  formattedValue: string;
  showSymbol: boolean;
}

const usePnlValue = (value: number | null): UsePnlValueResponse => {
  if (value === null) return { direction: "neutral", formattedValue: "-", showSymbol: false };
  const direction = Math.sign(value) > 0 ? "profit" : "loss";
  const formattedValue = formatPNLWithSign(value, "fiat", false);
  return { direction, formattedValue, showSymbol: true };
};

export const PNLValue = ({ children: value, ...props }: PNLValueProps) => {
  const { direction, formattedValue, showSymbol } = usePnlValue(value);

  return (
    <styles.StyledPNLValue $direction={direction} {...props}>
      <strong>{formattedValue}</strong> {showSymbol && "$"}
    </styles.StyledPNLValue>
  );
};
