import { TableCellText } from "src/components/shared/TableV2";
import styled from "styled-components";

export const StyledText = styled(TableCellText)`
  height: auto;
  overflow-y: auto;

  align-items: start;
  justify-content: flex-start;
  margin: auto;

  white-space: pre-wrap;
  word-break: break-word;
`;

export const TextWrapper = styled.div`
  height: 50px;
  min-width: 120px;
  display: flex;
  flex-direction: column;
`;
