import styled from "styled-components";
import {
  Body,
  Cell,
  Footer,
  Header,
  HeaderCell,
  TableContainer,
  TableRow,
  TableSortMixin,
} from "src/components/shared/GridTable";

export const TableContent = styled(TableContainer)``;

export const Head = styled(Header)``;

export const TableBody = styled(Body)``;

export const Row = styled(TableRow)``;

export const HeaderRow = styled(TableRow)``;

export const TableHeaderCell = styled(HeaderCell)`
  height: 30px;

  padding: 5px;

  ${TableSortMixin}
`;

export const TableHeaderCellContent = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const TableCell = styled(Cell)`
  height: 32px;

  padding: 5px;
`;

export const TableFooter = styled(Footer)``;

export const FooterRow = styled(Row)``;

export const FooterCell = styled(TableCell)`
  height: 20px;

  position: sticky;
  bottom: 0;
  z-index: 1;

  padding-bottom: 0;
  background: ${({ theme }) => theme.contentBackgroundColor};
  border-top: 1px solid ${({ theme }) => theme.lineColor};
  font-weight: ${({ theme }) => theme.fontWeights[600]};
`;
