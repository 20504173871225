import { Controller, useFormContext } from "react-hook-form";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ERRORS_MSGS, hookFormStrictlyGreaterThan } from "src/validation-schemas";
import { ModuleSoftParamsShell } from "../../../../shared";
import { SoftParamsFormsProps } from "../../../../shared/ModuleSoftParamsShell/types";
import { InputWrapper } from "../../../../shared/style";

const SIDE_TYPE = ["buy", "sell"];

interface TradeProps extends SoftParamsFormsProps {}

export const Trade = ({ index, ...props }: TradeProps) => {
  const { control } = useFormContext<IStrategyCreator>();

  return (
    <ModuleSoftParamsShell {...props}>
      <InputWrapper>
        <Controller
          control={control}
          name={`actions.${index}.soft_params.side`}
          rules={{ required: ERRORS_MSGS.isRequired }}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            const error = getFieldError(errors, `actions.${index}.soft_params.side`);
            return (
              <LabeledSelector
                label="Side"
                menuPosition="fixed"
                options={getSelectorList(SIDE_TYPE)}
                onChange={(data) => onChange(data?.value)}
                value={stringToSelectorValue(value)}
                errorHint={error?.message}
              />
            );
          }}
        />

        <Controller
          control={control}
          name={`actions.${index}.soft_params.price`}
          rules={{
            required: ERRORS_MSGS.isRequired,
            validate: hookFormStrictlyGreaterThan(0, "The value must be positive"),
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            const error = getFieldError(errors, `actions.${index}.soft_params.price`);

            return (
              <LabeledInput
                label="Price"
                type="number"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e))}
                errorHint={error?.message}
              />
            );
          }}
        />
        <Controller
          control={control}
          name={`actions.${index}.soft_params.amount`}
          rules={{
            required: ERRORS_MSGS.isRequired,
            validate: hookFormStrictlyGreaterThan(0, "The value must be positive"),
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            const error = getFieldError(errors, `actions.${index}.soft_params.amount`);
            return (
              <LabeledInput
                label="Amount"
                type="number"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e))}
                errorHint={error?.message}
              />
            );
          }}
        />
      </InputWrapper>
    </ModuleSoftParamsShell>
  );
};
