import { ComponentPropsWithoutRef } from "react";
import { Headline3 } from "src/components/shared/Typography";
import * as styles from "./style";

interface SettingsPanelProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
  afterTitle?: JSX.Element | undefined;
}

export const SettingsPanel = ({ title, afterTitle, children, ...props }: SettingsPanelProps) => (
  <styles.SettingsPanel {...props}>
    <styles.TitleWrapper>
      <Headline3>{title}</Headline3>
      {afterTitle}
    </styles.TitleWrapper>

    {children}
  </styles.SettingsPanel>
);
