import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { DEXV2BotProps } from "src/components/AllBots/Bots/DEXV2Bots/DEXV2Bot";
import { DEXV2BotMobProps } from "src/components/AllBots/Bots/DEXV2Bots/DEXV2BotMob";
import { joinMarket } from "src/helpers/botName";
import useLocalStore from "src/hooks/useLocalStore";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useAppState } from "src/state";
import DEXV2BotInfoStore from "src/state/DEXV2/DEXV2Bots/DEXV2BotInfoStore";
import * as styles from "./style";

export interface DEXV2BotInfoProps
  extends Pick<DEXV2BotMobProps, "showStartBtn" | "compactQuery">,
    React.ComponentPropsWithoutRef<"div"> {
  bot_uuid: string;
  mobileQuery?: string;
}

export const DEXV2_HEADER_INFO_CELLS = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Chain", icon: "" },
  { title: "Limit", icon: "" },
  { title: "Volume", icon: "" },
  { title: "Counter", icon: "" },
];

const DEFAULT_MOBILE_QUERY = "screen and (max-width: 850px)";

export const DEXV2BotInfo = observer(
  ({
    bot_uuid,
    mobileQuery = DEFAULT_MOBILE_QUERY,
    compactQuery,
    showStartBtn,
    ...props
  }: DEXV2BotInfoProps) => {
    const { chainMetaState } = useAppState();
    const state = useLocalStore(DEXV2BotInfoStore, chainMetaState);

    const showMobVers = useMediaQuery(mobileQuery);

    useEffect(() => {
      if (!bot_uuid) return;

      state.setBotUUID(bot_uuid);

      state.resumeBotFetching();

      return () => {
        state.suspendBotFetching();
      };
    }, [bot_uuid, state]);

    const { base, base_addr, quote, quote_addr, ...otherProps } = state.bot;
    const { name, exchange } = state.bot;

    const statsLink = `/DEX_V2/${bot_uuid}/?tab=STATS`;

    const market = joinMarket({ quote, base, exchange });

    const credential = {
      market,
      name,
      UUID: bot_uuid,
      link: statsLink,
    };

    const botProps: DEXV2BotProps = {
      credential,
      favorite: false,
      ...otherProps,
    };

    return (
      <styles.Container {...props}>
        {!showMobVers ? <styles.HeaderForPages cells={DEXV2_HEADER_INFO_CELLS} /> : null}
        {showMobVers ? (
          <styles.DEXV2BotMobForPages
            {...botProps}
            showStartBtn={showStartBtn}
            onStartClick={state.toggleBot}
            compactQuery={compactQuery}
          />
        ) : (
          <styles.DEXV2BotForPages
            {...botProps}
            showStartBtn={showStartBtn}
            onStartClick={state.toggleBot}
          />
        )}
      </styles.Container>
    );
  }
);
