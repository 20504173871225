import { makeAutoObservable } from "mobx";
import { GetVolumeShareResponse, getVolumeShare } from "src/api/bots/CEX/dashboard";
import { Mapper } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { valuesToNumber } from "../../utils";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type VolumeData = {
  total: number[];
  notOur: number[];
  our: number[];
  exchanges: string[];
};

const INITIAL_DATA: VolumeData = {
  total: [],
  notOur: [],
  our: [],
  exchanges: [],
};

const dataToVolume = (data: GetVolumeShareResponse["our"]) => {
  const values = Object.values(data);
  return valuesToNumber(values);
};

const volumeResponseToData: Mapper<GetVolumeShareResponse, VolumeData> = ({
  our,
  total,
  another,
}) => {
  const exchanges = Object.keys(our);

  return {
    our: dataToVolume(our),
    notOur: dataToVolume(another),
    total: dataToVolume(total),
    exchanges,
  };
};

interface IVolumeStoreParams extends IBaseDashboardV2StoreParams {}

export class VolumeV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: VolumeData = INITIAL_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IVolumeStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: VolumeData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get exchanges() {
    return this._data.exchanges;
  }

  get our() {
    return this._data.our;
  }

  get notOur() {
    return this._data.notOur;
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;
    if (!requestParams) return;

    const { party, queryParams } = requestParams;

    this._setData(INITIAL_DATA);
    try {
      const { data, isError } = await getVolumeShare(party, queryParams);

      if (!isError) {
        const volumeData = volumeResponseToData(data);
        this._setData(volumeData);
      }
    } catch {
      this._setData(INITIAL_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
