import { observer } from "mobx-react-lite";
import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { labelErrorFromHint } from "src/components/shared/Forms/Label";
import { TimeLine } from "src/components/shared/TimeLine";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SettingsToggleSwitch } from "../shared/SettingsToggleSwitch";
import * as styles from "./style";

interface Props {
  party: string;
  showLoader: boolean;
}

const SPREAD_INFO = `Spread:
Counter on timeline - amount of decompressions during chosen time period

Current limit - current maximum for one decompression after punishes

Onetime decompression limit - Initial maximum for one decompression

Punish multiplier - coef of current limit reduction per each decompression

Punish max count - maximum amount of decompressions before stop

Punish period - dynamic time window to calculate decompressions`;

const getModeError = (mode: "alongMode" | "oppositeMode", errors: any) => {
  const spreadDecompressionErrors = errors.settings?.spreadDecompression;
  const modeErrorsMap = {
    "Onetime limit": spreadDecompressionErrors?.[mode]?.oneTimeLimit,
    "Day limit": spreadDecompressionErrors?.[mode]?.dayLimit,
  };

  const modeErrors = Object.entries(modeErrorsMap).map(([limitType, limitError]) => {
    if (!limitError) return "";
    return limitType.concat(": ", limitError);
  });

  const errorText = modeErrors.filter(Boolean).join(",\n");
  return errorText;
};

const idForMode = (mode: "alongMode" | "oppositeMode") => {
  switch (mode) {
    case "alongMode": {
      return "along-mode";
    }
    case "oppositeMode": {
      return "opposite-mode";
    }
  }
};

const getModeLabelError = (mode: "alongMode" | "oppositeMode", errors: any) => {
  const alongModeError = getModeError(mode, errors);
  return labelErrorFromHint(idForMode(mode), alongModeError);
};

export const SpreadSettings = observer(({ showLoader, party }: Props) => {
  const state = useLateInitContext(CEXSettingsContext);

  const alongModeError = getModeLabelError("alongMode", state.errors);
  const oppositeModeError = getModeLabelError("oppositeMode", state.errors);

  return (
    <SettingsPanel
      label="Spread Decompression"
      party={party}
      submitHandler={state.submitModulHandler("spreadDecompression")}
      loader={showLoader}
      toggle={
        <SettingsToggleSwitch
          party={party}
          checked={state.data.settings.spreadDecompression.on}
          onChange={state.getOnHandler("spreadDecompression")}
        />
      }
      info={SPREAD_INFO}
      saved={state.savedModuls.spreadDecompression}
      disabled={!state.data.settings.spreadDecompression.on}
      style={{ gridArea: "spread" }}
    >
      <TimeLine
        endTime={state.endTime}
        period={state.spreadPeriod}
        points={state.decompSpread}
        style={{ paddingTop: "0px", marginBottom: "20px", marginTop: "10px" }}
        labelNested="Decompr per period"
        intervalStep={state.periodSteps}
      />

      <styles.SpreadLimitsTable>
        <styles.SpreadLimitsColumnHeader
          style={{ gridColumn: "items-start" }}
          error={alongModeError}
          htmlFor={idForMode("alongMode")}
        >
          Along with mode
        </styles.SpreadLimitsColumnHeader>
        <styles.SpreadLimitsColumnHeader
          error={oppositeModeError}
          htmlFor={idForMode("oppositeMode")}
        >
          Opposite to mode
        </styles.SpreadLimitsColumnHeader>

        <styles.SpreadLimitsRowHeader>Onetime limit $</styles.SpreadLimitsRowHeader>

        <LabeledInput
          type="number"
          // @ts-ignore
          id={idForMode("alongMode")}
          label=""
          value={state.data.settings?.spreadDecompression?.alongMode?.oneTimeLimit}
          onChange={state.getHandler("settings.spreadDecompression.alongMode.oneTimeLimit")}
          // @ts-ignore
          getError={state.getError("settings.spreadDecompression.alongMode.oneTimeLimit")}
          errorHint={state.errors.settings?.spreadDecompression?.alongMode?.oneTimeLimit}
        />

        <LabeledInput
          type="number"
          // @ts-ignore
          id={idForMode("oppositeMode")}
          label=""
          value={state.data.settings?.spreadDecompression?.oppositeMode?.oneTimeLimit}
          onChange={state.getHandler("settings.spreadDecompression.oppositeMode.oneTimeLimit")}
          // @ts-ignore
          getError={state.getError("settings.spreadDecompression.oppositeMode.oneTimeLimit")}
          errorHint={state.errors.settings?.spreadDecompression?.oppositeMode?.oneTimeLimit}
        />

        <styles.SpreadLimitsRowHeader>Day limit $</styles.SpreadLimitsRowHeader>

        <LabeledInput
          type="number"
          label=""
          value={state.data.settings.spreadDecompression.alongMode.dayLimit}
          onChange={state.getHandler("settings.spreadDecompression.alongMode.dayLimit")}
          // @ts-ignore
          getError={state.getError("settings.spreadDecompression.alongMode.dayLimit")}
          errorHint={state.errors.settings?.spreadDecompression?.alongMode?.dayLimit}
        />

        <LabeledInput
          type="number"
          label=""
          value={state.data.settings.spreadDecompression.oppositeMode.dayLimit}
          onChange={state.getHandler("settings.spreadDecompression.oppositeMode.dayLimit")}
          // @ts-ignore
          getError={state.getError("settings.spreadDecompression.oppositeMode.dayLimit")}
          errorHint={state.errors.settings?.spreadDecompression?.oppositeMode?.dayLimit}
        />
      </styles.SpreadLimitsTable>
    </SettingsPanel>
  );
});
