import styled from "styled-components";

export const FormContent = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;

  gap: 15px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 25px;
`;
