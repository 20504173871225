import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 15px;
`;

export const HeaderWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  gap: 4px;
`;
