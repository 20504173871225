import { ComponentType } from "react";
import { FilterProps } from "react-table";
import { PopupComponent, PopupComponentProps } from "src/components/shared/popup/PopupComponent";
import { getNumberRangeColumnFilterCount } from "../NumberRangeColumnFilter";
import { getSelectColumnFilterCount } from "../SelectColumnFilter";
import { ColumnFiltersIds } from "../shared";
import { FilterButton } from "./FilterButton";
import { FilterPanel } from "./FilterPanel";

const FILTERS_MAP: Record<ColumnFiltersIds, FilterCounter<any>> = {
  [ColumnFiltersIds.NumberRange]: getNumberRangeColumnFilterCount,
  [ColumnFiltersIds.Select]: getSelectColumnFilterCount,
};

const getDefaultFilterCount = <D extends Object>(id: ColumnFiltersIds) =>
  FILTERS_MAP[id] as FilterCounter<D>;

interface UseFilterCounterParams<D extends Object>
  extends Pick<FilterDropdownProps<D>, "filterCounter" | "showCounter" | "filterProps"> {
  filterId: ColumnFiltersIds;
}

const useFilterCounter = <D extends Object>({
  filterCounter,
  showCounter,
  filterProps,
  filterId,
}: UseFilterCounterParams<D>) => {
  if (!showCounter && !filterCounter) return undefined;

  const filterFunction = filterCounter || getDefaultFilterCount<D>(filterId);

  if (!filterFunction) return undefined;

  return filterFunction(filterProps);
};

type FilterRenderer<P extends unknown> = ComponentType<P> & {
  filterId: ColumnFiltersIds;
};

export type FilterCounter<D extends Object> = (props: FilterProps<D>) => number | undefined;
export interface FilterDropdownProps<D extends Object> {
  popupProps?: Omit<PopupComponentProps, "trigger">;
  filter: FilterRenderer<FilterProps<D>>;
  filterProps: FilterProps<D>;
  filterCounter?: FilterCounter<D>;
  showCounter?: boolean;
}

export const FilterDropdown = <D extends object>({
  filter: FilterComponent,
  filterCounter,
  showCounter = true,
  popupProps,
  filterProps,
}: FilterDropdownProps<D>) => {
  const Filter = (
    <FilterPanel>
      <FilterComponent {...filterProps} />
    </FilterPanel>
  );

  const counter = useFilterCounter({
    filterId: FilterComponent.filterId,
    filterProps,
    filterCounter,
    showCounter,
  });

  return (
    <PopupComponent
      trigger={<FilterButton count={counter} />}
      modal={false}
      closeOnDocumentClick
      {...popupProps}
    >
      {Filter}
    </PopupComponent>
  );
};
