import { useCallback, useLayoutEffect, useRef } from "react";
import { ChildrenProps } from "src/helpers/utils";
import * as styles from "./style";

export interface MasonryProps
  extends ChildrenProps,
    styles.MasonryStyledProps,
    Pick<React.ComponentPropsWithoutRef<"div">, "style"> {}

export const Masonry = ({ children, ...props }: MasonryProps) => {
  const masonryRef = useRef<HTMLDivElement>(null);

  const handleResize: ResizeObserverCallback = useCallback((masonryChildren) => {
    if (!masonryRef.current || !masonryChildren || masonryChildren.length === 0) {
      return;
    }
    const masonry = masonryRef.current;
    const masonryFirstChild = masonryRef.current.firstElementChild;
    const parentWidth = masonry.clientWidth;
    const firstChildWidth = masonryFirstChild?.clientWidth;

    if (parentWidth === 0 || firstChildWidth === 0) {
      return;
    }

    const masonryComputedStyle = window.getComputedStyle(masonry);
    const rowGap = parseInt(masonryComputedStyle.getPropertyValue("grid-row-gap"), 10);
    const rowHeight = parseInt(masonryComputedStyle.getPropertyValue("grid-auto-rows"), 10);

    masonry.childNodes.forEach((child) => {
      const childElem = child as HTMLElement;

      // Masonry items should have wrapper around its content
      const childContainer = childElem.firstElementChild;
      if (!childContainer) return;

      /*
       * Spanning for any brick = S
       * Grid's row-gap = G
       * Size of grid's implicitly create row-track = R
       * Height of item content = H
       * Net height of the item = H1 = H + G
       * Net height of the implicit row-track = T = G + R
       * S = H1 / T
       */
      const rowSpan = Math.ceil(
        (childContainer.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap)
      );

      /* Set the spanning as calculated above (S) */
      childElem.style.gridRowEnd = `span ${rowSpan}`;
    });
  }, []);

  useLayoutEffect(() => {
    let animationFrame: number;

    const observer = new ResizeObserver((entries, observer) => {
      animationFrame = window.requestAnimationFrame(() => {
        handleResize(entries, observer);
      });
    });

    if (masonryRef.current) {
      masonryRef.current.childNodes.forEach((childNode) => {
        observer.observe(childNode as Element);
      });
    }
    return () => {
      if (animationFrame) {
        window.cancelAnimationFrame(animationFrame);
      }

      observer.disconnect();
    };
  }, [children, handleResize]);

  return (
    <styles.Masonry ref={masonryRef} {...props}>
      {children}
    </styles.Masonry>
  );
};
