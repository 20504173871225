import styled from "styled-components";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { BaseButton } from "src/components/shared/Buttons/v2/shared/style";
import { CardSurface } from "src/components/shared/shared";
import { FullSizeProps } from "src/modules/shared";

interface FullscreenProps extends Pick<FullSizeProps, "isFullscreen"> {}

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin: 4px 0 8px;
`;

export const FullscreenButton = styled(BaseButton).attrs({
  boxed: true,
})<FullscreenProps>`
  color: ${({ theme, isFullscreen }) => (isFullscreen ? theme.primaryColor : theme.textColor)};
  font-size: 28px;

  ${CardSurface}
`;

export const StyledDepthSelector = styled(LabeledSelector)`
  width: 80px;
  box-shadow: ${({ theme }) => theme.shadows.dashboard};
`;

export const RangePickerContainer = styled.div`
  max-width: 340px;
  box-shadow: ${({ theme }) => theme.shadows.dashboard};
`;
