import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
