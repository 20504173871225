import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { ERRORS_MSGS } from "src/validation-schemas";
import { useGetCompare } from "../hooks/useGetCompare";
import * as styles from "./style";

export interface CompareSelectorProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  control: Control<TForm>;
  name: TName;
  onlyEqual?: boolean;
}

export const CompareSelector = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  control,
  name,
  onlyEqual = false,
}: CompareSelectorProps<TForm, TName>) => {
  const { getCompare, compareList } = useGetCompare(onlyEqual);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: ERRORS_MSGS.isRequired }}
      render={({ field: { value, onChange }, formState: { errors } }) => {
        const error = getFieldError(errors, name);

        return (
          <styles.CmpSelector
            label="Compare"
            menuPosition="fixed"
            options={compareList}
            onChange={(data) => onChange(data?.value)}
            value={getCompare(value, "value")}
            errorHint={error?.message}
          />
        );
      }}
    />
  );
};
