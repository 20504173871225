import { observer } from "mobx-react-lite";
import { StrategyListContext } from "src/context/CEX/ExpertSystem";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import StrategyFiltersStore, {
  FiltersKeys,
} from "src/state/expertSystem/strategies/Filters/StrategyFiltersStore";
import { SelectorFilter } from "../../../Modules/shared/ModuleFilters/SelectorFIlter";
import { FilterPanelContent } from "../../../shared/FilterPanel/FilterPanelContent";

export const STRATEGIES_FILTERS_TITLES: Record<FiltersKeys, string> = {
  type: "Execution order",
  status: "Status",
};

export interface FiltersContentProps {}

export const FiltersContent = observer((props: FiltersContentProps) => {
  const { updateFilters, filtersState } = useLateInitContext(StrategyListContext);

  const { close } = useLateInitContext(PopupContext);

  const state = useLocalStore(StrategyFiltersStore, {
    initialState: filtersState,
    onSubmit: updateFilters,
    onClose: close,
  });

  const { getSelectorProps, reset, submit } = state;

  return (
    <FilterPanelContent onReset={reset} onSubmit={submit}>
      <SelectorFilter label={STRATEGIES_FILTERS_TITLES.type} {...getSelectorProps("type")} />
      <SelectorFilter label={STRATEGIES_FILTERS_TITLES.status} {...getSelectorProps("status")} />
    </FilterPanelContent>
  );
});
