import { observer } from "mobx-react-lite";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { useDashboardState } from "../shared/hooks/useDashboardState";
import { SantimentCard } from "./SantimentCard";

export const SantimentOverview = observer((props: CardProps) => {
  const { party, selectedRange } = useDashboardState();

  return (
    <SantimentCard party={party} range={selectedRange} title="Santiment overview" {...props} />
  );
});
