import { IWalletsProvider } from "../WalletInputStore";
import { IBlacklistWalletsProvider } from "./BlacklistWalletsStore";

export class BlacklistWalletsAdapter implements IWalletsProvider {
  private _provider: IBlacklistWalletsProvider;

  constructor(provider: IBlacklistWalletsProvider) {
    this._provider = provider;
  }

  get wallets(): string[] {
    return this._provider.blacklistWallets;
  }

  setWallets = (wallets: string[]) => {
    this._provider.setBlacklistWallets(wallets);
  };
}
