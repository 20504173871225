import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { checkParty } from "src/helpers/checkParty";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useAppState } from "src/state";
import { BotInfo } from "../..";
import { TradeHistory } from "../TradeHistory";
import { ExchNotes } from "../shared";
import { LiquidityForm } from "./LiquidityForm";
import { AccountHistory } from "./Orders/AccountHistory";
import { FloatingOrders } from "./Orders/FloatingOrders";
import { LimitOrders } from "./Orders/LimitOrders";
import { StopOrders } from "./Orders/StopOrders";
import { TransferForm } from "./TransferForm";
import { VolumeForm } from "./VolumeForm";
import * as styles from "./style";

interface Props {
  abilityName: string;
  showTradeHistory: boolean;
  showNotes?: boolean;
  botInfo: BotInfo;
}

const TRADE_TABS = [
  { label: "Transfer", value: "TRANSFER" },
  { label: "Liquidity", value: "LIQUIDITY" },
  { label: "Volume", value: "VOLUME" },
  { label: "Acc history", value: "ACC_HISTORY" },
];

const ALGO_ORDERS_TABS = [
  { label: "Floating", value: "FLOATING" },
  { label: "Stop", value: "STOP" },
];

const NOTES_TAB = { label: "Notes", value: "NOTES" };

export const TradePanel = observer(
  ({ botInfo, abilityName, showTradeHistory, showNotes = false }: Props) => {
    const mainState = useLateInitContext(ExchangeContext);
    const { userState } = useAppState();
    const { party, bot_uuid } = botInfo;

    const checkPermission = checkParty(userState.abilities, abilityName, party);
    const checkNotesPermission = checkParty(userState.abilities, "cex-notes-view", party);

    const menuItemsDesc = () => {
      const tabs = [{ label: "Limit", value: "ORDERS" }];

      if (checkPermission && mainState.wsSupport) {
        tabs.push(...ALGO_ORDERS_TABS, ...TRADE_TABS);
      } else if (checkPermission) {
        tabs.push(...TRADE_TABS);
      }

      return tabs;
    };

    const menuItemsMob = () => {
      const tabs = [{ label: "Limit", value: "ORDERS" }];

      if (checkPermission && mainState.wsSupport) {
        tabs.push(...ALGO_ORDERS_TABS, ...TRADE_TABS, {
          label: "History",
          value: "HISTORY",
        });
      } else if (checkPermission) {
        tabs.push(...TRADE_TABS, {
          label: "History",
          value: "HISTORY",
        });
      } else {
        tabs.push({
          label: "History",
          value: "HISTORY",
        });
      }
      if (showNotes && checkNotesPermission) {
        tabs.push(NOTES_TAB);
      }

      return tabs;
    };

    const update = useCallback(() => {
      if (!mainState.market) return;

      mainState.updAllData();
    }, [mainState]);

    return (
      <styles.Container>
        <styles.ExchangeTabs
          menuItems={!showTradeHistory ? menuItemsDesc() : menuItemsMob()}
          defaultKey="ORDERS"
          onClickActive={(value: string) =>
            (value === "ORDERS" && mainState.updHandlers.updLimitOrders()) ||
            (value === "STOP" && mainState.updHandlers.updStopOrders()) ||
            (value === "FLOATING" && mainState.updHandlers.updFloatingOrders())
          }
          afterSwitch={<ReloadButton onClick={() => update()} />}
        >
          <LimitOrders key="ORDERS" party={party} abilityName={abilityName} />

          {checkPermission && mainState.wsSupport ? <StopOrders key="STOP" party={party} /> : null}

          {checkPermission && mainState.wsSupport ? <FloatingOrders key="FLOATING" /> : null}

          {checkPermission ? <TransferForm key="TRANSFER" /> : null}

          {checkPermission ? <LiquidityForm key="LIQUIDITY" /> : null}

          {checkPermission ? <VolumeForm key="VOLUME" /> : null}

          {checkPermission ? <AccountHistory key="ACC_HISTORY" party={party} /> : null}

          {!showTradeHistory ? null : <TradeHistory key="HISTORY" />}
          {!showTradeHistory || !showNotes || !checkNotesPermission ? null : (
            <ExchNotes key="NOTES" party={party} botUUID={bot_uuid} />
          )}
        </styles.ExchangeTabs>
      </styles.Container>
    );
  }
);
