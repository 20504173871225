import {
  ConstraintsInputProps,
  errorTypeColors,
  InputColorMixin,
} from "src/components/BotsContent/CEX/CEXBotSettings/shared";
import { Subtitle } from "src/components/shared/Typography";
import styled, { css } from "styled-components";

export interface HashLabelProps extends ConstraintsInputProps {}

export const Container = styled.div<HashLabelProps>`
  display: flex;
  align-items: center;

  ${({ theme, errorType }) => {
    if (!errorType) return;
    const contentColor =
      errorType === "error" ? theme.errorLabelColor : errorTypeColors(theme, errorType).content;

    return css`
      ${HashLabel} {
        color: ${contentColor};
      }
    `;
  }}

  ${({ theme, errorType }) => {
    switch (errorType) {
      case "warning":
      case "alert": {
        return InputColorMixin(theme, errorType);
      }
    }
  }}
`;

export const HashLabel = styled(Subtitle)`
  min-width: 0%;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;
