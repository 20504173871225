import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import { ButtonsRow } from "./ButtonsRow";
import { SelectorsRow } from "./SelectorsRow";
import * as styles from "./style";

export interface HeaderProps {}

export const Header = observer((props: HeaderProps) => {
  const isMobile = useMediaQuery(getMobileMediaQuery("650px"));

  return (
    <styles.Container>
      <SelectorsRow isMobile={isMobile} />
      <ButtonsRow />
    </styles.Container>
  );
});
