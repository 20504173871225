import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { ModalPanelV2 } from "src/components/shared/ModalPanelV2";
import { BotModulesContext } from "src/context/CEX/BotModules/BotModulesProvider";
import { AbilitiesContext } from "src/context/shared/Abilities";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { ModuleType } from "..";
import { CreateExchangeModule } from "../CreateModule/CreateExchangeModule";
import { CreateMainBotModule } from "../CreateModule/CreateMainBotModule";
import { CreateSwapModule } from "../CreateModule/CreateSwapModule";

export interface CreateModalProps {
  type: ModuleType;
  label?: string;
}

export const CreateModal = observer(({ label, type }: CreateModalProps) => {
  const state = useLateInitContext(BotModulesContext);

  const { abilityName } = useLateInitContext(AbilitiesContext);

  const getContent = useCallback((moduleType: ModuleType) => {
    switch (moduleType) {
      case "SWAP":
        return <CreateSwapModule />;

      case "MAINBOT":
        return <CreateMainBotModule />;

      case "EXCHANGE":
        return <CreateExchangeModule />;

      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  }, []);

  return (
    <PermissionWrapper abilityName={abilityName} party={state.party}>
      <ModalPanelV2 label={label || ""} trigger={<AddButton />} loading={state.isCreateLoading}>
        {getContent(type)}
      </ModalPanelV2>
    </PermissionWrapper>
  );
});
