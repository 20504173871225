import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 8px 0px 8px 16px;
  gap: 16px;

  border: 1.5px solid;
  border-right: none;
  border-left: none;
  border-color: ${({ theme }) => theme.headerBorder};
`;
