import {
  INFO_TOOLTIP_CLASSNAME,
  InfoTooltip,
} from "src/components/AllBots/Bots/DEXV2Bots/shared/ModeChip/ModeInfoTooltip/style";
import styled from "styled-components";

export const QuotesTooltip = styled(InfoTooltip)`
  &.${INFO_TOOLTIP_CLASSNAME} {
    width: auto;
  }
`;
