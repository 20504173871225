import styled from "styled-components";

export const RowBot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:first-of-type {
    margin-right: 27px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
`;
