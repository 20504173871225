import { observer } from "mobx-react-lite";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Switches } from "src/components/shared/Switches";
import { CreateBotContext } from "src/context/CEX/CreateBotProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SettingsPanel } from "../SettingsPanel";
import { Wrapper } from "../shared";

const ITEMS = [
  { value: 1, label: "1" },
  { value: 5, label: "5" },
];

export const PeriodSettings = observer(() => {
  const state = useLateInitContext(CreateBotContext);

  return (
    <SettingsPanel style={{ gridArea: "period" }} title="Period">
      <Wrapper>
        <LabeledInput
          type="number"
          label="Last Price"
          value={state.data.lastPrice ?? ""}
          onChange={state.getHandler("lastPrice")}
          errorHint={state.errors.lastPrice}
          step="any"
        />
        <Switches
          label="Period"
          items={ITEMS}
          selectedItem={state.data.settings?.period?.value ?? ""}
          onChange={state.getHandler("settings.period.value")}
        />
      </Wrapper>
    </SettingsPanel>
  );
});
