import styled from "styled-components";
import { Column } from "../style";

export const AddLabelForm = styled.form`
  height: 150px;

  display: flex;
`;

export const Wrapper = styled(Column)`
  justify-content: space-between;
`;
