import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SpectrContext } from "../shared/context";
import * as styles from "./style";

export interface SpectrProps {
  botUUID?: string;
  startPoint?: number;
  endPoint?: number;
}

export const Spectr = observer(({ botUUID, startPoint = 0, endPoint = 0 }: SpectrProps) => {
  const state = useLateInitContext(SpectrContext);

  const spectr = useRef<HTMLDivElement | null>(null);
  const spectrLine = useRef<HTMLCanvasElement | null>(null);

  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    if (spectr && spectr.current) {
      const { clientWidth } = spectr.current;
      setWidth(clientWidth);
    }
  }, [spectr]);

  useEffect(() => {
    if (!botUUID) return;
    state.setBotUUID(botUUID);
    state.getStatusList(startPoint, endPoint);
  }, [state, botUUID, startPoint, endPoint]);

  useEffect(() => {
    const spectrCurrent = spectr.current;

    if (!spectrCurrent) return;

    state.setTimePeriod((endPoint - startPoint) / 60);

    updateWidth();

    state.setSpectrWidth(width);

    state.createTimeIntervals(width);

    const resize = () => {
      updateWidth();

      state.createTimeIntervals(width);
    };

    const cb = (e: MouseEvent) => {
      state.changeStatus(e, spectrLine.current);
      state.showStatusTime(e, width, spectrLine.current);
    };

    window.addEventListener("resize", resize);

    spectrCurrent.addEventListener("mousemove", cb);

    return () => {
      window.removeEventListener("resize", resize);

      spectrCurrent.removeEventListener("mousemove", cb);
    };
  }, [state, startPoint, endPoint, updateWidth, width]);

  return (
    <styles.SpectrContainer>
      <styles.SpectrTimeLine ref={spectr}>
        <styles.Spectr id="spectr" ref={spectrLine} />
        {state.timeIntervals.map((el, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <styles.TimeInterval style={{ width: `${el.widthInterval}px` }} key={index}>
            {el.timeInterval}
          </styles.TimeInterval>
        ))}
      </styles.SpectrTimeLine>
    </styles.SpectrContainer>
  );
});
