import { useCallback } from "react";
import { FilterProps } from "react-table";
import { FiltersColumnProps } from "../SelectColumnFilter";
import { ColumnFiltersIds } from "../shared";
import { RangeFilterInput } from "./RangeFilterInput";
import * as styles from "./style";

export type NumberFilterRange = [number | "", number | ""];

const EMPTY_FILTER_RANGE: NumberFilterRange = ["", ""];

export const getNumberRangeColumnFilterCount = <D extends object>(
  props: NumberRangeColumnFilterProps<D>
): number | undefined => {
  const filterValues: NumberFilterRange = props.column.filterValue ?? EMPTY_FILTER_RANGE;
  return filterValues.reduce<number>(
    (count, value) => (typeof value === "number" ? count + 1 : count),
    0
  );
};

export interface NumberRangeColumnFilterProps<D extends object> extends FilterProps<D> {}

export const NumberRangeColumnFilter = <D extends object>({
  column,
}: NumberRangeColumnFilterProps<D>) => {
  const { filterValue = EMPTY_FILTER_RANGE, setFilter } = column as FiltersColumnProps<
    D,
    NumberFilterRange
  >;

  const [min, max] = filterValue;

  const onInputChange = useCallback(
    (type: "min" | "max") => (value: number | "") => {
      setFilter((rangeValue = EMPTY_FILTER_RANGE) => {
        const [prevMin, prevMax] = rangeValue;
        return type === "max" ? [prevMin, value] : [value, prevMax];
      });
    },
    [setFilter]
  );

  return (
    <styles.Container>
      <RangeFilterInput value={min} onChange={onInputChange("min")} operator="&ge;" />
      <RangeFilterInput value={max} onChange={onInputChange("max")} operator="&le;" />
    </styles.Container>
  );
};

NumberRangeColumnFilter.filterId = ColumnFiltersIds.NumberRange;
