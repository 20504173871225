import { makeAutoObservable } from "mobx";
import { TabStepNavigationAction } from "src/components/BotCreating/DEX";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { IBotUUIDProvider } from "../DEXV2Bots/DEXV2BotStore";
import { INITIAL_PAIR_TICKERS } from "../DEXV2Stats/Vaults";
import VaultsProviderStore, { IVaultsProvider } from "../DEXV2Stats/Vaults/VaultsProvider";
import DeployerInfoStore from "./DeployerInfoStore";

export default class TokenFillStore implements IBotUUIDProvider, IDisposable {
  private _loading = false;

  private _botUUID = "";

  private _deployerInfo: DeployerInfoStore;

  private _vaultsProvider: IVaultsProvider;

  constructor(deployerInfo: DeployerInfoStore) {
    makeAutoObservable(this);

    this._deployerInfo = deployerInfo;

    this._vaultsProvider = new VaultsProviderStore({
      chainProvider: deployerInfo,
      addressesProvider: deployerInfo,
      botUUIDProvider: this,
    });

    makeLoggable<any>(this, { vaults: true, actionsDisabled: true });
  }

  get vaults() {
    return this._vaultsProvider.vaults;
  }

  get roundedVaults() {
    return this._vaultsProvider.roundedVaults;
  }

  get actionsDisabled() {
    return this.vaults.length === 0;
  }

  get tickers() {
    return this._vaultsProvider.tickers ?? INITIAL_PAIR_TICKERS;
  }

  get chainMeta() {
    return this._deployerInfo.chainMeta;
  }

  private get _chainProvider() {
    return this._deployerInfo.chainProvider;
  }

  get chainInfo() {
    return this._chainProvider.currentChain;
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  getVaults = async () => {
    this._setLoading(true);
    try {
      await Promise.all([this._getDeployerInfo(), this._vaultsProvider.getVaults()]);
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  private _getDeployerInfo = async () => {
    await this._deployerInfo.getDeployerInfo(this._botUUID);
  };

  refreshBalances = async () => {
    await this._vaultsProvider.refreshBalances(this._setLoading);
  };

  refreshChainBalances = async () => {
    await this._vaultsProvider.refreshChainBalances(this._setLoading);
  };

  finishDeploy = async (nextStep: TabStepNavigationAction) => {
    const botUUID = this._botUUID;
    if (!botUUID) {
      return;
    }

    nextStep(botUUID);
  };

  destroy = () => {
    this._vaultsProvider.destroy();
  };
}
