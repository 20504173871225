import debounce from "lodash.debounce";
import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useCallback, useMemo } from "react";
import { Mapper } from "src/helpers/utils";
import { Field } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/SwapStateStore";
import { formatCurrencyAmount } from "src/state/DEXV2/DEXV2Swap/utils";
import { useSwapWidgetState } from "../../hooks/useSwapWidgetState";
import { BalanceSpan } from "./BalanceSpan";
import { PriceUSDSpan } from "./PriceUsdSpan";
import * as styles from "./style";

export interface FieldWrapperProps extends ComponentPropsWithoutRef<"div"> {
  field: Field;
}

export type Direction = "buy" | "sell";

const fieldToDirection: Mapper<Field, Direction> = (field: Field) =>
  field === Field.INPUT ? "sell" : "buy";

export const FieldWrapper = observer(({ field, ...props }: FieldWrapperProps) => {
  const state = useSwapWidgetState();
  const { swapState, swapInfoState } = state;
  const amount = swapState.getAmount(field);
  const token = swapState.getToken(field);

  const direction = fieldToDirection(field);
  const inputLabel = `You ${direction}`;

  const updateAmount = swapState.onAmountChange(field);

  const debouncedRefreshSwap = useMemo(() => debounce(state.refreshSwap, 500), [state.refreshSwap]);

  const onUpdateAmount = useCallback(
    (newAmount: string) => {
      updateAmount(newAmount);
      debouncedRefreshSwap();
    },
    [debouncedRefreshSwap, updateAmount]
  );

  const {
    [field]: { amount: infoAmount },
  } = swapInfoState.info;

  const formattedAmount = useMemo(() => {
    if (amount !== undefined) return amount;
    if (!infoAmount) return "";
    return formatCurrencyAmount(infoAmount);
  }, [amount, infoAmount]);

  return (
    <styles.InputCol {...props}>
      <styles.TokenInput
        label={inputLabel}
        onChange={onUpdateAmount}
        value={formattedAmount}
        token={token}
      />
      <styles.InfoRow>
        <PriceUSDSpan field={field} />
        <BalanceSpan field={field} />
      </styles.InfoRow>
    </styles.InputCol>
  );
});
