import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Column } from "react-table";
import { createStringNumberSortBy } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/utils";
import { CurrencyFeeContext } from "src/context/CEX/Dashboard/v2/CurrencyFee";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CurrencyFeeData } from "src/state/CEX/CEXDashboard/v2/widgets/CurrencyFeeV2Store";
import { Card, CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { FiatCell } from "../../../../shared/StatsTable/Cells";
import { DashboardV2Widget, getWidgetTitle } from "../../shared/constants";
import { CurrencyFeeFooter } from "../CurrencyFeeFooter";
import { getFeeTotal } from "../utils/totals";
import * as styles from "./style";

export interface CurrencyFeeTableProps extends Omit<CardProps, "title" | "loading"> {}

const DEFAULT_COLUMN: Partial<Column<CurrencyFeeData>> = {
  disableFilters: true,
  sortType: createStringNumberSortBy(),
};

export const CurrencyFeeTable = observer((props: CurrencyFeeTableProps) => {
  const { data, loading } = useLateInitContext(CurrencyFeeContext.Context);

  const columns = useMemo(() => {
    const columnsSchema: Column<CurrencyFeeData>[] = [
      {
        Header: "Currency",
        accessor: "currency",
        Footer: "Total",
        width: 20,
        disableSortBy: true,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <FiatCell value={value} showSymbol={false} />,
        width: 40,
      },
      {
        Header: "Amount $",
        accessor: "amountUsd",
        Cell: FiatCell,
        Footer: <CurrencyFeeFooter columnId="amountUsd" />,
        width: 40,
      },
    ];

    return columnsSchema;
  }, []);

  return (
    <Card title={getWidgetTitle(DashboardV2Widget.CurrencyFee)} loading={loading} {...props}>
      <styles.StyledCurrencyTable
        columns={columns}
        data={data}
        defaultColumn={DEFAULT_COLUMN}
        getColumnsTotal={getFeeTotal}
      />
    </Card>
  );
});
