import { IReactionDisposer, makeAutoObservable, observable, when } from "mobx";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { ICache, TTLCache } from "src/state/shared/Cache";
import { IRouterProvider } from "../../shared/Providers/RouterProvider";
import { ISwapFactoryAddressProvider } from "../../shared/Providers/SwapSettingsProvider";
import { IRouteStateProvider, IRouter, IRouterParams, Router } from "../../shared/Swap/Router";
import { V2RouteStateProvider } from "../Swap/Providers/V2RouteStateProvider";
import {
  IRoutesProviderParams,
  IV2RoutesProvider,
  V2RoutesProvider,
} from "../Swap/Providers/V2RoutesProvider";
import { V2Pair } from "../Swap/entities/V2Pair";

export interface IV2RouterProviderParams {
  factoryAddressProvider: ISwapFactoryAddressProvider;
  chainProvider: IChainProvider;
}

const ROUTER_PAIRS_TTL = 15 * 1000;

export class V2RouterProvider implements IRouterProvider {
  private _chainProvider: IChainProvider;

  private _factoryAddressProvider: ISwapFactoryAddressProvider;

  private _pairsCache: ICache<V2Pair>;

  private _routeStateProvider: IRouteStateProvider | null = null;

  private _router: IRouter | null = null;

  private _routesProviderReaction: IReactionDisposer;

  private _routerReaction: IReactionDisposer;

  constructor({ factoryAddressProvider, chainProvider }: IV2RouterProviderParams) {
    makeAutoObservable<this, "_routesProvider" | "_router">(this, {
      _router: observable.ref,
      _routesProvider: observable.ref,
    });

    this._factoryAddressProvider = factoryAddressProvider;
    this._chainProvider = chainProvider;

    this._pairsCache = new TTLCache({
      ttl: ROUTER_PAIRS_TTL,
    });

    this._routesProviderReaction = when(
      () => Boolean(this._routerProviderParams),
      () => {
        const providerParams = this._routerProviderParams;
        if (!providerParams) return;
        const routesProvider = new V2RoutesProvider(providerParams);
        this._setRouterProvider(routesProvider);
      }
    );

    this._routerReaction = when(
      () => Boolean(this._routerParams),
      () => {
        const routerParams = this._routerParams;
        if (!routerParams) return;
        const router = new Router(routerParams);

        this._setRouter(router);
      }
    );
  }

  private _setRouterProvider = (routesProvider: IV2RoutesProvider) => {
    this._routeStateProvider = new V2RouteStateProvider({ routesProvider });
  };

  private get _routerProviderParams(): IRoutesProviderParams | null {
    const { provider, chainID } = this._chainProvider;
    const { factoryAddress } = this._factoryAddressProvider;
    const chainId = +chainID;
    if (!provider || !factoryAddress || !chainId) return null;

    return {
      factoryAddress,
      provider,
      chainId,
      pairsCache: this._pairsCache,
    };
  }

  private _setRouter(router: Router) {
    this._router = router;
  }

  private get _routerParams(): IRouterParams | null {
    const routeStateProvider = this._routeStateProvider;
    if (!routeStateProvider) return null;
    return {
      routeStateProvider,
    };
  }

  get router() {
    return this._router;
  }

  destroy() {
    this._pairsCache.clear();
    this._routesProviderReaction();
    this._routerReaction();
  }
}
