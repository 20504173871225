import { makeAutoObservable } from "mobx";
import { GetPartiesMetricsResponse, getPartiesMetrics } from "src/api/bots/CEX/dashboard";
import { Mapper } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type PartyExchange = {
  name: string;
  workingSince: number;
};

export type ExchangeTokens = {
  exchange: PartyExchange;
  tokens: string[];
};

export type PartyKPIInfo = {
  workingSince: number;
  exchanges: ExchangeTokens[];
};

const partiesMetricsToPartyInfo: Mapper<GetPartiesMetricsResponse, PartyKPIInfo> = ({
  created,
  exchanges,
}) => {
  const exchangesTokens = exchanges.map(({ exchange, currencies, created }) => ({
    exchange: {
      name: exchange,
      workingSince: created,
    },
    tokens: currencies,
  }));

  return {
    workingSince: created,
    exchanges: exchangesTokens,
  };
};

const INITIAL_PARTY_DATA: PartyKPIInfo = {
  workingSince: 0,
  exchanges: [],
};

interface IPartiesMetricsStoreParams extends IBaseDashboardV2StoreParams {}

export default class PartiesMetricsV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _partyData: PartyKPIInfo = INITIAL_PARTY_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IPartiesMetricsStoreParams) {
    makeAutoObservable(this);

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    this._stateProvider = stateProvider;
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  get loading() {
    return this._baseState.loading;
  }

  private _setPartyData = (data: PartyKPIInfo) => {
    this._partyData = data;
  };

  get exchanges() {
    return this._partyData.exchanges.map(({ exchange }) => exchange);
  }

  get exchangesTokens() {
    return this._partyData.exchanges;
  }

  get workingSince() {
    return this._partyData.workingSince;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    const { party } = requestParams;

    this._setPartyData(INITIAL_PARTY_DATA);

    try {
      const { data, isError } = await getPartiesMetrics(party);

      if (!isError) {
        const partyData = partiesMetricsToPartyInfo(data);
        this._setPartyData(partyData);
      }
    } catch (error) {
      this._setPartyData(INITIAL_PARTY_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
