import { Content } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel/style";
import styled from "styled-components";
import { AccountForm } from "../../../../shared/AccountForm";
import { SaveButton } from "../../../../shared/SaveButton";

export const Form = styled(AccountForm)``;

export const FormContent = styled(Content)`
  gap: 20px;
`;

export const Button = styled(SaveButton)`
  align-self: flex-end;
  margin-top: 20px;
`;
