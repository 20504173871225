import { observer } from "mobx-react-lite";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import { ApiKeysContext } from "src/context/CEX/ApiKeys/ApiKeys";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { PermissionProps } from "src/permissions/PermissionWrapper/";
import { LiquidityAccounts } from "./LiquidityAccounts";
import { VolumeAccounts } from "./VolumeAccounts";
import * as styles from "./style";

export interface AccountsProps extends PermissionProps {}

enum AccountsTabs {
  Liquidity = "LIQUIDITY",
  Volume = "VOLUME",
}

interface AccountsMenuItem extends MenuItemData {
  value: AccountsTabs;
}

const ACCOUNTS_MENU_ITEMS: AccountsMenuItem[] = [
  {
    label: "Volume",
    value: AccountsTabs.Volume,
  },
  {
    label: "Liquidity",
    value: AccountsTabs.Liquidity,
  },
];

export const Accounts = observer((props: AccountsProps) => {
  const state = useLateInitContext(ApiKeysContext);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const tabsItems = ACCOUNTS_MENU_ITEMS;

  const { volumeAccountNames } = state.bindings;
  const { liquidityAccountNames } = state.bindings;

  const permissionProps = props;

  return (
    <styles.ApiKeysContainer>
      {isMobile ? (
        <styles.AccountTabs menuItems={tabsItems} defaultKey={AccountsTabs.Volume} type="spread">
          <VolumeAccounts
            key={AccountsTabs.Volume}
            accountNames={volumeAccountNames}
            onRefresh={state.refreshVolumeBindings}
            onAddAccount={state.addAccount}
            bindingsProvider={state.bindings}
            {...permissionProps}
          />
          <LiquidityAccounts
            key={AccountsTabs.Liquidity}
            accountNames={liquidityAccountNames}
            onRefresh={state.refreshLiquidityBindings}
            onAddAccount={state.addAccount}
            bindingsProvider={state.bindings}
            {...permissionProps}
          />
        </styles.AccountTabs>
      ) : (
        <>
          <VolumeAccounts
            accountNames={volumeAccountNames}
            onRefresh={state.refreshVolumeBindings}
            onAddAccount={state.addAccount}
            bindingsProvider={state.bindings}
            {...permissionProps}
          />
          <LiquidityAccounts
            accountNames={liquidityAccountNames}
            onRefresh={state.refreshLiquidityBindings}
            onAddAccount={state.addAccount}
            bindingsProvider={state.bindings}
            {...permissionProps}
          />
        </>
      )}
    </styles.ApiKeysContainer>
  );
});
