import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
} from "src/components/shared/Table";
import styled from "styled-components";

export const TableContent = styled(Table)`
  height: auto;
`;

export const Head = styled(TableHead)`
  border-bottom: none;
`;

export const Body = styled(TableBody)`
  overflow-y: inherit;
`;

export const Row = styled(TableRow)`
  height: 34px;
  font-size: 12px;
  :not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

export const HeaderRow = styled(TableHeadRow)``;

export const Header = styled(TableHeader)`
  font-size: 12px;
  user-select: none;
`;

export const Cell = styled(TableCell)`
  font-size: 12px;
  font-weight: normal;
  color: #767676;
  text-align: left;

  word-break: break-word;

  :first-of-type {
    width: 45px;
  }

  :nth-last-child(2) {
    text-align: center;
  }

  :last-of-type {
    width: 35px;
    text-align: inherit;
  }

  @media screen and (max-width: 1230px) {
    font-size: 10px;
  }
`;

export const Side = styled.span`
  font-weight: bold;
`;
