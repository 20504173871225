import { useCallback, useState } from "react";

export interface UseModalStateResult {
  isShown: boolean;
  show: () => void;
  close: () => void;
  toggle: () => void;
}

export const useModalState = (defaultShow: boolean = false): UseModalStateResult => {
  const [isShown, setIsShown] = useState(defaultShow);

  const toggle = useCallback(() => setIsShown((prevValue) => !prevValue), []);

  const show = useCallback(() => setIsShown(true), []);

  const close = useCallback(() => setIsShown(false), []);

  return { isShown, toggle, show, close };
};
