import { Observer, observer } from "mobx-react-lite";
import { Controller, UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { TickersContext } from "src/context/UserManager/UserManager";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { stringToSelectorValue } from "src/helpers/forms/selectors";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ITickerGroup } from "src/state/UserManager/EditParty/TickersStore";
import { ERRORS_MSGS } from "src/validation-schemas";
import * as styles from "./style";

export interface TickerElementProps {
  index: number;
  remove: UseFieldArrayRemove;
}

export const TickerElement = observer(({ index, remove }: TickerElementProps) => {
  const state = useLateInitContext(TickersContext);

  const { control } = useFormContext<ITickerGroup>();

  return (
    <styles.TickerElementWrapper>
      <Controller
        control={control}
        name={`tickers.${index}.exchange`}
        rules={{ required: ERRORS_MSGS.isRequired }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <Observer>
            {() => (
              <LabeledSelector
                menuPosition="fixed"
                options={state.exchangeList}
                onChange={(data) => onChange(data?.value)}
                value={stringToSelectorValue(value)}
                errorHint={errors.tickers?.[index]?.exchange?.message}
              />
            )}
          </Observer>
        )}
      />

      <Controller
        control={control}
        name={`tickers.${index}.name`}
        rules={{ required: ERRORS_MSGS.isRequired }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <LabeledInput
            value={value}
            onChange={(e) => onChange(getChangeEventValue(e))}
            errorHint={errors.tickers?.[index]?.name?.message}
          />
        )}
      />

      <Controller
        control={control}
        name={`tickers.${index}.ratio`}
        rules={{ required: ERRORS_MSGS.isRequired }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <LabeledInput
            type="number"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            errorHint={errors.tickers?.[index]?.ratio?.message}
          />
        )}
      />

      <DeleteButton onClick={() => remove(index)} />
    </styles.TickerElementWrapper>
  );
});
