import {
  PickerValueManager,
  areDatesEqual,
  getDefaultReferenceDate,
  getTodayDate,
  replaceInvalidDateByNull,
} from "@mui/x-date-pickers/internals";
import { DateTimeRangeValidationError } from "../hooks/useDateTimeValidation";
import { DateTimeRange } from "../models/dateTimeRange";
import { DateRange } from "../models/range";

export type RangePickerValueManager<
  TValue = [any, any],
  TDate = any,
  TError extends DateTimeRangeValidationError = any,
> = PickerValueManager<TValue, TDate, TError>;

export const EMPTY_DATE_TIME_RANGE: DateTimeRange = [null, null];

export const rangeValueManager: RangePickerValueManager = {
  emptyValue: EMPTY_DATE_TIME_RANGE,
  getTodayValue: (utils, timezone, valueType) => [
    getTodayDate(utils, timezone, valueType),
    getTodayDate(utils, timezone, valueType),
  ],
  getInitialReferenceValue: ({ value, referenceDate: referenceDateProp, ...params }) => {
    const shouldKeepStartDate = value[0] != null && params.utils.isValid(value[0]);
    const shouldKeepEndDate = value[1] != null && params.utils.isValid(value[1]);

    if (shouldKeepStartDate && shouldKeepEndDate) {
      return value;
    }

    const referenceDate = referenceDateProp ?? getDefaultReferenceDate(params);

    return [
      shouldKeepStartDate ? value[0] : referenceDate,
      shouldKeepEndDate ? value[1] : referenceDate,
    ];
  },
  cleanValue: (utils, value) =>
    value.map((date) => replaceInvalidDateByNull(utils, date)) as DateRange<any>,
  areValuesEqual: (utils, a, b) =>
    areDatesEqual(utils, a[0], b[0]) && areDatesEqual(utils, a[1], b[1]),
  isSameError: (a, b) => b !== null && a[1] === b[1] && a[0] === b[0],
  hasError: (error) => error[0] != null || error[1] != null,
  defaultErrorState: EMPTY_DATE_TIME_RANGE,
  getTimezone: (utils, value) => {
    const timezoneStart =
      value[0] == null || !utils.isValid(value[0]) ? null : utils.getTimezone(value[0]);
    const timezoneEnd =
      value[1] == null || !utils.isValid(value[1]) ? null : utils.getTimezone(value[1]);

    if (timezoneStart != null && timezoneEnd != null && timezoneStart !== timezoneEnd) {
      throw new Error("MUI: The timezone of the start and the end date should be the same");
    }

    return timezoneStart ?? timezoneEnd;
  },
  setTimezone: (utils, timezone, value) => [
    value[0] == null ? null : utils.setTimezone(value[0], timezone),
    value[1] == null ? null : utils.setTimezone(value[1], timezone),
  ],
};
