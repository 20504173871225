import styled from "styled-components";
import { TableCellText } from "src/components/shared/TableV2";

export const TableWrapper = styled.div`
  height: 0px;
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: flex-end;

  background: ${({ theme }) => theme.contentBackgroundColor};
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  min-width: max-content;
  margin: 8px;
`;

export const CellText = styled(TableCellText)`
  width: auto;
  white-space: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
`;
