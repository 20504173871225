import { observer } from "mobx-react-lite";
import { rgba } from "polished";
import { useMemo } from "react";
import { LiquidityContext } from "src/context/CEX/Dashboard/v2/Liquidity";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { LiquidityTypeData } from "src/state/CEX/CEXDashboard/v1/LiquidityStore";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { useDashboardColors } from "../../../../shared/hooks/useDashboardColors";
import { DashboardV2Widget, getWidgetInfo, getWidgetTitle } from "../../shared/constants";
import { LiquidityBar, LiquidityBarProps } from "../shared/LiquidityBar";
import { LiquidityCard } from "../shared/style";

interface UseLiquidityDataParams {
  time: number[];
  data: LiquidityTypeData;
  price: number[];
}

export const useLiquidityData = ({
  time,
  data: { our, notOur, total },
  price,
}: UseLiquidityDataParams) => {
  const {
    accent: { royalBlue, rubyRed, yellow },
  } = useDashboardColors();

  const barData = useMemo(
    (): LiquidityBarProps["data"] => ({
      labels: time,
      datasets: [
        {
          label: "Gotbit",
          data: our,
          borderWidth: 0,
          backgroundColor: rgba(royalBlue, 0.65),
          hoverBackgroundColor: rgba(royalBlue, 0.8),
        },
        {
          label: "Organic",
          data: notOur,
          borderWidth: 0,
          backgroundColor: rgba(rubyRed, 0.45),
          hoverBackgroundColor: rgba(rubyRed, 0.7),
        },
        { label: "Total", data: total, hidden: true },
        {
          type: "line",
          label: "Price",
          data: price,
          borderWidth: 1,
          backgroundColor: rgba(yellow, 0.8),
          borderColor: yellow,
          order: -1,
          pointRadius: 2,
          yAxisID: "y1",
        },
      ],
    }),
    [notOur, our, price, royalBlue, rubyRed, time, total, yellow]
  );

  return barData;
};

export interface BuyLiquidityChartProps extends Pick<LiquidityBarProps, "zoomDefault">, CardProps {}

export const BuyLiquidityChart = observer(({ zoomDefault, ...props }: BuyLiquidityChartProps) => {
  const { time, price, priceRange, buy, loading } = useLateInitContext(LiquidityContext.Context);

  const data = useLiquidityData({ time, data: buy, price });

  const barProps = { data, zoomDefault };

  return (
    <LiquidityCard
      title={getWidgetTitle(DashboardV2Widget.BuyLiquidity)}
      info={getWidgetInfo(DashboardV2Widget.BuyLiquidity)}
      loading={loading}
      {...props}
    >
      <LiquidityBar priceRange={priceRange} {...barProps} />
    </LiquidityCard>
  );
});
