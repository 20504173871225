import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { UserManagerContext } from "src/context/UserManager/UserManager";
import { checkAbility } from "src/helpers/checkParty";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Role } from "src/modules/userManager";
import { useAppState } from "src/state";
import WindowConsent from "src/state/WindowConsent";
import { SelectItem } from "../../shared";
import { Filter } from "../Filter";
import { List } from "../List";
import { AddRole } from "./AddRole";
import { EditRole } from "./EditRole";

const ABILITY_NAME = "auth-role-edit";

export const RolesList = observer(() => {
  const state = useLateInitContext(UserManagerContext);

  const { userState } = useAppState();

  const checkEditPermsn = checkAbility(userState.abilities, ABILITY_NAME);

  const openEditRole = useCallback(
    (role: Role, openPanel: (bool: boolean) => void) => {
      openPanel(true);
      state.setEditableRole(role);
    },
    [state]
  );

  const remove = useCallback(
    (roleName: string) => {
      WindowConsent.showWindow(
        "",
        `Are you sure you want to delete the role: ${roleName}?`,
        state.removeRole,
        roleName
      );
    },
    [state.removeRole]
  );

  const columns: any = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
        disableSortBy: true,

        Cell: ({
          cell: {
            row: { original: role },
          },
        }: any) => (
          <SelectItem onClick={() => openEditRole(role, state.openPanel("showEditRole"))}>
            {role.name}
          </SelectItem>
        ),
      },
      {
        Header: "",
        accessor: "amount",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "cancel",
        disableSortBy: true,
        Cell: ({
          cell: {
            row: { original: role },
          },
        }: any) => <DeleteButton onClick={() => remove(role.name)} />,
      },
    ],
    [state, remove, openEditRole]
  );

  const shortColumns: any = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: "",
        accessor: "amount",
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <>
      <List
        style={{ gridArea: "roles" }}
        label="Roles"
        btnLabel="add role +"
        openModal={state.openPanel("showAddRolesPanel")}
        data={state.roles}
        columns={checkEditPermsn ? columns : shortColumns}
        filter={<Filter value={state.rolesFilter} onChange={state.setRolesFilters} />}
        loader={state.isLoadingRoles}
        hideAddBtn={checkEditPermsn}
      />

      {checkEditPermsn ? (
        <>
          <AddRole />

          <EditRole editableRole={state.editableRole} />
        </>
      ) : null}
    </>
  );
});
