import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "src/components/shared/Toaster";
import {
  createExchangeModule,
  getExchangesModules,
  removeExchangeModule,
  startExchangeModule,
  stopExchangeModule,
  updExchangeModule,
} from "src/api/bots/CEX/multigrinder";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import WindowConsent from "src/state/WindowConsent";
import MultiGridStore from ".";
import AccountManagerStore from "../../shared/AccountManager";
import { MultiGridStatus } from "../shared/types";
import { INewExchangeModule, ModuleConfig } from "./AddModuleModal";
import { IModuleCreator } from "./CreateMultiGrid";

export interface ModuleErrors {
  active: boolean;
  created_at: number;
  text: string;
}

export interface IExchangeModule extends INewExchangeModule {
  errors: ModuleErrors[];
  logicError: number;
}

class ModulesStore implements IModuleCreator {
  mainState: MultiGridStore;

  exchModules: IExchangeModule[] = [];

  isLoading = false;

  accountsState: AccountManagerStore;

  private _updCbBalances: (() => void)[] = [];

  constructor(state: MultiGridStore) {
    this.mainState = state;

    this.mainState.setUpdHandlers("updModules", this.updData);

    this.accountsState = new AccountManagerStore(this);

    makeAutoObservable(this);
  }

  get party() {
    return this.mainState.party;
  }

  get gridUUID() {
    return this.mainState.gridUUID;
  }

  get countModules() {
    return this.exchModules.length;
  }

  get countPartyExchanges() {
    return this.accountsState.exchanges.length;
  }

  get countActiveModules() {
    return this.exchModules.filter((el) => el.status === "active").length;
  }

  setUpdBalanceCb = (cb: () => void) => {
    this._updCbBalances.push(cb);
  };

  loadData = () => {
    this.accountsState.loadAccounts();
    this._getExchangeModules(this.gridUUID);
  };

  updData = () => {
    this.loadData();
    this._updBalances();
  };

  private _updBalances = () => {
    this._updCbBalances.map((cb) => cb());
  };

  private _setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  private _setModuleStatus = (module: IExchangeModule, status: MultiGridStatus) => {
    // eslint-disable-next-line no-param-reassign
    module.status = status;
  };

  getModule = (id: string) => this.exchModules.find((el) => el.id === id);

  getModuleStatus = (status: MultiGridStatus) => status === "active";

  findModule = (arr: INewExchangeModule[], id: string) => arr.findIndex((el) => el.id === id);

  checkModuleDuplication = (id: string) => {
    const findModuleIndx = this.findModule(this.exchModules, id);

    if (findModuleIndx === -1) {
      return true;
    }

    toast.error(`Module for ${id} exchange already exists!`);
    return false;
  };

  addExchModule = async (module: INewExchangeModule, closeModal: (bool: boolean) => void) => {
    const valid = this.checkModuleDuplication(module.id);

    if (valid) {
      try {
        const { isError } = await createExchangeModule({
          uuid: this.gridUUID,
          data: module,
        });

        if (!isError) {
          closeModal(false);
          showSuccessMsg("Module successfully added");
          this.loadData();
        }
      } catch (err) {
        logError(err);
      }
    }
  };

  private _getExchangeModules = async (party: string) => {
    this._setLoading(true);

    try {
      const { isError, data } = await getExchangesModules(party);

      if (!isError) {
        runInAction(() => {
          this.exchModules = data;
        });
      }
    } finally {
      this._setLoading(false);
    }
  };

  toggleHandler = (id: string) => () => {
    this.toggleModuleState(id);
  };

  toggleModuleState = (id: string) => {
    const module = this.getModule(id);

    if (module) {
      const previewStatus = module.status;

      const revertCb = () => {
        this._setModuleStatus(module, previewStatus);
      };

      switch (module.status) {
        case "active": {
          this._setModuleStatus(module, "stopped");

          this._stopModule(id, revertCb);
          break;
        }

        case "stopped": {
          this._setModuleStatus(module, "active");

          this._activeModule(id, revertCb);
          break;
        }

        case "error": {
          this._setModuleStatus(module, "active");

          this._activeModule(id, revertCb);
          break;
        }

        default:
          break;
      }
    }
  };

  removeHandler = (id: string) => () => {
    WindowConsent.showWindow(
      "Are you sure you want to remove the module for:",
      `${id}`,
      this._removeModule(id)
    );
  };

  private _removeModule = (id: string) => async () => {
    this._setLoading(true);

    try {
      const { isError } = await removeExchangeModule({
        id,
        uuid: this.gridUUID,
      });

      if (!isError) {
        showSuccessMsg(`Module for ${id} exchange successfully removed`);

        this.loadData();
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  private _activeModule = async (id: string, revertCb: () => void) => {
    try {
      const { isError } = await startExchangeModule({
        id,
        uuid: this.gridUUID,
      });

      if (!isError) {
        showSuccessMsg(`Module for ${id} exchange successfully launched`);
        this.loadData();
      } else revertCb();
    } catch {
      revertCb();
    }
  };

  private _stopModule = async (id: string, revertCb: () => void) => {
    try {
      const { isError } = await stopExchangeModule({
        id,
        uuid: this.gridUUID,
      });

      if (!isError) {
        showSuccessMsg(`Module for ${id} exchange successfully stopped`);
        this.loadData();
      } else revertCb();
    } catch {
      revertCb();
    }
  };

  saveModuleSettings = (id: string) => async (data: ModuleConfig) => {
    this._setLoading(true);

    try {
      const { isError } = await updExchangeModule({
        id,
        uuid: this.gridUUID,
        data,
      });

      if (!isError) {
        showSuccessMsg(`Module for ${id} exchange successfully updated`);
        // resetForm();

        this.loadData();
      }
    } finally {
      this._setLoading(false);
    }
  };
}

export default ModulesStore;
