import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import { DashboardBreakpoints } from "../../../shared/style";

export enum MarketInfoArea {
  Price = "price",
  AllDepth = "all-depth",
  Pairs = "pairs",
  Arbitrage = "arbitrage",
  Spread = "spread",
  Volume = "volume",
  TotalVolume = "total-volume",
  BuyLiquidity = "buy-liquidity",
  SellLiquidity = "sell-liquidity",
  ChipCorrelation = "chip-correlation",
  KPI = "kpi",
  Santiment = "santiment",
}

export const Container = styled.div`
  width: 100%;

  display: grid;

  gap: 8px;

  grid-template-columns: minmax(230px, 2fr) minmax(120px, 1fr) 67px minmax(150px, 1fr) 170px;
  grid-template-rows: 100px auto 286px 314px repeat(2, 173px);

  grid-template-areas:
    "${MarketInfoArea.Price} ${MarketInfoArea.Santiment} ${MarketInfoArea.Santiment} ${MarketInfoArea.Santiment} ${MarketInfoArea.ChipCorrelation}"
    "${MarketInfoArea.Price} ${MarketInfoArea.Santiment} ${MarketInfoArea.Santiment} ${MarketInfoArea.Santiment} ${MarketInfoArea.KPI}"
    "${MarketInfoArea.Pairs} ${MarketInfoArea.Pairs} ${MarketInfoArea.Pairs} ${MarketInfoArea.Pairs} ${MarketInfoArea.Pairs}"
    "${MarketInfoArea.Spread} ${MarketInfoArea.Volume} ${MarketInfoArea.TotalVolume} ${MarketInfoArea.Arbitrage} ${MarketInfoArea.Arbitrage}"
    "${MarketInfoArea.BuyLiquidity} ${MarketInfoArea.AllDepth} ${MarketInfoArea.AllDepth} ${MarketInfoArea.AllDepth} ${MarketInfoArea.AllDepth}"
    "${MarketInfoArea.SellLiquidity} ${MarketInfoArea.AllDepth} ${MarketInfoArea.AllDepth} ${MarketInfoArea.AllDepth} ${MarketInfoArea.AllDepth}";

  @media ${getMobileMediaQuery(DashboardBreakpoints.Tablet)} {
    grid-template-columns: 173px minmax(100px, 2fr) minmax(100px, 3fr) 67px;
    grid-template-rows: 286px 100px auto 286px 314px repeat(2, 173px);

    grid-template-areas:
      "${MarketInfoArea.Price} ${MarketInfoArea.Price} ${MarketInfoArea.Santiment} ${MarketInfoArea.Santiment}"
      "${MarketInfoArea.ChipCorrelation} ${MarketInfoArea.Volume} ${MarketInfoArea.Volume} ${MarketInfoArea.TotalVolume}"
      "${MarketInfoArea.KPI} ${MarketInfoArea.Volume} ${MarketInfoArea.Volume} ${MarketInfoArea.TotalVolume}"
      "${MarketInfoArea.Pairs} ${MarketInfoArea.Pairs} ${MarketInfoArea.Pairs} ${MarketInfoArea.Pairs}"
      "${MarketInfoArea.Spread} ${MarketInfoArea.Spread} ${MarketInfoArea.Arbitrage} ${MarketInfoArea.Arbitrage}"
      "${MarketInfoArea.BuyLiquidity} ${MarketInfoArea.BuyLiquidity} ${MarketInfoArea.AllDepth} ${MarketInfoArea.AllDepth}"
      "${MarketInfoArea.SellLiquidity} ${MarketInfoArea.SellLiquidity} ${MarketInfoArea.AllDepth} ${MarketInfoArea.AllDepth}";
  }

  @media ${getMobileMediaQuery(DashboardBreakpoints.XMobile)} {
    grid-template-columns: minmax(100px, 1fr) 67px;
    grid-template-rows: repeat(2, 286px) 100px auto 244px 250px 322px 250px repeat(2, 173px) 300px;

    grid-template-areas:
      "${MarketInfoArea.Price} ${MarketInfoArea.Price}"
      "${MarketInfoArea.Santiment} ${MarketInfoArea.Santiment}"
      "${MarketInfoArea.ChipCorrelation} ${MarketInfoArea.ChipCorrelation}"
      "${MarketInfoArea.KPI} ${MarketInfoArea.KPI}"
      "${MarketInfoArea.Pairs} ${MarketInfoArea.Pairs}"
      "${MarketInfoArea.Spread} ${MarketInfoArea.Spread}"
      "${MarketInfoArea.Volume} ${MarketInfoArea.TotalVolume}"
      "${MarketInfoArea.Arbitrage} ${MarketInfoArea.Arbitrage}"
      "${MarketInfoArea.BuyLiquidity} ${MarketInfoArea.BuyLiquidity}"
      "${MarketInfoArea.SellLiquidity} ${MarketInfoArea.SellLiquidity}"
      "${MarketInfoArea.AllDepth} ${MarketInfoArea.AllDepth}";
  }
`;
