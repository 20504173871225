import styled from "styled-components";

export const InputWrapper = styled.div`
  width: 100%;

  display: flex;

  gap: 13px;
`;

export const InputColumnWrapper = styled(InputWrapper)`
  flex-direction: column;
`;
