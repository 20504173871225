import { observer } from "mobx-react-lite";
import { PlacesType, ITooltip as TooltipProps } from "react-tooltip";
import { FillingState } from "src/state/CEX/CEXExchange/OrderBook/OrderBookStore";
import { StatsTooltip, StatsTooltipProps } from "../StatsTooltip";
import * as styles from "../shared";

interface SeparatedTooltipProps extends TooltipProps, Omit<StatsTooltipProps, "filling" | "type"> {
  orderType: "ALL" | "OUR" | "ORGANIC";
  totalFilling: FillingState;
  ourFilling: FillingState;
  organicFilling: FillingState;
  place: PlacesType;
  id: string;
  disable?: boolean;
}

export const SeparatedTooltip = observer(
  ({
    orderType,
    totalFilling,
    ourFilling,
    organicFilling,
    quote,
    base,
    ...props
  }: SeparatedTooltipProps) => (
    <styles.Container {...props}>
      {totalFilling ? (
        <StatsTooltip type="Total" quote={quote} base={base} filling={totalFilling} />
      ) : null}
      {orderType === "ALL" ? (
        <>
          {ourFilling ? (
            <StatsTooltip type="Our" quote={quote} base={base} filling={ourFilling} />
          ) : null}

          {organicFilling ? (
            <StatsTooltip type="Organic" quote={quote} base={base} filling={organicFilling} />
          ) : null}
        </>
      ) : null}
    </styles.Container>
  )
);
