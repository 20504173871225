import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { PermissionProps } from "src/permissions/PermissionWrapper";
import { FundingStore } from "src/state/funding/CEX";
import { FundingManagementStore } from "src/state/funding/CEX/FundingManagementStore";
import TransferListStore from "src/state/funding/CEX/TransferListStore";
import { createContextProvider } from "../utils/createContextProvider";

const CreateFundingStore = () => useLocalStore(FundingStore);

const { Context: FundingStoreContext, Provider: FundingProvider } =
  createContextProvider<FundingStore>({
    stateCreator: CreateFundingStore,
  });

export { FundingProvider, FundingStoreContext };

const CreateFundingList = () =>
  useLocalStore(FundingManagementStore, useLateInitContext(FundingStoreContext));

const { Context: FundingListContext, Provider: FundingListProvider } =
  createContextProvider<FundingManagementStore>({
    stateCreator: CreateFundingList,
  });

export { FundingListContext, FundingListProvider };

const CreateTransferList = () =>
  useLocalStore(TransferListStore, useLateInitContext(FundingStoreContext));

const { Context: TransferListContext, Provider: TransferListProvider } =
  createContextProvider<TransferListStore>({
    stateCreator: CreateTransferList,
  });

export { TransferListContext, TransferListProvider };

export const FundingAbilitiesContext = createLateInitContext<PermissionProps>();
