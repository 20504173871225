import { observer } from "mobx-react-lite";
import { WithdrawGasContext } from "src/context/DEXV2/DEXV2Stats/GasWallets/WIthdrawGas";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { WithdrawGasForm } from "./WithdrawGasForm";
import { WithdrawGasSuccess } from "./WithdrawGasSuccess";

export interface WithdrawGasModalContentProps {}

export const WithdrawGasModalContent = observer((_props: WithdrawGasModalContentProps) => {
  const state = useLateInitContext(WithdrawGasContext);

  const withdrawSuccess = state.isSuccess;
  return withdrawSuccess ? <WithdrawGasSuccess /> : <WithdrawGasForm />;
});
