import { forwardRef } from "react";
import { Badge } from "src/components/shared/Badge";
import { IconButton, IconButtonProps } from "src/components/shared/Buttons/v2/IconButton";
import Icons from "src/icons/Icons";

export interface FilterButtonProps extends IconButtonProps {
  count?: number;
}

export const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  ({ count, ...props }, ref) => {
    const showCount = Boolean(count);
    return (
      <IconButton size="xs" {...props} ownRef={ref} style={{ position: "relative" }}>
        {Icons.filter()}
        {showCount && <Badge>{count}</Badge>}
      </IconButton>
    );
  }
);
