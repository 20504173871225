import { ComponentProps } from "react";
import { Pie as PieChart } from "react-chartjs-2";

type PieChartProps<TData, TLabel> = ComponentProps<typeof PieChart<TData, TLabel>>;

export interface PieProps<TData, TLabel> extends Omit<PieChartProps<TData, TLabel>, "ref"> {
  chartRef?: PieChartProps<TData, TLabel>["ref"];
}

export const Pie = <TData = number[], TLabel = unknown>({
  chartRef,
  ...props
}: PieProps<TData, TLabel>) => <PieChart {...props} ref={chartRef} />;
