import { formatPercent } from "src/helpers/string";

export const poolPercentToBips = (poolPercent: number) => poolPercent * 10000;

export const poolPercentBipsToPercent = (poolPercent: number) => {
  const percent = poolPercent / 10000;
  return percent;
};

export const formatPoolPercent = (poolPercent: number) => {
  const percent = poolPercentBipsToPercent(poolPercent);
  return formatPercent(percent);
};
