import { createColumnHelper } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { IActionModuleInfo } from "src/modules/expertSystem";
import { UniqueNameText } from "../../Conditions/ConditionsTable/UniqueNameText";
import { ModulesPagination } from "../../Conditions/ConditionsTable/style";
import { useModuleState } from "../../hooks/useModuleState";
import { ModulesEditTableProps } from "../../layout/ModulesList";
import { ModuleInfoModal } from "../../shared/ModuleInfoModal";
import { ModuleStatus } from "../../shared/ModuleStatus";
import {
  BASE_CELL_MODULE,
  BASE_MODULES_COLUMN_PROPS,
  BASE_MODULE_CELL_TEXT,
  ModulesTable,
  ModulesTableProps,
} from "../../shared/ModulesTable";

const columnHelper = createColumnHelper<IActionModuleInfo>();

export interface ActionsTableProps
  extends ModulesEditTableProps,
    Omit<ModulesTableProps<IActionModuleInfo>, "columns" | "loader" | "data"> {}

export const ActionsTable = observer((props: ActionsTableProps) => {
  const {
    loading,
    removeModule,
    modules,
    getModules,
    pagesCount,
    sortingState,
    setSortingState,
    currentPage,
    setCurrentPage,
  } = useModuleState("action");

  const removeBtn = useMemo(() => {
    if (props.editing) {
      return [
        columnHelper.accessor("uuid", {
          header: "",
          id: "delete_column",
          ...BASE_MODULES_COLUMN_PROPS,
          cell: (info) => (
            <DeleteButton
              onClick={() => {
                removeModule({ uuid: info.getValue() });
              }}
            />
          ),
          maxSize: 30,
        }),
      ];
    }
    return [];
  }, [props.editing, removeModule]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        ...BASE_MODULES_COLUMN_PROPS,
        enableSorting: true,
        ...BASE_MODULE_CELL_TEXT,
        maxSize: 100,
      }),
      columnHelper.accessor("type", {
        header: "Module",
        ...BASE_MODULES_COLUMN_PROPS,
        ...BASE_CELL_MODULE,
        maxSize: 100,
      }),
      columnHelper.accessor("unique_name", {
        header: "Unique name",
        ...BASE_MODULES_COLUMN_PROPS,
        cell: (info) => <UniqueNameText>{info.getValue()}</UniqueNameText>,
      }),
      columnHelper.accessor("linked", {
        header: "Status",
        ...BASE_MODULES_COLUMN_PROPS,
        cell: ({ row }) => {
          const { linked, linked_active } = row.original;
          return <ModuleStatus linked={linked} linkedActive={linked_active} />;
        },
      }),
      ...removeBtn,
      columnHelper.accessor("uuid", {
        header: "",
        id: "info_column",
        ...BASE_MODULES_COLUMN_PROPS,
        cell: ({ row }) => <ModuleInfoModal module={row.original} />,
        maxSize: 30,
      }),
    ],
    [removeBtn]
  );

  return (
    <>
      <ModulesTable
        columns={columns}
        loader={loading}
        data={modules}
        sortingState={sortingState}
        onSortingChange={setSortingState}
        {...props}
      />
      <ModulesPagination
        itemsPerPage={10}
        getItems={getModules}
        pageCount={pagesCount}
        onForcePageChange={setCurrentPage}
        forcePage={currentPage}
      />
    </>
  );
});
