import { observer } from "mobx-react-lite";
import { Button, ButtonProps } from "src/components/shared/Buttons/v2/Button";
import { IActiveGrid } from "src/state/CEX/liquidityTab/ActiveGrid";

interface GridToggleButtonProps extends Omit<ButtonProps, "onClick" | "color"> {
  state: IActiveGrid;
}

const GridToggleButton = observer(({ state, ...props }: GridToggleButtonProps) => {
  const buttonProps =
    state.form.data.state === "active"
      ? ({
          color: "danger",
          children: "Stop",
          onClick: state.stopHandler,
        } as const)
      : ({
          color: "success",
          children: "Start",
          onClick: state.startHandler,
        } as const);
  return <Button stretched {...buttonProps} {...props} />;
});

export default GridToggleButton;
