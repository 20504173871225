import { makeAutoObservable } from "mobx";
import { ITokenAddressProvider } from "../shared/TokenTickerValidationStore";
import { IDEXV2SettingsProvider } from "./DEXV2SettingsInfoStore";

type SettingsTokenType = "stable";

interface ISettingsAddressProviderStoreParams {
  dataProvider: Omit<IDEXV2SettingsProvider, "withdrawer">;
  tokenType: SettingsTokenType;
}

export class SettingsAddressProviderStore implements ITokenAddressProvider {
  private _dataProvider;

  private _tokenType: SettingsTokenType;

  constructor({ dataProvider, tokenType }: ISettingsAddressProviderStoreParams) {
    makeAutoObservable(this);

    this._dataProvider = dataProvider;
    this._tokenType = tokenType;
  }

  private get _data() {
    return this._dataProvider.settings;
  }

  get address() {
    switch (this._tokenType) {
      case "stable":
        return this._data.base_data.stable;
      default: {
        throw new Error("Unknown token type");
      }
    }
  }
}
