import { ChainId } from "./chains";

export type PriceFeedMap = Partial<Record<string, string>>;

export const nativePriceFeedMap: PriceFeedMap = {
  [ChainId.BSC]: "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE",
  [ChainId.Ethereum]: "0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419",
  [ChainId.Avalanche]: "0x0a77230d17318075983913bc2145db16c7366156",
  [ChainId.Fantom]: "0xf4766552d15ae4d256ad41b6cf2933482b0680dc",
  [ChainId.Polygon]: "0xab594600376ec9fd91f8e885dadf0ce036862de0",
  [ChainId.Arbitrum]: "0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612",
  [ChainId.Base]: "0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70",
  [ChainId.zkSync]: "0x6D41d1dc818112880b40e26BD6FD347E41008eDA",
  [ChainId.Linea]: "0x3c6Cd9Cc7c7a4c2Cf5a82734CD249D7D593354dA",
};
