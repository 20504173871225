import { Token } from "@uniswap/sdk-core";

export type TradeSide = "quote" | "base";

/**
 * Wrapper around uniswap token with required symbol
 * and helper trade side field
 */
export class TradeToken extends Token {
  /**
   * Token side in trade pair (quote/base)
   */
  readonly side: string;

  readonly symbol: string;

  /**
   *
   * @param side Token side in trade pair (quote/base)
   * @inheritDoc
   */
  constructor(
    chainId: number,
    address: string,
    decimals: number,
    symbol: string,
    side: TradeSide,
    name?: string,
    bypassChecksum?: boolean
  ) {
    super(chainId, address, decimals, symbol, name, bypassChecksum);
    this.symbol = symbol;
    this.side = side;
  }
}
