import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 23px;

  display: flex;
  align-items: center;

  gap: 15px;
`;

export const StyledPaginateContainer = styled.div`
  display: flex;

  width: 100%;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  li {
    list-style-type: none;

    color: ${({ theme }) => theme.inputBorderColor};
  }

  a {
    padding: 3px 7px;

    font-size: ${({ theme }) => theme.fontSizes["12px"]};

    cursor: pointer;
  }

  .pagination {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 5px 0;
    padding: 0;
    /* border: ${({ theme }) => theme.borders.solidGray}; */
    padding: 5px;
    /* border-radius: ${({ theme }) => theme.radii["4px"]}; */
  }

  .break-me {
    cursor: default;
  }

  .previous,
  .next {
    display: flex;
  }

  .active {
    border-radius: ${({ theme }) => theme.radii["4px"]};

    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const Step = styled.div`
  width: 10px;
  height: 10px;

  display: flex;

  border-right: 2px solid #e0e0e0;
  border-bottom: 2px solid #e0e0e0;
`;

export const NextStep = styled(Step)`
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
`;

export const PrewStep = styled(Step)`
  -ms-transform: rotate(130deg);
  -webkit-transform: rotate(130deg);
  transform: rotate(130deg);
`;
