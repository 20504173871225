import { observer } from "mobx-react-lite";
import { Headline1, Headline2 } from "src/components/shared/Typography";
import { FundingStoreContext } from "src/context/CEX/FundingProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { HeaderWrapper } from "./shared";

export const FundingHeader = observer(() => {
  const state = useLateInitContext(FundingStoreContext);

  return (
    <HeaderWrapper>
      <Headline1>Funding</Headline1>
      <Headline2>{`Party: ${state.party}`}</Headline2>
    </HeaderWrapper>
  );
});
