import * as BaseStyles from "src/components/shared/Graph/shared/Tooltip/style";
import styled from "styled-components";

export const TooltipContainer = styled(BaseStyles.TooltipContainer)`
  width: 200px;
`;

export const Content = styled(BaseStyles.Content)``;

export const SeriesContainer = styled(BaseStyles.SeriesContainer)`
  gap: 10px;
`;
