import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import { DashboardBreakpoints } from "../../../shared/style";

export enum PNLArea {
  PNLChart = "pnl-chart",
  PNLSummary = "pnl-summary",
  CurrencyFee = "currency-fee",
  ExchangesFee = "exchanges-fee",
}

export const Container = styled.div`
  width: 100%;

  display: grid;

  gap: 8px;

  grid-template-columns: minmax(230px, 1fr) repeat(2, minmax(190px, 1fr));
  grid-template-rows: 270px 212px;

  grid-template-areas:
    "${PNLArea.PNLChart} ${PNLArea.PNLChart} ${PNLArea.PNLChart}"
    "${PNLArea.PNLSummary} ${PNLArea.CurrencyFee} ${PNLArea.ExchangesFee}";

  @media ${getMobileMediaQuery(DashboardBreakpoints.Tablet)} {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: 270px repeat(2, 212px);

    grid-template-areas:
      "${PNLArea.PNLChart} ${PNLArea.PNLChart}"
      "${PNLArea.PNLSummary} ${PNLArea.PNLSummary}"
      "${PNLArea.CurrencyFee} ${PNLArea.ExchangesFee}";
  }

  @media ${getMobileMediaQuery(DashboardBreakpoints.XMobile)} {
    grid-template-columns: minmax(100px, 1fr);
    grid-template-rows: 270px repeat(3, 212px);

    grid-template-areas:
      "${PNLArea.PNLChart}"
      "${PNLArea.PNLSummary}"
      "${PNLArea.ExchangesFee}"
      "${PNLArea.CurrencyFee}";
  }
`;
