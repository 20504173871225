import { ButtonProps } from "../../Button";
import * as styles from "./style";

export interface SaveButtonProps
  extends Omit<ButtonProps, "size" | "color" | "disabled" | "variant"> {
  saved?: boolean;
  showText?: boolean;
}

export const SaveButton = ({
  saved = true,
  showText = true,
  children,
  ...props
}: SaveButtonProps) => {
  const text = "Save";

  return (
    <styles.StyledSaveButton type="submit" $saved={saved} disabled={saved} {...props}>
      {showText && text}
    </styles.StyledSaveButton>
  );
};
