import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface UniqueNameTextProps extends ComponentPropsWithoutRef<"div"> {
  children?: string;
}

export const UniqueNameText = ({ children, ...props }: UniqueNameTextProps) => {
  const nameText = children || "-";
  return (
    <styles.TextWrapper {...props}>
      <styles.StyledText>{nameText}</styles.StyledText>
    </styles.TextWrapper>
  );
};
