import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { PairTickers } from "src/state/DEXV2/shared/TradePair";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import {
  generateExchange,
  generateLiquidity,
  generatePairTickers,
  generatePercent,
  generatePercentChange,
  generatePriceUSD,
  generateVolume,
} from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type PairsData = {
  exchange: string;
  pair: PairTickers;
  priceUSD: string;
  priceChange: string;
  volume: string;
  volumeShare: string;
  liquidity: string;
  indexImpact: string;
};

const generatePair = (): PairsData => ({
  exchange: generateExchange(),
  pair: generatePairTickers(),
  priceUSD: generatePriceUSD(),
  priceChange: generatePercentChange(),
  volume: generateVolume(),
  volumeShare: generatePercent(),
  liquidity: generateLiquidity(),
  indexImpact: generatePercent(),
});

const generatePairs = (): PairsData[] => {
  const pairs = faker.helpers.multiple(generatePair, {
    count: { min: 5, max: 10 },
  });
  return pairs;
};

export type PairsDataKeys = keyof PairsData;

export class PairsV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: PairsData[] = [];

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBaseDashboardV2StoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { data: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  get loading() {
    return this._baseState.loading;
  }

  private _setData = (data: PairsData[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData([]);

    try {
      await delay(200);
      const pairs = generatePairs();
      this._setData(pairs);
    } catch {
      this._setData([]);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
