import { ApiModules, apiUrl } from "src/api";
import ResponseHandler from "src/state/network/ResponseHandler";

export const getHashes = async (bot_uuid: string, page: number, size: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `${bot_uuid}/hashes?page=${page}&size=${size}`),
    method: "GET",
  });

export const getBalances = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `${bot_uuid}/balances`),
    method: "GET",
  });

export const getWallets = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.SwapBot, `${bot_uuid}/wallets`),
    method: "GET",
  });
