import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import useLocalStore from "src/hooks/useLocalStore";
import { LoginStore } from "src/state/auth/LoginStore";
import { AuthForm } from "../base/AuthForm";
import * as styles from "./style";

export const Login = observer(() => {
  const history = useHistory();
  const state = useLocalStore(LoginStore);

  return (
    <styles.Container>
      <AuthForm title="Login" onSubmit={state.submitHandler(history)} isLoading={state.showLoader}>
        <styles.FormContent>
          <LabeledInput
            label="Login"
            value={state.data.login}
            onChange={state.getHandler("login")}
            errorHint={state.errors.login}
          />
          <LabeledInput
            label="Password"
            type="password"
            value={state.data.password}
            onChange={state.getHandler("password")}
            errorHint={state.errors.password}
          />
        </styles.FormContent>
      </AuthForm>
    </styles.Container>
  );
});
