import { InputStyle } from "src/components/shared/Forms/Inputs";
import { Headline2 } from "src/components/shared/Typography";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";
import { TokenInput } from "../SwapWidget/Inputs/TokenInput";
import { TokenTickerChip } from "../SwapWidget/Inputs/TokenInput/style";
import { RefreshButton as BaseRefreshButton } from "../SwapWidget/RefreshButton";
import { DisabledSurfaceMixin, DisabledSurfaceProps } from "../SwapWidget/style";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormContainer = styled.div<DisabledSurfaceProps>`
  position: relative;

  display: flex;

  padding: 16px 20px;

  ${CardSurface}

  ${DisabledSurfaceMixin}
`;

export const Title = styled(Headline2)``;

export const RefreshButton = styled(BaseRefreshButton)`
  right: 5px;
  top: 5px;
`;

export const SmallTokenInput = styled(TokenInput)`
  ${InputStyle.Input} {
    height: 30px;
  }

  ${TokenTickerChip} {
    height: 22px;
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
`;
