import { observer } from "mobx-react-lite";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import { objectToFormatString } from "src/helpers/string";
import { TooltipSeriesData } from "src/state/Graph/Tooltip/types";
import * as styles from "./style";

const formatTooltipValue = (value: TooltipSeriesData["value"]) => {
  if (typeof value === "number") {
    return value;
  }
  return objectToFormatString(value);
};

export interface TooltipSeriesProps extends Pick<TooltipSeriesData, "title"> {}

export const TooltipSeries = observer(({ title: seriesTitle }: TooltipSeriesProps) => {
  const { seriesDataMap } = useTooltipContext();

  const { color, title, value } = seriesDataMap.get(seriesTitle)!;

  const formattedValue = formatTooltipValue(value);

  return (
    <styles.SeriesContainer>
      <styles.SeriesNameContainer>
        {color && <styles.SeriesColor color={color} />}
        <styles.SeriesName>{title}:</styles.SeriesName>
      </styles.SeriesNameContainer>
      <styles.SeriesPrice>{formattedValue}</styles.SeriesPrice>
    </styles.SeriesContainer>
  );
});
