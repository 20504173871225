import styled from "styled-components";
import { AddressCell as BaseAddressCell } from "../../../Stats/Tables/TablePanel/AddressCell";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const AddressCell = styled(BaseAddressCell)`
  height: 30px;
  justify-content: start;
`;
