import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Loader } from "src/components/shared/Loader";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

export const TransferForm = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);

  useEffect(() => {
    mainState.transferState.setValue("price", mainState.avgPrice);
  }, [mainState, mainState.avgPrice]);

  return (
    <styles.Form
      onSubmit={mainState.transferState.submitHandler()}
      onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
      disabled={!mainState.isOriginPair}
    >
      <styles.Content>
        <styles.SelectorWrapper>
          <LabeledSelector
            label="Buy account"
            isDisabled={!mainState.isOriginPair}
            options={mainState.transferState.accounts as any}
            onChange={mainState.transferState.selectorHandler("buyAccountUUID") as any}
            value={mainState.transferState.currentBuyAcc}
            // @ts-ignore
            getError={mainState.transferState.getError("buyAccountUUID")}
            errorHint={mainState.transferState.errors.buyAccountUUID}
          />
          <LabeledSelector
            label="Sell account"
            isDisabled={!mainState.isOriginPair}
            options={mainState.transferState.accounts as any}
            onChange={mainState.transferState.selectorHandler("sellAccountUUID") as any}
            value={mainState.transferState.currentSellAcc}
            // @ts-ignore
            getError={mainState.transferState.getError("sellAccountUUID")}
            errorHint={mainState.transferState.errors.sellAccountUUID}
          />
        </styles.SelectorWrapper>
        <styles.InputsGroup>
          <styles.InputWrapper>
            <LabeledInput
              readOnly={!mainState.isOriginPair}
              type="number"
              step="any"
              label="Price"
              value={mainState.transferState.data.price}
              onChange={mainState.transferState.getHandler("price")}
              // @ts-ignore
              getError={mainState.transferState.getError("price")}
              errorHint={mainState.transferState.errors.price}
            />
          </styles.InputWrapper>
          <styles.InputWrapper>
            <LabeledInput
              readOnly={!mainState.isOriginPair}
              type="number"
              step="any"
              label="Amount"
              value={mainState.transferState.data.amount}
              onChange={mainState.transferState.getHandler("amount")}
              // @ts-ignore
              getError={mainState.transferState.getError("amount")}
              errorHint={mainState.transferState.errors.amount}
            />
            <LabeledInput
              readOnly={!mainState.isOriginPair}
              type="number"
              step="any"
              label="Total quote"
              value={mainState.transferState.totalAsset}
              onChange={mainState.transferState.getHandler("totalAsset")}
              // error={mainState.transferState.getError("totalAsset")}
              // errorHint={mainState.transferState.errors.totalAsset}
            />
          </styles.InputWrapper>
        </styles.InputsGroup>
      </styles.Content>

      <Loader show={mainState.transferState.showLoader} />
      <styles.SubmitWrapper>
        <CheckBox
          disabled={!mainState.isOriginPair}
          label="Do sell before buy"
          checked={mainState.transferState.data.sellFirst}
          onChange={mainState.transferState.getHandler("sellFirst")}
        />
        <SubmitButton size="m" disabled={!mainState.isOriginPair} />
      </styles.SubmitWrapper>
    </styles.Form>
  );
});
