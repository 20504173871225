import { CheckBox } from "src/components/shared/CheckBox";
import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const GraphContainer = styled(PanelContainer)`
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 10px;

  padding: 15px;
`;

export const QuoteCheckBox = styled(CheckBox)`
  color: ${({ theme }) => theme.assetColor};
`;

export const BaseCheckBox = styled(CheckBox)`
  color: ${({ theme }) => theme.tokenColor};
`;
