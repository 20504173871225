import { IReactionDisposer, comparer, makeAutoObservable, observable, reaction } from "mobx";
import { isDefaultSeriesPriceScaleId } from "src/components/shared/Graph/types";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable, Nullish } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import {
  IPriceScaleApiProvider,
  ISeriesApiProviderType,
  ISeriesStateOptions,
  PriceScaleVisibilityMap,
  SeriesLegendData,
  SeriesMap,
  SeriesOptionsMap,
  SeriesOptionsType,
  SeriesTitlesMap,
} from "./types";
import { seriesMapToLegendData } from "./utils";

export interface ISeriesState {
  get seriesMap(): SeriesMap;
  get series(): (ISeriesApiProviderType | null)[];
  get seriesTitlesMap(): SeriesTitlesMap;
  get legendSeriesData(): SeriesLegendData[];

  setPriceScalesApi: (api: IPriceScaleApiProvider | null) => void;
  setSeries: (id: string, series: Nullish<ISeriesApiProviderType>) => void;
  setSeriesOptions: (id: string, options: SeriesOptionsType) => void;
  toggleSeriesVisibility: (id: string) => void;
  showSingleSeries: (id: string) => void;
  setSeriesTitle: (id: string, title: string) => void;
}

export class SeriesStore implements ISeriesState, IDisposable {
  private _seriesMap: SeriesMap = observable.map(undefined, { deep: false });

  private _seriesOptionsMap: SeriesOptionsMap = new Map();

  private _seriesTitlesMap: SeriesTitlesMap = new Map();

  private _priceScalesApi: IPriceScaleApiProvider | null = null;

  private _scalesVisibilityReaction: IReactionDisposer;

  constructor({ autoShowScales = true }: ISeriesStateOptions = {}) {
    makeAutoObservable<this, "_seriesMap" | "_priceScalesApi">(this, {
      _seriesMap: false,
      _priceScalesApi: false,
    });

    makeLoggable<this, "_seriesOptionsMap" | "_priceScaleVisibilityMap">(this, {
      seriesMap: true,
      _seriesOptionsMap: true,
      _priceScaleVisibilityMap: true,
    });

    this._scalesVisibilityReaction = reaction(
      () => this._priceScaleVisibilityMap,
      (visibilityMap) => {
        if (!autoShowScales) return;

        if (!visibilityMap) return;

        this._priceScalesApi?.setScalesVisibility(visibilityMap);
      },
      {
        equals: (a, b) => {
          // ignore next empty visibility options
          if (!b) return true;
          if (!a) return false;
          return comparer.shallow(a, b);
        },
      }
    );
  }

  get seriesMap() {
    return this._seriesMap;
  }

  get series() {
    return Array.from(this._seriesMap.values());
  }

  private _getSeriesTitle = (id: string) => this._seriesTitlesMap.get(id) || "";

  get seriesTitlesMap() {
    return this._seriesTitlesMap;
  }

  get legendSeriesData() {
    const optionsEntries = Array.from(this._seriesOptionsMap.entries()).map(([id, options]) =>
      options ? ([id, options] as const) : null
    );

    const seriesData = filterBoolean(
      optionsEntries.map((entry) => {
        if (!entry) return null;
        const [id, options] = entry;
        const seriesApi = this._seriesMap.get(id);
        const seriesTitle = this._getSeriesTitle(id);
        const legendData = seriesMapToLegendData(id, seriesTitle, options, seriesApi);
        return legendData as SeriesLegendData;
      })
    );

    return seriesData;
  }

  private get _priceScaleVisibilityMap(): PriceScaleVisibilityMap | null {
    if (!this._seriesOptionsMap.size) return null;

    const scalesVisibilityMap = { left: 0, right: 0 };

    this._seriesOptionsMap.forEach((options) => {
      const priceScaleId = options?.priceScaleId;
      const visible = options?.visible;
      if (priceScaleId && isDefaultSeriesPriceScaleId(priceScaleId)) {
        scalesVisibilityMap[priceScaleId] += visible ? 1 : 0;
      }
    });

    return {
      left: scalesVisibilityMap.left > 0,
      right: scalesVisibilityMap.right > 0,
    };
  }

  setPriceScalesApi = (api: IPriceScaleApiProvider | null) => {
    this._priceScalesApi = api;
  };

  setSeriesOptions = (id: string, options: SeriesOptionsType) => {
    const series = this._seriesMap.get(id);
    if (!series) return;
    this._seriesOptionsMap.set(id, options);
  };

  setSeriesTitle = (id: string, title: string) => {
    this._seriesTitlesMap.set(id, title);
  };

  setSeries = (id: string, series: Nullish<ISeriesApiProviderType>) => {
    if (series) {
      this._seriesMap.set(id, series);
    } else {
      this._seriesMap.delete(id);
      // clear cached options
      this._seriesOptionsMap.delete(id);
      this._seriesTitlesMap.delete(id);
    }
  };

  toggleSeriesVisibility = (id: string) => {
    const series = this._seriesMap.get(id);
    if (series) {
      series.toggleVisibility();
    }
  };

  showSingleSeries = (id: string) => {
    this._seriesMap.forEach((series, seriesId) => {
      if (seriesId === id) {
        series?.show();
      } else {
        series?.hide();
      }
    });
  };

  destroy = () => {
    this._scalesVisibilityReaction();
  };
}
