import { ComponentPropsWithoutRef, memo, useCallback } from "react";
import { setTextClipboard } from "src/helpers/clipboard";
import { CopyButton } from "../../Buttons/v2/variants/IconButtons/CopyButton";
import { DeleteButton } from "../../Buttons/v2/variants/IconButtons/DeleteButton";
import { HashLabel, HashLabelOwnProps } from "./HashLabel";
import * as styles from "./style";

export interface HashesListItemProps extends HashLabelOwnProps, ComponentPropsWithoutRef<"li"> {
  onDeleteHash?: (hash: string) => void;
  showDelete?: boolean;
}

export const HashesListItem = memo(
  ({ address, errorHint, errorType, onDeleteHash, showDelete, ...props }: HashesListItemProps) => {
    const hashLabelProps = {
      address,
      errorHint,
      errorType,
    };
    const onCopyHash = useCallback(() => {
      setTextClipboard(address);
    }, [address]);

    const onDeleteHashCb = useCallback(() => {
      onDeleteHash?.(address);
    }, [address, onDeleteHash]);

    return (
      <styles.HashesListItem {...props}>
        <styles.AddressWrapper>
          <HashLabel {...hashLabelProps} />
          <CopyButton onClick={onCopyHash} />
        </styles.AddressWrapper>
        {showDelete && <DeleteButton onClick={onDeleteHashCb} />}
      </styles.HashesListItem>
    );
  }
);
