import { useCallback } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import Icons from "src/icons/Icons";
import { HashType } from "src/state/DEXV2/DEXV2Stats/Hashes/HashesTypeStore";
import * as styles from "./style";

export interface InteractionPanelProps {
  currentType: HashType;
  items: HashesMenuItem[];
  setCurrentType: (type: HashType) => void;
  onRefresh?: () => void;
  onDownload?: () => void;
}
export interface HashesMenuItem extends MenuItemData {
  value: HashType;
}

export const InteractionPanel = ({
  currentType,
  items,
  setCurrentType,
  onRefresh,
  onDownload,
}: InteractionPanelProps) => {
  const onChangeKey = useCallback(
    (value: string) => {
      const key = value as HashType;
      setCurrentType(key);
    },
    [setCurrentType]
  );

  const onRefreshHashes = useCallback(() => {
    onRefresh?.();
  }, [onRefresh]);

  const onDownloadHashes = useCallback(() => {
    onDownload?.();
  }, [onDownload]);

  return (
    <styles.Container>
      <IconButton onClick={onDownloadHashes}>{Icons.upload()}</IconButton>

      <styles.SwitchGraphs
        border={false}
        selectedItem={currentType}
        direction="row"
        menuItems={items}
        onChange={onChangeKey}
      />

      <ReloadButton onClick={onRefreshHashes} />
    </styles.Container>
  );
};
