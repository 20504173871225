import styled from "styled-components";
import { TableCellText } from "src/components/shared/TableV2";
import { PromptMsg } from "src/components/shared/shared";

export const Wrapper = styled.div`
  width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled(TableCellText)`
  width: auto;
  max-width: 130px;
  display: inline-block;
  text-align: left;
`;

export const MetaPromptInfo = styled.span`
  height: 12px;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const TitlePromptMsg = styled(PromptMsg)`
  font-size: 12px !important;
`;
