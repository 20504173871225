import { DEXBot } from "src/components/AllBots/Bots/DEXBots/DEXBot";
import { Header } from "src/components/AllBots/Bots/Header";
import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import styled from "styled-components";

export const BotInfoDEXFallback = styled(CrashPanel)`
  height: 90px;
  flex-shrink: 0;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const DEXBotForPages = styled(DEXBot)`
  & > div > div {
    grid-template-columns: repeat(5, minmax(90px, 1fr)) 70px;
  }
  &:not(:last-of-type) {
    margin: 0px;
  }
  /* & > div > div:first-of-type {
    left: 3px;
  } */
`;

export const HeaderForPages = styled(Header)`
  grid-template-columns: repeat(5, minmax(90px, 1fr)) 70px;
`;
