import { makeAutoObservable } from "mobx";
import WalletsStore, { IWalletsFormProvider } from "../WalletsStore";
import { BlacklistWalletsAdapter } from "./BlacklistWalletsAdapter";

export interface IBlacklistWalletsProvider {
  get blacklistWallets(): string[];
  setBlacklistWallets: (wallets: string[]) => void;
}

export default class BlacklistWalletsStore {
  private _blacklistWalletsState: IWalletsFormProvider;

  constructor(walletsProvider: IBlacklistWalletsProvider) {
    makeAutoObservable(this);

    const providerAdapter = new BlacklistWalletsAdapter(walletsProvider);

    this._blacklistWalletsState = new WalletsStore(providerAdapter, {
      walletName: "blacklist wallet",
      validateWallets: false,
    });
  }

  get blacklistWalletsState() {
    return this._blacklistWalletsState;
  }
}
