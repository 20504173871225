import { observer } from "mobx-react-lite";
import { AuxiliaryModuleText, Wrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { IStrategyModuleInfo, StrategyModuleTypes } from "src/modules/expertSystem";
import * as styles from "./style";

export interface ModuleTabletProps {
  module: IStrategyModuleInfo;
  counter: number;
  appendModule: (uuid: string, type: StrategyModuleTypes) => void;
}

export const ModuleTablet = observer(({ module, counter, appendModule }: ModuleTabletProps) => (
  <styles.Container>
    <styles.Title>{module.name}</styles.Title>

    <Wrapper>
      {counter ? <AuxiliaryModuleText>{counter} is added</AuxiliaryModuleText> : null}
      <AddButton onClick={() => appendModule(module.uuid, module.type)} />
    </Wrapper>
  </styles.Container>
));
