import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef } from "react";
import { ChartingLibraryWidgetOptions, IChartingLibraryWidget } from "src/charting_library";
import { widget } from "src/charting_library/charting_library.esm";
import { Loader } from "src/components/shared/Loader";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useDefaultTVChartProps } from "src/hooks/useDefaultChartProps";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import DatafeedStore from "src/state/CEX/CEXExchange/TVChart/DatafeedStore";
import { ThemeContext } from "styled-components";
import useLocalStore from "src/hooks/useLocalStore";
import { useSubscribableStore } from "src/hooks/useSubscribableStore";
import { TVChartProps } from "../../../Analytics/ChartPanel/Chart";
import * as styles from "./style";

interface TVChartContainerProps
  extends TVChartProps,
    Pick<React.ComponentPropsWithoutRef<"div">, "style"> {}

export const TVChart = observer(({ pricePrecision = 4, style }: TVChartContainerProps) => {
  const mainState = useLateInitContext(ExchangeContext);

  const tvWidgetRef = useRef<IChartingLibraryWidget | null>(null);

  const state = useLocalStore(
    DatafeedStore,
    tvWidgetRef,
    mainState.tvChartSettingsState.streamingState
  );

  useSubscribableStore(state);

  useEffect(() => {
    state.setOurOrders(
      mainState.orderBookState.ourOriginSellOrders,
      mainState.orderBookState.ourOriginBuyOrders
    );
  }, [
    state,
    mainState.orderBookState.ourOriginSellOrders,
    mainState.orderBookState.ourOriginBuyOrders,
  ]);

  const theme = useContext(ThemeContext);

  // let tvWidget: IChartingLibraryWidget | null = null;

  const defaultProps = useDefaultTVChartProps();

  const widgetOptions: ChartingLibraryWidgetOptions = {
    debug: false,
    // pass a string with the name of the bot to send requests to the exchange
    // symbol: `${pair}_${exchange}`,
    symbol: `${state.pair}`,
    datafeed: state as any,
    interval: defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
    container: "tv_chart_container",
    library_path: defaultProps.library_path as string,
    locale: defaultProps.locale,
    disabled_features: defaultProps.disabled_features,
    enabled_features: defaultProps.enabled_features
      ? [...defaultProps.enabled_features, "hide_left_toolbar_by_default"]
      : undefined,
    fullscreen: defaultProps.fullscreen,
    autosize: defaultProps.autosize,
    studies_overrides: defaultProps.studies_overrides,
    // darck mode
    overrides: defaultProps.overrides,
    custom_css_url: defaultProps.custom_css_url,
    save_load_adapter: {
      getAllCharts: async (): Promise<any> => {},
      removeChart: (id): any => {},
      saveChart: (data): any => {},
      getChartContent: async (data): Promise<string> => state.getDefaultAnalytic(String(data)),
      getAllStudyTemplates: async (): Promise<any> => {},
      removeStudyTemplate: (): any => {},
      saveStudyTemplate: (): any => {},
      getStudyTemplateContent: (): any => {},
      getDrawingTemplates: (): any => {},
      loadDrawingTemplate: (): any => {},
      removeDrawingTemplate: (): any => {},
      saveDrawingTemplate: (): any => {},
    },
  };

  const componentDidMount = () => {
    // eslint-disable-next-line new-cap
    const tvWidget1 = new widget(widgetOptions);
    tvWidgetRef.current = tvWidget1;

    const tvWidget = tvWidgetRef.current;

    tvWidget.onChartReady(() => {
      // set widget for store the current state of the chart
      state.setChartCreated(true);

      // create dropdown
      state.createShowHideOrders();

      // check default analytic
      if (state.defaultUUID) {
        state.createAnalyticNameBtn();

        state.loadDefaultAnalytic();
      }

      // set pricePrecision for price scale
      state.priceScaleRounding();

      // for toggle theme
      state.removeOurOrders();

      // search volume graph id
      const studyId = tvWidget?.activeChart().getAllStudies()[0].id;

      // separation of the volume chart from the main chart
      if (studyId) {
        tvWidget?.activeChart().getStudyById(studyId).unmergeDown();

        const panesHeight = tvWidget?.activeChart().getAllPanesHeight();

        if (panesHeight)
          tvWidget
            ?.activeChart()
            .setAllPanesHeight([panesHeight[0] - panesHeight[0] * 0.2, panesHeight[0] * 0.2]);
      }
    });
  };

  const componentWillUnmount = () => {
    if (tvWidgetRef.current !== null) {
      tvWidgetRef.current.remove();
      tvWidgetRef.current = null;
    }
  };

  useEffect(() => {
    componentDidMount();

    return () => {
      componentWillUnmount();
    };
  }, []);

  useEffect(() => {
    // create line our orders
    if (state.chartCreated) {
      state.removeOurOrders();
      state.highlightOurOrders();
    }
  }, [state.chartCreated, state.ourOrders]);

  useEffect(() => {
    state.setPricePrecision(pricePrecision);
    // state.priceScaleRounding();
  }, [pricePrecision]);

  useEffect(() => {
    state.getPairAnalytic();
  }, [state]);

  // switch theme for chart
  useEffect(() => {
    state.toggleChartTheme();
  }, [state, theme]);

  useEffect(() => {
    if (defaultProps.overrides) state.setOverrides(defaultProps.overrides);
  }, [defaultProps, state]);

  return (
    <styles.Content style={style}>
      <styles.TVChartContainer id="tv_chart_container" />
      <Loader show={state.isLoading} />
    </styles.Content>
  );
});
