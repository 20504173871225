import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface LaunchesCountSpanProps extends ComponentPropsWithoutRef<"span"> {
  total: number;
  completed: number;
}

const COUNT_DELIMITER = "/";

export const LaunchesCountSpan = ({ total, completed, ...props }: LaunchesCountSpanProps) => {
  const remainingCounts = total - completed;
  return (
    <styles.Container {...props}>
      <styles.LaunchesCountText>{total}</styles.LaunchesCountText>
      &nbsp;
      {COUNT_DELIMITER}
      &nbsp;
      <styles.LaunchesCountText $accent>{remainingCounts}</styles.LaunchesCountText>
    </styles.Container>
  );
};
