import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { BaseModuleForm, RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IActionModuleCreate, IStrategyModuleCreateType } from "src/modules/expertSystem";
import { BotUUIDField } from "../../../shared/BotUUIDField";
import { getEmptyActionModule } from "../shared/utils";

const MODULE_TYPE = "change_settings";

interface ChangeSettingsProps {
  createCb: (close: () => void) => (module: IActionModuleCreate) => void;
}

export const ChangeSettings = observer(({ createCb }: ChangeSettingsProps) => {
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<IStrategyModuleCreateType<typeof MODULE_TYPE>>({
    defaultValues: getEmptyActionModule(MODULE_TYPE),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <BaseModuleForm onSubmit={handleSubmit(createCb(close))} formId={MODULE_TYPE}>
        <RowWrapper>
          <BotUUIDField />
        </RowWrapper>
      </BaseModuleForm>
    </FormProvider>
  );
});
