import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { FloatingOrderParams } from "src/modules/exchange/trade";
import { OrderPanel, OrderPanelProps } from "../shared/OrderPanel";
import { FloatingOrderForm } from "./FloatingOrderForm";
import { LimitOrderForm } from "./LimitOrderForm";
import { OrderSwitch } from "./OrderSwitch";
import { StopOrderForm } from "./StopOrderForm";

const EMPTY_FLOATING_ORDER: FloatingOrderParams = {
  amount: "",
  amount_filled_limit: "",
  price_percent: "",
};

export interface OrderFormProps extends Pick<OrderPanelProps, "side"> {}

export const OrderForm = ({ side }: OrderFormProps) => {
  const [orderSize, setOrderSize] = useState<number>();

  const formId = `${side}_form`;

  const formProps = {
    side,
    formId,
    setOrderSize,
  };

  const methods = useForm<FloatingOrderParams>({
    defaultValues: EMPTY_FLOATING_ORDER,
  });

  return (
    <OrderPanel formId={formId} side={side} headerProps={{ orderSize }}>
      <OrderSwitch>
        <ErrorBoundary key="Limit">
          <LimitOrderForm key="Limit" {...formProps} />
        </ErrorBoundary>

        <ErrorBoundary key="Stop">
          <StopOrderForm key="Stop" {...formProps} />
        </ErrorBoundary>

        <ErrorBoundary key="Floating">
          <FormProvider {...methods}>
            <FloatingOrderForm key="Floating" {...formProps} />
          </FormProvider>
        </ErrorBoundary>
      </OrderSwitch>
    </OrderPanel>
  );
};
