import { useEventCallback } from "@mui/material";
import { useUtils } from "@mui/x-date-pickers/internals";
import { Dayjs } from "dayjs";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { SetRequired } from "src/helpers/utils";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useControlledValue } from "../../../../hooks/useControlledValue";
import { DatePicker } from "../DatePicker";
import { DateTimeRangeFieldProps } from "../DateTimeFields/DateTimeRangeField";
import { DateTimeRangeValidationError } from "../shared/hooks/useDateTimeValidation";
import { useValidateDateTimeRange } from "../shared/hooks/useMultiInputDateTimeRangeField";
import { DateTimeRange } from "../shared/models/dateTimeRange";
import { defaultShortcutsItems } from "../shared/utils/shortcuts";
import { rangeValueManager } from "../shared/utils/valueManager";
import { DateTimeRangePickerButtonsProps } from "./DateTimeRangePickerButtons";
import {
  DesktopDateTimeRangePicker,
  ShowablePickerShortcutsProps,
} from "./DesktopDateTimeRangePicker";
import { MobileDateTimeRangePicker } from "./MobileDateTimeRangePicker";
import { PickerShortcutsItem, PickerShortcutsOnChange } from "./PickerShortcuts";

type UseRangeErrorResponse = [DateTimeRangeValidationError, Dispatch<DateTimeRangeValidationError>];

const useRangeError = (): UseRangeErrorResponse => {
  const [rangeError, setRangeError] = useState<DateTimeRangeValidationError>(
    rangeValueManager.emptyValue
  );

  const onRangeError = useCallback((error: DateTimeRangeValidationError) => {
    setRangeError(error);
  }, []);
  return [rangeError, onRangeError];
};

interface UseDateTimeRangePickerButtonsParams
  extends SetRequired<
    Pick<DateTimeRangePickerProps, "value" | "onChange" | "showReset" | "onAccept">,
    "value" | "onChange"
  > {
  rangeError: DateTimeRangeValidationError;
}

const useDateTimeRangePickerButtons = ({
  value,
  onChange,
  showReset,
  rangeError,
  onAccept,
}: UseDateTimeRangePickerButtonsParams): SetRequired<
  DateTimeRangePickerButtonsProps,
  "onReset" | "onAccept"
> => {
  const utils = useUtils<Dayjs>();

  const showAccept = onAccept !== undefined;

  const onAcceptRange = useEventCallback(() => {
    if (
      !rangeValueManager.hasError(rangeError) &&
      !rangeValueManager.areValuesEqual(utils, value, rangeValueManager.emptyValue)
    ) {
      onAccept?.(value);
    }
  });

  const onReset = useCallback(() => {
    onChange(rangeValueManager.emptyValue);
  }, [onChange]);

  return {
    showAccept,
    showReset,
    onReset,
    onAccept: onAcceptRange,
  };
};

interface UseDateTimeRangePickerShortcutsParams
  extends SetRequired<
    Pick<
      DateTimeRangePickerProps,
      | "showShortcuts"
      | "shortcuts"
      | "onShortcutChange"
      | "onChange"
      | "acceptOnShortcutChange"
      | "onAccept"
    >,
    "onChange"
  > {
  validationProps: Omit<DateTimeRangeFieldProps, "onError" | "onChange">;
}

interface PickerShortcutsState {
  range: DateTimeRange;
  value: string;
}

const useDateTimeRangePickerShortcuts = ({
  validationProps,
  showShortcuts,
  shortcuts,
  onShortcutChange: outerOnShortcutChange,
  onChange,
  acceptOnShortcutChange,
  onAccept,
}: UseDateTimeRangePickerShortcutsParams): ShowablePickerShortcutsProps => {
  const utils = useUtils<Dayjs>();

  const [currentShortcut, setCurrentShortcut] = useState<PickerShortcutsState>({
    value: "",
    range: rangeValueManager.emptyValue,
  });

  const validateRange = useValidateDateTimeRange(validationProps);

  const outerOnChange: PickerShortcutsOnChange = outerOnShortcutChange ?? onChange;

  const onShortcutChange: PickerShortcutsOnChange = useCallback(
    (value, context) => {
      outerOnChange(value, context);
      setCurrentShortcut({ range: value, value: context.value });
    },
    [outerOnChange]
  );

  const onAcceptRange = useEventCallback((value: DateTimeRange) => {
    if (!rangeValueManager.areValuesEqual(utils, value, rangeValueManager.emptyValue)) {
      onAccept?.(value);
    }
  });

  useEffect(() => {
    const { value: shortcutValue, range: shortcutRange } = currentShortcut;
    if (!acceptOnShortcutChange || !shortcutValue) return;
    const rangeError = validateRange(shortcutRange);
    if (rangeValueManager.hasError(rangeError)) {
      return;
    }
    onAcceptRange(shortcutRange);
  }, [acceptOnShortcutChange, currentShortcut, onAcceptRange, validateRange]);

  return {
    show: showShortcuts,
    items: shortcuts,
    onChange: onShortcutChange,
  };
};

export interface DateTimeRangePickerProps
  extends Omit<DateTimeRangeFieldProps, "value" | "onChange" | "onError" | "picker" | "timezone"> {
  defaultValue?: DateTimeRange;
  value?: DateTimeRange;
  onChange?: (range: DateTimeRange) => void;
  onAccept?: (range: DateTimeRange) => void;
  onShortcutChange?: PickerShortcutsOnChange;
  acceptOnShortcutChange?: boolean;
  shortcuts?: PickerShortcutsItem[];
  showShortcuts?: boolean;
  showReset?: boolean;
  mobileQuery?: string;
  showUTC?: boolean;
}

export const DEFAULT_MOBILE_PICKER_QUERY = "(max-width: 500px)";

export const DateTimeRangePicker = ({
  defaultValue,
  value: valueProp,
  onChange: onChangeProp,
  maxDate,
  minDate,
  onAccept: outerOnAccept,
  shortcuts = defaultShortcutsItems,
  showShortcuts = true,
  onShortcutChange,
  acceptOnShortcutChange,
  showReset = true,
  mobileQuery = DEFAULT_MOBILE_PICKER_QUERY,
  showUTC = true,
  ...props
}: DateTimeRangePickerProps) => {
  const showMobile = useMediaQuery(mobileQuery);

  const [value, onChange] = useControlledValue({
    controlled: valueProp,
    onChange: onChangeProp,
    default: defaultValue,
  });

  const [rangeError, onRangeError] = useRangeError();

  const buttonsProps = useDateTimeRangePickerButtons({
    value,
    onChange,
    showReset,
    rangeError,
    onAccept: outerOnAccept,
  });

  const timezone = showUTC ? "UTC" : "system";

  const validationProps: UseDateTimeRangePickerShortcutsParams["validationProps"] = {
    maxDate,
    minDate,
    value,
    timezone,
    ...props,
  };

  const shortcutsProps = useDateTimeRangePickerShortcuts({
    shortcuts,
    showShortcuts,
    onShortcutChange,
    onChange,
    onAccept: outerOnAccept,
    acceptOnShortcutChange,
    validationProps,
  });

  const sharedProps = { value, onChange, maxDate, minDate };

  const pickerProps = { ...sharedProps, showUTC };
  const Picker = <DatePicker {...pickerProps} />;

  const fieldProps: DateTimeRangeFieldProps = {
    ...sharedProps,
    ...props,
    timezone,
    onError: onRangeError,
    picker: Picker,
  };

  if (showMobile) {
    return (
      <MobileDateTimeRangePicker
        fieldProps={fieldProps}
        buttonsProps={buttonsProps}
        shortcutsProps={shortcutsProps}
      />
    );
  }
  return (
    <DesktopDateTimeRangePicker
      fieldProps={fieldProps}
      buttonsProps={buttonsProps}
      shortcutsProps={shortcutsProps}
    />
  );
};
