import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";
import { Headline2 } from "src/components/shared/Typography";
import { FormRow } from "../../Settings/shared/style";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled(Headline2)``;

export const InfoPanel = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 14px;

  padding: 16px 20px;

  ${CardSurface}
`;

export const InfoRow = styled(FormRow)`
  gap: 11px;
`;
