import React, { useState } from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { Loader } from "src/components/shared/Loader";
import { TVChart } from "./TVChartContainer";
import { VolumeInfoPanel } from "./VolumeInfoPanel";
import { DepthChart } from "./DepthChart";
import * as styles from "./style";

interface ChartPanelProps {
  party: string;
}

export const ChartPanel = observer(({ party }: ChartPanelProps) => {
  const state = useLateInitContext(ExchangeContext);
  const [currentKey, setCurrentKey] = useState("TRADING");

  const onChangeKey = (value: string) => {
    const key = value as "TRADING" | "DEPTH";
    setCurrentKey(key);
  };

  return (
    <styles.Container>
      <styles.Header>
        <ErrorBoundary>
          <VolumeInfoPanel />
        </ErrorBoundary>

        <styles.SwitchGraphs
          border={false}
          selectedItem={currentKey}
          direction="row"
          menuItems={[
            { label: "Trading View", value: "TRADING" },
            { label: "Depth chart", value: "DEPTH" },
          ]}
          onChange={onChangeKey}
        />
      </styles.Header>
      {currentKey === "TRADING" ? (
        <ErrorBoundary>
          {state.pair ? (
            <TVChart pricePrecision={state.pricePrecision} style={{ gridArea: "trading-view" }} />
          ) : (
            <Loader show />
          )}
        </ErrorBoundary>
      ) : (
        <ErrorBoundary>
          <DepthChart />
        </ErrorBoundary>
      )}
    </styles.Container>
  );
});
