import { makeAutoObservable } from "mobx";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import {
  graterThan,
  isInteger,
  required,
  smallerThan,
  strictlyGreaterThan,
  validateData,
} from "src/validation-schemas";
import { INewExchangeModule } from "./AddModuleModal";

export interface MultiGrinderSettings {
  amountQuote: number | "";
  countBuy: number | "";
  countSell: number | "";
  periodCheck: number | "";
  priceConst: number | "";
}

export interface IMultiGrinder {
  party: string;
  exchanges: INewExchangeModule[];
  settings: MultiGrinderSettings;
}

const EMPTY_MULTI_GRINDER: IMultiGrinder = {
  party: "",
  exchanges: [],
  settings: {
    amountQuote: "",
    countBuy: "",
    countSell: "",
    periodCheck: "",
    priceConst: "",
  },
};

type MultiGrinderKeys = FormDataKeys<IMultiGrinder>;

class MultiGridFormStore {
  grinder = EMPTY_MULTI_GRINDER;

  handlers: FormHandlers<IMultiGrinder> = {};

  errors: FormErrors<IMultiGrinder> = {};

  private _validation: FormValidation<IMultiGrinder> = {
    "settings.amountQuote": [required(), strictlyGreaterThan(0, "The value must be positive")],
    "settings.countBuy": [
      required(),
      isInteger(),
      graterThan(3, "The value must be greater than or equal to 3"),
    ],
    "settings.countSell": [
      required(),
      isInteger(),
      graterThan(3, "The value must be greater than or equal to 3"),
    ],
    "settings.priceConst": [required(), strictlyGreaterThan(0, "The value must be positive")],
    "settings.periodCheck": [
      required(),
      isInteger(),
      strictlyGreaterThan(0, "The value must be positive"),
      graterThan(15, "The value must be greater 15"),
      smallerThan(60, "The value must be less 60"),
    ],
  };

  constructor() {
    makeAutoObservable(this);
  }

  private _clearHandlers = () => {
    this.handlers = {};
  };

  private _clearErrors = () => {
    this.errors = {};
  };

  private _clearForm = () => {
    this.grinder = EMPTY_MULTI_GRINDER;
    this._clearHandlers();
    this._clearErrors();
  };

  setSettings = (settings: MultiGrinderSettings) => {
    this._clearForm();
    this.grinder.settings = settings;
  };

  getHandler = (key: MultiGrinderKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.grinder, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        targetData[endKey] = getChangeEventValue(e);
      };
    }

    return this.handlers[key]!;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this._validation, this.grinder, this.errors, validateKeys);
}

export default MultiGridFormStore;
