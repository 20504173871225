import { Dayjs } from "dayjs";
import { ChartOptions, DeepPartial } from "lightweight-charts";
import { ComponentType } from "react";
import { BaseTheme, Theme } from "src/components/Themes";
import { LegendProps } from "../../shared/ChartLegend/Legend";

export * from "./series";

export type {
  ISeriesApiProvider,
  OptionalSeriesOptions,
  SeriesOptions,
} from "../ChartSeries/SeriesRoot/types";

export type ThemeLineColor = Exclude<
  keyof Theme,
  keyof BaseTheme | "botColorStatus" | "botBgSideColorStatus" | "botBgColorStatus" | "mode"
>;

export type LineColor = ThemeLineColor | string;

export type GraphRange = [Dayjs, Dayjs];

export type GraphDataRequest = (range: GraphRange) => void;

export type OptionalChartOptions = DeepPartial<ChartOptions>;

export type DefaultSeriesPriceScaleId = "left" | "right";

export type SeriesPriceScaleId = DefaultSeriesPriceScaleId | "";

export const isDefaultSeriesPriceScaleId = (
  scaleId: string
): scaleId is DefaultSeriesPriceScaleId => scaleId === "left" || scaleId === "right";

export interface GraphLegendOptions {
  show?: boolean;
  legend?: ComponentType<LegendProps>;
  position?: "top" | "bottom";
}
