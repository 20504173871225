import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const AccountsContainer = styled.div`
  width: 100%;

  overflow-y: scroll;

  padding-right: 5px;
  padding-bottom: 10px;

  ${ScrollSize}

  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const VolumeContainer = styled.div`
  width: 100%;
  max-width: 400px;

  display: flex;
  flex-direction: column;

  gap: 10px;

  @media screen and (max-width: 600px) {
    height: 0px;
    flex: 1 1 auto;
  }
`;
