import { makeAutoObservable, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import { FormEvent } from "react";
import { MultiValue } from "react-select";
import { SelectorPropsGetter } from "src/components/BotsContent/CEX/ExpertSystem/Modules/shared/ModuleFilters";
import { getSelectorList, toRawSelectorValue } from "src/helpers/forms/selectors";
import { makeLoggable } from "src/helpers/logger";
import {
  ACTIONS_MODULE_TYPES,
  CONDITION_MODULE_TYPES,
  StrategyCategoryModuleTypes,
  StrategyModuleCategory,
} from "src/modules/expertSystem";
import { SelectorValue } from "src/modules/shared";
import FiltersEnabledStore from "./FiltersEnabledStore";

export const MODULE_USAGE_STATUSES = ["active", "linked"] as const;

type ModuleUsageStatuses = (typeof MODULE_USAGE_STATUSES)[number];

const FILTER_KEYS = ["type", "status"] as const;

export type ModulesFilterKey = (typeof FILTER_KEYS)[number];

export type ModuleFiltersState<T extends StrategyModuleCategory> = {
  status: ModuleUsageStatuses[];
  type: StrategyCategoryModuleTypes<T>[];
};

type ModuleFiltersStateValue<
  T extends StrategyModuleCategory,
  K extends ModulesFilterKey,
> = ModuleFiltersState<T>[K][number];

type ModuleFilersOptions<T extends StrategyModuleCategory> = ModuleFiltersState<T>;

const INITIAL_VALUES: ModuleFiltersState<StrategyModuleCategory> = {
  type: [],
  status: [],
};

type OnModuleFiltersSubmit = (
  values: ModuleFiltersState<StrategyModuleCategory>
) => Promise<boolean | undefined>;

export interface IModuleFiltersParams<T extends StrategyModuleCategory> {
  type: T;
  onSubmit?: OnModuleFiltersSubmit;
  onClose?: () => void;
  initialState?: ModuleFiltersState<T>;
}

export default class ModuleFiltersStore<T extends StrategyModuleCategory> {
  private _options!: ModuleFilersOptions<T>;

  private _values!: ModuleFiltersState<T>;

  private _onSubmit?: OnModuleFiltersSubmit;

  private _onClose?: () => void;

  private _enabledState: FiltersEnabledStore<ModulesFilterKey>;

  constructor({ onSubmit, onClose, ...params }: IModuleFiltersParams<T>) {
    makeAutoObservable<any>(this, {
      _selectorOptions: false,
      _selectorValues: false,
      getFilterProps: false,
    });

    this._onSubmit = onSubmit;

    this._onClose = onClose;

    this._initState(params);

    this._enabledState = new FiltersEnabledStore({ filterStateProvider: this });

    makeLoggable<any>(this, {
      _values: true,
    });
  }

  private _initState = ({ type, initialState }: IModuleFiltersParams<T>) => {
    this._values = toJS(initialState) ?? INITIAL_VALUES;

    const initialTypeOptions = type === "condition" ? CONDITION_MODULE_TYPES : ACTIONS_MODULE_TYPES;

    this._options = {
      type: initialTypeOptions.slice() as StrategyCategoryModuleTypes<T>[],
      status: MODULE_USAGE_STATUSES.slice(),
    };
  };

  get state() {
    return this._values;
  }

  private _setFilter = <K extends ModulesFilterKey>(key: K, value: ModuleFiltersState<T>[K]) => {
    this._values[key] = value;
  };

  private _removeFilterValue = <K extends ModulesFilterKey>(
    key: K,
    value: ModuleFiltersStateValue<T, K>
  ) => {
    const currentValues = this._values[key] as string[];

    const newValues = currentValues.filter((v) => v !== value) as ModuleFiltersState<T>[K];

    this._setFilter(key, newValues);
  };

  private _selectorOptions = computedFn((field: ModulesFilterKey) =>
    getSelectorList(this._options[field])
  );

  private _selectorValues = computedFn((field: ModulesFilterKey) =>
    getSelectorList(this._values[field])
  );

  private _selectorOnChange = (field: ModulesFilterKey) => (data: MultiValue<SelectorValue>) => {
    if (data) {
      const newValues = toRawSelectorValue(data) as ModuleFiltersState<T>[ModulesFilterKey];
      this._setFilter(field, newValues);
    }
  };

  private _selectorOnRemove = (field: ModulesFilterKey) => (item: string) => {
    this._removeFilterValue(field, item as ModuleFiltersStateValue<T, ModulesFilterKey>);
  };

  getSelectorProps: SelectorPropsGetter<ModulesFilterKey> = (key) => ({
    options: this._selectorOptions(key),
    value: this._selectorValues(key),
    onChange: this._selectorOnChange(key),
    onRemove: this._selectorOnRemove(key),
    isDisabled: !this._enabledState.state[key],
  });

  reset = () => {
    this._values = INITIAL_VALUES;
  };

  submit = async (e: FormEvent) => {
    e.preventDefault();

    const isSuccess = await this._onSubmit?.(this._values);

    if (isSuccess) {
      this._onClose?.();
    }
  };

  destroy = () => {};
}
