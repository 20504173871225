import { SaveButton } from "src/components/shared/Buttons/v2/variants/SaveButton";
import styled from "styled-components";

export const Wrapper = styled.form`
  display: flex;
  align-items: flex-end;

  margin-bottom: 15px;

  & > button {
    /* padding-top: 15px; */
    margin-left: 5px;
  }
`;

export const SaveNameButton = styled(SaveButton)`
  flex: 0 0 auto;
  height: 30px;
`;
