import { ApiModules, apiUrl } from "src/api";
import { DEXV2ExchangeVersion } from "src/modules/bots";
import ResponseHandler from "src/state/network/ResponseHandler";

export interface SupportedChain {
  chain_id: number;
  rpc: string;
  scanner: string;
  symb: string;
  chain_name: string;
  dexscreener_alias: string;
}

export interface GetSupportedChainsResponse {
  chains: SupportedChain[];
}

export const getSupportedChains = async () =>
  ResponseHandler.handler<GetSupportedChainsResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "chains"),
      method: "GET",
    },
    "getSupportedChainsDEXV2"
  );

export interface SupportedExchange {
  factory: string;
  router: string;
  dex_version: DEXV2ExchangeVersion;
  pool_percent: number[];
}

export type SupportedExchanges = Record<number, Record<string, SupportedExchange>>;

export interface GetSupportedExchangesResponse extends SupportedExchanges {}
export const getSupportedExchanges = async () =>
  ResponseHandler.handler<GetSupportedExchangesResponse>(
    {
      url: apiUrl(ApiModules.DEXV2Bot, "/exchange"),
      method: "GET",
    },
    "getSupportedExchangesDEXV2"
  );
