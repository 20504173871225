import { observer } from "mobx-react-lite";
import React from "react";
import "chart.js/auto";
import { Pie } from "react-chartjs-2";
import { Loader } from "src/components/shared/Loader";
import { useFindWidth } from "src/hooks/useFindWidth";
import styled from "styled-components";
import { ReportItem } from "../style";

const BalancesPieContainer = styled(ReportItem)`
  position: relative;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  grid-area: balances-pie;
`;

export const BalancesPie = observer(({ showLoader, balancesPieLabels, balancesPieData }) => {
  const customLabels = balancesPieLabels.map(
    (label, index) => `${label}: ${balancesPieData[index].toFixed(2)}`
  );
  const legendTogglePosition = useFindWidth(1150);
  return (
    <BalancesPieContainer>
      <Pie
        data={{
          labels: customLabels,
          datasets: [
            {
              label: "Markets Monitored",
              data: [...balancesPieData],
              backgroundColor: [
                "rgba(255, 99, 132, 0.6)",
                "rgba(54, 162, 235, 0.6)",
                "rgba(255, 206, 86, 0.6)",
                "rgba(75, 192, 192, 0.6)",
                "rgba(153, 102, 255, 0.6)",
                "rgba(255, 159, 64, 0.6)",
              ],
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              position: `${legendTogglePosition ? "left" : "bottom"}`,
              align: "start",
            },
            title: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (tooltipItem) => tooltipItem.label,
              },
            },
          },

          maintainAspectRatio: false,
        }}
      />
      <Loader show={showLoader} />
    </BalancesPieContainer>
  );
});
