import { observer } from "mobx-react-lite";
import { ProfitLossChartV2Widget } from "../../../widgets/v2/ProfitLossChartV2";
import * as styles from "./style";

export interface BalanceSummaryViewProps {}

export const BalanceSummaryView = observer(() => (
  <styles.Container>
    <ProfitLossChartV2Widget style={{ width: "100%", height: "270px" }} />
  </styles.Container>
));
