import { observer } from "mobx-react-lite";
import { TokenFillContext } from "src/context/DEXV2/DEXV2Create/TokenFill";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import { WalletRow } from "./WalletRow";
import * as styles from "./style";

export const BalancesTable = observer(() => {
  const state = useLateInitContext(TokenFillContext);
  const {
    roundedVaults,
    chainInfo,
    tickers: { quote: quoteTicker, base: baseTicker },
  } = state;
  const scannerLink = getScannerLink(chainInfo, "wallet");

  return (
    <styles.BalanceContainer>
      <styles.BalanceHeader>
        <styles.BalanceRow>
          <styles.BalanceHeaderCell style={{ textAlign: "left" }}>Address</styles.BalanceHeaderCell>
          <styles.BalanceHeaderCell>Type</styles.BalanceHeaderCell>
          <styles.BalanceHeaderCell>{baseTicker} Balance</styles.BalanceHeaderCell>
          <styles.BalanceHeaderCell>{quoteTicker} Balance</styles.BalanceHeaderCell>
        </styles.BalanceRow>
      </styles.BalanceHeader>

      <styles.BalanceBody>
        {roundedVaults.map((vault) => (
          <WalletRow key={vault.address} {...vault} scannerLink={scannerLink} />
        ))}
      </styles.BalanceBody>
    </styles.BalanceContainer>
  );
});
