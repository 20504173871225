import { makeAutoObservable, runInAction } from "mobx";
import { stringDateToUnix, unixToDateFormat, unixToISOString } from "src/helpers/dateUtils";
import { toRounding } from "src/helpers/rounding";
import { Trade } from "src/modules/exchange/trade";
import TradeHistoryStore from "./TradeHistoryStore";
import RestAPIProvider from "./providers/RestAPIProvider";
import WSProvider from "./providers/WSProvider";

const MAX_TRADING_HISTORY_SIZE = 100;

interface IMainTradesStore extends TradeHistoryStore {}

class TradesDataStorageStore {
  mainState: IMainTradesStore;

  restAPIProvider: RestAPIProvider;

  streamProvider: WSProvider;

  private _data: Trade[] = [];

  private _prevStreamPair = "";

  constructor(state: IMainTradesStore) {
    this.mainState = state;
    this.restAPIProvider = new RestAPIProvider(this);
    this.streamProvider = new WSProvider(this);

    makeAutoObservable(this);
  }

  get pair() {
    return this.mainState.pair;
  }

  get exchange() {
    return this.mainState.exchange;
  }

  get pricePrecision() {
    return this.mainState.pricePrecision;
  }

  get amountPrecision() {
    return this.mainState.amountPrecision;
  }

  get data(): Trade[] {
    switch (this.mainState.requestMode) {
      case "FETCH":
        return this.restAPIProvider.data;

      case "WS":
        return this._streamData;

      default:
        return [];
    }
  }

  private get _streamData() {
    return this._data.map(({ price, amount, time, ...other }) => ({
      ...other,
      price: toRounding(parseFloat(price), this.pricePrecision),
      amount: toRounding(parseFloat(amount), this.amountPrecision),
      time: unixToDateFormat(stringDateToUnix(time), "FullTime"),
    }));
  }

  get isChangePair() {
    return this._prevStreamPair !== this.pair;
  }

  get lockedConnect() {
    return this.streamProvider.connectStatus === 1 || this.streamProvider.connectStatus === 2;
  }

  get isLoading() {
    return this.restAPIProvider.isLoading;
  }

  get infoAcc() {
    return this.mainState.infoAcc;
  }

  private _setData = (data: Trade[]) => {
    this._data = data;

    this._data.splice(MAX_TRADING_HISTORY_SIZE);
  };

  private _setPrevStreamPair = (pair: string) => {
    this._prevStreamPair = pair;
  };

  loadData = () => {
    switch (this.mainState.requestMode) {
      case "FETCH":
        if (!this.mainState.exchange) return;

        this._checkOpenWS();

        this.restAPIProvider.fetchData(this.mainState.pair, this.mainState.exchange);

        break;
      case "WS":
        if (!this.lockedConnect || this.isChangePair) this._connectStreamData();
        break;

      default:
        break;
    }
  };

  private _connectStreamData = async () => {
    await this.restAPIProvider.fetchData(this.mainState.pair, this.mainState.exchange);

    // set first request data from REST
    this._setData(this.restAPIProvider.originData);

    this._setPrevStreamPair(this.pair);

    this.streamProvider.streamData();
  };

  private _checkOpenWS = () => {
    if (
      this.streamProvider.connectStatus === 1 ||
      this.streamProvider.connectStatus !== undefined
    ) {
      this.streamProvider.webSocketState.closeWebSocket();
    }
  };

  updateProcessing = () => {
    const responseData = this.streamProvider.data;

    if (!responseData) return;
    if (responseData.type !== "trades") return;

    const trade = responseData.result.data;

    let lastTrade: Trade;

    if (trade) {
      const channelString = responseData.result.pair;

      if (channelString !== this.streamProvider.streamPair) return;

      lastTrade = {
        ...trade,
        time: unixToISOString(trade.time),
      };

      this._addTrade(lastTrade);
    }
  };

  private _addTrade = (lastTrade: Trade) => {
    const tradeHistorySize = this._data.length;

    if (tradeHistorySize === MAX_TRADING_HISTORY_SIZE) {
      this._data.pop();
    }

    runInAction(() => this._data.unshift(lastTrade));
  };
}

export default TradesDataStorageStore;
