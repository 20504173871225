import { PopupComponent, PopupComponentProps } from "src/components/shared/popup/PopupComponent";
import { BottomDrawerContent, BottomDrawerContentProps } from "./BottomDrawerContent";

export interface BottomDrawerProps extends BottomDrawerContentProps {
  popupProps: Omit<PopupComponentProps, "children">;
}

export const BottomDrawer = ({ popupProps, ...props }: BottomDrawerProps) => (
  <PopupComponent {...popupProps}>
    <BottomDrawerContent {...props} />
  </PopupComponent>
);
