import { observer } from "mobx-react-lite";
import { LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

export const TradeSettings = observer(() => {
  const state = useLateInitContext(CEXSettingsContext);

  return (
    <styles.Container>
      <LabeledPercentRangeInput
        label="BuyPercent"
        value={state.data.settings.trade.buyPercent}
        onChange={state.getHandler("settings.trade.buyPercent")}
      />
    </styles.Container>
  );
});
