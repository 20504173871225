import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useVirtual } from "react-virtual";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IOrderBookOrder } from "src/state/CEX/CEXExchange/OrderBook/OrderBookStore";
import * as styles from "../../shared";
import { BuyCupProps } from ".";

interface VirtualBuyRowCupProps extends Omit<BuyCupProps, "showMobVers"> {
  columns: any;
  rowSize: number;
}

export const VirtualBuyRowCup = observer(
  ({ data, columns, rowSize, buyCupRef, buyContainerRef }: VirtualBuyRowCupProps) => {
    const mainState = useLateInitContext(ExchangeContext);
    const { orderBookState } = mainState;
    const { buySellState } = mainState;

    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
      parentRef: buyContainerRef,
      size: rows.length,
      estimateSize: useCallback(() => rowSize, [rowSize]),
      overscan: 3,
    });
    const { virtualItems: virtualRows } = rowVirtualizer;

    return (
      <styles.Container
        ref={buyContainerRef}
        onClick={() => {
          buySellState.setOrder("sellOrder", {
            price: orderBookState.selectedBuyOrder?.price || "",
            amount: String(orderBookState.currentTotalBuy),
          });
        }}
      >
        <styles.OrderBookTable>
          <styles.Body
            style={{
              height: `${rowVirtualizer.totalSize}px`,
            }}
            ref={buyCupRef}
          >
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<IOrderBookOrder>;

              return (
                <styles.BuyRow
                  data-tooltip-id="buy-tooltip"
                  key={row.id}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                  onMouseEnter={() =>
                    orderBookState.setSelectedRow("selectedRowBuy", virtualRow.index)
                  }
                  onMouseLeave={() => {
                    orderBookState.setSelectedRow("selectedRowBuy", null);
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const cellValue = cell.getValue<string>();

                    return (
                      <styles.Cell key={cell.id} data-value={cellValue}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </styles.Cell>
                    );
                  })}
                </styles.BuyRow>
              );
            })}
          </styles.Body>
        </styles.OrderBookTable>
      </styles.Container>
    );
  }
);
