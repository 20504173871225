import { CardSurface, ModalBackground } from "src/components/shared/shared";
import styled from "styled-components";

export const Surface = styled(ModalBackground)`
  padding: 0px 10px;
`;

export const Panel = styled.div`
  width: 100%;
  max-width: 1700px;
  height: 100%;
  max-height: calc(100% - 120px);

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding: 28px;

  ${CardSurface}

  @media (max-width: 450px) {
    max-height: 655px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;

  gap: 16px;
  padding-top: 16px;
`;
