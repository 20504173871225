import { ColumnDef } from "@tanstack/react-table";
import {
  Active,
  Aum,
  Fundings,
  Indicators,
  OurDepth,
  PartyMarketCheck,
  PNLData,
} from "src/state/MarketCheck/types";
import { ActiveCell } from "./ActiveCell";
import { AUMCell } from "./AUMCell";
import { FundingCell } from "./FundingCell";
import { IndicatorsCell } from "./IndicatorsCell";
import { OurDepthCell } from "./OurDepthCell";
import { PNLCell } from "./PNLCell";
import { CellText } from "./style";

const DISABLE_CONFIG = {
  enableSorting: false,
  enableColumnFilter: false,
};

export const partiesListColumns: ColumnDef<PartyMarketCheck>[] = [
  {
    header: "Team Lead",
    accessorKey: "team_lead",
    enableColumnFilter: false,
    cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
  },
  {
    header: "Manager",
    accessorKey: "manager",
    enableColumnFilter: false,
    cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
  },
  {
    header: "Party",
    accessorKey: "party",
    enableColumnFilter: false,
    cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
  },
  {
    header: "Funding",
    accessorKey: "fundings",
    ...DISABLE_CONFIG,
    cell: (info) => <FundingCell cellData={info.getValue<Fundings>()} />,
  },
  {
    header: "PNL",
    accessorKey: "pnl",
    ...DISABLE_CONFIG,
    cell: (info) => <PNLCell cellData={info.getValue<PNLData>()} />,
  },
  {
    header: "AUM",
    accessorKey: "aum",
    ...DISABLE_CONFIG,
    cell: (info) => <AUMCell cellData={info.getValue<Aum>()} />,
  },
  {
    header: "Our depth",
    accessorKey: "our_depth",
    ...DISABLE_CONFIG,
    cell: (info) => <OurDepthCell cellData={info.getValue<OurDepth>()} />,
  },
  {
    header: "Indicators",
    accessorKey: "indicators",
    ...DISABLE_CONFIG,
    cell: (info) => <IndicatorsCell cellData={info.getValue<Indicators>()} />,
  },
  {
    header: "",
    accessorKey: "active",
    ...DISABLE_CONFIG,
    cell: (info) => <ActiveCell cellData={info.getValue<Active>()} />,
  },
];
