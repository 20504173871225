import styled from "styled-components";
import InfoHeading from "../InfoHeading";

export const ScrollTableWrapper = styled.div`
  overflow: hidden;
`;

export const ErrorsCounter = styled.span`
  color: ${({ theme }) => theme.lightGray};
`;

export const Content = styled.div`
  position: relative;

  display: grid;
  gap: 10px;

  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-template-rows: min-content 390px 44px;

  grid-template-areas:
    "profit       buy_sell    current"
    "settings     errors      errors"
    "warning      _           buttons";

  @media screen and (max-width: 1100px) {
    grid-template-columns: minmax(300px, 520px) minmax(320px, 1fr);
    grid-template-rows: repeat(3, 1fr) 380px 44px;

    grid-template-areas:
      "settings     profit"
      "settings     buy_sell"
      "settings     current"
      "errors       errors "
      "warning      buttons";
  }

  @media screen and (max-width: 750px) {
    grid-template-columns: minmax(330px, 1fr);
    grid-template-rows: 1fr repeat(3, min-content) 340px repeat(2, 44px);

    grid-template-areas:
      "settings"
      "profit"
      "buy_sell"
      "current"
      "errors"
      "warning"
      "buttons";
  }
`;

export const InfoStatus = styled(InfoHeading)`
  gap: 10px;

  margin-bottom: 15px;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }

  & * {
    @media screen and (max-width: 900px) {
      font-size: ${({ theme }) => theme.fontSizes["18px"]};
    }

    @media screen and (max-width: 750px) {
      font-size: ${({ theme }) => theme.fontSizes["16px"]};
    }
  }
`;
