import { Link } from "react-router-dom";
import styled from "styled-components";
import { LogoIcon } from "./LogoIcon";

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;

  width: 100%;
  height: ${({ theme }) => theme.dimensions.headerHeight};

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  box-sizing: border-box;
  padding: 3px 10px;

  background-color: ${({ theme }) => theme.contentBackgroundColor};
  border-bottom: 1px solid rgba(145, 158, 171, 0.2);
`;

export const Logo = styled(LogoIcon)`
  height: 40px;

  cursor: pointer;
  box-sizing: border-box;
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LoginWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const Login = styled.h3`
  width: 150px;

  margin: 0px;

  color: ${({ theme }) => theme.textColor};
  text-align: end;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights[600]};
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  > * {
    width: auto;
  }
`;
