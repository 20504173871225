import cleanDeep from "clean-deep";
import { makeAutoObservable } from "mobx";
import { UseFormSetValue } from "react-hook-form";
import { getCompare } from "src/components/BotsContent/CEX/ExpertSystem/Strategies/CreationStrategyModal/CreateStrategy/ConditionsStep/FormsArea/SoftParams/shared/hooks/useGetCompare";
import { getDataByKey, setData } from "src/helpers/forms/getByKey";
import { isEmptyObject } from "src/helpers/obj";
import { noOp } from "src/helpers/utils";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ChooseSettingParamsStore } from "./ChooseSettingParamsStore";
import {
  ChangeableCEXSettings,
  CompareConfig,
  IChangeSettingsModuleStore,
  SettingsParamsPaths,
} from "./shared/types";
import {
  generateExpressionPart,
  parseExpressionPart,
  parseSettingValueWithSchema,
} from "./shared/utils";

const EXPRESSION_SEPARATOR = " and ";

export class ConditionChangeSettingsStore implements IChangeSettingsModuleStore {
  private _chooseParamsState: ChooseSettingParamsStore;

  private _setModuleHardParam: UseFormSetValue<IStrategyCreator> = noOp;

  private _moduleIndex: number | null = null;

  private _compares: CompareConfig = {};

  private _settings: Partial<ChangeableCEXSettings> = {};

  private _expression: string = "";

  private _defaultCompareConfig: CompareConfig = {};

  private _defaultSettings: Partial<ChangeableCEXSettings> = {};

  constructor(state: ChooseSettingParamsStore, expression: string) {
    this._chooseParamsState = state;
    this._expression = expression;

    this._expressionConvertToConfigs(expression);

    makeAutoObservable(this);
  }

  get chooseParamsState() {
    return this._chooseParamsState;
  }

  get expression() {
    return this._expression;
  }

  get defaultCompareConfig() {
    return this._defaultCompareConfig;
  }

  get defaultSettings() {
    return this._defaultSettings;
  }

  private get _settingsParams() {
    return this._chooseParamsState.settingsParams;
  }

  setModuleSoftParamSetter = (cb: UseFormSetValue<IStrategyCreator>) => {
    this._setModuleHardParam = cb;
  };

  setModuleIndex = (indx: number) => {
    this._moduleIndex = indx;
  };

  setComparesConfig = (data: CompareConfig) => {
    this._compares = data;
  };

  setSettingsConfig = (data: Partial<ChangeableCEXSettings>) => {
    const isEmpty = isEmptyObject(data);

    if (!isEmpty) {
      this._settings = data;
    }
  };

  generateExpression = () => {
    this._expression = "";

    this._settingsParams.forEach((param) => {
      if (this._expression.length) this._expression += EXPRESSION_SEPARATOR;

      const valueParam = getDataByKey(this._settings, param);
      const cmpParam = getDataByKey(this._compares, param);

      const humanCmpView = getCompare(cmpParam, "value")?.label;

      if (humanCmpView) this._expression += generateExpressionPart(param, humanCmpView, valueParam);
    });

    this._setConditionSoftParam();
  };

  getAddedParamStatus = (param: SettingsParamsPaths) => this._expression.includes(param);

  removeConfigParam = (param: SettingsParamsPaths) => {
    setData(this._settings as ChangeableCEXSettings, param, undefined as any);
    setData(this._compares as Required<CompareConfig>, param, undefined as any);

    this._settings = cleanDeep(this._settings);
    this._compares = cleanDeep(this._compares);

    this.generateExpression();
  };

  private _setConditionSoftParam = () => {
    if (this._moduleIndex !== null) {
      this._setModuleHardParam(
        `conditions.${this._moduleIndex}.soft_params.value`,
        this._expression
      );
    }
  };

  private _expressionConvertToConfigs = (expression: string) => {
    if (!expression) return;

    const expressionParts = expression.split(EXPRESSION_SEPARATOR);

    const paths: SettingsParamsPaths[] = [];

    expressionParts.forEach((part) => {
      const [path, humanCmpView, value] = parseExpressionPart(part);

      const valueParam = parseSettingValueWithSchema(path, value);

      if (valueParam)
        setData(this._defaultSettings as ChangeableCEXSettings, path, valueParam as any);

      const cmpParam = getCompare(humanCmpView, "label")?.value;

      if (cmpParam) setData(this._defaultCompareConfig as Required<CompareConfig>, path, cmpParam);

      paths.push(path);
    });

    this.chooseParamsState.setSettingsParams(paths);
  };

  destroy = () => {};
}
