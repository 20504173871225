import { IChartApi, PriceFormatterFn, SeriesType } from "lightweight-charts";
import { roundSingleValue } from "src/helpers/rounding";
import { numFormatter } from "src/helpers/separation";
import { SeriesData } from "src/state/Graph/Tooltip/types";
import { isOHLCData } from "src/state/Graph/Tooltip/utils";
import { OptionalSeriesOptions } from "../types";

export const getSeriesDataValue = (point: SeriesData<SeriesType>) => {
  if (isOHLCData(point)) {
    const { open, high, low, close } = point;
    return (open + high + low + close) / 4;
  }
  return point.value;
};

export const priceFormatter: PriceFormatterFn = (price) => numFormatter(roundSingleValue(price));

export const createSeries = <T extends SeriesType>(
  type: T,
  chart: IChartApi,
  options: OptionalSeriesOptions<T>
) => {
  switch (type) {
    case "Area":
      return chart.addAreaSeries(options);
    case "Bar":
      return chart.addBarSeries(options);
    case "Candlestick":
      return chart.addCandlestickSeries(options);
    case "Histogram":
      return chart.addHistogramSeries(options);
    case "Line":
      return chart.addLineSeries(options);
    case "Baseline":
      return chart.addBaselineSeries(options);
    default: {
      throw new Error(`Unknown series type: ${type}`);
    }
  }
};
