import { observer } from "mobx-react-lite";
import {
  TableProps as BaseTableProps,
  CellPropGetter,
  Column,
  HeaderPropGetter,
  RowPropGetter,
  TableOptions,
  TablePropGetter,
  useTable,
  FooterPropGetter,
} from "react-table";
import * as styles from "./style";

export type TablePropsGetters<D extends object> = Partial<{
  tableProps: TablePropGetter<D>;
  headerProps: HeaderPropGetter<D>;
  rowProps: RowPropGetter<D>;
  cellProps: CellPropGetter<D>;
  footerProps: FooterPropGetter<D>;
}>;

export interface TableProps<D extends object>
  extends Pick<TableOptions<D>, "data" | "columns">,
    BaseTableProps {
  extraProps?: TablePropsGetters<D>;
}

export const indexColumn = <D extends object>(): Column<D> => ({
  Header: "№",
  id: "index",
  accessor: (_row, index) => index + 1,
});

export const Table = observer(
  <D extends object>({
    data,
    columns,
    extraProps: { tableProps, headerProps, rowProps, cellProps } = {},
    ...props
  }: TableProps<D>) => {
    const options: TableOptions<D> = {
      data: data.slice(),
      columns,
    };

    const { rows, prepareRow, getTableProps, getTableBodyProps, headers } = useTable(options);

    return (
      <styles.TContent {...getTableProps(tableProps)} {...props}>
        <styles.THead>
          <styles.THRow>
            {headers.map((column) => {
              const { key, ...headerProps } = column.getHeaderProps();
              return (
                <styles.THCell key={key} {...headerProps}>
                  {column.render("Header")}
                </styles.THCell>
              );
            })}
          </styles.THRow>
        </styles.THead>
        <styles.TBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            const { key, ...rowProps } = row.getRowProps();
            return (
              <styles.TRow key={key} {...rowProps}>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps();
                  return (
                    <styles.TBCell key={key} {...cellProps}>
                      {cell.render("Cell")}
                    </styles.TBCell>
                  );
                })}
              </styles.TRow>
            );
          })}
        </styles.TBody>
      </styles.TContent>
    );
  }
);
