import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";
import { Wrapper } from "./shared";

export const Container = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding-top: 33px;
  gap: 20px;
`;

export const HeaderWrapper = styled(Wrapper)`
  align-items: center;
`;

export const ContentWrapper = styled.div`
  position: relative;

  height: 0px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
  padding: 5px;

  overflow: auto;

  ${ScrollSize}
`;
