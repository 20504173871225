import { TableInstance } from "react-table";
import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";

type TableInstanceContextValue = {
  table: TableInstance<any>;
};

const TableInstanceContext = createLateInitContext<TableInstanceContextValue>();

interface TableInstanceProviderProps extends ChildrenProps {
  instance: TableInstance<any>;
}

export const TableInstanceProvider = ({ children, instance }: TableInstanceProviderProps) => {
  // create explicit object to force rerender of context consumers
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const tableInstance = { table: instance };

  return (
    <TableInstanceContext.Provider value={tableInstance}>{children}</TableInstanceContext.Provider>
  );
};

export const useTableInstanceContext = <D extends object>() =>
  useLateInitContext(TableInstanceContext).table as TableInstance<D>;
