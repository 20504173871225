import { observer } from "mobx-react-lite";
import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ConstraintsInput, Wrapper } from "../../shared";
import { WrapperForInputsGroup } from "../shared/style";

interface Props {
  showLoader: boolean;
  party: string;
}

const VOLUME_INFO = `Volume:
Modify Delta $ — amount of Quote currency you are ready to spend before Protection is On (in USD)

Modify Delta Base — amount of Base currency you are ready to spend before Protection is On

Volume $ — target volume per day (in USD)

Modify Volume $ — target volume per day after Protection is On (in USD)`;

export const VolumeSettings = observer(({ party, showLoader, ...props }: Props) => {
  const state = useLateInitContext(CEXSettingsContext);

  return (
    <SettingsPanel
      label="Volume"
      party={party}
      loader={showLoader}
      submitHandler={state.submitModulHandler("volume")}
      style={{ gridArea: "volume" }}
      info={VOLUME_INFO}
      saved={state.savedModuls.volume}
      {...props}
    >
      <Wrapper>
        <WrapperForInputsGroup>
          <ConstraintsInput
            type="number"
            label="Volume &#36;"
            value={state.data.settings.volume.tradePerDayMinUSD}
            onChange={state.getHandler("settings.volume.tradePerDayMinUSD")}
            // @ts-ignore
            getError={state.getError("settings.volume.tradePerDayMinUSD")}
            {...state.getFieldErrorAsProps("settings.volume.tradePerDayMinUSD")}
            step="any"
          />
          <LabeledInput
            type="number"
            label="Modify Delta &#36;"
            value={state.data.settings.volume.modifyDeltaBalanceQuoteUSD}
            onChange={state.getHandler("settings.volume.modifyDeltaBalanceQuoteUSD")}
            // @ts-ignore
            getError={state.getError("settings.volume.modifyDeltaBalanceQuoteUSD")}
            errorHint={state.errors.settings?.volume?.modifyDeltaBalanceQuoteUSD}
            step="any"
          />
        </WrapperForInputsGroup>
        <WrapperForInputsGroup>
          <LabeledInput
            type="number"
            label="Modify Volume &#36;"
            value={state.data.settings?.volume?.modifyTradePerDayMinUSD}
            onChange={state.getHandler("settings.volume.modifyTradePerDayMinUSD")}
            // @ts-ignore
            getError={state.getError("settings.volume.modifyTradePerDayMinUSD")}
            errorHint={state.errors.settings?.volume?.modifyTradePerDayMinUSD}
            step="any"
          />
          <LabeledInput
            type="number"
            label="Modify Delta Base"
            value={state.data.settings.volume.modifyDeltaBalanceBase}
            onChange={state.getHandler("settings.volume.modifyDeltaBalanceBase")}
            // @ts-ignore
            getError={state.getError("settings.volume.modifyDeltaBalanceBase")}
            errorHint={state.errors.settings?.volume?.modifyDeltaBalanceBase}
            step="any"
          />
        </WrapperForInputsGroup>
      </Wrapper>
    </SettingsPanel>
  );
});
