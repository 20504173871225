import { ComponentPropsWithoutRef } from "react";
import { Loader } from "src/components/shared/Loader";
import { Caption, CaptionProps } from "./Caption";
import { Header } from "./Header";
import * as styles from "./style";

export interface KPICardProps
  extends Omit<ComponentPropsWithoutRef<"div">, "title">,
    Pick<CaptionProps, "subtitle" | "caption"> {
  title: string;
  titleIcon?: JSX.Element;
  info?: string;
  kpi: string | JSX.Element;
  subtitleInfo?: string;
  loading?: boolean;
}

export const KPICard = ({
  title,
  titleIcon,
  info,
  subtitle,
  subtitleInfo,
  caption,
  kpi,
  loading = false,
  ...props
}: KPICardProps) => {
  const Kpi = typeof kpi === "string" ? <styles.KPIText>{kpi}</styles.KPIText> : kpi;

  const titleProps = {
    title,
    icon: titleIcon,
    info,
  };

  const captionProps = {
    subtitle,
    info: subtitleInfo,
    caption,
  };

  return (
    <styles.CardContainer {...props}>
      <Header {...titleProps} />
      {Kpi}
      <Caption {...captionProps} />
      <Loader show={loading} />
    </styles.CardContainer>
  );
};
