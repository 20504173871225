import { makeAutoObservable } from "mobx";
import { isEthAddress } from "src/validation-schemas";
import ArrayInputStore, {
  ArrayInputFormValidators,
  createArrayInputValidator,
  IArrayInputState,
} from "./ArrayInputStore";
import { WalletsInputFormAdapter } from "./WalletsInputFormAdapter";

export interface IWalletsProvider {
  get wallets(): string[];
  setWallets: (wallets: string[]) => void;
}

export default class WalletInputStore {
  private _adapter: WalletsInputFormAdapter;

  private _wallet: IArrayInputState<string>;

  constructor(_provider: IWalletsProvider, walletName: string = "wallet") {
    makeAutoObservable(this);

    this._adapter = new WalletsInputFormAdapter(_provider);

    const walletValidators: ArrayInputFormValidators<string> = [
      isEthAddress(),
      createArrayInputValidator<string>((wallet, wallets) => {
        const isDuplicate = wallets.includes(wallet);
        if (isDuplicate) {
          return `Duplicate ${walletName}!`;
        }
        return "";
      }),
    ];

    this._wallet = new ArrayInputStore(this._adapter, walletValidators);
  }

  get wallet() {
    return this._wallet;
  }
}
