import { Abilities } from "src/modules/abilities";

export const checkParty = (abilities: Abilities, abilityName: string, party: string): boolean => {
  if (!abilities) return false;

  const checkParty = abilities[abilityName]?.find((el) => el === party);

  // condition for root
  if (abilities.root) return true;

  if (checkParty) return true;

  return false;
};

export const checkAbility = (abilities: Abilities, abilityName: string) => {
  if (abilities[abilityName]) return true;

  return false;
};
