import { PanelHeaderProps } from "src/components/shared/PanelHeader";
import { Headline2 } from "src/components/shared/Typography";
import * as styles from "./style";

export interface ApiKeysPanelHeaderProps extends Omit<PanelHeaderProps, "label" | "titleSlot"> {}
export interface ApiKeysPanelProps extends React.ComponentPropsWithoutRef<"div"> {
  title: string;
  selector?: JSX.Element;
  buttons?: JSX.Element;
  headerProps?: ApiKeysPanelHeaderProps;
}

export const ApiKeysPanel = ({
  title,
  selector,
  buttons,
  headerProps,
  ...props
}: ApiKeysPanelProps) => (
  <styles.ApiKeysContainer {...props}>
    <styles.Header label={title} titleSlot={Headline2} {...headerProps} />
    <styles.Content>
      {selector}
      {buttons}
    </styles.Content>
  </styles.ApiKeysContainer>
);
