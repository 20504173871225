import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import React from "react";
import { stringToSelectorValue } from "src/helpers/forms/selectors";
import { FormErrors, FormValidation } from "src/helpers/forms/types";
import { IDisposable } from "src/helpers/utils";
import { AccountApi, BotAccountName, LiquidityAccountName } from "src/modules/accounts";
import { SelectorValue } from "src/modules/shared";
import { required, validateData } from "src/validation-schemas";
import { IAccountsBindingsProvider, accountToSelectorValue } from "./AccountsBindings";
import { AddAccountCallback, IAddVolumeBindingParams, SetLoading } from "./AddVolumeBinding";

export interface AddLiquidityBindingForm {
  name: BotAccountName<"liquidity"> | "";
  account: AccountApi;
}

export const INITIAL_ACCOUNT: AccountApi = {
  name: "",
  uuid: "",
};

const INITIAL_ADD_LIQUIDITY_BINDING: AddLiquidityBindingForm = {
  name: "",
  account: INITIAL_ACCOUNT,
};

export type FormSelectors = "botAccountName" | "accountName";

export interface IAddLiquidityBindingParams extends Omit<IAddVolumeBindingParams, "onAddAccount"> {
  onAddAccount: AddAccountCallback<"liquidity">;
}

export default class AddLiquidityBinding implements IDisposable {
  private _data: AddLiquidityBindingForm = INITIAL_ADD_LIQUIDITY_BINDING;

  private _errors: FormErrors<AddLiquidityBindingForm> = {};

  private _validation: FormValidation<AddLiquidityBindingForm> = {
    name: [required()],
    "account.name": [required()],
    "account.uuid": [required()],
  };

  private _bindingsProvider: IAccountsBindingsProvider;

  private _setLoading?: SetLoading;

  private _onSuccess?: () => void;

  private _onAddAccount: AddAccountCallback<"liquidity">;

  constructor({
    bindingsProvider,
    setLoading,
    onSuccess,
    onAddAccount,
  }: IAddLiquidityBindingParams) {
    makeAutoObservable(this);

    this._bindingsProvider = bindingsProvider;
    this._setLoading = setLoading;
    this._onSuccess = onSuccess;
    this._onAddAccount = onAddAccount;
  }

  get data() {
    return this._data;
  }

  get errors() {
    return this._errors;
  }

  private get _exchangeAccounts() {
    return this._bindingsProvider.exchangeAccounts;
  }

  private _updateSelectedAccount = (account: AccountApi) => {
    this._data.account = account;
  };

  private _setBotAccountName = (name: LiquidityAccountName | "") => {
    this._data.name = name;
  };

  private get _selectedAccount(): SelectorValue | null {
    const { account } = this._data;
    if (!account.name) return null;

    return accountToSelectorValue(account);
  }

  private get _accountSelectorOptions(): SelectorValue[] {
    const botAccountName = this._data.name;
    if (!botAccountName) {
      return [];
    }
    return this._bindingsProvider.accountBindingSelectorOptions(botAccountName);
  }

  private _onAccountSelected = (value: SelectorValue | null) => {
    if (!value) {
      this._updateSelectedAccount(INITIAL_ACCOUNT);
      return;
    }

    const accountId = String(value.id);

    const selectedAccount = this._exchangeAccounts.find(({ uuid }) => uuid === accountId);

    if (!selectedAccount) return;

    this._updateSelectedAccount(selectedAccount);
  };

  private get _selectedBotAccount(): SelectorValue | null {
    const accountName = this._data.name;

    if (!accountName) return null;

    return stringToSelectorValue(accountName);
  }

  private get _botAccountSelectorOptions(): SelectorValue[] {
    return this._bindingsProvider.liquidityNameSelectorOptions;
  }

  private _onBotAccountSelected = (value: SelectorValue | null) => {
    if (!value) {
      this._setBotAccountName("");
      return;
    }

    const name = String(value.value) as LiquidityAccountName;

    this._setBotAccountName(name);
  };

  selectorHandler = (key: FormSelectors) => (value: SelectorValue | null) => {
    switch (key) {
      case "accountName": {
        this._onAccountSelected(value);
        return;
      }
      case "botAccountName": {
        this._onBotAccountSelected(value);
      }
    }
  };

  selectorOptions = computedFn((key: FormSelectors) => {
    switch (key) {
      case "accountName": {
        return this._accountSelectorOptions;
      }
      case "botAccountName": {
        return this._botAccountSelectorOptions;
      }
    }
  });

  selectorValue = (key: FormSelectors) => {
    switch (key) {
      case "accountName": {
        return this._selectedAccount;
      }
      case "botAccountName": {
        return this._selectedBotAccount;
      }
    }
  };

  private _validate = () => validateData(this._validation, this._data, this._errors, undefined);

  private _addAccount = async (botAccountName: LiquidityAccountName, accountToBind: AccountApi) => {
    try {
      this._setLoading?.(true);

      const isError = await this._onAddAccount(botAccountName, accountToBind);

      if (!isError) {
        this._onSuccess?.();
      }
    } finally {
      this._setLoading?.(false);
    }
  };

  addAccount = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const valid = this._validate();

    if (!valid) return;

    const accountToBind = this._data.account;
    const accountName = this._data.name as LiquidityAccountName;

    this._addAccount(accountName, accountToBind);
  };

  destroy = () => {};
}
