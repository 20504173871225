import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px;
  justify-content: flex-end;
`;

export const SeriesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

interface SeriesTitleProps {
  $visible?: boolean;
}

export const BaseSeriesTitle = styled.span<SeriesTitleProps>`
  text-decoration: ${({ $visible }: SeriesTitleProps) => ($visible ? "none" : "line-through")};
`;

export const SeriesTitle = styled(BaseSeriesTitle)`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.textColor};
`;

interface SeriesColorBoxProps {
  $color?: string;
}

export const BaseSeriesColorBox = styled.span<SeriesColorBoxProps>`
  background-color: ${({ $color, theme }) => $color || theme.primaryColor};
`;

export const SeriesColorBox = styled(BaseSeriesColorBox)`
  width: 10px;
  height: 10px;
`;
