import { ComponentPropsWithoutRef, ComponentType, useMemo } from "react";
import { ITooltip as TooltipProps } from "react-tooltip";
import { PromptMsg } from "src/components/shared/shared";
import { useId } from "src/hooks/useId";
import Icons from "src/icons/Icons";
import * as styles from "./style";

const INFO_PROMPT_ID = "info_prompt";

const usePromptId = () => {
  const id = useId(4);
  const promptId = useMemo(() => `${INFO_PROMPT_ID}-${id}`, [id]);
  return promptId;
};

export interface InfoPromptProps
  extends Pick<ComponentPropsWithoutRef<"span">, "style" | "className">,
    Omit<TooltipProps, "id"> {
  message?: string;
  prompt?: ComponentType<TooltipProps>;
}

export const InfoPrompt = ({ message, style, className, prompt, ...props }: InfoPromptProps) => {
  const id = usePromptId();

  const containerProps = {
    style,
    className,
  };

  const Prompt = prompt || PromptMsg;

  return (
    <styles.InfoWrapper data-tooltip-content={message} data-tooltip-id={id} {...containerProps}>
      <Icons.Prompt />
      <Prompt id={id} {...props} />
    </styles.InfoWrapper>
  );
};
