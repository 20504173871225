import { makeAutoObservable } from "mobx";
import { toast } from "src/components/shared/Toaster";
import { logError } from "src/helpers/network/logger";
import { Note } from "src/modules/shared";

export class NotesStore {
  private _botUUID = "";

  private _message = "";

  private _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get reqParam() {
    return this._botUUID;
  }

  get message() {
    return this._message;
  }

  get isLoading() {
    return this._isLoading;
  }

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  private _setLoader = (bool: boolean) => {
    this._isLoading = bool;
  };

  private _setMessage = (value: string) => {
    this._message = value;
  };

  private _clearMessage = () => {
    this._message = "";
  };

  getValueNote = async (getNote: (reqParam: string) => Promise<any>) => {
    this._clearMessage();
    this._setLoader(true);

    try {
      const {
        isError,
        data: { note },
      } = await getNote(this.reqParam);

      if (!isError) this._setMessage(note);
    } catch (error) {
      logError(error);
    } finally {
      this._setLoader(false);
    }
  };

  textAreaHandler = () => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this._setMessage(e.target.value);
  };

  submitHandler =
    (setNote: (reqParam: string, data: Note) => Promise<any>) => async (e: React.FormEvent) => {
      e.preventDefault();
      this._setLoader(true);

      try {
        const { isError } = await setNote(this.reqParam, {
          note: this._message,
        });

        if (!isError) toast.success("Note saved");
      } catch (err) {
        logError(err);
      } finally {
        this._setLoader(false);
      }
    };

  destroy = () => {};
}
