import { Observer, observer } from "mobx-react-lite";
import { Controller, useFormContext } from "react-hook-form";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { DynamicSelectionContext } from "src/context/CEX/ExpertSystem";
import { Extends } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategyModuleCreateType, StrategyModuleTypes } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import { getListFromSelectorOptions } from "../utils";

type ModulesWithAccounts = Extends<
  StrategyModuleTypes,
  "balance_abs" | "balance_change" | "balance_delta" | "price_account_pnl"
>;

export const AccountField = observer(() => {
  const selectorsState = useLateInitContext(DynamicSelectionContext);
  const { accListState } = selectorsState;

  const { control } = useFormContext<IStrategyModuleCreateType<ModulesWithAccounts>>();

  return (
    <Controller
      control={control}
      name="hard_params.accounts"
      rules={{ required: ERRORS_MSGS.isRequired }}
      render={({ field: { onChange }, formState: { errors } }) => {
        const error = getFieldError(errors, "hard_params.accounts");
        return (
          <Observer>
            {() => (
              <LabeledSelector
                label="Account"
                menuPosition="fixed"
                isMulti
                options={accListState.fullAccOptions}
                onChange={(data) => {
                  onChange(getListFromSelectorOptions(data));
                }}
                errorHint={error?.message}
              />
            )}
          </Observer>
        );
      }}
    />
  );
});
