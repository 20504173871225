import { observer } from "mobx-react-lite";
import React from "react";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { getTheme } from "src/helpers/getStatus/getBotStatusColor";
import * as styles from "./style";

export const VolumeInfoPanel = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);

  const state = mainState.volumeInfoState;

  const theme = getTheme();

  return (
    <styles.Container>
      <styles.VolumeCell
        data={{
          label: "24h change",
          value: `${state.changePrice} ${state.changePricePercent}%`,
          cellProps: {
            value: {
              style: {
                color: +state.changePrice > 0 ? theme.successColor : theme.dangerColor,
              },
            },
          },
        }}
      />
      <styles.VolumeCell data={{ label: "24h High", value: state.hight }} />
      <styles.VolumeCell data={{ label: "24h Low", value: state.low }} />
      <styles.VolumeCell data={{ label: `24h Volume(${state.quote})`, value: state.quoteVolume }} />
      <styles.VolumeCell data={{ label: `24h Volume(${state.base})`, value: state.baseVolume }} />
    </styles.Container>
  );
});
