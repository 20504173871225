import { observer } from "mobx-react-lite";
import { TransferListContext } from "src/context/CEX/FundingProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useSubscribableStore } from "src/hooks/useSubscribableStore";
import { HeaderInfo } from "../../shared";
import { AddTransfersPanel } from "./AddTransfersPanel";
import { TransfersTable } from "./TransfersTable";
import * as styles from "./style";

export const TransferList = observer(() => {
  const state = useLateInitContext(TransferListContext);

  useSubscribableStore(state);

  return (
    <styles.Container>
      <AddTransfersPanel />
      <styles.TransfersContainer title="Information from exchange">
        {state.exchApiSupport ? (
          <TransfersTable />
        ) : (
          <HeaderInfo>
            {`${state.transferConfig.exchange} does not provide information about D&W via API`}
          </HeaderInfo>
        )}
      </styles.TransfersContainer>
    </styles.Container>
  );
});
