import { WrapperForRadio } from "src/components/shared/Switches/Switch/style";
import styled from "styled-components";

export const Container = styled.div`
  height: 32px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;

  padding: 0px 8px;

  border-bottom: ${({ theme }) => `1px solid ${theme.inputBorderColor}`};

  :last-of-type {
    border-bottom: none;
  }

  ${WrapperForRadio} {
    flex-direction: row-reverse;
    gap: 8px;
  }
`;
