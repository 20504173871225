import styled from "styled-components";
import { PNLV2Value } from "../Item/shared/PNLV2Value";

export const Container = styled.div`
  display: flex;
  gap: 4px;
`;

export const StyledFee = styled(PNLV2Value)`
  color: ${({ theme }) => theme.dashboard.textSubhead};
`;

export const FeeLabel = styled.span`
  font-size: 0.8em;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.dashboard.textAdditional};
`;
