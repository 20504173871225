import React from "react";
import { ThousandthSeparator } from "src/components/shared/ThousandthSeparator";
import { observer } from "mobx-react-lite";
import { IFillingOrderBook } from "src/state/CEX/CEXExchange/OrderBook/OrderBookStore";
import * as styles from "../shared";

export interface StatsTooltipProps {
  type: string;
  filling: IFillingOrderBook;
  quote: string;
  base: string;
}

export const StatsTooltip = observer(
  ({ type, filling: { totalBase, totalQuote, avg }, quote, base, ...props }: StatsTooltipProps) => (
    <styles.Content {...props}>
      <styles.Row>
        <styles.Key>
          {type} {base}
        </styles.Key>

        <styles.Value>
          <ThousandthSeparator value={totalBase} />
        </styles.Value>
      </styles.Row>

      <styles.Row>
        <styles.Key>
          {type} {quote}
        </styles.Key>

        <styles.Value>{totalQuote}</styles.Value>
      </styles.Row>

      <styles.Row>
        <styles.Key>Avg price</styles.Key>

        <styles.Value>{avg}</styles.Value>
      </styles.Row>
    </styles.Content>
  )
);
