import styled from "styled-components";
import { FormContent, FormRow } from "../../shared";

export const StyledFormContent = styled(FormContent)`
  max-width: 100%;
  width: 100%;
`;

export const StrategiesHeaderRow = styled(FormRow)`
  justify-content: space-between;
`;
