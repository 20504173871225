import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { useMemo } from "react";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "../style";

export const FeesRow = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);
  const id = useMemo(() => nanoid(5), []);

  return (
    <styles.WrapperFees>
      <styles.FeeTitle data-tooltip-id={id}>Fees Ratios</styles.FeeTitle>

      <styles.HeadTooltip place="left" id={id} variant="info">
        {mainState.buySellState.feesSupported ? (
          <>
            <styles.FeeLabel>
              Maker: <styles.FeeValue>{mainState.buySellState.makerFee}</styles.FeeValue>
            </styles.FeeLabel>
            <styles.FeeLabel>
              Taker: <styles.FeeValue>{mainState.buySellState.takerFee}</styles.FeeValue>
            </styles.FeeLabel>
            {mainState.buySellState.native ? (
              <styles.FeeLabel>
                Native fee: <styles.FeeValue>{mainState.buySellState.native}</styles.FeeValue>
              </styles.FeeLabel>
            ) : (
              ""
            )}
          </>
        ) : (
          mainState.buySellState.feesTooltipMsg
        )}
      </styles.HeadTooltip>
    </styles.WrapperFees>
  );
});
