import styled from "styled-components";

interface BalancesContainerProps {
  isEmpty?: boolean;
}

export const BalancesContainer = styled.div<BalancesContainerProps>`
  display: grid;
  grid-template-columns: repeat(2, minmax(40px, 1fr));
  row-gap: 2px;
`;

export const Row = styled.div`
  display: contents;
`;

export const Type = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.dashboard.textSecondary};
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
`;

export const Balance = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.dashboard.textDefault};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;
