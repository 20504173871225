import { HTMLInputTypeAttribute } from "react";
import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { LabeledInputProps } from "src/components/shared/Forms/Inputs";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getFieldError } from "../../../utils/errors";

export interface ControllerWrapperProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  label?: string;
  type?: HTMLInputTypeAttribute;
  control: Control<TForm>;
  name: TName;
  rules?: Omit<
    RegisterOptions<TForm, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  ignoreType?: boolean;
  component: React.ComponentType<LabeledInputProps>;
}

export const ControllerWrapper = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  label,
  type = "string",
  name,
  component: InputComponent,
  ignoreType = false,
  ...props
}: ControllerWrapperProps<TForm, TName>) => (
  <Controller
    name={name}
    {...props}
    render={({ field: { value, onChange }, formState: { errors } }) => {
      const error = getFieldError(errors, name);

      return (
        <InputComponent
          label={label}
          type={type}
          value={value}
          onChange={(e) => onChange(getChangeEventValue(e, ignoreType))}
          errorHint={error?.message}
        />
      );
    }}
  />
);
