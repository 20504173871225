import { makeAutoObservable } from "mobx";
import { IDisposable } from "src/helpers/utils";
import { DEXV2ExchangeVersion } from "src/modules/bots";
import { IBotChainInfoProvider } from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { V2RouterProvider } from "../../../v2/Providers/V2RouterProvider";
import { V3RouterProvider } from "../../../v3/Providers/V3RouterProvider";
import { IRouter } from "../../Swap/Router";
import { INonNullableVersionProvider, VersionedSwapState } from "../../VersionedSwapState";
import { ISwapSettingsProvider } from "../SwapSettingsProvider";
import { EmptySwapRouterProvider } from "./EmptySwapRouterProvider";

export interface IRouterProviderParams {
  swapSettingsProvider: ISwapSettingsProvider;
  chainInfoProvider: IBotChainInfoProvider;
  versionProvider: INonNullableVersionProvider;
}

export interface IRouterProvider {
  get router(): IRouter | null;
}

export class RouterProvider implements IRouterProvider, IDisposable {
  private _state;

  constructor({ versionProvider, ...params }: IRouterProviderParams) {
    makeAutoObservable(this);

    this._state = new VersionedSwapState({
      versionProvider,
      getVersionedState: this._getRouter,
      ...params,
    });
  }

  private _getRouter = (
    version: DEXV2ExchangeVersion,
    { swapSettingsProvider, chainInfoProvider }: Omit<IRouterProviderParams, "versionProvider">
  ): IRouterProvider => {
    switch (version) {
      case DEXV2ExchangeVersion.V2: {
        return new V2RouterProvider({
          factoryAddressProvider: swapSettingsProvider,
          chainProvider: chainInfoProvider.chainProvider,
        });
      }
      case DEXV2ExchangeVersion.V3: {
        return new V3RouterProvider({
          chainProvider: chainInfoProvider.chainProvider,
          dexNameProvider: swapSettingsProvider,
        });
      }
      default: {
        return new EmptySwapRouterProvider();
      }
    }
  };

  get router() {
    return this._state.state.router;
  }

  destroy = () => {
    this._state.destroy();
  };
}
