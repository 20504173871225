import styled from "styled-components";

export const SettingsContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  border-right: ${({ theme }) => theme.borders.solidGray};
  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
    padding-right: 0px;
    border-right: none;
  }
`;
