import { memo, useCallback } from "react";
import { unixToISOString } from "src/helpers/dateUtils";
import { SelectableRecentParty } from "src/state/CEX/CEXBots/RecentPartiesFilterStore";
import * as styles from "./style";

interface ClientProps extends SelectableRecentParty {
  toggleSelection(name: string): void;
}

export const Party = memo(
  ({ name, createdAt, daysPassed, selected, toggleSelection }: ClientProps) => {
    const togglePartySelection = useCallback(() => {
      toggleSelection(name);
    }, [name, toggleSelection]);

    return (
      <styles.ListItem selected={selected} onClick={togglePartySelection}>
        <styles.Name>{name}</styles.Name>
        <styles.CreatedAt dateTime={unixToISOString(createdAt)} selected={selected}>
          {daysPassed}
        </styles.CreatedAt>
      </styles.ListItem>
    );
  }
);
