import { rgba } from "polished";
import styled, { css } from "styled-components";
import { ChipVariant } from ".";

interface ChipContainerProps extends React.ComponentPropsWithoutRef<"div"> {
  variant: ChipVariant;
}

const BorderMixin = ({ variant }: ChipContainerProps) => {
  const borderColor = variant === "filled" ? "transparent" : "currentColor";

  return css`
    border: 1px solid ${borderColor};
    border-radius: ${({ theme }) => theme.radii["2px"]};
  `;
};

export const BackgroundColorMixin = ({ variant, color }: ChipContainerProps) => css`
  ${({ theme }) => {
    const defaultColor = color ?? theme.primaryColor;

    const backgroundColor = variant === "filled" ? "currentColor" : rgba(defaultColor, 0.2);

    return css`
      background-color: ${backgroundColor};
    `;
  }}
`;

const TextColorMixin = ({ variant, color }: ChipContainerProps) => css`
  ${({ theme }) => {
    const textColor = color ?? theme.primaryColor;

    const variantText = variant === "filled" ? theme.textColor : textColor;

    return css`
      color: ${variantText};
    `;
  }}
`;

export const ChipContainer = styled.div<ChipContainerProps>`
  height: 18px;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  padding: 3px 5px;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.primaryColor};
  background: ${({ theme }) => rgba(theme.primaryColor, 0.1)};

  ${BorderMixin}
  ${BackgroundColorMixin}
  ${TextColorMixin}
`;

export const RemoveBtn = styled.button`
  width: 15px;
  height: 15px;
  position: relative;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radii["2px"]};

  ::before,
  ::after {
    position: absolute;
    content: "";
    width: 1.3px;
    height: 10px;
    background: ${({ theme }) => theme.primaryColor};
    left: 7px;
    top: 2.5px;
    -ms-transform: rotate(140deg);
    -webkit-transform: rotate(140deg);
    transform: rotate(140deg);
    transform: rotate(47deg);
  }

  ::after {
    right: 9px;
    -ms-transform: rotate(230deg);
    -webkit-transform: rotate(230deg);
    transform: rotate(230deg);
    transform: rotate(-47deg);
  }

  :hover {
    background: inherit;
  }
`;
