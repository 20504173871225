import styled from "styled-components";
import { Card } from "src/components/BotsContent/CEX/shared/Card";
import { MainContentWrapper } from "src/components/BotsContent/CEX/shared/Card/style";

export const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 8px;
`;

export const PiesContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 8px;

  display: grid;
  grid-template-columns: repeat(2, calc(50% - 8px));
  grid-template-rows: repeat(2, calc(50% - 8px));
  gap: 16px;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ChartWrapper = styled.div`
  width: 90%;
  height: 80%;
`;

export const ChartTitle = styled.h3`
  padding: 0;
  margin: 0;
  max-width: 25ch;

  color: ${({ theme }) => theme.dashboard.textSubhead};
  font-size: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  letter-spacing: 0.2px;
`;

export const ExchangesCard = styled(Card)`
  ${MainContentWrapper} {
    width: calc(100% - 140px);
  }
`;
