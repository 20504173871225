import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { SubmitSplitButtonOwnProps, SubmitSplitButtonProps } from "../../shared/SubmitSplitButton";
import * as styles from "./style";

export interface SubmitAllButtonProps
  extends Omit<SubmitSplitButtonProps, keyof SubmitSplitButtonOwnProps> {}

export const SubmitAllButton = observer((props: SubmitAllButtonProps) => {
  const { hasAbility } = useLateInitContext(AbilitiesContext);
  const enableSave = hasAbility(DEXV2Ability.SettingsEdit);

  const state = useLateInitContext(DEXV2SettingsContext);
  const menuItems = useMemo(
    () => ({
      items: [{ label: "Force submit", onClick: state.submitForceHandler }],
    }),
    [state.submitForceHandler]
  );
  return (
    <>
      {enableSave && (
        <styles.Submit
          submitProps={{ children: "Submit all", onClick: state.submitHandler }}
          menuProps={menuItems}
          {...props}
        />
      )}
    </>
  );
});
