import { addAPI } from "src/api/userManager/accountsAPI";
import { NewAccount } from "src/modules/userManager";
import { required } from "src/validation-schemas";
import { AccountParams } from ".";

export class APIStore implements AccountParams {
  party = "";

  message = "API created successfully";

  uuid = "";

  validation = {
    "credentials.data.KEY": required(),
    "credentials.data.SECRET": required(),
    "credentials.data.ACCOUNT_ID": required(),
    "credentials.name": required(),
  };

  constructor(currentParty: string, currentAcc: string) {
    this.party = currentParty;
    this.uuid = currentAcc;
  }

  addEntity = (acc: NewAccount) => addAPI(this.uuid, acc.credentials);
}
