import { Controller, useForm } from "react-hook-form";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { StrategyListContext } from "src/context/CEX/ExpertSystem";
import { PopupContext } from "src/context/shared/PopupContext";
import { getUTCDateTimeInputValue } from "src/helpers/dateUtils";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ExpiredStrategyParam } from "src/state/expertSystem/strategies";
import { ERRORS_MSGS, hookFormIsFutureDate } from "src/validation-schemas";
import { DateTimeInputPrompt } from "../../../shared";
import * as styles from "./style";

export interface ExtendFormProps {
  uuid: string;
}

export const ExtendForm = ({ uuid }: ExtendFormProps) => {
  const state = useLateInitContext(StrategyListContext);
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<ExpiredStrategyParam>({
    defaultValues: { expired_at: "" },
  });

  const { handleSubmit, control } = methods;

  return (
    <styles.FormContainer onSubmit={handleSubmit(state.extendStrategyHandler(uuid, close))}>
      <Controller
        control={control}
        name="expired_at"
        rules={{
          required: ERRORS_MSGS.isRequired,
          validate: hookFormIsFutureDate(),
        }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <LabeledInput
            label="Time"
            after={<DateTimeInputPrompt />}
            type="datetime-local"
            placeholder="YYYY-MM-DDThh:mm"
            value={getUTCDateTimeInputValue(value)}
            onChange={(e) => onChange(getChangeEventValue(e))}
            errorHint={errors.expired_at?.message}
          />
        )}
      />

      <SubmitButton size="l" />
    </styles.FormContainer>
  );
};
