import { observer } from "mobx-react-lite";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { BindingsBotContext } from "src/context/CEX/BindingsBotProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { WrapperForModule } from "../style";

export const SwapModule = observer(() => {
  const state = useLateInitContext(BindingsBotContext);

  return (
    <WrapperForModule>
      <LabeledSelector
        labelAfter={
          <CheckBox label="Swap" checked={state.checkSwap} onChange={state.toggleSwapBinding} />
        }
        value={state.currentBindings.swapBindingUUID ? state.swapSelected : null}
        isClearable={!state.checkSwap}
        options={state.swapModules}
        onChange={state.selectorHandler("swapBindingUUID")}
      />
    </WrapperForModule>
  );
});
