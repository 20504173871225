import styled from "styled-components";
import { Card } from "src/components/BotsContent/CEX/shared/Card";
import { MainContentWrapper } from "src/components/BotsContent/CEX/shared/Card/style";
import { ChartCardPaddingFix } from "../../../shared/style";

export const BalancesCard = styled(Card)`
  ${MainContentWrapper} {
    width: calc(100% - 200px);
  }

  ${ChartCardPaddingFix}
`;
