import { observer } from "mobx-react-lite";
import { ToggleSwitch, ToggleSwitchProps } from "src/components/shared/ToggleSwitch";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";

export interface SettingsToggleSwitchProps extends Omit<ToggleSwitchProps, "disabled"> {}

export const SettingsToggleSwitch = observer((props: SettingsToggleSwitchProps) => {
  const { hasAbility } = useLateInitContext(AbilitiesContext);
  const enabled = hasAbility(DEXV2Ability.SettingsEdit);

  return <ToggleSwitch disabled={!enabled} {...props} />;
});
