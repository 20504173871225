import { observer } from "mobx-react-lite";
import StatsItem, { StatsItemData, StatsItemProps } from "./StatsItem";
import * as styles from "./style";

export type StatsItemDataProps = StatsItemData & Pick<StatsItemProps, "itemProps">;
export interface StatsListProps extends React.ComponentPropsWithoutRef<"div"> {
  items: StatsItemDataProps[];
}

const StatsList = observer(({ items, ...props }: StatsListProps) => (
  <styles.Container {...props}>
    {items.map(({ itemProps, ...itemData }) => (
      <StatsItem key={itemData.name} item={itemData} itemProps={itemProps} />
    ))}
  </styles.Container>
));

export default StatsList;
