import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import useMediaQuery from "src/hooks/useMediaQuery";
import { DashboardView } from "src/state/CEX/CEXDashboard/v1";
import { DashboardV2View } from "src/state/CEX/CEXDashboard/v2";
import { BotInfoProps } from "..";
import { MobileHeader } from "./Header/MobileHeader";
import { getDashboardVersion } from "./shared/config/versions";
import { useDashboardState } from "./shared/hooks/useDashboardState";
import { DashboardBreakpoints } from "./shared/style";
import * as styles from "./style";
import { BalanceSummaryView } from "./views/v1/BalanceSummaryView";
import { DetailedView } from "./views/v1/DetailedView";
import { SummaryView } from "./views/v1/SummaryView";
import { BalanceSummaryView as BalanceSummaryV2View } from "./views/v2/BalanceSummaryView";
import { MarketInfoView } from "./views/v2/MarketInfoView";
import { PNLView } from "./views/v2/PNLView";

const getViewContent = (view: DashboardView | DashboardV2View) => {
  const version = getDashboardVersion();

  if (version === "v1") {
    switch (view as DashboardView) {
      case "detailed": {
        return <DetailedView />;
      }
      case "summary": {
        return <SummaryView />;
      }
      case "balance-summary": {
        return <BalanceSummaryView />;
      }
    }
  } else {
    switch (view as DashboardV2View) {
      case "balance-summary": {
        return <BalanceSummaryV2View />;
      }
      case "market-info": {
        return <MarketInfoView />;
      }
      case "pnl": {
        return <PNLView />;
      }
    }
  }
};

export interface DashboardProps extends BotInfoProps {}

export const Dashboard = observer(({ botInfo: { bot_uuid, party } }: DashboardProps) => {
  const state = useDashboardState();

  const isMobile = useMediaQuery(getMobileMediaQuery(DashboardBreakpoints.XMobile));

  const Header = isMobile ? <MobileHeader /> : <styles.DashboardHeader />;

  useEffect(() => {
    if (!party || !bot_uuid) return;
    state.setBotParams({
      uuid: bot_uuid,
      party,
    });
    state.getInitialData();
  }, [party, bot_uuid, state]);

  const viewContent = getViewContent(state.currentView);

  const content = !state.initialLoading && (
    <>
      {Header}
      <styles.Content>{viewContent}</styles.Content>
    </>
  );

  return (
    <styles.Container>
      {content}
      <Loader show={state.initialLoading} />
    </styles.Container>
  );
});
