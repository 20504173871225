import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { UserGroupsContext } from "src/context/UserManager/UserGroups";
import { stringToSelectorValue } from "src/helpers/forms/selectors";
import { noOp } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import { SelectorValue } from "src/modules/shared";
import * as styles from "./styles";

export interface UserGroupLinkProps {
  userName: string;
}

export const UserGroupLink = observer(({ userName }: UserGroupLinkProps) => {
  const {
    userGroupsState: { editUserGroupState: state },
  } = useLateInitContext(UserGroupsContext);

  const [userGroupName, setCurrentUserGroup] = useState(state.editUserGroup.name);

  const onUserGroupNameChanged = useCallback((data: SelectorValue | null) => {
    if (!data) return;
    setCurrentUserGroup(String(data.value));
  }, []);

  const userType = state.getUserTypeByName(userName);

  const moveGroupEnabled = userGroupName !== state.editUserGroup.name;

  return (
    <styles.Container>
      <styles.RowWrapper>
        <LabeledInput label="User name" readOnly value={userName} onChange={noOp} />

        <styles.GroupWrapper>
          <LabeledSelector
            label="User group"
            options={state.groupsSelectorOptions(userType)}
            value={stringToSelectorValue(userGroupName) as SelectorValue}
            onChange={onUserGroupNameChanged}
            menuPosition="fixed"
          />
          <IconButton
            onClick={() => {
              state.moveUserToUserGroup(userName, userGroupName);
            }}
            disabled={!moveGroupEnabled}
          >
            {Icons.moveBot()}
          </IconButton>
        </styles.GroupWrapper>
      </styles.RowWrapper>
    </styles.Container>
  );
});
