import { useState } from "react";
import { ExpandButton } from "src/components/UserManagement/Access/Scopes/ScopesTree/ExpandButton";
import { ItemBody, ItemContainer, ItemHeaderWrapper } from "src/components/shared/nestedList";
import { IStrategyModuleInfo } from "src/modules/expertSystem";
import { ModuleTablet, ModuleTabletProps } from "./ModuleTablet";
import * as styles from "./style";

export interface ModuleTypeListProps extends Pick<ModuleTabletProps, "appendModule"> {
  title: string;
  modules: IStrategyModuleInfo[];
  cbCounter: (uuid: string) => number;
}

export const ModuleTypeList = ({
  appendModule,
  title,
  modules,
  cbCounter,
}: ModuleTypeListProps) => {
  const [hide, setHide] = useState(false);

  return (
    <ItemContainer>
      <ItemHeaderWrapper>
        <ExpandButton hide={hide} onClick={() => setHide(!hide)} />

        <styles.Title>
          {title} ({modules.length})
        </styles.Title>
      </ItemHeaderWrapper>
      {hide ? (
        <ItemBody>
          {modules.map((el) => (
            <ModuleTablet
              key={el.uuid}
              module={el}
              appendModule={appendModule}
              counter={cbCounter(el.uuid)}
            />
          ))}
        </ItemBody>
      ) : null}
    </ItemContainer>
  );
};
