import { DashboardContext } from "src/context/CEX/Dashboard";
import { useLateInitContext } from "src/hooks/useLateInitContext";

export const useDashboardState = () => {
  const { state } = useLateInitContext(DashboardContext);
  return state;
};

export const useDashboardV2State = () => {
  const { state, version } = useLateInitContext(DashboardContext);
  if (version === "v1") {
    throw Error("useDashboardV2State: Wrong dashboard version!");
  }
  return state;
};
