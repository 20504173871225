import { createPortal } from "react-dom";
import { useAccountModalState } from "src/components/BotsContent/CEX/CEXApiKeys/Accounts/VolumeAccounts";
import {
  AddVolumeAccountModal,
  AddVolumeAccountModalProps,
} from "src/components/BotsContent/CEX/CEXApiKeys/Accounts/VolumeAccounts/AddVolumeAccountModal";
import { AccountsHeader } from "src/components/BotsContent/CEX/CEXApiKeys/shared/AccountsHeader";
import { VolumeAccountName } from "src/modules/accounts";
import { Account } from "../Account";
import * as styles from "./style";

export interface VolumeAccountsProps
  extends Pick<AddVolumeAccountModalProps, "onRefresh" | "onAddAccount" | "bindingsProvider"> {
  accountNames: VolumeAccountName[];
}

export const VolumeAccounts = ({ accountNames, ...props }: VolumeAccountsProps) => {
  const { show: showAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useAccountModalState();

  const volumeAccounts = accountNames.map((name) => <Account key={name} accountName={name} />);

  return (
    <styles.VolumeContainer>
      <AccountsHeader type="volume" onAddAccount={onOpenAdd} checkPermission={false} />
      <styles.AccountsContainer>{volumeAccounts}</styles.AccountsContainer>
      {createPortal(
        <AddVolumeAccountModal show={showAdd} close={onCloseAdd} {...props} />,
        document.body
      )}
    </styles.VolumeContainer>
  );
};
