import { ComponentPropsWithoutRef } from "react";
import { PanelHeaderOwnProps } from "src/components/shared/PanelHeader";
import * as styles from "./style";

export interface InfoPanelOwnProps
  extends Pick<PanelHeaderOwnProps, "label" | "labelIcon" | "info"> {}

export interface InfoPanelProps
  extends InfoPanelOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, keyof InfoPanelOwnProps> {}

export const InfoPanel = ({ label, labelIcon, info, children, ...props }: InfoPanelProps) => {
  const headerProps = { label, labelIcon, info };

  return (
    <styles.Container {...props}>
      <styles.Header {...headerProps} />
      <styles.Content>{children}</styles.Content>
    </styles.Container>
  );
};
