import { NewCredential, RequestAccount } from "src/modules/userManager";
import ResponseHandler from "src/state/network/ResponseHandler";
import { ApiModules, apiUrl } from "..";

export const addAPILAbel = async (uuid: string, id: number, meta: { [key: string]: string }) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/credentials/${id}/meta`),
    method: "PUT",
    data: { meta },
  });

export const addAccLabel = async (uuid: string, meta: { [key: string]: string }) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/meta`),
    method: "PUT",
    data: { meta },
  });

export const changeNameAcc = async (uuid: string, name: { name: string }) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/name`),
    method: "PUT",
    data: name,
  });

export const addAccount = async (party: string, account: RequestAccount) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Parties, `${party}/accounts`),
    method: "POST",
    data: account,
  });

export const deleteAccount = async (uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}`),
    method: "DELETE",
  });

export const addAPI = async (uuid: string, API: NewCredential) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/credentials`),
    method: "POST",
    data: API,
  });

export const deleteAPI = async (uuid: string, API_ID: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/credentials/${API_ID}`),
    method: "DELETE",
  });

export const changeNameAPI = async (uuid: string, API_ID: number, name: { name: string }) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/credentials/${API_ID}/name`),
    method: "PUT",
    data: name,
  });

export const getAPIKey = async (uuid: string, id: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/credentials/${id}?show_keys=true`),
    method: "GET",
  });

export const activateAPI = async (uuid: string, id: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/credentials/${id}/active`),
    method: "POST",
    data: { active_id: id },
  });

export const editAPI = async (uuid: string, id: number, meta: Record<string, string>) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/credentials/${id}/meta`),
    method: "PUT",
    data: { meta },
  });

export const editAcc = async (uuid: string, meta: Record<string, string>) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Accounts, `${uuid}/meta`),
    method: "PUT",
    data: { meta },
  });
