import { useCallback, useMemo } from "react";
import { GroupBase } from "react-select";
import { SelectorProps } from "src/components/shared/Forms/Selectors";
import { SelectorValue } from "src/modules/shared";
import { BalanceType } from "src/state/CEX/CEXDashboard/v2/widgets/BalancesV2Store";
import * as styles from "./style";

interface BalancesSelectorValue extends SelectorValue {
  value: BalanceType;
  label: string;
}

const BALANCES_MODE_OPTIONS: BalancesSelectorValue[] = [
  { value: "total", label: "Total" },
  { value: "quote", label: "Quote" },
  { value: "base", label: "Base" },
];

export interface BalancesModeSelectorProps
  extends Omit<
    SelectorProps<SelectorValue, false, GroupBase<SelectorValue>>,
    "value" | "onChange" | "options"
  > {
  type: BalanceType;
  onChange: (type: BalanceType) => void;
}

export const BalancesModeSelector = ({
  type,
  onChange: setMode,
  ...props
}: BalancesModeSelectorProps) => {
  const options = BALANCES_MODE_OPTIONS;

  const value = useMemo(() => options.find((option) => option.value === type), [options, type]);

  const onChange = useCallback(
    (selectorValue: SelectorValue | null) => {
      if (!selectorValue) return;
      const mode = selectorValue.value as BalanceType;
      setMode(mode);
    },
    [setMode]
  );

  return (
    <styles.StyledBalancesSelector value={value} onChange={onChange} options={options} {...props} />
  );
};
