import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import { ButtonsRow } from "./ButtonsRow/style";

export const Container = styled.div`
  width: 100%;

  @media ${getMobileMediaQuery("700px")} {
    flex-flow: column;
  }

  display: flex;
  gap: 8px;

  ${ButtonsRow} {
    @media ${getMobileMediaQuery("500px")} {
      flex-flow: column;
    }
  }
`;
