import { useParams } from "react-router-dom";

export interface BotUUIDQueryParams {
  uuid: string;
}

export const useBotUUID = () => {
  const { uuid } = useParams<BotUUIDQueryParams>();
  return uuid;
};
