import styled from "styled-components";
import { FormCol, FormRow } from "../../../Settings/shared/style";
import { TokenInput as BaseTokenInput } from "../Inputs/TokenInput";

export const InputCol = styled(FormCol)`
  gap: 8px;
  position: relative;
`;

export const InfoRow = styled(FormRow)`
  justify-content: space-between;
  align-items: center;
`;

export const TokenInput = styled(BaseTokenInput)``;
