import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { CheckBox } from "src/components/shared/CheckBox";
import { DepthChartContext } from "src/context/CEX/Exchange/DepthChart";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Subtitle } from "src/components/shared/Typography";
import * as styles from "./style";

const SCALE_TYPES = [
  { value: "linear", label: "Linear" },
  { value: "logarithmic", label: "Log" },
];

export const OptionsPanel = observer(() => {
  const state = useLateInitContext(DepthChartContext);

  const volumeType = useMemo(
    () => [
      { value: "quote", label: state.quote },
      { value: "base", label: state.base },
    ],
    [state.quote, state.base]
  );

  return (
    <styles.OptionsPanel>
      <styles.MarketDepthRange
        min={5}
        label="Market depth"
        value={state.marketDepth}
        onChange={state.getMarketDepthHandler()}
      />
      <styles.ScaleTypeSwitch
        label="Scale type"
        name="ScaleTypes"
        items={SCALE_TYPES}
        selectedItem={state.typeScale}
        onChange={state.getTypesScalesHandler()}
      />

      <styles.OrdersSelection>
        <Subtitle>Orders selection</Subtitle>
        <styles.CheckboxWrapper>
          <CheckBox
            label="Buy orders"
            checked={state.showBuyOrders}
            onChange={state.getShowOrdersHandler("showBuyOrders")}
          />
          <CheckBox
            label="Sell orders"
            checked={state.showSellOrders}
            onChange={state.getShowOrdersHandler("showSellOrders")}
          />
        </styles.CheckboxWrapper>
      </styles.OrdersSelection>

      <styles.ScaleTypeSwitch
        label="Volume type"
        name="VolumeType"
        items={volumeType}
        selectedItem={state.typeVolume}
        onChange={state.getTypesVolumeHandler()}
      />
    </styles.OptionsPanel>
  );
});
