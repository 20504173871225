import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { Loader } from "src/components/shared/Loader";
import { TickersContext } from "src/context/UserManager/UserManager";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { INewTickerGroup } from "src/state/UserManager/EditParty/TickersStore";
import { TickerForm } from "../shared/TickerForm";
import * as styles from "./style";

const FORM_ID = "create_ticker";

const EMPTY_TICKER_GROUP: INewTickerGroup = {
  name: "",
  type: "",
  tickers: [],
};

export const AddForm = observer(() => {
  const state = useLateInitContext(TickersContext);

  const methods = useForm<INewTickerGroup>({
    defaultValues: EMPTY_TICKER_GROUP,
  });

  const { handleSubmit } = methods;

  const { close } = useLateInitContext(PopupContext);

  return (
    <FormProvider {...methods}>
      <styles.Content>
        <TickerForm id={FORM_ID} onSubmit={handleSubmit(state.createTickerGroup(close))} />

        <SubmitButton form={FORM_ID}>Add ticker +</SubmitButton>

        <Loader show={state.isCreateLoading} />
      </styles.Content>
    </FormProvider>
  );
});
