import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { ethers } from "ethers";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { expToNumber } from "src/helpers/rounding";
import { IDisposable } from "src/helpers/utils";
import { IBotTradePairProvider, ISwapPairAddressProvider } from "../../../DEXV2Bots/DEXV2BotStore";
import { INativeUSDPriceProvider } from "../../../Providers/NativeUSDPriceProvider";
import { ISwapVaultProvider } from "../../Vaults";
import { CacheOptions } from "../../utils";
import { IBaseUSDPriceProvider } from "../shared/Providers/BaseUsdPriceProvider";
import { IGasPriceProvider } from "../shared/Providers/GasPriceProvider";
import { IRouterProvider } from "../shared/Providers/RouterProvider";
import { ISwapGasLimitProvider } from "../shared/Providers/SwapSettingsProvider";
import { toPercent } from "../shared/SlippageStore";
import { SwapInfo } from "../shared/SwapModules/SwapInfo";
import { ISwapSlippageProvider } from "../shared/SwapModules/SwapWidget";
import { Field, ISwapState, isExactInput } from "../shared/SwapStateStore";
import { INonNullableVersionProvider } from "../shared/VersionedSwapState";
import { ISwapInfoGasEstimate, SwapInfoGasEstimateStore } from "./SwapInfoGasEstimateStore";
import { ISwapInfoImpact, SwapInfoImpactStore } from "./SwapInfoImpact";
import { ISwapInfoRoute, SwapInfoRouteStore } from "./SwapInfoRouteStore";
import { ISwapInfoTradePair, SwapInfoTradePairStore } from "./SwapInfoTradePairStore";

type SwapBalances = Partial<Record<Field, CurrencyAmount<Token>>>;

type AmountsUSD = SwapBalances;

export const INITIAL_SWAP_INFO = {
  [Field.INPUT]: {},
  [Field.OUTPUT]: {},
};

export interface ISwapInfo {
  get info(): SwapInfo;
  calculateInfo: (options?: CacheOptions) => Promise<void>;
}

export interface IBaseSwapInfoParams {
  swapState: ISwapState;
  vaultProvider: ISwapVaultProvider;
  slippageProvider: ISwapSlippageProvider;
  gasLimitProvider: ISwapGasLimitProvider;
  tradePairProvider: IBotTradePairProvider;
  routerProvider: IRouterProvider;
  baseUSDPriceProvider: IBaseUSDPriceProvider;
  nativeUSDPriceProvider: INativeUSDPriceProvider;
  poolAddressProvider: ISwapPairAddressProvider;
  versionProvider: INonNullableVersionProvider;
  gasPriceProvider: IGasPriceProvider;
}

export class SwapInfoStore implements ISwapInfo, IDisposable {
  private _swapState: ISwapState;

  private _vaultProvider: ISwapVaultProvider;

  private _slippageProvider: ISwapSlippageProvider;

  private _gasPriceProvider: IGasPriceProvider;

  private _baseUSDPriceProvider: IBaseUSDPriceProvider;

  private _nativeUSDPriceProvider: INativeUSDPriceProvider;

  private _swapInfoRouteState: ISwapInfoRoute & IDisposable;

  private _swapPairInfoState: ISwapInfoTradePair & IDisposable;

  private _swapImpactInfoState: ISwapInfoImpact & IDisposable;

  private _swapGasInfoState: ISwapInfoGasEstimate & IDisposable;

  constructor({
    swapState,
    vaultProvider,
    slippageProvider,
    gasLimitProvider,
    tradePairProvider,
    routerProvider,
    baseUSDPriceProvider,
    nativeUSDPriceProvider,
    poolAddressProvider,
    versionProvider,
    gasPriceProvider,
  }: IBaseSwapInfoParams) {
    makeAutoObservable(this);

    this._swapState = swapState;

    this._vaultProvider = vaultProvider;
    this._slippageProvider = slippageProvider;

    this._gasPriceProvider = gasPriceProvider;

    this._nativeUSDPriceProvider = nativeUSDPriceProvider;

    this._baseUSDPriceProvider = baseUSDPriceProvider;

    this._swapInfoRouteState = new SwapInfoRouteStore({
      swapState,
      routerProvider,
      poolAddressProvider,
    });

    this._swapPairInfoState = new SwapInfoTradePairStore({
      swapState,
      tradePairProvider,
    });

    this._swapImpactInfoState = new SwapInfoImpactStore({
      pairInfo: this._swapPairInfoState,
      routeInfo: this._swapInfoRouteState,
      versionProvider,
    });

    this._swapGasInfoState = new SwapInfoGasEstimateStore({
      gasLimitProvider,
      nativeUSDPriceProvider,
      gasPriceProvider: this._gasPriceProvider,
    });

    makeLoggable<any>(this, {
      info: true,
      _swapRoute: true,
      _amounts: true,
      _swapPath: true,
      _parsedAmount: true,
      _vaultTokenField: true,
      _balances: true,
    });
  }

  private get _swap() {
    return this._swapState.swap;
  }

  get _swapRoute() {
    return this._swapInfoRouteState.swapRoute;
  }

  get _swapRouteError() {
    return this._swapInfoRouteState.swapRouteError;
  }

  private _routerTrade = async (options?: CacheOptions) => {
    await this._swapInfoRouteState.routeTrade(options);
  };

  private get _amounts() {
    const { type } = this._swap;
    const trade = this._swapRoute?.trade;
    const { parsedAmount } = this._swapInfoRouteState;
    const amounts = isExactInput(type)
      ? ([parsedAmount, trade?.outputAmount] as const)
      : ([trade?.inputAmount, parsedAmount] as const);
    return amounts;
  }

  private get _tradeSideField() {
    return this._swapPairInfoState.tradeSideField;
  }

  // we invert prices in case current prices are in trade base
  private get _shouldInvertPrice() {
    return this._swapPairInfoState.shouldInvertPrice;
  }

  private get _priceImpact() {
    return this._swapImpactInfoState.priceImpact;
  }

  private get _balances(): SwapBalances {
    const { quote: quoteBalance, base: baseBalance } = this._vaultProvider.vault;

    const fixedQuoteBalance = expToNumber(quoteBalance);
    const fixedBaseBalance = expToNumber(baseBalance);

    const { quote: quoteField, base: baseField } = this._tradeSideField;

    if (!quoteField || !baseField) {
      const tokenIn = this._swap[Field.INPUT];
      const tokenOut = this._swap[Field.OUTPUT];
      if (!tokenIn || !tokenOut) return {};
      return {
        [Field.INPUT]: CurrencyAmount.fromRawAmount(tokenIn, 0),
        [Field.OUTPUT]: CurrencyAmount.fromRawAmount(tokenOut, 0),
      };
    }

    const quoteToken = quoteField ? this._swap[quoteField] : undefined;
    const baseToken = baseField ? this._swap[baseField] : undefined;
    if (!quoteToken || !baseToken) {
      return {};
    }

    const weiBaseBalance = ethers.utils.parseUnits(fixedBaseBalance, baseToken.decimals);

    const weiQuoteBalance = ethers.utils.parseUnits(fixedQuoteBalance, quoteToken.decimals);

    return {
      [baseField]: CurrencyAmount.fromRawAmount(baseToken, weiBaseBalance.toString()),
      [quoteField]: CurrencyAmount.fromRawAmount(quoteToken, weiQuoteBalance.toString()),
    };
  }

  private get _transactionFee() {
    const { quote: quoteField, base: baseField } = this._tradeSideField;

    if (!quoteField || !baseField) {
      return undefined;
    }

    switch (true) {
      case quoteField === Field.INPUT && baseField === Field.OUTPUT: {
        return this._slippageProvider.transactionFees.buy;
      }
      case quoteField === Field.OUTPUT && baseField === Field.INPUT: {
        return this._slippageProvider.transactionFees.sell;
      }
      default:
        return undefined;
    }
  }

  private get _slippage() {
    const { error: slippageError, percent: baseSlippagePercent } = this._slippageProvider.slippage;
    if (!baseSlippagePercent || slippageError?.type === "error") {
      return undefined;
    }

    const transactionFee = this._transactionFee;
    const transactionFeePercent = toPercent(transactionFee);
    if (!transactionFeePercent) {
      return baseSlippagePercent;
    }

    return baseSlippagePercent.add(transactionFeePercent);
  }

  private get _gasEstimateUSD() {
    return this._swapGasInfoState.gasEstimateUSD;
  }

  private get _baseUSDPrice() {
    return this._baseUSDPriceProvider.baseUSDPrice;
  }

  // helper variables to track state when tokens are switched in ui,
  // but no trade refetch occurred with new tokens order
  private get _isSwitchingAmounts() {
    const [amountIn, amountOut] = this._amounts;
    if (!amountIn || !amountOut) return false;
    return amountIn.currency.equals(amountOut.currency);
  }

  private get _isSwitchingTrade() {
    const trade = this._swapRoute?.trade;
    const tokenIn = this._swap[Field.INPUT];
    if (!trade || !tokenIn) return false;
    return !trade.inputAmount.currency.equals(tokenIn);
  }

  private get _isSwitchingTokens() {
    return this._isSwitchingAmounts || this._isSwitchingTrade;
  }

  private get _amountsUsd(): AmountsUSD {
    if (this._isSwitchingTokens) {
      return {};
    }
    const baseUSDPrice = this._baseUSDPrice;
    const { base: baseField, quote: quoteField } = this._tradeSideField;
    const baseToken = baseField ? this._swap[baseField] : undefined;

    if (!baseUSDPrice || !quoteField || !baseField || !baseToken) {
      return {};
    }

    const [amountIn, amountOut] = this._amounts;

    const fieldAmounts = {
      [Field.INPUT]: amountIn,
      [Field.OUTPUT]: amountOut,
    };

    const baseAmount = fieldAmounts[baseField];
    if (!baseAmount) return {};

    const baseAmountUSD = baseUSDPrice.quote(baseAmount);

    const route = this._swapRoute?.route;

    const quoteAmount = fieldAmounts[quoteField];

    if (!quoteAmount || !route) {
      return { [baseField]: baseAmountUSD };
    }

    // get mid price in terms of trade base
    const midPriceInTradeBase = route.midPrice.baseCurrency.equals(baseToken)
      ? route.midPrice.invert()
      : route.midPrice;
    const quoteUsdPrice = midPriceInTradeBase.multiply(baseUSDPrice);

    const quoteAmountUSD = quoteUsdPrice.quote(quoteAmount);

    return { [baseField]: baseAmountUSD, [quoteField]: quoteAmountUSD };
  }

  private get _priceInfo(): SwapInfo["price"] {
    if (this._isSwitchingTokens) {
      return {};
    }

    const trade = this._swapRoute?.trade;
    const route = this._swapRoute?.route;

    const midPrice = route?.midPrice;
    const nextMidPrice = trade?.nextMidPrice ?? undefined;
    const executionPrice = trade?.executionPrice;

    // invert prices in case current prices are in trade base
    if (this._shouldInvertPrice) {
      return {
        midPrice: midPrice?.invert(),
        nextMidPrice: nextMidPrice?.invert(),
        executionPrice: executionPrice?.invert(),
      };
    }

    return { midPrice, nextMidPrice, executionPrice };
  }

  private get _tradeInfo() {
    const trade = this._swapRoute?.trade;
    const routeError = this._swapRouteError ?? undefined;

    const tradeInfo = {
      trade,
      gasEstimateUSD: this._gasEstimateUSD,
      error: routeError,
    };

    return tradeInfo as SwapInfo["trade"];
  }

  get info(): SwapInfo {
    const [amountIn, amountOut] = this._amounts;
    const amountsUsd = this._amountsUsd;
    const balances = this._balances;
    const priceInfo = this._priceInfo;

    const tradeInfo = this._tradeInfo;

    return {
      [Field.INPUT]: {
        token: this._swap[Field.INPUT],
        amount: amountIn,
        balance: balances[Field.INPUT],
        usd: amountsUsd[Field.INPUT],
      },
      [Field.OUTPUT]: {
        token: this._swap[Field.OUTPUT],
        amount: amountOut,
        balance: balances[Field.OUTPUT],
        usd: amountsUsd[Field.OUTPUT],
      },
      trade: tradeInfo,
      price: priceInfo,
      impact: this._priceImpact,
      slippage: this._slippage,
    } as SwapInfo;
  }

  private _refreshGasPrice = async (options?: CacheOptions) => {
    await this._gasPriceProvider.getGasPrice(options);
  };

  private _refreshTokenPrice = async (options?: CacheOptions) => {
    await this._baseUSDPriceProvider.getBaseUSDPrice(options);
  };

  private _refreshNativeUSDPrice = async (options?: CacheOptions) => {
    await this._nativeUSDPriceProvider.getNativeUSDPrice(options);
  };

  private get _swapInitialized() {
    return this._swapInfoRouteState.routeInitialized;
  }

  calculateInfo = async (options?: CacheOptions) => {
    if (!this._swapInitialized) return;
    try {
      await Promise.all([
        this._routerTrade(options),
        this._refreshGasPrice(options),
        this._refreshTokenPrice(options),
        this._refreshNativeUSDPrice(options),
      ]);
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {
    this._swapInfoRouteState.destroy();
    this._swapPairInfoState.destroy();
    this._swapImpactInfoState.destroy();
    this._swapGasInfoState.destroy();
  };
}
