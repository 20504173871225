import { toJS } from "mobx";
import { addAccount } from "src/api/userManager/accountsAPI";
import { NewAccount, RequestAccount } from "src/modules/userManager";
import { required } from "src/validation-schemas";

export class AccStore {
  party = "";

  message = "Account created successfully";

  validation = {
    name: required(),
    exchange: required(),
    "credentials.data.KEY": required(),
    "credentials.data.SECRET": required(),
    "credentials.data.ACCOUNT_ID": required(),
    "credentials.name": required(),
  };

  constructor(currentParty: string) {
    this.party = currentParty;
  }

  addEntity = (acc: NewAccount) => addAccount(this.party, this._mutateAccount(acc));

  _mutateAccount = (acc: NewAccount): RequestAccount => {
    // for default active API
    acc.credentials.is_active = true;

    const { credentials, ...accFields } = toJS(acc);

    const credential = [credentials];

    return { credentials: credential, ...accFields };
  };
}
