import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { getDEXBot } from "src/api/bots/DEX/bots";
import { DEXBotMob } from "src/components/AllBots/Bots/DEXBots/DEXBotMob";
import { DEFAULT_FULL_TIME_FORMAT, getCurrentUnix, unixToUTCFormat } from "src/helpers/dateUtils";
import { useFindWidth } from "src/hooks/useFindWidth";
import * as styles from "./styles";

const header = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Last trade", icon: "" },
  { title: "Next trade", icon: "" },
  { title: "Message", icon: "" },
];

export const BotInfoDEX = ({ bot_uuid }) => {
  const showMobVers = useFindWidth(850);

  const { data: result } = useQuery([`DEXBot/${bot_uuid}`, bot_uuid], () => getDEXBot(bot_uuid), {
    refetchInterval: 5000,
    initialData: {
      isError: false,
      data: {
        swap_bot: {
          swap_bot_id: 0,
          partyId: 0,
          exchange: "",
          quote: "",
          base: "",
          lastTrade: 0,
          nextTrade: 0,
          message: "",
          client: "",
          link: "",
        },
      },
    },
    enabled: Boolean(bot_uuid),
  });

  const { isError, data } = result;
  const swapBot = !isError ? data.swap_bot : undefined;

  const getColorStatus = (time) => {
    if (time === -1) {
      return "gray";
    }

    if (time + 60 < getCurrentUnix()) {
      return "red";
    }

    return "green";
  };

  const pair = useMemo(
    () => (typeof swapBot === "object" ? `${swapBot?.base}_${swapBot?.quote}` : ""),
    [swapBot]
  );

  const market = useMemo(
    () => (typeof swapBot === "object" ? `${pair}_${swapBot?.exchange}` : ""),
    [swapBot, pair]
  );

  const statsLink = `/DEX/${swapBot?.swap_bot_uuid}/?tab=STATS`;

  const credential = {
    market,
    UUID: swapBot?.swap_bot_uuid,
    link: statsLink,
  };

  const getTrade = useCallback(
    (tradeTime) => (tradeTime ? unixToUTCFormat(tradeTime, DEFAULT_FULL_TIME_FORMAT) : "No trade"),
    []
  );

  return (
    <styles.Container>
      {!showMobVers ? <styles.HeaderForPages cells={header} /> : null}
      {showMobVers ? (
        <DEXBotMob
          favotite={false}
          market={swapBot ? `${swapBot?.base}_${swapBot?.quote}_${swapBot?.exchange}` : ""}
          pair={swapBot ? `${swapBot?.base}_${swapBot?.quote}` : ""}
          lastTrade={getTrade(swapBot?.lastTrade)}
          nextTrade={getTrade(swapBot?.nextTrade)}
          client={swapBot?.client ?? ""}
          link={swapBot?.link ?? ""}
          exchange={swapBot?.exchange ?? ""}
          message={swapBot?.message ?? ""}
          colorStatus={swapBot ? getColorStatus(swapBot.nextTrade) : "gray"}
          credential={credential}
        />
      ) : (
        <styles.DEXBotForPages
          favotite={false}
          market={swapBot ? `${swapBot?.base}_${swapBot?.quote}_${swapBot?.exchange}` : ""}
          pair={swapBot ? `${swapBot?.base}_${swapBot?.quote}` : ""}
          lastTrade={getTrade(swapBot?.lastTrade)}
          nextTrade={getTrade(swapBot?.nextTrade)}
          client={swapBot?.client ?? ""}
          link={swapBot?.link ?? ""}
          exchange={swapBot?.exchange ?? ""}
          message={swapBot?.message ?? ""}
          colorStatus={swapBot ? getColorStatus(swapBot.nextTrade) : "gray"}
          credential={credential}
        />
      )}
    </styles.Container>
  );
};
