import { forwardRef, useMemo } from "react";
import { IconButton, IconButtonProps } from "src/components/shared/Buttons/v2/IconButton";
import { PopoverMenu, PopoverMenuProps } from "src/components/shared/PopoverMenu";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import Icons from "src/icons/Icons";

export interface StrategiesDropdownButtonProps {
  onDelete?: () => void;
  onEdit?: () => void;
  disableDelete?: boolean;
  menuProps?: PopoverMenuProps;
}

interface DotsButtonProps extends IconButtonProps {}

const DotsButton = forwardRef<HTMLButtonElement, DotsButtonProps>((props, ref) => (
  <IconButton boxed {...props} ownRef={ref}>
    {Icons.dots()}
  </IconButton>
));

export const StrategiesDropdownButton = ({
  onDelete,
  disableDelete,
  onEdit,
  menuProps,
}: StrategiesDropdownButtonProps) => {
  const actionItems = useMemo(() => {
    const editItem = {
      label: "Edit",
      onClick: onEdit,
    };

    const deleteItem = disableDelete
      ? undefined
      : {
          label: "Delete",
          onClick: onDelete,
        };

    return filterBoolean([editItem, deleteItem]);
  }, [disableDelete, onDelete, onEdit]);

  return <PopoverMenu trigger={<DotsButton />} items={actionItems} {...menuProps} />;
};
