import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { Column } from "react-table";
import { ExchangeAccountingContext } from "src/context/CEX/ExchangeAccounting";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ExchangeAccounting } from "src/state/CEX/accounting";
import { FilterDropdown } from "../TableHeader/Filters/FilterDropdown";
import { NumberRangeColumnFilter } from "../TableHeader/Filters/NumberRangeColumnFilter";
import { SelectColumnFilter } from "../TableHeader/Filters/SelectColumnFilter";
import {
  createExchangesSortBy,
  createIncludesFilter,
  createNumberBetweenFilter,
} from "../TableHeader/Filters/utils";
import { HeaderCell } from "../TableHeader/HeaderCell";
import { InfoTooltip } from "../TableHeader/HeaderCell/InfoPrompt/InfoTooltip";
import { ExchangesSpan } from "./ExchangesSpan";
import * as styles from "./style";

type ExchangeAccountingRowData = ExchangeAccounting;

const BASE_COLUMN_PROPS: Omit<
  Column<ExchangeAccountingRowData>,
  "Header" | "accessor" | "Footer" | "Cell"
> = {
  disableFilters: true,
};

const TABLE_INFO_TEXT = {
  team: "Name of the team the client belongs to",
  party: "Internal name of the client in the MM department",
  trader: "Trader who is responsible for client’s markets",
  avgExchanges:
    "The amount of hours when exchanges were active divided by the length of the period in days",
  avgExchangesNames: "List of average exchanges",
  currentExchanges: "Number of active exchanges now",
  currentExchangesNames: "List of active exchanges now",
  totalUnique: "Number of unique exchanges throughout the period",
};

export interface AccountingTableProps {
  data: ExchangeAccountingRowData[];
}

const INFO_TOOLTIP_ID = "accounting-info-id";

const EXCHANGES_TOOLTIP_ID = "exchanges-info";

export const AccountingTable = observer(({ data }: AccountingTableProps) => {
  const state = useLateInitContext(ExchangeAccountingContext.Context);

  const getInfoProps = useCallback(
    (column: keyof typeof TABLE_INFO_TEXT) => ({
      info: TABLE_INFO_TEXT[column],
      infoId: INFO_TOOLTIP_ID,
    }),
    []
  );

  const columns = useMemo(() => {
    const columnsSchema: Column<ExchangeAccountingRowData>[] = [
      {
        Header: <HeaderCell title="#" />,
        accessor: "index",
        ...BASE_COLUMN_PROPS,
      },
      {
        Header: <HeaderCell title="Party" {...getInfoProps("party")} />,
        accessor: "party",
        Filter: (props) => (
          <FilterDropdown<ExchangeAccounting>
            filterProps={props}
            filter={styles.NamesSelectorFilter}
          />
        ),
        filter: createIncludesFilter(),
      },
      {
        Header: <HeaderCell title="Team" {...getInfoProps("team")} />,
        accessor: "team",
        Filter: (props) => (
          <FilterDropdown<ExchangeAccounting>
            filterProps={props}
            filter={styles.NamesSelectorFilter}
          />
        ),
        filter: createIncludesFilter(),
      },
      {
        Header: <HeaderCell title="Trader" {...getInfoProps("trader")} />,
        accessor: "trader",
        Filter: (props) => (
          <FilterDropdown<ExchangeAccounting>
            filterProps={props}
            filter={styles.NamesSelectorFilter}
          />
        ),
        filter: createIncludesFilter(),
      },
      {
        Header: <HeaderCell title="Current exchanges" {...getInfoProps("currentExchanges")} />,
        accessor: "currentExchanges",
        Filter: (props) => (
          <FilterDropdown<ExchangeAccounting> filterProps={props} filter={SelectColumnFilter} />
        ),
        filter: createIncludesFilter(),
      },
      {
        Header: <HeaderCell title="Current exchanges" {...getInfoProps("currentExchangesNames")} />,
        accessor: "currentExchangesNames",
        Cell: ({ value: exchanges }) => (
          <ExchangesSpan exchanges={exchanges} id={EXCHANGES_TOOLTIP_ID} />
        ),
        Filter: (props) => (
          <FilterDropdown<ExchangeAccounting>
            filterProps={props}
            filter={styles.NamesSelectorFilter}
          />
        ),
        filter: createIncludesFilter(),
        sortType: createExchangesSortBy(),
      },
      {
        Header: <HeaderCell title="Avg exchanges" {...getInfoProps("avgExchanges")} />,
        accessor: "averageExchanges",
        Filter: (props) => (
          <FilterDropdown<ExchangeAccounting>
            filterProps={props}
            filter={NumberRangeColumnFilter}
          />
        ),
        filter: createNumberBetweenFilter({
          maxInclusive: true,
          minInclusive: true,
        }),
      },
      {
        Header: <HeaderCell title="Avg exchanges" {...getInfoProps("avgExchangesNames")} />,
        accessor: "averageExchangesNames",
        Cell: ({ value: exchanges }) => (
          <ExchangesSpan exchanges={exchanges} id={EXCHANGES_TOOLTIP_ID} />
        ),
        Filter: (props) => (
          <FilterDropdown<ExchangeAccounting>
            filterProps={props}
            filter={styles.NamesSelectorFilter}
          />
        ),
        filter: createIncludesFilter(),
        sortType: createExchangesSortBy(),
      },
      {
        Header: () => <HeaderCell title="Total unique" {...getInfoProps("totalUnique")} />,
        accessor: "totalUnique",
        Filter: (props) => (
          <FilterDropdown<ExchangeAccounting> filterProps={props} filter={SelectColumnFilter} />
        ),
        filter: createIncludesFilter(),
      },
    ];

    return columnsSchema;
  }, [getInfoProps]);

  return (
    <>
      <styles.StyledAccountingTable
        columns={columns}
        data={data}
        onRowsChanged={state.setTableRows}
      />
      <styles.ExchangesTooltip id={EXCHANGES_TOOLTIP_ID} />
      <InfoTooltip place="right" id={INFO_TOOLTIP_ID} />
    </>
  );
});
