import { ComponentPropsWithoutRef } from "react";
import { isDefined } from "src/helpers/utils";
import { usePNLV2Value } from "../Item/PNLV2Item";
import * as styles from "./style";

export interface PNLV2FeeProps extends ComponentPropsWithoutRef<"div"> {
  value?: string | number;
  showSymbol?: boolean;
}

export const PNLV2Fee = ({ value, showSymbol, ...props }: PNLV2FeeProps) => {
  const formattedValue = usePNLV2Value({
    value,
    type: "delta",
    showSymbol,
    showPlus: false,
  });
  return (
    <>
      {isDefined(value) && (
        <styles.Container {...props}>
          <styles.FeeLabel>Fee:</styles.FeeLabel>
          <styles.StyledFee>{formattedValue}</styles.StyledFee>
        </styles.Container>
      )}
    </>
  );
};
