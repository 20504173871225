import { PNLCharts } from "src/components/shared/PNLV2/shared/PNLCharts";
import { PNLData } from "src/state/CEX/shared/CEXPNLStore/types";
import { CardProps } from "../../Card";
import { useFeeChecked, useFundingsExchangeInfo, usePrice } from "../../hooks";
import { PNLHeader, PNLHeaderOwnProps } from "./PNLHeader";
import { PNLItems, PNLItemsProps } from "./PNLItems";
import * as styles from "./style";

export interface PNLWithChartsProps extends PNLData, Omit<CardProps, "info" | "afterTitle"> {
  info?: string;
}

export const PNLWithCharts = ({
  base,
  deltaBase,
  deltaBaseUsd,
  deltaQuoteUsd,
  deltaUsd,
  currentPriceBase,
  priceGrowth,
  priceNet,
  fee,
  info,
  title = "PNL",
  fundingsExchange,
  charts,
  ...props
}: PNLWithChartsProps) => {
  const [useFee, onFeeChecked] = useFeeChecked(true);
  const price = usePrice({ priceGrowth, priceNet, useFee });
  const fundingsExchangeInfo = useFundingsExchangeInfo(fundingsExchange);

  const headerProps: PNLHeaderOwnProps = {
    fee,
    feeChecked: useFee,
    onFeeChecked,
    price: currentPriceBase,
    info: fundingsExchangeInfo,
  };

  const itemsProps: PNLItemsProps = {
    base,
    deltaBase,
    deltaBaseUsd,
    deltaQuoteUsd,
    price,
    deltaUsd,
    fundingsExchange,
    charts,
  };

  return (
    <styles.StyledCard
      info={info}
      title={title}
      afterTitle={<PNLHeader {...headerProps} />}
      {...props}
    >
      <styles.Container>
        {charts && <PNLCharts {...charts} />}

        <PNLItems {...itemsProps} />
      </styles.Container>
    </styles.StyledCard>
  );
};
