import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { ISwapModule } from "src/state/CEX/botModules";
import { ModulePanel } from "../ModulePanel";
import { SwapModuleForm } from "./SwapModuleForm";

export interface SwapModuleProps {
  module: ISwapModule;
}

export const SwapModule = observer(({ module }: SwapModuleProps) => {
  const methods = useForm<ISwapModule>({
    defaultValues: module,
  });

  return (
    <FormProvider {...methods}>
      <ModulePanel module={module}>
        <SwapModuleForm />
      </ModulePanel>
    </FormProvider>
  );
});
