import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { ExchangesLegendContext } from "src/context/CEX/Dashboard/ExchangesLegend";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

export interface ExchangesLegendProps extends ComponentPropsWithoutRef<"div"> {}

export const ExchangesLegend = observer((props: ExchangesLegendProps) => {
  const state = useLateInitContext(ExchangesLegendContext.Context);
  return (
    <styles.LegendContainer {...props}>
      <styles.PieSelector {...state.chartSelectorProps} />
      <styles.Table />
    </styles.LegendContainer>
  );
});
