import { ComponentPropsWithoutRef, RefObject, useMemo } from "react";
import { createPortal } from "react-dom";
import { entries } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { useId } from "src/hooks/useId";
import { AccountBalance } from "src/state/CEX/CEXDashboard/v2/widgets/AccountBalancesStore";
import { BalancesTooltip } from "../BalancesTooltip";
import { EmptyBalancesPlaceholder } from "./EmptyBalancesPlaceholder";
import * as styles from "./style";

const BALANCES_TOOLTIP_ID = "account-balances-tooltip";

export const useTooltipId = () => {
  const baseTooltipId = useId();

  const id = useMemo(() => `${BALANCES_TOOLTIP_ID}-${baseTooltipId}`, [baseTooltipId]);

  return id;
};

export interface AccountBalanceListInfoProps {
  infoAnchorEl?: RefObject<HTMLElement> | null;
  showInfo?: boolean;
}
export interface AccountBalanceListProps
  extends AccountBalanceListInfoProps,
    ComponentPropsWithoutRef<"div"> {
  balances: AccountBalance;
  currency: string;
  showEmpty?: boolean;
}

export const AccountBalanceList = ({
  balances,
  currency,
  infoAnchorEl,
  showInfo,
  showEmpty = true,
  ...props
}: AccountBalanceListProps) => {
  const tooltipAnchor = infoAnchorEl?.current ?? document.body;

  const tooltipId = useTooltipId();

  const tooltipProps = {
    currency,
    balances,
    id: tooltipId,
  };

  const balancesEntries = useMemo(() => filterBoolean(entries(balances)), [balances]);

  const nonEmptyBalancesCount = useMemo(
    () => balancesEntries.filter(([, balance]) => balance !== undefined).length,
    [balancesEntries]
  );

  const isEmptyBalances = nonEmptyBalancesCount === 0;

  return isEmptyBalances && showEmpty ? (
    <EmptyBalancesPlaceholder currency={currency} />
  ) : (
    <>
      <styles.BalancesContainer data-tooltip-id={tooltipId} {...props}>
        {balancesEntries.map(([type, balance]) => (
          <styles.Row key={type}>
            <styles.Type>{type}</styles.Type>
            <styles.Balance>{balance}</styles.Balance>
          </styles.Row>
        ))}
      </styles.BalancesContainer>
      {showInfo && createPortal(<BalancesTooltip {...tooltipProps} />, tooltipAnchor)}
    </>
  );
};
