import { observer } from "mobx-react-lite";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FormContent, FormRow, SettingsPanel } from "../shared";

export const VolumeSettings = observer(() => {
  const state = useLateInitContext(DEXV2CreatingContext);

  return (
    <SettingsPanel>
      <FormContent>
        <CheckBox
          label="Use Receiver"
          checked={state.data.volume_data.use_receiver}
          onChange={state.getHandler("volume_data.use_receiver")}
        />

        <LabeledInput
          type="number"
          step="any"
          label="Period minutes"
          {...state.getInputProps("volume_data.period")}
        />

        <LabeledPercentRangeInput
          label="BuyPercent"
          step="0.1"
          {...state.getInputProps("volume_data.buy_percent")}
        />

        <FormRow>
          <LabeledInput
            type="number"
            step="any"
            label="Min Amount"
            {...state.getInputProps("volume_data.min_amount")}
          />

          <LabeledInput
            type="number"
            step="any"
            label="Max Amount"
            {...state.getInputProps("volume_data.max_amount")}
          />
        </FormRow>

        <FormRow>
          <LabeledInput
            type="number"
            step="any"
            label="Min Trades"
            {...state.getInputProps("volume_data.min_trades")}
          />

          <LabeledInput
            type="number"
            step="any"
            label="Max Trades"
            {...state.getInputProps("volume_data.max_trades")}
          />
        </FormRow>

        <LabeledInput
          type="number"
          step="0.1"
          label="Slippage"
          {...state.getInputProps("volume_data.slippage")}
        />

        <LabeledPercentRangeInput
          label="Gas Mult"
          step="0.1"
          {...state.getInputProps("volume_data.gas_mult")}
        />

        <LabeledInput
          type="number"
          step="any"
          label="Gas Max"
          {...state.getInputProps("volume_data.gas_price_limit")}
        />
      </FormContent>
    </SettingsPanel>
  );
});
