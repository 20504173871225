import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { FilledFloatingOrder } from "src/modules/exchange/trade";
import * as styles from "./style";

export interface FilledOrdersTableProps {
  orders: FilledFloatingOrder[];
}

export const FilledOrdersTable = ({ orders }: FilledOrdersTableProps) => {
  const columns = useMemo(() => {
    const columnSchema: ColumnDef<FilledFloatingOrder>[] = [
      {
        header: () => "ID",
        accessorKey: "external_id",
        enableColumnFilter: false,
        enableResizing: false,
      },
      {
        header: () => "Filled",
        accessorKey: "amount_filled",
        enableColumnFilter: false,
        enableResizing: false,
      },
      {
        header: () => "Created",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableResizing: false,
      },
    ];

    return columnSchema;
  }, []);

  return <styles.OrdersTable data={orders} columns={columns} loader={false} />;
};
