import { forwardRef } from "react";
import { IconButton, IconButtonProps } from "src/components/shared/Buttons/v2/IconButton";
import Icons from "src/icons/Icons";

export interface FilterButtonProps {}

export const FilterButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
  <IconButton {...props} ownRef={ref}>
    {Icons.filter()}
  </IconButton>
));
