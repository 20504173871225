import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { checkParty } from "src/helpers/checkParty";
import { Abilities } from "src/modules/abilities";
import styled from "styled-components";
import { BaseCEXPNLWidget } from "../shared/BaseCEXPNLWidget";
import { CEXNotes } from "./CEXNotes";

export interface ContainerProps {
  abilities: Abilities;
  party: string;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;

  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr) 335px 220px;
  grid-template-rows: 1fr repeat(3, 120px) auto;
  grid-template-areas: ${({ abilities, party }) => {
    const checkNotesPermsn = checkParty(abilities, "cex-notes-view", party);

    const checkSettingsPermsn = checkParty(abilities, "cex-settings-view", party);

    if (!checkSettingsPermsn && checkNotesPermsn)
      return `
    "bot                bot                   bot                 bot                   balances-pie    "
    "balances-graph     balances-graph        balances-graph      PNL                   balances-pie    "
    "balances-graph     balances-graph        balances-graph      balances-table        notes           "
    "balances-graph     balances-graph        balances-graph      balances-table        notes           "
    "balance-USDT       balance-USDT          balance-USDT        balance-USDT          balance-USDT    "
    "balance-token      balance-token         balance-token       balance-token         balance-token   "
    "assets-balances    assets-balances       assets-balances     assets-balances       assets-balances ";
    `;

    if (!checkSettingsPermsn && !checkNotesPermsn)
      return `
    "bot                bot                   bot                 bot                   balances-pie    "
    "balances-graph     balances-graph        balances-graph      PNL                   balances-pie    "
    "balances-graph     balances-graph        balances-graph      balances-table        balances-table  "
    "balances-graph     balances-graph        balances-graph      balances-table        balances-table  "
    "balance-USDT       balance-USDT          balance-USDT        balance-USDT          balance-USDT    "
    "balance-token      balance-token         balance-token       balance-token         balance-token   "
    "assets-balances    assets-balances       assets-balances     assets-balances       assets-balances ";
    `;

    if (checkNotesPermsn && checkSettingsPermsn)
      return `
    "bot                bot                   bot                 bot                   balances-pie    "
    "time-line          time-line             time-line           PNL                   balances-pie    "
    "balances-graph     balances-graph        balances-graph      balances-table        notes           "
    "balances-graph     balances-graph        balances-graph      balances-table        notes           "
    "balance-USDT       balance-USDT          balance-USDT        balance-USDT          balance-USDT    "
    "balance-token      balance-token         balance-token       balance-token         balance-token   "
    "assets-balances    assets-balances       assets-balances     assets-balances       assets-balances ";
    `;

    return `
    "bot                bot                   bot                 bot                   balances-pie    "
    "time-line          time-line             time-line           PNL                   balances-pie    "
    "balances-graph     balances-graph        balances-graph      balances-table        balances-table  "
    "balances-graph     balances-graph        balances-graph      balances-table        balances-table  "
    "balance-USDT       balance-USDT          balance-USDT        balance-USDT          balance-USDT    "
    "balance-token      balance-token         balance-token       balance-token         balance-token   "
    "assets-balances    assets-balances       assets-balances     assets-balances       assets-balances ";
    `;
  }};

  box-sizing: border-box;
  /* padding: 10px 0; */
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr) auto;
    grid-template-rows: 1fr repeat(3, 120px) 200px auto;

    grid-template-areas: ${({ abilities, party }) => {
      const checkNotesPermsn = checkParty(abilities, "cex-notes-view", party);

      const checkSettingsPermsn = checkParty(abilities, "cex-settings-view", party);

      if (checkNotesPermsn && checkSettingsPermsn)
        return `
      "bot                bot                   bot             "
      "time-line          time-line             PNL             "
      "balances-graph     balances-graph        balances-table  "
      "balances-graph     balances-graph        balances-table  "
      "balances-pie       balances-pie          notes           "
      "balance-USDT       balance-USDT          balance-USDT    "
      "balance-token      balance-token         balance-token   "
      "assets-balances    assets-balances       assets-balances ";`;

      if (!checkSettingsPermsn && checkNotesPermsn)
        return `
      "bot                bot                   bot             "
      "balances-graph     balances-graph        PNL             "
      "balances-graph     balances-graph        balances-table  "
      "balances-graph     balances-graph        balances-table  "
      "balances-pie       balances-pie          notes           "
      "balance-USDT       balance-USDT          balance-USDT    "
      "balance-token      balance-token         balance-token   "
      "assets-balances    assets-balances       assets-balances ";`;

      if (!checkSettingsPermsn && !checkNotesPermsn)
        return `
      "bot                bot                   bot             "
      "balances-graph     balances-graph        PNL             "
      "balances-graph     balances-graph        balances-table  "
      "balances-graph     balances-graph        balances-table  "
      "balances-pie       balances-pie          balances-pie    "
      "balance-USDT       balance-USDT          balance-USDT    "
      "balance-token      balance-token         balance-token   "
      "assets-balances    assets-balances       assets-balances ";`;

      return `
      "bot                bot                   bot             "
      "time-line          time-line             PNL             "
      "balances-graph     balances-graph        balances-table  "
      "balances-graph     balances-graph        balances-table  "
      "balances-pie       balances-pie          balances-pie    "
      "balance-USDT       balance-USDT          balance-USDT    "
      "balance-token      balance-token         balance-token   "
      "assets-balances    assets-balances       assets-balances ";
      `;
    }};
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, calc(50% - 5px));

    grid-template-areas: ${({ abilities, party }) => {
      const checkNotesPermsn = checkParty(abilities, "cex-notes-view", party);

      const checkSettingsPermsn = checkParty(abilities, "cex-settings-view", party);

      if (checkNotesPermsn && checkSettingsPermsn)
        return `
      "bot                        bot             "
      "time-line                  PNL             "
      "balances-graph             balances-table  "
      "balances-graph             balances-table  "
      "balances-pie               notes           "
      "balance-USDT               balance-USDT    "
      "balance-token              balance-token   "
      "assets-balances            assets-balances ";`;

      if (!checkSettingsPermsn && checkNotesPermsn)
        return `
      "bot                        bot             "
      "balances-graph             PNL             "
      "balances-graph             balances-table  "
      "balances-graph             balances-table  "
      "balances-pie               notes           "
      "balance-USDT               balance-USDT    "
      "balance-token              balance-token   "
      "assets-balances            assets-balances ";`;

      if (!checkSettingsPermsn && !checkNotesPermsn)
        return `
      "bot                        bot             "
      "balances-graph             PNL             "
      "balances-graph             balances-table  "
      "balances-graph             balances-table  "
      "balances-pie               balances-pie    "
      "balance-USDT               balance-USDT    "
      "balance-token              balance-token   "
      "assets-balances            assets-balances ";`;

      return `
      "bot                        bot             "
      "time-line                  PNL             "
      "balances-graph             balances-table  "
      "balances-graph             balances-table  "
      "balances-pie               balances-pie    "
      "balance-USDT               balance-USDT    "
      "balance-token              balance-token   "
      "assets-balances            assets-balances ";`;
    }};
  }
  @media (max-width: 750px) {
    grid-template-columns: 100%;

    grid-template-areas: ${({ abilities, party }) => {
      const checkNotesPermsn = checkParty(abilities, "cex-notes-view", party);

      const checkSettingsPermsn = checkParty(abilities, "cex-settings-view", party);

      if (checkNotesPermsn && checkSettingsPermsn)
        return `
      "bot              "
      "time-line        "
      "PNL              "
      "balances-graph   "
      "balances-table   "
      "balances-pie     "
      "notes            "
      "balance-USDT     "
      "balance-token    "
      "assets-balances  ";`;

      if (checkNotesPermsn && !checkSettingsPermsn)
        return `
      "bot              "
      "PNL              "
      "balances-graph   "
      "balances-graph   "
      "balances-table   "
      "balances-pie     "
      "notes            "
      "balance-USDT     "
      "balance-token    "
      "assets-balances  ";`;

      if (!checkNotesPermsn && !checkSettingsPermsn)
        return `
      "bot              "
      "PNL              "
      "balances-graph   "
      "balances-graph   "
      "balances-table   "
      "balances-pie     "
      "balance-USDT     "
      "balance-token    "
      "assets-balances  ";`;

      return `
      "bot              "
      "time-line        "
      "PNL              "
      "balances-graph   "
      "balances-table   "
      "balances-pie     "
      "balance-USDT     "
      "balance-token    "
      "assets-balances  ";`;
    }};
  }
`;

export const ReportItem = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  border: ${({ theme }) => theme.borders.solidGray};
  border-radius: ${({ theme }) => theme.radii["16px"]};
  background-color: ${({ theme }) => theme.contentBackgroundColor};
`;

export const StyledPNL = styled(BaseCEXPNLWidget)`
  grid-area: PNL;
`;

export const BotContainer = styled.div`
  width: 100%;
  max-width: 100%;

  grid-area: bot;
`;

export const NotesContainer = styled(CEXNotes)`
  min-height: 200px;

  grid-area: notes;
`;

export const NotesFallback = styled(CrashPanel)`
  min-height: 200px;
  grid-area: notes;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.textColor};
`;
