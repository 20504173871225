import styled from "styled-components";

export const CaptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const CaptionTitle = styled.p`
  padding: 0;
  margin: 0;

  color: ${({ theme }) => theme.dashboard.textSecondary};

  font-size: ${({ theme }) => theme.fontSizes["8px"]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

export const CaptionBody = styled(CaptionTitle)`
  color: ${({ theme }) => theme.dashboard.textSubhead};

  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
