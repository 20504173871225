import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;
`;

interface ColorBoxProps {
  $color: string;
}

export const ColorBox = styled.div<ColorBoxProps>`
  border-radius: 100%;
  background-color: ${({ $color }) => $color};
  width: 4px;
  height: 4px;
`;

interface TitleProps {
  $hidden: boolean;
}

export const Title = styled.span<TitleProps>`
  text-decoration: ${({ $hidden }) => ($hidden ? "line-through" : "none")};
`;
