import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ScopeItem } from "./ScopeItem";
import * as styles from "./shared";

interface ScopesTreeProps {
  hide: React.Dispatch<React.SetStateAction<boolean>>;
  setEditedParty: React.Dispatch<React.SetStateAction<string>>;
}

export const ScopesTree = observer(({ hide, setEditedParty }: ScopesTreeProps) => {
  const state = useLateInitContext(UserManagerContext);

  return (
    <styles.Container>
      {state.scopeGraph[0] ? (
        <ScopeItem
          key={state.scopeGraph[0].model.scope_id}
          scope={state.scopeGraph[0]}
          childrenID={state.scopeAdjList[0]}
          hideEditPanel={hide}
          setEditedParty={setEditedParty}
          parentLeftPosition={0}
        />
      ) : null}
      <Loader show={state.isLoadingScopes} />
    </styles.Container>
  );
});
