import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import GasWalletsStore from "src/state/DEXV2/DEXV2Stats/GasWallets";
import { DEXV2BotContext } from "../../DEXV2Bots/DEXV2Bot";

export const GasWalletsContext = createLateInitContext<GasWalletsStore>();

interface GasWalletsProviderProps extends ChildrenProps {}

export const GasWalletsProvider = ({ children }: GasWalletsProviderProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const walletsStore = useLocalStore(GasWalletsStore, botState);

  return <GasWalletsContext.Provider value={walletsStore}>{children}</GasWalletsContext.Provider>;
};
