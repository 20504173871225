import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { SelectPanel } from "src/components/shared/Forms/Selectors/SelectionPanel/SelectPanel";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Role } from "src/modules/userManager";
import * as styles from "../../../shared";

interface Props {
  editableRole: Role;
}

export const EditRole = observer(({ editableRole }: Props) => {
  const state = useLateInitContext(UserManagerContext);

  const abilities = useMemo(
    () => editableRole.abilities.map((el) => ({ value: el, label: el })),
    [editableRole.abilities]
  );

  return (
    <ModalPanel
      label="Edit role"
      show={state.visiblePanel.showEditRole}
      close={state.openPanel("showEditRole")}
      loading={false}
    >
      <styles.Container>
        <styles.Wrapper>
          <styles.Column>
            <LabeledInput
              label="Role name"
              readOnly
              value={editableRole.name}
              onChange={() => {}}
            />{" "}
          </styles.Column>

          <styles.Column>
            <SelectPanel
              editable={false}
              selectItems={abilities}
              removeClick={() => {}}
              style={{ height: "350px" }}
            />
            <OutlineButton
              onClick={() => state.removeRole(editableRole.name)}
              style={{ marginTop: "43px" }}
            >
              Delete role
            </OutlineButton>
          </styles.Column>
        </styles.Wrapper>
      </styles.Container>
    </ModalPanel>
  );
});
