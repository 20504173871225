import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 17px;

  > * {
    flex-grow: 1;
  }
`;
