import styled from "styled-components";
import { InfoTooltip } from "src/components/BotsContent/CEX/shared/Card/Header/InfoIconTooltip/style";
import { TOOLTIP_CLASSNAME } from "src/components/BotsContent/CEX/shared/consts";

export const Container = styled.div`
  display: flex;
  gap: 8px;

  padding: 2px 4px;

  border-radius: ${({ theme }) => theme.radii["8px"]};

  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.dashboard.backgroundOverlay};
  }
`;

export const IndicatorTooltip = styled(InfoTooltip)`
  &.${TOOLTIP_CLASSNAME} {
    width: 100px;
  }
`;
