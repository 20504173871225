import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import {
  GenerateTimeSeriesOptions,
  generateTime,
  generateTimeSeries,
  generateTokenTickers,
} from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

type SpreadTokenData = {
  ticker: string;
  data: ChartPoint[];
};

export type SpreadData = SpreadTokenData[];

const generateSpreadData = (): SpreadData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 12, max: 100 });

  const tokens = generateTokenTickers({ count: { min: 4, max: 10 } });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    startTimestamp,
    count: pointsCount,
    value: {
      min: 0,
      max: 100,
      precision: 0.01,
    },
  };

  const spreadData = tokens.map((ticker) => {
    const data = generateTimeSeries(generateSeriesOptions);
    return { ticker, data };
  });

  return spreadData;
};

export class SpreadV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: SpreadData = [];

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBaseDashboardV2StoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { data: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: SpreadData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData([]);
    try {
      await delay(200);
      const data = generateSpreadData();
      this._setData(data);
    } catch {
      this._setData([]);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
