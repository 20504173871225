import { CellProps } from "react-table";
import { ModuleErrors } from "src/state/CEX/multiGrid/Modules";
import * as styles from "./style";

export interface ErrorCellWrapperProps<D extends object>
  extends Pick<CellProps<D>, "value" | "row"> {}

const ErrorCellWrapper = ({ row, value }: ErrorCellWrapperProps<ModuleErrors>) => (
  <styles.ErrorCellWrapper isActive={row.original.active}>{value}</styles.ErrorCellWrapper>
);

export default ErrorCellWrapper;
