/* eslint-disable react/no-array-index-key */
import { ConstraintSpan } from "../shared";

const alertsWarningsRegExp = /\b(alerts|warnings)\b/;

const tokenizeAlertsWarningsText = (text: string) => text.split(alertsWarningsRegExp);

export const createConstraintModalStyledText = (text: string) => {
  const tokens = tokenizeAlertsWarningsText(text);
  const styledTokens = tokens.map((token, index) => {
    if (token.includes("alert"))
      return (
        <ConstraintSpan $type="alert" key={index}>
          {token}
        </ConstraintSpan>
      );
    if (token.includes("warning"))
      return (
        <ConstraintSpan $type="warning" key={index}>
          {token}
        </ConstraintSpan>
      );
    return token;
  });

  return <>{styledTokens}</>;
};
