export default class DEXV2BotIcons {
  static volume = () => (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.55556 8.77778V7.22222M2.55556 2.55556V1M4.11111 3.02222V6.75556C4.11111 6.87932 4.06194 6.99802 3.97443 7.08554C3.88691 7.17306 3.76821 7.22222 3.64444 7.22222H1.46667C1.3429 7.22222 1.2242 7.17306 1.13668 7.08554C1.04917 6.99802 1 6.87932 1 6.75556V3.02222C1 2.89845 1.04917 2.77976 1.13668 2.69224C1.2242 2.60472 1.3429 2.55556 1.46667 2.55556H3.64444C3.76821 2.55556 3.88691 2.60472 3.97443 2.69224C4.06194 2.77976 4.11111 2.89845 4.11111 3.02222Z"
        stroke="black"
        strokeOpacity="0.3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.55556 12.7778V11.2222M9.55556 6.55556V5M11.1111 7.02222V10.7556C11.1111 10.8793 11.0619 10.998 10.9744 11.0855C10.8869 11.1731 10.7682 11.2222 10.6444 11.2222H8.46667C8.3429 11.2222 8.2242 11.1731 8.13668 11.0855C8.04917 10.998 8 10.8793 8 10.7556V7.02222C8 6.89845 8.04917 6.77976 8.13668 6.69224C8.2242 6.60472 8.3429 6.55556 8.46667 6.55556H10.6444C10.7682 6.55556 10.8869 6.60472 10.9744 6.69224C11.0619 6.77976 11.1111 6.89845 11.1111 7.02222Z"
        stroke="black"
        strokeOpacity="0.3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  static limit = () => (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 11.5H13.3529" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M1 8.41176L2.36753 6.58839C2.59055 6.29104 3.04852 6.33233 3.21474 6.66478L3.94985 8.13498C4.13411 8.50351 4.66001 8.50351 4.84427 8.13498L6.72926 4.36502C6.91352 3.99649 7.43942 3.99649 7.62368 4.36502L9.47081 8.05927C9.66167 8.44098 10.2122 8.42352 10.3785 8.03048L13.3529 1"
        stroke="black"
        strokeOpacity="0.3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  static counter = () => (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.21202 3.52777L3.21202 12.375M3.21202 12.375L1.00022 9.94201M3.21202 12.375L5.42383 9.94201"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.89952 9.84723L8.89952 1.00001M8.89952 1.00001L6.68772 3.43299M8.89952 1.00001L11.1113 3.43299"
        stroke="black"
        strokeOpacity="0.3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
