import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 0px;

  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1 1 auto;

  ${CardSurface}
`;
