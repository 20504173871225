import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import * as styles from "./style";

export interface TooltipDateProps extends ComponentPropsWithoutRef<"span"> {}

export const TooltipDate = observer((props: TooltipDateProps) => {
  const state = useTooltipContext();
  return <styles.Date {...props}>{state.date}</styles.Date>;
});
