import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import { Headline3 } from "src/components/shared/Typography";
import { ModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { AbilitiesContext } from "src/context/shared/Abilities";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { StrategyModuleCategory } from "src/modules/expertSystem";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { TabHeader, Wrapper } from "../../../shared";
import { CreationModal } from "../../CreationModal";
import * as styles from "./style";

interface ModulesMenuItem extends MenuItemData {
  value: StrategyModuleCategory;
}

const MODULES_MENU_ITEMS: ModulesMenuItem[] = [
  {
    label: "Conditions",
    value: "condition",
  },
  {
    label: "Actions",
    value: "action",
  },
];

const useModulesTabs = () => {
  const { currentType, setCurrentType } = useLateInitContext(ModulesContext.Context);

  const onChangeKey = useCallback(
    (value: string) => {
      const key = value as StrategyModuleCategory;
      setCurrentType(key);
    },
    [setCurrentType]
  );

  const tabsItems = MODULES_MENU_ITEMS;

  return {
    tabsItems,
    currentKey: currentType,
    onChangeKey,
  };
};

export interface ModulesEditTableProps {
  editing: boolean;
}

export interface ModulesListProps {
  table: React.ReactNode;
  filter: React.ReactNode;
  onRefresh?: () => void;
}

export const ModulesList = observer(({ table, filter, onRefresh }: ModulesListProps) => {
  const { party } = useLateInitContext(ModulesContext.Context);
  const { abilityName } = useLateInitContext(AbilitiesContext);

  const { tabsItems, currentKey, onChangeKey } = useModulesTabs();

  return (
    <styles.ModuleContainer>
      <TabHeader>
        <styles.HeaderWrapper>
          <Headline3>Modules</Headline3>
          <styles.StyledSwitchTabs
            border={false}
            selectedItem={currentKey}
            direction="row"
            menuItems={tabsItems}
            onChange={onChangeKey}
          />
        </styles.HeaderWrapper>

        <Wrapper>
          <ReloadButton onClick={onRefresh} />
          {filter}

          <PermissionWrapper party={party} abilityName={abilityName}>
            <CreationModal />
          </PermissionWrapper>
        </Wrapper>
      </TabHeader>

      {table}
    </styles.ModuleContainer>
  );
});
