import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { Field } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/SwapStateStore";
import { formatCurrencyAmount } from "src/state/DEXV2/DEXV2Swap/utils";
import { useSwapWidgetState } from "../../../hooks/useSwapWidgetState";
import * as styles from "./style";

const getPriceUSDMessage = (usdAmount?: CurrencyAmount<Token>) => {
  const formattedPriceUSD = formatCurrencyAmount(usdAmount);
  return `$ ${formattedPriceUSD}`;
};

export interface PriceUSDSpanProps extends ComponentPropsWithoutRef<"span"> {
  field: Field;
}

export const PriceUSDSpan = observer(({ field, ...props }: PriceUSDSpanProps) => {
  const { swapInfoState } = useSwapWidgetState();

  const {
    [field]: { usd },
  } = swapInfoState.info;

  const message = getPriceUSDMessage(usd);

  return <styles.PriceUSDSpan {...props}>{message}</styles.PriceUSDSpan>;
});
