import { ComponentPropsWithoutRef, useMemo } from "react";
import * as styles from "./style";

export interface EventSideTextProps
  extends ComponentPropsWithoutRef<"span">,
    Pick<styles.EventSideSpanProps, "type"> {
  side?: "input" | "output";
}

export const EventSideText = ({ type = "buy", side = "input", ...props }: EventSideTextProps) => {
  const text = useMemo(() => {
    const text = type === "buy" ? "Buy" : "Sell";
    return text;
  }, [type]);

  return (
    <styles.EventSideSpan type={type} {...props}>
      {text}
    </styles.EventSideSpan>
  );
};
