import { useCallback } from "react";
import { TabStepNavigationAction } from "..";

export type StepAction = () => void;

export const useStepActions = (
  uuid: string,
  prevStep?: TabStepNavigationAction,
  nextStep?: TabStepNavigationAction
): [StepAction, StepAction] => {
  const prev = useCallback(() => {
    prevStep?.(uuid);
  }, [prevStep, uuid]);

  const next = useCallback(() => {
    nextStep?.(uuid);
  }, [nextStep, uuid]);

  return [prev, next];
};
