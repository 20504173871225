import {
  IReactionDisposer,
  isObservableObject,
  makeAutoObservable,
  observable,
  reaction,
  toJS,
} from "mobx";
import { createViewModel, IViewModel } from "mobx-utils";
import { toast } from "src/components/shared/Toaster";
import { changeEmail, changePassword, changeTgHandler } from "src/api/userManager/usersAPI";
import { setData } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { FormDataKeys, FormErrors, FormValidation } from "src/helpers/forms/types";
import { ObjectPathValue } from "src/helpers/forms/types/NestedObject";
import { NewUser, User } from "src/modules/userManager";
import { email, required, validateData } from "src/validation-schemas";
import { INITIAL_USER } from ".";

export interface EditUserProvider {
  getUsersList: () => Promise<void>;
  getUserByName(name: string): User;
}

export type EditUser = Pick<NewUser, "email" | "password" | "tg_handler">;
type PasswordUser = User & Pick<EditUser, "password">;

type EditUserKeys = FormDataKeys<EditUser>;

export const INITIAL_EDIT_USER: EditUser = {
  email: "",
  password: "",
  tg_handler: "",
};

const INITIAL_PASSWORD_USER: PasswordUser = {
  ...INITIAL_USER,
  password: "",
};

export default class EditUserStore {
  private _isLoading = false;

  private _currentEditUser!: EditUser & IViewModel<EditUser>;

  private _currentUser!: PasswordUser;

  private _isShown = false;

  private _currentEditUserName = "";

  errors: FormErrors<EditUser> = {};

  validation: FormValidation<EditUser> = {
    password: required(),
    email: email(),
    tg_handler: required(),
  };

  private _provider: EditUserProvider;

  private _isShownReaction: IReactionDisposer;

  private _currentUserChangedReaction: IReactionDisposer;

  constructor(provider: EditUserProvider) {
    this._provider = provider;

    this._clearCurrentUser();

    makeAutoObservable(this);

    this._isShownReaction = reaction(
      () => this.isShown,
      (isShown) => {
        if (!isShown) {
          this._resetForm();
        }
      }
    );

    this._currentUserChangedReaction = reaction(
      () => this._currentPasswordUser,
      (currentPasswordUser) => {
        this._setCurrentUser(currentPasswordUser);
      }
    );
  }

  private _setCurrentEditUser = (editUser: EditUser, userChanged: boolean) => {
    if (this._currentEditUser && !userChanged) {
      this._updateCurrentEditUser(editUser);
    } else {
      this._initCurrentEditUser(editUser);
    }
  };

  private _initCurrentEditUser = (editUser: EditUser) => {
    const observableEditUser = isObservableObject(editUser) ? editUser : observable(editUser);
    this._currentEditUser = createViewModel(observableEditUser);
  };

  private _updateCurrentEditUser = (savedUser: EditUser) => {
    const unsavedChanges = this._currentEditUser.changedValues;
    this._initCurrentEditUser(savedUser);
    this._reapplyChanges(unsavedChanges);
  };

  private _reapplyChanges = (
    changes: Map<EditUserKeys, ObjectPathValue<EditUser, EditUserKeys>>
  ) => {
    changes.forEach((val, key) => {
      this._setData(key, val);
    });
  };

  get currentEditUser(): EditUser {
    return this._currentEditUser;
  }

  private _setCurrentUser = (user: PasswordUser) => {
    const userChanged = this._currentUser?.name !== user.name;
    this._currentUser = user;
    this._setCurrentEditUser(this._currentUser, userChanged);
  };

  private _clearCurrentUser = () => {
    this._setCurrentUser(INITIAL_PASSWORD_USER);
  };

  get currentUser(): User {
    return this._currentUser;
  }

  private get _currentPasswordUser(): PasswordUser {
    const currentSavedUser = this._provider.getUserByName(this._currentEditUserName);
    return Object.assign(toJS(currentSavedUser), {
      password: "",
    });
  }

  get isLoading() {
    return this._isLoading;
  }

  private _setIsLoading = (loading: boolean) => {
    this._isLoading = loading;
  };

  private _setCurrentEditUserName(userName: string) {
    this._currentEditUserName = userName;
  }

  private _setIsModalShow(isShown: boolean) {
    this._isShown = isShown;
  }

  get isShown() {
    return this._isShown;
  }

  openModal = (userName: string) => {
    this._setIsModalShow(true);
    this._setCurrentEditUserName(userName);
  };

  closeModal = () => {
    this._setIsModalShow(false);
    this._setCurrentEditUserName("");
  };

  savedStatus = (key: Exclude<EditUserKeys, "is_active">) =>
    !this._currentEditUser.isPropertyDirty(key);

  private _setData = <K extends EditUserKeys>(key: K, value: ObjectPathValue<EditUser, K>) => {
    setData(this._currentEditUser, key, value);
  };

  getHandler =
    (key: Exclude<EditUserKeys, "is_active">) => (e: React.ChangeEvent<HTMLInputElement>) => {
      switch (key) {
        default: {
          this._setData(key, String(getChangeEventValue(e, true)));
        }
      }
    };

  private _validate = (...validateKeys: EditUserKeys[]) => {
    const keysToValidate = validateKeys.length ? validateKeys : undefined;
    return validateData(this.validation, this._currentEditUser, this.errors, keysToValidate);
  };

  saveTgHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this._validate("tg_handler");

    if (valid) {
      this._setIsLoading(true);
      try {
        const { isError } = await changeTgHandler(
          this.currentUser.name,
          this._currentEditUser.tg_handler
        );
        if (!isError) {
          toast.success("Tg handler changed successfully");
          this._provider.getUsersList();
        }
      } finally {
        this._setIsLoading(false);
      }
    }
  };

  savePassword = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this._validate("password");

    if (valid) {
      this._setIsLoading(true);

      try {
        const { isError } = await changePassword(
          this.currentUser.name,
          this._currentEditUser.password
        );
        if (!isError) {
          toast.success("Password changed successfully");
          this._setData("password", "");
          this._provider.getUsersList();
        }
      } finally {
        this._setIsLoading(false);
      }
    }
  };

  saveEmail = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this._validate("email");

    if (valid) {
      this._setIsLoading(true);

      try {
        const { isError } = await changeEmail(this.currentUser.name, this._currentEditUser.email);
        if (!isError) {
          toast.success("Email changed successfully");
          this._provider.getUsersList();
        }
      } finally {
        this._setIsLoading(false);
      }
    }
  };

  private _resetForm = () => {
    this._clearCurrentUser();
    this.errors = {};
  };

  destroy() {
    this._isShownReaction();
    this._currentUserChangedReaction();
  }
}
