import { CSSProperties } from "react";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { noOp } from "src/helpers/utils";
import { Loader } from "src/components/shared/Loader";
import { MobilePNLV2, MobilePNLV2Props } from "src/components/shared/PNLV2/MobilePNLV2";
import {
  DateTimeRangePickerSelector,
  DateTimeRangePickerSelectorProps,
} from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { EMPTY_DATE_TIME_RANGE } from "src/components/shared/DatePickers/shared/utils/valueManager";
import { PNLWithCharts, PNLWithChartsProps } from "./PNLWithCharts";
import * as styles from "./style";

interface DateTimeRangePicker extends Partial<DateTimeRangePickerSelectorProps> {}

export type LayoutPNLProps<T extends boolean> = T extends true
  ? PNLWithChartsProps
  : MobilePNLV2Props;

export interface LayoutCEXPNLProps<T extends boolean> {
  showPicker?: boolean;
  showCharts?: T;
  loading?: boolean;
  className?: string;
  style?: CSSProperties;
  rangePickerProps?: DateTimeRangePicker;
  pnlProps: LayoutPNLProps<T>;
}

export const LayoutCEXPNL = <T extends boolean>({
  showPicker,
  showCharts,
  loading,
  className,
  style,
  rangePickerProps,
  pnlProps,
}: LayoutCEXPNLProps<T>) => {
  const rootProps = { className, style };

  const preparedRangePickerProps = {
    value: EMPTY_DATE_TIME_RANGE,
    onChange: noOp,
    maxDate: getCurrentDayjs(),
    disableFuture: true,
    ...rangePickerProps,
  };

  const preparedPnlProps = { loading, ...pnlProps };

  return (
    <styles.Container {...rootProps}>
      {showPicker && <DateTimeRangePickerSelector {...preparedRangePickerProps} />}

      {showCharts ? (
        <PNLWithCharts {...(preparedPnlProps as PNLWithChartsProps)} />
      ) : (
        <>
          <MobilePNLV2 {...preparedPnlProps} />
          <Loader show={loading} />
        </>
      )}
    </styles.Container>
  );
};
