import { localStringTimeToUTCUnix } from "src/helpers/dateUtils";

export const getChangeEventValue = (
  e: React.ChangeEvent<HTMLInputElement>,
  ignoreType?: boolean
) => {
  if (ignoreType) return e.target.value;
  switch (e.target.type) {
    case "range":
    case "number":
    case "radio": {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    case "checkbox": {
      return e.target.checked;
    }
    case "datetime-local": {
      return localStringTimeToUTCUnix(e.target.value);
    }
    case "text":
    default: {
      return e.target.value;
    }
  }
};

export const getChangeEventValueRangePer = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = e.target;
  const { max } = e.target;
  if (Boolean(max) && +value > +max) {
    return null;
  }
  if (value !== "") {
    const newValue = String((+value / 100).toFixed(4));
    return newValue;
  }
  return String(value);
};

export const getChangeEventValueNumb = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.value !== "") {
    return +e.target.value;
  }
  return e.target.value;
};
