import styled from "styled-components";
import { SwitchTabs, TabContent } from "../../../shared";

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StyledSwitchTabs = styled(SwitchTabs)`
  min-width: 180px;
`;

export const ModuleContainer = styled(TabContent)``;
