import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { LiquidityTabContext } from "src/context/CEX/LiquidityTab/LiquidityTabProvider";
import { stringDateToUnix } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import ActiveGrid, { IActiveGrid } from "src/state/CEX/liquidityTab/ActiveGrid";
import { LiquidityTabProps } from "..";
import { Warning } from "../../../../shared/Prompts/Warning";
import { ErrorsTablePanel } from "../ErrorsTablePanel";
import { GridForm as GridFormComponent } from "../GridForm";
import { BuySellStats, CurrentStats, ProfitStats } from "../GridStats";
import Status from "../Status";
import { Panel } from "../base/Panel";
import { SubmitButtonGroup } from "../base/SubmitButtonGroup";
import GridToggleButton from "./GridToggleButton";
import * as styles from "./style";

interface ActiveGridViewProps extends LiquidityTabProps {}

const GRID_FORM_ID = "GRID_FORM_EDIT";

const ActiveGridView = observer(
  ({ botInfo: { market = "", party }, abilityName }: ActiveGridViewProps) => {
    const liquidityTabState = useLateInitContext(LiquidityTabContext);
    const state: IActiveGrid = useLocalStore(ActiveGrid, liquidityTabState);

    const name = market.split("_").join(" ");

    return (
      <>
        <styles.InfoStatus
          title={name}
          after={
            <Status
              status={state.form.data.state}
              updateTime={stringDateToUnix(state.grid.updatedAt)}
            />
          }
        />

        <styles.Content>
          <ProfitStats stats={state.stats} style={{ gridArea: "profit" }} />

          <BuySellStats stats={state.stats} style={{ gridArea: "buy_sell" }} />

          <CurrentStats stats={state.stats} form={state.form} style={{ gridArea: "current" }} />

          <Panel title="Settings" style={{ gridArea: "settings" }}>
            <GridFormComponent state={state} id={GRID_FORM_ID} />
          </Panel>

          <ErrorsTablePanel
            count={state.grid.errorsInfo.count}
            errors={state.gridErrors}
            style={{ gridArea: "errors" }}
          />

          <Warning
            text="All orders except Grid orders from selected Account will be canceled."
            style={{ gridArea: "warning" }}
          />

          <SubmitButtonGroup
            abilityName={abilityName}
            party={party}
            formId={GRID_FORM_ID}
            style={{ gridArea: "buttons" }}
          >
            <GridToggleButton state={state} />
          </SubmitButtonGroup>

          <Loader show={state.loading} />
        </styles.Content>
      </>
    );
  }
);

export default ActiveGridView;
