import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  max-height: 200px;

  display: flex;
  flex-direction: column;

  gap: 20px;

  overflow-y: auto;

  ${ScrollSize}
`;
