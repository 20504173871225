import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import WithdrawGasStore, {
  WithdrawGasParameters,
} from "src/state/DEXV2/DEXV2Stats/GasWallets/WithdrawGasStore";

export const WithdrawGasContext = createLateInitContext<WithdrawGasStore>();

interface WithdrawGasProviderProps extends ChildrenProps, WithdrawGasParameters {}

export const WithdrawGasProvider = ({ children, ...props }: WithdrawGasProviderProps) => {
  const walletsStore = useLocalStore(WithdrawGasStore, props);

  return <WithdrawGasContext.Provider value={walletsStore}>{children}</WithdrawGasContext.Provider>;
};
