import { LinearProgress } from "src/components/shared/Progress";
import { formatPercent } from "src/helpers/string";
import CEXDashboardIcons from "src/icons/CEXDashboardIcons";
import { KPICardProps } from "../../../../shared/KPICard";
import * as styles from "../shared/style";

const getIndexImpactInfoText = (party: string) =>
  `Gotbit impact index indicates what share Gotbit holds in the ${party} markets. It takes into account trading volume, number of trades and provided liquidity.`;

export interface ImpactCardProps
  extends Omit<KPICardProps, "title" | "caption" | "subtitle" | "kpi"> {
  impact: string;
  party?: string;
}

export const ImpactCard = ({ impact, party = "party", ...props }: ImpactCardProps) => {
  const progressPercent = +impact;
  const impactPercent = formatPercent(impact);

  const impactInfoText = getIndexImpactInfoText(party);

  return (
    <styles.StyledKPICard
      title="Gotbit impact index:"
      info={impactInfoText}
      titleIcon={<CEXDashboardIcons.Percent />}
      kpi={
        <styles.KPIWrapper>
          <styles.KPILabel>{impactPercent}</styles.KPILabel>
          <LinearProgress value={progressPercent} />
        </styles.KPIWrapper>
      }
      {...props}
    />
  );
};
