import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import HashesStore from "src/state/DEXV2/DEXV2Stats/Hashes";
import { DEXV2BotContext } from "../../DEXV2Bots/DEXV2Bot";

export const HashesContext = createLateInitContext<HashesStore>();

type HashesProviderProps = ChildrenProps;

export const HashesProvider = ({ children }: HashesProviderProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const store: HashesStore = useLocalStore(HashesStore, botState);

  return <HashesContext.Provider value={store}>{children}</HashesContext.Provider>;
};
