import styled from "styled-components";
import { MainSettingsPanelMixin, SideSettingsPanelMixin } from "../Volume/style";
import { LimitPanel as BaseLimitPanel } from "./LimitPanel";
import { TradePanel as BaseTradePanel } from "./TradePanel";

export const LimitPanel = styled(BaseLimitPanel)`
  /* ${MainSettingsPanelMixin} */
`;

export const TradePanel = styled(BaseTradePanel)`
  /* ${SideSettingsPanelMixin} */
`;
