import { makeAutoObservable, runInAction } from "mobx";
import { getAllApiKeys } from "src/api/bots/CEX/apiKeys";
import { getDecimals } from "src/api/bots/CEX/settings";
import { joinPair } from "src/helpers/botName";
import { getSelectorList } from "src/helpers/forms/selectors";
import { toRounding } from "src/helpers/rounding";
import { calcAvgPrice } from "src/helpers/trading";
import { filterCallback, keys, noOp } from "src/helpers/utils";
import { BotAccountName, BotAccountsMap } from "src/modules/accounts";
import { Pair, Trade } from "src/modules/exchange/trade";
import { PairDecimals } from "src/modules/settings";
import { IExchange } from "src/modules/shared";
import { getClients, getPairs } from "../../../api/bots/CEX/exchange";
import {
  allApiKeysResponseToBotAccountsMap,
  filterBaseNameAccounts,
  sortAccounts,
} from "../CEXApiKeys/AccountsBindings";
import { CEXSettingsStore, EMPTY_DECIMALS } from "../CEXSettings";
import AccountHistoryStore from "./AccountHistoryStore";
import BalancesStore from "./BalancesStore";
import OrderBookStore from "./OrderBook/OrderBookStore";
import SettingsProvider from "./TVChart/SettingsProvider";
import TradeHistoryStore from "./TradeHistory/TradeHistoryStore";
import VolumeInfoStore from "./VolumeInfoStore";
import TerminalSettingsStore from "./shared/TerminalSettingsStore";
import LiquidityStore from "./trade/LiquidityStore";
import BuySellStore from "./trade/OpenOrders/BuySellOrders/BuySellStore";
import { FloatingOrdersStore } from "./trade/OpenOrders/FloatingOrdersStore";
import LimitOrdersStore from "./trade/OpenOrders/LimitOrdersStore";
import StopOrdersStore from "./trade/OpenOrders/StopOrdersStore";
import TransferStore from "./trade/TransferStore";
import VolumeStore from "./trade/VolumeStore";

export const MAIN_PAIRS = ["USDT_BTC", "USDT_ETH", "USDT_USDC", "USDT_SOL", "USDT_AVAX"];

type UpdCb = () => void;

interface TerminalUpdHandlers {
  updBalances: UpdCb;
  updSettings: UpdCb;
  updChart: UpdCb;
  updLimitOrders: UpdCb;
  updStopOrders: UpdCb;
  updOrderBook: UpdCb;
  updTradeHistory: UpdCb;
  updFees: UpdCb;
  updVolumes: UpdCb;
  updBindings: UpdCb;
  updFloatingOrders: UpdCb;
}

type UpdHandlersKeys = keyof TerminalUpdHandlers;

type ArrUpdKeys = Array<keyof TerminalUpdHandlers>;

interface PairPartsProps {
  quote: string;
  base: string;
}

class ExchangeStore {
  market: string = "";

  private _party = "";

  private _pair: string = "";

  private _pairs: Pair[] = [];

  private _searchPair: string = "";

  private _botUUID: string = "";

  // decimals from exchange
  private _exchDecimals: PairDecimals = EMPTY_DECIMALS;

  _accounts = {} as BotAccountsMap;

  selectedAccount: BotAccountName = "spread";

  totalPrecision = 0;

  action = false;

  wsSupport: boolean = false;

  // list of requests by account
  private _accountRequests: ArrUpdKeys = [
    "updBalances",
    "updLimitOrders",
    "updStopOrders",
    "updFees",
    "updVolumes",
  ];

  private _reqDependsSocket: ArrUpdKeys = [
    "updStopOrders",
    "updChart",
    "updOrderBook",
    "updTradeHistory",
  ];

  private _reqNotDependsSocket: ArrUpdKeys = [
    "updSettings",
    // "updBindings",
    "updBalances",
    "updLimitOrders",
    "updFees",
    "updVolumes",
  ];

  accountUpdModule: ArrUpdKeys = [
    "updBalances",
    "updChart",
    "updLimitOrders",
    "updStopOrders",
    "updOrderBook",
    "updTradeHistory",
    "updFees",
    "updVolumes",
  ];

  limitTransactionModule: ArrUpdKeys = [
    "updBalances",
    "updOrderBook",
    "updChart",
    "updTradeHistory",
    "updLimitOrders",
  ];

  stopTransactionModule: ArrUpdKeys = [
    "updBalances",
    "updOrderBook",
    "updChart",
    "updTradeHistory",
    "updStopOrders",
  ];

  floatingTransactionModule: ArrUpdKeys = [
    "updBalances",
    "updOrderBook",
    "updChart",
    "updTradeHistory",
    "updFloatingOrders",
  ];

  settingsPageModule: ArrUpdKeys = ["updBalances", "updTradeHistory"];

  updHandlers: TerminalUpdHandlers = {
    updBalances: noOp,
    updSettings: noOp,
    updChart: noOp,
    updLimitOrders: noOp,
    updStopOrders: noOp,
    updOrderBook: noOp,
    updTradeHistory: noOp,
    updFees: noOp,
    updVolumes: noOp,
    updBindings: noOp,
    updFloatingOrders: noOp,
  };

  settingsState: CEXSettingsStore;

  tradeHistoryState: TradeHistoryStore;

  orderBookState: OrderBookStore;

  limitOrdersState: LimitOrdersStore;

  stopOrdersState: StopOrdersStore;

  floatingOrdersState: FloatingOrdersStore;

  balancesState: BalancesStore;

  buySellState: BuySellStore;

  transferState: TransferStore;

  liquidityState: LiquidityStore;

  volumeState: VolumeStore;

  volumeInfoState: VolumeInfoStore;

  accHistoryState: AccountHistoryStore;

  terminalSettingsState: TerminalSettingsStore;

  tvChartSettingsState: SettingsProvider;

  constructor(settings: CEXSettingsStore) {
    this.terminalSettingsState = new TerminalSettingsStore(this);
    this.tvChartSettingsState = new SettingsProvider(this);
    this.settingsState = settings;

    // this.setUpdHandlers("updSettings", this.settingsState.getData);

    this.tradeHistoryState = new TradeHistoryStore(this);
    this.orderBookState = new OrderBookStore(this);

    this.limitOrdersState = new LimitOrdersStore(this);
    this.stopOrdersState = new StopOrdersStore(this);
    this.floatingOrdersState = new FloatingOrdersStore(this);

    this.balancesState = new BalancesStore(this);
    this.buySellState = new BuySellStore(this);

    this.transferState = new TransferStore(this);
    this.liquidityState = new LiquidityStore(this);
    this.volumeState = new VolumeStore(this);

    this.volumeInfoState = new VolumeInfoStore(this);
    this.accHistoryState = new AccountHistoryStore(this);

    makeAutoObservable(this);
  }

  get party() {
    return this._party;
  }

  get botUUID() {
    return this._botUUID;
  }

  get pair() {
    return this._pair;
  }

  get quote(): string {
    return this.pair.split("_")[0];
  }

  get base(): string {
    return this.pair.split("_")[1];
  }

  get exchange(): string {
    return this.market.split("_")[2];
  }

  get lastTrade(): Trade | null {
    if (this.tradeHistoryState.lastTrade) return this.tradeHistoryState.lastTrade;

    return {
      side: "",
      time: "",
      amount: "",
      price: `${this.customLastPrice}`,
    };
  }

  get customLastPrice() {
    if (this.orderBookState.maxBuy && this.orderBookState.minSell) {
      const avgSpread = (this.orderBookState.minSell + this.orderBookState.maxBuy) / 2;

      return toRounding(avgSpread, this.orderBookState.pricePrecision);
    }

    return "";
  }

  get DTPMin() {
    return this.settingsState.DTPMin;
  }

  get DTPMax() {
    return this.settingsState.DTPMax;
  }

  get avgPrice() {
    if (this.orderBookState.maxBuy && this.orderBookState.minSell && this.DTPMin && this.DTPMax)
      return parseFloat(
        calcAvgPrice(
          this.orderBookState.maxBuy,
          this.orderBookState.minSell,
          this.DTPMin,
          this.DTPMax,
          this.pricePrecision
        )
      );

    return "";
  }

  // decimals from exchange for OrderBook
  get exchPricePrecision() {
    return this._exchDecimals.decimals.price;
  }

  // decimals from exchange for OrderBook
  get exchAmountPrecision() {
    return this._exchDecimals.decimals.amount;
  }

  get amountPrecision() {
    if (this.isOriginPair && this.settingsState.data.settings.decimals.amount !== "")
      return this.settingsState.data.settings.decimals.amount;

    if (this.exchAmountPrecision) return this.exchAmountPrecision;

    return this.orderBookState.calculatedAmountPrecision;
  }

  get pricePrecision() {
    if (this.isOriginPair && this.settingsState.data.settings.decimals.price !== "")
      return this.settingsState.data.settings.decimals.price;

    if (this.exchPricePrecision) return this.exchPricePrecision;

    return this.orderBookState.calculatedPricePrecision;
  }

  get accounts() {
    const nonInfoAccounts = filterBaseNameAccounts(this._accounts, ["info"]);

    const sortedAccounts = keys(nonInfoAccounts).sort(sortAccounts);

    return getSelectorList(sortedAccounts);
  }

  get originBase() {
    const [, base] = this.market.split("_");

    return base;
  }

  get originQuote() {
    const [quote] = this.market.split("_");

    return quote;
  }

  private get pairs() {
    return this._pairs
      .filter(({ base, quote }) => this._pairsFilter({ quote, base }))
      .sort((a, b) => this._baseAlphabeticalSort(a, b))
      .map(({ base, quote }) => this._joinPairForSelectorList(quote, base))
      .sort((a, b) => this._pairsSort(a.value, b.value));
  }

  get originPair() {
    const [asset, token] = this.market.split("_");
    return `${asset}_${token}`;
  }

  get isOriginPair() {
    return this.pair === this.originPair;
  }

  get currentPath() {
    return `${this.pair}_${this.exchange}`;
  }

  get shortPairsList() {
    return this.pairs.filter(({ value }) => filterCallback(value, this._searchPair)).slice(0, 50);
  }

  get updKeys() {
    return Object.keys(this.updHandlers) as ArrUpdKeys;
  }

  get currentAccID() {
    const currentAcc = this._accounts[this.selectedAccount];

    if (currentAcc) {
      return currentAcc.uuid;
    }

    return "";
  }

  get infoAcc() {
    return this._accounts.info?.uuid;
  }

  setParty = (party: string) => {
    this._party = party;
  };

  setUUID = (bot_uuid: string) => {
    this._botUUID = bot_uuid;
  };

  setNewPair = (value: string) => {
    const [quote, base] = value.split("_");

    const newPair: Pair = {
      base,
      quote,
      minAmountBase: "",
      minAmountQuote: "",
    };

    this._pairs = [newPair, ...this._pairs];
  };

  setUpdHandlers = (key: UpdHandlersKeys, cb: () => void) => {
    this.updHandlers[key] = cb;
  };

  private _joinPairForSelectorList = (quote: string, base: string) => {
    const pair = joinPair(quote, base);

    return {
      value: pair,
      label: pair,
    };
  };

  private _baseAlphabeticalSort = (a: Pair, b: Pair) => {
    const nameA = a.base.toLowerCase();
    const nameB = b.base.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  private _pairsSort = (a: string, b: string) => {
    const firstCheck = this._priorityCheck(a);
    const secondCheck = this._priorityCheck(b);

    return secondCheck - firstCheck;
  };

  private _pairsFilter = ({ quote, base }: PairPartsProps) => {
    const pair = joinPair(quote, base);

    return MAIN_PAIRS.includes(pair) || base === this.originBase;
  };

  private _priorityCheck = (pair: string) => {
    const [, base] = pair.split("_");

    if (pair === this.originPair) return 3;

    if (base === this.originBase) return 2;

    if (MAIN_PAIRS.includes(pair)) return 1;

    return 0;
  };

  setSearchPair = () => (value: string) => {
    runInAction(() => {
      this._searchPair = value;
    });
  };

  downloadDefaultData = async () => {
    // first of all, accounts are requested for subsequent requests of the trade terminal
    await this._fetchAccounts(this.botUUID);

    this._getPairDecimals();

    this._downloadSocketSupRequest();

    this._getPairs();

    this._callUpdHandlers(this._reqNotDependsSocket);
  };

  downloadSettingsPageData = async () => {
    await this._fetchAccounts(this.botUUID);

    this.downloadDataModule(this.settingsPageModule);
    this.orderBookState.downloadOrderBook();
  };

  downloadData = () => {
    this._callUpdHandlers(this.updKeys);
  };

  updAllData = () => {
    this._callUpdHandlers(this.updKeys);
  };

  downloadDataModule = (keys: ArrUpdKeys) => {
    this._callUpdHandlers(keys);
  };

  private _downloadSocketSupRequest = async () => {
    // before sending requests to the exchange,
    // we receive information about support for websocket connections
    await this._checkWebsocketSupport();

    this._callUpdHandlers(this._reqDependsSocket);
  };

  private _callUpdHandlers = (keys: ArrUpdKeys) => {
    keys.forEach((key) => {
      const requestAllowed = this._checkUpdCredential(key);
      if (requestAllowed) this.updHandlers[key]();
    });
  };

  private _checkUpdCredential = (key: UpdHandlersKeys) => {
    const isAccRequest = this._accountRequests.includes(key);

    if (isAccRequest) {
      if (!this.accounts.length) return false;
    }

    return true;
  };

  updLimitTradingData = () => {
    this.downloadDataModule(this.limitTransactionModule);
  };

  updStopTradingData = () => {
    this.downloadDataModule(this.stopTransactionModule);
  };

  updFloatingTradingData = () => {
    this.downloadDataModule(this.floatingTransactionModule);
  };

  updAccData = () => {
    this.downloadDataModule(this.accountUpdModule);
  };

  setMarket = (botName: string) => {
    const [asset, token] = botName.split("_");
    this._pair = `${asset}_${token}`;
    this.market = botName;
  };

  changePair = async (pair: string) => {
    this._pair = pair;

    // get exch pair decimals before get all data for correct rounding
    await this._getPairDecimals();

    this.updAllData();
  };

  switchAccount = (account: BotAccountName): void => {
    this.selectedAccount = account;

    // reset selected orders for switch account
    this.limitOrdersState.resetSelectOrders();
    this.stopOrdersState.resetSelectOrders();

    this.downloadDataModule(this.accountUpdModule);
  };

  switchAccountSettingsPage = (account: BotAccountName): void => {
    this.selectedAccount = account;

    this.updHandlers.updBalances();
  };

  setWSocketSupport = (bool: boolean) => {
    this.wsSupport = bool;

    if (!this.wsSupport) {
      this.terminalSettingsState.lockWSMode();
    }
  };

  convertBase = (balance: number, price: number) => {
    if (this.originPair) return `${price * balance}`;
    return "";
  };

  private _getPairs = async () => {
    try {
      const {
        data: { data },
        isError,
      } = await getPairs(this.exchange);

      if (!isError) {
        runInAction(() => {
          this._pairs = data;
        });
      }
    } catch {
      runInAction(() => {
        this._pairs = [];
      });
    }
  };

  private _fetchAccounts = async (bot_uuid: string) => {
    try {
      const { data, isError } = await getAllApiKeys(bot_uuid);

      if (!isError) {
        const accounts = allApiKeysResponseToBotAccountsMap(data);
        runInAction(() => {
          this._accounts = accounts;
        });

        if (!this._accounts.spread) {
          const nonInfoAccounts = filterBaseNameAccounts(this._accounts, ["info"]);
          const account = keys(nonInfoAccounts)[0];
          if (account) {
            runInAction(() => {
              this.selectedAccount = account;
            });
          }
        }
      }
    } catch {
      runInAction(() => {
        this._accounts = {};
      });
    }
  };

  private _checkWebsocketSupport = async () => {
    try {
      const { data, isError } = await getClients();

      if (!isError) {
        const exchanges = data.data;
        const exchInfo = exchanges.find((el: IExchange) => el.id === this.exchange);

        this.setWSocketSupport(exchInfo?.websocket ?? false);
      }
    } catch {
      this.setWSocketSupport(false);
    }
  };

  private _getPairDecimals = async () => {
    try {
      const { data, isError } = await getDecimals("", this._pair, this.exchange);

      if (!isError) {
        runInAction(() => {
          this._exchDecimals.decimals = data.data;
        });
      } else {
        runInAction(() => {
          this._exchDecimals = EMPTY_DECIMALS;
        });
      }
    } catch {
      runInAction(() => {
        this._exchDecimals = EMPTY_DECIMALS;
      });
    }
  };

  destroy = () => {
    this.orderBookState.destroy();
    this.tradeHistoryState.destroy();
  };
}

export default ExchangeStore;
