import { makeAutoObservable, runInAction } from "mobx";
import {
  GetFloatingOrderStatusRequest,
  cancelFloatingOrder,
  getFloatingOrderStatus,
  getFloatingOrders,
} from "src/api/bots/CEX/exchange";
import { formatElapsedTime, stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { logError } from "src/helpers/network/logger";
import { calcRoundingValues, toRounding } from "src/helpers/rounding";
import { CreatedFloatingOrder, FloatingOrderStatus } from "src/modules/exchange/trade";
import TableStore, { TableOrders } from "src/state/Table";
import ExchangeStore from "../..";
import GetStatusOrderStore from "./GetStatusOrderStore";

export class FloatingOrdersStore implements TableOrders {
  tableState: TableStore;

  getStatusState: GetStatusOrderStore<FloatingOrderStatus>;

  private _mainState: ExchangeStore;

  private _orders: CreatedFloatingOrder[] = [];

  private _isLoading = false;

  constructor(state: ExchangeStore) {
    this._mainState = state;

    this.tableState = new TableStore(this, { showCancelOrderConsent: true });

    this.getStatusState = new GetStatusOrderStore(this);

    this._mainState.setUpdHandlers("updFloatingOrders", this.downloadData);

    makeAutoObservable(this);
  }

  get items() {
    const amountPrecision = this._orderAmountPrecision;

    const pricePrecision = this._orderPricePrecision;

    return this._orders
      .filter(({ pair }) => pair === this._pair)
      .map(({ price_current, amount, side, id, updated_at, created_at, ...otherParams }) => ({
        ...otherParams,
        id,
        amount: toRounding(parseFloat(amount), amountPrecision),
        price_current: toRounding(parseFloat(price_current), pricePrecision),
        side,
        updated_at: unixToDateFormat(stringDateToUnix(updated_at), "FullDate"),
        created_at: formatElapsedTime(stringDateToUnix(created_at)),
      }));
  }

  get isLoading() {
    return this._isLoading;
  }

  get ordersCount() {
    return this._orders.length;
  }

  get buyOrderCount() {
    return this._orders.filter((el) => el.side === "BUY").length;
  }

  get sellOrderCount() {
    return this._orders.filter((el) => el.side === "SELL").length;
  }

  get orderInfo(): FloatingOrderStatus | undefined {
    const orderStatus = this.getStatusState.selectOrderStatus;

    if (orderStatus) {
      const filledOrders = orderStatus.filled_orders.map((el) => ({
        ...el,
        created_at: unixToDateFormat(stringDateToUnix(el.created_at), "FullDate"),
      }));

      return {
        ...orderStatus,
        created_at: unixToDateFormat(stringDateToUnix(orderStatus.created_at), "FullDate"),
        updated_at: unixToDateFormat(stringDateToUnix(orderStatus.updated_at), "FullDate"),
        filled_orders: filledOrders,
      };
    }

    return undefined;
  }

  get orderErrors() {
    const orderStatus = this.getStatusState.selectOrderStatus;

    return orderStatus?.error || "";
  }

  get viewOrderStatusKeys() {
    if (this.orderInfo) return Object.keys(this.orderInfo).filter((key) => key !== "error");

    return [];
  }

  private get _pair() {
    return this._mainState.pair;
  }

  private get _currentAccUUID() {
    return this._mainState.currentAccID;
  }

  private get _orderAmountPrecision() {
    const amounts: string[] = [];

    this._orders.forEach(({ amount }) => {
      amounts.push(amount);
    });

    return calcRoundingValues(amounts);
  }

  private get _orderPricePrecision() {
    const prices: string[] = [];

    this._orders.forEach(({ price_current }) => {
      prices.push(price_current);
    });

    return calcRoundingValues(prices);
  }

  cancelRequest = async (selectItemsIds: string[]) => {
    this._algoCancelRequest(selectItemsIds);
  };

  cancelManyRequest = async (selectItemsIds: string[]) => {
    this._algoCancelRequest(selectItemsIds);
  };

  cancelAllRequest = async (selectItemsIds: string[]) => {
    this._algoCancelRequest(selectItemsIds);
  };

  downloadData = () => {
    if (this._mainState.wsSupport) this._getOrders(this._currentAccUUID, this._pair);
  };

  getOrder = async (id: string) =>
    this._getOrderStatus({
      account_uuid: this._currentAccUUID,
      order_id: id,
    });

  private _getOrderStatus = async (props: GetFloatingOrderStatusRequest) =>
    getFloatingOrderStatus(props);

  private _setOrders = (orders: CreatedFloatingOrder[]) => {
    this._orders = orders;
  };

  private _getOrders = async (accountUUID: string, pair: string) => {
    this._isLoading = true;

    try {
      const { isError, data } = await getFloatingOrders({ account_uuid: accountUUID, pair });

      if (!isError) {
        const { data: orders } = data;

        this._setOrders(orders);
      } else {
        this._setOrders([]);
      }
    } catch (error) {
      logError(error);
      this._setOrders([]);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  };

  private _algoCancelRequest = async (selectItems: string[]) => {
    this._isLoading = true;

    await Promise.all(
      selectItems.map((el) =>
        cancelFloatingOrder({
          account_uuid: this._currentAccUUID,
          order_id: el,
        })
      )
    )
      .then((data) => {
        this._mainState.updFloatingTradingData();

        return data.find((data) => data.isError === true);
      })
      .finally(() => {
        runInAction(() => {
          this._isLoading = false;
        });
      });
  };

  destroy = () => {};
}
