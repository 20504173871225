import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { BigNumber, BigNumberish, ethers } from "ethers";
import { DEXV2VaultType } from "src/api/bots/DEXV2/create";
import { PermissionResponse } from "src/api/bots/DEXV2/stats";
import {
  SwapExactTokensForTokensPermissionRequest,
  SwapTokensForExactTokensPermissionRequest,
  getSwapExactTokensForTokensPermission,
  getSwapTokensForExactTokensPermission,
} from "src/api/bots/DEXV2/swap";
import { toast } from "src/components/shared/Toaster";
import { IVaultLimitUpgradeable } from "src/contracts/vaults/v1/IVaultLimitUpgradeable";
import { IVaultVolumeUpgradeable } from "src/contracts/vaults/v1/IVaultVolumeUpgradeable";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { showSuccessMsg } from "src/helpers/message";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logDev } from "src/helpers/network/logger";
import { Mapper, OmitUnion, entries } from "src/helpers/utils";
import { SetLoading } from "../../../DEXV2Stats/GasWallets/WithdrawGasStore";
import { vaultTypeToOrder } from "../../../DEXV2Stats/Vaults/TransferStore";
import {
  SwapExactTokensForTokensParams as BaseSwapExactTokensForTokensParams,
  SwapTokensForExactTokensParams as BaseSwapTokensForExactTokensParams,
  ISwapVaultContract,
  OraclePermission,
} from "../../Version/SwapVaultContract";
import { formatCurrencyAmount, swapErrorToUserReadableMessage } from "../../utils";

type SwapExactTokensForTokensParams<TAmount, Deadline> = {
  method: "swapExactTokensForTokens";
} & BaseSwapExactTokensForTokensParams<TAmount, Deadline>;

type SwapTokensForExactTokensParams<TAmount, Deadline> = {
  method: "swapTokensForExactTokens";
  amountOut: TAmount;
  amountInMax: TAmount;
} & BaseSwapTokensForExactTokensParams<TAmount, Deadline>;

export type SwapParams<TAmount = CurrencyAmount<Token>, Deadline = BigNumber> =
  | SwapExactTokensForTokensParams<TAmount, Deadline>
  | SwapTokensForExactTokensParams<TAmount, Deadline>;

export type SwapCallInfoParams = OmitUnion<
  SwapParams<CurrencyAmount<Token>>,
  "path" | "useReceiver"
>;

export type SwapCallParams = SwapParams<BigNumberish, BigNumberish>;

type SwapMethodParams<
  M extends SwapParams["method"],
  P extends Pick<SwapParams, "method">,
> = Extract<P, { method: M }>;

export type SwapVaultContract = IVaultLimitUpgradeable | IVaultVolumeUpgradeable;

export const EMPTY_ORACLE_PERMISSION: OraclePermission = {
  permExpiresAt: 0,
  nonce: 0,
  signature: [],
};

type SwapCallInfoTokens = { in?: string; out?: string };
type SwapCallInfoItem = { label: string; value: string };
type SwapCallInfo = {
  tokens: SwapCallInfoTokens;
  items: SwapCallInfoItem[];
};

type TokenAmounts<K extends string> = Record<K, CurrencyAmount<Token>>;

export interface SwapPermissionParams {
  vaultType: DEXV2VaultType;
  sender: string;
  botId: string;
  withdrawer?: string;
}

export interface ISwapExecuteParams {
  vaultContract: ISwapVaultContract;
  swapParams: SwapParams;
  permissionParams: SwapPermissionParams;
  setLoading?: SetLoading;
}

export interface ISwapExecutor {
  swap: (params: ISwapExecuteParams) => Promise<ethers.ContractReceipt | undefined>;
}

export const permissionResponseToOraclePermission: Mapper<PermissionResponse, OraclePermission> = ({
  expire,
  nonce,
  signature,
}) => ({
  permExpiresAt: expire,
  nonce,
  signature: ethers.utils.isBytesLike(signature) ? signature : [],
});

export class SwapExecutorStore implements ISwapExecutor {
  private _getSwapInfoMessage = (info: SwapCallInfo) => {
    const {
      tokens: { in: tokenIn, out: tokenOut },
      items,
    } = info;
    const tokensMessage =
      tokenIn && tokenOut ? `Swapping ${tokenIn} to ${tokenOut}` : "Swapping tokens";

    const amountsMessage = items.map(({ value, label }) => `${label}: ${value}`);

    return [tokensMessage].concat(amountsMessage).filter(Boolean).join("\n");
  };

  private _getSwapInfoAmounts = <K extends string>(
    amounts: TokenAmounts<K>
  ): SwapCallInfoItem[] => {
    const infoAmounts = entries(amounts).map(([key, amount]) => ({
      label: key,
      value: formatCurrencyAmount(amount, true),
    }));
    return infoAmounts;
  };

  private _getSwapInfoTokens = (
    params: OmitUnion<SwapCallInfoParams, "deadline">
  ): SwapCallInfo => {
    switch (params.method) {
      case "swapExactTokensForTokens": {
        const { method, ...amounts } = params;
        const { amountIn, amountOutMin } = amounts;
        const tokens = {
          in: amountIn.currency.symbol,
          out: amountOutMin.currency.symbol,
        };
        return {
          tokens,
          items: this._getSwapInfoAmounts(amounts),
        };
      }
      case "swapTokensForExactTokens": {
        const { method, ...amounts } = params;
        const { amountInMax, amountOut } = amounts;
        const tokens = {
          in: amountInMax.currency.symbol,
          out: amountOut.currency.symbol,
        };
        return {
          tokens,
          items: this._getSwapInfoAmounts(amounts),
        };
      }
    }
  };

  private _getDeadlineInfo = ({
    deadline,
  }: Pick<SwapCallInfoParams, "deadline">): SwapCallInfoItem => {
    logDev(["deadline.toNumber()", deadline.toNumber()]);
    const deadlineDate = unixToDateFormat(deadline.toNumber(), "FullDate");
    return {
      label: "valid until",
      value: deadlineDate,
    };
  };

  private _showSwapCallInfo = (params: SwapCallInfoParams) => {
    const { deadline, ...otherParams } = params;
    const deadlineInfo = this._getDeadlineInfo({ deadline });
    const { items: tokensInfoItems, tokens } = this._getSwapInfoTokens({
      ...otherParams,
    });

    const info: SwapCallInfo = {
      tokens,
      items: [...tokensInfoItems, deadlineInfo],
    };

    const message = this._getSwapInfoMessage(info);

    showSuccessMsg(message);
  };

  private _swapCall = async (
    vaultContract: ISwapVaultContract,
    swapParams: SwapCallParams,
    permission: OraclePermission
  ) => {
    switch (swapParams.method) {
      case "swapExactTokensForTokens": {
        logDev(["swapExactTokensForTokens", swapParams, permission]);
        const { method, ...params } = swapParams;

        const tx = await vaultContract.swapExactTokensForTokens(params, permission);

        return tx;
      }
      case "swapTokensForExactTokens": {
        logDev(["swapTokensForExactTokens", swapParams, permission]);
        const { method, ...params } = swapParams;

        const tx = await vaultContract.swapTokensForExactTokens(params, permission);

        return tx;
      }
    }
  };

  private _getSwapExactTokensForTokensPermissionRequest = (
    swapParams: SwapMethodParams<"swapExactTokensForTokens", SwapParams>,
    permissionParams: SwapPermissionParams
  ): SwapExactTokensForTokensPermissionRequest => {
    const { deadline, amountIn, amountOutMin, path, useReceiver } = swapParams;

    const { vaultType, sender } = permissionParams;

    const fromVault = vaultTypeToOrder(vaultType);

    logDev(["_getSwapExactTokensForTokensPermissionRequest", amountIn, amountOutMin]);

    return {
      deadline: deadline.toNumber(),
      amount_in: amountIn.quotient.toString(),
      min_amount_out: amountOutMin.quotient.toString(),
      path,
      from_vault: fromVault,
      use_receiver: useReceiver,
      sender,
    };
  };

  private _getSwapExactTokensForTokensPermission = async (
    swapParams: SwapMethodParams<"swapExactTokensForTokens", SwapParams>,
    permissionParams: SwapPermissionParams
  ): Promise<PermissionResponse | null> => {
    const permissionRequestParams = this._getSwapExactTokensForTokensPermissionRequest(
      swapParams,
      permissionParams
    );
    const { isError, data } = await getSwapExactTokensForTokensPermission(
      permissionParams.botId,
      permissionRequestParams
    );
    if (!isError) {
      return data;
    }
    return null;
  };

  private _getSwapTokensForExactTokensPermissionRequest = (
    swapParams: SwapMethodParams<"swapTokensForExactTokens", SwapParams>,
    permissionParams: SwapPermissionParams
  ): SwapTokensForExactTokensPermissionRequest => {
    const { deadline, amountInMax, amountOut, path, useReceiver } = swapParams;

    const { vaultType, sender } = permissionParams;

    const fromVault = vaultTypeToOrder(vaultType);

    return {
      deadline: deadline.toNumber(),
      max_amount_in: amountInMax.quotient.toString(),
      amount_out: amountOut.quotient.toString(),
      path,
      from_vault: fromVault,
      use_receiver: useReceiver,
      sender,
    };
  };

  private _getSwapTokensForExactTokensPermission = async (
    swapParams: SwapMethodParams<"swapTokensForExactTokens", SwapParams>,
    permissionParams: SwapPermissionParams
  ): Promise<PermissionResponse | null> => {
    const permissionRequestParams = this._getSwapTokensForExactTokensPermissionRequest(
      swapParams,
      permissionParams
    );
    const { isError, data } = await getSwapTokensForExactTokensPermission(
      permissionParams.botId,
      permissionRequestParams
    );
    if (!isError) {
      return data;
    }
    return null;
  };

  private _getSwapPermission = async (
    swapParams: SwapParams,
    permissionParams: SwapPermissionParams
  ) => {
    const { sender, withdrawer } = permissionParams;
    const isSenderWithdrawer = sender === withdrawer;
    // permission not needed for withdrawer
    if (isSenderWithdrawer) {
      return EMPTY_ORACLE_PERMISSION;
    }

    let permission: PermissionResponse | null = null;
    switch (swapParams.method) {
      case "swapExactTokensForTokens": {
        permission = await this._getSwapExactTokensForTokensPermission(
          swapParams,
          permissionParams
        );
        break;
      }
      case "swapTokensForExactTokens": {
        permission = await this._getSwapTokensForExactTokensPermission(
          swapParams,
          permissionParams
        );
        break;
      }
    }

    const oraclePermission = permission && permissionResponseToOraclePermission(permission);

    return oraclePermission;
  };

  private _swapExactTokensForTokens = async (
    vaultContract: ISwapVaultContract,
    swapParams: SwapMethodParams<"swapExactTokensForTokens", SwapParams>,
    permission: OraclePermission
  ) => {
    const { amountIn, amountOutMin, ...params } = swapParams;
    const swapCallParams = {
      amountIn: amountIn.quotient.toString(),
      amountOutMin: amountOutMin.quotient.toString(),
      ...params,
    };

    const { method, deadline } = params;
    const swapCallInfoParams = {
      amountIn,
      amountOutMin,
      deadline,
      method,
    };

    const tx = await this._swapCall(vaultContract, swapCallParams, permission);

    this._showSwapCallInfo(swapCallInfoParams);

    return tx;
  };

  private _swapTokensForExactTokens = async (
    vaultContract: ISwapVaultContract,
    swapParams: SwapMethodParams<"swapTokensForExactTokens", SwapParams>,
    permission: OraclePermission
  ) => {
    const { amountInMax, amountOut, ...params } = swapParams;
    const swapCallParams = {
      amountInMax: amountInMax.quotient.toString(),
      amountOut: amountOut.quotient.toString(),
      ...params,
    };

    const { method, deadline } = params;
    const swapCallInfoParams = {
      amountInMax,
      amountOut,
      deadline,
      method,
    };

    const tx = await this._swapCall(vaultContract, swapCallParams, permission);

    this._showSwapCallInfo(swapCallInfoParams);

    return tx;
  };

  private _swap = async (
    vaultContract: ISwapVaultContract,
    swapParams: SwapParams,
    permissionParams: SwapPermissionParams
  ) => {
    const permission = await this._getSwapPermission(swapParams, permissionParams);
    if (!permission) return null;

    switch (swapParams.method) {
      case "swapExactTokensForTokens": {
        const tx = await this._swapExactTokensForTokens(vaultContract, swapParams, permission);
        return tx;
      }
      case "swapTokensForExactTokens": {
        const tx = await this._swapTokensForExactTokens(vaultContract, swapParams, permission);
        return tx;
      }
    }
  };

  swap = async ({
    vaultContract,
    swapParams,
    permissionParams,
    setLoading,
  }: ISwapExecuteParams) => {
    setLoading?.(true);
    try {
      const swapTx = await this._swap(vaultContract, swapParams, permissionParams);
      if (!swapTx) return;
      const receipt = await swapTx.wait();
      showSuccessMsg("Tokens swapped successfully!");
      return receipt;
    } catch (err) {
      const message = swapErrorToUserReadableMessage(err);
      if (message) {
        toast.error(message);
      }

      chainErrorHandler(err, { showToast: !message });
    } finally {
      setLoading?.(false);
    }
  };
}
