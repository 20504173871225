import { addAPILAbel } from "src/api/userManager/accountsAPI";
import { LabelParams } from ".";

export class APIStore implements LabelParams {
  private API_ID: number;

  constructor(id: number) {
    this.API_ID = id;
  }

  addLabel = (data: Record<string, string>, uuid: string) => addAPILAbel(uuid, this.API_ID, data);

  validate = () => !!this.API_ID;
}
