import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import * as styles from "./style";

export interface FooterPanelProps extends ComponentPropsWithoutRef<"div"> {
  onReset?: () => void;
  formId?: string;
}

export const FooterPanel = observer(({ formId, onReset, ...props }: FooterPanelProps) => (
  <styles.Footer {...props}>
    <OutlineButton onClick={onReset}>Reset</OutlineButton>

    <SubmitButton form={formId} />
  </styles.Footer>
));
