import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h5`
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0;
  padding-bottom: 5px;
  color: ${({ theme }) => theme.textColor};
  border-bottom: ${({ theme }) => theme.borders.solidGray};
`;

export const WrapperForTitle = styled.span`
  & > svg {
    width: 14px;
    height: 17px;
    margin-left: 10px;
    fill: ${({ theme }) => theme.textColor};
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 3px;
`;
