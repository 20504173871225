import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import { RoadMap } from "../RoadMap";

export const SettingsForm = styled.form`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  flex: 1 1 auto;

  gap: 37px;
  width: 100%;
`;

export const Container = styled.div`
  max-width: 680px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;
`;

export const ProfileSettingsFallback = styled(CrashPanel)`
  max-width: 560px;
  min-height: 500px;
`;

export const Content = styled.div`
  width: fit-content;

  display: flex;
  height: 560px;

  @media ${getMobileMediaQuery("500px")} {
    min-height: 560px;
    height: 100%;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 400px;

  gap: 18px;

  > * {
    width: 50%;
    max-width: 100%;
  }
`;

export const SettingsRoadMap = styled(RoadMap)`
  max-width: 500px;
`;
