import { observer } from "mobx-react-lite";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXSettingsContext } from "../../shared/context/SettingsProvider";
import { SubTitle, Wrapper } from "../style";
import * as styles from "./style";

export const Info = observer(() => {
  const state = useLateInitContext(DEXSettingsContext);
  return (
    <styles.Content>
      <SubTitle>Info</SubTitle>
      <Wrapper>
        <LabeledInput
          type="number"
          label="Tokens buy/sell for 1 hour"
          readOnly
          value={state.tokensBuySell || 0}
        />
        <LabeledInput type="number" label="Fee per day" readOnly value={state.fee || 0} />
      </Wrapper>
      <LabeledInput
        type="number"
        label="Token volume per day"
        readOnly
        value={state.tokenVolume || 0}
      />
    </styles.Content>
  );
});
