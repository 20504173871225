import { UseGoogleLoginOptionsImplicitFlow, useGoogleLogin } from "@react-oauth/google";
import { useCallback, useMemo } from "react";
import { toast } from "src/components/shared/Toaster";
import { useAppState } from "src/state";

export interface UseGoogleAuthParams {
  scopes?: string[];
}

type ImplicitFlowOptions = Required<Omit<UseGoogleLoginOptionsImplicitFlow, "flow">>;

export const useGoogleAuth = ({ scopes }: UseGoogleAuthParams = {}) => {
  const {
    googleAuthState: { setAccessToken, clearAccessToken, accessToken, isLoggedIn },
  } = useAppState();

  const scope = useMemo(() => scopes?.join(" ") ?? undefined, [scopes]);

  const handleError: ImplicitFlowOptions["onError"] = useCallback(
    ({ error_description }) => {
      toast.error(error_description);
      clearAccessToken();
    },
    [clearAccessToken]
  );

  const handleSuccess: ImplicitFlowOptions["onSuccess"] = useCallback(
    (response) => {
      setAccessToken(response.access_token);
    },
    [setAccessToken]
  );

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: handleError,
    scope,
  });

  return { accessToken, isLoggedIn, login };
};
