import { Input } from "src/components/shared/Forms/Inputs/Input/Input/style";
import styled from "styled-components";

export const InputContainer = styled.div``;

export const InputPage = styled(Input)`
  position: relative;

  width: 50px;
  max-height: 23px;

  padding: 9px 5px;

  text-align: center;

  border: 1px solid #d6d6d6;
  font-size: ${({ theme }) => theme.fontSizes["12px"]};

  :focus {
    box-shadow: none;
  }

  :invalid {
    box-shadow: ${({ theme }) => `0 0 0 1px ${theme.errorLabelColor}`};

    :focus {
      box-shadow: ${({ theme: { errorLabelColor } }) => `0 0 0 1px ${errorLabelColor}`};
    }
  }
`;
