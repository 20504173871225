import { useCallback, useRef, useState } from "react";

export interface UseControlledProps<T = unknown> {
  /**
   * Holds the component value when it's controlled.
   */
  controlled?: T;
  /**
   * Holds onChange when it's controlled.
   */
  onChange?: (newValue: T) => void;
  /**
   * The default value when uncontrolled.
   */
  default?: T;
}
export const useControlledValue = <T = unknown>({
  controlled,
  default: defaultProp,
  onChange: onControlledChange,
}: UseControlledProps<T>): [T, (newValue: T) => void] => {
  // isControlled is ignored in the hook dependency lists as it should never change.
  const { current: isControlled } = useRef(controlled !== undefined);
  const [valueState, setValue] = useState(defaultProp);
  const value = isControlled ? controlled : valueState;

  const onChange = useCallback(
    (newValue: T) => {
      if (!isControlled) {
        setValue(newValue);
      } else {
        onControlledChange?.(newValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onControlledChange]
  );

  return [value!, onChange];
};
