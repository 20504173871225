import Icons from "src/icons/Icons";
import * as styles from "./style";

interface FieldIndicatorProps {
  isAdded: boolean;
}

export const FieldIndicator = ({ isAdded }: FieldIndicatorProps) => (
  <styles.Wrapper>{isAdded ? <Icons.Success /> : <Icons.Error />}</styles.Wrapper>
);
