import { usePNLV2Color, usePNLV2Value } from "src/components/shared/PNLV2/shared/Item/PNLV2Item";
import { PNLV2Label } from "src/components/shared/PNLV2/shared/Item/shared/PNLV2Label";
import { PNLV2Value } from "src/components/shared/PNLV2/shared/Item/shared/PNLV2Value";
import * as styles from "./style";

interface LastPriceCellProps {
  value: string;
}

export const LastPriceCell = ({ value }: LastPriceCellProps) => {
  const formattedValue = usePNLV2Value({
    value,
    type: "price",
    showSymbol: false,
    showPlus: false,
  });

  const color = usePNLV2Color({ direction: "neutral" });

  return (
    <styles.PNLItemWrapper>
      <PNLV2Label colored color={color}>
        Last price
      </PNLV2Label>

      <PNLV2Value color={color}>{formattedValue}</PNLV2Value>
    </styles.PNLItemWrapper>
  );
};
