import { BigNumberish, ContractTransaction, ethers, providers } from "ethers";
import { DEXV2VaultType } from "src/api/bots/DEXV2/create";
import { IVaultLimitUpgradeable__factory } from "src/contracts/factories/vaults/v1/IVaultLimitUpgradeable__factory";
import { IVaultVolumeUpgradeable__factory } from "src/contracts/factories/vaults/v1/IVaultVolumeUpgradeable__factory";
import { IVaultLimitUpgradeable2__factory } from "src/contracts/factories/vaults/v2/IVaultLimitUpgradeable2__factory";
import { IVaultVolumeUpgradeable2__factory } from "src/contracts/factories/vaults/v2/IVaultVolumeUpgradeable2__factory";
import { IVaultLimitUpgradeable } from "src/contracts/vaults/v1/IVaultLimitUpgradeable";
import { IVaultVolumeUpgradeable } from "src/contracts/vaults/v1/IVaultVolumeUpgradeable";
import { IVaultLimitUpgradeable2 } from "src/contracts/vaults/v2/IVaultLimitUpgradeable2";
import { IVaultVolumeUpgradeable2 } from "src/contracts/vaults/v2/IVaultVolumeUpgradeable2";
import { DEXV2BotVersion, DEXV2ExchangeVersion } from "src/modules/bots";

type BaseSwapParams<D> = {
  path: string[];
  deadline: D;
  useReceiver: boolean;
};

export type SwapExactTokensForTokensParams<TAmount = BigNumberish, Deadline = BigNumberish> = {
  amountIn: TAmount;
  amountOutMin: TAmount;
} & BaseSwapParams<Deadline>;

export type SwapTokensForExactTokensParams<TAmount = BigNumberish, Deadline = BigNumberish> = {
  amountOut: TAmount;
  amountInMax: TAmount;
} & BaseSwapParams<Deadline>;

export type OraclePermission = Parameters<IVaultVolumeUpgradeable["swapExactTokensForTokens"]>[1];

export type DEXV2SwapVaultType = Exclude<DEXV2VaultType, "main">;

type SwapVaultVersionContract =
  | {
      version: DEXV2BotVersion.V2;
      contract: IVaultLimitUpgradeable | IVaultVolumeUpgradeable;
    }
  | {
      version: DEXV2BotVersion.V3;
      contract: IVaultLimitUpgradeable2 | IVaultVolumeUpgradeable2;
    };

const ZERO_BYTES_STRING = "0x";

export interface ISwapVaultContract {
  swapExactTokensForTokens: (
    params: SwapExactTokensForTokensParams,
    permission: OraclePermission
  ) => Promise<ContractTransaction>;
  swapTokensForExactTokens: (
    params: SwapTokensForExactTokensParams,
    permission: OraclePermission
  ) => Promise<ContractTransaction>;
  get contract(): SwapVaultVersionContract["contract"];
}

export class SwapVaultContract implements ISwapVaultContract {
  private _contract: SwapVaultVersionContract;

  private _poolPercent: number;

  private _dexVersion: DEXV2ExchangeVersion;

  constructor(
    type: DEXV2SwapVaultType,
    version: DEXV2BotVersion,
    dexVersion: DEXV2ExchangeVersion,
    poolPercent: number,
    address: string,
    signer: providers.JsonRpcSigner
  ) {
    this._contract = this._getContract(type, version, address, signer);

    this._poolPercent = poolPercent;

    this._dexVersion = dexVersion;
  }

  private _getContract = (
    type: DEXV2SwapVaultType,
    version: DEXV2BotVersion,
    address: string,
    signer: providers.JsonRpcSigner
  ): SwapVaultVersionContract => {
    switch (type) {
      case "limit": {
        if (version === DEXV2BotVersion.V3) {
          const contract = IVaultLimitUpgradeable2__factory.connect(address, signer);
          return { version: DEXV2BotVersion.V3, contract };
        }

        const contract = IVaultLimitUpgradeable__factory.connect(address, signer);

        return { version: DEXV2BotVersion.V2, contract };
      }
      case "volume":
      case "counter": {
        if (version === DEXV2BotVersion.V3) {
          const contract = IVaultVolumeUpgradeable2__factory.connect(address, signer);
          return { version: DEXV2BotVersion.V3, contract };
        }

        const contract = IVaultVolumeUpgradeable__factory.connect(address, signer);

        return { version: DEXV2BotVersion.V2, contract };
      }
    }
  };

  // data parameter for v3 only
  // 32 bytes first - percent pool fee | 32 bytes second - all zeroes
  private get _v3SwapDataBytes() {
    const poolPercent = this._poolPercent;

    const poolPercentBytes = ethers.utils.hexZeroPad(ethers.utils.hexlify(poolPercent), 32);

    const emptyBytes32 = ethers.utils.hexZeroPad(ZERO_BYTES_STRING, 32);

    const combinedBytes = ethers.utils.concat([poolPercentBytes, emptyBytes32]);

    return combinedBytes;
  }

  private get _swapDataBytes() {
    if (this._dexVersion === DEXV2ExchangeVersion.V3) {
      return this._v3SwapDataBytes;
    }

    return ZERO_BYTES_STRING;
  }

  get contract() {
    return this._contract.contract;
  }

  swapExactTokensForTokens = async (
    params: SwapExactTokensForTokensParams,
    permission: OraclePermission
  ) => {
    const { contract, version } = this._contract;

    const swapData = this._swapDataBytes;

    if (version === DEXV2BotVersion.V3) {
      return await contract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
        params,
        permission,
        swapData
      );
    }

    return await contract.swapExactTokensForTokensSupportingFeeOnTransferTokens(params, permission);
  };

  swapTokensForExactTokens = async (
    params: SwapTokensForExactTokensParams,
    permission: OraclePermission
  ) => {
    const { contract, version } = this._contract;

    const swapData = this._swapDataBytes;

    if (version === DEXV2BotVersion.V3) {
      return await contract.swapTokensForExactTokens(params, permission, swapData);
    }

    return await contract.swapTokensForExactTokens(params, permission);
  };
}
