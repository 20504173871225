import { toast as toastDefault, ExternalToast } from "sonner";
import { createToastSettings, SUCCESS_TOAST_SETTINGS } from "./toastSettings";

type Toast = typeof toastDefault;

const toastInit = () => {
  const toast: Toast = ((message, toastSettings?: ExternalToast) =>
    toastDefault(message, createToastSettings({ message, toastSettings }))) as Toast;

  toast.success = (message, toastSettings?: ExternalToast) =>
    toastDefault.success(
      message,
      createToastSettings({ message, toastSettings, defaultToastSettings: SUCCESS_TOAST_SETTINGS })
    );

  const baseToastMethods = ["error", "warning", "info", "message", "loading"] as const;

  baseToastMethods.forEach((key) => {
    toast[key] = (message, toastSettings?: ExternalToast) =>
      toastDefault[key](message, createToastSettings({ message, toastSettings }));
  });

  toast.custom = (jsx, toastSettings?: ExternalToast) =>
    toastDefault.custom(jsx, createToastSettings({ toastSettings }));

  toast.promise = (promise, toastSettings?: ExternalToast) =>
    toastDefault.promise(promise, createToastSettings({ toastSettings }));

  toast.dismiss = (id) => toastDefault.dismiss(id);

  return toast;
};

export const toast = toastInit();
