import fscreen from "fscreen";
import { useEffect, useState } from "react";

type FullscreenHookResult = {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
};

export const useFullscreen = (): FullscreenHookResult => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(fscreen.fullscreenElement !== null);
    };

    fscreen.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      fscreen.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const enterFullscreen = (element: HTMLElement) => {
    if (fscreen.fullscreenEnabled) {
      fscreen.requestFullscreen(element);
    }
  };

  const exitFullscreen = () => {
    if (fscreen.fullscreenEnabled) {
      fscreen.exitFullscreen();
    }
  };

  const toggleFullscreen = () => {
    const element = document.documentElement;

    if (isFullscreen) {
      exitFullscreen();
      document.body.style.overflowY = "scroll";
    } else {
      enterFullscreen(element);
      element.style.overflowY = "hidden";
    }
  };

  return { isFullscreen, toggleFullscreen };
};
