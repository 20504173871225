import { observer } from "mobx-react-lite";
import { PartiesMetricsContext } from "src/context/CEX/Dashboard/v2/PartiesMetrics";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { TrackableTokens, TrackableTokensProps } from "../../../TrackableTokens";

interface TrackableTokensCardProps extends Omit<TrackableTokensProps, "items"> {}

const TrackableTokensCard = observer((props: TrackableTokensCardProps) => {
  const { exchangesTokens, loading } = useLateInitContext(PartiesMetricsContext.Context);
  return <TrackableTokens items={exchangesTokens} loading={loading} {...props} />;
});

export interface TrackableTokensWidgetProps extends TrackableTokensCardProps {}

export const TrackableTokensWidget = (props: TrackableTokensWidgetProps) => (
  <PartiesMetricsContext.Provider>
    <TrackableTokensCard {...props} />
  </PartiesMetricsContext.Provider>
);
