import styled from "styled-components";
import { DropDownProps, ItemHeader, ScopeIconButtonMixin } from "../../../../ScopesTree/shared";

export const ExchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExchangeHeader = styled(ItemHeader)<DropDownProps>`
  padding-left: 20px;

  cursor: pointer;

  ::before {
    position: absolute;
    content: "";
    width: 3.5px;
    height: 3.5px;
    left: 7px;
    top: 6.5px;
    border-bottom: solid;
    border-right: solid;
    border-width: 1.3px;
    border-color: #d6d6d6;
    -ms-transform: ${({ hide }) => (hide ? "rotate(45deg)" : "rotate(-45deg)")};
    -webkit-transform: ${({ hide }) => (hide ? "rotate(45deg)" : "rotate(-45deg)")};
    transform: ${({ hide }) => (hide ? "rotate(45deg)" : "rotate(-45deg)")};
    transition: all 0.5s;
  }
`;

export const ExchangeHeaderRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: start;

  ${ScopeIconButtonMixin}
`;

export const ExchName = styled.span`
  width: 100%;

  font-weight: 500;
  font-size: 10px;

  color: ${({ theme }) => theme.darkGray};
`;

export const ExchBody = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: 10px;
`;
