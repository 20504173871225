import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface LegendLabelV2Props extends ComponentPropsWithoutRef<"div"> {
  color: string;
  text: string;
  hidden: boolean;
}

export const LegendLabelV2 = ({ color, text, hidden, ...props }: LegendLabelV2Props) => (
  <styles.Container {...props}>
    <styles.ColorBox $color={color} />
    <styles.Title $hidden={hidden}>{text}</styles.Title>
  </styles.Container>
);
