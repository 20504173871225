import { Tooltip } from "react-tooltip";
import styled from "styled-components";

export const Container = styled(Tooltip)`
  width: 220px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 5px !important;

  border-radius: ${({ theme }) => theme.radii["4px"]} !important;
  box-shadow: ${({ theme }) => theme.shadows.dashboard} !important;
  background: ${({ theme }) => theme.bgSettingsPnl} !important;

  opacity: 0.9 !important;

  ::after {
    visibility: hidden;
  }

  ::before {
    visibility: hidden;
  }

  @media screen and (max-width: 880px) {
    width: 200px;
  }
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  padding: 0px 5px 0px 0px;

  :not(:last-of-type) {
    margin-bottom: 5px;
    border-bottom: 1px solid #e0e0e0;
  }
`;

export const Row = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0.3em 0;
`;

export const Key = styled.label`
  padding-left: 5px;

  white-space: nowrap;

  color: ${({ theme }) => theme.textColor} !important;

  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  line-height: ${({ theme }) => theme.lineHeights["15px"]};
`;

export const Value = styled.label`
  /* font-weight: ${({ theme }) => theme.fontWeights.normal}; */
  color: ${({ theme }) => theme.textColor};
`;
