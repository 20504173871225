import { observer } from "mobx-react-lite";
import { BlacklistWalletsPanel } from "./BlacklistWalletsPanel";
import { CounterSettingsPanel } from "./CounterSettingsPanel";
import { TradeSettingsPanel } from "./TradeSettingsPanel";
import * as styles from "./style";

export const CounterSettings = observer(() => (
  <styles.Container>
    <CounterSettingsPanel style={{ gridArea: styles.CounterSettingsAreas.Strategies }} />

    <TradeSettingsPanel style={{ gridArea: styles.CounterSettingsAreas.Settings }} />
    <BlacklistWalletsPanel style={{ gridArea: styles.CounterSettingsAreas.BlackList }} />
  </styles.Container>
));
