import { observer } from "mobx-react-lite";
import { Headline2 } from "src/components/shared/Typography";
import { CreateBotContext } from "src/context/CEX/CreateBotProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { LiquidityAccounts } from "./LiquidityAccounts";
import { VolumeAccounts } from "./VolumeAccounts";
import * as styles from "./style";

export const Accounts = observer(() => {
  const state = useLateInitContext(CreateBotContext);

  const liquidityNames = state.bindings.volumeAccountNames;
  const volumeNames = state.bindings.liquidityAccountNames;

  return (
    <styles.AccountContainer>
      <Headline2>Accounts</Headline2>
      <styles.Content>
        <VolumeAccounts
          accountNames={liquidityNames}
          bindingsProvider={state.bindings}
          onAddAccount={state.addVolumeAccount}
        />
        <LiquidityAccounts
          accountNames={volumeNames}
          bindingsProvider={state.bindings}
          onAddAccount={state.addLiquidityAccount}
        />
      </styles.Content>
    </styles.AccountContainer>
  );
});
