import { SingleValue } from "react-select";
import { SelectorValue } from "src/modules/shared";

/**
 * Converts an array of strings to an array of objects of type selectValue
 * @param arr - read only array
 * @returns array selector objects
 */
export const getSelectorList = (arr: readonly string[]) => arr.map(stringToSelectorValue);

export const stringToSelectorValue = (str: string) => ({ value: str, label: str });

export const selectorValueToString = (selector: SelectorValue) => String(selector.value);

export const toRawSelectorValue = (arr: readonly SelectorValue[]) => arr.map(selectorValueToString);

export type RawPrimitiveSelectorValue = string | number;
export interface PrimitiveSelectorValue<V extends RawPrimitiveSelectorValue> {
  label: string;
  value: V;
}

export const getPrimitiveSelectorList = <V extends RawPrimitiveSelectorValue>(
  arr: V[]
): PrimitiveSelectorValue<V>[] => arr.map(toPrimitiveSelectorValue);

export const toPrimitiveSelectorValue = <V extends RawPrimitiveSelectorValue>(value: V) => {
  const strValue = value.toString();
  return { value, label: strValue };
};

export const toRawPrimitiveSelectorValue = <V extends RawPrimitiveSelectorValue>(
  arr: readonly PrimitiveSelectorValue<V>[]
) => arr.map(({ value }) => value);

export const primitiveSelectorValueToString = (
  selector: SingleValue<PrimitiveSelectorValue<string>>
) => {
  if (selector) {
    return selector.value;
  }

  return "";
};

export const getSelectorValueFromOptions =
  <V extends RawPrimitiveSelectorValue>(options: PrimitiveSelectorValue<V>[]) =>
  (value: V) =>
    options.find((el) => el.value === value);
