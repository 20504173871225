import { observer } from "mobx-react-lite";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BotName } from "../shared";
import * as styles from "./style";
import { TerminalSettings } from "../TerminalSettings";

export const TitleExchange = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);

  const update = () => {
    if (!mainState.botUUID) return;

    mainState.updAllData();
  };

  return (
    <styles.Container onKeyDown={(event) => event.key === "Enter" && update()}>
      <BotName>{mainState.market.replace(/_/g, " ")}</BotName>

      <TerminalSettings />

      <ReloadButton onClick={update} />
    </styles.Container>
  );
});
