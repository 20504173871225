import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { MainFilter } from "./MainFilter";
import { MainFilterFallback } from "./MainFilter/style";
import { PartiesFilter } from "./PartiesFilter";
import { PartiesFilterFallback } from "./PartiesFilter/style";
import * as styles from "./style";

export const Aside = observer(() => (
  <styles.FiltersContainer>
    <ErrorBoundary fallback={<MainFilterFallback />}>
      <MainFilter />
    </ErrorBoundary>
    <ErrorBoundary fallback={<PartiesFilterFallback />}>
      <PartiesFilter />
    </ErrorBoundary>
  </styles.FiltersContainer>
));
