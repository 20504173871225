import {
  DateTimeRangeField,
  DateTimeRangeFieldProps,
} from "../../DateTimeFields/DateTimeRangeField";
import {
  DateTimeRangePickerButtons,
  DateTimeRangePickerButtonsProps,
} from "../DateTimeRangePickerButtons";
import { PickerShortcuts, PickerShortcutsProps } from "../PickerShortcuts";
import * as styles from "./style";

export interface ShowablePickerShortcutsProps extends PickerShortcutsProps {
  show?: boolean;
}
export interface DesktopDateTimeRangePickerProps {
  fieldProps: DateTimeRangeFieldProps;
  buttonsProps: DateTimeRangePickerButtonsProps;
  shortcutsProps: ShowablePickerShortcutsProps;
}

export const DesktopDateTimeRangePicker = ({
  fieldProps,
  buttonsProps,
  shortcutsProps: { show: showShortcuts, ...otherShortcutsProps },
}: DesktopDateTimeRangePickerProps) => {
  const Shortcuts = showShortcuts ? <PickerShortcuts {...otherShortcutsProps} /> : null;
  return (
    <styles.Container>
      <DateTimeRangeField {...fieldProps} />
      {Shortcuts}
      <DateTimeRangePickerButtons {...buttonsProps} />
    </styles.Container>
  );
};
