import { PanelContainer } from "src/components/shared/shared";
import { checkParty } from "src/helpers/checkParty";
import styled, { css } from "styled-components";
import { GridProps } from "../Versions/FullExchange/style";

export const ExchForm = styled.form`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 5px;

  grid-area: form-exchange;
`;

export const Content = styled(PanelContainer)<GridProps>`
  position: relative;

  display: grid;
  grid-template-columns:
    minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)
    minmax(100px, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-areas:
    "module             pair-settings     pair-settings       diff"
    "virtual-range      trade-settings    save-diff           save-diff";

  flex: 1;

  padding: 16px;

  @media screen and (max-width: 1231px) {
    grid-template-rows: auto;

    ${({ abilities, party }) => {
      const checkSettingsEdit = checkParty(abilities, "cex-settings-edit", party);

      const checkSettingsView = checkParty(abilities, "cex-settings-view", party);

      return css`
        grid-template-areas: ${() => {
          // all permissions view
          if (checkSettingsEdit && checkSettingsView)
            return `
       "pair-settings  "
       "pair-settings  "
       "diff           "
       "trade-settings "
       "module         "
       "virtual-range  "
       "save-diff      "`;

          // external view
          if (!checkSettingsEdit && checkSettingsView)
            return `
       "pair-settings  diff"
       "pair-settings  trade-settings"
       "module        _ "   
       "virtual-range save-diff      "`;
        }};

        grid-template-columns: ${() => {
          // all permissions view
          if (checkSettingsEdit && checkSettingsView) return "minmax(100px, 1fr)";

          // external view
          if (!checkSettingsEdit && checkSettingsView)
            return "minmax(100px, 1fr) minmax(160px, 1fr)";
        }};
      `;
    }}
  }
`;

export const SaveDiffWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  grid-area: save-diff;
`;

export const SupportText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.labelColor};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 880px) {
    justify-content: space-between;
  }
`;
