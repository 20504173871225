import { ApiModules, apiUrl } from "src/api";
import ResponseHandler from "src/state/network/ResponseHandler";

export const getCEXBots = async () =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Empty, "get-bots"),
    method: "GET",
  });

export const getCEXBot = async (bot_uuid: string) =>
  ResponseHandler.handler(
    {
      url: apiUrl(ApiModules.Bot, `${bot_uuid}/get-bot`),
      method: "GET",
    },
    "getCEXBot"
  );

export const getWatchList = async () =>
  ResponseHandler.handler(
    {
      url: apiUrl(ApiModules.WatchList, ""),
      method: "GET",
    },
    "getWatchList"
  );

export const getRecentParties = async () =>
  ResponseHandler.handler(
    {
      url: apiUrl(ApiModules.Empty, "get-bots-latest"),
      method: "GET",
    },
    "getRecentParties"
  );

// export const getCEXBlackList = async () => {
//   return ResponseHandler.handler("/api/GetBlackList", "GET");
// };

// export const addCEXToBlackList = async (path: string) => {
//   return ResponseHandler.handler("/api/AddBlackList", "POST", { path });
// };

// export const removeCEXFromBlackList = async (path: string) => {
//   return ResponseHandler.handler("/api/DeleteBlackList", "POST", { path });
// };
