import { CSSProperties } from "react";
import { ThemeMode } from "src/components/Themes";
import * as styles from "./style";

const SANTIMENT_CHART_URL = "https://embed.santiment.net/chart";
const DEFAULT_SLUG = "bitcoin";
const DEFAULT_TICKER = "BTC";
const SHARED_TOKEN = "shchcfg_KnC9HQBhkbqe";
const OTHER_SANTIMENT_PARAMS =
  '&utc=1&emmmc=1&emcal=1&emcg=1&emms=1&wm=price_usd;social_volume_total;sentiment_volume_consumed_total&wax=0;1;2&wc=#26C953;#68DBF4;#FF5B5B&ws=;{"interval":"1d"};{"interval":"1d"}';

interface SantimentProps {
  theme: ThemeMode;
  slug?: string;
  ticker?: string;
  startDate?: string;
  endDate?: string;
  style: CSSProperties;
}

const getSantimentWidgetUrl = ({
  theme,
  startDate,
  endDate,
  slug,
  ticker,
}: Omit<SantimentProps, "style">) => {
  const santimentTheme = theme === "dark" ? "emnm=1" : "";
  const slugValue = slug || DEFAULT_SLUG;
  const tickerValue = ticker || DEFAULT_TICKER;

  return `${SANTIMENT_CHART_URL}?${santimentTheme}&ps=${slugValue}&pt=${tickerValue}&df=${startDate}&dt=${endDate}&sat=${SHARED_TOKEN}${OTHER_SANTIMENT_PARAMS}`;
};

export const Santiment = ({ style, ...props }: SantimentProps) => {
  const widgetUrl = getSantimentWidgetUrl(props);

  return <styles.SantimentIframe title="Santiment Chart Widget" src={widgetUrl} style={style} />;
};
