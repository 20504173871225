import { useContext, useMemo } from "react";
import { Theme, darkTheme, lightTheme } from "src/components/Themes";
import { ThemeContext } from "styled-components";

export const getTheme = (): Theme =>
  localStorage.getItem("theme") === "light" ? lightTheme : darkTheme;

export const getColor = (status: number, timeDontWork: number, modifyVolume: boolean): string => {
  const themes = getTheme();

  // let grey = "rgb(164, 162, 163)";
  // let green = "rgb(128, 152, 85)";
  // let yellow = "rgb(254, 207, 101)";
  // let blue = "rgb(100, 106, 132)";
  // let dark_red = "rgb(183, 96, 72)";
  // let light_red = "rgb(240, 127, 95)";
  // let purple = "rgb(97, 79, 91)";

  const { gray } = themes.botColorStatus;
  const { green } = themes.botColorStatus;
  const { yellow } = themes.botColorStatus;
  const { blue } = themes.botColorStatus;
  const dark_red = themes.botColorStatus.darkRed;
  const light_red = themes.botColorStatus.lightRed;
  const { purple } = themes.botColorStatus;

  // if (localStorage.getItem("theme") !== "light") {
  //   gray = "rgb(121, 115, 103)";
  //   green = "rgb(130, 141, 108)";
  //   yellow = "rgb(240, 199, 109)";
  //   blue = "rgb(56, 68, 90)";
  //   dark_red = "rgb(186, 109, 93)";
  //   light_red = "rgb(226, 135, 106)";
  //   purple = "rgb(93, 80, 89)";
  // }

  if (modifyVolume) {
    return purple;
  }
  if (status === 4) {
    return gray;
  }
  if (status === 3) {
    return green;
  }
  if (status === 1) {
    return yellow;
  }
  if (status === 2) {
    return blue;
  }
  if (status === 0 && timeDontWork <= 300) {
    return light_red;
  }
  if (status === 0 && timeDontWork > 300) {
    return dark_red;
  }

  return gray;
};

export const useColorStatus = () => {
  const { botColorStatus } = useContext(ThemeContext);

  const colorMapper = useMemo(
    () =>
      (status: number, timeDontWork: number): string => {
        switch (status) {
          case 0: {
            if (timeDontWork > 300) {
              return botColorStatus.darkRed;
            }

            return botColorStatus.lightRed;
          }
          case 1: {
            return botColorStatus.yellow;
          }
          case 2: {
            // return botColorStatus.gray;
            return botColorStatus.blue;
          }
          case 3: {
            return botColorStatus.green;
          }
          case 4: {
            // return botColorStatus.blue;
            return botColorStatus.gray;
          }
          default: {
            return botColorStatus.green;
          }
        }
      },
    [botColorStatus]
  );

  return colorMapper;
};

export const useBgColorStatus = () => {
  const { botBgColorStatus } = useContext(ThemeContext);

  const colorMapper = useMemo(
    () =>
      (status: number, timeDontWork: number): string => {
        switch (status) {
          case 0: {
            if (timeDontWork > 300) {
              return botBgColorStatus.darkRed;
            }

            return botBgColorStatus.lightRed;
          }
          case 1: {
            return botBgColorStatus.yellow;
          }
          case 2: {
            // return botBgColorStatus.gray;
            return botBgColorStatus.blue;
          }
          case 3: {
            return botBgColorStatus.green;
          }
          case 4: {
            // return botBgColorStatus.blue;
            return botBgColorStatus.gray;
          }
          default: {
            return botBgColorStatus.green;
          }
        }
      },
    [botBgColorStatus]
  );

  return colorMapper;
};

export const useBgSideColorStatus = () => {
  const { botBgSideColorStatus } = useContext(ThemeContext);

  const colorMapper = useMemo(
    () =>
      (status: number, timeDontWork: number): string => {
        switch (status) {
          case 0: {
            if (timeDontWork > 300) {
              return botBgSideColorStatus.darkRed;
            }

            return botBgSideColorStatus.lightRed;
          }
          case 1: {
            return botBgSideColorStatus.yellow;
          }
          case 2: {
            // return botBgSideColorStatus.gray;
            return botBgSideColorStatus.blue;
          }
          case 3: {
            return botBgSideColorStatus.green;
          }
          case 4: {
            // return botBgSideColorStatus.blue;
            return botBgSideColorStatus.gray;
          }
          default: {
            return botBgSideColorStatus.green;
          }
        }
      },
    [botBgSideColorStatus]
  );

  return colorMapper;
};
