import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import {
  generatePriceUSD,
  generateTime,
  generateTimeSeries,
  randomTrendSort,
} from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type DepthLevelData = {
  price: number;
  data: ChartPoint[];
};

export type DepthLevelsData = Record<string, DepthLevelData>;

export type DepthData = {
  buy: DepthLevelsData;
  sell: DepthLevelsData;
};

type AllDepthData = {
  all: DepthData;
  our: DepthData;
};

const INITIAL_DEPTH_DATA: DepthData = { buy: {}, sell: {} };
const INITIAL_DATA: AllDepthData = { all: INITIAL_DEPTH_DATA, our: INITIAL_DEPTH_DATA };

const DEPTH_LEVELS = [2, 5, 10, 20];

const generateDepthLevelData = (startTime: number, count: number): DepthLevelData => {
  const pointsCount = faker.number.int({ min: 10, max: 30 });

  const rangeMultiplier = faker.number.float({ min: 0.2, max: 5 });
  const rangeOffset = faker.number.int({ min: 0, max: 1000 });
  const min = 100 * rangeMultiplier + rangeOffset;
  const max = 1000 * rangeMultiplier + rangeOffset;

  const price = generatePriceUSD();

  const data = generateTimeSeries({
    ...{
      startTimestamp: startTime,
      count: pointsCount,
    },
    ...{ count, value: { min, max } },
  });

  const values = data.map(({ value }) => value);
  const times = data.map(({ time }) => time);

  const trendValues = randomTrendSort(values, { count: { min: 2, max: 4 } });

  const trendData = times.map((time, index) => ({
    time,
    value: trendValues[index],
  }));

  return { price: +price, data: trendData };
};

const generateDepthLevelsData = (
  levels: number[],
  startTime: number,
  count: number
): DepthLevelsData => {
  const levelsData = levels.reduce((acc, level) => {
    const data = generateDepthLevelData(startTime, count);
    return { [level]: data, ...acc };
  }, {} as DepthLevelsData);

  return levelsData;
};

const generateDepthData = (): DepthData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 10, max: 30 });

  const buyDepthLevels = DEPTH_LEVELS;
  const buyData = generateDepthLevelsData(buyDepthLevels, startTimestamp, pointsCount);

  const sellDepthLevels = DEPTH_LEVELS.map((v) => -v);
  const sellData = generateDepthLevelsData(sellDepthLevels, startTimestamp, pointsCount);

  return { buy: buyData, sell: sellData };
};

const generateAllDepthData = (): AllDepthData => {
  const allData = generateDepthData();
  const ourData = generateDepthData();

  return { all: allData, our: ourData };
};

export class DepthV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: AllDepthData = INITIAL_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBaseDashboardV2StoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { data: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: AllDepthData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get allDepth() {
    return this._data.all;
  }

  get ourDepth() {
    return this._data.our;
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData(INITIAL_DATA);
    try {
      await delay(200);
      const data = generateAllDepthData();
      this._setData(data);
    } catch {
      this._setData(INITIAL_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
