import { observer } from "mobx-react-lite";
import * as styles from "./style";

export interface PanelProps extends Omit<React.ComponentPropsWithoutRef<"div">, "title"> {
  title: string | React.ReactElement;
}

export const Panel = observer(({ children, title, ...props }: PanelProps) => (
  <styles.Content {...props}>
    <styles.Title>{title}</styles.Title>
    {children}
  </styles.Content>
));
