import { PanelContainer, ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled(PanelContainer)`
  height: 100%;
  width: 20%;
  min-width: 220px;
  min-height: 220px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 15px;
  gap: 10px;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  height: 0px;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  overflow-y: auto;

  ${ScrollSize}
`;
