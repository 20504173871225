import { LabelValue } from "src/state/MarketCheck/types";
import * as styles from "./style";

export const ActiveInfo = ({ label, value }: LabelValue) => (
  <styles.Container>
    <styles.Label>{label}</styles.Label>

    <styles.Value>{value}</styles.Value>
  </styles.Container>
);
