import HeaderIcons from "src/icons/HeaderIcons";
import { Abilities } from "src/modules/abilities";
import { useAppState } from "src/state";

const apiKeys = {
  label: "API",
  value: "APIKEYS",
  icon: HeaderIcons.apiKeys,
  to: (path: string) => `/CEX/${path}/?tab=APIKEYS`,
};

const stats = {
  label: "Stats",
  value: "STATS",
  icon: HeaderIcons.stats,
  to: (path: string) => `/CEX/${path}/?tab=STATS`,
};

const dashboard = {
  label: "Dashboard",
  value: "DASHBOARD",
  icon: HeaderIcons.dashboard,
  to: (path: string) => `/CEX/${path}/?tab=DASHBOARD`,
};

const settings = {
  label: "Settings",
  value: "SETTINGS",
  icon: HeaderIcons.settings,
  to: (path: string) => `/CEX/${path}/?tab=SETTINGS`,
};

const exchange = {
  label: "Exchange",
  value: "EXCHANGE",
  icon: HeaderIcons.exchange,
  to: (path: string) => `/CEX/${path}/?tab=EXCHANGE`,
};

const grid = {
  label: "Grid",
  value: "GRID",
  icon: HeaderIcons.grid,
  to: (path: string) => `/CEX/${path}/?tab=GRID`,
};

const multiGrid = {
  label: "Multi grid",
  value: "MULTIGRID",
  icon: HeaderIcons.multiGrid,
  to: (path: string) => `/CEX/${path}/?tab=MULTIGRID`,
};

const funding = {
  label: "Funding",
  value: "FUNDING",
  icon: HeaderIcons.funding,
  to: (path: string) => `/CEX/${path}/?tab=FUNDING`,
};

const modules = {
  label: "Modules",
  value: "MODULES",
  icon: HeaderIcons.modules,
  to: (path: string) => `/CEX/${path}/?tab=MODULES`,
};

const analytics = {
  label: "Analytics",
  value: "ANALYTICS",
  icon: HeaderIcons.analytics,
  to: (path: string) => `/CEX/${path}/?tab=ANALYTICS`,
};

const strategies = {
  label: "Strategies",
  value: "STRATEGIES",
  icon: HeaderIcons.expert,
  to: (path: string) => `/CEX/${path}/?tab=STRATEGIES`,
};

const defaultMenuItems = [stats, dashboard, exchange];

const mapRoleToMenuItems = (abilities: Abilities, party: string = "") => {
  const menuItems = [...defaultMenuItems];

  if (
    abilities["cex-expert-system-view"] &&
    abilities["cex-expert-system-view"].includes(`${party}`)
  ) {
    menuItems.push(strategies);
  }

  if (
    abilities["cex-candle-analytics-view"] &&
    abilities["cex-candle-analytics-view"].includes(`${party}`)
  ) {
    menuItems.push(analytics);
  }

  if (abilities["cex-settings-view"] && abilities["cex-settings-view"].includes(`${party}`)) {
    menuItems.push(settings);
  }

  if (abilities["cex-modules-view"] && abilities["cex-modules-view"].includes(`${party}`)) {
    menuItems.push(modules);
  }

  if (abilities["cex-grid-view"] && abilities["cex-grid-view"].includes(`${party}`)) {
    menuItems.push(grid);
    menuItems.push(multiGrid);
  }

  menuItems.push(funding);

  if (abilities["cex-api-view"] && abilities["cex-api-view"].includes(`${party}`)) {
    menuItems.push(apiKeys);
  }

  return menuItems;
};

export const CEXMenuItems = () => {
  const { userState, CEXBotState } = useAppState();

  return mapRoleToMenuItems(userState.abilities, CEXBotState.party);
};
