import React from "react";
import { Depth } from "src/state/MarketCheck/types";
import { formatPNLPrice } from "src/components/shared/PNLV2/shared/utilts";
import { getRounded } from "../helpers";
import * as styles from "./style";

interface DepthInfoProps {
  title: string;
  depthData: Depth;
}

export const DepthInfo = ({ title, depthData }: DepthInfoProps) => {
  const data = [
    { label: `Our ${depthData.our_base.label}`, value: getRounded(depthData.our_base.value) },
    { label: "Our USD", value: getRounded(depthData.our_quote.value) },
    { label: "Avg Price", value: formatPNLPrice(depthData.avg_price) },
  ];

  return (
    <styles.Container>
      <styles.Title>{title}</styles.Title>

      {data.map(({ label, value }) => (
        <React.Fragment key={label}>
          <styles.Label>{label}</styles.Label>
          <styles.Value>{value}</styles.Value>
        </React.Fragment>
      ))}
    </styles.Container>
  );
};
