import AppContext from "src/context";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { AllBotsStore } from "./AllBots";
import { CEXBotStore } from "./CEX/CEXBot";
import { CEXBotsStore } from "./CEX/CEXBots/CEXBots";
import { DEXBotStore } from "./DEX/DEXBot";
import { DEXBotsStore } from "./DEX/DEXBots";
import { DEXV2BotsStore } from "./DEXV2/DEXV2Bots/DEXV2Bots";
import { GoogleAuthStore } from "./GoogleAuth";
import { UserStore } from "./User";
import { ChainMetaStore } from "./chain/ChainMetaStore";
import { ChainsInfoStore, IChainsInfo } from "./chain/ChainsInfoStore";
import { UserConnection } from "./shared/UserConnection";
import { ITimezoneStore, TimezoneStore } from "./time/TimezoneStore";

const chainsStore = new ChainsInfoStore() as IChainsInfo;

const chainMetaStore = new ChainMetaStore();

const userStore = new UserStore();

const userConnection = new UserConnection(userStore);

export const appState = {
  allBotsState: new AllBotsStore(),
  cexBotsState: new CEXBotsStore(),
  dexBotsState: new DEXBotsStore(),
  dexV2BotsState: new DEXV2BotsStore(chainsStore, chainMetaStore),
  userState: userStore,
  CEXBotState: new CEXBotStore(),
  DEXBotState: new DEXBotStore(),
  chainsInfoState: chainsStore,
  chainMetaState: chainMetaStore,
  userConnectionState: userConnection,
  googleAuthState: new GoogleAuthStore(),
  timezone: new TimezoneStore() as ITimezoneStore,
};

export type AppState = typeof appState;

export const useAppState = (): AppState => useLateInitContext(AppContext);
