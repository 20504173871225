import { Observer } from "mobx-react-lite";
import { ColumnDef } from "@tanstack/react-table";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { FundingMenuValue, IFundingCommon } from "src/state/funding/CEX/types";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { FundingManagementStore } from "src/state/funding/CEX/FundingManagementStore";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import { FundingCell } from "../../../shared";
import { CellText } from "../../style";
import { DISABLE_CONFIG } from "../../..";
import { EditFundingModal } from "../../../EditFundingModal";
import { CommentCell } from "./style";

type ColumnKeys = keyof typeof baseFundingColumnSchema | keyof typeof actionsColumnSchema;

const baseColumns: ColumnKeys[] = [
  "type",
  "amount",
  "currency",
  "exchange",
  "account_name",
  "time",
];

const tabColumns: Record<FundingMenuValue, ColumnKeys[]> = {
  AGGREGATED: [...baseColumns],
  TRANSFERS: [...baseColumns, "comment"],
  MANUAL: [...baseColumns, "comment"],
  AUTO: [...baseColumns, "comment"],
};

const tabActionColumns: Record<FundingMenuValue, ColumnKeys[]> = {
  AGGREGATED: [],
  TRANSFERS: ["ignoreAction"],
  MANUAL: ["editAction", "deleteAction"],
  AUTO: ["ignoreAction"],
};

const baseFundingColumnSchema: Partial<Record<keyof IFundingCommon, ColumnDef<IFundingCommon>>> = {
  type: {
    header: "Type",
    accessorKey: "type",
    enableSorting: false,
    cell: (info) => <CellText>{info.getValue<string>().toUpperCase()}</CellText>,
  },
  amount: {
    header: "Amount",
    accessorKey: "amount",
    enableColumnFilter: false,
    cell: (info) => {
      const amount = info.getValue<number>();
      return <FundingCell type={info.cell.row.original.type} value={amount} />;
    },
  },
  currency: {
    header: "Currency",
    accessorKey: "currency",
    enableSorting: false,
    cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
  },
  exchange: {
    header: "Exchange",
    accessorKey: "exchange",
    enableSorting: false,
    cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
  },
  account_name: {
    header: "Account",
    accessorKey: "account_name",
    enableSorting: false,
    cell: (info) => <CellText>{info.getValue<string>()}</CellText>,
  },
  time: {
    header: "Time",
    accessorKey: "time",
    enableColumnFilter: false,
    cell: (info) => <CellText>{unixToDateFormat(info.getValue<number>(), "FullDate")}</CellText>,
  },
  comment: {
    header: "Comment",
    accessorKey: "comment",
    size: 200,
    enableColumnFilter: false,
    cell: (info) => {
      const comment = info.getValue<string>();

      return (
        <CommentCell data-tooltip-id="comment" data-tooltip-content={comment}>
          {comment}
        </CommentCell>
      );
    },
  },
};

const actionsColumnSchema = {
  ignoreAction: (state: FundingManagementStore) =>
    ({
      header: "Ignore",
      accessorKey: "skip",
      enableSorting: false,
      cell: ({
        cell: {
          row: {
            original: { id, skip },
          },
        },
      }) => (
        <Observer>
          {() => (
            <CellText>
              <ToggleSwitch
                checked={skip}
                onChange={(e) => state.toggleFundingIgnore(id, e.target.checked)}
              />
            </CellText>
          )}
        </Observer>
      ),
    }) as ColumnDef<IFundingCommon>,
  editAction: () =>
    ({
      header: "",
      accessorKey: "id",
      id: "editAction",
      size: 35,
      ...DISABLE_CONFIG,
      cell: ({
        cell: {
          row: {
            original: { id },
          },
        },
      }) => (
        <Observer>
          {() => (
            <CellText>
              <EditFundingModal id={id} />
            </CellText>
          )}
        </Observer>
      ),
    }) as ColumnDef<IFundingCommon>,
  deleteAction: (state: FundingManagementStore) =>
    ({
      header: "",
      accessorKey: "id",
      id: "deleteAction",
      size: 35,
      ...DISABLE_CONFIG,
      cell: ({
        cell: {
          row: {
            original: { id },
          },
        },
      }) => (
        <Observer>
          {() => (
            <CellText>
              <DeleteButton style={{ margin: "auto" }} onClick={() => state.deleteFunding(id)} />
            </CellText>
          )}
        </Observer>
      ),
    }) as ColumnDef<IFundingCommon>,
};

export const getColumnsForTab = (state: FundingManagementStore, checkPermsn: boolean) => {
  const columnSchema = { ...baseFundingColumnSchema, ...actionsColumnSchema };
  let columnsKeys = tabColumns[state.activeFundingMenu];

  if (checkPermsn) columnsKeys = [...columnsKeys, ...tabActionColumns[state.activeFundingMenu]];

  return columnsKeys.map((key) => {
    const column = columnSchema[key];

    if (typeof column === "function") return column(state);

    return column;
  }) as ColumnDef<IFundingCommon>[];
};
