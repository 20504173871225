import { useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { DEXV2SwapContext } from "src/context/DEXV2/DEXV2Swap/DEXV2Swap";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DesktopSwap } from "./DesktopSwap";
import { MobileSwap } from "./MobileSwap";
import * as styles from "./style";

export interface SwapProps {}

export const Swap = observer((_props: SwapProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const state = useLateInitContext(DEXV2SwapContext);
  const { botUUID } = botState;

  useEffect(() => {
    if (!botUUID) return;
    state.setBotUUID(botUUID);
    state.getSettings();
  }, [botUUID, state]);

  const isMobile = useMediaQuery(getMobileMediaQuery("700px"));

  return <styles.Container>{isMobile ? <MobileSwap /> : <DesktopSwap />}</styles.Container>;
});
