import { BigNumber } from "ethers";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { chainErrorHandler } from "src/helpers/network/chain";
import { IDisposable } from "src/helpers/utils";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { CacheOptions } from "../../../utils";

export type GasPrice = {
  gasPriceWei: BigNumber;
};

export interface IGasPriceProvider {
  get gasPrice(): GasPrice | undefined;
  getGasPrice(options?: CacheOptions): Promise<void>;
}

export class GasPriceProvider implements IGasPriceProvider, IDisposable {
  private _chainProvider: IChainProvider;

  private _gasPrice: GasPrice | undefined;

  constructor(chainProvider: IChainProvider) {
    makeAutoObservable(this);

    this._chainProvider = chainProvider;

    makeLoggable(this, { gasPrice: true });
  }

  private get _provider() {
    return this._chainProvider.provider;
  }

  private _setGasPrice = (price: GasPrice) => {
    this._gasPrice = price;
  };

  get gasPrice() {
    return this._gasPrice;
  }

  async getGasPrice() {
    if (!this._provider) return;
    try {
      const { maxFeePerGas, gasPrice } = await this._provider.getFeeData();

      if (!gasPrice) return;

      if (!maxFeePerGas) {
        // chain not supporting EIP-1559
        this._setGasPrice({ gasPriceWei: gasPrice });
        return;
      }

      this._setGasPrice({ gasPriceWei: maxFeePerGas });
    } catch (err) {
      chainErrorHandler(err);
    }
  }

  destroy = () => {};
}
