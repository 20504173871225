import { deepmerge } from "deepmerge-ts";

type DeepMergeReturnType<Ts extends Readonly<ReadonlyArray<unknown>>> = ReturnType<
  typeof deepmerge<[...Ts]>
>;

export const deepMerge = <Ts extends Readonly<ReadonlyArray<unknown>>>(
  ...objects: readonly [...Ts]
) => {
  const nonEmptyObjects = objects.filter(Boolean) as [...Ts];

  return deepmerge(...nonEmptyObjects) as NonNullable<DeepMergeReturnType<Ts>>;
};
