import { LabelText } from "src/components/shared/CheckBox/style";
import styled from "styled-components";
import { StatsTable } from "../../../shared/StatsTable";
import { Row, TableCell, TableFooterCell, TableHeaderCell } from "../../../shared/StatsTable/style";
import { StyledColumnsFilter as BaseColumnsFilter } from "../AccountBalancesTable/style";

export const StyledColumnsFilter = styled(BaseColumnsFilter)`
  ${LabelText} {
    max-width: 70%;
  }
`;

export const StyledPairsTable = styled(StatsTable)`
  ${TableHeaderCell} {
    height: 30px;
    white-space: normal;
  }

  & ${Row} {
    ${TableCell} {
      flex-direction: row-reverse;
      justify-content: start;
      max-width: 250px;
    }
  }

  ${TableFooterCell} {
    font-weight: ${({ theme }) => theme.fontWeights["500"]};
  }
` as typeof StatsTable;
