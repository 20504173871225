import { PNLV2Item } from "src/components/shared/PNLV2/shared/Item/PNLV2Item";
import {
  PNLV2TextItem,
  PNLV2TextItemProps,
} from "src/components/shared/PNLV2/shared/Item/PNLV2TextItem";

export interface PNLV2PriceItemProps extends Omit<PNLV2TextItemProps, "value"> {
  price?: string | number;
  quote?: string | number;
}

const checkZero = (value?: string | number): boolean => `${value}` === "0";

/**  Show price in a format:
 * 1. If price is 0, render NET PROFIT/NET LOSS based on quote value
 * 2. If price is not 0, render price as a pnl number */
export const PNLV2PriceItem = ({ price, quote, ...props }: PNLV2PriceItemProps) => {
  const isPriceZero = checkZero(price);

  return isPriceZero ? (
    <PNLV2TextItem value={quote} {...props} />
  ) : (
    <PNLV2Item value={price} {...props} />
  );
};
