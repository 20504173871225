import { observer } from "mobx-react-lite";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { Loader } from "src/components/shared/Loader";
import * as styles from "./style";

interface AuthFormOwnProps extends Pick<React.ComponentPropsWithoutRef<"form">, "onSubmit"> {
  title?: string;
  submitBefore?: JSX.Element;
  isLoading?: boolean;
}

interface AuthFormProps
  extends AuthFormOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof AuthFormOwnProps> {}

export const AuthForm = observer(
  ({ title, onSubmit, submitBefore, isLoading, children, ...props }: AuthFormProps) => (
    <styles.Container {...props}>
      <styles.Title>{title}</styles.Title>
      <styles.FormContainer>
        <styles.Form onSubmit={onSubmit}>
          {children}
          <styles.ButtonsWrapper>
            {submitBefore}
            <SubmitButton />
          </styles.ButtonsWrapper>
          <Loader show={isLoading} />
        </styles.Form>
      </styles.FormContainer>
    </styles.Container>
  )
);
