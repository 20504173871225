import { observer } from "mobx-react-lite";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { WithdrawGasContext } from "src/context/DEXV2/DEXV2Stats/GasWallets/WIthdrawGas";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import * as styles from "./style";

export interface WithdrawGasSuccessProps {}

export const WithdrawGasSuccess = observer((_props: WithdrawGasSuccessProps) => {
  const state = useLateInitContext(WithdrawGasContext);
  const { chainInfo } = useLateInitContext(DEXV2BotContext);
  const txScannerLink = getScannerLink(chainInfo, "tx");
  const walletScannerLink = getScannerLink(chainInfo, "wallet");

  return (
    <styles.Container>
      <styles.Title>Successful withdrawal with the following transactions:</styles.Title>
      <styles.TransactionList
        transactions={state.transactions}
        txScannerLink={txScannerLink}
        walletScannerLink={walletScannerLink}
      />
    </styles.Container>
  );
});
