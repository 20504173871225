import { ModeIconColorMixin } from "src/components/AllBots/Bots/DEXV2Bots/shared/ModeChip/ModeIcon/style";
import styled from "styled-components";

export const IconWrapper = styled.span`
  width: 20px;
  height: 20px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  align-self: center;
  flex-shrink: 0;

  border-radius: ${({ theme }) => theme.radii["6px"]};
  border-width: 2px;
  border-style: solid;

  & > svg {
    width: 60%;
    height: auto;
  }

  ${ModeIconColorMixin}
`;
