import { PanelHeader } from "src/components/shared/PanelHeader";
import { Switches } from "src/components/shared/Switches";
import styled from "styled-components";

export const Container = styled.div`
  padding: 13px 0px 13px 17px;
`;

export const Header = styled(PanelHeader)`
  padding: 0;
  white-space: nowrap;
`;

export const Switcher = styled(Switches)`
  height: 100%;

  & > div {
    height: calc(100% - 20px);
    overflow-y: scroll;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;

    gap: 3px;

    ::-webkit-scrollbar-corner {
      background-color: inherit;
    }
    ::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }

  & > div > div {
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
  }

  & > div > div > label:first-of-type {
    font-family: "San Francisco Pro", "Inter";
    font-weight: ${({ theme }) => theme.fontWeights[500]};
    font-size: ${({ theme }) => theme.fontSizes["11px"]};
  }

  & > div > div > label:last-of-type {
    margin-right: 14px;
    flex: 0 0 auto;
  }

  & > div > div > label:not(:first-of-type) {
    width: 16px;
    height: 16px;
  }
`;
