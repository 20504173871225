import { ErrorMsg } from "src/components/shared/shared";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ErrInfoMsg = styled(ErrorMsg)`
  :after {
    display: none;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;

  width: 15px;
  height: auto;

  > svg {
    width: 100%;
    height: auto;
  }
`;
