import { ValidationProps } from "@mui/x-date-pickers/internals";
import { Dayjs } from "dayjs";
import { DEFAULT_FULL_DATE_FORMAT } from "src/helpers/dateUtils";
import {
  DateTimeRangeComponentValidationProps,
  DateTimeRangeValidationError,
  DateTimeRangeValidationErrorValue,
} from "../hooks/useDateTimeValidation";
import { DateRange } from "../models/range";

interface HumanizeDateTimeRangeValueProps<TDate>
  extends Omit<HumanizeDateTimeRangeProps<TDate>, "value"> {
  value: DateRange<TDate>[0];
}

const getMaxDateErrorText = (maxDate: Dayjs) =>
  `Date should be < ${maxDate.format(DEFAULT_FULL_DATE_FORMAT)}`;

const getMinDateErrorText = (minDate: Dayjs) =>
  `Date should be > ${minDate.format(DEFAULT_FULL_DATE_FORMAT)}`;

const humanizeRangeErrorValue = (
  error: DateTimeRangeValidationErrorValue,
  { minDate, maxDate, maxTime, minTime }: HumanizeDateTimeRangeValueProps<Dayjs>
) => {
  if (!error) return undefined;
  switch (error) {
    case "maxDate": {
      if (!maxDate || !maxDate.isValid()) return "Max date";
      return getMaxDateErrorText(maxDate);
    }
    case "maxTime": {
      if (!maxTime || !maxTime.isValid()) return "Max time";
      return getMaxDateErrorText(maxTime);
    }
    case "minDate": {
      if (!minDate || !minDate.isValid()) return "Min date";
      return getMinDateErrorText(minDate);
    }
    case "minTime": {
      if (!minTime || !minTime.isValid()) return "Min time";
      return getMinDateErrorText(minTime);
    }
    case "invalidDate": {
      return "Invalid date";
    }
    case "invalidRange": {
      return "End date should be > than start date";
    }
    case "disableFuture": {
      return "Date should be < than current time";
    }
    default: {
      return error;
    }
  }
};

export interface HumanizeDateTimeRangeProps<TDate>
  extends Omit<
    ValidationProps<
      DateTimeRangeValidationError,
      DateRange<TDate>,
      Partial<DateTimeRangeComponentValidationProps<TDate>>
    >,
    "onError"
  > {}

export type HumanizedDateTimeRangeValidationError = [string | undefined, string | undefined];

export const humanizeRangeError = (
  [start, end]: DateTimeRangeValidationError,
  { value: [startValue, endValue], ...props }: HumanizeDateTimeRangeProps<Dayjs>
): HumanizedDateTimeRangeValidationError => {
  const startError = humanizeRangeErrorValue(start, {
    ...props,
    value: startValue,
  });
  const endError = humanizeRangeErrorValue(end, { ...props, value: endValue });
  return [startError, endError];
};
