import { Fraction, Percent } from "@uniswap/sdk-core";
import { AbstractNativeCurrency } from "./AbstractNativeCurrency";

export const ADDRESS_ZERO = `0x${"0".repeat(40)}`;

// one basis JSBI.BigInt
export const BIPS_BASE = 10_000;

export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(100, BIPS_BASE); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(300, BIPS_BASE); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(500, BIPS_BASE); // 5%

export const ZERO_PERCENT = new Percent("0");
export const ONE_HUNDRED_PERCENT = new Percent("1");

// 5 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 5 * 60;

export const ZERO_FRACTION = new Fraction("0");

export const MAX_HIGH_SLIPPAGE = new Percent(1, 2);
export const MIN_HIGH_SLIPPAGE = new Percent(1, 100);

export const ABSTRACT_NATIVE_CURRENCY = new AbstractNativeCurrency();

export enum SwapV2DEXType {
  SYNC = "sync",
  UNISWAP = "uniswap",
}

export const SYNC_SWAP_FACTORY_ADDRESS = "0xf2DAd89f2788a8CD54625C60b55cD3d2D0ACa7Cb";

export enum SwapV3DEXType {
  UNISWAP = "uniswap",
  LYNEX = "lynex",
}
