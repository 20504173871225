/* eslint-disable react/jsx-key */
import { observer } from "mobx-react-lite";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { Loader } from "src/components/shared/Loader";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useStep } from "src/hooks/useStep";
import { DEXV2CreatingModuleNames } from "src/state/DEXV2/DEXV2Create/Create";
import { FirstTabStepProps, RoadMapSteps } from "..";
import { StepHeading } from "../shared/StepHeading";
import { BaseSettings } from "./BaseSettings";
import { CounterSettings } from "./CounterSettings";
import { LimitSettings } from "./LimitSettings";
import { MainSettings } from "./MainSettings";
import { VolumeSettings } from "./VolumeSettings";
import * as styles from "./style";

type SettingsRoadMapSteps<T extends DEXV2CreatingModuleNames = DEXV2CreatingModuleNames> =
  T extends DEXV2CreatingModuleNames ? RoadMapSteps<T> : never;

const DEX_SETTINGS_STEPS: SettingsRoadMapSteps[] = [
  { value: "base_data", label: "Base settings" },
  { value: "main_data", label: "Main settings" },
  { value: "volume_data", label: "Volume settings" },
  { value: "limit_data", label: "Limit settings" },
  { value: "counter_data", label: "Counter settings" },
];

const DEX_SETTINGS_MOBILE_STEPS: SettingsRoadMapSteps[] = [
  { value: "base_data", label: "Base" },
  { value: "main_data", label: "Main" },
  { value: "volume_data", label: "Volume" },
  { value: "limit_data", label: "Limit" },
  { value: "counter_data", label: "Counter" },
];

interface ProfileSettingsProps extends FirstTabStepProps {}

const SETTINGS_FORM_STEPS_CONTENT = [
  <BaseSettings />,
  <MainSettings />,
  <VolumeSettings />,
  <LimitSettings />,
  <CounterSettings />,
];

export const ProfileSettings = observer(({ nextStep: nextRoadMapStep }: ProfileSettingsProps) => {
  const state = useLateInitContext(DEXV2CreatingContext);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const formContent = SETTINGS_FORM_STEPS_CONTENT;
  const formSteps = isMobile ? DEX_SETTINGS_MOBILE_STEPS : DEX_SETTINGS_STEPS;
  const stepsCount = formSteps.length;
  const lastStep = stepsCount - 1;

  const [step, { nextStep, prevStep, canGoToPrev }] = useStep(stepsCount);

  const switchNextStep = () => {
    const nextModule = formSteps[step].value;
    if (step !== lastStep) {
      return state.nextStepHandler(nextModule, nextStep);
    }
    return state.finishStepHandler(nextRoadMapStep);
  };

  const chainInfoProps = state.chainMeta;

  return (
    <styles.Container>
      <StepHeading title="Add profile" {...chainInfoProps} />

      <styles.SettingsRoadMap items={formSteps} currentStep={step} />

      <styles.SettingsForm>
        <styles.Content>{formContent[step]}</styles.Content>

        <styles.ButtonsGroup>
          {canGoToPrev ? <OutlineButton onClick={prevStep}>Step back</OutlineButton> : null}

          {step !== lastStep ? (
            <Button onClick={switchNextStep()}>Next</Button>
          ) : (
            <Button onClick={switchNextStep()}>Create Profile</Button>
          )}
        </styles.ButtonsGroup>

        <Loader show={state.loading} />
      </styles.SettingsForm>
    </styles.Container>
  );
});
