import { AreaData, AreaStyleOptions } from "lightweight-charts";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { darken, rgba } from "polished";
import { useMemo } from "react";
import { GraphLegendOptions, SeriesProps } from "src/components/shared/Graph/types";
import { ArbitrageContext } from "src/context/CEX/Dashboard/v2/Arbitrage";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ArbitrageData } from "src/state/CEX/CEXDashboard/v2/widgets/ArbitrageV2Store";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { useGraphsChartOptions } from "../../../shared/config/graphs";
import { useDashboardColors } from "../../../shared/hooks/useDashboardColors";
import { DashboardV2Widget, getWidgetTitle } from "../shared/constants";
import { StyledGraph } from "../shared/style";
import { ArbitrageChartLegend } from "./ArbitrageChartLegend";
import { ArbitrageChartTooltip } from "./ArbitrageChartTooltip";
import * as styles from "./style";

interface AreaColorOptions
  extends Pick<AreaStyleOptions, "lineColor" | "topColor" | "bottomColor"> {}

const useMaxPriceAreaColor = () => {
  const {
    accent: { dodgerBlue, coralPink },
  } = useDashboardColors();

  const topColor = coralPink;
  const bottomColor = dodgerBlue;

  const colors = useMemo((): AreaColorOptions => {
    const darkenTopColor = darken(0.2, topColor);
    const topAlphaColor = rgba(darkenTopColor, 0.4);
    const bottomAlphaColor = rgba(bottomColor, 0.2);
    const lineColor = rgba(topColor, 0.75);
    return {
      lineColor,
      topColor: topAlphaColor,
      bottomColor: bottomAlphaColor,
    };
  }, [bottomColor, topColor]);

  return colors;
};

const useMinPriceAreaColor = () => {
  const {
    backgroundBaseDefault,
    accent: { dodgerBlue },
  } = useDashboardColors();

  const topColor = dodgerBlue;
  const backgroundColor = backgroundBaseDefault;

  const colors = useMemo((): AreaColorOptions => {
    const lineColor = rgba(topColor, 0.75);
    return {
      lineColor,
      topColor: backgroundColor,
      bottomColor: backgroundColor,
    };
  }, [backgroundColor, topColor]);

  return colors;
};

interface UseSeriesProps {
  data: ArbitrageData;
}

const useSeriesData = ({ data }: UseSeriesProps): SeriesProps[] => {
  const maxPriceColors = useMaxPriceAreaColor();
  const minPriceColors = useMinPriceAreaColor();

  const seriesProps = useMemo((): SeriesProps[] => {
    const { maxPrice, minPrice } = toJS(data);

    const series: SeriesProps[] = [
      {
        type: "Area",
        data: maxPrice as AreaData[],
        visible: true,
        title: "Max Price",
        side: "left",
        showAreaColor: false,
        options: {
          lineWidth: 1,
          ...maxPriceColors,
        },
      },
      {
        type: "Area",
        data: minPrice as AreaData[],
        visible: true,
        title: "Min Price",
        side: "left",
        showAreaColor: false,
        options: {
          lineWidth: 1,
          ...minPriceColors,
        },
      },
    ];
    return series;
  }, [data, maxPriceColors, minPriceColors]);

  return seriesProps;
};

export interface ArbitrageChartProps extends Omit<CardProps, "title"> {}

export const ArbitrageChart = observer((props: ArbitrageChartProps) => {
  const state = useLateInitContext(ArbitrageContext.Context);

  const { data, loading } = state;

  const { options } = useGraphsChartOptions();

  const seriesProps = useSeriesData({ data });

  const legendOptions = useMemo(
    (): GraphLegendOptions => ({
      show: true,
      legend: ArbitrageChartLegend,
      position: "top",
    }),
    []
  );

  return (
    <styles.ArbitrageChartCard
      title={getWidgetTitle(DashboardV2Widget.Arbitrage)}
      loading={loading}
      {...props}
    >
      <StyledGraph
        series={seriesProps}
        allowTimeScale={false}
        autoColorScales={false}
        options={options}
        legendOptions={legendOptions}
        showInfo={false}
        tooltip={ArbitrageChartTooltip}
      />
    </styles.ArbitrageChartCard>
  );
});

export interface ArbitrageChartWidgetProps extends ArbitrageChartProps {}

export const ArbitrageChartWidget = (props: ArbitrageChartWidgetProps) => (
  <ArbitrageContext.Provider>
    <ArbitrageChart {...props} />
  </ArbitrageContext.Provider>
);
