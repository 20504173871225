import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { Menu } from "src/components/shared/SwitchMenu/style";
import { Tabs as BaseTabs } from "src/components/shared/Tabs";
import { Panel } from "src/components/shared/Tabs/style";
import styled from "styled-components";

export const ContainerMobile = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  box-sizing: border-box;
`;

export const ExchangeTabs = styled(BaseTabs)`
  ${Menu} {
    display: flex;
  }

  ${Panel} {
    margin-bottom: 5px;
  }
`;

export const ExchangeContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  flex: 1;

  gap: 10px;
`;

export const OrderBookWrapper = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  gap: 10px;

  @media (max-width: 880px) {
    gap: 5px;
  }
`;

export const AccountContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 450px;

  display: flex;
  flex-direction: column;

  gap: 10px;
`;

export const SettingsContainer = styled.div`
  width: 100%;

  display: flex;
`;

export const SettingsContainerFallback = styled(CrashPanel)`
  height: 40%;
`;

export const OrdersContainer = styled.div`
  width: 100%;
  height: 0px;

  /* height: 600px; */

  display: flex;
  flex-direction: column;

  flex: 1 1 auto;

  gap: 5px;
`;

export const PNLWrapperFallback = styled(CrashPanel)`
  height: 70px;
`;

export const TVChartFallback = styled(CrashPanel)`
  height: 50% !important;
`;

export const TradePanelFallback = styled(CrashPanel)`
  height: 50% !important;
`;
