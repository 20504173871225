import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { BaseModuleForm } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategyModuleCreateType } from "src/modules/expertSystem";
import { getEmptyActionModule } from "../shared/utils";
import { TradeContentForm } from "./TradeContentForm";

const MODULE_TYPE = "trade";

export const Trade = observer(() => {
  const state = useLateInitContext(CreateModulesContext);
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<IStrategyModuleCreateType<typeof MODULE_TYPE>>({
    defaultValues: getEmptyActionModule(MODULE_TYPE),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <BaseModuleForm
        onSubmit={handleSubmit(state.createActionHandler(close))}
        formId={MODULE_TYPE}
      >
        <TradeContentForm />
      </BaseModuleForm>
    </FormProvider>
  );
});
