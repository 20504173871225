import styled from "styled-components";
import { HighlightCell } from "../../shared";

export const Amount = styled(HighlightCell)`
  text-align: right;
  padding-right: 5px;
`;

export const Total = styled(HighlightCell)`
  text-align: right;
`;
