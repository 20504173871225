import styled from "styled-components";
import { List } from "../List";
import { Content, ListContentHeight } from "../List/style";

export const HierarchyList = styled(List)`
  @media screen and (max-width: 500px) {
    & ${Content} {
      ${ListContentHeight(154)}
    }
  }
`;
