import { ComponentPropsWithoutRef, memo } from "react";
import { TimeShortcut, formatElapsedTime, unixToDateFormat } from "src/helpers/dateUtils";
import { entries } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { ModeInfoItem } from "./ModeInfoItem";
import * as styles from "./style";

interface GetTradeTimeTextOptions {
  formatType?: "short" | "full";
}

export const getTradeTimeText = (
  time: number | undefined,
  { formatType = "short" }: GetTradeTimeTextOptions = {}
) => {
  if (typeof time !== "number") return null;

  if (time <= 0) return "No trade";

  const format: TimeShortcut = formatType === "short" ? "FullTime" : "FullDate";

  const timeText = unixToDateFormat(time, format);

  return timeText;
};

const getTradeElapsedTime = (time: number | undefined) => {
  if (typeof time !== "number" || time <= 0) return null;

  return formatElapsedTime(time);
};

type ModeInfoValue =
  | {
      type: "message";
      value: string | undefined;
    }
  | { type: "time"; value: number | undefined };

const getModeInfoTextValue = ({ value, type }: ModeInfoValue): ModeInfoItemData["value"] | null => {
  switch (type) {
    case "message": {
      if (typeof value !== "string") return null;
      return !value ? "-" : value;
    }
    case "time": {
      const timeText = getTradeTimeText(value, { formatType: "full" });
      const elapsedTimeText = getTradeElapsedTime(value);
      if (!timeText || !elapsedTimeText) return null;
      return { time: timeText, elapsedTime: elapsedTimeText };
    }
  }
};

type TimeModeInfoItem = {
  label: string;
  value: {
    time: string;
    elapsedTime: string;
  };
};

type MessageModeInfoItem = {
  label: string;
  value: string;
};

export type ModeInfoItemData = TimeModeInfoItem | MessageModeInfoItem;

const getModeInfoItems = ({
  nextTrade,
  lastTrade,
  message,
}: ModeInfoOwnProps): ModeInfoItemData[] => {
  const items = {
    "Next trade": getModeInfoTextValue({ value: nextTrade, type: "time" }),
    "Last trade": getModeInfoTextValue({ value: lastTrade, type: "time" }),
    Message: getModeInfoTextValue({ value: message, type: "message" }),
  };

  const infoItems = filterBoolean(
    entries(items).map(([key, value]) => {
      if (!value) return null;
      return { label: key, value };
    })
  ) as ModeInfoItemData[];

  return infoItems;
};

export interface ModeInfoOwnProps {
  nextTrade?: number;
  lastTrade?: number;
  message?: string;
  title?: string;
}
export interface ModeInfoProps
  extends Omit<ComponentPropsWithoutRef<"div">, keyof ModeInfoOwnProps>,
    ModeInfoOwnProps {}

export const ModeInfo = memo(
  ({ nextTrade, lastTrade, message, title, ...props }: ModeInfoProps) => {
    const items = getModeInfoItems({ nextTrade, lastTrade, message });

    return (
      <styles.Container {...props}>
        {title && <styles.Title>{title}</styles.Title>}
        {items.map((item) => (
          <ModeInfoItem key={item.label} item={item} />
        ))}
      </styles.Container>
    );
  }
);
