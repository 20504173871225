import { observer } from "mobx-react-lite";
import { formatFiat } from "src/helpers/string";
import { SeriesSingleDataValue, TooltipSeriesData } from "src/state/Graph/Tooltip/types";
import {
  GraphTooltipSeriesContent,
  GraphTooltipSeriesContentProps,
  useSeriesData,
} from "../../../../../../../shared/Graph/GraphTooltip/GraphTooltipSeriesContent";

export interface BaseBalanceSeriesProps extends Pick<TooltipSeriesData, "title"> {}

export const BaseBalanceSeries = observer(({ title: seriesTitle }: BaseBalanceSeriesProps) => {
  const { title, color, value } = useSeriesData(seriesTitle);

  const price = value as SeriesSingleDataValue;

  const titleText = `${title}:`;
  const formattedPrice = formatFiat(price);

  const contentProps: GraphTooltipSeriesContentProps = {
    color,
    title: titleText,
    value: formattedPrice,
  };

  return <GraphTooltipSeriesContent {...contentProps} />;
});
