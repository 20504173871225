/* eslint-disable react/jsx-key */
import { useLayoutEffect, useMemo } from "react";
import {
  TableProps as BaseTableProps,
  Column,
  PluginHook,
  Row,
  TableInstance,
  TableOptions,
  emptyRenderer,
  useFlexLayout,
  useSortBy,
  useTable,
} from "react-table";
import {
  TableTotalizerProvider,
  useTableTotalizer,
} from "src/context/CEX/Dashboard/TableTotalizer";
import { ElementRef } from "src/helpers/utils";
import {
  ColumnTotalCb,
  ColumnsTotalMap,
  ITableTotalizerOptions,
} from "src/state/CEX/CEXDashboard/shared/TableTotalizer";
import { TablePropsGetters } from "../../../LiquidityTab/base/Table";
import * as styles from "./style";

const DEFAULT_PLUGINS = [useSortBy, useFlexLayout];

const EMPTY_OPTIONS: any[] = [];

export const setTableColumnWidth = (
  width: number,
  minWidth = width
): Pick<Column<any>, "width" | "maxWidth" | "minWidth"> => ({ width, minWidth });

interface UseTableOptionsParams<D extends object> extends Partial<TableOptions<D>> {
  plugins?: Array<PluginHook<D>>;
}

interface UseTableOptionsResponse<D extends object> {
  options: TableOptions<D>;
  plugins: Array<PluginHook<D>>;
}

export const useTableOptions = <D extends object>({
  data = EMPTY_OPTIONS,
  columns = EMPTY_OPTIONS,
  plugins = EMPTY_OPTIONS,
  ...options
}: UseTableOptionsParams<D>): UseTableOptionsResponse<D> => {
  const tableData = useMemo(() => data.slice(), [data]);

  return {
    options: { data: tableData, columns, autoResetSortBy: false, ...options },
    plugins: [...plugins, ...DEFAULT_PLUGINS],
  };
};
export interface UseTableInstanceParams<D extends object>
  extends Partial<Pick<TableOptions<D>, "data" | "columns" | "defaultColumn">> {
  instance?: TableInstance<D>;
  plugins?: Array<PluginHook<D>>;
}

const EMPTY_TABLE_OPTIONS: UseTableOptionsParams<any> = {
  data: [],
  columns: [],
  defaultColumn: {},
  plugins: [],
};

const useTableInstance = <D extends object>({
  data,
  columns,
  defaultColumn,
  instance: outerInstance,
  plugins: outerPlugins,
}: UseTableInstanceParams<D>) => {
  const tableOptionsParams = outerInstance
    ? EMPTY_TABLE_OPTIONS
    : {
        data,
        columns,
        defaultColumn,
        plugins: outerPlugins,
      };

  const { options, plugins } = useTableOptions(tableOptionsParams);
  const instance = useTable(options, ...plugins);

  return outerInstance || instance;
};

interface UseFooterVisibleParams<D extends object>
  extends Pick<StatsTableRootProps<D>, "showFooter">,
    Pick<TableInstance<D>, "footerGroups"> {}

const useFooterVisible = <D extends object>({
  showFooter,
  footerGroups,
}: UseFooterVisibleParams<D>) => {
  const isFooterVisible = useMemo(() => {
    if (showFooter !== undefined) return showFooter;
    const isEmptyFooter = footerGroups.every((group) =>
      group.headers.every((column) => column.Footer === emptyRenderer)
    );
    return !isEmptyFooter;
  }, [footerGroups, showFooter]);

  return isFooterVisible;
};

export interface StatsTableInstanceProps<D extends object> extends UseTableInstanceParams<D> {}
export interface StatsTableRootProps<D extends object>
  extends StatsTableInstanceProps<D>,
    BaseTableProps {
  ownRef?: ElementRef<"table">;
  showFooter?: boolean;
  extraProps?: TablePropsGetters<D>;
  onRowsChanged?: (rows: Row<D>[]) => void;
}

const StatsTableRoot = <D extends object>({
  data,
  columns,
  defaultColumn,
  ownRef,
  showFooter,
  instance: outerInstance,
  plugins,
  onRowsChanged,
  extraProps: { tableProps, headerProps, rowProps, cellProps, footerProps } = {},
  ...props
}: StatsTableRootProps<D>) => {
  const instance = useTableInstance({
    data,
    columns,
    defaultColumn,
    instance: outerInstance,
    plugins,
  });

  const { footerGroups, rows, headerGroups, prepareRow, getTableProps, getTableBodyProps } =
    instance;

  const { setRows } = useTableTotalizer<D, ColumnsTotalMap<D>>();

  useLayoutEffect(() => {
    setRows(rows);
    onRowsChanged?.(rows);
  }, [onRowsChanged, rows, setRows]);

  const isFooterVisible = useFooterVisible({ showFooter, footerGroups });

  return (
    <styles.TableContent {...getTableProps(tableProps)} {...props} ref={ownRef}>
      <styles.TableHeader>
        {headerGroups.map((headerGroup) => (
          <styles.HeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <styles.TableHeaderCell
                {...column.getHeaderProps((props, meta) => {
                  const outerProps =
                    typeof headerProps === "function"
                      ? headerProps(props, meta)
                      : headerProps || [];
                  const extraProps = [props].concat(column.getSortByToggleProps(), outerProps);
                  return extraProps;
                })}
              >
                <styles.ColumnSortIcon $column={column} />
                {column.canFilter ? column.render("Filter") : null}
                {column.render("Header")}
              </styles.TableHeaderCell>
            ))}
          </styles.HeaderRow>
        ))}
      </styles.TableHeader>

      <styles.TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <styles.Row {...row.getRowProps(rowProps)}>
              {row.cells.map((cell) => (
                <styles.TableCell {...cell.getCellProps(cellProps)}>
                  {cell.render("Cell")}
                </styles.TableCell>
              ))}
            </styles.Row>
          );
        })}
      </styles.TableBody>

      {isFooterVisible ? (
        <styles.TableFooter>
          {footerGroups.map((group) => (
            <styles.FooterRow {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <styles.TableFooterCell {...column.getFooterProps(footerProps)}>
                  {column.render("Footer")}
                  {column.Footer === emptyRenderer}
                </styles.TableFooterCell>
              ))}
            </styles.FooterRow>
          ))}
        </styles.TableFooter>
      ) : null}
    </styles.TableContent>
  );
};

export interface StatsTableProps<
  D extends object,
  T extends ColumnsTotalMap<D> = ColumnsTotalMap<D>,
> extends StatsTableRootProps<D> {
  getColumnsTotal?: ColumnTotalCb<D, T>;
}

export const StatsTable = <
  D extends object = {},
  T extends ColumnsTotalMap<D> = ColumnsTotalMap<D>,
>({
  getColumnsTotal,
  ...props
}: StatsTableProps<D, T>) => {
  const totalizerOptions: ITableTotalizerOptions<D, T> = {
    columnTotalCb: getColumnsTotal,
  };

  return (
    <TableTotalizerProvider options={totalizerOptions}>
      <StatsTableRoot {...props} />
    </TableTotalizerProvider>
  );
};
