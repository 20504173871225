import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { GroupBase } from "react-select";
import { SelectorProps } from "src/components/shared/Forms/Selectors";
import { StringSelectorValue } from "src/modules/shared";
import { useDashboardState } from "../../../shared/hooks/useDashboardState";
import * as styles from "./style";

export interface ExchangesSelectorProps extends ComponentPropsWithoutRef<"div"> {
  exchangeSelectorProps?: Omit<
    SelectorProps<StringSelectorValue, true, GroupBase<StringSelectorValue>>,
    "value" | "onChange" | "options"
  >;
}

export const ExchangesSelector = observer(
  ({ exchangeSelectorProps, ...props }: ExchangesSelectorProps) => {
    const state = useDashboardState();
    return (
      <styles.ExchangeSelectorWrapper {...props}>
        <styles.ExchangeSelector
          placeholder="exchange"
          controlShouldRenderValue={false}
          {...state.selectorProps("exchange")}
          {...exchangeSelectorProps}
        />

        <styles.ExchangeSelectPanel {...state.selectProps("exchange")} />
      </styles.ExchangeSelectorWrapper>
    );
  }
);
