import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 7px;
`;

export const PairChangeFallback = styled(CrashPanel)`
  grid-area: pair-selector;

  height: 30px;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;

  display: block;
  outline: none;
  border: none;

  margin: 0;
  padding-left: 5px;

  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  line-height: ${({ theme }) => theme.lineHeights["14px"]};
  color: ${({ theme }) => theme.blueGray};

  background: ${({ theme }) => theme.bgGrayBlack};
  border: ${({ theme }) => theme.borders.solidGray};
  border-radius: ${({ theme }) => theme.radii["4px"]};
`;

export const Button = styled.div`
  height: 30px;
  width: 35px;

  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background: ${({ theme }) => theme.bgGrayBlack};
  border: ${({ theme }) => theme.borders.solidGray};
  border-radius: ${({ theme }) => theme.radii["4px"]};
`;
