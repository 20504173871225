import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { IExchangeModule } from "src/state/CEX/botModules";
import { ModulePanel } from "../ModulePanel";
import { ExchangeModuleForm } from "./ExchangeModuleForm";

export interface ExchangeModuleProps {
  module: IExchangeModule;
}

export const ExchangeModule = observer(({ module }: ExchangeModuleProps) => {
  const methods = useForm<IExchangeModule>({
    defaultValues: module,
  });

  return (
    <FormProvider {...methods}>
      <ModulePanel module={module}>
        <ExchangeModuleForm />
      </ModulePanel>
    </FormProvider>
  );
});
