import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { GenerateTimeSeriesOptions, generateTime, generateTimeSeries } from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type ArbitrageData = {
  maxPrice: ChartPoint[];
  minPrice: ChartPoint[];
};

const INITIAL_DATA: ArbitrageData = {
  maxPrice: [],
  minPrice: [],
};

const generateArbitrageData = (): ArbitrageData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 12, max: 100 });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    startTimestamp,
    count: pointsCount,
  };

  const maxPrice = generateTimeSeries({
    ...generateSeriesOptions,
    ...{ value: { min: 50_000, max: 100_000 } },
  });
  const minPrice = generateTimeSeries({
    ...generateSeriesOptions,
    ...{ value: { min: 0, max: 50_000 } },
  });

  return { maxPrice, minPrice };
};

export class ArbitrageV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: ArbitrageData = INITIAL_DATA;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBaseDashboardV2StoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { data: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: ArbitrageData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData(INITIAL_DATA);
    try {
      await delay(200);
      const data = generateArbitrageData();
      this._setData(data);
    } catch {
      this._setData(INITIAL_DATA);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
