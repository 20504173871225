import { observer } from "mobx-react-lite";
import { Button, ButtonProps } from "src/components/shared/Buttons/v2/Button";
import { BotWalletConnectionContext } from "src/context/DEXV2/DEXV2Stats/Vaults/BotWalletConnection";
import { useLateInitContext } from "src/hooks/useLateInitContext";

export interface ConnectWalletButtonProps extends Omit<ButtonProps, "onClick" | "color"> {}

export const ConnectWalletButton = observer((props: ConnectWalletButtonProps) => {
  const state = useLateInitContext(BotWalletConnectionContext);

  return (
    <Button onClick={state.connectWallet} {...props}>
      Connect wallet
    </Button>
  );
});
