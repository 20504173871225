import { Column } from "@tanstack/react-table";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup } from "src/hooks/useClosePopup";
import * as styles from "./style";

interface ColumnFilterProps {
  column: Column<any, unknown>;
  defaultFilter?: string;
  label?: string;
}

export const ColumnFilter = ({ label, column, defaultFilter }: ColumnFilterProps) => {
  const [showPanel, setShowPanel] = useState(false);

  const panel = useRef<HTMLDivElement>(null);

  useClosePopup(panel, setShowPanel);

  const uniqueValues = column.getFacetedUniqueValues();

  useEffect(() => {
    if (defaultFilter) column.setFilterValue(defaultFilter);
  }, [defaultFilter, column]);

  const sortedUniqueValues = useMemo(() => Array.from(uniqueValues.keys()).sort(), [uniqueValues]);
  const getSkipLabel = (b: boolean) => (b ? "Skip" : "Don`t Skip");
  const getSkipValue = (val: string) => val === "true";

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>, option: any) => {
    const val = e.target.value;

    column.setFilterValue(typeof option === "boolean" ? getSkipValue(val) : val);
  };

  return (
    <styles.Container ref={panel}>
      <styles.Wrapper onClick={() => setShowPanel(!showPanel)}>
        <styles.ColumnName>{label}</styles.ColumnName>

        <styles.Row check={showPanel} />
      </styles.Wrapper>

      {showPanel ? (
        <styles.Content>
          <styles.CheckFilter
            onChange={(e) => column.setFilterValue(e.target.value)}
            key="all_checkbox"
            label="All"
            value={undefined}
            checked={column.getFilterValue() === undefined}
          />

          {sortedUniqueValues.map((option, i: number) => (
            <styles.CheckFilter
              onChange={(e) => onFilterChange(e, option)}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              label={typeof option === "boolean" ? getSkipLabel(option) : option}
              value={option}
              checked={column.getFilterValue() === option}
            />
          ))}
        </styles.Content>
      ) : null}
    </styles.Container>
  );
};
