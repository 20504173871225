import { ComponentPropsWithoutRef } from "react";
import { InfoIconTooltip } from "src/components/BotsContent/CEX/shared/Card/Header/InfoIconTooltip";
import * as styles from "./style";

export interface HeaderProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
  icon?: JSX.Element;
  info?: string;
}

export const Header = ({ title, icon, info, ...props }: HeaderProps) => (
  <styles.Container {...props}>
    {icon && <styles.TitleIconWrapper>{icon}</styles.TitleIconWrapper>}
    <styles.Title>{title}</styles.Title>
    <InfoIconTooltip info={info} />
  </styles.Container>
);
