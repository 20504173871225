import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { LabeledCreatableSelector, LabeledSelector } from "src/components/shared/Forms/Selectors";
import { ModalPanel, ModalPanelProps } from "src/components/shared/ModalPanel";
import { useSubscribableStore } from "src/hooks/useSubscribableStore";
import AddModalModuleStore from "src/state/CEX/multiGrid/AddModuleModal";
import { IModuleCreator } from "src/state/CEX/multiGrid/CreateMultiGrid";
import * as styles from "./style";

interface AddModuleModalProps extends Pick<ModalPanelProps, "show" | "close"> {
  moduleCreatorState: IModuleCreator;
}

export const AddModuleModal = observer(
  ({ moduleCreatorState: gridState, show, close }: AddModuleModalProps) => {
    const state = useMemo(() => new AddModalModuleStore(gridState, close), [gridState, close]);

    useEffect(() => {
      state.getAccounts();
    }, [state]);

    const handleCreate = (inputValue: string) => {
      state.setNewPair(inputValue);
      state.changePair(String(inputValue));
    };

    useSubscribableStore(state);

    return (
      <ModalPanel label="Add module" show={show} close={close} loading={state.isLoading}>
        <styles.AddForm onSubmit={state.submitHandler()}>
          <styles.InputWrapper>
            <LabeledSelector
              label="Exchange"
              options={state.creatorState.accountsState.exchOptions}
              value={state.selectorValue("id")}
              onChange={state.onSelectorChange("id")}
              errorHint={state.errors.id}
            />
            <LabeledSelector
              label="Account"
              options={state.accountList}
              value={state.currentAccount}
              onChange={state.onSelectorChange("settings.accountUUID")}
              errorHint={state.errors.settings?.accountUUID}
              isDisabled={!state.exchangeSelected}
            />

            <LabeledCreatableSelector
              label="Pair"
              value={state.selectorValue("pair")}
              options={state.shortPairsList}
              onChange={state.onSelectorChange("pair")}
              onInputChange={state.setSearchPair()}
              onCreateOption={handleCreate}
              errorHint={state.errors.pair}
              isDisabled={!state.exchangeSelected}
            />
          </styles.InputWrapper>

          <styles.InputWrapper>
            <LabeledInput
              type="number"
              label="Amount Dec"
              value={state.module.settings.amountDecimals}
              onChange={state.getHandler("settings.amountDecimals")}
              errorHint={state.errors.settings?.amountDecimals}
            />

            <LabeledInput
              type="number"
              label="Price Dec"
              value={state.module.settings.priceDecimals}
              onChange={state.getHandler("settings.priceDecimals")}
              errorHint={state.errors.settings?.priceDecimals}
            />

            <LabeledPercentRangeInput
              label="Maker Fee %"
              max="1"
              step="0.01"
              value={state.module.settings.makerFee}
              onChange={state.getHandler("settings.makerFee")}
              errorHint={state.errors.settings?.makerFee}
            />
          </styles.InputWrapper>

          <SubmitButton size="m">Add</SubmitButton>
        </styles.AddForm>
      </ModalPanel>
    );
  }
);
