import { observer } from "mobx-react-lite";
import { useState } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { AnalyticsContext } from "src/context/CEX/Analytics";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { AnalyticModal } from "./AddPairAnalytic";
import { Pair } from "./Pair";
import * as styles from "./style";

export const SelectionPanel = observer(() => {
  const state = useLateInitContext(AnalyticsContext);

  const [isShownAddModal, setIsShownAddModal] = useState(false);

  const [isShownEditModal, setIsShownEditModal] = useState(false);

  return (
    <styles.Container>
      <styles.Content>
        {state.analytics.map((el) => (
          <Pair
            key={el.pair}
            pairAnalytic={el}
            shownModal={setIsShownAddModal}
            shownEditModal={setIsShownEditModal}
          />
        ))}
      </styles.Content>

      {isShownAddModal ? (
        <AnalyticModal
          bot_uuid={state.botUUID}
          market={state.market}
          label="Add new analytic"
          type="add"
          show={isShownAddModal}
          close={setIsShownAddModal}
          pair={state.newPair}
          saveAnalytic={state.createAnalytic}
          setPairCb={state.setPair}
        />
      ) : null}

      {isShownEditModal ? (
        <AnalyticModal
          bot_uuid={state.botUUID}
          market={state.market}
          label="Edit analytic"
          type="edit"
          show={isShownEditModal}
          close={setIsShownEditModal}
          pair={state.pairEditAnalytic}
          saveAnalytic={state.editNameAnalytic}
          setPairCb={state.setPair}
        />
      ) : null}

      <PermissionWrapper abilityName="cex-candle-analytics-edit" party={state.party}>
        <OutlineButton
          onClick={() => {
            state.createNewPairAnalytic(state.currentPair);
            setIsShownAddModal(true);
          }}
          stretched
        >
          Add pair +
        </OutlineButton>
      </PermissionWrapper>
    </styles.Container>
  );
});
