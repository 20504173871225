import { createContextProvider } from "src/context/utils/createContextProvider";
import useLocalStore from "src/hooks/useLocalStore";
import { DEXSettingsStore } from "src/state/DEX/DEXSettings/DEXSettings";

const DEXSettingsStoreCreator = () => useLocalStore(DEXSettingsStore);

const { Context: DEXSettingsContext, Provider: DEXSettingsProvider } =
  createContextProvider<DEXSettingsStore>({
    stateCreator: DEXSettingsStoreCreator,
  });

export { DEXSettingsContext, DEXSettingsProvider };
