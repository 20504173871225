import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import {
  generateAccountName,
  generateAmount,
  generateExchange,
  generateTimeMs,
  generateTokenTicker,
} from "../../shared/mocks";

const FUNDING_TYPE = ["Deposit", "Withdraw"] as const;
export type FundingType = (typeof FUNDING_TYPE)[number];

export type FundingData = {
  type: FundingType;
  amount: string;
  currency: string;
  exchange: string;
  account: string;
  time: number;
};

export type FundingDataKeys = keyof FundingData;

const generateFundingType = () => faker.helpers.arrayElement(FUNDING_TYPE);

const generateFunding = (): FundingData => ({
  type: generateFundingType(),
  amount: generateAmount(),
  currency: generateTokenTicker(),
  exchange: generateExchange(),
  account: generateAccountName(),
  time: generateTimeMs(),
});

const generateFundings = (): FundingData[] => {
  const fundings = faker.helpers.multiple(generateFunding, {
    count: { min: 3, max: 10 },
  });
  return fundings;
};

export default class FundingStore implements IDisposable {
  private _data: FundingData[] = [];

  constructor() {
    makeAutoObservable(this);

    makeLoggable(this, { data: true });
  }

  private _setData = (data: FundingData[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  getFundings = () => {
    try {
      const fundings = generateFundings();
      this._setData(fundings);
    } catch {
      this._setData([]);
    }
  };

  destroy = () => {};
}
