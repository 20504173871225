import { observer } from "mobx-react-lite";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Headline3 } from "src/components/shared/Typography";
import MultiGridFormStore from "src/state/CEX/multiGrid/MultiGridForm";
import { PanelContainer, Wrapper } from "../../shared";

interface MultiGridFormContentProps {
  state: MultiGridFormStore;
}

export const MultiGridFormContent = observer(({ state }: MultiGridFormContentProps) => (
  <PanelContainer style={{ justifyContent: "start", gap: "20px" }}>
    <Headline3>Settings</Headline3>

    <Wrapper>
      <LabeledInput
        type="number"
        label="Quote"
        value={state.grinder.settings.amountQuote}
        onChange={state.getHandler("settings.amountQuote")}
        errorHint={state.errors.settings?.amountQuote}
      />
      <LabeledInput
        type="number"
        label="Period check (sec.)"
        value={state.grinder.settings.periodCheck}
        onChange={state.getHandler("settings.periodCheck")}
        errorHint={state.errors.settings?.periodCheck}
      />
    </Wrapper>

    <Headline3>Sell / Buy configuration</Headline3>

    <Wrapper>
      <LabeledInput
        type="number"
        label="Sell order number"
        value={state.grinder.settings.countSell}
        onChange={state.getHandler("settings.countSell")}
        errorHint={state.errors.settings?.countSell}
      />
      <LabeledInput
        type="number"
        label="Buy order number"
        value={state.grinder.settings.countBuy}
        onChange={state.getHandler("settings.countBuy")}
        errorHint={state.errors.settings?.countBuy}
      />
    </Wrapper>

    <Wrapper>
      <LabeledInput
        type="number"
        label="Price const"
        value={state.grinder.settings.priceConst}
        onChange={state.getHandler("settings.priceConst")}
        errorHint={state.errors.settings?.priceConst}
      />
    </Wrapper>

    {/* <Warning>
          <styles.TextWrapper>
            <styles.Text>Average price:</styles.Text>
            <styles.Text>15.592</styles.Text>
          </styles.TextWrapper>
        </Warning> */}
  </PanelContainer>
));
