import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { getUTCRangeFromDuration } from "src/helpers/dateUtils";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { useAppState } from "src/state";
import ReportState from "src/state/CEX/CEXStats/Report";
import { BotInfoProps } from "..";
import { BotInfoCEX } from "../BotInfoCEX";
import { BotInfoCEXFallback } from "../BotInfoCEX/style";
import { AssetsBalances } from "./AssetsBalances";
import { BalanceToken } from "./BalanceToken";
import { BalanceUSDT } from "./BalanceUSDT";
import { BalancesGraph } from "./BalancesGraph";
import { BalancesPie } from "./BalancesPie";
import { BalancesTable } from "./BalancesTable";
import { TimeLine } from "./TimeLine";
import * as styles from "./style";

export interface StatsProps extends BotInfoProps {}

export const Stats = observer(({ botInfo }: BotInfoProps) => {
  const { userState } = useAppState();

  const [range, setRange] = useState<DateTimeRange>(() => getUTCRangeFromDuration({ hours: 12 }));

  const state = useMemo(() => new ReportState(range[0]?.unix(), range[1]?.unix()), [range]);

  useEffect(() => {
    if (!botInfo.market) return;
    if (!botInfo.bot_uuid) return;
    state.setMarket(botInfo.market);

    state.setBotUUID(botInfo.bot_uuid);

    state.downloadData();
  }, [botInfo.market, botInfo.bot_uuid, state]);

  return (
    <styles.Container abilities={userState.abilities} party={botInfo.party || ""}>
      <styles.BotContainer>
        <ErrorBoundary fallback={<BotInfoCEXFallback />}>
          <BotInfoCEX botInfo={botInfo} />
        </ErrorBoundary>
      </styles.BotContainer>
      <PermissionWrapper party={botInfo.party} abilityName="cex-settings-view">
        <TimeLine
          botUUID={botInfo.bot_uuid}
          startPoint={range[0]?.unix()}
          endPoint={range[1]?.unix()}
        />
      </PermissionWrapper>

      <styles.StyledPNL party={botInfo.party} range={range} onRangeChange={setRange} />

      <BalancesPie
        showLoader={state.loaderGraphanaStats}
        balancesPieLabels={state.balancesPieLabels}
        balancesPieData={state.balancesPieData}
      />
      <PermissionWrapper party={botInfo.party} abilityName="cex-notes-view">
        <ErrorBoundary fallback={<styles.NotesFallback />}>
          <styles.NotesContainer party={botInfo.party} botUUID={botInfo.bot_uuid} minRows={9} />
        </ErrorBoundary>
      </PermissionWrapper>
      <BalancesGraph
        showLoader={state.loaderGraphanaBalanceSum}
        balanceTableData={state.balanceTableData}
      />
      <BalancesTable
        showLoader={state.loaderGraphanaStats}
        balanceTableData={state.balanceTableData}
        usdTotalPercent={state.usdTotalPercent}
        deltaTotal={state.allTotal}
      />
      <BalanceUSDT
        showLoader={state.loaderGraphanaBalanceTotal}
        data={state.balanceTotalUSDTData}
        setRange={setRange}
      />
      <BalanceToken
        showLoader={state.loaderGraphanaBalanceSum}
        data={state.balanceTokenData}
        token={state.base}
        setRange={setRange}
      />
      <AssetsBalances
        showLoader={state.loaderGraphanaBalanceSum}
        data={state.assetsBalancesData}
        setRange={setRange}
      />
    </styles.Container>
  );
});
