import { observer } from "mobx-react-lite";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import { entries } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { GraphQuote, GraphsTooltipData } from "src/state/DEXV2/DEXV2Stats/Graphs";
import { SeriesSingleDataValue, TooltipSeriesData } from "src/state/Graph/Tooltip/types";
import { formatQuote } from "../../../../Tables/Vaults/VaultsQuotesTooltip/VaultsPricesTooltipContent";
import * as styles from "./style";

const useGraphQuotes = (quote?: GraphQuote) => {
  if (!quote) return null;

  const { currency, ...quotes } = quote;

  const formattedQuotes = filterBoolean(entries(quotes)).map(([quoteType, quote]) =>
    formatQuote(quoteType, quote)
  );

  return formattedQuotes;
};

export interface TooltipSeriesProps extends Pick<TooltipSeriesData, "title"> {}

export const TooltipSeries = observer(({ title: seriesTitle }: TooltipSeriesProps) => {
  const { seriesDataMap } = useTooltipContext<GraphsTooltipData>();

  const { color, title, value, quote } = seriesDataMap.get(seriesTitle)!;

  const price = value as SeriesSingleDataValue;

  const quotes = useGraphQuotes(quote);

  const hasQuotes = quotes && quotes.length > 0;

  return (
    <styles.Container>
      <styles.SeriesWrapper>
        <styles.NameContainer>
          {color && <styles.Color color={color} />}
          <styles.Name>{title}:</styles.Name>
        </styles.NameContainer>
        <styles.Price>{price}</styles.Price>
      </styles.SeriesWrapper>

      {hasQuotes && <styles.QuotesInfo items={quotes} />}
    </styles.Container>
  );
});
