import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import styled from "styled-components";

export const FilterSelect = styled(LabeledSelector)`
  max-width: 144px;
  min-width: 90px;

  flex: 1;

  & .Custom-select__value-container {
    font-size: 0.7em;
  }
` as typeof LabeledSelector;

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 0;

  display: flex;
  justify-content: flex-end;
  gap: 15px;

  margin-left: 15px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;
