import { FormProvider, useForm } from "react-hook-form";
import { IExchangeModule } from "src/state/CEX/botModules";
import { CreateModule } from "..";
import { ExchangeModuleForm } from "../../Modules/ExchangeModule/ExchangeModuleForm";

export const CreateExchangeModule = () => {
  const methods = useForm<IExchangeModule>({
    defaultValues: { kind: "exchange" },
  });

  return (
    <FormProvider {...methods}>
      <CreateModule>
        <ExchangeModuleForm />
      </CreateModule>
    </FormProvider>
  );
};
