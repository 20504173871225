import { checkParty } from "src/helpers/checkParty";
import { Abilities } from "src/modules/abilities";
import styled from "styled-components";

export interface ContainerProps {
  abilities: Abilities;
  abilityName: string;
  party: string;
}

export const Container = styled.div<ContainerProps>`
  /* height: 100%; */
  grid-area: current-fundings;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  border-right: ${({ abilities, abilityName, party }) => {
    const checkPermsn = checkParty(abilities, abilityName, party);

    if (checkPermsn) return "1px solid #767676";

    return "none";
  }};
  @media (max-width: 900px) {
    padding-right: 0px;
    border-right: none;
  }
`;

export const FundingList = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* min-height: 500px; */
  margin-top: 10px;
  color: ${({ theme }) => theme.textColor};
  height: 300px;
  padding-right: 5px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    width: 3px;
  }
  @media (max-width: 900px) {
    height: 28vh;
  }
`;
