import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import useLocalStore from "src/hooks/useLocalStore";
import StrategyInfoModalStore from "src/state/expertSystem/strategies/StrategyInfoModalStore";
import { StrategyInfoCells } from "./StrategyInfoCells";
import * as styles from "./style";

export interface InfoModalContentProps {
  uuid: string;
}

export const InfoModalContent = observer(({ uuid }: InfoModalContentProps) => {
  const { isLoading, strategy, getStrategy } = useLocalStore(StrategyInfoModalStore);

  useEffect(() => {
    getStrategy(uuid);
  }, [getStrategy, uuid]);

  return (
    <styles.Container>
      <styles.Content>
        {strategy ? <StrategyInfoCells strategy={strategy} /> : null}

        <Loader show={isLoading} />
      </styles.Content>
    </styles.Container>
  );
});
