import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  padding: 0 8px;

  background-color: ${({ theme }) => theme.dashboard.borderDefault};
`;

export const PlaceholderText = styled.span`
  width: 30ch;

  color: ${({ theme }) => theme.dashboard.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes["9px"]};
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
  white-space: initial;
`;
