import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import {
  MultiToggleButton,
  MultiToggleButtonItem,
} from "src/components/shared/Buttons/v2/MultiToggleButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Loader } from "src/components/shared/Loader";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { StopOrder } from "src/modules/exchange/trade";
import { OrderPanelProps } from "../../shared/OrderPanel";
import * as styles from "../../shared/style";

const VALID_DAYS = [
  {
    label: "1 day",
    value: "86400",
  },
  {
    label: "3 days",
    value: "259200",
  },
  {
    label: "7 days",
    value: "604800",
  },
  {
    label: "14 days",
    value: "1209600",
  },
  {
    label: "30 days",
    value: "2592000",
  },
];

const SLIDER_ITEMS: MultiToggleButtonItem<StopOrder["triggerCompare"]>[] = [
  {
    label: "</>",
    value: "",
  },
  {
    label: ">",
    value: "GREATER",
  },
  {
    label: "<",
    value: "LESS",
  },
];

enum Fields {
  BUY = "stopBuyOrder",
  SELL = "stopSellOrder",
}

interface StopOrderFormProps extends Pick<OrderPanelProps, "side" | "formId"> {
  setOrderSize: Dispatch<SetStateAction<number | undefined>>;
}

export const StopOrderForm = observer(({ formId, side, setOrderSize }: StopOrderFormProps) => {
  const { buySellState } = useLateInitContext(ExchangeContext);
  const { stopBuyTotal } = buySellState;

  const { orderType, loading } = useMemo(() => {
    if (side === "BUY") {
      return {
        orderType: Fields[side],

        loading: buySellState.buyLoader,
      };
    }
    return {
      orderType: Fields[side],

      loading: buySellState.sellLoader,
    };
  }, [side, buySellState.buyLoader, buySellState.sellLoader]);

  const { amount } = buySellState[orderType];

  useEffect(() => {
    const totalAsset = stopBuyTotal;
    const orderSize = orderType === "stopBuyOrder" ? parseFloat(totalAsset) : parseFloat(amount);

    setOrderSize(orderSize);
  }, [stopBuyTotal, amount, orderType, setOrderSize]);

  return (
    <styles.BuySellForm id={formId} onSubmit={buySellState.submitStopOrder(orderType)}>
      <styles.TriggerWrapper style={{ flexDirection: "row" }}>
        <MultiToggleButton
          items={SLIDER_ITEMS}
          onChange={buySellState.getTriggerCompareHandler(orderType)}
          value={buySellState.getTriggerCompareValue(orderType)}
          type="button"
          // @ts-ignore
          getError={buySellState.getStopOrderError(orderType, "triggerCompare")}
          errorHint={buySellState[`${orderType}Errors`].triggerCompare}
        />

        <LabeledInput
          type="number"
          step="any"
          label="Trigger price"
          value={buySellState[orderType].triggerPrice}
          onChange={buySellState.getHandler(orderType, "triggerPrice")}
          errorHint={buySellState[`${orderType}Errors`].triggerPrice}
        />
      </styles.TriggerWrapper>
      <LabeledInput
        type="number"
        step="any"
        label="Limit price"
        value={buySellState[orderType].price}
        onChange={buySellState.getHandler(orderType, "price")}
        errorHint={buySellState[`${orderType}Errors`].price}
      />

      <LabeledInput
        type="number"
        step="any"
        label="Amount"
        value={buySellState[orderType].amount}
        onChange={buySellState.getHandler(orderType, "amount")}
        errorHint={buySellState[`${orderType}Errors`].amount}
      />
      <styles.Wrapper>
        <LabeledSelector
          label="Valid for"
          value={{
            value: `${buySellState[orderType].expireIn}`,
            label: `${Math.ceil(+buySellState[orderType].expireIn / 60 / 60 / 24)} days`,
          }}
          options={VALID_DAYS}
          onChange={buySellState.validSelectHandler(orderType)}
          errorHint={buySellState[`${orderType}Errors`].expireIn}
        />

        <LabeledInput
          type="number"
          step="any"
          label="Total"
          value={buySellState[orderType === "stopBuyOrder" ? "stopBuyTotal" : "stopSellTotal"]}
          onChange={
            orderType === "stopBuyOrder"
              ? buySellState.stopBuyTotalHandler()
              : buySellState.stopSellTotalHandler()
          }
        />
      </styles.Wrapper>

      <Loader show={loading} />
    </styles.BuySellForm>
  );
});
