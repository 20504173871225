import { BigNumber } from "ethers";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { DEXV2BotMode } from "src/modules/bots";
import {
  IBotChainInfoProvider,
  IBotUUIDProvider,
  ISwapPairAddressProvider,
} from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { IDEXV2SettingsProvider } from "src/state/DEXV2/DEXV2Settings/DEXV2SettingsInfoStore";
import { IWithdrawerProvider } from "src/state/DEXV2/shared/WithdrawerProvider";
import {
  DEXV2SwapVersionStore,
  ISwapVersionProvider,
} from "../../../Version/DEXV2SwapVersionStore";

export interface ISwapFactoryAddressProvider {
  get factoryAddress(): string | null;
}

export interface ISwapGasLimitProvider {
  get gasLimit(): BigNumber | null;
}

export interface ISwapDexNameProvider {
  get dexName(): string;
}

export type BotSlippages = Record<DEXV2BotMode, string>;

export type BotTransactionFees = {
  buy: string;
  sell: string;
};

export interface ISwapSlippageInfoProvider {
  get slippages(): BotSlippages;

  get slippage(): string;

  get transactionFees(): BotTransactionFees;
}

export interface ISwapSettingsProvider
  extends ISwapFactoryAddressProvider,
    ISwapPairAddressProvider,
    ISwapGasLimitProvider,
    ISwapDexNameProvider,
    Pick<IWithdrawerProvider, "withdrawer">,
    ISwapSlippageInfoProvider,
    IBotUUIDProvider {
  get versionProvider(): ISwapVersionProvider;
}

export interface ISwapSettingsParams {
  settingsProvider: IDEXV2SettingsProvider;
  chainInfoProvider: IBotChainInfoProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  botUUIDProvider: IBotUUIDProvider;
}

export class SwapSettingsProvider implements IDisposable, ISwapSettingsProvider {
  private _versionProvider: ISwapVersionProvider & IDisposable;

  private _settingsProvider: IDEXV2SettingsProvider;

  private _botChainInfoProvider: IBotChainInfoProvider;

  private _pairAddressProvider: ISwapPairAddressProvider;

  private _botUUIDProvider: IBotUUIDProvider;

  constructor({
    settingsProvider,
    chainInfoProvider,
    pairAddressProvider,
    botUUIDProvider,
  }: ISwapSettingsParams) {
    makeAutoObservable(this);

    this._settingsProvider = settingsProvider;

    this._botChainInfoProvider = chainInfoProvider;

    this._pairAddressProvider = pairAddressProvider;

    this._botUUIDProvider = botUUIDProvider;

    this._versionProvider = new DEXV2SwapVersionStore({
      settingsProvider,
    });

    makeLoggable(this, {
      factoryAddress: true,
      pairAddress: true,
      dexName: true,
    });
  }

  get versionProvider(): ISwapVersionProvider {
    return this._versionProvider;
  }

  private get _settings() {
    return this._settingsProvider.settings;
  }

  get botUUID() {
    return this._botUUIDProvider.botUUID;
  }

  get withdrawer() {
    return this._settingsProvider.withdrawer;
  }

  get transactionFees(): BotTransactionFees {
    return {
      buy: this._settings.base_data.tt_buy_fee.toString(),
      sell: this._settings.base_data.tt_sell_fee.toString(),
    };
  }

  // get addresses() {
  //   return this._tradePair?.addresses ?? INITIAL_PAIR_ADDRESSES;
  // }

  get slippages(): BotSlippages {
    return {
      limit: this._settings.limit_data.slippage.toString(),
      volume: this._settings.volume_data.slippage.toString(),
      counter: this._settings.counter_data.slippage.toString(),
    };
  }

  get slippage() {
    return this.slippages.volume;
  }

  get gasLimit() {
    const gasLimit = this._settings.base_data.gas_limit;
    if (!gasLimit) return null;
    return BigNumber.from(gasLimit);
  }

  get dexName() {
    return this._settings.market;
  }

  get pairAddress() {
    return this._pairAddressProvider.pairAddress;
  }

  private get _chainProvider() {
    return this._botChainInfoProvider.chainProvider;
  }

  private get _exchange() {
    return this._botChainInfoProvider.exchange;
  }

  private get _exchangesInfo() {
    return this._chainProvider.currentChainExchanges;
  }

  get factoryAddress() {
    const exchangesInfo = this._exchangesInfo;
    const exchange = this._exchange;

    if (!exchange || !exchangesInfo) return null;

    return exchangesInfo[exchange]?.factory ?? null;
  }

  destroy = () => {
    this._versionProvider.destroy();
  };
}
