import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { ModuleListContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { AddExchangeModule } from "../../AddExchangeModule";
import * as styles from "./style";

export const ListHeader = observer(() => {
  const state = useLateInitContext(ModuleListContext);

  const highlightWorks = useMemo(
    () => state.countActiveModules < state.countModules,
    [state.countActiveModules, state.countModules]
  );

  return (
    <styles.HeaderWrapper>
      <AddExchangeModule label="Exchanges" moduleCreatorState={state} />

      <styles.HeaderWrapper>
        <styles.TitleText>
          Connected: <styles.TitleText>{state.countModules} </styles.TitleText>
          <styles.SubTitle>/{state.countPartyExchanges}</styles.SubTitle>
        </styles.TitleText>

        <styles.TitleText>
          Works:{" "}
          <styles.TitleText highlight={highlightWorks}>
            {state.countActiveModules}{" "}
          </styles.TitleText>
          <styles.SubTitle highlight={highlightWorks}>/{state.countModules}</styles.SubTitle>
        </styles.TitleText>
      </styles.HeaderWrapper>
    </styles.HeaderWrapper>
  );
});
