import { observer } from "mobx-react-lite";
import { useBotsState } from "src/components/AllBots/hooks/useBotsStore";
import { IRecentPartiesProvider } from "src/state/CEX/CEXBots/CEXBots";
import { Party } from "./Party";
import * as styles from "./style";

export const PartiesList = observer(() => {
  const state: IRecentPartiesProvider = useBotsState();

  return (
    <>
      {state.recentParties.length ? (
        <styles.PartiesList>
          {state.recentParties.map((party) => (
            <Party key={party.name} {...party} toggleSelection={state.togglePartySelection} />
          ))}
        </styles.PartiesList>
      ) : (
        <styles.EmptyListText>No new clients</styles.EmptyListText>
      )}
    </>
  );
});
