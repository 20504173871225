import { IChartApi } from "lightweight-charts";
import { isDevEnv } from "src/helpers/utils";

const _getPaneCrosshairCanvas = (chartApi: IChartApi): HTMLCanvasElement | undefined => {
  const chart = chartApi as any;
  if (isDevEnv()) {
    const canvas =
      chart._private__chartWidget?._private__paneWidgets[0]?._private__topCanvasBinding
        ?._canvasElement;
    return canvas;
  }
  const canvas = chart.Vm?.Pv[0]?.Vf?._canvasElement;
  return canvas;
};

export const getPaneCrosshairCanvas = (chart: IChartApi | null) => {
  if (!chart) return null;
  const canvas = _getPaneCrosshairCanvas(chart);
  if (!canvas) return null;
  return canvas;
};

const _getPaneCanvas = (chartApi: IChartApi): HTMLCanvasElement | undefined => {
  const chart = chartApi as any;
  if (isDevEnv()) {
    const canvas =
      chart._private__chartWidget?._private__paneWidgets[0]?._private__canvasBinding
        ?._canvasElement;
    return canvas;
  }
  const canvas = chart.Vm?.Pv[0]?.zf?._canvasElement;
  return canvas;
};

export const getPaneCanvas = (chart: IChartApi | null) => {
  if (!chart) return null;
  const canvas = _getPaneCanvas(chart);
  if (!canvas) return null;
  return canvas;
};
