import { observer } from "mobx-react-lite";
import { formatPercent } from "src/helpers/string";
import { SeriesSingleDataValue, TooltipSeriesData } from "src/state/Graph/Tooltip/types";
import {
  GraphTooltipSeriesContent,
  GraphTooltipSeriesContentProps,
  useSeriesData,
} from "../../../../../shared/Graph/GraphTooltip/GraphTooltipSeriesContent";

export interface SpreadTooltipSeriesProps extends Pick<TooltipSeriesData, "title"> {}

export const SpreadTooltipSeries = observer(({ title: seriesTitle }: SpreadTooltipSeriesProps) => {
  const { title, color, value } = useSeriesData(seriesTitle);

  const percent = value as SeriesSingleDataValue;

  const titleText = `${title}:`;
  const formattedPercent = formatPercent(percent);

  const contentProps: GraphTooltipSeriesContentProps = {
    color,
    title: titleText,
    value: formattedPercent,
  };

  return <GraphTooltipSeriesContent {...contentProps} />;
});
