import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";
import { CardContainer } from "../shared/KPICard/style";
import { ExchangesListItem as BaseExchangesListItem } from "./ExchangesListItem";

export const Container = styled(CardContainer)`
  padding: 8px 16px;
  gap: 8px;
`;

export const ExchangesList = styled.ul`
  width: 100%;
  overflow-x: auto;

  ${ScrollSize}

  padding: 0;
  margin: 0;
  padding-bottom: 5px;

  display: flex;
  gap: 8px;

  list-style: none;
`;

export const ExchangesListItem = styled(BaseExchangesListItem)`
  width: 100%;
  max-width: 120px;
`;
