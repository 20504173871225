import { ToggleSwitchProps } from "src/components/shared/ToggleSwitch";
import { useId } from "src/hooks/useId";
import * as styles from "./style";

const ACTIVE_TOGGLE_ID = "strategy-active-toggle";

export interface ActiveToggleProps extends Omit<ToggleSwitchProps, "label"> {
  showLabel?: boolean;
}

export const ActiveToggle = ({ showLabel = true, ...props }: ActiveToggleProps) => {
  const id = useId();
  const toggleId = `${ACTIVE_TOGGLE_ID}-${id}`;

  const label = showLabel ? "Active" : undefined;

  return <styles.ActiveToggleSwitch label={label} id={toggleId} {...props} />;
};
