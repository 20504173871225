import { useMemo } from "react";
import { MultiValueGenericProps, MultiValueProps, OnChangeValue, components } from "react-select";
import { TableInstance } from "react-table";
import { SelectorCommonProps } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/SelectColumnFilter";
import { CheckboxInputOption } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/SelectColumnFilter/CheckboxInputOption";
import { PrimitiveSelectorValue, toRawPrimitiveSelectorValue } from "src/helpers/forms/selectors";
import * as styles from "./style";

type SelectFilterValue = string;

type VisibilitySelectorValue = PrimitiveSelectorValue<SelectFilterValue> & {
  isDisabled: boolean;
};

const MultiValueContainer = (props: MultiValueGenericProps<VisibilitySelectorValue>) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { isDisabled } = props.data;
  return !isDisabled ? <components.MultiValueContainer {...props} /> : null;
};

export interface ColumnsVisibilityFilterProps extends SelectorCommonProps {
  instance: TableInstance<any>;
  getTitleById?: (id: string) => string | undefined;
  lockedColumns?: string[];
}

const DelimiterMultiValue = ({
  children,
  ...props
}: MultiValueProps<VisibilitySelectorValue, true>) => {
  const { data, selectProps } = props;

  const isLastValue = useMemo(() => {
    const selectorValue = (selectProps.value as VisibilitySelectorValue[]) ?? [];
    return data === selectorValue[selectorValue.length - 1];
  }, [data, selectProps.value]);

  return (
    <components.MultiValue {...props}>
      {children}
      {isLastValue ? null : <span> - </span>}
    </components.MultiValue>
  );
};

const EmptyMultiValueRemove = () => null;

export const ColumnsVisibilityFilter = ({
  instance: { allColumns, visibleColumns },
  getTitleById,
  style,
  className,
  lockedColumns,
}: ColumnsVisibilityFilterProps) => {
  const selectorProps = { style, className };

  const visibleColumnsSet = useMemo(
    () => new Set(visibleColumns.map(({ id }) => id)),
    [visibleColumns]
  );

  const lockedColumnsSet = useMemo(() => new Set(lockedColumns), [lockedColumns]);

  const selectorOptions: VisibilitySelectorValue[] = useMemo(
    () =>
      allColumns.map(({ id }) => {
        const title = getTitleById?.(id) ?? id;
        const isDisabled = lockedColumnsSet.has(id);
        return { value: id, label: title, isDisabled };
      }),
    [allColumns, getTitleById, lockedColumnsSet]
  );

  const selectorValue = useMemo(
    () =>
      selectorOptions.filter((option) => {
        const isVisible = visibleColumnsSet.has(option.value);
        return isVisible;
      }),
    [selectorOptions, visibleColumnsSet]
  );

  const selectorOnChange = (newValue: OnChangeValue<VisibilitySelectorValue, true>) => {
    const selectedColumnsIds = toRawPrimitiveSelectorValue(newValue);
    const selectedColumnsIdsSet = new Set(selectedColumnsIds);

    allColumns.forEach((column) => {
      if (selectedColumnsIdsSet.has(column.id)) {
        column?.toggleHidden(false);
      } else {
        column?.toggleHidden(true);
      }
    });
  };

  return (
    <styles.StyledSelector
      isMulti
      options={selectorOptions}
      value={selectorValue}
      onChange={selectorOnChange}
      components={{
        Option: CheckboxInputOption as typeof CheckboxInputOption<VisibilitySelectorValue, true>,
        MultiValueContainer,
        MultiValue: DelimiterMultiValue,
        MultiValueRemove: EmptyMultiValueRemove,
      }}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      backspaceRemovesValue={false}
      {...selectorProps}
    />
  );
};
