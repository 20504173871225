import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { Loader } from "src/components/shared/Loader";
import { TotalVolumeShareContext } from "src/context/CEX/Dashboard/v2/TotalVolumeShare";
import { formatFiat } from "src/helpers/string";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DashboardV2Widget, getWidgetTitle } from "../shared/constants";
import { StatsSection } from "./StatsSection";
import { StatsSectionItem } from "./StatsSection/StatsSectionItem";
import * as styles from "./style";

export interface TotalVolumeShareProps extends ComponentPropsWithoutRef<"div"> {}

export const TotalVolumeShare = observer((props: TotalVolumeShareProps) => {
  const { our, ourPercent, total, loading } = useLateInitContext(TotalVolumeShareContext.Context);

  const ourPercentValue = +ourPercent;

  const ourText = formatFiat(our);

  const totalText = formatFiat(total);

  const title = getWidgetTitle(DashboardV2Widget.TotalVolumeShare);

  return (
    <styles.Container {...props}>
      <styles.Title>{title}</styles.Title>
      <styles.TotalVolumeBar value={ourPercentValue} />
      <StatsSection>
        <StatsSectionItem label="Gotbit" value={ourText} />
        <StatsSectionItem label="Total" value={totalText} />
      </StatsSection>
      <Loader show={loading} />
    </styles.Container>
  );
});

export interface TotalVolumeShareWidgetProps extends TotalVolumeShareProps {}

export const TotalVolumeShareWidget = (props: TotalVolumeShareWidgetProps) => (
  <TotalVolumeShareContext.Provider>
    <TotalVolumeShare {...props} />
  </TotalVolumeShareContext.Provider>
);
