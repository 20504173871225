import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "src/components/shared/Toaster";
import {
  activateAPI,
  changeNameAPI,
  changeNameAcc,
  deleteAPI,
  deleteAccount,
  editAPI,
  editAcc,
  getAPIKey,
} from "src/api/userManager/accountsAPI";
import {
  DEXV2PartyBot,
  GetPartyDEXV2BotsResponse,
  getPartyAccounts,
  getPartyBots,
  getPartyDEXV2Bots,
  getPartySwapBots,
  moveBotNewParty,
  moveDEXV2BotNewParty,
  moveSwapBotNewParty,
} from "src/api/userManager/partiesAPI";
import { getSelectorList } from "src/helpers/forms/selectors";
import { logError } from "src/helpers/network/logger";
import { Mapper } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { ICEXBotInfo, ICEXBotInfoWithMarket, IDEXBotInfo, IDEXV2BotInfo } from "src/modules/bots";
import { Account, Credential } from "src/modules/userManager";

const RESET_SHOW_INTERVAL = 30000;

type Loaders = "isLoadingAcc" | "isLoadingBot" | "isLoadingSwapBot" | "isLoadingDEXV2Bot";

const dexV2PartyBotToBot: Mapper<DEXV2PartyBot, IDEXV2BotInfo> = ({
  base_ticker,
  quote_ticker,
  name,
  exchange,
  uuid,
}) => ({
  base: base_ticker,
  quote: quote_ticker,
  name,
  exchange,
  bot_uuid: uuid,
});

const dexV2PartyBotsToBots: Mapper<GetPartyDEXV2BotsResponse, IDEXV2BotInfo[]> = (resp) =>
  resp.map(dexV2PartyBotToBot);

const cexBotInfoToMarketInfo: Mapper<ICEXBotInfo, ICEXBotInfoWithMarket> = (bot) => {
  const baseMarket = `${bot.quote}_${bot.base}_${bot.exchange}`;
  const name = bot.name ? `(${bot.name})` : "";

  const market = filterBoolean([baseMarket, name]).join(" ");
  return { ...bot, market };
};

class EditPartyStore {
  isLoadingBot = false;

  isLoadingSwapBot = false;

  isLoadingDEXV2Bot = false;

  isLoadingAcc = false;

  private _bots: ICEXBotInfo[] = [];

  private _swapBots: IDEXBotInfo[] = [];

  private _dexV2Bots: IDEXV2BotInfo[] = [];

  currentAPI: number | "" = "";

  currentAccount: string = "";

  newAPIAcc: Account | null = null;

  _currentEditAcc: string = "";

  _accounts: Record<string, Account[]> = {};

  partyName = "";

  constructor(name: string) {
    makeAutoObservable(this);

    this.partyName = name;
  }

  get party() {
    return this.partyName;
  }

  get exchanges() {
    return Object.keys(this._accounts);
  }

  get exchSelector() {
    return getSelectorList(this.exchanges);
  }

  get credentials() {
    const hashMap: Record<number, Credential> = {};

    for (const key of Object.keys(this._accounts)) {
      if (Array.isArray(this._accounts[key])) {
        for (const el of this._accounts[key]) {
          for (const credential of el.credentials) {
            hashMap[credential.id] = credential;
          }
        }
      }
    }

    return hashMap;
  }

  get accounts() {
    const hashMap: Record<string, Account> = {};

    for (const key of Object.keys(this._accounts)) {
      if (Array.isArray(this._accounts[key])) {
        for (const el of this._accounts[key]) {
          hashMap[el.uuid] = el;
        }
      }
    }

    return hashMap;
  }

  get currentCredential() {
    return this.credentials[+this.currentAPI];
  }

  get isActiveCurrentAPI() {
    return (
      this.currentCredential?.id !== undefined &&
      this.currentCredential?.id === this.currentAcc?.active_id
    );
  }

  get currentEditAcc() {
    return this.accounts[this._currentEditAcc];
  }

  get currentMetaKeys() {
    return Object.keys(this.currentCredential.meta);
  }

  get currentAccMetaKeys() {
    return Object.keys(this.currentEditAcc.meta);
  }

  get CEXBots() {
    return this._bots.map(cexBotInfoToMarketInfo);
  }

  get DEXBots() {
    return this._swapBots.map((el) => ({
      ...el,
      market: el.path,
    }));
  }

  get DEXV2Bots() {
    return this._dexV2Bots.map(cexBotInfoToMarketInfo);
  }

  getSelectedBot = (bot_uuid: string) => this.CEXBots.find((el) => el.bot_uuid === bot_uuid);

  getSelectedSwapBot = (bot_uuid: string) => this.DEXBots.find((el) => el.bot_uuid === bot_uuid);

  getSelectedDEXV2Bot = (bot_uuid: string) => this.DEXV2Bots.find((el) => el.bot_uuid === bot_uuid);

  setCurrentAPI = (value: number) => {
    this.currentAPI = value;
  };

  clearCurrentAPI = () => {
    this.currentAPI = "";
  };

  setCurrentAcc = (value: string) => {
    this.currentAccount = value;
  };

  get currentAcc() {
    return this.accounts[this.currentAccount];
  }

  private _clearCurrentAccounts = () => {
    this.setCurrentAcc("");
    this.setCurrentEditAcc("");
  };

  setNewAPIAcc = (acc: Account) => {
    this.newAPIAcc = acc;
  };

  setCurrentEditAcc = (uuid: string) => {
    this._currentEditAcc = uuid;
  };

  loadData = () => {
    this.getBots();
    this.getSwapBots();
  };

  setLoading = (field: Loaders, bool: boolean) => {
    this[field] = bool;
  };

  private _setAccLoading = (loading: boolean) => {
    this.isLoadingAcc = loading;
  };

  resetShowAPIKeys = (link: Credential, data: Credential["data"]) => {
    setTimeout(() => {
      runInAction(() => {
        // eslint-disable-next-line no-param-reassign
        link.data = data;
      });
    }, RESET_SHOW_INTERVAL);
  };

  clickMoveCEXHandler = (but_uuid: string, party: string) => () => {
    if (party) {
      this.moveBotParty(but_uuid, party);
    } else toast.error("Select the party where you want to move the bot");
  };

  clickMoveDEXHandler = (bot_uuid: string, party: string) => () => {
    if (party) {
      this.moveSwapBotParty(bot_uuid, party);
    } else toast.error("Select the party where you want to move the bot");
  };

  clickMoveDEXV2Handler = (bot_uuid: string, party: string) => () => {
    if (party) {
      this.moveDEXV2BotParty(bot_uuid, party);
    } else toast.error("Select the party where you want to move the bot");
  };

  getBots = async () => {
    this.setLoading("isLoadingBot", true);

    try {
      const { data, isError } = await getPartyBots(this.party);

      if (!isError) {
        runInAction(() => {
          this._bots = data;
        });
      }
    } catch {
      this._bots = [];
    } finally {
      this.setLoading("isLoadingBot", false);
    }
  };

  getSwapBots = async () => {
    this.setLoading("isLoadingSwapBot", true);

    try {
      const { data, isError } = await getPartySwapBots(this.party);

      if (!isError) {
        runInAction(() => {
          this._swapBots = data;
        });
        return;
      }

      this._swapBots = [];
    } catch {
      this._swapBots = [];
    } finally {
      this.setLoading("isLoadingSwapBot", false);
    }
  };

  getDEXV2Bots = async () => {
    this.setLoading("isLoadingDEXV2Bot", true);

    try {
      const { data, isError } = await getPartyDEXV2Bots(this.party);

      if (!isError) {
        const bots = dexV2PartyBotsToBots(data);
        runInAction(() => {
          this._dexV2Bots = bots;
        });
        return;
      }

      runInAction(() => {
        this._dexV2Bots = [];
      });
    } catch {
      runInAction(() => {
        this._dexV2Bots = [];
      });
    } finally {
      this.setLoading("isLoadingDEXV2Bot", false);
    }
  };

  getAccounts = async () => {
    this.setLoading("isLoadingAcc", true);

    try {
      const { data, isError } = await getPartyAccounts(this.party);

      if (!isError) {
        runInAction(() => {
          this._accounts = data;
        });

        return;
      }

      this._accounts = {};
    } catch {
      this._accounts = {};
    } finally {
      this.setLoading("isLoadingAcc", false);
    }
  };

  moveBotParty = async (bot_uuid: string, party: string) => {
    this.setLoading("isLoadingBot", true);

    try {
      const { isError } = await moveBotNewParty(bot_uuid, party);

      if (!isError) {
        this.getBots();

        const selectedBot = this.getSelectedBot(bot_uuid);

        toast.success(`Bot ${selectedBot?.market ?? ""} move in ${party} party successfully`);
      }
    } finally {
      this.setLoading("isLoadingBot", false);
    }
  };

  moveSwapBotParty = async (bot_uuid: string, party: string) => {
    this.setLoading("isLoadingSwapBot", true);
    try {
      const { isError } = await moveSwapBotNewParty(bot_uuid, party);

      if (!isError) {
        this.getSwapBots();

        const selectedBot = this.getSelectedSwapBot(bot_uuid);

        toast.success(
          `Bot ${selectedBot ? selectedBot.market : ""} move in ${party} party successfully`
        );
      }
    } finally {
      this.setLoading("isLoadingSwapBot", false);
    }
  };

  moveDEXV2BotParty = async (bot_uuid: string, party: string) => {
    this.setLoading("isLoadingDEXV2Bot", true);
    try {
      const { isError } = await moveDEXV2BotNewParty(bot_uuid, party);

      if (!isError) {
        const selectedBot = this.getSelectedDEXV2Bot(bot_uuid);

        toast.success(
          `Bot ${selectedBot ? selectedBot.market : ""} move in ${party} party successfully`
        );

        await this.getDEXV2Bots();
      }
    } finally {
      this.setLoading("isLoadingDEXV2Bot", false);
    }
  };

  getShownAPIKey = async () => {
    this.setLoading("isLoadingAcc", true);

    const prewCredentialData = this.credentials[+this.currentAPI].data;

    try {
      const { data, isError } = await getAPIKey(this.currentAccount, +this.currentAPI);

      if (!isError) {
        if (data.data) {
          runInAction(() => {
            this.credentials[+this.currentAPI].data = data.data;
          });

          this.resetShowAPIKeys(this.credentials[+this.currentAPI], prewCredentialData);
        }
      }
    } finally {
      this.setLoading("isLoadingAcc", false);
    }
  };

  removeMetaDataAPI = async ({ API, label }: { API: Credential; label: string }) => {
    this.setLoading("isLoadingAcc", true);

    // const prewMetaProp = API.meta.optional[label];

    const newMeta = { ...API.meta };

    delete newMeta[label];

    try {
      const { isError } = await editAPI(this.currentAccount, API.id, newMeta);

      if (!isError) {
        toast.success(`Meta data ${label} removed successfully`);

        // eslint-disable-next-line no-param-reassign
        delete API.meta[label];

        this.getAccounts();
      }
    } finally {
      this.setLoading("isLoadingAcc", false);
    }
  };

  removeMetaDataAcc = async ({ acc, label }: { acc: Account; label: string }) => {
    this.setLoading("isLoadingAcc", true);

    const newMeta = { ...acc.meta };

    delete newMeta[label];

    // const prewMetaProp = API.meta.optional[label];

    try {
      const { isError } = await editAcc(acc.uuid, newMeta);

      if (!isError) {
        toast.success(`Meta data ${label} removed successfully`);

        delete acc.meta[label];

        this.getAccounts();
      }

      // API.meta.optional[label] = prewMetaProp;
    } catch (err) {
      logError(err);
      // API.meta.optional[label] = prewMetaProp;
    } finally {
      this.setLoading("isLoadingAcc", false);
    }
  };

  toggleAPI = async ({ uuid, id }: { uuid: string; id: number }) => {
    this.setLoading("isLoadingAcc", true);

    const prewActiveAPI = this.accounts[uuid].active_id;

    runInAction(() => {
      this.accounts[uuid].active_id = id;
    });

    try {
      const { isError } = await activateAPI(uuid, id);

      if (!isError) {
        toast.success("Account API successfully connected");
        return;
      }

      runInAction(() => {
        this.accounts[uuid].active_id = prewActiveAPI;
      });
    } catch {
      runInAction(() => {
        this.accounts[uuid].active_id = prewActiveAPI;
      });
    } finally {
      this.setLoading("isLoadingAcc", false);
    }
  };

  editName = async (
    e: React.FormEvent,
    name: string,
    type: "API" | "ACCOUNT",
    changeSaveStatus: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    e.preventDefault();

    this._setAccLoading(true);

    try {
      const { isError } =
        type === "ACCOUNT"
          ? await changeNameAcc(this.currentEditAcc.uuid, { name })
          : await changeNameAPI(this.currentAccount, +this.currentAPI, {
              name,
            });

      if (!isError) {
        toast.success("Name success changed");

        changeSaveStatus(true);
        this.getAccounts();
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setAccLoading(false);
    }
  };

  archiveAccount = async () => {
    this._setAccLoading(true);

    try {
      const { isError } = await deleteAccount(this.currentEditAcc.uuid);

      if (!isError) {
        toast.success("Account successfully deleted");
        this._clearCurrentAccounts();
        this.clearCurrentAPI();
        await this.getAccounts();
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setAccLoading(false);
    }
  };

  archiveAPI = async () => {
    this._setAccLoading(true);

    try {
      const { isError } = await deleteAPI(this.currentAccount, +this.currentAPI);

      if (!isError) {
        toast.success("API successfully deleted");
        this.clearCurrentAPI();
        await this.getAccounts();
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setAccLoading(false);
    }
  };
}

export default EditPartyStore;
