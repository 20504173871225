import { useMemo } from "react";
import { formatFiat } from "src/helpers/string";
import { Nullish } from "src/helpers/utils";

export interface FiatCellProps {
  value: Nullish<number | string>;
  showSymbol?: boolean;
  showSign?: boolean;
}

export const FiatCell = ({ value, showSymbol, showSign }: FiatCellProps) => {
  const formattedValue = useMemo(
    () => formatFiat(value, showSymbol, showSign),
    [showSign, showSymbol, value]
  );
  return <>{formattedValue}</>;
};
