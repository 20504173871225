import { Switches } from "src/components/shared/Switches";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 16px 0px;

  border-top: 1px solid #dfe1e9;
`;

export const WrapperTitle = styled.div`
  display: flex;

  margin-bottom: 16px;

  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: baseline;
`;

export const RequestModeSwitcher = styled(Switches)`
  max-width: 80px;
`;
