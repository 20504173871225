import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BaseModuleForm } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { ControlledSelector } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledSelector";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared/style";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";
import { PrimitiveSelectorValue } from "src/helpers/forms/selectors";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategyModuleCreateType } from "src/modules/expertSystem";
import { MultiGridStatus } from "src/state/CEX/shared/types";
import { ERRORS_MSGS } from "src/validation-schemas";
import { getEmptyConditionModule } from "../shared/utils";

const MODULE_TYPE = "multigrinder_status";

const MULTIGRID_STATUS_LIST: PrimitiveSelectorValue<MultiGridStatus>[] = [
  { label: "Active", value: "active" },
  { label: "Error", value: "error" },
  { label: "Stopped", value: "stopped" },
];

export const MultigridStatus = observer(() => {
  const { createConditionHandler, multigridUUIDOptions } = useLateInitContext(CreateModulesContext);
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<IStrategyModuleCreateType<typeof MODULE_TYPE>>({
    defaultValues: getEmptyConditionModule(MODULE_TYPE),
  });

  const { handleSubmit, control } = methods;

  const getStatusValue = useCallback(
    (value: MultiGridStatus | "") => MULTIGRID_STATUS_LIST.find((el) => el.value === value),
    []
  );

  const getUUIDValue = useCallback(
    (value: string) => multigridUUIDOptions.find((el) => el.value === value),
    [multigridUUIDOptions]
  );

  return (
    <FormProvider {...methods}>
      <BaseModuleForm onSubmit={handleSubmit(createConditionHandler(close))} formId={MODULE_TYPE}>
        <RowWrapper>
          <ControlledSelector
            controllerProps={{
              control,
              name: "hard_params.multigrinder_uuid",
              rules: { required: ERRORS_MSGS.isRequired },
            }}
            componentProps={{
              label: "Multi grinder",
              menuPosition: "fixed",
              options: multigridUUIDOptions,
              getValueHandler: getUUIDValue,
              onChangeHandler: (data) => (data as PrimitiveSelectorValue<string>)?.value,
            }}
          />

          <ControlledSelector
            controllerProps={{
              control,
              name: "hard_params.status",
              rules: { required: ERRORS_MSGS.isRequired },
            }}
            componentProps={{
              label: "Status",
              menuPosition: "fixed",
              options: MULTIGRID_STATUS_LIST,
              getValueHandler: getStatusValue,
              onChangeHandler: (data) => (data as PrimitiveSelectorValue<MultiGridStatus>)?.value,
            }}
          />
        </RowWrapper>
      </BaseModuleForm>
    </FormProvider>
  );
});
