import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Headline1 } from "src/components/shared/Typography";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import { Filter } from "../Access/Lists/Filter";

export const MOBILE_TEAMS_BREAKPOINT = "900px";

export const Container = styled.div`
  position: relative;

  height: 100%;
  width: 100%;
  max-width: 1700px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 40px 60px;
`;

export const Title = styled(Headline1)`
  margin-bottom: 10px;

  @media ${getMobileMediaQuery(MOBILE_TEAMS_BREAKPOINT)} {
    font-size: 36px;
  }

  @media ${getMobileMediaQuery("450px")} {
    font-size: 32px;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media ${getMobileMediaQuery(MOBILE_TEAMS_BREAKPOINT)} {
    justify-content: space-between;
    gap: 20px;
  }

  @media ${getMobileMediaQuery("450px")} {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;

export const TeamsSelector = styled(LabeledSelector)`
  min-width: 75px;
  max-width: 150px;

  @media ${getMobileMediaQuery("450px")} {
    max-width: 100%;
  }
` as typeof LabeledSelector;

export const PartiesSearch = styled(Filter)`
  width: 100%;
  min-width: 75px;
  max-width: 150px;

  @media ${getMobileMediaQuery("450px")} {
    max-width: 100%;
  }
`;
