export const calcRecommendDiff = (
  oldDiff: number,
  minSell: number,
  maxBuy: number,
  dtpMin: number,
  dtpMax: number
): number => {
  const modulePrice: number = ((dtpMin + dtpMax) * 50) / (100 + oldDiff);

  const nearPrice: number =
    Math.abs(modulePrice - minSell) < Math.abs(modulePrice - maxBuy) ? minSell : maxBuy;

  const recommendDiff: number = (nearPrice / modulePrice - 1) * 100;

  if (Math.abs(recommendDiff) < 0.1) {
    return 0;
  }

  return +recommendDiff.toFixed(1);
};
