import { makeAutoObservable } from "mobx";
import { GetVolumeShareResponse, getVolumeShare } from "src/api/bots/CEX/dashboard";
import { logError } from "src/helpers/network/logger";
import { IDisposable, Mapper } from "src/helpers/utils";
import { IBaseStatsStoreParams, IDashboardStateProvider, IStatsFetcher } from ".";
import { valuesToNumber } from "../utils";

interface IVolumeStoreParams extends IBaseStatsStoreParams {}

export type VolumeData = {
  total: number[];
  notOur: number[];
  our: number[];
  exchanges: string[];
};

const INITIAL_DATA: VolumeData = {
  total: [],
  notOur: [],
  our: [],
  exchanges: [],
};

const dataToVolume = (data: GetVolumeShareResponse["our"]) => {
  const values = Object.values(data);
  return valuesToNumber(values);
};

const volumeResponseToData: Mapper<GetVolumeShareResponse, VolumeData> = ({
  our,
  total,
  another,
}) => {
  const exchanges = Object.keys(our);

  return {
    our: dataToVolume(our),
    notOur: dataToVolume(another),
    total: dataToVolume(total),
    exchanges,
  };
};

export default class VolumeStore implements IStatsFetcher, IDisposable {
  private _stateProvider: IDashboardStateProvider;

  private _data: VolumeData = INITIAL_DATA;

  private _loading = false;

  constructor({ stateProvider }: IVolumeStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;
  }

  private get _botParams() {
    return this._stateProvider.botParams;
  }

  private get _queryParams() {
    return this._stateProvider.queryParams;
  }

  private _setData = (data: VolumeData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get exchanges() {
    return this._data.exchanges;
  }

  get our() {
    return this._data.our;
  }

  get notOur() {
    return this._data.notOur;
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _getVolume = async () => {
    const { party } = this._botParams;
    const queryParams = this._queryParams;
    if (!party || !queryParams) return;

    this._setData(INITIAL_DATA);
    this._setLoading(true);
    try {
      const { data, isError } = await getVolumeShare(party, queryParams);

      if (!isError) {
        const volumeData = volumeResponseToData(data);
        this._setData(volumeData);
      }
    } finally {
      this._setLoading(false);
    }
  };

  getStats = async () => {
    try {
      await this._getVolume();
    } catch (error) {
      logError(error);
    }
  };

  destroy = () => {};
}
