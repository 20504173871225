import { observer } from "mobx-react-lite";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { CreateBotContext } from "src/context/CEX/CreateBotProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SettingsPanel } from "../SettingsPanel";
import { Wrapper } from "../shared";

export const VolumeSettings = observer(() => {
  const state = useLateInitContext(CreateBotContext);

  return (
    <SettingsPanel style={{ gridArea: "volume" }} title="Volume">
      <Wrapper>
        <LabeledInput
          type="number"
          label="Volume &#36;"
          value={state.data.settings?.volume?.tradePerDayMinUSD ?? ""}
          onChange={state.getHandler("settings.volume.tradePerDayMinUSD")}
          errorHint={state.errors.settings?.volume?.tradePerDayMinUSD}
          step="any"
        />
        <LabeledInput
          type="number"
          label="Modify Volume &#36;"
          value={state.data.settings?.volume?.modifyTradePerDayMinUSD ?? ""}
          onChange={state.getHandler("settings.volume.modifyTradePerDayMinUSD")}
          errorHint={state.errors.settings?.volume?.modifyTradePerDayMinUSD}
          step="any"
        />
      </Wrapper>

      <Wrapper>
        <LabeledInput
          type="number"
          label="Modify Delta &#36;"
          value={state.data.settings?.volume?.modifyDeltaBalanceQuoteUSD ?? ""}
          onChange={state.getHandler("settings.volume.modifyDeltaBalanceQuoteUSD")}
          errorHint={state.errors.settings?.volume?.modifyDeltaBalanceQuoteUSD}
          step="any"
        />
        <LabeledInput
          type="number"
          label="Modify Delta Bases"
          value={state.data.settings?.volume?.modifyDeltaBalanceBase ?? ""}
          onChange={state.getHandler("settings.volume.modifyDeltaBalanceBase")}
          errorHint={state.errors.settings?.volume?.modifyDeltaBalanceBase}
          step="any"
        />
      </Wrapper>
    </SettingsPanel>
  );
});
