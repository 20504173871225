import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const PanelContainer = styled.div`
  position: relative;

  height: 100%;
  max-height: 100%;

  display: flex;
  flex-direction: column;

  gap: 12px;

  padding: 10px 13px;

  ${CardSurface}
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  gap: 7px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px;
`;

export const CountInfoRow = styled.span`
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.textColor};
`;

export const UnimportantInfo = styled.span`
  color: ${({ theme }) => theme.labelColor};
`;

export const GridConfigContainer = styled.div`
  position: relative;

  width: 100%;
  max-width: 600px;

  display: flex;
  flex-direction: column;

  gap: 10px;
`;
