import "./config/chartjs";
import "./config/dayjsConfig"; // Note: configs should be imported firstly (side-effects)
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { ReactNode } from "react";
import { render } from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import AppContext from "./context";
import { googleClientId } from "./environment/env";
import { appState } from "./state";
import { App } from "./app/App";
import "./app/index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

// fixes bug with child elements for QueryClientProvider
declare module "react-query/types/react/QueryClientProvider" {
  interface QueryClientProviderProps {
    children?: ReactNode;
  }
}

render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={appState}>
          <GoogleOAuthProvider clientId={googleClientId}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </GoogleOAuthProvider>
        </AppContext.Provider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
