import * as styles from "./style";

interface InfoHeadingProps extends React.ComponentPropsWithoutRef<"div"> {
  title: string;
  after?: React.ReactNode;
}

const InfoHeading = ({ title, after, ...props }: InfoHeadingProps) => (
  <styles.Content {...props}>
    <styles.Title>{title}</styles.Title>
    {after}
  </styles.Content>
);

export default InfoHeading;
