import styled from "styled-components";
import { ScrollSize } from "src/components/shared/shared";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const Content = styled.div`
  position: relative;
  min-height: 300px;
  height: 0px;

  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;

  gap: 16px;

  overflow: auto;

  ${ScrollSize}
`;
