import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import {
  ActiveMultiGridContext,
  MultiGridContext,
  MultiGridStateContext,
} from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import ActiveMultiGridStore from "src/state/CEX/multiGrid/ActiveMultiGrid";
import MultiGridStateStore from "src/state/CEX/multiGrid/MultiGridState";
import Status from "../../LiquidityTab/Status";
import { GridState } from "./GridState";
import { LogsPanel } from "./LogsPanel";
import { ModuleList } from "./ModuleList";
import { MultiGridSettings } from "./MultiGridSettings";
import { StatsPanel } from "./StatsPanel";
import * as styles from "./style";

export const ActiveMultiGrid = observer(() => {
  const mainState = useLateInitContext(MultiGridContext);

  const gridState = useMemo(() => new MultiGridStateStore(mainState), [mainState]);

  const settingsState = useMemo(() => new ActiveMultiGridStore(mainState), [mainState]);

  return (
    <styles.Container>
      <ActiveMultiGridContext.Provider value={settingsState}>
        <styles.GridHeading
          style={{ gridArea: "heading" }}
          title={`${mainState.party}: ${mainState.gridUUID}` || ""}
          after={
            <styles.HeadingWrapper>
              <ReloadButton onClick={mainState.updAllData} />
              <Status status={settingsState.gridStatus} updateTime={settingsState.gridLastUpd} />
            </styles.HeadingWrapper>
          }
        />

        <ErrorBoundary fallback={<styles.MultiGridSettingsFallback />}>
          <MultiGridSettings />
        </ErrorBoundary>
      </ActiveMultiGridContext.Provider>

      {/* <BalancePanel /> */}

      <ErrorBoundary fallback={<styles.ModuleListFallback />}>
        <ModuleList />
      </ErrorBoundary>

      <MultiGridStateContext.Provider value={gridState}>
        <ErrorBoundary fallback={<styles.StatsPanelFallback />}>
          <StatsPanel />
        </ErrorBoundary>

        <LogsPanel />

        <ErrorBoundary fallback={<styles.GridStateFallback />}>
          <GridState />
        </ErrorBoundary>
      </MultiGridStateContext.Provider>
    </styles.Container>
  );
});
