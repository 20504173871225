import { ComponentPropsWithoutRef } from "react";
import { GroupBase } from "react-select";
import { SelectorValue } from "src/modules/shared";
import { LabeledSelector, LabeledSelectorProps } from "../Selector";
import { SelectPanel, SelectPanelProps } from "./SelectPanel";
import * as styles from "./styles";

export interface SelectionPanelSelectorProps<IsMulti extends boolean>
  extends LabeledSelectorProps<SelectorValue, IsMulti, GroupBase<SelectorValue>> {}

export interface SelectionPanelProps<IsMulti extends boolean>
  extends ComponentPropsWithoutRef<"div">,
    Pick<SelectPanelProps, "removeClick"> {
  selectorProps: SelectionPanelSelectorProps<IsMulti>;
  selectItems: SelectorValue[];
}

export const SelectionPanel = <IsMulti extends boolean = false>({
  selectItems,
  removeClick,
  selectorProps,
  ...props
}: SelectionPanelProps<IsMulti>) => (
  <styles.Container {...props}>
    <SelectPanel selectItems={selectItems} removeClick={removeClick} />

    <LabeledSelector {...selectorProps} />
  </styles.Container>
);
