import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  grid-area: pair-settings;

  gap: 20px;

  @media screen and (max-width: 1231px) {
    flex-direction: column-reverse;

    gap: 0px;
  }
`;

export const PromptInput = styled.div`
  position: absolute;
  height: 28px;
  top: 2px;
  right: 6px;
  z-index: 1;
  display: flex;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
`;

export const PromptTopInput = styled.div`
  position: absolute;

  top: -17px;
  right: 6px;

  display: flex;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["9px"]};
`;

export const Separator = styled.span`
  margin-top: 4px;
  margin-right: 3px;
  min-height: 1px;
  height: 18px;
  background: #767676;
  align-self: stretch;

  width: 1px;
  box-sizing: border-box;
`;

export const TextPrompt = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  color: currentcolor;

  & > svg {
    fill: currentcolor;
    margin-left: 3px;
  }
`;
