import styled from "styled-components";
import { Headline1 } from "src/components/shared/Typography";
import { FullscreenModeMixin, FullscreenProps } from "src/components/shared/shared";

export const Container = styled.div<FullscreenProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  ${FullscreenModeMixin}
`;

export const Title = styled(Headline1)`
  font-size: ${({ theme }) => theme.fontSizes["24px"]};
`;
