import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import { getExchangeAccounts } from "src/api/bots/CEX/apiKeys";
import { createBot } from "src/api/bots/CEX/settings";
import { getParties, getShortPartyAccounts } from "src/api/userManager/partiesAPI";
import { toast } from "src/components/shared/Toaster";
import { getPathAndKey, getTargetValueByPath, getValueByPath } from "src/helpers/forms/getByKey";
import { SubscribableStore, entries } from "src/helpers/utils";
import {
  AccountApi,
  AccountType,
  BotAccountName,
  LiquidityAccountBinding,
  LiquidityAccountName,
  VolumeAccountName,
  isLiquidityAccountName,
} from "src/modules/accounts";
import { SelectorValue } from "src/modules/shared";
import {
  ERRORS_MSGS,
  graterThan,
  graterThanKey,
  isInteger,
  isNumber,
  notEqual,
  required,
  shortThan,
  smallerThanKey,
  validateData,
} from "src/validation-schemas";
import WindowConsent from "../WindowConsent";
import AccountBinding from "./CEXApiKeys/AccountBinding";
import AccountsBindings, { AccountBindingMap } from "./CEXApiKeys/AccountsBindings";
import { accountToAccountApi, isRequiredBotAccount } from "./CEXApiKeys/CEXApiKeys";

const storageKey = "botStartingForm";

const botSelectorFields = ["party", "exchange"] as const;

type BotSelectorFields = (typeof botSelectorFields)[number];

export type AccountSelectorErrors = Record<LiquidityAccountName, string>;

export interface RequestAccountBinding {
  name: BotAccountName;
  account: {
    uuid: string;
  };
}

const DEFAULT_LIQUIDITY_BINDINGS: LiquidityAccountBinding[] = [
  new AccountBinding({
    name: "info",
    uuid: "",
    type: "liquidity",
    account: null,
  }),
];

export class CreateBotStore implements SubscribableStore {
  private _parties: string[] = [];

  private _exchanges: string[] = [];

  private _accountsBindings = new AccountsBindings();

  data: any = {
    name: "",
    party: "",
    accounts: {},
    settings: {
      accounts: {
        sameMM: false,
      },
      pair: {
        candlePercentMin: 20,
        candlePercentMax: 30,
        exchange: "",
      },
      period: {
        value: 1,
      },
      volume: {},
      trade: {
        buyPercent: 0,
      },
      decimals: {},
      virtualRange: {},
      spreadDecompression: {},
      verifyOrder: {},
    },
  };

  startConfig = false;

  handlers: any = {};

  formCleared = false;

  private _loader = false;

  validation = {
    name: shortThan(50),
    party: required(),
    "settings.pair.exchange": required(),
    "settings.pair.quote": required(),
    "settings.pair.base": required(),
    "settings.period.value": required(),
    "settings.pair.minExchangeAmount": [required(), isNumber()],
    "settings.pair.dontCheckAmount": [required(), isNumber()],
    "settings.decimals.amount": [required(), isInteger()],
    "settings.decimals.price": [required(), isInteger()],

    "settings.trade.minTrades": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      notEqual(0, "Value cannot be 0"),
      smallerThanKey("settings.trade.maxTrades", "TradesMin should be less than TradesMax"),
    ],
    "settings.trade.maxTrades": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      notEqual(0, "Value cannot be 0"),
      graterThanKey("settings.trade.minTrades", "TradesMax must be greater than TradesMin"),
    ],
    "settings.pair.dontTradePriceMax": [required(), isNumber()],
    "settings.pair.dontTradePriceMin": [required(), isNumber()],

    "settings.trade.buyBeforeSellMin": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      smallerThanKey("settings.trade.buyBeforeSellMax", "BBsMin should be less than BBsMax"),
    ],
    "settings.trade.buyBeforeSellMax": [
      required(),
      isInteger(),
      graterThan(0, "The value must be positive"),
      graterThanKey("settings.trade.buyBeforeSellMin", "BBsMax must be greater than BBsMin"),
    ],
    "settings.volume.tradePerDayMinUSD": [
      required(),
      // isInteger(),
      graterThan(0, "The value must be positive"),
    ],
    // volumeToTradePerDayMaxUSD: volumeToTradePerDayMinUSD * 1.05,
    "settings.volume.modifyTradePerDayMinUSD": [
      required(),
      // isInteger(),
      graterThan(0, "The value must be positive"),
    ],
    // modifyVolumeToTradePerDayMaxUSD: modifyVolumeToTradePerDayMinUSD * 1.05,
    "settings.volume.modifyDeltaBalanceQuoteUSD": [
      required(),
      // isInteger(),
      graterThan(0, "The value must be positive"),
    ],
    "settings.volume.modifyDeltaBalanceBase": [
      required(),
      // isInteger(),
      graterThan(0, "The value must be positive"),
    ],
    lastPrice: [required(), isNumber()],
    "settings.trade.buyPercent": required(),
  };

  errors: any = {};

  onChangeValidate: Record<string, string[]> = {
    "settings.trade.minTrades": ["settings.trade.minTrades", "settings.trade.maxTrades"],
    "settings.trade.maxTrades": ["settings.trade.minTrades", "settings.trade.maxTrades"],
    "settings.trade.buyBeforeSellMin": [
      "settings.trade.buyBeforeSellMin",
      "settings.trade.buyBeforeSellMax",
    ],
    "settings.trade.buyBeforeSellMax": [
      "settings.trade.buyBeforeSellMin",
      "settings.trade.buyBeforeSellMax",
    ],
  };

  private _exchangeChangedReaction?: IReactionDisposer;

  private _partyChangedReaction?: IReactionDisposer;

  constructor() {
    const storedItems = localStorage.getItem(storageKey);

    if (storedItems) {
      const data = JSON.parse(storedItems);
      if (data.settings.pair && data.settings.virtualRange && data.settings.period) {
        this.data = data;
      }
    }

    makeAutoObservable(this);

    window.addEventListener("beforeunload", () => {
      localStorage.setItem(storageKey, this._serializeData());
    });

    this._initBindings();
  }

  private _initBindings = () => {
    this._accountsBindings.clear();
    this._accountsBindings.setLiquidityBindings(DEFAULT_LIQUIDITY_BINDINGS);
  };

  subscribe = () => {
    this._exchangeChangedReaction = reaction(
      () => this.getSelector("exchange"),
      (exchange) => {
        this._initBindings();
        if (!exchange) return;
        this._getAllAccounts(this.getSelector("party"), exchange);
      }
    );

    this._partyChangedReaction = reaction(
      () => this.getSelector("party"),
      (party) => {
        this._clearExchanges();
        if (!party) return;
        this.getExchangesList(party);
      }
    );
  };

  unsubscribe = () => {
    this._exchangeChangedReaction?.();
    this._partyChangedReaction?.();
  };

  private _serializeData() {
    const fieldsToOmit = new Set(["party", "exchange", "accounts"]);
    return JSON.stringify(this.data, (key, value) => {
      if (fieldsToOmit.has(key)) return undefined;
      return value;
    });
  }

  private _setLoader = (bool: boolean) => {
    this._loader = bool;
  };

  get loader() {
    return this._loader;
  }

  private _getAccountBinding = (name: BotAccountName) =>
    this._accountsBindings.getAccountBinding(name);

  get bindings() {
    return this._accountsBindings;
  }

  private _setAccountError = (name: BotAccountName, error: string) => {
    const binding = this._getAccountBinding(name);
    binding?.setError(error);
  };

  accountError = (name: BotAccountName) => {
    const binding = this._getAccountBinding(name);
    return binding?.error ?? "";
  };

  toggleStartConf = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.startConfig = e.target.checked;
  };

  private _setPartiesList(parties: string[]) {
    this._parties = parties;
  }

  getPartiesList = async () => {
    try {
      const { data, isError } = await getParties();

      if (!isError) {
        this._setPartiesList(data);
      } else {
        this._setPartiesList([]);
      }
    } catch {
      this._setPartiesList([]);
    }
  };

  private _stringToSelectorValue = (str: string) => ({ value: str, label: str });

  get exchangeSelectorEnabled() {
    return Boolean(this.getSelector("party"));
  }

  get accountsBindingEnabled() {
    return this._accountsBindings.exchangeAccounts.length > 0;
  }

  private _setExchangesList(exchanges: string[]) {
    this._exchanges = exchanges;
  }

  getExchangesList = async (party: string) => {
    try {
      const { data, isError } = await getShortPartyAccounts(party);

      if (!isError) {
        const exchangesList = Object.keys(data);
        this._setExchangesList(exchangesList);
      } else {
        this._setExchangesList([]);
      }
    } catch {
      this._setExchangesList([]);
    }
  };

  private _clearExchanges = () => {
    this._updateSelector("exchange", "");
    this._setExchangesList([]);
  };

  private _updateSelector = (field: BotSelectorFields, value: string) => {
    switch (field) {
      case "exchange": {
        this.data.settings.pair.exchange = value;
        break;
      }
      case "party": {
        this.data.party = value;
      }
    }
  };

  getSelector = (field: BotSelectorFields) => {
    switch (field) {
      case "exchange": {
        return this.data.settings.pair.exchange;
      }
      case "party": {
        return this.data.party;
      }
    }
  };

  selectorOptions = computedFn((field: BotSelectorFields) => {
    switch (field) {
      case "exchange": {
        return this._exchanges.map(this._stringToSelectorValue);
      }
      case "party": {
        return this._parties.map((el) => ({ value: el, label: el }));
      }
    }
  });

  selectorValue = computedFn((field: BotSelectorFields) => {
    const currentValue = this.getSelector(field);

    if (!currentValue) {
      return null;
    }
    return this._stringToSelectorValue(currentValue);
  });

  onSelectorChange = (field: BotSelectorFields) => (newValue: SelectorValue | null) => {
    if (!newValue) return;
    this._updateSelector(field, String(newValue.value));
  };

  private _getAllAccounts = async (party: string, exchange: string) => {
    try {
      const { data, isError } = await getExchangeAccounts(party, exchange);

      if (!isError) {
        const accounts = data[exchange] ?? [];

        const apiAccounts = accounts.map(accountToAccountApi);

        this._accountsBindings.setExchangeAccounts(apiAccounts);
        return;
      }

      this._accountsBindings.setExchangeAccounts([]);
    } catch {
      this._accountsBindings.setExchangeAccounts([]);
    }
  };

  getHandler = (key: string) => {
    // При необходимости задавать вложенную структуру data
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);
      const targetData = getTargetValueByPath(this.data, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (key === "settings.trade.buyPercent") {
          if (+this.getChangeEventValueRangePer(e) <= 100) {
            targetData[endKey] = this.getChangeEventValueRangePer(e);
          }
        } else targetData[endKey] = this.getChangeEventValue(e);
        if (key in this.onChangeValidate) {
          this.validate(this.onChangeValidate[key]);
        }
      };
    }
    return this.handlers[key];
  };

  getChangeEventValueRangePer = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    return +e.target.value;
  };

  getChangeEventValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number" || e.target.type === "radio") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    if (e.target.type === "checkbox") {
      return e.target.checked;
    }
    if (e.target.type === "text") {
      return e.target.value;
    }
    this.formCleared = false;
  };

  getError = (key: string) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(
      () => getValueByPath(this.errors, path, endKey, undefined)
      // getByKey(key, this.errors, undefined)
    );
    return result;
  };

  private _setErrorText = (accountName: BotAccountName, isValid: boolean, error: string) => {
    const errorText = isValid ? "" : error;
    this._setAccountError(accountName, errorText);
  };

  private _validateRequiredBindings = (accounts: BotAccountName[]) => {
    const accountValidationMap = accounts.reduce(
      (validationMap, accountName) => {
        const accountToBind = this._getAccountBinding(accountName)?.account ?? null;

        const isValid =
          !isRequiredBotAccount(accountName) ||
          this._accountsBindings.validators.REQUIRED(accountName, accountToBind);

        // eslint-disable-next-line no-param-reassign
        validationMap[accountName] = isValid;

        return validationMap;
      },
      {} as Record<BotAccountName, boolean>
    );

    entries(accountValidationMap).forEach(([accountName, isValid]) => {
      this._setErrorText(accountName, isValid, ERRORS_MSGS.isRequired);
    });

    const isValid = Object.values(accountValidationMap).every(Boolean);

    return isValid;
  };

  private _validateBindings = () => {
    const liquidityNames = this._accountsBindings.liquidityAccountNames as BotAccountName[];
    const volumeNames = this._accountsBindings.volumeAccountNames;

    const accountsToValidate = liquidityNames.concat(volumeNames);

    return this._validateRequiredBindings(accountsToValidate);
  };

  validate = (validateKeys?: string[]) => {
    const isValidData = validateData(this.validation, this.data, this.errors, validateKeys);

    const skipBindingValidation = validateKeys !== undefined;

    const isValidBinding = skipBindingValidation || this._validateBindings();

    return isValidData && isValidBinding;
  };

  private _accountBindingToRequestBinding = (
    binding?: AccountBinding<AccountType>
  ): RequestAccountBinding | null => {
    if (!binding) return null;
    const { name, account, type } = binding;
    const botName = type === "volume" ? "mm" : name;
    if (!account) return null;
    return { name: botName, account: { uuid: account.uuid } };
  };

  private _accountBindingsToRequestBindings = (bindingMap: AccountBindingMap<AccountType>) => {
    const requestBindings = Object.values(bindingMap)
      .flatMap((accounts) => accounts)
      .map(this._accountBindingToRequestBinding)
      .filter(Boolean) as RequestAccountBinding[];
    return requestBindings;
  };

  private _getRequestBindings = () => {
    const volumeBindings = this._accountBindingsToRequestBindings(
      this._accountsBindings.volumeBindings
    );
    const liquidityBindings = this._accountBindingsToRequestBindings(
      this._accountsBindings.liquidityBindings
    );
    return volumeBindings.concat(liquidityBindings);
  };

  deleteEnabled = computedFn((name: BotAccountName) => {
    const binding = this._getAccountBinding(name);

    if (!binding) return false;
    const { account } = binding;
    const isRequiredAccount = isRequiredBotAccount(binding.name);

    return Boolean(account) && !isRequiredAccount;
  });

  deleteAccount = (accountName: BotAccountName) => () => {
    const valid = this._validateRequiredBindings([accountName]);

    if (!valid) return;

    if (isLiquidityAccountName(accountName)) {
      this._accountsBindings.deleteLiquidityBinding(accountName);
    } else {
      this._accountsBindings.deleteVolumeBinding(accountName);
    }
  };

  addVolumeAccount = async (botAccountName: VolumeAccountName, accountToBind: AccountApi) => {
    const binding = new AccountBinding({
      name: botAccountName,
      uuid: "",
      type: "volume",
      account: accountToBind,
    });
    this.bindings.addVolumeBinding(binding);
    return false;
  };

  addLiquidityAccount = async (botAccountName: LiquidityAccountName, accountToBind: AccountApi) => {
    const binding = new AccountBinding({
      name: botAccountName,
      uuid: "",
      type: "liquidity",
      account: accountToBind,
    });
    this.bindings.addLiquidityBinding(binding);
    return false;
  };

  submitHandler = (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();

    if (valid) {
      this.data.settings.volume.tradePerDayMaxUSD = Math.round(
        this.data.settings.volume.tradePerDayMinUSD * 1.05
      );
      this.data.settings.volume.modifyTradePerDayMaxUSD = Math.round(
        this.data.settings.volume.modifyTradePerDayMinUSD * 1.05
      );

      this.data.accounts = this._getRequestBindings();

      if (this.startConfig) {
        WindowConsent.showWindow(
          "",
          "After creation, the bot will be launched immediately!",
          this.create
        );

        return;
      }

      this.create();
    }
  };

  create = async () => {
    try {
      this._setLoader(true);

      const { isError } = await createBot(this.data, this.data.party, this.startConfig);

      if (!isError) {
        toast.success("Bot created successfully");
      }
    } finally {
      this._setLoader(false);
    }
  };

  cancelHandler = () => {
    this.clearForm("settings", this.data);
    this.clearForm("accounts", this.data);
    this.data.lastPrice = "";
    this.data.name = "";
    this._updateSelector("party", "");
    this._updateSelector("exchange", "");
    this._initBindings();
    this.data.settings.pair.buyPercent = 0;
    this.data.settings.pair.candlePercentMin = 20;
    this.data.settings.pair.candlePercentMax = 30;
    this.data.settings.period.value = 1;

    this.errors = {};
  };

  clearForm = (key: string, obj: any) => {
    for (const nextKey of Object.keys(obj[key])) {
      const nextObj = obj[key];
      if (typeof nextObj[nextKey] === "object") {
        this.clearForm(nextKey, nextObj);
      } else if (typeof nextObj[nextKey] === "boolean") {
        nextObj[nextKey] = false;
      } else if (typeof nextObj[nextKey] === "string") {
        nextObj[nextKey] = "";
      } else if (typeof nextObj[nextKey] === "number") {
        nextObj[nextKey] = "";
      }
    }
  };

  destroy = () => {};
}
