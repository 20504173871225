import { makeAutoObservable } from "mobx";
import { botNameToParts } from "src/helpers/botName";
import { showSuccessMsg } from "src/helpers/message";
import RangePickerStore from "src/state/shared/RangePicker";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { roundSingleValue } from "src/helpers/rounding";
import {
  FundingMenuValue,
  AggregatedFunding,
  TransfersFunding,
  ManualFunding,
  AutoFunding,
  UpdateFunding,
  PartyFundingRange,
} from "../types";
import { FundingStore, DEFAULT_FUNDING_RANGE } from "..";
import { AggregatedFundingStore } from "./AggregatedFundingStore";
import { TransfersFundingStore } from "./TransfersFundingStore";
import { ManualFundingStore } from "./ManualFundingStore";
import { AutoFundingStore } from "./AutoFundingStore";

export class FundingManagementStore {
  aggregatedFundingStore: AggregatedFundingStore;

  transfersFundingStore: TransfersFundingStore;

  manualFundingStore: ManualFundingStore;

  autoFundingStore: AutoFundingStore;

  private _activeFundingMenu: FundingMenuValue = FundingMenuValue.AGGREGATED;

  private _isLoading = false;

  private _market = "";

  mainState: FundingStore;

  rangeState: RangePickerStore;

  constructor(state: FundingStore) {
    this.aggregatedFundingStore = new AggregatedFundingStore();
    this.transfersFundingStore = new TransfersFundingStore();
    this.manualFundingStore = new ManualFundingStore();
    this.autoFundingStore = new AutoFundingStore();

    this.mainState = state;
    this.mainState.setUpdHandlers("updFundingList", this.updData);

    this.rangeState = new RangePickerStore(this, DEFAULT_FUNDING_RANGE);

    makeAutoObservable(this);
  }

  get activeFundingMenu() {
    return this._activeFundingMenu;
  }

  set activeFundingMenu(activeList: FundingMenuValue) {
    this._activeFundingMenu = activeList;
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(loading: boolean) {
    this._isLoading = loading;
  }

  get party() {
    return this.mainState.party;
  }

  get botNameParts() {
    return botNameToParts(this._market);
  }

  get base() {
    return this.botNameParts?.base ?? "";
  }

  get tableData() {
    return this.currentFundingStore.list.map((el) => ({
      ...el,
      amount: roundSingleValue(Number(el.amount)),
    }));
  }

  private get currentFundingStore() {
    const store = {
      AGGREGATED: this.aggregatedFundingStore,
      TRANSFERS: this.transfersFundingStore,
      MANUAL: this.manualFundingStore,
      AUTO: this.autoFundingStore,
    };

    return store[this.activeFundingMenu];
  }

  loadData = () => {
    if (this.rangeState.start && this.rangeState.end)
      this._getFundings({
        party: this.mainState.party,
        from: this.rangeState.start,
        to: this.rangeState.end,
      });
  };

  updData = () => {
    this.loadData();
  };

  setMarket = (market: string) => {
    this._market = market;
  };

  setRange = (period: DateTimeRange) => this.rangeState.setRange(period);

  getSelectFunding = (id: number) => this.currentFundingStore.getSelectFunding(id);

  toggleFundingIgnore = (id: number, isIgnore: boolean) =>
    this.updateFunding(id, { skip: isIgnore });

  updateFunding = async (id: number, data: UpdateFunding, successCb?: () => void) => {
    this.isLoading = true;

    try {
      const resp = await this.currentFundingStore.updateFunding(id, data);

      if (resp && !resp.isError) {
        showSuccessMsg("Funding record successfully updated");

        successCb?.();
        this.mainState.updAllData();
      }
    } finally {
      this.isLoading = false;
    }
  };

  deleteFunding = (id: number) => {
    const deleteAction = async () => {
      this.isLoading = true;

      try {
        const resp = await this.currentFundingStore.deleteFundingAction(id);

        if (resp && !resp.isError) {
          showSuccessMsg("Funding record successfully deleted");

          this.mainState.updAllData();
        }
      } finally {
        this.isLoading = false;
      }
    };

    this.currentFundingStore.deleteFundingModal(id, deleteAction);
  };

  private _setActiveFundingList = (
    data: (AggregatedFunding | TransfersFunding | ManualFunding | AutoFunding)[]
  ) => {
    this.currentFundingStore.list = data;
  };

  private _getFundings = async (fundingParam: PartyFundingRange) => {
    this.isLoading = true;

    try {
      const { isError, data } = await this.currentFundingStore.loadFunding(fundingParam);

      if (!isError) this._setActiveFundingList(data);
      else this._setActiveFundingList([]);
    } catch {
      this._setActiveFundingList([]);
    } finally {
      this.isLoading = false;
    }
  };

  destroy = () => {};
}
