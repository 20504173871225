import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Column } from "react-table";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { HashesResponseMap } from "src/state/DEXV2/DEXV2Stats/Hashes/Providers/HashesPaginationFetcherProvider";
import { getScannerLink } from "src/state/chain/ChainsInfoStore";
import { WalletTypeCell } from "../../GasWallets/WalletTypeCell";
import { AddressCell } from "../../TablePanel/AddressCell";
import { TableContentProps } from "../../TablePanel/TableContent";
import { useVaultsHashesColumns } from "../VaultsHashesTable";
import * as styles from "./style";

type WalletHistoryHash = HashesResponseMap["wallet"];

const useWalletsHashesColumns = () => {
  const { chainInfo } = useLateInitContext(DEXV2BotContext);
  const walletsScannerLink = getScannerLink(chainInfo, "wallet");

  const vaultsColumns = useVaultsHashesColumns();
  const walletsColumns = useMemo(() => {
    const columnsSchema: Column<WalletHistoryHash>[] = [
      {
        Header: "Address",
        accessor: "address",
        Cell: ({ value }) => <AddressCell address={value} scannerLink={walletsScannerLink} />,
        disableFilters: true,
      },
      {
        Header: "Wallet",
        accessor: "walletType",
        Cell: ({ value }) => <WalletTypeCell type={value} />,
        sortType: "string",
        disableFilters: true,
      },
      ...(vaultsColumns as Column<WalletHistoryHash>[]),
    ];

    return columnsSchema;
  }, [vaultsColumns, walletsScannerLink]);

  return walletsColumns;
};

export interface WalletsHashesTableProps extends Omit<TableContentProps, "data" | "columns"> {
  txScannerLink?: string;
  hashes: WalletHistoryHash[];
}

export const WalletsHashesTable = observer(({ hashes, ...props }: WalletsHashesTableProps) => {
  const columns = useWalletsHashesColumns();

  return <styles.HashesTable columns={columns} data={hashes} {...props} />;
});
