/* eslint-disable react/jsx-key */
import { useState } from "react";
import { ButtonSize } from "src/components/shared/Buttons/v2/Button/style";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import Icons from "src/icons/Icons";
import { DemoCard } from "../../shared/DemoCard";
import { BUTTON_SIZE_M, SIZE_OPTIONS } from "../Buttons";
import { ComponentsContainer, ControlsContainer, IconWrapper } from "../shared/style";
import { TypedSelectorValue } from "../shared/types";
import * as styles from "./style";

const BUTTON_ICONS = [
  <IconWrapper currentStroke>{Icons.remove()}</IconWrapper>,
  <IconWrapper currentStroke>{Icons.search()}</IconWrapper>,
  <IconWrapper currentFill>{Icons.addTeam()}</IconWrapper>,
  <IconWrapper currentStroke>{Icons.upload()}</IconWrapper>,
];

export interface IconButtonsProps {}

export const IconButtons = (props: IconButtonsProps) => {
  const [size, setSize] = useState<TypedSelectorValue<ButtonSize>>(BUTTON_SIZE_M);

  const [disabled, setDisabled] = useState(false);
  const [boxed, setBoxed] = useState(false);

  return (
    <DemoCard title="Icon Buttons">
      <ControlsContainer>
        <LabeledSelector
          label="Size"
          value={size}
          options={SIZE_OPTIONS}
          onChange={(option) => setSize(option!)}
        />
        <CheckBox
          label="Disabled"
          checked={disabled}
          onChange={(e) => setDisabled(e.target.checked)}
        />
        <CheckBox label="Boxed" checked={boxed} onChange={(e) => setBoxed(e.target.checked)} />
      </ControlsContainer>

      <ComponentsContainer>
        <styles.IconButtonsContainer>
          {BUTTON_ICONS.map((icon, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <IconButton key={index} size={size.value} disabled={disabled} boxed={boxed}>
              <IconWrapper currentFill>{icon}</IconWrapper>
            </IconButton>
          ))}
        </styles.IconButtonsContainer>
      </ComponentsContainer>
    </DemoCard>
  );
};
