import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;

  grid-area: diff;

  & > div > input {
    padding-right: 50px;
  }
`;
