import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useReducer, useState } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { Loader } from "src/components/shared/Loader";
import { PartyContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { AddAccEntity } from "./AddAccEntity";
import { AddLabel } from "./AddLabel";
import { CredentialsPanel } from "./CredentialsPanel";
import { EditAcc } from "./EditAcc";
import { ExchangeList } from "./ExchangeList";
import * as styles from "./style";

type Action<T extends string> = {
  type: T;
};
type ActionWithPayload<T extends string, P> = {
  type: T;
  payload: P;
};

type AddAccModalAction =
  | ActionWithPayload<"OPEN", string | undefined>
  | Action<"CLOSE">
  | Action<"TOGGLE">;

type AddAccModalState = {
  show: boolean;
  defaultExchange: string;
};

const INITIAL_ADD_ACC_MODAL_STATE: AddAccModalState = {
  show: false,
  defaultExchange: "",
};

const addAccModalReducer = (
  state: AddAccModalState,
  action: AddAccModalAction
): AddAccModalState => {
  switch (action.type) {
    case "OPEN":
      return {
        ...state,
        show: true,
        defaultExchange: action.payload ?? "",
      };
    case "CLOSE":
      return {
        ...state,
        show: false,
        defaultExchange: "",
      };
    case "TOGGLE":
      return {
        ...state,
        show: !state.show,
        defaultExchange: "",
      };
  }
};

export const Accounts = observer(() => {
  const partyState = useLateInitContext(PartyContext);

  const [showAddLabel, setShowAddLabel] = useState(false);

  const [{ show: showAddAcc, defaultExchange: defaultAddAccExchange }, addAccModalDispatch] =
    useReducer(addAccModalReducer, INITIAL_ADD_ACC_MODAL_STATE);
  const setShowAddAcc = useCallback((exchange?: string) => {
    addAccModalDispatch({ type: "OPEN", payload: exchange });
  }, []);
  const setCloseAddAcc = useCallback(() => {
    addAccModalDispatch({ type: "CLOSE" });
  }, []);
  const toggleShowAddAcc = useCallback(() => {
    addAccModalDispatch({ type: "TOGGLE" });
  }, []);

  const [showAddAPI, setShowAddAPI] = useState(false);
  const [showEditAcc, setShowEditAcc] = useState(false);

  useEffect(() => {
    partyState?.getAccounts();
  }, [partyState]);

  return (
    <styles.Container>
      <styles.Column>
        <ExchangeList
          showAddAPI={setShowAddAPI}
          showEditAcc={setShowEditAcc}
          showAddAcc={setShowAddAcc}
        />
        <OutlineButton
          stretched
          style={{ flex: "0 0 auto", marginTop: "20px" }}
          onClick={toggleShowAddAcc}
        >
          Add account +
        </OutlineButton>
      </styles.Column>

      <styles.Column>
        {partyState?.currentAPI ? (
          <>
            <CredentialsPanel />
            <OutlineButton
              stretched
              style={{ marginTop: "20px" }}
              onClick={() => setShowAddLabel(!showAddLabel)}
            >
              Add label +
            </OutlineButton>
          </>
        ) : null}
      </styles.Column>

      {showAddLabel ? <AddLabel show={showAddLabel} hide={setShowAddLabel} type="API" /> : null}

      {showAddAcc ? (
        <AddAccEntity
          type="ACCOUNT"
          show={showAddAcc}
          hide={setCloseAddAcc}
          defaultExchange={defaultAddAccExchange}
        />
      ) : null}

      {showAddAPI ? <AddAccEntity type="API" show={showAddAPI} hide={setShowAddAPI} /> : null}

      {showEditAcc && partyState.currentEditAcc ? (
        <EditAcc show={showEditAcc} hide={setShowEditAcc} />
      ) : null}

      <Loader show={partyState?.isLoadingAcc || false} />
    </styles.Container>
  );
});
