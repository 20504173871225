import React from "react";
import * as styles from "../style";

interface Props {
  timeIntervals: { widthInterval: string; timeInterval: string }[];
}

export const TimeScale = ({ timeIntervals }: Props) => (
  <styles.TimeScale>
    {timeIntervals.map((el, index) => (
      <styles.TimeInterval
        style={{
          width: `${el.widthInterval}px`,
        }}
        //   width={el.widthInterval}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        {el.timeInterval}
      </styles.TimeInterval>
    ))}
  </styles.TimeScale>
);
