import { ComponentPropsWithoutRef, memo } from "react";
import DEXV2BotIcons from "src/icons/DEXV2BotIcons";
import { DEXV2BotMode } from "src/modules/bots";
import * as styles from "./style";

export interface ModeIconProps
  extends ComponentPropsWithoutRef<"div">,
    styles.ModeIconWrapperProps {
  mode: DEXV2BotMode;
}

export const getModeIcon = (mode: DEXV2BotMode) => {
  switch (mode) {
    case "counter": {
      return DEXV2BotIcons.counter();
    }
    case "limit": {
      return DEXV2BotIcons.limit();
    }
    case "volume": {
      return DEXV2BotIcons.volume();
    }
  }
};

export const ModeIcon = memo(({ mode, ...props }: ModeIconProps) => {
  const icon = getModeIcon(mode);

  return <styles.ModeIconWrapper {...props}>{icon}</styles.ModeIconWrapper>;
});
