import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { Form } from "src/components/BotsContent/DEX_V2/Stats/Tables/shared";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import useLocalStore from "src/hooks/useLocalStore";
import CounterStrategyStore, {
  CounterStrategiesParameters,
} from "src/state/DEXV2/shared/settings/counter/CounterStrategyStore";
import { FormCol, FormRow } from "../../../../shared/style";
import { ActiveToggle } from "../../StrategiesTable/ActiveToggle";
import * as styles from "./style";

export interface StrategiesModalContentOwnProps
  extends Omit<CounterStrategiesParameters, "chainProvider"> {}

export interface StrategiesModalContentProps
  extends StrategiesModalContentOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, "onSubmit" | keyof StrategiesModalContentOwnProps> {}
export const StrategiesModalContent = observer(
  ({ onClose, setLoading, initialData, onSave, ...props }: StrategiesModalContentProps) => {
    const strategiesParams: CounterStrategiesParameters = {
      onClose,
      setLoading,
      initialData,
      onSave,
    };

    const state = useLocalStore(CounterStrategyStore, strategiesParams);

    return (
      <Form as="div" {...props}>
        <FormCol>
          <ActiveToggle checked={state.data.status} onChange={state.getHandler("status")} />
          <CheckBox
            label="Use Receiver"
            checked={state.data.use_receiver}
            onChange={state.getHandler("use_receiver")}
          />
          <styles.CounterSwitch
            label="Input event"
            name="input-event"
            items={state.switchOptions("input_event")}
            selectedItem={state.data.input_event}
            onChange={state.getHandler("input_event")}
            $orientation="row"
          />

          <FormRow>
            <LabeledInput
              type="number"
              step="any"
              label="Min Amount Base"
              {...state.getInputProps("input_lower_bound")}
            />
            <LabeledInput
              type="number"
              step="any"
              label="Max Amount Base"
              {...state.getInputProps("input_upper_bound")}
            />
          </FormRow>

          <FormRow>
            <LabeledInput
              type="number"
              step="any"
              label="Min Price"
              {...state.getInputProps("lower_price")}
            />
            <LabeledInput
              type="number"
              step="any"
              label="Max Price"
              {...state.getInputProps("upper_price")}
            />
          </FormRow>
        </FormCol>
        <FormCol>
          <styles.CounterSwitch
            label="Output event"
            name="output-event"
            items={state.switchOptions("output_event")}
            selectedItem={state.data.output_event}
            onChange={state.getHandler("output_event")}
            $orientation="row"
          />
          <LabeledPercentRangeInput
            step="0.1"
            label="Fill Percent Min"
            {...state.getInputProps("output_lower_bound_perc")}
          />
          <LabeledPercentRangeInput
            step="0.1"
            label="Fill Percent Max"
            {...state.getInputProps("output_upper_bound_perc")}
          />
        </FormCol>
        <Button size="m" type="button" onClick={state.onSubmit}>
          Save
        </Button>
      </Form>
    );
  }
);
