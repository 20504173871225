import { FontSpec } from "chart.js";

function fontObjectToFontString(fontConfig: Partial<FontSpec> = {}) {
  const {
    family = "Inter",
    size = 12,
    style = "normal",
    weight = "normal",
    lineHeight = 1.2,
  } = fontConfig;

  return `${style || ""} ${weight || ""} ${size}px${lineHeight ? `/${lineHeight}` : ""} ${family}`;
}

export interface IDrawTextOptions {
  ctx: CanvasRenderingContext2D;
  text: string;
  x: number;
  y: number;
  font?: Partial<FontSpec>;
  color?: string;
  align?: CanvasTextAlign;
  baseline?: CanvasTextBaseline;
}

export const drawText = ({
  ctx,
  text,
  x,
  y,
  font,
  align = "center",
  baseline = "middle",
  color = "black",
}: IDrawTextOptions) => {
  ctx.save();

  const fontString = fontObjectToFontString(font);

  ctx.fillStyle = color;
  ctx.font = fontString;
  ctx.textAlign = align;
  ctx.textBaseline = baseline;
  ctx.fillText(text, x, y);

  ctx.restore();
};

type ClipArea = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export const clipArea = (ctx: CanvasRenderingContext2D, { x, y, width, height }: ClipArea) => {
  ctx.save();

  ctx.beginPath();
  ctx.rect(x, y, width, height);
  ctx.clip();

  ctx.restore();
};
