import React from "react";
import { Loader } from "src/components/shared/Loader";
import { TablePanelHeader, TablePanelHeaderOwnProps } from "./TablePanelHeader";
import * as styles from "./style";

export interface TablePanelProps
  extends TablePanelHeaderOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof TablePanelHeaderOwnProps> {
  isLoading?: boolean;
}

export const TablePanel = ({
  title,
  afterTitle,
  isLoading,
  children,
  ...props
}: TablePanelProps) => (
  <styles.Container {...props}>
    <TablePanelHeader title={title} afterTitle={afterTitle} />

    <styles.Content>
      {children}
      <Loader show={isLoading} />
    </styles.Content>
  </styles.Container>
);
