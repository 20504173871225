import { PNLV2ItemContainer } from "src/components/shared/PNLV2/shared/Item/PNLV2Item/style";
import styled from "styled-components";

export const PNLItemWrapper = styled(PNLV2ItemContainer)`
  display: flex;
  justify-content: space-between;

  /* color: inherit; */

  padding: 0 8px;
  gap: 8px;
`;
