import { FieldPath, FieldValues } from "react-hook-form";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import { ControllerWrapper, ControllerWrapperProps } from "./ControllerWrapper";

export interface ControlledToggleProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  controllerProps: Omit<ControllerWrapperProps<TForm, TName>, "component">;
}

export const ControlledToggle = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  controllerProps,
}: ControlledToggleProps<TForm, TName>) => (
  <ControllerWrapper {...controllerProps} component={ToggleSwitch} />
);
