import { Subtitle } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

export const OperatorText = styled(Subtitle)`
  font-weight: ${({ theme }) => theme.fontWeights["600"]};
`;
