import { useMemo } from "react";
import { useTheme } from "styled-components";
import { ChartProps } from "react-chartjs-2";
import { rgba } from "polished";
import { ChartPoint } from "src/modules/shared";
import { LineChartPoint } from "src/modules/dashboard";

type ChartLineProps = ChartProps<"line", LineChartPoint[] | number[], string>;

const useChartColors = () => {
  const theme = useTheme();
  const {
    dashboard: {
      accent: { malachite, rubyRed },
    },
  } = theme;

  return { profitColor: malachite, lossColor: rubyRed };
};

const useMaxAbsValue = (series: ChartPoint[]) => {
  const maxAbs = useMemo(() => {
    const absSeries = series.map((point) => Math.abs(point.value));

    return Math.max(...absSeries);
  }, [series]);

  return maxAbs;
};

export const useBaseData = (series: ChartPoint[]) => {
  const { lossColor, profitColor } = useChartColors();

  const data = useMemo((): ChartLineProps["data"] => {
    if (!series.length) return { datasets: [] };

    const lastValue = series[series.length - 1].value;
    const borderColor = lastValue < 0 ? lossColor : profitColor;
    const backgroundColor = rgba(borderColor, 0.2);
    const preparedData = series.map((item, i) => ({ x: i, y: item.value }));

    return {
      datasets: [
        {
          data: preparedData,
          borderColor,
          backgroundColor,
          fill: true,
          pointRadius: 0,
          borderWidth: 1,
        },
      ],
    };
  }, [lossColor, profitColor, series]);

  return data;
};

export const useBaseOptions = (series: ChartPoint[]) => {
  const maxAbsValue = useMaxAbsValue(series);

  const options = useMemo(
    (): ChartLineProps["options"] => ({
      maintainAspectRatio: false,
      layout: { padding: { top: 0, left: 0, right: 0, bottom: 0 } },
      plugins: { legend: { display: false }, tooltip: { enabled: false } },
      scales: {
        x: {
          display: true,
          grid: { display: false },
          ticks: { display: false },
          type: "linear",
          position: "center",
          bounds: "data",
        },
        y: {
          display: false,
          min: -maxAbsValue,
          max: maxAbsValue,
        },
      },
    }),
    [maxAbsValue]
  );

  return options;
};
