import { observer } from "mobx-react-lite";
import { rgba } from "polished";
import { useMemo } from "react";
import { LiquidityTypeData } from "src/state/CEX/CEXDashboard/v1/LiquidityStore";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { useDashboardColors } from "../../../../shared/hooks/useDashboardColors";
import { useWidgetsState } from "../../../../shared/hooks/useWidgetsState";
import { LiquidityBar, LiquidityBarProps } from "../shared/LiquidityBar";
import { LiquidityCard } from "../shared/style";

const BUY_LIQUIDITY_INFO_TEXT = `Bar charts show organic and Gotbit shares of liquidity in the orderbook for buy side orders.
Yellow line displays the average price of the ticker in USD.`;

interface UseLiquidityDataParams {
  time: number[];
  data: LiquidityTypeData;
  price: number[];
}

export const useLiquidityData = ({
  time,
  data: { our, notOur, total },
  price,
}: UseLiquidityDataParams) => {
  const {
    accent: { royalBlue, rubyRed, yellow },
  } = useDashboardColors();

  const barData = useMemo(
    (): LiquidityBarProps["data"] => ({
      labels: time,
      datasets: [
        {
          label: "Gotbit",
          data: our,
          borderWidth: 0,
          backgroundColor: rgba(royalBlue, 0.65),
          hoverBackgroundColor: rgba(royalBlue, 0.8),
        },
        {
          label: "Organic",
          data: notOur,
          borderWidth: 0,
          backgroundColor: rgba(rubyRed, 0.45),
          hoverBackgroundColor: rgba(rubyRed, 0.7),
        },
        { label: "Total", data: total, hidden: true },
        {
          type: "line",
          label: "Price",
          data: price,
          borderWidth: 1,
          backgroundColor: rgba(yellow, 0.8),
          borderColor: yellow,
          order: -1,
          pointRadius: 2,
          yAxisID: "y1",
        },
      ],
    }),
    [notOur, our, price, royalBlue, rubyRed, time, total, yellow]
  );

  return barData;
};

export interface BuyLiquidityChartProps extends Pick<LiquidityBarProps, "zoomDefault">, CardProps {}

export const BuyLiquidityChart = observer(({ zoomDefault, ...props }: BuyLiquidityChartProps) => {
  const { time, price, priceRange, buy, loading } = useWidgetsState("liquidity");

  const data = useLiquidityData({ time, data: buy, price });

  const barProps = { data, zoomDefault };

  return (
    <LiquidityCard
      title="Buy liquidity"
      info={BUY_LIQUIDITY_INFO_TEXT}
      loading={loading}
      {...props}
    >
      <LiquidityBar priceRange={priceRange} {...barProps} />
    </LiquidityCard>
  );
});
