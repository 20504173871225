import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { ChooseSettingParamsContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import {
  ChangeableCEXSettings,
  CompareConfig,
  SettingsParamsPaths,
} from "src/state/expertSystem/strategies/changeSettingsModule/shared/types";
import {
  checkOnlyEqualParam,
  getDefaultParam,
} from "src/state/expertSystem/strategies/changeSettingsModule/shared/utils";
import { settingsValidationSchema } from "src/state/expertSystem/strategies/changeSettingsModule/shared/validation";
import { FieldIndicator } from "../../../../../shared/ChangeSettingsModule/FieldIndicator";
import { SwitchController } from "../../../../../shared/ChangeSettingsModule/FormFields/SwitchController";
import { useSetFormSetter } from "../../../../../shared/ChangeSettingsModule/FormFields/hooks/useSetFormSetter";
import { useYupValidationResolver } from "../../../../../shared/ChangeSettingsModule/FormFields/hooks/useYupValidationResolver";
import { RowsContainer } from "../../../../../shared/ChangeSettingsModule/FormFields/style";
import { LabelValue } from "../../../../../shared/LabelValue";
import { SubModuleShell } from "../../../../../shared/SubModuleShell";
import { getControlConfig } from "../../../../../shared/utils/changeSettings";
import { CompareSelector } from "../../shared/CompareSelector";

export interface FormRowsProps {
  setComparesConfig: (data: CompareConfig) => void;
  setSettingsConfig: (data: Partial<ChangeableCEXSettings>) => void;
  generateExpression: () => void;
  defaultSettings: Partial<ChangeableCEXSettings>;
  defaultCompareConfig: CompareConfig;
  getParamStatus: (param: SettingsParamsPaths) => boolean;
  removeConfigParam: (param: SettingsParamsPaths) => void;
}

export const FormRows = observer(
  ({
    defaultSettings,
    defaultCompareConfig,
    setComparesConfig,
    setSettingsConfig,
    generateExpression,
    getParamStatus,
    removeConfigParam,
  }: FormRowsProps) => {
    const state = useLateInitContext(ChooseSettingParamsContext);

    const {
      control,
      unregister,
      setValue,
      handleSubmit,
      formState: { isValid: isSettingsValid },
    } = useForm<Partial<ChangeableCEXSettings>>({
      defaultValues: defaultSettings,
      resolver: useYupValidationResolver(settingsValidationSchema),
    });

    const {
      control: compareControl,
      unregister: cmpUnregister,
      handleSubmit: cmpHandleSubmit,
      formState: { isValid: isCmpValid },
    } = useForm<CompareConfig>({ defaultValues: defaultCompareConfig });

    const setCb = useCallback(
      (param: SettingsParamsPaths) => setValue(param, getDefaultParam(param)),
      [setValue]
    );

    useSetFormSetter(setCb, state);

    const handleSettingsForm = handleSubmit(setSettingsConfig);
    const handleCmpForm = cmpHandleSubmit(setComparesConfig);

    const handleForm = async () => {
      await handleCmpForm();
      await handleSettingsForm();

      if (isCmpValid && isSettingsValid) generateExpression();
    };

    return (
      <>
        <RowsContainer
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleForm();
            }
          }}
        >
          {state.settingsParams.map((param) => (
            <SubModuleShell
              key={param}
              headerProps={{
                remove: state.removeSettingParamHandler(param, [
                  (param: SettingsParamsPaths) => unregister(param),
                  (param: SettingsParamsPaths) => cmpUnregister(param),
                  (param: SettingsParamsPaths) => removeConfigParam(param),
                ]),
              }}
            >
              <CompareSelector
                control={compareControl}
                name={param}
                onlyEqual={checkOnlyEqualParam(param)}
              />

              <SwitchController
                config={getControlConfig(param)}
                control={control}
                name={param}
                param={param}
              />

              <FieldIndicator isAdded={getParamStatus(param)} />

              <LabelValue label="Current value" value={state.getCurrentSettingsParam(param)} />
            </SubModuleShell>
          ))}
        </RowsContainer>

        <OutlineButton disabled={state.isAddedParams} size="m" onClick={handleForm}>
          Confirm
        </OutlineButton>
      </>
    );
  }
);
