import styled from "styled-components";
import { Header } from "../Header";
import { DEXV2Bot } from "./DEXV2Bot";
import { DEXV2BotColumns } from "./DEXV2Bot/style";
import { DEXV2BotMob } from "./DEXV2BotMob";

export const DEXV2HeaderMain = styled(Header)`
  grid-template-columns: 40px ${DEXV2BotColumns};
`;

export const DEXV2BotMobMain = styled(DEXV2BotMob)``;

export const DEXV2BotMain = styled(DEXV2Bot)``;
