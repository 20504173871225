import { SeriesType } from "lightweight-charts";
import { useCallback } from "react";
import { Nullish } from "src/helpers/utils";
import { SeriesRoot, SeriesRootProps } from "./SeriesRoot";
import { ISeriesApiProvider } from "./SeriesRoot/types";

export type ChartSeriesProps<T extends SeriesType> = SeriesRootProps<T> & {
  onSeriesChange?: (id: string, series: Nullish<ISeriesApiProvider<T>>) => void;
};

export const ChartSeries = <T extends SeriesType>({
  onSeriesChange,
  id,
  ...props
}: ChartSeriesProps<T>) => {
  const seriesRefCb = useCallback(
    (series: Nullish<ISeriesApiProvider<T>>) => {
      onSeriesChange?.(id, series);
    },
    [onSeriesChange, id]
  );

  return <SeriesRoot ref={seriesRefCb} id={id} {...props} />;
};
