import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { providers } from "ethers";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logDev } from "src/helpers/network/logger";
import { ICache, MapCache } from "src/state/shared/Cache";
import { CacheOptions, validatePath } from "../../../../utils";
import { V2Pair } from "../entities/V2Pair";
import { V2Route } from "../entities/V2Route";
import { FactoryContract, IFactoryContract } from "../entities/contracts/FactoryContract";
import { IPairContract, PairReserves } from "../entities/contracts/PairContract";

export interface IV2RoutesProvider {
  getRoute: (path: Token[], options?: CacheOptions) => Promise<V2Route<Token, Token> | null>;
}

export interface IRoutesProviderParams {
  factoryAddress: string;
  chainId: number;
  provider: providers.JsonRpcProvider;
  pairsCache: ICache<V2Pair>;
}

export class V2RoutesProvider implements IV2RoutesProvider {
  private _provider: providers.JsonRpcProvider;

  private _chainId: number;

  private _factory: IFactoryContract;

  private _pairContractsCache: ICache<IPairContract>;

  private _pairCache: ICache<V2Pair>;

  constructor({ factoryAddress, provider, chainId, pairsCache }: IRoutesProviderParams) {
    this._provider = provider;

    this._chainId = chainId;

    this._factory = new FactoryContract(factoryAddress, provider);

    this._pairContractsCache = new MapCache();

    this._pairCache = pairsCache;
  }

  private getContractsPairCacheKey = (chainId: number, token0: Token, token1: Token) =>
    `pair-${chainId}-${token0.address}-${token1.address}`;

  private _getPairCacheKey = (chainId: number, pairAddress: string) =>
    `pair-${chainId}-${pairAddress}`;

  private _getPairContract = async (token0: Token, token1: Token) => {
    try {
      const contract = await this._factory.getPair(token0.address, token1.address);
      return contract;
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  private _getCachedPairContract = async (token0: Token, token1: Token) => {
    const pairKey = this.getContractsPairCacheKey(this._chainId, token0, token1);

    const cachedContract = this._pairContractsCache.get(pairKey);
    if (cachedContract) {
      return cachedContract;
    }

    const contract = await this._getPairContract(token0, token1);
    if (contract) {
      this._pairContractsCache.set(pairKey, contract);
    }
    return contract;
  };

  private _getPairReserves = async (
    pairContract: IPairContract
  ): Promise<PairReserves | undefined> => {
    try {
      const [reserve0, reserve1] = await pairContract.getReserves();
      return [reserve0, reserve1];
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  private _getPair = async (contract: IPairContract, token0: Token, token1: Token) => {
    const reserves = await this._getPairReserves(contract);
    if (!reserves) {
      return;
    }
    const [reserve0, reserve1] = reserves;

    logDev(["_getPair", "tokens", token0, token1, reserves]);

    return new V2Pair(
      CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
      CurrencyAmount.fromRawAmount(token1, reserve1.toString())
    );
  };

  private _getCachedPair = async (
    pairContract: IPairContract,
    token0: Token,
    token1: Token,
    useCache: boolean
  ) => {
    const pairKey = this._getPairCacheKey(this._chainId, pairContract.address);

    if (useCache) {
      const cachedPair = this._pairCache.get(pairKey);
      if (cachedPair) {
        return cachedPair;
      }
    }

    const pair = await this._getPair(pairContract, token0, token1);
    if (pair) {
      this._pairCache.set(pairKey, pair);
    }

    return pair;
  };

  private _getPoolPair = async (tokenA: Token, tokenB: Token, useCache = true) => {
    const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];

    const contract = await this._getCachedPairContract(token0, token1);
    if (!contract) return;

    const pair = await this._getCachedPair(contract, token0, token1, useCache);
    return pair;
  };

  getRoute = async (path: Token[], options: CacheOptions = {}) => {
    validatePath(path);

    const pairs: V2Pair[] = [];

    for (let i = 0; i < path.length - 1; i += 1) {
      const token0 = path[i];
      const token1 = path[i + 1];
      // eslint-disable-next-line no-await-in-loop
      const pair = await this._getPoolPair(token0, token1, options?.useCache);
      if (!pair) {
        continue;
      }
      pairs.push(pair);
    }

    // pairs count should be tokens count - 1;
    if (pairs.length !== path.length - 1) {
      return null;
    }

    const tokenIn = path[0];
    const tokenOut = path[path.length - 1];

    return new V2Route(pairs, tokenIn, tokenOut);
  };
}
