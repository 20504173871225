import { Headline3 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 4px;
`;

export const TitleIconWrapper = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.dashboard.textSecondary};
`;

export const Title = styled(Headline3)`
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  color: ${({ theme }) => theme.dashboard.textSecondary};
`;
