import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 1 auto;

  gap: 15px;
`;
