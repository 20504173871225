import { useEventCallback } from "@mui/material";
import { useRef } from "react";

export type UseScrollToTopResponse<T> = [React.RefObject<T>, () => void];

export const useScrollToTop = <T extends HTMLElement>(
  options: ScrollToOptions = { top: 0 }
): UseScrollToTopResponse<T> => {
  const tableRef = useRef<T>(null);

  const scrollToTop = useEventCallback(() => {
    if (tableRef.current) {
      tableRef.current.scrollTo(options);
    }
  });

  return [tableRef, scrollToTop];
};
