import { CONDITION_MODULE_GROUPS_MAP, ConditionModuleGroupsNames } from "src/modules/expertSystem";
import { CONDITIONAL_EMPTY_MAP } from "../consts";

type EmptyMap = typeof CONDITIONAL_EMPTY_MAP;

export const getModulesInGroup = (group: ConditionModuleGroupsNames) =>
  CONDITION_MODULE_GROUPS_MAP[group];

export const getEmptyConditionModule = <T extends ConditionModuleGroupsNames>(
  group: T
): EmptyMap[T] => CONDITIONAL_EMPTY_MAP[group];
