import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Loader } from "src/components/shared/Loader";
import * as styles from "./style";

export interface FormProps extends Omit<React.ComponentPropsWithoutRef<"form">, "onKeyPress"> {
  isLoading?: boolean;
}

export const Form = observer(({ isLoading = false, children, ...props }: FormProps) => {
  const preventEnterPress: React.KeyboardEventHandler<HTMLFormElement> = useCallback((event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }, []);

  return (
    <styles.LiquidityForm onKeyPress={preventEnterPress} {...props}>
      {children}
      <Loader show={isLoading} />
    </styles.LiquidityForm>
  );
});
