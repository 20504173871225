import { observer } from "mobx-react-lite";
import { FilterPanel } from "../../../shared";
import { useModuleState } from "../../hooks/useModuleState";
import { ActionsFiltersContent } from "./ActionsFiltersContent";

export interface ActionsFiltersProps {}

export const ActionsFilters = observer(() => {
  const { filtersCount } = useModuleState("action");
  return (
    <FilterPanel count={filtersCount}>
      <ActionsFiltersContent />
    </FilterPanel>
  );
});
