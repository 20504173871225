import { Tabs } from "src/components/shared/Tabs";
import styled from "styled-components";

export const ApiKeysContainer = styled.div`
  width: 100%;
  height: 0px;

  display: flex;
  flex: 1 1 auto;
  gap: 22px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const AccountTabs = styled(Tabs)``;
