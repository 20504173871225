import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledCreatableSelector, LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Loader } from "src/components/shared/Loader";
import { FundingStoreContext } from "src/context/CEX/FundingProvider";
import { stringToSelectorValue } from "src/helpers/forms/selectors";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { FundingFormStore } from "src/state/funding/CEX/FundingFormStore";
import { DateTimeInputPrompt } from "../../ExpertSystem/shared";
import * as styles from "./style";

const MENU_ITEMS = [
  { value: "withdraw", label: "withdraw" },
  { value: "deposit", label: "deposit" },
];

const CREATE_FUNDING_ID = "createFundingForm";

export const FundingForm = observer(() => {
  const mainStore = useLateInitContext(FundingStoreContext);

  const state = useLocalStore(FundingFormStore, mainStore);

  const handleCreate = (inputValue: string) => {
    state.setCurrency(inputValue);

    const setSelectCurrency = state.onFundingSelectorChange("currency");

    setSelectCurrency(stringToSelectorValue(inputValue));
  };

  useEffect(() => {
    state.getCurrencies();
  }, [state]);

  return (
    <styles.Container title="New funding">
      <styles.FormContent id={CREATE_FUNDING_ID} onSubmit={state.submitHandler}>
        <styles.Wrapper>
          <LabeledSelector
            label="Type"
            options={MENU_ITEMS}
            value={state.selectorValue("type")}
            onChange={state.onFundingSelectorChange("type")}
            errorHint={state.errors.type}
          />

          <LabeledSelector
            label="Exchange"
            options={state.exchanges}
            value={state.selectorValue("exchange")}
            onChange={state.onFundingSelectorChange("exchange")}
            errorHint={state.errors.exchange}
          />

          <LabeledSelector
            isDisabled={!state.exchangeSelected}
            label="Account"
            options={state.mainState.accountState.getSelectAccounts(state.newFunding.exchange)}
            value={state.currentAccount}
            onChange={state.onFundingSelectorChange("account_uuid")}
            errorHint={state.errors.account_uuid}
          />
        </styles.Wrapper>
        <styles.Wrapper>
          <LabeledInput
            label="Amount"
            type="number"
            step="any"
            value={state.newFunding.amount}
            onChange={state.getHandler("amount")}
            errorHint={state.errors.amount}
          />

          <LabeledCreatableSelector
            label="Currency"
            value={state.selectorValue("currency")}
            options={state.currencyList}
            onChange={state.onFundingSelectorChange("currency")}
            onCreateOption={handleCreate}
            errorHint={state.errors.currency}
          />

          <LabeledInput
            label="Time"
            after={<DateTimeInputPrompt />}
            type="datetime-local"
            placeholder="YYYY-MM-DDThh:mm"
            value={state.selectedTime}
            onChange={state.getHandler("time")}
            errorHint={state.errors.time}
          />
        </styles.Wrapper>

        <Loader show={state.isLoading} />
      </styles.FormContent>

      <styles.ButtonWrapper>
        <styles.TextArea
          placeholder="Comment"
          value={state.newFunding.comment}
          onChange={(e) =>
            state.getHandler("comment")(e as unknown as React.ChangeEvent<HTMLInputElement>)
          }
          minRows={3}
          maxRows={3}
        />

        <OutlineButton color="neutral" onClick={state.clearForm}>
          Clear
        </OutlineButton>

        <SubmitButton form={CREATE_FUNDING_ID} />
      </styles.ButtonWrapper>
    </styles.Container>
  );
});
