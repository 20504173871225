import { IReactionDisposer, makeAutoObservable, reaction, toJS } from "mobx";
import { computedFn } from "mobx-utils";
import {
  changeUserGroup,
  deleteUserGroup,
  moveUserToUserGroup,
} from "src/api/userManager/userGroupsAPI";
import { toast } from "src/components/shared/Toaster";
import { getData, setData } from "src/helpers/forms/getByKey";
import { getSelectorList, stringToSelectorValue } from "src/helpers/forms/selectors";
import { FormDataKeys, FormErrors, FormHandlers, FormValidation } from "src/helpers/forms/types";
import { ObjectPathValue } from "src/helpers/forms/types/NestedObject";
import { Extends, IDisposable } from "src/helpers/utils";
import { SelectorValue } from "src/modules/shared";
import { GroupType, NewUserGroup, User, UserGroup } from "src/modules/userManager";
import { required, validateData } from "src/validation-schemas";
import { SelectorHandler } from "./AddUserGroupStore";

type EditUserGroup = UserGroup;

type EditUserGroupKeys = FormDataKeys<EditUserGroup>;

type FormSelectors = Extends<EditUserGroupKeys, "head">;

// type GroupLinkSelectors = "groups";
export interface EditUserGroupProvider {
  getUserGroupsList: () => Promise<void>;
  getUsersList: () => Promise<void>;
  usersByGroupType: (type: GroupType) => User[];
  userGroupsByUserType: (type: GroupType) => UserGroup[];
  getUserGroupByName: (name: string) => UserGroup;
  getUserByName(name: string): User;
}

export const INITIAL_EDIT_USER_GROUP: EditUserGroup = {
  name: "",
  type: "other",
  head: "",
  users: [],
  id: 0,
};

export default class EditUserGroupStore implements IDisposable {
  private _validation: FormValidation<NewUserGroup> = {
    name: required(),
    type: required(),
  };

  private _isShownReaction: IReactionDisposer;

  private _currentUserGroupChangedReaction: IReactionDisposer;

  private _provider: EditUserGroupProvider;

  handlers: FormHandlers<NewUserGroup> = {};

  errors: FormErrors<NewUserGroup> = {};

  private _isLoading = false;

  editUserGroup: EditUserGroup = INITIAL_EDIT_USER_GROUP;

  private _currentEditUserGroupName = "";

  isShown: boolean = false;

  constructor(provider: EditUserGroupProvider) {
    this._provider = provider;

    makeAutoObservable(this, {
      selectorValue: false,
    });

    this._isShownReaction = reaction(
      () => this.isShown,
      (isShown) => {
        if (!isShown) {
          this._resetForm();
        }
      }
    );

    this._currentUserGroupChangedReaction = reaction(
      () => this._currentEditUserGroup,
      (userGroup) => {
        this._setEditUserGroup(userGroup);
      }
    );
  }

  setLoading = (loading: boolean) => {
    this._isLoading = loading;
  };

  get isLoading() {
    return this._isLoading;
  }

  get deleteDisabled() {
    return this.editUserGroup.users.length > 0;
  }

  private _setIsModalShow(isShown: boolean) {
    this.isShown = isShown;
  }

  private _resetForm = () => {
    this.handlers = {};
    this.errors = {};
    this.editUserGroup = INITIAL_EDIT_USER_GROUP;
  };

  private _setEditUserGroup = (userGroup: EditUserGroup) => {
    this.editUserGroup = toJS(userGroup);
  };

  private _setCurrentEditUserGroupName = (name: string) => {
    this._currentEditUserGroupName = name;
  };

  private get _currentEditUserGroup() {
    return this._provider.getUserGroupByName(this._currentEditUserGroupName);
  }

  openModal = (groupName: string) => {
    this._setIsModalShow(true);
    this._setCurrentEditUserGroupName(groupName);
  };

  closeModal = () => {
    this._setIsModalShow(false);
    this._setCurrentEditUserGroupName("");
  };

  getSelectorHandler = (key: FormSelectors): SelectorHandler => {
    switch (key) {
      case "head": {
        return (data) => {
          const value = data === null ? "" : String(data.value);
          this._setData(key, value);
        };
      }
    }
  };

  selectorValue = computedFn((key: FormSelectors): SelectorValue | null => {
    switch (key) {
      case "head": {
        const value = this._getData(key);
        if (!value) return null;
        return stringToSelectorValue(value);
      }
      default: {
        const value = this._getData(key);
        return stringToSelectorValue(value);
      }
    }
  });

  getUserTypeByName = (name: string) => this._provider.getUserByName(name).group_type;

  get headSelectorOptions(): SelectorValue[] {
    return getSelectorList(
      this._provider.usersByGroupType(this.editUserGroup.type).map((user) => user.name)
    );
  }

  groupsSelectorOptions = (userType: GroupType): SelectorValue[] =>
    getSelectorList(this._provider.userGroupsByUserType(userType).map((group) => group.name));

  private _setData = <K extends EditUserGroupKeys>(
    key: K,
    value: ObjectPathValue<EditUserGroup, K>
  ) => {
    setData(this.editUserGroup, key, value);
  };

  private _getData = <K extends EditUserGroupKeys>(key: K) => getData(this.editUserGroup, key);

  validate = (validateKeys?: string[]) =>
    validateData(this._validation, this.editUserGroup, this.errors, validateKeys);

  moveUserToUserGroup = async (userName: string, userGroup: string) => {
    this.setLoading(true);

    try {
      const { isError } = await moveUserToUserGroup(userName, userGroup);

      if (!isError) {
        toast.success("User moved successfully");
        this._provider.getUserGroupsList();
        this._provider.getUsersList();
      }
    } finally {
      this.setLoading(false);
    }
  };

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();

    if (valid) {
      this.setLoading(true);

      try {
        const { isError } = await changeUserGroup(this.editUserGroup.name, this.editUserGroup.head);

        if (!isError) {
          toast.success("User group edited successfully");
          this._provider.getUserGroupsList();
        }
      } finally {
        this.setLoading(false);
      }
    }
  };

  deleteUserGroup = async () => {
    this.setLoading(true);

    try {
      const { isError } = await deleteUserGroup(this.editUserGroup.name);

      if (!isError) {
        toast.success("User group deleted successfully");
        this._setIsModalShow(false);
        this._provider.getUserGroupsList();
      }
    } finally {
      this.setLoading(false);
    }
  };

  destroy() {
    this._isShownReaction();
    this._currentUserGroupChangedReaction();
  }
}
