import { memo } from "react";
import { useId } from "src/hooks/useId";
import Icons from "src/icons/Icons";
import { TransactionState, TransactionStatus } from "src/modules/bots";
import * as styles from "./style";

interface ErrorCellProps {
  status: TransactionStatus;
}

const useStatusIcon = (status: TransactionStatus) => {
  const id = useId(4);

  switch (status.state) {
    case TransactionState.Success: {
      return (
        <styles.IconWrapper>
          <Icons.Success />
        </styles.IconWrapper>
      );
    }
    case TransactionState.Pending: {
      return (
        <styles.IconWrapper>
          <Icons.Pending />
        </styles.IconWrapper>
      );
    }
    case TransactionState.Error: {
      return (
        <styles.IconWrapper data-tooltip-content={status.message} data-tooltip-id={id}>
          <Icons.Error />
          <styles.ErrInfoMsg id={id} variant="error" place="left" />
        </styles.IconWrapper>
      );
    }
  }
};

export const TransactionStatusCell = memo(({ status }: ErrorCellProps) => {
  const StatusIcon = useStatusIcon(status);
  return <styles.Wrapper>{StatusIcon}</styles.Wrapper>;
});
