import styled from "styled-components";
import { TableCellText } from "src/components/shared/TableV2";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Container = styled.div`
  width: max-content;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
`;

export const AmountList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`;

export const FundingType = styled(TableCellText)`
  width: auto;
  height: auto;
  font-weight: bold;
`;

export const Amount = styled(TableCellText)`
  width: auto;
  text-align: right;
`;
