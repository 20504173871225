import { makeAutoObservable, runInAction } from "mobx";
import { getPairs } from "src/api/bots/CEX/exchange";
import { getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { logError } from "src/helpers/network/logger";
import { filterCallback, noOp } from "src/helpers/utils";
import { NewPairAnalytic } from "src/modules/analytics";
import { Pair } from "src/modules/exchange/trade";
import { required, validateData } from "src/validation-schemas";

const MAIN_PAIRS = ["USDT_BTC", "USDT_ETH", "USDT_USDC"];

type NewPairAnalyticsKeys = FormDataKeys<NewPairAnalytic>;

export const INITIAL_NEW_PAIR: NewPairAnalytic = {
  pair: "",
  name: "",
};

class ModalAnalyticStore {
  newPairAnalytic = INITIAL_NEW_PAIR;

  private _validation: FormValidation<NewPairAnalytic> = {
    name: required(),
    pair: required(),
  };

  private _pairs: Pair[] = [];

  private _bot_uuid: string = "";

  private _market: string = "";

  private _searchPair: string = "";

  handlers: FormHandlers<NewPairAnalytic> = {};

  errors: FormErrors<NewPairAnalytic> = {};

  private _isLoading: boolean = false;

  isShown: boolean = false;

  saveCb: (analytic: NewPairAnalytic) => void = noOp;

  shownModal: React.Dispatch<React.SetStateAction<boolean>> = noOp;

  constructor() {
    makeAutoObservable(this);
  }

  setModalShow = (setShown: React.Dispatch<React.SetStateAction<boolean>>) => {
    this.shownModal = setShown;
  };

  setLoading = (loading: boolean) => {
    this._isLoading = loading;
  };

  get isLoading() {
    return this._isLoading;
  }

  get originBase() {
    const [, base] = this._market.split("_");

    return base;
  }

  private get pairs() {
    return this._pairs
      .map(({ base, quote }) => ({
        value: `${quote}_${base}`,
        label: `${quote}_${base}`,
      }))
      .sort((a, b) => this._pairsSort(a.value, b.value));
  }

  get shortPairsList() {
    return this.pairs.filter(({ value }) => filterCallback(value, this._searchPair)).slice(0, 50);
  }

  get exchange() {
    return this._market.split("_")[2] || "";
  }

  private _pairsSort = (a: string, b: string) => {
    const firstCheck = this._priorityCheck(a);
    const secondCheck = this._priorityCheck(b);

    return secondCheck - firstCheck;
  };

  private _priorityCheck = (pair: string) => {
    if (MAIN_PAIRS.includes(pair)) return 2;
    if (pair.includes(this.originBase)) return 1;
    return 0;
  };

  setBotUUID = (uuid: string) => {
    this._bot_uuid = uuid;
  };

  setMarket = (market: string) => {
    this._market = market;
  };

  setPair = (pair: string) => {
    this.newPairAnalytic.pair = pair;
  };

  setNewPair = (value: string) => {
    const [quote, base] = value.split("_");

    const newPair: Pair = {
      base,
      quote,
      minAmountBase: "",
      minAmountQuote: "",
    };

    this._pairs = [newPair, ...this._pairs];
  };

  setSearchPair = () => (value: string) => {
    runInAction(() => {
      this._searchPair = value;
    });
  };

  setSaveCb = (cb: (analytic: NewPairAnalytic) => void) => {
    this.saveCb = cb;
  };

  getAllExchPairs = async () => {
    try {
      const {
        data: { data },
        isError,
      } = await getPairs(this.exchange);

      if (!isError) {
        runInAction(() => {
          this._pairs = data;
        });
      }
    } catch (err) {
      logError(err);

      runInAction(() => {
        this._pairs = [];
      });
    }
  };

  openModal = () => {
    this.shownModal(true);
  };

  closeModal = () => {
    this.shownModal(false);
  };

  getHandler = (key: NewPairAnalyticsKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.newPairAnalytic, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        targetData[endKey] = getChangeEventValue(e);
      };
    }

    return this.handlers[key]!;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this._validation, this.newPairAnalytic, this.errors, validateKeys);

  submitHandler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();

    if (valid) {
      this.setLoading(true);

      try {
        this.saveCb(this.newPairAnalytic);

        this.closeModal();
      } finally {
        this.setLoading(false);
      }
    }
  };
}

export default ModalAnalyticStore;
