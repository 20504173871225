import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { MenuItemData } from "src/components/shared/SwitchMenu/MenuItem";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { GasWalletsProvider } from "src/context/DEXV2/DEXV2Stats/GasWallets/GasWallets";
import { GraphsProvider } from "src/context/DEXV2/DEXV2Stats/Graphs/Graphs";
import { HashesProvider } from "src/context/DEXV2/DEXV2Stats/Hashes/Hashes";
import { VaultsProvider } from "src/context/DEXV2/DEXV2Stats/Vaults/Vaults";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { DEXV2BotInfo } from "../DEXV2BotInfo";
import { DEXV2Notes } from "./DEXV2Notes";
import { DEXV2PNL } from "./DEXV2PNL";
import { Graphs } from "./Graphs";
import { GraphFallback } from "./Graphs/style";
import { GasWallets } from "./Tables/GasWallets";
import { GasWalletsFallback } from "./Tables/GasWallets/styles";
import { Hashes, useHashesTableTabs } from "./Tables/Hashes";
import { HashesFallback } from "./Tables/Hashes/style";
import { Vaults } from "./Tables/Vaults";
import { VaultsFallback } from "./Tables/Vaults/styles";
import * as styles from "./style";

const enum TablesTabs {
  Wallets = "GAS_WALLETS",
  Vaults = "VAULTS",
  Hashes = "HASHES",
}

interface TablesMenuItem extends MenuItemData {
  value: TablesTabs;
}

const MENU_ITEMS: TablesMenuItem[] = [
  { label: "Gas Wallets", value: TablesTabs.Wallets },
  { label: "Vaults", value: TablesTabs.Vaults },
  { label: "Bot hashes", value: TablesTabs.Hashes },
];

interface UseStatsTablesResponse {
  tables: (JSX.Element | null)[];
  tabs: TablesMenuItem[];
}

const getAreaStyles = (areas: string[]) => ({ style: { gridArea: areas.shift() ?? "" } });

const useStatsTables = (): UseStatsTablesResponse => {
  const { hasAbility } = useLateInitContext(AbilitiesContext);

  const hashesTabs = useHashesTableTabs();
  const showHashes = hashesTabs.length > 0;

  const showWallets = hasAbility(DEXV2Ability.WalletsView);

  const showVaults = hasAbility(DEXV2Ability.VaultsView);

  const tableAreas = ["gasWallets", "vaults", "hashes"];

  const WalletsTable = showWallets ? (
    <GasWalletsProvider key={TablesTabs.Wallets}>
      <ErrorBoundary fallback={<GasWalletsFallback />}>
        <GasWallets {...getAreaStyles(tableAreas)} />
      </ErrorBoundary>
    </GasWalletsProvider>
  ) : null;

  const VaultsTable = showVaults ? (
    <VaultsProvider key={TablesTabs.Vaults}>
      <ErrorBoundary fallback={<VaultsFallback />}>
        <Vaults {...getAreaStyles(tableAreas)} />
      </ErrorBoundary>
    </VaultsProvider>
  ) : null;

  const HashesTable = showHashes ? (
    <HashesProvider key={TablesTabs.Hashes}>
      <ErrorBoundary fallback={<HashesFallback />}>
        <Hashes tabs={hashesTabs} {...getAreaStyles(tableAreas)} />
      </ErrorBoundary>
    </HashesProvider>
  ) : null;
  const tables = [WalletsTable, VaultsTable, HashesTable];

  const tabs = useMemo(
    () =>
      MENU_ITEMS.filter(({ value: tab }) => {
        switch (tab) {
          case TablesTabs.Wallets: {
            return showWallets;
          }
          case TablesTabs.Vaults: {
            return showVaults;
          }
          case TablesTabs.Hashes: {
            return showHashes;
          }
          default: {
            return false;
          }
        }
      }),
    [showHashes, showVaults, showWallets]
  );

  return {
    tabs,
    tables,
  };
};

export interface DEXStatsProps {}

// using negation interval to fix rounding bug for min/max queries with same breakpoint
const MOBILE_BOT_INFO_QUERY = "(max-width: 850px), (min-width: 1100px)";
const MOBILE_COMPACT_BOT_INFO_QUERY =
  "(max-width: 650px),(min-width: 1100px) and (max-width: 1250px)";

export const DEXStats = observer((_props: DEXStatsProps) => {
  const showTabs = useMediaQuery("(max-width: 1100px)");

  const botState = useLateInitContext(DEXV2BotContext);
  const { botUUID } = botState;

  const { tabs, tables: Tables } = useStatsTables();

  const { hasAbility } = useLateInitContext(AbilitiesContext);

  const showNotes = hasAbility(DEXV2Ability.NotesView);

  const defaultTab = tabs[0]?.value ?? TablesTabs.Wallets;

  return (
    <styles.Container showNotes={showNotes} showTables={tabs.length > 0}>
      <DEXV2BotInfo
        bot_uuid={botUUID}
        showStartBtn
        mobileQuery={MOBILE_BOT_INFO_QUERY}
        compactQuery={MOBILE_COMPACT_BOT_INFO_QUERY}
        style={{
          gridArea: "bot",
          alignSelf: "end",
        }}
      />
      <DEXV2PNL />

      <DEXV2Notes style={{ gridArea: "notes" }} />

      <ErrorBoundary fallback={<GraphFallback />}>
        <GraphsProvider>
          <Graphs />
        </GraphsProvider>
      </ErrorBoundary>

      {showTabs ? (
        <styles.TablesTabs menuItems={tabs} defaultKey={defaultTab}>
          {Tables}
        </styles.TablesTabs>
      ) : (
        <>{Tables}</>
      )}
    </styles.Container>
  );
});
