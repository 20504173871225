import { ComponentPropsWithoutRef } from "react";
import { PopupComponent, PopupComponentProps } from "../popup/PopupComponent";
import { SideBarContent, SideBarContentProps } from "./SideBarContent";

export interface SidebarProps extends ComponentPropsWithoutRef<"div">, SideBarContentProps {
  popupProps: Omit<PopupComponentProps, "children">;
  lockBackgroundScroll?: boolean;
}

export const Sidebar = ({ lockBackgroundScroll = true, popupProps, ...props }: SidebarProps) => (
  <PopupComponent {...popupProps}>
    <SideBarContent {...props} />
  </PopupComponent>
);
