import Icons from "src/icons/Icons";
import { MarketCheckContext } from "src/context/MarketCheck";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { FullSizeProps } from "src/modules/shared";
import { DepthSelector } from "./DepthSelector";
import * as styles from "./style";

interface TableParamsProps extends FullSizeProps {}

export const TableParams = ({ isFullscreen, toggleFullscreen }: TableParamsProps) => {
  const state = useLateInitContext(MarketCheckContext);

  return (
    <styles.Wrapper>
      <styles.FullscreenButton isFullscreen={isFullscreen} onClick={toggleFullscreen}>
        {Icons.fullScreen()}
      </styles.FullscreenButton>

      <DepthSelector depth={state.depth} setDepth={state.setDepth} />

      <styles.RangePickerContainer>
        <DateTimeRangePickerSelector
          value={state.range}
          onChange={state.setRange}
          maxDate={getCurrentDayjs()}
          disableFuture
        />
      </styles.RangePickerContainer>
    </styles.Wrapper>
  );
};
