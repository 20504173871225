import { makeAutoObservable } from "mobx";
import { GetClientsResponse, getClients } from "src/api/bots/CEX/exchange";
import { getSelectorList } from "src/helpers/forms/selectors";
import { logError } from "src/helpers/network/logger";
import { IDisposable, Mapper } from "src/helpers/utils";

const clientsResponseToExchanges: Mapper<GetClientsResponse, string[]> = (response) =>
  response.data.map((client) => client.id);

export interface IAllExchangesProvider {
  getAllExchanges: () => Promise<string[]>;
  get allExchanges(): string[];
  get allExchangesOptions(): string[];
}

export default class AllExchangesProvider implements IDisposable {
  private _allExchanges: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get allExchanges() {
    return this._allExchanges;
  }

  get allExchangesOptions() {
    return getSelectorList(this.allExchanges);
  }

  getAllExchanges = async () => this._getExchanges();

  private _setAllExchanges = (exchanges: string[]) => {
    this._allExchanges = exchanges;
  };

  private _getExchanges = async () => {
    try {
      const { data, isError } = await getClients();

      if (!isError) {
        const exchanges = clientsResponseToExchanges(data);
        this._setAllExchanges(exchanges);
      }
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}
