import { FormRow } from "src/components/BotsContent/DEX_V2/Settings/shared/style";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled from "styled-components";
import {
  FormContent as BaseFormContent,
  SettingsContainer as BaseSettingsContainer,
  FormDynamicRow,
} from "../shared";
import { BaseSettingsPanel } from "./BaseSettingsPanel";

export const FormContent = styled(BaseFormContent)`
  width: 100%;
  max-width: 100%;

  @media ${getMobileMediaQuery("500px")} {
    max-width: 270px;
  }
`;

export const SettingsContainer = styled(BaseSettingsContainer)`
  max-width: 600px;

  @media ${getMobileMediaQuery("500px")} {
    max-width: 300px;
  }
`;

export const SettingsPanel = styled(BaseSettingsPanel)`
  ${FormContent} {
    @media ${getMobileMediaQuery("500px")} {
      ${FormRow} {
        flex-direction: column;
        gap: 20px;
      }

      ${FormDynamicRow} {
        > * {
          max-width: 100%;
          margin-right: 0;
        }
      }
    }
  }
`;
