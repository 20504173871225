import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { Column } from "react-table";
import { ErrorTableContent } from "src/components/BotsContent/CEX/LiquidityTab/ErrorsTablePanel/ErrorsTable/style";
import { TableProps, indexColumn } from "src/components/BotsContent/CEX/LiquidityTab/base/Table";
import { ModalPanelV2, ModalPanelV2Props } from "src/components/shared/ModalPanelV2";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { ModuleErrors } from "src/state/CEX/multiGrid/Modules";
import { ErrorInfoProps } from "../ErrorInfo";
import ErrorCellWrapper from "./ErrorCellWrapper";

interface ErrorsModalProps extends Pick<ModalPanelV2Props, "trigger">, ErrorInfoProps {
  activeErrors: number;
}

export const ErrorsModal = observer(
  ({ exchange, errors, activeErrors, trigger }: ErrorsModalProps) => {
    const columns = useMemo(() => {
      const columnsSchema: Column<ModuleErrors>[] = [
        indexColumn(),
        {
          Header: "Error text",
          accessor: "text",
          Cell: (info) => ErrorCellWrapper(info),
        },
        {
          Header: "Time",
          accessor: "created_at",
          Cell: ({ row, value }) =>
            ErrorCellWrapper({ row, value: unixToDateFormat(value, "FullDate") }),
        },
      ];
      return columnsSchema;
    }, []);

    return (
      <ModalPanelV2
        trigger={trigger}
        label={`Exchange: ${exchange}
Errors: ${activeErrors}`}
      >
        <ErrorTableContent<FC<TableProps<ModuleErrors>>> data={errors} columns={columns} />
      </ModalPanelV2>
    );
  }
);
