import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable, entries } from "src/helpers/utils";
import { IBotTradePairProvider } from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { TradeSide } from "src/state/DEXV2/shared/TradeToken";
import { Field, ISwapState } from "../shared/SwapStateStore";

export type TradeSideFieldMap = Partial<Record<TradeSide, Field>>;

interface ISwapInfoTradePairParams {
  swapState: ISwapState;
  tradePairProvider: IBotTradePairProvider;
}

export interface ISwapInfoTradePair {
  get tradeSideField(): TradeSideFieldMap;
  get shouldInvertPrice(): boolean;
}

export class SwapInfoTradePairStore implements ISwapInfoTradePair, IDisposable {
  private _swapState: ISwapState;

  private _tradePairProvider: IBotTradePairProvider;

  constructor({ swapState, tradePairProvider }: ISwapInfoTradePairParams) {
    makeAutoObservable(this);

    this._swapState = swapState;

    this._tradePairProvider = tradePairProvider;

    makeLoggable(this, {});
  }

  private get _swap() {
    return this._swapState.swap;
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private get _pair() {
    return this._tradePair?.pair ?? null;
  }

  get tradeSideField(): TradeSideFieldMap {
    const tradePair = this._pair;
    if (!tradePair) return {};

    const tradePairEntries = entries(tradePair);

    const tokenField: TradeSideFieldMap = {};

    const fields = Object.values(Field);

    for (const field of fields) {
      const fieldToken = this._swap[field];
      if (!fieldToken) continue;

      const tradeTokenEntry = tradePairEntries.find(([, token]) => token.equals(fieldToken));
      if (!tradeTokenEntry) continue;
      const [tradeSide] = tradeTokenEntry;

      tokenField[tradeSide] = field;
    }

    return tokenField;
  }

  private get _isBaseOutput() {
    const { base: baseField } = this.tradeSideField;
    return baseField === Field.OUTPUT;
  }

  // we invert prices in case current prices are in trade base
  get shouldInvertPrice() {
    return this._isBaseOutput;
  }

  destroy = () => {};
}
