import { StyledSelectorFilter } from "src/components/ExchangeAccounting/Content/TableHeader/Filters/SelectColumnFilter/style";
import styled from "styled-components";

export const StyledSelector = styled(StyledSelectorFilter)`
  width: auto;

  & .Custom-select__multi-value {
    ::after {
      content: "";
    }
  }
` as typeof StyledSelectorFilter;
