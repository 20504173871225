import styled from "styled-components";

export const Item = styled.div`
  width: 100%;
  height: 30px;

  display: -webkit-box;

  justify-content: space-between;
  align-items: center;

  padding: 3.5px;

  border: ${({ theme }) => theme.borders.solidGray};
  border-radius: ${({ theme }) => theme.radii["4px"]};
  background-color: ${({ theme }) => theme.bgGrayBlack};

  font-size: ${({ theme }) => theme.fontSizes["16px"]};

  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export const Asset = styled.span`
  color: ${({ amount }: { amount: number }) => (amount > 0 ? "#079E31" : "#ef0000")};
`;

export const Amount = styled(Asset)`
  padding-right: 10px;

  text-align: right;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  white-space: nowrap;
`;

export const Time = styled.span`
  margin-right: 10px;

  color: #767676;
`;

export const Account = styled(Time)`
  padding-right: 5px;

  border-right: ${({ theme }) => theme.borders.solidGray};
`;

export const Delete = styled.button`
  position: relative;

  width: 21px;
  height: 21px;

  border: none;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  background-color: ${({ theme }) => theme.closeBtnBg};

  cursor: pointer;

  ::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 12px;
    background: #767676;
    left: 10px;
    top: 4px;
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  ::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 12px;
    background: #767676;
    left: 10px;
    top: 4px;
    -ms-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
`;
