import { ComponentPropsWithoutRef } from "react";
import { HeaderForm, HeaderFormProps } from "../HeaderForm";
import { TradeButton, TradeButtonProps } from "../TradeButton";
import { FeesRow } from "./FeesRow";
import * as styles from "./style";

export interface OrderPanelProps
  extends ComponentPropsWithoutRef<"div">,
    Pick<TradeButtonProps, "side"> {
  headerProps: Omit<HeaderFormProps, "side">;
  formId: string;
}

export const OrderPanel = ({ headerProps, side, formId, children }: OrderPanelProps) => (
  <styles.Container>
    <styles.Content>
      <HeaderForm side={side} {...headerProps} />

      <styles.FormContent>{children}</styles.FormContent>

      <FeesRow />
    </styles.Content>

    <TradeButton form={formId} side={side} />
  </styles.Container>
);
