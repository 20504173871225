import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BalancesDynamicsBars } from "src/state/CEX/CEXDashboard/v2/widgets/BalancesDynamicsV2Store";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { BalancesDynamicsContext } from "../../../../../../../context/CEX/Dashboard/v2/BalancesDynamics";
import { useChartColorWheel } from "../../../shared/hooks/useChartColorWheel";
import { DashboardV2Widget, getWidgetTitle } from "../shared/constants";
import { BalancesBar, BalancesBarProps } from "./BalancesBar";
import { BalancesTable } from "./BalancesTable";
import * as styles from "./style";

export type TokensColorsMap = Record<string, string>;

const useTokensColors = (tokens: string[]) => {
  const colorWheel = useChartColorWheel();

  const tokensColors = useMemo(
    () =>
      tokens.reduce(
        (acc, token, index) => {
          acc[token] = colorWheel[index];
          return acc;
        },
        {} as Record<string, string>
      ),
    [colorWheel, tokens]
  );

  return tokensColors;
};

interface UseBalanceDynamicsDataParams {
  bars: BalancesDynamicsBars;
  colorsMap: TokensColorsMap;
}

const useBalanceDynamicsData = ({
  bars: { labels: tokens, data: values },
  colorsMap,
}: UseBalanceDynamicsDataParams) => {
  const colors = useMemo(() => tokens.map((token) => colorsMap[token]), [colorsMap, tokens]);

  const data = useMemo(
    (): BalancesBarProps["data"] => ({
      labels: tokens,
      datasets: [
        {
          indexAxis: "y",
          data: values,
          backgroundColor: colors,
          borderWidth: 0,
        },
      ],
    }),
    [colors, tokens, values]
  );

  return data;
};

export interface BalancesDynamicsProps
  extends Omit<CardProps, "title" | "after">,
    styles.BalancesCardProps {}

export const BalancesDynamics = observer(({ orientation, ...props }: BalancesDynamicsProps) => {
  const { bars, loading } = useLateInitContext(BalancesDynamicsContext.Context);

  const colorsMap = useTokensColors(bars.labels);

  const barsData = useBalanceDynamicsData({ bars, colorsMap });

  return (
    <styles.StyledBalancesCard
      title={getWidgetTitle(DashboardV2Widget.BalancesDynamics)}
      after={<BalancesTable colorsMap={colorsMap} />}
      loading={loading}
      $orientation={orientation}
      {...props}
    >
      <BalancesBar data={barsData} />
    </styles.StyledBalancesCard>
  );
});

export interface BalancesDynamicsWidgetProps extends BalancesDynamicsProps {}

export const BalancesDynamicsWidget = (props: BalancesDynamicsWidgetProps) => (
  <BalancesDynamicsContext.Provider>
    <BalancesDynamics {...props} />
  </BalancesDynamicsContext.Provider>
);
