import { makeAutoObservable, when } from "mobx";
import { isWhenCancelledError } from "src/helpers/mobx";
import { logError } from "src/helpers/network/logger";
import { IDisposable, WhenReactionPromise } from "src/helpers/utils";
import { ISwapVersionProvider } from "../../../Version/DEXV2SwapVersionStore";
import { INonNullableVersionProvider } from "../VersionedSwapState";

interface INonNullableVersionProviderParams {
  versionProvider: ISwapVersionProvider;
}

export interface INonNullableVersionProviderBuilder {
  getVersion(): Promise<INonNullableVersionProvider | undefined>;
}

export class NonNullableVersionProvider implements INonNullableVersionProviderBuilder, IDisposable {
  private _versionProvider: ISwapVersionProvider;

  private _initPromise: WhenReactionPromise | null = null;

  constructor({ versionProvider }: INonNullableVersionProviderParams) {
    makeAutoObservable(this);

    this._versionProvider = versionProvider;
  }

  private get _dexVersion() {
    return this._versionProvider.dexVersion;
  }

  private get _botVersion() {
    return this._versionProvider.botVersion;
  }

  private get _versionInitialized() {
    return Boolean(this._dexVersion) && Boolean(this._botVersion);
  }

  private get _initializedReaction() {
    return when(() => Boolean(this._versionInitialized));
  }

  private _cancelCurrentInit = () => {
    this._initPromise?.cancel();
  };

  private get _currentInitReaction() {
    this._cancelCurrentInit();
    const initialized = this._initializedReaction;
    this._initPromise = initialized;
    return initialized;
  }

  getVersion = async () => {
    try {
      await this._currentInitReaction;
      const versionProvider = this._versionProvider as INonNullableVersionProvider;
      return versionProvider;
    } catch (e) {
      if (!isWhenCancelledError(e)) {
        logError(e);
      }
    }
  };

  destroy = () => {
    this._cancelCurrentInit();
  };
}
