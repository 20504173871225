import { useLocation } from "react-router-dom";
import { useQueryParams } from "./useQueryParams";

const BOTS_PREFIXES = ["/CEX/", "/DEX/", "/DEX_V2/"];

const getCurrentTab = (pathname: string, queryParams: URLSearchParams) => {
  if (BOTS_PREFIXES.some((prefix) => pathname.startsWith(prefix))) {
    return queryParams.get("tab");
  }

  return "/AllBots";
};

const useNavigation = (path: string) => {
  const location = useLocation();
  const queryParams = useQueryParams();

  const currentTab = getCurrentTab(location.pathname, queryParams);

  return { currentTab };
};

export default useNavigation;
