import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay, entries } from "src/helpers/utils";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { generateAmount } from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

type AumSummaryTypeData = {
  quote: string;
  base: string;
  total: string;
};

export type AumSummaryData = {
  free: AumSummaryTypeData;
  locked: AumSummaryTypeData;
  total: AumSummaryTypeData;
};

export interface AumSummaryRowData extends AumSummaryTypeData {
  type: string;
  isAumTotal?: boolean;
}

const generateAumSummaryTypeData = (): AumSummaryTypeData => ({
  quote: generateAmount(),
  base: generateAmount(),
  total: generateAmount(),
});

const generateAumSummary = (): AumSummaryData => ({
  free: generateAumSummaryTypeData(),
  locked: generateAumSummaryTypeData(),
  total: generateAumSummaryTypeData(),
});

const mapAumSummaryToRowData = (summary: AumSummaryData): AumSummaryRowData[] =>
  entries(summary).map(([type, value]) => ({
    type,
    quote: value.quote,
    base: value.base,
    total: value.total,
    isAumTotal: type === "total",
  }));

interface IAumSummaryStoreParams extends IBaseDashboardV2StoreParams {}

export class AumSummaryV2Store implements IDashboardV2WidgetState {
  private _data: AumSummaryRowData[] = [];

  private _baseState: BaseWidgetV2Store;

  private _stateProvider: IDashboardV2StateProvider;

  constructor({ stateProvider }: IAumSummaryStoreParams) {
    makeAutoObservable(this);

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    this._stateProvider = stateProvider;

    makeLoggable(this, { data: true });
  }

  private _setData = (data: AumSummaryRowData[]) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._stateProvider.getRequestParams();
    if (!requestParams) return;

    try {
      await delay(300);
      const data = generateAumSummary();
      const rows = mapAumSummaryToRowData(data);
      this._setData(rows);
    } catch {
      this._setData([]);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
