import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { BaseModuleForm } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { StrategyModuleGroupCreateType } from "src/modules/expertSystem";
import { getEmptyConditionModule } from "../shared/utils";
import { FormContent } from "./FormContent";

const MODULE_TYPE = "depth_abs";

export const DepthAbs = observer(() => {
  const state = useLateInitContext(CreateModulesContext);
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<StrategyModuleGroupCreateType<typeof MODULE_TYPE>>({
    defaultValues: getEmptyConditionModule(MODULE_TYPE),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <BaseModuleForm
        formId={MODULE_TYPE}
        onSubmit={handleSubmit(state.createConditionHandler(close))}
      >
        <FormContent />
      </BaseModuleForm>
    </FormProvider>
  );
});
