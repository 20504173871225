import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
} from "src/components/shared/Table";
import styled from "styled-components";

interface CellProps {
  highlight?: boolean;
  showTotal?: boolean;
}

export const ScrollContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  box-shadow: none !important;
  border-radius: ${({ theme }) => theme.radii["4px"]};
`;

export const BalancesTable = styled(Table)``;

export const Head = styled(TableHead)`
  border: none;
`;

export const HeadRow = styled(TableHeadRow)`
  height: 21px;
`;

export const Header = styled(TableHeader)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  color: ${({ theme }) => theme.darkGray};
`;

export const Body = styled(TableBody)`
  position: relative;
  height: calc(100% - 30px);
`;

export const Row = styled(TableRow)`
  height: 19px;

  font-size: ${({ theme }) => theme.fontSizes["15px"]};
`;

export const InfoRow = styled(TableRow)`
  height: 100%;

  display: flex;
  justify-content: center;

  padding-top: 1.5em;

  font-size: ${({ theme }) => theme.fontSizes.heading3};
`;

export const QuoteHeader = styled(Header)`
  @media screen and (max-width: 1380px) {
    width: 50px;
  }
`;

export const Cell = styled(TableCell)<CellProps>`
  cursor: ${({ showTotal }) => (showTotal ? "help" : "auto")};

  color: ${({ theme }) => theme.bigHeader};

  font-weight: ${({ theme, highlight }) =>
    highlight ? theme.fontWeights[600] : theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
`;

export const QuoteCell = styled(Cell)`
  @media screen and (max-width: 1380px) {
    width: 50px;
  }
`;
