import { observer } from "mobx-react-lite";
import React from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { Loader } from "src/components/shared/Loader";
import { PanelTitle } from "src/components/shared/shared";
import { ListContent } from "./ListContent";
import * as styles from "./style";

interface Props {
  data: any;
  columns: any;
  loader: boolean;
  openModal: (bool: boolean) => void;
  hideAddBtn?: boolean | undefined;
  label?: string;
  btnLabel?: string;
  filter?: JSX.Element;
  style?: React.CSSProperties;
}

export const List = observer(
  ({
    label,
    filter,
    btnLabel,
    data,
    columns,
    loader,
    openModal,
    hideAddBtn = true,
    ...props
  }: Props) => (
    <styles.Container {...props}>
      <styles.Header>
        <PanelTitle>{label}</PanelTitle>

        {filter || null}
      </styles.Header>

      <styles.Content>
        <ListContent data={data} columns={columns} />
      </styles.Content>

      <styles.Footer>
        {hideAddBtn ? (
          <OutlineButton onClick={() => openModal(true)} stretched>
            {btnLabel}
          </OutlineButton>
        ) : null}
      </styles.Footer>

      <Loader show={loader} />
    </styles.Container>
  )
);
