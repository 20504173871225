import { StyledProps } from "src/helpers/styled/types";
import { toPx } from "src/helpers/styled/units";
import styled, { css } from "styled-components";

export type ButtonGroupOrientation = "vertical" | "horizontal";
export type ButtonGroupGap = "none" | "s" | "m";

export interface ButtonGroupProps {
  orientation: ButtonGroupOrientation;
  gap: ButtonGroupGap;
}

interface ButtonGroupStyleProps extends StyledProps<ButtonGroupProps> {}

const gapToPx = (gap: ButtonGroupGap) => {
  switch (gap) {
    case "none": {
      return 0;
    }
    case "m": {
      return 10;
    }
    case "s": {
      return 6;
    }
  }
};

const GapMixin = ({ $gap }: ButtonGroupStyleProps) => {
  const gapPx = gapToPx($gap);
  if (gapPx) {
    return css`
      gap: ${toPx(gapPx)};
    `;
  }
};

const OrientationMixin = ({ $orientation }: ButtonGroupStyleProps) => {
  switch ($orientation) {
    case "horizontal": {
      return css`
        flex-direction: row;
      `;
    }
    case "vertical": {
      return css`
        flex-direction: column;
      `;
    }
  }
};

const borderSideToRadius = (side: "start" | "end" | "top" | "bottom" | "full", radius: number) => {
  const radiusPx = toPx(radius);
  switch (side) {
    case "end": {
      return css`
        border-top-right-radius: ${radiusPx};
        border-bottom-right-radius: ${radiusPx};
      `;
    }
    case "start": {
      return css`
        border-top-left-radius: ${radiusPx};
        border-bottom-left-radius: ${radiusPx};
      `;
    }
    case "top": {
      return css`
        border-top-right-radius: ${radiusPx};
        border-top-left-radius: ${radiusPx};
      `;
    }
    case "bottom": {
      return css`
        border-bottom-right-radius: ${radiusPx};
        border-bottom-left-radius: ${radiusPx};
      `;
    }
    case "full": {
      return css`
        border-radius: ${radiusPx};
      `;
    }
  }
};

const BorderMixin = ({ $orientation, $gap }: ButtonGroupStyleProps) => {
  if ($gap !== "none") return;
  const notFirstChildSelector = "> *:not(:first-of-type)";
  const firstChildSelector = "> *:first-of-type:not(:last-of-type)";
  const lastChildSelector = "> *:not(:first-of-type):last-of-type";
  const middleChildrenSelector = "> *:not(:first-of-type):not(:last-of-type)";
  if ($orientation === "horizontal") {
    return css`
      ${firstChildSelector} {
        ${borderSideToRadius("end", 0)}
      }
      ${middleChildrenSelector} {
        ${borderSideToRadius("full", 0)}
      }
      ${lastChildSelector} {
        ${borderSideToRadius("start", 0)}
      }
      ${notFirstChildSelector} {
        margin-left: -1px;
      }
    `;
  }
  return css`
    ${firstChildSelector} {
      ${borderSideToRadius("bottom", 0)}
    }
    ${middleChildrenSelector} {
      ${borderSideToRadius("full", 0)}
    }
    ${lastChildSelector} {
      ${borderSideToRadius("top", 0)}
    }
    ${notFirstChildSelector} {
      margin-top: -1px;
    }
  `;
};

export const ButtonGroup = styled.div<ButtonGroupStyleProps>`
  max-width: 100%;

  display: inline-flex;
  ${OrientationMixin}
  ${GapMixin}
  ${BorderMixin}

  > * {
    min-width: auto;
  }
`;
