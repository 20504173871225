import styled from "styled-components";

export const ChartContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.span`
  color: #cad2dc;
  text-align: center;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
`;

export const Divider = styled.hr`
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;

  border-bottom: 1px solid ${({ theme }) => theme.dashboard.borderDefault};
`;
