import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 40px;
  padding-top: 120px;

  width: 100%;

  @media screen and (max-width: 900px) {
    padding-top: 100px;
  }

  @media screen and (max-width: 450px) {
    padding-top: 70px;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
