import { Mapper } from "src/helpers/utils";
import { ChartPoint, ChartPointResponse } from "src/modules/shared";

export const chartPointResponseMapper: Mapper<ChartPointResponse, ChartPoint> = ({
  time,
  value,
}) => ({
  time,
  value: Number(value),
});
