import { LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import {
  PercentRangeInputWrapper,
  StyledPercentInput,
} from "src/components/shared/Forms/Inputs/PercentRangeInput/PercentRangeInput/style";
import { Switches } from "src/components/shared/Switches";
import { WrapperForRadioItems } from "src/components/shared/Switches/style";
import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const OptionsPanel = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  align-items: center;

  gap: 10px;

  padding: 5px 10px;

  border-bottom: 1px solid #e3effd99;

  overflow-x: auto;

  ${ScrollSize}

  & > div:not(:last-of-type) {
    border-right: 1px solid #e3effd99;
    padding-right: 5px;
  }

  & > div {
    flex: 0 0 auto;
  }
`;

export const ChartContent = styled.div`
  height: 0px;

  display: flex;
  flex: 1 1 auto;
`;

export const MarketDepthRange = styled(LabeledPercentRangeInput)`
  height: 100%;
  width: 150px;

  gap: 10px;

  ${PercentRangeInputWrapper} {
    gap: 5px;
  }

  ${StyledPercentInput} {
    height: 20px;
    font-size: 12px;
  }
`;

export const ScaleTypeSwitch = styled(Switches)`
  width: max-content;

  font-size: ${({ theme }) => theme.fontSizes["12px"]};

  ${WrapperForRadioItems} {
    max-width: 135px;

    gap: 10px;
  }
`;

export const OrdersSelection = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;

  gap: 12px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
