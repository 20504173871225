import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { getPairs } from "src/api/bots/CEX/exchange";
import { filterCallback } from "src/helpers/utils";
import { Pair } from "src/modules/exchange/trade";

export interface IPairsConsumer {
  exchange: string;
  priorityBase?: string;
}

export const MAIN_PAIRS = ["USDT_BTC", "USDT_ETH", "USDT_USDC"];

class ExchangePairsStore {
  private _pairs: Pair[] = [];

  private _searchPair: string = "";

  private _exchangeChangeReaction: IReactionDisposer;

  private _mainState: IPairsConsumer;

  constructor(state: IPairsConsumer) {
    makeAutoObservable(this);

    this._mainState = state;

    this._exchangeChangeReaction = reaction(
      () => this._exchange,
      () => {
        this.changeExchange();
      }
    );
  }

  unsubscribe = () => {
    this._exchangeChangeReaction();
  };

  changeExchange = () => {
    this._resetPairs();

    this.getExchangePairs();
  };

  get shortPairsList() {
    return this.pairs.filter(({ value }) => filterCallback(value, this._searchPair)).slice(0, 50);
  }

  private get pairs() {
    return this._pairs
      .map(({ base, quote }) => ({
        value: `${quote}_${base}`,
        label: `${quote}_${base}`,
      }))
      .sort((a, b) => this._pairsSort(a.value, b.value));
  }

  private get originBase() {
    return this._mainState.priorityBase;
  }

  private get _exchange() {
    return this._mainState.exchange;
  }

  setSearchPair = () => (value: string) => {
    runInAction(() => {
      this._searchPair = value;
    });
  };

  setNewPair = (value: string) => {
    const [quote, base] = value.split("_");

    const newPair: Pair = {
      base,
      quote,
      minAmountBase: "",
      minAmountQuote: "",
    };

    this._pairs = [newPair, ...this._pairs];
  };

  getExchangePairs = () => {
    if (this._exchange) this._getPairs(this._exchange);
  };

  private _resetPairs = () => {
    this._pairs = [];
  };

  private _pairsSort = (a: string, b: string) => {
    const firstCheck = this._priorityCheck(a);
    const secondCheck = this._priorityCheck(b);

    return secondCheck - firstCheck;
  };

  private _priorityCheck = (pair: string) => {
    if (MAIN_PAIRS.includes(pair)) return 2;

    // for trade terminal, prioritization of the original bot pair
    if (this.originBase) {
      if (pair.includes(this.originBase)) return 1;
    }

    return 0;
  };

  private _getPairs = async (exchange: string) => {
    try {
      const {
        data: { data },
        isError,
      } = await getPairs(exchange);

      if (!isError) {
        runInAction(() => {
          this._pairs = data;
        });
      } else this._resetPairs();
    } catch {
      this._resetPairs();
    }
  };

  destroy = () => {
    this.unsubscribe();
  };
}

export default ExchangePairsStore;
