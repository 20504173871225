import { makeAutoObservable } from "mobx";
import { getBotStatus } from "src/api/bots/DEXV2/bots";
import { getNotes, setNotes } from "src/api/bots/DEXV2/stats";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { lastUrlSegment } from "src/helpers/url";
import { IDisposable } from "src/helpers/utils";
import { DEXV2Bot } from "src/modules/bots";
import { IChainMeta } from "src/modules/chain";
import { Note } from "src/modules/shared";
import { IChainMetaProvider } from "src/state/chain/ChainMetaStore";
import { IChainIDProvider, IChainProvider } from "src/state/chain/ChainProviderStore";
import {
  BaseUSDPriceProvider,
  IBaseUSDPriceProvider,
} from "../DEXV2Swap/SwapModules/shared/Providers/BaseUsdPriceProvider";
import {
  BlockTimestampProvider,
  IBlockTimestampProvider,
} from "../DEXV2Swap/SwapModules/shared/Providers/BlockTimestampProvider";
import {
  GasPriceProvider,
  IGasPriceProvider,
} from "../DEXV2Swap/SwapModules/shared/Providers/GasPriceProvider";
import { IDEXV2BotsCacheProvider } from "../Providers/DEXV2BotsCacheProvider";
import {
  INativeUSDPriceProvider,
  NativeUSDPriceProvider,
} from "../Providers/NativeUSDPriceProvider";
import TradePairProviderStore, { ITradePairProvider } from "../Providers/TradePairProvider";
import {
  ITradePairPriceProvider,
  TradePairPriceProvider,
} from "../Providers/TradePairUSDPriceProvider";
import { IAbilitiesVerifier } from "../shared/AbilitiesStore";
import { PairAddresses, PairTickers } from "../shared/TradePair";
import {
  DEXV2BotToListDEXV2Bot,
  INITIAL_DEX_V2_BOT,
  botStatusResponseToDEXV2Bot,
} from "./DEXV2BotInfoStore";

export interface IBotChainProvider {
  chainProvider: IChainProvider;
}

export interface IBotChainMetaProvider {
  get chainMeta(): IChainMeta | undefined;
}

export interface IBotExchangeProvider {
  get exchange(): string | undefined;
}

export interface IBotChainInfoProvider
  extends IBotChainProvider,
    IBotChainMetaProvider,
    IChainIDProvider,
    IBotExchangeProvider {}

export interface IBotProvider {
  bot: DEXV2Bot;
}

export interface IPartyProvider {
  get party(): string;
}

export interface IBotUUIDProvider {
  get botUUID(): string;
}

export interface IBotAddressesProvider {
  get addresses(): PairAddresses | null;
}

export interface IBotTickersProvider {
  get tickers(): PairTickers | null;
}

export interface ISwapPairAddressProvider {
  get pairAddress(): string | null;
}

export interface IBotTradePairProvider extends Pick<ITradePairProvider, "tradePair"> {}

export interface IBotInfoProvider
  extends IBotAddressesProvider,
    IBotTickersProvider,
    IBotUUIDProvider {}

export interface IDEXV2BotParams {
  chainProvider: IChainProvider;
  chainMetaProvider: IChainMetaProvider;
  cacheProvider: IDEXV2BotsCacheProvider;
  abilitiesVerifier: IAbilitiesVerifier;
}
export default class DEXV2BotStore
  implements
    IDisposable,
    IBotChainInfoProvider,
    IBotProvider,
    IBotInfoProvider,
    IBotTradePairProvider,
    ISwapPairAddressProvider,
    IPartyProvider
{
  private _bot: DEXV2Bot = INITIAL_DEX_V2_BOT;

  private _botUUID: string = "";

  isLoading: boolean = false;

  private _chainProvider: IChainProvider;

  private _tradePairProvider: ITradePairProvider;

  private _chainMetaProvider: IChainMetaProvider;

  private _tradePairPriceProvider: ITradePairPriceProvider;

  private _baseUSDPriceProvider: IBaseUSDPriceProvider;

  private _nativeUSDPriceProvider: INativeUSDPriceProvider;

  private _abilitiesVerifier: IAbilitiesVerifier;

  private _gasPriceProvider: IGasPriceProvider & IDisposable;

  private _blockTimestampProvider: IBlockTimestampProvider & IDisposable;

  constructor({
    chainProvider,
    chainMetaProvider,
    cacheProvider,
    abilitiesVerifier,
  }: IDEXV2BotParams) {
    makeAutoObservable(this);

    this._chainProvider = chainProvider;

    this._chainMetaProvider = chainMetaProvider;

    this._tradePairProvider = new TradePairProviderStore(this, this, this);

    this._tradePairPriceProvider = new TradePairPriceProvider({
      chainIdProvider: this,
      tradePairProvider: this,
      pairAddressProvider: this,
      priceCacheStore: cacheProvider.pairPriceUSDCache,
    });

    this._baseUSDPriceProvider = new BaseUSDPriceProvider({
      tradePairPriceProvider: this._tradePairPriceProvider,
    });

    this._nativeUSDPriceProvider = new NativeUSDPriceProvider({
      chainProvider,
      priceCacheStore: cacheProvider.nativePriceUSDCache,
    });

    this._gasPriceProvider = new GasPriceProvider(chainProvider);

    this._blockTimestampProvider = new BlockTimestampProvider(chainProvider);

    this._abilitiesVerifier = abilitiesVerifier;

    this._subscribeAbilitiesVerifier();

    makeLoggable(this, { bot: true });
  }

  private _subscribeAbilitiesVerifier = () => {
    this._abilitiesVerifier.setPartyProvider(this);
  };

  private _unsubscribeAbilitiesVerifier = () => {
    this._abilitiesVerifier.setPartyProvider(null);
  };

  get tradePairPriceProvider() {
    return this._tradePairPriceProvider;
  }

  get baseUSDPriceProvider() {
    return this._baseUSDPriceProvider;
  }

  get gasPriceProvider(): IGasPriceProvider {
    return this._gasPriceProvider;
  }

  get blockTimestampProvider(): IBlockTimestampProvider {
    return this._blockTimestampProvider;
  }

  get nativeUSDPriceProvider() {
    return this._nativeUSDPriceProvider;
  }

  get chainProvider() {
    return this._chainProvider;
  }

  get chainID() {
    return this._chainProvider.chainID;
  }

  get exchange() {
    return this._bot.exchange;
  }

  private get _chainMetaMap() {
    return this._chainMetaProvider.chainMetaMap;
  }

  get chainMeta() {
    if (!this.chainID) return undefined;
    return this._chainMetaMap.get(this.chainID);
  }

  private _setBot = (bot: DEXV2Bot) => {
    this._bot = bot;
  };

  setBotUUID = (uuid: string): void => {
    this._botUUID = uuid;
  };

  private get _listBot() {
    return DEXV2BotToListDEXV2Bot(this._bot, this._chainMetaProvider.chainMetaMap);
  }

  get isBotStopped() {
    return this._listBot.isStopped;
  }

  get bot() {
    return this._bot;
  }

  get quote() {
    return this.bot.quote_addr;
  }

  get base() {
    return this.bot.base_addr;
  }

  get addresses() {
    const baseAddress = this.bot.base_addr;
    const quoteAddress = this.bot.quote_addr;
    if (!quoteAddress || !baseAddress) {
      return null;
    }
    return { quote: quoteAddress, base: baseAddress };
  }

  get tickers() {
    const baseTicker = this.bot.base;
    const quoteTicker = this.bot.quote;
    if (!quoteTicker || !baseTicker) {
      return null;
    }
    return { quote: quoteTicker, base: baseTicker };
  }

  get botUUID() {
    return this._botUUID;
  }

  get party() {
    return this._bot.party;
  }

  get screenerUrl() {
    return this._bot.link;
  }

  get pairAddress() {
    const { screenerUrl } = this;
    if (!screenerUrl) return null;
    // get pair address from screener url
    const pairAddress = lastUrlSegment(screenerUrl) ?? null;
    return pairAddress;
  }

  get chainInfo() {
    return this._chainProvider.currentChain;
  }

  get tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private _setLoading = (load: boolean) => {
    this.isLoading = load;
  };

  private _fetchBot = async () => {
    const { isError, data } = await getBotStatus(this._botUUID);
    if (!isError) {
      const bot = botStatusResponseToDEXV2Bot(data[0]);
      this._setBot(bot);
    }
    return isError;
  };

  loadBot = async () => {
    this._setLoading(true);

    try {
      const isError = await this._fetchBot();
      if (!isError) {
        this._chainProvider.setChainId(this.bot.chain_id.toString());
        await this._tradePairProvider.getTradePair();
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  getNotes = async (botId: string) => await getNotes(botId);

  setNotes = async (botId: string, note: Note) => await setNotes(botId, note);

  destroy = () => {
    // clear party providing to avoid leak
    this._unsubscribeAbilitiesVerifier();

    this._tradePairProvider.destroy();
    this._tradePairPriceProvider.destroy();
    this._baseUSDPriceProvider.destroy();
    this._gasPriceProvider.destroy();
    this._blockTimestampProvider.destroy();

    this._nativeUSDPriceProvider.destroy();
  };
}
