import { observer } from "mobx-react-lite";
import { ModalPanel, ModalPanelProps } from "src/components/shared/ModalPanel";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { toRounding } from "src/helpers/rounding";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

interface TotalFeesModalProps extends Omit<ModalPanelProps, "children"> {}

export const TotalFeesModal = observer(({ label, close, show, loading }: TotalFeesModalProps) => {
  const mainState = useLateInitContext(ExchangeContext);

  const { accHistoryState } = mainState;

  return (
    <ModalPanel
      label={label}
      close={close}
      show={show}
      loading={loading}
      style={{ width: "270px" }}
    >
      <styles.Container>
        {accHistoryState.currencies.map((el, index) => {
          const key = el + index;
          return (
            <styles.FeeCell
              key={key}
              data={{
                label: el,
                value: toRounding(accHistoryState.totalFeeMap[el], mainState.pricePrecision),
              }}
              style={{ flexDirection: "row" }}
            />
          );
        })}
      </styles.Container>
    </ModalPanel>
  );
});
