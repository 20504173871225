import { Context, useCallback } from "react";
import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import { useLocalStoreCreator } from "src/hooks/useLocalStore";
import { ExchangesLegendV2Store } from "src/state/CEX/CEXDashboard/shared/ExchangesLegendV2Store";
import {
  IExchangesLegendChartDataProvider,
  IExchangesLegendState,
} from "src/state/CEX/CEXDashboard/shared/ExchangesLegendV2Store/types";

// workaround to create a generic context
const getExchangesLegendContext = (() => {
  let context: Context<IExchangesLegendState<any> | undefined> | null = null;

  return <Id extends string>() => {
    if (!context) {
      context = createLateInitContext<IExchangesLegendState<Id>>();
    }

    return context as Context<IExchangesLegendState<Id> | undefined>;
  };
})();

export const useExchangesLegendV2State = <Id extends string>() => {
  const ctx = useLateInitContext(getExchangesLegendContext<Id>());
  return ctx;
};

export interface ExchangesLegendV2ProviderProps<Id extends string> extends ChildrenProps {
  dataProvider: IExchangesLegendChartDataProvider<Id>;
}

export const ExchangesLegendV2Provider = <Id extends string>({
  children,
  dataProvider,
}: ExchangesLegendV2ProviderProps<Id>) => {
  const stateCreator = useCallback(() => {
    const state = new ExchangesLegendV2Store({ dataProvider });
    return state;
  }, [dataProvider]);

  const LegendContext = getExchangesLegendContext<Id>();

  const store: IExchangesLegendState<Id> = useLocalStoreCreator(stateCreator);

  return <LegendContext.Provider value={store}>{children}</LegendContext.Provider>;
};
