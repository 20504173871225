import { observer } from "mobx-react-lite";
import { StrategyListContext } from "src/context/CEX/ExpertSystem";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FilterPanel } from "../../shared";
import { FiltersContent } from "./FilterContent";

export interface FiltersProps {}

export const Filters = observer(() => {
  const { filtersCount } = useLateInitContext(StrategyListContext);

  return (
    <FilterPanel count={filtersCount}>
      <FiltersContent />
    </FilterPanel>
  );
});
