import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { SwitchMenu } from "src/components/shared/SwitchMenu";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 10px;

  grid-area: graphs;
`;

export const GraphFallback = styled(CrashPanel)`
  grid-area: graphs;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SwitchGraphs = styled(SwitchMenu)`
  width: 250px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
