import { observer } from "mobx-react-lite";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { ChooseSettingParamsContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

export const ChooseParams = observer(() => {
  const state = useLateInitContext(ChooseSettingParamsContext);

  return (
    <>
      <RowWrapper>
        <LabeledSelector
          menuPosition="fixed"
          value={state.selectParam}
          options={state.selectorOptions}
          onChange={state.selectorHandler}
        />{" "}
        <AddButton onClick={state.addSettingParam} />
      </RowWrapper>

      <styles.BotName label="Bot" value={state.currentBotName} />
    </>
  );
});
