import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Column } from "react-table";
import { AumSummaryContext } from "src/context/CEX/Dashboard/v2/AumSummary";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { AumSummaryRowData } from "src/state/CEX/CEXDashboard/v2/widgets/AumSummaryV2Store";
import { Card, CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { FiatCell } from "../../../shared/StatsTable/Cells";
import { DashboardV2Widget, getWidgetTitle } from "../shared/constants";
import { AumCell } from "./AumCell";
import { TypeCell } from "./TypeCell";
import * as styles from "./style";

const DEFAULT_COLUMN: Partial<Column<AumSummaryRowData>> = {
  width: 50,
  disableSortBy: true,
};

export interface AumSummaryTableProps extends Omit<CardProps, "title" | "loading"> {}

export const AumSummaryTable = observer(({ ...props }: AumSummaryTableProps) => {
  const { data, loading } = useLateInitContext(AumSummaryContext.Context);

  const currencyColumns = useMemo(() => {
    const columnsSchema: Column<AumSummaryRowData>[] = [
      {
        Header: "",
        accessor: "type",
        Cell: ({ value }) => <TypeCell>{value}</TypeCell>,
      },
      {
        Header: "Quote $",
        accessor: "quote",
        Cell: FiatCell,
      },
      {
        Header: "Base $",
        accessor: "base",
        Cell: FiatCell,
      },
      {
        Header: "Total $",
        accessor: "total",
        Cell: ({ value, row: { original } }) => {
          const isAum = original?.isAumTotal ?? false;
          return isAum ? <AumCell>{value}</AumCell> : <FiatCell value={value} />;
        },
      },
    ];

    return columnsSchema;
  }, []);

  return (
    <Card title={getWidgetTitle(DashboardV2Widget.AumSummary)} loading={loading} {...props}>
      <styles.StyledAumTable columns={currencyColumns} data={data} defaultColumn={DEFAULT_COLUMN} />
    </Card>
  );
});

export interface AumSummaryWidgetProps extends AumSummaryTableProps {}

export const AumSummaryWidget = (props: AumSummaryWidgetProps) => (
  <AumSummaryContext.Provider>
    <AumSummaryTable {...props} />
  </AumSummaryContext.Provider>
);
