import { ChartOptions, ChartType, ScaleOptionsByType, TooltipOptions } from "chart.js";
import { DeepPartial } from "chart.js/types/utils";
import { rgba } from "polished";
import { useMemo } from "react";
import { InOutIndicatorPluginOptions } from "src/config/chartjs/plugins/inOutIndicator";
import { useDashboardColors } from "../hooks/useDashboardColors";

export const useBaseTooltip = () => {
  const { borderDefault, textSubhead, backgroundOverlay } = useDashboardColors();

  const options: DeepPartial<TooltipOptions> = useMemo(
    () => ({
      yAlign: "center",
      caretSize: 0,
      backgroundColor: backgroundOverlay,
      borderColor: borderDefault,
      borderWidth: 1,
      titleColor: textSubhead,
      titleFont: {
        size: 8,
        weight: "400",
      },
      titleMarginBottom: 4,
      bodyColor: textSubhead,
      bodyFont: {
        size: 8,
        weight: "600",
      },
      padding: 8,
      displayColors: false,
    }),
    [backgroundOverlay, borderDefault, textSubhead]
  );

  return options;
};

export const BASE_TIME_CONFIG: Omit<DeepPartial<ScaleOptionsByType<"time">>, "type"> = {
  time: {
    isoWeekday: true,
    tooltipFormat: "MM.DD.YYYY",
    displayFormats: {
      day: "MM.DD",
      hour: "HH:mm",
      minute: "HH:mm",
      second: "HH:mm",
      millisecond: "HH:mm:ss.SSS",
    },
  },
  adapters: {
    date: {
      utc: true,
    },
  },
  ticks: {
    maxRotation: 0,
  },
};

export const MINUTES_MS = 60_000;
export const HOURS_MS = 3_600_000;
export const HALF_DAY_MS = 43_200_000;
export const THREE_DAY_MS = 259_200_000;
export const TEN_DAYS_MS = 864_000_000;

export interface ZoomPluginConfigParams {
  minTimeRange?: number;
  wheelSpeed?: number;
  enabled?: boolean;
}

export const getZoomPluginConfig = ({
  minTimeRange = TEN_DAYS_MS,
  wheelSpeed = 0.01,
  enabled = true,
}: ZoomPluginConfigParams = {}): ChartOptions<ChartType> => ({
  plugins: {
    zoom: {
      limits: {
        x: { min: "original", max: "original", minRange: minTimeRange },
      },
      pan: {
        enabled,
        mode: "x",
      },
      zoom: {
        wheel: {
          speed: wheelSpeed,
          enabled,
        },
        pinch: {
          enabled,
        },
        mode: "x",
      },
    },
  },
});

export const useGridOptions = () => {
  const { borderDefault, textSecondary } = useDashboardColors();

  const options = useMemo(
    (): ChartOptions => ({
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            color: textSecondary,
          },
        },
        y: {
          grid: {
            borderDash: [10, 4],
            display: true,
            color: borderDefault,
            borderColor: borderDefault,
          },
          ticks: {
            color: textSecondary,
          },
        },
      },
    }),
    [borderDefault, textSecondary]
  );

  return options;
};

export const usePlaceholderOptions = () => {
  const { backgroundOverlay, textSecondary } = useDashboardColors();

  const options = useMemo(
    (): ChartOptions => ({
      plugins: {
        chartPlaceholder: {
          title: {
            color: textSecondary,
          },
          fillColor: rgba(backgroundOverlay, 0.5),
          shape: { type: "rect" },
        },
      },
    }),
    [backgroundOverlay, textSecondary]
  );

  return options;
};

export const useInOutIndicatorOptions = () => {
  const {
    textDefault,
    accent: { darkLime, rubyRed },
  } = useDashboardColors();

  const options = useMemo(
    (): InOutIndicatorPluginOptions => ({
      gap: 10,
      fillColor: {
        in: darkLime,
        out: rubyRed,
        neutral: textDefault,
      },
    }),
    [darkLime, rubyRed, textDefault]
  );

  return options;
};
