import { BaseButton } from "src/components/shared/Buttons/v2/shared/style";
import { InfoPanelCell } from "src/components/shared/InfoPanel/InfoPanelCell";
import { Label, Value } from "src/components/shared/InfoPanel/style";
import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Content = styled.div`
  position: relative;

  width: 100%;
  min-height: 300px;
  max-height: 450px;

  display: flex;
  flex-direction: column;

  gap: 20px;

  overflow: auto;

  ${ScrollSize}
`;

export const GridContent = styled.div`
  width: 100%;

  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;

  justify-content: space-between;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, 120px);
  }
`;

export const OrderInfoCell = styled(InfoPanelCell)`
  height: max-content;

  gap: 5px;

  ${Label} {
    font-size: 12px;
  }

  ${Value} {
    font-family: inherit;
    word-break: break-all;
  }

  ${Label}, ${Value} {
    white-space: pre-line;
  }
`;

export const OpenOrderLink = styled(BaseButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${({ theme }) => theme.fontSizes["12px"]};

  color: ${({ theme }) => theme.linkColorText};
  background: none;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  cursor: pointer;
  text-decoration: underline;
`;
