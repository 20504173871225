import { ComponentPropsWithoutRef, memo } from "react";
import { LabelError, LabelErrorIcon } from "src/components/shared/Forms/Label/LabelErrorIcon";
import { strFormatter } from "src/helpers/string";
import { FieldErrorProps } from "src/state/CEX/CEXSettings";
import * as styles from "./style";

export interface HashItem extends Partial<FieldErrorProps> {
  address: string;
}

export interface HashLabelOwnProps extends HashItem {}

export interface HashLabelProps extends HashLabelOwnProps, ComponentPropsWithoutRef<"div"> {}

export const HashLabel = memo(({ address, errorType, errorHint, ...props }: HashLabelProps) => {
  const hasError = Boolean(errorHint);
  const labelError: LabelError = { id: address, hint: errorHint ?? "" };
  return (
    <styles.Container errorType={errorType} {...props}>
      <styles.HashLabel as="span">{strFormatter(address)}</styles.HashLabel>
      {hasError ? <LabelErrorIcon error={labelError} /> : null}
    </styles.Container>
  );
});
