import { makeAutoObservable } from "mobx";
import { invariant } from "mobx-utils";
import { TradeSide, TradeToken } from "./TradeToken";

export type PairAddresses = Record<TradeSide, string>;
export type PairTickers = Record<TradeSide, string>;
export type PairTokens = Record<TradeSide, TradeToken>;

export default class TradePair {
  private _base: TradeToken;

  private _quote: TradeToken;

  constructor(baseToken: TradeToken, quoteToken: TradeToken) {
    invariant(baseToken.side === "base", "Base trade token side is not base");
    invariant(quoteToken.side === "quote", "Quote trade token side is not quote");
    makeAutoObservable(this);
    this._base = baseToken;
    this._quote = quoteToken;
  }

  get base() {
    return this._base;
  }

  get quote() {
    return this._quote;
  }

  get pair(): PairTokens {
    return {
      base: this._base,
      quote: this._quote,
    };
  }

  get addresses(): PairAddresses {
    return { base: this._base.address, quote: this._quote.address };
  }

  get tickers(): PairTickers {
    return { base: this._base.symbol, quote: this._quote.symbol };
  }
}
