import { Observer, observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared/style";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { DynamicSelectionContext } from "src/context/CEX/ExpertSystem";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategyModuleCreateType } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import { ExchPairFields } from "../../../shared";

export const TradeContentForm = observer(() => {
  const selectorsState = useLateInitContext(DynamicSelectionContext);
  const { accListState } = selectorsState;

  const { control, resetField } = useFormContext<IStrategyModuleCreateType<"trade">>();

  const exchange = useWatch({ control, name: "hard_params.exchange" });

  useEffect(() => {
    resetField("hard_params.account_uuid");
  }, [exchange, resetField]);

  return (
    <RowWrapper>
      <ExchPairFields />

      <Controller
        control={control}
        name="hard_params.account_uuid"
        rules={{ required: ERRORS_MSGS.isRequired }}
        render={({ field: { onChange }, formState: { errors } }) => {
          const error = getFieldError(errors, "hard_params.account_uuid");

          return (
            <Observer>
              {() => (
                <LabeledSelector
                  label="Account"
                  menuPosition="fixed"
                  options={accListState.accountOptions}
                  onChange={(data) => {
                    onChange(data?.value);
                    accListState.setCurrentAccount(data?.value || "");
                  }}
                  value={accListState.selectAccountOption}
                  errorHint={error?.message}
                />
              )}
            </Observer>
          );
        }}
      />
    </RowWrapper>
  );
});
