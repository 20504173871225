import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import UserGroupsStore from "src/state/UserManager/UserGroups/UserGroupsStore";
import { UserManagerContext } from "./UserManager";

export const UserGroupsContext = createLateInitContext<UserGroupsStore>();

type ScopesProviderProps = ChildrenProps;

export const UserGroupsProvider = ({ children }: ScopesProviderProps) => {
  const state = useLateInitContext(UserManagerContext);

  const scopesStore: UserGroupsStore = useLocalStore(UserGroupsStore, state);

  return <UserGroupsContext.Provider value={scopesStore}>{children}</UserGroupsContext.Provider>;
};
