import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import HeaderIcons from "src/icons/HeaderIcons";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { HeaderMenuItem } from "..";

const DEXV2_CONTENT_TABS = [
  "STATS",
  "SETTINGS",
  // "DASHBOARD",
  "SWAP",
] as const;

type DEXV2ContentTabs = (typeof DEXV2_CONTENT_TABS)[number];

export const isDEXV2ContentTab = (value: string): value is DEXV2ContentTabs =>
  DEXV2_CONTENT_TABS.includes(value as DEXV2ContentTabs);

export interface BotContentMenuItem<T extends string> extends HeaderMenuItem {
  value: T;
}

type DEXV2MenuItem<T extends DEXV2ContentTabs = DEXV2ContentTabs> = T extends DEXV2ContentTabs
  ? BotContentMenuItem<T>
  : never;

const stats = {
  label: "Stats",
  value: "STATS",
  icon: HeaderIcons.stats,
  to: (path: string) => `/DEX_V2/${path}/?tab=STATS`,
} as const;

const settings = {
  label: "Settings",
  value: "SETTINGS",
  icon: HeaderIcons.settings,
  to: (path: string) => `/DEX_V2/${path}/?tab=SETTINGS`,
} as const;

const swap = {
  label: "Swap",
  value: "SWAP",
  icon: HeaderIcons.swap,
  to: (path: string) => `/DEX_V2/${path}/?tab=SWAP`,
} as const;

const useMenuItems = () => {
  const { hasAbility } = useLateInitContext(AbilitiesContext);
  const menuItems: DEXV2MenuItem[] = [];

  if (hasAbility(DEXV2Ability.StatsView)) {
    menuItems.push(stats);
  }

  if (hasAbility(DEXV2Ability.SettingsView)) {
    menuItems.push(settings);
  }

  if (hasAbility(DEXV2Ability.ExchangeView)) {
    menuItems.push(swap);
  }

  return menuItems;
};

export const DEXV2MenuItems = () => {
  const items = useMenuItems();
  return items;
};
