import styled from "styled-components";
import { TradeForm } from "../shared";

export const Form = styled(TradeForm)``;

export const Content = styled.div`
  height: 110px;
  display: flex;
  justify-content: space-between;

  & > div:not(:last-of-type) {
    margin-right: 12px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 7px;

  & > div:first-of-type {
    margin: 0;
    flex-direction: unset;
  }
`;
