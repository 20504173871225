import { makeAutoObservable, when } from "mobx";
import { IERC20 } from "src/contracts/IERC20";
import { IERC20__factory } from "src/contracts/factories/IERC20__factory";
import { makeLoggable } from "src/helpers/logger";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logError } from "src/helpers/network/logger";
import { IDisposable, WhenReactionPromise } from "src/helpers/utils";
import {
  IBotAddressesProvider,
  IBotChainProvider,
  IBotTickersProvider,
} from "../DEXV2Bots/DEXV2BotStore";
import TradePair, { PairTickers } from "../shared/TradePair";
import { TradeSide, TradeToken } from "../shared/TradeToken";

type PairContracts = Record<TradeSide, IERC20>;

interface TradePairParams {
  contracts: PairContracts;
  tickers: PairTickers;
  chainId: number;
}

export interface ITradePairProvider extends IDisposable {
  get tradePair(): TradePair | null;

  getTradePair: () => Promise<void>;
}

export default class TradePairProviderStore implements ITradePairProvider {
  private _tradePair: TradePair | null = null;

  private _botChainProvider: IBotChainProvider;

  private _addressProvider: IBotAddressesProvider;

  private _tickersProvider: IBotTickersProvider;

  private _tradePairDepsReaction?: WhenReactionPromise;

  constructor(
    chainProvider: IBotChainProvider,
    addressProvider: IBotAddressesProvider,
    tickersProvider: IBotTickersProvider
  ) {
    this._botChainProvider = chainProvider;
    this._addressProvider = addressProvider;
    this._tickersProvider = tickersProvider;

    makeAutoObservable<this, "_decimalsDeps" | "_currentDecimalsDeps" | "_getTokenContract">(this, {
      _decimalsDeps: false,
      _currentDecimalsDeps: false,
      _getTokenContract: false,
    });

    makeLoggable<any>(this, {
      tradePair: true,
    });
  }

  private _setTradePair = (base: TradeToken, quote: TradeToken) => {
    this._tradePair = new TradePair(base, quote);
  };

  get tradePair() {
    return this._tradePair;
  }

  private get _pairAddresses() {
    return this._addressProvider.addresses;
  }

  private get _pairTickers() {
    return this._tickersProvider.tickers;
  }

  private get _chainProvider() {
    return this._botChainProvider.chainProvider;
  }

  private get _provider() {
    return this._chainProvider.multicallProvider;
  }

  private get _chainId() {
    return this._chainProvider.chainID;
  }

  private _getTokenContract = (address: string | undefined) => {
    const provider = this._provider;
    if (!provider || !address) return null;
    return IERC20__factory.connect(address, provider);
  };

  private get _pairContracts(): PairContracts | null {
    const baseContract = this._getTokenContract(this._pairAddresses?.base);
    const quoteContract = this._getTokenContract(this._pairAddresses?.quote);
    if (!baseContract || !quoteContract) return null;
    return { base: baseContract, quote: quoteContract };
  }

  private get _tradePairParams(): TradePairParams | null {
    const contracts = this._pairContracts;
    const chainId = this._chainId;
    const tickers = this._pairTickers;
    if (!contracts || !chainId || !tickers) return null;

    return { contracts, chainId: +chainId, tickers };
  }

  private get _tradePairDeps() {
    return when(() => Boolean(this._tradePairParams));
  }

  private get _currentTradePairDeps() {
    this._tradePairDepsReaction?.cancel();
    const tradePairDeps = this._tradePairDeps;
    this._tradePairDepsReaction = tradePairDeps;
    return tradePairDeps;
  }

  getTradePair = async () => {
    try {
      const tradePairDeps = this._currentTradePairDeps;
      await tradePairDeps;
      await this._getTradePair();
    } catch (err) {
      logError(err);
    }
  };

  private _getTradePair = async () => {
    const pairParams = this._tradePairParams;
    if (!pairParams) return;
    const { contracts, tickers, chainId } = pairParams;
    try {
      const [baseToken, quoteToken] = await Promise.all([
        this._getToken("base", contracts.base, tickers.base, chainId),
        this._getToken("quote", contracts.quote, tickers.quote, chainId),
      ]);
      if (!baseToken || !quoteToken) {
        return;
      }
      this._setTradePair(baseToken, quoteToken);
    } catch (err) {
      logError(err);
    }
  };

  private _getToken = async (
    side: TradeSide,
    contract: IERC20,
    symbol: string,
    chainId: number
  ) => {
    try {
      const [decimals, name] = await Promise.all([contract.decimals(), contract.name()]);
      return new TradeToken(chainId, contract.address, decimals, symbol, side, name);
    } catch (err) {
      chainErrorHandler(err);
    }
  };

  destroy() {
    this._tradePairDepsReaction?.cancel();
  }
}
