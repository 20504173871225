import { Indicator } from "src/components/BotsContent/CEX/LiquidityTab/Status/style";
import { TableCellText } from "src/components/shared/TableV2";
import styled from "styled-components";

export const StyledModuleStatus = styled(Indicator)`
  width: 11px;
  height: 11px;

  margin: 0px;
`;

export const ModuleStatusCell = styled(TableCellText)`
  justify-content: flex-start;

  gap: 4px;

  white-space: nowrap;
`;
