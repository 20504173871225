import { useState } from "react";
import { setTextClipboard } from "src/helpers/clipboard";
import Icons from "src/icons/Icons";
import * as styles from "./style";

interface CopyToastProps {
  msg: string;
}

export const CopyToast = ({ msg }: CopyToastProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const title = isCopied ? "Copied" : "Copy";

  return (
    <styles.Wrapper
      title={title}
      onClick={() =>
        setTextClipboard(msg, { isShowSuccessMsg: false, onSuccess: () => setIsCopied(true) })
      }
    >
      {isCopied ? <Icons.Success fill="currentColor" /> : <Icons.Copy />}
    </styles.Wrapper>
  );
};
