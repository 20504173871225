import { Observer, observer } from "mobx-react-lite";
import { Controller, useFormContext } from "react-hook-form";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledCreatableSelector } from "src/components/shared/Forms/Selectors";
import { BotModulesContext } from "src/context/CEX/BotModules/BotModulesProvider";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { stringToSelectorValue } from "src/helpers/forms/selectors";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ISwapModule } from "src/state/CEX/botModules";
import { ERRORS_MSGS } from "src/validation-schemas";
import { InputColumnWrapper, InputWrapper } from "../shared";

export const SwapModuleForm = observer(() => {
  const state = useLateInitContext(BotModulesContext);

  const { control, setValue } = useFormContext<ISwapModule>();

  const handleCreate = (inputValue: string) => {
    state.setRoute(inputValue);
    setValue("swap", String(inputValue));
  };

  return (
    <>
      <Controller
        control={control}
        name="swap"
        rules={{ required: ERRORS_MSGS.isRequired }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <Observer>
            {() => (
              <LabeledCreatableSelector
                label="Swap"
                options={state.swapRoutesList}
                onChange={(data) => onChange(data?.value)}
                value={stringToSelectorValue(value)}
                onCreateOption={handleCreate}
                errorHint={errors.swap?.message}
              />
            )}
          </Observer>
        )}
      />

      <InputWrapper>
        <InputColumnWrapper>
          <Controller
            control={control}
            name="swapQuote"
            rules={{ required: ERRORS_MSGS.isRequired }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <LabeledInput
                label="Swap Quote"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e))}
                errorHint={errors.swapQuote?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="currentQuote"
            rules={{ required: ERRORS_MSGS.isRequired }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <LabeledInput
                label="Current Quote"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e))}
                errorHint={errors.currentQuote?.message}
              />
            )}
          />
        </InputColumnWrapper>

        <InputColumnWrapper>
          <Controller
            control={control}
            name="quoteAddress"
            rules={{ required: ERRORS_MSGS.isRequired }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <LabeledInput
                label="Quote Contract"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e))}
                errorHint={errors.quoteAddress?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="baseAddress"
            rules={{ required: ERRORS_MSGS.isRequired }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <LabeledInput
                label="Base Contract"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e))}
                errorHint={errors.baseAddress?.message}
              />
            )}
          />
        </InputColumnWrapper>
      </InputWrapper>
    </>
  );
});
