import { BotStatusColors } from "src/components/Themes";
import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";
import { Button } from "../../Button";

export interface BotButtonStyleProps {
  accentColor: BotStatusColors;
}

const BackgroundColorMixin = ({ accentColor }: Pick<BotButtonStyleProps, "accentColor">) => css`
  ${({ theme }) => {
    const mainColor = theme.botColorStatus[accentColor];

    const enabledAlpha = hexToRgb(mainColor, 0.2);
    const hoverAlpha = hexToRgb(mainColor, 0.4);
    const focusedAlpha = hexToRgb(mainColor, 0.6);
    const activeAlpha = hexToRgb(mainColor, 0.8);

    return css`
      background-color: ${enabledAlpha};

      :hover {
        background-color: ${hoverAlpha};
      }

      :active {
        background-color: ${activeAlpha};
      }

      :focus-visible {
        background-color: ${focusedAlpha};
      }
    `;
  }}
`;

export const BotButton = styled(Button)<BotButtonStyleProps>`
  color: ${({ theme }) => theme.bigTextColor};

  ${BackgroundColorMixin}
`;
