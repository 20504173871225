import { useRef } from "react";
import { ChartScaleProvider } from "src/context/Graph/ChartScale";
import { SeriesProvider, SeriesProviderProps } from "src/context/Graph/Series";
import { TooltipProvider, TooltipProviderProps } from "src/context/Graph/Tooltip";
import { ChartScaleConfig } from "src/state/Graph/ChartScaleStore";
import { ISeriesStateOptions } from "src/state/Graph/Series/types";
import { ITooltipSeriesDataProvider } from "src/state/Graph/Tooltip";
import { TooltipSeriesDataProvider } from "src/state/Graph/Tooltip/TooltipSeriesDataProvider";
import { TooltipSeriesData } from "src/state/Graph/Tooltip/types";
import { GraphRoot, GraphRootProps } from "./GraphRoot";

export interface GraphProps<T extends TooltipSeriesData>
  extends GraphRootProps,
    Partial<Pick<TooltipProviderProps<T>, "seriesDataProvider">>,
    Pick<ISeriesStateOptions, "autoShowScales"> {
  selectionOptions?: ChartScaleConfig;
  seriesStateProvider?: SeriesProviderProps["stateProvider"];
}

export const Graph = <T extends TooltipSeriesData = TooltipSeriesData>({
  selectionOptions,
  seriesDataProvider,
  seriesStateProvider,
  autoShowScales = true,
  ...props
}: GraphProps<T>) => {
  const defaultTooltipSeriesProviderRef = useRef<ITooltipSeriesDataProvider<T>>(
    new TooltipSeriesDataProvider()
  );

  const tooltipSeriesDataProvider = seriesDataProvider ?? defaultTooltipSeriesProviderRef.current;

  const seriesProviderProps = {
    stateProvider: seriesStateProvider,
    autoShowScales,
  };

  return (
    <ChartScaleProvider config={selectionOptions}>
      <SeriesProvider {...seriesProviderProps}>
        <TooltipProvider seriesDataProvider={tooltipSeriesDataProvider}>
          <GraphRoot {...props} />
        </TooltipProvider>
      </SeriesProvider>
    </ChartScaleProvider>
  );
};
