import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface PNLV2LabelProps extends ComponentPropsWithoutRef<"span"> {
  colored?: boolean;
  color?: string;
  children?: string;
}

export const PNLV2Label = ({ colored, color, children, ...props }: PNLV2LabelProps) => (
  <styles.StyledPNLV2Label $colored={colored} $color={color} {...props}>
    {children}
  </styles.StyledPNLV2Label>
);
