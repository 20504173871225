import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import { BindingsBotContext } from "src/context/CEX/BindingsBotProvider";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { toRounding } from "src/helpers/rounding";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import ExchangeIcons from "src/icons/ExchangeIcons";
import { ConstraintsInput } from "../../../CEXBotSettings/shared";
import * as styles from "./style";

export const PairSettings = observer(() => {
  const state = useLateInitContext(CEXSettingsContext);
  const bindingsState = useLateInitContext(BindingsBotContext);

  const DTPMin = useRef(null);
  const DTPMax = useRef(null);

  const [DTPLength, setDTPLength] = useState(9);

  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    if (DTPMin && DTPMin.current) {
      const { clientWidth } = DTPMin.current;
      setWidth(clientWidth);
    }
  }, [DTPMin]);

  useEffect(() => {
    updateWidth();

    const resizeInput = () => {
      updateWidth();
      const countNum = parseInt(String(width / 10), 10);

      // subtract from the length of the entered value the length of the hint
      // and 2 additional characters due to the difference in fonts

      if (state.pricePrecision)
        setDTPLength(countNum - (toRounding(state.DTPMin, state.pricePrecision).length - 1));
    };

    resizeInput();

    window.addEventListener("resize", resizeInput);

    return () => window.removeEventListener("resize", resizeInput);
  }, [updateWidth, width, state.DTPMin, state.pricePrecision]);

  return (
    <styles.Container>
      <ConstraintsInput
        inputRef={DTPMin}
        type="number"
        label="DTPMin"
        value={state.data.settings.pair.dontTradePriceMin}
        onChange={state.getHandler("settings.pair.dontTradePriceMin")}
        step="any"
        after={
          // eslint-disable-next-line no-nested-ternary
          bindingsState.currentBindings.currentBinding ? (
            String(state.data.settings.pair.dontTradePriceMin).length < DTPLength ? (
              <styles.PromptInput>
                <styles.Separator />
                <styles.TextPrompt>
                  {toRounding(state.DTPMinPrompt, state.pricePrecision)}
                  {ExchangeIcons.prompt()}
                </styles.TextPrompt>
              </styles.PromptInput>
            ) : (
              <styles.PromptTopInput>
                <styles.TextPrompt>
                  {toRounding(state.DTPMinPrompt, state.pricePrecision)}
                  {ExchangeIcons.prompt()}
                </styles.TextPrompt>
              </styles.PromptTopInput>
            )
          ) : null
        }
        {...state.getFieldErrorAsProps("settings.pair.dontTradePriceMin")}
        // @ts-ignore
        getError={state.getError("settings.pair.dontTradePriceMin")}
      />

      <ConstraintsInput
        inputRef={DTPMax}
        type="number"
        label="DTPMax"
        value={state.data.settings.pair.dontTradePriceMax}
        onChange={state.getHandler("settings.pair.dontTradePriceMax")}
        step="any"
        after={
          // eslint-disable-next-line no-nested-ternary
          bindingsState.currentBindings.currentBinding ? (
            String(state.data.settings.pair.dontTradePriceMax).length < DTPLength ? (
              <styles.PromptInput>
                <styles.Separator />
                <styles.TextPrompt>
                  {toRounding(state.DTPMaxPrompt, state.pricePrecision)}
                  {ExchangeIcons.prompt()}
                </styles.TextPrompt>
              </styles.PromptInput>
            ) : (
              <styles.PromptTopInput>
                <styles.TextPrompt>
                  {toRounding(state.DTPMaxPrompt, state.pricePrecision)}
                  {ExchangeIcons.prompt()}
                </styles.TextPrompt>
              </styles.PromptTopInput>
            )
          ) : null
        }
        {...state.getFieldErrorAsProps("settings.pair.dontTradePriceMax")}
        // @ts-ignore
        getError={state.getError("settings.pair.dontTradePriceMax")}
      />
    </styles.Container>
  );
});
