import { PanelHeader } from "src/components/shared/PanelHeader";
import { Wrapper, Title } from "src/components/shared/PanelHeader/style";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export interface ContentProps {
  disabled?: boolean;
}

export const Content = styled.div<ContentProps>`
  width: 100%;

  display: flex;

  opacity: ${({ disabled }: ContentProps) => (disabled ? "0.6" : 1)};
  pointer-events: ${({ disabled }: ContentProps) => (disabled ? "none" : "")};
`;

export const Header = styled(PanelHeader)`
  padding: 0;

  ${Title} {
    display: flex;
    gap: 5px;
  }

  ${Wrapper} {
    & > div:first-of-type {
      margin-right: 0px;
    }
    gap: 15px;
  }
`;
