import {
  BigintIsh,
  Currency,
  CurrencyAmount,
  Fraction,
  Percent,
  Price,
  TradeType,
} from "@uniswap/sdk-core";
import { Trade as BaseTrade, Route, TickMath } from "@uniswap/v3-sdk";
import { logDev } from "src/helpers/network/logger";
import { calculateNextPriceImpact } from "src/state/DEXV2/DEXV2Swap/utils";
import { V3Pool } from "./V3Pool";
import { V3Route } from "./V3Route";

interface ITradeV3Params<
  TInput extends Currency,
  TOutput extends Currency,
  TTradeType extends TradeType,
> {
  route: Route<TInput, TOutput>;
  inputAmount: CurrencyAmount<TInput>;
  outputAmount: CurrencyAmount<TOutput>;
  tradeType: TTradeType;
  sqrtPriceX96After?: BigintIsh;
}

export class V3Trade<
  TInput extends Currency,
  TOutput extends Currency,
  TTradeType extends TradeType,
> {
  private _trade: BaseTrade<TInput, TOutput, TTradeType>;

  private _sqrtPriceAfter: Fraction["numerator"] | undefined;

  private _nextMidPrice: Price<TInput, TOutput> | null = null;

  private _nextPriceImpact: Percent | null = null;

  constructor({ sqrtPriceX96After, ...params }: ITradeV3Params<TInput, TOutput, TTradeType>) {
    this._trade = BaseTrade.createUncheckedTrade(params);

    this._sqrtPriceAfter = sqrtPriceX96After
      ? new Fraction(sqrtPriceX96After).numerator
      : undefined;
  }

  get path() {
    return this._trade.swaps[0].route.tokenPath;
  }

  get swaps() {
    return this._trade.swaps;
  }

  get tradeType() {
    return this._trade.tradeType;
  }

  get inputAmount() {
    return this._trade.inputAmount;
  }

  get outputAmount() {
    return this._trade.outputAmount;
  }

  get executionPrice() {
    return this._trade.executionPrice;
  }

  get priceImpact() {
    return this._trade.priceImpact;
  }

  get nextMidPrice() {
    if (!this._sqrtPriceAfter) {
      return null;
    }

    if (this._nextMidPrice) {
      return this._nextMidPrice;
    }

    const { route } = this.swaps[0];

    const { token0, token1, fee, liquidity } = route.pools[0];

    const nextCurrentTick = TickMath.getTickAtSqrtRatio(this._sqrtPriceAfter);

    const nextPool = new V3Pool(
      token0,
      token1,
      fee,
      this._sqrtPriceAfter,
      liquidity,
      nextCurrentTick
    );

    const { input, output } = route;

    const { midPrice } = new V3Route([nextPool], input, output);

    this._nextMidPrice = midPrice;

    logDev(["midPrice", midPrice, route.midPrice]);

    return midPrice;
  }

  get nextPriceImpact() {
    if (!this.nextMidPrice) {
      return null;
    }

    if (this._nextPriceImpact) {
      return this._nextPriceImpact;
    }

    const { route } = this.swaps[0];

    const { midPrice } = route;

    const nextPriceImpact = calculateNextPriceImpact(midPrice, this.nextMidPrice);

    this._nextPriceImpact = nextPriceImpact;

    return nextPriceImpact;
  }

  minimumAmountOut(slippageTolerance: Percent, amountOut?: CurrencyAmount<TOutput>) {
    return this._trade.minimumAmountOut(slippageTolerance, amountOut);
  }

  maximumAmountIn(slippageTolerance: Percent, amountIn?: CurrencyAmount<TInput>) {
    return this._trade.maximumAmountIn(slippageTolerance, amountIn);
  }

  worstExecutionPrice(slippageTolerance: Percent) {
    return this._trade.worstExecutionPrice(slippageTolerance);
  }
}
