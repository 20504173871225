import { ColumnDef, FilterFn, createColumnHelper } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { SeparatorNum } from "src/components/shared/Separator";
import { MultiGridStateContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IGridStateRow } from "src/state/CEX/multiGrid/MultiGridState";
import { SideOrderCell, StateOrderCell } from "./Cells";
import { StateTableRows } from "./StateTableRows";
import * as styles from "./style";

export const StateTable = observer(() => {
  const state = useLateInitContext(MultiGridStateContext);

  const columnHelper = createColumnHelper<IGridStateRow>();

  const priceFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const price = row.getValue(columnId);

    if (value === "OUR") return Boolean(price);

    return true;
  };

  const subColumnConfig = useMemo(
    () => ({
      header: () => "",
      enableSorting: false,
      enableColumnFilter: false,
    }),
    []
  );

  const exchangesColumns = useMemo(() => {
    const columnSchema: ColumnDef<IGridStateRow>[] = state.exchanges.map((el) =>
      columnHelper.group({
        header: el,
        id: "exchange",
        columns: [
          {
            ...subColumnConfig,
            header: () => "MMS",
            id: `${el}MMS`,
            accessorKey: "columns",
            minSize: 60,
            cell: ({
              cell: {
                row: {
                  original: { columns },
                },
              },
            }) => <StateOrderCell state={columns[el].mms_state} amount={columns[el].mms} />,
          },
          {
            ...subColumnConfig,
            header: () => "SELL",
            id: `${el}SELL`,
            accessorKey: "columns",
            minSize: 60,
            cell: ({
              cell: {
                row: {
                  original: { columns },
                },
              },
            }) => <SideOrderCell type="sell" side={columns[el].mms} amount={columns[el].sell} />,
          },
          {
            ...subColumnConfig,
            header: () => "BUY",
            id: `${el}BUY`,
            accessorKey: "columns",
            minSize: 60,
            cell: ({
              cell: {
                row: {
                  original: { columns },
                },
              },
            }) => <SideOrderCell type="buy" side={columns[el].mmb} amount={columns[el].buy} />,
          },
          {
            ...subColumnConfig,
            header: () => "MMB",
            id: `${el}MMB`,
            accessorKey: "columns",
            minSize: 60,
            cell: ({
              cell: {
                row: {
                  original: { columns },
                },
              },
            }) => <StateOrderCell state={columns[el].mmb_state} amount={columns[el].mmb} />,
          },
        ],
      })
    );

    return columnSchema;
  }, [subColumnConfig, columnHelper, state.exchanges]);

  const columns = useMemo(() => {
    const columnSchema: ColumnDef<IGridStateRow>[] = [
      columnHelper.group({
        id: "price",
        header: () => "Price",
        size: 50,
        columns: [
          {
            ...subColumnConfig,
            enableColumnFilter: true,
            filterFn: priceFilter,
            accessorKey: "price",
            cell: (info) => {
              const price = info.getValue<string>();
              return <styles.CellText>{price && <SeparatorNum value={price} />}</styles.CellText>;
            },
          },
        ],
      }),

      ...exchangesColumns,

      columnHelper.group({
        id: "buy",
        header: () => "Buy",
        size: 50,
        columns: [
          {
            accessorKey: "buy",
            ...subColumnConfig,
            cell: (info) => <styles.CellText>{info.getValue<string>()}</styles.CellText>,
          },
        ],
      }),

      columnHelper.group({
        id: "sell",
        header: () => "Sell",
        size: 50,
        columns: [
          {
            accessorKey: "sell",
            ...subColumnConfig,
            cell: (info) => <styles.CellText>{info.getValue<string>()}</styles.CellText>,
          },
        ],
      }),
    ];

    return columnSchema;
  }, [subColumnConfig, columnHelper, exchangesColumns]);

  return (
    <styles.Container>
      <StateTableRows
        columns={columns}
        orders={state.stateRows}
        loader={state.isLoading}
        rowSize={30}
        filters={["ALL", "OUR"]}
      />
      <styles.StatePagination
        itemsPerPage={1}
        onForcePageChange={state.setOffset}
        getItems={state.getOffset}
        pageCount={state.pageCount}
        forcePage={state.offset}
      />
    </styles.Container>
  );
});
