import React from "react";
import { calcRoundingValue } from "src/helpers/rounding";
import { TimePoint } from "src/modules/shared";
import * as styles from "./style";

interface Props {
  point: TimePoint;
  labelNested?: string;
}

export const PointTooltip = ({ point, labelNested }: Props) => (
  <styles.Container
    //  effect="solid"
    place="top"
    id={`${point.id}`}
  >
    <styles.Content>
      {point.nested ? (
        <>
          <styles.Title>
            {labelNested}: {point.nested.length + 1}
          </styles.Title>
          <styles.Line />
        </>
      ) : null}
      <styles.ContentPoint>
        <styles.Title>{point.info}</styles.Title>
        <styles.Title>
          Amount: <styles.Value>{point.amount}</styles.Value>
        </styles.Title>
        <styles.Title>
          Price: <styles.Value>{point.price}</styles.Value>
        </styles.Title>
        <styles.Title>
          Total: <styles.Value>{point.total.toFixed(calcRoundingValue(+point.total))}</styles.Value>
        </styles.Title>
        {point.nested ? <styles.Line /> : null}
      </styles.ContentPoint>

      {point.nested
        ? point.nested.map((el, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <styles.ContentPoint key={index}>
              <styles.Title>{el.info}</styles.Title>
              <styles.Title>
                Amount: <styles.Value>{el.amount}</styles.Value>
              </styles.Title>
              <styles.Title>
                Price: <styles.Value>{el.price}</styles.Value>
              </styles.Title>
              <styles.Title>
                Total:{" "}
                <styles.Value>{el.total.toFixed(calcRoundingValue(+point.total))}</styles.Value>
              </styles.Title>
              <styles.Line />
            </styles.ContentPoint>
          ))
        : null}
    </styles.Content>
  </styles.Container>
);
