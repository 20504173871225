import styled from "styled-components";
import { DepthChartTitle } from "./DepthChartTitle";
import { ChartTitle } from "./DepthChartTitle/style";

export const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const AllDepthTitle = styled(DepthChartTitle)`
  margin-bottom: 25px;
`;

export const OurDepthTitle = styled(DepthChartTitle)``;

export const DepthContainer = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-template-rows: repeat(2, 50%);

  ${ChartTitle} {
    padding-left: 8px;
  }
`;
