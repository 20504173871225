import styled from "styled-components";

export const InfoWrapper = styled.div`
  min-height: 54px;

  display: flex;
  flex-direction: column;

  padding: 5px 0px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

export const InfoRow = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights["400"]};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.lightGray};

  @media screen and (max-width: 450px) {
    font-size: ${({ theme }) => theme.fontSizes["8px"]};
  }
`;
