import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "src/components/shared/Toaster";
import { addRole } from "src/api/userManager/rolesAPI";
import { SelectorValue } from "src/modules/shared";
import { Role } from "src/modules/userManager";
import { ERRORS_MSGS } from "src/validation-schemas";

class AddRoleStore {
  newRole: Role = {
    name: "",
    abilities: [],
  };

  isLoadingRole = false;

  nameErr = "";

  abilitiesErr = "";

  updateList: () => void = () => {};

  constructor() {
    makeAutoObservable(this);
  }

  resetForm = () => {
    this.newRole = {
      name: "",
      abilities: [],
    };

    this.nameErr = "";
    this.abilitiesErr = "";
  };

  get selectAbilities() {
    return this.newRole.abilities.map((el) => ({ value: el, label: el }));
  }

  clearAbilities = () => {
    this.newRole.abilities = [];
  };

  setUpdateList = (func: () => void) => {
    this.updateList = func;
  };

  setAbilitiesHandler = () => (data: readonly SelectorValue[]) => {
    runInAction(() => {
      this.newRole.abilities = [];
    });

    for (const el of data) {
      runInAction(() => this.newRole.abilities.push(String(el.value)));
    }
  };

  removeAbility = (ability: string) => {
    this.newRole.abilities = this.newRole.abilities.filter((value) => value !== ability);
  };

  getHandler = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      this.newRole.name = String(this.getChangeEventValue(e));
    });
  };

  getChangeEventValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.type === "number" || e.target.type === "radio") {
      if (e.target.value !== "") {
        return +e.target.value;
      }
      return e.target.value;
    }
    if (e.target.type === "checkbox") {
      return e.target.checked;
    }
    if (e.target.type === "text") {
      return e.target.value;
    }
  };

  getError = () => {
    if (!this.newRole.name) this.nameErr = ERRORS_MSGS.isRequired;
    if (!this.newRole.abilities.length) this.abilitiesErr = ERRORS_MSGS.isRequired;

    if (this.newRole.name) this.nameErr = "";
    if (this.newRole.abilities.length) this.abilitiesErr = "";
  };

  submitHendler = () => async (e: React.FormEvent) => {
    e.preventDefault();

    this.getError();

    const valid = this.newRole.name && this.newRole.abilities.length;

    if (valid) {
      runInAction(() => {
        this.isLoadingRole = true;
      });

      try {
        const { isError } = await addRole(this.newRole);
        if (!isError) {
          toast.success("Role created successfully");
          this.updateList();
        }
      } finally {
        runInAction(() => {
          this.isLoadingRole = false;
        });
      }
    }
  };
}

export default AddRoleStore;
