import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import { Loader } from "src/components/shared/Loader";
import useLocalStore from "src/hooks/useLocalStore";
import { Note } from "src/modules/shared";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { NotesStore } from "src/state/Notes";
import { SubmitButton } from "../Buttons/v2/variants/SubmitButton";
import { Headline3 } from "../Typography";
import * as styles from "./style";

export interface NotesProps
  extends Pick<TextareaAutosizeProps, "minRows">,
    React.ComponentPropsWithoutRef<"div"> {
  getNote: (botName: string) => Promise<any>;
  setNote: (botName: string, data: Note) => Promise<any>;
  abilityName: string;
  party: string | undefined;
  botUUID?: string;
}

export const Notes = observer(
  ({ party, botUUID, getNote, setNote, minRows, abilityName, ...props }: NotesProps) => {
    const state = useLocalStore(NotesStore);

    useEffect(() => {
      if (!botUUID) return;
      state.setBotUUID(botUUID);
      state.getValueNote(getNote);
    }, [botUUID, getNote, state]);

    return (
      <styles.Container {...props}>
        <styles.NotesForm onSubmit={state.submitHandler(setNote)}>
          <styles.Header>
            <Headline3>Notes</Headline3>

            <PermissionWrapper party={party} abilityName={abilityName}>
              <SubmitButton size="s">Save</SubmitButton>
            </PermissionWrapper>
          </styles.Header>
          <styles.Content>
            <styles.TextArea
              placeholder="Enter your notes"
              value={state.message}
              onChange={state.textAreaHandler()}
              minRows={minRows}
            />
          </styles.Content>
          <Loader show={state.isLoading} />
        </styles.NotesForm>
      </styles.Container>
    );
  }
);
