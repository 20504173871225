import styled from "styled-components";
import { PNLChart } from "./PNLChart";

export const ChartsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const PNLChartContainer = styled(PNLChart)`
  width: calc(100% / 3);
`;

export const ChartsSeparator = styled.div`
  height: 100%;
  border-left: ${({ theme }) => `2px dashed ${theme.dashboard.borderDefault}`};
`;

export const ChartWrapper = styled.div``;
