import { useFormContext } from "react-hook-form";
import { ControlledInput } from "src/components/BotsContent/CEX/ExpertSystem/shared/Forms/ControlledInput";
import { Extends } from "src/helpers/utils";
import { IStrategyModuleCreateType, StrategyModuleTypes } from "src/modules/expertSystem";
import { ERRORS_MSGS, isTimeFrame } from "src/validation-schemas";

type ModulesWithExchPair = Extends<
  StrategyModuleTypes,
  "price_change" | "balance_change" | "balance_delta" | "volume_change" | "volatility_abs"
>;

export const TimeframeField = () => {
  const { control } = useFormContext<IStrategyModuleCreateType<ModulesWithExchPair>>();

  return (
    <ControlledInput
      controllerProps={{
        label: "Time",
        control,
        name: "hard_params.timeframe",
        rules: {
          required: ERRORS_MSGS.isRequired,
          validate: isTimeFrame(),
        },
      }}
    />
  );
};
