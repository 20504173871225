import { faker } from "@faker-js/faker";
import dayjs, { QUnitType } from "dayjs";
import { Comparators } from "src/helpers/sorting";
import {
  BalancesTokensTransactions,
  BalancesTransactionsPoints,
  ChartSeriesData,
} from "src/modules/dashboard";
import { ChartPoint } from "src/modules/shared";
import { PairTickers } from "src/state/DEXV2/shared/TradePair";

export const generateBalance = () => faker.number.float({ precision: 0.0001 });

export const generateRandomBoolean = () => Math.random() < 0.5;

export const generateRandomBooleanProb = (prob: number) => Math.random() < prob;

export const generateAccountName = () => faker.finance.accountName().replace(/Account/g, "");

export const MOCK_EXCHANGES = [
  "Binance",
  "Coinbase",
  "Kraken",
  "Bitfinex",
  "Bitstamp",
  "Bittrex",
  "Poloniex",
  "Gemini",
  "Kucoin",
  "Huobi",
];

interface CountOptions {
  count?: number | { min: number; max: number };
}

export const generateExchange = () => faker.helpers.arrayElement(MOCK_EXCHANGES);

export const generateExchanges = ({ count }: CountOptions) =>
  faker.helpers.arrayElements(MOCK_EXCHANGES, count);

interface GenerateAmountOptions {
  min?: number;
  max?: number;
  dec?: number;
}
export const generateAmount = ({
  min = 0,
  max = 1_000_000_000,
  dec = 10,
}: GenerateAmountOptions = {}) => faker.finance.amount({ min, max, dec });

export const MOCK_TOKENS = ["BTC", "ETH", "BNB", "ADA", "USDT", "XRP", "DOT", "DOGE", "LTC"];

export const generateTokenTickers = ({ count }: CountOptions) =>
  faker.helpers.arrayElements(MOCK_TOKENS, count);

export const generateTokenTicker = () => generateTokenTickers({ count: 1 })[0];

export const generatePairTickers = (): PairTickers => ({
  base: generateTokenTicker(),
  quote: generateTokenTicker(),
});

export const generateTimeMs = () => {
  const date = faker.date.past();
  return date.getTime();
};

export const generateTime = () => {
  const date = faker.date.past();
  return dayjs(date).unix();
};

export const generatePriceUSD = () => faker.commerce.price({ min: 0, max: 100000 });

export const generatePercent = () => {
  const percent = faker.number.float({ min: 0, max: 1, precision: 0.0001 });
  return `${percent}`;
};

export const generatePercentChange = () => {
  const isPositive = generateRandomBoolean();
  const percent = generatePercent();
  return isPositive ? `${percent}` : `-${percent}`;
};
export const generateVolume = () => faker.commerce.price({ min: 0, max: 10000000 });

export const generateLiquidity = () => generateVolume();

export interface GenerateTimeSeriesOptions {
  startTimestamp?: number;
  step?: {
    value: number;
    unit: QUnitType;
  };
  count?: number;
  value?: {
    min?: number;
    max?: number;
    precision?: number;
  };
}

export const generateTimeSeries = ({
  step: { value: dayCount, unit: dayUnit } = { value: 1, unit: "hour" },
  startTimestamp,
  count,
  value: { min: minValue = 0, max: maxValue = 100, precision = 0.01 } = {},
}: GenerateTimeSeriesOptions = {}): ChartPoint[] => {
  const startTime = startTimestamp ?? generateTime();
  const startDay = dayjs.utc(startTime * 1000);

  let currentDay = startDay;

  const generateTimePoint = (): ChartPoint => {
    const time = currentDay.unix();
    const value = faker.number.float({
      min: minValue,
      max: maxValue,
      precision,
    });
    currentDay = currentDay.add(dayCount, dayUnit);
    return { time, value };
  };

  const timeSeries = faker.helpers.multiple(generateTimePoint, {
    count,
  });

  return timeSeries;
};

export const generateTransactions = (tickers: string[]): BalancesTokensTransactions | undefined => {
  const transactionsTickers = faker.helpers.arrayElements(
    tickers,
    faker.number.int({ min: 0, max: tickers.length })
  );

  if (!transactionsTickers.length) return undefined;

  return transactionsTickers.reduce((acc, ticker) => {
    acc[ticker] = generateAmount();
    return acc;
  }, {} as BalancesTokensTransactions);
};

export const generateTransactionsPoints = (times: number[]): BalancesTransactionsPoints => {
  const tokensCount = faker.number.int({ min: 2, max: 5 });

  const tokens = generateTokenTickers({ count: tokensCount });

  const addEmptyPoint = (points: BalancesTransactionsPoints, time: number) => {
    points.time.push(time);
    points.value.push(null);
  };

  const points = times.reduce<BalancesTransactionsPoints>(
    (points, time) => {
      const skipPoint = faker.datatype.boolean();
      if (skipPoint) {
        addEmptyPoint(points, time);
        return points;
      }
      const inTransactions = generateTransactions(tokens);
      const outTransactions = generateTransactions(tokens);
      if (!inTransactions && !outTransactions) {
        addEmptyPoint(points, time);
        return points;
      }

      points.time.push(time);
      points.value.push({ in: inTransactions, out: outTransactions });
      return points;
    },
    { time: [], value: [] } as BalancesTransactionsPoints
  );

  return points;
};

export const pointsToSeriesData = (points: ChartPoint[]): ChartSeriesData => {
  const time: number[] = [];
  const value: number[] = [];

  points.forEach((point) => {
    time.push(point.time);
    value.push(point.value);
  });

  return { time, value };
};

interface RandomTrendSortOptions {
  count?: { min: number; max: number };
}

export const randomTrendSort = (array: number[], { count }: RandomTrendSortOptions = {}) => {
  const shuffled = faker.helpers.shuffle(array);

  let currentIndex = 0;
  const minCount = count?.min ?? 1;
  const maxCount = count?.max ?? 4;

  const minSegment = array.length / maxCount;
  const maxSegment = array.length / minCount;

  while (currentIndex < array.length) {
    const remainingLength = array.length - currentIndex;
    const randomSegmentLength = faker.number.int({ min: minSegment, max: maxSegment });
    const segmentLength = Math.min(remainingLength, randomSegmentLength);

    const segment = array.slice(currentIndex, currentIndex + segmentLength);

    const sortAscending = generateRandomBoolean();
    const compareFn = sortAscending ? Comparators.Number : Comparators.Number.reverse;
    segment.sort(compareFn);

    shuffled.splice(currentIndex, segment.length, ...segment);

    currentIndex += segmentLength;
  }

  return shuffled;
};
