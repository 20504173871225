import { makeAutoObservable } from "mobx";
import {
  GetBalanceResponse,
  GetBalancesRequest,
  GetBalancesResponse,
  GetBotWalletResponse,
  GetBotWalletsResponse,
  StatsWallet,
  StatsWalletType,
  getBalances,
  getBotWallets,
} from "src/api/bots/DEXV2/stats";
import { arrayToMapObj, deleteElem } from "src/helpers/array";
import { setTextClipboard } from "src/helpers/clipboard";
import { getBalanceHelperContract, getBalanceNative } from "src/helpers/getBalances";
import { makeLoggable } from "src/helpers/logger";
import { bigNumbersToNumbers } from "src/helpers/math";
import { chainErrorHandler } from "src/helpers/network/chain";
import { logError } from "src/helpers/network/logger";
import { calcRoundingValues, roundNumber } from "src/helpers/rounding";
import { Mapper } from "src/helpers/utils";
import { IBotChainProvider } from "../../DEXV2Bots/DEXV2BotStore";
import { walletsRespToWithdrawer } from "../../shared/WithdrawerProvider";

const walletRespToWallet = (
  { id, addr }: GetBotWalletResponse,
  type: StatsWalletType,
  balance?: number
): StatsWallet => ({
  id,
  address: addr,
  type,
  balance: balance ?? 0,
});

export const walletsRespToWallets = (walletsResp: GetBotWalletsResponse): StatsWallet[] => {
  const deployerWallet = walletRespToWallet(walletsResp.deployer, "deployer");

  const limitWallets = walletsResp.limit.map((executor) => walletRespToWallet(executor, "limit"));

  const volumeWallets = walletsResp.volume.map((executor) =>
    walletRespToWallet(executor, "volume")
  );

  const counterWallets = walletsResp.counter.map((executor) =>
    walletRespToWallet(executor, "counter")
  );

  return ([] as StatsWallet[]).concat(deployerWallet, limitWallets, volumeWallets, counterWallets);
};

const walletsToBalancesReq: Mapper<StatsWallet[], GetBalancesRequest> = (wallets) => {
  const getWalletTypeIds = (wallets: StatsWallet[], type: StatsWalletType) =>
    wallets.filter(({ type: walletType }) => walletType === type).map(({ id }) => id);

  const deployerId = getWalletTypeIds(wallets, "deployer")[0];
  const limitIds = getWalletTypeIds(wallets, "limit");
  const volumeIds = getWalletTypeIds(wallets, "volume");
  const counterIds = getWalletTypeIds(wallets, "counter");
  return {
    deployer_id: deployerId,
    limit_executors: limitIds,
    volume_executors: volumeIds,
    counter_executors: counterIds,
  };
};

type WalletsBalances = Record<string, number>;

const walletBalancesRespToWalletBalances: Mapper<GetBalanceResponse[], WalletsBalances> = (
  balancesResp
) => {
  const balances: WalletsBalances = {};

  balancesResp.forEach(({ key_id, balance }) => {
    balances[key_id] = balance;
  });

  return balances;
};

const balancesRespToBalances = (
  { deployer_balance, limit_executors, volume_executors, counter_executors }: GetBalancesResponse,
  deployerId: string
): WalletsBalances => {
  const deployerBalance = walletBalancesRespToWalletBalances([
    { key_id: deployerId, balance: deployer_balance },
  ]);

  const limitBalances = limit_executors ? walletBalancesRespToWalletBalances(limit_executors) : {};

  const volumeBalances = volume_executors
    ? walletBalancesRespToWalletBalances(volume_executors)
    : {};

  const counterBalances = counter_executors
    ? walletBalancesRespToWalletBalances(counter_executors)
    : {};

  return {
    ...deployerBalance,
    ...limitBalances,
    ...volumeBalances,
    ...counterBalances,
  };
};

export type SenderWallet = Omit<StatsWallet, "balance">;

export default class GasWalletsStore {
  private _selectedAddresses: string[] = [];

  private _wallets: StatsWallet[] = [];

  private _withdrawer = "";

  private _botChainProvider: IBotChainProvider;

  private _botUUID = "";

  private _loading = false;

  constructor(chainProvider: IBotChainProvider) {
    this._botChainProvider = chainProvider;

    makeAutoObservable(this);

    makeLoggable<any>(this, { wallets: true, _selectedAddresses: true });
  }

  private get _chainProvider() {
    return this._botChainProvider.chainProvider;
  }

  private get _balancesHelperContract() {
    const chainInfo = this._chainProvider.currentChain;
    if (!chainInfo) return null;
    return getBalanceHelperContract(chainInfo);
  }

  private get _chainInfo() {
    return this._chainProvider.currentChain;
  }

  get nativeTicker() {
    return this._chainInfo?.native ?? "Native";
  }

  get chainProvider() {
    return this._chainProvider;
  }

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get wallets() {
    return this._wallets;
  }

  get roundedWallets(): StatsWallet[] {
    const wallets = this._wallets;

    const balances = wallets.map(({ balance }) => balance);
    const fractionDigits = calcRoundingValues(balances);

    return wallets.map(({ balance, ...wallet }) => ({
      ...wallet,
      balance: +balance.toFixed(fractionDigits),
    }));
  }

  private get _totalBalance() {
    return this._wallets.reduce((totalBalance, { balance }) => totalBalance + balance, 0);
  }

  get totalRoundedBalance() {
    return roundNumber(this._totalBalance);
  }

  private get _addressWalletMap() {
    return arrayToMapObj(this._wallets, "address");
  }

  get selectedAddresses() {
    return this._selectedAddresses;
  }

  get selectedSenderWallets(): SenderWallet[] {
    return this.selectedAddresses.map((selectedAddress) => {
      const { balance, ...wallet } = this._addressWalletMap[selectedAddress];
      return { ...wallet };
    });
  }

  get withdrawer() {
    return this._withdrawer;
  }

  private _setWithdrawer = (withdrawer: string) => {
    this._withdrawer = withdrawer;
  };

  get deployerId() {
    return this._wallets.find(({ type }) => type === "deployer")?.id ?? "";
  }

  get selectedAddressesBalance(): number {
    return this.selectedAddresses.reduce((totalBalance, selectedAddress) => {
      const { balance } = this._addressWalletMap[selectedAddress];
      return totalBalance + balance;
    }, 0);
  }

  get allCheckedAddresses() {
    if (!this._selectedAddresses.length) return false;

    for (const { address } of this._wallets) {
      if (!this._selectedAddresses.includes(address)) return false;
    }

    return true;
  }

  get deployerSelected() {
    return !!this._selectedAddresses.find((address) =>
      this._addressWalletMap[address] ? this._addressWalletMap[address].type === "deployer" : false
    );
  }

  get isAddressSelected() {
    return !!this._selectedAddresses.length;
  }

  copyAllAddresses = () => {
    const addresses: string[] = [];
    this.wallets.forEach((el) => {
      addresses.push(el.address);
    });

    setTextClipboard(addresses.join("\n"));
  };

  selectAll = () => {
    if (this.allCheckedAddresses) {
      this._selectedAddresses = [];
    } else this._wallets.forEach((wallet) => this._selectedAddresses.push(wallet.address));
  };

  selectAddress = (address: string) => {
    const checkDuplication = this._selectedAddresses.includes(address);

    if (checkDuplication) {
      deleteElem(this._selectedAddresses, address);
    } else this._selectedAddresses.push(address);
  };

  private _setWalletsAddresses = (wallets: StatsWallet[]) => {
    this._wallets = wallets;
  };

  private _setWalletsBalances = (balances: WalletsBalances) => {
    this._wallets.forEach((wallet) => {
      // eslint-disable-next-line no-param-reassign
      wallet.balance = balances[wallet.id] ?? 0;
    });
  };

  private _setBalances = (balances: number[]) => {
    if (this._wallets.length !== balances.length) return;
    this._wallets.forEach((wallet, index) => {
      // eslint-disable-next-line no-param-reassign
      wallet.balance = balances[index];
    });
  };

  private _getWallets = async () => {
    const { isError, data } = await getBotWallets(this._botUUID);
    if (!isError) {
      const wallets = walletsRespToWallets(data);
      this._setWalletsAddresses(wallets);
      const withdrawer = walletsRespToWithdrawer(data);
      this._setWithdrawer(withdrawer);
    }
    return isError;
  };

  private _getWalletsBalances = async () => {
    const balancesReq = walletsToBalancesReq(this._wallets);
    const { isError, data } = await getBalances(this._botUUID, balancesReq);
    if (!isError) {
      const balances = balancesRespToBalances(data, this._botUUID);
      this._setWalletsBalances(balances);
    }
    return isError;
  };

  getWalletBalances = async () => {
    this._setLoading(true);

    try {
      const isWalletsError = await this._getWallets();
      if (!isWalletsError) {
        await this._getWalletsBalances();
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  private _refreshWallets = async () => {
    if (this._wallets.length) return false;
    return await this._getWallets();
  };

  private _refreshWalletsBalances = async (refreshBalances: () => Promise<any>) => {
    this._setLoading(true);

    try {
      const isWalletsError = await this._refreshWallets();
      if (!isWalletsError) {
        await refreshBalances();
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  refreshBalances = async () => {
    await this._refreshWalletsBalances(this._refreshBalances);
  };

  private _refreshBalances = async () => {
    await this._getWalletsBalances();
  };

  refreshChainBalances = async () => {
    await this._refreshWalletsBalances(this._refreshChainBalances);
  };

  private _refreshChainBalances = async () => {
    const addresses = this._wallets.map(({ address }) => address);
    const chainBalances = await this._fetchChainBalances(addresses);
    if (chainBalances.length > 0) {
      this._setBalances(chainBalances);
    }
  };

  _fetchChainBalances = async (addresses: string[]) => {
    const contract = this._balancesHelperContract;
    if (!contract) return [];

    try {
      const data = await getBalanceNative(contract, addresses);

      return bigNumbersToNumbers(data);
    } catch (err) {
      chainErrorHandler(err);
      return [];
    }
  };

  destroy = () => {};
}
