import { makeAutoObservable } from "mobx";
import {
  GetWalletHistoryQueryParams,
  GetWalletHistoryResponse,
  WalletHistoryHash,
  getWalletHistory,
} from "src/api/bots/DEXV2/history";
import { GetBotWalletsResponse, getBotWallets } from "src/api/bots/DEXV2/stats";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { makeLoggable } from "src/helpers/logger";
import { Mapper } from "src/helpers/utils";
import { IBotUUIDProvider } from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { HashesPaginationResponse, HashesResponseMap, IHashesPaginationFetcherProvider } from ".";
import { walletsRespToWallets } from "../../../GasWallets";
import { WalletHashType } from "../../HashesTypeStore";
import { transactionHashToStatus } from "./VaultsPaginationFetcherProvider";

export type WalletsResponse = HashesResponseMap[WalletHashType];

export interface WalletsPaginationResponse extends HashesPaginationResponse<"wallet"> {}

const walletHistoryHashToTransactionHash = (
  { trx_hash, date, type, error, status, wallet_type, wallet_id }: WalletHistoryHash,
  addressMap: WalletsIdAddressMap
): WalletsResponse => ({
  type,
  hash: trx_hash,
  date: unixToDateFormat(date, "FullDate"),
  status: transactionHashToStatus(status, error),
  walletType: wallet_type,
  address: addressMap[wallet_id] ?? "",
});

export const walletHistoryRespToHistoryHash = (
  { history, total_pages }: GetWalletHistoryResponse,
  addressMap: WalletsIdAddressMap
): WalletsPaginationResponse => {
  const hashes = history.map((hash) => walletHistoryHashToTransactionHash(hash, addressMap));

  return { items: hashes, pagesCount: total_pages, type: "wallet" };
};

type WalletsIdAddressMap = Partial<Record<string, string>>;

const walletsRespToWalletsAddressMap: Mapper<GetBotWalletsResponse, WalletsIdAddressMap> = (
  resp
) => {
  const wallets = walletsRespToWallets(resp);

  const addressesMap = wallets.reduce((walletsMap, { address, id }) => {
    // eslint-disable-next-line no-param-reassign
    walletsMap[id] = address;
    return walletsMap;
  }, {} as WalletsIdAddressMap);
  return addressesMap;
};

export class WalletsPaginationFetcherProvider
  implements IHashesPaginationFetcherProvider<"wallet">
{
  private _botUUIDProvider: IBotUUIDProvider;

  private _addressMap: WalletsIdAddressMap = {};

  constructor(botUUIDProvider: IBotUUIDProvider) {
    makeAutoObservable(this);

    this._botUUIDProvider = botUUIDProvider;

    makeLoggable<any>(this, { _addressMap: true });
  }

  private get _botUUID() {
    return this._botUUIDProvider.botUUID;
  }

  private _setAddressMap = (addressMap: WalletsIdAddressMap) => {
    this._addressMap = addressMap;
  };

  private get _isEmptyAddressMap() {
    return Object.keys(this._addressMap).length === 0;
  }

  private _getWallets = async () => {
    if (!this._botUUID) return;
    if (!this._isEmptyAddressMap) return;

    const { isError, data } = await getBotWallets(this._botUUID);
    if (!isError) {
      const addressMap = walletsRespToWalletsAddressMap(data);
      this._setAddressMap(addressMap);
    }
    return isError;
  };

  private _getWalletHashes = async (uuid: string, params?: GetWalletHistoryQueryParams) => {
    await this._getWallets();

    const { isError, data } = await getWalletHistory(uuid, params);
    if (!isError) {
      return walletHistoryRespToHistoryHash(data, this._addressMap);
    }
  };

  getHashesFetcher = () => this._getWalletHashes;
}
