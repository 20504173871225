import { ComponentPropsWithoutRef, useMemo } from "react";
import { PNLV2Item, PNLV2ItemProps } from "src/components/shared/PNLV2/shared/Item/PNLV2Item";
import {
  PNLV2USDItem,
  PNLV2USDItemProps,
} from "src/components/shared/PNLV2/shared/Item/PNLV2USDItem";
import {
  PNLV2PriceItem,
  PNLV2PriceItemProps,
} from "src/components/shared/PNLV2/shared/Item/PNLV2PriceItem";
import { PNLData } from "src/state/CEX/shared/CEXPNLStore/types";
import * as styles from "./style";

interface PNLItemOwnProps
  extends Omit<PNLData, "currentPriceBase" | "fee" | "priceGrowth" | "priceNet"> {
  price?: string | number;
}

export interface PNLItemsProps extends PNLItemOwnProps, ComponentPropsWithoutRef<"div"> {}

export const PNLItems = ({
  base = "Base",
  deltaBase,
  deltaBaseUsd,
  deltaQuoteUsd,
  price,
  ...props
}: PNLItemsProps) => {
  const baseItemsProps: Partial<PNLV2ItemProps> = {
    orientation: "column-reverse",
    showArrow: false,
    coloredLabel: false,
  };

  const pnlItems = useMemo(() => {
    const baseLabel = `Δ${base}`;
    const quoteLabel = "ΔUSD";
    const priceLabel = "Price";

    const items: (PNLV2ItemProps | PNLV2USDItemProps | PNLV2PriceItemProps)[] = [
      { label: quoteLabel, value: deltaQuoteUsd, type: "delta", key: "quote" },
      { label: baseLabel, value: deltaBase, valueUSD: deltaBaseUsd, type: "delta", key: "base" },
      { label: priceLabel, price, quote: deltaQuoteUsd, type: "price", key: "price" },
    ];

    return items;
  }, [base, deltaBase, deltaBaseUsd, deltaQuoteUsd, price]);

  return (
    <styles.Container {...props}>
      {pnlItems.map(({ key, ...itemProps }) => {
        switch (key) {
          case "base": {
            return <PNLV2USDItem {...itemProps} {...baseItemsProps} key={key} />;
          }
          case "price": {
            return <PNLV2PriceItem {...itemProps} {...baseItemsProps} key={key} />;
          }
          default: {
            return <PNLV2Item {...itemProps} {...baseItemsProps} key={key} />;
          }
        }
      })}
    </styles.Container>
  );
};
