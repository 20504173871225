import { observer } from "mobx-react-lite";
import { PNLSummaryContext } from "src/context/CEX/Dashboard/v2/PNLSummary";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { LayoutCEXPNL } from "src/components/BotsContent/CEX/shared/LayoutCEXPNL";
import { DashboardV2Widget, getWidgetInfo, getWidgetTitle } from "../shared/constants";

export interface DashboardPNLWidgetProps extends Omit<CardProps, "loading" | "title" | "info"> {}

export const DashboardPNLWidget = observer((props: DashboardPNLWidgetProps) => {
  const { pnlState } = useLateInitContext(PNLSummaryContext.Context);
  const { data, loading } = pnlState;

  return (
    <LayoutCEXPNL
      showCharts
      loading={loading}
      pnlProps={{
        title: getWidgetTitle(DashboardV2Widget.PNLSummary),
        info: getWidgetInfo(DashboardV2Widget.PNLSummary),
        ...data,
      }}
      style={props.style}
    />
  );
});
