import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { BaseModuleFormWithType } from "src/components/BotsContent/CEX/ExpertSystem/shared/BaseModuleFormWithType";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { StrategyModuleGroupCreateType } from "src/modules/expertSystem";
import { ExchPairFields } from "../../../shared";
import { getEmptyConditionModule, getModulesInGroup } from "../shared/utils";

const MODULE_GROUP = "abs_modules";

const MODULES_IN_GROUP = getModulesInGroup(MODULE_GROUP);

export const AbsModules = observer(() => {
  const state = useLateInitContext(CreateModulesContext);
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<StrategyModuleGroupCreateType<typeof MODULE_GROUP>>({
    defaultValues: getEmptyConditionModule(MODULE_GROUP),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <BaseModuleFormWithType
        formId={MODULE_GROUP}
        types={MODULES_IN_GROUP}
        onSubmit={handleSubmit(state.createConditionHandler(close))}
      >
        <RowWrapper>
          <ExchPairFields showAllExchanges />
        </RowWrapper>
      </BaseModuleFormWithType>
    </FormProvider>
  );
});
