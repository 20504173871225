import styled, { css } from "styled-components";

export type DisabledCellProps = {
  isDisabled?: boolean;
  disablePointer?: boolean;
};

export const DisabledCellWrapper = styled.span<DisabledCellProps>`
  ${({ isDisabled, disablePointer = true }) => {
    if (isDisabled) {
      return css`
        opacity: 0.6;
        ${disablePointer ? "pointer-events: none" : ""};
      `;
    }
  }}
`;
