import { FormProvider, useForm } from "react-hook-form";
import { ISwapModule } from "src/state/CEX/botModules";
import { CreateModule } from "..";
import { SwapModuleForm } from "../../Modules/SwapModule/SwapModuleForm";

export const CreateSwapModule = () => {
  const methods = useForm<ISwapModule>({ defaultValues: { kind: "swap" } });

  return (
    <FormProvider {...methods}>
      <CreateModule>
        <SwapModuleForm />
      </CreateModule>
    </FormProvider>
  );
};
