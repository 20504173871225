import { DebouncedFunc, throttle } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { Order, OrderBookResponse } from "src/modules/exchange/orderBook";
import WebSocketStore, { IStreamConfig } from "src/state/network/WebSocketHandler";
import DataStorageStore from "../DataStorageStore";

const ORDERBOOK_PATH = "order-books";

const DEPTH_ORDER_BOOK = 50;

const THROTTLE_UPD_MS = 300;

const EMPTY_ORDERBOOK_RESPONSE: OrderBookResponse["data"] = {
  asks: [],
  bids: [],
};

class WSProvider {
  dataStorageState;

  webSocketState;

  private _prevStreamPair: string = "";

  _wsResData: OrderBookResponse["data"] | null = null;

  _throttledHandleUpd: DebouncedFunc<() => void>;

  constructor(state: DataStorageStore) {
    this.dataStorageState = state;

    this._throttledHandleUpd = throttle(this._updateProcessing, THROTTLE_UPD_MS);

    this.webSocketState = new WebSocketStore({
      subCb: [this._throttledHandleUpd],
    });

    makeAutoObservable(this, { _throttledHandleUpd: false });
  }

  get pair() {
    return this.dataStorageState.pair;
  }

  get exchange() {
    return this.dataStorageState.exchange;
  }

  get data(): OrderBookResponse {
    return { data: { asks: this.asks, bids: this.bids } };
  }

  get asks() {
    if (this._wsResData) {
      return this.adjustDepth(this._wsResData.asks, DEPTH_ORDER_BOOK).slice().reverse();
    }

    return [];
  }

  get bids() {
    if (this._wsResData) {
      return this.adjustDepth(this._wsResData.bids, DEPTH_ORDER_BOOK).slice().reverse();
    }

    return [];
  }

  get connectStatus() {
    return this.webSocketState.socketStatus;
  }

  get payload() {
    return {
      exchange: this.exchange,
      pair: this.pair,
    };
  }

  get streamConfig(): IStreamConfig {
    return {
      type: "subscribe",
      payload: this.payload,
    };
  }

  get isChangePair() {
    return this._prevStreamPair !== this.pair;
  }

  resetData = () => {
    this._wsResData = EMPTY_ORDERBOOK_RESPONSE;
  };

  afterGetFirstDataCb = () => {
    this.dataStorageState.mainState.calcPrecisions();
    this.dataStorageState.setFirstLoad(false);
  };

  private _updateProcessing = () => {
    if (this.webSocketState.data?.type === "orderbooks") {
      const requestData = this.webSocketState.data;

      if (requestData.result.pair === this.pair) {
        const { data } = requestData.result;

        runInAction(() => {
          this._wsResData = data;
        });

        this.afterGetFirstDataCb();
      }
    }
  };

  adjustDepth = (arr: Order[], depth: number) => {
    const deltaLength = arr.length - depth;

    if (deltaLength > 0) {
      return arr.slice(0, depth);
    }
    if (deltaLength < 0) {
      for (let i = 0; i > deltaLength; i -= 1) {
        arr.push({ price: "", amount: "" });
      }
    }

    return arr;
  };

  streamData = () => {
    if (this.connectStatus === 3 || this.connectStatus === undefined) {
      this._openSocket();
    } else if (this.connectStatus === 1 && this.isChangePair) {
      this._setupWebSocket();
    }
  };

  private _setPrevStreamPair = (pair: string) => {
    this._prevStreamPair = pair;
  };

  private _openSocket = async () => {
    this.webSocketState.setupWebSocket({
      url: ORDERBOOK_PATH,
      config: this.streamConfig,
    });

    this._setPrevStreamPair(this.pair);
  };

  private _setupWebSocket = () => {
    if (this._prevStreamPair) this._unSubPrevPair(this._prevStreamPair);

    this.webSocketState.sendMsgOnStream(this.streamConfig);

    this._setPrevStreamPair(this.pair);
  };

  private _unSubPrevPair = (pair: string) => {
    this.webSocketState.sendMsgOnStream({
      type: "unsubscribe",
      payload: { exchange: this.exchange, pair },
    });
  };

  closeConnect = () => {
    this._throttledHandleUpd.cancel();
    this.webSocketState.closeWebSocket();
  };
}

export default WSProvider;
