import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 17px 8px;
`;

export const Title = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  margin: 0px;
  color: ${({ theme }) => theme.bigHeader};

  & > span > svg {
    cursor: pointer;
    margin: 0px 4px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-of-type {
    margin-right: 15px;
  }
`;
