import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { DEXV2SwapStore } from "src/state/DEXV2/DEXV2Swap";
import { DEXV2BotContext } from "../DEXV2Bots/DEXV2Bot";

export const DEXV2SwapContext = createLateInitContext<DEXV2SwapStore>();

type DEXV2SwapProviderProps = ChildrenProps;

export const DEXV2SwapProvider = ({ children }: DEXV2SwapProviderProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const store = useLocalStore(DEXV2SwapStore, {
    chainInfoProvider: botState,
    tradePairProvider: botState,
    pairAddressProvider: botState,
    tradePairPriceProvider: botState.tradePairPriceProvider,
    nativeUSDPriceProvider: botState.nativeUSDPriceProvider,
    gasPriceProvider: botState.gasPriceProvider,
    blockTimestampProvider: botState.blockTimestampProvider,
    baseUSDPriceProvider: botState.baseUSDPriceProvider,
  });

  return <DEXV2SwapContext.Provider value={store}>{children}</DEXV2SwapContext.Provider>;
};
