import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { DEXV2PermissionsWrapper } from "../shared/Permissions/DEXV2PermissionsWrapper";
import { SubmitAllButton } from "./Buttons/SubmitAllButton";
import { Counter } from "./Counter";
import { Info } from "./Info";
import { Limit } from "./Limit";
import { Main } from "./Main";
import { Volume } from "./Volume";
import * as styles from "./style";

export interface SettingsProps {
  bot_uuid: string;
}

export const Settings = observer(({ bot_uuid }: SettingsProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  const { hasAbility } = useLateInitContext(AbilitiesContext);

  const showInfo = hasAbility(DEXV2Ability.InfoSettingsView);

  useEffect(() => {
    if (!bot_uuid) return;
    state.setBotUUID(bot_uuid);
    state.getData();
  }, [bot_uuid, state]);

  return (
    <styles.Container>
      <styles.BotInfo bot_uuid={bot_uuid} showStartBtn />
      <ErrorBoundary fallback={<styles.SettingsFallback />}>
        <styles.SettingsContainer showInfo={showInfo}>
          <Volume style={{ gridArea: "volume_settings" }} />
          <Limit style={{ gridArea: "limit_settings" }} />
          <Counter style={{ gridArea: "counter_settings" }} />
          <Main style={{ gridArea: "manage_settings" }} />
          <DEXV2PermissionsWrapper ability={DEXV2Ability.InfoSettingsView}>
            <Info style={{ gridArea: "info" }} />
          </DEXV2PermissionsWrapper>
          <SubmitAllButton style={{ gridArea: "submit" }} />
        </styles.SettingsContainer>
      </ErrorBoundary>
    </styles.Container>
  );
});
