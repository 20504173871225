import { ButtonGroup, ButtonGroupProps } from "src/components/shared/Buttons/v2/ButtonGroup";
import { SaveButton, SaveButtonProps } from "src/components/shared/Buttons/v2/variants/SaveButton";
import { PopoverMenu, PopoverMenuProps } from "src/components/shared/PopoverMenu";
import { SaveDropdownButton } from "./SaveDropdownButton";

export interface SaveSplitButtonOwnProps {
  saveProps?: Omit<SaveButtonProps, "saved">;
  menuProps?: Omit<PopoverMenuProps, "trigger">;
  saved?: boolean;
}

export interface SaveSplitButtonProps
  extends SaveSplitButtonOwnProps,
    Omit<ButtonGroupProps, keyof SaveSplitButtonOwnProps> {}

export const SaveSplitButton = ({
  saveProps = {},
  menuProps = {},
  saved,
  ...props
}: SaveSplitButtonProps) => (
  <ButtonGroup {...props}>
    <PopoverMenu
      trigger={(isOpen) => <SaveDropdownButton open={isOpen} saved={saved} />}
      {...menuProps}
    />
    <SaveButton saved={saved} {...saveProps} />
  </ButtonGroup>
);
