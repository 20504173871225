import { forwardRef } from "react";
import { Button, ButtonProps } from "../Button";
import * as styles from "./style";

export interface DropdownButtonProps extends Omit<ButtonProps, "ownRef">, styles.ExpandIconProps {}

export const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ open, ...props }, ref) => (
    <Button endIcon={<styles.ExpandIcon $open={open} />} ownRef={ref} {...props} />
  )
);
