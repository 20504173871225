import { LinearProgressProps } from "src/components/shared/Progress";
import { toPercent } from "src/helpers/styled/units";
import * as styles from "./style";

export interface ProgressBarProps extends Omit<LinearProgressProps, "orientation"> {}

export const ProgressBar = ({ style, className, value, ...props }: ProgressBarProps) => {
  const progressText = value ? toPercent(value) : "-%";

  const styleProps = {
    style,
    className,
  };

  return (
    <styles.Container {...styleProps}>
      <styles.ProgressLabel>{progressText}</styles.ProgressLabel>
      <styles.StyledProgressBar value={value} {...props} />
    </styles.Container>
  );
};
