import { ComponentPropsWithoutRef } from "react";
import Icons from "src/icons/Icons";
import { Loader } from "../../Loader";
import * as styles from "./style";

export interface WarningProps
  extends ComponentPropsWithoutRef<"div">,
    Partial<styles.AppearanceProps> {
  text: string;
  isLoading?: boolean;
}

export const Warning = ({
  children,
  text,
  isLoading,
  appearance = "primary",
  ...props
}: WarningProps) => {
  const Icon = appearance === "primary" ? <Icons.Prompt /> : <Icons.WarningSign />;
  return (
    <styles.Container appearance={appearance} {...props}>
      {Icon}
      <styles.Text appearance={appearance}>{text}</styles.Text>
      <Loader show={isLoading} />
    </styles.Container>
  );
};
