import { observer } from "mobx-react-lite";
import React from "react";
import * as styles from "./style";

type ElementRef = React.ComponentPropsWithRef<"div">["ref"];

export interface PromptProps {
  value: string | number;
  prefix?: string;
  icon?: JSX.Element;
  setValue?: Function;
  style?: React.CSSProperties;
  ownRef?: ElementRef;
}

export const Prompt = observer(
  ({ value, prefix, icon, setValue = () => null, ownRef, ...props }: PromptProps) => (
    <styles.Prompt onClick={() => setValue(value)} ref={ownRef} {...props}>
      <styles.Text>
        {prefix ? `${prefix} ` : ""}
        {value}
      </styles.Text>
      {icon}
    </styles.Prompt>
  )
);
