import { useCallback } from "react";
import { ITooltip as TooltipProps } from "react-tooltip";
import { SettingsInfoTooltipContent } from "./SettingsInfoTooltipContent";
import * as styles from "./style";

type TooltipRender = NonNullable<TooltipProps["render"]>;

export interface SettingsInfoTooltipProps extends Omit<TooltipProps, "render" | "className"> {}

export const SettingsInfoTooltip = (props: SettingsInfoTooltipProps) => {
  const getTooltipContent: TooltipRender = useCallback(
    ({ content: price }) => price && <SettingsInfoTooltipContent price={price} />,
    []
  );

  return <styles.StyledTooltip render={getTooltipContent} {...props} />;
};
