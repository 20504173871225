import { FormProvider, useForm } from "react-hook-form";
import { BaseModuleFormWithType } from "src/components/BotsContent/CEX/ExpertSystem/shared/BaseModuleFormWithType";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared/style";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";

import { useLateInitContext } from "src/hooks/useLateInitContext";
import { StrategyModuleGroupCreateType } from "src/modules/expertSystem";
import { ExchPairFields } from "../../../shared";
import { TimeframeField } from "../../../shared/TimeframeField";
import { getEmptyConditionModule, getModulesInGroup } from "../shared/utils";

const MODULE_GROUP = "timeframe_modules";

export const ChangeModules = () => {
  const state = useLateInitContext(CreateModulesContext);

  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<StrategyModuleGroupCreateType<typeof MODULE_GROUP>>({
    defaultValues: getEmptyConditionModule(MODULE_GROUP),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <BaseModuleFormWithType
        onSubmit={handleSubmit(state.createConditionHandler(close))}
        formId={MODULE_GROUP}
        types={getModulesInGroup(MODULE_GROUP)}
      >
        <RowWrapper>
          <ExchPairFields />
        </RowWrapper>

        <RowWrapper>
          <TimeframeField />
        </RowWrapper>
      </BaseModuleFormWithType>
    </FormProvider>
  );
};
