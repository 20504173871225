import { makeAutoObservable } from "mobx";
import { getBalancePNL } from "src/api/bots/CEX/dashboard";
import { getRangeQueryParams } from "src/state/shared/utils";
import { pnlResponseMapper } from "./pnlResponseMapper";
import { PNLData, PNLRequestData } from "./types";

const INITIAL_DATA: PNLData = {
  base: "",
  currentPriceBase: "",
  deltaQuoteUsd: "",
  deltaBase: "",
  deltaBaseUsd: "",
  priceGrowth: "0",
  priceNet: "0",
  fee: "",
  deltaUsd: "",
  fundingsExchange: {},
  charts: null,
};

export class CEXPNLStore {
  private _data: PNLData = INITIAL_DATA;

  private _loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get data() {
    return this._data;
  }

  get loading() {
    return this._loading;
  }

  private _setData = (data: PNLData) => {
    this._data = data;
  };

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  private _getRequestParams({ range, party, withCharts = false }: PNLRequestData) {
    const rangeParams = getRangeQueryParams(range);

    if (!rangeParams) return;

    return { ...rangeParams, party, chart: withCharts };
  }

  getPNLData = async (data: PNLRequestData) => {
    const queryParams = this._getRequestParams(data);

    if (!queryParams) return;

    this._setLoading(true);
    try {
      const { party, ...params } = queryParams;
      const { isError, data } = await getBalancePNL(party, params);

      if (!isError) {
        const pnlData = pnlResponseMapper(data);
        this._setData(pnlData);
      }
    } catch {
      this._setData(INITIAL_DATA);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
