import { useEventCallback } from "@mui/material";
import {
  DateTimeValidationError,
  UseDateTimeFieldComponentProps,
  UseDateTimeFieldDefaultizedProps,
  UseDateTimeFieldProps,
} from "@mui/x-date-pickers";
import { useDateTimeField } from "@mui/x-date-pickers/DateTimeField/useDateTimeField";
import {
  FieldChangeHandler,
  FieldChangeHandlerContext,
  UseFieldResponse,
  applyDefaultDate,
  useControlledValueWithTimezone,
  useDefaultDates,
  useLocalizationContext,
  useUtils,
  useValidation,
} from "@mui/x-date-pickers/internals";
import { useValueWithTimezone } from "@mui/x-date-pickers/internals/hooks/useValueWithTimezone";
import { ExtractStrict } from "src/helpers/utils";
import {
  UseDateTimeRangeFieldDefaultizedProps,
  UseDateTimeRangeFieldProps,
} from "../models/dateTimeRange";
import { DateRange } from "../models/range";
import { rangeValueManager } from "../utils/valueManager";
import {
  DateTimeRangeComponentValidationProps,
  DateTimeRangeValidationError,
  validateDateTimeRange,
} from "./useDateTimeValidation";

export interface UseMultiInputDateTimeRangeFieldProps<TDate>
  extends Omit<UseDateTimeRangeFieldProps<TDate>, "unstableFieldRef"> {}

export type UseMultiInputDateTimeRangeFieldDefaultizedProps<
  TDate,
  AdditionalProps extends {},
> = UseDateTimeRangeFieldDefaultizedProps<TDate> &
  Omit<AdditionalProps, "value" | "defaultValue" | "onChange">;

export interface UseMultiInputRangeFieldParams<
  TSharedProps extends {},
  TTextFieldSlotProps extends {},
> {
  sharedProps: TSharedProps;
  startTextFieldProps: TTextFieldSlotProps;
  startInputRef?: React.Ref<HTMLInputElement>;
  endTextFieldProps: TTextFieldSlotProps;
  endInputRef?: React.Ref<HTMLInputElement>;
}

export type UseMultiInputDateTimeRangeFieldParams<
  TDate,
  TTextFieldSlotProps extends {},
> = UseMultiInputRangeFieldParams<UseMultiInputDateTimeRangeFieldProps<TDate>, TTextFieldSlotProps>;

export const useDefaultizedDateTimeFormat = <TDate>(
  props: Pick<UseMultiInputDateTimeRangeFieldProps<TDate>, "format" | "ampm">
): string => {
  const utils = useUtils<TDate>();

  const ampm = props.ampm ?? utils.is12HourCycleInCurrentLocale();
  const defaultFormat = ampm
    ? utils.formats.keyboardDateTime12h
    : utils.formats.keyboardDateTime24h;

  return props.format ?? defaultFormat;
};

export type DateTimeRangeMinMaxPropsKeys = ExtractStrict<
  keyof UseMultiInputDateTimeRangeFieldProps<any>,
  "minDateTime" | "maxDateTime" | "minDate" | "maxDate" | "minTime" | "maxTime"
>;

export const useDefaultizedDateTimeMinMaxProps = <TDate>(
  props: Pick<UseMultiInputDateTimeRangeFieldProps<TDate>, DateTimeRangeMinMaxPropsKeys>
): Pick<
  UseMultiInputDateTimeRangeFieldDefaultizedProps<TDate, {}>,
  DateTimeRangeMinMaxPropsKeys | "disableIgnoringDatePartForTimeValidation"
> => {
  const utils = useUtils<TDate>();
  const defaultDates = useDefaultDates<TDate>();

  return {
    ...props,
    minDate: applyDefaultDate(utils, props.minDateTime ?? props.minDate, defaultDates.minDate),
    maxDate: applyDefaultDate(utils, props.maxDateTime ?? props.maxDate, defaultDates.maxDate),
    minTime: props.minDateTime ?? props.minTime,
    maxTime: props.maxDateTime ?? props.maxTime,
    disableIgnoringDatePartForTimeValidation: Boolean(props.minDateTime || props.maxDateTime),
  };
};

/// DEFAULTIZE INPUT PROPS HOOK
// NOTE: same defaults as in useDateTimeField
const useDefaultizedDateTimeRangeFieldProps = <TDate, AdditionalProps extends {}>(
  props: UseMultiInputDateTimeRangeFieldProps<TDate>
): UseMultiInputDateTimeRangeFieldDefaultizedProps<TDate, AdditionalProps> => {
  const format = useDefaultizedDateTimeFormat<TDate>(props);

  const minMaxProps = useDefaultizedDateTimeMinMaxProps<TDate>(props);

  return {
    ...props,
    ...minMaxProps,
    disablePast: props.disablePast ?? false,
    disableFuture: props.disableFuture ?? false,
    format,
  } as any;
};

interface UseMultiInputRangeFieldResponse<TForwardedProps extends {}> {
  startDate: UseFieldResponse<TForwardedProps>;
  endDate: UseFieldResponse<TForwardedProps>;
}

export const useMultiInputDateTimeRangeField = <TDate, TTextFieldSlotProps extends {}>({
  sharedProps: inSharedProps,
  startTextFieldProps,
  startInputRef,
  endTextFieldProps,
  endInputRef,
}: UseMultiInputDateTimeRangeFieldParams<
  TDate,
  TTextFieldSlotProps
>): UseMultiInputRangeFieldResponse<TTextFieldSlotProps> => {
  const sharedProps = useDefaultizedDateTimeRangeFieldProps<TDate, UseDateTimeFieldProps<TDate>>(
    inSharedProps
  );
  const adapter = useLocalizationContext<TDate>();

  const {
    value: valueProp,
    defaultValue,
    format,
    timezone: timezoneProp,
    onChange,
    disabled,
    readOnly,
  } = sharedProps;

  const {
    value: currentValue,
    handleValueChange,
    timezone,
  } = useControlledValueWithTimezone({
    name: "useMultiInputDateRangeField",
    timezone: timezoneProp,
    value: valueProp,
    defaultValue,
    onChange,
    valueManager: rangeValueManager,
  });

  // TODO: Maybe export utility from `useField` instead of copy/pasting the logic
  const buildChangeHandler =
    (index: 0 | 1): FieldChangeHandler<TDate | null, DateTimeValidationError> =>
    (newDate, rawContext) => {
      const newDateRange: DateRange<TDate> =
        index === 0 ? [newDate, currentValue[1]] : [currentValue[0], newDate];

      const context: FieldChangeHandlerContext<DateTimeRangeValidationError> = {
        ...rawContext,
        validationError: validateDateTimeRange({
          adapter,
          value: newDateRange,
          props: { ...sharedProps, timezone },
        }),
      };

      handleValueChange(newDateRange, context);
    };

  const handleStartDateChange = useEventCallback(buildChangeHandler(0));
  const handleEndDateChange = useEventCallback(buildChangeHandler(1));

  const validationError = useValidation<
    DateRange<TDate>,
    TDate,
    DateTimeRangeValidationError,
    DateTimeRangeComponentValidationProps<TDate>
  >(
    { ...sharedProps, value: currentValue, timezone },
    validateDateTimeRange,
    rangeValueManager.isSameError,
    rangeValueManager.defaultErrorState
  );

  const startFieldProps: UseDateTimeFieldComponentProps<
    TDate,
    UseDateTimeFieldDefaultizedProps<TTextFieldSlotProps>
  > = {
    error: !!validationError[0],
    ...startTextFieldProps,
    format,
    disabled,
    readOnly,
    timezone,
    value: valueProp === undefined ? undefined : valueProp[0],
    defaultValue: defaultValue === undefined ? undefined : defaultValue[0],
    onChange: handleStartDateChange,
  };

  const endFieldProps: UseDateTimeFieldComponentProps<
    TDate,
    UseDateTimeFieldDefaultizedProps<TTextFieldSlotProps>
  > = {
    error: !!validationError[1],
    ...endTextFieldProps,
    format,
    disabled,
    readOnly,
    timezone,
    value: valueProp === undefined ? undefined : valueProp[1],
    defaultValue: defaultValue === undefined ? undefined : defaultValue[1],
    onChange: handleEndDateChange,
  };

  const startDateResponse = useDateTimeField({
    props: startFieldProps,
    inputRef: startInputRef,
  }) as UseFieldResponse<TTextFieldSlotProps>;

  const endDateResponse = useDateTimeField({
    props: endFieldProps,
    inputRef: endInputRef,
  }) as UseFieldResponse<TTextFieldSlotProps>;

  return { startDate: startDateResponse, endDate: endDateResponse };
};

export type UseValidateDateTimeRangeParams<TDate> = Omit<
  UseMultiInputRangeFieldParams<UseMultiInputDateTimeRangeFieldProps<TDate>, {}>["sharedProps"],
  "onError" | "onChange"
>;

export const useValidateDateTimeRange = <TDate>(props: UseValidateDateTimeRangeParams<TDate>) => {
  const adapter = useLocalizationContext<TDate>();

  const defaultizedProps = useDefaultizedDateTimeRangeFieldProps<
    TDate,
    UseDateTimeFieldProps<TDate>
  >(props);

  const { value: valueProp, timezone: timezoneProp } = defaultizedProps;

  const { timezone } = useValueWithTimezone({
    timezone: timezoneProp,
    value: valueProp,
    defaultValue: undefined,
    onChange: undefined,
    valueManager: rangeValueManager,
  });

  const validateRange = useEventCallback((value: DateRange<TDate>) =>
    validateDateTimeRange({
      adapter,
      value,
      props: { ...defaultizedProps, timezone },
    })
  );
  return validateRange;
};
