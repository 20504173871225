import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
    border-top: ${({ theme }) => theme.borders.solidGray};
  }
`;
