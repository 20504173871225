import { makeAutoObservable } from "mobx";
import { CEXPNLStore } from "src/state/CEX/shared/CEXPNLStore";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export class PNLSummaryV2Store implements IDashboardV2WidgetState {
  pnlState: CEXPNLStore;

  private _stateProvider: IDashboardV2StateProvider;

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBaseDashboardV2StoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;
    this._baseState = new BaseWidgetV2Store({ state: stateProvider, widgetState: this });
    this.pnlState = new CEXPNLStore();
  }

  onStatsUpdate = async () => {
    const { party, selectedRange } = this._stateProvider;

    await this.pnlState.getPNLData({ range: selectedRange, party, withCharts: true });
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
