import { Observer, observer } from "mobx-react-lite";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { fetchBalance } from "src/api/bots/CEX/exchange";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { LabeledInput, LabeledPercentRangeInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { ModuleListContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { getChangeEventValue, getChangeEventValueNumb } from "src/helpers/forms/inputs";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ModuleConfig } from "src/state/CEX/multiGrid/AddModuleModal";
import { ERRORS_MSGS } from "src/validation-schemas";
import { CreatedModuleProps } from "..";
import { BalancesTable } from "./BalancesTable";
import { ErrorInfo } from "./ErrorInfo";
import * as styles from "./style";

interface ModuleContentProps extends CreatedModuleProps {}

export const ModuleContent = observer(({ module }: ModuleContentProps) => {
  const state = useLateInitContext(ModuleListContext);

  const { control } = useFormContext<ModuleConfig>();

  const accUUID = useWatch({
    control,
    name: "accountUUID",
  });

  const useFetchBalance = () =>
    useQuery([`moduleBalance/${accUUID}`, accUUID], () => fetchBalance({ account_uuid: accUUID }), {
      initialData: {
        isError: false,
        data: {
          data: [],
        },
      },
      enabled: Boolean(accUUID),
    });

  const balanceQuery = useFetchBalance();

  state.setUpdBalanceCb(balanceQuery.refetch);

  if (balanceQuery.isSuccess) {
    const requestData = balanceQuery.data;

    const {
      isError,
      data: { data: balances },
    } = requestData;

    return (
      <styles.Container>
        <styles.LeftModuleContent>
          <Controller
            name="amountDecimals"
            rules={{ required: ERRORS_MSGS.isRequired }}
            control={control}
            render={({ field: { value, onChange, ref }, formState: { errors } }) => (
              <LabeledInput
                type="number"
                label="Amount Decimals"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e))}
                inputRef={ref as any}
                errorHint={errors.amountDecimals?.message}
              />
            )}
          />

          <Controller
            name="priceDecimals"
            rules={{ required: ERRORS_MSGS.isRequired }}
            control={control}
            render={({ field: { value, onChange, ref }, formState: { errors } }) => (
              <LabeledInput
                type="number"
                label="Price Decimals"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e))}
                inputRef={ref as any}
                errorHint={errors.priceDecimals?.message}
              />
            )}
          />

          <ErrorInfo exchange={module.id} errors={module.errors} />
        </styles.LeftModuleContent>

        <styles.RightModuleContent>
          <Controller
            name="accountUUID"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <Observer>
                {() => (
                  <LabeledSelector
                    label="Account"
                    options={state.accountsState.getSelectAccounts(module.id)}
                    onChange={(data) => onChange(data?.value)}
                    value={state.accountsState.getSelectAccount(module.id, value)}
                    errorHint={errors.accountUUID?.message}
                  />
                )}
              </Observer>
            )}
          />

          <Controller
            name="makerFee"
            control={control}
            rules={{ required: ERRORS_MSGS.isRequired }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <LabeledPercentRangeInput
                label="Maker Fee %"
                max="1"
                step="0.01"
                value={value}
                onChange={(e) => onChange(getChangeEventValueNumb(e))}
                errorHint={errors.makerFee?.message}
              />
            )}
          />

          <BalancesTable
            data={!isError ? balances : []}
            loading={balanceQuery.isFetching}
            selectAssets={module.pair.split("_")}
          />
        </styles.RightModuleContent>
      </styles.Container>
    );
  }

  return <ErrorBoundary />;
});
