import { ApiModules, apiUrl } from "src/api";
import { buildQueryUrl } from "src/helpers/url";
import { Mapper } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import ResponseHandler from "src/state/network/ResponseHandler";
import { DEXV2VaultType } from "./create";
import { RangeQueryParams, StatsWalletType } from "./stats";

export interface GetBotBalanceHistoryRequestParams extends RangeQueryParams {}

export interface BalanceHistory extends ChartPoint {}

export type GetBotBalanceHistoryResponse = {
  base: BalanceHistory[];
  native: BalanceHistory[];
  quote: BalanceHistory[];
};

export const getBotBalanceHistory = async (
  deployerId: string,
  params?: GetBotBalanceHistoryRequestParams
) => {
  const url = buildQueryUrl(`/dex/bot/${deployerId}/point/balance`, params);
  return ResponseHandler.handler<GetBotBalanceHistoryResponse>({
    url: apiUrl(ApiModules.Empty, url),
    method: "GET",
  });
};

export interface PaginationQueryParams {
  offset: string;
  page_num: string;
}

export interface BalanceHistoryQueryParams {
  pagination?: PaginationQueryParams;
  range?: RangeQueryParams;
}
interface BaseBalanceHistoryQueryParams
  extends Partial<PaginationQueryParams>,
    Partial<RangeQueryParams> {}

const balanceHistoryParamsToBaseParams: Mapper<
  BalanceHistoryQueryParams | undefined,
  BaseBalanceHistoryQueryParams | undefined
> = (params) => {
  if (!params) return undefined;
  const { pagination, range } = params;
  return { ...pagination, ...range };
};

export interface WalletHistoryHash extends VaultHistoryHash {
  wallet_type: StatsWalletType;
  wallet_id: string;
}

export interface GetWalletHistoryQueryParams extends BalanceHistoryQueryParams {
  type?: StatsWalletType;
}

export interface GetWalletHistoryResponse {
  history: WalletHistoryHash[];
  total_pages: number;
}

export const getWalletHistory = async (
  deployerId: string,
  params?: GetWalletHistoryQueryParams
) => {
  const historyParams = balanceHistoryParamsToBaseParams(params);
  const queryParams = {
    ...historyParams,
    type: params?.type,
  };

  const url = buildQueryUrl(`${deployerId}/history/wallet`, queryParams);
  return ResponseHandler.handler<GetWalletHistoryResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, url),
    method: "GET",
  });
};

export interface GetDeployerHistoryQueryParams extends BalanceHistoryQueryParams {}
export interface GetDeployerHistoryResponse extends GetWalletHistoryResponse {}

export const getDeployerHistory = async (
  deployerId: string,
  params?: GetDeployerHistoryQueryParams
) => getWalletHistory(deployerId, { type: "deployer", ...params });

export interface VaultHistoryHash {
  type: string;
  date: number;
  page_num: number;
  trx_hash: string;
  status: boolean;
  error: string;
}

type HistoryVaultType = Exclude<DEXV2VaultType, "main">;

interface GetVaultBalanceHistoryQueryParams extends BalanceHistoryQueryParams {}

type GetVaultBalanceHistoryResponse<T extends HistoryVaultType> = {
  [key in `${T}_history`]: {
    history: VaultHistoryHash[];
    total_pages: number;
  };
};

const getVaultBalanceHistory = async <T extends HistoryVaultType>(
  deployerId: string,
  type: T,
  params?: GetVaultBalanceHistoryQueryParams
) => {
  const historyParams = balanceHistoryParamsToBaseParams(params);
  const queryParams = {
    type,
    ...historyParams,
  };

  const url = buildQueryUrl(`${deployerId}/history/vault`, queryParams);

  return ResponseHandler.handler<GetVaultBalanceHistoryResponse<T>>({
    url: apiUrl(ApiModules.DEXV2Bot, url),
    method: "GET",
  });
};

export interface GetVolumeVaultHistoryQueryParams extends BalanceHistoryQueryParams {}

export interface GetVolumeVaultHistoryResponse extends GetVaultBalanceHistoryResponse<"volume"> {}

export const getVolumeVaultHistory = async (
  deployerId: string,
  params?: GetVolumeVaultHistoryQueryParams
) => getVaultBalanceHistory(deployerId, "volume", params);

export interface GetLimitVaultHistoryQueryParams extends BalanceHistoryQueryParams {}

export interface GetLimitVaultHistoryResponse extends GetVaultBalanceHistoryResponse<"limit"> {}

export const getLimitVaultHistory = async (
  deployerId: string,
  params?: GetLimitVaultHistoryQueryParams
) => getVaultBalanceHistory(deployerId, "limit", params);

export interface GetCounterVaultHistoryQueryParams extends BalanceHistoryQueryParams {}

export interface GetCounterVaultHistoryResponse extends GetVaultBalanceHistoryResponse<"counter"> {}

export const getCounterVaultHistory = async (
  deployerId: string,
  params?: GetCounterVaultHistoryQueryParams
) => getVaultBalanceHistory(deployerId, "counter", params);
