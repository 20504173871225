import { observer } from "mobx-react-lite";
import { useFormContext } from "react-hook-form";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { SaveButton } from "src/components/shared/Buttons/v2/variants/SaveButton";
import { Headline3 } from "src/components/shared/Typography";
import { TickersContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ITickerGroup } from "src/state/UserManager/EditParty/TickersStore";
import { Wrapper } from "../shared";
import * as styles from "./style";

export interface TickerHeaderProps {
  formID: string;
}

export const TickerHeader = observer(({ formID }: TickerHeaderProps) => {
  const state = useLateInitContext(TickersContext);

  const {
    handleSubmit,
    reset,
    formState: { isDirty, defaultValues },
  } = useFormContext<ITickerGroup>();

  return (
    <styles.HeaderWrapper>
      <Wrapper>
        <Headline3>Ticker group: {defaultValues?.name}</Headline3>
        <SaveButton
          form={formID}
          saved={!isDirty}
          onClick={handleSubmit(state.editTicker(reset))}
        />
      </Wrapper>

      <DeleteButton
        onClick={state.removeHandler(defaultValues?.name || "", defaultValues?.uuid || "")}
      />
    </styles.HeaderWrapper>
  );
});
