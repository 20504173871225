import { rgba } from "polished";
import { hexToRgb } from "src/helpers/colors";
import styled from "styled-components";

interface CheckBoxProps {
  disabled?: boolean;
}

export const WrapperForCheckbox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.smallHeader};
`;

export const Label = styled.label<CheckBoxProps>`
  position: relative;
  display: block;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radii["2px"]};

  width: 18px;
  height: 18px;

  background: ${({ theme, disabled }) =>
    disabled ? theme.checkBoxBg : hexToRgb(theme.checkBoxBg, 0.1)};
  border-radius: ${({ theme }) => theme.radii["2px"]};

  /* box-shadow: ${({ theme }) => `0 0 0 1px ${theme.checkBoxBg}`}; */
  border: ${({ theme }) => `1px solid ${theme.checkBoxBg}`};
`;

export const LabelText = styled.label`
  cursor: pointer;

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};

  margin-right: 8px;
  margin-left: 8px;

  color: currentColor;
`;

export const CheckBox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  z-index: -1;
  opacity: 0;

  :checked + ${Label} {
    background: ${({ theme }) => theme.primaryColor};
    box-shadow: 0px 0px 0px 3px ${({ theme }) => rgba(theme.primaryColor, 0.14)};
    border-radius: ${({ theme }) => theme.radii["2px"]};
  }

  :checked + ${Label}::before {
    position: absolute;
    content: "";
    width: 4px;
    height: 6px;
    left: 5px;
    top: 3px;
    border-bottom: solid;
    border-right: solid;
    border-width: 2px;
    border-color: #fff;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all 0.5s;
  }

  :disabled + ${Label} {
    opacity: 0.5;
  }
`;
