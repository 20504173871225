import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from "mobx";
import { getAnalytic, getPairAnalytics } from "src/api/bots/CEX/analytics";
import { getCandles } from "src/api/bots/CEX/exchange";
import {
  EntityId,
  ErrorCallback,
  HistoryCallback,
  IChartingLibraryWidget,
  LibrarySymbolInfo,
  OnReadyCallback,
  Overrides,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
} from "src/charting_library/charting_library";
import { SHOW_ICON_STR } from "src/components/BotsContent/CEX/Exchange/ChartPanel/TVChartContainer/showIcon";
import { hexToRgb } from "src/helpers/colors";
import { getCurrentUnix, stringDateToUnix } from "src/helpers/dateUtils";
import { logError } from "src/helpers/network/logger";
import { ListAnalytic } from "src/modules/analytics";
import { CreatedLimitOrder } from "src/modules/exchange/trade";
import { OurOrder } from "../OrderBook/OrderBookStore";
import StreamingStore from "./StreamingStore";

// const lastBarsCache = new Map<string, Bar>();

const createResolution = (s: string) => s as ResolutionString;

const RESOLUTION: ResolutionString[] = [
  createResolution("1"),
  createResolution("5"),
  createResolution("15"),
  createResolution("30"),
  createResolution("60"),
  createResolution("240"),
  createResolution("720"),
  createResolution("1D"),
  createResolution("1W"),
  createResolution("1M"),
];

const configurationData = {
  supported_resolutions: RESOLUTION,
  exchanges: [],
  symbols_types: [],
};

class DatafeedStore {
  private _currentResolution: ResolutionString = createResolution("5");

  ourOrders: OurOrder[] = [];

  private _ourOrdersID: EntityId[] = [];

  private _pairAnalytic: ListAnalytic[] = [];

  defaultUUID: string = "";

  accountHistory: CreatedLimitOrder[] = [];

  private _accHistoryIDs: EntityId[] = [];

  private _pairChangeReaction?: IReactionDisposer;

  private _analyticNameEl: HTMLElement | undefined;

  _overrides: Overrides | undefined;

  selectAnalytic: string = "";

  tvWidget: React.RefObject<IChartingLibraryWidget>;

  isLoading: boolean = false;

  chartCreated = false;

  showOrdersState = {
    all: true,
    buy: true,
    sell: true,
  };

  pricePrecision = 4;

  streamingState;

  constructor(tvWidgetRef: React.RefObject<IChartingLibraryWidget>, state: StreamingStore) {
    this.tvWidget = tvWidgetRef;
    this.streamingState = state;

    this.streamingState.setFetchUpd(this.fetchUpdData);

    makeAutoObservable(this, { tvWidget: false });
  }

  private get _party() {
    return this.streamingState.party;
  }

  get botUUID() {
    return this.streamingState.botUUID;
  }

  get pair() {
    return this.streamingState.pair;
  }

  get exchange() {
    return this.streamingState.exchange;
  }

  get isOurOrders() {
    return this._ourOrdersID.length;
  }

  get allOrdersHide() {
    return this.showOrdersState.buy && this.showOrdersState.sell;
  }

  get defaultAnalytic() {
    if (!this._pairAnalytic.length) return undefined;

    return this._pairAnalytic[0].analytics.find((el) => el.uuid === this.defaultUUID);
  }

  get currentSubscriberUID() {
    return `${this.streamingState.channelString}_#_${this._currentResolution}`;
  }

  private get _lastTimeUpd() {
    const subscriptionItem = this.streamingState.channelToSubscription.get(
      this.streamingState.channelString
    );

    if (subscriptionItem !== undefined) {
      const subscriberUID = this.currentSubscriberUID;

      const subscriber = subscriptionItem.get(subscriberUID);

      if (subscriber) {
        const lastBar = subscriber.lastDailyBar;

        if (lastBar) {
          const lastCandleTime = Math.floor(lastBar.time / 1000);

          const nowTime = getCurrentUnix();

          if (lastCandleTime < nowTime) {
            return lastCandleTime;
          }
          return nowTime;
        }
      }
    }

    return 0;
  }

  subscribe = () => {
    this._pairChangeReaction = reaction(
      () => this.pair,
      (pair) => {
        this.changePair(pair);
      }
    );
  };

  unsubscribe = () => {
    this._pairChangeReaction?.();
  };

  setOurOrders = (sellOrders: OurOrder[], buyOrders: OurOrder[]) => {
    this.ourOrders = [];
    this.ourOrders = [...sellOrders, ...buyOrders];
  };

  setAccHistory = (orders: CreatedLimitOrder[]) => {
    this.accountHistory = [];
    this.accountHistory = orders;
  };

  setChartCreated = (bool: boolean) => {
    this.chartCreated = bool;
  };

  setPricePrecision = (precision: number) => {
    this.pricePrecision = precision;

    this.priceScaleRounding();
  };

  setOverrides = (overrides: Overrides) => {
    this._overrides = overrides;
  };

  onReady = (callback: OnReadyCallback) => {
    // console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
  };

  resolveSymbol = async (
    symbolName: string,
    onSymbolResolvedCallback: ResolveCallback,
    onResolveErrorCallback: ErrorCallback
  ) => {
    // const [bot_name, pair] = symbolName.split("|");

    const symbolItem = {
      full_name: symbolName,
      symbol: symbolName,
      exchange: this.exchange,
    };

    const symbolInfo: LibrarySymbolInfo = {
      ticker: symbolItem.full_name,
      full_name: symbolName,
      name: symbolName,
      description: "",
      type: "crypto",
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: "",
      listed_exchange: "",
      format: "price",
      minmov: 1,
      pricescale: 100000000,
      has_intraday: true,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 8,
      data_status: "streaming",
      has_empty_bars: true,
      // pair: symbolItem.full_name,
    };

    // console.log("[resolveSymbol]: Symbol resolved", symbolName);

    setTimeout(() => {
      onSymbolResolvedCallback(symbolInfo);
      // console.log("Resolving that symbol....", symbolInfo);
    }, 0);
  };

  getBars = async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback
  ) => {
    if (!this.botUUID) return;

    const { from, to, firstDataRequest, countBack } = periodParams;

    // console.log("[getBars]: Method call", symbolInfo, resolution);

    if (firstDataRequest)
      runInAction(() => {
        this.isLoading = true;
      });

    runInAction(() => {
      this._currentResolution = resolution;
    });

    let fromData = from;

    const sixMonthAgo = to - 15552000;

    // condition to prevent requests for more than 6 months
    if (from < sixMonthAgo) {
      fromData = sixMonthAgo;
    }

    try {
      const {
        data: { data: bars = [] },
        isError,
      } = await getCandles(
        this.exchange,
        this.pair,
        // from,
        fromData,
        to,
        resolution,
        countBack,
        this.streamingState.infoAcc
      );

      if (isError || !bars.length) {
        // "noData" should be set if there is no data in the requested period.
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }

      // this._setTimeLastCandle(bars);

      if (firstDataRequest) {
        this.streamingState.lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }

      // console.log(`[getBars]: returned ${bars.length} bar(s)`);

      if (bars.length) {
        onHistoryCallback(bars, {
          noData: false,
        });
      } else {
        onHistoryCallback(bars, {
          noData: true,
        });
      }
    } catch (error) {
      onErrorCallback(String(error));
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  subscribeBars = (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    subscriberUID: string,
    onResetCacheNeededCallback: () => void
  ) => {
    // console.log("=====subscribeBars running");

    const lastBar = this.streamingState.lastBarsCache.get(symbolInfo.full_name);

    if (!lastBar) return;

    this.streamingState.subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      // fix for old strategies where the exchange symbol is used
      // subscriberUID,
      this.currentSubscriberUID,
      onResetCacheNeededCallback,
      lastBar
    );
  };

  unsubscribeBars = (subscriberUID: string) => {
    // console.log("=====unsubscribeBars running");

    this.streamingState.unsubscribeFromStream(subscriberUID);
  };

  changePair = (pair: string) => {
    const widget = this.tvWidget.current;

    if (widget) {
      widget.onChartReady(() => {
        if (widget) widget.setSymbol(pair, this._currentResolution, () => {});
      });

      this._changePairAnalytic();
    }
  };

  fetchUpdData = async () => {
    this.updateData();
  };

  updateData = async () => {
    if (!this._lastTimeUpd) return;

    runInAction(() => {
      this.isLoading = true;
    });

    const nowTime = getCurrentUnix();

    try {
      const {
        isError,
        data: { data: bars },
      } = await getCandles(
        this.exchange,
        this.pair,
        this._lastTimeUpd,
        nowTime,
        this._currentResolution,
        undefined,
        this.streamingState.infoAcc
      );

      if (isError) return;

      if (bars.length) {
        for (const bar of bars) {
          this.streamingState.setLastDailyBar(
            this.streamingState.channelString,
            this._currentResolution,
            bar
          );
        }
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  hideOurOrders = (typeOrder: "buy" | "sell" | "all") => {
    const chart = this.tvWidget.current?.activeChart();

    if (!chart) return;

    this.toggleShowState(typeOrder);

    for (const id of this._ourOrdersID) {
      const shapeProp = chart.getShapeById(id).getProperties();

      // const stateOrder = this.showOrdersState[shapeProp.title]

      if (typeOrder === "all") {
        chart.getShapeById(id).setProperties({
          visible: this.showOrdersState[typeOrder],
        });
      } else if (shapeProp.title === typeOrder) {
        chart.getShapeById(id).setProperties({
          visible: this.showOrdersState[typeOrder],
        });
      }
    }
  };

  toggleShowState = (typeOrder: "buy" | "sell" | "all") => {
    if (typeOrder === "all") {
      const allState = !this.showOrdersState.all;

      this.showOrdersState.all = allState;
      this.showOrdersState.buy = allState;
      this.showOrdersState.sell = allState;
    } else {
      this.showOrdersState[typeOrder] = !this.showOrdersState[typeOrder];
      this.showOrdersState.all = this.allOrdersHide;
    }
  };

  createShowHideOrders = () => {
    const tvWidget = this.tvWidget.current;

    if (!tvWidget) return;

    tvWidget.createDropdown({
      title: "Hide",
      tooltip: "Display of our orders",
      items: [
        {
          title: "All orders",
          onSelect: () => {
            this.hideOurOrders("all");
          },
        },
        {
          title: "Buy orders",
          onSelect: () => {
            this.hideOurOrders("buy");
          },
        },
        {
          title: "Sell orders",
          onSelect: () => {
            this.hideOurOrders("sell");
          },
        },
      ],
      icon: SHOW_ICON_STR,
    });
  };

  highlightOurOrders = () => {
    if (!this.tvWidget.current) return;

    this.tvWidget.current.onChartReady(() => {
      const chartWidget = this.tvWidget.current?.activeChart();

      if (!chartWidget) return;

      this.resetShowOrders();

      for (const order of this.ourOrders) {
        if (parseFloat(order.total) < 0) continue;

        const shapeID = chartWidget.createMultipointShape(
          [{ price: parseFloat(order.price), time: order.time || 0 }],
          { shape: "horizontal_ray" }
        );

        if (shapeID) {
          this._ourOrdersID.push(shapeID);

          chartWidget.getShapeById(shapeID).setProperties({
            linecolor: order.side === "buy" ? hexToRgb("#69B40A", 0.5) : hexToRgb("#D84E4E", 0.5),
            title: order.side,
            text: order.total,
            showLabel: true,
            frozen: true,
            fontsize: "9",
            horzLabelsAlign: "right",
            showPrice: false,
          });

          // console.log(this.chartWidget.getShapeById(shapeID).getProperties());
        }
      }
    });
  };

  highlightAccountHistory = () => {
    if (!this.tvWidget.current) return;

    this.tvWidget.current.onChartReady(() => {
      const chartWidget = this.tvWidget.current?.activeChart();

      if (!chartWidget) return;

      // this.resetShowOrders();

      for (const order of this.accountHistory) {
        const shapeID = chartWidget.createShape(
          {
            price: parseFloat(order.price),
            time: stringDateToUnix(order.time) || 0,
          },
          { shape: order.side === "BUY" ? "arrow_up" : "arrow_down" }
        );

        if (shapeID) {
          this._accHistoryIDs.push(shapeID);

          chartWidget.getShapeById(shapeID).setProperties({
            title: order.side,
            text: order.amount,
            showLabel: true,
            frozen: true,
            fontsize: "9",
          });

          // console.log(this.chartWidget.getShapeById(shapeID).getProperties());
        }
      }
    });
  };

  getPairAnalytic = async () => {
    try {
      const { isError, data } = await getPairAnalytics(this._party, this.pair);

      if (!isError) {
        if (data.length) {
          runInAction(() => {
            this._pairAnalytic = data;
          });
          runInAction(() => {
            this.defaultUUID = this._pairAnalytic[0].default;
          });
        }
      }
    } catch (error) {
      logError(error);
    }
  };

  getDefaultAnalytic = async (uuid: string): Promise<string> => {
    try {
      const { isError, data } = await getAnalytic(uuid);

      if (!isError) {
        runInAction(() => {
          this.selectAnalytic = data.name;
        });
        this._setSelectAnalyticName(this.selectAnalytic);

        return data.content;
      }

      return "";
    } catch {
      return "";
    }
  };

  removeOurOrders = () => {
    this.removeShapes(this._ourOrdersID, this.clearOurOrdersID);
  };

  removeAccHistory = () => {
    this.removeShapes(this._accHistoryIDs, this.clearOurOrdersID);
  };

  removeShapes = (ids: EntityId[], afterCb: () => void) => {
    if (!this.tvWidget.current) return;

    this.tvWidget.current.onChartReady(() => {
      const chartWidget = this.tvWidget.current?.activeChart();

      if (!chartWidget) return;

      for (const id of ids) {
        chartWidget?.removeEntity(id);
      }

      afterCb();
    });
  };

  clearOurOrdersID = () => {
    this._ourOrdersID = [];
    this._accHistoryIDs = [];
  };

  resetShowOrders = () => {
    this.showOrdersState.all = true;
    this.showOrdersState.buy = true;
    this.showOrdersState.sell = true;
  };

  toggleChartTheme = () => {
    const widget = this.tvWidget.current;

    if (!widget) return;

    widget.onChartReady(() => {
      if (!widget) return;

      const theme = localStorage.getItem("theme") === "dark" ? "Dark" : "Light";

      widget.addCustomCSSFile(theme === "Dark" ? "/darkTV.css" : "/lightTV.css");

      widget.changeTheme(theme).then(() => {
        if (this._overrides && this.tvWidget.current)
          this.tvWidget.current.applyOverrides(this._overrides);
      });
    });
  };

  private _changePairAnalytic = async () => {
    this._resetAnalyticsName();
    this._resetAnalyticsUUID();

    await this.getPairAnalytic();

    this.loadDefaultAnalytic();
  };

  priceScaleRounding = () => {
    if (this.tvWidget.current)
      this.tvWidget.current.applyOverrides({
        "mainSeriesProperties.minTick": `${10 ** this.pricePrecision},1,false`,
      });
  };

  createAnalyticNameBtn = () => {
    const widget = this.tvWidget.current;

    this._analyticNameEl = widget?.createButton();

    if (this._analyticNameEl) {
      this._analyticNameEl.onclick = () => {};
    }
  };

  private _setSelectAnalyticName = (name: string) => {
    if (this._analyticNameEl) {
      this._analyticNameEl.innerHTML = name;
    }
  };

  private _resetAnalyticsName = () => {
    this._setSelectAnalyticName("");
  };

  private _resetAnalyticsUUID = () => {
    this.defaultUUID = "";
  };

  loadDefaultAnalytic = () => {
    const widget = this.tvWidget.current;

    if (!this.defaultAnalytic?.uuid) return;

    const data = {
      ...this.defaultAnalytic,
      id: this.defaultAnalytic?.uuid,
    };

    widget?.subscribe("chart_load_requested", () => {
      this.priceScaleRounding();
      this.toggleChartTheme();
    });

    widget?.subscribe("panes_order_changed", () => {
      this.clearOurOrdersID();
      this.highlightOurOrders();
    });

    widget?.loadChartFromServer(data as any);
  };

  destroy = () => {
    this.streamingState.unbindProcessing();
  };
}

export default DatafeedStore;
