import { observer } from "mobx-react-lite";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { ExchangeAccountingContext } from "src/context/CEX/ExchangeAccounting";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DownloadButton } from "./DownloadButton";
import { SpreadsheetButton } from "./SpreadsheetButton";
import * as styles from "./style";

export interface ButtonsRowProps {}

export const ButtonsRow = observer((props: ButtonsRowProps) => {
  const state = useLateInitContext(ExchangeAccountingContext.Context);

  return (
    <styles.ButtonsRow>
      <styles.DownloadButtonsWrapper>
        <DownloadButton
          onDownloadOriginal={state.downloadFullCSV}
          onDownloadFiltered={state.downloadFilteredCSV}
          text="Download in CSV"
        />
        <SpreadsheetButton />
      </styles.DownloadButtonsWrapper>

      <styles.RefreshButtonWrapper>
        <ReloadButton onClick={state.refreshExchangesAccounting} />
        <styles.CountText>
          Shown: <strong>{state.selectedCount}</strong>
        </styles.CountText>
      </styles.RefreshButtonWrapper>
    </styles.ButtonsRow>
  );
});
