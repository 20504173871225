import { useMemo } from "react";
import { SetRequiredU } from "src/helpers/utils";
import { GraphRootProps } from "..";
import { SeriesProps } from "../types/series";

interface UseDefaultizedSeriesPropsParams extends Pick<GraphRootProps, "series"> {}

type DefaultizedSeriesProps = SetRequiredU<SeriesProps, "title" | "side" | "id">;

/**
 * Custom hook that applies default values to series props.
 *
 * @param props - The series props to be defaultized.
 * @returns The defaultized series props.
 */
export const useDefaultizedSeriesProps = ({
  series: seriesProps = [],
}: UseDefaultizedSeriesPropsParams): DefaultizedSeriesProps[] => {
  const defaultizedSeriesProps = useMemo(
    () =>
      seriesProps.map((series, index) => {
        const id = series.id || `${index}`;
        const defaultSide = index === 0 || index > 1 ? "left" : "right";
        const side = series.side ?? defaultSide;
        const title = series.title ?? `line${index}`;
        const color =
          series.type === "Area" || series.type === "Line"
            ? { color: series.color ?? "primaryColor" }
            : {};
        return { ...series, title, side, id, ...color };
      }),
    [seriesProps]
  );

  return defaultizedSeriesProps;
};
