import {
  BalanceHistoryQueryParams,
  GetCounterVaultHistoryResponse,
  GetLimitVaultHistoryResponse,
  GetVolumeVaultHistoryResponse,
  VaultHistoryHash,
  getCounterVaultHistory,
  getLimitVaultHistory,
  getVolumeVaultHistory,
} from "src/api/bots/DEXV2/history";
import { unixToDateFormat } from "src/helpers/dateUtils";
import { Mapper } from "src/helpers/utils";
import { TransactionState, TransactionStatus, createTransactionStatus } from "src/modules/bots";
import {
  HashesPaginationHistoryFetcher,
  HashesPaginationResponse,
  HashesResponseMap,
  IHashesPaginationFetcherProvider,
} from ".";
import { VaultHashType } from "../../HashesTypeStore";

export type VaultsResponse = HashesResponseMap[VaultHashType];

export const transactionHashToStatus = (status: boolean, error?: string): TransactionStatus => {
  if (status || !error) {
    return createTransactionStatus(TransactionState.Success, {});
  }
  if (error.toLowerCase() === "pending") {
    return createTransactionStatus(TransactionState.Pending, {});
  }
  return createTransactionStatus(TransactionState.Error, { message: error });
};

export interface VaultsPaginationResponse<T extends VaultHashType>
  extends HashesPaginationResponse<T> {}

const vaultHistoryHashToTransactionHash: Mapper<VaultHistoryHash, VaultsResponse> = ({
  trx_hash,
  date,
  type,
  error,
  status,
}) => ({
  type,
  hash: trx_hash,
  date: unixToDateFormat(date, "FullDate"),
  status: transactionHashToStatus(status, error),
});

export const limitHistoryRespToHistoryHash: Mapper<
  GetLimitVaultHistoryResponse,
  VaultsPaginationResponse<"limit">
> = (resp) => {
  const { history, total_pages } = resp.limit_history;
  const hashes = history.map(vaultHistoryHashToTransactionHash);
  return { items: hashes, pagesCount: total_pages, type: "limit" };
};

export const volumeHistoryRespToHistoryHash: Mapper<
  GetVolumeVaultHistoryResponse,
  VaultsPaginationResponse<"volume">
> = (resp) => {
  const { history, total_pages } = resp.volume_history;
  const hashes = history.map(vaultHistoryHashToTransactionHash);
  return { items: hashes, pagesCount: total_pages, type: "volume" };
};

export const counterHistoryRespToHistoryHash: Mapper<
  GetCounterVaultHistoryResponse,
  VaultsPaginationResponse<"counter">
> = (resp) => {
  const { history, total_pages } = resp.counter_history;
  const hashes = history.map(vaultHistoryHashToTransactionHash);
  return { items: hashes, pagesCount: total_pages, type: "counter" };
};

export class VaultsPaginationFetcherProvider<T extends VaultHashType>
  implements IHashesPaginationFetcherProvider<T>
{
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private _type: T) {}

  private _getLimitHashes = async (uuid: string, params?: BalanceHistoryQueryParams) => {
    const { isError, data } = await getLimitVaultHistory(uuid, params);
    if (!isError) {
      return limitHistoryRespToHistoryHash(data);
    }
  };

  private _getVolumeHashes = async (uuid: string, params?: BalanceHistoryQueryParams) => {
    const { isError, data } = await getVolumeVaultHistory(uuid, params);
    if (!isError) {
      return volumeHistoryRespToHistoryHash(data);
    }
  };

  private _getCounterHashes = async (uuid: string, params?: BalanceHistoryQueryParams) => {
    const { isError, data } = await getCounterVaultHistory(uuid, params);
    if (!isError) {
      return counterHistoryRespToHistoryHash(data);
    }
  };

  private _getHashesFetcher = () => {
    switch (this._type) {
      case "limit": {
        return this._getLimitHashes;
      }
      case "volume": {
        return this._getVolumeHashes;
      }
      case "counter": {
        return this._getCounterHashes;
      }
      default: {
        throw new Error("unknown hashes type");
      }
    }
  };

  getHashesFetcher = () => {
    const fetcher = this._getHashesFetcher() as HashesPaginationHistoryFetcher<T>;

    return fetcher;
  };
}
