import { observer } from "mobx-react-lite";
import { CellProps } from "react-table";
import { DEXV2Vault } from "src/api/bots/DEXV2/create";
import { SwapVaultsContext } from "src/context/DEXV2/DEXV2Swap/Vaults/SwapVaults";
import { ChildrenProps } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

export interface DisabledCellWrapperProps
  extends Pick<CellProps<DEXV2Vault>, "row">,
    ChildrenProps,
    Pick<styles.DisabledCellProps, "disablePointer"> {}

export const DisabledCellWrapper = observer(
  ({ row, children, disablePointer }: DisabledCellWrapperProps) => {
    const state = useLateInitContext(SwapVaultsContext);

    const isDisabled = !state.isSelectedVault(row.original.address);
    return (
      <styles.DisabledCellWrapper isDisabled={isDisabled} disablePointer={disablePointer}>
        {children}
      </styles.DisabledCellWrapper>
    );
  }
);
