import { Time, UTCTimestamp } from "lightweight-charts";
import { computed, makeAutoObservable } from "mobx";
import {
  DEFAULT_FULL_DATE_FORMAT,
  localTimestampToUTC,
  unixToDateFormat,
  unixToUTCFormat,
} from "src/helpers/dateUtils";
import { joinStrings } from "src/helpers/string";
import { IDisposable } from "src/helpers/utils";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { SeriesMap, SeriesTitlesMap } from "../Series/types";
import { MouseEventSeriesData, SeriesDataMap, TooltipSeriesData } from "./types";
import { seriesDataToString, seriesDataToTooltipSeriesData } from "./utils";

export interface ITooltipSeriesDataProvider<T extends TooltipSeriesData> {
  getTooltipData: (seriesData: TooltipSeriesData, time: number) => T;
  seriesToString?: (seriesData: SeriesDataMap<T>, utcTimestamp: number) => string;
}

export interface ITooltipState<T extends TooltipSeriesData> {
  get date(): string;

  get seriesTitles(): string[];

  get seriesDataMap(): SeriesDataMap<T>;

  get seriesDataString(): string;

  updateTooltipData: (
    time: Time,
    seriesMap: SeriesMap,
    seriesTitleMap: SeriesTitlesMap,
    seriesData: MouseEventSeriesData
  ) => void;
}

export class TooltipStore<T extends TooltipSeriesData> implements ITooltipState<T>, IDisposable {
  private _utcTimestamp: number = 0;

  private _seriesDataMap: SeriesDataMap<T> = new Map();

  private _seriesDataProvider: ITooltipSeriesDataProvider<T>;

  constructor(seriesDataProvider: ITooltipSeriesDataProvider<T>) {
    makeAutoObservable(this, {
      seriesTitles: computed({
        equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
      }),
    });

    this._seriesDataProvider = seriesDataProvider;
  }

  private _setUTCTimestamp = (timestamp: number) => {
    this._utcTimestamp = timestamp;
  };

  private _timeToUTCTimestamp = (time: Time) => {
    const utcTimeStamp = localTimestampToUTC(time as UTCTimestamp);
    return utcTimeStamp;
  };

  private _setTimestamp = (time: Time) => {
    const timestamp = this._timeToUTCTimestamp(time);
    this._setUTCTimestamp(timestamp);
  };

  private _timestampToFormatDate = (utcTimestamp: number) => {
    const date = unixToUTCFormat(utcTimestamp, DEFAULT_FULL_DATE_FORMAT);
    return date;
  };

  get date() {
    const timestamp = this._utcTimestamp;
    if (timestamp <= 0) return "";
    return this._timestampToFormatDate(timestamp);
  }

  private _setSeriesDataMap = (data: TooltipSeriesData[]) => {
    const timestamp = this._utcTimestamp;

    this._seriesDataMap.clear();
    data.forEach((series) => {
      const seriesData = this._seriesDataProvider.getTooltipData(series, timestamp);

      this._seriesDataMap.set(series.title, seriesData);
    });
  };

  private _setSeriesData = (
    seriesMap: SeriesMap,
    seriesTitlesMap: SeriesTitlesMap,
    seriesData: MouseEventSeriesData
  ) => {
    const data = Array.from(seriesMap).map(([id, series]) => {
      if (!series) return null;
      const title = seriesTitlesMap.get(id) || "";
      return seriesDataToTooltipSeriesData(title, series.api(), seriesData);
    });

    const filteredData = filterBoolean(data);

    this._setSeriesDataMap(filteredData);
  };

  updateTooltipData = (
    time: Time,
    seriesMap: SeriesMap,
    seriesTitlesMap: SeriesTitlesMap,
    seriesData: MouseEventSeriesData
  ) => {
    const newDateTimestamp = this._timeToUTCTimestamp(time);
    if (newDateTimestamp === this._utcTimestamp) return;

    this._setTimestamp(time);
    this._setSeriesData(seriesMap, seriesTitlesMap, seriesData);
  };

  get seriesTitles() {
    return Array.from(this._seriesDataMap.keys());
  }

  get seriesDataMap() {
    return this._seriesDataMap;
  }

  private _defaultSeriesToString = (seriesMap: SeriesDataMap<T>, timestamp: number) => {
    const dataEntries = Array.from(seriesMap);
    const date = unixToDateFormat(timestamp, "FullDate");

    const seriesData = dataEntries.map(([seriesTitle, data]) =>
      seriesDataToString(seriesTitle, data)
    );

    const seriesString = joinStrings([date, ...seriesData]);
    return seriesString;
  };

  get seriesDataString() {
    const seriesData = this._seriesDataMap;
    const timestamp = this._utcTimestamp;

    const seriesToString = this._seriesDataProvider.seriesToString ?? this._defaultSeriesToString;

    const seriesString = seriesToString(seriesData, timestamp);
    return seriesString;
  }

  destroy = () => {};
}
