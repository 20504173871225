import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useModuleState } from "../hooks/useModuleState";
import { ModulesEditTableProps, ModulesList } from "../layout/ModulesList";
import { ConditionsFilters } from "./ConditionsFilters";
import { ConditionsTable } from "./ConditionsTable";

export interface ConditionsProps extends ModulesEditTableProps {}

export const Conditions = observer(({ editing }: ConditionsProps) => {
  const { getModules, refreshModules } = useModuleState("condition");

  useEffect(() => {
    getModules(0);
  }, [getModules]);

  const Table = <ConditionsTable editing={editing} />;

  const Filters = <ConditionsFilters />;

  return <ModulesList table={Table} filter={Filters} onRefresh={refreshModules} />;
});
