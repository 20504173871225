import { Popup } from "reactjs-popup";
import { usePopupState } from "src/hooks/usePopupState";
import { FormFieldOuterProps } from "../../../Forms/FormField";
import { LabelInputCombinedProps } from "../../../Forms/Inputs";
import { DatePickerIcon } from "./DatePickerIcon";
import { LabeledDateTimeInput, LabeledDateTimeInputOwnProps } from "./LabeledDateTimeInput";
import * as styles from "./style";

export interface DateTimeFieldProps
  extends Omit<LabeledDateTimeInputOwnProps, "after">,
    LabelInputCombinedProps,
    Pick<FormFieldOuterProps, "style" | "className"> {
  picker?: null | JSX.Element;
}

export const DateTimeField = ({ style, className, picker, ...props }: DateTimeFieldProps) => {
  const [showPopup, { onToggle: onPopupToggle, onClose: onPopupClose }] = usePopupState();

  const inputProps = props;

  const Picker = picker;

  const showPicker = Boolean(Picker);

  const PickerIcon = showPicker ? <DatePickerIcon onClick={onPopupToggle} /> : null;

  const Input = <LabeledDateTimeInput {...inputProps} after={PickerIcon} />;

  return (
    <styles.Container>
      {Input}
      {showPicker && (
        <Popup
          trigger={<styles.AnchorDiv />} // using empty div as an anchor for popup
          open={showPopup}
          arrow={false}
          onClose={onPopupClose}
          closeOnDocumentClick
          nested
          position={["bottom center"]}
        >
          {Picker}
        </Popup>
      )}
    </styles.Container>
  );
};
