import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { Loader } from "src/components/shared/Loader";
import { ApiKeysContext } from "src/context/CEX/ApiKeys/ApiKeys";
import { checkParty } from "src/helpers/checkParty";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BotAccountName } from "src/modules/accounts";
import { PermissionProps, PermissionWrapper } from "src/permissions/PermissionWrapper";
import { useAppState } from "src/state";
import { ApiKeysPanel, ApiKeysPanelHeaderProps } from "../../ApiKeysPanel";
import { AccountFormProps } from "../AccountForm";
import { AccountSelector } from "./AccountSelector";
import { BindButtons } from "./BindButtons";
import * as styles from "./style";

export interface AccountProps extends PermissionProps, Omit<AccountFormProps, "onSubmit"> {
  accountName: BotAccountName;
}

export const Account = observer(({ accountName, abilityName, party, ...props }: AccountProps) => {
  const state = useLateInitContext(ApiKeysContext);
  const { userState } = useAppState();

  const { bindings } = state;

  const permissionProps = {
    party,
    abilityName,
  };

  const hasEditPermissions = checkParty(userState.abilities, abilityName, party ?? "");

  const bindAccount = useMemo(() => state.bindAccount(accountName), [accountName, state]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      bindAccount();
    },
    [bindAccount]
  );

  const availableAccounts = bindings.accountBindingSelectorOptions(accountName);

  const Selector = (
    <AccountSelector
      options={availableAccounts}
      onChange={bindings.onAccountBindingSelected(accountName)}
      value={bindings.selectedAccountBinding(accountName)}
      errorHint={state.accountError(accountName)}
      isDisabled={!hasEditPermissions}
      fixed
    />
  );

  const Buttons = (
    <PermissionWrapper {...permissionProps}>
      <BindButtons
        buttons={
          <Button type="submit" size="m" disabled={!state.bindEnabled(accountName)}>
            Bind
          </Button>
        }
      />
    </PermissionWrapper>
  );

  const headerProps: ApiKeysPanelHeaderProps = {
    btn: (
      <PermissionWrapper {...permissionProps}>
        <DeleteButton onClick={state.unbindAccount(accountName)} />
      </PermissionWrapper>
    ),
  };

  return (
    <styles.Form onSubmit={onSubmit} {...props}>
      <ApiKeysPanel
        title={accountName}
        selector={Selector}
        buttons={Buttons}
        headerProps={headerProps}
      />
      <Loader show={state.accountLoading(accountName)} />
    </styles.Form>
  );
});
