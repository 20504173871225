import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
`;

export const RowWrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 27px;
`;

export const GroupWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
`;
