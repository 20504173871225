import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const AccountsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  overflow-x: scroll;
  padding-bottom: 10px;

  ${ScrollSize};
`;

export const VolumeContainer = styled.div`
  width: 100%;
  min-height: 175px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;
