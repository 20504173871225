import { ComponentPropsWithoutRef, MouseEventHandler, useCallback } from "react";
import { OwnRefProps } from "src/helpers/utils";
import * as styles from "./style";

export interface ToggleButtonProps
  extends Omit<ComponentPropsWithoutRef<"button">, keyof styles.ToggleButtonStyleProps>,
    Partial<styles.ToggleButtonStyleProps>,
    OwnRefProps<"button"> {}

export const ToggleButton = ({
  selected = false,
  size = "m",
  elevated = false,
  ownRef,
  children,
  onChange,
  onClick,
  ...props
}: ToggleButtonProps) => {
  const handleChange: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      onClick?.(event);
      onChange?.(event);
    },
    [onChange, onClick]
  );

  return (
    <styles.ToggleButton
      ref={ownRef}
      aria-pressed={selected}
      $selected={selected}
      $size={size}
      $elevated={elevated}
      onClick={handleChange}
      onChange={onChange}
      {...props}
    >
      {children}
    </styles.ToggleButton>
  );
};
