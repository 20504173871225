import { observer } from "mobx-react-lite";
import { SettingsPanelProps } from "src/components/BotsContent/DEX_V2/Settings/shared/SettingsPanel";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { WalletLabeledInput } from "../../../shared/WalletLabeledInput";
import { SideSettingsPanel, WalletsList } from "../../shared";
import { FormContent } from "../style";

export interface TransferWalletsPanelProps extends Omit<SettingsPanelProps, "label" | "children"> {}

export const TransferWalletsPanel = observer((props: TransferWalletsPanelProps) => {
  const { transferWalletsState, errors } = useLateInitContext(DEXV2CreatingContext);

  const saveTransferEnabled = Boolean(transferWalletsState.wallet);
  return (
    <SideSettingsPanel label="Transfer Wallets" {...props}>
      <FormContent>
        <WalletLabeledInput
          label="Transfer Wallet"
          addEnabled={saveTransferEnabled}
          onAdd={transferWalletsState.addWallet}
          value={transferWalletsState.wallet}
          onChange={transferWalletsState.setWallet}
          errorHint={transferWalletsState.walletErrors}
        />

        <WalletsList
          hashes={transferWalletsState.walletsAsFieldProps}
          hashesErrors={errors.base_data?.transfer_oracle_wallets}
          onDeleteHash={transferWalletsState.deleteWallet}
        />
      </FormContent>
    </SideSettingsPanel>
  );
});
