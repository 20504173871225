import styled from "styled-components";
import { ModalBackground, PanelContainer, PanelTitle, ScrollSize } from "../shared";

export interface ModalContainerProps {
  zIndex?: number;
}

export const Container = styled(ModalBackground)<ModalContainerProps>`
  z-index: ${({ zIndex = 999 }) => zIndex};
`;

export const Panel = styled(PanelContainer)`
  width: 100%;
  max-width: 650px;
  max-height: 100%;

  padding: 46px;

  overflow: auto;
  ${ScrollSize}

  @media screen and (max-width: 600px) {
    width: calc(100% - 20px);
  }
`;

export const CloseWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 25px;
  top: 25px;
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 34px;

  gap: 20px;

  @media screen and (max-width: 450px) {
    margin-bottom: 14px;
  }
`;

export const Title = styled(PanelTitle)``;

export const Content = styled.div`
  display: flex;
  flex: 1;
`;
