import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { PartyContext } from "src/context/UserManager/UserManager";
import { Exchange } from "./Exchange";
import * as styles from "./style";

export interface ExchangesProps {
  showAddAPI: React.Dispatch<React.SetStateAction<boolean>>;
  showEditAcc: React.Dispatch<React.SetStateAction<boolean>>;
  showAddAcc: (exchange?: string) => void;
}

export const ExchangeList = observer(({ showAddAPI, showEditAcc, showAddAcc }: ExchangesProps) => {
  const partyState = useContext(PartyContext);

  return (
    <styles.Container>
      {partyState?.exchanges.map((el, index) => (
        <Exchange
          name={el}
          key={el}
          showAddAPI={showAddAPI}
          showEditAcc={showEditAcc}
          showAddAcc={showAddAcc}
        />
      ))}
    </styles.Container>
  );
});
