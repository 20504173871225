import { observer } from "mobx-react-lite";
import { CreateMultiGridContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ExchangeModule } from "./ExchangeModule";
import * as styles from "./style";

export const ModuleList = observer(() => {
  const state = useLateInitContext(CreateMultiGridContext);

  return (
    <styles.Container>
      {state.moduleList.map((el, index) => {
        const key = el.id + index;
        return <ExchangeModule key={key} module={el} />;
      })}
    </styles.Container>
  );
});
