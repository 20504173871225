import styled from "styled-components";
import { Card } from "src/components/BotsContent/CEX/shared/Card";
import { Content, MainContentWrapper } from "src/components/BotsContent/CEX/shared/Card/style";
import { ExchangesLegendV2 } from "../../../ExchangesOverview/ExchangesLegendV2";

export const PieWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledExchangesCard = styled(Card)`
  ${MainContentWrapper} {
    width: calc(100% - 170px);
  }

  ${Content} {
    padding: 8px;
  }
`;

export const StyledExchangesLegend = styled(ExchangesLegendV2)`
  width: 170px;
`;
