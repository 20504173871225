import { CEXBot } from "src/components/AllBots/Bots/CEXBots/CEXBot";
import { Header } from "src/components/AllBots/Bots/Header";
import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import styled from "styled-components";

export const BotInfoCEXFallback = styled(CrashPanel)`
  height: 90px;
  flex-shrink: 0;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CEXBotForPages = styled(CEXBot)`
  &:not(:last-of-type) {
    margin: 0px;
  }

  & > div > div:first-of-type {
    left: 3px;
  }
`;

export const HeaderForPages = styled(Header)`
  grid-template-columns:
    minmax(135px, 215px) minmax(75px, 125px) minmax(72px, 210px)
    122px minmax(125px, 410px) minmax(74px, 150px) minmax(100px, 1fr);
`;
