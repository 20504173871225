import { ITooltip as TooltipProps } from "react-tooltip";
import { useId } from "src/hooks/useId";
import Icons from "src/icons/Icons";
import { InfoTooltip } from "./InfoTooltip";

export interface InfoPromptProps extends TooltipProps {
  children?: string;
  renderTooltip?: boolean;
}

export const InfoPrompt = ({ children, renderTooltip, id: outerId, ...props }: InfoPromptProps) => {
  const localId = useId();

  const id = outerId || localId;

  const Tooltip = <InfoTooltip id={id} place="right" {...props} />;

  return (
    <>
      {children ? (
        <span data-tooltip-content={children} data-tooltip-id={id}>
          <Icons.Prompt />
        </span>
      ) : null}
      {renderTooltip && Tooltip}
    </>
  );
};
