import { observer } from "mobx-react-lite";
import { ChainInfoChipOwnProps } from "src/components/AllBots/Bots/DEXV2Bots/shared/ChainInfoChip";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import useMediaQuery from "src/hooks/useMediaQuery";
import Icons from "src/icons/Icons";
import { WalletConnection } from "../WalletConnection";
import { WithdrawerChip } from "./WithdrawerChip";
import * as styles from "./style";

interface WalletsInteractionPanelProps {
  withdrawer?: string;
  showWithdrawer?: boolean;
  showWalletConnection?: boolean;
  walletsDeployed?: boolean;
  chainInfoProps?: ChainInfoChipOwnProps;
  onRefreshBackendBalances?: () => void;
  onRefreshChainBalances?: () => void;
  mobileQuery?: string;
  spacing?: "dense" | "sparse";
}

export const WalletsInteractionPanel = observer(
  ({
    withdrawer = "",
    showWithdrawer = true,
    showWalletConnection = true,
    walletsDeployed,
    chainInfoProps,
    onRefreshBackendBalances,
    onRefreshChainBalances,
    mobileQuery = getMobileMediaQuery("600px"),
    spacing = "dense",
  }: WalletsInteractionPanelProps) => {
    const isMobile = useMediaQuery(mobileQuery);
    const orientation = isMobile ? "column" : "row";

    return (
      <styles.Container $orientation={orientation}>
        <styles.WithdrawerWrapper>
          {showWithdrawer && <WithdrawerChip withdrawer={withdrawer} />}
          <styles.ChainInfoChip {...chainInfoProps} />
        </styles.WithdrawerWrapper>

        <styles.WalletConnectionWrapper gap={spacing === "sparse" ? 20 : undefined}>
          {showWalletConnection && <WalletConnection deployed={walletsDeployed} />}

          <styles.RefreshIconsWrapper>
            <IconButton onClick={onRefreshBackendBalances}>{Icons.updateBackend()}</IconButton>

            <IconButton onClick={onRefreshChainBalances}>{Icons.updateOnChain()}</IconButton>
          </styles.RefreshIconsWrapper>
        </styles.WalletConnectionWrapper>
      </styles.Container>
    );
  }
);
