import styled from "styled-components";
import { TradeForm } from "../shared";

export const Form = styled(TradeForm)``;

export const Content = styled.div`
  display: flex;
  height: 170px;
  justify-content: space-between;
  & > div:not(:last-of-type) {
    margin-right: 12px;
  }
`;

export const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputsGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
`;

export const SubmitWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 7px;

  & > div:first-of-type {
    margin: 0;
    flex-direction: unset;
  }
`;
