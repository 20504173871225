import { useMemo } from "react";
import { LabeledInputProps } from "src/components/shared/Forms/Inputs";
import { separation } from "src/helpers/separation";
import { InfoPrompt } from "../../../shared/InfoPrompt";
import { SettingsInfoTooltip } from "../SettingsInfoTooltip";
import * as styles from "./style";

export interface InfoInputProps
  extends Omit<LabeledInputProps, "labelAfter" | "readOnly" | "value"> {
  info: string;
  formatSpace?: boolean;
  value?: string | number;
}

export const InfoInput = ({ info, formatSpace = false, value, ...props }: InfoInputProps) => {
  const formattedValue = useMemo(() => {
    if (formatSpace) {
      return separation(value ?? null);
    }
    return value;
  }, [formatSpace, value]);

  return (
    <styles.StyledInfoInput
      labelAfter={<InfoPrompt message={info} prompt={SettingsInfoTooltip} />}
      readOnly
      value={formattedValue}
      {...props}
    />
  );
};
