import { makeAutoObservable } from "mobx";
import WebSocketStore, { IStreamConfig } from "src/state/network/WebSocketHandler";
import TradesDataStorageStore from "../TradesDataStorageStore";

const TRADES_URL = "trades";

class WSProvider {
  dataStorage: TradesDataStorageStore;

  private _prevStreamPair: string = "";

  webSocketState;

  constructor(state: TradesDataStorageStore) {
    this.dataStorage = state;

    this.webSocketState = new WebSocketStore({
      subCb: [this.dataStorage.updateProcessing],
    });

    makeAutoObservable(this);
  }

  get pair() {
    return this.dataStorage.pair;
  }

  get exchange() {
    return this.dataStorage.exchange;
  }

  get data() {
    return this.webSocketState.data;
  }

  get connectStatus() {
    return this.webSocketState.socketStatus;
  }

  get streamPair() {
    return this.pair;
  }

  get isChangePair() {
    return this._prevStreamPair !== this.streamPair;
  }

  get payload() {
    return {
      exchange: this.exchange,
      pair: this.pair,
    };
  }

  get streamConfig(): IStreamConfig {
    return {
      type: "subscribe",
      payload: this.payload,
    };
  }

  streamData = () => {
    if (this.connectStatus === 3 || this.connectStatus === undefined) {
      this._openSocket();
    } else if (this.connectStatus === 1 && this.isChangePair) {
      this._setupWebSocket();
    }
  };

  private _setPrevStreamPair = (pair: string) => {
    this._prevStreamPair = pair;
  };

  private _openSocket = async () => {
    this.webSocketState.setupWebSocket({
      url: TRADES_URL,
      config: this.streamConfig,
    });

    this._setPrevStreamPair(this.streamPair);
  };

  private _setupWebSocket = () => {
    if (this._prevStreamPair) this._unSubPrevPair(this._prevStreamPair);

    this.webSocketState.sendMsgOnStream(this.streamConfig);

    this._setPrevStreamPair(this.streamPair);
  };

  private _unSubPrevPair = (pair: string) => {
    this.webSocketState.sendMsgOnStream({
      type: "unsubscribe",
      payload: { pair, exchange: this.exchange },
    });
  };

  closeConnect = () => {
    this.webSocketState.closeWebSocket();
  };
}

export default WSProvider;
