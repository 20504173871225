import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  height: 280px;

  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  gap: 13px;
  padding: 10px 10px 20px;

  ${CardSurface}
`;

export const HeaderWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;
