import { makeAutoObservable } from "mobx";
import { RefObject } from "react";
import { ElemRectParam } from "src/hooks/useElementRect";
import ExchangeStore from "..";

type TableElement = RefObject<HTMLTableSectionElement>;
type DivElement = RefObject<HTMLDivElement>;

class RangeStore {
  rangePosition: number = 0;

  topRangePosition: number | null = 0;

  bottomRangePosition: number | null = 0;

  // default position paddings for range
  paddingTopRange = 29;

  paddingBottomRange = 0;

  lastTradeHeight = 26;

  sellCont: DivElement;

  buyCont: DivElement;

  sellCup: TableElement;

  buyCup: TableElement;

  parentStore: ExchangeStore;

  constructor(
    mainState: ExchangeStore,
    sellContRef: DivElement,
    buyContRef: DivElement,
    sellCupRef: TableElement,
    buyCupRef: TableElement
  ) {
    this.parentStore = mainState;

    this.sellCont = sellContRef;
    this.buyCont = buyContRef;

    this.sellCup = sellCupRef;
    this.buyCup = buyCupRef;

    makeAutoObservable(this, {
      sellCont: false,
      buyCont: false,
      sellCup: false,
      buyCup: false,
    });
  }

  get sellContParam(): ElemRectParam {
    const elem = this.sellCont.current;

    return {
      width: elem?.offsetWidth || 0,
      height: elem?.offsetHeight || 0,
      top: elem?.getBoundingClientRect().top || 0,
      bottom: elem?.getBoundingClientRect().bottom || 0,
    };
  }

  get buyContParam(): ElemRectParam {
    const elem = this.buyCont.current;

    return {
      width: elem?.offsetWidth || 0,
      height: elem?.offsetHeight || 0,
      top: elem?.getBoundingClientRect().top || 0,
      bottom: elem?.getBoundingClientRect().bottom || 0,
    };
  }

  get topPosition() {
    const position = Math.max(Number(this.topRangePosition), 0) + this.paddingTopRange;

    return position;
  }

  get bottomPosition() {
    const position =
      Number(this.bottomRangePosition) > 0
        ? Number(this.bottomRangePosition) + 4
        : this.paddingBottomRange;

    return position;
  }

  get rangeStatus() {
    if (this.DTPMin > this.DTPMax) return "red";
    return "#166ce2";
  }

  get DTPMin() {
    return this.parentStore.settingsState.data.settings.pair.dontTradePriceMin;
  }

  get DTPMax() {
    return this.parentStore.settingsState.data.settings.pair.dontTradePriceMax;
  }

  get firstBuyOrderPrice() {
    const orders = this.parentStore.orderBookState.buyOrders;

    if (orders.length) return parseFloat(orders[0].price);

    return 0;
  }

  get lastBuyOrderPrice() {
    const orders = this.parentStore.orderBookState.buyOrders;
    const arrLength = orders.length;

    if (arrLength) {
      const lastPrice = parseFloat(orders[arrLength - 1].price);
      if (lastPrice) return lastPrice;

      // search for a non-empty order
      for (let i = arrLength - 1; i >= 0; i -= 1) {
        const orderPrice = parseFloat(orders[i].price);

        if (orderPrice) return orderPrice;
      }
    }

    return 0;
  }

  get firstSellOrderPrice() {
    const orders = this.parentStore.orderBookState.sellOrders;

    if (orders.length) {
      if (+orders[0].price) return parseFloat(orders[0].price);

      // search for a non-empty order
      const firstOrder = orders.find((el) => !!parseFloat(el.price));
      if (firstOrder) return parseFloat(firstOrder.price);
    }

    return 0;
  }

  get lastSellOrderPrice() {
    const orders = this.parentStore.orderBookState.sellOrders;

    if (orders.length) return parseFloat(orders[orders.length - 1].price);

    return 0;
  }

  findTopRangePos = (DTPMax: number): void => {
    const sellCup = this.sellCup.current;
    const buyCup = this.buyCup.current;

    // set the position to null to move to the next order book if necessary
    this.topRangePosition = null;

    if (DTPMax === 0) {
      this.topRangePosition = 0;
      return;
    }

    if (sellCup === null && buyCup) {
      this.nextFindTopRangePos(DTPMax);
      return;
    }

    if (sellCup === null) return;

    const sellCupBottomPos = this.sellContParam.bottom;
    const sellCupTopPos = this.sellContParam.top;

    if (!sellCup.rows.length) return;

    // if (this.firstSellOrderPrice >= DTPMax) {
    for (const row of sellCup.rows) {
      const priceValue = +row.children[0]!.getAttribute("data-value")!;

      // to exclude the processing of empty orders (WS mode)
      if (!priceValue) continue;

      if (priceValue < DTPMax) {
        this.topRangePosition = Math.min(
          sellCupBottomPos - sellCupTopPos + 1,
          row.getBoundingClientRect().top - sellCupTopPos
        );

        break;
      }
    }
    // }

    if (!this.topRangePosition && this.topRangePosition !== 0) {
      this.nextFindTopRangePos(DTPMax);
    }
  };

  // if the border value was not found in the order book
  nextFindTopRangePos = (DTPMax: number) => {
    const sellCup = this.sellCup.current;
    const buyCup = this.buyCup.current;

    const buyCupTopPos = this.buyContParam.top;
    const sellCupBottomPos = this.sellContParam.bottom;
    const sellCupTopPos = this.sellContParam.top;

    const buyCupBottomPos = this.buyContParam.bottom;

    if (this.firstBuyOrderPrice >= DTPMax && buyCup) {
      for (const row of buyCup.rows) {
        const priceValue = +row.children[0]!.getAttribute("data-value")!;

        // to exclude the processing of empty orders (WS mode)
        if (!priceValue) continue;

        if (priceValue < DTPMax) {
          if (sellCup) {
            this.topRangePosition = Math.min(
              Math.max(buyCupTopPos, row.getBoundingClientRect().top) - sellCupTopPos,
              buyCupBottomPos - sellCupTopPos
            );
          } else {
            this.topRangePosition = Math.min(
              Math.max(buyCupTopPos, row.getBoundingClientRect().top) -
                buyCupTopPos +
                this.lastTradeHeight,
              buyCupBottomPos - buyCupTopPos + this.lastTradeHeight
            );
          }

          break;
        }
      }
    }

    if (!this.topRangePosition) {
      if (DTPMax > this.firstSellOrderPrice) {
        this.topRangePosition = 0;
      } else if (DTPMax > this.firstBuyOrderPrice) {
        this.topRangePosition = sellCupBottomPos - sellCupTopPos + 1;
      } else if (DTPMax <= this.lastBuyOrderPrice && sellCup) {
        this.topRangePosition =
          this.buyContParam.height + this.sellContParam.height + this.lastTradeHeight;
      } else if (DTPMax > this.lastBuyOrderPrice && sellCup) {
        this.topRangePosition =
          this.buyContParam.height + this.sellContParam.height + this.lastTradeHeight;
      } else if (DTPMax > this.lastBuyOrderPrice && sellCup === null) {
        this.topRangePosition = this.buyContParam.height + this.lastTradeHeight;
      } else if (DTPMax <= this.lastBuyOrderPrice && sellCup === null) {
        this.topRangePosition = this.buyContParam.height + this.lastTradeHeight;
      }
    }
  };

  findBottomRangePos = (DTPMin: number) => {
    const sellCup = this.sellCup.current;
    const buyCup = this.buyCup.current;

    // set the position to null to move to the next order book if necessary
    this.bottomRangePosition = null;

    if (DTPMin === 0) {
      this.bottomRangePosition = 0;
      return;
    }

    if (buyCup === null && sellCup) {
      this.nextFindBottomRangePos(DTPMin);
      return;
    }

    if (buyCup === null) return;

    const buyCupBottomPos = this.buyContParam.bottom;
    const buyCupTopPos = this.buyContParam.top;

    if (!buyCup.rows.length) return;

    if (this.firstBuyOrderPrice >= DTPMin) {
      for (const row of buyCup.rows) {
        const priceValue = +row.children[0]!.getAttribute("data-value")!;

        // to exclude the processing of empty orders (WS mode)
        if (!priceValue) continue;

        if (priceValue <= DTPMin) {
          break;
        }
        this.bottomRangePosition =
          buyCupBottomPos - Math.max(buyCupTopPos, row.getBoundingClientRect().bottom);
      }
    }

    if (this.firstBuyOrderPrice >= DTPMin && this.bottomRangePosition === null) {
      this.bottomRangePosition = this.buyContParam.height;
    }

    if (!this.bottomRangePosition && this.bottomRangePosition !== 0) {
      this.nextFindBottomRangePos(DTPMin);
    }
  };

  // if the border value was not found in the order book
  nextFindBottomRangePos = (DTPMin: number) => {
    const sellCup = this.sellCup.current;
    const buyCup = this.buyCup.current;

    const sellCupBottomPos = this.sellContParam.bottom;

    const buyCupBottomPos = this.buyContParam.bottom;

    if (this.firstSellOrderPrice >= DTPMin && sellCup) {
      for (const row of sellCup.rows) {
        const priceValue = +row.children[0]!.getAttribute("data-value")!;

        // to exclude the processing of empty orders (WS mode)
        if (!priceValue) continue;

        if (priceValue <= DTPMin) {
          break;
        }

        if (buyCup) {
          this.bottomRangePosition =
            buyCupBottomPos - Math.min(sellCupBottomPos, row.getBoundingClientRect().bottom);
        } else {
          this.bottomRangePosition =
            sellCupBottomPos +
            this.lastTradeHeight -
            Math.min(sellCupBottomPos, row.getBoundingClientRect().bottom);
        }
      }
    }

    if (
      this.firstSellOrderPrice >= DTPMin &&
      this.lastSellOrderPrice < DTPMin &&
      this.bottomRangePosition === null
    ) {
      this.bottomRangePosition =
        this.buyContParam.height + this.sellContParam.height + this.lastTradeHeight;
    }

    if (this.bottomRangePosition === null) {
      if (DTPMin > this.firstSellOrderPrice) {
        this.bottomRangePosition =
          this.buyContParam.height + this.sellContParam.height + this.lastTradeHeight;
      } else if (DTPMin > this.firstBuyOrderPrice) {
        this.bottomRangePosition = this.buyContParam.height;
      } else if (DTPMin < this.lastBuyOrderPrice) {
        this.bottomRangePosition = 0;
      }
    }
  };
}

export default RangeStore;
