import dayjs, { Dayjs } from "dayjs";
import { DEFAULT_FULL_DATE_FORMAT } from "src/helpers/dateUtils";
import { DatePickerRange } from "../models/datePicker";
import { DateTimeRange } from "../models/dateTimeRange";
import { DateRange } from "../models/range";

const getUtcOffset = () => dayjs().utcOffset();

const dayjsToLocalDate = (dayjsDate: Dayjs) => {
  const offset = getUtcOffset();
  return dayjsDate.subtract(offset, "minute").toDate();
};

const localDateToDayjs = (date: Date) => {
  const dayjsDate = dayjs(date);
  const offset = getUtcOffset();
  return dayjsDate.add(offset, "minute").utc();
};

interface DayjsToDateOptions {
  useUtc?: boolean;
}

const dayjsToDate = (dayjsDate: Dayjs | null, { useUtc }: DayjsToDateOptions = {}) => {
  if (!dayjsDate) return null;
  if (!dayjsDate.isValid()) return null;
  const date = useUtc ? dayjsToLocalDate(dayjsDate) : dayjsDate.toDate();
  return date;
};

interface DateToDayjsOptions {
  useUtc?: boolean;
  useCurrentTime?: boolean;
}

const dateToDayjs = (date: Date | null, { useCurrentTime, useUtc }: DateToDayjsOptions = {}) => {
  if (!date) return null;
  const dayjsDate = useUtc ? localDateToDayjs(date) : dayjs(date);
  if (!useCurrentTime) return dayjsDate;

  const now = useUtc ? dayjs.utc() : dayjs();

  if (now.diff(dayjsDate, "day") === 0) {
    return dayjsDate
      .set("hour", now.hour())
      .set("minute", now.minute())
      .set("second", now.second())
      .set("millisecond", now.millisecond());
  }

  return dayjsDate;
};

interface DayjsRangeToPickerRangeOptions extends DayjsToDateOptions {}

export const dayjsRangeToPickerRange = (
  range: DateRange<Dayjs>,
  options: DayjsRangeToPickerRangeOptions = {}
): DatePickerRange => {
  const dateRange = range.map((date) => dayjsToDate(date, options)) as DatePickerRange;
  return dateRange;
};

interface PickerRangeToDayjsRangeOptions extends Omit<DateToDayjsOptions, "useCurrentTime"> {}

export const pickerRangeToDayjsRange = (
  [start, end]: DatePickerRange,
  options: PickerRangeToDayjsRangeOptions = {}
): DateRange<Dayjs> => {
  // use current time for end date for now (instead of 23:59)
  // todo: apply only on futureDate/maxTime conditioning
  const range = [
    dateToDayjs(start, options),
    dateToDayjs(end, { ...options, useCurrentTime: true }),
  ] as DateRange<Dayjs>;
  return range;
};

const EMPTY_RANGE_TEXT = "--:--";

export const rangeToText = (
  [start, end]: DateRange<Dayjs>,
  formatStr = DEFAULT_FULL_DATE_FORMAT
) => {
  const startText = start?.format(formatStr);
  const endText = end?.format(formatStr);
  if (!startText || !endText) return EMPTY_RANGE_TEXT;
  return `${startText} - ${endText}`;
};

export const dayRangeToDiff = ([start, end]: DateTimeRange): number | null => {
  if (!start || !end) return null;
  return start.diff(end, "seconds");
};
