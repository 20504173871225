import { observer } from "mobx-react-lite";
import * as styles from "src/components/UserManagement/Access/shared";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { ScopesContext } from "src/context/UserManager/Scopes";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ButtonSideWrapper } from "../style";

export const AddParty = observer(() => {
  const {
    scopesState: { addPartyState: partyState },
  } = useLateInitContext(ScopesContext);

  return (
    <ModalPanel
      label="Add party"
      show={partyState.isShown}
      close={partyState.closeModal}
      loading={partyState.isLoading}
    >
      <styles.AccessForm
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        onSubmit={partyState.submitHandler()}
      >
        <styles.FormContent>
          <styles.FormRow>
            <LabeledInput
              label="Party name"
              value={partyState.newParty.party}
              onChange={partyState.getHandler("party")}
              errorHint={partyState.errors.party}
            />
          </styles.FormRow>

          <styles.FormRow>
            <LabeledInput
              type="number"
              label="TG chat ID"
              value={partyState.newParty.tg_chat_id ?? ""}
              onChange={partyState.getHandler("tg_chat_id")}
              errorHint={partyState.errors.tg_chat_id}
            />
            <LabeledSelector
              label="Client user group"
              options={partyState.selectorOptions("client_group")}
              value={partyState.selectorValue("client_group")}
              onChange={partyState.getSelectorHandler("client_group")}
              errorHint={partyState.errors.client_group}
            />
          </styles.FormRow>

          <styles.FormRow>
            <LabeledSelector
              label="Admin user group"
              options={partyState.selectorOptions("admin_group")}
              value={partyState.selectorValue("admin_group")}
              onChange={partyState.getSelectorHandler("admin_group")}
              errorHint={partyState.errors.admin_group}
            />
            <LabeledSelector
              label="Admin"
              options={partyState.selectorOptions("admin")}
              value={partyState.selectorValue("admin")}
              onChange={partyState.getSelectorHandler("admin")}
              errorHint={partyState.errors.admin}
              isDisabled={!partyState.selectorEnabled("admin")}
            />
          </styles.FormRow>
        </styles.FormContent>

        <ButtonSideWrapper>
          <OutlineButton type="submit" stretched>
            Add party
          </OutlineButton>
        </ButtonSideWrapper>
      </styles.AccessForm>
    </ModalPanel>
  );
});
