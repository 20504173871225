import { ComponentPropsWithoutRef } from "react";
import { InfoIconTooltip } from "./InfoIconTooltip";
import * as styles from "./style";

export interface HeaderOwnProps {
  title?: string;
  afterTitle?: JSX.Element;
  info?: string;
}

export interface HeaderProps extends HeaderOwnProps, ComponentPropsWithoutRef<"div"> {}
export const Header = ({ title, afterTitle, info }: HeaderProps) => {
  const showTitle = title || info;
  return (
    <styles.Container>
      {showTitle && (
        <styles.TitleWrapper>
          {title && <styles.CardTitle>{title}</styles.CardTitle>}
          <InfoIconTooltip info={info} />
        </styles.TitleWrapper>
      )}
      {afterTitle}
    </styles.Container>
  );
};
