import { Controller, useFormContext } from "react-hook-form";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ModuleSoftParamsShell } from "../../../../shared";
import { SoftParamsFormsProps } from "../../../../shared/ModuleSoftParamsShell/types";

interface AlertTgProps extends SoftParamsFormsProps {}

export const AlertTg = ({ index, ...props }: AlertTgProps) => {
  const { control } = useFormContext<IStrategyCreator>();

  return (
    <ModuleSoftParamsShell {...props}>
      <Controller
        control={control}
        name={`actions.${index}.soft_params.text`}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          const error = getFieldError(errors, `actions.${index}.soft_params.text`);
          return (
            <LabeledInput
              label="Text message"
              value={value}
              onChange={(e) => onChange(getChangeEventValue(e))}
              errorHint={error?.message}
            />
          );
        }}
      />
    </ModuleSoftParamsShell>
  );
};
