import { IWalletsProvider } from "../WalletInputStore";
import { ITransferWalletsProvider } from "./TransferWalletsStore";

export class TransferWalletsAdapter implements IWalletsProvider {
  private _provider: ITransferWalletsProvider;

  constructor(provider: ITransferWalletsProvider) {
    this._provider = provider;
  }

  get wallets(): string[] {
    return this._provider.transferWallets;
  }

  setWallets = (wallets: string[]) => {
    this._provider.setTransferWallets(wallets);
  };
}
