import { observer } from "mobx-react-lite";
import { useShowContent } from "../../hooks/useShowContent";
import { Arrow } from "../MainFilter/style";
import { PartiesList } from "./PartiesList";
import * as styles from "./style";

export const PartiesFilter = observer(() => {
  const [showContent, toggleShow] = useShowContent(true);

  return (
    <styles.Container>
      <styles.TitleRow onClick={toggleShow}>
        <styles.Title>New clients</styles.Title>
        <Arrow check={showContent} />
      </styles.TitleRow>
      {showContent ? <PartiesList /> : null}
    </styles.Container>
  );
});
