import { createContextProvider } from "src/context/utils/createContextProvider";
import useLocalStore from "src/hooks/useLocalStore";
import { DEXStatsStore } from "src/state/DEX/DEXStats/DEXStats";
import { WalletsStore } from "src/state/DEX/DEXStats/Wallets";

const DEXStatsStoreCreator = () => useLocalStore(DEXStatsStore);

const { Context: DEXStatsContext, Provider: DEXStatsProvider } =
  createContextProvider<DEXStatsStore>({
    stateCreator: DEXStatsStoreCreator,
  });

export { DEXStatsContext, DEXStatsProvider };

const WalletsStoreCreator = () => useLocalStore(WalletsStore);

const { Context: WalletsContext, Provider: WalletsProvider } = createContextProvider<WalletsStore>({
  stateCreator: WalletsStoreCreator,
});

export { WalletsContext, WalletsProvider };
