import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { GraphProps } from "src/components/shared/Graph";
import { OptionalChartOptions, SeriesProps } from "src/components/shared/Graph/types";
import { GraphsContext } from "src/context/DEXV2/DEXV2Stats/Graphs/Graphs";
import { OmitStrict } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { GraphsTooltipData } from "src/state/DEXV2/DEXV2Stats/Graphs";
import { StatsGraphTooltip } from "./StatsGraphTooltip";
import * as styles from "./style";

export const BASE_STATS_SERIES_PROPS: Omit<SeriesProps, "type" | "data"> = {
  showPriceSuffix: true,
};

export interface StatsGraphProps
  extends OmitStrict<
    GraphProps<GraphsTooltipData>,
    "seriesDataProvider" | "tooltip" | "allowTimeScale"
  > {}

export const StatsGraph = observer((props: StatsGraphProps) => {
  const state = useLateInitContext(GraphsContext);

  const options: OptionalChartOptions = useMemo(
    () => ({
      handleScale: {
        axisPressedMouseMove: {
          price: true,
        },
      },
    }),
    []
  );

  return (
    <styles.Graph
      seriesDataProvider={state}
      tooltip={StatsGraphTooltip}
      allowTimeScale={false}
      options={options}
      {...props}
    />
  );
});
