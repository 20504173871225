import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import { GenerateTimeSeriesOptions, generateTime, generateTimeSeries } from "../../../shared/mocks";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

export type FiatBalanceData = ChartPoint[];

const generateFiatData = (): FiatBalanceData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 12, max: 100 });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    startTimestamp,
    count: pointsCount,
    value: {
      min: 0,
      max: 100,
      precision: 0.01,
    },
  };

  const data = generateTimeSeries(generateSeriesOptions);

  return data;
};

interface IFiatBalanceStoreParams extends IBaseDashboardV2StoreParams {}

export default class FiatBalanceV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _data: FiatBalanceData = [];

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IFiatBalanceStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { data: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setData = (data: FiatBalanceData) => {
    this._data = data;
  };

  get data() {
    return this._data;
  }

  get loading() {
    return this._baseState.loading;
  }

  onRangeChange = (range: DateTimeRange) => {
    this._stateProvider.setRange(range);
  };

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;

    if (!requestParams) return;

    this._setData([]);
    try {
      await delay(200);
      const data = generateFiatData();
      this._setData(data);
    } catch {
      this._setData([]);
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
