import { Headline4 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  padding: 16px;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const Title = styled(Headline4)`
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  color: ${({ theme }) => theme.dashboard.textSecondary};
`;

export const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  justify-content: center;
  height: 100%;
`;

export const BalanceText = styled.p`
  padding: 0;
  margin: 0;

  font-size: ${({ theme }) => theme.fontSizes["13px"]};
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.dashboard.textHeading};
`;

export const Subtitle = styled.p`
  padding: 0;
  margin: 0;

  color: ${({ theme }) => theme.dashboard.textSecondary};

  font-size: ${({ theme }) => theme.fontSizes["8px"]};
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;
