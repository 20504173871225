import { MouseEventHandler } from "lightweight-charts";
import { observer } from "mobx-react-lite";
import { ComponentType, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { ChartApiContext } from "src/context/Graph/ChartApi";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SeriesMap, SeriesTitlesMap } from "src/state/Graph/Series/types";
import { Tooltip, TooltipProps } from "../../shared/Tooltip";

export interface ChartTooltipProps {
  container: HTMLDivElement | null;
  seriesTitlesMap: SeriesTitlesMap;
  seriesMap: SeriesMap;
  cursorMargin?: number;
  showTooltip?: boolean;
  tooltip?: ComponentType<TooltipProps>;
}

export const ChartTooltip = observer(
  ({
    container,
    seriesTitlesMap,
    seriesMap,
    cursorMargin = 15,
    showTooltip,
    tooltip: tooltipSlot,
  }: ChartTooltipProps) => {
    const chart = useLateInitContext(ChartApiContext);

    const state = useTooltipContext();

    const tooltip = useRef<HTMLDivElement>(null);

    const TooltipComponent = tooltipSlot ?? Tooltip;

    useEffect(() => {
      const chartApi = chart?.api;
      if (!showTooltip || !chartApi) return;

      const crossHairMoveCb: MouseEventHandler = (params) => {
        const tooltipEl = tooltip.current;
        if (!tooltipEl) return;
        const containerWidth = container?.clientWidth ?? 0;
        const containerHeight = container?.clientHeight ?? 0;
        if (
          !container ||
          !seriesMap ||
          params.point === undefined ||
          !params.time ||
          params.point.x < 0 ||
          params.point.x > containerWidth ||
          params.point.y < 0 ||
          params.point.y > containerHeight
        ) {
          tooltipEl.style.display = "none";
        } else {
          state.updateTooltipData(params.time, seriesMap, seriesTitlesMap, params.seriesData);

          const { width: tooltipWidth, height: tooltipHeight } = tooltipEl.getBoundingClientRect();

          const currentX = params.point.x;
          const currentY = params.point.y;

          let left = currentX + cursorMargin;
          if (left > containerWidth - tooltipWidth) {
            left = currentX - cursorMargin - tooltipWidth;
          }

          let top = currentY + cursorMargin;
          if (top > containerHeight - tooltipHeight) {
            top = currentY - tooltipHeight - cursorMargin;
          }

          tooltipEl.style.left = `${left}px`;
          tooltipEl.style.top = `${top}px`;
          tooltipEl.style.display = "block";
        }
      };
      chartApi.subscribeCrosshairMove(crossHairMoveCb);

      return () => {
        const chartApi = chart?.api;
        chartApi?.unsubscribeCrosshairMove(crossHairMoveCb);
      };
    }, [chart, container, cursorMargin, seriesMap, seriesTitlesMap, showTooltip, state]);

    return (
      <>
        {showTooltip && container && createPortal(<TooltipComponent ownRef={tooltip} />, container)}
      </>
    );
  }
);
