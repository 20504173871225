import { makeAutoObservable, runInAction } from "mobx";
import { fetchOrderBook } from "src/api/bots/CEX/exchange";
import { OrderBookResponse } from "src/modules/exchange/orderBook";
import DataStorageStore from "../DataStorageStore";

class RestAPIProvider {
  dataStorageState;

  private _data: OrderBookResponse | null = null;

  isLoading = false;

  constructor(state: DataStorageStore) {
    this.dataStorageState = state;

    makeAutoObservable(this);
  }

  get data() {
    return this._data;
  }

  fetchData = (pair: string, exchange: string) => {
    this._fetchOrderBook(pair, exchange);
  };

  private _fetchOrderBook = async (pair: string, exchange: string) => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      const { data, isError } = await fetchOrderBook({
        pair,
        exchange,
        account_uuid: this.dataStorageState.infoAcc,
      });

      if (!isError) {
        runInAction(() => {
          this._data = data;
        });

        // setting a flag for scrolling the orderBook and calc precisions
        this.dataStorageState.setFirstLoad(true);

        this.dataStorageState.mainState.calcPrecisions();
      }
    } catch {
      runInAction(() => {
        this._data = null;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

export default RestAPIProvider;
