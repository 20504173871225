import styled from "styled-components";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 18px;

  margin-top: 33px;
`;

export const SaveInfoButton = styled(SubmitButton)`
  grid-column-end: -1;
`;
