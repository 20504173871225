import React from "react";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { CSSProperties } from "styled-components";
import { SaveButton } from "../../../../../../shared/Buttons/v2/variants/SaveButton";
import { Loader } from "../../../../../../shared/Loader";
import { PanelHeader } from "../../../../../../shared/PanelHeader";
import * as styles from "./style";

interface Props {
  label: string;
  party: string;
  loader: boolean;
  submitHandler: React.FormEventHandler<HTMLFormElement>;
  children: JSX.Element[] | JSX.Element;
  saved: boolean;
  checkbox?: JSX.Element;
  style?: CSSProperties;
  toggle?: JSX.Element;
  info?: string;
  disabled?: boolean;
}

export const SettingsPanel = ({
  label,
  children,
  submitHandler,
  loader,
  checkbox,
  party,
  toggle,
  info,
  disabled,
  saved,
  ...props
}: Props) => (
  <styles.SettingsForm
    onSubmit={submitHandler}
    onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
    disabled={disabled}
    {...props}
  >
    <styles.Container>
      <PanelHeader
        label={label}
        checkbox={checkbox}
        toggle={toggle}
        btn={
          <PermissionWrapper party={party} abilityName="cex-settings-edit">
            <SaveButton saved={saved} />
          </PermissionWrapper>
        }
        info={info}
      />
      <styles.Content disabled={disabled}>{children}</styles.Content>
    </styles.Container>
    {/* <PermissionWrapper party={party} abilityName={"cex-settings-edit"}>
        <SubmitButton />
      </PermissionWrapper> */}
    <Loader show={loader} />
  </styles.SettingsForm>
);
