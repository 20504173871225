import { Mapper } from "src/helpers/utils";
import { GetBalancePNLResponse } from "src/api/bots/CEX/dashboard";
import { chartPointResponseMapper } from "src/state/shared/mappers";
import { PNLData } from "./types";

export const pnlResponseMapper: Mapper<GetBalancePNLResponse, PNLData> = ({
  base_ticker,
  current_price_base,
  delta_base,
  delta_base_usd,
  delta_quote_usd,
  delta_usd,
  fee_usd,
  price_growth,
  price_net,
  fundings,
  charts,
}) => ({
  base: base_ticker,
  currentPriceBase: current_price_base,
  deltaBase: delta_base,
  deltaBaseUsd: delta_base_usd,
  deltaQuoteUsd: delta_quote_usd,
  deltaUsd: delta_usd,
  fee: fee_usd,
  priceGrowth: price_growth,
  priceNet: price_net,
  fundingsExchange: fundings,
  charts: charts && {
    deltaQuoteChart: charts.delta_quote?.map(chartPointResponseMapper),
    deltaBaseChart: charts.delta_base?.map(chartPointResponseMapper),
    priceChart: charts.price?.map(chartPointResponseMapper),
  },
});
