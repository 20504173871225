import { ChildrenProps } from "src/helpers/utils";
import { useChainProvider } from "src/hooks/useChainProvider";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { useAppState } from "src/state";
import DeployerInfoStore from "src/state/DEXV2/DEXV2Create/DeployerInfoStore";
import WalletGasFillStore from "src/state/DEXV2/DEXV2Create/WalletGasFillStore";

export const WalletGasFillContext = createLateInitContext<WalletGasFillStore>();

type WalletGasFillProviderProps = ChildrenProps;

export const useDeployerInfoStore = () => {
  const { chainMetaState } = useAppState();
  const chainProvider = useChainProvider();
  const store = useLocalStore(DeployerInfoStore, chainProvider, chainMetaState);
  return store;
};

export const WalletGasFillProvider = ({ children }: WalletGasFillProviderProps) => {
  const deployerInfoStore = useDeployerInfoStore();
  const gasFillStore: WalletGasFillStore = useLocalStore(WalletGasFillStore, deployerInfoStore);

  return (
    <WalletGasFillContext.Provider value={gasFillStore}>{children}</WalletGasFillContext.Provider>
  );
};
