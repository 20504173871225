import styled from "styled-components";
import { Wrapper } from "../shared";

export const TradesWrapper = styled(Wrapper)`
  align-items: flex-end;

  @media screen and (max-width: 870px) {
    height: 100%;

    align-items: normal;

    flex-direction: column;
    justify-content: space-between;
  }
`;
