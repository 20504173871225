import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  flex: 1 1 auto;

  gap: 15px;
`;

export const Content = styled(PanelContainer)`
  position: relative;

  height: 0;

  display: flex;
  flex: 1 1 auto;

  padding: 15px;
`;
