import { LabelValue } from "src/state/MarketCheck/types";
import { getRounded } from "../helpers";
import * as styles from "./style";

interface FundingListProps {
  title: string;
  items: LabelValue[];
}

export const FundingList = ({ title, items }: FundingListProps) => (
  <styles.Container>
    <styles.FundingType>{title}</styles.FundingType>

    <styles.AmountList>
      {items.map(({ label, value }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <styles.Amount key={index}>{`${getRounded(value)} ${label}`}</styles.Amount>
      ))}
    </styles.AmountList>
  </styles.Container>
);
