import { observer } from "mobx-react-lite";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import ModuleFiltersStore from "src/state/expertSystem/modules/Filters/ModuleFiltersStore";
import { FilterPanelContent } from "../../../../shared/FilterPanel/FilterPanelContent";
import { MODULES_FILTERS_TITLES } from "../../../Conditions/ConditionsFilters/ConditionsFiltersContent";
import { useModuleState } from "../../../hooks/useModuleState";
import { SelectorFilter } from "../../../shared/ModuleFilters/SelectorFIlter";

export interface ActionsFiltersContentProps {}

export const ActionsFiltersContent = observer((props: ActionsFiltersContentProps) => {
  const { close } = useLateInitContext(PopupContext);

  const { updateFilters, filtersState } = useModuleState("action");

  const state = useLocalStore(ModuleFiltersStore, {
    type: "action",
    initialState: filtersState,
    onSubmit: updateFilters,
    onClose: close,
  });

  const { getSelectorProps, reset, submit } = state;

  return (
    <FilterPanelContent onReset={reset} onSubmit={submit}>
      <SelectorFilter label={MODULES_FILTERS_TITLES.type} {...getSelectorProps("type")} />
      <SelectorFilter label={MODULES_FILTERS_TITLES.status} {...getSelectorProps("status")} />
    </FilterPanelContent>
  );
});
