import { observer } from "mobx-react-lite";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import * as styles from "./style";

export const SelectFilters = observer(() => {
  const state = useLateInitContext(UserManagerContext);

  return (
    <styles.Container>
      <styles.FilterSelect
        placeholder="Users"
        isMulti
        options={state.usersSelect}
        value={state.selectUsers.length ? state.selectUsers : null}
        onChange={(value) => state.setSelectFilters("_selectUsers", [...value])}
      />

      <styles.FilterSelect
        placeholder="Roles"
        options={state.rolesSelect}
        isMulti
        value={state.selectRoles.length ? state.selectRoles : null}
        onChange={(value) => state.setSelectFilters("_selectRoles", [...value])}
      />

      <styles.FilterSelect
        placeholder="Scopes"
        isMulti
        options={state.scopesSelect}
        value={state.selectScopes.length ? state.selectScopes : null}
        onChange={(value) => state.setSelectFilters("_selectScopes", [...value])}
      />

      <IconButton onClick={() => state.resetSelectFilters()}>{Icons.reset()}</IconButton>
    </styles.Container>
  );
});
