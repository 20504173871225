import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Loader } from "src/components/shared/Loader";
import { MultiGridAbilitiesContext, MultiGridContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { PermissionProps } from "src/permissions/PermissionWrapper";
import MultiGridStore from "src/state/CEX/multiGrid";
import { BotInfoCEXProps } from "../BotInfoCEX";
import { ActiveMultiGrid } from "./ActiveMultiGrid";
import { CreateMultiGrid } from "./CreateMultiGrid";
import * as styles from "./styles";

interface MultiGridProps extends Pick<PermissionProps, "abilityName">, BotInfoCEXProps {}

export const MultiGrid = observer(({ botInfo: { party }, abilityName }: MultiGridProps) => {
  const state = useMemo(() => new MultiGridStore(), []);
  const abilitiesProps = { abilityName };

  useEffect(() => {
    if (!party) return;
    state.setParty(party);
    state.checkGridState();
  }, [state, party]);

  return (
    <MultiGridAbilitiesContext.Provider value={abilitiesProps}>
      <MultiGridContext.Provider value={state}>
        <styles.Container>
          {state.multiGridExists ? <ActiveMultiGrid /> : <CreateMultiGrid />}

          <Loader show={state.isLoading} />
        </styles.Container>
      </MultiGridContext.Provider>
    </MultiGridAbilitiesContext.Provider>
  );
});
