import { ComponentPropsWithoutRef, useMemo } from "react";
import * as styles from "./style";

interface ProgressBarProps extends ComponentPropsWithoutRef<"span">, styles.ProgressBarStyleProps {}

interface UseProgressProps extends Required<Pick<LinearProgressProps, "value" | "orientation">> {}

const useProgress = ({ value, orientation }: UseProgressProps): ProgressBarProps => {
  const minValue = 0;
  const maxValue = 100;
  // limit value in between 0-100
  const valueNow = Math.min(100, Math.max(0, Math.round(value)));

  const transformValue = orientation === "horizontal" ? valueNow - 100 : 100 - valueNow;

  const props: ProgressBarProps = useMemo(
    () => ({
      "aria-valuenow": valueNow,
      "aria-valuemin": minValue,
      "aria-valuemax": maxValue,
      transform: transformValue,
    }),
    [transformValue, valueNow]
  );

  return props;
};

interface UseProgressBarStyledPropsProps
  extends ComponentPropsWithoutRef<"span">,
    styles.ProgressBarStyleProps {}

const useProgressBarStyledProps = ({
  color,
  transform,
  animate,
  orientation,
  ...props
}: UseProgressBarStyledPropsProps): styles.ProgressBarStyledProps => ({
  $color: color,
  $transform: transform,
  $animate: animate,
  $orientation: orientation,
  ...props,
});

interface UseProgressSpanStyledProps
  extends ComponentPropsWithoutRef<"span">,
    styles.ProgressSpanStyleProps {}

const useProgressSpanStyledProps = ({
  backgroundColor,
  orientation,
  ...props
}: UseProgressSpanStyledProps): styles.ProgressSpanStyledProps => ({
  $backgroundColor: backgroundColor,
  $orientation: orientation,
  ...props,
});

interface LinearProgressOwnProps
  extends styles.ProgressSpanStyleProps,
    Omit<styles.ProgressBarStyleProps, "transform"> {
  value?: number;
}

export interface LinearProgressProps
  extends LinearProgressOwnProps,
    Omit<ComponentPropsWithoutRef<"span">, keyof LinearProgressOwnProps> {}

export const LinearProgress = ({
  color,
  backgroundColor,
  value = 0,
  animate = true,
  orientation = "horizontal",
  ...props
}: LinearProgressProps) => {
  const progressProps = useProgress({ value, orientation });

  const progressBarProps = useProgressBarStyledProps({
    color,
    animate,
    orientation,
    ...progressProps,
  });

  const progressSpanProps = useProgressSpanStyledProps({
    backgroundColor,
    orientation,
    ...props,
  });

  return (
    <styles.ProgressSpan {...progressSpanProps}>
      <styles.ProgressBar {...progressBarProps} />
    </styles.ProgressSpan>
  );
};
