import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const Content = styled.div`
  position: relative;

  height: 100%;
  min-height: 260px;
  width: 100%;

  display: flex;

  grid-area: trading-view;
`;

export const TVChartContainer = styled(PanelContainer)`
  height: 100%;
  padding: 1px;
`;
