import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { VolumeAccountName } from "src/modules/accounts";
import { PermissionProps } from "src/permissions/PermissionWrapper";
import { AccountsHeader } from "../../shared/AccountsHeader";
import { AddVolumeAccountModal, AddVolumeAccountModalProps } from "./AddVolumeAccountModal";
import { VolumeAccount } from "./VolumeAccount";
import * as styles from "./style";

export interface VolumeAccountsProps
  extends PermissionProps,
    Pick<AddVolumeAccountModalProps, "onRefresh" | "onAddAccount" | "bindingsProvider"> {
  accountNames: VolumeAccountName[];
}

export const useAccountModalState = () => {
  const [show, setShow] = useState(false);
  const onOpen = useCallback(() => {
    setShow(true);
  }, []);
  const onClose = useCallback(() => {
    setShow(false);
  }, []);
  return {
    show,
    onClose,
    onOpen,
  };
};

export const VolumeAccounts = observer(
  ({ accountNames, party, abilityName, ...props }: VolumeAccountsProps) => {
    const { show: showAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useAccountModalState();

    const permissionProps = { abilityName, party };

    const volumeAccounts = accountNames.map((name) => (
      <VolumeAccount key={name} accountName={name} {...permissionProps} />
    ));

    return (
      <styles.VolumeContainer>
        <AccountsHeader type="volume" onAddAccount={onOpenAdd} {...permissionProps} />
        <styles.AccountsContainer>{volumeAccounts}</styles.AccountsContainer>
        <AddVolumeAccountModal show={showAdd} close={onCloseAdd} {...props} />
      </styles.VolumeContainer>
    );
  }
);
