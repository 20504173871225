import { makeAutoObservable } from "mobx";
import { GetBotWalletsResponse, getBotWallets } from "src/api/bots/DEXV2/stats";
import { makeLoggable } from "src/helpers/logger";
import { Mapper } from "src/helpers/utils";
import { logError } from "src/helpers/network/logger";
import { IBotUUIDProvider } from "../DEXV2Bots/DEXV2BotStore";

export const walletsRespToWithdrawer: Mapper<GetBotWalletsResponse, string> = (walletsResp) =>
  walletsResp.withdrawers[0] ?? "";

export interface IWithdrawerProvider {
  get withdrawer(): string | null;
  getWithdrawer(): Promise<void>;
}

export default class WithdrawerProvider implements IWithdrawerProvider {
  private _withdrawer: string | null = null;

  private _uuidProvider: IBotUUIDProvider;

  constructor(uuidProvider: IBotUUIDProvider) {
    makeAutoObservable(this);

    this._uuidProvider = uuidProvider;

    makeLoggable(this, {
      withdrawer: true,
    });
  }

  private _setWithdrawer = (withdrawer: string) => {
    this._withdrawer = withdrawer;
  };

  get withdrawer() {
    return this._withdrawer;
  }

  getWithdrawer = async () => {
    const { botUUID } = this._uuidProvider;
    if (!botUUID) {
      return;
    }
    try {
      const { isError, data } = await getBotWallets(botUUID, "withdrawer");
      if (!isError) {
        const withdrawer = walletsRespToWithdrawer(data);
        this._setWithdrawer(withdrawer);
      }
    } catch (err) {
      logError(err);
    }
  };
}
