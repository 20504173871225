import { LineStyle } from "lightweight-charts";
import { useMemo } from "react";
import { OptionalChartOptions } from "src/components/shared/Graph/types";
import { useDashboardColors } from "../hooks/useDashboardColors";

export const useGraphsChartOptions = () => {
  const { textSecondary, borderDefault } = useDashboardColors();
  const baseOptions = useMemo(
    (): OptionalChartOptions => ({
      layout: {
        textColor: textSecondary,
        fontSize: 9,
      },
      grid: {
        horzLines: {
          style: LineStyle.LargeDashed,
          color: borderDefault,
        },
        vertLines: {
          visible: false,
        },
      },
      rightPriceScale: {
        borderColor: borderDefault,
      },
      leftPriceScale: {
        borderColor: borderDefault,
      },
      timeScale: {
        borderColor: borderDefault,
      },
    }),
    [borderDefault, textSecondary]
  );

  return { options: baseOptions };
};
