export interface Trade {
  time: string;
  amount: string;
  side: TradeSideType | "";
  price: string;
}

export type TradeSideType = "buy" | "sell";

export interface MakeVolume {
  account_uuid: string;
  number: number | "";
  priceMax: number | "";
  priceMin: number | "";
  quoteMin: number | "";
  quoteMax: number | "";
  symbol: string;
  precisionPrice: number;
  precisionAmount: number;
  sellFirst: boolean;
}

export interface PlaceLiquidity {
  number: number | "";
  priceMax: number | "";
  priceMin: number | "";
  assetMax: number | "";
  assetMin: number | "";
  account_uuid: string;
  side: string;
}

export interface Transfer {
  pair: string;
  price: number | "";
  amount: number | "";
  sellAccountUUID: string;
  buyAccountUUID: string;
  sellFirst: boolean;
}

export interface NewLiquidGridSettings {
  pair: {
    base: string;
    quote: string;
    minAmountBase: string;
    minAmountQuote: string;
  };
  botUUID: string;
  exchange: string;
  accountName: string;
  minPrice: string;
  maxPrice: string;
  ordersCount: number;
  quotePerOrder: string;
  delay: string;
  state: string;
  buyCount: number;
  lastCount: number;
  sellCount: number;
  profit: {
    base: string;
    quote: string;
  };
  sellFees: {
    base: string;
    quote: string;
  };
  buyFees: {
    base: string;
    quote: string;
  };
  precision: {
    amount: number;
    price: number;
  };
  deltas: {
    base: string;
    quote: string;
  };
  position: {
    base: string;
    quote: string;
  };
}

export interface Pair {
  base: string;
  minAmountBase: string;
  minAmountQuote: string;
  quote: string;
}

export type LiquidGridError = { text: string; time: string };

export type LiquidGridInfo = {
  id: number;
  updatedAt: string;
  errorsInfo: {
    count: number;
    errors: LiquidGridError[] | null;
    resets: string[] | null;
  };
};

export interface LiquidGridSettings extends NewLiquidGridSettings, LiquidGridInfo {}

export type OrderSideType = "BUY" | "SELL";

export interface StopOrder {
  name: string;
  pair: string;
  side: OrderSideType | "";
  price: string;
  triggerPrice: string;
  triggerCompare: "GREATER" | "LESS" | "";
  amount: string;
  expireIn: number | "";
}

export interface Volume24h {
  baseVolume: string;
  close: string;
  high: string;
  low: string;
  open: string;
  quoteVolume: string;
  time: string;
}

export interface FloatingOrderParams {
  amount: number | "";
  amount_filled_limit: number | "";
  price_percent: number | "";
}
export interface FloatingOrder extends FloatingOrderParams {
  account_uuid: string;
  pair: string;
  side: OrderSideType;
}

export interface CreatedFloatingOrder
  extends Omit<FloatingOrder, "price_percent" | "amount_filled_limit" | "amount"> {
  amount_filled: string;
  attempts: number;
  created_at: string;
  error: string;
  exchange_id: string;
  external_id: string;
  id: number;
  price_current: string;
  price_percent: string;
  status: string;
  updated_at: string;
  amount: string;
  amount_filled_limit: string;
}

export interface FilledFloatingOrder {
  amount_filled: number;
  created_at: string;
  external_id: string;
}

export interface FloatingOrderStatus extends CreatedFloatingOrder {
  filled_orders: FilledFloatingOrder[];
}

export const LIMIT_ORDER_TYPE = ["FOK", "GTC"] as const;

export type LimitOrderType = (typeof LIMIT_ORDER_TYPE)[number];

export interface LimitOrder {
  orderType: LimitOrderType;
  price: string;
  amount: string;
  totalAsset: string;
}

export interface CreatedLimitOrder {
  amount: string;
  average: string;
  cancelled: boolean;
  fee: string;
  feeCurrency: string;
  fillOrKill: boolean;
  filled: string;
  id: string;
  pair: { base: string; quote: string };
  base: string;
  quote: string;
  partiallyFilled: false;
  price: string;
  side: string;
  status: string;
  time: string;
}

export interface CreatedAlgoOrder {
  accountUuid: string;
  amount: string;
  apiKey: string;
  attempts: 0;
  createdAt: string;
  error: string;
  exchangeId: string;
  expiredAt: string;
  externalId: string;
  id: number;
  pair: string;
  price: string;
  side: OrderSideType;
  status: string;
  triggerCompare: string;
  triggerPrice: string;
  updatedAt: string;
}

export interface AlgoStatus {
  lastPrice: {
    price: number;
    updatedAt: string;
  };

  order: CreatedAlgoOrder;
}
