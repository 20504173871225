import { SwitchLabel } from "src/components/shared/Switches/style";
import styled from "styled-components";
import { ModesSwitch } from "../../../../Limit/LimitPanel/style";

export const CounterSwitch = styled(ModesSwitch)`
  ${SwitchLabel} {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes["14px"]};
  }
`;
