import { Observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { Extends } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategyModuleCreateType, StrategyModuleTypes } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";

type ModulesWithBotUUID = Extends<StrategyModuleTypes, "bot_status">;

export const BotUUIDField = () => {
  const state = useLateInitContext(CreateModulesContext);

  const { control } = useFormContext<IStrategyModuleCreateType<ModulesWithBotUUID>>();

  useEffect(() => {
    state.getBotList();
  }, [state]);

  return (
    <Controller
      control={control}
      name="hard_params.bot_uuid"
      rules={{ required: ERRORS_MSGS.isRequired }}
      render={({ field: { value, onChange }, formState: { errors } }) => {
        const error = getFieldError(errors, "hard_params.bot_uuid");
        return (
          <Observer>
            {() => (
              <LabeledSelector
                label="Bot"
                menuPosition="fixed"
                options={state.botList}
                onChange={(data) => onChange(data?.value)}
                value={state.getSelectBot(value)}
                errorHint={error?.message}
              />
            )}
          </Observer>
        );
      }}
    />
  );
};
