import { createLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionProps } from "src/permissions/PermissionWrapper";
import MultiGridStore from "src/state/CEX/multiGrid";
import ActiveMultiGridStore from "src/state/CEX/multiGrid/ActiveMultiGrid";
import CreateMultiGridStore from "src/state/CEX/multiGrid/CreateMultiGrid";
import ModulesStore from "src/state/CEX/multiGrid/Modules";
import MultiGridStateStore from "src/state/CEX/multiGrid/MultiGridState";

export const MultiGridContext = createLateInitContext<MultiGridStore>();

export const ActiveMultiGridContext = createLateInitContext<ActiveMultiGridStore>();

export const CreateMultiGridContext = createLateInitContext<CreateMultiGridStore>();

export const ModuleListContext = createLateInitContext<ModulesStore>();

export const MultiGridStateContext = createLateInitContext<MultiGridStateStore>();

export const MultiGridAbilitiesContext = createLateInitContext<PermissionProps>();
