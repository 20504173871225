import { observer } from "mobx-react-lite";
import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

interface Props {
  showLoader: boolean;
  party: string;
}

const NAME_INFO = `Name:
custom bot name`;

export const NamePanel = observer(({ party, showLoader }: Props) => {
  const state = useLateInitContext(CEXSettingsContext);

  return (
    <SettingsPanel
      label="Name"
      party={party}
      loader={showLoader}
      submitHandler={state.submitFieldHandler("name")}
      info={NAME_INFO}
      saved={state.savedModuls.name}
      style={{ gridArea: "name" }}
    >
      <styles.Wrapper>
        <LabeledInput
          label="Name"
          value={state.data.name}
          onChange={state.getHandler("name")}
          errorHint={state.errors.name}
        />
      </styles.Wrapper>
    </SettingsPanel>
  );
});
