import { StyledProps } from "src/helpers/styled/types";
import styled from "styled-components";

interface PNLV2LabelProps {
  colored?: boolean;
  color?: string;
}

interface StyledPNLV2LabelProps extends StyledProps<PNLV2LabelProps> {}

export const StyledPNLV2Label = styled.span<StyledPNLV2LabelProps>`
  white-space: nowrap;

  color: ${({ theme, $colored, $color }) => ($colored ? $color : theme.dashboard.textSecondary)};

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 0.8em;
  letter-spacing: 0.2px;
`;
