import styled from "styled-components";

export const FormContainer = styled.form`
  display: flex;
  align-items: center;

  gap: 5px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  gap: 5px;
`;

export const DatePickerWrapper = styled(Wrapper)`
  & :first-child {
    flex-shrink: 1.2;
  }
`;
