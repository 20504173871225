import { Currency, CurrencyAmount, TradeType } from "@uniswap/sdk-core";
import { providers } from "ethers";
import { invariant } from "mobx-utils";
import { logDev } from "src/helpers/network/logger";
import { SwapV3DEXType, isLynexDEX } from "src/state/DEXV2/DEXV2Swap/utils";
import { ICache, MapCache } from "src/state/shared/Cache";
import { V3Route } from "../entities/V3Route";
import {
  GetQuoteExactOutputSingleParams,
  GetQuoteExactSingleParams,
  V3QuoteResult,
  V3QuoterContract,
} from "../entities/contracts/V3QuoterContract";
import { QuotesError } from "../error/Error";

type GetQuoteParams =
  | (GetQuoteExactSingleParams & { method: "quoteExactInputSingle" })
  | (GetQuoteExactOutputSingleParams & { method: "quoteExactOutputSingle" });

export interface IV3QuotesProvider {
  getQuote: <TInput extends Currency, TOutput extends Currency>(
    route: V3Route<TInput, TOutput>,
    amount: CurrencyAmount<TInput | TOutput>,
    tradeType: TradeType
  ) => Promise<V3QuoteResult | null>;
}

export interface IV3QuotesProviderParams {
  chainId: string;
  quoterAddress: string;
  dexName: string;
  provider: providers.JsonRpcProvider;
}

export class V3QuotesProvider implements IV3QuotesProvider {
  private _provider: providers.JsonRpcProvider;

  private _chainId: string;

  private _quotersContractsCache: ICache<V3QuoterContract>;

  private _quoterAddress: string;

  private _dexName: string;

  constructor({ provider, chainId, quoterAddress, dexName }: IV3QuotesProviderParams) {
    this._provider = provider;

    this._chainId = chainId;

    this._quoterAddress = quoterAddress;

    this._dexName = dexName;

    this._quotersContractsCache = new MapCache();
  }

  private _getQuotersCacheKey = (chainId: string, address: string) =>
    `quoter-${chainId}-${address}`;

  private _getQuoterContract = () => {
    const provider = this._provider;

    const quoterAddress = this._quoterAddress;

    if (!provider || !quoterAddress) {
      return null;
    }

    const dexName = this._dexName;

    const dexType = isLynexDEX(dexName) ? SwapV3DEXType.LYNEX : SwapV3DEXType.UNISWAP;

    const contract = new V3QuoterContract(quoterAddress, provider, dexType);

    return contract;
  };

  private _getCachedQuoterContract = () => {
    const address = this._quoterAddress;

    if (!address) {
      return null;
    }

    const quoterKey = this._getQuotersCacheKey(this._chainId, address);

    const cachedContract = this._quotersContractsCache.get(quoterKey);
    if (cachedContract) {
      return cachedContract;
    }

    const contract = this._getQuoterContract();
    if (contract) {
      this._quotersContractsCache.set(quoterKey, contract);
    }
    return contract;
  };

  private _getQuoteParams = <TInput extends Currency, TOutput extends Currency>(
    route: V3Route<TInput, TOutput>,
    amount: CurrencyAmount<TInput | TOutput>,
    tradeType: TradeType
  ): GetQuoteParams => {
    const quoteAmount = amount.quotient.toString();

    const quoteParams: GetQuoteParams = {
      tokenIn: route.tokenPath[0].address,
      tokenOut: route.tokenPath[1].address,
      fee: route.pools[0].fee,
      sqrtPriceLimitX96: 0,
      ...(tradeType === TradeType.EXACT_INPUT
        ? { amountIn: quoteAmount, method: "quoteExactInputSingle" }
        : { amount: quoteAmount, method: "quoteExactOutputSingle" }),
    };

    return quoteParams;
  };

  private _getQuote = async (
    quoter: V3QuoterContract,
    quoteParams: GetQuoteParams
  ): Promise<V3QuoteResult> => {
    if (quoteParams.method === "quoteExactInputSingle") {
      const { method, ...params } = quoteParams;

      const quoteResult = await quoter.quoteExactInputSingle(params);

      return quoteResult;
    }

    const { method, ...params } = quoteParams;

    const quoteResult = await quoter.quoteExactOutputSingle(params);

    return quoteResult;
  };

  getQuote = async <TInput extends Currency, TOutput extends Currency>(
    route: V3Route<TInput, TOutput>,
    amount: CurrencyAmount<TInput | TOutput>,
    tradeType: TradeType
  ) => {
    invariant(route.pools.length === 1, "Multi-hop quotes not supported");

    const quoterContract = this._getCachedQuoterContract();

    if (!quoterContract) {
      throw new QuotesError(`Quoter contract not found for the chain ${this._chainId}!`);
    }

    try {
      const quote = await this._getQuote(
        quoterContract,
        this._getQuoteParams(route, amount, tradeType)
      );

      logDev(["getQuote", quote]);

      return quote;
    } catch (err) {
      throw new QuotesError("Failed to get quote");
    }
  };
}
