import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { DEXV2_HEADER_INFO_CELLS } from "src/components/BotsContent/DEX_V2/DEXV2BotInfo";
import { Loader } from "src/components/shared/Loader";
import { joinMarket } from "src/helpers/botName";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useAppState } from "src/state";
import { useBotsVirtualizer } from "../../hooks/useBotsVirtualizer";
import { Container, Content, ContentScrollWrapper } from "../shared/shared";
import * as styles from "./style";

export const DEXV2_HEADER_CELLS = [
  { title: "Pair", icon: "" },
  { title: "Exchange", icon: "" },
  { title: "Last trade", icon: "" },
  { title: "Next trade", icon: "" },
  { title: "Message", icon: "" },
];

const MOBILE_COMPACT_QUERY = getMobileMediaQuery("900px");

export const DEXV2Bots = observer(() => {
  const {
    dexV2BotsState: state,
    allBotsState: { realtimeEnabled },
  } = useAppState();

  const showMobile = useMediaQuery(getMobileMediaQuery("1050px"));

  const {
    scrollParentRef,
    virtualizer: botsVirtualizer,
    getVirtualRowStyles,
  } = useBotsVirtualizer({
    heights: { mobile: 85, desktop: 88 },
    isMobile: showMobile,
    size: state.bots.length,
  });

  const getBotInfoContainer = useCallback(() => scrollParentRef.current, [scrollParentRef]);

  useEffect(() => {
    if (realtimeEnabled) {
      state.resumeBotsFetching();
    } else {
      state.suspendBotsFetching();
    }

    return () => {
      if (realtimeEnabled) {
        state.suspendBotsFetching();
      }
    };
  }, [realtimeEnabled, state]);

  const bots = botsVirtualizer.virtualItems.map((virtualRow) => {
    const bot = state.bots[virtualRow.index];
    const { base, base_addr, quote, quote_addr, ...botProps } = bot;
    const { bot_uuid, name, exchange } = bot;

    const statsLink = `/DEX_V2/${bot.bot_uuid}/?tab=STATS`;

    const market = joinMarket({ quote, base, exchange });

    const credential = {
      market,
      name,
      UUID: bot_uuid,
      link: statsLink,
    };

    return showMobile ? (
      <styles.DEXV2BotMobMain
        key={bot_uuid}
        favorite
        toggleIsWatched={state.toggleIsWatched}
        compactQuery={MOBILE_COMPACT_QUERY}
        credential={credential}
        getInfoContainer={getBotInfoContainer}
        {...botProps}
        {...getVirtualRowStyles(virtualRow)}
      />
    ) : (
      <styles.DEXV2BotMain
        key={bot_uuid}
        favorite
        toggleIsWatched={state.toggleIsWatched}
        credential={credential}
        getInfoContainer={getBotInfoContainer}
        {...botProps}
        {...getVirtualRowStyles(virtualRow)}
      />
    );
  });

  return (
    <Container>
      {!showMobile ? (
        <styles.DEXV2HeaderMain
          favorite
          toggleWatchList={state.toggleWatchList}
          watchListEnabled={state.watchListEnabled}
          cells={DEXV2_HEADER_INFO_CELLS}
        />
      ) : null}
      <Content ref={scrollParentRef}>
        <ContentScrollWrapper $height={botsVirtualizer.totalSize}>{bots}</ContentScrollWrapper>
      </Content>
      <Loader show={state.loading && state.firstLoad} />
    </Container>
  );
});
