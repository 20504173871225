import { CurrencyAmount, NativeCurrency, Token } from "@uniswap/sdk-core";
import { IReactionDisposer, makeAutoObservable, when } from "mobx";
import { computedFn } from "mobx-utils";
import { getData } from "src/helpers/forms/getByKey";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { Extends, IDisposable } from "src/helpers/utils";
import {
  IBotChainInfoProvider,
  IBotTradePairProvider,
  ISwapPairAddressProvider,
} from "../../DEXV2Bots/DEXV2BotStore";
import {
  ISwapInfoGasEstimate,
  SwapInfoGasEstimateStore,
} from "../../DEXV2Swap/SwapModules/SwapInfo/SwapInfoGasEstimateStore";
import { IBaseUSDPriceProvider } from "../../DEXV2Swap/SwapModules/shared/Providers/BaseUsdPriceProvider";
import { IGasPriceProvider } from "../../DEXV2Swap/SwapModules/shared/Providers/GasPriceProvider";
import { ISwapSettingsProvider } from "../../DEXV2Swap/SwapModules/shared/Providers/SwapSettingsProvider";
import { CacheOptions } from "../../DEXV2Swap/utils";
import { INativeUSDPriceProvider } from "../../Providers/NativeUSDPriceProvider";
import { DEXV2SettingsKeys } from "../../types";
import { IDEXV2SettingsProvider } from "../DEXV2SettingsInfoStore";
import { parseBaseQuote } from "../utils";
import { HistoryFeesStore, IHistoryFees } from "./HistoryFeesStore";
import { LimitInfoStore } from "./LimitInfoStore";
import { DEXV2ModeImpactInfo } from "./PriceImpactInfo/ModeImpactInfoStore";
import { VolumeInfoStore } from "./VolumeInfoStore";

export interface DEXV2BaseInfo {
  gasEstimate: {
    usd?: CurrencyAmount<Token>;
    native?: CurrencyAmount<NativeCurrency>;
  };
  last24hFees: {
    usd?: CurrencyAmount<Token>;
    native?: CurrencyAmount<NativeCurrency>;
  };
  priceImpact: DEXV2ModeImpactInfo;
}

export interface DEXV2VolumeInfo extends DEXV2BaseInfo {
  averageVolume: {
    base?: CurrencyAmount<Token>;
    usd?: CurrencyAmount<Token>;
  };
}

export interface DEXV2LimitInfo extends DEXV2BaseInfo {}

export type BaseQuoteSettingsKeys = Extends<
  DEXV2SettingsKeys,
  | "volume_data.min_amount"
  | "volume_data.max_amount"
  | "limit_data.max_amount"
  | "limit_data.min_amount"
>;

interface IDEXV2InfoParams {
  swapSettingsProvider: ISwapSettingsProvider;
  settingsProvider: IDEXV2SettingsProvider;
  gasPriceProvider: IGasPriceProvider;
  nativeUSDPriceProvider: INativeUSDPriceProvider;
  tradePairProvider: IBotTradePairProvider;
  baseUSDPriceProvider: IBaseUSDPriceProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  botChainInfoProvider: IBotChainInfoProvider;
}

export class DEXV2InfoStore implements IDisposable {
  private _nativeUSDPriceProvider: INativeUSDPriceProvider;

  private _gasPriceProvider: IGasPriceProvider;

  private _baseUSDPriceProvider: IBaseUSDPriceProvider;

  private _gasEstimateState: ISwapInfoGasEstimate & IDisposable;

  private _historyFeesState: IHistoryFees & IDisposable;

  private _tradePairProvider: IBotTradePairProvider;

  private _initializedReaction: IReactionDisposer;

  private _limitInfoState: LimitInfoStore;

  private _volumeInfoState: VolumeInfoStore;

  private _settingsProvider: IDEXV2SettingsProvider;

  constructor(params: IDEXV2InfoParams) {
    makeAutoObservable(this, { baseSettingsQuotes: false });

    const {
      settingsProvider,
      swapSettingsProvider,
      gasPriceProvider,
      nativeUSDPriceProvider,
      tradePairProvider,
      baseUSDPriceProvider,
      pairAddressProvider,
      botChainInfoProvider,
    } = params;

    this._nativeUSDPriceProvider = nativeUSDPriceProvider;

    this._gasPriceProvider = gasPriceProvider;

    this._baseUSDPriceProvider = baseUSDPriceProvider;

    this._tradePairProvider = tradePairProvider;

    this._settingsProvider = settingsProvider;

    this._gasEstimateState = new SwapInfoGasEstimateStore({
      gasLimitProvider: swapSettingsProvider,
      nativeUSDPriceProvider,
      gasPriceProvider,
    });

    this._historyFeesState = new HistoryFeesStore({
      botUUIDProvider: swapSettingsProvider,
      nativeUSDPriceProvider,
    });

    this._limitInfoState = new LimitInfoStore({
      settingsProvider,
      swapSettingsProvider,
      tradePairProvider,
      pairAddressProvider,
      botChainInfoProvider,
    });

    this._volumeInfoState = new VolumeInfoStore({
      settingsProvider,
      swapSettingsProvider,
      tradePairProvider,
      baseUSDPriceProvider,
      pairAddressProvider,
      botChainInfoProvider,
    });

    this._initializedReaction = when(
      () => this._initialized,
      () => {
        this.getVolumeInfo();
      }
    );

    makeLoggable(this, { limitInfo: true, volumeInfo: true });
  }

  private get _initialized() {
    return Boolean(this._tradePair);
  }

  private get _settings() {
    return this._settingsProvider.settings;
  }

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private get _baseUSDPrice() {
    return this._baseUSDPriceProvider.baseUSDPrice;
  }

  private get _baseToken() {
    return this._tradePair?.base;
  }

  private get _gasEstimate() {
    return this._gasEstimateState.gasEstimate;
  }

  private get _gasEstimateUSD() {
    return this._gasEstimateState.gasEstimateUSD;
  }

  private get _limitLastFees() {
    return this._historyFeesState.historyFees("limit");
  }

  private get _volumeLastFees() {
    return this._historyFeesState.historyFees("volume");
  }

  get limitInfo(): DEXV2LimitInfo {
    const baseInfo = this._limitInfoState.info;

    return {
      ...baseInfo,
      gasEstimate: {
        usd: this._gasEstimateUSD,
        native: this._gasEstimate,
      },
      last24hFees: this._limitLastFees,
    };
  }

  get volumeInfo(): DEXV2VolumeInfo {
    const baseInfo = this._volumeInfoState.info;

    return {
      ...baseInfo,
      gasEstimate: {
        usd: this._gasEstimateUSD,
        native: this._gasEstimate,
      },
      last24hFees: this._volumeLastFees,
    };
  }

  baseSettingsQuotes = computedFn((key: BaseQuoteSettingsKeys) => {
    const baseValue = getData(this._settings, key);
    const baseUSDPrice = this._baseUSDPrice;
    const baseToken = this._baseToken;

    const baseQuote = parseBaseQuote(baseValue, baseToken, baseUSDPrice);
    return baseQuote;
  });

  private _refreshGasPrice = async (options?: CacheOptions) => {
    await this._gasPriceProvider.getGasPrice(options);
  };

  private _refreshTokenPrice = async (options?: CacheOptions) => {
    await this._baseUSDPriceProvider.getBaseUSDPrice(options);
  };

  private _refreshNativeUSDPrice = async (options?: CacheOptions) => {
    await this._nativeUSDPriceProvider.getNativeUSDPrice(options);
  };

  private _refreshLast24hFees = async () => {
    await this._historyFeesState.getHistoryFees();
  };

  getVolumeInfo = async (options?: CacheOptions) => {
    if (!this._initialized) return;
    try {
      await Promise.all([
        this._refreshLast24hFees(),
        this._refreshGasPrice(options),
        this._refreshTokenPrice(options),
        this._refreshNativeUSDPrice(options),
      ]);
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {
    this._initializedReaction();
    this._historyFeesState.destroy();
    this._gasEstimateState.destroy();
    this._limitInfoState.destroy();
    this._volumeInfoState.destroy();
  };
}
