import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { CheckBox } from "src/components/shared/CheckBox";
import { BindingsBotProvider } from "src/context/CEX/BindingsBotProvider";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { useFindWidth } from "src/hooks/useFindWidth";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import ExchangeStore from "src/state/CEX/CEXExchange";
import WindowConsent from "src/state/WindowConsent";
import { BotInfoProps } from "..";
import { Balances } from "../Exchange/Balances";
import { TerminalPnL } from "../Exchange/TerminalPnL";
import { FinanceWrapper } from "../Exchange/shared";
import { CandleCloser } from "./Settings/CandleCloser";
import { Decimals } from "./Settings/Decimals";
import { LinkToPairPage } from "./Settings/LinkToPairPage";
import { NamePanel } from "./Settings/NamePanel";
import { PairSettings } from "./Settings/PairSettings";
import { PeriodSettings } from "./Settings/PeriodSettings";
import { SpreadSettings } from "./Settings/SpreadSettings";
import { TradeSettings } from "./Settings/TradeSettings";
import { VerifyOrderSettings } from "./Settings/VerifyOrderSettings";
import { VolumeSettings } from "./Settings/VolumeSettings";
import { VirtWalls } from "./VirtWalls";
import * as styles from "./style";

export interface CEXBotSettingsProps extends BotInfoProps {}

const MENU_ITEMS = [
  { label: "Pair", value: "pair" },
  { label: "Binding", value: "binding" },
  { label: "Period", value: "period" },
  { label: "Decimals", value: "decimals" },
  { label: "Trade", value: "trade" },
  { label: "Volume", value: "volume" },
  { label: "Spread", value: "spread" },
  { label: "Verify", value: "verify" },
];

export const CEXBotSettings = observer(({ botInfo }: CEXBotSettingsProps) => {
  const { bot_uuid, market, party = "" } = botInfo;
  const state = useLateInitContext(CEXSettingsContext);

  const exchangeState = useMemo(() => new ExchangeStore(state), [state]);

  const showTabs = useFindWidth(450);

  useEffect(() => {
    if (!bot_uuid) return;
    if (!market) return;
    state.setBotUUID(bot_uuid);
    state.setMarket(market);
    exchangeState.setUUID(bot_uuid);

    state.getData();

    exchangeState.setMarket(market);

    exchangeState.downloadSettingsPageData();
  }, [state, exchangeState, market, bot_uuid]);

  const openModal = () => {
    if (state.data.saveDiff) {
      WindowConsent.showWindow(
        "You agree to the terms of the transaction AllDiffForm",
        "Are you sure? This action  may cause problems!",
        state.toggleSaveDiffPair
      );
    } else state.toggleSaveDiffPair();
  };

  if (showTabs) {
    return (
      <ExchangeContext.Provider value={exchangeState}>
        <styles.BotSettingsForm
          // onSubmit={state.submitHandler()}
          onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        >
          <styles.TabSettings menuItems={MENU_ITEMS} allFilter defaultKey="all" type="spread">
            <styles.PeriodWrapper key="period">
              <PeriodSettings showLoader={state.loader} party={party} />
              <LinkToPairPage showLoader={state.loader} party={party} />
              <NamePanel showLoader={state.loader} party={party} />
            </styles.PeriodWrapper>

            <Decimals key="decimals" showLoader={state.loader} party={party} />

            <PairSettings key="pair" showLoader={state.loader} party={party} />

            <VolumeSettings key="volume" showLoader={state.loader} party={party} />

            <TradeSettings key="trade" showLoader={state.loader} party={party} />

            <SpreadSettings key="spread" party={party} showLoader={state.loader} />

            <VerifyOrderSettings key="verify" party={party} showLoader={state.loader} />

            <CandleCloser key="candle" party={party} showLoader={state.loader} />

            <BindingsBotProvider key="binding">
              <VirtWalls botInfo={botInfo} />
            </BindingsBotProvider>
          </styles.TabSettings>

          <CheckBox
            label="Save Diff for All Pairs on this Exchange"
            checked={state.data.saveDiff}
            onChange={openModal}
            style={{ gridArea: "saveDiff" }}
          />
          <PermissionWrapper abilityName="cex-settings-edit" party={party}>
            <Button stretched onClick={state.submitHandler()} style={{ gridArea: "submit" }}>
              Submit all
            </Button>
          </PermissionWrapper>
        </styles.BotSettingsForm>
      </ExchangeContext.Provider>
    );
  }

  return (
    <ExchangeContext.Provider value={exchangeState}>
      <styles.BotSettingsForm
        // onSubmit={state.submitHandler()}
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
      >
        <PeriodSettings showLoader={state.loader} party={party} />

        <LinkToPairPage showLoader={state.loader} party={party} />

        <NamePanel showLoader={state.loader} party={party} />

        <Decimals showLoader={state.loader} party={party} />

        <PairSettings showLoader={state.loader} party={party} />

        <VolumeSettings showLoader={state.loader} party={party} />

        <TradeSettings showLoader={state.loader} party={party} />

        <SpreadSettings showLoader={state.loader} party={party} />

        <VerifyOrderSettings showLoader={state.loader} party={party} />

        <CandleCloser key="candle" party={party} showLoader={state.loader} />

        <BindingsBotProvider>
          <VirtWalls botInfo={botInfo} />
        </BindingsBotProvider>

        <FinanceWrapper>
          <PermissionWrapper party={botInfo.party} abilityName="cex-stats-view">
            <TerminalPnL botInfo={botInfo} isShortView />
          </PermissionWrapper>

          <Balances
            style={{ height: "calc(100% - 80px)" }}
            switchAccount={exchangeState.switchAccountSettingsPage}
            updAcc={exchangeState.updHandlers.updBalances}
          />
        </FinanceWrapper>

        <CheckBox
          label="Save Diff for All Pairs on this Exchange"
          checked={state.data.saveDiff}
          onChange={openModal}
          style={{ gridArea: "saveDiff" }}
        />
        <PermissionWrapper abilityName="cex-settings-edit" party={party}>
          <Button stretched onClick={state.submitHandler()} style={{ gridArea: "submit" }}>
            Submit all
          </Button>
        </PermissionWrapper>
      </styles.BotSettingsForm>
    </ExchangeContext.Provider>
  );
});
