import { Button, ButtonProps } from "../../Button";

export interface SubmitButtonProps extends Omit<ButtonProps, "variant"> {}

export const SubmitButton = ({
  size = "l",
  type = "submit",
  children = "Submit",
  ...props
}: SubmitButtonProps) => (
  <Button size={size} type={type} {...props}>
    {children}
  </Button>
);
