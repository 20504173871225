import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { Headline3 } from "src/components/shared/Typography";
import { StrategyListContext } from "src/context/CEX/ExpertSystem";
import { AbilitiesContext } from "src/context/shared/Abilities";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { TabContent, TabHeader, TablePagination, Wrapper } from "../shared/style";
import { CreationStrategyModal } from "./CreationStrategyModal";
import { Filters } from "./Filters";
import { StrategiesTable } from "./StrategiesTable";

export const Strategies = observer(() => {
  const state = useLateInitContext(StrategyListContext);
  const { abilityName } = useLateInitContext(AbilitiesContext);

  useEffect(() => {
    if (!state.party) return;

    state.getStrategies(0);
  }, [state, state.party]);

  return (
    <TabContent>
      <TabHeader>
        <Headline3>Strategies</Headline3>

        <Wrapper>
          <ReloadButton onClick={state.refreshStrategies} />
          <Filters />

          <PermissionWrapper party={state.party} abilityName={abilityName}>
            <CreationStrategyModal />
          </PermissionWrapper>
        </Wrapper>
      </TabHeader>

      <StrategiesTable />

      <TablePagination
        itemsPerPage={10}
        getItems={state.getStrategies}
        pageCount={state.pagesCount}
        onForcePageChange={state.setCurrentPage}
        forcePage={state.currentPage}
      />
    </TabContent>
  );
});
