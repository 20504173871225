import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 4px 8px;

  box-shadow: ${({ theme }) => `0 1px 0 0 ${theme.inputBorderColor}`};

  :last-of-type {
    box-shadow: none;
  }

  :hover {
    background-color: #dfe1e9;

    border-radius: ${({ theme }) => theme.radii["4px"]};
  }
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;
