import { LabelValue } from "src/state/MarketCheck/types";
import { formatPercent } from "src/helpers/string";
import { getRounded } from "../helpers";
import * as styles from "./style";

interface AssetCellProps extends LabelValue {
  percent?: string;
}

export const AssetCell = ({ label, value, percent }: AssetCellProps) => (
  <>
    <styles.AssetLabel>{label}:</styles.AssetLabel>

    <styles.AssetValue>
      {getRounded(value)}

      {percent !== undefined && (
        <styles.AssetPercent> ({formatPercent(percent)})</styles.AssetPercent>
      )}
    </styles.AssetValue>
  </>
);
