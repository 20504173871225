import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { ChooseSettingParamsContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ActionChangeSettingsStore } from "src/state/expertSystem/strategies/changeSettingsModule/ActionChangeSettingsStore";
import { FormRows } from "./FormRows";

export interface FormContentProps {
  moduleIndex: number;
  setModuleParam: UseFormSetValue<IStrategyCreator>;
  softParam: string;
}

export const FormContent = observer(
  ({ softParam, moduleIndex, setModuleParam }: FormContentProps) => {
    const state = useLateInitContext(ChooseSettingParamsContext);
    const {
      defaultSettingsConfig,
      setModuleSoftParamSetter,
      setModuleIndex,
      generateSettingsConfig,
      getAddedParamStatus,
      removeConfigParam,
    } = useLocalStore(ActionChangeSettingsStore, state, softParam);

    useEffect(() => {
      setModuleSoftParamSetter(setModuleParam);
      setModuleIndex(moduleIndex);
    }, [setModuleParam, setModuleSoftParamSetter, setModuleIndex, moduleIndex]);

    return (
      <FormRows
        defaultConfig={defaultSettingsConfig}
        generateSettings={generateSettingsConfig}
        getParamStatus={getAddedParamStatus}
        removeConfigParam={removeConfigParam}
      />
    );
  }
);
