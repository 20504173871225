import { ComponentPropsWithoutRef } from "react";
import { Milestone } from "./Milestone";
import * as styles from "./style";

export interface RoadMapItem {
  value: string;
  label: string;
}

interface RoadMapProps extends ComponentPropsWithoutRef<"div"> {
  items: RoadMapItem[];
  currentStep: number;
}

export const RoadMap = ({ items, currentStep, ...props }: RoadMapProps) => (
  <styles.Container {...props}>
    {items.map((el, index) => (
      <Milestone key={el.value} step={index} currentStep={currentStep} label={el.label} />
    ))}
  </styles.Container>
);
