import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { ToggleSwitchProps } from "src/components/shared/ToggleSwitch";
import { getChangeEventValue } from "src/helpers/forms/inputs";

export interface ControllerWrapperProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  label?: string;
  control: Control<TForm>;
  name: TName;
  rules?: Omit<
    RegisterOptions<TForm, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  ignoreType?: boolean;
  component: React.ComponentType<ToggleSwitchProps>;
}

export const ControllerWrapper = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  label,
  name,
  component: ToggleComponent,
  ignoreType = false,
  ...props
}: ControllerWrapperProps<TForm, TName>) => (
  <Controller
    name={name}
    {...props}
    render={({ field: { value, onChange } }) => (
      <ToggleComponent
        label={label}
        checked={value}
        onChange={(e) => onChange(getChangeEventValue(e, ignoreType))}
      />
    )}
  />
);
