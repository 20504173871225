import { useEventCallback } from "@mui/material";
import { Dayjs } from "dayjs";
import { useRef, useState } from "react";
import { DateTimeRangeValidationError } from "../../shared/hooks/useDateTimeValidation";
import {
  DateTimeRangeMinMaxPropsKeys,
  UseMultiInputDateTimeRangeFieldProps,
  useDefaultizedDateTimeMinMaxProps,
  useMultiInputDateTimeRangeField,
} from "../../shared/hooks/useMultiInputDateTimeRangeField";
import { DateTimeRange } from "../../shared/models/dateTimeRange";
import {
  HumanizeDateTimeRangeProps,
  HumanizedDateTimeRangeValidationError,
  humanizeRangeError,
} from "../../shared/utils/error-utils";
import { DateTimeField, DateTimeFieldProps } from "../DateTimeField";
import * as styles from "./style";

interface UseRangeErrorParams
  extends Pick<DateTimeRangeFieldProps, "onError">,
    Omit<HumanizeDateTimeRangeProps<Dayjs>, "value"> {}

interface UseRangeErrorResponse extends Required<Pick<DateTimeRangeFieldProps, "onError">> {
  rangeError: HumanizedDateTimeRangeValidationError;
}

const useRangeError = ({ onError, ...props }: UseRangeErrorParams): UseRangeErrorResponse => {
  const [rangeError, setRangeError] = useState<HumanizedDateTimeRangeValidationError>([
    undefined,
    undefined,
  ]);

  const onRangeError = useEventCallback(
    (error: DateTimeRangeValidationError, value: DateTimeRange) => {
      const humanizedError = humanizeRangeError(error, { value, ...props });
      setRangeError(humanizedError);
      onError?.(error, value);
    }
  );
  return { rangeError, onError: onRangeError };
};

const useRangeErrorProps = (props: DateTimeRangeFieldInternalOwnProps): UseRangeErrorParams => {
  const minMaxProps = useDefaultizedDateTimeMinMaxProps(props);

  return {
    disableFuture: props.disableFuture,
    onError: props.onError,
    ...minMaxProps,
  };
};

type UseMultiInputDateTimeRangeFieldComponentProps<TDate, TChildProps extends {}> = Omit<
  TChildProps,
  keyof UseMultiInputDateTimeRangeFieldProps<TDate>
> &
  UseMultiInputDateTimeRangeFieldProps<TDate>;

interface DateTimeRangeFieldInternalOwnProps
  extends Pick<
    UseMultiInputDateTimeRangeFieldComponentProps<Dayjs, {}>,
    | "onChange"
    | "value"
    | "onError"
    | "ampm"
    | "disableFuture"
    | "format"
    | "timezone"
    | DateTimeRangeMinMaxPropsKeys
  > {}

interface DateTimeRangeFieldOwnProps
  extends Omit<
    DateTimeRangeFieldInternalOwnProps,
    "minTime" | "maxTime" | "minDateTime" | "maxDateTime"
  > {}

const useDateTimeRangeFieldInternalProps = ({
  minDate,
  maxDate,
  ...props
}: DateTimeRangeFieldOwnProps): DateTimeRangeFieldInternalOwnProps => ({
  ...props,
  minDateTime: minDate,
  maxDateTime: maxDate,
});

export interface DateTimeRangeFieldProps
  extends DateTimeRangeFieldOwnProps,
    Pick<DateTimeFieldProps, "picker"> {}

export const DateTimeRangeField = ({ picker, ...props }: DateTimeRangeFieldProps) => {
  const inputStartRef = useRef<HTMLInputElement>(null);
  const inputEndRef = useRef<HTMLInputElement>(null);

  const fieldProps = useDateTimeRangeFieldInternalProps(props);

  const rangeErrorProps = useRangeErrorProps(fieldProps);

  const {
    rangeError: [startError, endError],
    onError: onRangeError,
  } = useRangeError(rangeErrorProps);

  const { startDate, endDate } = useMultiInputDateTimeRangeField({
    sharedProps: { ...fieldProps, onError: onRangeError },
    startTextFieldProps: {},
    endTextFieldProps: {},
    startInputRef: inputStartRef,
    endInputRef: inputEndRef,
  });

  const { ref: startInputRef, ...startDateProps } = startDate;
  const { ref: endInputRef, ...endDateProps } = endDate;

  return (
    <styles.Container>
      <DateTimeField
        label="From"
        errorHint={startError}
        {...startDateProps}
        inputRef={startInputRef}
        picker={picker}
      />
      <DateTimeField
        label="To"
        errorHint={endError}
        {...endDateProps}
        inputRef={endInputRef}
        picker={picker}
      />
    </styles.Container>
  );
};
