import { makeAutoObservable, observable } from "mobx";
import { IDisposable } from "src/helpers/utils";
import { DEXV2ExchangeVersion } from "src/modules/bots";
import { ISwapVersionProvider } from "../../Version/DEXV2SwapVersionStore";

export type INonNullableVersionProvider = {
  [K in keyof ISwapVersionProvider]: NonNullable<ISwapVersionProvider[K]>;
};

type GetVersionedState<P, S> = (version: DEXV2ExchangeVersion, params: P) => S;

type IVersionedSwapStateParams<P, S> = P & {
  versionProvider: INonNullableVersionProvider;
  getVersionedState: GetVersionedState<P, S>;
};

export class VersionedSwapState<P, S> {
  private _versionProvider: INonNullableVersionProvider;

  private _currentState!: S;

  private _getVersionedState: GetVersionedState<P, S>;

  constructor({
    versionProvider,
    getVersionedState: getVersionsState,
    ...params
  }: IVersionedSwapStateParams<P, S>) {
    makeAutoObservable<this, "_currentState">(this, {
      _currentState: observable.ref,
    });

    this._versionProvider = versionProvider;

    this._getVersionedState = getVersionsState;

    const state = this._getState(this._version, params as P);

    this._setCurrentState(state);
  }

  private get _version() {
    return this._versionProvider.dexVersion;
  }

  private _getState = (version: DEXV2ExchangeVersion, params: P): S =>
    this._getVersionedState(version, params as P);

  private _setCurrentState = (state: S) => {
    this._currentState = state;
  };

  get state() {
    return this._currentState;
  }

  private _destroyState = () => {
    const state = this._currentState as Partial<IDisposable>;
    state.destroy?.();
  };

  destroy = () => {
    this._destroyState();
  };
}
