import styled from "styled-components";

export const Content = styled.div`
  display: grid;

  grid-template-columns: minmax(240px, 350px) minmax(240px, 400px) minmax(290px, 400px);
  grid-template-rows: 120px 173px 120px;

  grid-template-areas:
    "name       decimals    period"
    "exchange   volume      pair"
    "trade      trade       trade";

  gap: 15px;

  @media screen and (max-width: 870px) {
    grid-template-columns: repeat(2, 1fr);

    grid-template-rows: 120px 173px 120px 173px;

    grid-template-areas:
      "name       decimals  "
      "exchange   volume    "
      "trade      period    "
      "trade      pair      ";
  }

  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;

    grid-template-rows: 120px 120px 120px 173px 173px 173px 230px;

    grid-template-areas:
      "name     "
      "decimals "
      "period   "
      "exchange "
      "volume   "
      "pair     "
      "trade    ";
  }
`;
