import ChainIcons from "src/icons/ChainIcons";
import { IChainMeta } from "src/modules/chain";
import { ChainId } from "./chains";

export type ChainMetaConfig = Partial<Record<string, IChainMeta>>;

export const chainMetaConfig: ChainMetaConfig = {
  [ChainId.BSC]: {
    abbr: "BSC",
    icon: ChainIcons.bsc(),
    color: "#f0b90b",
  },
  [ChainId.BSCTestnet]: {
    abbr: "BSCTest",
    icon: ChainIcons.bsc(),
    color: "#f0b90b",
  },
  [ChainId.opBNB]: {
    abbr: "opBNB",
    icon: ChainIcons.opBNB(),
    color: "#f0b90b",
  },
  [ChainId.Ethereum]: {
    abbr: "Ethereum",
    icon: ChainIcons.eth(),
    color: "#62688f",
  },
  [ChainId.Avalanche]: {
    abbr: "Avalanche",
    icon: ChainIcons.avalanche(),
    color: "#e84142",
  },
  [ChainId.Fantom]: {
    abbr: "Fantom",
    icon: ChainIcons.fantom(),
    color: "#13b5ec",
  },
  [ChainId.Polygon]: {
    abbr: "Polygon",
    icon: ChainIcons.polygon(),
    color: "#8247e5",
  },
  [ChainId.Arbitrum]: {
    abbr: "Arbitrum",
    icon: ChainIcons.arbitrum(),
    color: "#2d374b",
  },
  [ChainId.Base]: {
    abbr: "Base",
    icon: ChainIcons.base(),
    color: "#0052ff",
  },
  [ChainId.zkSync]: {
    abbr: "zkSync",
    icon: ChainIcons.zkSync(),
    color: "#7a7af4",
  },
  [ChainId.Mantle]: {
    abbr: "Mantle",
    icon: ChainIcons.mantle(),
    color: "#65b3ae",
  },
  [ChainId.Core]: {
    abbr: "Core",
    icon: ChainIcons.core(),
    color: "#ff9500",
  },
  [ChainId.Kava]: {
    abbr: "Kava",
    icon: ChainIcons.kava(),
    color: "#ff433e",
  },
  [ChainId.Linea]: {
    abbr: "Linea",
    icon: ChainIcons.linea(),
    color: "#61dfff",
  },
  [ChainId.LineaTestnet]: {
    abbr: "LineaTest",
    icon: ChainIcons.linea(),
    color: "#61dfff",
  },
};
