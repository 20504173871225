import { observer } from "mobx-react-lite";
import { TeamsContext } from "src/context/UserManager/Groups";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Member } from "../Member";
import { getTeamThemeColor } from "../TeamsList/style";
import * as styles from "./style";

export interface TeamColorProps {
  accentColor: string;
}

export interface TeamProps {
  name: string;
}

export const Team = observer(({ name }: TeamProps) => {
  const state = useLateInitContext(TeamsContext);
  const { members, head, index } = state.teamByName(name);
  const accentColor = getTeamThemeColor(index);

  const headSpan = head ? (
    <>
      - <styles.HeadSpan accent={accentColor}>{head}</styles.HeadSpan>{" "}
    </>
  ) : null;

  return (
    <styles.TeamWrapper>
      <styles.Name>
        {name} {headSpan}
        <styles.CountSpan>({members.length})</styles.CountSpan>
      </styles.Name>
      <styles.MembersMasonry itemWidth={{ min: 100, max: 140 }}>
        {members.map((member) => (
          <Member key={member.name} accentColor={accentColor} team={name} {...member} />
        ))}
      </styles.MembersMasonry>
    </styles.TeamWrapper>
  );
});
