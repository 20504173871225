import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabelWrapper } from "src/components/shared/Forms/Label/style";
import styled from "styled-components";

export const StyledInfoInput = styled(LabeledInput)`
  ${LabelWrapper} {
    gap: 4px;
    justify-content: space-between;
  }
`;
