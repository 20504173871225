import { observer } from "mobx-react-lite";
import React from "react";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { Loader } from "src/components/shared/Loader";
import styled from "styled-components";
import { ReportItem } from "../style";

const BalancesGraphContainer = styled(ReportItem)`
  position: relative;
  /* grid-area: balances-graph; */
  /* max-height: 230px; */
  /* max-width: 365px; */
  min-width: 300px;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  grid-area: balances-graph;
`;

export const BalancesGraph = observer(({ showLoader, balanceTableData }) => (
  <BalancesGraphContainer>
    <Bar
      data={{
        labels: balanceTableData.map(({ currency }) => currency),
        datasets: [
          {
            label: "Δ%",
            data: [...balanceTableData.map(({ deltaPercent }) => deltaPercent)],
            backgroundColor: [
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
              "rgba(255, 159, 64, 0.6)",
            ],
          },
        ],
      }}
      options={{
        indexAxis: "y",
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          yAxis: {
            position: "right",
          },
        },
      }}
    />
    <Loader show={showLoader} />
  </BalancesGraphContainer>
));
