import { PromptMsg } from "src/components/shared/shared";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import { useId } from "src/hooks/useId";
import Icons from "src/icons/Icons";
import { GraphRootProps } from "..";
import { SELECTION_CANVAS_TEXT, TOOLTIP_DATA_TEXT } from "../constants";
import { InfoWrapper } from "../style";

interface UseGraphActionsInfoParams
  extends Pick<GraphRootProps, "showTooltip" | "allowTimeScale" | "showInfo"> {}

/**
 * Custom hook for retrieving graph tooltip and scale info.
 *
 * @returns The graph actions information.
 */
export const useGraphActionsInfo = ({
  showTooltip = true,
  allowTimeScale = true,
  showInfo = true,
}: UseGraphActionsInfoParams = {}) => {
  const id = useId(4);

  if (!showInfo) return null;

  const tooltipInfo = showTooltip && TOOLTIP_DATA_TEXT;
  const selectionInfo = allowTimeScale && SELECTION_CANVAS_TEXT;
  const info = filterBoolean([tooltipInfo, selectionInfo]).join("\n");
  return (
    Boolean(info) && (
      <InfoWrapper data-tooltip-content={info} data-tooltip-id={id}>
        <Icons.Prompt />
        <PromptMsg id={id} place="right" />
      </InfoWrapper>
    )
  );
};
