import { Controller, FormProvider, useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import { FundingListContext } from "src/context/CEX/FundingProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PopupContext } from "src/context/shared/PopupContext";
import { ModalPanelV2 } from "src/components/shared/ModalPanelV2";
import { EditButton } from "src/components/shared/Buttons/v2/variants/IconButtons/EditButton";
import { RefButtonWrapper } from "src/components/shared/Buttons/v2/variants/RefButtonWrapper";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { Loader } from "src/components/shared/Loader";
import { UpdateFunding } from "src/state/funding/CEX/types";
import { TextArea } from "../FundingForm/style";
import * as styles from "./style";

interface EditFundingModalProps {
  id: number;
}

const FORM_ID = "edit_funding";

export const EditFundingModal = observer(({ id }: EditFundingModalProps) => (
  <ModalPanelV2
    label="Edit funding"
    trigger={
      <RefButtonWrapper>
        <EditButton />
      </RefButtonWrapper>
    }
  >
    <EditFunding id={id} />
  </ModalPanelV2>
));

const EditFunding = ({ id }: EditFundingModalProps) => {
  const state = useLateInitContext(FundingListContext);
  const { close } = useLateInitContext(PopupContext);

  const funding = state.getSelectFunding(id);

  const methods = useForm<UpdateFunding>({ defaultValues: { comment: funding?.comment || "" } });
  const { handleSubmit, control, formState } = methods;
  const { isDirty } = formState;

  const onSubmit = (data: UpdateFunding) => state.updateFunding(id, data, close);

  return (
    <FormProvider {...methods}>
      <styles.FormContainer id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="comment"
          render={({ field: { value, onChange } }) => (
            <TextArea value={value} onChange={onChange} minRows={3} maxRows={5} />
          )}
        />

        <SubmitButton form={FORM_ID} disabled={!isDirty}>
          Save
        </SubmitButton>
      </styles.FormContainer>

      <Loader show={state.isLoading} />
    </FormProvider>
  );
};
