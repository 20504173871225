import { observer } from "mobx-react-lite";
import { CheckBox } from "src/components/shared/CheckBox";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { BindingsBotContext } from "src/context/CEX/BindingsBotProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { WrapperForModule } from "../style";

export const ExchangeModule = observer(() => {
  const state = useLateInitContext(BindingsBotContext);

  return (
    <WrapperForModule>
      <LabeledSelector
        labelAfter={
          <CheckBox
            label="Exchange module"
            checked={state.checkExchange}
            onChange={state.toggleExchangeBinding}
          />
        }
        value={state.currentBindings.exchangeBindingUUID ? state.exchangeSelected : null}
        isClearable={!state.checkExchange}
        options={state.exchangeModules}
        onChange={state.selectorHandler("exchangeBindingUUID")}
      />
    </WrapperForModule>
  );
});
