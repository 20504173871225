import { useTooltipContext } from "src/context/Graph/Tooltip";
import { TooltipSeriesData } from "src/state/Graph/Tooltip/types";
import * as styles from "./style";

export const useSeriesData = <T extends TooltipSeriesData>(title: string) => {
  const { seriesDataMap } = useTooltipContext<T>();

  const seriesData = seriesDataMap.get(title)!;

  return seriesData;
};

export interface GraphTooltipSeriesContentProps {
  color?: string;
  title: string;
  value: string;
}

export const GraphTooltipSeriesContent = ({
  color,
  title,
  value,
}: GraphTooltipSeriesContentProps) => (
  <styles.Container>
    <styles.TitleWrapper>
      {color && <styles.ColorBox $color={color} />}
      <styles.Title>{title}</styles.Title>
    </styles.TitleWrapper>
    <styles.Value>{value}</styles.Value>
  </styles.Container>
);
