import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { IndexImpactContext } from "src/context/CEX/Dashboard/v2/IndexImpact";
import { PartiesMetricsContext } from "src/context/CEX/Dashboard/v2/PartiesMetrics";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ImpactCard } from "./ImpactCard";
import { WorkingSinceCard } from "./WorkingSinceCard";
import * as styles from "./style";

export interface MarketKPIPanelProps extends ComponentPropsWithoutRef<"div"> {}

export const MarketKPIPanel = observer((props: MarketKPIPanelProps) => {
  const {
    exchanges,
    workingSince,
    loading: partiesLoading,
  } = useLateInitContext(PartiesMetricsContext.Context);
  const { indexImpact, loading: impactLoading } = useLateInitContext(IndexImpactContext.Context);

  return (
    <styles.Container {...props}>
      <styles.StyledImpactCard impact={indexImpact} loading={impactLoading} />
      <styles.StyledWorkingSinceCard
        sinceDate={workingSince}
        exchanges={exchanges}
        loading={partiesLoading}
      />
    </styles.Container>
  );
});

export interface MarketKPIPanelWidgetProps extends MarketKPIPanelProps {}

export const MarketKPIPanelWidget = (props: MarketKPIPanelWidgetProps) => (
  <PartiesMetricsContext.Provider>
    <IndexImpactContext.Provider>
      <MarketKPIPanel {...props} />
    </IndexImpactContext.Provider>
  </PartiesMetricsContext.Provider>
);
