import { ComponentPropsWithoutRef } from "react";
import { useId } from "src/hooks/useId";
import { InOutIndicator } from "./InOutIndicator";
import * as styles from "./style";

export interface InOutIndicatorsProps extends ComponentPropsWithoutRef<"div"> {
  info?: string;
}

export const InOutIndicators = ({ info, ...props }: InOutIndicatorsProps) => {
  const id = useId();
  return (
    <styles.Container data-tooltip-content={info} data-tooltip-id={id} {...props}>
      <InOutIndicator type="in" />
      <InOutIndicator type="out" />
      <styles.IndicatorTooltip id={id} />
    </styles.Container>
  );
};
