import { observer } from "mobx-react-lite";
import { darken } from "polished";
import { useMemo } from "react";
import { BalanceOverviewContext } from "src/context/CEX/Dashboard/v2/BalanceOverview";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { useDashboardColors } from "../../../../shared/hooks/useDashboardColors";
import { DashboardV2Widget, getWidgetInfo, getWidgetTitle } from "../../shared/constants";
import { BalancesModeSelector } from "./BalancesModeSelector";
import { BalancesOverviewBar, BalancesOverviewBarProps } from "./BalancesOverviewBar";
import * as styles from "./style";

export interface BalancesOverviewCardProps
  extends Pick<BalancesOverviewBarProps, "zoomDefault">,
    CardProps {}

export const BalancesOverviewCard = observer(
  ({ zoomDefault, ...props }: BalancesOverviewCardProps) => {
    const {
      freeLocked: { free, locked },
      price,
      transactions,
      loading,
      currentType,
      setCurrentType,
    } = useLateInitContext(BalanceOverviewContext.Context);

    const {
      accent: { dodgerBlue, coralPink, yellow },
    } = useDashboardColors();

    const balancesData = useMemo(
      (): BalancesOverviewBarProps["data"] => ({
        datasets: [
          {
            label: "Free",
            data: free.value,
            borderWidth: 0,
            backgroundColor: dodgerBlue,
            hoverBackgroundColor: darken(0.15, dodgerBlue),
          },
          {
            label: "Locked",
            data: locked.value,
            borderWidth: 0,
            backgroundColor: coralPink,
            hoverBackgroundColor: darken(0.15, coralPink),
          },
          {
            type: "line",
            label: "Price",
            data: price.value,
            borderWidth: 1,
            backgroundColor: darken(0.2, yellow),
            borderColor: yellow,
            order: -1,
            pointRadius: 3,
            yAxisID: "y1",
          },
          {
            label: "Transactions",
            type: "line",
            data: transactions.value,
            hidden: true,
          },
        ],
        labels: free.time,
      }),
      [coralPink, dodgerBlue, free, locked, price, transactions, yellow]
    );

    const barProps = { data: balancesData, zoomDefault };
    return (
      <styles.BalancesCard
        title={getWidgetTitle(DashboardV2Widget.BalancesOverview)}
        afterTitle={<BalancesModeSelector type={currentType} onChange={setCurrentType} />}
        info={getWidgetInfo(DashboardV2Widget.BalancesOverview)}
        loading={loading}
        {...props}
      >
        <BalancesOverviewBar {...barProps} />
      </styles.BalancesCard>
    );
  }
);
