import styled from "styled-components";
import * as base from "../../../shared";

export const Divider = styled.hr`
  width: 100%;

  border: none;
  border-top: 1px solid ${({ theme }) => theme.lineColor};
  border-radius: 15px;
`;

export const UserGroupLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
  padding-right: 4px;

  flex-grow: 1;
`;

export const UserGroupLinksScrollWrapper = styled(base.ScrollWrapper)`
  margin-bottom: 37px;

  @media screen and (max-width: 450px) {
    margin-bottom: 0;
  }
`;

export const FlexModalPanel = styled(base.FlexModalPanel)`
  height: 500px;
`;

export const FormContent = styled(base.FormContent)`
  flex-grow: 1;
`;

export const ButtonsWrapper = styled(base.ButtonsWrapper)`
  margin-top: auto;
`;

export const AddButtonWrapper = styled.div`
  width: 100%;

  display: flex;
`;
