import { observer } from "mobx-react-lite";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import AddDependencyStore from "src/state/UserManager/AddDependencyStore";
import { AddDependencyStoreParams } from "src/state/UserManager/AddDependencyStoreParams";
import * as styles from "../../../shared";

export const AddHierarchy = observer(() => {
  const state = useLateInitContext(UserManagerContext);

  const dependencyState = useLocalStore(AddDependencyStore, new AddDependencyStoreParams(state));

  return (
    <ModalPanel
      label="Add dependency"
      show={state.visiblePanel.showAddHierarchyPanel}
      close={state.openPanel("showAddHierarchyPanel")}
      loading={dependencyState.isLoading}
    >
      <styles.AccessForm
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        onSubmit={dependencyState.submitHandler()}
      >
        <LabeledSelector
          label="User"
          options={state.usersSelect}
          onChange={dependencyState.getHandler("user_name")}
          errorHint={dependencyState.errors.user_name}
        />

        <LabeledSelector
          label="Role"
          options={state.rolesSelect}
          onChange={dependencyState.getHandler("role")}
          errorHint={dependencyState.errors.role}
        />

        <LabeledSelector
          label="Scope"
          options={state.scopesSelect}
          onChange={dependencyState.getHandler("scope_id")}
          errorHint={dependencyState.errors.scope_id}
        />

        <styles.SideWrapper>
          <OutlineButton type="submit" stretched>
            Add dependency +
          </OutlineButton>
        </styles.SideWrapper>
      </styles.AccessForm>
    </ModalPanel>
  );
});
