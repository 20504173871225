import { PanelContainer } from "src/components/shared/shared";
import styled, { css } from "styled-components";

export const Container = styled(PanelContainer)`
  height: 100%;
  padding: 30px 25px;

  & > div:not(:last-of-type) {
    margin-bottom: 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListContentHeight = (
  headerHeight: number = 30,
  footerHeight: number = 44,
  spacing: number = 32
) => css`
  height: calc(100% - ${headerHeight}px - ${footerHeight}px - 2 * ${spacing}px);
`;

export const Content = styled.div`
  ${ListContentHeight()}
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

export const Footer = styled.div``;
