import { roundSingleValue } from "src/helpers/rounding";
import { ColumnTotalCb, ColumnsTotalMap } from "src/state/CEX/CEXDashboard/shared/TableTotalizer";
import {
  CurrencyFeeData,
  CurrencyFeeDataKeys,
} from "src/state/CEX/CEXDashboard/v2/widgets/CurrencyFeeV2Store";

export type FeeTotalMap = ColumnsTotalMap<CurrencyFeeData, number>;

const getTotalValue = (currentTotal: number, value: string | undefined) => {
  const valueNumber = value ? +value : 0;
  return +roundSingleValue(currentTotal + valueNumber);
};

export const getFeeTotal: ColumnTotalCb<CurrencyFeeData, FeeTotalMap> = (id, row, prevTotal) => {
  const key = id as CurrencyFeeDataKeys;
  switch (key) {
    case "amount":
    case "amountUsd": {
      const currentValue = row[key];
      const currentTotal = prevTotal || 0;

      const total = getTotalValue(currentTotal, currentValue);

      return total;
    }
    default: {
      return prevTotal;
    }
  }
};
