import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";

export interface DropDownProps {
  hide: boolean;
}

export type HighlightProps = {
  isHighlighted?: boolean;
};

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemContent = styled.div`
  position: relative;

  height: 20px;
  width: max-content;
  min-width: 108px;

  display: flex;
  align-items: center;

  border-radius: ${({ theme }) => theme.radii["2px"]};
  font-weight: ${({ theme }) => theme.fontWeights["500"]};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
`;

export const ItemHeader = styled(ItemContent)`
  margin-bottom: 16px;
  padding-left: 10px;

  justify-content: space-between;

  background-color: #a1a1a11a;
  border: 1px solid #efefef;
`;

export const ItemName = styled.span`
  width: 100%;

  font-weight: 500;
  font-size: 10px;

  color: ${({ theme }) => theme.darkGray};
`;

export const ArrowItemHeader = styled(ItemHeader)<DropDownProps>`
  margin: 0;

  padding-left: 20px;

  cursor: pointer;

  ::before {
    position: absolute;
    content: "";
    width: 3.5px;
    height: 3.5px;
    left: 7px;
    top: 6.5px;
    border-bottom: solid;
    border-right: solid;
    border-width: 1.3px;
    border-color: #d6d6d6;
    -ms-transform: ${({ hide }) => (hide ? "rotate(45deg)" : "rotate(-45deg)")};
    -webkit-transform: ${({ hide }) => (hide ? "rotate(45deg)" : "rotate(-45deg)")};
    transform: ${({ hide }) => (hide ? "rotate(45deg)" : "rotate(-45deg)")};
    transition: all 0.5s;
  }
`;

export const ItemHeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 8px;

  margin-bottom: 8px;
`;

export const ItemBody = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0px 10px 10px;

  gap: 5px;
`;

export const HighlightMixin = css<HighlightProps>`
  ${({ theme, isHighlighted }) => {
    if (!isHighlighted) return;
    return `
    box-shadow: 0 0 5px 2px ${hexToRgb(theme.primaryColor, 0.3)};
  `;
  }}
`;

export const SelectItem = styled.span<HighlightProps>`
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
  font-weight: 600;
  ${HighlightMixin}
`;

export const SwitchItemContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 12px;
`;

export const SwitchItem = styled(SelectItem)<DropDownProps>`
  position: relative;

  height: 100%;
  min-width: 40px;

  display: inline-flex;
  align-items: center;

  padding-right: 15px;

  color: ${({ hide, theme }) => (hide ? theme.primaryColor : theme.darkGray)};
  text-decoration: ${({ hide }) => (hide ? "underline" : "")};
  font-size: 10px;
`;
