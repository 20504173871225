import { getDashboardVersion } from "src/components/BotsContent/CEX/Dashboard/shared/config/versions";
import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import CEXDashboardStore, { IDashboardState } from "src/state/CEX/CEXDashboard/v1";
import { CEXDashboardV2Store, IDashboardV2State } from "src/state/CEX/CEXDashboard/v2";

type DashboardState =
  | { state: IDashboardState; version: "v1" }
  | { state: IDashboardV2State; version: "v2" };

const useDashboardState = () => {
  const version = getDashboardVersion();
  const stateConstructor = version === "v1" ? CEXDashboardStore : CEXDashboardV2Store;

  const state = useLocalStore(stateConstructor);
  return { state, version } as DashboardState;
};

export const DashboardContext = createLateInitContext<DashboardState>();

type DashboardProviderProps = ChildrenProps;

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const state = useDashboardState();

  return <DashboardContext.Provider value={state}>{children}</DashboardContext.Provider>;
};
