import { useMemo } from "react";
import { ConditionCompareType } from "src/modules/expertSystem";

export interface CompareOption {
  label: string;
  value: ConditionCompareType;
}

export const MODULE_COMPARE_LIST: CompareOption[] = [
  { label: "<=", value: "lte" },
  { label: "<", value: "lt" },
  { label: "=", value: "eq" },
  { label: "!=", value: "ne" },
  { label: ">", value: "gt" },
  { label: ">=", value: "gte" },
];

export const ONLY_EQUAL_COMPARE_LIST: CompareOption[] = [
  { label: "=", value: "eq" },
  { label: "!=", value: "ne" },
];

export const getCompare = <K extends keyof CompareOption>(v: CompareOption[K] | "", key: K) =>
  MODULE_COMPARE_LIST.find((el) => el[key] === v);

export const useGetCompare = (onlyEqual: boolean = false) => {
  const compareList = useMemo(
    () => (onlyEqual ? ONLY_EQUAL_COMPARE_LIST : MODULE_COMPARE_LIST),
    [onlyEqual]
  );

  return { getCompare, compareList };
};
