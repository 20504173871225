import { observer } from "mobx-react-lite";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { DateTimeRangePickerSelector } from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { TransferListContext } from "src/context/CEX/FundingProvider";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

export const AddTransfersPanel = observer(() => {
  const state = useLateInitContext(TransferListContext);

  return (
    <styles.FormContainer onSubmit={state.submitHandler}>
      <styles.Wrapper>
        <LabeledSelector
          placeholder="Exchange"
          options={state.exchanges}
          value={state.selectorValue("exchange")}
          onChange={state.onSelectorChange("exchange")}
          errorHint={state.errors.exchange}
        />

        <LabeledSelector
          placeholder="Account"
          isDisabled={!state.exchangeSelected}
          options={state.mainState.accountState.getSelectAccounts(state.transferConfig.exchange)}
          value={state.currentAccount}
          onChange={state.onSelectorChange("accountUUID")}
          errorHint={state.errors.accountUUID}
        />
      </styles.Wrapper>

      <styles.DatePickerWrapper>
        <LabeledInput
          placeholder="Currency"
          value={state.transferConfig.currency}
          onChange={state.getHandler("currency")}
          errorHint={state.errors.currency}
        />

        <DateTimeRangePickerSelector
          value={state.rangeState.range}
          onChange={state.rangeState.setRange}
          maxDate={getCurrentDayjs()}
          disableFuture
          popupProps={{ position: "bottom right" }}
          mobileQuery="(max-width: 1700px)"
        />

        <AddButton type="submit" />
      </styles.DatePickerWrapper>
    </styles.FormContainer>
  );
});
