import { CheckBox } from "src/components/shared/CheckBox";
import styled from "styled-components";
import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { TablePanel } from "../TablePanel";
import { TableContent } from "../TablePanel/TableContent";

export const GasWalletsPanel = styled(TablePanel)`
  grid-area: gasWallets;
`;

export const GasWalletsFallback = styled(CrashPanel)`
  grid-area: gasWallets;
`;

export const GasWalletsTable = styled(TableContent)`
  grid-template-columns:
    40px
    minmax(150px, 1fr) minmax(50px, 0.75fr)
    minmax(50px, 0.75fr);
`;

export const MainCheckBox = styled(CheckBox)`
  justify-content: center;

  & > label:first-of-type {
    border: ${({ theme }) => `1px solid ${theme.primaryColor}`};
  }
`;

export const TableCheckBox = styled(CheckBox)`
  justify-content: center;
`;
