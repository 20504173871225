import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { Headline1, Headline2 } from "src/components/shared/Typography";
import { EmptyTextLineHeight } from "src/components/shared/shared";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import styled, { css } from "styled-components";
import { DEXV2BotInfo } from "../DEXV2BotInfo";
import { BaseInfoContent } from "./Info/BaseInfo/style";

export const TABLET_SETTINGS_BREAKPOINT = "1200px";

export const Container = styled.div`
  width: 100%;
  max-width: 1500px;

  padding-top: 50px;

  display: flex;
  flex-direction: column;
`;

export const BotName = styled(Headline2)`
  margin-bottom: 40px;

  color: ${({ theme }) => theme.lightGray};

  ${EmptyTextLineHeight}
`;

export const Title = styled(Headline1)`
  margin-bottom: 16px;
`;

interface SettingsContainerProps {
  showInfo?: boolean;
}

const getDesktopGrid = (props: SettingsContainerProps) => css`
  grid-template-columns: minmax(0, 3fr) minmax(0, 1fr) minmax(0, 2fr);
  grid-template-rows: repeat(3, minmax(254px, auto)) auto;
  grid-template-areas:
    "volume_settings     limit_settings     limit_settings"
    "counter_settings    counter_settings   counter_settings"
    "manage_settings     manage_settings    info"
    ".                   submit             submit";
`;

const getTabletGrid = ({ showInfo }: SettingsContainerProps) => {
  const emptyRow = { area: "", row: "" };
  const infoRow = showInfo ? { area: '"info"', row: "minmax(auto, 120px)" } : emptyRow;

  return css`
    @media ${getMobileMediaQuery(TABLET_SETTINGS_BREAKPOINT)} {
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows:
        repeat(4, minmax(254px, auto))
        ${infoRow.row} auto;
      grid-template-areas:
        "volume_settings   "
        "limit_settings    "
        "counter_settings  "
        "manage_settings   "
        ${infoRow.area}
        "submit            ";

      ${BaseInfoContent} {
        flex-direction: row;
      }
    }
  `;
};

const getMobileGrid = ({ showInfo }: SettingsContainerProps) => {
  const emptyRow = { area: "", row: "" };
  const infoRow = showInfo ? { area: '"info"', row: "1fr" } : emptyRow;

  return css`
    @media ${getMobileMediaQuery("700px")} {
      grid-template-columns: minmax(0, 1fr);
      grid-template-rows:
        repeat(4, minmax(254px, auto))
        ${infoRow.row} auto;
      grid-template-areas:
        "volume_settings    "
        "limit_settings     "
        "counter_settings   "
        "manage_settings    "
        ${infoRow.area}
        "submit             ";

      ${BaseInfoContent} {
        flex-direction: column;
      }
    }
  `;
};

export const SettingsContainer = styled.div<SettingsContainerProps>`
  width: 100%;

  display: grid;
  row-gap: 30px;
  column-gap: 15px;

  ${getDesktopGrid}

  ${getTabletGrid}
  
  ${getMobileGrid}
`;

export const SettingsFallback = styled(CrashPanel)`
  min-height: 60vh;
`;

export const BotInfo = styled(DEXV2BotInfo)`
  margin-bottom: 37px;
`;
