import { CEXBotActions } from "src/components/AllBots/Bots/CEXBots/CEXBot";
import { PopoverMenu, PopoverMenuItem } from "src/components/shared/PopoverMenu";
import { BotStartButton } from "../BotStartButton";
import { BotTriggerButton } from "../BotTriggerButton";

export interface BotActionButtonProps {
  status: number;
  actions?: CEXBotActions;
}

export const BotActionButton = ({ status, actions }: BotActionButtonProps) => {
  if (!actions) {
    return null;
  }

  const { start: startBot, stop: stopBot } = actions;

  // bot in unknown state
  if (status === 2) {
    const menuItems: PopoverMenuItem[] = [
      { label: "start", onClick: startBot },
      { label: "stop", onClick: stopBot },
    ];
    const Button = (
      <PopoverMenu
        trigger={(isOpen) => <BotTriggerButton open={isOpen}>Choose</BotTriggerButton>}
        items={menuItems}
        popupProps={{ keepTooltipInside: true }}
      />
    );
    return Button;
  }

  const isStopped = status === 4;
  const Button = <BotStartButton started={!isStopped} onClick={isStopped ? startBot : stopBot} />;
  return Button;
};
