import { observer } from "mobx-react-lite";
import { useBotsState } from "src/components/AllBots/hooks/useBotsStore";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { IBotsSelectors, hasChainSelector } from "src/state/CEX/CEXBots/CEXBots";
import { SelectPanel } from "../../../../shared/Forms/Selectors/SelectionPanel/SelectPanel";
import * as styles from "./style";

export const BotSelectors = observer(() => {
  const state: IBotsSelectors<boolean> = useBotsState();

  return (
    <styles.Container>
      <SelectPanel selectItems={state.selectedBase} removeClick={state.removeBaseSelected} />

      <LabeledSelector
        label="Select base"
        menuPosition="fixed"
        isMulti
        options={state.allBase}
        value={state.selectedBase}
        onChange={state.setSelectedBases}
      />

      <SelectPanel selectItems={state.selectedExchanges} removeClick={state.removeExchSelected} />

      <LabeledSelector
        label="Select exchanges"
        menuPosition="fixed"
        isMulti
        options={state.allExchanges}
        value={state.selectedExchanges}
        onChange={state.setSelectedExchanges}
      />

      <SelectPanel selectItems={state.selectedParties} removeClick={state.removePartiesSelected} />

      <LabeledSelector
        label="Select parties"
        menuPosition="fixed"
        isMulti
        options={state.allParties}
        value={state.selectedParties}
        onChange={state.setSelectedParties}
      />

      {state.chainsEnabled && (
        <>
          <SelectPanel selectItems={state.selectedChains} removeClick={state.removeSelectedChain} />

          <LabeledSelector
            label="Select chains"
            menuPosition="fixed"
            isMulti
            options={state.allChains}
            value={state.selectedChains}
            onChange={state.setSelectedChains}
          />
        </>
      )}

      {state.dexVersionsEnabled && (
        <>
          <SelectPanel
            selectItems={state.selectedVersions}
            removeClick={state.removeSelectedVersion}
          />

          <LabeledSelector
            label="Select versions"
            menuPosition="fixed"
            isMulti
            options={state.allVersions}
            value={state.selectedVersions}
            onChange={state.setSelectedVersions}
          />
        </>
      )}
    </styles.Container>
  );
});
