import React, { ComponentPropsWithoutRef } from "react";
import { useHistory } from "react-router-dom";
import { pushOrReplaceHistory } from "../../../../helpers/router";
import * as styles from "./style";

export interface MenuItemProps extends ComponentPropsWithoutRef<"li"> {
  to?: string;
}

export const MenuItem = ({ children, to, ...props }: MenuItemProps) => {
  const history = useHistory();

  return (
    <styles.Container onClick={() => !!to && pushOrReplaceHistory(history, to)} {...props}>
      <styles.Label>{children}</styles.Label>
    </styles.Container>
  );
};
