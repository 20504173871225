import { forwardRef } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { PopupComponent } from "src/components/shared/popup/PopupComponent";
import { CreateStrategyProvider } from "src/context/CEX/ExpertSystem";
import { CreateStrategy } from "./CreateStrategy";

const AddStrategyBtn = forwardRef<HTMLButtonElement>(({ ...props }, ref) => (
  <OutlineButton size="m" ownRef={ref} {...props}>
    + Add strategy
  </OutlineButton>
));

export const CreationStrategyModal = () => (
  <PopupComponent trigger={<AddStrategyBtn />}>
    <CreateStrategyProvider>
      <CreateStrategy />
    </CreateStrategyProvider>
  </PopupComponent>
);
