import { observer } from "mobx-react-lite";
import { SuggestionLabeledInput } from "src/components/shared/Forms/Variants";
import { Prompt } from "src/components/shared/Prompts/Prompt";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { CEXSettingsContext } from "src/context/CEX/Settings/CEXSettingsProvider";
import { calcRecommendDiff } from "src/helpers/calcDiff";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useTheme } from "styled-components";
import { ConstraintsInput } from "../../../CEXBotSettings/shared";
import * as styles from "./style";

export const Diff = observer(() => {
  const state = useLateInitContext(CEXSettingsContext);
  const exchangeState = useLateInitContext(ExchangeContext);
  const { orderBookState } = exchangeState;

  const { primaryColor } = useTheme();

  const calc = calcRecommendDiff(
    +state.diff,
    +orderBookState.maxBuy,
    +orderBookState.minSell,
    +exchangeState.DTPMin,
    +exchangeState.DTPMax
  );

  const checkShowDiff = (): boolean =>
    !(
      exchangeState.DTPMin < orderBookState.minSell && exchangeState.DTPMax > orderBookState.maxBuy
    ) &&
    state.hideDiff &&
    exchangeState.pair === exchangeState.originPair &&
    !state.loader &&
    !exchangeState.limitOrdersState.isLoading &&
    calc !== +state.data.settings.virtualRange.diff &&
    calc !== Infinity &&
    !isNaN(calc);

  return (
    <styles.Container>
      <ConstraintsInput
        as={SuggestionLabeledInput}
        type="number"
        label="Diff"
        value={state.data.settings.virtualRange.diff}
        onChange={state.getHandler("settings.virtualRange.diff")}
        errorHint={state.errors.settings?.virtualRange?.diff}
        {...state.getFieldErrorAsProps("settings.virtualRange.diff")}
        step="any"
        getSuggestion={(getRef: (node: HTMLDivElement | null) => void) =>
          checkShowDiff() ? (
            <Prompt
              style={{
                top: "revert",
                color: primaryColor,
                boxShadow: `0 0 0 1px ${primaryColor}`,
              }}
              value={String(calc)}
              setValue={() => state.getDiff(calc)}
              ownRef={getRef}
            />
          ) : null
        }
        suggestionGap={8}
      />
    </styles.Container>
  );
});
