import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Loader } from "src/components/shared/Loader";
import { DynamicSelectionContext } from "src/context/CEX/ExpertSystem";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { IStrategyModuleCreateType, StrategyModuleTypes } from "src/modules/expertSystem";
import SelectorProvider from "src/state/expertSystem/modules/SelectorProvider";
import { ERRORS_MSGS } from "src/validation-schemas";
import { RowWrapper } from "../style";
import * as styles from "./style";

export interface BaseModuleFormProps extends ComponentPropsWithoutRef<"form"> {
  formId: string;
  beforeNameField?: JSX.Element;
}

export const BaseModuleForm = observer(
  ({ formId, children, beforeNameField, ...props }: BaseModuleFormProps) => {
    const state = useLateInitContext(CreateModulesContext);

    const selectorsState = useLocalStore(SelectorProvider, {
      state: state.accountsState,
    });

    const { control } = useFormContext<IStrategyModuleCreateType<StrategyModuleTypes>>();

    return (
      <DynamicSelectionContext.Provider value={selectorsState}>
        <styles.FormContainer id={formId} {...props}>
          <RowWrapper>
            {beforeNameField}

            <Controller
              control={control}
              name="name"
              rules={{ required: ERRORS_MSGS.isRequired }}
              render={({ field: { value, onChange }, formState: { errors } }) => (
                <LabeledInput
                  label="Name"
                  value={value}
                  onChange={(e) => onChange(getChangeEventValue(e))}
                  errorHint={errors.name?.message}
                />
              )}
            />
          </RowWrapper>

          {children}

          <Loader show={state.isLoading} />
        </styles.FormContainer>
      </DynamicSelectionContext.Provider>
    );
  }
);
