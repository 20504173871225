import { observer } from "mobx-react-lite";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { PriceContext } from "src/context/CEX/Dashboard/v2/Price";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useGraphsChartOptions } from "../../../../shared/config/graphs";
import { DashboardV2Widget, getWidgetTitle } from "../../shared/constants";
import { GraphCard, StyledGraph } from "../../shared/style";
import { PriceChartTooltip } from "./PriceChartTooltip";
import { useSeriesData, useStartOptions } from "./hooks";

export interface PriceChartProps extends Omit<CardProps, "title" | "loading"> {}

export const PriceChart = observer((props: PriceChartProps) => {
  const { data, loading } = useLateInitContext(PriceContext.Context);

  const seriesProps = useSeriesData({ data });

  const { options } = useGraphsChartOptions();

  const startOptions = useStartOptions();

  return (
    <GraphCard title={getWidgetTitle(DashboardV2Widget.PriceChart)} loading={loading} {...props}>
      <StyledGraph
        series={seriesProps}
        tooltip={PriceChartTooltip}
        allowTimeScale={false}
        autoColorScales={false}
        options={options}
        startOptions={startOptions}
        showInfo={false}
      />
    </GraphCard>
  );
});
