import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { openBuyOrder, openSellOrder } from "src/api/bots/CEX/exchange";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Loader } from "src/components/shared/Loader";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { OrderPanelProps } from "../../shared/OrderPanel";
import * as styles from "../../shared/style";

interface LimitOrderFormProps extends Pick<OrderPanelProps, "side" | "formId"> {
  setOrderSize: Dispatch<SetStateAction<number | undefined>>;
}

enum Fields {
  BUY = "buyOrder",
  SELL = "sellOrder",
}

export const LimitOrderForm = observer(({ formId, side, setOrderSize }: LimitOrderFormProps) => {
  const mainState = useLateInitContext(ExchangeContext);
  const { buySellState } = mainState;

  const { orderType, openOrder, loading } = useMemo(() => {
    if (side === "BUY") {
      return {
        orderType: Fields[side],
        openOrder: openBuyOrder,
        loading: buySellState.buyLoader,
      };
    }
    return {
      orderType: Fields[side],
      openOrder: openSellOrder,
      loading: buySellState.sellLoader,
    };
  }, [side, buySellState.buyLoader, buySellState.sellLoader]);

  const currentValue = {
    value: buySellState[orderType].orderType,
    label: buySellState[orderType].orderType,
  };

  const { totalAsset, amount } = buySellState[orderType];

  useEffect(() => {
    const orderSize = orderType === "buyOrder" ? parseFloat(totalAsset) : parseFloat(amount);

    setOrderSize(orderSize);
  }, [buySellState, totalAsset, amount, orderType, setOrderSize]);

  const id = useMemo(() => nanoid(5), []);

  const avgPrice = buySellState.getAvgPrice(orderType);

  return (
    <styles.BuySellForm
      id={formId}
      onSubmit={buySellState.submitHandler(
        side,
        orderType,
        openOrder,
        mainState.market,
        mainState.pair,
        mainState.selectedAccount,
        +mainState.pricePrecision,
        +mainState.amountPrecision
      )}
    >
      <LabeledSelector
        label="Order type"
        options={buySellState.limitOrderTypeList}
        onChange={buySellState.selectorHandler(orderType)}
        value={currentValue}
      />

      <LabeledInput
        type="number"
        step="any"
        label="Price"
        value={buySellState[orderType].price}
        onChange={buySellState.priceHandler(orderType)}
        errorHint={buySellState[`${orderType}Errors`].price}
      />

      <LabeledInput
        type="number"
        step="any"
        label="Amount"
        value={buySellState[orderType].amount}
        onChange={buySellState.amountHandler(orderType)}
        errorHint={buySellState[`${orderType}Errors`].amount}
      />

      <styles.Wrapper>
        <LabeledInput
          type="number"
          step="any"
          label="Total quote"
          value={buySellState[orderType].totalAsset}
          onChange={buySellState.totalAssetHandler(orderType)}
        />

        <LabeledInput
          data-tooltip-id={id}
          type="number"
          step="any"
          label="Calculated total"
          readOnly
          value={
            orderType === "buyOrder" ? buySellState.buyOrderTotal : buySellState.sellOrderTotal
          }
        />
      </styles.Wrapper>

      {avgPrice ? (
        <styles.HeadTooltip place="left" id={id} variant="info">
          Avg Price: {avgPrice}
        </styles.HeadTooltip>
      ) : (
        ""
      )}

      <Loader show={loading} />
    </styles.BuySellForm>
  );
});
