import { observer } from "mobx-react-lite";
import { List } from "src/components/shared/List";
import { Loader } from "src/components/shared/Loader";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import DEXStatsIcons from "src/icons/DEXStatsIcons";
import { Wallet } from "src/state/DEX/DEXStats/Wallets";
import { WalletsContext } from "../../../shared/context/Stats";
import { WalletRow, WalletRowProps } from "./WalletRow";
import * as styles from "./style";

interface WalletsListProps extends WalletRowProps {
  asset: string;
  token: string;
  wallets: Wallet[];
  button?: JSX.Element;
}

export const WalletsList = observer(
  ({
    title,
    asset,
    token,
    wallets,
    button,
    leftBalances,
    rightBalances,
    nativeBalances,
  }: WalletsListProps) => {
    const state = useLateInitContext(WalletsContext);

    return (
      <List title={title} button={button}>
        <styles.Header>
          <styles.Title>Name</styles.Title>
          <styles.Title>Wallet</styles.Title>

          <styles.Copy onClick={state.copyAllWallet(title)}> {DEXStatsIcons.copy()}</styles.Copy>
          <styles.Title>{asset}</styles.Title>
          <styles.Title>{token}</styles.Title>
          <styles.Title>Native</styles.Title>
        </styles.Header>
        <styles.Content>
          <WalletRow
            wallets={wallets}
            title={title}
            leftBalances={leftBalances}
            rightBalances={rightBalances}
            nativeBalances={nativeBalances}
          />
          <Loader show={state.loadWallets} />
        </styles.Content>
      </List>
    );
  }
);
