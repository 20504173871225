import { ComponentPropsWithoutRef } from "react";
import { MobilePNLV2OwnProps } from "../MobilePNLV2";
import { PNLV2CardContainer } from "../MobilePNLV2/style";
import { PNLV2Header, PNLV2HeaderOwnProps } from "../shared/PNLHeader";
import { PNLV2Fee } from "../shared/PNLV2Fee";
import { PNLV2Data, PNLV2Items, PNLV2ItemsOwnProps } from "../shared/PNLV2Items";

export interface DesktopPNLV2OwnProps
  extends Omit<PNLV2HeaderOwnProps, "after">,
    Pick<PNLV2ItemsOwnProps, "orientation" | "showArrow">,
    Pick<MobilePNLV2OwnProps, "afterHeader" | "showFeeSymbol">,
    PNLV2Data {}

export interface DesktopPNLV2Props extends DesktopPNLV2OwnProps, ComponentPropsWithoutRef<"div"> {}

export const DesktopPNLV2 = ({
  afterHeader,
  afterTitle,
  title,
  quote,
  base,
  deltaBase,
  deltaQuote,
  price,
  fee,
  showFeeSymbol,
  showArrow,
  ...props
}: DesktopPNLV2Props) => {
  const after = afterHeader || <PNLV2Fee value={fee} showSymbol={showFeeSymbol} />;
  const headerProps = { after, afterTitle, title };
  const contentProps = { quote, base, deltaBase, deltaQuoteUsd: deltaQuote, price, showArrow };

  return (
    <PNLV2CardContainer {...props}>
      <PNLV2Header {...headerProps} />
      <PNLV2Items
        {...contentProps}
        itemsOrientation="column-reverse"
        coloredLabel={false}
        showArrow={false}
      />
    </PNLV2CardContainer>
  );
};
