import { AreaData, LineStyle } from "lightweight-charts";
import { useMemo } from "react";
import buyMockData from "src/components/BotsContent/DEX_V2/Dashboard/Charts/BuySellChart/buyMockData.json";
import sellMockData from "src/components/BotsContent/DEX_V2/Dashboard/Charts/BuySellChart/sellMockData.json";
import { Graph } from "src/components/shared/Graph";
import { OptionalChartOptions, SeriesProps } from "src/components/shared/Graph/types";
import { noOp } from "src/helpers/utils";
import { Card } from "src/components/BotsContent/CEX/shared/Card";
import { useDashboardColors } from "../../../shared/hooks/useDashboardColors";
import * as styles from "./style";

export interface LiquidityChartsProps {}

export const LiquidityCharts = (props: LiquidityChartsProps) => {
  const { textSecondary, borderDefault } = useDashboardColors();
  const baseOptions: OptionalChartOptions = useMemo(
    () => ({
      layout: {
        textColor: textSecondary,
        fontSize: 9,
      },
      grid: {
        horzLines: {
          style: LineStyle.LargeDashed,
          color: borderDefault,
        },
        vertLines: {
          visible: false,
        },
      },
      rightPriceScale: {
        borderColor: borderDefault,
      },
      leftPriceScale: {
        borderColor: borderDefault,
      },
      timeScale: {
        visible: false,
      },
    }),
    [borderDefault, textSecondary]
  );

  const allDepthOptions: OptionalChartOptions = useMemo(
    () => ({
      ...baseOptions,
      timeScale: {
        visible: true,
        borderColor: borderDefault,
      },
    }),
    [baseOptions, borderDefault]
  );

  const seriesProps = useMemo(
    (): SeriesProps[] => [
      {
        type: "Area",
        data: buyMockData as AreaData[],
        color: "assetColor",
        visible: true,
        title: "USDT",
        side: "left",
      },
      {
        type: "Area",
        data: sellMockData as AreaData[],
        color: "tokenColor",
        visible: true,
        title: "BTC",
        side: "left",
      },
    ],
    []
  );

  return (
    <Card title="Liquidity" style={{ width: "532px", height: "376px", padding: "8px" }}>
      <styles.Container>
        <styles.ChartContainer>
          <Graph
            series={seriesProps}
            allowTimeScale={false}
            request={noOp}
            autoColorScales={false}
            options={allDepthOptions}
          />
          <styles.Title>All Depth</styles.Title>
        </styles.ChartContainer>

        <styles.Divider />

        <styles.ChartContainer>
          <Graph
            series={seriesProps}
            allowTimeScale={false}
            request={noOp}
            autoColorScales={false}
            options={baseOptions}
          />
          <styles.Title>Our Depth</styles.Title>
        </styles.ChartContainer>
      </styles.Container>
    </Card>
  );
};
