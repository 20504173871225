import { ComponentPropsWithoutRef } from "react";
import { usePNLV2Value } from "src/components/shared/PNLV2/shared/Item/PNLV2Item";
import * as styles from "./style";

export interface PNLPriceProps extends ComponentPropsWithoutRef<"div"> {
  value?: number | string;
}

export const PNLPrice = ({ value, ...props }: PNLPriceProps) => {
  const formattedValue = usePNLV2Value({ value, type: "price", showSymbol: true, showPlus: false });

  return (
    <styles.Container {...props}>
      <styles.Label>Price</styles.Label>
      <styles.StyledPrice>{formattedValue}</styles.StyledPrice>
    </styles.Container>
  );
};
