import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { Title } from "../style";
import * as styles from "./style";

export const AssetsBalances = observer(({ showLoader, data, setRange }) => (
  <styles.AssetsBalancesCont>
    <Title>Quotes balances</Title>
    <styles.AssetsGraphsContainer>
      {data.map(({ currency, graphData }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <styles.AssetGraphContainer key={index}>
          <styles.Subtitle>{currency}</styles.Subtitle>
          <styles.AssetsGraph
            series={[{ type: "Area", data: graphData, title: currency }]}
            data={graphData}
            request={setRange}
          />
        </styles.AssetGraphContainer>
      ))}
      <Loader show={showLoader} />
    </styles.AssetsGraphsContainer>
  </styles.AssetsBalancesCont>
));
