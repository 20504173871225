import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import {
  DateTimeRangePickerSelector,
  DateTimeRangePickerSelectorProps,
} from "src/components/shared/DatePickers/DateTimeRangePickerSelector";
import { ExchangeAccountingContext } from "src/context/CEX/ExchangeAccounting";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

export interface SelectorsRowProps extends ComponentPropsWithoutRef<"div"> {
  isMobile?: boolean;
}

export const SelectorsRow = observer(({ isMobile, ...props }: SelectorsRowProps) => {
  const state = useLateInitContext(ExchangeAccountingContext.Context);

  const popupProps: DateTimeRangePickerSelectorProps["popupProps"] = !isMobile
    ? { position: ["bottom left"] }
    : undefined;

  return (
    <styles.SelectorsRow {...props}>
      <styles.PickerContainer>
        <DateTimeRangePickerSelector
          onChange={state.setRange}
          value={state.selectedRange}
          mobileQuery="(max-width: 100px)"
          popupProps={popupProps}
        />
      </styles.PickerContainer>
    </styles.SelectorsRow>
  );
});
