import { observer } from "mobx-react-lite";
import { SelectModuleItem } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { ListContainer } from "src/components/BotsContent/CEX/ExpertSystem/shared/style";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ActionTypes } from "src/modules/expertSystem";

export const ActionList = observer(() => {
  const state = useLateInitContext(CreateModulesContext);

  return (
    <ListContainer>
      {state.actions.map(({ label, value: type }) => (
        <SelectModuleItem
          key={label + type}
          switchProps={{
            label,
            checked: type === state.currentAction,
            value: type,
            onChange: (e) => {
              state.setAction(e.target.value as ActionTypes);
            },
          }}
        />
      ))}
    </ListContainer>
  );
});
