import { observer } from "mobx-react-lite";
import { InfoButton } from "src/components/BotsContent/CEX/Exchange/TradePanel/Orders/shared/InfoButton";
import { RefButtonWrapper } from "src/components/shared/Buttons/v2/variants/RefButtonWrapper";
import { ModalPanelV2 } from "src/components/shared/ModalPanelV2";
import { InfoModalContent, InfoModalContentProps } from "./InfoModalContent";

export interface ModuleInfoModalProps extends InfoModalContentProps {}

export const ModuleInfoModal = observer((props: ModuleInfoModalProps) => {
  const { name, type } = props.module;

  const title = `${name} (${type})`;

  return (
    <ModalPanelV2
      label={title}
      trigger={
        <RefButtonWrapper>
          <InfoButton size="l" />
        </RefButtonWrapper>
      }
    >
      <InfoModalContent {...props} />
    </ModalPanelV2>
  );
});
