import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { Tabs } from "src/components/shared/Tabs";
import { PartyContext, UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import EditPartyStore from "src/state/UserManager/EditParty/EditParty";
import { Accounts } from "./Accounts";
import { Bots } from "./Bots";
import { Info } from "./Info";
import { Tickers } from "./Tickers";
import * as styles from "./style";

interface EditPartyProps {
  partyName: string;
  show: boolean;
  hide: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditParty = observer(({ partyName, show, hide }: EditPartyProps) => {
  const state = useLateInitContext(UserManagerContext);

  const partyState = useMemo(() => new EditPartyStore(partyName), [partyName]);

  return (
    <styles.EditPartyModal label={`Party: ${partyName}`} show={show} close={hide} loading={false}>
      <styles.Content>
        <PartyContext.Provider value={partyState}>
          <Tabs
            menuItems={[
              { value: "CEX", label: "CEX bots" },
              { value: "DEX", label: "DEX bots" },
              { value: "DEXV2", label: "DEXV2 bots" },
              { value: "ACCOUNTS", label: "Accounts" },
              { value: "INFO", label: "Info" },
              { value: "TICKERS", label: "Tickers" },
            ]}
            defaultKey="CEX"
          >
            <Bots
              key="CEX"
              typeBot="CEX"
              bots={partyState.CEXBots}
              parties={state.parties}
              showLoader={partyState.isLoadingBot}
              getBots={partyState.getBots}
              clickMoveHandler={partyState.clickMoveCEXHandler}
            />

            <Bots
              key="DEX"
              typeBot="DEX"
              bots={partyState.DEXBots}
              parties={state.parties}
              getBots={partyState.getSwapBots}
              showLoader={partyState.isLoadingSwapBot}
              clickMoveHandler={partyState.clickMoveDEXHandler}
            />

            <Bots
              key="DEXV2"
              typeBot="DEXV2"
              bots={partyState.DEXV2Bots}
              parties={state.parties}
              getBots={partyState.getDEXV2Bots}
              showLoader={partyState.isLoadingDEXV2Bot}
              clickMoveHandler={partyState.clickMoveDEXV2Handler}
            />
            <Accounts key="ACCOUNTS" />

            <Info key="INFO" />

            <Tickers key="TICKERS" />
          </Tabs>
        </PartyContext.Provider>
      </styles.Content>
    </styles.EditPartyModal>
  );
});
