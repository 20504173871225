/**
 * Indicates error occurred during retrieving quotes
 */
export class QuotesError extends Error {
  public readonly isQuotesError: true = true;

  public constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
