import styled from "styled-components";

interface StatusIndProps {
  bgColor: string | null;
}

export const TimeLineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 120px;
  grid-area: time-line;
`;

export const CurrentTime = styled.span`
  min-width: 80px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.textColor};
  margin-right: 10px;
  align-items: center;
`;

export const StatusLine = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  :after {
    position: absolute;
    display: block;
    width: calc(100% - 200px);
    height: 1px;
    background-color: gray;
    content: "";
    right: 0;
  }
`;

export const Status = styled(CurrentTime)`
  display: flex;
`;

export const StatusInd = styled.div<StatusIndProps>`
  width: 12px;
  height: 12px;

  margin-left: 8px;
  border-radius: ${({ theme }) => theme.radii["100%"]};
  background-color: ${({ bgColor }) => bgColor || "green"};
`;
