import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { useAppState } from "src/state";
import { BotInfoProps } from "../../CEX";
import { FundingDEXContext } from "../shared/context/FundingProvider";
import { CurrentFundings } from "./CurrentFundings";
import { FundingForm } from "./FundingForm";
import * as styles from "./style";

export interface FundingProps extends BotInfoProps {
  type: "CEX" | "DEX";
  abilityName: string;
}

export const Funding = observer(({ type, botInfo, abilityName }: FundingProps) => {
  const { bot_uuid, party = "" } = botInfo;
  const { userState } = useAppState();
  const state = useLateInitContext(FundingDEXContext);

  useEffect(() => {
    if (!bot_uuid) return;
    state.setBotUUID(bot_uuid);
    state.downloadData();
  }, [state, type, bot_uuid]);

  return (
    <styles.Container>
      <styles.Title>Funding</styles.Title>
      <styles.Content party={party} abilityName={abilityName} abilities={userState.abilities}>
        <CurrentFundings
          type={type}
          botInfo={botInfo}
          state={state}
          abilityName={abilityName}
          abilities={userState.abilities}
        />
        <PermissionWrapper party={party} abilityName={abilityName}>
          <FundingForm state={state} />
        </PermissionWrapper>
      </styles.Content>
    </styles.Container>
  );
});
