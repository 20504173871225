import { observer } from "mobx-react-lite";
import { WalletsInteractionPanel } from "src/components/BotsContent/DEX_V2/shared/WalletsInteractionPanel";
import { DEXV2SwapContext } from "src/context/DEXV2/DEXV2Swap/DEXV2Swap";
import { SwapVaultsContext } from "src/context/DEXV2/DEXV2Swap/Vaults/SwapVaults";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IChainMeta } from "src/modules/chain";

const useChainInfoProps = (chainMeta?: IChainMeta) => {
  const chainInfoProps = chainMeta;
  return { ...chainInfoProps };
};

interface VaultsInteractionPanelProps {}

export const VaultsInteractionPanel = observer((_props: VaultsInteractionPanelProps) => {
  const { refreshBalances, refreshChainBalances, vaultsDeployed } =
    useLateInitContext(SwapVaultsContext);
  const { chainMeta } = useLateInitContext(DEXV2SwapContext);

  const chainInfoProps = useChainInfoProps(chainMeta);

  return (
    <WalletsInteractionPanel
      showWithdrawer={false}
      walletsDeployed={vaultsDeployed}
      chainInfoProps={chainInfoProps}
      onRefreshBackendBalances={refreshBalances}
      onRefreshChainBalances={refreshChainBalances}
      spacing="sparse"
    />
  );
});
