import { SwitchMenu } from "src/components/shared/SwitchMenu";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  gap: 5px;
`;

export const SwitchGraphs = styled(SwitchMenu)`
  width: 250px;

  @media screen and (max-width: 550px) {
    width: 170px;

    & > div > label {
      font-size: 10px;
    }
  }
`;
