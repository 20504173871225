import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface PNLV2HeaderOwnProps {
  title?: string;
  afterTitle?: JSX.Element;
  after?: JSX.Element;
}

export interface PNLV2HeaderProps extends PNLV2HeaderOwnProps, ComponentPropsWithoutRef<"div"> {}

export const PNLV2Header = ({ title = "PNL", afterTitle, after, ...props }: PNLV2HeaderProps) => (
  <styles.Container {...props}>
    <styles.TitleWrapper>
      {title && <styles.HeaderTitle>{title}</styles.HeaderTitle>}
      {afterTitle}
    </styles.TitleWrapper>
    {after}
  </styles.Container>
);
