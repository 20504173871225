import { makeAutoObservable } from "mobx";
import {
  getCEXInfo,
  getGraphanaBalanceSum,
  getGraphanaBalanceTotal,
  getGraphanaStats,
} from "src/api/bots/CEX/stats";
import { calcRoundingValue, toRounding } from "src/helpers/rounding";

const getPrecision = (number) => {
  const [significand, exponent] = String(number).split("e");
  // const [integer, fractional] = significand.split(".");
  const fractional = significand.split(".")[1];

  return (fractional?.length || 0) + Math.max(0, -(exponent || 0));
};

class ReportState {
  market = null;

  _bot_uuid = "";

  // interval = null;
  baseCurrencies = ["USD", "USDT", "USDC", "BUSD"];

  startPoint = null;

  endPoint = null;

  step = null;

  actualPrice = null;

  deltaTotal = null;

  allTotal = null;

  deltaToken = null;

  usdTotalStart = null;

  usdTotalEnd = null;

  usdTotalPercent = null;

  balanceTableData = [];

  balancesPieData = [];

  balancesPieLabels = [];

  balanceTotalUSDTData = [];

  balanceTokenData = [];

  assetsBalancesData = [];

  exchangesDepthsData = [];

  loaderGraphanaBalanceSum = false;

  loaderGraphanaBalanceTotal = false;

  loaderGraphanaStats = false;

  loaderGraphanaDepth = false;

  loaderActualPrice = false;

  constructor(startPoint, endPoint) {
    makeAutoObservable(this, {}, {});
    this.startPoint = startPoint;
    this.endPoint = endPoint;
    this.step = this.calcStep(startPoint, endPoint);
  }

  setBotUUID = (uuid) => {
    this._bot_uuid = uuid;
  };

  setMarket = (botName) => {
    this.market = botName;
  };

  checkCurrency = (currency) => this.baseCurrencies.find((el) => el === currency);

  downloadData = () => {
    this.loadGraphanaStats(this.base, this.step, this.startPoint, this.endPoint);
    this.loadTotalBalance(this.base, this.step, this.startPoint, this.endPoint);
    this.loadSummaryBalances(this.base, this.step, this.startPoint, this.endPoint);
    // this.loadDepths(this.path, this.step, this.startPoint, this.endPoint);
  };

  downloadPNL = () => {
    this.loadGraphanaStats(this.base, this.step, this.startPoint, this.endPoint);
    // this.loadActualPrice(this._bot_uuid);
  };

  calcStep = (startPoint, endPoint) => Math.ceil((endPoint - startPoint) / 11000 / 15) * 15;

  getInterval = (e) => {
    this.interval = e;
  };

  get quote() {
    if (!this.market) {
      return null;
    }
    return this.market.split("_")[0];
  }

  get base() {
    if (!this.market) {
      return null;
    }
    return this.market.split("_")[1];
  }

  get exchange() {
    if (!this.market) {
      return null;
    }
    return this.market.split("_")[2];
  }

  get usdTotalDelta() {
    if (this.usdTotalEnd === null || this.usdTotalStart === null) {
      return null;
    }
    return this.usdTotalEnd - this.usdTotalStart;
  }

  get price() {
    if (this.deltaTotal >= 0 && this.deltaToken >= 0) {
      return "NET PROFIT";
    }

    if (this.deltaTotal < 0 && this.deltaToken < 0) {
      return "NET LOSS";
    }

    if (this.deltaTotal < 0 && this.deltaToken === 0) {
      return "NET LOSS";
    }

    if (this.deltaTotal === 0 && this.deltaToken < 0) {
      return "NET LOSS";
    }

    const price = this.deltaTotal / this.deltaToken;

    return Math.abs(+toRounding(price, calcRoundingValue(price)));
  }

  get pricePrecision() {
    if (!this.actualPrice) {
      return 0;
    }

    return getPrecision(this.actualPrice);
  }

  *loadGraphanaStats(base, step, startPoint, endPoint) {
    let responseData = null;

    this.loadActualPrice(this._bot_uuid);

    this.loaderGraphanaStats = true;

    try {
      const {
        data: { result },
      } = yield getGraphanaStats(base, step, startPoint, endPoint);

      responseData = result;
    } catch {
      // set initial values
      this.deltaTotal = null;
      this.deltaToken = null;
      this.usdTotalStart = null;
      this.usdTotalEnd = null;
      this.usdTotalPercent = null;
      this.balanceTableData = [];
      this.balancesPieData = [];
      this.balancesPieLabels = [];

      return;
    } finally {
      this.loaderGraphanaStats = false;
    }

    if (responseData) {
      const {
        deltaCurrency = {},
        deltaPercent = {},
        deltaTotal = null,
        deltaToken = null,
        deltaAbs = {},
        shares = {},
        prices = {},
        usdTotalStart = null,
        usdTotalEnd = null,
        usdTotalPercent = null,
      } = responseData;

      let totalSum = null;

      for (const currency of Object.keys(shares)) {
        this.balancesPieLabels.push(currency);
        this.balancesPieData.push(shares[currency]);
      }

      for (const currency of Object.keys(deltaCurrency)) {
        let priceBase = null;

        if (this.checkCurrency(this.quote) && !deltaAbs[currency] && currency === this.base) {
          // priceBase = this.actualPrice * deltaCurrency[this.token];
          priceBase = this.actualPrice * deltaCurrency[currency];
        } else if (!deltaAbs[currency] && !this.checkCurrency(this.quote)) {
          const price = prices[currency];
          const priceAbs = price * this.actualPrice;

          priceBase = deltaCurrency[this.base] * priceAbs;
        } else {
          priceBase = deltaAbs[currency];
        }

        this.balanceTableData.push({
          currency,
          deltaCurrency:
            // this.checkCurrency(currency) || currency === this.token
            //   ? deltaCurrency[currency]
            //   : null,
            deltaCurrency[currency],
          deltaPercent: deltaPercent[currency],
          deltaAbs: priceBase,
        });

        totalSum += priceBase || 0;
      }

      this.allTotal = totalSum;
      this.deltaTotal = deltaTotal;
      this.deltaToken = toRounding(deltaToken, calcRoundingValue(deltaToken));
      this.usdTotalStart = usdTotalStart;
      this.usdTotalEnd = usdTotalEnd;
      this.usdTotalPercent = usdTotalPercent;
    }
  }

  *loadTotalBalance(base, step, startPoint, endPoint) {
    let responseData = null;
    this.loaderGraphanaBalanceTotal = true;

    try {
      const {
        data: { result },
      } = yield getGraphanaBalanceTotal(base, step, startPoint, endPoint);

      responseData = result;
    } catch {
      this.balanceTotalUSDTData = [];
      return;
    } finally {
      this.loaderGraphanaBalanceTotal = false;
    }

    this.balanceTotalUSDTData = responseData;
  }

  *loadSummaryBalances(base, step, startPoint, endPoint) {
    let responseData = null;

    this.loaderGraphanaBalanceSum = true;

    try {
      const {
        data: { result },
      } = yield getGraphanaBalanceSum(base, step, startPoint, endPoint);

      responseData = result;

      this.balanceTokenData = responseData[this.base];
    } catch {
      this.balanceTokenData = [];
      this.assetsBalancesData = [];
      return;
    } finally {
      this.loaderGraphanaBalanceSum = false;
    }

    for (const currency of Object.keys(responseData)) {
      if (currency === this.base) {
        continue;
      }
      this.assetsBalancesData.push({
        currency,
        graphData: responseData[currency],
      });
    }
  }

  // *loadDepths(base, step, startPoint, endPoint) {
  //   let responseData = null;

  //   this.loaderGraphanaDepth = true;

  //   try {
  //     const { result } = yield getGraphanaDepth(
  //       base,
  //       step,
  //       startPoint,
  //       endPoint
  //     );

  //     responseData = result;
  //   } catch (error) {
  //     this.balanceTokenData = [];
  //     this.assetsBalancesData = [];

  //     return;
  //   } finally {
  //     this.loaderGraphanaDepth = false;
  //   }

  //   for (let exchange in responseData) {
  //     let pairs = [];
  //     for (let pair in responseData[exchange]) {
  //       pairs.push({
  //         pair,
  //         graphData: responseData[exchange][pair],
  //       });
  //     }
  //     this.exchangesDepthsData.push({
  //       exchange,
  //       pairs,
  //     });
  //   }
  // }

  *loadActualPrice(bot_uuid) {
    this.loaderActualPrice = true;

    try {
      const {
        data: { lastPrice },
      } = yield getCEXInfo(bot_uuid);

      this.actualPrice = lastPrice;
    } catch {
      this.actualPrice = null;
      return;
    } finally {
      this.loaderActualPrice = false;
    }
  }
}

export default ReportState;
