import { Children, ComponentPropsWithoutRef, ReactNode } from "react";
import * as styles from "./style";

export interface StatsSectionProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
}

export const StatsSection = ({ children, ...props }: StatsSectionProps) => {
  const itemsCount = Children.count(children);

  const itemsWithDivider: ReactNode[] = [];

  Children.forEach(children, (item, index) => {
    itemsWithDivider.push(item);
    if (index + 1 < itemsCount) {
      // eslint-disable-next-line react/no-array-index-key
      itemsWithDivider.push(<styles.Divider key={index} />);
    }
  });
  return <styles.Container {...props}>{itemsWithDivider}</styles.Container>;
};
