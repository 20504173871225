import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
} from "src/components/shared/Table";
import styled from "styled-components";

export const TContent = styled(Table)`
  height: auto;

  display: table;
`;

export const THead = styled(TableHead)`
  border-bottom: none;
`;

export const TBody = styled(TableBody)`
  display: revert;

  overflow-y: inherit;
`;

export const TRow = styled(TableRow)`
  display: revert;
`;

export const THRow = styled(TableHeadRow)`
  height: auto;

  display: revert;
`;

export const THCell = styled(TableHeader)`
  user-select: none;
`;

export const TBCell = styled(TableCell)`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;
