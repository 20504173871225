import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import {
  BotModesTotal,
  GetPNLRequestParams,
  GetPNLResponse,
  getPNL,
} from "src/api/bots/DEXV2/stats";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { PNLV2Data } from "src/components/shared/PNLV2/shared/PNLV2Items";
import { getUTCRangeFromDuration } from "src/helpers/dateUtils";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { IBotProvider } from "../../DEXV2Bots/DEXV2BotStore";

interface PNLData extends GetPNLResponse {}

const INITIAL_BOT_TYPE_PNL_DATA: BotModesTotal = {
  limit_bot_total: 0,
  volume_bot_total: 0,
  counter_bot_total: 0,
};

const INITIAL_PNL_DATA: PNLData = {
  base: 0,
  native: 0,
  price: 0,
  quote: 0,
  value_by_bot_type: {
    base: INITIAL_BOT_TYPE_PNL_DATA,
    native: INITIAL_BOT_TYPE_PNL_DATA,
    quote: INITIAL_BOT_TYPE_PNL_DATA,
  },
};

type PNLInfo = PNLV2Data;

export default class PNLStore implements IDisposable {
  private _botUUID = "";

  private _loading = false;

  private _pnl: PNLData = INITIAL_PNL_DATA;

  private _dateRange: DateTimeRange = getUTCRangeFromDuration({ hours: 12 });

  private _botProvider: IBotProvider;

  private _rangeChangedReaction: IReactionDisposer;

  constructor(botProvider: IBotProvider) {
    this._botProvider = botProvider;

    makeAutoObservable(this);

    makeLoggable<any>(this, {
      pnlInfo: true,
      _currentDateRange: true,
      _range: true,
      _pnlParams: true,
    });

    this._rangeChangedReaction = reaction(
      () => this._dateRange,
      () => {
        this.getPNL();
      }
    );
  }

  private _setPNL = (pnl: PNLData) => {
    this._pnl = pnl;
  };

  setBotUUID = (uuid: string) => {
    this._botUUID = uuid;
  };

  get botUUID() {
    return this._botUUID;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get loading() {
    return this._loading;
  }

  private get _bot() {
    return this._botProvider.bot;
  }

  private get _base() {
    const { base } = this._bot;
    return base || "BASE";
  }

  private get _quote() {
    const { quote } = this._bot;

    return quote || "QUOTE";
  }

  private get _deltaBase() {
    const { base } = this._pnl;
    return base;
  }

  private get _deltaQuote() {
    const { quote } = this._pnl;
    return quote;
  }

  private get _fees() {
    const { native } = this._pnl;
    return native;
  }

  private get _price() {
    const { price } = this._pnl;
    return price;
  }

  get pnlInfo(): PNLInfo {
    return {
      base: this._base,
      quote: this._quote,
      deltaBase: this._deltaBase,
      deltaQuote: this._deltaQuote,
      price: this._price,
      fee: this._fees,
    };
  }

  setDateRange = (range: DateTimeRange) => {
    this._dateRange = range;
  };

  get dateRange() {
    return this._dateRange;
  }

  private get _pnlParams(): GetPNLRequestParams | undefined {
    const currentRange = this._dateRange;
    const [start, end] = currentRange;
    if (!start || !end) return undefined;
    return { start: `${start.unix()}`, end: `${end.unix()}` };
  }

  getPNL = async () => {
    this._setLoading(true);

    try {
      const { isError, data } = await getPNL(this._botUUID, this._pnlParams);
      if (!isError) {
        this._setPNL(data);
      }
    } catch (err) {
      logError(err);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {
    this._rangeChangedReaction();
  };
}
