import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`;

export const Quote = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.textColor};
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  white-space: nowrap;
`;
