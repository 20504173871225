import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  color: ${({ theme }) => theme.dashboard.textAdditional};
  white-space: pre-wrap;
  word-break: break-word;
`;
