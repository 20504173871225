import { InfoPanelCell } from "src/components/shared/InfoPanel/InfoPanelCell";
import { Label, Value } from "src/components/shared/InfoPanel/style";
import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;

  overflow-x: auto;

  justify-content: space-between;

  gap: 20px;

  padding: 5px 10px;

  ${ScrollSize};
`;

export const VolumeCell = styled(InfoPanelCell)`
  height: 100%;

  ${Label} {
    text-align: center;
    font-size: 10px;
  }

  ${Value} {
    text-align: center;
    font-size: 10px;
  }
`;
