import { ComponentPropsWithoutRef, useMemo } from "react";
import * as styles from "./style";

export interface ExchangesSpanProps extends ComponentPropsWithoutRef<"span"> {
  id: string;
  exchanges: string[];
}

export const ExchangesSpan = ({ id, exchanges, ...props }: ExchangesSpanProps) => {
  const exchangesText = useMemo(
    () => (exchanges.length > 0 ? exchanges.join(", ") : " - "),
    [exchanges]
  );

  return (
    <styles.ExchangesText data-tooltip-id={id} data-tooltip-content={exchangesText} {...props}>
      {exchangesText}
    </styles.ExchangesText>
  );
};
