import { observer } from "mobx-react-lite";
import { useCallback, useContext, useState } from "react";
import { ExpandButton } from "src/components/UserManagement/Access/Scopes/ScopesTree/ExpandButton";
import {
  ExchAccHeader,
  Wrapper,
} from "src/components/UserManagement/Access/Scopes/ScopesTree/shared";
import { SelectItem } from "src/components/UserManagement/Access/shared";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { PartyContext } from "src/context/UserManager/UserManager";
import { Account } from "src/modules/userManager";
import { ExchangesProps } from "../..";
import { ItemAPI } from "./ItemAPI";
import * as styles from "./style";

interface AccountProps extends Omit<ExchangesProps, "showAddAcc"> {
  account: Account;
}

export const AccountItem = observer(({ account, showAddAPI, showEditAcc }: AccountProps) => {
  const partyState = useContext(PartyContext);

  const [hide, setHide] = useState(false);

  const toggleVisibility = useCallback(() => {
    setHide(!hide);
  }, [hide]);

  return (
    <styles.AccountContainer>
      <Wrapper>
        <ExpandButton hide={hide} onClick={() => toggleVisibility()} />

        <ExchAccHeader
          onClick={() => {
            showEditAcc(true);
            partyState?.setCurrentEditAcc(account.uuid);
          }}
        >
          <SelectItem style={{ width: "100%" }}>{account.name}</SelectItem>
        </ExchAccHeader>

        <AddButton
          onClick={() => {
            showAddAPI(true);
            partyState?.setNewAPIAcc(account);
          }}
        />
      </Wrapper>

      {hide ? (
        <styles.AccountBody>
          {account?.credentials?.map((el, index) => (
            <ItemAPI API={el} account={account} key={el.id} />
          ))}
        </styles.AccountBody>
      ) : null}
    </styles.AccountContainer>
  );
});
