import TTLCacheImpl from "@isaacs/ttlcache";
import { ICache } from "../ICache";
import { BaseTTLCache } from "./BaseTTLCache";

export interface TTLCacheOptions<T> extends TTLCacheImpl.Options<string, T> {}

export class TTLCache<T> implements ICache<T> {
  private _cache: ICache<T>;

  constructor(options: TTLCacheOptions<T>) {
    const cacheImpl = new TTLCacheImpl(options);
    this._cache = new BaseTTLCache(cacheImpl);
  }

  get = (key: string) => this._cache.get(key);

  set = (key: string, value: T) => this._cache.set(key, value);

  has = (key: string) => this._cache.has(key);

  delete = (key: string) => this._cache.delete(key);

  clear = () => {
    this._cache.clear();
  };
}
