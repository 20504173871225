import { ApiKeysPanel as BaseApiKeysPanel } from "src/components/BotsContent/CEX/CEXApiKeys/ApiKeysPanel";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const ApiKeysPanel = styled(BaseApiKeysPanel)`
  width: 250px;
  flex-shrink: 0;

  ${CardSurface}
`;
