import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Content = styled.div`
  height: 0px;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  overflow: auto;

  ${ScrollSize}
`;
