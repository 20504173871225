import { ChainInfoChip as BaseChainInfoChip } from "src/components/AllBots/Bots/DEXV2Bots/shared/ChainInfoChip";
import styled, { css } from "styled-components";
import { OrientationProps } from "../../Settings/shared/SettingsFormContent/style";

export interface ContainerProps extends OrientationProps {}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  gap: 5px;

  ${({ $orientation: orientation }) => {
    switch (orientation) {
      case "column":
      case "column-reverse": {
        return css`
          flex-direction: column;
          align-items: flex-start;
        `;
      }
      case "row":
      case "row-reverse": {
        return css`
          flex-direction: row;
        `;
      }
    }
  }}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;

export const RefreshIconsWrapper = styled(Wrapper)``;

export interface SpacingProps {
  gap?: number;
}

export const WalletConnectionWrapper = styled(Wrapper)<SpacingProps>`
  gap: ${({ gap = 10 }) => `${gap}px`};
`;

export const WithdrawerWrapper = styled(Wrapper)``;

export const ChainInfoChip = styled(BaseChainInfoChip)`
  width: auto;
`;
