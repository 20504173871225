import { makeAutoObservable } from "mobx";
import { DEXV2ExchangeVersion } from "src/modules/bots";
import { IPriceCalculator } from "./shared/SwapModules/PriceCalculator";
import { EmptyPriceCalculator } from "./shared/SwapModules/empty/EmptyPriceCalculator";
import { INonNullableVersionProvider, VersionedSwapState } from "./shared/VersionedSwapState";
import { IV2PriceCalculatorParams, V2PriceCalculatorStore } from "./v2/PriceCalculator";

interface IPriceCalculatorParams extends IV2PriceCalculatorParams {
  versionProvider: INonNullableVersionProvider;
}

export class PriceCalculatorStore {
  private _state;

  constructor({ versionProvider, ...params }: IPriceCalculatorParams) {
    makeAutoObservable(this);

    this._state = new VersionedSwapState({
      versionProvider,
      getVersionedState: this._getPriceCalculator,
      ...params,
    });
  }

  private _getPriceCalculator = (
    version: DEXV2ExchangeVersion | null,
    params: Omit<IPriceCalculatorParams, "versionProvider">
  ): IPriceCalculator => {
    switch (version) {
      case DEXV2ExchangeVersion.V2: {
        return new V2PriceCalculatorStore(params);
      }
      default: {
        return new EmptyPriceCalculator();
      }
    }
  };

  get state() {
    return this._state.state;
  }

  destroy = () => {
    this._state.destroy();
  };
}
