import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";
import { PanelContainer } from "../../shared";

export const Panel = styled(PanelContainer)`
  position: relative;

  padding: 7px 10px;
`;

export const Container = styled.div`
  height: 0;

  display: flex;
  flex-direction: column;

  gap: 5px;

  overflow-y: auto;

  flex: 1 1 auto;

  ${ScrollSize}
`;

export const LogRowText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.textColor};
`;
