import { LegendProps } from "src/components/shared/Graph/shared/ChartLegend/Legend";
import { GraphLegendRoot } from "../../../../shared/Graph/GraphLegend/GraphLegendRoot";
import { GraphLegendSeries } from "../../../../shared/Graph/GraphLegend/GraphLegendSeries";

export interface ArbitrageChartLegendProps extends LegendProps {}

export const ArbitrageChartLegend = (props: ArbitrageChartLegendProps) => {
  const LegendRoot = GraphLegendRoot;
  const Series = GraphLegendSeries;

  return <LegendRoot series={Series} {...props} />;
};
