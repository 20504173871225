import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import useMediaQuery from "src/hooks/useMediaQuery";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import { BaseInfo } from "./BaseInfo";
import { ReceiversInfo } from "./ReceiversInfo";
import * as styles from "./style";
import { TABLET_SETTINGS_BREAKPOINT } from "../style";

export interface InfoProps extends ComponentPropsWithoutRef<"div"> {}

export const Info = observer((props: InfoProps) => {
  const isMobile = useMediaQuery(getMobileMediaQuery("700px"));
  const isTablet = useMediaQuery(getMobileMediaQuery(TABLET_SETTINGS_BREAKPOINT));
  const fixedHashesHeight = isTablet && !isMobile;
  return (
    <styles.Container {...props}>
      <BaseInfo />
      <ReceiversInfo fixedHashesHeight={fixedHashesHeight} />
    </styles.Container>
  );
});
