import { createContextProvider } from "src/context/utils/createContextProvider";
import useLocalStore from "src/hooks/useLocalStore";
import { CEXSettingsStore } from "src/state/CEX/CEXSettings";

const CEXSettingsStoreCreator = () => useLocalStore(CEXSettingsStore);

const { Context: CEXSettingsContext, Provider: CEXSettingsProvider } =
  createContextProvider<CEXSettingsStore>({
    stateCreator: CEXSettingsStoreCreator,
  });

export { CEXSettingsContext, CEXSettingsProvider };
