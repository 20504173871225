import { observer } from "mobx-react-lite";
import { AddButton } from "src/components/shared/Buttons/v2/variants/IconButtons/AddButton";
import { ModalPanelV2 } from "src/components/shared/ModalPanelV2";
import { AddForm } from "./AddForm";

export const AddTicker = observer(() => (
  <ModalPanelV2 label="Create ticker group:" trigger={<AddButton />} zIndex={9999}>
    <AddForm />
  </ModalPanelV2>
));
