import { ColumnInstance } from "react-table";
import { ScrollSize } from "src/components/shared/shared";
import CEXDashboardIcons from "src/icons/CEXDashboardIcons";
import styled, { css } from "styled-components";

export const TableBody = styled.tbody`
  flex-grow: 1;

  overflow: auto;

  ${ScrollSize}
`;

export const Row = styled.tr`
  display: flex;

  padding: 0 8px;

  background: ${({ theme }) => theme.dashboard.backgroundBaseDefault};
  color: ${({ theme }) => theme.dashboard.textDefault};
  border-bottom: 1px solid ${({ theme }) => theme.dashboard.borderDefault};
`;

const CellMixin = css`
  padding: 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TableCell = styled.td`
  ${CellMixin}
  height: 32px;

  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableHeaderCell = styled(TableCell)`
  user-select: none;

  height: 20px;
`;

interface ColumnSortIconProps {
  $column: ColumnInstance<any>;
}

export const ColumnSortIcon = styled(CEXDashboardIcons.ArrowUp)<ColumnSortIconProps>`
  ${({ $column }) => {
    if (!$column.isSorted)
      return css`
        visibility: hidden;
      `;
    return css`
      transform: rotate(${$column.isSortedDesc ? "180deg" : "0deg"});
    `;
  }}
`;

export const HeaderRow = styled(Row)`
  background: ${({ theme }) => theme.dashboard.backgroundTableHead};
  color: ${({ theme }) => theme.dashboard.textSecondary};
`;

export const TableFooter = styled.tfoot`
  position: sticky;
  bottom: 0;
  z-index: 1;
`;

export const TableFooterCell = styled(TableCell)`
  font-weight: ${({ theme }) => theme.fontWeights[600]};
`;

export const FooterRow = styled(Row)`
  background: ${({ theme }) => theme.dashboard.backgroundSurface};
`;

export const StatsTableCellPaddingMixin = css`
  // move padding to start and end columns
  // to avoid extra space with horizontal scroll
  ${Row} {
    padding: 0;
  }

  ${TableCell} {
    :first-of-type {
      padding-left: 8px;
    }
    :last-of-type {
      padding-right: 8px;
    }
  }
`;

export const StatsTableFlexScrollMixin = css`
  min-width: auto !important;
  overflow: auto;

  ${ScrollSize}

  ${TableBody} {
    overflow: initial;
  }

  ${TableHeader} {
    display: flex;
    flex-direction: column;
  }

  // move padding to start and end columns
  // to avoid extra space with horizontal scroll
  ${StatsTableCellPaddingMixin}
`;

export const TableContent = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  border-collapse: collapse;

  tr {
    th,
    td {
      display: flex;
      align-items: center;
      justify-content: end;
    }

    th:first-of-type,
    td:first-of-type {
      justify-content: start;
      flex-direction: row-reverse;
    }

    th:last-of-type,
    td:last-of-type {
      justify-content: end;
    }
  }

  // apply scrolling to the whole table for consistent behavior on sticky header/footer
  // and table responsive shrinking
  ${StatsTableFlexScrollMixin}
`;
