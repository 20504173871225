import { SelectPanel } from "src/components/shared/Forms/Selectors/SelectionPanel/SelectPanel";
import { Selector } from "src/components/shared/Forms/Selectors";
import { StringSelectorValue } from "src/modules/shared";
import styled from "styled-components";

export const ExchangeSelectorWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  gap: 8px;
`;

export const ExchangeSelector = styled(Selector).attrs({
  isMulti: true,
})`` as typeof Selector<StringSelectorValue, true>;

export const ExchangeSelectPanel = styled(SelectPanel)``;
