import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ModalPanel } from "../../../shared/ModalPanel";
import { useButtonSize } from "../../../shared/hooks/useButtonSize";
import { SwitchTabs } from "../../../shared/style";
import { ActionsModules } from "./ActionsModules";
import { ConditionsModules } from "./ConditionsModules";
import * as styles from "./style";

export const CreateModule = observer(() => {
  const state = useLateInitContext(CreateModulesContext);

  useEffect(() => {
    state.loadData();
  }, [state]);

  const { close } = useLateInitContext(PopupContext);

  const btnSize = useButtonSize();

  return (
    <ModalPanel title="Create new module">
      <SwitchTabs
        border={false}
        selectedItem={state.currentModuleTab}
        direction="row"
        menuItems={state.tabMenuItems}
        onChange={state.setModuleTab}
      />

      {state.currentModuleTab === "CONDITIONS" ? <ConditionsModules /> : <ActionsModules />}

      <styles.Footer>
        <OutlineButton size={btnSize} onClick={() => close()}>
          Cancel
        </OutlineButton>
        <SubmitButton size={btnSize} form={state.currentModuleType}>
          Save
        </SubmitButton>
      </styles.Footer>
    </ModalPanel>
  );
});
