import { Component } from "react";
import { useTheme } from "styled-components";

type SvgProps = React.SVGProps<SVGSVGElement>;

export default class CEXDashboardIcons extends Component {
  static Candles = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 8V7M6 10.5V9.5M9.5 6.5V5.5M2.5 4V3M6 6.5V5.5M9.5 2.5V1.5M3.5 4.3V6.7C3.5 6.77956 3.46839 6.85587 3.41213 6.91213C3.35587 6.96839 3.27956 7 3.2 7H1.8C1.72044 7 1.64413 6.96839 1.58787 6.91213C1.53161 6.85587 1.5 6.77956 1.5 6.7V4.3C1.5 4.22044 1.53161 4.14413 1.58787 4.08787C1.64413 4.03161 1.72044 4 1.8 4H3.2C3.27956 4 3.35587 4.03161 3.41213 4.08787C3.46839 4.14413 3.5 4.22044 3.5 4.3ZM7 6.8V9.2C7 9.27956 6.96839 9.35587 6.91213 9.41213C6.85587 9.46839 6.77956 9.5 6.7 9.5H5.3C5.22044 9.5 5.14413 9.46839 5.08787 9.41213C5.03161 9.35587 5 9.27956 5 9.2V6.8C5 6.72044 5.03161 6.64413 5.08787 6.58787C5.14413 6.53161 5.22044 6.5 5.3 6.5H6.7C6.77956 6.5 6.85587 6.53161 6.91213 6.58787C6.96839 6.64413 7 6.72044 7 6.8ZM10.5 2.8V5.2C10.5 5.27956 10.4684 5.35587 10.4121 5.41213C10.3559 5.46839 10.2796 5.5 10.2 5.5H8.8C8.7606 5.5 8.72159 5.49224 8.68519 5.47716C8.6488 5.46209 8.61573 5.43999 8.58787 5.41213C8.56001 5.38427 8.53791 5.3512 8.52284 5.31481C8.50776 5.27841 8.5 5.2394 8.5 5.2V2.8C8.5 2.7606 8.50776 2.72159 8.52284 2.68519C8.53791 2.6488 8.56001 2.61573 8.58787 2.58787C8.61573 2.56001 8.6488 2.53791 8.68519 2.52284C8.72159 2.50776 8.7606 2.5 8.8 2.5H10.2C10.2796 2.5 10.3559 2.53161 10.4121 2.58787C10.4684 2.64413 10.5 2.72044 10.5 2.8Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  static Wallet = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 10H2.5C2.23478 10 1.98043 9.89464 1.79289 9.70711C1.60536 9.51957 1.5 9.26522 1.5 9V4.5C1.5 4.23478 1.60536 3.98043 1.79289 3.79289C1.98043 3.60536 2.23478 3.5 2.5 3.5H9.5C9.76522 3.5 10.0196 3.60536 10.2071 3.79289C10.3946 3.98043 10.5 4.23478 10.5 4.5V9C10.5 9.26522 10.3946 9.51957 10.2071 9.70711C10.0196 9.89464 9.76522 10 9.5 10Z"
        stroke="currentColor"
      />
      <path
        d="M8.25 7C8.1837 7 8.12011 6.97366 8.07322 6.92678C8.02634 6.87989 8 6.8163 8 6.75C8 6.6837 8.02634 6.62011 8.07322 6.57322C8.12011 6.52634 8.1837 6.5 8.25 6.5C8.3163 6.5 8.37989 6.52634 8.42678 6.57322C8.47366 6.62011 8.5 6.6837 8.5 6.75C8.5 6.8163 8.47366 6.87989 8.42678 6.92678C8.37989 6.97366 8.3163 7 8.25 7Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3.49998V2.80148C8.99996 2.64823 8.96469 2.49704 8.89693 2.35959C8.82917 2.22213 8.73072 2.10209 8.60919 2.00874C8.48766 1.91538 8.3463 1.8512 8.19603 1.82116C8.04575 1.79112 7.89058 1.79602 7.7425 1.83548L2.2425 3.30198C2.02953 3.35873 1.84127 3.48426 1.70699 3.65904C1.57272 3.83383 1.49995 4.04807 1.5 4.26848V4.49998"
        stroke="currentColor"
      />
    </svg>
  );

  static Percent = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4932_49216)">
        <path
          d="M6 11C8.7615 11 11 8.7615 11 6C11 3.2385 8.7615 1 6 1C3.2385 1 1 3.2385 1 6C1 8.7615 3.2385 11 6 11Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.75 8C7.8163 8 7.87989 7.97366 7.92678 7.92678C7.97366 7.87989 8 7.8163 8 7.75C8 7.6837 7.97366 7.62011 7.92678 7.57322C7.87989 7.52634 7.8163 7.5 7.75 7.5C7.6837 7.5 7.62011 7.52634 7.57322 7.57322C7.52634 7.62011 7.5 7.6837 7.5 7.75C7.5 7.8163 7.52634 7.87989 7.57322 7.92678C7.62011 7.97366 7.6837 8 7.75 8ZM4.25 4.5C4.3163 4.5 4.37989 4.47366 4.42678 4.42678C4.47366 4.37989 4.5 4.3163 4.5 4.25C4.5 4.1837 4.47366 4.12011 4.42678 4.07322C4.37989 4.02634 4.3163 4 4.25 4C4.1837 4 4.12011 4.02634 4.07322 4.07322C4.02634 4.12011 4 4.1837 4 4.25C4 4.3163 4.02634 4.37989 4.07322 4.42678C4.12011 4.47366 4.1837 4.5 4.25 4.5Z"
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8 4L4 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4932_49216">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  static Calendar = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 1.5V0.5M6.5 1.5V2.5M6.5 1.5H4.25M0.5 4.5V9C0.5 9.26522 0.605357 9.51957 0.792893 9.70711C0.98043 9.89464 1.23478 10 1.5 10H8.5C8.76522 10 9.01957 9.89464 9.20711 9.70711C9.39464 9.51957 9.5 9.26522 9.5 9V4.5M0.5 4.5H9.5M0.5 4.5V2.5C0.5 2.23478 0.605357 1.98043 0.792893 1.79289C0.98043 1.60536 1.23478 1.5 1.5 1.5H2.5M9.5 4.5V2.5C9.5 2.23478 9.39464 1.98043 9.20711 1.79289C9.01957 1.60536 8.76522 1.5 8.5 1.5H8.25M2.5 0.5V2.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  static FilledInfo = (props: SvgProps) => {
    const theme = useTheme();
    const backgroundColor = theme.contentBackgroundColor;

    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M11 6C11 8.7615 8.7615 11 6 11C3.2385 11 1 8.7615 1 6C1 3.2385 3.2385 1 6 1C8.7615 1 11 3.2385 11 6Z"
          fill="currentColor"
        />
        <path
          d="M6 5.75001V8.25001V5.75001ZM6 3.75501L6.005 3.74951L6 3.75501Z"
          fill="currentColor"
        />
        <path
          d="M6 5.75001V8.25001M6 3.75501L6.005 3.74951"
          stroke={backgroundColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  static OutlinedInfo = (props: SvgProps) => {
    const theme = useTheme();
    const backgroundColor = theme.contentBackgroundColor;

    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g>
          <path
            d="M6 5.75V8.25M6 3.755L6.005 3.7495M6 11C8.7615 11 11 8.7615 11 6C11 3.2385 8.7615 1 6 1C3.2385 1 1 3.2385 1 6C1 8.7615 3.2385 11 6 11Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath>
            <rect width="1em" height="1em" fill={backgroundColor} />
          </clipPath>
        </defs>
      </svg>
    );
  };

  static ArrowUp = (props: SvgProps) => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g>
          <path
            d="M5 2.32433L5 9.67567C5 9.8548 5.22399 10 5.5 10C5.77601 10 6 9.85482 6 9.67567L6 2.32433C6 2.1452 5.77618 2 5.5 2C5.22382 2 5 2.14518 5 2.32433Z"
            fill="currentColor"
          />
        </g>
      </g>
      <path
        d="M7.58946 3.42177L5.86279 1.6951C5.60279 1.4351 5.18279 1.4351 4.92279 1.6951L3.19612 3.42177C2.77612 3.84177 3.07613 4.56177 3.66946 4.56177L7.12279 4.56177C7.71612 4.56177 8.00946 3.84177 7.58946 3.42177Z"
        fill="currentColor"
      />
    </svg>
  );
}
