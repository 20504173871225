import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { DropdownButton } from "src/components/shared/Buttons/v2/DropdownButton";
import { PopoverMenu } from "src/components/shared/PopoverMenu";
import { filterBoolean } from "src/helpers/utils/filterBoolean";
import * as styles from "./style";

export interface ActionsPanelProps {
  address: string;
  onVaultAddressChange?: (address: string) => void;
  onTransferOpen?: () => void;
  transferEnabled?: boolean;
  onWithdrawOpen?: () => void;
  withdrawEnabled?: boolean;
  disabled?: boolean;
}

export const ActionsPanel = observer(
  ({
    address,
    onTransferOpen: onOpenTransfer,
    transferEnabled,
    onWithdrawOpen: onOpenWithdraw,
    withdrawEnabled,
    onVaultAddressChange,
    disabled,
  }: ActionsPanelProps) => {
    const onTransferClick = useCallback(() => {
      onOpenTransfer?.();
      onVaultAddressChange?.(address);
    }, [address, onOpenTransfer, onVaultAddressChange]);

    const onWithdrawClick = useCallback(() => {
      onOpenWithdraw?.();
      onVaultAddressChange?.(address);
    }, [address, onOpenWithdraw, onVaultAddressChange]);

    const actionItems = useMemo(() => {
      const transferItem = transferEnabled && {
        label: "Transfer",
        onClick: onTransferClick,
      };

      const withdrawItem = withdrawEnabled && {
        label: "Withdraw",
        onClick: onWithdrawClick,
      };

      return filterBoolean([transferItem, withdrawItem]);
    }, [onTransferClick, onWithdrawClick, transferEnabled, withdrawEnabled]);

    const panelDisabled = useMemo(
      () => disabled || Object.entries(actionItems).length === 0,
      [actionItems, disabled]
    );

    return (
      <styles.Container>
        <PopoverMenu
          trigger={(isOpen) => (
            <DropdownButton
              size="s"
              variant="outline"
              color="neutral"
              open={isOpen}
              disabled={panelDisabled}
            >
              Choose
            </DropdownButton>
          )}
          items={actionItems}
        />
      </styles.Container>
    );
  }
);
