import { SeriesType } from "lightweight-charts";
import { OmitUnion } from "src/helpers/utils";
import { OptionalSeriesOptions } from "../types";
import { useSeriesColor } from "./useSeriesColor";
import { UseSeriesOptionsParams } from "./useSeriesOptions";

export type UseSeriesTypeOptionsParams = OmitUnion<
  UseSeriesOptionsParams,
  "title" | "showTitleLabel" | "data" | "options"
>;

/**
 * Custom hook that returns extra series options based on the series type.
 */
export const useSeriesTypeOptions = (
  props: UseSeriesTypeOptionsParams
): OptionalSeriesOptions<SeriesType> => {
  const options = useSeriesColor(props);

  return options;
};
