import { PNLV2Price } from "src/components/shared/PNLV2/shared/PNLV2Items";
import { pnlResponseMapper } from "src/state/CEX/shared/CEXPNLStore/pnlResponseMapper";
import { PNLData } from "src/state/MarketCheck/types";
import { LastPriceCell } from "./LastPriceCell";
import * as styles from "./style";

interface PNLCellProps {
  cellData: PNLData;
}

export const PNLCell = ({ cellData }: PNLCellProps) => {
  const data = pnlResponseMapper(cellData);

  const { priceNet, deltaQuoteUsd, currentPriceBase, ...restData } = data;
  const pnlProps = { ...restData, price: priceNet as PNLV2Price, deltaQuote: deltaQuoteUsd };

  return (
    <styles.PNLCellContainer>
      <styles.PNLWrapper {...pnlProps} isMobile orientation="column" showHeader={false} />

      <LastPriceCell value={currentPriceBase} />
    </styles.PNLCellContainer>
  );
};
