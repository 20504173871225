import { Container as BaseContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled(BaseContainer)`
  max-width: 1700px;

  align-items: center;

  padding-top: 15px;
`;
