import { StyledProps } from "src/helpers/styled/types";
import styled from "styled-components";

export interface PNLV2ItemContainerProps {
  orientation?: "row" | "column" | "row-reverse" | "column-reverse";
  space?: "space-between" | "center" | "flex-end" | "flex-start";
}

interface StyledPNLItemContainerProps extends StyledProps<PNLV2ItemContainerProps> {}

export const PNLV2ItemContainer = styled.div<StyledPNLItemContainerProps>`
  width: 100%;

  display: flex;
  flex-direction: ${({ $orientation = "row" }) => $orientation};
  align-items: center;
  justify-content: ${({ $space = "flex-start" }) => $space};
  gap: 4px;
`;
