import styled from "styled-components";
import { ModalBackground, PanelContainer, PanelTitle } from "../shared";

export const Container = styled(ModalBackground)``;

export const Panel = styled(PanelContainer)`
  padding: 46px;
  width: 573px;

  @media screen and (max-width: 600px) {
    width: calc(100% - 20px);
  }
`;

export const CloseWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 25px;
  top: 25px;
`;

export const WrapperTitle = styled.div`
  display: flex;

  margin-bottom: 34px;

  gap: 20px;

  @media screen and (max-width: 450px) {
    margin-bottom: 14px;
  }
`;

export const Title = styled(PanelTitle)`
  white-space: break-spaces;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
`;
