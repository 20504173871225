import { observer } from "mobx-react-lite";
import { TeamsContext } from "src/context/UserManager/Groups";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Team } from "../Team";
import * as styles from "./style";

export const TeamsList = observer(() => {
  const state = useLateInitContext(TeamsContext);

  return (
    <styles.Container>
      <styles.TeamsGrid>
        {state.teamNames.map((name) => (
          <Team key={name} name={name} />
        ))}
      </styles.TeamsGrid>
    </styles.Container>
  );
});
