import { ComponentPropsWithoutRef } from "react";
import { TransactionState } from "src/modules/bots";
import { WalletTransaction } from "src/state/DEXV2/DEXV2Stats/GasWallets/WithdrawGasStore";
import { TransactionStatusCell } from "../../../../../Hashes/TransactionStatusCell";
import { AddressCell } from "../../../../../TablePanel/AddressCell";
import { WalletTypeCell } from "../../../../WalletTypeCell";
import * as styles from "./style";

const TRANSACTION_DELIMITER = " - ";

export interface TransactionOwnProps {
  transaction: WalletTransaction;
  txScannerLink?: string;
  walletScannerLink?: string;
}
export interface TransactionProps extends TransactionOwnProps, ComponentPropsWithoutRef<"li"> {}

const useTransactionCell = ({ transaction, txScannerLink }: TransactionOwnProps) => {
  switch (transaction.state) {
    case TransactionState.Error: {
      return <TransactionStatusCell status={transaction} />;
    }
    case TransactionState.Success: {
      return (
        <>
          <AddressCell address={transaction.trx} scannerLink={txScannerLink} />
          <TransactionStatusCell status={transaction} />
        </>
      );
    }
  }
};

export const Transaction = ({
  transaction,
  walletScannerLink,
  txScannerLink,
  ...props
}: TransactionProps) => {
  const Transaction = useTransactionCell({ transaction, txScannerLink });

  const { address, type } = transaction;

  const Wallet = (
    <>
      <AddressCell address={address} scannerLink={walletScannerLink} />
      <WalletTypeCell type={type} />
    </>
  );

  return (
    <styles.TransactionsListItem key={address} {...props}>
      {Wallet}
      {TRANSACTION_DELIMITER}
      {Transaction}
    </styles.TransactionsListItem>
  );
};
