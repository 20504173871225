import { PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 5px;
`;

export const Content = styled(PanelContainer)`
  position: initial;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 8px;
  padding: 8px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 8px;
`;
