import { RETURN_VALUE_ERROR_CODES } from "@enzoferey/ethers-error-parser";
import { Currency, CurrencyAmount, Fraction, Percent, Price, Token } from "@uniswap/sdk-core";
import { BigNumber, ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { ChainId } from "src/config/chains";
import { QUOTER_V2_ADDRESSES } from "src/config/quoterV2";
import { countDecimalsPlaces } from "src/helpers/math";
import { getEthersErrorMessage, joinErrorMessage } from "src/helpers/network/chain";
import { LogLevel, logDev } from "src/helpers/network/logger";
import { calcRoundingValue, toRounding } from "src/helpers/rounding";
import { Nullish } from "src/helpers/utils";
import invariant from "tiny-invariant";
import { V2SwapRouteError } from "../SwapModules/v2/Swap/Providers/V2RouteStateProvider";
import { V3SwapRouteError } from "../SwapModules/v3/Swap/Providers/V3RouteStateProvider";
import { ALLOWED_PRICE_IMPACT_MEDIUM, ONE_HUNDRED_PERCENT } from "./constants";

export const validatePath = (path: Token[]) => {
  invariant(path.length >= 2, "Path should contain at least two tokens!");
};

export const validatePoolsPath = (path: Token[], pools: string[]) => {
  validatePath(path);

  invariant(pools.length === path.length - 1, "Pools should contain one less token than path!");
};

/**
 * Parses a CurrencyAmount from the passed string.
 * Returns the CurrencyAmount, or undefined if parsing fails.
 */
export function tryParseCurrencyAmount<T extends Currency>(
  value?: string,
  currency?: T
): CurrencyAmount<T> | undefined {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== "0") {
      return CurrencyAmount.fromRawAmount(currency, typedValueParsed);
    }
  } catch (error) {
    // fails if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    logDev([`Failed to parse input amount: "${value}"`, error], {
      level: LogLevel.Debug,
    });
  }
  return undefined;
}

export function tryParsePrice<TQuote extends Currency, TBase extends Currency>(
  value?: string,
  quoteCurrency?: TQuote,
  baseCurrency?: TBase
): Price<TBase, TQuote> | undefined {
  if (!value || !quoteCurrency || !baseCurrency) {
    return undefined;
  }
  try {
    const decimalsCount = countDecimalsPlaces(value);
    const typedPriceParsed = parseUnits(value, decimalsCount).toString();
    if (typedPriceParsed === "0") {
      return undefined;
    }
    const priceDecimalsScale = BigNumber.from(10).pow(decimalsCount).toString();
    const priceFraction = new Fraction(typedPriceParsed, priceDecimalsScale);

    const unitPrice = new Price({
      baseAmount: CurrencyAmount.fromRawAmount(baseCurrency, 1),
      quoteAmount: CurrencyAmount.fromRawAmount(quoteCurrency, 1),
    });

    // price (quote(y)/base(x)) decimals scalar (dx/dy)
    const priceScalar = unitPrice.scalar;

    // string price from Price class: p = y/x * dx/dy (decimals adjusted) =>
    // Price class from string price: y/x = p*dy/dx
    const priceRawFraction = priceFraction.multiply(priceScalar.invert());

    return new Price(
      baseCurrency,
      quoteCurrency,
      priceRawFraction.denominator,
      priceRawFraction.numerator
    );
  } catch (error) {
    logDev([`Failed to parse price: "${value}"`, error], {
      level: LogLevel.Debug,
    });
  }
  return undefined;
}

export function formatNumber(input: Nullish<number>, placeholder = "-") {
  if (input === null || input === undefined) {
    return placeholder;
  }

  const fractionDigits = Math.min(calcRoundingValue(input), 6);
  const roundedInput = toRounding(input, fractionDigits);
  return roundedInput;
}

export const formatCurrencyAmount = (
  amount: Nullish<CurrencyAmount<Currency>>,
  showSymbol = false,
  placeholder = "-"
) => {
  if (amount === null || amount === undefined) {
    return placeholder;
  }
  const formattedAmount = formatNumber(parseFloat(amount.toFixed()), placeholder);

  const symbol = amount?.currency.symbol ?? "";
  if (!showSymbol || !symbol) {
    return formattedAmount;
  }

  return `${formattedAmount} ${symbol}`;
};

export interface FormatFiatAmountOptions {
  tickerPosition?: "start" | "end";
}

export const formatFiatAmount = (
  amount: Nullish<CurrencyAmount<Currency>>,
  { tickerPosition = "start" }: FormatFiatAmountOptions = {}
) => {
  const formattedAmount = formatCurrencyAmount(amount);
  const fiatTicker = "$";

  const fiatAmountTicker =
    tickerPosition === "start" ? [fiatTicker, formattedAmount] : [formattedAmount, fiatTicker];

  return fiatAmountTicker.join(" ");
};

export const formatPrice = (
  price: Nullish<Price<Currency, Currency>>,
  quoteSymbol = false,
  placeholder = "-"
) => {
  if (price === null || price === undefined) {
    return placeholder;
  }
  const formattedPrice = formatNumber(parseFloat(price.toSignificant()), placeholder);

  const symbol = price.quoteCurrency.symbol ?? "";
  if (!quoteSymbol || !symbol) return formattedPrice;
  return `${formattedPrice} ${symbol}`;
};

/**
 * Formats the price impact value directly without sign correction.
 */
export const formatPriceImpactValue = (impact: Percent | undefined) => {
  if (!impact) return "-";
  return `${impact.toFixed(3)}%`;
};

export type FormatPriceImpact = {
  impact: string;
  sign?: number;
};

/**
 * Formats the price impact value adjusting for sign from sdk.
 */
export const formatPriceImpact = (priceImpact: Percent | undefined): FormatPriceImpact => {
  if (!priceImpact) return { impact: "-" };

  // reverse sign of price impact to display, because we store opposite sign internally
  const invertedPriceImpact = priceImpact.multiply(-1);
  const priceImpactSign = fractionSign(invertedPriceImpact);
  return {
    impact: formatPriceImpactValue(invertedPriceImpact),
    sign: priceImpactSign,
  };
};

export function getPriceImpactWarning(priceImpact: Percent): boolean | undefined {
  const absPriceImpactFraction = fractionAbs(priceImpact);
  const absPriceImpact = new Fraction(
    absPriceImpactFraction.numerator,
    absPriceImpactFraction.denominator
  );
  logDev(["getPriceImpactWarning", absPriceImpact, priceImpact, ALLOWED_PRICE_IMPACT_MEDIUM]);
  if (absPriceImpact.greaterThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return true;
}

export function fractionSign(fraction: Fraction) {
  const numerator = BigNumber.from(fraction.numerator.toString());
  const denominator = BigNumber.from(fraction.denominator.toString());
  const fractionMul = numerator.mul(denominator);
  if (fractionMul.isNegative()) {
    return -1;
  }
  if (fractionMul.isZero()) {
    return 0;
  }
  return 1;
}

export function fractionAbs(fraction: Fraction) {
  // we can't use fractionSign to calculate fraction abs, since no sign is stored per fraction
  // there are separate numerator/denominator signs => comparisons wont work
  const numeratorAbs = BigNumber.from(fraction.numerator.toString()).abs();
  const denominatorAbs = BigNumber.from(fraction.denominator.toString()).abs();
  return new Fraction(numeratorAbs.toString(), denominatorAbs.toString());
}

export function fractionToPercent(fraction: Fraction) {
  const percent = new Percent(fraction.numerator, fraction.denominator);
  return percent;
}

export function percentAbs(percent: Percent) {
  const absFraction = fractionAbs(percent);
  return fractionToPercent(absFraction);
}

export const unitCurrencyAmount = <T extends Currency>(currency: T) => {
  const rawUnitAmount = ethers.utils.parseUnits("1", currency.decimals);

  return CurrencyAmount.fromRawAmount(currency, rawUnitAmount.toString());
};

export const zeroCurrencyAmount = <T extends Currency>(currency: T) =>
  CurrencyAmount.fromRawAmount(currency, "0");

/**
 * Returns the percent difference between the mid price and the next mid price
 * @param midPrice mid price before the trade
 * @param nextMidPrice mid price after the trade
 */
export function calculateNextPriceImpact<TBase extends Currency, TQuote extends Currency>(
  midPrice: Price<TBase, TQuote>,
  nextMidPrice: Price<TBase, TQuote>
): Percent {
  const priceImpact = midPrice.subtract(nextMidPrice).divide(midPrice);
  return new Percent(priceImpact.numerator, priceImpact.denominator);
}

/**
 * Inverts price impact in terms of prices used in price impact calculation
 * @param priceImpact - original price impact
 */
export function invertPriceImpact(priceImpact: Percent) {
  // inverse price impact = p/(p-1)
  const inversePriceImpact = priceImpact.divide(priceImpact.subtract(ONE_HUNDRED_PERCENT));
  return inversePriceImpact;
}

export const swapRouteErrorToText = (error: V2SwapRouteError | V3SwapRouteError) => {
  switch (error) {
    case V2SwapRouteError.InsufficientInputAmount: {
      return "Insufficient input amount";
    }
    case V2SwapRouteError.InsufficientReserves: {
      return "Insufficient pair reserves";
    }
    case V3SwapRouteError.QuoteError: {
      return "Failed to fetch quotes";
    }
  }
};

export const swapRevertReasonToMessage = (reason: string) => {
  switch (reason) {
    case "UniswapV2Router: EXPIRED":
      return "This transaction could not be sent because the deadline has passed. Please check that your transaction deadline is not too low.";
    case "UniswapV2Router: INSUFFICIENT_OUTPUT_AMOUNT":
    case "UniswapV2Router: EXCESSIVE_INPUT_AMOUNT":
      return "This transaction will not succeed either due to price movement or fee on transfer. Try increasing your slippage tolerance.";
    case "TransferHelper: TRANSFER_FROM_FAILED":
      return "The input token cannot be transferred. There may be an issue with the input token.";
    case "UniswapV2: TRANSFER_FAILED":
      return "The output token cannot be transferred. There may be an issue with the output token.";
    case "UniswapV2: K":
      return "The Uniswap invariant x*y=k was not satisfied by the swap. This usually means one of the tokens you are swapping incorporates custom behavior on transfer.";
    case "Too little received":
    case "Too much requested":
    case "STF":
      return "This transaction will not succeed due to price movement. Try increasing your slippage tolerance.";
    case "TF":
      return "The output token cannot be transferred. There may be an issue with the output token.";
    default:
      return undefined;
  }
};

/**
 * This is hacking out the revert reason from the ethers provider thrown error however it can.
 * This object seems to be undocumented by ethers.
 * @param error an error from the ethers provider
 */
export function swapErrorToUserReadableMessage(error: unknown): string | undefined {
  const ethersMessage = getEthersErrorMessage(error);
  if (!ethersMessage) return undefined;

  const { code, message: revertReason, tag: revertTag } = ethersMessage;

  if (code !== RETURN_VALUE_ERROR_CODES.EXECUTION_REVERTED || !revertReason) {
    return undefined;
  }

  const swapErrorMessage = swapRevertReasonToMessage(revertReason);

  if (!swapErrorMessage) return undefined;

  return joinErrorMessage(revertTag, swapErrorMessage);
}

export const getQuoterV2Address = (chainId: string, dex: string) =>
  QUOTER_V2_ADDRESSES[chainId as ChainId]?.[dex] ?? null;

export const isLynexDEX = (dex: string) => dex === "LynexV3";
