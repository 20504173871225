import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 10px;
`;

export const Wrapper = styled.div`
  display: flex;

  flex: 1;
`;
