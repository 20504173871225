import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import {
  IDEXFunding,
  IDEXFundingCreator,
  addDEXFunding,
  deleteDEXFunding,
  getAllDEXFundings,
  getAssets,
} from "src/api/shared/funding";
import { toast } from "src/components/shared/Toaster";
import { getUTCDateTimeInputValue, unixToMs } from "src/helpers/dateUtils";
import { getPathAndKey, getValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { PrimitiveSelectorValue, getSelectorList } from "src/helpers/forms/selectors";
import { logError } from "src/helpers/network/logger";
import { ERRORS_MSGS, graterThan, required, validateData } from "src/validation-schemas";

export class FundingDEXStore {
  currentFundings: IDEXFunding[] = [];

  private _assets = [];

  private _botUUID = "";

  funding: IDEXFundingCreator = {
    time: "",
    currency: "",
    amount: "",
  };

  typeOperation = "";

  typeOperationErrMsg = "";

  private _dateValue: number | "" = "";

  showLoader = false;

  validation = {
    time: required(),
    currency: required(),
    amount: [required(), graterThan(0, "The value must be positive")],
  };

  errors = {
    time: "",
    currency: "",
    amount: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  downloadData = () => {
    this.getTokens();
  };

  setLoader = (bool: boolean) => {
    this.showLoader = bool;
  };

  setBotUUID = (value: string) => {
    this._botUUID = value;
  };

  get botUUID() {
    return this._botUUID;
  }

  setCurrentFundings = (arr: IDEXFunding[]) => {
    this.currentFundings = arr || [];
  };

  setSelect = (
    data: PrimitiveSelectorValue<string> | null,
    key: "typeOperation" | "funding",
    field?: "currency"
  ) => {
    if (!data) return;

    if (field) {
      (this[key] as IDEXFundingCreator)[field] = data.value;
    } else {
      this[key as "typeOperation"] = data.value;
    }
  };

  setInputValue = <K extends keyof IDEXFundingCreator>(key: K, value: IDEXFundingCreator[K]) => {
    this.funding[key] = value;
  };

  get assets() {
    return getSelectorList(this._assets);
  }

  get dateValue() {
    return getUTCDateTimeInputValue(this._dateValue);
  }

  getSelectHandler =
    (key: "typeOperation" | "funding", field?: "currency") =>
    (data: PrimitiveSelectorValue<string> | null) =>
      this.setSelect(data, key, field);

  getInputHandler = (key: "amount") => (e: ChangeEvent<HTMLInputElement>) => {
    this.setInputValue(key, getChangeEventValue(e) as number);
  };

  getInputDateHandler = () => (e: ChangeEvent<HTMLInputElement>) => {
    this._dateValue = getChangeEventValue(e) as number | "";
  };

  getTokens = async () => {
    try {
      const { data, isError } = await getAssets();
      if (!isError) {
        this._assets = data.tokens;
      }
    } catch (error) {
      logError(error);
    }
  };

  getAllFundings = () => {
    this.setLoader(true);

    getAllDEXFundings(this.botUUID)
      .then(({ isError, data }) => {
        if (!isError) {
          if (data.fundings) {
            this.setCurrentFundings(data.fundings);
          } else {
            this.setCurrentFundings([]);
          }
        }
      })
      .finally(() => {
        this.setLoader(false);
      });
  };

  getError = (key: keyof IDEXFundingCreator) => {
    const [path, endKey] = getPathAndKey(key);
    const result = runInAction(() => getValueByPath(this.errors, path, endKey, undefined));
    return result;
  };

  convertDate = (time: number) => unixToMs(time);

  validate = () => validateData(this.validation, this.funding, this.errors, undefined);

  submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    this.setInputValue("time", this.convertDate(this._dateValue || 0));
    const valid = this.validate();

    if (this.typeOperation) {
      this.typeOperationErrMsg = "";
    } else {
      this.typeOperationErrMsg = ERRORS_MSGS.isRequired;
    }

    if (valid && this.typeOperation) {
      if (this.typeOperation === "WITHDRAWAL") {
        this.funding.amount = -this.funding.amount;
      } else {
        this.funding.amount = Math.abs(+this.funding.amount);
      }

      this.setLoader(true);

      addDEXFunding(this.funding, this.botUUID)
        .then(({ isError }) => {
          if (!isError) {
            toast.success("Funding successfully completed");
            this.getAllFundings();
          }
        })
        .finally(() => {
          this.setLoader(false);
        });
      this.funding.amount = Math.abs(this.funding.amount);
    }
  };

  deleteFunding = (index: number) => this.currentFundings.splice(index, 1);

  deleteCurrentFunding = (id: number) => () => {
    const index = this.currentFundings.findIndex((n) => n.funding_id === id);
    if (index !== -1) {
      this.setLoader(true);

      deleteDEXFunding(this.botUUID, id)
        .then(({ isError }) => {
          if (!isError) {
            this.deleteFunding(index);
            toast.success("Funding successfully deleted");
          }
        })
        .finally(() => {
          this.setLoader(false);
        });
    }
  };

  destroy = () => {};
}
