import { ChainConnectionContext } from "src/context/DEXV2/ChainConnectionContext";
import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { SwapModulesStore } from "src/state/DEXV2/DEXV2Swap/SwapModules";
import { DEXV2BotContext } from "../../DEXV2Bots/DEXV2Bot";
import { DEXV2SwapContext } from "../DEXV2Swap";
import { SwapVaultsContext } from "../Vaults/SwapVaults";

export const SwapModulesContext = createLateInitContext<SwapModulesStore>();

type SwapModulesProviderProps = ChildrenProps;

export const SwapModulesProvider = ({ children }: SwapModulesProviderProps) => {
  const botState = useLateInitContext(DEXV2BotContext);
  const swapState = useLateInitContext(DEXV2SwapContext);
  const vaultsState = useLateInitContext(SwapVaultsContext);
  const walletConnectionState = useLateInitContext(ChainConnectionContext);

  const store = useLocalStore(SwapModulesStore, {
    vaultProvider: vaultsState,
    vaultsBalancesProvider: vaultsState,
    swapProviders: swapState,
    chainInfoProvider: botState,
    walletConnectionProvider: walletConnectionState,
  });

  return <SwapModulesContext.Provider value={store}>{children}</SwapModulesContext.Provider>;
};
