import { Chart } from "chart.js";
import Zoom from "chartjs-plugin-zoom";
import { observer } from "mobx-react-lite";
import { useMemo, useRef } from "react";
import { Bar, BarProps } from "src/components/shared/Charts/Bar";
import { ChartPlaceholder } from "src/config/chartjs/plugins/chartPlaceholder";
import { Totalizer, getTotal } from "src/config/chartjs/plugins/totalizer";
import { deepMerge } from "src/helpers/utils/deepMerge";
import {
  BASE_TIME_CONFIG,
  useBaseTooltip,
  useGridOptions,
  usePlaceholderOptions,
} from "../../../../shared/config";
import { useChartZoom } from "../../../../shared/hooks/useChartZoom";
import { useDashboardColors } from "../../../../shared/hooks/useDashboardColors";
import { getSeriesLabel } from "../../../../shared/utils";

const useBaseOptions = () => {
  const { textSubhead, textAdditional } = useDashboardColors();

  const tooltipOptions = useBaseTooltip();

  const options: BarProps<any, any>["options"] = useMemo(
    () => ({
      maintainAspectRatio: false,
      layout: { padding: 8 },
      scales: {
        x: {
          type: "time",
          ...BASE_TIME_CONFIG,
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      plugins: {
        tooltip: {
          ...tooltipOptions,
          displayColors: true,
          mode: "index",
          bodyFont: {
            size: 8,
            weight: "400",
          },
          footerColor: textSubhead,
          footerFont: {
            size: 8,
            weight: "500",
          },
          multiKeyBackground: "rgba(0,0,0,0)",
          callbacks: {
            footer() {
              const { dataIndex } = this.dataPoints[0];
              const total = getTotal(this.chart, dataIndex);
              return `Total: ${total}`;
            },
            labelColor(item) {
              const color = (item.dataset.backgroundColor as string) ?? "#fff";
              return {
                borderColor: "rgba(0,0,0,0)",
                backgroundColor: color,
                borderWidth: 0,
              };
            },
            label(ctx) {
              const title = ctx.dataset.label || "";
              const value = ctx.raw as number;
              const total = getTotal(ctx.chart, ctx.dataIndex);

              return getSeriesLabel(title, value, total);
            },
          },
        },
        legend: {
          position: "top",
          align: "end",
          labels: {
            pointStyle: "rect",
            usePointStyle: true,
            boxHeight: 12,
            font: {
              size: 10,
              weight: "400",
            },
            color: textAdditional,
          },
        },
      },
    }),
    [textAdditional, textSubhead, tooltipOptions]
  );

  return options;
};

export interface BalancesOverviewBarProps extends BarProps<number[], number> {
  zoomDefault?: boolean;
}

export const BalancesOverviewBar = observer(
  ({ options: inOptions, zoomDefault, ...props }: BalancesOverviewBarProps) => {
    const chartRef = useRef<Chart<any, any, any>>(null);

    const baseOptions = useBaseOptions();

    const placeholderOptions = usePlaceholderOptions();

    const gridOptions = useGridOptions();

    const zoomOptions = useChartZoom({
      chartRef,
      shouldZoom: zoomDefault,
      data: props.data,
    });

    const options = useMemo(
      () => deepMerge(placeholderOptions, gridOptions, baseOptions, zoomOptions, inOptions),
      [baseOptions, gridOptions, inOptions, placeholderOptions, zoomOptions]
    );

    return (
      <Bar
        chartRef={chartRef}
        options={options}
        plugins={[Totalizer, Zoom, ChartPlaceholder]}
        {...props}
      />
    );
  }
);
