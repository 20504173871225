import { List } from "src/components/shared/List";
import styled from "styled-components";

export const HashesContainer = styled(List)`
  width: 100%;
  & > h5 {
    border: none;
  }
`;

export const HashesButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: auto;
  min-height: 100px;
  max-height: 360px;
  padding: 5px 0;
  overflow-y: auto;
`;

export const HashRow = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: ${({ theme }) => theme.borders.solidGray};
  color: ${({ theme }) => theme.linkColorText};
  font-size: 0.7em;
  background-color: ${({ theme }) => theme.searchInputBgColor};
  border-radius: ${({ theme }) => theme.radii["4px"]};
  height: 20px;
  align-items: center;
  :visited {
    color: #70a5ed;
  }
  :not(:last-of-type) {
    margin-bottom: 5px;
  }
  & > span {
    font-family: "Roboto", monospace;
  }

  @media (max-width: 550px) {
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }
  @media (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }
  @media (max-width: 420px) {
    font-size: ${({ theme }) => theme.fontSizes["8px"]};
  }
`;
