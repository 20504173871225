import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import { ModalPanel, ModalPanelProps } from "src/components/shared/ModalPanel";
import {
  AddVolumeAccountModalContent,
  AddVolumeAccountModalContentOwnProps,
} from "./AddVolumeAccountModalContent";

export interface AddVolumeAccountModalProps
  extends Pick<ModalPanelProps, "show" | "close">,
    Omit<AddVolumeAccountModalContentOwnProps, "setLoading" | "onSuccess"> {
  onRefresh?: () => void;
}

export const AddVolumeAccountModal = observer(
  ({ show, close, onRefresh, ...props }: AddVolumeAccountModalProps) => {
    const [loading, setLoading] = useState(false);

    const onAccountAdded = useCallback(() => {
      close(false);
      onRefresh?.();
    }, [close, onRefresh]);

    return (
      <ModalPanel
        label="Add volume account"
        show={show}
        close={close}
        loading={loading}
        zIndex={10000}
      >
        <AddVolumeAccountModalContent
          {...props}
          setLoading={setLoading}
          onSuccess={onAccountAdded}
        />
      </ModalPanel>
    );
  }
);
