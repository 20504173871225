import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { ApiKeysContext } from "src/context/CEX/ApiKeys/ApiKeys";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BotInfoProps } from "..";
import { Accounts } from "./Accounts";
import * as styles from "./style";

export interface CEXApiKeysProps extends BotInfoProps {}

export const CEXApiKeys = observer(({ botInfo: { bot_uuid, market, party } }: CEXApiKeysProps) => {
  const state = useLateInitContext(ApiKeysContext);

  useEffect(() => {
    if (!market || !party || !bot_uuid) return;
    state.setBotParameters({
      uuid: bot_uuid,
      party,
      exchange: market.split("_").pop()!,
    });
    state.getData();
  }, [party, market, bot_uuid, state]);

  return (
    <styles.Container>
      <styles.Title>{market?.split("_").join(" ")}</styles.Title>
      <Accounts party={party} abilityName="cex-api-edit" />
      <Loader show={state.loading} />
    </styles.Container>
  );
});
