import { StyledProps } from "src/helpers/styled/types";
import styled, { css } from "styled-components";
import { Card } from "src/components/BotsContent/CEX/shared/Card";
import { MainContentWrapper } from "src/components/BotsContent/CEX/shared/Card/style";

export interface BalancesCardProps {
  orientation?: "horizontal" | "vertical";
}

interface StyledBalancesCardProps extends StyledProps<BalancesCardProps> {}

export const StyledBalancesCard = styled(Card)<StyledBalancesCardProps>`
  ${({ $orientation }) => {
    if ($orientation === "vertical") {
      return css`
        ${MainContentWrapper} {
          height: 50%;
        }

        flex-direction: column;
      `;
    }
    return css`
      ${MainContentWrapper} {
        width: 50%;
      }

      > * {
        flex: 1 1 50%;
      }
    `;
  }}
`;
