import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import {
  GetKPIBalancesResponse,
  GetKPIIndexResponse,
  GetPartiesMetricsResponse,
  getKPIAUM,
  getKPIAUMResponse,
  getKPIBalances,
  getKPIIndex,
  getPartiesMetrics,
} from "src/api/bots/CEX/dashboard";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { formatDecimalPercent } from "src/helpers/string";
import { IDisposable, Mapper } from "src/helpers/utils";
import { IBaseStatsStoreParams, IDashboardStateProvider, IStatsFetcher } from ".";

export interface IKPIStoreParams extends IBaseStatsStoreParams {}

type AumData = { aum: string };

const INITIAL_AUM_DATA: AumData = {
  aum: "",
};

type BalancesData = {
  free: string;
  locked: string;
};

const INITIAL_BALANCES_DATA: BalancesData = {
  free: "",
  locked: "",
};

type IndexImpactData = { indexImpact: string };

const INITIAL_INDEX_DATA: IndexImpactData = {
  indexImpact: "",
};

const aumResponseToData: Mapper<getKPIAUMResponse, AumData> = (resp) => resp;

const balancesResponseToData: Mapper<GetKPIBalancesResponse, BalancesData> = (resp) => resp;

const indexResponseToData: Mapper<GetKPIIndexResponse, IndexImpactData> = ({ index_impact }) => ({
  indexImpact: index_impact,
});

export type PartyExchange = {
  name: string;
  workingSince: number;
};

export type ExchangeTokens = {
  exchange: PartyExchange;
  tokens: string[];
};

export type PartyKPIInfo = {
  workingSince: number;
  exchanges: ExchangeTokens[];
};

const partiesMetricsToPartyInfo: Mapper<GetPartiesMetricsResponse, PartyKPIInfo> = ({
  created,
  exchanges,
}) => {
  const exchangesTokens = exchanges.map(({ exchange, currencies, created }) => ({
    exchange: {
      name: exchange,
      workingSince: created,
    },
    tokens: currencies,
  }));

  return {
    workingSince: created,
    exchanges: exchangesTokens,
  };
};

const INITIAL_PARTY_DATA: PartyKPIInfo = {
  workingSince: 0,
  exchanges: [],
};

type KPILoadersData = {
  aum: boolean;
  balances: boolean;
  index: boolean;
  party: boolean;
};

type KPILoaders = keyof KPILoadersData;

const INITIAL_LOADERS: KPILoadersData = {
  aum: false,
  balances: false,
  index: false,
  party: false,
};

export default class KPIStore implements IStatsFetcher, IDisposable {
  private _stateProvider: IDashboardStateProvider;

  private _aum: AumData = INITIAL_AUM_DATA;

  private _balances: BalancesData = INITIAL_BALANCES_DATA;

  private _index: IndexImpactData = INITIAL_INDEX_DATA;

  private _partyData: PartyKPIInfo = INITIAL_PARTY_DATA;

  private _loading: KPILoadersData = INITIAL_LOADERS;

  constructor({ stateProvider }: IKPIStoreParams) {
    makeAutoObservable(this, { loading: false });

    this._stateProvider = stateProvider;

    makeLoggable(this, { aum: true, balances: true, indexImpact: true });
  }

  private get _botParams() {
    return this._stateProvider.botParams;
  }

  private get _rangeParams() {
    return this._stateProvider.queryRangeParams;
  }

  loading = computedFn((key: KPILoaders) => this._loading[key]);

  private _setLoading = (key: KPILoaders, loading: boolean) => {
    this._loading[key] = loading;
  };

  private _setAumData = (data: AumData) => {
    this._aum = data;
  };

  private _setBalancesData = (data: BalancesData) => {
    this._balances = data;
  };

  private _setIndexData = (data: IndexImpactData) => {
    this._index = data;
  };

  private _setPartyData = (data: PartyKPIInfo) => {
    this._partyData = data;
  };

  get aum() {
    return this._aum.aum;
  }

  get balances() {
    return this._balances;
  }

  get indexImpact() {
    const { indexImpact } = this._index;
    const percentImpact = formatDecimalPercent(indexImpact, false);
    return percentImpact;
  }

  get exchanges() {
    return this._partyData.exchanges.map(({ exchange }) => exchange);
  }

  get exchangesTokens() {
    return this._partyData.exchanges;
  }

  get workingSince() {
    return this._partyData.workingSince;
    // return dayjs.unix(this._partyData.workingSince).format("Do [of] MMMM YYYY");
  }

  private get _queryParams() {
    const { party } = this._botParams;
    const rangeParams = this._rangeParams;
    if (!rangeParams || !party) return {};
    return { rangeParams, party };
  }

  private _getAum = async () => {
    const { party, rangeParams } = this._queryParams;
    if (!party || !rangeParams) return;

    this._setAumData(INITIAL_AUM_DATA);
    this._setLoading("aum", true);

    try {
      const { data, isError } = await getKPIAUM(party, rangeParams);

      if (!isError) {
        const kpiData = aumResponseToData(data);
        this._setAumData(kpiData);
      }
    } finally {
      this._setLoading("aum", false);
    }
  };

  private _getBalances = async () => {
    const { party, rangeParams } = this._queryParams;
    if (!party || !rangeParams) return;

    this._setBalancesData(INITIAL_BALANCES_DATA);
    this._setLoading("balances", true);

    try {
      const { data, isError } = await getKPIBalances(party, rangeParams);

      if (!isError) {
        const kpiData = balancesResponseToData(data);
        this._setBalancesData(kpiData);
      }
    } finally {
      this._setLoading("balances", false);
    }
  };

  private _getIndex = async () => {
    const { party, rangeParams } = this._queryParams;
    if (!party || !rangeParams) return;

    this._setIndexData(INITIAL_INDEX_DATA);
    this._setLoading("index", true);

    try {
      const { data, isError } = await getKPIIndex(party, rangeParams);

      if (!isError) {
        const kpiData = indexResponseToData(data);
        this._setIndexData(kpiData);
      }
    } finally {
      this._setLoading("index", false);
    }
  };

  private _getPartiesMetrics = async () => {
    const { party } = this._botParams;

    if (!party) return;

    const { data, isError } = await getPartiesMetrics(party);

    if (!isError) {
      const partyData = partiesMetricsToPartyInfo(data);
      this._setPartyData(partyData);
    }
  };

  getStats = async () => {
    try {
      await Promise.all([
        this._getAum(),
        this._getBalances(),
        this._getIndex(),
        this._getPartiesMetrics(),
      ]);
    } catch (error) {
      logError(error);
    }
  };

  destroy = () => {};
}
