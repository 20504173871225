import styled from "styled-components";
import { Label, Value } from "src/components/shared/InfoPanel/style";
import { OrderInfoCell } from "../../OrderInfoModal/style";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export const FeeCell = styled(OrderInfoCell)`
  ${Label}, ${Value} {
    text-align: center;
    white-space: nowrap;
  }
`;
