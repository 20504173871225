import { useMemo } from "react";
import { GraphProps } from "src/components/shared/Graph";
import { TooltipSeriesData } from "src/state/Graph/Tooltip/types";

export const useStartOptions = (): GraphProps<TooltipSeriesData>["startOptions"] =>
  useMemo(
    () => ({
      overlayPriceScales: {
        scaleMargins: {
          top: 0.8,
          bottom: 0,
        },
      },
      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0.25,
        },
      },
    }),
    []
  );
