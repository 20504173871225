import { BigNumber, providers } from "ethers";
import { LynexQuoter } from "src/contracts/ethers/LynexQuoter";
import { LynexQuoter__factory } from "src/contracts/ethers/factories/LynexQuoter__factory";
import { IQuoterV2__factory } from "src/contracts/factories/uniswap/v3/IQuoterV2__factory";
import { IQuoterV2 } from "src/contracts/uniswap/v3/IQuoterV2";
import { EntriesUnion } from "src/helpers/utils";
import { SwapV3DEXType } from "src/state/DEXV2/DEXV2Swap/utils";

export type GetQuoteExactSingleParams = Parameters<IQuoterV2["quoteExactInputSingle"]>[0];
export type GetQuoteExactOutputSingleParams = Parameters<IQuoterV2["quoteExactOutputSingle"]>[0];

export type V3QuoteResult = {
  amountOut: BigNumber;
  sqrtPriceX96After?: BigNumber;
};

type SwapV3QuoterContractMap = {
  [SwapV3DEXType.LYNEX]: LynexQuoter;
  [SwapV3DEXType.UNISWAP]: IQuoterV2;
};

type SwapV3QuoterContract = EntriesUnion<SwapV3QuoterContractMap, "type", "contract">;

export interface IV3QuoterContract {
  get address(): string;
  quoteExactInputSingle: (params: GetQuoteExactSingleParams) => Promise<V3QuoteResult>;
  quoteExactOutputSingle: (params: GetQuoteExactOutputSingleParams) => Promise<V3QuoteResult>;
}

export class V3QuoterContract implements IV3QuoterContract {
  private _address: string;

  private _contract!: SwapV3QuoterContract;

  constructor(address: string, provider: providers.JsonRpcProvider, dexType: SwapV3DEXType) {
    this._address = address;

    this._contract = this._getContract(address, provider, dexType);
  }

  private _getContract = (
    address: string,
    provider: providers.JsonRpcProvider,
    dexType: SwapV3DEXType
  ): SwapV3QuoterContract => {
    switch (dexType) {
      case SwapV3DEXType.LYNEX: {
        return {
          type: SwapV3DEXType.LYNEX,
          contract: LynexQuoter__factory.connect(address, provider),
        };
      }
      case SwapV3DEXType.UNISWAP: {
        return {
          type: SwapV3DEXType.UNISWAP,
          contract: IQuoterV2__factory.connect(address, provider),
        };
      }
    }
  };

  get address() {
    return this._address;
  }

  quoteExactInputSingle = async (params: GetQuoteExactSingleParams) => {
    const { type, contract } = this._contract;
    switch (type) {
      case SwapV3DEXType.LYNEX: {
        const { tokenIn, tokenOut, amountIn, sqrtPriceLimitX96 } = params;
        const [quote] = await contract.callStatic.quoteExactInputSingle(
          tokenIn,
          tokenOut,
          amountIn,
          sqrtPriceLimitX96
        );
        return {
          amountOut: quote,
        };
      }
      case SwapV3DEXType.UNISWAP: {
        const [quote, sqrtPriceX96After] = await contract.callStatic.quoteExactInputSingle(params);

        return { amountOut: quote, sqrtPriceX96After };
      }
    }
  };

  quoteExactOutputSingle = async (params: GetQuoteExactOutputSingleParams) => {
    const { type, contract } = this._contract;
    switch (type) {
      case SwapV3DEXType.LYNEX: {
        const { tokenIn, tokenOut, amount, sqrtPriceLimitX96 } = params;
        const [quote] = await contract.callStatic.quoteExactOutputSingle(
          tokenIn,
          tokenOut,
          amount,
          sqrtPriceLimitX96
        );
        return {
          amountOut: quote,
        };
      }
      case SwapV3DEXType.UNISWAP: {
        const [quote, sqrtPriceX96After] = await contract.callStatic.quoteExactOutputSingle(params);

        return { amountOut: quote, sqrtPriceX96After };
      }
    }
  };
}
