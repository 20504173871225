import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Headline2 } from "src/components/shared/Typography";
import { ExpertSystemContext, StrategyListProvider } from "src/context/CEX/ExpertSystem";
import { ModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { AbilitiesContext } from "src/context/shared/Abilities";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Modules } from "./Modules";
import { Strategies } from "./Strategies";
import { SwitchTabs } from "./shared/style";
import * as styles from "./style";

export interface ExpertSystemProps {
  party: string;
  abilityName: string;
}

export const ExpertSystem = observer(({ party, abilityName }: ExpertSystemProps) => {
  const state = useLateInitContext(ExpertSystemContext);

  const abilitiesProps = { abilityName };

  useEffect(() => {
    state.setParty(party);
  }, [state, party]);

  const [currentKey, setCurrentKey] = useState("STRATEGIES");

  const onChangeKey = (value: string) => {
    const key = value as "STRATEGIES" | "MODULES";
    setCurrentKey(key);
  };

  return (
    <AbilitiesContext.Provider value={abilitiesProps}>
      <styles.Container>
        <styles.Header>
          <styles.WrapperTitle>
            <Headline2>Expert System</Headline2>
            <styles.SubTitle>Party: {party}</styles.SubTitle>
          </styles.WrapperTitle>

          <SwitchTabs
            border={false}
            selectedItem={currentKey}
            direction="row"
            menuItems={[
              { label: "STRATEGIES", value: "STRATEGIES" },
              { label: "MODULES", value: "MODULES" },
            ]}
            onChange={onChangeKey}
          />
        </styles.Header>

        <styles.Content>
          {currentKey === "STRATEGIES" ? (
            <StrategyListProvider>
              <Strategies />
            </StrategyListProvider>
          ) : (
            <ModulesContext.Provider>
              <Modules />
            </ModulesContext.Provider>
          )}
        </styles.Content>
      </styles.Container>
    </AbilitiesContext.Provider>
  );
});
