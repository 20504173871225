import { css } from "styled-components";
import { Container } from "src/components/BotsContent/CEX/shared/Card/Header/style";
import { Content } from "src/components/BotsContent/CEX/shared/Card/style";

export const ChartCardPaddingFix = css`
  ${Container} {
    z-index: 1;
  }

  ${Content} {
    //chart adds weird top padding, compensate with margin
    margin-top: -10px;
  }
`;

export enum DashboardBreakpoints {
  Tablet = "1100px",
  XMobile = "750px",
  Mobile = "450px",
}
