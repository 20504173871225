import styled, { css } from "styled-components";

interface ItemWidth {
  max: number;
  min: number;
}
export interface MasonryStyledProps {
  gap?: number;
  precision?: number;
  itemWidth?: ItemWidth;
}
function toPx(defaultPx: number, px?: number) {
  const pxValue = px !== undefined ? px : defaultPx;
  return `${pxValue}px`;
}

export const Masonry = styled.div<MasonryStyledProps>`
  width: 100%;
  height: 100%;

  display: grid;
  grid-gap: ${({ gap }) => toPx(5, gap)};
  ${({ itemWidth: width }) => {
    const columnWidth = width ? `minmax(${toPx(width.min)}, ${toPx(width.max)})` : "25%";
    return css`
      grid-template-columns: repeat(auto-fill, ${columnWidth});
    `;
  }}
  grid-auto-rows: ${({ precision }) => toPx(20, precision)};
  grid-auto-flow: row dense;
`;
