import styled from "styled-components";

export const Container = styled.div`
  display: inline-flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
