import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Loader } from "src/components/shared/Loader";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { PartyContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import AddLabelStore from "src/state/UserManager/EditParty/AddLabelStore";
import * as styles from "./style";

interface AddLabelProps {
  show: boolean;
  hide: React.Dispatch<React.SetStateAction<boolean>>;
  type: "API" | "ACCOUNT";
}

export const AddLabel = observer(({ show, hide, type }: AddLabelProps) => {
  const partyState = useLateInitContext(PartyContext);

  const labelState = useMemo(
    () =>
      new AddLabelStore(
        String(partyState?.party),
        partyState.currentAccount,
        partyState.currentEditAcc,
        type,
        partyState?.currentCredential
      ),
    [
      partyState?.party,
      partyState.currentAccount,
      partyState.currentEditAcc,
      partyState?.currentCredential,
      type,
    ]
  );

  useEffect(() => {
    labelState.reset();
    labelState.setTrigger(hide);
    if (partyState) labelState.setUpdateList(partyState?.getAccounts);
  }, [labelState, partyState, show, hide]);

  return (
    <ModalPanel label="Add label" show={show} loading={false} close={hide}>
      <styles.AddLabelForm
        onSubmit={labelState.submitHandler()}
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
      >
        <styles.Wrapper>
          <LabeledInput
            label="Name"
            value={labelState.name}
            onChange={(e) => labelState.setValue("name", e.target.value)}
            errorHint={labelState.nameErr}
          />
        </styles.Wrapper>

        <styles.Wrapper>
          <LabeledInput
            label="Value"
            value={labelState.value}
            onChange={(e) => labelState.setValue("value", e.target.value)}
            errorHint={labelState.valueErr}
          />

          <OutlineButton type="submit">Add label +</OutlineButton>
        </styles.Wrapper>
      </styles.AddLabelForm>
      <Loader show={labelState.isLoading} />
    </ModalPanel>
  );
});
