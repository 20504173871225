import * as styles from "./style";

export interface ChainInfoCardOwnProps {
  abbr?: string;
  icon?: JSX.Element;
  color?: string;
}

export interface ChainInfoCardProps
  extends ChainInfoCardOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof ChainInfoCardOwnProps> {}

export const ChainInfoCard = ({ abbr = "Network", icon, ...props }: ChainInfoCardProps) => {
  const Icon = icon ?? <styles.IconPlaceholder />;
  return (
    <styles.ChainInfoContainer {...props}>
      <styles.IconWrapper>{Icon}</styles.IconWrapper>
      <styles.Text>{abbr}</styles.Text>
    </styles.ChainInfoContainer>
  );
};
