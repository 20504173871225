import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface EmptyBalancesPlaceholderProps extends ComponentPropsWithoutRef<"div"> {
  currency: string;
}

export const EmptyBalancesPlaceholder = ({ currency }: EmptyBalancesPlaceholderProps) => {
  const text = `There is no ${currency} currency on this account`;
  return (
    <styles.Container>
      <styles.PlaceholderText>{text}</styles.PlaceholderText>
    </styles.Container>
  );
};
