import { TableContent } from "src/components/BotsContent/DEX_V2/Stats/Tables/TablePanel/TableContent";
import { HeaderCell } from "src/components/shared/GridTable";
import styled from "styled-components";

export const StyledStrategiesTable = styled(TableContent)`
  grid-template-columns:
    minmax(40px, auto) minmax(90px, 1fr) minmax(55px, auto) minmax(110px, 2fr)
    minmax(110px, 2fr) minmax(90px, 1fr)
    minmax(120px, 2fr) auto;

  td,
  th {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  ${HeaderCell} {
    font-size: ${({ theme }) => theme.fontSizes["12px"]};
  }
`;

export const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
`;
