import { observer } from "mobx-react-lite";
import { WalletLabeledInput } from "src/components/BotCreating/DEX/shared/WalletLabeledInput";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { SaveableProps } from "../../Volume";
import { SettingsPanel, SettingsPanelProps } from "../../shared/SettingsPanel";
import { FormContent } from "../../shared/style";
import * as styles from "./style";

export interface TransferWalletsPanelProps
  extends Omit<SettingsPanelProps, "label" | "children">,
    SaveableProps {
  isMobile?: boolean;
}

export const TransferWalletsPanel = observer(
  ({ isMobile, saveable, ...props }: TransferWalletsPanelProps) => {
    const { transferWalletsState: state, transferWalletsSubmitState: submitState } =
      useLateInitContext(DEXV2SettingsContext);

    const addEnabled = saveable && Boolean(state.wallet);

    return (
      <SettingsPanel label="Transfer Wallets" {...props}>
        <FormContent>
          <WalletLabeledInput
            label="Transfer Wallet"
            onAdd={submitState.addUpdateWallet}
            addEnabled={addEnabled}
            value={state.wallet}
            onChange={state.setWallet}
            errorHint={state.walletErrors}
          />
          <styles.MainWalletsList
            hashes={state.walletsAsFieldProps}
            hashesErrors={state.walletsError}
            onDeleteHash={submitState.deleteUpdateWallet}
            showDelete={saveable}
            fixedHeight={isMobile}
          />
        </FormContent>
      </SettingsPanel>
    );
  }
);
