import { Controller, FormProvider, useForm } from "react-hook-form";
import { BaseModuleForm } from "src/components/BotsContent/CEX/ExpertSystem/shared";
import { RowWrapper } from "src/components/BotsContent/CEX/ExpertSystem/shared/style";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { PopupContext } from "src/context/shared/PopupContext";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategyModuleCreateType } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import { getEmptyActionModule } from "../shared/utils";

const MODULE_TYPE = "alert_tg";

export const AlertTg = () => {
  const state = useLateInitContext(CreateModulesContext);
  const { close } = useLateInitContext(PopupContext);

  const methods = useForm<IStrategyModuleCreateType<typeof MODULE_TYPE>>({
    defaultValues: getEmptyActionModule(MODULE_TYPE),
  });

  const { handleSubmit, control } = methods;

  return (
    <FormProvider {...methods}>
      <BaseModuleForm
        onSubmit={handleSubmit(state.createActionHandler(close))}
        formId={MODULE_TYPE}
      >
        <RowWrapper>
          <Controller
            control={control}
            name="hard_params.username"
            rules={{ required: ERRORS_MSGS.isRequired }}
            render={({ field: { value, onChange }, formState: { errors } }) => {
              const error = getFieldError(errors, "hard_params.username");

              return (
                <LabeledInput
                  label="User name"
                  value={value}
                  onChange={(e) => onChange(getChangeEventValue(e))}
                  errorHint={error?.message}
                />
              );
            }}
          />
        </RowWrapper>
      </BaseModuleForm>
    </FormProvider>
  );
};
