import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { Button } from "src/components/shared/Buttons/v2/Button";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { useDeployerInfoStore } from "src/context/DEXV2/DEXV2Create/WalletGasFill";
import { LoadState } from "src/helpers/network/state";
import useLocalStore from "src/hooks/useLocalStore";
import ContractsDeployStore from "src/state/DEXV2/DEXV2Create/ContractsDeployStore";
import { TabStepProps } from "..";
import { useBotUUID } from "../hooks/useBotUUID";
import { useStepActions } from "../hooks/useStepActions";
import { StepHeading } from "../shared/StepHeading";
import { ErrorState } from "./ErrorState";
import { FinishState } from "./FinishState";
import { LoadingState } from "./LoadState";
import * as styles from "./style";

interface ContractsDeployProps extends TabStepProps {}

export const ContractsDeploy = observer(({ nextStep, prevStep }: ContractsDeployProps) => {
  const deployerInfoStore = useDeployerInfoStore();
  const state = useLocalStore(ContractsDeployStore, deployerInfoStore);

  const uuid = useBotUUID();
  const [prev, next] = useStepActions(uuid, prevStep, nextStep);

  useEffect(() => {
    if (!uuid) return;
    state.setBotUUID(uuid);
    state.getDeployerStatus(prev);
  }, [state, uuid, prev]);

  const isErrorDeploy = state.deployStatus === LoadState.Error;
  const isSuccessDeploy = state.deployStatus === LoadState.Success;

  const showNavigation = isErrorDeploy || isSuccessDeploy;

  const DeployState = useCallback(() => {
    switch (state.deployStatus) {
      case LoadState.Success: {
        return <FinishState />;
      }
      case LoadState.Error: {
        return <ErrorState />;
      }
      default: {
        return <LoadingState />;
      }
    }
  }, [state.deployStatus]);

  const chainMetaProps = state.chainMeta;

  return (
    <styles.Container>
      <StepHeading title="Contracts Deploy" {...chainMetaProps} />
      <styles.StatusPanel>
        <DeployState />
      </styles.StatusPanel>
      {showNavigation && (
        <styles.WrapperBtn>
          <OutlineButton stretched onClick={prev} disabled={isSuccessDeploy}>
            Step back
          </OutlineButton>
          <Button stretched onClick={next} disabled={!isSuccessDeploy}>
            Finish
          </Button>
        </styles.WrapperBtn>
      )}
    </styles.Container>
  );
});
