import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useModalState } from "src/hooks/useModalState";
import WindowConsent from "src/state/WindowConsent";
import { SettingsModeIcon } from "../../shared/SettingsModeIcon";
import { SettingsPanelProps } from "../../shared/SettingsPanel";
import { FormCol, HeaderCheckbox } from "../../shared/style";
import { StrategiesModal } from "./StrategiesModal";
import { StrategiesTable } from "./StrategiesTable";
import * as styles from "./style";

export interface CounterPanelProps extends Omit<SettingsPanelProps, "label" | "children"> {
  isMobile?: boolean;
}

export const CounterPanel = observer(({ isMobile, ...props }: CounterPanelProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  const {
    isShown: isStrategiesModalShown,
    show: showStrategiesModal,
    close: closeStrategiesModal,
  } = useModalState();

  const onSelectStrategy = useCallback(
    (index: number | null) => {
      state.setSelectedCounterStrategy(index);
      showStrategiesModal();
    },
    [showStrategiesModal, state]
  );

  const onDeleteStrategy = useCallback(
    (index: number) => {
      WindowConsent.showWindow(
        "",
        "Are you sure you want to delete the strategy?",
        state.deleteCounterStrategy,
        index
      );
    },
    [state]
  );

  return (
    <styles.StyledCounterPanel
      label="Counter Settings"
      labelIcon={<SettingsModeIcon mode="counter" />}
      toggle={
        <OutlineButton size="s" onClick={() => onSelectStrategy(null)}>
          Add
        </OutlineButton>
      }
      checkbox={
        <HeaderCheckbox
          label="Cumulative"
          checked={state.data.counter_data.cumulative}
          onChange={state.getHandler("counter_data.cumulative")}
        />
      }
      {...props}
      $isMobile={isMobile}
    >
      <styles.FormContent orientation="row">
        <FormCol>
          <StrategiesTable
            strategies={state.counterStrategies}
            onDeleteStrategy={onDeleteStrategy}
            onEditStrategy={onSelectStrategy}
            onToggleActiveStrategy={state.toggleCounterStrategyStatus}
          />
        </FormCol>
      </styles.FormContent>
      <StrategiesModal
        show={isStrategiesModalShown}
        onClose={closeStrategiesModal}
        initialData={state.selectedCounterStrategy}
        onSave={state.updateCounterStrategy}
      />
    </styles.StyledCounterPanel>
  );
});
