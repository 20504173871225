import { makeAutoObservable } from "mobx";
import { BalanceHistoryQueryParams } from "src/api/bots/DEXV2/history";
import { IBotUUIDProvider } from "src/state/DEXV2/DEXV2Bots/DEXV2BotStore";
import { IHashesPaginationStoreProvider } from "../HashesPaginationStore";
import { HashType } from "../HashesTypeStore";
import { IHashesPaginationFetcherProvider } from "./HashesPaginationFetcherProvider";

export class HashesPaginationProvider<T extends HashType>
  implements IHashesPaginationStoreProvider<T>
{
  private _uuidProvider: IBotUUIDProvider;

  private _fetcherProvider: IHashesPaginationFetcherProvider<T>;

  constructor(
    uuidProvider: IBotUUIDProvider,
    fetcherProvider: IHashesPaginationFetcherProvider<T>
  ) {
    makeAutoObservable(this);

    this._uuidProvider = uuidProvider;
    this._fetcherProvider = fetcherProvider;
  }

  private _getFetcherQueryParams = (
    fromPage: number,
    pageSize: number
  ): BalanceHistoryQueryParams => ({
    pagination: { offset: `${pageSize}`, page_num: `${fromPage}` },
  });

  private _getHashesFetcher = () => this._fetcherProvider.getHashesFetcher();

  getItems = async (fromPage: number, pageSize: number) => {
    const uuid = this._uuidProvider.botUUID;
    if (!uuid) return;

    const queryParams = this._getFetcherQueryParams(fromPage, pageSize);
    const getHashes = this._getHashesFetcher();

    const hashes = await getHashes(uuid, queryParams);
    if (!hashes) return undefined;

    const { items, pagesCount, type } = hashes;
    return { type, items: items ?? [], pagesCount };
  };
}
