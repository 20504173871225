import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const GraphContainer = styled.div`
  width: 100%;

  display: flex;
  flex: 1 0 auto;
`;

export const InfoWrapper = styled.span`
  position: absolute;
  right: -5px;
  top: -5px;
  cursor: pointer;
  z-index: 1000;

  opacity: 0.6;

  transition: opacity 0.3s ease;

  :hover {
    opacity: 1;
  }

  > svg {
    width: 15px;
    height: 15px;
  }
`;
