import { ComponentPropsWithoutRef, useMemo } from "react";
import { PNLV2Item, PNLV2ItemProps } from "../Item/PNLV2Item";
import { PNLV2ItemContainerProps } from "../Item/PNLV2Item/style";
import { PNLV2PriceItem, PNLV2PriceItemProps } from "../Item/PNLV2PriceItem";
import * as styles from "./style";

export type PNLV2TextPrice = "NET PROFIT" | "NET LOSS";
export type PNLV2Price = number | `${number}` | PNLV2TextPrice;

export type PNLV2Data = {
  quote?: string;
  base?: string;
  deltaQuote?: string | number;
  deltaBase?: string | number;
  price?: PNLV2Price;
  fee?: string | number;
};

export interface PNLV2ItemsOwnProps
  extends Omit<PNLV2Data, "fee">,
    styles.PNLV2ItemsContainerProps,
    Pick<PNLV2ItemProps, "coloredLabel" | "showArrow"> {
  itemsOrientation?: PNLV2ItemContainerProps["orientation"];
  itemsSpace?: PNLV2ItemContainerProps["space"];
}

export interface PNLV2ItemsProps extends PNLV2ItemsOwnProps, ComponentPropsWithoutRef<"div"> {}

export const PNLV2Items = ({
  quote = "USD",
  base = "Base",
  deltaBase,
  deltaQuote,
  price,
  space,
  orientation,
  itemsOrientation,
  itemsSpace,
  coloredLabel,
  showArrow,
  ...props
}: PNLV2ItemsProps) => {
  const baseItemsProps: Partial<PNLV2ItemProps> = {
    orientation: itemsOrientation,
    coloredLabel,
    showArrow,
    space: itemsSpace,
  };

  const pnlItems = useMemo(() => {
    const baseLabel = `Δ${base}`;
    const quoteLabel = `Δ${quote}`;
    const priceLabel = "Price";

    const items: (PNLV2ItemProps | PNLV2PriceItemProps)[] = [
      { label: quoteLabel, value: deltaQuote, type: "delta", key: "quote" },
      { label: baseLabel, value: deltaBase, type: "delta", key: "base" },
      {
        label: priceLabel,
        price,
        quote: deltaQuote,
        type: "price",
        key: "price",
        showArrow: false,
      },
    ];

    return items;
  }, [base, deltaBase, deltaQuote, price, quote]);

  return (
    <styles.PNLV2ItemsContainer {...props} $orientation={orientation} $space={space}>
      {pnlItems.map(({ key, ...itemProps }) => {
        switch (key) {
          case "price": {
            return <PNLV2PriceItem {...baseItemsProps} {...itemProps} key={key} />;
          }
          default: {
            return <PNLV2Item {...baseItemsProps} {...itemProps} key={key} />;
          }
        }
      })}
    </styles.PNLV2ItemsContainer>
  );
};
