import { CurrencyAmount, NativeCurrency, Price, Token } from "@uniswap/sdk-core";
import { Mapper } from "src/helpers/utils";
import { ABSTRACT_NATIVE_CURRENCY, tryParseCurrencyAmount } from "../../DEXV2Swap/utils";
import { ModuleStatusName, SettingsStatusModule } from "../types";

export type NativeQuote = {
  usd?: CurrencyAmount<Token>;
  native?: CurrencyAmount<NativeCurrency>;
};

export type BaseQuote = {
  usd?: CurrencyAmount<Token>;
  base?: CurrencyAmount<Token>;
};

export const parseNativeQuote = (
  native: string | number | undefined,
  nativeUSDPrice: Price<NativeCurrency, Token> | undefined
): NativeQuote => {
  const rawNative = tryParseCurrencyAmount(native?.toString(), ABSTRACT_NATIVE_CURRENCY);

  if (!rawNative) {
    return {};
  }

  if (!nativeUSDPrice) return { native: rawNative };

  const nativeUsd = nativeUSDPrice.quote(rawNative);

  return { usd: nativeUsd, native: rawNative };
};

export const parseBaseQuote = (
  base: string | number | undefined,
  baseToken: Token | undefined,
  baseUSDPrice: Price<Token, Token> | undefined
): BaseQuote => {
  const rawBase = tryParseCurrencyAmount(base?.toString(), baseToken);

  if (!rawBase) {
    return {};
  }

  if (!baseUSDPrice) return { base: rawBase };

  const baseUSD = baseUSDPrice.quote(rawBase);

  return { usd: baseUSD, base: rawBase };
};

export const settingsModuleToStatusModule: Mapper<SettingsStatusModule, ModuleStatusName> = (
  module
) => {
  const [moduleStatus] = module.split("_");
  return moduleStatus as ModuleStatusName;
};

export const statusModuleToSettingsModule: Mapper<ModuleStatusName, SettingsStatusModule> = (
  module
) => `${module}_data` as const;
