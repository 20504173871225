import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { formatCurrencyAmount } from "src/state/DEXV2/DEXV2Swap/utils";
import { usePriceCalculatorState } from "../../hooks/usePriceCalculatorState";
import * as styles from "./style";

const getQuoteMessage = (quote?: CurrencyAmount<Token>) => {
  const formattedQuote = formatCurrencyAmount(quote, true);
  return formattedQuote;
};

export interface QuoteSpanProps extends ComponentPropsWithoutRef<"span"> {}

export const QuoteSpan = observer((props: QuoteSpanProps) => {
  const { info } = usePriceCalculatorState();

  const {
    quoteAmount: { quote },
  } = info;

  const message = getQuoteMessage(quote);
  return <styles.QuoteSpan {...props}>{message}</styles.QuoteSpan>;
});
