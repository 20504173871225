import { observer } from "mobx-react-lite";
import { HTMLAttributes, useCallback } from "react";
import { ITooltip as TooltipProps } from "react-tooltip";
import { VaultsQuotesProviderContext } from "src/context/DEXV2/DEXV2Stats/Vaults/VaultsQuotesProvider";
import { IVaultsQuotesProvider } from "src/state/DEXV2/DEXV2Stats/Vaults/VaultsProvider";
import { TradeSide } from "src/state/DEXV2/shared/TradeToken";
import {
  VaultQuoteAddress,
  VaultsQuotesTooltipContent,
  getVaultQuotesDataTip,
} from "./VaultsPricesTooltipContent";
import * as styles from "./style";

export type TooltipRender = NonNullable<TooltipProps["render"]>;

const VAULTS_QUOTES_TOOLTIP_ID = "vaults-quotes-tooltip";

export const getVaultsQuotesTooltipAttrs = (
  address: VaultQuoteAddress,
  side: TradeSide
): HTMLAttributes<any> => {
  const dataTip = getVaultQuotesDataTip(address, side);
  return {
    "data-tooltip-content": dataTip,
    "data-tooltip-id": VAULTS_QUOTES_TOOLTIP_ID,
  };
};
export interface VaultsQuotesTooltipProps
  extends Omit<TooltipProps, "id" | "place" | "render" | "className"> {
  quotesProvider: IVaultsQuotesProvider;
}

export const VaultsQuotesTooltip = observer(
  ({ quotesProvider, ...props }: VaultsQuotesTooltipProps) => {
    const tooltipID = VAULTS_QUOTES_TOOLTIP_ID;

    const { isEmptyQuotes } = quotesProvider;

    const getTooltipContent: TooltipRender = useCallback(
      ({ content: tooltipStr }) =>
        !isEmptyQuotes && <VaultsQuotesTooltipContent dataTip={tooltipStr} />,
      [isEmptyQuotes]
    );

    return (
      <VaultsQuotesProviderContext.Provider value={quotesProvider}>
        <styles.QuotesTooltip id={tooltipID} render={getTooltipContent} {...props} />
      </VaultsQuotesProviderContext.Provider>
    );
  }
);
