import * as styles from "./style";

export interface EmptyListTextProps {
  error?: string;
  emptyText?: string;
}

export const EmptyHashesText = ({ error, emptyText = "No wallets added" }: EmptyListTextProps) =>
  error ? (
    <styles.HashesErrorText>{error}</styles.HashesErrorText>
  ) : (
    <styles.EmptyHashesText>{emptyText}</styles.EmptyHashesText>
  );
