import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { useCallback, useLayoutEffect, useState } from "react";
import { useVirtual } from "react-virtual";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IOrderBookOrder } from "src/state/CEX/CEXExchange/OrderBook/OrderBookStore";
import { SellCupProps } from ".";
import * as styles from "../../shared";
import { SellBody } from "./style";

interface VirtualSellRowCupProps extends Omit<SellCupProps, "showMobVers"> {
  columns: any;
  rowSize: number;
}

export const VirtualSellRowCup = observer(
  ({ data, columns, rowSize, sellCupRef, sellContainerRef }: VirtualSellRowCupProps) => {
    const mainState = useLateInitContext(ExchangeContext);
    const { orderBookState } = mainState;
    const { buySellState } = mainState;

    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });

    // scroll down
    useLayoutEffect(() => {
      const { firstLoad } = orderBookState.dataStorage;

      // for single scroll in a WS mode
      if (firstLoad) {
        const scrollContainer = sellContainerRef.current;

        if (scrollContainer) {
          scrollContainer.scrollTop = scrollContainer.scrollHeight;
        }
      }
    }, [sellContainerRef, data, orderBookState.dataStorage.firstLoad]);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
      parentRef: sellContainerRef,
      size: rows.length,
      estimateSize: useCallback(() => rowSize, [rowSize]),
      overscan: 3,
    });
    const { virtualItems: virtualRows } = rowVirtualizer;

    return (
      <styles.Container
        ref={sellContainerRef}
        onClick={() => {
          buySellState.setOrder("buyOrder", {
            price: orderBookState.selectedSellOrder?.price || "",
            amount: String(orderBookState.currentTotalSell),
          });
        }}
      >
        <styles.OrderBookTable style={{ display: "flex" }}>
          <SellBody
            style={{
              height: `${rowVirtualizer.totalSize}px`,
            }}
            ref={sellCupRef}
          >
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<IOrderBookOrder>;

              return (
                <styles.SellRow
                  data-tooltip-id="sell-tooltip"
                  key={row.id}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                  onMouseEnter={() =>
                    orderBookState.setSelectedRow("selectedRowSell", virtualRow.index)
                  }
                  onMouseLeave={() => {
                    orderBookState.setSelectedRow("selectedRowSell", null);
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    const cellValue = cell.getValue<string>();

                    return (
                      <styles.Cell key={cell.id} data-value={cellValue}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </styles.Cell>
                    );
                  })}
                </styles.SellRow>
              );
            })}
          </SellBody>
        </styles.OrderBookTable>
      </styles.Container>
    );
  }
);
