import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import {
  ExchangeLegendChartData,
  IExchangesLegendChartDataProvider,
} from "../../shared/ExchangesLegendV2Store/types";

export const EXCHANGES_FEE_ID = "fee";

export type ExchangeFeePieType = typeof EXCHANGES_FEE_ID;

export class ExchangesFeeV2DataProvider
  implements IExchangesLegendChartDataProvider<ExchangeFeePieType>
{
  private _chartData: ExchangeLegendChartData | null = null;

  constructor() {
    makeAutoObservable(this);

    makeLoggable<any>(this, { _chartDataMap: true });
  }

  private _setChartData = (data: ExchangeLegendChartData | null) => {
    this._chartData = data;
  };

  private _clearChartData = () => {
    this._setChartData(null);
  };

  get data() {
    return this._chartData?.data ?? [];
  }

  get chartSelectorProps() {
    return null;
  }

  get columnTitles() {
    return {
      amount: "Amount $",
    };
  }

  get total() {
    return this._chartData?.total;
  }

  get charts() {
    const chart = this._chartData?.chart;
    return chart ? [chart] : [];
  }

  setChartData = (_chartId: ExchangeFeePieType, data: ExchangeLegendChartData) => {
    this._setChartData(data);
  };

  removeChartData = (chartId: ExchangeFeePieType) => {
    this._clearChartData();
  };

  destroy = () => {
    this._clearChartData();
  };
}
