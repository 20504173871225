import styled from "styled-components";
import { ColorBox as BaseColorBox } from "../../../../../../shared/Graph/GraphTooltip/GraphTooltipSeriesContent/style";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ColorBox = styled(BaseColorBox)``;
