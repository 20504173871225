interface RadioIconProps {
  checked: boolean;
}

export const RadioIcon = ({
  checked,
  ...props
}: RadioIconProps & React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden {...props}>
    <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" fill="none" />
    {checked && <circle cx="12" cy="12" r="7.5" fill="currentColor" />}
  </svg>
);
