import styled from "styled-components";

export const AddAnalyticForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 10px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 27px;

  margin-top: 37px;
`;
