import styled from "styled-components";
import { ReportItem } from "../style";

export const BalanceTokenContainer = styled(ReportItem)`
  position: relative;
  /* grid-area: balance-token; */
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 100%;
  border-radius: ${({ theme }) => theme.radii["4px"]};

  grid-area: balance-token;
`;
