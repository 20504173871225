import { createContextProvider } from "src/context/utils/createContextProvider";
import useLocalStore from "src/hooks/useLocalStore";
import { LiquidityTabStore } from "src/state/CEX/liquidityTab";

const CreateLiquidityTabStore = () => useLocalStore(LiquidityTabStore);

const { Context: LiquidityTabContext, Provider: LiquidityTabProvider } =
  createContextProvider<LiquidityTabStore>({
    stateCreator: CreateLiquidityTabStore,
  });

export { LiquidityTabContext, LiquidityTabProvider };
