import { PNLV2Context } from "src/context/CEX/Dashboard/v2/PNLV2";
import { ProfitLossChartV2, ProfitLossChartV2Props } from "./ProfitLossChartV2";

export interface ProfitLossChartV2WidgetProps extends ProfitLossChartV2Props {}

export const ProfitLossChartV2Widget = (props: ProfitLossChartV2WidgetProps) => (
  <PNLV2Context.Provider>
    <ProfitLossChartV2 {...props} />
  </PNLV2Context.Provider>
);
