import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Tooltip } from "react-tooltip";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { UserGroupsContext } from "src/context/UserManager/UserGroups";
import { noOp } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import WindowConsent from "src/state/WindowConsent";
import { AccessForm, FormRow } from "../../../shared";
import { UserGroupLink } from "./UserGroupLink";
import * as styles from "./styles";

export const EditUserGroup = observer(() => {
  const {
    userGroupsState: { editUserGroupState: state },
  } = useLateInitContext(UserGroupsContext);

  const onRemoveUserGroup = useCallback(() => {
    WindowConsent.showWindow(
      "",
      `Are you sure you want to delete the user group: ${state.editUserGroup.name}?`,
      state.deleteUserGroup
    );
  }, [state]);

  const userGroupLinks = state.editUserGroup.users.map((user) => (
    <UserGroupLink userName={user} key={user} />
  ));

  return (
    <styles.FlexModalPanel
      label={`Edit user group ${state.editUserGroup.name}`}
      show={state.isShown}
      close={state.closeModal}
      loading={state.isLoading}
    >
      <AccessForm
        onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
        onSubmit={state.submitHandler()}
      >
        <styles.FormContent style={{ flexGrow: 1 }}>
          <FormRow>
            <LabeledInput
              label="Group type"
              value={state.editUserGroup.type}
              readOnly
              onChange={noOp}
            />
            <LabeledSelector
              label="User group head"
              options={state.headSelectorOptions}
              onChange={state.getSelectorHandler("head")}
              value={state.selectorValue("head")}
              errorHint={state.errors.head}
              isClearable
            />
          </FormRow>

          <styles.Divider />

          {userGroupLinks.length ? (
            <styles.UserGroupLinksScrollWrapper>
              <styles.UserGroupLinksWrapper>{userGroupLinks}</styles.UserGroupLinksWrapper>
            </styles.UserGroupLinksScrollWrapper>
          ) : null}

          <styles.ButtonsWrapper>
            <styles.AddButtonWrapper
              data-tooltip-content="Can`t delete group with users"
              data-iscapture="true"
            >
              <OutlineButton stretched onClick={onRemoveUserGroup} disabled={state.deleteDisabled}>
                Delete group
              </OutlineButton>
              {state.deleteDisabled ? <Tooltip variant="info" /> : null}
            </styles.AddButtonWrapper>
            <SubmitButton stretched>Save group</SubmitButton>
          </styles.ButtonsWrapper>
        </styles.FormContent>
      </AccessForm>
    </styles.FlexModalPanel>
  );
});
