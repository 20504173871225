import { ComponentPropsWithoutRef } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import * as styles from "./style";

export interface BottomDrawerContentProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
}

export const BottomDrawerContent = ({ title, children, ...props }: BottomDrawerContentProps) => {
  const { close: onClose } = useLateInitContext(PopupContext);
  return (
    <styles.BackgroundOverlay {...props}>
      <styles.Panel>
        <styles.Container>
          <styles.Header>
            <IconButton onClick={onClose} size="l" boxed>
              {Icons.arrowBack()}
            </IconButton>
            <styles.Title>{title}</styles.Title>
          </styles.Header>
          <styles.Content>{children}</styles.Content>
        </styles.Container>
      </styles.Panel>
    </styles.BackgroundOverlay>
  );
};
