import { ApiModules, apiUrl } from "src/api";
import ResponseHandler from "src/state/network/ResponseHandler";

export const getDEXBots = async () =>
  ResponseHandler.handler({ url: "/swap-bots", method: "GET" }, "getDEXBots");

export const getDEXClients = async () =>
  ResponseHandler.handler(
    { url: apiUrl(ApiModules.SwapBots, "clients"), method: "GET" },
    "DEXClients"
  );

export const getDEXExchanges = async () =>
  ResponseHandler.handler(
    { url: apiUrl(ApiModules.SwapBots, "exchanges"), method: "GET" },
    "DEXExch"
  );

export const getDEXBot = async (bot_uuid: string) =>
  ResponseHandler.handler(
    { url: apiUrl(ApiModules.SwapBot, `${bot_uuid}`), method: "GET" },
    "getDEXBot"
  );

export const getDEXRecentParties = async () =>
  ResponseHandler.handler(
    {
      url: apiUrl(ApiModules.SwapBots, "/latest"),
      method: "GET",
    },
    "getDEXRecentParties"
  );
