import { Dispatch, useCallback, useMemo, useState } from "react";

export interface UseStepHelpers {
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
  canGoToNext: boolean;
  canGoToPrev: boolean;
  setStep: Dispatch<number>;
}

export type SetStepCallbackType = (step: number) => void;

/**
 * Steps (0-based) navigation and state provider
 * @param stepsCount total number of steps
 * @returns currentStep and action functions
 */
export const useStep = (stepsCount: number): [number, UseStepHelpers] => {
  const [currentStep, setCurrentStep] = useState(0);

  const canGoToNext = useMemo(() => currentStep + 1 < stepsCount, [currentStep, stepsCount]);

  const canGoToPrev = useMemo(() => currentStep - 1 >= 0, [currentStep]);

  const setStep = useCallback<SetStepCallbackType>(
    (newStep) => {
      if (newStep >= 0 && newStep < stepsCount) {
        setCurrentStep(newStep);
        return;
      }

      throw new Error(`new step ${newStep} is out of range: [${0}-${stepsCount}]`);
    },
    [stepsCount]
  );

  const nextStep = useCallback(() => {
    if (canGoToNext) {
      setCurrentStep((step) => step + 1);
    }
  }, [canGoToNext]);

  const prevStep = useCallback(() => {
    if (canGoToPrev) {
      setCurrentStep((step) => step - 1);
    }
  }, [canGoToPrev]);

  const reset = useCallback(() => {
    setCurrentStep(0);
  }, []);

  return [
    currentStep,
    {
      nextStep,
      prevStep,
      canGoToNext,
      canGoToPrev,
      setStep,
      reset,
    },
  ];
};
