import { Token, TradeType } from "@uniswap/sdk-core";
import { IGetRouteStateParams, IRouteStateProvider } from "../../../shared/Swap/Router";
import { V2Route } from "../entities/V2Route";
import { V2Trade } from "../entities/V2Trade";
import { IV2RoutesProvider } from "./V2RoutesProvider";

export enum V2SwapRouteError {
  InsufficientInputAmount = "InsufficientInputAmount",
  InsufficientReserves = "InsufficientReserves",
}

export type V2SwapRoute = {
  /**
   * The Trade object representing the swap.
   */
  trade: V2Trade<Token, Token, TradeType>;
  /**
   * The route of the swap.
   */
  route: V2Route<Token, Token>;
};

interface IV2RouteStateParams {
  routesProvider: IV2RoutesProvider;
}

export class V2RouteStateProvider implements IRouteStateProvider {
  private _routesProvider: IV2RoutesProvider;

  constructor({ routesProvider }: IV2RouteStateParams) {
    this._routesProvider = routesProvider;
  }

  getRouteState = async ({ amount, swapType, path, options }: IGetRouteStateParams) => {
    const route = await this._routesProvider.getRoute(path, options);
    if (!route) return null;

    const trade = new V2Trade(route, amount, swapType);

    return { trade, route };
  };
}
