import styled from "styled-components";
import { ImpactCard } from "./ImpactCard";
import { WorkingSinceCard } from "./WorkingSinceCard";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledImpactCard = styled(ImpactCard)`
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;

export const StyledWorkingSinceCard = styled(WorkingSinceCard)`
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;
