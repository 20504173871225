import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { ExchangeAccountingContext } from "src/context/CEX/ExchangeAccounting";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { AccountingTable } from "./AccountingTable";
import * as styles from "./style";

export interface ContentProps {}

export const Content = observer((props: ContentProps) => {
  const state = useLateInitContext(ExchangeAccountingContext.Context);

  return (
    <styles.Container>
      <AccountingTable data={state.selectedData} />
      <Loader show={state.loading} />
    </styles.Container>
  );
});
