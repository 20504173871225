import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledCreatableSelector } from "src/components/shared/Forms/Selectors";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { NewPairAnalytic } from "src/modules/analytics";
import ModalAnalyticStore from "src/state/CEX/analytics/ModalAnalyticStore";
import * as styles from "./style";

interface UsePairInputParams extends Pick<AnalyticModalProps, "type" | "pair"> {
  state: ModalAnalyticStore;
}

const usePairInput = ({ type, pair, state }: UsePairInputParams) => {
  const handleCreate = useCallback(
    (inputValue: string) => {
      state.setNewPair(inputValue);
      state.setPair(String(inputValue));
    },
    [state]
  );

  if (type !== "add") return "";
  return pair ? (
    <LabeledInput readOnly label="Pair" value={pair} />
  ) : (
    <LabeledCreatableSelector
      label="Pair"
      value={{
        value: state.newPairAnalytic.pair,
        label: state.newPairAnalytic.pair,
      }}
      options={state.shortPairsList}
      onChange={(data) => {
        state.setPair(String(data ? data.value : ""));
      }}
      onInputChange={state.setSearchPair()}
      onCreateOption={handleCreate}
      errorHint={state.errors.pair}
    />
  );
};

interface AnalyticModalProps {
  bot_uuid: string;
  market: string;
  label: string;
  type: "add" | "edit";
  show: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  pair: string;
  saveAnalytic: (analytic: NewPairAnalytic) => void;
  setPairCb: (pair: string) => void;
}

export const AnalyticModal = observer(
  ({
    bot_uuid,
    market,
    label,
    type,
    show,
    close,
    pair,
    saveAnalytic,
    setPairCb,
  }: AnalyticModalProps) => {
    const state = useMemo(() => new ModalAnalyticStore(), []);

    const PairInput = usePairInput({ type, pair, state });

    useEffect(() => {
      state.setBotUUID(bot_uuid);
      state.setMarket(market);
      state.getAllExchPairs();
    }, [state, bot_uuid, market]);

    useEffect(() => {
      state.setPair(pair);
      state.setSaveCb(saveAnalytic);
      state.setModalShow(close);
    }, [state, pair, saveAnalytic, close]);

    useEffect(() => {
      if (!state.newPairAnalytic.pair) return;

      setPairCb(state.newPairAnalytic.pair);
    }, [setPairCb, state.newPairAnalytic.pair]);

    return (
      <ModalPanel label={label} show={show} close={close} loading={state.isLoading} zIndex={1000}>
        <styles.AddAnalyticForm
          onKeyDown={(event) => event.key === "Enter" && event.preventDefault()}
          onSubmit={state.submitHandler()}
        >
          {PairInput}

          <LabeledInput
            label="Strategy name"
            value={state.newPairAnalytic.name}
            onChange={state.getHandler("name")}
            errorHint={state.errors.name}
          />

          <styles.ButtonsWrapper>
            <OutlineButton type="submit" stretched>
              {type === "add" ? "Add pair +" : "Save change"}
            </OutlineButton>
          </styles.ButtonsWrapper>
        </styles.AddAnalyticForm>
      </ModalPanel>
    );
  }
);
