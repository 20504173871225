import { deepmerge } from "@mui/utils";
import { SeriesType } from "lightweight-charts";
import { useMemo } from "react";
import { SeriesRootProps } from "..";
import { OptionalSeriesOptions } from "../types";
import { priceFormatter } from "../utils";

type PriceFormatOptions = NonNullable<OptionalSeriesOptions<SeriesType>["priceFormat"]>;
export type UseDefaultSeriesOptionsParams<T extends SeriesType> = Pick<
  SeriesRootProps<T>,
  "showPriceSuffix" | "startOptions"
>;

export const useDefaultSeriesOptions = <T extends SeriesType>({
  showPriceSuffix,
  startOptions,
}: UseDefaultSeriesOptionsParams<T>) => {
  const defaultOptions: OptionalSeriesOptions<T> = useMemo(() => {
    const priceFormat: PriceFormatOptions = showPriceSuffix
      ? {
          type: "custom",
          formatter: priceFormatter,
        }
      : {
          precision: 4,
        };
    return {
      priceFormat,
    };
  }, [showPriceSuffix]);

  const options = useMemo(
    () => deepmerge(defaultOptions, startOptions),
    [defaultOptions, startOptions]
  );

  return options;
};
