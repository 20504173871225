import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  padding-top: 20px;

  @media screen and (max-width: 450px) {
    padding-top: 0px;
  }
`;

export const Column = styled.div`
  width: 50%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:first-of-type {
    margin-right: 24px;
  }
`;
