import { LineData } from "lightweight-charts";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { SeriesProps } from "src/components/shared/Graph/types";
import { SpreadContext } from "src/context/CEX/Dashboard/v2/Spread";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { SpreadData } from "src/state/CEX/CEXDashboard/v2/widgets/SpreadV2Store";
import { ISeriesState, SeriesStore } from "src/state/Graph/Series";
import { CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { useGraphsChartOptions } from "../../../shared/config/graphs";
import { useChartColorWheel } from "../../../shared/hooks/useChartColorWheel";
import { DashboardV2Widget, getWidgetTitle } from "../shared/constants";
import { GraphCard, StyledGraph } from "../shared/style";
import { LegendSeriesSelector } from "./SpreadChartLegend/LegendSeriesSelector";
import { SpreadChartTooltip } from "./SpreadChartTooltip";

interface UseSeriesProps {
  data: SpreadData;
}

const useSeriesData = ({ data }: UseSeriesProps): SeriesProps[] => {
  const colorWheel = useChartColorWheel();

  const seriesProps = useMemo((): SeriesProps[] => {
    const seriesData = toJS(data);
    return seriesData.map(({ ticker, data }, index) => {
      const color = colorWheel[index];
      const series: SeriesProps = {
        type: "Line",
        color,
        data: data as LineData[],
        visible: index === 0,
        title: ticker,
        side: "left",
        startOptions: {
          priceFormat: {
            type: "percent",
          },
        },
        options: {
          lineWidth: 1,
        },
      };

      return series;
    });
  }, [colorWheel, data]);

  return seriesProps;
};

export interface SpreadChartProps extends Omit<CardProps, "title"> {}

export const SpreadChart = observer((props: SpreadChartProps) => {
  const state = useLateInitContext(SpreadContext.Context);

  const seriesState: ISeriesState = useLocalStore(SeriesStore);

  const { data, loading } = state;

  const { options } = useGraphsChartOptions();

  const seriesProps = useSeriesData({ data });

  return (
    <GraphCard
      title={getWidgetTitle(DashboardV2Widget.Spread)}
      loading={loading}
      afterTitle={
        <LegendSeriesSelector
          data={seriesState.legendSeriesData}
          showSeries={seriesState.showSingleSeries}
        />
      }
      {...props}
    >
      <StyledGraph
        series={seriesProps}
        allowTimeScale={false}
        autoColorScales={false}
        options={options}
        tooltip={SpreadChartTooltip}
        showInfo={false}
        seriesStateProvider={seriesState}
      />
    </GraphCard>
  );
});

export interface SpreadChartWidgetProps extends SpreadChartProps {}

export const SpreadChartWidget = (props: SpreadChartWidgetProps) => (
  <SpreadContext.Provider>
    <SpreadChart {...props} />
  </SpreadContext.Provider>
);
