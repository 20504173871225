import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Loader } from "src/components/shared/Loader";
import { Headline2 } from "src/components/shared/Typography";
import { PartyContext } from "src/context/UserManager/UserManager";
import { stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import { Account } from "src/modules/userManager";
import WindowConsent from "src/state/WindowConsent";
import { AddLabel } from "../AddLabel";
import { EditName } from "../EditName";
import { InfoRows } from "../InfoRows";
import * as styles from "./style";

interface EditAccProps {
  show: boolean;
  hide: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditAcc = observer(({ show, hide }: EditAccProps) => {
  const partyState = useLateInitContext(PartyContext);

  const [showAddLabel, setShowAddLabel] = useState(false);

  const metaDataRemove = (acc: Account, label: string) => {
    if (partyState)
      WindowConsent.showWindow(
        "",
        `Are you sure you want to remove the ${label} from the Account: ${acc.name}`,
        partyState?.removeMetaDataAcc,
        { acc, label }
      );
  };

  const onArchiveAccount = useCallback(() => {
    if (!partyState) return;
    const account = partyState.currentEditAcc;
    WindowConsent.showWindow(
      "",
      `Are you sure you want to archive Account: ${account.name} from Exchange: ${account.exchange}?`,
      partyState.archiveAccount
    );
  }, [partyState]);

  const createdAt = partyState.currentEditAcc.credentials[0].info.created_at;
  const updatedAt = partyState.currentEditAcc.credentials[0].info.updated_at;

  return (
    <styles.EditAccModal label="Edit account" show={show} close={hide} loading={false}>
      <styles.Container>
        <Headline2>MetaData</Headline2>

        <styles.Content>
          <EditName
            name={partyState.currentEditAcc.name}
            updNameRequest={partyState?.editName}
            type="ACCOUNT"
          />

          {partyState.currentAccMetaKeys.map((el) => (
            <styles.Wrapper key={el}>
              <LabeledInput
                label={el}
                value={partyState.currentEditAcc.meta ? partyState.currentEditAcc.meta[el] : ""}
                onChange={() => {}}
                readOnly
              />

              <DeleteButton onClick={() => metaDataRemove(partyState.currentEditAcc, el)} />
            </styles.Wrapper>
          ))}
        </styles.Content>

        <InfoRows
          style={{ marginBottom: "20px" }}
          created={createdAt ? unixToDateFormat(stringDateToUnix(createdAt), "FullDate") : ""}
          updated={updatedAt ? unixToDateFormat(stringDateToUnix(updatedAt), "FullDate") : ""}
          uuid={partyState.currentEditAcc.uuid || ""}
        />

        <styles.BtnWrapper>
          <OutlineButton stretched startIcon={Icons.archive()} onClick={onArchiveAccount}>
            Move to archive
          </OutlineButton>
          <OutlineButton stretched onClick={() => setShowAddLabel(true)}>
            Add label +
          </OutlineButton>
        </styles.BtnWrapper>
      </styles.Container>
      <AddLabel show={showAddLabel} hide={setShowAddLabel} type="ACCOUNT" />
      <Loader show={partyState.isLoadingAcc} />
    </styles.EditAccModal>
  );
});
