import { Controller, useFormContext } from "react-hook-form";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ERRORS_MSGS, isTimeFrame } from "src/validation-schemas";
import { ModuleSoftParamsShell } from "../../../../shared/ModuleSoftParamsShell";
import { SoftParamsFormsProps } from "../../../../shared/ModuleSoftParamsShell/types";
import { InputWrapper } from "../../../../shared/style";
import { CompareSelector } from "../shared/CompareSelector";

interface ModuleWithTimeframeProps extends SoftParamsFormsProps {}

export const ModuleWithTimeframe = ({ index, ...props }: ModuleWithTimeframeProps) => {
  const { control } = useFormContext<IStrategyCreator>();

  return (
    <ModuleSoftParamsShell {...props}>
      <InputWrapper>
        <CompareSelector control={control} name={`conditions.${index}.soft_params.compare`} />

        <Controller
          control={control}
          name={`conditions.${index}.soft_params.value`}
          rules={{
            required: ERRORS_MSGS.isRequired,
            validate: isTimeFrame(),
          }}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            const error = getFieldError(errors, `conditions.${index}.soft_params.value`);

            return (
              <LabeledInput
                label="Time"
                type="text"
                value={value}
                onChange={(e) => onChange(getChangeEventValue(e))}
                errorHint={error?.message}
              />
            );
          }}
        />
      </InputWrapper>
    </ModuleSoftParamsShell>
  );
};
