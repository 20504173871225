import { makeAutoObservable, runInAction } from "mobx";
import { logError } from "src/helpers/network/logger";
import WindowConsent from "./WindowConsent";

export interface TableOrders {
  items: any[];
  cancelRequest: (selectItems: string[]) => Promise<any>;
  cancelManyRequest: (selectItems: string[]) => Promise<any>;
  cancelAllRequest: (selectItems: string[]) => Promise<any>;
}

export interface TableStoreConfig {
  showCancelOrderConsent?: boolean;
}

class TableStore {
  private _allChecked: boolean = false;

  selectItems: string[] = [];

  loader: boolean = false;

  filters: string[] = [];

  selectFilters: string[] = [];

  private _filterParam = "";

  parentState: TableOrders;

  private _showCancelOrderConsent = false;

  constructor(state: TableOrders, config: TableStoreConfig = {}) {
    this.parentState = state;

    makeAutoObservable(this);

    this._initConfig(config);
  }

  private _initConfig = ({ showCancelOrderConsent }: TableStoreConfig) => {
    this._showCancelOrderConsent = showCancelOrderConsent ?? false;
  };

  setAllChecked = (b: boolean) => {
    this._allChecked = b;
  };

  setAllFilters = (arr: string[], filterParam: string, defaultFilter: string = "all") => {
    this.filters = arr;

    this._filterParam = filterParam;

    if (defaultFilter === "all") {
      this.selectFilters = this.filters;
    } else {
      const checkDefaultFilter = this.selectFilters.includes(defaultFilter);

      if (!checkDefaultFilter) this.selectFilters.push(defaultFilter);
    }
  };

  setFilters = (value: string) => {
    if (this.selectFilters.includes(value)) {
      this.removeFilter(value);
    } else {
      this.addFilter(value);
    }

    this.selectItems = [];
  };

  selectAllFilters = () => {
    if (this.selectFilters.length !== this.filters.length) {
      this.selectFilters = this.filters;

      this.selectItems = [];
    } else {
      this.selectFilters = [];
    }
  };

  get allChecked() {
    if (!this.tableItems.length) return false;
    if (!this.selectItems.length) {
      runInAction(() => this.setAllChecked(false));

      return false;
    }

    for (const el of this.tableItems) {
      // crutch to avoid selecting inactive stop orders
      if (el.status === "new" || el.status === "open") {
        if (!this.selectItems.includes(String(el.id))) {
          runInAction(() => this.setAllChecked(false));

          return false;
        }
      }
    }

    runInAction(() => this.setAllChecked(true));

    return true;
  }

  get cancelActive() {
    return Boolean(this.selectItems.length);
  }

  get tableItems(): any {
    if (this.checkAllFilters) return this.parentState.items;

    return this.parentState.items.filter((el) =>
      this.selectFilters.includes(el[this._filterParam])
    );
  }

  get checkAllFilters() {
    return this.filters.length === this.selectFilters.length;
  }

  addId = (id: string) => {
    this.selectItems = [...this.selectItems, id];
  };

  removeId = (id: string) => {
    this.selectItems = this.selectItems.filter((item) => item !== id);
  };

  addFilter = (value: string) => {
    this.selectFilters = [...this.selectFilters, value];
  };

  removeFilter = (value: string) => {
    this.selectFilters = this.selectFilters.filter((item) => item !== value);
  };

  allCheck = () => {
    this._allChecked = !this._allChecked;

    this.selectItems = [];

    if (this._allChecked)
      for (const el of this.tableItems) {
        if (el.status) {
          // crutch to avoid selecting inactive stop orders
          if (el.status === "new" || el.status === "open") {
            if (el.id) this.addId(String(el.id));
          }
        } else if (el.id) this.addId(String(el.id));
      }
  };

  check = (id: string) => {
    if (this.selectItems.includes(id)) {
      this.removeId(id);

      // this._allChecked = false;
    } else {
      this.addId(id);
    }
  };

  cancelOrders = async () => {
    if (!this.selectItems.length) return;

    if (this.selectItems.length >= 2) {
      WindowConsent.showWindow(
        "",
        `Are you sure you want to cancel ${
          this.parentState.items.length === this.selectItems.length
            ? "all"
            : this.selectItems.length
        } orders?`,
        this.cancel(this.selectItems)
      );
    } else await this.cancelOrder(this.selectItems);
  };

  private _cancelOrder = async (items: string[]) => {
    try {
      const isError = await this.parentState.cancelRequest(items);

      if (!isError) this._removedSelectItems(items);
    } catch (err) {
      logError(err);
    }
  };

  cancelOrder = async (items: string[]) => {
    if (this._showCancelOrderConsent) {
      WindowConsent.showWindow(
        "",
        "Are you sure you want to cancel the order?",
        this._cancelOrder,
        items
      );
    } else {
      await this._cancelOrder(items);
    }
  };

  cancel = (items: string[]) => async () => {
    try {
      let isError = true;

      if (this.parentState.items.length === this.selectItems.length) {
        isError = await this.parentState.cancelAllRequest(items);
      } else if (this.selectItems.length >= 2) {
        isError = await this.parentState.cancelManyRequest(items);
      } else {
        isError = await this.parentState.cancelRequest(items);
      }

      if (!isError) this._removedSelectItems(items);
    } catch (err) {
      logError(err);
    }
  };

  resetSelectItems = () => {
    this._allChecked = false;

    this.selectItems = [];
  };

  private _removedSelectItems = (items: string[]) => {
    items.forEach((id) => {
      this.removeId(id);
    });
  };
}

export default TableStore;
