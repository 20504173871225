import { IDisposable } from "src/helpers/utils";
import { UserManagerStore } from "..";
import AddUserGroupStore from "./AddUserGroupStore";
import EditUserGroupStore from "./EditUserGroupStore";

export type ListUpdate = () => void;
export interface UserGroupsUpdate {
  getUserGroupsList: ListUpdate;
}

export default class UserGroupsStore implements IDisposable {
  private _userManagerState: UserManagerStore;

  userGroupsState;

  constructor(userManagerState: UserManagerStore) {
    this._userManagerState = userManagerState;
    this.userGroupsState = {
      addUserGroupState: new AddUserGroupStore(this._userManagerState),
      editUserGroupState: new EditUserGroupStore(this._userManagerState),
    };
  }

  destroy() {
    for (const store of Object.values(this.userGroupsState)) {
      store.destroy();
    }
  }
}
