import styled from "styled-components";
import { Headline3, Headline4 } from "src/components/shared/Typography";
import { CardContainer as BaseCardContainer } from "src/components/BotsContent/CEX/shared/Card/style";

export const CardContainer = styled(BaseCardContainer)`
  padding: 12px 16px;

  flex-direction: column;
  gap: 23px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const TitleIconWrapper = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.dashboard.textSecondary};
`;

export const Title = styled(Headline3)`
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["11px"]};
  color: ${({ theme }) => theme.dashboard.textSecondary};
`;

export const KPIText = styled(Headline4)`
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.dashboard.textHeading};
`;
