import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  calculateStep,
  chooseStep,
  secondsToFormattedPeriod,
  unixToDateFormat,
} from "src/helpers/dateUtils";
import { TimePoint } from "src/modules/shared";
import { PointTooltip } from "./PointTooltip";
import { TimeScale } from "./TimeScale";
import * as styles from "./style";

interface Props {
  period: number;
  points: TimePoint[];
  additionalInfo?: JSX.Element;
  endTime: number;
  style?: React.CSSProperties | undefined;
  labelNested?: string;
  intervalStep?: number;
}

export const TimeLine = ({
  period,
  points,
  additionalInfo,
  endTime,
  labelNested,
  intervalStep,
  ...props
}: Props) => {
  const scale = useRef<HTMLDivElement>(null);

  // const endTime = getCurrentUnixTime();

  const startTime = endTime - period;

  const [scaleWidth, setScaleWidth] = useState(0);

  const [mergePoints, setMergePoints] = useState(points);

  const updateWidth = useCallback(() => {
    if (scale && scale.current) {
      const { clientWidth } = scale.current;
      setScaleWidth(clientWidth);
    }
  }, [scale]);

  const getCoordinate = (el: TimePoint) => {
    if (points.length) {
      const coord = calculateStep(el.time, startTime, endTime);

      return String((+scaleWidth * +coord) / 100);
    }
    return "0";
  };

  const getWidthPoint = (el: TimePoint) => {
    const widthPoint = period * 0.012;

    if (points.length) {
      const firstCoord = calculateStep(el.time, startTime, endTime);

      const endCoord = calculateStep(el.time + widthPoint, startTime, endTime);

      const width = +endCoord - +firstCoord;

      return String((+scaleWidth * width) / 100);
    }
    return "0";
  };

  const getTimeIntervals = useCallback(() => {
    if (!scaleWidth) return [];

    const step = intervalStep || chooseStep(period);
    const interval = period / step;
    const widthInterval = (scaleWidth / interval).toFixed(2);

    let timeCounter = startTime;

    const intervals = [];

    for (let i = 0; i < interval; i += 1) {
      intervals.push({
        widthInterval,
        timeInterval: unixToDateFormat(timeCounter, "ShortTime"),
      });

      timeCounter += step;
    }

    return intervals;
  }, [period, scaleWidth, startTime, intervalStep]);

  const unionPoints = useCallback(
    (points: TimePoint[]) => {
      const mergedPoints: TimePoint[] = [];
      points.sort((a, b) => a.time - b.time);

      for (let i = 0; i < points.length; i += 1) {
        let deltaTime = 0;
        const periodFactor = 0.05;

        if (mergedPoints.length) {
          const lastIndx = mergedPoints.length - 1;
          const lastMergePoint = mergedPoints[lastIndx];

          if (lastMergePoint.nested) {
            const lastNestEl = lastMergePoint.nested![lastMergePoint.nested!.length - 1];

            deltaTime = Math.abs(lastNestEl.time - points[i].time);

            if (deltaTime < period * periodFactor) {
              lastMergePoint.nested?.push(points[i]);
            } else {
              mergedPoints.push(points[i]);
            }
          } else {
            deltaTime = Math.abs(lastMergePoint.time - points[i].time);

            if (deltaTime < period * periodFactor) {
              lastMergePoint.nested = [];

              lastMergePoint.nested?.push(points[i]);
            } else mergedPoints.push(points[i]);
          }
        } else mergedPoints.push(points[i]);
      }

      setMergePoints(mergedPoints);
    },
    [period]
  );

  useEffect(() => {
    unionPoints(points);
  }, [points, unionPoints]);

  useEffect(() => {
    updateWidth();

    const resize = () => {
      updateWidth();
    };

    resize();

    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, [period, startTime, updateWidth, scaleWidth]);

  return (
    <styles.Container {...props}>
      <styles.InfoPanel>
        <styles.Wrapper>
          <styles.TimePeriod>{secondsToFormattedPeriod(period)} ago</styles.TimePeriod>

          <styles.CountPoint> / {points.length}</styles.CountPoint>
        </styles.Wrapper>

        {additionalInfo}
      </styles.InfoPanel>

      <styles.Line ref={scale}>
        {mergePoints.map((el, index) => (
          <styles.Point
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              left: `${getCoordinate(el)}px`,
              width: el.nested?.length ? `${+getWidthPoint(el) * 2}px` : `${getWidthPoint(el)}px`,
              height: el.nested?.length ? `${+getWidthPoint(el) * 2}px` : `${getWidthPoint(el)}px`,
              top: el.nested?.length ? "-2.5px" : "-0.5px",
            }}
            data-tooltip-id={String(el.id)}
            data-iscapture="true"
            data-delay-hide="1000"
          >
            {" "}
            <PointTooltip point={el} labelNested={labelNested} />
          </styles.Point>
        ))}
      </styles.Line>

      <TimeScale timeIntervals={getTimeIntervals()} />
    </styles.Container>
  );
};
