import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";

interface RangeParams {
  from: string;
  to: string;
}

export const getRangeQueryParams = (range: DateTimeRange | null): RangeParams | null => {
  if (!range) return null;

  const [start, end] = range;

  if (!start || !end) return null;

  return { from: `${start.unix()}`, to: `${end.unix()}` };
};
