import { PropertyKeyKeys, PropertyKeyValues } from "src/modules/shared";
import { NestedObjectLeaves } from "../forms/types/NestedObject";

export interface GenericSelectorValue<T, K extends PropertyKeyKeys<T>> {
  label: T[K];
  value: K;
}

export const objectToArrayOptions = <T, K extends PropertyKeyKeys<T>>(
  obj: PropertyKeyValues<T>
): GenericSelectorValue<T, K>[] => {
  const result: GenericSelectorValue<T, K>[] = [];

  const keys = Object.keys(obj) as K[];

  keys.forEach((key) => {
    const value = obj[key];
    result.push({ label: value, value: key });
  });

  return result;
};

export const isEmptyObject = (obj: any) => Object.keys(obj).length === 0;

type ValueObject = { [key: string]: any };

export const getPathsFromObject = <T>(
  obj: ValueObject,
  parentKey: string = ""
): NestedObjectLeaves<T>[] => {
  let paths: string[] = [];

  const keys = Object.keys(obj) as string[];

  keys.forEach((key) => {
    if (typeof key !== "symbol") {
      const hasProperty = Object.prototype.hasOwnProperty.call(obj, key);

      if (hasProperty) {
        const currentPath = parentKey ? `${parentKey}.${key}` : key;

        if (typeof obj[key] === "object" && obj[key] !== null) {
          paths = paths.concat(getPathsFromObject(obj[key], currentPath));
        } else {
          paths.push(currentPath);
        }
      }
    }
  });

  return paths as NestedObjectLeaves<T>[];
};
