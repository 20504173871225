import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useCallback } from "react";
import { StrategiesModal } from "src/components/BotsContent/DEX_V2/Settings/Counter/CounterPanel/StrategiesModal";
import { StrategiesTable } from "src/components/BotsContent/DEX_V2/Settings/Counter/CounterPanel/StrategiesTable";
import { OutlineButton } from "src/components/shared/Buttons/v2/variants/OutlineButton";
import { CheckBox } from "src/components/shared/CheckBox";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useModalState } from "src/hooks/useModalState";
import { FormCol, FormRow, SettingsPanel } from "../../shared";
import * as styles from "./style";

export interface CounterSettingsPanelProps extends ComponentPropsWithoutRef<"div"> {}

export const CounterSettingsPanel = observer((props: CounterSettingsPanelProps) => {
  const state = useLateInitContext(DEXV2CreatingContext);

  const {
    isShown: isStrategiesModalShown,
    show: showStrategiesModal,
    close: closeStrategiesModal,
  } = useModalState();

  const onSelectStrategy = useCallback(
    (index: number | null) => {
      state.setSelectedCounterStrategy(index);
      showStrategiesModal();
    },
    [showStrategiesModal, state]
  );

  return (
    <SettingsPanel {...props}>
      <styles.StyledFormContent>
        <FormCol>
          <styles.StrategiesHeaderRow>
            <CheckBox
              label="Cumulative"
              checked={state.data.counter_data.cumulative}
              onChange={state.getHandler("counter_data.cumulative")}
            />
            <OutlineButton size="s" onClick={() => onSelectStrategy(null)}>
              Add
            </OutlineButton>
          </styles.StrategiesHeaderRow>
          <StrategiesTable
            strategies={state.counterStrategies}
            onDeleteStrategy={state.deleteCounterStrategy}
            onEditStrategy={onSelectStrategy}
            onToggleActiveStrategy={state.toggleCounterStrategyStatus}
            error={state.counterStrategiesError}
          />
        </FormCol>
      </styles.StyledFormContent>
      <StrategiesModal
        show={isStrategiesModalShown}
        onClose={closeStrategiesModal}
        initialData={state.selectedCounterStrategy}
        onSave={state.updateCounterStrategy}
      />
    </SettingsPanel>
  );
});
