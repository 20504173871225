import { makeAutoObservable, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
import { addManualFunding, getAssets } from "src/api/shared/funding";
import { getUTCDateTimeInputValue } from "src/helpers/dateUtils";
import { getPathAndKey, getTargetValueByPath } from "src/helpers/forms/getByKey";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { getSelectorList } from "src/helpers/forms/selectors";
import {
  FormDataKeys,
  FormErrors,
  FormFieldHandler,
  FormHandlers,
  FormValidation,
} from "src/helpers/forms/types";
import { showSuccessMsg } from "src/helpers/message";
import { SelectorValue } from "src/modules/shared";
import { graterThan, required, validateData } from "src/validation-schemas";
import { NewFormManualFunding, NewManualFunding } from "./types";
import { FundingStore } from ".";

type FundingKeys = FormDataKeys<NewManualFunding>;

const selectorFields = ["exchange", "account_uuid", "type", "currency"] as const;

type FundingSelectorFields = (typeof selectorFields)[number];

const EMPTY_FUNDING: NewFormManualFunding = {
  exchange: "",
  account_uuid: "",
  amount: "",
  currency: "",
  time: "",
  type: "deposit",
  comment: "",
};

export class FundingFormStore {
  newFunding: NewFormManualFunding = EMPTY_FUNDING;

  private _currency: string[] = [];

  private _isLoading = false;

  mainState: FundingStore;

  handlers: FormHandlers<NewManualFunding> = {};

  private _validation: FormValidation<NewManualFunding> = {
    account_uuid: required(),
    exchange: required(),
    type: required(),
    time: required(),
    currency: required(),
    amount: [required(), graterThan(0, "The value must be positive")],
  };

  errors: FormErrors<NewManualFunding> = {};

  constructor(state: FundingStore) {
    this.mainState = state;

    // set cb for to record the transfer in the funding form
    this.mainState.setFundingFormCb(this.setFunding);

    makeAutoObservable(this);
  }

  get party() {
    return this.mainState.party;
  }

  get currencyList() {
    return getSelectorList(this._currency);
  }

  get exchangeSelected() {
    return Boolean(this.newFunding.exchange);
  }

  get accounts() {
    return this.mainState.accountState.accounts;
  }

  get exchanges() {
    return this.mainState.accountState.exchOptions;
  }

  get accountList() {
    const exchAccounts = this.accounts[this.newFunding.exchange];

    if (exchAccounts)
      return exchAccounts.map(({ name, uuid }) => ({
        value: uuid,
        label: name,
      }));

    return [];
  }

  get currentAccount() {
    return this.mainState.accountState.getSelectAccount(
      this.newFunding.exchange,
      this.newFunding.account_uuid
    );
  }

  get selectedTime() {
    if (this.newFunding.time) return getUTCDateTimeInputValue(this.newFunding.time);

    return "";
  }

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(loading: boolean) {
    this._isLoading = loading;
  }

  setFunding = (funding: NewFormManualFunding) => {
    this.clearForm();

    this.newFunding = funding;
  };

  updFundingList = () => {
    this.mainState.updAllData();
  };

  private _stringToSelectorValue = (str: string) => ({ value: str, label: str });

  selectorValue = computedFn((field: FundingSelectorFields) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.newFunding, path);

    const currentValue = targetData[endKey];

    if (!currentValue) {
      return null;
    }
    return this._stringToSelectorValue(currentValue);
  });

  setCurrency = (value: string) => {
    this._currency = [value, ...this._currency];
  };

  private _updateSelector = (field: FundingSelectorFields, value: string) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.newFunding, path);

    targetData[endKey] = value;
  };

  private _resetAccount = () => {
    this._updateSelector("account_uuid", "");
  };

  onFundingSelectorChange = (field: FundingSelectorFields) => (newValue: SelectorValue | null) => {
    if (!newValue) return;
    this._updateSelector(field, String(newValue.value));
    if (field === "exchange") this._resetAccount();
  };

  getFundingSelector = (field: FundingSelectorFields) => {
    const [path, endKey] = getPathAndKey(field);

    const targetData = getTargetValueByPath(this.newFunding, path);

    return targetData[endKey];
  };

  getCurrencies = async () => {
    try {
      const { isError, data } = await getAssets();
      if (!isError) {
        runInAction(() => {
          this._currency = data.tokens;
        });
      }
    } catch {
      this._currency = [];
    }
  };

  getHandler = (key: FundingKeys): FormFieldHandler => {
    if (!this.handlers[key]) {
      const [path, endKey] = getPathAndKey(key);

      const targetData = getTargetValueByPath(this.newFunding, path);

      this.handlers[key] = (e: React.ChangeEvent<HTMLInputElement>) => {
        targetData[endKey] = getChangeEventValue(e);
      };
    }

    return this.handlers[key]!;
  };

  validate = (validateKeys?: string[]) =>
    validateData(this._validation, this.newFunding, this.errors, validateKeys);

  submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    const valid = this.validate();

    if (valid) {
      this.isLoading = true;

      try {
        const preparedNewFunding = { ...this.newFunding, party: this.party };
        const { isError } = await addManualFunding(preparedNewFunding);

        if (!isError) {
          showSuccessMsg("Funding successfully completed");
          this.updFundingList();
          this.clearForm();
        }
      } finally {
        this.isLoading = false;
      }
    }
  };

  clearForm = () => {
    this.newFunding = EMPTY_FUNDING;
    this.handlers = {};
    this.errors = {};
  };

  destroy = () => {};
}
