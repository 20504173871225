import { LabeledInput } from "src/components/shared/Forms/Inputs";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  grid-area: module;
`;

export const ReadOnlyInput = styled(LabeledInput)`
  font-size: 0.7em;
`;
