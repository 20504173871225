import { observer } from "mobx-react-lite";
import { darken } from "polished";
import { useMemo } from "react";
import { VolumeShareContext } from "src/context/CEX/Dashboard/v2/VolumeShare";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { VolumeData } from "src/state/CEX/CEXDashboard/v1/VolumeStore";
import { Card, CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { useDashboardColors } from "../../../shared/hooks/useDashboardColors";
import { DashboardV2Widget, getWidgetInfo, getWidgetTitle } from "../shared/constants";
import { VolumeShareBar, VolumeShareBarProps } from "./VolumeShareBar";

interface UseVolumeDataParams {
  data: VolumeData;
}

export const useVolumeData = ({ data: { exchanges, our, notOur, total } }: UseVolumeDataParams) => {
  const {
    accent: { coralPink, dodgerBlue },
  } = useDashboardColors();

  const barData = useMemo(
    (): VolumeShareBarProps["data"] => ({
      labels: exchanges,
      datasets: [
        {
          label: "Gotbit volume",
          data: our,
          borderWidth: 0,
          backgroundColor: dodgerBlue,
          hoverBackgroundColor: darken(0.15, dodgerBlue),
        },
        {
          label: "Organic volume",
          data: notOur,
          borderWidth: 0,
          backgroundColor: coralPink,
          hoverBackgroundColor: darken(0.15, coralPink),
        },
        { label: "Total", data: total, hidden: true },
      ],
    }),
    [coralPink, dodgerBlue, exchanges, notOur, our, total]
  );

  return barData;
};

export interface VolumeShareProps extends CardProps {}

export const VolumeShare = observer((props: VolumeShareProps) => {
  const { data, loading } = useLateInitContext(VolumeShareContext.Context);

  const volumeData = useVolumeData({ data });

  const barProps = { data: volumeData };

  return (
    <Card
      title={getWidgetTitle(DashboardV2Widget.VolumeShare)}
      info={getWidgetInfo(DashboardV2Widget.VolumeShare)}
      loading={loading}
      {...props}
    >
      <VolumeShareBar {...barProps} />
    </Card>
  );
});

export interface VolumeShareWidgetProps extends VolumeShareProps {}

export const VolumeShareWidget = (props: VolumeShareWidgetProps) => (
  <VolumeShareContext.Provider>
    <VolumeShare {...props} />
  </VolumeShareContext.Provider>
);
