import { deepmerge } from "@mui/utils";
import { useMemo } from "react";
import { GraphRootProps } from "..";
import { OptionalChartOptions } from "../types";

interface UseDefaultChartOptionsParams
  extends Pick<GraphRootProps, "allowTimeScale" | "startOptions"> {}

export const useDefaultChartOptions = ({
  allowTimeScale,
  startOptions,
}: UseDefaultChartOptionsParams) => {
  const defaultOptions = useMemo(
    (): OptionalChartOptions => ({
      localization: {
        locale: "en-US",
        dateFormat: "yyyy/MM/dd",
      },
      timeScale: {
        timeVisible: true,
        minBarSpacing: 0.001,
        fixRightEdge: true,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: true,
      },
      rightPriceScale: {
        autoScale: true,
        ticksVisible: true,
        alignLabels: true,
        visible: false,
      },
      leftPriceScale: {
        autoScale: true,
        ticksVisible: true,
        alignLabels: true,
        visible: false,
      },
      handleScale: {
        axisPressedMouseMove: {
          price: false,
          time: !allowTimeScale,
        },
      },
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: !allowTimeScale,
      },
    }),
    [allowTimeScale]
  );

  const options = useMemo(
    () => deepmerge(defaultOptions, startOptions),
    [defaultOptions, startOptions]
  );

  return options;
};
