import { Chart as ChartComponent } from "react-chartjs-2";
import { LineChartPoint } from "src/modules/dashboard";
import { DepthData } from "src/state/CEX/CEXDashboard/v2/widgets/DepthV2Store";
import { ChartProps } from "../../../../../shared/types";
import { AllDepthPlugin, AllDepthTextPosition } from "../../plugin";
import { useBaseOptions, useDepthData } from "./hooks";

export type DepthChartLineProps = ChartProps<"line", LineChartPoint[] | number[], string>;

export interface DepthChartTicksOptions {
  position?: AllDepthTextPosition;
  padding?: number;
  level?: {
    show?: boolean;
    offset?: number;
  };
  offset?: number;
}

export interface DepthChartProps extends Omit<DepthChartLineProps, "type" | "data"> {
  data: DepthData;
  ticks?: DepthChartTicksOptions;
  gap?: number;
  levelWidth?: number;
}

export const DepthChart = ({
  data,
  ticks,
  gap = 5,
  levelWidth = 10,
  ...props
}: DepthChartProps) => {
  const { data: depthData, minX, maxX } = useDepthData({ data, gap, levelWidth });

  const options = useBaseOptions({ ticks, minX, maxX });

  return (
    <ChartComponent
      type="line"
      options={options}
      data={depthData}
      plugins={[AllDepthPlugin]}
      {...props}
    />
  );
};
