import { ChartArea } from "chart.js";
import { rgba } from "polished";
import { useMemo } from "react";
import { useDashboardColors } from "src/components/BotsContent/CEX/Dashboard/shared/hooks/useDashboardColors";
import { roundToTwoDecimals } from "src/helpers/rounding";
import { LineChartPoint } from "src/modules/dashboard";
import { DepthLevelData } from "src/state/CEX/CEXDashboard/v2/widgets/DepthV2Store";
import { DepthChartLineProps, DepthChartProps } from ".";

const useDepthColors = () => {
  const {
    accent: { malachite, rubyRed },
    textSecondary,
  } = useDashboardColors();

  return {
    buy: malachite,
    sell: rubyRed,
    level: textSecondary,
  };
};

interface UseBaseOptionsParams extends Pick<DepthChartProps, "ticks"> {
  minX?: number;
  maxX?: number;
}

export const useBaseOptions = ({ ticks = {}, minX, maxX }: UseBaseOptionsParams) => {
  const {
    padding: ticksPadding = 30,
    position: ticksPosition = "bottom",
    level,
    offset: ticksOffset,
  } = ticks;

  const showLevel = level?.show ?? true;
  const levelOffset = level?.offset;

  const { buy: buyColor, sell: sellColor, level: levelColor } = useDepthColors();

  const options = useMemo((): DepthChartLineProps["options"] => {
    const padding: Partial<ChartArea> = {
      top: ticksPosition === "top" ? ticksPadding : 8,
      left: 8,
      right: 8,
      bottom: ticksPosition === "bottom" ? ticksPadding : 8,
    };

    return {
      maintainAspectRatio: false,
      layout: { padding },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        allDepth: {
          price: {
            buyColor,
            sellColor,
          },
          level: {
            color: levelColor,
            display: showLevel,
            offset: levelOffset,
          },
          position: ticksPosition,
          offset: ticksOffset,
        },
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          type: "linear",
          position: "bottom",
          min: minX,
          max: maxX,
        },
        y: {
          display: false,
        },
      },
    };
  }, [
    buyColor,
    levelColor,
    levelOffset,
    maxX,
    minX,
    sellColor,
    showLevel,
    ticksOffset,
    ticksPadding,
    ticksPosition,
  ]);

  return options;
};

const mapDepthLevelDataToPoints = ({ data }: DepthLevelData, start: number, width: number) => {
  const timeRange = data[data.length - 1].time - data[0].time;
  const widthPerTime = width / timeRange;
  const points: LineChartPoint[] = [];
  let x = start;

  for (let i = 0; i < data.length; i += 1) {
    const { time, value } = data[i];
    const timeGap = i > 0 ? time - data[i - 1].time : 0;
    x += timeGap * widthPerTime;
    const roundedX = roundToTwoDecimals(x);
    points.push({ x: roundedX, y: value });
  }

  return points;
};

interface UseDepthDataParams
  extends Required<Pick<DepthChartProps, "data" | "gap" | "levelWidth">> {}

interface UseDepthDataResponse {
  data: DepthChartLineProps["data"];
  minX: number;
  maxX: number;
}

export const useDepthData = ({
  data,
  gap,
  levelWidth,
}: UseDepthDataParams): UseDepthDataResponse => {
  const { buy: buyColor, sell: sellColor } = useDepthColors();

  const depthData = useMemo((): UseDepthDataResponse => {
    const { buy, sell } = data;

    const datasets: DepthChartLineProps["data"]["datasets"] = [];
    const levels = [...Object.keys(sell), ...Object.keys(buy)];
    const levelsData = [...Object.values(sell), ...Object.values(buy)];
    const levelsCount = levelsData.length;
    let previousPoints: LineChartPoint[] = [];

    const getPreviousX = () => {
      const previous = previousPoints[previousPoints.length - 1];
      return previous ? previous.x : 0;
    };

    for (let i = 0; i < levelsCount; i += 1) {
      const levelData = levelsData[i];
      const previousX = getPreviousX();
      const startX = i > 0 ? previousX + gap : 0;
      const points = mapDepthLevelDataToPoints(levelData, startX, levelWidth);
      previousPoints = points;

      const level = levels[i];

      const color = +level > 0 ? buyColor : sellColor;
      const backgroundColor = rgba(color, 0.2);

      datasets.push({
        label: level,
        data: points,
        borderColor: color,
        backgroundColor,
        fill: true,
        pointRadius: 0,
        borderWidth: 1,
      });
    }

    datasets.push({
      label: "Price",
      type: "line",
      data: levelsData.map(({ price }) => price),
      hidden: true,
    });

    const minX = 0;
    const maxX = getPreviousX();

    return {
      data: {
        datasets,
        labels: levels,
      },
      minX,
      maxX,
    };
  }, [buyColor, data, gap, levelWidth, sellColor]);

  return depthData;
};
