import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";
import { DEXV2SettingsModuleNames } from "src/state/DEXV2/types";
import {
  SaveSplitButton,
  SaveSplitButtonOwnProps,
  SaveSplitButtonProps,
} from "../../shared/SaveSplitButton";

export interface SaveModuleButtonProps
  extends Omit<SaveSplitButtonProps, keyof SaveSplitButtonOwnProps> {
  module: DEXV2SettingsModuleNames;
}

export const SaveModuleButton = observer(({ module, ...props }: SaveModuleButtonProps) => {
  const { hasAbility } = useLateInitContext(AbilitiesContext);
  const enableSave = hasAbility(DEXV2Ability.SettingsEdit);

  const state = useLateInitContext(DEXV2SettingsContext);
  const isSaved = state.moduleSavedStatus(module);
  const onForceSave = state.submitForceModuleHandler(module);

  const menuItemsProps = useMemo(
    () => ({
      items: [{ label: "Force save", onClick: onForceSave }],
    }),
    [onForceSave]
  );

  return (
    <>{enableSave && <SaveSplitButton menuProps={menuItemsProps} saved={isSaved} {...props} />}</>
  );
});
