import {
  ColumnFiltersState,
  Row,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import { useVirtual } from "react-virtual";
import { Loader } from "src/components/shared/Loader";
import { ColumnFilter } from "../ColumnFilter";
import * as styles from "./style";

interface StateTableRowsProps extends React.ComponentPropsWithoutRef<"div"> {
  rowSize: number;
  columns: any;
  orders: any;
  loader: boolean;
  filters?: string[];
}

export const StateTableRows = observer(
  ({ orders, rowSize, columns, loader, filters = [], ...props }: StateTableRowsProps) => {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const table = useReactTable({
      data: orders,
      columns,
      state: {
        columnFilters,
      },
      onColumnFiltersChange: setColumnFilters,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      debugTable: false,
    });

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const { rows } = table.getRowModel();
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
      estimateSize: useCallback(() => rowSize, [rowSize]),
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    useEffect(() => {
      const body = tableBodyRef.current;

      const resetSelect = () => {
        setSelectColumn(null);
      };

      body?.addEventListener("mouseleave", resetSelect);

      return () => {
        body?.removeEventListener("mouseleave", resetSelect);
      };
    });

    const [selectColumn, setSelectColumn] = useState<null | number>(null);

    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;

    const PaddingTop = () =>
      paddingTop > 0 ? (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      ) : null;

    const paddingBottom =
      virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    const PaddingBottom = () =>
      paddingBottom > 0 ? (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      ) : null;

    return (
      <styles.TableWrapper ref={tableContainerRef} {...props}>
        <styles.StateTable>
          <styles.Head>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <styles.HeadRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <styles.HeadCell
                    key={header.id}
                    colSpan={header.colSpan}
                    column={header.column}
                    style={{
                      width: header.getSize(),
                    }}
                    {...{
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanFilter() ? (
                      <ColumnFilter
                        column={header.column}
                        filters={filters}
                        defaultFilter={filters[0]}
                      />
                    ) : null}
                  </styles.HeadCell>
                ))}
              </styles.HeadRow>
            ))}
          </styles.Head>
          <styles.StateTableBody ref={tableBodyRef} columnIndex={selectColumn}>
            <PaddingTop />

            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<any>;

              return (
                <styles.Row key={row.id}>
                  {row.getVisibleCells().map((cell, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <styles.StateTableCell
                      {...{
                        key: cell.id,
                        style: {
                          width: cell.column.getSize(),
                        },
                      }}
                      onMouseMove={() => {
                        setSelectColumn(index);
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </styles.StateTableCell>
                  ))}
                </styles.Row>
              );
            })}

            <PaddingBottom />
          </styles.StateTableBody>
        </styles.StateTable>

        <Loader show={loader} />
      </styles.TableWrapper>
    );
  }
);
