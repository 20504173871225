import { ApiModules, apiUrl } from "src/api";
import { DeepOmit } from "src/helpers/utils";
import { DEXV2CreatingBot } from "src/modules/bots";
import ResponseHandler from "src/state/network/ResponseHandler";

export interface RegisterBotResponse {
  deployer_id: string;
  address: string;
  price: number;
}

export type RegisterBotRequest = DeepOmit<DEXV2CreatingBot, "withdrawer"> & {
  base_data: {
    withdrawers: string[];
    swap_oracle_wallets: string[];
  };
};

export const registerBot = async (partyId: string, data: RegisterBotRequest) =>
  ResponseHandler.handler<RegisterBotResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `${partyId}/register`),
    method: "POST",
    data,
  });

export interface GetDeployInfoResponse {
  deployer_id: string;
  address: string;
  price: number;
  chain_id: number;
  trx_hash: string;
  trx_status: string;
  base: string;
  quote: string;
}

export const getDeployInfo = async (deployerId: string) =>
  ResponseHandler.handler<GetDeployInfoResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/info `),
    method: "GET",
  });

export interface DeployProfileResponse {
  hash: string;
}

export const deployProfile = async (deployerId: string) =>
  ResponseHandler.handler<GetDeployInfoResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/deploy`),
    method: "PATCH",
  });

export type DEXV2VaultType = "main" | "limit" | "volume" | "counter";

export interface DEXV2Vault {
  address: string;
  type: DEXV2VaultType;
  base: number;
  quote: number;
}

export interface DEXV2VaultBalanceResponse {
  base: number;
  quote: number;
}

export interface GetVaultsBalancesResponse
  extends Record<DEXV2VaultType, DEXV2VaultBalanceResponse> {}

export const getVaultBalances = async (deployerId: string) =>
  ResponseHandler.handler<GetVaultsBalancesResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${deployerId}/vault/balance`),
    method: "GET",
  });

export interface GetBotVaultsResponse {
  main_vault: string;
  limit_vault: string;
  volume_vault: string;
  counter_vault: string;
  base_ticker: string;
  quote_ticker: string;
}

export const getBotVaults = async (botId: string) =>
  ResponseHandler.handler<GetBotVaultsResponse>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/vaults`),
    method: "GET",
  });

export const deployBot = async (botId: string) =>
  ResponseHandler.handler<{}>({
    url: apiUrl(ApiModules.DEXV2Bot, `/${botId}/cluster/deploy`),
    method: "PATCH",
  });
