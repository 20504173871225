import styled from "styled-components";

export const Container = styled.div`
  height: calc(100% - 260px);

  display: flex;
  flex-direction: column;
  flex: 1;

  padding-top: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 15px 3px 1px 1px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  & > div:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;

  & > button {
    /* padding-top: 15px; */
    margin-left: 5px;
  }
`;
