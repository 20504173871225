import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Button, ButtonProps } from "src/components/shared/Buttons/v2/Button";
import { ChainConnectionContext } from "src/context/DEXV2/ChainConnectionContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";

export interface SwitchChainButtonProps extends Omit<ButtonProps, "onClick"> {
  chainId: number;
}

export const SwitchChainButton = observer(({ chainId, ...props }: SwitchChainButtonProps) => {
  const state = useLateInitContext(ChainConnectionContext);

  const onSwitchNetwork = useCallback(() => {
    state.switchChain(chainId);
  }, [chainId, state]);

  return (
    <Button onClick={onSwitchNetwork} {...props}>
      Switch network
    </Button>
  );
});
