import { ComponentPropsWithoutRef } from "react";
import { OwnRefProps } from "src/helpers/utils";
import * as styles from "./style";

export interface IconButtonProps
  extends Omit<ComponentPropsWithoutRef<"button">, keyof styles.IconButtonStyleProps>,
    OwnRefProps<"button">,
    Partial<styles.IconButtonStyleProps> {}

export const IconButton = ({
  children,
  ownRef,
  size = "m",
  elevated = false,
  boxed = false,
  ...props
}: IconButtonProps) => (
  <styles.StyledIconButton ref={ownRef} $size={size} $elevated={elevated} $boxed={boxed} {...props}>
    {children}
  </styles.StyledIconButton>
);
