import { observer } from "mobx-react-lite";
import React, { ComponentPropsWithoutRef } from "react";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BotAccountName } from "src/modules/accounts";
import { MenuItems } from "src/modules/shared";
import { AccountSwitcher } from "./AccountSwitcher";
import { BalanceTable } from "./BalanceTable";
import * as styles from "./styles";

interface BalancesProps extends ComponentPropsWithoutRef<"div"> {
  switchAccount: (account: BotAccountName) => void;
  updAcc: () => void;
}

export const Balances = observer(({ switchAccount, updAcc, ...props }: BalancesProps) => {
  const mainState = useLateInitContext(ExchangeContext);

  const accounts = mainState.accounts.map((el: MenuItems) => ({
    value: el.value,
    label: el.label.toUpperCase(),
  }));

  return (
    <styles.Container {...props}>
      <styles.Body>
        <AccountSwitcher
          menuItems={accounts}
          defaultKey={mainState.selectedAccount}
          onChange={(value: BotAccountName) => switchAccount(value)}
          onClickActive={() => updAcc()}
        />
        <BalanceTable
          data={mainState.balancesState.balances}
          accountState={mainState.balancesState.accountState}
          loader={mainState.balancesState.isLoading}
        />
      </styles.Body>
    </styles.Container>
  );
});
