import { makeAutoObservable } from "mobx";
import { joinStrings } from "src/helpers/string";
import { Extends, IDisposable, entries } from "src/helpers/utils";
import { ConstraintsErrorsReport } from "src/state/CEX/CEXSettings/ConstraintsStore";
import { IChainProvider } from "src/state/chain/ChainProviderStore";
import { DEXV2CreatingKeys, DEXV2CreatingModuleNames, IDEXV2CreateDataProvider } from ".";
import TokenTickerValidationStore, {
  ITokenTickerValidation,
  getTokenValidationReportText,
} from "../../shared/TokenTickerValidationStore";
import { IWalletsValidation } from "../../shared/WalletsStore";
import { ConstraintsValidator } from "../../types";
import CreateAddressProviderStore from "./CreateAddressProviderStore";

export interface ICreateConstraintsStateProvider extends IDEXV2CreateDataProvider {
  get transferWalletsState(): IWalletsValidation;
  get swapWalletsState(): IWalletsValidation;
  isWalletAddress: (address: string) => Promise<Record<string, boolean>>;
}

export interface ICreateConstraintsValidation {
  validator: ConstraintsValidator;
  getValidationReport: () => ConstraintsErrorsReport;
}

interface ICreateConstraintsParams {
  stateProvider: ICreateConstraintsStateProvider;
  chainProvider: IChainProvider;
}

export interface ICreateConstraints {
  getWarningByKey: (key: DEXV2CreatingKeys) => string | undefined;
  getConstraintsValidation: (
    module: Extends<DEXV2CreatingModuleNames, "base_data" | "main_data">
  ) => ICreateConstraintsValidation;
}

const mapWarningsEntries = (entry?: [DEXV2CreatingKeys, string | undefined]) => {
  if (!entry) return undefined;
  const [, error] = entry;
  return error;
};

export default class CreateConstraintsStore implements IDisposable, ICreateConstraints {
  private _warnings: Partial<Record<DEXV2CreatingKeys, string | undefined>> = {};

  private _stateProvider: ICreateConstraintsStateProvider;

  private _baseTickerValidation: ITokenTickerValidation;

  private _quoteTickerValidation: ITokenTickerValidation;

  private _stableTickerValidation: ITokenTickerValidation;

  constructor({ chainProvider, stateProvider }: ICreateConstraintsParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseTickerValidation = new TokenTickerValidationStore({
      chainProvider,
      addressProvider: new CreateAddressProviderStore({
        dataProvider: stateProvider,
        tokenType: "base",
      }),
      tokenType: "base",
    });

    this._quoteTickerValidation = new TokenTickerValidationStore({
      chainProvider,
      addressProvider: new CreateAddressProviderStore({
        dataProvider: stateProvider,
        tokenType: "quote",
      }),
      tokenType: "quote",
    });

    this._stableTickerValidation = new TokenTickerValidationStore({
      chainProvider,
      addressProvider: new CreateAddressProviderStore({
        dataProvider: stateProvider,
        tokenType: "stable",
      }),
      tokenType: "stable",
    });
  }

  private get _data() {
    return this._stateProvider.data;
  }

  private get _withdrawer() {
    return this._data.base_data.withdrawer;
  }

  private get _transferWalletsState() {
    return this._stateProvider.transferWalletsState;
  }

  private get _swapWalletsState() {
    return this._stateProvider.swapWalletsState;
  }

  private _isWalletAddress = async (address: string) =>
    await this._stateProvider.isWalletAddress(address);

  private _setWarningByKey = (key: DEXV2CreatingKeys, warning: string | undefined) => {
    this._warnings[key] = warning;
  };

  getWarningByKey = (key: DEXV2CreatingKeys) => {
    switch (key) {
      case "base_data.base": {
        return this._baseTickerValidation.warning;
      }
      case "base_data.quote": {
        return this._quoteTickerValidation.warning;
      }
      case "main_data.stable": {
        return this._stableTickerValidation.warning;
      }
      default: {
        return this._warnings[key];
      }
    }
  };

  private _tickerValidation = async () => {
    const validations = await Promise.all([
      this._baseTickerValidation.validateTicker(),
      this._quoteTickerValidation.validateTicker(),
    ]);

    return validations;
  };

  private _withdrawerWalletValidation = async () => {
    const address = this._withdrawer;
    const withdrawerValid = await this._isWalletAddress(address);
    const warning =
      withdrawerValid[address] === false ? "withdrawer address can't be contract!" : "";
    this._setWarningByKey("base_data.withdrawer", warning);

    return !warning;
  };

  private _walletsValidation = async () => {
    const validations = await Promise.all([
      this._withdrawerWalletValidation(),
      this._transferWalletsState.validateWalletsConstraints(),
      this._swapWalletsState.validateWalletsConstraints(),
    ]);

    return validations;
  };

  private _baseConstraintsValidation = async () => {
    const validations = await Promise.all([this._tickerValidation(), this._walletsValidation()]);

    return validations.flatMap((it) => it).every(Boolean);
  };

  private _tickerValidationReport = (): ConstraintsErrorsReport => {
    const baseTicker = this._baseTickerValidation.ticker;
    const quoteTicker = this._quoteTickerValidation.ticker;

    if (quoteTicker && baseTicker) {
      const title = "Are you sure you want to create a bot with the following pair:";
      const message = `Quote: ${quoteTicker}, Base: ${baseTicker}`;
      return { title, message };
    }

    const baseTickerText = !baseTicker ? "Base" : "";
    const quoteTickerText = !quoteTicker ? "Quote" : "";
    const tickersText = [baseTickerText, quoteTickerText].filter(Boolean).join(" & ");

    const report = getTokenValidationReportText(tickersText);

    return report;
  };

  private _baseWarningsValidationReport = (): ConstraintsErrorsReport => {
    const warningErrorMessages = entries(this._warnings).map(mapWarningsEntries);

    return {
      title: "warnings",
      message: joinStrings(warningErrorMessages),
    };
  };

  private _getBaseValidationReport = (): ConstraintsErrorsReport => {
    const { title: tickersTitle, message: tickersMessage } = this._tickerValidationReport();

    const { message: warningsMessage } = this._baseWarningsValidationReport();

    const { message: transferWalletsMessage } =
      this._transferWalletsState.walletsValidationReport();

    const { message: swapWalletsMessage } = this._swapWalletsState.walletsValidationReport();

    const warningsText = joinStrings([warningsMessage, transferWalletsMessage, swapWalletsMessage]);
    const warningsTitle = warningsText.length > 0 ? "\nThere are following warnings: " : "";

    const messages = [tickersMessage, warningsTitle, warningsText, "\nWant to continue?"];
    return { title: tickersTitle, message: joinStrings(messages) };
  };

  private _mainConstraintsValidation = async () => {
    const validations = await Promise.all([this._stableTickerValidation.validateTicker()]);

    return validations.every(Boolean);
  };

  private _stableTickerValidationReport = (): ConstraintsErrorsReport => {
    const stableTicker = this._stableTickerValidation.ticker;

    if (stableTicker) {
      const title = "Are you sure you want to use the following stable token:";
      const message = `${stableTicker}`;
      return { title, message };
    }

    const report = this._stableTickerValidation.tokenValidationReport;

    return report;
  };

  private _getMainValidationReport = (): ConstraintsErrorsReport => {
    const report = this._stableTickerValidationReport();

    return report;
  };

  getConstraintsValidation = (
    module: Extends<DEXV2CreatingModuleNames, "base_data" | "main_data">
  ): ICreateConstraintsValidation => {
    switch (module) {
      case "base_data": {
        return {
          validator: this._baseConstraintsValidation,
          getValidationReport: this._getBaseValidationReport,
        };
      }
      case "main_data": {
        return {
          validator: this._mainConstraintsValidation,
          getValidationReport: this._getMainValidationReport,
        };
      }
    }
  };

  destroy = () => {};
}
