import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useCallback } from "react";
import { Loader } from "src/components/shared/Loader";
import { DEXV2SwapContext } from "src/context/DEXV2/DEXV2Swap/DEXV2Swap";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { useSwapWidgetState } from "../hooks/useSwapWidgetState";
import { GasEstimateInfo } from "./GasEstimateInfo";
import { Input } from "./Input";
import { SlippageInput } from "./Inputs/SlippageInput";
import { Output } from "./Output";
import { PriceInfo } from "./PriceInfo";
import { RefreshButton } from "./RefreshButton";
import { SwapDirectionIcon } from "./SwapDirectionIcon";
import { WarningRow } from "./WarningRow";
import * as styles from "./style";

export interface SwapWidgetProps extends ComponentPropsWithoutRef<"div"> {}

export const SwapWidget = observer((props: SwapWidgetProps) => {
  const state = useSwapWidgetState();
  const swapState = useLateInitContext(DEXV2SwapContext);

  const chainInfoProps = swapState.chainMeta;
  const vaultType = state.currentVault.type;

  const onSwitchTokens = useCallback(() => {
    state.swapState.switchSwapTokens();
    state.refreshSwap();
  }, [state]);

  return (
    <styles.Container {...props}>
      <styles.CardHeader
        title={`Swap on ${swapState.dexName}`}
        afterTitle={
          <styles.CardRow>
            <styles.ChainInfoChip {...chainInfoProps} />
          </styles.CardRow>
        }
      />
      <styles.Card $disabled={!state.enabled}>
        <RefreshButton loading={state.isRefreshing} onRefresh={state.forceRefreshSwap} />

        <styles.CardRow>
          <SlippageInput value={state.slippage} onChange={state.setSlippage} />
          <styles.LargeVaultTypeCell type={vaultType} text={`${vaultType} vault`} />
        </styles.CardRow>
        <styles.InputsWrapper>
          <styles.InputCol>
            <Input />
            <SwapDirectionIcon onClick={onSwitchTokens} />
          </styles.InputCol>

          <styles.InputCol>
            <Output />
            <GasEstimateInfo />
          </styles.InputCol>
        </styles.InputsWrapper>

        <PriceInfo />

        <WarningRow />

        <styles.SwapActionButton />

        <Loader show={state.isForceRefreshing} />
      </styles.Card>
    </styles.Container>
  );
});
