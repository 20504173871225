import React, { useCallback, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { ChartOptions, TooltipItem } from "chart.js";
import { observer } from "mobx-react-lite";
import { getTheme } from "src/helpers/getStatus/getBotStatusColor";
import { hexToRgb } from "src/helpers/colors";
import DepthChartStore, { IDepthChartPoint } from "src/state/CEX/CEXExchange/DepthChart";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { DepthChartContext } from "src/context/CEX/Exchange/DepthChart";
import { numFormatter } from "src/helpers/separation";
import { roundSingleValue, toRounding } from "src/helpers/rounding";
import { useChartJsCrosshairPlugin } from "src/hooks/useChartJsCrosshairPlugin";
import { VolumeScale } from "./VolumeScale";
import { OptionsPanel } from "./OptionsPanel";
import * as styles from "./style";

interface ConfigProps {
  maxY: number;
  maxX: number;
  minX: number;
  minY: number;
  typeScale: string;
}

const Y_AXIS_TICKS_CONFIG = {
  ticks: {
    callback(value: string | number) {
      return numFormatter(value);
    },
    padding: 10,
  },
};

export const DepthChart = observer(() => {
  const theme = getTheme();

  const exchState = useLateInitContext(ExchangeContext);

  const state = useMemo(() => new DepthChartStore(exchState), [exchState]);

  const calcAvgPrice = useCallback((ctx: TooltipItem<"line">) => {
    const dataSet = ctx.dataset;
    const data = dataSet.data as unknown as IDepthChartPoint[];
    const { length } = data;
    const currentDataIndex = ctx.dataIndex;
    const totalVolume = (ctx.raw as IDepthChartPoint).volume;
    const yAxis = ctx.dataset.yAxisID;

    let total = 0;

    if (yAxis === "y") {
      total = data
        .slice(currentDataIndex, length)
        .reduce((accumulator, { amount }) => accumulator + amount, 0);
    } else if (yAxis === "y1") {
      total = data
        .slice(0, currentDataIndex + 1)
        .reduce((accumulator, { amount }) => accumulator + amount, 0);
    }

    const avgPrice = totalVolume / total;

    return roundSingleValue(avgPrice);
  }, []);

  const hoverCrosshair = useChartJsCrosshairPlugin(state.mainState.pricePrecision);

  const xAxisTicksConfig = useMemo(
    () => ({
      ticks: {
        callback(value: string | number) {
          return toRounding(+value, state.mainState.pricePrecision);
        },
      },
    }),
    [state.mainState.pricePrecision]
  );

  const tooltipTitleConfig = useMemo(
    () => ({
      title(ctx: TooltipItem<"line">[]) {
        if (!ctx.length) return;

        const chartCtx = ctx[0];

        const avgPrice = calcAvgPrice(chartCtx);

        let price = "";

        if (chartCtx.parsed.x !== null) {
          price += toRounding(chartCtx.parsed.x, state.mainState.pricePrecision);
        }
        return `Price: ${price}
Avg price: ${avgPrice}`;
      },
    }),
    [state.mainState.pricePrecision, calcAvgPrice]
  );

  const getAxisData = useCallback(() => {
    const axis = [];

    if (state.showBuyOrders)
      axis.push({
        label: `Buy total ${state.totalTicker}`,
        data: state.buyOrders,
        borderColor: theme.successColor,
        backgroundColor: hexToRgb(theme.successColor, 0.5),
        yAxisID: "y",
        borderWidth: 2,
        fill: true,
      });

    if (state.showSellOrders)
      axis.push({
        label: `Sell total ${state.totalTicker}`,
        data: state.sellOrders,
        borderColor: theme.dangerColor,
        backgroundColor: hexToRgb(theme.dangerColor, 0.5),
        yAxisID: "y1",
        borderWidth: 2,
        fill: true,
      });

    return {
      datasets: axis,
    };
  }, [
    state.buyOrders,
    state.sellOrders,
    state.showBuyOrders,
    state.showSellOrders,
    theme.successColor,
    theme.dangerColor,
    state.totalTicker,
  ]);

  const getConfig = useCallback(
    ({ maxY, maxX, minX, minY, typeScale }: ConfigProps) =>
      ({
        responsive: true,
        maintainAspectRatio: false,
        stacked: false,

        interaction: {
          mode: "nearest" as const,
          intersect: false,
          axis: "x",
        },
        parsing: {
          xAxisKey: "price",
          yAxisKey: "volume",
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "nearest" as const,
            intersect: false,
            callbacks: {
              ...tooltipTitleConfig,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          xAxis: {
            offset: false,
            type: "linear" as const,

            max: maxX,
            min: minX,
            ...xAxisTicksConfig,
          },
          y: {
            type: typeScale as any,
            display: true,
            max: maxY,
            min: minY,
            ...Y_AXIS_TICKS_CONFIG,
          },
          y1: {
            type: typeScale as any,
            display: true,
            position: "right" as const,
            grid: {
              drawOnChartArea: false,
            },
            max: maxY,
            min: minY,
            ...Y_AXIS_TICKS_CONFIG,
          },
        },
      }) as ChartOptions<"line">,
    [xAxisTicksConfig, tooltipTitleConfig]
  );

  const config = getConfig({
    maxY: state.maxYCoord,
    maxX: state.maxXCoord,
    minX: state.minXCoord,
    minY: state.minYCoord,
    typeScale: state.typeScale,
  });

  const multiAxisData = getAxisData();

  return (
    <DepthChartContext.Provider value={state}>
      <styles.Container>
        <OptionsPanel />
        <VolumeScale />

        <styles.ChartContent>
          <Line data={multiAxisData} options={config} plugins={[hoverCrosshair]} />
        </styles.ChartContent>
      </styles.Container>
    </DepthChartContext.Provider>
  );
});
