import { observer } from "mobx-react-lite";
import React, { ChangeEvent } from "react";
import * as styles from "./style";

export interface ToggleSwitchProps {
  label?: string;
  defaultChecked?: boolean;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  id?: string;
  style?: React.CSSProperties;
}

export const ToggleSwitch = observer(
  ({ label, checked, defaultChecked, onChange, disabled, id, ...props }: ToggleSwitchProps) => (
    <styles.Container {...props}>
      <styles.Switch>
        <styles.CheckBox
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          id={id}
        />
        <styles.Slider />
      </styles.Switch>
      {label ? (
        <styles.SwitchTitle checked={checked} htmlFor={id}>
          {label}
        </styles.SwitchTitle>
      ) : (
        ""
      )}
    </styles.Container>
  )
);
