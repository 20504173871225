import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import TokenFillStore from "src/state/DEXV2/DEXV2Create/TokenFillStore";
import { useDeployerInfoStore } from "./WalletGasFill";

export const TokenFillContext = createLateInitContext<TokenFillStore>();

type TokenFillProviderProps = ChildrenProps;

export const TokenFillProvider = ({ children }: TokenFillProviderProps) => {
  const deployerInfoStore = useDeployerInfoStore();

  const gasFillStore: TokenFillStore = useLocalStore(TokenFillStore, deployerInfoStore);

  return <TokenFillContext.Provider value={gasFillStore}>{children}</TokenFillContext.Provider>;
};
