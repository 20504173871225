import { SeriesContext } from "src/context/Graph/Series";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { GraphRootProps } from "..";
import { useDefaultizedSeriesProps } from "./useDefaultizedSeriesProps";

interface UseSeriesParams extends Pick<GraphRootProps, "series"> {}

export const useSeries = ({ series: seriesProps }: UseSeriesParams) => {
  const { setSeries, seriesMap, seriesTitlesMap, setSeriesOptions, setSeriesTitle } =
    useLateInitContext(SeriesContext);

  const defaultizedSeriesProps = useDefaultizedSeriesProps({
    series: seriesProps,
  });

  return {
    seriesMap,
    setSeries,
    setSeriesOptions,
    seriesTitlesMap,
    setSeriesTitle,
    defaultizedSeriesProps,
  };
};
