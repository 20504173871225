import { observer } from "mobx-react-lite";
import { useFormContext } from "react-hook-form";
import { DeleteButton } from "src/components/shared/Buttons/v2/variants/IconButtons/DeleteButton";
import { SaveButton } from "src/components/shared/Buttons/v2/variants/SaveButton";
import { ToggleSwitch } from "src/components/shared/ToggleSwitch";
import { ModuleListContext, MultiGridAbilitiesContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { IExchangeModule } from "src/state/CEX/multiGrid/Modules";
import * as styles from "./style";

interface ModuleHeaderProps {
  module: IExchangeModule;
}

export const ModuleHeader = observer(({ module }: ModuleHeaderProps) => {
  const {
    formState: { isDirty },
  } = useFormContext();

  const state = useLateInitContext(ModuleListContext);

  const { abilityName } = useLateInitContext(MultiGridAbilitiesContext);

  return (
    <styles.HeaderContainer>
      <styles.Wrapper>
        <styles.TitleWrapper>
          <styles.ModuleStatus status={module.status} />
          <styles.Title>{`${module.id} - ${module.pair}`}</styles.Title>
        </styles.TitleWrapper>
      </styles.Wrapper>

      <PermissionWrapper party={state.party} abilityName={abilityName}>
        <styles.Wrapper>
          <styles.BtnWrapper>
            <ToggleSwitch
              checked={state.getModuleStatus(module.status)}
              onChange={state.toggleHandler(module.id)}
            />
            <SaveButton form={module.id} saved={!isDirty} />
          </styles.BtnWrapper>

          <DeleteButton onClick={state.removeHandler(module.id)} />
        </styles.Wrapper>
      </PermissionWrapper>
    </styles.HeaderContainer>
  );
});
