import { Children, ComponentPropsWithoutRef, ReactNode } from "react";
import { ChildrenProps } from "src/helpers/utils";
import * as styles from "./style";

export interface SettingsFormContentProps
  extends styles.OrientationOuterProps,
    ChildrenProps,
    ComponentPropsWithoutRef<"div"> {}

export const SettingsFormContent = ({
  orientation,
  children,
  ...props
}: SettingsFormContentProps) => {
  const childrenCount = Children.count(children);
  const childrenWithDivider: ReactNode[] = [];

  Children.forEach(children, (child, index) => {
    childrenWithDivider.push(child);
    if (index + 1 < childrenCount) {
      // eslint-disable-next-line react/no-array-index-key
      childrenWithDivider.push(<styles.Divider key={index} $orientation={orientation} />);
    }
  });
  return (
    <styles.Container $orientation={orientation} {...props}>
      {childrenWithDivider}
    </styles.Container>
  );
};
