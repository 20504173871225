import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { ModalPanel, ModalPanelProps } from "src/components/shared/ModalPanel";
import { useTheme } from "styled-components";
import * as styles from "./style";

interface OrderInfoProps<T> extends Omit<ModalPanelProps, "children"> {
  data: T | undefined;
  keys: Array<keyof T>;
  error?: string;
}

export const OrderInfoModal = observer(
  <T extends object>({
    data,
    show,
    keys,
    close,
    label,
    error,
    loading,
    afterLabel,
  }: OrderInfoProps<T>) => {
    const theme = useTheme();

    return (
      <ModalPanel label={label} show={show} close={close} afterLabel={afterLabel}>
        <styles.Content>
          <styles.GridContent>
            {data
              ? keys.map((el, index) => {
                  const key = String(el) + index;

                  return (
                    <styles.OrderInfoCell
                      key={key}
                      data={{
                        label: String(el),
                        value: String(data[el]),
                        cellProps:
                          el === "side"
                            ? {
                                value: {
                                  style: {
                                    color:
                                      String(data[el]) === "BUY"
                                        ? theme.successColor
                                        : theme.dangerColor,
                                  },
                                },
                                label: {},
                              }
                            : undefined,
                      }}
                    />
                  );
                })
              : null}
          </styles.GridContent>
          {error ? <styles.OrderInfoCell data={{ label: "error", value: error }} /> : null}

          <Loader show={loading} />
        </styles.Content>
      </ModalPanel>
    );
  }
);
