import { RefButtonWrapper } from "src/components/shared/Buttons/v2/variants/RefButtonWrapper";
import { ModalPanelV2 } from "src/components/shared/ModalPanelV2";
import { InfoButton } from "../../shared/InfoButton";
import { ModalContent, ModalContentProps } from "./ModalContent";

export interface FloatingOrderInfoProps extends ModalContentProps {}

export const FloatingOrderInfo = (props: FloatingOrderInfoProps) => (
  <ModalPanelV2
    label="Floating order info:"
    trigger={
      <RefButtonWrapper>
        <InfoButton />
      </RefButtonWrapper>
    }
  >
    <ModalContent {...props} />
  </ModalPanelV2>
);
