import { ComponentPropsWithoutRef } from "react";
import { Title } from "../style";
import * as styles from "./style";

interface LabelValueProps extends ComponentPropsWithoutRef<"div"> {
  label: string;
  value: string;
}

export const LabelValue = ({ label, value, ...props }: LabelValueProps) => (
  <Title {...props}>
    {label}: <styles.ValueText>{`${value}`}</styles.ValueText>
  </Title>
);
