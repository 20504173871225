import React, { ComponentPropsWithoutRef } from "react";
import { CellData } from "..";
import * as styles from "../style";

interface InfoPanelCellProps extends ComponentPropsWithoutRef<"div"> {
  data: CellData;
}

export const InfoPanelCell = ({
  data: { label, value, quote, color, cellProps },
  ...props
}: InfoPanelCellProps) => (
  <styles.InfoItem {...props}>
    <styles.Label {...cellProps?.label}>{label}</styles.Label>
    <styles.Value {...cellProps?.value}>
      {value}
      {quote ? <styles.Quote style={{ color }}>{quote}</styles.Quote> : null}
    </styles.Value>
  </styles.InfoItem>
);
