import { ComponentPropsWithoutRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { BotModulesContext } from "src/context/CEX/BotModules/BotModulesProvider";
import { PopupContext } from "src/context/shared/PopupContext";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CEXBindingModule } from "src/state/CEX/botModules";
import { ERRORS_MSGS } from "src/validation-schemas";
import { FormContent } from "../Modules/shared/FormContent";
import * as styles from "./style";

const FORM_ID = "create_form";
const MODULE_NAME_VALIDATION = {
  value: /^\d*[a-zA-Z][a-zA-Z\d]*$/i,
  message: "Name must contain only letters and numbers without spaces",
};

export interface CreateModuleProps extends ComponentPropsWithoutRef<"div"> {}

export const CreateModule = ({ children }: CreateModuleProps) => {
  const state = useLateInitContext(BotModulesContext);

  const { control } = useFormContext<CEXBindingModule>();

  const { close } = useLateInitContext(PopupContext);

  return (
    <styles.Container>
      <Controller
        control={control}
        name="name"
        rules={{
          required: ERRORS_MSGS.isRequired,
          pattern: {
            value: MODULE_NAME_VALIDATION.value,
            message: MODULE_NAME_VALIDATION.message,
          },
        }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <LabeledInput
            label="Name"
            value={value}
            onChange={(e) => onChange(getChangeEventValue(e))}
            errorHint={errors.name?.message}
          />
        )}
      />

      <FormContent formId={FORM_ID} submitHandler={state.createModule(close)}>
        {children}
      </FormContent>

      <SubmitButton size="m" form={FORM_ID}>
        Add
      </SubmitButton>
    </styles.Container>
  );
};
