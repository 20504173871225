import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { IDisposable } from "src/helpers/utils";
import { IRouterProvider } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/Providers/RouterProvider";
import { IBotTradePairProvider, ISwapPairAddressProvider } from "../../../DEXV2Bots/DEXV2BotStore";
import {
  ISwapInfoImpact,
  SwapInfoImpactStore,
} from "../../../DEXV2Swap/SwapModules/SwapInfo/SwapInfoImpact";
import {
  ISwapInfoRoute,
  SwapInfoRouteStore,
} from "../../../DEXV2Swap/SwapModules/SwapInfo/SwapInfoRouteStore";
import {
  ISwapInfoTradePair,
  SwapInfoTradePairStore,
} from "../../../DEXV2Swap/SwapModules/SwapInfo/SwapInfoTradePairStore";
import { ISwapState, SwapStateStore } from "../../../DEXV2Swap/SwapModules/shared/SwapStateStore";
import { INonNullableVersionProvider } from "../../../DEXV2Swap/SwapModules/shared/VersionedSwapState";
import { CacheOptions } from "../../../DEXV2Swap/utils";

export interface IVolumeInfoParams {
  tradePairProvider: IBotTradePairProvider;
  routerProvider: IRouterProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  versionProvider: INonNullableVersionProvider;
}

export class PriceImpactInfoStore implements IDisposable {
  private _swapState: ISwapState;

  private _swapInfoRouteState: ISwapInfoRoute & IDisposable;

  private _swapPairInfoState: ISwapInfoTradePair & IDisposable;

  private _swapImpactInfoState: ISwapInfoImpact & IDisposable;

  constructor({
    tradePairProvider,
    routerProvider,
    pairAddressProvider,
    versionProvider,
  }: IVolumeInfoParams) {
    makeAutoObservable(this);

    this._swapState = new SwapStateStore();

    this._swapInfoRouteState = new SwapInfoRouteStore({
      swapState: this._swapState,
      routerProvider,
      poolAddressProvider: pairAddressProvider,
    });

    this._swapPairInfoState = new SwapInfoTradePairStore({
      swapState: this._swapState,
      tradePairProvider,
    });

    this._swapImpactInfoState = new SwapInfoImpactStore({
      pairInfo: this._swapPairInfoState,
      routeInfo: this._swapInfoRouteState,
      versionProvider,
    });

    makeLoggable(this, { priceImpact: true });
  }

  get swapState() {
    return this._swapState;
  }

  get routeInitialized() {
    return this._swapInfoRouteState.routeInitialized;
  }

  getPriceImpact = async (options?: CacheOptions) => {
    await this._swapInfoRouteState.routeTrade(options);
  };

  get priceImpact() {
    return this._swapImpactInfoState.priceImpact;
  }

  destroy = () => {
    this._swapInfoRouteState.destroy();
    this._swapPairInfoState.destroy();
    this._swapImpactInfoState.destroy();
  };
}
