import { ComponentPropsWithoutRef } from "react";
import * as styles from "./style";

export interface DemoCardProps extends ComponentPropsWithoutRef<"div"> {
  title: string;
}

export const DemoCard = ({ title, children, ...props }: DemoCardProps) => (
  <styles.DemoCard {...props}>
    <styles.Title>{title}</styles.Title>
    <styles.Content>{children}</styles.Content>
  </styles.DemoCard>
);
