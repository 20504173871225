import { ModalPanel } from "src/components/shared/ModalPanel";
import styled from "styled-components";

export const EditAccModal = styled(ModalPanel)`
  @media screen and (max-width: 670px) {
    width: calc(100% - 20px);
  }
`;

export const Container = styled.div`
  height: 350px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 15px 3px 1px 1px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  & > div:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > button {
    padding-top: 15px;
    margin-left: 5px;
  }
`;

export const BtnWrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 24px;
`;

export const IconSpan = styled.span`
  display: inline-block;
  margin-right: 8px;
`;
