import { useMemo } from "react";
import useMediaQuery from "src/hooks/useMediaQuery";

export const useButtonSize = () => {
  const isMobile = useMediaQuery("(max-width: 700px)");

  const btnSize = useMemo(() => (isMobile ? "m" : "l"), [isMobile]);

  return btnSize;
};
