import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import {
  BotModesTotal,
  GetPNLRequestParams,
  GetPNLResponse,
  getPNL,
} from "src/api/bots/DEXV2/stats";
import { Duration } from "src/components/shared/DatePickers/shared/utils/parse-utils";
import { getCurrentDayjs } from "src/helpers/dateUtils";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable, Mapper, entries } from "src/helpers/utils";
import { DEXV2BotMode } from "src/modules/bots";
import { IBotUUIDProvider } from "../../DEXV2Bots/DEXV2BotStore";
import { INativeUSDPriceProvider } from "../../Providers/NativeUSDPriceProvider";
import { NativeQuote, parseNativeQuote } from "../utils";

interface IHistoryFeesParams {
  botUUIDProvider: IBotUUIDProvider;
  nativeUSDPriceProvider: INativeUSDPriceProvider;
  duration?: Duration;
}

type ModesFees = Record<DEXV2BotMode, number>;

export interface IHistoryFees {
  historyFees: (key: DEXV2BotMode) => NativeQuote;
  getHistoryFees: () => Promise<void>;
}

const totalKeyToModeKey = (key: keyof BotModesTotal) => key.split("_")[0] as DEXV2BotMode;

const pnlResponseToModesFees: Mapper<GetPNLResponse, ModesFees> = (response) => {
  const nativeFeesByMode = response.value_by_bot_type.native;

  const modesFees = entries(nativeFeesByMode).reduce((acc, [key, value]) => {
    const modeKey = totalKeyToModeKey(key);
    acc[modeKey] = value;
    return acc;
  }, {} as ModesFees);

  return modesFees;
};

const INITIAL_MODE_FEES: ModesFees = {
  limit: 0,
  volume: 0,
  counter: 0,
};

export class HistoryFeesStore implements IHistoryFees, IDisposable {
  private _historyFees: ModesFees = INITIAL_MODE_FEES;

  private _botUUIDProvider: IBotUUIDProvider;

  private _nativeUSDPriceProvider: INativeUSDPriceProvider;

  private _rangeDuration: Duration;

  constructor({
    botUUIDProvider,
    nativeUSDPriceProvider,
    duration = { hours: 24 },
  }: IHistoryFeesParams) {
    makeAutoObservable(this, { historyFees: false });

    this._botUUIDProvider = botUUIDProvider;

    this._nativeUSDPriceProvider = nativeUSDPriceProvider;

    this._rangeDuration = duration;

    makeLoggable(this, { historyFees: true });
  }

  private get _nativeUSDPrice() {
    return this._nativeUSDPriceProvider.nativeUSDPrice;
  }

  private _setHistoryFees = (fees: ModesFees) => {
    this._historyFees = fees;
  };

  private get _botUUID() {
    return this._botUUIDProvider.botUUID;
  }

  historyFees = computedFn((key: DEXV2BotMode) => {
    const fee = this._historyFees[key];
    const nativeUSDPrice = this._nativeUSDPrice;
    const rawFees = parseNativeQuote(fee, nativeUSDPrice);
    return rawFees;
  });

  private get _historyRange() {
    const end = getCurrentDayjs();
    const dayjsDuration = dayjs.duration(this._rangeDuration);
    const start = end.subtract(dayjsDuration);
    return [start, end] as const;
  }

  private get _pnlParams(): GetPNLRequestParams | undefined {
    const [start, end] = this._historyRange;
    if (!start || !end) return undefined;
    return { start: `${start.unix()}`, end: `${end.unix()}` };
  }

  getHistoryFees = async () => {
    const params = this._pnlParams;
    const botUUID = this._botUUID;
    if (!botUUID || !params) return;

    try {
      const { isError, data } = await getPNL(botUUID, params);
      if (!isError) {
        const fees = pnlResponseToModesFees(data);
        this._setHistoryFees(fees);
      }
    } catch (err) {
      logError(err);
    }
  };

  destroy = () => {};
}
