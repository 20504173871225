import { makeAutoObservable } from "mobx";
import { HashItem } from "src/components/shared/HashesList/HashesListItem/HashLabel";
import { FormErrors, FormFieldHandler, FormValidation } from "src/helpers/forms/types";
import { strFormatter } from "src/helpers/string";
import { entries } from "src/helpers/utils";
import { FieldErrorProps } from "src/state/CEX/CEXSettings";
import { ConstraintsErrorsReport } from "src/state/CEX/CEXSettings/ConstraintsStore";
import { isContainsElements, validateData } from "src/validation-schemas";
import WalletInputStore, { IWalletsProvider } from "./WalletInputStore";

export interface IWalletAddressValidation {
  isWalletAddress: (address: string | string[]) => Promise<Record<string, boolean>>;
}

interface WalletsForm {
  wallets: string[];
}

export interface WalletsStoreConfig {
  walletValidationProvider?: IWalletAddressValidation;
  walletName?: string;
  validateWallets?: boolean;
}

export interface IWalletsValidation {
  validateWallets: () => boolean;
  validateWalletsConstraints: () => Promise<boolean>;
  walletsValidationReport: () => ConstraintsErrorsReport;
}

export interface IWalletsState {
  get walletsAsFieldProps(): HashItem[];
  get walletsError(): string | undefined;

  addWallet: () => boolean;
  deleteWallet: (wallet: string) => boolean;
  get wallet(): string;
  setWallet: FormFieldHandler;
  get walletErrors(): string | undefined;
}

export interface IWalletsFormProvider extends IWalletsValidation, IWalletsState {}

export default class WalletsStore implements IWalletsFormProvider {
  private _walletStore: WalletInputStore;

  private _walletsWarnings: Record<string, string | undefined> = {};

  private _walletsErrors: FormErrors<WalletsForm> = {};

  private _walletsValidation: FormValidation<WalletsForm> = {
    wallets: [isContainsElements()],
  };

  private _walletsProvider: IWalletsProvider;

  private _walletValidationProvider?: IWalletAddressValidation;

  private _walletName: string;

  private _validateWallets: boolean;

  constructor(
    _walletsProvider: IWalletsProvider,
    { walletValidationProvider, walletName, validateWallets = true }: WalletsStoreConfig = {}
  ) {
    makeAutoObservable(this);

    this._walletValidationProvider = walletValidationProvider;
    this._walletName = walletName ?? "name";
    this._validateWallets = validateWallets;

    this._walletsProvider = _walletsProvider;

    this._walletStore = new WalletInputStore(this._walletsProvider, this._walletName);
  }

  private get _wallets() {
    return this._walletsProvider.wallets;
  }

  private get _walletsForm(): WalletsForm {
    return { wallets: this._wallets };
  }

  private get _walletInput() {
    return this._walletStore.wallet;
  }

  private _setWalletsWarnings = (address: string, value: string | undefined) => {
    this._walletsWarnings[address] = value;
  };

  private _clearWalletsWarnings = () => {
    this._walletsWarnings = {};
  };

  validateWallets = () => {
    if (!this._validateWallets) return true;
    return validateData(this._walletsValidation, this._walletsForm, this._walletsErrors, undefined);
  };

  validateWalletsConstraints = async () => {
    if (!this._walletValidationProvider) {
      return true;
    }

    const walletsValidation = await this._walletValidationProvider.isWalletAddress(this._wallets);

    const walletsValid = Object.values(walletsValidation).every((valid) => valid !== false);

    this._clearWalletsWarnings();

    this._wallets.forEach((address) => {
      const warning =
        walletsValidation[address] === false
          ? `${this._walletName} address can't be contract!`
          : "";
      this._setWalletsWarnings(address, warning);
    });

    return walletsValid;
  };

  private _joinMessages = (messages: (string | undefined)[]) => messages.filter(Boolean).join("\n");

  walletsValidationReport = () => {
    const addressWarningMap = entries(this._walletsWarnings)
      .map(([address, error]) => {
        if (!error) return undefined;
        return { address: strFormatter(address), warning: error };
      })
      .filter(Boolean) as {
      address: string;
      warning: string;
    }[];

    const warningAddressesMap = addressWarningMap.reduce(
      (acc, { address, warning }) => {
        if (!acc[warning]) {
          acc[warning] = [];
        }
        acc[warning].push(address);
        return acc;
      },
      {} as Record<string, string[]>
    );

    const warningErrorMessages = entries(warningAddressesMap).map(([warning, addresses]) => {
      const addressesText = addresses.join(", ");
      return `${warning} : ${addressesText}`;
    });

    return {
      title: "warnings",
      message: this._joinMessages(warningErrorMessages),
    };
  };

  get walletsAsFieldProps(): HashItem[] {
    return this._wallets.map((address) => {
      const warning = this._walletsWarnings[address];
      const fieldError: Partial<FieldErrorProps> = warning
        ? {
            errorType: "warning",
            errorHint: warning,
          }
        : {};
      return { address, ...fieldError };
    });
  }

  get walletsError() {
    return this._walletsErrors.wallets;
  }

  addWallet = () => this._walletInput.addToArray();

  deleteWallet = (wallet: string) => this._walletInput.deleteFromArray(wallet);

  get wallet() {
    return this._walletInput.inputValue;
  }

  setWallet: FormFieldHandler = (e) => this._walletInput.getInputValueHandler(e);

  get walletErrors() {
    return this._walletInput.inputErrors;
  }
}
