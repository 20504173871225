import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { Loader } from "src/components/shared/Loader";
import { CheckMark, PanelContainer } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 500px;

  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 40px;
`;

export const ContractsDeployFallback = styled(CrashPanel)`
  max-width: 500px;
  min-height: 500px;
`;

export const StatusPanel = styled(PanelContainer)`
  height: 200px;
  width: 100%;
  max-width: 500px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  padding: 35px 40px;
  gap: 10px;
`;

export const LoaderWrapper = styled.div`
  position: relative;

  height: 100px;
  width: 200px;
`;

export const StatusLoader = styled(Loader)`
  background: ${({ theme }) => theme.bgSettingsPnl};

  & > img {
    height: 100%;
  }
`;

export const SuccessItem = styled.span`
  position: relative;

  width: 24px;
  height: 24px;

  border-radius: 100%;

  background: ${({ theme }) => theme.primaryColor};

  ${CheckMark}
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

export const ScsText = styled.span`
  color: ${({ theme }) => theme.primaryColor};
`;

export const ErrorText = styled.span`
  color: ${({ theme }) => theme.dangerColor};
`;

export const DefaultText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  color: ${({ theme }) => theme.smallHeader};
`;

export const WrapperBtn = styled.div`
  width: 100%;

  display: flex;
  gap: 32px;

  justify-content: space-between;
`;
