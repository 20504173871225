import { ModalPanel } from "src/components/shared/ModalPanel";
import { Content } from "src/components/shared/ModalPanel/style";
import { hexToRgb } from "src/helpers/colors";
import styled, { css } from "styled-components";

interface ToggleLabelProps {
  checked: boolean;
}

export type SideWrapperProps = {
  align?: "flex-end" | "flex-start" | "center";
};

export type HighlightProps = {
  isHighlighted?: boolean;
};

export const HighlightMixin = css<HighlightProps>`
  ${({ theme, isHighlighted }) => {
    if (!isHighlighted) return;
    return `
    box-shadow: 0 0 5px 2px ${hexToRgb(theme.primaryColor, 0.3)};
  `;
  }}
`;

export const SelectItem = styled.span<HighlightProps>`
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
  font-weight: 600;
  ${HighlightMixin}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AccessForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;

  & > div:first-of-type {
    margin-right: 27px;
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;

    & > div:first-of-type {
      margin-right: 0px;

      margin-bottom: 27px;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: end;

  & > div {
    margin-right: 10px;
  }
`;

export const Column = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const ToggleLabel = styled.label<ToggleLabelProps>`
  width: max-content;

  display: flex;

  font-weight: 600;
  color: ${({ theme, checked }) => (checked ? theme.primaryColor : "#767676")};
  cursor: pointer;

  & > div {
    margin-right: 10px;
  }
`;

export const SideWrapper = styled.div<SideWrapperProps>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: ${({ align = "flex-end" }) => align};

  gap: 27px;
  margin-top: 20px;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  height: 0;
  flex-grow: 1;

  ::-webkit-scrollbar {
    width: 3px;
  }

  @media screen and (max-width: 450px) {
    height: 80px;
  }
`;

export const FlexModalPanel = styled(ModalPanel)`
  display: flex;
  flex-direction: column;

  & ${Content} {
    flex-grow: 1;
  }

  @media screen and (max-width: 450px) {
    height: 521px;
  }
`;

export const FormContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormRow = styled.div`
  width: 100%;

  display: flex;
  gap: 27px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;

  display: flex;
  gap: 27px;

  margin-top: 37px;
`;

export const GridContentWrapper = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 27px;
  grid-row-gap: 20px;

  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`;
