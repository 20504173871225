import * as styles from "../../shared/style";

export interface HeadTooltipProps {
  id: string;
  amountAllOrders?: number;
  amountSellOrders?: number;
  amountBuyOrders?: number;
}

export const HeadTooltip = ({
  id,
  amountAllOrders,
  amountBuyOrders,
  amountSellOrders,
}: HeadTooltipProps) => (
  <styles.HeadTooltip positionStrategy="fixed" place="top" id={id} variant="info">
    <styles.TooltipRow>{`All orders: ${amountAllOrders}`}</styles.TooltipRow>

    {amountSellOrders ? (
      <styles.TooltipRow>{`Sell orders: ${amountSellOrders}`}</styles.TooltipRow>
    ) : null}

    {amountBuyOrders ? (
      <styles.TooltipRow>{`Buy orders: ${amountBuyOrders}`}</styles.TooltipRow>
    ) : null}
  </styles.HeadTooltip>
);
