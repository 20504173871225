import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { observer } from "mobx-react-lite";
import DEXV2DashboardIcons from "src/icons/DEXV2DashboardIcons";
import { formatFiatAmount } from "src/state/DEXV2/DEXV2Swap/utils";
import { useSwapWidgetState } from "../../hooks/useSwapWidgetState";
import * as styles from "./style";

const getGasMessage = (gasEstimateUSD?: CurrencyAmount<Token>) => {
  const formattedPriceUSD = formatFiatAmount(gasEstimateUSD);
  return `GAS: ${formattedPriceUSD}`;
};

export interface GasEstimateInfoProps {}

export const GasEstimateInfo = observer((_props: GasEstimateInfoProps) => {
  const state = useSwapWidgetState();
  const { swapInfoState } = state;

  const {
    trade: { gasEstimateUSD },
  } = swapInfoState.info;

  const message = getGasMessage(gasEstimateUSD);

  return (
    <styles.GasPriceWrapper>
      {DEXV2DashboardIcons.gas()}
      <styles.GasPriceSpan>{message}</styles.GasPriceSpan>
    </styles.GasPriceWrapper>
  );
});
