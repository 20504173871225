import { Column } from "@tanstack/table-core";
import { Tooltip } from "react-tooltip";
import { CheckBox } from "src/components/shared/CheckBox";
import { Label } from "src/components/shared/CheckBox/style";
import { ScrollSize } from "src/components/shared/shared";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRow,
} from "src/components/shared/Table";
import { Abilities } from "src/modules/abilities";
import { OrderSideType } from "src/modules/exchange/trade";
import styled, { css } from "styled-components";

interface HeaderProps {
  column?: any;
  abilities: Abilities;
  party: string;
}

interface NewHeaderProps {
  column: Column<unknown, unknown>;
  abilities: Abilities;
  party: string;
}

interface CellProps {
  abilities: Abilities;
  party: string;
}

interface SideProps {
  side: OrderSideType;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  overflow-y: scroll;

  ${ScrollSize}
`;

export const OrdersTable = styled(Table)`
  display: table;
`;

export const Head = styled(TableHead)`
  position: sticky;
  top: 0;

  background-color: ${({ theme }) => theme.bgSettingsPnl};
  z-index: 1;
`;

export const Body = styled(TableBody)`
  height: calc(100% - 32px);
  padding-top: 5px;

  overflow-y: inherit;
`;

export const Row = styled(TableRow)`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
`;

export const HeaderRow = styled(TableHeadRow)`
  width: 100%;
`;

export const Header = styled(TableHeader)<HeaderProps>`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  user-select: none;

  @media screen and (max-width: 1100px) {
    font-size: 10px;
  }

  ${({ column }) => css`
    ${column.isSorted &&
    css`
      ::after {
        color: ${({ theme }) => theme.primaryColor};
        content: "${column.isSortedDesc ? "▼" : "▲"}";
        position: absolute;
      }
    `}
    ${column.canSort &&
    css`
      cursor: pointer;
    `}
  `}
`;

export const NewTestHeader = styled(TableHeader)<NewHeaderProps>`
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
  user-select: none;

  @media screen and (max-width: 1100px) {
    font-size: 10px;
  }

  ${({ column }) => css`
    ${column.getIsSorted() &&
    css`
      ::after {
        color: ${({ theme }) => theme.primaryColor};
        content: "${column.getIsSorted() === "desc" ? "▼" : "▲"}";
        position: absolute;
      }
    `}
    ${column.getCanSort() &&
    css`
      cursor: pointer;
    `}
  `}
`;

export const HeadCheckBox = styled(CheckBox)`
  & > label:first-of-type {
    border: ${({ theme }) => `1px solid ${theme.primaryColor}`};
  }

  & > input:checked + ${Label}::before {
    left: 5px;
    top: 2.5px;
  }
`;

export const Cell = styled(TableCell)<CellProps>`
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  /* font-family: "Roboto"; */
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: #767676;

  @media screen and (max-width: 950px) {
    font-size: 8px;
  }

  @media screen and (max-width: 880px) {
    font-size: 10px;
  }

  &:last-child > button {
    text-decoration: none;
  }
`;

export const Side = styled.span<SideProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 2px;

  color: ${({ theme, side }) => (side === "BUY" ? theme.successColor : theme.dangerColor)};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const Text = styled.span`
  font-weight: bold;
`;

export const AmountWrapper = styled.span`
  cursor: help;
`;

export const HeadTooltip = styled(Tooltip)`
  display: flex !important;
  flex-direction: column !important;

  gap: 5px !important;

  z-index: 1;
`;

export const TooltipRow = styled.span``;
