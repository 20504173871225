import { mix } from "polished";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { roundToTwoDecimals } from "src/helpers/rounding";
import Icons from "src/icons/Icons";
import { useTheme } from "styled-components";
import { useDashboardColors } from "../../../../../shared/hooks/useDashboardColors";
import * as styles from "./style";

type TokenCorrelationDirection = "up" | "down" | "neutral";

const useTokenDirection = (value: number): TokenCorrelationDirection => {
  switch (Math.sign(value)) {
    case 1: {
      return "up";
    }
    case -1: {
      return "down";
    }
    default: {
      return "neutral";
    }
  }
};

const useBaseColor = (direction: TokenCorrelationDirection) => {
  const {
    accent: { rubyRed, darkLime },
    textHeading,
  } = useDashboardColors();

  switch (direction) {
    case "up": {
      return darkLime;
    }
    case "down": {
      return rubyRed;
    }
    case "neutral": {
      return textHeading;
    }
  }
};

const getMaxLimitRatio = (value: number, max: number) => Math.min(Math.abs(value / max), 1);

interface UseTokenCorrelationProps extends Required<TokenCorrelationCellOwnProps> {}

const useTokenCorrelation = ({ value, minValue, maxValue }: UseTokenCorrelationProps) => {
  const { bgSettingsPnl } = useTheme();

  const direction = useTokenDirection(value);
  const baseColor = useBaseColor(direction);

  const baseBackground = bgSettingsPnl;

  const fillRatio = useMemo(() => {
    if (value >= 0) {
      return getMaxLimitRatio(value, maxValue);
    }
    return getMaxLimitRatio(value, minValue);
  }, [maxValue, minValue, value]);

  const backgroundColor = useMemo(
    () =>
      // mix between 0 to 50% of the fillRatio to have some contrast for max value
      mix((fillRatio * 1) / 2, baseColor, baseBackground),
    [baseBackground, baseColor, fillRatio]
  );

  return { color: baseColor, backgroundColor, direction };
};

const useChipArrow = (direction: TokenCorrelationDirection) => {
  switch (direction) {
    case "up": {
      return Icons.arrowUpV2();
    }
    case "down": {
      return Icons.arrowDownV2();
    }
    default: {
      return null;
    }
  }
};

const useCorrelationValue = (value: number) => roundToTwoDecimals(value);

interface TokenCorrelationCellOwnProps {
  value?: number;
  minValue?: number;
  maxValue?: number;
}

export interface TokenCorrelationCellProps
  extends TokenCorrelationCellOwnProps,
    ComponentPropsWithoutRef<"div"> {
  value?: number;
  minValue?: number;
  maxValue?: number;
}

export const TokenCorrelationCell = ({
  value = 0,
  minValue = -1,
  maxValue = 1,
  ...props
}: TokenCorrelationCellProps) => {
  const formattedValue = useCorrelationValue(value);

  const { color, backgroundColor, direction } = useTokenCorrelation({
    value: formattedValue,
    minValue,
    maxValue,
  });

  const Arrow = useChipArrow(direction);

  return (
    <styles.StyledDirectionCell $color={color} $backgroundColor={backgroundColor} {...props}>
      {formattedValue}
      {Arrow}
    </styles.StyledDirectionCell>
  );
};
