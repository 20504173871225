import { makeAutoObservable, runInAction } from "mobx";
import { getStrategy } from "src/api/expertSystem";
import { logError } from "src/helpers/network/logger";
import { IStrategy } from "src/modules/expertSystem";
import { convertConditions } from "./shared/utils";

export default class StrategyInfoModalStore {
  private _strategy: IStrategy | undefined;

  private _isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get strategy() {
    if (!this._strategy) return;

    const convertStrategy = {
      ...this._strategy,
      conditions: convertConditions(this._strategy.conditions),
    };

    return convertStrategy as never as IStrategy;
  }

  get strategyStatus() {
    return this._strategy?.status;
  }

  get isLoading() {
    return this._isLoading;
  }

  getStrategy = (uuid: string) => {
    this._getStrategy(uuid);
  };

  private _getStrategy = async (uuid: string) => {
    runInAction(() => {
      this._isLoading = true;
    });

    try {
      const { isError, data } = await getStrategy(uuid);

      if (!isError) {
        runInAction(() => {
          this._strategy = data;
        });
      }
    } catch (error) {
      logError(error);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  destroy = () => {};
}
