import { observer } from "mobx-react-lite";
import { SettingsPanelProps } from "src/components/BotsContent/DEX_V2/Settings/shared/SettingsPanel";
import { DEXV2CreatingContext } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { WalletLabeledInput } from "../../../shared/WalletLabeledInput";
import { FormContent, SideSettingsPanel } from "../../shared";
import * as styles from "./style";

export interface BlacklistWalletsPanelProps
  extends Omit<SettingsPanelProps, "label" | "children"> {}

export const BlacklistWalletsPanel = observer((props: BlacklistWalletsPanelProps) => {
  const { blacklistWalletsState } = useLateInitContext(DEXV2CreatingContext);

  const saveBlacklistEnabled = Boolean(blacklistWalletsState.wallet);
  return (
    <SideSettingsPanel label="Blacklist Wallets" {...props}>
      <FormContent>
        <WalletLabeledInput
          label="Blacklist Wallet"
          addEnabled={saveBlacklistEnabled}
          onAdd={blacklistWalletsState.addWallet}
          value={blacklistWalletsState.wallet}
          onChange={blacklistWalletsState.setWallet}
          errorHint={blacklistWalletsState.walletErrors}
        />
        <styles.StyledWalletsList
          hashes={blacklistWalletsState.walletsAsFieldProps}
          onDeleteHash={blacklistWalletsState.deleteWallet}
        />
      </FormContent>
    </SideSettingsPanel>
  );
});
