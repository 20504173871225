import { PriceScaleMode, PriceScaleOptions } from "lightweight-charts";

export const SELECTION_CANVAS_TEXT = `Range Selection:

Click on the chart to set the starting point, then drag the mouse cursor to define the desired range. 
Release the mouse button to apply the range.
`;

export const TOOLTIP_DATA_TEXT = `Tooltip Data Copying:

Hover your mouse over a data point on the chart to display a tooltip.
Double-click while the tooltip is visible, the data from the tooltip will be copied to your clipboard.
`;

export const DEFAULT_MOBILE_TOOLTIP_QUERY = "(max-width: 500px)";

export const priceScaleOptionsLibDefaults: PriceScaleOptions = {
  autoScale: true,
  mode: PriceScaleMode.Normal,
  invertScale: false,
  alignLabels: true,
  borderVisible: true,
  borderColor: "#2B2B43",
  entireTextOnly: false,
  visible: false,
  ticksVisible: false,
  scaleMargins: {
    bottom: 0.1,
    top: 0.2,
  },
};
