import { EmptyTextLineHeight } from "src/components/shared/shared";
import { Headline1 } from "src/components/shared/Typography";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 50px 20px;
`;

export const Title = styled(Headline1)`
  ${EmptyTextLineHeight}

  margin-bottom: 20px;

  @media screen and (max-width: 700px) {
    font-size: 32px;
  }

  @media screen and (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSizes["25px"]};
  }
`;
