import { SettingsPanel } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel";
import { Content } from "src/components/BotsContent/CEX/CEXBotSettings/Settings/shared/SettingsPanel/style";
import styled from "styled-components";

export const VerifySettings = styled(SettingsPanel)`
  ${Content} {
    @media screen and (max-width: 950px) and (min-width: 650px) {
      justify-content: space-between;
    }
  }
`;
