import { ComponentPropsWithoutRef } from "react";
import { isFiniteNumber } from "src/helpers/math";
import { isDefined } from "src/helpers/utils";
import {
  PNLV2ColorProps,
  PNLV2ItemLabelProps,
  PNLV2ItemValueProps,
  usePNLV2ColorProps,
  usePNLV2ValueInfo,
} from "../PNLV2Item";
import { PNLV2ItemContainer, PNLV2ItemContainerProps } from "../PNLV2Item/style";
import { PNLV2Label } from "../shared/PNLV2Label";
import { PNLV2Value } from "../shared/PNLV2Value";

const getValueDirectionText = (value: number) => {
  switch (Math.sign(value)) {
    case 1:
      return "NET PROFIT";
    case -1:
      return "NET LOSS";
    default:
      return "-";
  }
};

interface UsePNLV2TextValueParams extends Pick<PNLV2TextItemProps, "value"> {}

const usePNLV2TextValue = ({ value }: UsePNLV2TextValueParams) => {
  if (!isDefined(value) || !isFiniteNumber(value)) return "-";

  const numValue = +value;

  const formattedValue = getValueDirectionText(numValue);

  return formattedValue;
};

export interface PNLV2TextItemOwnProps
  extends PNLV2ItemLabelProps,
    Pick<PNLV2ItemValueProps, "value">,
    PNLV2ColorProps,
    PNLV2ItemContainerProps {}

export interface PNLV2TextItemProps
  extends PNLV2TextItemOwnProps,
    ComponentPropsWithoutRef<"div"> {}

export const PNLV2TextItem = ({
  orientation,
  space,
  value,
  label,
  coloredLabel = true,
  ...props
}: PNLV2TextItemProps) => {
  const { colorProps, otherProps: rootProps } = usePNLV2ColorProps(props);

  const { color: valueColor } = usePNLV2ValueInfo({ ...colorProps, value });

  const formattedValue = usePNLV2TextValue({ value });

  return (
    <PNLV2ItemContainer $orientation={orientation} $space={space} {...rootProps}>
      {label && (
        <PNLV2Label colored={coloredLabel} color={valueColor}>
          {label}
        </PNLV2Label>
      )}
      <PNLV2Value color={valueColor}>{formattedValue}</PNLV2Value>
    </PNLV2ItemContainer>
  );
};
