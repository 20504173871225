import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";
import { TablePanel } from "../TablePanel";
import { TableContent } from "../TablePanel/TableContent";

export const HashesPanel = styled(TablePanel)`
  min-height: 0px;

  grid-area: hashes;
`;

export const HashesFallback = styled(CrashPanel)`
  grid-area: hashes;
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 10px;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 0px;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 7px;

  ${ScrollSize}
`;

interface HashTableProps {
  count: number;
}

export const HashTable = styled(TableContent)<HashTableProps>`
  grid-template-columns: ${({ count }) => `repeat(${count}, minmax(60px, 1fr))`};
  /* grid-template-columns:  */
  /* minmax(120px, 1.5fr) minmax(80px, 1fr)
    minmax(90px, 1fr) minmax(55px, 1fr); */
`;
