import { Chart, ChartArea, FontSpec, LineElement, Plugin } from "chart.js";
import { formatPNLDelta } from "src/components/shared/PNLV2/shared/utilts";
import { clipArea, drawText } from "src/config/chartjs/plugins/utils";
import { formatWithSign } from "src/helpers/string";
import { filterBoolean } from "src/helpers/utils/filterBoolean";

interface TextOptions {
  font?: Partial<FontSpec>;
  color?: string;
}

export type AllDepthTextPosition = "top" | "bottom";

export interface AllDepthPluginOptions {
  price?: {
    buyColor?: TextOptions["color"];
    sellColor?: TextOptions["color"];
    font?: TextOptions["font"];
  };
  level?: {
    display?: boolean;
    offset?: number;
  } & TextOptions;
  offset?: number;
  position?: AllDepthTextPosition;
}

declare module "chart.js" {
  interface PluginOptionsByType<TType extends ChartType> {
    allDepth?: AllDepthPluginOptions;
  }
}

const getDatasetsMeta = (chart: Chart) => {
  const meta = filterBoolean(
    chart.data.datasets.map((_dataset, index) => {
      const meta = chart.getDatasetMeta(index);
      if (!meta) return null;
      if (!chart.isDatasetVisible(index)) return null;
      return meta;
    })
  );

  return meta;
};

const getPriceDataset = (chart: Chart) => {
  const dataset = chart.data.datasets.find(({ hidden }) => hidden);

  return dataset || null;
};

interface IDrawPriceOptions {
  ctx: CanvasRenderingContext2D;
  area: ChartArea;
  data: LineElement[];
  price: number | null;
  level: string;
  buyColor: string;
  sellColor: string;
  priceFont: Partial<FontSpec>;
  offset: number;
  position: AllDepthTextPosition;
}

const drawPrice = ({
  ctx,
  area,
  data,
  price,
  level,
  offset,
  buyColor,
  sellColor,
  priceFont,
  position,
}: IDrawPriceOptions) => {
  if (!data.length || !price) return null;
  const priceText = formatPNLDelta(price, false, false);

  const textColor = +level > 0 ? buyColor : sellColor;

  const firstPoint = data[0].getCenterPoint();
  const lastPoint = data[data.length - 1].getCenterPoint();

  const x = (firstPoint.x + lastPoint.x) / 2;
  const y = position === "bottom" ? area.bottom + offset : area.top - offset;

  drawText({
    ctx,
    text: priceText,
    x,
    y,
    font: priceFont,
    color: textColor,
  });

  return { x, y };
};

interface IDrawLevelOptions {
  ctx: CanvasRenderingContext2D;
  level: string | undefined;
  x: number;
  y: number;
  font: Partial<FontSpec>;
  color: string;
  offset: number;
  position: AllDepthTextPosition;
}

const drawLevel = ({
  ctx,
  level,
  x,
  y: baseY,
  font,
  color,
  offset,
  position,
}: IDrawLevelOptions) => {
  if (!level) return;

  const formattedLevel = formatWithSign(level, level);

  const y = position === "bottom" ? baseY + offset : baseY - offset;

  drawText({
    ctx,
    text: formattedLevel,
    x,
    y,
    font,
    color,
  });
};

export const AllDepthPlugin: Plugin<"line"> = {
  id: "allDepth",
  afterDatasetsDraw: (chart, _args, options: AllDepthPluginOptions) => {
    const { price, level, offset = 15 } = options;
    const buyColor = price?.buyColor || "green";
    const sellColor = price?.sellColor || "red";
    const priceFont = price?.font || { size: 10, weight: "500" };

    const levelFont = level?.font || { size: 8, weight: "500" };
    const levelColor = level?.color || "grey";
    const showLevel = level?.display ?? true;
    const levelOffset = level?.offset ?? 2;
    const position = options.position || "bottom";

    const { ctx, chartArea: area } = chart;

    const datasetsMeta = getDatasetsMeta(chart);

    if (!datasetsMeta.length) return;

    const priceDataset = getPriceDataset(chart);

    if (!priceDataset) return;

    const levels = chart.data.labels as string[] | undefined;
    if (!levels?.length) return;

    clipArea(ctx, {
      x: area.left,
      y: area.top,
      width: area.width,
      height: area.height + offset * 2,
    });

    datasetsMeta.forEach((meta, index) => {
      const data = meta.data as any as LineElement[];

      const price = priceDataset.data[index] as number | null;

      const level = levels[index];

      const priceCoords = drawPrice({
        ctx,
        area,
        data,
        price,
        level,
        offset,
        buyColor,
        sellColor,
        priceFont,
        position,
      });

      if (showLevel && priceCoords) {
        const { x, y } = priceCoords;
        const priceFontSize = priceFont.size ?? 10;
        drawLevel({
          ctx,
          level,
          x,
          y,
          font: levelFont,
          color: levelColor,
          offset: priceFontSize + levelOffset,
          position,
        });
      }
    });
  },
};
