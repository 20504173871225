import { observer } from "mobx-react-lite";
import { checkParty } from "src/helpers/checkParty";
import { useAppState } from "../../state";

export interface PermissionProps {
  abilityName: string;
  party?: string;
}

export interface PermissionWrapperProps extends PermissionProps {
  children: JSX.Element;
  noPermissionsChildren?: JSX.Element;
}

export const PermissionWrapper = observer(
  ({ abilityName, party = "", children, noPermissionsChildren }: PermissionWrapperProps) => {
    const { userState } = useAppState();

    const checkPermsn = checkParty(userState.abilities, abilityName, party);

    // for only root pages
    if (abilityName === "root") {
      if (userState.abilities[abilityName]) {
        return children;
      }
    }

    // for only bot_manager pages
    if (abilityName === "bot-manager") {
      if (userState.abilities[abilityName]) {
        return children;
      }
    }

    // for only auth-view pages
    if (abilityName === "auth-view") {
      if (userState.abilities[abilityName]) {
        return children;
      }
    }

    if (abilityName === "auth-user-group-view") {
      if (userState.abilities[abilityName]) {
        return children;
      }
    }

    if (abilityName === "accounting-view") {
      if (userState.abilities[abilityName]) {
        return children;
      }
    }

    if (abilityName === "party-view") {
      if (userState.abilities[abilityName]) {
        return children;
      }
    }

    // for all abilities
    if (userState.abilities[abilityName]) {
      if (checkPermsn) return children;
    }

    return noPermissionsChildren ?? null;
  }
);
