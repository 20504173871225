import styled from "styled-components";
import { Body, HighlightCell } from "../../shared";

export const SellBody = styled(Body)`
  display: flex;
  flex-flow: column nowrap;

  margin-top: auto !important;
  /* use !important to prevent breakage from child margin settings */
`;

export const Amount = styled(HighlightCell)`
  text-align: right;
  padding-right: 5px;
`;

export const Total = styled(HighlightCell)`
  text-align: right;
`;
