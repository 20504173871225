import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${CardSurface}

  box-shadow: none;

  > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.dashboard.borderDefault};
  }
`;
