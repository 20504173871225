import React from "react";
import * as styles from "./style";
import { SettingsModules } from "./SettingsModules";
import { FooterPanel } from "./FooterPanel";

export const SettingsPanel = () => (
  <styles.Container>
    <SettingsModules />
    <FooterPanel />
  </styles.Container>
);
