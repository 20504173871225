import { TooltipDate } from "src/components/shared/Graph/shared/Tooltip/TooltipDate";
import styled from "styled-components";
import { TooltipSurface } from "../../../../TrackableTokens/ExchangesListItem/style";

export const Container = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 0px;

  ${TooltipSurface}

  display: none;

  width: 120px;
  min-height: 60px;

  pointer-events: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 5px;
`;

export const SeriesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Date = styled(TooltipDate)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  color: ${({ theme }) => theme.dashboard.textSubhead};
`;
