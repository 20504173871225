import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Headline3 } from "src/components/shared/Typography";
import { PartyInfoStore } from "src/state/UserManager/EditParty/Info/PartyInfoStore";
import { ERRORS_MSGS } from "src/validation-schemas";
import * as styles from "../../../shared";
import { SaveInfoButton } from "./style";

const SLUG_VALIDATION = {
  value: /^[^-][a-z0-9-]+[^-]$/,
  message: "Invalid slug format",
};

export const SantimentInfo = ({ state }: { state: PartyInfoStore }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: state.editSantimentInfo });

  useEffect(() => {
    if (state.editSantimentInfo) reset({ ...state.editSantimentInfo });
  }, [state.editSantimentInfo, reset]);

  return (
    <>
      <Headline3>Santiment info</Headline3>

      <styles.AccessForm
        onKeyDown={(event) => event.key === "Enter" && event.preventDefault()}
        onSubmit={handleSubmit(state.submitSantimentInfoHandler)}
      >
        <styles.GridContentWrapper>
          <Controller
            name="slug"
            control={control}
            rules={{
              required: ERRORS_MSGS.isRequired,
              pattern: SLUG_VALIDATION,
            }}
            render={({ field }) => (
              <LabeledInput type="text" label="Slug" errorHint={errors.slug?.message} {...field} />
            )}
          />

          <Controller
            name="ticker"
            control={control}
            rules={{ required: ERRORS_MSGS.isRequired }}
            render={({ field }) => (
              <LabeledInput
                type="text"
                label="Ticker"
                errorHint={errors.ticker?.message}
                {...field}
              />
            )}
          />

          <SaveInfoButton stretched type="submit">
            Save information
          </SaveInfoButton>
        </styles.GridContentWrapper>
      </styles.AccessForm>
    </>
  );
};
