import { observer } from "mobx-react-lite";
import { ModalPanel } from "src/components/shared/ModalPanel";
import { UserManagerContext } from "src/context/UserManager/UserManager";
import { useLateInitContext } from "src/hooks/useLateInitContext";

export const EditHierarchy = observer(() => {
  const state = useLateInitContext(UserManagerContext);

  return (
    <ModalPanel
      label="Edit hierarchy"
      show={state.visiblePanel.showEditHierarchy}
      close={state.openPanel("showEditHierarchy")}
      loading={false}
    >
      <div />
    </ModalPanel>
  );
});
