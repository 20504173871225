import { HighlightMixin, HighlightProps } from "src/components/UserManagement/Access/shared";
import { CardSurface } from "src/components/shared/shared";
import styled from "styled-components";

export interface ListItemProps extends HighlightProps {}

export const ListItem = styled.li<ListItemProps>`
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 2px 0px 2px 20px;

  font-weight: ${({ theme }) => theme.fontWeights[400]};
  font-size: ${({ theme }) => theme.fontSizes.subHeading};
  color: ${({ theme }) => theme.inputTextColor};

  ${CardSurface}

  ${HighlightMixin}
`;
