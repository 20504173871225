import { TimeModeInfoItem } from "src/components/AllBots/Bots/DEXV2Bots/shared/ModeChip/ModeInfo/ModeInfoItem/TimeModeInfoItem";
import { ActivationIndicator } from "src/components/shared/Indicators";
import { formatElapsedTime, unixToDateFormat } from "src/helpers/dateUtils";
import { IStrategy } from "src/modules/expertSystem";
import { useParamsText } from "../../../../../shared/hooks/useParamText";
import { InfoColumn, InfoLabel, InfoRow, InfoUniqueName } from "../../../../../shared/style";
import { ModuleInfo } from "./ModuleInfo";
import * as styles from "./style";

export interface StrategyInfoCellsProps {
  strategy: IStrategy;
}

export const StrategyInfoCells = ({ strategy }: StrategyInfoCellsProps) => {
  const { status, created_at, expired_at, conditions, actions, ...otherParams } = strategy;
  const mainStrategyParams = useParamsText(otherParams);

  return (
    <>
      <InfoColumn>
        <styles.InfoCell>
          <InfoLabel>Status:</InfoLabel>
          <ActivationIndicator title={status} status={status} />
        </styles.InfoCell>

        <styles.InfoCell>
          <InfoLabel>Created at:</InfoLabel>
          {unixToDateFormat(created_at, "FullDate")}
        </styles.InfoCell>

        <styles.InfoCell>
          <InfoLabel>Expired at:</InfoLabel>
          <TimeModeInfoItem
            style={{ width: "auto", gap: 0 }}
            label=""
            time={unixToDateFormat(expired_at, "FullDate")}
            elapsedTime={formatElapsedTime(expired_at)}
          />
        </styles.InfoCell>
      </InfoColumn>

      <InfoRow>
        <InfoLabel>Other params:</InfoLabel>
        <InfoUniqueName>{mainStrategyParams}</InfoUniqueName>
      </InfoRow>

      <InfoRow>
        <InfoLabel>Conditions:</InfoLabel>
      </InfoRow>
      {conditions.map((el) => {
        const key = el.uuid + el.soft_params.key;
        return <ModuleInfo key={key} module={el} />;
      })}

      <InfoRow>
        <InfoLabel>Actions:</InfoLabel>
      </InfoRow>
      {actions.map((el, index) => {
        const key = el.uuid + index;
        return <ModuleInfo key={key} module={el} />;
      })}
    </>
  );
};
