import { FormProps } from "src/modules/shared";
import styled from "styled-components";

export const TradeForm = styled.form`
  position: relative;

  width: 100%;
  height: 146px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  opacity: ${({ disabled }: FormProps) => (disabled ? "0.3" : "1")};
`;
