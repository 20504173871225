import { observer } from "mobx-react-lite";
import React from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { BindingsBotContext } from "src/context/CEX/BindingsBotProvider";
import * as styles from "./style";

export const BindingsModules = observer(() => {
  const state = useLateInitContext(BindingsBotContext);

  return (
    <styles.Container>
      <styles.ReadOnlyInput
        label="Selected module"
        value={
          state.currentBindings.currentBinding
            ? `${state.currentBindings.currentBinding}: ${
                state.currentBindings.currentBinding === "bot"
                  ? state.getFullBotName(state.botBinding?.mainBot)
                  : state.currentBinding
              }`
            : "Not selected"
        }
        onChange={() => {}}
        readOnly
      />
    </styles.Container>
  );
});
