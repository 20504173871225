import styled from "styled-components";

interface Props {
  currentTab?: any;
}

export const Content = styled.div`
  position: relative;

  height: max-content;
  width: 100%;
  max-width: 1700px;
  padding: 10px;
  display: flex;
  flex-direction: ${({ currentTab }: Props) =>
    currentTab === "STATS" || currentTab === "EXCHANGE" ? "row" : "column"};
  background-color: inherit;
  align-items: center;
  flex: 1;

  @media (max-width: 376px) {
    height: 100%;
  }
`;
