import { observer } from "mobx-react-lite";
import React from "react";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DepthChartContext } from "src/context/CEX/Exchange/DepthChart";
import { calcRoundingValue, toRounding } from "src/helpers/rounding";
import { numFormatter } from "src/helpers/separation";
import * as styles from "./style";

export const VolumeScale = observer(() => {
  const state = useLateInitContext(DepthChartContext);

  return (
    <styles.Container>
      <styles.BuyTitle>
        {`${state.depthBuyPercent}%`} Buy{" "}
        <styles.OrderCount>{`(${state.buyDepth})`}</styles.OrderCount>{" "}
      </styles.BuyTitle>

      <styles.VolumeWrapper>
        <styles.BuyVolume style={{ width: `${state.buyVolumePercent}%` }}>
          <styles.BuyValue>{`${state.buyVolumePercent}% ${numFormatter(
            toRounding(state.allBuyVolume, calcRoundingValue(state.allBuyVolume))
          )}`}</styles.BuyValue>
        </styles.BuyVolume>

        <styles.SellVolume style={{ width: `${state.sellVolumePercent}%` }}>
          <styles.SellValue>{`${numFormatter(
            toRounding(state.allSellVolume, calcRoundingValue(state.allSellVolume))
          )} ${state.sellVolumePercent}%`}</styles.SellValue>
        </styles.SellVolume>
      </styles.VolumeWrapper>

      <styles.SellTitle>
        {" "}
        <styles.OrderCount>{`(${state.sellDepth})`}</styles.OrderCount> Sell{" "}
        {`+${state.depthSellPercent}%`}
      </styles.SellTitle>
    </styles.Container>
  );
});
