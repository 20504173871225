import styled from "styled-components";
import { BaseCEXPNLWidget } from "../../shared/BaseCEXPNLWidget";
import { StyledCard } from "../../shared/LayoutCEXPNL/PNLWithCharts/style";

export const FundingPnL = styled(BaseCEXPNLWidget)`
  grid-area: PnL;
  min-height: 250px;

  ${StyledCard} {
    box-shadow: ${({ theme }) => theme.shadows.dashboard};
  }
`;
