import { faker } from "@faker-js/faker";
import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { delay } from "src/helpers/utils";
import { BalancesTransactionsPoints } from "src/modules/dashboard";
import { ChartPoint } from "src/modules/shared";
import { IBaseDashboardV2StoreParams, IDashboardV2StateProvider } from "..";
import {
  GenerateTimeSeriesOptions,
  generateTime,
  generateTimeSeries,
  generateTransactionsPoints,
  pointsToSeriesData,
} from "../../../shared/mocks";
import { padSeriesData, rawSeriesToData, timeToMs } from "../../utils";
import { BaseWidgetV2Store, IDashboardV2WidgetState } from "./BaseWidgetV2Store";

interface IBalancesStoreParams extends IBaseDashboardV2StoreParams {}

type BalancesPoints = {
  time: number[];
  value: string[];
};

type BalancesData = {
  free: BalancesPoints;
  locked: BalancesPoints;
  price: BalancesPoints;
};

export type TransactionsData = BalancesTransactionsPoints;

const INITIAL_BALANCES_DATA: BalancesData = {
  free: {
    time: [],
    value: [],
  },
  locked: {
    time: [],
    value: [],
  },
  price: {
    time: [],
    value: [],
  },
};

export const INITIAL_TRANSACTIONS_DATA: TransactionsData = {
  time: [],
  value: [],
};

const pointsToBalancesData = (points: ChartPoint[]) => {
  const { time, value } = pointsToSeriesData(points);

  return { time, value: value.map((v) => v.toString()) };
};

const generateBalancesData = (): BalancesData => {
  const startTimestamp = generateTime();

  const pointsCount = faker.number.int({ min: 10, max: 30 });

  const generateSeriesOptions: GenerateTimeSeriesOptions = {
    step: {
      value: 1,
      unit: "day",
    },
    startTimestamp,
    count: pointsCount,
    value: {
      min: 0,
      max: 1000,
      precision: 0.01,
    },
  };

  const freeData = generateTimeSeries(generateSeriesOptions);

  const lockedData = generateTimeSeries(generateSeriesOptions);

  const priceData = generateTimeSeries(generateSeriesOptions);

  return {
    free: pointsToBalancesData(freeData),
    locked: pointsToBalancesData(lockedData),
    price: pointsToBalancesData(priceData),
  };
};

export type BalanceType = "total" | "quote" | "base";

export class BalancesV2Store implements IDashboardV2WidgetState {
  private _stateProvider: IDashboardV2StateProvider;

  private _balancesData: BalancesData = INITIAL_BALANCES_DATA;

  private _transactionsData: TransactionsData = INITIAL_TRANSACTIONS_DATA;

  private _currentType: BalanceType = "total";

  private _baseState: BaseWidgetV2Store;

  constructor({ stateProvider }: IBalancesStoreParams) {
    makeAutoObservable(this);

    this._stateProvider = stateProvider;

    this._baseState = new BaseWidgetV2Store({
      state: stateProvider,
      widgetState: this,
    });

    makeLoggable(this, { freeLocked: true, price: true, transactions: true });
  }

  private get _requestParams() {
    return this._stateProvider.getRequestParams();
  }

  private _setBalancesData = (data: BalancesData) => {
    this._balancesData = data;
  };

  private _setTransactionsData = (data: TransactionsData) => {
    this._transactionsData = data;
  };

  setCurrentType = (type: BalanceType) => {
    this._currentType = type;

    this.getStats();
  };

  private _setInitialData = () => {
    this._setBalancesData(INITIAL_BALANCES_DATA);
    this._setTransactionsData(INITIAL_TRANSACTIONS_DATA);
  };

  get currentType() {
    return this._currentType;
  }

  private get _lockedData() {
    return rawSeriesToData(this._balancesData.locked);
  }

  private get _locked() {
    const seriesData = this._lockedData;
    return padSeriesData(seriesData);
  }

  private get _freeData() {
    return rawSeriesToData(this._balancesData.free);
  }

  private get _free() {
    const seriesData = this._freeData;
    return padSeriesData(seriesData);
  }

  get freeLocked() {
    return {
      free: this._free,
      locked: this._locked,
    };
  }

  private get _price() {
    return rawSeriesToData(this._balancesData.price);
  }

  get price() {
    const seriesData = this._price;
    return padSeriesData(seriesData);
  }

  private get _transactions() {
    const { time, value } = this._transactionsData;
    return { time: timeToMs(time), value };
  }

  get transactions() {
    return padSeriesData(this._transactions);
  }

  get startBalances() {
    const freeStart = this._freeData.value[0] ?? 0;
    const lockedStart = this._lockedData.value[0] ?? 0;
    return { free: freeStart, locked: lockedStart };
  }

  get endBalances() {
    const freeValues = this._freeData.value;
    const freeEnd = freeValues[freeValues.length - 1] ?? 0;

    const lockedValues = this._lockedData.value;
    const lockedEnd = lockedValues[lockedValues.length - 1] ?? 0;

    return { free: freeEnd, locked: lockedEnd };
  }

  get deltaBalances() {
    const { startBalances } = this;
    const { endBalances } = this;
    const lockedDelta = endBalances.locked - startBalances.locked;
    const freeDelta = endBalances.free - startBalances.free;
    return { free: freeDelta, locked: lockedDelta };
  }

  get loading() {
    return this._baseState.loading;
  }

  onStatsUpdate = async () => {
    const requestParams = this._requestParams;
    if (!requestParams) return;

    this._setInitialData();
    try {
      await delay(300);
      const balancesData = generateBalancesData();
      const transactionsData = generateTransactionsPoints(balancesData.free.time);

      this._setBalancesData(balancesData);
      this._setTransactionsData(transactionsData);
    } catch {
      this._setInitialData();
    }
  };

  getStats = async () => {
    await this._baseState.getStats();
  };

  subscribe = () => {};

  destroy = () => {};
}
