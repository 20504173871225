import { makeAutoObservable, runInAction } from "mobx";
import { getPartyBots } from "src/api/userManager/partiesAPI";
import { joinMarket, joinMarketBotName } from "src/helpers/botName";
import { IPartyBot } from "../botModules";

export interface IBotListConsumer {
  party: string;
}

class BotListStore {
  private _mainState: IBotListConsumer;

  private _partyBots: IPartyBot[] = [];

  constructor(state: IBotListConsumer) {
    makeAutoObservable(this);
    this._mainState = state;
  }

  get botList() {
    return this._partyBots
      .map((bot) => ({
        market: joinMarket({
          quote: bot.quote,
          base: bot.base,
          exchange: bot.exchange,
        }),
        ...bot,
      }))
      .map((bot) => ({
        value: bot.bot_uuid,
        label: joinMarketBotName(bot.market, bot.name),
      }));
  }

  private get _party() {
    return this._mainState.party;
  }

  getBotList = () => {
    this._getBotList(this._party);
  };

  getSelectBot = (uuid: string) => ({ value: uuid, label: this.getFullBotName(uuid) });

  getFullBotName(uuid: string) {
    const findBot = this._partyBots.find((bot) => bot.bot_uuid === uuid);

    if (findBot) {
      const market = joinMarket({
        quote: findBot.quote,
        base: findBot.base,
        exchange: findBot.exchange,
      });

      return joinMarketBotName(market, findBot.name);
    }

    return "";
  }

  private _getBotList = async (party: string) => {
    try {
      const { data, isError } = await getPartyBots(party);

      if (!isError) {
        runInAction(() => {
          this._partyBots = data;
        });
      } else this._resetPartyBots();
    } catch {
      this._resetPartyBots();
    }
  };

  private _resetPartyBots = () => {
    this._partyBots = [];
  };

  destroy = () => {};
}

export default BotListStore;
