import { makeAutoObservable, runInAction } from "mobx";
import { getChangeEventValueNumb } from "src/helpers/forms/inputs";
import { toRounding } from "src/helpers/rounding";
import ExchangeStore from ".";

export interface IDepthChartPoint {
  amount: number;
  price: number;
  volume: number;
  total: number;
}

class DepthChartStore {
  marketDepth: number | "" = 100;

  typeScale: string = "linear";

  showBuyOrders = true;

  showSellOrders = true;

  typeVolume = "quote";

  mainState: ExchangeStore;

  constructor(state: ExchangeStore) {
    this.mainState = state;

    makeAutoObservable(this);
  }

  private get _buyOrders(): IDepthChartPoint[] {
    const orders = this.mainState.orderBookState.buyOrders.slice();
    const depthOrders = [];
    let reduceVolume = 0;

    for (const el of orders) {
      if (this.typeVolume === "quote") reduceVolume += +el.total;
      if (this.typeVolume === "base") reduceVolume += +el.amount;

      depthOrders.push({ ...el, volume: reduceVolume });
    }

    return depthOrders.reverse().map((el) => ({
      amount: +el.amount,
      price: +el.price,
      volume: el.volume,
      total: +el.total,
    }));
  }

  private get _sellOrders(): IDepthChartPoint[] {
    const orders = this.mainState.orderBookState.sellOrders.slice().reverse();
    const depthOrders = [];
    let reduceVolume = 0;

    for (const el of orders) {
      if (this.typeVolume === "quote") reduceVolume += +el.total;
      if (this.typeVolume === "base") reduceVolume += +el.amount;

      depthOrders.push({ ...el, volume: reduceVolume });
    }

    return depthOrders.map((el) => ({
      amount: +el.amount,
      price: +el.price,
      volume: el.volume,
      total: +el.total,
    }));
  }

  get buyOrders() {
    return this._buyOrders.slice(-this.buyDepth);
  }

  get sellOrders() {
    return this._sellOrders.slice(0, this.sellDepth);
  }

  get buyDepth() {
    const { length } = this._buyOrders;
    const depth = length;

    if (this.marketDepth) return Math.round((depth * this.marketDepth) / 100);

    return depth;
  }

  get sellDepth() {
    const { length } = this._sellOrders;
    const depth = length;

    if (this.marketDepth) return Math.round((depth * this.marketDepth) / 100);

    return depth;
  }

  get maxYCoord() {
    let maxCoord = 1;

    if (this.showBuyOrders && !this.showSellOrders) {
      maxCoord = this.allBuyVolume;
    } else if (!this.showBuyOrders && this.showSellOrders) {
      maxCoord = this.allSellVolume;
    } else {
      maxCoord = Math.max(this.allBuyVolume, this.allSellVolume);
    }

    return Math.round(maxCoord + maxCoord * 0.05);
  }

  get minYCoord() {
    if (this.typeScale === "linear") return 0;

    if (this.showBuyOrders && !this.showSellOrders) {
      return this.minBuyTotal;
    }
    if (!this.showBuyOrders && this.showSellOrders) {
      return this.minSellTotal;
    }
    return Math.min(this.minBuyTotal, this.minSellTotal);
  }

  get maxXCoord() {
    if (!this.sellOrders.length && this.buyOrders.length) return this.maxBuyPrice;

    if (this.sellOrders.length) {
      if (this.showSellOrders) return +this.sellOrders[this.sellOrders.length - 1].price;

      return this.maxBuyPrice;
    }

    return 1;
  }

  get minXCoord() {
    if (this.sellOrders.length && !this.buyOrders.length) return this.minSellPrice;

    if (this.buyOrders.length) {
      if (this.showBuyOrders) return this.minBuyPrice;

      return this.minSellPrice;
    }

    return 1;
  }

  get depthBuyPercent() {
    if (!this.buyOrders.length) return 0;

    const maxPrice = this.maxBuyPrice;
    const minPrice = this.minBuyPrice;

    return toRounding(((minPrice - maxPrice) / maxPrice) * 100, 2);
  }

  get depthSellPercent() {
    if (!this.sellOrders.length) return 0;

    const maxPrice = this.maxSellPrice;
    const minPrice = this.minSellPrice;

    return toRounding(((maxPrice - minPrice) / minPrice) * 100, 2);
  }

  get quote() {
    return this.mainState.quote;
  }

  get base() {
    return this.mainState.base;
  }

  get totalTicker() {
    if (this.typeVolume === "quote") return this.quote;

    return this.base;
  }

  get overallVolume() {
    return this.allBuyVolume + this.allSellVolume;
  }

  get buyVolumePercent() {
    if (this.buyOrders.length) return toRounding((this.allBuyVolume / this.overallVolume) * 100, 2);

    return 0;
  }

  get sellVolumePercent() {
    if (this.sellOrders.length)
      return toRounding((this.allSellVolume / this.overallVolume) * 100, 2);

    return 0;
  }

  get allBuyVolume() {
    if (this.buyOrders.length) return this.buyOrders[0].volume;

    return 0;
  }

  get allSellVolume() {
    if (this.sellOrders.length) return this.sellOrders[this.sellOrders.length - 1].volume;

    return 0;
  }

  get minBuyTotal() {
    if (this.buyOrders.length) return +toRounding(+this.buyOrders[0].total, 0);

    return 0;
  }

  get minSellTotal() {
    if (this.sellOrders.length) return +toRounding(+this.sellOrders[0].total, 0);

    return 0;
  }

  get minBuyPrice() {
    if (this.buyOrders.length) return +this.buyOrders[0].price;

    return 0;
  }

  get maxBuyPrice() {
    if (this.buyOrders.length) return +this.buyOrders[this.buyOrders.length - 1].price;

    return 0;
  }

  get minSellPrice() {
    if (this.sellOrders.length) return +this.sellOrders[0].price;

    return 0;
  }

  get maxSellPrice() {
    if (this.sellOrders.length) return +this.sellOrders[this.sellOrders.length - 1].price;

    return 0;
  }

  getMarketDepthHandler = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      this.marketDepth = getChangeEventValueNumb(e);
    });
  };

  getTypesScalesHandler = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      this.typeScale = String(e.target.value);
    });
  };

  getTypesVolumeHandler = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      this.typeVolume = String(e.target.value);
    });
  };

  getShowOrdersHandler =
    (key: "showBuyOrders" | "showSellOrders") => (e: React.ChangeEvent<HTMLInputElement>) => {
      runInAction(() => {
        this[key] = e.target.checked;
      });
    };
}

export default DepthChartStore;
