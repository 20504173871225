import { SelectPanel as FixedSelectPanel } from "src/components/shared/Forms/Selectors/SelectionPanel/SelectPanel";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { Headline3 } from "src/components/shared/Typography";
import { Wrapper } from "src/components/UserManagement/Access/shared";
import styled from "styled-components";

export const FlexWrapper = styled(Wrapper)`
  flex-grow: 1;
`;

export const SelectPanel = styled(FixedSelectPanel)`
  height: auto;
`;

export const ColumnTitle = styled(Headline3)`
  margin-bottom: 28px;
`;

export const ScopesContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 14px;
  padding-right: 4px;
`;

export const ScopeInput = styled(LabeledInput)`
  @media screen and (max-width: 450px) {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const AddBtnWrapper = styled.div`
  width: 100%;

  display: flex;
`;
