import { observer } from "mobx-react-lite";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { LabeledSelector } from "src/components/shared/Forms/Selectors";
import { Loader } from "src/components/shared/Loader";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { CheckBox } from "src/components/shared/CheckBox";
import * as styles from "./style";

const sideOptions = [
  { label: "BUY", value: "BUY" },
  { label: "SELL", value: "SELL" },
];

export const LiquidityForm = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);
  const { liquidityState } = mainState;

  const currentSide = {
    value: liquidityState.data.side,
    label: liquidityState.data.side ? liquidityState.data.side : "",
  };

  return (
    <styles.Form
      onSubmit={liquidityState.submitHandler()}
      onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
      disabled={!mainState.isOriginPair}
    >
      <Loader show={liquidityState.showLoader} />

      <styles.SelectColumn>
        <LabeledSelector
          isDisabled={!mainState.isOriginPair}
          label="Account"
          value={liquidityState.currentNameAcc}
          onChange={liquidityState.selectorHandler("account_uuid")}
          // @ts-ignore
          getError={liquidityState.getError("account_uuid")}
          errorHint={liquidityState.errors.account_uuid}
          options={liquidityState.accounts}
        />
        <LabeledSelector
          isDisabled={!mainState.isOriginPair}
          label="Side"
          value={currentSide}
          onChange={liquidityState.selectorHandler("side")}
          // @ts-ignore
          getError={liquidityState.getError("side")}
          errorHint={liquidityState.errors.side}
          options={sideOptions}
        />
        <LabeledInput
          readOnly={!mainState.isOriginPair}
          label="Number of order"
          type="number"
          step="any"
          value={liquidityState.data.number}
          onChange={liquidityState.getHandler("number")}
          // @ts-ignore
          getError={liquidityState.getError("number")}
          errorHint={liquidityState.errors.number}
        />
      </styles.SelectColumn>

      <styles.ColumnWrapper>
        <LabeledInput
          readOnly={!mainState.isOriginPair}
          label="Quote max"
          type="number"
          step="any"
          value={liquidityState.data.assetMax}
          onChange={liquidityState.getHandler("assetMax")}
          // @ts-ignore
          getError={liquidityState.getError("assetMax")}
          errorHint={liquidityState.errors.assetMax}
        />
        <LabeledInput
          readOnly={!mainState.isOriginPair}
          label="Quote min"
          type="number"
          step="any"
          value={liquidityState.data.assetMin}
          onChange={liquidityState.getHandler("assetMin")}
          // @ts-ignore
          getError={liquidityState.getError("assetMin")}
          errorHint={liquidityState.errors.assetMin}
        />

        <styles.CheckBoxWrapper>
          <CheckBox
            disabled={!mainState.isOriginPair}
            label="Curve mode"
            checked={liquidityState.curve}
            onChange={liquidityState.checkBoxHandler()}
          />
        </styles.CheckBoxWrapper>
      </styles.ColumnWrapper>

      <styles.ColumnWrapper>
        <LabeledInput
          readOnly={!mainState.isOriginPair}
          label="Price max"
          type="number"
          step="any"
          value={liquidityState.data.priceMax}
          onChange={liquidityState.getHandler("priceMax")}
          // @ts-ignore
          getError={liquidityState.getError("priceMax")}
          errorHint={liquidityState.errors.priceMax}
        />
        <LabeledInput
          readOnly={!mainState.isOriginPair}
          label="Price min"
          type="number"
          step="any"
          value={liquidityState.data.priceMin}
          onChange={liquidityState.getHandler("priceMin")}
          // @ts-ignore
          getError={liquidityState.getError("priceMin")}
          errorHint={liquidityState.errors.priceMin}
        />

        <styles.BtnWrapper>
          <SubmitButton size="m" disabled={!mainState.isOriginPair} />
        </styles.BtnWrapper>
      </styles.ColumnWrapper>
    </styles.Form>
  );
});
