import { memo } from "react";
import { GroupBase } from "react-select";
import { LabeledSelectorProps } from "src/components/shared/Forms/Selectors";
import { SelectorValue } from "src/modules/shared";
import * as styles from "./style";

export interface AccountSelectorProps
  extends Omit<
    LabeledSelectorProps<SelectorValue, false, GroupBase<SelectorValue>>,
    "label" | "placeholder" | "menuPosition" | "isClearable"
  > {
  fixed?: boolean;
}

export const AccountSelector = memo(({ fixed, ...props }: AccountSelectorProps) => {
  const fixedProps = fixed
    ? {
        menuPosition: "fixed" as const,
        menuShouldBlockScroll: true,
      }
    : {};
  return (
    <styles.Selector label="Exchange account" placeholder="accounts" {...fixedProps} {...props} />
  );
});
