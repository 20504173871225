import { makeAutoObservable } from "mobx";
import { openFloatingOrder } from "src/api/bots/CEX/exchange";
import { showSuccessMsg } from "src/helpers/message";
import { logError } from "src/helpers/network/logger";
import { FloatingOrder, FloatingOrderParams, OrderSideType } from "src/modules/exchange/trade";
import ExchangeStore from "../../..";

export class CreateFloatingOrderStore {
  private _mainState: ExchangeStore;

  private _isLoading = false;

  constructor(state: ExchangeStore) {
    this._mainState = state;

    makeAutoObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }

  private get _lastPrice() {
    const { lastTrade } = this._mainState;

    if (lastTrade) return parseFloat(lastTrade.price);

    return 0;
  }

  private get _currentAccId() {
    return this._mainState.currentAccID;
  }

  private get _pair() {
    return this._mainState.pair;
  }

  private _setLoading = (bool: boolean) => {
    this._isLoading = bool;
  };

  calcTotalQuote = (side: OrderSideType, pricePercent: number, amount: number) => {
    const percent = this._calcPercent(side, pricePercent);

    const total = this._lastPrice * percent * amount;

    return total;
  };

  calcAmount = (side: OrderSideType, pricePercent: number, totalQuote: number) => {
    const percent = this._calcPercent(side, pricePercent);

    const amount = totalQuote / (this._lastPrice * percent);

    return amount;
  };

  private _calcPercent = (side: OrderSideType, pricePercent: number) => {
    let percent = 0;

    if (side === "BUY") {
      percent = 1 - pricePercent / 100;
    } else {
      percent = 1 + pricePercent / 100;
    }

    return percent;
  };

  submitFloatingOrderHandler = (side: OrderSideType) => async (order: FloatingOrderParams) => {
    const requestData: FloatingOrder = {
      ...order,
      account_uuid: this._currentAccId,
      pair: this._pair,
      side,
    };

    this._setLoading(true);

    try {
      const { isError } = await openFloatingOrder({ data: requestData });
      if (!isError) {
        showSuccessMsg("Application successfully processed");
        this._mainState.updFloatingTradingData();
      }
    } catch (error) {
      logError(error);
    } finally {
      this._setLoading(false);
    }
  };

  destroy = () => {};
}
