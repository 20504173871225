import useMediaQuery from "src/hooks/useMediaQuery";
import { GraphRootProps } from "..";

interface UseTooltipShowParams
  extends Required<Pick<GraphRootProps, "showTooltip" | "tooltipQuery">> {}

export const useTooltipShow = ({ tooltipQuery, showTooltip }: UseTooltipShowParams) => {
  const isMobileTooltip = useMediaQuery(tooltipQuery);
  return isMobileTooltip ? false : showTooltip;
};
