import Icons from "src/icons/Icons";
import { IconButton, IconButtonProps } from "../../../IconButton";

export interface CopyButtonProps extends IconButtonProps {}

export const CopyButton = (props: CopyButtonProps) => (
  <IconButton boxed {...props}>
    <Icons.Copy />
  </IconButton>
);
