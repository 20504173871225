import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ModalPanel, ModalProps } from "src/components/shared/ModalPanel";
import { TransferModalContent, TransferModalContentProps } from "./TransferModalContent";

export interface ModalTransferParams
  extends Omit<TransferModalContentProps, "onClose" | "setLoading"> {}

export interface ModalTransferProps extends ModalProps, ModalTransferParams {}

export const ModalTransfer = observer(
  ({ show, close, fromVault, deployerId, tradePair, withdrawer }: ModalTransferProps) => {
    const [loading, setLoading] = useState(false);

    const contentProps: TransferModalContentProps = {
      onClose: close,
      fromVault,
      setLoading,
      deployerId,
      tradePair,
      withdrawer,
    };
    return (
      <ModalPanel
        label={`Transfer from ${fromVault?.type.toUpperCase()} vault`}
        show={show}
        close={close}
        loading={loading}
        zIndex={1000}
      >
        <TransferModalContent {...contentProps} />
      </ModalPanel>
    );
  }
);
