import { forwardRef } from "react";
import { ExpandIcon, ExpandIconProps } from "src/components/shared/Buttons/v2/DropdownButton/style";
import { SaveButtonProps } from "src/components/shared/Buttons/v2/variants/SaveButton";
import * as styles from "./style";

export interface SaveDropdownButtonProps
  extends Omit<SaveButtonProps, "ownRef" | "showText" | "endIcon" | "startIcon">,
    ExpandIconProps {}

export const SaveDropdownButton = forwardRef<HTMLButtonElement, SaveDropdownButtonProps>(
  ({ open, ...props }, ref) => (
    <styles.StyledSaveButton
      type="button"
      showText={false}
      endIcon={<ExpandIcon $open={open} />}
      ownRef={ref}
      {...props}
    />
  )
);
