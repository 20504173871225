import { ContentPanel } from "../../../../shared";
import { PanelWrapper } from "../../../../shared/style";
import { ActionList } from "./ActionList";
import { FormsArea } from "./FormsArea";

export const ActionsStep = () => (
  <PanelWrapper>
    <ContentPanel title="Available actions">
      <ActionList />
    </ContentPanel>
    <ContentPanel title="Added actions in strategy">
      <FormsArea />
    </ContentPanel>
  </PanelWrapper>
);
