import { AreaData } from "lightweight-charts";
import { ComponentPropsWithoutRef } from "react";
import { Loader } from "src/components/shared/Loader";
import { noOp } from "src/helpers/utils";
import { ChartPoint } from "src/modules/shared";
import { BaseGraphsProps, priceFormatter } from "..";
import { BASE_STATS_SERIES_PROPS, StatsGraph } from "../StatsGraph";
import * as styles from "../shared";

export type NativeGraphData = ChartPoint[];

interface NativeGraphProps extends BaseGraphsProps, ComponentPropsWithoutRef<"div"> {
  data: NativeGraphData;
  ticker: string;
  nativePrecision?: number;
}

export const NativeGraph = ({
  data,
  isLoading,
  onRefresh = noOp,
  ticker,
  nativePrecision,
  ...props
}: NativeGraphProps) => (
  <styles.GraphContainer {...props}>
    <StatsGraph
      series={[
        {
          ...BASE_STATS_SERIES_PROPS,
          type: "Area",
          data: data as AreaData[],
          title: ticker,
          options: {
            priceFormat: {
              type: "custom",
              formatter: priceFormatter(nativePrecision || 0),
            },
          },
        },
      ]}
      request={onRefresh}
    />
    <Loader show={isLoading} />
  </styles.GraphContainer>
);
