import { useMemo } from "react";
import { Loader } from "src/components/shared/Loader";
import { SeparatorNum } from "src/components/shared/Separator";
import { Balance } from "src/modules/exchange/balance";
import * as styles from "./style";

interface BalancesTableProps {
  data: Balance[];
  loading: boolean;
  selectAssets?: string[];
}

export const BalancesTable = ({ data, loading, selectAssets = [] }: BalancesTableProps) => {
  const balances = useMemo(
    () => data.filter((el) => (selectAssets.length ? selectAssets.includes(el.asset) : true)),
    [data, selectAssets]
  );

  return (
    <styles.BalancesContainer>
      <styles.BalancesTable>
        <styles.Head>
          <styles.HeadRow>
            <styles.QuoteHeader />
            <styles.HeadCell>Free</styles.HeadCell>
            <styles.HeadCell>Locked</styles.HeadCell>
          </styles.HeadRow>
        </styles.Head>
        <styles.TableBody>
          {balances.map(({ asset, free, locked }) => (
            <styles.Row key={asset}>
              <styles.QuoteCell>{asset}</styles.QuoteCell>

              <styles.TableCell>
                <SeparatorNum value={free} />
              </styles.TableCell>

              <styles.TableCell>
                <SeparatorNum value={locked} />
              </styles.TableCell>
            </styles.Row>
          ))}
        </styles.TableBody>
      </styles.BalancesTable>

      <Loader show={loading} />
    </styles.BalancesContainer>
  );
};
