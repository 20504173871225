import { CurrencyAmount, Token } from "@uniswap/sdk-core";
import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { Field } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/SwapStateStore";
import { formatCurrencyAmount } from "src/state/DEXV2/DEXV2Swap/utils";
import { useSwapWidgetState } from "../../../hooks/useSwapWidgetState";
import * as styles from "./style";

const getBalanceMessage = (balance?: CurrencyAmount<Token>) => {
  const formattedBalance = formatCurrencyAmount(balance, true);
  return `Balance ${formattedBalance}`;
};

export interface BalanceSpanProps extends ComponentPropsWithoutRef<"span"> {
  field: Field;
}

export const BalanceSpan = observer(({ field, ...props }: BalanceSpanProps) => {
  const { swapInfoState } = useSwapWidgetState();

  const {
    [field]: { balance },
  } = swapInfoState.info;

  const message = getBalanceMessage(balance);
  return <styles.BalanceSpan {...props}>{message}</styles.BalanceSpan>;
});
