import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import styled from "styled-components";

export const Lists = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 10px;
  & > div:not(:last-of-type) {
    margin-bottom: 10px;
  }
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const ListItemFallback = styled(CrashPanel)`
  min-height: 250px;
`;
