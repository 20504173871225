import { Observer, observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { LabeledCreatableSelector, LabeledSelector } from "src/components/shared/Forms/Selectors";
import { DynamicSelectionContext } from "src/context/CEX/ExpertSystem";
import { CreateModulesContext } from "src/context/CEX/ExpertSystem/modules";
import { stringToSelectorValue } from "src/helpers/forms/selectors";
import { Extends } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IStrategyModuleCreateType, StrategyModuleTypes } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";

type ModulesWithExchPair = Extends<StrategyModuleTypes, "price_abs" | "trade">;

export interface ExchPairFieldsProps {
  showAllExchanges?: boolean;
}

export const ExchPairFields = observer(
  ({ showAllExchanges: useAllExchanges }: ExchPairFieldsProps) => {
    const state = useLateInitContext(CreateModulesContext);

    const exchangesOptions = useAllExchanges ? state.allExchangesOptions : state.exchangesOptions;

    const selectorsState = useLateInitContext(DynamicSelectionContext);

    const { exchPairState } = selectorsState;

    const { control, resetField } =
      useFormContext<IStrategyModuleCreateType<ModulesWithExchPair>>();

    const exchange = useWatch({ control, name: "hard_params.exchange" });

    useEffect(() => {
      resetField("hard_params.pair");
      selectorsState.setExchange(exchange);
    }, [selectorsState, exchange, resetField]);

    return (
      <>
        <Controller
          control={control}
          name="hard_params.exchange"
          rules={{ required: ERRORS_MSGS.isRequired }}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            const error = getFieldError(errors, "hard_params.exchange");
            return (
              <Observer>
                {() => (
                  <LabeledSelector
                    label="Exchange"
                    menuPosition="fixed"
                    options={exchangesOptions}
                    onChange={(data) => {
                      onChange(data?.value);
                    }}
                    value={stringToSelectorValue(value)}
                    errorHint={error?.message}
                  />
                )}
              </Observer>
            );
          }}
        />

        <Controller
          control={control}
          name="hard_params.pair"
          rules={{ required: ERRORS_MSGS.isRequired }}
          render={({ field: { value, onChange }, formState: { errors } }) => {
            const error = getFieldError(errors, "hard_params.pair");
            return (
              <Observer>
                {() => (
                  <LabeledCreatableSelector
                    label="Pair"
                    menuPosition="fixed"
                    options={exchPairState.shortPairsList}
                    onChange={(data) => onChange(data?.value)}
                    onCreateOption={(value) => {
                      onChange(value);
                      exchPairState.setNewPair(value);
                    }}
                    onInputChange={exchPairState.setSearchPair()}
                    value={stringToSelectorValue(value)}
                    errorHint={error?.message}
                  />
                )}
              </Observer>
            );
          }}
        />
      </>
    );
  }
);
