import { ApiModules, apiUrl } from "src/api";
import { NewLiquidGridSettings } from "src/modules/exchange/trade";
import ResponseHandler from "src/state/network/ResponseHandler";

// export const getCEXInfo = async (bot_uuid: string, botName: string) => {
//   return ResponseHandler.handler("/api/GetInfo", "POST", {
//     bot_uuid,
//     path: botName,
//   });
// };

export const getLiquiditySettings = async (bot_uuid: string) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Grid, `?bot-uuid=${bot_uuid}`, true),
    method: "GET",
  });

export const setLiquiditySettings = async (data: NewLiquidGridSettings) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Grid, ""),
    method: "POST",
    data,
  });

export const startLiquidity = async (id: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Grid, `/${id}/start`),
    method: "POST",
  });

export const stopLiquidity = async (id: number) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Grid, `/${id}/stop`),
    method: "POST",
  });

export const changeLiquidity = async (id: number, data: NewLiquidGridSettings) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Grid, `/${id}`),
    method: "PUT",
    data,
  });
