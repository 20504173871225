import { CrashPanel } from "src/components/shared/ErrorBoundary/CrashPanel";
import { Warning as BaseWarning } from "src/components/shared/Prompts/Warning";
import { PanelContainer } from "src/components/shared/shared";
import { toPx } from "src/helpers/styled/units";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 765px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;
`;

export const TablePanelFallback = styled(CrashPanel)`
  max-width: 765px;
  min-height: 500px;
`;

export const WrapperContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 20px;
`;

export const WarningWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 10px;
`;

export const Warning = styled(BaseWarning)`
  font-size: 16px;
`;

export interface GapProps {
  gap?: number;
}

export const ButtonsWrapper = styled.div<GapProps>`
  display: flex;

  gap: ${({ gap = 8 }) => toPx(gap)};
`;

export const WalletPanel = styled(PanelContainer)`
  height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 40px 30px;

  gap: 15px;
`;
