import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { FundingListContext } from "src/context/CEX/FundingProvider";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { FundingList } from "../Tables/FundingList";
import * as styles from "./style";

interface FundingInfoProps {
  market?: string;
}

export const FundingInfo = observer(({ market }: FundingInfoProps) => {
  const state = useLateInitContext(FundingListContext);

  useEffect(() => {
    if (market) state.setMarket(market);
  }, [state, market]);

  const { party, rangeState, mainState } = state;

  const setUpdatePNL = useCallback(
    (cb: () => void) => {
      mainState.setUpdHandlers("updPnL", cb);
    },
    [mainState]
  );

  return (
    <>
      <styles.FundingPnL
        party={party}
        range={rangeState.range}
        onRangeChange={rangeState.setRange}
        pickerPosition="bottom left"
        setUpdatePNLCb={setUpdatePNL}
        showCharts
      />
      <FundingList />
    </>
  );
});
