import { Graph } from "src/components/shared/Graph";
import styled from "styled-components";
import { DemoCard } from "../../shared/DemoCard";
import { Content } from "../../shared/DemoCard/style";

export const GraphDemoCard = styled(DemoCard)`
  ${Content} {
    flex-direction: column;
  }
`;

export const StyledDemoGraph = styled(Graph)`
  width: 100%;
  height: 300px;
`;
