import {
  SubmitButton,
  SubmitButtonProps,
} from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { OrderSideType } from "src/modules/exchange/trade";
import { capitalizeOnlyFirstLetter } from "../utils";

export interface TradeButtonProps extends SubmitButtonProps {
  side: OrderSideType;
}

export const TradeButton = ({ side, ...props }: TradeButtonProps) => {
  const color = side === "SELL" ? "danger" : "success";
  return (
    <SubmitButton color={color} stretched {...props}>
      {capitalizeOnlyFirstLetter(side)}
    </SubmitButton>
  );
};
