import styled from "styled-components";
import { CardSurface } from "../../../../../../shared/shared";

interface Props {
  disabled?: boolean;
}

export const SettingsForm = styled.form<Props>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* overflow: hidden; */
  ${CardSurface}
`;

export const Container = styled.div<Props>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii["4px"]};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 17px 8px;
`;

export const Title = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  margin: 0px;
  color: ${({ theme }) => theme.bigHeader};

  & > span > svg {
    cursor: pointer;
    margin: 0px 4px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-of-type {
    margin-right: 15px;
  }
`;

export const Content = styled.div<Props>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 17px 15px;
  pointer-events: ${({ disabled }: Props) => (disabled ? "none" : "")};
  opacity: ${({ disabled }: Props) => (disabled ? "0.6" : 1)};
`;
