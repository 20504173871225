import { IWalletsProvider } from "../WalletInputStore";
import { ISwapWalletsProvider } from "./SwapWalletsStore";

export class SwapWalletsAdapter implements IWalletsProvider {
  private _provider: ISwapWalletsProvider;

  constructor(provider: ISwapWalletsProvider) {
    this._provider = provider;
  }

  get wallets(): string[] {
    return this._provider.swapWallets;
  }

  setWallets = (wallets: string[]) => {
    this._provider.setSwapWallets(wallets);
  };
}
