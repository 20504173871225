import { getDataByKey, setData } from "src/helpers/forms/getByKey";
import { logError } from "src/helpers/network/logger";
import { ValidationError } from "yup";
import {
  EMPTY_CHANGEABLE_SETTINGS_PARAMS,
  ONLY_EQUAL_PARAMS,
  PARAMETER_DEPENDENCY_GRAPH,
} from "../consts";
import { ChangeableCEXSettings, FormControlConfig, SettingsParamsPaths } from "../types";
import { settingsValidationSchema } from "../validation";

export const formConfigToSelectorOptions = (
  map: Record<SettingsParamsPaths, FormControlConfig>
) => {
  const keys = Object.keys(map) as SettingsParamsPaths[];

  const newMap: any = {};

  keys.forEach((key) => {
    const { label } = map[key];

    if (label) {
      newMap[key] = label;
    }
  });

  return newMap as Record<SettingsParamsPaths, string>;
};

export const getParamDependencies = (param: SettingsParamsPaths) =>
  PARAMETER_DEPENDENCY_GRAPH[param];

export const getDefaultParam = (param: SettingsParamsPaths) =>
  getDataByKey(EMPTY_CHANGEABLE_SETTINGS_PARAMS, param);

export const checkOnlyEqualParam = (param: SettingsParamsPaths) =>
  ONLY_EQUAL_PARAMS.includes(param);

/**
 * method for calculating and setting volumes
 * @param data - CEX bot settings config
 */
export const setCalcVolumesMaxParams = (data: Partial<ChangeableCEXSettings>) => {
  // crutch for calculating max settings volumes
  if (data.volume?.tradePerDayMinUSD) {
    setVolumeMaxParam(
      data,
      "volume.tradePerDayMaxUSD" as SettingsParamsPaths,
      data.volume?.tradePerDayMinUSD
    );
  }
  // crutch for calculating max settings volumes
  if (data.volume?.modifyTradePerDayMinUSD) {
    setVolumeMaxParam(
      data,
      "volume.modifyTradePerDayMaxUSD" as SettingsParamsPaths,
      data.volume?.modifyTradePerDayMinUSD
    );
  }
};

const setVolumeMaxParam = (
  data: Partial<ChangeableCEXSettings>,
  path: SettingsParamsPaths,
  value: number
) => {
  const calcValue = String(Math.round(value * 1.1));

  setData(data as ChangeableCEXSettings, path, calcValue);
};

export const generateExpressionPart = (param: SettingsParamsPaths, cmp: string, value: any) =>
  `${param} ${cmp} ${value}`;

/**
 * parse part of the expression into configuration parameters
 * @param part - expression part: ${param} ${humanCmpView} ${valueParam}
 * @returns
 */
export const parseExpressionPart = (part: string) =>
  part.split(" ") as [SettingsParamsPaths, string, any];

export const parseSettingValueWithSchema = (path: SettingsParamsPaths, value: any) => {
  const dataValue = {};

  setData(dataValue as ChangeableCEXSettings, path, value);

  try {
    const parsedValue = settingsValidationSchema.validateSyncAt(path, dataValue);

    return parsedValue;
  } catch (error) {
    const parsedValue = (error as ValidationError).params?.value;

    // crutch for interdependent fields
    if (parsedValue !== null) return parsedValue;

    logError(error);
  }
};

export const handleSettingsParamValue = (value: number | string | boolean) => {
  if (typeof value === "boolean") {
    return value ? "on" : "off";
  }

  return String(value);
};
