import { observer } from "mobx-react-lite";
import { DEXV2BotContext } from "src/context/DEXV2/DEXV2Bots/DEXV2Bot";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXScreenerChart, DEXScreenerChartProps } from "../../Dashboard/DEXScreenerChart";

export interface SwapScreenerProps extends Omit<DEXScreenerChartProps, "baseUrl" | "network"> {}

export const SwapScreener = observer((props: SwapScreenerProps) => {
  const { screenerUrl } = useLateInitContext(DEXV2BotContext);

  return <DEXScreenerChart baseUrl={screenerUrl} {...props} />;
});
