import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { DEXV2CreatingProvider } from "src/context/DEXV2/DEXV2Create/DEXV2Creating";
import { TokenFillProvider } from "src/context/DEXV2/DEXV2Create/TokenFill";
import { WalletGasFillProvider } from "src/context/DEXV2/DEXV2Create/WalletGasFill";
import { pushOrReplaceHistory } from "src/helpers/router";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useQueryParams } from "src/hooks/useQueryParams";
import { useStep } from "../../../hooks/useStep";
import { ContractsDeploy } from "./ContractsDeploy";
import { ContractsDeployFallback } from "./ContractsDeploy/style";
import { ProfileSettings } from "./ProfileSettings";
import { ProfileSettingsFallback } from "./ProfileSettings/style";
import { RoadMap, RoadMapItem } from "./RoadMap";
import { TokenFill } from "./TokenFill";
import { TokenFillFallback } from "./TokenFill/style";
import { WalletGasFill } from "./WalletGasFill";
import { WalletGasFillFallback } from "./WalletGasFill/style";
import { useBotUUID } from "./hooks/useBotUUID";
import * as styles from "./style";

const DEX_CREATING_STEPS_TABS = ["Settings", "GasFill", "ContractsDeploy", "TokenFill"] as const;

type DEXCreatingTabs = (typeof DEX_CREATING_STEPS_TABS)[number];

function isDEXCreatingTab(value: string): value is DEXCreatingTabs {
  return DEX_CREATING_STEPS_TABS.includes(value as DEXCreatingTabs);
}

export interface RoadMapSteps<T extends string> extends RoadMapItem {
  label: string;
  value: T;
}

type TabRoadMapSteps<T extends DEXCreatingTabs = DEXCreatingTabs> = T extends DEXCreatingTabs
  ? RoadMapSteps<T>
  : never;

const DEX_CREATING_STEPS: TabRoadMapSteps[] = [
  { value: "Settings", label: "Settings" },
  { value: "GasFill", label: "Wallet Gas Fill" },
  { value: "ContractsDeploy", label: "Contracts Deploy" },
  { value: "TokenFill", label: "Tokens Fill" },
];

const DEX_CREATING_MOBILE_STEPS: TabRoadMapSteps[] = [
  { value: "Settings", label: "Settings" },
  { value: "GasFill", label: "Gas Fill" },
  { value: "ContractsDeploy", label: "Deploy" },
  { value: "TokenFill", label: "Tokens Fill" },
];

const tabFromTabStep = (tabStep: TabRoadMapSteps) => tabStep.value;

const tabFromStep = (tabsSteps: TabRoadMapSteps[], step: number): DEXCreatingTabs =>
  tabFromTabStep(tabsSteps[step]);

const urlFromTab = (tab: DEXCreatingTabs, uuid: string) => {
  const baseUrl = "/DEXCreating";
  if (tab === "Settings") return baseUrl;
  const uuidUrl = uuid;
  const tabParams = `?tab=${tab}`;
  const urlParts = [baseUrl, uuidUrl, tabParams];
  const url = urlParts.filter(Boolean).join("/");
  return url;
};

export type TabStepNavigationAction = (uuid: string) => void;

interface UseTabsNavigationActions {
  nextStep: TabStepNavigationAction;
  prevStep: TabStepNavigationAction;
  finishStep: TabStepNavigationAction;
}

const useTabsNavigation = (
  tabsSteps: TabRoadMapSteps[]
): [DEXCreatingTabs | "", number, UseTabsNavigationActions] => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const urlTab = queryParams.get("tab") ?? "";
  const uuid = useBotUUID();
  const currentTab = isDEXCreatingTab(urlTab) && uuid ? urlTab : "";

  const [step, { reset: resetStep, setStep, canGoToNext, canGoToPrev }] = useStep(tabsSteps.length);

  const syncTabToStep = useCallback(() => {
    const currentStep = tabsSteps.findIndex((el) => el.value === currentTab);

    if (currentStep === -1) {
      resetStep();
    } else {
      setStep(currentStep);
    }
  }, [currentTab, resetStep, setStep, tabsSteps]);

  useEffect(() => {
    syncTabToStep();
  }, [syncTabToStep]);

  const updateStepHistory = useCallback(
    (step: number, uuid: string) => {
      const nextTab = tabFromStep(tabsSteps, step);
      const url = urlFromTab(nextTab, uuid);
      pushOrReplaceHistory(history, url);
    },
    [history, tabsSteps]
  );

  const nextStep = useCallback(
    (uuid: string) => {
      if (!canGoToNext) return;
      updateStepHistory(step + 1, uuid);
    },
    [canGoToNext, step, updateStepHistory]
  );

  const prevStep = useCallback(
    (uuid: string) => {
      if (!canGoToPrev) return;
      updateStepHistory(step - 1, uuid);
    },
    [canGoToPrev, step, updateStepHistory]
  );

  const finishStep = useCallback(
    (uuid: string) => {
      pushOrReplaceHistory(history, `/DEX_V2/${uuid}/?tab=STATS`);
    },
    [history]
  );

  return [currentTab, step, { nextStep, prevStep, finishStep }];
};

export interface FirstTabStepProps extends Pick<UseTabsNavigationActions, "nextStep"> {}

export interface LastTabStepProps extends Pick<UseTabsNavigationActions, "prevStep"> {}

export interface TabStepProps extends FirstTabStepProps, LastTabStepProps {}

export interface StepProps {
  nextStep: () => void;
  prevStep: () => void;
}

export const DEXBotCreating = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const createSteps = isMobile ? DEX_CREATING_MOBILE_STEPS : DEX_CREATING_STEPS;

  const [currentTab, currentStep, { nextStep, prevStep, finishStep }] =
    useTabsNavigation(createSteps);

  const getContent = useCallback(() => {
    switch (currentTab) {
      case "Settings":
      case "":
        return (
          <DEXV2CreatingProvider>
            <ErrorBoundary fallback={<ProfileSettingsFallback />}>
              <ProfileSettings nextStep={nextStep} />
            </ErrorBoundary>
          </DEXV2CreatingProvider>
        );

      case "GasFill":
        return (
          <WalletGasFillProvider>
            <ErrorBoundary fallback={<WalletGasFillFallback />}>
              <WalletGasFill prevStep={prevStep} nextStep={nextStep} />
            </ErrorBoundary>
          </WalletGasFillProvider>
        );

      case "ContractsDeploy":
        return (
          <ErrorBoundary fallback={<ContractsDeployFallback />}>
            <ContractsDeploy prevStep={prevStep} nextStep={nextStep} />
          </ErrorBoundary>
        );

      case "TokenFill":
        return (
          <TokenFillProvider>
            <ErrorBoundary fallback={<TokenFillFallback />}>
              <TokenFill nextStep={finishStep} />
            </ErrorBoundary>
          </TokenFillProvider>
        );
    }
  }, [currentTab, finishStep, nextStep, prevStep]);

  return (
    <styles.Container>
      <RoadMap items={createSteps} currentStep={currentStep} />
      {getContent()}
    </styles.Container>
  );
};
