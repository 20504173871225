import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: 1fr repeat(3, auto);
  grid-gap: 4px;
  justify-content: space-between;
`;

export const Title = styled.span`
  grid-column: 1 / -1;
  font-weight: bold;
`;

export const Label = styled.span``;

export const Value = styled.span`
  color: ${({ theme }) => theme.labelColor};
  text-align: right;
`;
