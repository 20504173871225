import { Selector } from "src/components/shared/Forms/Selectors";
import { SELECTOR_CLASSNAME } from "src/components/shared/Forms/Selectors/shared/style";
import { StringSelectorValue } from "src/modules/shared";
import styled from "styled-components";
import { LegendTableV2 } from "./LegendTableV2";

export const LegendContainer = styled.div`
  width: 140px;
  padding-right: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-shrink: 0;
`;

export const Table = styled(LegendTableV2)`
  height: 155px;
`;

export const PieSelector = styled(Selector)`
  & .${SELECTOR_CLASSNAME}__control {
    height: 24px;
  }

  & .${SELECTOR_CLASSNAME}__value-container {
    font-size: ${({ theme }) => theme.fontSizes["10px"]};
  }

  & .${SELECTOR_CLASSNAME}__dropdown-indicator {
    ::before {
      width: 4px;
      height: 4px;
      left: 3px;
    }
  }
` as typeof Selector<StringSelectorValue>;
