import { PNLV2 } from "src/components/shared/PNLV2";
import { PNLV2CardContainer } from "src/components/shared/PNLV2/MobilePNLV2/style";
import styled from "styled-components";

export const PNLCellContainer = styled.div`
  height: 100%;

  span {
    font-size: 12px;
  }
`;

export const PNLWrapper = styled(PNLV2)`
  min-width: 130px;

  ${PNLV2CardContainer} {
    padding-top: 0;
  }
`;
