import { ComponentPropsWithoutRef, memo } from "react";
import { IconButton } from "src/components/shared/Buttons/v2/IconButton";
import Icons from "src/icons/Icons";
import { AccountType } from "src/modules/accounts";
import { PermissionProps, PermissionWrapper } from "src/permissions/PermissionWrapper";
import * as styles from "./style";

export interface AccountsHeaderProps
  extends Omit<ComponentPropsWithoutRef<"div">, "onClick">,
    Partial<PermissionProps> {
  onAddAccount?: () => void;
  type: AccountType;
  checkPermission?: boolean;
}

export const AccountsHeader = memo(
  ({
    onAddAccount,
    type,
    checkPermission = true,
    abilityName = "",
    party = "",
    ...props
  }: AccountsHeaderProps) => {
    const title = `${type} accounts`;

    const permissionProps = { abilityName, party };

    const Button = <IconButton onClick={onAddAccount}>{Icons.plus()}</IconButton>;

    return (
      <styles.Header {...props}>
        <styles.Title>{title}</styles.Title>
        {checkPermission ? (
          <PermissionWrapper {...permissionProps}>{Button}</PermissionWrapper>
        ) : (
          Button
        )}
      </styles.Header>
    );
  }
);
