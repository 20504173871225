import { makeAutoObservable } from "mobx";
import { UserManagerStore } from ".";
import { AddDependencyParams } from "./AddDependencyStore";

export class AddDependencyStoreParams implements AddDependencyParams {
  private _managerStore: UserManagerStore;

  constructor(managerStore: UserManagerStore) {
    this._managerStore = managerStore;

    makeAutoObservable(this);
  }

  updateList = () => {
    this._managerStore.getHierarchieList();
  };

  closeModal = () => {
    this._managerStore.openPanel("showAddHierarchyPanel")(false);
  };

  get isModalShown() {
    return this._managerStore.visiblePanel.showAddHierarchyPanel;
  }
}
