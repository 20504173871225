import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Message } from "src/permissions/ContentPermission/style";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";

export interface DEXV2ContentPermissionProps {
  ability: DEXV2Ability;
  loading?: boolean;
  children: JSX.Element;
}

const NO_PERMISSIONS_TEXT = "You don't have enough rights...";

export const DEXV2ContentPermission = observer(
  ({ ability, loading, children }: DEXV2ContentPermissionProps) => {
    const { hasAbility } = useLateInitContext(AbilitiesContext);

    const hasPermission = hasAbility(ability);

    if (hasPermission) {
      return children;
    }
    if (loading) {
      return <Loader show={loading} />;
    }

    return <Message>{NO_PERMISSIONS_TEXT}</Message>;
  }
);
