import { makeAutoObservable } from "mobx";
import {
  OrderTerminalProps,
  cancelAlgoOrder,
  getAlgOrders,
  getAlgStatus,
} from "src/api/bots/CEX/exchange";
import { formatElapsedTime, stringDateToUnix, unixToDateFormat } from "src/helpers/dateUtils";
import { calcRoundingValues, toRounding } from "src/helpers/rounding";
import { AlgoStatus, CreatedAlgoOrder } from "src/modules/exchange/trade";
import TableStore, { TableOrders } from "src/state/Table";
import ExchangeStore from "../..";
import GetStatusOrderStore, { OrdersStore } from "./GetStatusOrderStore";

export default class StopOrdersStore implements TableOrders, OrdersStore {
  private _algoOrders: CreatedAlgoOrder[] = [];

  mainState: ExchangeStore;

  isLoading = false;

  tableState: TableStore;

  getStatusState: GetStatusOrderStore<AlgoStatus>;

  constructor(state: ExchangeStore) {
    this.mainState = state;

    this.mainState.setUpdHandlers("updStopOrders", this.downloadData);

    this.tableState = new TableStore(this, { showCancelOrderConsent: true });

    this.getStatusState = new GetStatusOrderStore(this);

    makeAutoObservable(this);
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  downloadData = () => {
    if (this.mainState.wsSupport)
      this._fetchAlgOrders(this.mainState.currentAccID, this.mainState.pair);
  };

  get items() {
    return this.algoOrders;
  }

  get algoAmountPrecision() {
    const amounts: string[] = [];

    this._algoOrders.map(({ amount }) => amounts.push(amount));

    return calcRoundingValues(amounts);
  }

  get algoPricePrecision() {
    const prices: string[] = [];

    this._algoOrders.map(({ price }) => prices.push(price));

    return calcRoundingValues(prices);
  }

  get algoOrders() {
    const amountPrecision = this.algoAmountPrecision;

    const pricePrecision = this.algoPricePrecision;

    return this._algoOrders
      .filter(({ pair }) => pair === this.mainState.pair)
      .map(({ price, amount, side, id, expiredAt, ...otherParams }) => ({
        ...otherParams,
        id,
        amount: toRounding(parseFloat(amount), amountPrecision),
        price: toRounding(parseFloat(price), pricePrecision),
        side: side.toUpperCase(),
        expiredAt: formatElapsedTime(stringDateToUnix(expiredAt)),
      }));
  }

  get amountSellOrders() {
    return this._algoOrders.filter((el) => el.side.toUpperCase() === "SELL").length;
  }

  get amountBuyOrders() {
    return this._algoOrders.filter((el) => el.side.toUpperCase() === "BUY").length;
  }

  get orderInfo() {
    const orderStatus = this.getStatusState.selectOrderStatus;

    if (orderStatus)
      return {
        ...orderStatus.order,
        last_price_price: orderStatus.lastPrice.price,
        last_price_updatedAt: unixToDateFormat(
          stringDateToUnix(orderStatus.lastPrice.updatedAt || ""),
          "FullDate"
        ),
        expiredAt: unixToDateFormat(
          stringDateToUnix(orderStatus.order.expiredAt || ""),
          "FullDate"
        ),
        createdAt: unixToDateFormat(
          stringDateToUnix(orderStatus.order.createdAt || ""),
          "FullDate"
        ),
        updatedAt: unixToDateFormat(
          stringDateToUnix(orderStatus.order.updatedAt || ""),
          "FullDate"
        ),
        side: orderStatus.order.side.toUpperCase(),
      };

    return undefined;
  }

  get algoStatusError() {
    const orderStatus = this.getStatusState.selectOrderStatus;

    if (orderStatus) return orderStatus?.order.error;

    return "";
  }

  cancelRequest = async (selectItems: string[]) => {
    this._algoCancelRequest(selectItems);
  };

  cancelManyRequest = async (selectItems: string[]) => {
    this._algoCancelRequest(selectItems);
  };

  cancelAllRequest = async (selectItems: string[]) => {
    this._algoCancelRequest(selectItems);
  };

  resetSelectOrders = () => {
    this.tableState.resetSelectItems();
  };

  private _algoCancelRequest = async (selectItems: string[]) => {
    this.setLoading(true);

    await Promise.all(
      selectItems.map((el) =>
        cancelAlgoOrder({
          account_uuid: this.mainState.currentAccID,
          order_id: el,
        })
      )
    )
      .then((data) => {
        this.mainState.updStopTradingData();

        return data.find((data) => data.isError === true);
      })
      .finally(() => {
        this.setLoading(false);
      });
  };

  private _fetchAlgOrders = async (account_uuid: string, pair: string) => {
    try {
      this.setLoading(true);

      const {
        data: { data },
        isError,
      } = await getAlgOrders({
        account_uuid,
        pair,
      });

      if (!isError) {
        this._setOrders(data);
      } else {
        this._setOrders([]);
      }
    } catch {
      this._setOrders([]);
    } finally {
      this.setLoading(false);
    }
  };

  private _setOrders = (orders: CreatedAlgoOrder[]) => {
    this._algoOrders = orders;
  };

  private _fetchAlgStatus = async ({ account_uuid, order_id }: OrderTerminalProps) =>
    getAlgStatus({ account_uuid, order_id });

  getOrder = async (id: string) =>
    this._fetchAlgStatus({
      account_uuid: this.mainState.currentAccID,
      order_id: id,
    });
}
