import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 10px;

  grid-area: title;
`;
