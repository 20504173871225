import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
} from "react-hook-form";
import { GroupBase, OnChangeValue, PropsValue } from "react-select";
import { LabeledSelectorProps } from "src/components/shared/Forms/Selectors";
import { SelectorValue } from "src/modules/shared";
import { getFieldError } from "../../../utils/errors";

export interface ComponentSelectorProps<
  TForm extends FieldValues,
  TName extends FieldPath<TForm>,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends LabeledSelectorProps<Option, IsMulti, Group> {
  getValueHandler: (value: PathValue<TForm, TName>) => PropsValue<Option> | undefined;
  onChangeHandler: (selector: OnChangeValue<Option, IsMulti>) => string;
}

export interface ControllerWrapperProps<
  TForm extends FieldValues,
  TName extends FieldPath<TForm>,
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> {
  control: Control<TForm>;
  name: TName;
  rules?: Omit<
    RegisterOptions<TForm, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  componentProps: ComponentSelectorProps<TForm, TName, Option, IsMulti, Group>;
  component: React.ComponentType<LabeledSelectorProps<Option, IsMulti, Group>>;
}

export const ControllerWrapper = <
  TForm extends FieldValues,
  TName extends FieldPath<TForm>,
  Option = SelectorValue,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  component: SelectorComponent,
  componentProps: { onChangeHandler, getValueHandler, ...otherComponentProps },
  ...props
}: ControllerWrapperProps<TForm, TName, Option, IsMulti, Group>) => (
  <Controller
    name={name}
    {...props}
    render={({ field: { value, onChange }, formState: { errors } }) => {
      const error = getFieldError(errors, name);

      return (
        <SelectorComponent
          value={getValueHandler(value)}
          onChange={(data) => onChange(onChangeHandler(data))}
          errorHint={error?.message}
          {...otherComponentProps}
        />
      );
    }}
  />
);
