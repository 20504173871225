import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  font-size: 12px;
`;
