import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { EmptyHashesText } from "./EmptyHashesText";
import { HashesListItem, HashesListItemProps } from "./HashesListItem";
import { HashItem } from "./HashesListItem/HashLabel";
import * as styles from "./style";

export interface HashesListProps
  extends Pick<HashesListItemProps, "onDeleteHash" | "showDelete">,
    ComponentPropsWithoutRef<"div"> {
  hashes: HashItem[];
  hashesErrors?: string;
}

export const HashesList = observer(
  ({ hashes, onDeleteHash, hashesErrors, showDelete = true, ...props }: HashesListProps) => {
    const hashesCount = hashes.length;

    return (
      <styles.Container {...props}>
        {hashesCount ? (
          <styles.HashesList>
            {hashes.map((hash) => (
              <HashesListItem
                key={hash.address}
                onDeleteHash={onDeleteHash}
                showDelete={showDelete}
                {...hash}
              />
            ))}
          </styles.HashesList>
        ) : (
          <EmptyHashesText error={hashesErrors} />
        )}
      </styles.Container>
    );
  }
);
