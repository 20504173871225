import { useEffect } from "react";
import { useDashboardV2State } from "src/components/BotsContent/CEX/Dashboard/shared/hooks/useDashboardState";
import { ChildrenProps, ClassConstructor, IDisposable, ISubscribable } from "src/helpers/utils";
import { createLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { IBaseDashboardV2StoreParams } from "src/state/CEX/CEXDashboard/v2";

type WidgetStoreConstructorFunction<T> = abstract new (args: IBaseDashboardV2StoreParams) => T;

type WidgetStoreConstructor<T> = ClassConstructor<
  WidgetStoreConstructorFunction<T>,
  IDisposable & ISubscribable
>;

interface DashboardWidgetProviderProps extends ChildrenProps {}

export const createDashboardWidgetContext = <T,>(stateConstructor: WidgetStoreConstructor<T>) => {
  const Context = createLateInitContext<InstanceType<WidgetStoreConstructor<T>>>();

  const Provider = ({ children }: DashboardWidgetProviderProps) => {
    const state = useDashboardV2State();

    const constructorParams: ConstructorParameters<WidgetStoreConstructor<T>> = [
      { stateProvider: state },
    ];
    const widgetState = useLocalStore(stateConstructor, ...constructorParams);

    useEffect(() => {
      widgetState.subscribe();
    }, [widgetState]);

    return <Context.Provider value={widgetState}>{children}</Context.Provider>;
  };

  return { Context, Provider };
};
