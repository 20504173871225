import { ComponentPropsWithoutRef } from "react";
import { isFiniteNumber } from "src/helpers/math";
import { Nullish, isDefined } from "src/helpers/utils";
import { useTheme } from "styled-components";
import { PNLV2TextPrice } from "../../PNLV2Items";
import { formatPNLDelta, formatPNLPrice } from "../../utilts";
import { PNLV2Label } from "../shared/PNLV2Label";
import { PNLV2Value } from "../shared/PNLV2Value";
import * as styles from "./style";

export type PNLV2Direction = "profit" | "loss" | "neutral";

export type PNLV2ValueType = "delta" | "price";

export const isPNLTextPrice = (value: string | number): value is PNLV2TextPrice =>
  value === "NET PROFIT" || value === "NET LOSS";

const getValueDirection = (value: number): PNLV2Direction => {
  switch (Math.sign(value)) {
    case 1:
      return "profit";
    case -1:
      return "loss";
    default:
      return "neutral";
  }
};

const getPriceTextDirection = (value: PNLV2TextPrice): PNLV2Direction => {
  switch (value) {
    case "NET PROFIT":
      return "profit";
    case "NET LOSS":
      return "loss";
    default:
      return "neutral";
  }
};

interface UsePNLV2ValueDirectionParams extends Pick<PNLV2ItemProps, "value"> {}

const usePNLV2ValueDirection = ({ value }: UsePNLV2ValueDirectionParams) => {
  if (!isDefined(value)) return "neutral";

  if (isPNLTextPrice(value)) {
    const direction = getPriceTextDirection(value);
    return direction;
  }

  if (!isFiniteNumber(value)) return "neutral";

  const numValue = +value;

  const direction: PNLV2Direction = getValueDirection(numValue);

  return direction;
};

interface UsePNLV2ValueParams
  extends Pick<PNLV2ItemValueProps, "type" | "value" | "showSymbol" | "showPlus"> {}

export const usePNLV2Value = ({ value, type, showSymbol, showPlus }: UsePNLV2ValueParams) => {
  if (!isDefined(value)) return "-";

  if (isPNLTextPrice(value)) return value;

  if (!isFiniteNumber(value)) return "-";

  const numValue = +value;

  const formattedValue =
    type === "delta"
      ? formatPNLDelta(numValue, showSymbol, showPlus)
      : formatPNLPrice(numValue, showSymbol, showPlus);

  return formattedValue;
};

export interface PNLV2ColorProps {
  profitColor?: string;
  lossColor?: string;
  neutralColor?: string;
}

interface UsePNLV2ColorPropsResponse<T> {
  colorProps: PNLV2ColorProps;
  otherProps: Omit<T, keyof PNLV2ColorProps>;
}

export const usePNLV2ColorProps = <T extends PNLV2ColorProps>(
  props: T
): UsePNLV2ColorPropsResponse<T> => {
  const { profitColor, lossColor, neutralColor, ...otherProps } = props;

  const colorProps = { profitColor, lossColor, neutralColor };

  return { colorProps, otherProps };
};

interface UsePNLV2ColorParams extends PNLV2ColorProps {
  direction: PNLV2Direction;
}

export const usePNLV2Color = ({
  direction,
  profitColor,
  lossColor,
  neutralColor,
}: UsePNLV2ColorParams) => {
  const {
    textColor,
    dashboard: {
      accent: { darkLime, rubyRed },
    },
  } = useTheme();

  switch (direction) {
    case "profit":
      return profitColor ?? darkLime;
    case "loss":
      return lossColor ?? rubyRed;
    default:
      return neutralColor ?? textColor;
  }
};

export type PNLV2ValueInfo = {
  direction: PNLV2Direction;
  color: string;
};

interface UsePNLV2ValueInfoParams extends Pick<PNLV2ItemProps, "value">, PNLV2ColorProps {}

export const usePNLV2ValueInfo = ({ value, ...colorProps }: UsePNLV2ValueInfoParams) => {
  const direction = usePNLV2ValueDirection({ value });

  const color = usePNLV2Color({ direction, ...colorProps });

  return { color, direction };
};

export interface PNLV2ItemValueProps {
  value?: Nullish<number | string>;
  type?: PNLV2ValueType;
  showArrow?: boolean;
  showSymbol?: boolean;
  showPlus?: boolean;
}

export interface PNLV2ItemLabelProps {
  label?: string;
  coloredLabel?: boolean;
}

export interface PNLV2ItemOwnProps
  extends PNLV2ColorProps,
    styles.PNLV2ItemContainerProps,
    PNLV2ItemValueProps,
    PNLV2ItemLabelProps {}

export interface PNLV2ItemProps extends PNLV2ItemOwnProps, ComponentPropsWithoutRef<"div"> {}

export const PNLV2Item = ({
  label,
  value,
  showSymbol = false,
  showArrow = true,
  type = "delta",
  coloredLabel = true,
  showPlus,
  orientation,
  space,
  ...props
}: PNLV2ItemProps) => {
  const { colorProps, otherProps: rootProps } = usePNLV2ColorProps(props);

  const valueInfo = usePNLV2ValueInfo({ ...colorProps, value });

  const formattedValue = usePNLV2Value({ value, type, showSymbol, showPlus });

  return (
    <styles.PNLV2ItemContainer $orientation={orientation} $space={space} {...rootProps}>
      {label && (
        <PNLV2Label colored={coloredLabel} color={valueInfo.color}>
          {label}
        </PNLV2Label>
      )}
      <PNLV2Value showArrow={showArrow} {...valueInfo}>
        {formattedValue}
      </PNLV2Value>
    </styles.PNLV2ItemContainer>
  );
};
