import { Percent } from "@uniswap/sdk-core";
import debounce from "lodash.debounce";
import { IReactionDisposer, makeAutoObservable, reaction, when } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { logError } from "src/helpers/network/logger";
import { IDisposable } from "src/helpers/utils";
import { IRouterProvider } from "src/state/DEXV2/DEXV2Swap/SwapModules/shared/Providers/RouterProvider";
import { IBotTradePairProvider, ISwapPairAddressProvider } from "../../../DEXV2Bots/DEXV2BotStore";
import { Field } from "../../../DEXV2Swap/SwapModules/shared/SwapStateStore";
import { INonNullableVersionProvider } from "../../../DEXV2Swap/SwapModules/shared/VersionedSwapState";
import { CacheOptions, percentAbs } from "../../../DEXV2Swap/utils";
import { PriceImpactInfoStore } from "./PriceImpactInfoStore";

export interface DEXV2ModeImpactInfo {
  buy?: Percent;
  sell?: Percent;
  average?: Percent;
}

export interface IPriceImpactInfoDataProvider {
  get inputAmount(): string;
}

export interface IModeInfoParams {
  tradePairProvider: IBotTradePairProvider;
  routerProvider: IRouterProvider;
  pairAddressProvider: ISwapPairAddressProvider;
  versionProvider: INonNullableVersionProvider;
  dataProvider: IPriceImpactInfoDataProvider;
}

export interface IModeImpactInfo {
  get priceImpact(): DEXV2ModeImpactInfo;
  refreshImpactInfo: (options?: CacheOptions) => Promise<void>;
}

export class ModeImpactInfoStore implements IModeImpactInfo, IDisposable {
  private _tradePairProvider: IBotTradePairProvider;

  private _tradePairReaction: IReactionDisposer;

  private _buyImpactState: PriceImpactInfoStore;

  private _sellImpactState: PriceImpactInfoStore;

  private _amountChangeReaction: IReactionDisposer;

  private _initialChangeReaction: IReactionDisposer;

  private _dataProvider: IPriceImpactInfoDataProvider;

  constructor({
    tradePairProvider,
    routerProvider,
    pairAddressProvider,
    versionProvider,
    dataProvider,
  }: IModeInfoParams) {
    makeAutoObservable<this, "_debouncedRefreshImpactInfo">(this, {
      _debouncedRefreshImpactInfo: false,
    });

    this._tradePairProvider = tradePairProvider;

    this._dataProvider = dataProvider;

    const impactInfoParams = {
      tradePairProvider,
      routerProvider,
      pairAddressProvider,
      versionProvider,
    };

    this._buyImpactState = new PriceImpactInfoStore(impactInfoParams);

    this._sellImpactState = new PriceImpactInfoStore(impactInfoParams);

    this._tradePairReaction = when(() => Boolean(this._tradePair), this._initSwapState);

    this._amountChangeReaction = reaction(() => this._inputAmount, this._onAmountChange);

    this._initialChangeReaction = when(() => this._routeInitialized, this._initBaseAmount);

    makeLoggable(this, { priceImpact: true });
  }

  private get _inputAmount() {
    return this._dataProvider.inputAmount;
  }

  private get _routeInitialized() {
    return this._buyImpactState.routeInitialized && this._sellImpactState.routeInitialized;
  }

  private _initSwapState = () => {
    const pair = this._tradePair;
    if (!pair) return;

    this._buyImpactState.swapState.setSwapTokens(pair);

    this._sellImpactState.swapState.setSwapTokens(pair);
    // by default state is set for base buy
    this._sellImpactState.swapState.switchSwapTokens();
  };

  private _updateBaseAmount = async (amount: string, debounceUpdate = true) => {
    this._buyImpactState.swapState.onAmountChange(Field.OUTPUT)(amount);
    this._sellImpactState.swapState.onAmountChange(Field.INPUT)(amount);

    const refreshImpactInfo = debounceUpdate
      ? this._debouncedRefreshImpactInfo
      : this.refreshImpactInfo;

    await refreshImpactInfo();
  };

  private _initBaseAmount = async () => {
    const amount = this._inputAmount;
    this._updateBaseAmount(amount.toString(), false);
  };

  private _onAmountChange = (amount: string) => {
    if (amount === "") return;

    this._updateBaseAmount(amount.toString());
  };

  refreshImpactInfo = async (options?: CacheOptions) => {
    try {
      await Promise.all([
        this._buyImpactState.getPriceImpact(options),
        this._sellImpactState.getPriceImpact(options),
      ]);
    } catch (err) {
      logError(err);
    }
  };

  private _debouncedRefreshImpactInfo = debounce(this.refreshImpactInfo, 500);

  private get _tradePair() {
    return this._tradePairProvider.tradePair;
  }

  private get _buyImpact() {
    return this._buyImpactState.priceImpact?.percent;
  }

  private get _sellImpact() {
    return this._sellImpactState.priceImpact?.percent;
  }

  private get _averageImpact() {
    const buy = this._buyImpact;
    const sell = this._sellImpact;

    if (!buy || !sell) return undefined;
    const absBuy = percentAbs(buy);
    const absSell = percentAbs(sell);

    return absBuy.greaterThan(absSell) ? absBuy : absSell;
  }

  get priceImpact(): DEXV2ModeImpactInfo {
    return {
      buy: this._buyImpact,
      sell: this._sellImpact,
      average: this._averageImpact,
    };
  }

  destroy = () => {
    this._initialChangeReaction();
    this._tradePairReaction();
    this._amountChangeReaction();

    this._buyImpactState.destroy();
    this._sellImpactState.destroy();
  };
}
