import { ComponentPropsWithoutRef } from "react";
import { Headline2 } from "src/components/shared/Typography";
import * as styles from "./style";

export interface TablePanelHeaderOwnProps {
  title: string;
  afterTitle?: JSX.Element;
}

export interface TablePanelHeaderProps
  extends TablePanelHeaderOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, keyof TablePanelHeaderOwnProps> {}

export const TablePanelHeader = ({ title, afterTitle, ...props }: TablePanelHeaderProps) => (
  <styles.Header {...props}>
    <Headline2>{title}</Headline2>

    <styles.Wrapper>{afterTitle}</styles.Wrapper>
  </styles.Header>
);
