import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useEffect } from "react";
import { Loader } from "src/components/shared/Loader";
import { ChooseSettingParamsContext } from "src/context/CEX/ExpertSystem/modules";
import useLocalStore from "src/hooks/useLocalStore";
import { ChangeSettings } from "src/modules/expertSystem";
import { ChooseSettingParamsStore } from "src/state/expertSystem/strategies/changeSettingsModule/ChooseSettingParamsStore";
import { ModuleSoftParamsShell } from "../../ModuleSoftParamsShell";
import { HardParamsViewProps } from "../../ModuleSoftParamsShell/HardParamsView";
import { SoftParamsFormsProps } from "../../ModuleSoftParamsShell/types";
import { ChooseParams } from "./ChooseParams";
import * as styles from "./style";

interface ShellProps extends HardParamsViewProps, SoftParamsFormsProps {}

export interface ChangeSettingsPanelProps extends ComponentPropsWithoutRef<"div"> {
  shellProps: ShellProps;
  error: string | undefined;
}

export const ChangeSettingsPanel = observer(
  ({ shellProps, error, children }: ChangeSettingsPanelProps) => {
    const botUUID = (shellProps.hardParams as ChangeSettings).bot_uuid;

    const state = useLocalStore(ChooseSettingParamsStore, botUUID);

    useEffect(() => {
      state.loadData();
    }, [state]);

    return (
      <ChooseSettingParamsContext.Provider value={state}>
        <ModuleSoftParamsShell {...shellProps}>
          <styles.Container error={!error}>
            <styles.Header>
              <ChooseParams />
            </styles.Header>

            <styles.Content>{children}</styles.Content>

            <Loader show={state.isLoading} />
          </styles.Container>
        </ModuleSoftParamsShell>
      </ChooseSettingParamsContext.Provider>
    );
  }
);
