import { ComponentPropsWithoutRef } from "react";
import {
  PNLV2ColorProps,
  PNLV2ItemValueProps,
  usePNLV2ColorProps,
  usePNLV2Value,
  usePNLV2ValueInfo,
} from "../../PNLV2Item";
import { PNLV2Value } from "../../shared/PNLV2Value";

export interface PNLV2USDValueOwnProps extends PNLV2ItemValueProps, PNLV2ColorProps {}

export interface PNLV2USDValueProps
  extends PNLV2USDValueOwnProps,
    ComponentPropsWithoutRef<"span"> {}

export const PNLV2USDValue = ({
  value,
  type,
  showSymbol,
  showArrow,
  showPlus,
  ...props
}: PNLV2USDValueProps) => {
  const formattedValue = usePNLV2Value({ value, type, showPlus, showSymbol });

  const { colorProps, otherProps: rootProps } = usePNLV2ColorProps(props);

  const valueInfo = usePNLV2ValueInfo({ value, ...colorProps });

  return (
    <PNLV2Value {...valueInfo} {...rootProps}>
      {formattedValue}
    </PNLV2Value>
  );
};
