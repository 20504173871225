import {
  SETTINGS_PARAMS_CONFIG_MAP,
  SETTINGS_SWITCHES_ITEMS_MAP,
} from "src/state/expertSystem/strategies/changeSettingsModule/shared/consts";
import { SettingsParamsPaths } from "src/state/expertSystem/strategies/changeSettingsModule/shared/types";

export const getLabelParam = (path: SettingsParamsPaths) => SETTINGS_PARAMS_CONFIG_MAP[path].label;

export const getControlConfig = (path: SettingsParamsPaths) => SETTINGS_PARAMS_CONFIG_MAP[path];

export const getSwitchesItems = (path: SettingsParamsPaths) =>
  SETTINGS_SWITCHES_ITEMS_MAP[path] || [];
