import { GroupBase, OptionProps, components } from "react-select";
import { LegendSeriesSelectorValue } from "..";
import * as styles from "./style";

export interface LegendSeriesOptionProps<
  IsMulti extends boolean,
  Group extends GroupBase<LegendSeriesSelectorValue>,
> extends OptionProps<LegendSeriesSelectorValue, IsMulti, Group> {}

export const LegendSeriesOption = <
  IsMulti extends boolean = boolean,
  Group extends GroupBase<LegendSeriesSelectorValue> = GroupBase<LegendSeriesSelectorValue>,
>({
  children,
  data,
  ...props
}: LegendSeriesOptionProps<IsMulti, Group>) => {
  const optionProps = {
    data,
  };

  return (
    <components.Option {...props} {...optionProps}>
      <styles.Container>
        <styles.ColorBox $color={data.color} />
        {children}
      </styles.Container>
    </components.Option>
  );
};
