import { ApiModules, apiUrl } from "src/api";
import { buildQueryUrl } from "src/helpers/url";
import { ApiResponse } from "src/modules/network";
import { ChartPointResponse } from "src/modules/shared";
import ResponseHandler from "src/state/network/ResponseHandler";

export interface DashboardRangeParams {
  from: string;
  to: string;
}

export interface DashboardQueryParams extends DashboardRangeParams {
  exchanges?: string;
  accounts?: string;
}

const buildDashboardQueryUrl = (party: string, urlPath: string, params?: DashboardQueryParams) =>
  buildQueryUrl(`/${party}/${urlPath}`, params);

export interface GetKPIResponse {
  aum: number;
  balance: {
    free: number;
    locked: number;
  };
  index_impact: number;
  exchanges: string[];
}

export const getKPI = async (
  party: string,
  params: DashboardRangeParams
): Promise<ApiResponse<GetKPIResponse>> => {
  const url = buildDashboardQueryUrl(party, "stats_overview", params);
  return ResponseHandler.handler<GetKPIResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface getKPIAUMResponse {
  aum: string;
}

export const getKPIAUM = async (
  party: string,
  params: DashboardRangeParams
): Promise<ApiResponse<getKPIAUMResponse>> => {
  const url = buildDashboardQueryUrl(party, "stats_aum", params);
  return ResponseHandler.handler<getKPIAUMResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetKPIBalancesResponse {
  free: string;
  locked: string;
}

export const getKPIBalances = async (
  party: string,
  params: DashboardRangeParams
): Promise<ApiResponse<GetKPIBalancesResponse>> => {
  const url = buildDashboardQueryUrl(party, "stats_balance", params);
  return ResponseHandler.handler<GetKPIBalancesResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetKPIIndexResponse {
  depth_impact: string;
  volume_impact: string;
  index_impact: string;
}

export const getKPIIndex = async (
  party: string,
  params: DashboardRangeParams
): Promise<ApiResponse<GetKPIIndexResponse>> => {
  const url = buildDashboardQueryUrl(party, "stats_index", params);
  return ResponseHandler.handler<GetKPIIndexResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface CurrencyData {
  [key: string]: string;
}

export interface FundingsExchange {
  [key: string]: CurrencyData;
}

interface PNLChartsResponse {
  delta_base: ChartPointResponse[] | null;
  delta_quote: ChartPointResponse[] | null;
  price: ChartPointResponse[] | null;
}

export interface GetBalancePNLResponse {
  base_ticker: string;
  current_price_base: string;
  delta_base: string;
  delta_base_usd: string;
  delta_quote_usd: string;
  delta_usd: string;
  fee_usd: string;
  price_growth: string;
  price_net: string;
  fundings: FundingsExchange;
  charts: PNLChartsResponse | null;
}

export const getBalancePNL = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "balance_pnl", params);

  return ResponseHandler.handler<GetBalancePNLResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetPNLResponse {
  all_time: number;
  bars_abs: {
    time: number[];
    value: number[];
  };
  bars_percent: {
    time: number[];
    value: number[];
  };
}

export const getPNL = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "profit_loss", params);

  return ResponseHandler.handler<GetPNLResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetBalancesResponse {
  free: {
    time: number[];
    value: string[];
  };
  locked: {
    time: number[];
    value: string[];
  };
}

export const getBalances = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "balance_overview", params);

  return ResponseHandler.handler<GetBalancesResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

type ExchangesBalances = Record<string, string>;

export interface GetExchangesResponse {
  free_usd: ExchangesBalances;
  locked_usd: ExchangesBalances;
  total_tokens: ExchangesBalances;
  total_usd: ExchangesBalances;
}

export const getExchanges = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "exchange_overview", params);

  return ResponseHandler.handler<GetExchangesResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetLiquidityResponse {
  buy: {
    all: string[];
    not_our: string[];
    our: string[];
  };
  sell: {
    all: string[];
    not_our: string[];
    our: string[];
  };
  time: number[];
  price_avg: string[];
}

export const getLiquidity = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "buy_sell_liquidity", params);

  return ResponseHandler.handler<GetLiquidityResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

type ExchangesVolume = Record<string, string>;
export interface GetVolumeShareResponse {
  another: ExchangesVolume;
  our: ExchangesVolume;
  total: ExchangesVolume;
}

export const getVolumeShare = async (party: string, params: DashboardQueryParams) => {
  const url = buildDashboardQueryUrl(party, "volume_share", params);

  return ResponseHandler.handler<GetVolumeShareResponse>({
    url: apiUrl(ApiModules.Dashboard, url),
    method: "GET",
  });
};

export interface GetPartiesMetricsResponse {
  created: number;
  exchanges: [
    {
      created: number;
      currencies: string[];
      exchange: string;
    },
  ];
}

export const getPartiesMetrics = async (party: string) =>
  ResponseHandler.handler<GetPartiesMetricsResponse>({
    url: apiUrl(ApiModules.Parties, `${party}/track-metrics`),
    method: "GET",
  });

interface GetPartyConfigResponse {
  slug: string;
  ticker: string;
}

export const getPartyConfig = async (party: string) =>
  ResponseHandler.handler<GetPartyConfigResponse>({
    url: apiUrl(ApiModules.Auth, `/parties/${party}/config`),
    method: "GET",
  });
