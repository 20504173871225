import { PopupContext } from "src/context/shared/PopupContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import Icons from "src/icons/Icons";
import { IconButton } from "../../Buttons/v2/IconButton";
import * as styles from "./style";

export interface SideBarContentProps extends React.ComponentPropsWithoutRef<"div"> {
  label?: string;
  afterLabel?: JSX.Element;
}

export const SideBarContent = ({ label, afterLabel, children, ...props }: SideBarContentProps) => {
  const { close } = useLateInitContext(PopupContext);

  return (
    <styles.Container {...props}>
      <styles.Panel>
        <styles.CloseWrapper>
          <IconButton onClick={close} size="l" boxed>
            {Icons.cross()}
          </IconButton>
        </styles.CloseWrapper>

        <styles.WrapperTitle>
          <styles.Title>{label}</styles.Title>
          {afterLabel}
        </styles.WrapperTitle>

        <styles.Content>{children}</styles.Content>
      </styles.Panel>
    </styles.Container>
  );
};
