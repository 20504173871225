import { Arrow } from "src/components/AllBots/Aside/MainFilter/style";
import { CheckBox } from "src/components/shared/CheckBox";
import { Input } from "src/components/shared/Forms/Inputs/Input/Input/style";
import { PanelContainer, ScrollSize } from "src/components/shared/shared";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ColumnName = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Row = styled(Arrow)`
  width: 15px;
  height: 10px;

  ::before {
    left: 4.5px;
    top: 0.5px;
    width: 4px;
    height: 4px;
  }
`;

export const Content = styled(PanelContainer)`
  position: absolute;
  top: 22px;
  left: 15px;

  width: max-content;
  min-width: 60px;
  max-height: 130px;

  display: flex;
  flex-direction: column;

  overflow: auto;

  padding: 10px;

  gap: 10px;

  ${ScrollSize}
`;

export const FindStatus = styled(Input)`
  width: 95px;
  height: 20px;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 3px 6px;
`;

export const CheckFilter = styled(CheckBox)`
  & > label:first-of-type {
    width: 15px;
    height: 15px;
  }

  & > input:checked + label:first-of-type::before {
    width: 3px;
    height: 4px;
    left: 4px;
    top: 3px;
  }
`;
