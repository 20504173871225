import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Headline1 } from "src/components/shared/Typography";
import { Subtitle } from "src/components/shared/shared";
import { BotModulesContext } from "src/context/CEX/BotModules/BotModulesProvider";
import { AbilitiesContext } from "src/context/shared/Abilities";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionProps } from "src/permissions/PermissionWrapper";
import { BotInfoProps } from "..";
import { ModulesList } from "./ModulesList";
import * as styles from "./style";

export interface BotModulesProps extends Pick<PermissionProps, "abilityName">, BotInfoProps {}

export const BotModules = observer(
  ({ botInfo: { bot_uuid, market, party }, abilityName }: BotModulesProps) => {
    const state = useLateInitContext(BotModulesContext);

    const abilitiesProps = { abilityName };

    useEffect(() => {
      if (!party) return;
      state.setParty(party);

      if (market) state.setMarket(market);

      state.loadData();
    }, [state, bot_uuid, party, market]);

    return (
      <AbilitiesContext.Provider value={abilitiesProps}>
        <styles.Container>
          <styles.Header>
            <Headline1>Module page</Headline1>
            <Subtitle>
              Party: {state.party} / Bot: {state.viewMarket}
            </Subtitle>
          </styles.Header>

          <ModulesList />
        </styles.Container>
      </AbilitiesContext.Provider>
    );
  }
);
