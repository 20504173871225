import { observer } from "mobx-react-lite";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { DEXV2LimitInfo } from "src/state/DEXV2/DEXV2Settings/Info";
import { useBaseInfoFormat } from "../../../hooks";
import { InfoInput } from "../../../shared/InfoInput";
import { InfoPanelProps } from "../../../shared/InfoPanel";
import * as styles from "./style";

interface LimitInfoDataParams {
  info: DEXV2LimitInfo;
}

const useLimitInfoData = ({ info }: LimitInfoDataParams) => {
  const limitInfo = useBaseInfoFormat({ info });

  return limitInfo;
};

export interface LimitInfoProps extends Omit<InfoPanelProps, "label" | "children"> {}

export const LimitInfo = observer(({ ...props }: LimitInfoProps) => {
  const { limitInfo } = useLateInitContext(DEXV2SettingsContext);

  const {
    last24hFeesUsdText,
    last24hFeesNativeText,
    impactAverageText,
    impactBuySellText,
    gasNativeText,
    gasUsdText,
  } = useLimitInfoData({ info: limitInfo });

  return (
    <styles.LimitInfoPanel label="Limit Info" {...props}>
      <styles.LimitInfoContent>
        <InfoInput label="Price Impact" value={impactAverageText} info={impactBuySellText} />
        <InfoInput
          label="Fees paid last 24h"
          value={last24hFeesNativeText}
          info={last24hFeesUsdText}
        />
        <InfoInput label="Transaction Cost" value={gasNativeText} info={gasUsdText} />
      </styles.LimitInfoContent>
    </styles.LimitInfoPanel>
  );
});
