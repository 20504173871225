import { DEXV2Bot } from "src/components/AllBots/Bots/DEXV2Bots/DEXV2Bot";
import { DEXV2BotColumns } from "src/components/AllBots/Bots/DEXV2Bots/DEXV2Bot/style";
import { DEXV2BotMob } from "src/components/AllBots/Bots/DEXV2Bots/DEXV2BotMob";
import { Header } from "src/components/AllBots/Bots/Header";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const DEXV2BotForPages = styled(DEXV2Bot)``;

export const DEXV2BotMobForPages = styled(DEXV2BotMob)``;

export const HeaderForPages = styled(Header)`
  grid-template-columns: ${DEXV2BotColumns};
`;
