import { LinearProgress, LinearProgressProps } from "src/components/shared/Progress";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const StyledProgressBar = styled(LinearProgress).attrs<LinearProgressProps>({
  orientation: "vertical",
})`
  width: 24px;
  height: 100%;
`;

export const ProgressLabel = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights[600]};
  font-size: ${({ theme }) => theme.fontSizes["9px"]};
  color: ${({ theme }) => theme.dashboard.textHeading};
`;
