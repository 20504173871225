import { makeAutoObservable } from "mobx";
import { DateTimeRange } from "src/components/shared/DatePickers/shared/models/dateTimeRange";
import { DurationWithMonth, getUTCRangeFromDuration } from "src/helpers/dateUtils";

const DEFAULT_RANGE: DurationWithMonth = {};

export interface IUseRangePicker {
  loadData: () => void;
}

class RangePickerStore {
  private _range!: DateTimeRange;

  private readonly _mainState: IUseRangePicker;

  private _defaultDuration: DurationWithMonth;

  constructor(state: IUseRangePicker, defaultRange: DurationWithMonth = DEFAULT_RANGE) {
    this._mainState = state;

    this._defaultDuration = defaultRange;

    this._setDefaultRange();

    makeAutoObservable(this);
  }

  get range() {
    return this._range;
  }

  private _setRange = (range: DateTimeRange) => {
    this._range = range;
  };

  get start() {
    if (this.range[0]) return this.range[0].unix();

    return null;
  }

  get end() {
    if (this.range[1]) return this.range[1].unix();

    return null;
  }

  private get _defaultRange(): DateTimeRange {
    const range = getUTCRangeFromDuration(this._defaultDuration);

    return range;
  }

  private _setDefaultRange = () => {
    const range = this._defaultRange;
    this._setRange(range);
  };

  setRange = (period: DateTimeRange) => {
    this._setRange(period);

    if (this.range[0] && this.range[1]) this._mainState.loadData();
  };

  updDataWithDefaultRange = () => {
    this._setDefaultRange();
  };
}

export default RangePickerStore;
