import { IChartApi } from "lightweight-charts";
import { useCallback, useState } from "react";
import { SeriesContext } from "src/context/Graph/Series";
import { Nullish } from "src/helpers/utils";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { IChartApiState } from "src/state/Graph/ChartApiStore";
import { IPriceScaleApiProvider } from "src/state/Graph/Series/types";

/**
 * Custom hook for managing the chart api state.
 * @returns A tuple containing the chart api and a function to update the chart state.
 */
export const useChartState = () => {
  const [chart, setChart] = useState<IChartApiState | null>(null);

  const { setPriceScalesApi } = useLateInitContext(SeriesContext);

  const getPriceScalesApi = useCallback(
    (chartApi: Nullish<IChartApi>): IPriceScaleApiProvider | null => {
      if (!chartApi) return null;
      return {
        setScalesVisibility: ({ left: leftVisible, right: rightVisible }) => {
          chartApi.applyOptions({
            leftPriceScale: { visible: leftVisible },
            rightPriceScale: { visible: rightVisible },
          });
        },
      };
    },
    []
  );

  const onChartUpdate = useCallback(
    (chart: IChartApiState | null) => {
      setChart(chart);

      const chartApi = chart?.api;

      const priceScalesApi = getPriceScalesApi(chartApi);
      setPriceScalesApi(priceScalesApi);
    },
    [getPriceScalesApi, setPriceScalesApi]
  );

  return [chart, onChartUpdate] as const;
};
