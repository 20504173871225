import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Column, useTable } from "react-table";
import {
  createStringNumberSortBy,
  createTokenPairSortBy,
} from "src/components/ExchangeAccounting/Content/TableHeader/Filters/utils";
import { DashboardPairsContext } from "src/context/CEX/Dashboard/v2/Pairs";
import { joinPair } from "src/helpers/botName";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PairsData, PairsDataKeys } from "src/state/CEX/CEXDashboard/v2/widgets/PairsV2Store";
import { Card, CardProps } from "src/components/BotsContent/CEX/shared/Card";
import { setTableColumnWidth, useTableOptions } from "../../../shared/StatsTable";
import { DecimalPercentCell, FiatCell } from "../../../shared/StatsTable/Cells";
import { DashboardV2Widget, getWidgetTitle } from "../shared/constants";
import { PairsFooter } from "./PairsFooter";
import { PriceChangeText } from "./PriceChangeText";
import * as styles from "./style";
import { getAllPairsTotal } from "./utils/totals";

const PAIRS_COLUMNS_TITLES: Record<PairsDataKeys, string> = {
  exchange: "Exchange",
  pair: "Pair",
  priceUSD: "Price",
  priceChange: "24H Price Change",
  volume: "24H Volume",
  volumeShare: "Gotbit 24H volume % share",
  liquidity: "Gotbit orderbook liquidity",
  indexImpact: "Gotbit index impact",
};

const LOCKED_COLUMNS: PairsDataKeys[] = ["exchange", "pair"];

const getColumnTitleById = (id: string) => PAIRS_COLUMNS_TITLES[id as PairsDataKeys];

const DEFAULT_COLUMN: Partial<Column<PairsData>> = {
  ...setTableColumnWidth(70),
  disableFilters: true,
  sortType: createStringNumberSortBy(),
};

export interface PairsTableProps extends Omit<CardProps, "title" | "afterTitle" | "loading"> {}

export const PairsTable = observer((props: PairsTableProps) => {
  const { data, loading } = useLateInitContext(DashboardPairsContext.Context);

  const columns = useMemo(
    (): Column<PairsData>[] => [
      {
        Header: getColumnTitleById("exchange"),
        accessor: "exchange",
        sortType: "string",
        Footer: "Total",
      },
      {
        Header: getColumnTitleById("pair"),
        accessor: "pair",
        Cell: ({ value: { base, quote } }) => <>{joinPair(quote, base)}</>,
        sortType: createTokenPairSortBy(),
      },
      {
        Header: getColumnTitleById("priceUSD"),
        accessor: "priceUSD",
        Cell: FiatCell,
      },
      {
        Header: getColumnTitleById("priceChange"),
        accessor: "priceChange",
        Cell: ({ value }) => <PriceChangeText>{value}</PriceChangeText>,
      },
      {
        Header: getColumnTitleById("volume"),
        accessor: "volume",
        Cell: FiatCell,
        Footer: <PairsFooter columnId="volume" />,
      },
      {
        Header: getColumnTitleById("volumeShare"),
        accessor: "volumeShare",
        Cell: DecimalPercentCell,
        ...setTableColumnWidth(90),
      },
      {
        Header: getColumnTitleById("liquidity"),
        accessor: "liquidity",
        Cell: FiatCell,
        Footer: <PairsFooter columnId="liquidity" />,
        ...setTableColumnWidth(90),
      },
      {
        Header: getColumnTitleById("indexImpact"),
        accessor: "indexImpact",
        Cell: DecimalPercentCell,
        ...setTableColumnWidth(90),
      },
    ],
    []
  );

  const { options, plugins } = useTableOptions({
    data,
    defaultColumn: DEFAULT_COLUMN,
    columns,
  });

  const table = useTable(options, ...plugins);

  return (
    <Card
      title={getWidgetTitle(DashboardV2Widget.Pairs)}
      afterTitle={
        <styles.StyledColumnsFilter
          instance={table}
          getTitleById={getColumnTitleById}
          lockedColumns={LOCKED_COLUMNS}
        />
      }
      loading={loading}
      {...props}
    >
      <styles.StyledPairsTable instance={table} getColumnsTotal={getAllPairsTotal} />
    </Card>
  );
});

export interface PairsTableWidgetProps extends PairsTableProps {}

export const PairsTableWidget = (props: PairsTableWidgetProps) => (
  <DashboardPairsContext.Provider>
    <PairsTable {...props} />
  </DashboardPairsContext.Provider>
);
