import { observer } from "mobx-react-lite";
import { MultiToggleButton } from "src/components/shared/Buttons/v2/MultiToggleButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { DEXV2SettingsContext } from "src/context/DEXV2/DEXV2Settings/DEXV2Settings";
import { getMobileMediaQuery } from "src/helpers/styled/media";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useMediaQuery from "src/hooks/useMediaQuery";
import { PriceInfoInput } from "../../shared/PriceInfoInput";
import { SettingsModeIcon } from "../../shared/SettingsModeIcon";
import { SettingsPanel, SettingsPanelProps } from "../../shared/SettingsPanel";
import { FormContent, FormRow, HeaderCheckbox } from "../../shared/style";
import { TABLET_SETTINGS_BREAKPOINT } from "../../style";
import * as styles from "./style";

export interface LimitPanelProps extends Omit<SettingsPanelProps, "label" | "children"> {}

export const LimitPanel = observer(({ ...props }: LimitPanelProps) => {
  const state = useLateInitContext(DEXV2SettingsContext);

  const isMobile = useMediaQuery(getMobileMediaQuery(TABLET_SETTINGS_BREAKPOINT));
  const switchesOrientation = isMobile ? "column" : "row";
  return (
    <SettingsPanel
      label="Limit Settings"
      labelIcon={<SettingsModeIcon mode="limit" />}
      checkbox={
        <HeaderCheckbox
          label="Use Receiver"
          checked={state.data.limit_data.use_receiver}
          onChange={state.getHandler("limit_data.use_receiver")}
        />
      }
      {...props}
    >
      <FormContent>
        <styles.ModesSwitch
          label="Mode"
          name="mode"
          items={state.switchOptions("mod")}
          selectedItem={state.data.limit_data.mod}
          onChange={state.getHandler("limit_data.mod")}
          $orientation={switchesOrientation}
        />
        <LabeledInput
          type="number"
          step="any"
          label="Period"
          {...state.getInputProps("limit_data.period")}
        />

        <styles.CompareFormRow>
          <MultiToggleButton
            items={state.switchOptions("trigger_compare")}
            value={state.data.limit_data.trigger_compare}
            onChange={state.setSwitchOption("trigger_compare")}
            disableSelected
          />
          <LabeledInput
            type="number"
            step="any"
            label="Price"
            {...state.getInputProps("limit_data.price")}
          />
        </styles.CompareFormRow>

        <FormRow>
          <PriceInfoInput
            type="number"
            step="any"
            label="Min Amount"
            price={state.getFieldUSD("limit_data.min_amount")}
            {...state.getInputProps("limit_data.min_amount")}
          />
          <PriceInfoInput
            type="number"
            step="any"
            label="Max Amount"
            price={state.getFieldUSD("limit_data.max_amount")}
            {...state.getInputProps("limit_data.max_amount")}
          />
        </FormRow>
      </FormContent>
    </SettingsPanel>
  );
});
