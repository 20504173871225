import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { PanelContainer, ScrollSize } from "../shared";

export const Container = styled(PanelContainer)`
  position: relative;

  height: 100%;

  display: flex;
  /* flex-direction: column; */

  overflow-y: auto;

  ${ScrollSize}
`;

export const NotesForm = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;

  /* box-sizing: border-box; */
  /* border: ${({ theme }) => theme.borders.solidGray};
  border-radius: ${({ theme }) => theme.radii["4px"]};

  background-color: ${({ theme }) => theme.contentBackgroundColor}; */
  /* overflow-y: auto;
  ::-webkit-scrollbar {
    height: 3px;
  } */
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  /* height: 36.5px; */
  padding: 10px;
  /* border-bottom: ${({ theme }) => theme.borders.solidGray}; */
`;

export const Title = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  color: ${({ theme }) => theme.textColor};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  padding: 0px 10px 10px;

  /* height: 178px; */
  overflow-y: auto;

  ${ScrollSize}
`;

export const TextArea = styled(TextareaAutosize)`
  flex: 1 1 auto;

  font-weight: ${({ theme }) => theme.fontWeights.bold};
  cursor: auto;
  font-size: ${({ theme }) => theme.fontSizes["16px"]};
  color: ${({ theme }) => theme.primaryColor};
  /* color: ${({ theme }) => theme.textColor}; */
  resize: none;
  outline: none;
  border: none;
  background: inherit;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  /* ::-webkit-scrollbar {
    width: 3px;
  } */

  ${ScrollSize}

  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.radii["10px"]};
    cursor: pointer;
    background-color: rgba(180, 180, 180, 0.5);
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  /* border-top: ${({ theme }) => theme.borders.solidGray}; */
  align-items: center;
  /* height: 60px; */
  padding: 8px 10px;
`;
