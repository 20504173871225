import styled from "styled-components";

export enum KPIAreas {
  Aum = "aum",
  Balance = "balance",
  Impact = "impact",
  PNL = "pnl",
  Tokens = "tokens",
}

export const KPIContainer = styled.div`
  position: relative;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 2fr)) minmax(100px, 3fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "${KPIAreas.Aum}  ${KPIAreas.Balance}   ${KPIAreas.Impact} ${KPIAreas.PNL}"
    "${KPIAreas.Aum}  ${KPIAreas.Balance}   ${KPIAreas.Impact} ${KPIAreas.Tokens}";
  gap: 8px;
`;

export const KPIBaseRow = styled.div`
  width: 100%;

  display: flex;
  gap: 8px;
`;

export const KPIBaseWrapper = styled(KPIBaseRow)`
  flex-shrink: 1;
`;

export const PNLWrapper = styled(KPIBaseRow)`
  height: 100%;
`;

export const PNLTokensWrapper = styled(KPIBaseRow)`
  flex-direction: column;
  width: 100%;
`;
