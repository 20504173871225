import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import * as styles from "./style";

interface EditNameProps {
  name: string;
  updNameRequest: (
    e: React.FormEvent,
    name: string,
    type: "API" | "ACCOUNT",
    changeSaveStatus: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  type: "API" | "ACCOUNT";
}

export const EditName = observer(({ name, updNameRequest, type }: EditNameProps) => {
  const [newName, setNewName] = useState(name);

  const [saveState, setSaveState] = useState(true);

  const changeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSaveState(false);
    } else setSaveState(true);

    setNewName(e.target.value);
  }, []);

  useEffect(() => {
    setNewName(name);
    setSaveState(true);
  }, [name]);

  return (
    <styles.Wrapper
      onSubmit={(e) => updNameRequest(e, newName, type, setSaveState)}
      onKeyPress={(event) => event.key === "Enter" && event.preventDefault()}
    >
      <LabeledInput
        label="Name"
        value={newName}
        onChange={(e) => {
          changeValue(e);
        }}
        // readOnly={ true}
      />

      <styles.SaveNameButton saved={saveState} />
    </styles.Wrapper>
  );
});
