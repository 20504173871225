import { useEventCallback } from "@mui/material";
import { useCallback } from "react";
import { useTooltipContext } from "src/context/Graph/Tooltip";
import { setTextClipboard } from "src/helpers/clipboard";

interface UseTooltipDataCopyParams {
  showTooltip: boolean;
}

export const useTooltipDataCopy = ({ showTooltip }: UseTooltipDataCopyParams) => {
  const tooltipState = useTooltipContext();

  const copyTooltipData = useEventCallback(() => {
    const seriesData = tooltipState.seriesDataString;
    setTextClipboard(seriesData);
  });

  const onCopy = useCallback(() => {
    if (showTooltip) {
      copyTooltipData();
    }
  }, [copyTooltipData, showTooltip]);

  return onCopy;
};
