import { ThousandthSeparator } from "src/components/shared/ThousandthSeparator";
import { OrderState } from "src/state/CEX/multiGrid/MultiGridState";
import * as styles from "../style";

interface OrderCellProps {
  amount: string;
}

interface StateOrderCellProps extends OrderCellProps {
  state: OrderState;
}

interface SideOrderCellProps extends OrderCellProps {
  type: "sell" | "buy" | "";
  side: string;
}

export const StateOrderCell = ({ state, amount }: StateOrderCellProps) => (
  <styles.StateOrder state={state}>
    <ThousandthSeparator value={amount} />
  </styles.StateOrder>
);

export const SideOrderCell = ({ type, side, amount }: SideOrderCellProps) => (
  <styles.Order side={side || amount ? type : ""}>
    <ThousandthSeparator value={amount} />
  </styles.Order>
);
