import { IDisposable } from "../utils";

export type ChildDisposableStorages = Record<string, IDisposable>;

/**
 * helper function for call destroy in child store
 * @param storages - object of child storages
 *
 */
export const destroyer = (storages: ChildDisposableStorages) => {
  for (const key of Object.keys(storages)) {
    storages[key].destroy();
  }
};
