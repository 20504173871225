import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { checkParty } from "src/helpers/checkParty";
import { useAppState } from "../../state";
import * as styles from "./style";

interface Props {
  abilityName: string | string[];
  party?: string;
  load?: boolean;
  children: JSX.Element;
}

const NO_PERMISSIONS_TEXT = "You don't have enough rights...";

export const ContentPermission = observer(({ abilityName, party = "", load, children }: Props) => {
  const { userState } = useAppState();

  if (typeof abilityName === "object") {
    for (const el of abilityName) {
      if (checkParty(userState.abilities, el, party)) {
        return { ...children };
      }
    }

    return <styles.Message>{NO_PERMISSIONS_TEXT}</styles.Message>;
  }

  const checkPermsn = checkParty(userState.abilities, abilityName, party);

  // for only root pages
  if (userState.abilities.root) {
    return { ...children };
  }

  // for all abilities on bots pages
  if (userState.abilities[abilityName]) {
    if (checkPermsn) return { ...children };
  }

  // for all abilities on other pages
  if (userState.abilities[abilityName]) {
    return { ...children };
  }

  if (load) return <Loader show={load} />;

  return <styles.Message>{NO_PERMISSIONS_TEXT}</styles.Message>;
});
