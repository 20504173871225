import {
  SuggestionLabeledInput,
  SuggestionLabeledInputProps,
} from "src/components/shared/Forms/Variants";
import { OmitStrict } from "src/helpers/utils";
import * as styles from "./style";

export interface WalletLabeledInputProps
  extends OmitStrict<
    SuggestionLabeledInputProps<HTMLButtonElement>,
    "suggestionGap" | "getSuggestion"
  > {
  addEnabled?: boolean;
  onAdd?: () => void;
}

export const WalletLabeledInput = ({ addEnabled, onAdd, ...props }: WalletLabeledInputProps) => (
  <SuggestionLabeledInput<HTMLButtonElement>
    getSuggestion={(getRef) =>
      addEnabled ? (
        <styles.SaveWalletButton size="s" ownRef={getRef} onClick={onAdd}>
          Save
        </styles.SaveWalletButton>
      ) : null
    }
    suggestionGap={6}
    {...props}
  />
);
