import { ExpandIcon as BaseExpandIcon } from "src/components/shared/Buttons/v2/DropdownButton/style";
import { StyledProps } from "src/helpers/styled/types";
import styled from "styled-components";

export interface ExpandIconProps {
  hide?: boolean;
}

export interface ExpandIconStyledProps extends StyledProps<ExpandIconProps> {}

export const ExpandIcon = styled(BaseExpandIcon)<ExpandIconStyledProps>`
  transform: ${({ $hide }) => ($hide ? "rotate(-90deg)" : "rotate(0deg)")};
`;
