import React, { useCallback } from "react";
import { StringMenuItem } from "src/components/AllBots/Aside/MainFilter/BotFilterPanel";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { Headline2 } from "src/components/shared/Typography";
import * as styles from "./style";

export type GraphType = "Native" | "Balances";

type GraphTypeMenuOptions<T extends GraphType = GraphType> = T extends GraphType
  ? StringMenuItem<T>
  : never;

export interface GraphsHeaderProps {
  currentType: GraphType;
  onTypeChange: React.Dispatch<GraphType>;
  onRefresh?: (type: GraphType) => void;
}

const GRAPH_OPTIONS: GraphTypeMenuOptions[] = [
  { label: "Balances", value: "Balances" },
  { label: "Native", value: "Native" },
];

export const GraphsHeader = ({
  currentType,
  onTypeChange: setCurrentType,
  onRefresh,
}: GraphsHeaderProps) => {
  const onItemChange = useCallback(
    (value: string) => {
      const key = value as GraphType;
      setCurrentType(key);
    },
    [setCurrentType]
  );

  const onGraphRefresh = useCallback(() => {
    onRefresh?.(currentType);
  }, [currentType, onRefresh]);

  return (
    <styles.Header>
      <styles.Wrapper>
        <Headline2>Graphs</Headline2>
      </styles.Wrapper>

      <styles.Wrapper>
        <styles.SwitchGraphs
          border={false}
          selectedItem={currentType}
          direction="row"
          menuItems={GRAPH_OPTIONS}
          onChange={onItemChange}
        />
        <ReloadButton onClick={onGraphRefresh} />
      </styles.Wrapper>
    </styles.Header>
  );
};
