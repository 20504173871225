import { observer } from "mobx-react-lite";
import { IExternalSaveLoadAdapter } from "src/charting_library/charting_library";
import { ReloadButton } from "src/components/shared/Buttons/v2/variants/IconButtons/ReloadIcon";
import { ErrorBoundary } from "src/components/shared/ErrorBoundary";
import { Loader } from "src/components/shared/Loader";
import { Headline3 } from "src/components/shared/Typography";
import { AnalyticsContext } from "src/context/CEX/Analytics";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { Chart } from "./Chart";
import { FooterPanel } from "./FooterPanel";
import * as styles from "./style";

export const ChartPanel = observer(() => {
  const state = useLateInitContext(AnalyticsContext);

  const saveLoadAdapter: IExternalSaveLoadAdapter = {
    getAllCharts: async (): Promise<any> => [],
    removeChart: (id): any => {
      state.fetchDeleteAnalytic(String(id));
    },
    saveChart: (data): any => {
      state.saveMediator(data);
    },
    getChartContent: async (data): Promise<string> => await state.fetchCurrentChart(String(data)),
    getAllStudyTemplates: async (): Promise<any> => [],
    removeStudyTemplate: (): any => [],
    saveStudyTemplate: (): any => [],
    getStudyTemplateContent: (): any => [],
    getDrawingTemplates: (): any => [],
    loadDrawingTemplate: (): any => [],
    removeDrawingTemplate: (): any => [],
    saveDrawingTemplate: (): any => [],
  };

  return (
    <styles.Container>
      <styles.HeaderWrapper>
        <Headline3>{state.exchange.toUpperCase()} </Headline3>

        <ReloadButton size="s" onClick={() => state.updChartCb()} />
      </styles.HeaderWrapper>

      <ErrorBoundary>
        {state.currentPair ? (
          <Chart
            setUpdChartCb={state.setUpdChartCb}
            setChartWidget={state.setChartWidget}
            setForceUpdChart={state.setForceUpdChart}
            save_load_adapter={saveLoadAdapter}
            loadCurrentAnalytic={state.selectCurrentAnalytic}
          />
        ) : (
          <Loader show />
        )}
      </ErrorBoundary>

      <FooterPanel />
    </styles.Container>
  );
});
