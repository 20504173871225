import { ChildrenProps } from "src/helpers/utils";
import { createLateInitContext, useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { ScopesStore } from "src/state/UserManager/Scopes/ScopesStore";
import { UserManagerContext } from "./UserManager";

export const ScopesContext = createLateInitContext<ScopesStore>();

type ScopesProviderProps = ChildrenProps;

export const ScopesProvider = ({ children }: ScopesProviderProps) => {
  const state = useLateInitContext(UserManagerContext);

  const scopesStore: ScopesStore = useLocalStore(ScopesStore, state);

  return <ScopesContext.Provider value={scopesStore}>{children}</ScopesContext.Provider>;
};
