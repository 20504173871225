import React from "react";
import * as styles from "./style";
import { TVChartModule } from "./TVChartModule";
import { OrderBookModule } from "./OrderBookModule";
import { TradeHistoryModule } from "./TradeHistoryModule";

export const SettingsModules = () => (
  <styles.Content>
    <TVChartModule />
    <OrderBookModule />
    <TradeHistoryModule />
  </styles.Content>
);
