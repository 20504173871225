import { makeAutoObservable } from "mobx";
import { makeLoggable } from "src/helpers/logger";
import { ValueOf } from "src/helpers/utils";
import { ChainConnectionState, IChainConnection } from "src/state/chain/ChainConnectionStore";
import { IChainIDProvider } from "src/state/chain/ChainProviderStore";

export const BotWalletConnectionState = {
  ...ChainConnectionState,
  WrongNetwork: "WrongNetwork",
} as const;

export type BotWalletConnectionStateType = ValueOf<typeof BotWalletConnectionState>;

export interface IBotWalletConnectionProvider {
  get connectionState(): BotWalletConnectionStateType;
}

export default class BotWalletConnectionStore implements IBotWalletConnectionProvider {
  private _botChainIdProvider: IChainIDProvider;

  private _chainConnection: IChainConnection;

  constructor(botChainIdProvider: IChainIDProvider, chainConnection: IChainConnection) {
    this._botChainIdProvider = botChainIdProvider;
    this._chainConnection = chainConnection;

    makeAutoObservable(this);

    makeLoggable(this, { connectionState: true });
  }

  get botChainId() {
    return +this._botChainIdProvider.chainID;
  }

  private get _chainAccount() {
    return this._chainConnection.currentAccount;
  }

  private get _chainId() {
    return this._chainConnection.chainID;
  }

  get chainAccount() {
    return this._chainAccount;
  }

  private get _chainConnectionState() {
    return this._chainConnection.connectionState;
  }

  connectWallet = () => {
    this._chainConnection.connectWallet(this.botChainId);
  };

  disconnectWallet = () => {
    this._chainConnection.disconnectWallet();
  };

  private get _isWrongNetwork() {
    return (
      this._chainConnectionState === ChainConnectionState.Connected &&
      this.botChainId !== this._chainId
    );
  }

  get connectionState() {
    if (this._isWrongNetwork) {
      return BotWalletConnectionState.WrongNetwork;
    }
    return this._chainConnection.connectionState;
  }

  destroy = () => {};
}
