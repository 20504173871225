import { ChangeEventHandler, useCallback } from "react";
import { Input, InputProps } from "src/components/shared/Forms/Inputs";
import { getChangeEventValue } from "src/helpers/forms/inputs";
import * as styles from "./style";

export interface RangeFilterInputProps extends Omit<InputProps, "onChange"> {
  operator?: string;
  onChange: (value: number | "") => void;
}

export const RangeFilterInput = ({
  operator,
  style,
  className,
  onChange: outerOnChange,
  ...props
}: RangeFilterInputProps) => {
  const containerProps = { style, className };

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const value = getChangeEventValue(event) as number;

      if (!isNaN(value)) {
        return outerOnChange(value);
      }
      return outerOnChange("");
    },
    [outerOnChange]
  );

  return (
    <styles.Container {...containerProps}>
      <styles.OperatorText>{operator}</styles.OperatorText>
      <Input type="number" onChange={onChange} {...props} />
    </styles.Container>
  );
};
