import { Controller, useFormContext } from "react-hook-form";
import { getFieldError } from "src/components/BotsContent/CEX/ExpertSystem/shared/utils/errors";
import { IStrategyCreator } from "src/modules/expertSystem";
import { ERRORS_MSGS } from "src/validation-schemas";
import {
  ChangeSettingsPanel,
  ChangeSettingsPanelProps,
} from "../../../../shared/ChangeSettingsModule/ChangeSettingsPanel";
import { FormContent, FormContentProps } from "./FormContent";

interface ChangeSettingsProps
  extends Omit<ChangeSettingsPanelProps, "error">,
    Pick<FormContentProps, "setModuleParam" | "softParam"> {}

export const ChangeSettings = ({ softParam, setModuleParam, ...props }: ChangeSettingsProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IStrategyCreator>();

  const moduleIndex = props.shellProps.index;
  const moduleParamName = `actions.${moduleIndex}.soft_params.settings` as const;
  const error = getFieldError(errors, moduleParamName);

  return (
    <ChangeSettingsPanel error={error?.message} {...props}>
      <Controller
        control={control}
        name={moduleParamName}
        rules={{ required: ERRORS_MSGS.isRequired }}
        render={() => (
          <FormContent
            softParam={softParam}
            moduleIndex={props.shellProps.index}
            setModuleParam={setModuleParam}
          />
        )}
      />
    </ChangeSettingsPanel>
  );
};
