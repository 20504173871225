import styled, { css } from "styled-components";

interface HeaderProps {
  column?: any;
}

export const Table = styled.table`
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  table-layout: fixed;
  font-family: inherit;
  background: inherit;
  border-collapse: collapse;
  border-radius: ${({ theme }) => theme.radii["4px"]};
`;

export const TableHead = styled.thead`
  width: 100%;
`;

export const TableHeader = styled.th`
  color: ${({ theme }) => theme.inputTextColor};
`;

export const TableBody = styled.tbody`
  height: 100%;

  display: block;

  overflow-y: scroll;
  color: #767676;

  padding-right: 3px;

  ::-webkit-scrollbar-corner {
    background-color: inherit;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

export const TableRow = styled.tr`
  width: 100%;

  display: table;

  table-layout: fixed;
  font-size: ${({ theme }) => theme.fontSizes["12px"]};
`;

export const TableHeadRow = styled(TableRow)`
  width: calc(100% - 6px);
  height: 30px;

  padding-right: 3px;

  font-size: ${({ theme }) => theme.fontSizes["15px"]};
`;

export const TableCell = styled.td`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes["10px"]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: inherit;
`;

export const TableSortMixin = css<HeaderProps>`
  ${({ column }) => css`
    ${column.getIsSorted() &&
    css`
      padding-right: 15px;
      ::after {
        color: ${({ theme }) => theme.primaryColor};
        content: "${column.getIsSorted() === "desc" ? "▼" : "▲"}";
        position: absolute;
      }
    `}
    ${column.getCanSort() &&
    css`
      cursor: pointer;
    `}
  `}
`;
