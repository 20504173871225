import { DragIcon } from "../../shared/DragIcon";
import * as styles from "./style";

interface PartyProps extends styles.ListItemProps {
  name: string;
}

export const Party = ({ name, ...props }: PartyProps) => (
  <styles.ListItem {...props}>
    {name}
    <DragIcon />
  </styles.ListItem>
);
