import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Divider = styled.hr`
  height: 2px;

  margin: 0;

  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.dashboard.borderDefault};
`;
