import { Label } from "src/components/shared/Forms/Label";
import { ComponentPropsWithoutRef } from "react";
import { AddressCellOwnProps } from "../../../Stats/Tables/TablePanel/AddressCell";
import * as styles from "./style";

export interface LabeledAddressProps extends AddressCellOwnProps, ComponentPropsWithoutRef<"div"> {
  label: string;
}

export const LabeledAddress = ({
  label,
  address,
  scannerLink,
  isShort,
  ...props
}: LabeledAddressProps) => {
  const addressProps = { address, scannerLink, isShort };

  return (
    <styles.Container {...props}>
      <Label>{label}</Label>
      <styles.AddressCell {...addressProps} />
    </styles.Container>
  );
};
