import { makeAutoObservable } from "mobx";
import { logError } from "src/helpers/network/logger";
import { HashType } from "./HashesTypeStore";
import { HashesPaginationResponse } from "./Providers/HashesPaginationFetcherProvider";

export interface HashesPaginationConfig {
  itemsPerPage?: number;
  pagesCount?: number;
}

export type HistoryHashes<T extends HashType> = Exclude<HashesPaginationResponse<T>, "pagesCount">;

export interface IHashesPaginationStoreProvider<T extends HashType> {
  getItems: (
    fromPage: number,
    pageSize: number
  ) => Promise<HashesPaginationResponse<T> | undefined>;
}
export default class HashesPaginationStore<T extends HashType> {
  private _currentPage = 0;

  private _pagesCount = 0;

  private _itemsPerPage = 0;

  private _hashes: HistoryHashes<T>;

  private _loading = false;

  private _provider: IHashesPaginationStoreProvider<T>;

  private _config: HashesPaginationConfig;

  private readonly _type: T;

  constructor(
    type: T,
    provider: IHashesPaginationStoreProvider<T>,
    config: HashesPaginationConfig = {}
  ) {
    makeAutoObservable(this);

    this._type = type;
    this._hashes = {
      type: this._type,
      items: [],
    };
    this._provider = provider;
    this._config = config;

    this._initConfig(this._config);
  }

  private _initConfig = ({ itemsPerPage, pagesCount }: HashesPaginationConfig) => {
    this._setItemsPerPage(itemsPerPage ?? 10);
    this._setPagesCount(pagesCount ?? 0);
  };

  get currentPage() {
    return this._currentPage;
  }

  setCurrentPage = (page: number) => {
    this._currentPage = page;
  };

  get pagesCount() {
    return this._pagesCount;
  }

  private _setPagesCount = (count: number) => {
    this._pagesCount = count;
  };

  get itemsPerPage() {
    return this._itemsPerPage;
  }

  private _setItemsPerPage = (itemsPerPage: number) => {
    this._itemsPerPage = itemsPerPage;
  };

  get loading() {
    return this._loading;
  }

  private _setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  get hashes() {
    return this._hashes;
  }

  private _setHashes(hashes: HistoryHashes<T>) {
    this._hashes = hashes;
  }

  getHashes = async (fromPage: number, pageSize: number) => {
    this._setLoading(true);
    try {
      const response = await this._provider.getItems(fromPage, pageSize);
      if (response) {
        const { items, pagesCount, type } = response;
        this._setHashes({ items, type });
        if (pagesCount) {
          this._setPagesCount(pagesCount);
        }
        return true;
      }

      this._resetHashes();
    } catch (err) {
      logError(err);
      this._resetHashes();
    } finally {
      this._setLoading(false);
    }
  };

  private _resetHashes = () => {
    this._hashes = {
      type: this._type,
      items: [],
    };
  };

  destroy = () => {};
}
