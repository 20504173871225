import { observer } from "mobx-react-lite";
import React from "react";
import { StatusConnect } from "src/components/shared/Indicators/StatusConnect";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { TerminalRequestMode } from "src/state/CEX/CEXExchange/shared/TerminalSettingsStore";
import { REQUEST_MODES } from "../../..";
import { SettingsModule } from "../SettingsModule";
import { RequestModeSwitcher } from "../shared";

export const OrderBookModule = observer(() => {
  const mainState = useLateInitContext(ExchangeContext);

  const { orderBookState } = mainState;

  const { settingsConfig } = mainState.terminalSettingsState;

  return (
    <SettingsModule title="Order Book">
      <RequestModeSwitcher
        key="order_book_modes"
        items={REQUEST_MODES}
        disabled={!mainState.terminalSettingsState.wsModeSupport}
        selectedItem={settingsConfig.orderBookModule.requestMode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          orderBookState.setStreamMode(e.target.value as TerminalRequestMode)
        }
      />

      <StatusConnect
        type="data"
        status={orderBookState.exchStatusConnection}
        delay={orderBookState.exchangeDelay}
      />
    </SettingsModule>
  );
});
