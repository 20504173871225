import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef, useState } from "react";
import { ExchangeContext } from "src/context/CEX/Exchange/Exchange";
import { getSelectorList } from "src/helpers/forms/selectors";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import * as styles from "./style";

const MENU_ITEMS = ["Limit", "Stop", "Floating"] as const;
type OrderType = (typeof MENU_ITEMS)[number];

export interface OrderSwitchProps extends ComponentPropsWithoutRef<"div"> {
  children: (JSX.Element | null)[];
}

export const OrderSwitch = observer(({ children }: OrderSwitchProps) => {
  const { wsSupport } = useLateInitContext(ExchangeContext);

  const [currentTab, setCurrentTab] = useState<OrderType>("Limit");

  const onChangeTab = (value: string) => {
    const tab = value as OrderType;
    setCurrentTab(tab);
  };

  return (
    <>
      {/* if exchange supported websocket connection, 
    then show stop and floating orders form  */}
      {wsSupport && (
        <styles.OrderFormSwitch
          border={false}
          selectedItem={currentTab}
          direction="row"
          menuItems={getSelectorList(MENU_ITEMS)}
          onChange={onChangeTab}
        />
      )}
      {children?.filter((child) => child?.key === currentTab)}
    </>
  );
});
