import { observer } from "mobx-react-lite";
import { Loader } from "src/components/shared/Loader";
import { Headline2 } from "src/components/shared/Typography";
import { MultiGridStateContext } from "src/context/CEX/MultiGrid/MultiGrid";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { ColumnWrapper } from "../../shared";
import * as styles from "./style";

export const LogsPanel = observer(() => {
  const state = useLateInitContext(MultiGridStateContext);

  return (
    <ColumnWrapper style={{ gridArea: "logs" }}>
      <Headline2>Logs</Headline2>

      <styles.Panel>
        <styles.Container>
          {state.logs.map((el, index) => {
            const key = el + index;
            return <styles.LogRowText key={key}>{`${index + 1}. ${el}`}</styles.LogRowText>;
          })}
        </styles.Container>

        <Loader show={state.isLoading} />
      </styles.Panel>
    </ColumnWrapper>
  );
});
