import { IChartApi, createChart } from "lightweight-charts";

export interface IChartApiState {
  get api(): IChartApi | null;

  get isDisposed(): boolean;

  dispose: () => void;
}

type CreateChartParams = Parameters<typeof createChart>;

/** Wrapper around lightweight-charts chart api with disposal state
 * for cases when hooks may be called after chart is removed */
export class ChartApiStore implements IChartApiState {
  private _disposed: boolean;

  private _chartApi: IChartApi | null;

  private constructor(...params: CreateChartParams) {
    this._chartApi = createChart(...params);
    this._disposed = false;
  }

  static createChartApi(...params: CreateChartParams): IChartApiState {
    return new ChartApiStore(...params);
  }

  get api(): IChartApi | null {
    if (this._disposed) return null;
    return this._chartApi;
  }

  get isDisposed(): boolean {
    return this._disposed;
  }

  dispose = () => {
    this._chartApi?.remove();
    this._disposed = true;
    this._chartApi = null;
  };
}
