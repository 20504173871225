import { GetPartyInfoResponse } from "src/api/userManager/partiesAPI";
import { EditGroupInfo, EditSantimentInfo } from "./PartyInfoStore";

export const PartyInfoMapper = {
  groupInfoMapFrom: (data: GetPartyInfoResponse): EditGroupInfo => {
    const { tg_chat_id, client_group, admin_group, admin } = data;

    return { tg_chat_id, client_group, admin_group, admin };
  },

  santimentInfoMapFrom: (data: GetPartyInfoResponse): EditSantimentInfo => {
    const { slug, ticker } = data;

    return { slug, ticker };
  },
};
