import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef } from "react";
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  IExternalSaveLoadAdapter,
} from "src/charting_library/charting_library";
import { widget } from "src/charting_library/charting_library.esm";
import { Loader } from "src/components/shared/Loader";
import { AnalyticsContext } from "src/context/CEX/Analytics";
import { useDefaultTVChartProps } from "src/hooks/useDefaultChartProps";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import useLocalStore from "src/hooks/useLocalStore";
import { useSubscribableStore } from "src/hooks/useSubscribableStore";
import DatafeedStore from "src/state/CEX/CEXExchange/TVChart/DatafeedStore";
import StreamingStore from "src/state/CEX/CEXExchange/TVChart/StreamingStore";
import { ThemeContext } from "styled-components";
import * as styles from "./style";

export interface TVChartProps {
  setUpdChartCb?: (cb: () => Promise<void>) => void;
  setChartWidget?: (chart: IChartingLibraryWidget) => void;
  setForceUpdChart?: (cb: () => void) => void;
  loadCurrentAnalytic?: () => void;
  save_load_adapter?: IExternalSaveLoadAdapter;
  updTVChart?: () => void;
  pricePrecision?: number;
}

export const Chart = observer(
  ({
    setUpdChartCb,
    setChartWidget,
    setForceUpdChart,
    loadCurrentAnalytic,
    save_load_adapter,
  }: TVChartProps) => {
    const tvWidgetRef = useRef<IChartingLibraryWidget | null>(null);

    const mainState = useLateInitContext(AnalyticsContext);

    const streamState = useLocalStore(StreamingStore, mainState);

    const state = useLocalStore(DatafeedStore, tvWidgetRef, streamState);

    useSubscribableStore(state);

    const theme = useContext(ThemeContext);

    const defaultProps = useDefaultTVChartProps();

    const widgetOptions: ChartingLibraryWidgetOptions = {
      debug: false,
      // pass a string with the name of the bot to send requests to the exchange
      symbol: `${state.pair}`,
      datafeed: state as any,
      interval: defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
      container: "tv_chart_container",
      library_path: defaultProps.library_path as string,
      locale: defaultProps.locale,
      disabled_features: defaultProps.disabled_features,
      enabled_features: defaultProps.enabled_features,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studies_overrides,
      // theme mode
      overrides: defaultProps.overrides,
      custom_css_url: defaultProps.custom_css_url,
      save_load_adapter,
    };

    const componentDidMount = () => {
      if (!state.pair || !state.exchange) return;

      widgetOptions.symbol = `${state.pair}`;

      // eslint-disable-next-line new-cap
      const tvWidget1 = new widget(widgetOptions);

      tvWidgetRef.current = tvWidget1;

      const tvWidget = tvWidgetRef.current;

      tvWidget.onChartReady(() => {
        // set widget for store the current state of the chart
        // state.setChart(tvWidget?.activeChart());

        // search volume graph id
        const studyId = tvWidget?.activeChart().getAllStudies()[0].id;

        // separation of the volume chart from the main chart
        if (studyId) {
          tvWidget?.activeChart().getStudyById(studyId).unmergeDown();

          const panesHeight = tvWidget?.activeChart().getAllPanesHeight();

          if (panesHeight)
            tvWidget
              ?.activeChart()
              .setAllPanesHeight([panesHeight[0] - panesHeight[0] * 0.2, panesHeight[0] * 0.2]);
        }

        if (tvWidget && setChartWidget) setChartWidget(tvWidget);

        if (loadCurrentAnalytic) loadCurrentAnalytic();

        tvWidget?.subscribe("chart_loaded", () => {
          if (defaultProps.overrides) state.toggleChartTheme();
        });
      });
    };

    const componentWillUnmount = () => {
      if (tvWidgetRef.current !== null) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
      }
    };

    useEffect(() => {
      componentDidMount();

      return () => {
        componentWillUnmount();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // set upd cb candels
    useEffect(() => {
      if (setUpdChartCb) setUpdChartCb(state.updateData);
    }, [setUpdChartCb, state]);

    useEffect(() => {
      if (setForceUpdChart) setForceUpdChart(componentDidMount);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setForceUpdChart]);

    // switch theme for chart
    useEffect(() => {
      state.toggleChartTheme();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, theme]);

    useEffect(() => {
      if (defaultProps.overrides) state.setOverrides(defaultProps.overrides);
    }, [defaultProps, state]);

    return (
      <styles.Content>
        <styles.TVChartContainer id="tv_chart_container" />
        <Loader show={state.isLoading} />
      </styles.Content>
    );
  }
);
