import { observer } from "mobx-react-lite";
import { IGridForm } from "src/state/CEX/liquidityTab/GridForm";
import { useTheme } from "styled-components";
import { ProfitStatsProps } from "../ProfitStats";
import StatsPanel from "../StatsPanel";
import { StatsItemDataProps } from "../StatsPanel/StatsList";

export interface CurrentStatsProps extends ProfitStatsProps {
  form: IGridForm;
}

export const CurrentStats = observer(({ stats, form, ...props }: CurrentStatsProps) => {
  const theme = useTheme();

  const currentStatsItems: StatsItemDataProps[] = [
    {
      name: "Quote",
      value: stats.current.quote,
    },
    {
      name: "Base",
      value: stats.current.base,
    },
    {
      name: "Price",
      value: stats.current.price,
      itemProps: {
        value: () => ({
          style: {
            color: +form.data.position.quote < 0 ? theme.successColor : theme.dangerColor,
          },
        }),
      },
    },
  ];

  return <StatsPanel title="Current" items={currentStatsItems} {...props} />;
});
