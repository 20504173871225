import { StyledProps } from "src/helpers/styled/types";
import styled from "styled-components";

export interface BadgeProps {
  color?: string;
  backgroundColor?: string;
}

interface StyledBadgeProps extends StyledProps<BadgeProps> {}

export const StyledBadge = styled.span<StyledBadgeProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);

  min-width: 12px;
  height: 12px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;

  padding: 0 4px;

  border-radius: 6px;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights[500]};
  font-size: ${({ theme }) => theme.fontSizes["8px"]};

  background-color: ${({ theme, $backgroundColor }) => $backgroundColor || theme.primaryColor};
  color: ${({ theme, $color }) => $color || theme.backgroundWhiteColor};
`;
