import { observer } from "mobx-react-lite";
import { SubmitButton } from "src/components/shared/Buttons/v2/variants/SubmitButton";
import { LabeledInput } from "src/components/shared/Forms/Inputs";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper } from "src/permissions/PermissionWrapper";
import { DEXSettingsContext } from "../../../shared/context/SettingsProvider";
import { SubTitle, Wrapper } from "../../style";
import * as styles from "./style";

export interface SwapSettingsProps {
  party: string;
  abilityName: string;
}

export const SwapSettings = observer(({ party, abilityName }: SwapSettingsProps) => {
  const state = useLateInitContext(DEXSettingsContext);

  return (
    <styles.Container>
      <SubTitle>Swap settings</SubTitle>
      <Wrapper>
        <LabeledInput
          label="Slip page"
          type="number"
          value={state.data.settings.slipPage}
          onChange={state.getHandler("settings.slipPage")}
          errorHint={state.errors.settings?.slipPage}
          step="any"
        />
        <LabeledInput
          label="Gas Mult"
          type="number"
          value={state.data.settings.gasMult}
          onChange={state.getHandler("settings.gasMult")}
          errorHint={state.errors.settings?.gasMult}
          step="any"
        />
        <LabeledInput
          label="Gas Max"
          type="number"
          value={state.data.settings.gasMax}
          onChange={state.getHandler("settings.gasMax")}
          errorHint={state.errors.settings?.gasMax}
          step="any"
        />
      </Wrapper>
      <Wrapper>
        <LabeledInput
          label="Time Seconds"
          type="number"
          value={state.data.settings.timeSeconds}
          onChange={state.getHandler("settings.timeSeconds")}
          errorHint={state.errors.settings?.timeSeconds}
          step="any"
        />
        <PermissionWrapper party={party} abilityName={abilityName}>
          <styles.WrapperForButton>
            <SubmitButton size="m">Save</SubmitButton>
          </styles.WrapperForButton>
        </PermissionWrapper>
      </Wrapper>
    </styles.Container>
  );
});
