import { observer } from "mobx-react-lite";
import { AbilitiesContext } from "src/context/DEXV2/DEXV2Bots/AbilitiesContext";
import { useLateInitContext } from "src/hooks/useLateInitContext";
import { PermissionWrapper, PermissionWrapperProps } from "src/permissions/PermissionWrapper";
import { DEXV2Ability } from "src/state/DEXV2/shared/AbilitiesStore";

export interface DEXV2PermissionsWrapperProps
  extends Omit<PermissionWrapperProps, "abilityName" | "party"> {
  ability: DEXV2Ability;
}

export const DEXV2PermissionsWrapper = observer(
  ({ ability, ...props }: DEXV2PermissionsWrapperProps) => {
    const { party } = useLateInitContext(AbilitiesContext);
    return <PermissionWrapper party={party} abilityName={ability} {...props} />;
  }
);
